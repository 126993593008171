import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {RulesWeightedCombinationMDComponent} from './rules-weighted-combination-md.component';
import {
    CreateRulesWeightedCombinationComponent
} from './create-rules-weighted-combination/create-rules-weighted-combination.component';

@NgModule({
    declarations: [RulesWeightedCombinationMDComponent, CreateRulesWeightedCombinationComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class RulesWeightedCombinationMDModule {
}
