<div class="other-centers-filter-container mb-4">
    <span *ngIf="!!slotsCenters"
          id="slots-local-filters-center-tippy-button"
          class="other-centers-filter-label text-high-contrast">
        <span translate="label.centers"></span>&nbsp;
        ({{slotsManagementMdUtils.slotsCentersOptions?.selectedCenters?.length}}/{{slotsCenters?.centers?.length}})
    </span>
    <div id="slots-local-filters-center-tippy-popover"
         class="other-centers-filter-popover create-entity-md-wrapper p-3"
         [ngClass]="!tippyInstance ? 'd-none' : null">
        <form name="form" novalidate (ngSubmit)="saveSlotsCentersForDisplayingSlots(slotsCenters.centers)">
            <div class="popover-body px-0 pb-2 pt-0" *ngFor="let center of slotsCenters?.centers">
                <div (click)="selectCenter(center)" class="center-information-container" [ngClass]="{'selected-center': center?.selected, 'unselected-center': !center?.selected}">
                    <span class="pr-1"><i class="fas fa-map-pin"></i></span>
                    <span class="pr-1 font-weight-bold" *ngIf="!!center?.distanceMeters">{{getDistanceFromMetersInKm(center?.distanceMeters) | commaDotSwapLocalized}} km</span>
                    <span class="pr-1">{{center?.name}}</span>
                    <br>
                    <span class="pr-1 font-weight-bold">{{getTodayAndTotalSlotsForCenter(center?.shortId)}}</span>
                    <span class="pr-1" translate="label.slotsToday"></span> /&nbsp;
                    <span class="pr-1 font-weight-bold">{{getTodayAndTotalSlotsForCenter(center?.shortId, true)}}</span>
                    <span class="pr-1" translate="label.slotsTotal"></span>
                </div>
            </div>

            <button class="btn float-right theme-btn"
                    type="submit"
                    translate="label.ok">
            </button>
        </form>
    </div>
</div>
