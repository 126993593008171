import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {TagMDComponent} from './tag-md.component';
import {CreateTagComponent} from './create-tag/create-tag.component';

@NgModule({
    declarations: [TagMDComponent, CreateTagComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class TagMdModule {
}
