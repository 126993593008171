import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserProvider, ApiKeysType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {constants} from '../../../shared/constants/constants';

@Component({
    selector: 'app-user-api-key-modal',
    templateUrl: './user-api-key-modal.component.html',
    styleUrls: ['./user-api-key-modal.component.scss']
})
export class UserApiKeyModalComponent implements OnInit {
    @Input() userId: string;
    @Input() userFullName: string;
    @Input() userName: string;
    @Input() apiKey: ApiKeysType = {} as ApiKeysType;
    constants = constants;

    constructor(
        public activeModal: NgbActiveModal,
        private userProvider: UserProvider,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService
    ) {
    }

    ngOnInit() {
        if (!this.apiKey) {
            this.apiKey = this.getEmptyApiKey();
        }

        if (this.apiKey?.apiKeyId || this.apiKey.apiKeyHint) {
            this.apiKey.apiKey = constants.DUMMY_API_KEY;
        }
    }

    generateApiKey() {
        this.ngxLoader.start();
        this.userProvider.generateApiKey(this.userId).subscribe(
            (result) => {
                this.apiKey.apiKey = result.value;
                this.ngxLoader.stop();
            },
            (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    deleteApiKey() {
        this.ngxLoader.start();
        this.userProvider.deleteApiKey(this.apiKey.apiKeyId).subscribe(
            () => {
                this.apiKey = this.getEmptyApiKey();
                this.ngxLoader.stop();
            },
            (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private getEmptyApiKey() {
        return {apiKey: '', apiKeyHint: '', purpose: '', userId: '', apiKeyId: ''} as ApiKeysType;
    }
}
