import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ActionExecutionLogsMDComponent} from './action-execution-logs-md.component';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
    declarations: [ActionExecutionLogsMDComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        NgSelectModule
    ]
})
export class ActionExecutionLogsMDModule {
}
