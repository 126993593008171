import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {ConfirmDeleteModalService, DataStoreProvider, DataStoreType, EntityTypeEnum} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DataStoreMdUtils} from './data-store-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-data-store-md',
    templateUrl: './data-store-md.component.html',
    styleUrls: ['./data-store-md.component.scss']
})
export class DataStoreMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    dataStoreMdList: DataStoreType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    DATA_STORE = 'DataStore';
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchDataStoreChanged = new Subject<string>();

    constructor(
        public dataStoreMdUtils: DataStoreMdUtils,
        public dataStoreProvider: DataStoreProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('DataStoreRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createDataStore') {
            this.loadInitialFilters({tableFilters: true});
        }

        this.loadDataStoreMdData();
        this.subscribeSearchByDataStoreChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create DataStore
    createDataStore(): void {
        this.router.navigate(['/createDataStore']);
    }

    editDataStore(dataStore: DataStoreType) {
        this.router.navigate(['/createDataStore'], {state: {dataStore, action: constants.EDIT}});
    }

    viewDataStore(dataStore: DataStoreType) {
        this.router.navigate(['/createDataStore'], {state: {dataStore, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.dataStoreMdUtils.tableFilters.currentPage = page;
        this.loadDataStoreMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.dataStoreMdUtils.tableFilters.currentPage = 1;
        this.dataStoreMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadDataStoreMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.dataStoreMdUtils.tableFilters.orderBy[property];
        if (this.dataStoreMdUtils.tableFilters.orderBy) {
            this.dataStoreMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadDataStoreMdData(false);
    }

    onSearchDataStoreChanged(value: string) {
        this.searchDataStoreChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.dataStoreMdUtils.tableFilters.orderBy[selectedItem];
        this.loadDataStoreMdData(false);
    }

    displayConfirmDeleteItemModal(dataStoreId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.dataStoreProvider, dataStoreId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(dataStoreId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    // function to get DataStore data
    private loadDataStoreMdData(includeCount: boolean = true) {
        const queryFilter = this.dataStoreMdUtils.getQueryFilterForDataStoreMD(this.dataStoreMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.dataStoreProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.dataStoreMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.onSearchDataStoreChanged('');
        this.loadInitialFilters({tableFilters: true});
        this.loadDataStoreMdData(true);
    }

    private loadInitialFilters({tableFilters}: { tableFilters?: boolean }) {
        if (!!tableFilters) {
            this.dataStoreMdUtils.tableFilters = this.dataStoreMdUtils.getInitialTableFilter();
        }
    }

    // method for deleting table item
    private deleteItem(id) {
        this.dataStoreMdUtils.tableFilters = this.dataStoreMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.dataStoreProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadDataStoreMdData();
                this.messagesService.success('toastr.success.dataStoreDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByDataStoreChanged() {
        const self = this;
        this.searchDataStoreChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.dataStoreMdUtils.tableFilters.filter.name = searchValue;
                self.loadDataStoreMdData();
            });
    }
}
