import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AvailabilitiesTimeInputsDefaultTimesType,
    AvailabilityValidationType,
    BackofficeAvailabilityDataType,
    BackofficeAvailabilityServiceType,
    BackofficeAvailabilityTimeSlotAppointmentTypeType,
    BackofficeAvailabilityTimeSlotType,
    DateAndTimeRangeType,
    ValidCreateAvailabilityType
} from '../availability.types';
import {MatStepper} from '@angular/material/stepper';
import {
    ActionType,
    FormValidationType,
    ScreenTemplateLayoutType,
    TagsForEntityEnum
} from '../../../data-model/general.type';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {AvailabilityUtils} from '../availability-utils';
import {
    AppointmentTypeProvider,
    AvailabilityDataServiceType,
    AvailabilityDataSubServiceType,
    AvailabilityDataTimeSlotType,
    AvailabilityDataType,
    AvailabilityProvider,
    AvailabilityWithTagsType,
    CenterProvider,
    CoveragePlanProvider,
    CoveragePlanSearchResultType,
    DateRangeOptionsType,
    DateRangeResponseType,
    FilterWrapperNameEnum,
    LocationSearchType,
    ODataQueryObjectType,
    ResourceProvider,
    ResourceSearchType,
    ResourceTypeProvider,
    SearchFilterUtils,
    ServiceProvider,
    ServiceSearchType,
    ServiceType,
    TagProvider,
    TagDependentFiltersScopeEnum,
    AvailabilityStatusEnum
} from 'sked-base';
import {mergeMap, take} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {TagsFiltersType, TagsType} from 'sked-base/lib/data-model/tagTypes';
import {AssignSubServicesOptionsTypes} from '../../../shared/component/assign-sub-services/assign-sub-services.types';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {TimeSlotsUtils} from '../../../shared/utils/time-slots.utils';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {NgxTimepickerFieldComponent} from 'ngx-material-timepicker';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-availability',
    templateUrl: './create-availability.component.html',
    styleUrls: ['./create-availability.component.scss']
})
export class CreateAvailabilityComponent implements OnInit, AfterViewInit, OnDestroy {
    VIEW = ActionType.View;
    CREATE = ActionType.Create;
    EDIT = ActionType.Edit;
    action: ActionType;
    readonly validateAvailabilityLoaderId = 'validateAvailabilityLoader';
    readonly serviceTagsLoaderId = 'serviceTagsLoader';
    readonly resourceTagsLoaderId = 'resourceTagsLoader';
    validTemplate: ValidCreateAvailabilityType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;
    @ViewChild('timePickerTimeFrom') timePickerTimeFrom: NgxTimepickerFieldComponent;
    totalStepsCount: number;
    availabilityData: BackofficeAvailabilityDataType = {} as BackofficeAvailabilityDataType;
    initialAvailability: BackofficeAvailabilityDataType;
    mainDependentFilters: any;
    dateRangeOptions: DateRangeOptionsType;
    tagsForService: TagsType[] = [];
    tagsForResource: TagsType[] = [];
    availabilityStatusEnum = AvailabilityStatusEnum;

    scopedService: BackofficeAvailabilityServiceType;
    scopedTimeSlot: BackofficeAvailabilityTimeSlotType;
    timeSlotDaysList: string[] = this.timeSlotsUtils.getWeekDays();
    updateAvailabilityServices: boolean;
    updateAvailabilityTimeSlots: boolean;
    validServiceDuration = true;
    validServiceControlDuration = true;
    isTimeValid = true;
    initialScopedServiceValues: ServiceSearchType[] = [];
    subServicesOptions: AssignSubServicesOptionsTypes = {} as AssignSubServicesOptionsTypes;

    initialResource: ResourceSearchType[] = [];
    initialCenter: LocationSearchType[] = [];
    initialScopedService: ServiceSearchType[] = [];
    initialCoveragePlan: CoveragePlanSearchResultType[] = [];
    initialAppointmentType: BackofficeAvailabilityTimeSlotAppointmentTypeType[] = [];
    availabilityValidation = {} as AvailabilityValidationType;

    RegexWholeNumber = /^[0-9]\d*$/;
    availabilityDateAndTimeRange: DateAndTimeRangeType;
    availabilityRangeValidation: FormValidationType;
    servicesExclusionList: string[] = [];
    timeInputsDefaultTimes = {} as AvailabilitiesTimeInputsDefaultTimesType;

    constructor(
        public appointmentTypeProvider: AppointmentTypeProvider,
        private availabilityProvider: AvailabilityProvider,
        public availabilityUtils: AvailabilityUtils,
        public centerProvider: CenterProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        public dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public resourceProvider: ResourceProvider,
        private resourceTypeProvider: ResourceTypeProvider,
        private router: Router,
        public searchFilterUtils: SearchFilterUtils,
        public serviceProvider: ServiceProvider,
        public tagProvider: TagProvider,
        private timeSlotsUtils: TimeSlotsUtils,
        private configData: ConfigDataService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.setupInitialState();
        this.loadInitialData();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        // we need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(key));
        });
        if (this.action === ActionType.Edit && this.myStepper?._steps?.length) {
            // set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.myStepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
    }

    ngOnDestroy(): void {
    }

    onStepHeaderClick(stepClicked: number) {
        const selectedIndex = this.myStepper.selectedIndex;
        // click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
        // user just clicked on stepClicked and the move was successful
        this.displayErrorToastr(selectedIndex, stepClicked);
    }

    // returns true if error was displayed
    private displayErrorToastr(selectedIndex, clickedIndex) {
        if (this.updateAvailabilityServices || this.updateAvailabilityTimeSlots) {
            // if clicked on previous or next step headers while having the services / timeslot create open:
            // set selectedIndex by hand
            if (this.updateAvailabilityServices) {
                this.myStepper.selectedIndex = 1;
            } else if (this.updateAvailabilityTimeSlots) {
                this.myStepper.selectedIndex = 2;
            }
            this.messagesService.warning('label.error.createAvailabilityCardIsOpenPleaseSaveOrCancel');
            return;
        }
        // error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!this.areStepsValid(step)) {
                if (step === 0) {
                    this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                } else if (step === 1) {
                    if (this.availabilityData.services.length === 0) {
                        this.messagesService.warning('label.error.multiAppointmentTemplateNoServices');
                    } else {
                        this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                    }
                } else if (step === 2) {
                    if (this.availabilityData.timeSlots.length === 0) {
                        this.messagesService.warning('label.error.multiAppointmentTemplateNoTimeSlot');
                    } else {
                        this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                    }
                }
                if (selectedIndex !== step) {
                    this.myStepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    //BACK button
    goBack(stepper: MatStepper) {
        if (this.updateAvailabilityServices || this.updateAvailabilityTimeSlots) {
            this.displayErrorToastr(stepper.selectedIndex, stepper.selectedIndex - 1);
        } else {
            stepper.previous();
        }
    }

    //NEXT button
    goForward(stepper: MatStepper) {
        if (this.areStepsValid(stepper.selectedIndex)) {
            stepper.next();
        } else {
            this.displayErrorToastr(stepper.selectedIndex, stepper.selectedIndex + 1);
        }
    }

    //Wizard
    onStepChange(stepper: StepperSelectionEvent) {
        const previousStep = stepper.previouslySelectedIndex;
        const currentStep = stepper.selectedIndex;
        // this.showNotSavedChangesMessage = this.updateAvailabilityServices || this.updateAvailabilityTimeSlots;
        if (currentStep === this.totalStepsCount - 1) {
            if (this.availabilityData.endOfDay) {
                // Prepare data to be shown correctly
                this.availabilityData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                    this.availabilityData.validTo, this.availabilityData.timeZoneId, 1
                );
            }
            this.validateAvailability();
        } else {
            if (previousStep === 3 && this.availabilityData.endOfDay) {
                // When we leave the last step, Subtract back one day (added when we reached step 4)
                this.availabilityData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                    this.availabilityData.validTo, this.availabilityData.timeZoneId, -1
                );
            }
        }
    }

    displayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    isLinearStepperOrNot(validTemplate: ValidCreateAvailabilityType) {
        for (const property in validTemplate) {
            if (validTemplate[property] === false) {
                return true;
            }
        }
        return false;
    }

    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.availabilityUtils.isGeneralInfoStepValid(this.availabilityData);
            case 1:
                return this.availabilityUtils.isServicesStepValid(this.availabilityData, this.updateAvailabilityServices);
            case 2:
                return this.availabilityUtils.isTimeSlotsStepValid(this.availabilityData, this.updateAvailabilityTimeSlots);
            default:
                return true; // other steps which don't need validation
        }
    }

    // used to check whether you can go to other steps or not
    getStepControl(step: number): { invalid?: boolean, pending?: boolean } {
        return {
            invalid: !this.areStepsValid(step)
        };
    }

    isTheWholeFormValid(): boolean {
        if (!this.areStepsValid(0)) {
            return false;
        }

        if (!this.areStepsValid(1)) {
            return false;
        }

        if (!this.areStepsValid(2)) {
            return false;
        }
        return true;
    }

    //GENERAL
    onSelectedResource(resourceList) {
        this.availabilityData.tags = [];
        if (resourceList?.length > 0) {
            this.availabilityData.resource = resourceList[0];
            this.availabilityData.resourceId = resourceList[0].id;
            this.mainDependentFilters.location.resourceId = resourceList[0].id;
            this.mainDependentFilters.service.resourceId = resourceList[0].id;
            this.subServicesOptions.resourceId = this.availabilityData.resourceId;
            this.setNewListOfResourceTags(this.availabilityData.resourceId);
        } else {
            this.availabilityData.resource = undefined;
            this.availabilityData.resourceId = undefined;
            this.mainDependentFilters.location.resourceId = undefined;
            this.mainDependentFilters.service.resourceId = undefined;
            this.subServicesOptions.resourceId = undefined;
            this.tagsForResource = [];
        }

    }

    onSelectedCenter(centerList) {
        if (centerList?.length > 0) {
            this.availabilityData.center = centerList[0];
            this.availabilityData.centerId = centerList[0].id;
            this.mainDependentFilters.resource.locationId = centerList[0].id;
            this.mainDependentFilters.service.locationId = centerList[0].id;
            this.loadTimeZoneId(this.availabilityData.centerId);
        } else {
            this.availabilityData.center = undefined;
            this.availabilityData.centerId = undefined;
            this.mainDependentFilters.resource.locationId = undefined;
            this.mainDependentFilters.service.locationId = undefined;
        }
    }

    onSelectTagsForResource(tagList) {
        if (tagList?.length > 0) {
            this.availabilityData.tags = tagList;
        } else {
            this.availabilityData.tags = [];
        }
    }

    //SERVICES
    onSelectedService(serviceList) {
        this.scopedService.tags = [];
        this.validServiceDuration = true;
        this.validServiceControlDuration = true;
        this.initialScopedService = serviceList;
        this.subServicesOptions.subServices = [];
        if (serviceList?.length > 0) {
            // first check if service already exists in availability services table
            const foundServiceIndex = lodash.findIndex(this.availabilityData.services, {serviceId: serviceList[0].id});
            if (foundServiceIndex > -1) {
                this.messagesService.error('toastr.error.duplicateServiceInAvailabilityServices');
            } else {
                this.scopedService.service = serviceList[0];
                this.scopedService.onlineConsultation = serviceList[0].onlineConsultation;
                this.scopedService.subServices = [];
                this.subServicesOptions.serviceId = this.scopedService.service.id;
                this.fetchAdditionalDataForService(this.scopedService.service);
            }
        } else {
            this.scopedService.service = undefined;
            delete this.subServicesOptions.serviceId;
            this.tagsForService = [];
        }
    }

    onSelectServiceTags(tags) {
        if (tags?.length > 0) {
            this.scopedService.tags = tags;
        } else {
            this.scopedService.tags = [];
        }
    }

    onSelectedCoveragePlans(coveragePlanList) {
        if (coveragePlanList?.length > 0) {
            this.scopedService.coveragePlans = coveragePlanList;
        } else {
            this.scopedService.coveragePlans = [];
        }
    }

    onSelectedSubServices(subServicesList) {
        this.scopedService.subServices = lodash.map(subServicesList, (item) => {
            if (!item.subServiceId) {
                item.subServiceId = item.id;
            }
            return item;
        });
        this.subServicesOptions.subServices = subServicesList;
        this.validServiceDuration = this.availabilityUtils.isScopedServiceDurationValid(this.scopedService);
    }

    isServiceDurationValid(scopedService: BackofficeAvailabilityServiceType): boolean {
        return this.availabilityUtils.isScopedServiceDurationValid(this.scopedService);
    }

    openAvailabilityServicesSection(action: ActionType, selectedService: AvailabilityDataServiceType | undefined) {
        this.updateAvailabilityServices = !this.updateAvailabilityServices;

        if (action === ActionType.Edit && !lodash.isEmpty(selectedService)) {
            this.scopedService = lodash.cloneDeep(selectedService);
            this.initialScopedServiceValues.push(this.availabilityUtils.mapAvailabilityDataServiceToServiceSearch(selectedService));
            this.subServicesOptions.subServices = lodash.cloneDeep(this.scopedService.subServices);
        } else {
            this.scopedService = this.availabilityUtils.getInitialScopedService();
            this.initialScopedServiceValues = [];
            this.subServicesOptions.subServices = [];
        }
    }

    onSaveService(scopedService: BackofficeAvailabilityServiceType) {
        const availabilityService = scopedService as unknown as AvailabilityDataServiceType;
        this.validServiceDuration = this.availabilityUtils.isScopedServiceDurationValid(scopedService);
        this.validServiceControlDuration = this.availabilityUtils.isScopedServiceControlDurationValid(scopedService);
        // validate scoped service
        const isValid = this.availabilityUtils.isAvailabilityServiceValid(scopedService, this.availabilityData.services);
        if (isValid) {
            // add additional information
            availabilityService.serviceId = scopedService?.service?.id;
            availabilityService.name = scopedService?.service?.name;
            availabilityService.specialityName = scopedService?.service?.specialityName;
            availabilityService.isMainResourceInBluePrint = scopedService.showDurationForMainResource;
            availabilityService.onlineConsultation = scopedService.onlineConsultation;
            //add or edit the services table
            if (scopedService?.editedIndex >= 0) {
                this.availabilityData.services[scopedService.editedIndex] = availabilityService;
            } else {
                this.availabilityData.services.push(availabilityService);
            }
            this.servicesExclusionList = this.availabilityData?.services.map(service => service.serviceId);
            this.onCancelService();
        }
    }

    onCancelService() {
        this.scopedService = {} as unknown as BackofficeAvailabilityServiceType;
        this.subServicesOptions = {} as AssignSubServicesOptionsTypes;
        this.initialScopedService = [];
        this.initialCoveragePlan = [];
        this.updateAvailabilityServices = false;
        this.validServiceDuration = true;
        this.validServiceControlDuration = true;
        this.servicesExclusionList = this.availabilityData?.services?.map(service => service.serviceId);
    }

    editAvailabilityService(service: AvailabilityDataServiceType, index: number) {
        this.scopedService = lodash.cloneDeep(service) as unknown as BackofficeAvailabilityServiceType;
        this.scopedService.editedIndex = index;
        this.scopedService.showDurationForMainResource = service?.isMainResourceInBluePrint;

        if (!this.scopedService.service) {
            this.scopedService.service = lodash.cloneDeep(service) as ServiceSearchType;
            this.scopedService.service.id = service.serviceId;
        }
        this.subServicesOptions.subServices = this.scopedService.subServices as unknown as AvailabilityDataSubServiceType[];
        this.subServicesOptions.serviceId = this.scopedService.serviceId;
        this.subServicesOptions.showDurationForMainResource = this.scopedService.showDurationForMainResource;
        this.initialScopedService = [this.scopedService.service];
        this.initialCoveragePlan = this.scopedService.coveragePlans as unknown as CoveragePlanSearchResultType[];
        this.updateAvailabilityServices = true;
        this.setNewListOfServiceTags(this.scopedService.service.id, this.EDIT);
    }

    deleteAvailabilityService(index: number) {
        this.availabilityData.services.splice(index, 1);
        this.onCancelService();
    }

    onScopedServiceDurationChanged(duration: number) {
        this.validServiceDuration = this.availabilityUtils.isScopedServiceDurationValid(this.scopedService);
    }

    onScopedServiceControlDurationChanged(scopedService: BackofficeAvailabilityServiceType) {
        this.validServiceControlDuration = this.availabilityUtils.isScopedServiceControlDurationValid(scopedService);
    }

    onEndOfDayChange() {
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
        if (this.availabilityData.endOfDay === false) {
            // If we disable the endOfDay flag we set the time to what it previously was
            this.onValidToDateTimeChanged(this.availabilityDateAndTimeRange.dateTo, this.availabilityDateAndTimeRange.timeTo);
        }
    }

    onValidFromDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct, dateChanged: boolean = false) {
        // if validFrom date is changed, set validFrom time to 00:00
        if (dateChanged) {
            time = {hour: 0, minute: 0, second: 0} as NgbTimeStruct;
            this.timePickerTimeFrom.changeHour(0);
            this.timePickerTimeFrom.changeMinute(0);
        }
        this.availabilityDateAndTimeRange.timeFrom = time;
        this.availabilityData.validFrom = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.availabilityDateAndTimeRange.timeFrom, this.availabilityData.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    onValidToDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct) {
        this.availabilityDateAndTimeRange.timeTo = time;
        this.availabilityData.validTo = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.availabilityDateAndTimeRange.timeTo, this.availabilityData.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    getValidateAvailabilityDateAndTimeRange(): FormValidationType {
        return this.dateTimeUtils.getValidateDateAndTimeRange(
            this.availabilityData.validFrom,
            this.availabilityData.validTo,
            this.availabilityData.endOfDay,
            this.availabilityData.timeZoneId);
    }

    //TIME SLOTS
    // Obsolete
    onDateRangeChanged(dateRangeObject: DateRangeResponseType) {
        if (dateRangeObject.isValid) {
            // tslint:disable-next-line:max-line-length
            this.availabilityData.validFrom = dateRangeObject.fromDate === null ? null : moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRangeObject.fromDate)).format();
            // tslint:disable-next-line:max-line-length
            this.availabilityData.validTo = dateRangeObject.toDate === null ? null : moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRangeObject.toDate)).format();
        }
    }

    openTimeSlotsSection(action: ActionType, selectedTimeSlots: AvailabilityDataTimeSlotType | undefined) {
        this.updateAvailabilityTimeSlots = !this.updateAvailabilityTimeSlots;

        if (action === ActionType.Edit && !lodash.isEmpty(selectedTimeSlots)) {
            this.scopedTimeSlot = lodash.cloneDeep(selectedTimeSlots);
        } else {
            this.scopedTimeSlot = this.availabilityUtils.getInitialTimeSlot();
        }
    }

    onHourFromChanged(hourFromTime: string) {
        this.scopedTimeSlot.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.scopedTimeSlot.hourFromTime = hourFromTime;
        this.isTimeValid =
            !this.generalUtils.isNullOrUndefined(this.scopedTimeSlot.hourFrom) &&
            !this.generalUtils.isNullOrUndefined(this.scopedTimeSlot.hourTo) &&
            this.scopedTimeSlot.hourTo > this.scopedTimeSlot.hourFrom;
    }

    onHourToChanged(hourToTime: string) {
        this.scopedTimeSlot.hourTo = moment.duration(hourToTime).asMinutes();
        this.scopedTimeSlot.hourToTime = hourToTime;
        this.isTimeValid =
            !this.generalUtils.isNullOrUndefined(this.scopedTimeSlot.hourFrom) &&
            !this.generalUtils.isNullOrUndefined(this.scopedTimeSlot.hourTo) &&
            this.scopedTimeSlot.hourTo > this.scopedTimeSlot.hourFrom;
    }

    addNewTimeSlotAppointmentType() {
        const newAppointmentType = this.availabilityUtils.getInitialAppointmentType();
        if (this.scopedTimeSlot?.appointmentTypes) {
            this.scopedTimeSlot.appointmentTypes.push(newAppointmentType);
        }
    }

    onAppointmentTypeSelected(appointmentType: BackofficeAvailabilityTimeSlotAppointmentTypeType[], index: number) {
        //filter component returns array with only one value
        if (appointmentType[0]?.id) {
            this.scopedTimeSlot.appointmentTypes[index].id = appointmentType[0].id;
            this.scopedTimeSlot.appointmentTypes[index].name = appointmentType[0].name;
            this.scopedTimeSlot.appointmentTypes[index].consumesPlannedCapacity = appointmentType[0].consumesPlannedCapacity;
        } else {
            this.scopedTimeSlot.appointmentTypes[index] = this.availabilityUtils.getInitialAppointmentType();
        }
    }

    onAppointmentTypeDeleted(appointmentType: BackofficeAvailabilityTimeSlotAppointmentTypeType, index: number) {
        this.scopedTimeSlot.appointmentTypes.splice(index, 1);
    }

    onSaveTimeSlot(scopedTimeSlot: BackofficeAvailabilityTimeSlotType) {
        const isTimeSlotValid = this.availabilityUtils.validateTimeSlot(scopedTimeSlot);
        if (isTimeSlotValid) {
            const timeSlotToSave = lodash.cloneDeep(this.availabilityUtils.mapTimeSlotFromViewToSave(scopedTimeSlot));
            //add or edit the services table
            if (scopedTimeSlot?.editedIndex >= 0) {
                this.availabilityData.timeSlots[scopedTimeSlot.editedIndex] = timeSlotToSave;
            } else {
                this.availabilityData.timeSlots.push(timeSlotToSave);
            }
            //show success message after timeslot was added
            this.messagesService.success('toastr.success.timeSlotAdded');

            // initialize scoped time slot
            this.clearScopedTimeSlot();
        }
    }

    onCancelTimeSlot() {
        this.clearScopedTimeSlot();
    }

    private clearScopedTimeSlot() {
        this.scopedTimeSlot = {} as unknown as BackofficeAvailabilityTimeSlotType;
        this.updateAvailabilityTimeSlots = false;
    }

    editAvailabilityTimeSlot(timeSlot: AvailabilityDataTimeSlotType, index: number) {
        this.scopedTimeSlot = lodash.cloneDeep(timeSlot) as unknown as BackofficeAvailabilityTimeSlotType;
        this.scopedTimeSlot.editedIndex = index;
        // We need to force rerender hourFromTime and hourToTime, because if they already have values, and we want to change them they are not updated
        this.scopedTimeSlot.hourFromTime = null;
        this.scopedTimeSlot.hourToTime = null;
        this.changeDetectorRef.detectChanges();
        this.scopedTimeSlot.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.scopedTimeSlot.hourFrom);
        this.scopedTimeSlot.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.scopedTimeSlot.hourTo);
        this.scopedTimeSlot.selectedDays = this.timeSlotsUtils.getTimeSlotSelectedDays(timeSlot);
        this.updateAvailabilityTimeSlots = true;
    }

    deleteAvailabilityTimeSlot(timeSlot: AvailabilityDataTimeSlotType, index: number) {
        this.availabilityData.timeSlots.splice(index, 1);
        this.onCancelTimeSlot();
    }

    // Obsolete
    areDatesValid(validFrom: string, validTo: string): boolean {
        return this.dateTimeUtils.isDateRangeTodayOrFuture(new Date(validFrom), new Date(validTo));
    }

    //OVERVIEW
    saveAvailabilityData(availabilityData: BackofficeAvailabilityDataType) {
        if (this.isTheWholeFormValid()) {
            const availabilityToSend = this.availabilityUtils.mapAvailabilityForSend(availabilityData);
            if (this.screenTemplateLayout.action === ActionType.Create || this.screenTemplateLayout.action === ActionType.Copy) {
                this.addAvailability(availabilityToSend);
            } else if (this.screenTemplateLayout.action === ActionType.Edit) {
                this.editAvailability(availabilityToSend);
            }
        }
    }

    goToParentPage() {
        if (!history?.state?.parentPageLink) {
            this.router.navigate(['availability']);
        } else {
            this.router.navigate([history?.state?.parentPageLink]);
        }
    }

    goToEdit() {
        history.replaceState({availabilityId: this.availabilityData.id, action: ActionType.Edit}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private addAvailability(availabilityToSend: AvailabilityWithTagsType) {
        this.ngxLoader.start();
        this.availabilityProvider.addAvailability(availabilityToSend)
            .pipe(take(1))
            .subscribe(response => {
                this.messagesService.success('toastr.success.newAvailabilityAdded');
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private editAvailability(availabilityToSend: AvailabilityWithTagsType) {
        this.ngxLoader.start();
        this.availabilityProvider.editAvailability(availabilityToSend)
            .pipe(take(1))
            .subscribe(response => {
                this.messagesService.success('toastr.success.availabilityEdit');
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private loadInitialData() {
        this.ngxLoader.start();
        this.getAvailabilityData()
            .pipe(
                mergeMap((availabilityResponse) => {
                    return forkJoin([of(availabilityResponse),
                        this.getAllTagsWithRecommendationsForEntity(TagsForEntityEnum.Resource, availabilityResponse?.resourceId),
                        this.resourceTypeProvider.getResourceTypeRoom()]);
                }),
                take(1))
            .subscribe(([availabilityData, tagsForResource, resourceTypeRoom]) => {
                if (availabilityData.services) {
                    this.servicesExclusionList = availabilityData?.services.map(service => service.serviceId);
                }
                this.tagsForResource = lodash.orderBy(tagsForResource.value, ['recommended'], ['desc']);
                this.initialAvailability = lodash.cloneDeep(availabilityData);
                this.mainDependentFilters.resource.resourceTypeExclusionList = [resourceTypeRoom?.value[0]?.id];
                if (this.action !== ActionType.Create) {
                    this.availabilityData = availabilityData as unknown as BackofficeAvailabilityDataType;
                    if (this.availabilityData?.center) {
                        this.availabilityData.center.id = this.availabilityData.centerId;
                    }
                    this.availabilityData.resource.id = this.availabilityData.resourceId;
                    this.subServicesOptions.resourceId = this.availabilityData.resourceId;
                    this.mainDependentFilters.location.resourceId = this.availabilityData.resourceId;
                    this.mainDependentFilters.resource.locationId = this.availabilityData.centerId;
                    this.mainDependentFilters.service.resourceId = this.availabilityData.resourceId;
                    this.mainDependentFilters.service.locationId = this.availabilityData.centerId;

                    const isEditOrCopy = this.action === ActionType.Edit || this.action === ActionType.Copy;
                    if (availabilityData.endOfDay) {
                        availabilityData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                            availabilityData.validTo, availabilityData.timeZoneId, isEditOrCopy ? -1 : 0
                        );
                    }

                    this.availabilityDateAndTimeRange = this.dateTimeUtils.getDateAndTimeRangeFromString(
                        availabilityData.validFrom, availabilityData.validTo, availabilityData.timeZoneId
                    );
                    this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
                    this.timeInputsDefaultTimes.timeFrom = this.dateTimeUtils.ngbTimeStructToHourMinuteString(this.availabilityDateAndTimeRange.timeFrom);
                    this.timeInputsDefaultTimes.timeTo = this.dateTimeUtils.ngbTimeStructToHourMinuteString(this.availabilityDateAndTimeRange.timeTo);
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            location: this.searchFilterUtils.getLocationDependentFilters(),
            resource: this.searchFilterUtils.getResourceDependentFilters(),
            service: this.searchFilterUtils.getServiceDependentFilters(),
            coveragePlan: this.searchFilterUtils.getCoveragePlanDependentFilters(),
            appointmentType: this.searchFilterUtils.getAppointmentTypeDependentFilters(),
            resourceTags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedResource, false),
            serviceTags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedService, false)
        };
        this.mainDependentFilters.service.applyOnlineConsultationCondition = false;
        this.dateRangeOptions = this.dateTimeUtils.getInitialDateRangeOptions();
        this.dateRangeOptions.minDate = this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate;
        this.availabilityDateAndTimeRange = this.dateTimeUtils.getInitialDateAndTimeRange();
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();

        if (!history?.state?.action) {
            this.action = ActionType.Create;
        } else {
            this.action = history?.state?.action;
        }
        if (this.action === ActionType.View) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', ActionType.View, undefined, 'button.back');
        } else if (this.action === ActionType.Edit) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', ActionType.Edit, 'button.save', 'label.close');
        } else if (this.action === ActionType.Copy) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.copy', ActionType.Copy, 'button.save', 'label.close');
        } else if (this.action === ActionType.Create) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', ActionType.Create, 'label.create', 'label.close');
            this.availabilityData = this.availabilityUtils.getInitialAvailabilityDataValue();
            this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
            this.timeInputsDefaultTimes.timeFrom = this.dateTimeUtils.ngbTimeStructToHourMinuteString(this.availabilityDateAndTimeRange.timeFrom);
            this.timeInputsDefaultTimes.timeTo = this.dateTimeUtils.ngbTimeStructToHourMinuteString(this.availabilityDateAndTimeRange.timeTo);
            this.checkForInitialFiltersValueForCreate(history?.state?.filters);
        }
    }

    private checkForInitialFiltersValueForCreate(filters) {
        if (filters) {
            for (const filter of filters) {
                switch (filter.name) {
                    case FilterWrapperNameEnum.resource: {
                        this.availabilityData.resource = filter.value;
                        this.availabilityData.resourceId = filter?.value?.id;
                        this.mainDependentFilters.location.resourceId = filter?.value?.id;
                        this.mainDependentFilters.service.resourceId = filter?.value?.id;
                        this.setNewListOfResourceTags(this.availabilityData.resourceId);
                        break;
                    }
                    case FilterWrapperNameEnum.location: {
                        this.availabilityData.center = filter.value;
                        this.availabilityData.centerId = filter?.value?.id;
                        this.mainDependentFilters.resource.locationId = filter?.value?.id;
                        this.mainDependentFilters.service.locationId = filter?.value?.id;
                        this.loadTimeZoneId(this.availabilityData.centerId);
                        break;
                    }
                }
            }
        }
    }

    private setNewListOfResourceTags(resourceId: string) {
        this.ngxLoader.startLoader(this.resourceTagsLoaderId);
        this.getAllTagsWithRecommendationsForEntity(TagsForEntityEnum.Resource, resourceId)
            .pipe(take(1))
            .subscribe((tagsForResource) => {
                this.tagsForResource = lodash.orderBy(tagsForResource.value, ['recommended'], ['desc']);
                //autofill recommended tags
                this.availabilityData.tags = lodash.filter(tagsForResource.value, 'recommended');
            }, err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stopLoader(this.resourceTagsLoaderId);
            }, () => {
                this.ngxLoader.stopLoader(this.resourceTagsLoaderId);
            });
    }

    private setNewListOfServiceTags(serviceId: string, actionType: ActionType) {
        this.ngxLoader.startLoader(this.serviceTagsLoaderId);
        this.getAllTagsWithRecommendationsForEntity(TagsForEntityEnum.Service, serviceId)
            .pipe(take(1))
            .subscribe((tags) => {
                this.onServiceTagsReceived(tags?.value, actionType);
            }, err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stopLoader(this.serviceTagsLoaderId);
            }, () => {
                this.ngxLoader.stopLoader(this.serviceTagsLoaderId);
            });
    }

    private getAllTagsWithRecommendationsForEntity(type: TagsForEntityEnum, entityId: string): Observable<{ value: TagsType[], count?: number }> {
        if (entityId && this.action !== ActionType.View) {
            const filter: TagsFiltersType = {} as TagsFiltersType;
            filter.entityType = type;
            filter.entityId = entityId;
            return this.tagProvider.getAllTagsWithRecommendationsForEntity(filter);
        } else {
            return of({value: [], count: 0});
        }
    }

    private getAvailabilityData(): Observable<AvailabilityDataType> {
        if (this.action !== ActionType.Create) {
            return this.availabilityProvider.getAvailabilityDataById(history?.state?.availabilityId);
        } else {
            return of({} as AvailabilityDataType);
        }
    }

    private onErrorResponse(err) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
    }

    private loadTimeZoneId(centerId: string) {
        const filter = {} as ODataQueryObjectType;
        filter.filter = {Id: {eq: {type: 'guid', value: centerId}}};
        filter.select = ['Id', 'TimeZoneId'];

        this.ngxLoader.start();
        this.availabilityData.timeZoneId = undefined;
        this.centerProvider.getEntries(filter)
            .pipe(take(1))
            .subscribe((center) => {
                this.availabilityData.timeZoneId = center.value[0].timeZoneId;
            }, err => {
                this.onErrorResponse(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private validateAvailability() {
        this.availabilityValidation = {} as AvailabilityValidationType;
        const availabilityToSend = this.availabilityUtils.mapAvailabilityForSend(this.availabilityData);
        this.ngxLoader.startLoader(this.validateAvailabilityLoaderId);
        this.availabilityProvider.validate(availabilityToSend, this.availabilityUtils.getTypeForValidateAvailability(this.action))
            .subscribe((response) => {
                this.availabilityValidation = response as AvailabilityValidationType;
                this.availabilityValidation.isValid = !response.error;
            }, err => {
                this.onErrorResponse(err);
                this.ngxLoader.stopLoader(this.validateAvailabilityLoaderId);
            }, () => {
                this.ngxLoader.stopLoader(this.validateAvailabilityLoaderId);
            });
    }

    private fetchAdditionalDataForService(service) {
        if (service?.id) {
            this.ngxLoader.start();
            forkJoin([this.serviceProvider.getEntries(this.availabilityUtils.getQueryFilterForServices(service)),
                this.resourceProvider.getIsBluePrintMainResourceForService(this.availabilityData.resourceId, service.id),
                this.getAllTagsWithRecommendationsForEntity(TagsForEntityEnum.Service, service.id)])
                .pipe(take(1))
                .subscribe(([serviceAdditionalData, showDurationForMainResource, serviceTags]) => {
                    const isControlPatientServiceDurationEnabled = this.configData.isFeatureActive('control-patient-service-duration');
                    this.setServiceAdditionalData(serviceAdditionalData.value[0], showDurationForMainResource.value,
                        isControlPatientServiceDurationEnabled);
                    // if service doesn't have sub services then we can validate duration
                    if (!serviceAdditionalData.value[0].hasSubServices) {
                        this.validServiceDuration = this.availabilityUtils.isScopedServiceDurationValid(this.scopedService);
                        this.validServiceControlDuration = this.availabilityUtils.isScopedServiceControlDurationValid(this.scopedService);
                    }
                    this.subServicesOptions.showDurationForMainResource = this.scopedService.showDurationForMainResource;

                    this.onServiceTagsReceived(serviceTags.value, this.CREATE);
                }, err => {
                    this.onErrorResponse(err);
                    this.ngxLoader.stop();
                }, () => {
                    this.ngxLoader.stop();
                });
        }
    }

    private setServiceAdditionalData(serviceAdditionalData: ServiceType, showDurationForMainResource: boolean,
                                     isControlDurationEnabled: boolean) {
        this.scopedService.defaultDuration = serviceAdditionalData.defaultDuration;
        this.scopedService.minSubServices = serviceAdditionalData.minSubServices;
        this.scopedService.maxSubServices = serviceAdditionalData.maxSubServices;
        this.scopedService.duration = serviceAdditionalData.defaultDuration;
        this.scopedService.controlDuration = !!isControlDurationEnabled ? serviceAdditionalData.defaultDuration : undefined;
        this.scopedService.serviceId = serviceAdditionalData.id;
        this.scopedService.multiResourceBluePrintId = serviceAdditionalData.multiResourceBluePrintId;
        this.scopedService.showDurationForMainResource = showDurationForMainResource;
    }

    private onServiceTagsReceived(tags, actionType: ActionType) {
        this.tagsForService = lodash.orderBy(tags, ['recommended'], ['desc']);
        if (actionType !== this.EDIT) {
            //autofill recommended tags
            this.scopedService.tags = lodash.filter(tags, 'recommended');
        }
    }


}
