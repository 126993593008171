import {NgModule} from '@angular/core';
import {FeatureRoutingModule} from './feature-routing.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {PageNotFoundModule} from './page-not-found/page-not-found.module';
import {CapacityPlannerModule} from './capacity-planner/capacity-planner.module';
import {SharedModule} from '../shared/shared.module';
import {SigninOidcComponent} from './login-process/signin-oidc/signin-oidc.component';
import {RedirectSilentRenewComponent} from './login-process/redirect-silent-renew/redirect-silent-renew.component';
import {RoomReservationModule} from './room-reservation/room-reservation.module';
import {ExportModule} from './export/export.module';
import {MultiAppointmentModule} from './multi-appointment-template-md/multi-appointment.module';
import {RoomReservationsExportComponent} from './room-reservations-export/room-reservations-export.component';
import {SubServiceModule} from './sub-service-md/sub-service.module';
import {ServiceMDModule} from './service-md/service-md.module';
import {SpecialityMDModule} from './speciality-md/speciality-md.module';
import {AreaMDModule} from './area-md/area-md.module';
import {UserMDModule} from './user-md/user-md.module';
import {TeamMDModule} from './team-md/team-md.module';
import {TagMdModule} from './tag-md/tag-md.module';
import {ResourceCalendarModule} from './resource-calendar/resource-calendar.module';
import {ResourceTypeMDModule} from './resource-type-md/resource-type-md.module';
import {AppointmentTypeMDModule} from './appointment-type-md/appointment-type-md.module';
import {AppointmentTypeDefinitionMDModule} from './appointment-type-definition-md/appointment-type-definition-md.module';
import {CoverageCompanyMDModule} from './coverage-company-md/coverage-company-md.module';
import {CoveragePlanMDModule} from './coverage-plan-md/coverage-plan-md.module';
import {CenterMDModule} from './center-md/center-md.module';
import {NavBarModule} from './nav-bar/nav-bar.module';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {SideBarComponent} from './side-bar/side-bar.component';
import {SideBarModule} from './side-bar/side-bar.module';
import {AvailabilityModule} from './availability/availability.module';
import {ExclusionModule} from './exclusion/exclusion.module';
import {HolidayMDModule} from './holiday-md/holiday-md.module';
import {ExclusionReasonMDModule} from './exclusion-reason-md/exclusion-reason-md.module';
import {HolidayCalendarMDModule} from './holiday-calendar-md/holiday-calendar-md.module';
import {AppointmentStatusTransitionReasonMDModule} from './appointment-status-transition-reason-md/appointment-status-transition-reason-md.module';
import {AvailabilityExclusionExportMDComponent} from './availability-exclusion-export-md/availability-exclusion-export-md.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {ActivityPlanTemplateMDModule} from './activity-plan-template-md/activity-plan-template-md.module';
import {SystemConfigComponent} from './system-config/system-config.component';
import {SlotsManagementModule} from './slots-management/slots-management.module';
import {BookingFiltersManagementModule} from './booking-filters-management/booking-filters-management.module';
import {EventActionMDModule} from './event-action-md/event-action-md.module';
import {DataStoreMDModule} from './data-store-md/data-store-md.module';
import {PatientDocumentsComponent} from './patient-documents/patient-documents.component';
import {RelationPairsMDModule} from './relation-pairs-md/relation-pairs-md.module';
import {ObjectDetailMDModule} from './object-detail-md/object-detail-md.module';
import {ResourceUtilizationModule} from './resource-utilization/resource-utilization.module';
import {GraphicalResourcePlannerModule} from './graphical-resource-planner/graphical-resource-planner.module';
import {ApiIntegrationComponent} from './api-integration/api-integration.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TaskListModule} from './task-list/task-list.module';
import {PatientAuditModule} from './patient-audit/patient-audit.module';
import {PatientDashboardModule} from './patient-dashboard/patient-dashboard.module';
import {AppointmentListComponent} from './appointment-list/appointment-list.component';
import {AppointmentListFiltersComponent} from './appointment-list/appointment-list-filters/appointment-list-filters.component';
import {TimeWindowForChannelMDModule} from './time-window-for-channel-md/time-window-for-channel-md.module';
import {ExcelImportComponent} from './excel-import/excel-import.component';
import {RulesModule} from './rules-management/rules.module';
import {TimeWindowSpecialityAreaMDModule} from './time-window-speciality-area-md/time-window-speciality-area-md.module';
import {ResourceChannelRestrictionMDModule} from './resource-channel-restriction-md/resource-channel-restriction-md.module';
import {MultiResourceBluePrintMDModule} from './multi-resource-blue-print-md/multi-resource-blue-print-md.module';
import {MultiResourceCombinationMDModule} from './multi-resource-combination-md/multi-resource-combination-md.module';
import {ExportAppointmentsComponent} from './export-appointments/export-appointments.component';
import {ResourceSwapComponent} from './resource-swap/resource-swap.component';
import {ResourceSwapFiltersComponent} from './resource-swap/resource-swap-filters/resource-swap-filters.component';
import {ResourceSwapAppointmentListComponent} from './resource-swap/resource-swap-appointment-list/resource-swap-appointment-list.component';
import {ResourceSwapOptionsComponent} from './resource-swap/resource-swap-options/resource-swap-options.component';
import {ResourceWorkScheduleMDModule} from './resource-work-schedule-md/resource-work-schedule-md.module';
import {ResourceMDModule} from './resource-md/resource-md.module';
import {MultiAppointmentBookingModule} from './multi-appointment-booking/multi-appointment-booking.module';
import {RulesWeightedCombinationMDModule} from './rules-weighted-combination-md/rules-weighted-combination-md.module';
import {ActionExecutionLogsMDModule} from './action-execution-logs-md/action-execution-logs-md.module';
import {ChangeLogMDModule} from './change-log-md/change-log-md.module';
import {AppointmentListExportModalComponent} from './appointment-list/appointment-list-export-modal/appointment-list-export-modal.component';
import {ActivityPlanModule} from './activity-plan/activity-plan.module';

@NgModule({
    declarations: [SigninOidcComponent,
        RedirectSilentRenewComponent,
        RoomReservationsExportComponent,
        AvailabilityExclusionExportMDComponent,
        UserProfileComponent,
        SystemConfigComponent,
        PatientDocumentsComponent,
        ApiIntegrationComponent,
        AppointmentListComponent,
        AppointmentListFiltersComponent,
        ExcelImportComponent,
        ExportAppointmentsComponent,
        ResourceSwapComponent,
        ResourceSwapFiltersComponent,
        ResourceSwapAppointmentListComponent,
        ResourceSwapOptionsComponent,
        AppointmentListExportModalComponent],
    imports: [
        FeatureRoutingModule,
        DashboardModule,
        CapacityPlannerModule,
        PageNotFoundModule,
        SharedModule,
        ExportModule,
        RoomReservationModule,
        MultiAppointmentModule,
        ServiceMDModule,
        SubServiceModule,
        SpecialityMDModule,
        AreaMDModule,
        UserMDModule,
        TeamMDModule,
        TagMdModule,
        ResourceCalendarModule,
        PatientDashboardModule,
        PatientAuditModule,
        ResourceTypeMDModule,
        AppointmentTypeMDModule,
        AppointmentTypeDefinitionMDModule,
        CoverageCompanyMDModule,
        CoveragePlanMDModule,
        CenterMDModule,
        AvailabilityModule,
        ExclusionModule,
        NavBarModule,
        SideBarModule,
        HolidayMDModule,
        ExclusionReasonMDModule,
        HolidayCalendarMDModule,
        AppointmentStatusTransitionReasonMDModule,
        ActivityPlanTemplateMDModule,
        SlotsManagementModule,
        BookingFiltersManagementModule,
        EventActionMDModule,
        DataStoreMDModule,
        RelationPairsMDModule,
        ObjectDetailMDModule,
        ResourceUtilizationModule,
        GraphicalResourcePlannerModule,
        TaskListModule,
        ClipboardModule,
        TimeWindowForChannelMDModule,
        RulesModule,
        TimeWindowSpecialityAreaMDModule,
        ResourceChannelRestrictionMDModule,
        MultiResourceBluePrintMDModule,
        MultiResourceCombinationMDModule,
        ResourceWorkScheduleMDModule,
        ResourceMDModule,
        MultiAppointmentBookingModule,
        RulesWeightedCombinationMDModule,
        ActionExecutionLogsMDModule,
        ChangeLogMDModule,
        ActivityPlanModule,
    ],
    exports: [FeatureRoutingModule, NavBarComponent, SideBarComponent]
})
export class FeaturesModule {
}
