import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ResourceWorkScheduleMDComponent} from './resource-work-schedule-md.component';
import {CreateResourceWorkScheduleComponent} from './create-resource-work-schedule/create-resource-work-schedule.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
    declarations: [ResourceWorkScheduleMDComponent, CreateResourceWorkScheduleComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        NgxMaterialTimepickerModule,
        MatFormFieldModule,
        MatSelectModule,
    ]
})
export class ResourceWorkScheduleMDModule {
}
