import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    MultiAppointmentBookingCalendarDayType,
    MultiAppointmentBookingCalendarPageType,
    MultiAppointmentBookingCalendarSelectedDayType,
    MultiAppointmentBookingCalendarDayTypeEnum
} from '../multi-appointment-booking.types';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';

@Component({
    selector: 'app-mab-slots-calendar',
    templateUrl: './mab-slots-calendar.component.html',
    styleUrls: ['./mab-slots-calendar.component.scss']
})
export class MabSlotsCalendarComponent implements OnInit {
    @Output() selectValue: EventEmitter<MultiAppointmentBookingCalendarDayType> = new EventEmitter<MultiAppointmentBookingCalendarDayType>();
    @Output() continueSearching: EventEmitter<void> = new EventEmitter<void>();
    ddFormatWeekDays = this.getWeekDays('dd');
    dayTypeEnum = MultiAppointmentBookingCalendarDayTypeEnum;
    LEFT = 'left';
    RIGHT = 'right';

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils
    ) {
    }

    ngOnInit(): void {
    }

    onArrowClick(arrow: string) {
        let direction = 1;
        if (!this.multiAppointmentBookingMdUtils.slotsCalendarOptions.areOptionsAfterSearch) {
            // Calendar not ready to be interactive yet
            return;
        }
        if (arrow === this.LEFT) {
            if (this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage <= 0) {
                // User clicked on left arrow but currentPage is first page
                return;
            }
            direction = -1;
        } else if (this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage >=
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions.numberOfPages - 1) {
            // User clicked on right arrow but currentPage is last page
            // If didn't already search on time window maximum, continue searching and navigate
            if (this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch !== this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum) {
                this.multiAppointmentBookingMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch = true;
                this.continueSearching.emit();
            }
            return;
        }
        // Direction is 1 if arrow is Right and -1 if arrow is Left
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage += direction;

        // If currentPage is last page, and there are disabled days, continue searching
        if (this.shouldContinueSearching()) {
            this.continueSearching.emit();
        }
        // Update display month with the months and years of the current page
        this.multiAppointmentBookingMdUtils.updateCalendarDisplayMonthAndYear();

        // Keep state of calendar updated
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsCalendarOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsCalendarOptions);
    }

    onCalendarDaySelected(dayItem: MultiAppointmentBookingCalendarDayType, dayIndex: number) {
        // Don't do anything if user didn't search for slots yet
        if (!this.multiAppointmentBookingMdUtils.slotsCalendarOptions.areOptionsAfterSearch) {
            return;
        }
        // Don't do anything if selected day is disabled
        if (dayItem.isDisabled) {
            return;
        }
        // First remove isSelected from the previously selected day
        if (this.multiAppointmentBookingMdUtils.slotsCalendarOptions.previouslySelectedDay?.pageIndex !== undefined &&
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions.previouslySelectedDay?.dayIndex !== undefined) {
            const previouslySelectedDay = this.multiAppointmentBookingMdUtils.slotsCalendarOptions.previouslySelectedDay;
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions
                .calendarPages[previouslySelectedDay.pageIndex]
                .calendarDays[previouslySelectedDay.dayIndex]
                .isSelected = false;
        }
        // Then add isSelected to the newly selected day
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions
            .calendarPages[this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage]
            .calendarDays[dayIndex]
            .isSelected = true;
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.previouslySelectedDay = {
            pageIndex: this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage,
            dayIndex,
        } as MultiAppointmentBookingCalendarSelectedDayType;
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.selectedDay =  this.multiAppointmentBookingMdUtils.slotsCalendarOptions
            .calendarPages[this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage]
            .calendarDays[dayIndex];
        // Update the display month and year
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.displayMonth = dayItem.momentDate.format('MMMM');
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.displayYear = dayItem.momentDate.format('YYYY');
        // And emit changes to parent
        this.selectValue.emit(dayItem);
    }

    onTodayButtonClick() {
        // Go to page 0 and simulate click on today
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage = 0;
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions.calendarPages.forEach((page: MultiAppointmentBookingCalendarPageType) => {
            page.calendarDays.forEach((day: MultiAppointmentBookingCalendarDayType, dayIndex: number) => {
                if (day.momentDate.isSame(moment(), 'day')) {
                    this.onCalendarDaySelected(day, dayIndex);
                    return;
                }
            });
        });
    }

    isRightArrowEnabled(): boolean {
        // Basically allow user to click on the right arrow when all days on the current page have
        // been searched but maxtimewindow hasn't been reached
        const currentPage = this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage;
        const numberOfPages = this.multiAppointmentBookingMdUtils.slotsCalendarOptions.numberOfPages;
        if (currentPage < numberOfPages - 1) {
            // Not on last page
            return true;
        }
        // Only enable right arrow on last page if not already searched on time window maximum
        return this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch !== this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum;
    }

    getCurrentPageOptions(): MultiAppointmentBookingCalendarPageType {
        return this.multiAppointmentBookingMdUtils.slotsCalendarOptions.calendarPages[
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage
        ];
    }

    private shouldContinueSearching(): boolean {
        // If not on last page, don't continue searching
        if (this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage <
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions.numberOfPages - 1) {
            return false;
        }
        // If already searched on time window maximum, don't continue searching
        if (this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch === this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum) {
            return false;
        }
        // Otherwise, continue searching
        return true;
    }

    private getWeekDays(format): string[] {
        return [...Array(7).keys()].map(i => moment().days(i + 1).format(format).toUpperCase());
    }
}
