import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {DisplayChangeLogModalComponent} from './display-change-log-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DisplayChangeLogModalService {

    constructor(
        private modalService: NgbModal
    ) {
    }

    displayModal(id: string, actionType: string, entityName?: string, entityTranslationKey?: string) {
        const modalOptions = this.getModalOptions();
        const modalRef = this.modalService.open(DisplayChangeLogModalComponent, modalOptions);
        modalRef.componentInstance.entityId = id;
        modalRef.componentInstance.entityType = actionType;
        modalRef.componentInstance.entityName = entityName;
        modalRef.componentInstance.entityTranslationKey = entityTranslationKey;
    }

    private getModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: true,
            size: 'xl'
        };
    }
}
