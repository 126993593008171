<div class="container-fluid create-rule-table-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-rule-table-header">
        <h3 class="create-rule-table-title"><span translate="label.ruleTable"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveRuleTableData(ruleSetItem)" *ngIf="ruleType">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <div class="row">
                <div class="col-5">
                    <div class="form-group customSelect">
                        <label translate="label.ruleTable"></label>
                        <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                      [buttonName]="'label.searchForRuleTable'"
                                      [dependentFilters]="ruleSetDependentFilters"
                                      [initialFilterValues]="[initialRuleSet]"
                                      [providerInstance]="ruleTableProvider"
                                      [isMultipleSelectedItem]="false"
                                      [useSelectedValueAsLabel]="true"
                                      [hideSelectedItems]="true">
                        </sbase-filter>
                    </div>
                </div>
                <div class="col-2" *ngIf="isCreateRuleSetButtonActive">
                    <button type="button" class="d-flex btn theme-alt-btn border-0 mt-4"
                            [activityDisplay]="'RuleCreate'"
                            (click)="onCreateRuleTable()">
                        <span translate='label.createRuleTable'></span>
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="displayRuleSetInfo">
                <div class="col-5">
                    <div class="form-group">
                        <label translate="label.name"></label>
                        <input type="text" name="name" class="input-element"
                               [(ngModel)]="ruleSetItem.name"
                               [ngClass]="{'input-element-has-error': !ruleSetItem.name}"
                               placeholder="{{'label.name'|translate}}"
                               autocomplete="off">
                        <div *ngIf="!ruleSetItem.name" class="help-block">
                            <span translate="label.error.required"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="displayRuleSetInfo">
                <div class="col-5">
                    <div class="form-group">
                        <label translate="label.description"></label>
                        <span>
                            <input type="text" name="description" class="form-control rounded-0 pl-0"
                                   [(ngModel)]="ruleSetItem.description"
                                   placeholder="{{'label.description'|translate}}"
                                   autocomplete="off">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn close-btn mr-3" type="button" (click)="goToParentPage()" translate="button.cancel">
            </button>
            <button *ngIf="displayRuleSetInfo"
                    [disabled]="!ruleSetItem.name"
                    class="btn theme-btn mr-3"
                    type="submit"
                    translate="button.save">
            </button>
            <button *ngIf="displayRuleSetInfo && ruleSetItem?.id"
                    class="btn alert-btn mr-3" type="button"
                    (click)="displayConfirmDeleteItemModal(ruleSetItem.id)"
                    [activityDisplay]="'RuleDelete'" translate="label.delete">
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
