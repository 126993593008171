import {Injectable} from '@angular/core';
import {
    AvailabilityExclusionIntraAvailabilityManagementContextStateFiltersType
} from '../../features/availability/availability.types';
import {TableFiltersType} from '../../data-model/general.type';
import {FilterWrapperNameEnum, GenericFilterResultType} from 'sked-base';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityManagementContextService {
    filtersState: AvailabilityExclusionIntraAvailabilityManagementContextStateFiltersType;

    constructor() {
    }

    saveIntraAvailabilityManagementState(tableFilters: TableFiltersType): void {
        this.filtersState = {
            filterWrapperInitialValues: {
                [FilterWrapperNameEnum.resource]: {
                    name: FilterWrapperNameEnum.resource,
                    value: tableFilters.filter[FilterWrapperNameEnum.resource],
                },
                [FilterWrapperNameEnum.location]: {
                    name: FilterWrapperNameEnum.location,
                    value: tableFilters.filter[FilterWrapperNameEnum.location],
                }
            },
        };
    }

    getUpdatedFilterWrapperValues(filterNames: FilterWrapperNameEnum[], tableFilters: TableFiltersType): GenericFilterResultType[] {
        const values: GenericFilterResultType[] = [];
        filterNames.forEach((filterName: FilterWrapperNameEnum) => {
            if (tableFilters.filter[filterName]) {
                values.push({
                    name: filterName,
                    value: tableFilters.filter[filterName],
                });
            }
        });
        return values;
    }
}
