import {Injectable} from '@angular/core';
import {
    ExternalKeysValueType,
    ODataFilterQueryType,
    ODataOrderByQueryType,
    TableFiltersType
} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {ODataQueryObjectType, ServiceDependentFiltersType, ServiceSearchType, SubServiceType} from 'sked-base';
import {
    EnabledOnPatientAccessOptionsType, FavoriteOptionsType,
    SubServiceModalFilterOptionsType,
    ValidSubServiceObjectType
} from '../../data-model/sub-service.types';

@Injectable({
    providedIn: 'root'
})
export class SubServiceMDUtils {
    tableFilters: TableFiltersType;
    modalFilters: SubServiceModalFilterOptionsType;
    previousModalFilters: SubServiceModalFilterOptionsType;
    initialServiceValues: ServiceSearchType[] = [];

    constructor() {
    }

    getServiceMainDependentFilters(): { service: ServiceDependentFiltersType } {
        return {
            service: {
                searchPhrase: '',
                includeAvailabilities: false,
                coveragePlanId: null,
                includeChannel: false,
                onlyAssignedToLocationsOfUser: false,
                patientId: null,
                locationId: null,
                regionId: null,
                resourceId: null,
                areaId: null,
                exclusionList: [],
                count: true
            }
        };
    }

    getQueryFilterForSubServiceMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            select: ['Id', 'ShortId', 'Name', 'Code', 'RowVersion', 'DefaultDuration', 'EnabledOnPatientAccess', 'Favorite'],
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: {
                SubServiceExternalKeys: {select: ['Id', 'Key', 'Origin', 'EntityId']},
                Services: {select: ['Id', 'Name']}
            }
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item)) {
                if (filter[item]?.id) {
                    filterQuery[lodash.upperFirst(item) + 's'] = {any: {Id: {eq: {type: 'guid', value: filter[item].id}}}};
                } else if (typeof filter[item] === 'boolean') {
                    filterQuery[item] = {eq: filter[item]};
                } else if (filter[item]) {
                    filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getInitialSubService(): SubServiceType {
        const subService: SubServiceType = {} as SubServiceType;
        subService.name = '';
        subService.code = '';
        subService.defaultDuration = 0;
        subService.subServiceExternalKeys = [];
        subService.enabledOnPatientAccess = false;
        subService.favorite = false;
        return subService;
    }

    getEmptyExternalKeyItem(): ExternalKeysValueType {
        return {
            id: '',
            origin: '',
            key: '',
            entityId: ''
        };
    }

    getValidatedFields(subServiceItem: SubServiceType): ValidSubServiceObjectType {
        const validTemplate: ValidSubServiceObjectType = {} as ValidSubServiceObjectType;
        validTemplate.isNameValid = !!(subServiceItem && subServiceItem.name);
        validTemplate.isDefaultDurationValid = !!(subServiceItem && subServiceItem.defaultDuration !== undefined &&
            subServiceItem.defaultDuration !== null && subServiceItem.defaultDuration >= 0);
        validTemplate.isExternalKeysValid = this.isExternalKeysTableValid(subServiceItem.subServiceExternalKeys);

        return validTemplate;
    }

    isExternalKeysTableValid(externalKeysList): boolean {
        const externalKeyItem = lodash.find(externalKeysList, (item) => {
            if (item.key === '' || item.origin === '') {
                return item;
            }
        });

        return !externalKeyItem;
    }

    areAllTemplateInputsValid(validTemplate): boolean {
        return validTemplate.isNameValid && validTemplate.isDefaultDurationValid && validTemplate.isExternalKeysValid;
    }

    getInitialModalFilters(): SubServiceModalFilterOptionsType {
        return {
            enabledOnPatientAccessOptions: {
                ngModel: null as EnabledOnPatientAccessOptionsType
            },
            favoriteOptions: {
                ngModel: null as FavoriteOptionsType
            }
        } as SubServiceModalFilterOptionsType;
    }

    isAnyFilterActive(modalFilters: SubServiceModalFilterOptionsType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different than the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }
}


