<div class="container-fluid book-appointments-md-wrapper">
    <div class="book-success d-flex mb-2 p-2" *ngIf="currentPage===2">
        <i class="fas fa-check-circle ml-3 mr-2 mt-1"></i>
        <span translate="toastr.success.appointmentsBooked"></span>
    </div>
    <div class="book-error d-flex mb-2 p-2" *ngIf="!!multiAppointmentBookingMdUtils.bookAppointmentsError">
        <i class="fas fa-exclamation-circle ml-3 mr-2 mt-1"></i>
        <span>{{"label.errorWithAppointmentIndex" | translate: '{index:' + (multiAppointmentBookingMdUtils.bookAppointmentsError.index + 1) + '}'}}&nbsp;</span>
        <span>{{multiAppointmentBookingMdUtils.bookAppointmentsError.message}}</span>
    </div>

    <sbase-patient-details
            [messagesService]="messagesService"
            [patientInfo]="multiAppointmentBookingMdUtils.appointmentInformationOptions.patient"
            [showRemoveButton]="false"></sbase-patient-details>

    <app-mab-appointments-information
            class="mab-appointments-information-wrapper"
            *ngIf="currentPage===1 && displayAppointmentInformationComponent"
            [options]="multiAppointmentBookingMdUtils.appointmentInformationOptions"
            (action)="onBookAction($event)"></app-mab-appointments-information>

    <app-mab-appointments-information
            class="mab-appointments-information-wrapper"
            *ngIf="currentPage===2 && displayAppointmentInformationComponent"
            [ngClass]="{'appointment-info-container': currentPage===2}"
            [options]="multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions"
            (action)="onDoneAction($event)"></app-mab-appointments-information>
</div>
