<div class="container-fluid create-service-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-service-header">
        <h3 class="create-service-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.service2"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-service-section create-entity-md-section">
        <div class="col-12 p-0">
            <mat-horizontal-stepper #stepper
                                    (selectionChange)="onStepChange($event)"
                                    [linear]="screenTemplateLayout.action === EDIT ? isLinearStepperOrNot(validTemplate) : true"
                                    labelPosition="bottom" class="h-100">
                <!-- General-->
                <mat-step *ngIf="screenTemplateLayout.action !== VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row pb-5rem">
                            <div class="col-4">
                                <!-- service Name-->
                                <div class="form-group form-name-field">
                                    <label translate="label.name"></label>
                                    <input [(ngModel)]="serviceItem.name"
                                           (ngModelChange)="onServiceValueChanged(serviceItem)"
                                           [disabled]="screenTemplateLayout.action===VIEW"
                                           [ngClass]="{'input-element-has-error': !validTemplate.isNameValid}"
                                           class="service-name input-element"
                                           placeholder="{{'label.name'|translate}}"
                                           type="text">
                                    <div *ngIf="!validTemplate.isNameValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                                <!-- area-->
                                <div class="form-group"
                                     [ngClass]="{'has-error': !serviceItem.area}">
                                    <label translate="label.area2"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            autofocus
                                            dropdownPosition="bottom"
                                            [(ngModel)]="serviceItem.area"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngClass]="{'input-element-has-error': !validTemplate.isAreaValid}"
                                            [disabled]="screenTemplateLayout.action===VIEW"
                                            (change)="onServiceValueChanged(serviceItem)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let area of areas"
                                                   [value]="area">
                                            {{area.name}}
                                        </ng-option>
                                    </ng-select>

                                    <div *ngIf="!validTemplate.isAreaValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!-- speciality-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.speciality2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedSpecialityFilter($event)"
                                                  [buttonName]=specialityButtonName
                                                  [dependentFilters]="mainDependentFilters.speciality"
                                                  [hideSelectedItems]="true"
                                                  [initialFilterValues]="[serviceItem.speciality]"
                                                  [isMultipleSelectedItem]="false"
                                                  [ngClass]="{'input-element-has-error':!validTemplate.isSpecialityValid || serviceItem.speciality === undefined}"
                                                  [providerInstance]="specialityProvider"
                                                  [useSelectedValueAsLabel]="true">
                                    </sbase-filter>
                                    <div *ngIf="!validTemplate.isSpecialityValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!-- duration -->
                                <div class="form-group">
                                    <label translate="label.duration"></label>
                                    <input [(ngModel)]='serviceItem.defaultDuration'
                                           (ngModelChange)="onServiceValueChanged(serviceItem)"
                                           [disabled]="screenTemplateLayout.action===VIEW"
                                           [ngClass]="{'input-element-has-error': !serviceMdUtils.getDurationValidation(serviceItem.defaultDuration)}"
                                           [placeholder]="'label.duration' | translate"
                                           class="input-element"
                                           min="0"
                                           required type="number">
                                    <div *ngIf="!validTemplate.isDurationValid && serviceItem.defaultDuration === null"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!validTemplate.isDurationValid && serviceItem.defaultDuration < 0"
                                         class="help-block">
                                        <span translate="label.error.mustBePositiveNumber"></span>
                                    </div>
                                </div>

                                <!-- tags -->
                                <div class="form-group customSelect">
                                    <label translate="label.tags"></label>
                                    <div>
                                        <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                                      [buttonName]='"label.newTag"'
                                                      [dependentFilters]="mainDependentFilters?.tags"
                                                      [initialFilterValues]="serviceItem.tags"
                                                      [isMultipleSelectedItem]="true"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="tagProvider">
                                        </sbase-filter>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.bluePrintName"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="serviceItem.multiResourceBluePrint"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [disabled]="screenTemplateLayout.action===VIEW"
                                            (change)="onChangeBluePrint($event)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let bluePrint of multiResourceBluePrints"
                                                   [value]="bluePrint">
                                            {{bluePrint.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <label translate="label.channelExclusions"></label>
                                    <div class="d-flex justify-content-between pr-5">
                                        <div>
                                            <label *ngFor="let channel of channels"
                                                   class="checkbox d-flex align-items-center">
                                                <input [(ngModel)]="channel.selected"
                                                       [checked]="channel.selected"
                                                       [disabled]="screenTemplateLayout.action===VIEW"
                                                       [ngModelOptions]="{standalone: true}"
                                                       (ngModelChange)="onChannelRestrictionChange(channel)"
                                                       name="channelExclusions"
                                                       type="checkbox"/>
                                                <span>{{('label.channels.' + channel.enumValue) | translate}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label translate="label.checkinDisabled"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input [(ngModel)]="serviceItem.checkinDisabled"
                                                   [disabled]="screenTemplateLayout.action===VIEW"
                                                   checked
                                                   type="checkbox"
                                            >
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>

                                <!--online consultation -->
                                <div class="form-group">
                                    <label translate="label.onlineConsultation"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input [(ngModel)]="serviceItem.onlineConsultation"
                                                   [disabled]="screenTemplateLayout.action===VIEW"
                                                   checked
                                                   type="checkbox"
                                            >
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Wait List for Patient Access -->
                                <div class="form-group" *ngIf="isPatientAccessWaitListFeatureActive">
                                    <label translate="label.enableWaitListForPatientAccess"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input [(ngModel)]="serviceItem.enableWaitList"
                                                   [disabled]="screenTemplateLayout.action===VIEW"
                                                   checked
                                                   type="checkbox">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.code"></label>
                                    <input [(ngModel)]='serviceItem.code'
                                           [disabled]="screenTemplateLayout.action===VIEW"
                                           class="input-element"
                                           placeholder="{{'label.code'|translate}}" type="text">

                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <!-- Centers -->
                <mat-step *ngIf="screenTemplateLayout.action !== VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.centers"></span>&nbsp;
                    </ng-template>
                    <div class="step-form mt-3">
                        <div class="help-block" *ngIf="!validTemplate.areCentersValid">
                            <span translate="label.error.required"></span>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <app-multi-select-table *ngIf="!!this.serviceMultiSelectTableOptions?.centers"
                                                        [options]="this.serviceMultiSelectTableOptions.centers"
                                                        (itemsChange)="onSelectedCenter($event)"
                                                        class="sbase-dropdown-ml"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <!-- Coverage plans -->
                <mat-step *ngIf="screenTemplateLayout.action !== VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.coveragePlans"></span>&nbsp;
                    </ng-template>
                    <div class="step-form mt-3">
                        <div class="help-block" *ngIf="!validTemplate.areCoveragePlansValid">
                            <span translate="label.error.required"></span>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <app-multi-select-table *ngIf="!!this.serviceMultiSelectTableOptions?.coveragePlans"
                                                        [options]="this.serviceMultiSelectTableOptions.coveragePlans"
                                                        (itemsChange)="onSelectedCoveragePlan($event)"
                                                        class="sbase-dropdown-ml"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <!-- Sub Services-->
                <mat-step *ngIf="screenTemplateLayout.action !== VIEW && isSubServiceFeatureActive"
                          [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <div class="step-form mt-3">
                        <ng-template matStepLabel><span translate="label.subServices"></span></ng-template>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.minSubServices"></label>
                                    <input [(ngModel)]='serviceItem.minSubServices'
                                           (ngModelChange)="onMinSubServicesValueChanged(serviceItem)"
                                           [disabled]="screenTemplateLayout.action===VIEW"
                                           [ngClass]="{ 'has-error': serviceItem.minSubServices < 0}"
                                           class="form-control pl-0 rounded-0" min="0"
                                           type="number">
                                    <div *ngIf="serviceItem.minSubServices < 0" class="help-block">
                                        <span translate="label.error.mustBePositiveNumber"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.maxSubServices"></label>
                                    <input [(ngModel)]='serviceItem.maxSubServices'
                                           (ngModelChange)="onMaxSubServicesValueChanged(serviceItem)"
                                           [ngClass]="{ 'has-error': !validTemplate.isMaxSubServicesNumberValid}"
                                           [disabled]="screenTemplateLayout.action===VIEW"
                                           class="form-control pl-0 rounded-0" min="1"
                                           type="number">
                                    <div *ngIf="!validTemplate.isMaxSubServicesNumberValid" class="help-block">
                                        <span *ngIf="messageErrorForMaxSubServices !== ''">{{'label.error.' + messageErrorForMaxSubServices | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <app-multi-select-table *ngIf="!!this.serviceMultiSelectTableOptions?.subServices"
                                                        [options]="this.serviceMultiSelectTableOptions.subServices"
                                                        (itemsChange)="onSelectedSubService($event)"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step *ngIf="screenTemplateLayout.action !== VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.externalKeysLabel"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="external-keys-title">
                            <label *ngIf="screenTemplateLayout.action === EDIT"
                                   translate="label.externalKeysLabel"></label>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button (click)="onAddExternalKeyItem()" *ngIf="screenTemplateLayout.action!==VIEW"
                                        class="btn addTableRow-btn btn-block text-left py-2" type="button">
                                    <i class="fas fa-plus-circle"></i>
                                    <span class="name-button" translate="label.addTableRow"></span>
                                </button>
                                <div class="container-external-keys-table">
                                    <table [ngClass]="{'disabled-input' : screenTemplateLayout.action===VIEW}"
                                           class="table mt-3 table-bordered border-0">
                                        <thead>
                                        <tr>
                                            <th class="border-0 p-0" translate="label.origin"></th>
                                            <th class="border-0 p-0" translate="label.key"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of serviceItem.serviceExternalKeys; let i = index">
                                            <td>
                                                <div class="form-group m-0">
                                                    <input [(ngModel)]="item.origin"
                                                           [disabled]="screenTemplateLayout.action===VIEW"
                                                           [ngModelOptions]="{standalone: true}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           name="{{item.origin}}"
                                                           placeholder="{{'label.origin'|translate}}"
                                                           type="text">

                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group m-0 d-flex">
                                                    <input [(ngModel)]="item.key"
                                                           [disabled]="screenTemplateLayout.action===VIEW"
                                                           [ngModelOptions]="{standalone: true}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           name="{{item.key}}"
                                                           placeholder="{{'label.key'|translate}}"
                                                           type="text">
                                                    <!--                          (ngModelChange)="onInputChanged(serviceItem)"-->
                                                    <button *ngIf="screenTemplateLayout.action!==VIEW"
                                                            class="btn float-right p-0 pl-2"
                                                            type="button"><i
                                                            (click)="onRemoveExternalKeyItem(i)"
                                                            class="fas fa-times"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <!-- Overview-->
                <mat-step [completed]="false" [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.generalInformation"></label>
                                    <table class="table border service-details">
                                        <tbody>
                                        <tr>
                                            <td translate="label.name"></td>
                                            <td class="text-right">{{serviceItem.name}}</td>
                                        </tr>
                                        <tr *ngIf="screenTemplateLayout.action !== CREATE">
                                            <td translate="label.shortId"></td>
                                            <td class="text-right">{{serviceItem.shortId}}</td>
                                        </tr>
                                        <tr *ngIf="screenTemplateLayout.action !== CREATE">
                                            <td translate="label.id"></td>
                                            <td class="text-right">{{serviceItem.id}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.area2"></td>
                                            <td class="text-right">
                                                <span *ngIf="serviceItem.area">{{serviceItem.area.name}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.speciality2"></td>
                                            <td class="text-right">
                                                <span *ngIf="serviceItem.speciality">{{serviceItem.speciality.name}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.duration"></td>
                                            <td class="text-right">{{serviceItem.defaultDuration}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.checkinDisabled"></td>
                                            <td class="text-right">{{ serviceItem.checkinDisabled |yesNoPipe | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.onlineConsultation"></td>
                                            <td class="text-right">{{ serviceItem.onlineConsultation |yesNoPipe | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.channelExclusions"></td>
                                            <td class="text-right">
                                            <span *ngFor="let channel of serviceItem.channelRestrictions; last as islast">
                                                {{('label.channels.' + channel.enumValue) | translate}}<span *ngIf="!islast">, </span>
                                            </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.tags"></td>
                                            <td class="text-right">
                                              <span *ngFor="let tag of serviceItem.tags; last as islast">
                                                    {{tag.name}}<span *ngIf="!islast">, </span>
                                              </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.bluePrintName"></td>
                                            <td class="text-right">
                                                <span *ngIf="serviceItem.multiResourceBluePrint">{{serviceItem.multiResourceBluePrint.name}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.code"></td>
                                            <td class="text-right">
                                                <span>{{serviceItem.code}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group">
                                    <label translate="label.centers"></label>
                                    <table class="table border service-details centers-table">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <span *ngFor="let center of serviceItem.centers; last as islast">
                                                    {{center.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div *ngIf="isSubServiceFeatureActive"
                                     class="form-group">
                                    <label translate="label.subServices"></label>
                                    <table class="table border subService-table service-details">
                                        <tbody>
                                        <tr>
                                            <td class="long-name" translate="label.minSubServices"></td>
                                            <td class="text-right">{{serviceItem.minSubServices}}</td>
                                        </tr>
                                        <tr>
                                            <td class="long-name" translate="label.maxSubServices"></td>
                                            <td class="text-right">{{serviceItem.maxSubServices}}</td>
                                        </tr>
                                        <tr>
                                            <td class="col-name" translate="label.assignedSubServices"></td>
                                            <td class="text-right">{{serviceItem.subServices?.length}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group">
                                    <label translate="label.coveragePlans"></label>
                                    <table class="table border coverage-plans-table service-details">
                                        <tbody>
                                        <tr>
                                            <td class="col-name" translate="label.name"></td>
                                            <td class="text-right">
                                                <span *ngFor="let coveragePlanName of getSortedCoveragePlanNameList(); last as islast">
                                                    {{coveragePlanName}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group">
                                    <label translate="label.externalKeysLabel"></label>
                                    <table class="table table-bordered service-details">
                                        <thead>
                                        <tr>
                                            <td translate="label.origin"></td>
                                            <td translate="label.key"></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of serviceItem.serviceExternalKeys">
                                            <td>
                                                {{item.origin}}
                                            </td>
                                            <td>
                                                {{item.key}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button (click)="goToParentPage()" class="btn mr-3"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button (click)="goBack(stepper)" *ngIf="screenTemplateLayout.action !== VIEW"
                    [disabled]="stepper.selectedIndex === 0"
                    class="btn back-btn mr-3"
                    translate="button.back" type="button">
            </button>
            <button (click)="goForward(stepper)" *ngIf="(stepper.selectedIndex < totalStepsCount-1)"
                    [disabled]="!areStepsValid(validTemplate, stepper.selectedIndex)"
                    class="btn theme-btn"
                    type="button">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveServiceData(serviceItem)"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==VIEW"
                    class="btn theme-btn"
                    type="button">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===VIEW"
                    [activityDisplay]="'ServiceUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
