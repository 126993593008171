<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav mr-auto navbar-toggle">
            <!-- This is  -->
            <!--<li class="nav-item"><a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"><i class="ti-menu"></i></a>-->
            <!--</li>-->
            <li class="nav-item"
                (click)="toggleSidebar()"
                [ngClass]="{'disabled':isFullScreen}"><a
                    class="nav-link sidebartoggler waves-effect waves-dark"><i
                    class="ti-menu"></i></a></li>
        </ul>

        <div class="navbar-header">
            <a class="navbar-brand" (click)="goToDashboard()">
                <!-- Logo icon -->
                <!--        <img src="../../../assets/images/logo.png" alt="{{'label.dashboard'|translate}}"/>-->
                <img [src]="logoPath" alt="{{'label.dashboard'|translate}}"/>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- FullScreen -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       ngbTooltip="{{'label.fullScreenMode'| translate}}"
                       (click)="toggleFullscreen()">
                        <i class=" fas fa-expand-arrows-alt" *ngIf="!isFullScreen"></i>
                        <i class="fas fa-compress-arrows-alt" *ngIf="isFullScreen"></i>
                    </a>
                </li>
                <!-- ============================================================== -->
                <!-- End FullScreen -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Language -->
                <!-- ============================================================== -->
                <sbase-language (onSelectedLanguageOutput)="onSelectedLang($event)"
                                [initialLanguageCode]='translatedLanguage'
                                *ngIf="!!translatedLanguage"></sbase-language>

                <!-- ============================================================== -->
                <!-- Patient Dashboard -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown"
                    [featureDisplay]="'backoffice-new-patients'"
                    [activityDisplay]="'PatientRead'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       ngbTooltip="{{'label.patients'| translate}}"
                       routerLink="/patientDashboard">
                        <i class="fas fa-id-card-alt"></i>
                    </a>
                </li>

                <!-- ============================================================== -->
                <!-- Export View -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown"
                    [featureDisplay]="'admin-export-view'"
                    [activityDisplay]="'GetJobResult'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       ngbTooltip="{{'label.exportView'| translate}}" [routerLink]="['export']">
                        <i class="fas fa-file-export"></i>
                    </a>
                </li>
                <!-- ============================================================== -->
                <!-- HelpDesk -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       ngbTooltip="{{'label.helpDesk' | translate}}"
                       (click)="openHelpDeskPage()">
                        <i class="fa-solid fa-circle-question"></i>
                    </a>
                </li>
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <sbase-user-info [userInfo]="currentUser"
                                 (onLoggedInOutput)="onLoggedIn()"
                                 (onLoggedOutOutput)="onLoggedOut()"
                                 (onViewedProfileOutput)="onViewedProfile()"></sbase-user-info>
            </ul>
        </div>
    </nav>
</header>
