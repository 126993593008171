<div class="container-fluid create-cross-availability-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-cross-availability-rule-header">
        <h3 class="create-cross-availability-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.crossAvailabilityRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.crossAvailabilityRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.crossAvailabilityRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.crossAvailabilityRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.crossAvailabilityRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule priority-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input type="number" name="priority" class="input-element"
                                           [(ngModel)]="ruleItem.priority"
                                           [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(ruleItem.priority) || !isPriorityValid(ruleItem.priority)}"
                                           min="1"
                                           step="1"
                                           max=200
                                           placeholder="{{'label.priority'|translate}}"
                                           autocomplete="off"/>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem.priority)" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem.priority) && !isPriorityValid(ruleItem.priority)"
                                         class="help-block">
                                            <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                                min: 1,
                                                max: 200
                                            } }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule only warning-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.onlyWarning"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.onlyWarning"
                                                   name="onlyWarning">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.configuration"></span></ng-template>
                    <div class="step-form">
                        <div class="row d-inline-block w-100 ml-1 mb-2" *ngIf="!isInputStepValidAtLeastOneMatch(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.crossAvailabilityRuleInputValidationAtLeastOneMatch"></span>
                        </div>
                        <div class="row d-inline-block w-100 ml-1 mb-2" *ngIf="!isInputStepValidAtLeastOneOutputSegment(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.crossAvailabilityRuleInputValidationAtLeastOneOutputSegment"></span>
                        </div>
                        <div class="row d-inline-block w-100 ml-1 mb-2" *ngIf="!isInputStepValidAtLeastOneOutputCapacity(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.crossAvailabilityRuleInputValidationAtLeastOneOutputCapacity"></span>
                        </div>
                        <hr class="mt-0" *ngIf="!isInputStepValidAtLeastOneMatch(ruleItem) || !isInputStepValidAtLeastOneOutputSegment(ruleItem) || !isInputStepValidAtLeastOneOutputCapacity(ruleItem)">
                        <div class="row">
                            <!-- Channel -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.channel">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedChannelValue($event)"
                                            [buttonName]='"label.channel"'
                                            [dependentFilters]="mainDependentFilters?.channel"
                                            [initialFilterValues]="[initialChannel]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="channelProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <!-- These fields have error styles and messages already prepared, just replace false with the conditions -->
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Channel - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.channel">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.channel"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.channelRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.channelRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'channelRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.channelRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Area -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.area2">
                                    </label>
                                    <sbase-filter
                                                  (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.area, $event)"
                                                  [buttonName]='"label.area2"'
                                                  [dependentFilters]="mainDependentFilters?.area"
                                                  [initialFilterValues]="[ruleItem.area]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="areaProvider"
                                                  [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Area - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.area">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.area2"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.areaRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.areaRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'areaRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.areaRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Center tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.centerTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.centerTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.centerTag"
                                            [initialFilterValues]="[ruleItem.centerTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Center tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.centerTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.centerTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.centerTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.centerTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'centerTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.centerTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Coverage plan tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.coveragePlanTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.coveragePlanTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.coveragePlanTag"
                                            [initialFilterValues]="[ruleItem.coveragePlanTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Coverage plan tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.coveragePlanTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.coveragePlanTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.coveragePlanTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.coveragePlanTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'coveragePlanTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.coveragePlanTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Speciality tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.specialityTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.specialityTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.specialityTag"
                                            [initialFilterValues]="[ruleItem.specialityTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Speciality tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.specialityTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.specialityTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.specialityTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.specialityTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'specialityTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.specialityTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Service tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.serviceTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.serviceTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.serviceTag"
                                            [initialFilterValues]="[ruleItem.serviceTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Service tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.serviceTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.serviceTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.serviceTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.serviceTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'serviceTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.serviceTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Resource tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.resourceTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.resourceTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.resourceTag"
                                            [initialFilterValues]="[ruleItem.resourceTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Resource tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.resourceTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.resourceTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.resourceTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.resourceTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'resourceTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.resourceTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Availability tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.availabilityTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.availabilityTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.availabilityTag"
                                            [initialFilterValues]="[ruleItem.availabilityTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Availability tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.availabilityTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.availabilityTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.availabilityTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.availabilityTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'availabilityTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumListWithoutInput" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.availabilityTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Patient tag -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-1 mr-2 w-100" translate="label.patientTag">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.patientTag, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.patientTag"
                                            [initialFilterValues]="[ruleItem.patientTag]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider"
                                            [ngClass]="{'input-element-has-error': false}">
                                    </sbase-filter>
                                    <div *ngIf="false" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Patient tag - Rule Option -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group" *ngIf="!!ruleItem.patientTag">
                                    <label class="mb-0 mr-2">
                                        <span translate="label.patientTag"></span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span translate="label.ruleOption"></span>
                                    </label>
                                    <ng-select
                                            class="pb-0 custom-ng-select input-element"
                                            [ngClass]="{'input-element-has-error': !ruleItem.patientTagRuleOption}"
                                            loadingText="{{'label.loading' | translate}}"
                                            placeholder="{{'label.choose' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="ruleItem.patientTagRuleOption"
                                            [multiple]="false" [searchable]="false" [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="sanitizeNgSelectValue($event, 'patientTagRuleOption')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleOptionEnumList" [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleOption.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem.patientTagRuleOption" class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Patient tag exception -->
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group customSelect">
                                    <label class="mb-1 mr-2 w-100" translate="label.patientTagException">
                                    </label>
                                    <sbase-filter
                                            (selectedItemsOutput)="onSelectedTagOrAreaValue(CrossAvailabilityRuleFiltersEnum.patientTagException, $event)"
                                            [buttonName]='"label.newTag"'
                                            [dependentFilters]="mainDependentFilters?.patientTagException"
                                            [initialFilterValues]="[ruleItem.patientTagException]"
                                            [isMultipleSelectedItem]="false"
                                            [useSelectedValueAsLabel]="true"
                                            [hideSelectedItems]="true"
                                            [providerInstance]="tagProvider">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Type -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="mb-1 mr-2" translate="label.type"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.type' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [ngClass]="!!ruleItem.type ? 'ng-select-has-value' : 'input-element-has-error'"
                                               [(ngModel)]="ruleItem.type"
                                               (ngModelChange)="sanitizeNgSelectValue($event, 'type')"
                                               [disabled]="!isTypeSelectEnabled">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of crossAvailabilityRuleTypeEnumList"
                                                   [value]="enumValue">
                                            <span>{{'enum.crossAvailabilityRuleType.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.type)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Rule scope -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="mb-1 mr-2" translate="label.ruleScope"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.ruleScope' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [ngClass]="!!ruleItem.ruleScope ? 'ng-select-has-value' : 'input-element-has-error'"
                                               [(ngModel)]="ruleItem.ruleScope"
                                               (ngModelChange)="sanitizeNgSelectValue($event, 'ruleScope')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of ruleScopeEnumList" [value]="enumValue">
                                            <span>{{'enum.ruleScope.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.ruleScope)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Unit -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="mb-1 mr-2" translate="label.unit"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.unit' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [ngClass]="!!ruleItem.unit ? 'ng-select-has-value' : 'input-element-has-error'"
                                               [(ngModel)]="ruleItem.unit"
                                               (ngModelChange)="sanitizeNgSelectValue($event, 'unit')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let enumValue of ruleUnitEnumList"
                                                   [value]="enumValue">
                                            <span>{{'enum.revenueOptimizationRuleUnit.' + enumValue | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.unit)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Value -->
                            <div class="col-4">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.value"></label>
                                    <input type="number" class="input-element"
                                           placeholder="{{'label.value' | translate}}"
                                           autocomplete="off" min="0"
                                           [ngClass]="{'input-element-has-error': !rulesUtils.isNumberValidForUnit(ruleItem.unit, ruleItem.value)}"
                                           [(ngModel)]="ruleItem.value">
                                    <div *ngIf="!rulesUtils.isNumberValidForUnit(ruleItem.unit, ruleItem.value)"
                                         class="help-block">
                                        <span class="has-error">{{rulesUtils.getErrorMessageForUnitNumber(ruleItem.unit, ruleItem.value) | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Deactivate before (days) -->
                            <div class="col-4">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.deactivateBefore"></label>
                                    <input type="number" class="input-element"
                                           placeholder="{{'label.deactivateBefore' | translate}}"
                                           autocomplete="off" min="0"
                                           [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore)
                                        || (!generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore) && !isWholePositiveNumber(ruleItem?.deactivateBefore))}"
                                           [(ngModel)]="ruleItem.deactivateBefore">
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore) && !isWholePositiveNumber(ruleItem?.deactivateBefore)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.invalidNumber"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Message -->
                            <div class="col-8">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.message"></label>
                                    <input type="text" class="input-element"
                                           placeholder="{{'label.message' | translate}}"
                                           autocomplete="off"
                                           [ngClass]="{'input-element-has-error': !ruleItem?.message}"
                                           [(ngModel)]="ruleItem.message">
                                    <div *ngIf="!ruleItem?.message"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleTable"></td>
                                                <td class="text-right">{{ruleItem.crossAvailabilityRuleTable?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.name"></td>
                                                <td class="text-right">{{ruleItem.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.priority"></td>
                                                <td class="text-right">{{ruleItem.priority}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.active"></td>
                                                <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.onlyWarning"></td>
                                                <td class="text-right">{{ruleItem.onlyWarning | yesNoPipe | translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.configuration"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.channel"></td>
                                                <td class="text-right" *ngIf="ruleItem?.channel">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.channelRuleOption"></app-rule-option-tooltip>
                                                    {{'enum.channel.' + ruleItem.channel | translate}}
                                                </td>
                                                <td *ngIf="!ruleItem?.channel"></td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.area2"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.areaRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.area?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.centerTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.centerTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.centerTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.coveragePlanTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.coveragePlanTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.coveragePlanTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.specialityTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.specialityTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.specialityTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.serviceTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.serviceTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.serviceTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.resourceTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.resourceTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.resourceTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.availabilityTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.availabilityTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.availabilityTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.patientTag"></td>
                                                <td class="text-right">
                                                    <app-rule-option-tooltip [ruleOption]="ruleItem?.patientTagRuleOption"></app-rule-option-tooltip>
                                                    {{ruleItem.patientTag?.name}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.type"></td>
                                                <td class="text-right">
                                                    {{'enum.crossAvailabilityRuleType.' + ruleItem.type | translate}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleScope"></td>
                                                <td class="text-right">
                                                    {{'enum.ruleScope.' + ruleItem.ruleScope | translate}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.unit"></td>
                                                <td class="text-right">
                                                    {{'enum.revenueOptimizationRuleUnit.' + ruleItem.unit?.toLowerCase()
                                                    | translate}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.value"></td>
                                                <td class="text-right">{{ruleItem.value}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.deactivateBefore"></td>
                                                <td class="text-right">{{ruleItem.deactivateBefore}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.patientTagException"></td>
                                                <td class="text-right">{{ruleItem.patientTagException?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.message"></td>
                                                <td class="text-right">{{ruleItem.message}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div *ngIf="stepper.selectedIndex === 1">
            <div class="row ml-1 mb-2 info-message" *ngIf="infoMessages?.length > 0 && (!!infoMessages[0]?.visible(ruleItem) || !!infoMessages[1]?.visible(ruleItem) || !!infoMessages[3]?.visible(ruleItem))">
                <ngb-alert type="custom-info" [dismissible]="false">
                    <i class="fas fa-info-circle mr-3"></i>
                    <div class="messages-container">
                        <div *ngIf="!!infoMessages[0]?.visible(ruleItem)">
                            <span [translate]="infoMessages[0].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[0].getPropertiesNames(ruleItem)}}</span>
                        </div>
                        <div *ngIf="!!infoMessages[1]?.visible(ruleItem)">
                            <span [translate]="infoMessages[1].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[1].getPropertiesNames(ruleItem)}}</span>
                        </div>
                        <div *ngIf="!!infoMessages[3]?.visible(ruleItem)">
                            <span [translate]="infoMessages[3].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[3].getPropertiesNames(ruleItem)}}</span>
                        </div>
                    </div>
                </ngb-alert>
            </div>
        </div>
        <div *ngIf="stepper.selectedIndex === 2">
            <div class="row ml-1 mb-2 info-message" *ngIf="infoMessages?.length > 0 && !!infoMessages[2]?.visible(ruleItem)">
                <ngb-alert type="custom-info" [dismissible]="false">
                    <i class="fas fa-info-circle mr-3"></i>
                    <div class="messages-container">
                        <div *ngIf="!!infoMessages[2]?.visible(ruleItem)">
                            <span [translate]="infoMessages[2].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[2].getPropertiesNames(ruleItem)}}</span>
                        </div>
                    </div>
                </ngb-alert>
            </div>
        </div>
        <div *ngIf="stepper.selectedIndex === 3">
            <div class="row ml-1 mb-2 info-message" *ngIf="infoMessages?.length > 0 && (!!infoMessages[0]?.visible(ruleItem) || !!infoMessages[1]?.visible(ruleItem) || !!infoMessages[2]?.visible(ruleItem) || !!infoMessages[3]?.visible(ruleItem))">
                <ngb-alert type="custom-info" [dismissible]="false">
                    <i class="fas fa-info-circle mr-3"></i>
                    <div class="messages-container">
                        <div *ngIf="!!infoMessages[0]?.visible(ruleItem)">
                            <span [translate]="infoMessages[0].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[0].getPropertiesNames(ruleItem)}}</span>
                        </div>
                        <div *ngIf="!!infoMessages[1]?.visible(ruleItem)">
                            <span [translate]="infoMessages[1].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[1].getPropertiesNames(ruleItem)}}</span>
                        </div>
                        <div *ngIf="!!infoMessages[2]?.visible(ruleItem)">
                            <span [translate]="infoMessages[2].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[2].getPropertiesNames(ruleItem)}}</span>
                        </div>
                        <div *ngIf="!!infoMessages[3]?.visible(ruleItem)">
                            <span [translate]="infoMessages[3].label" class="mr-2"></span>
                            <span class="font-weight-bold">{{infoMessages[3].getPropertiesNames(ruleItem)}}</span>
                        </div>
                    </div>
                </ngb-alert>
            </div>
        </div>
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'CrossAvailabilityRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
