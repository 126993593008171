export interface PatientOverviewCoveragePlansType {
    mainCoveragePlan: string;
    secondaryCoveragePlans: string[];
}

export interface PatientOverviewClientAgreementsType {
    onlineAccount: boolean;
    whatsappNotifications: boolean;
}

export enum PatientClientAgreementsEnum {
    OnlineAccount = 'AccountActivated',
    WhatsappNotifications = 'WhatsAppNotifications'
}
