//for a good overview, please add a new constant in alphabetic order
//and group by category
export const constants = {
  // action
  BLOCKING_SIMULATION: 'blockingSimulation',
  APPROVE: 'approve',
  DECLINE: 'decline',
  COPY: 'copy',
  BLOCKINGS: 'blocking',
  HISTORY: 'history',
  SPLIT: 'split',
  READ: 'read',
  ADD: 'add',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  EDIT: 'edit',
  MOVE: 'move',
  VIEW: 'view',
  CLICK: 'click',
  ANONIMIZE_PATIENT: 'anonimizePatient',
  //order
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  // status
  ASSIGNED: 'Assigned',
  BLOCKED: 'Blocked',
  RESERVED: 'Reserved',
  TO_BE_ASSIGNED: 'ToBeAssigned',
  // storage names
  ACCESS_CONFIG_STORAGE_NAME: 'AccessConfig',
  TENANT_CONFIG_STORAGE_NAME: 'TenantConfig',
  SYSTEM_CONFIG_STORAGE_NAME: 'SystemConfig',
  ACTIVE_ACTIVITIES_STORAGE_NAME: 'ActiveActivities',
  USER_INFO_STORAGE_NAME: 'UserInfo',
  APP_VERSION: 'appVersion',
  inputDebounceTime: 400, // milliseconds
  itemsPerPage: 10,
  CAPACITY_PLANNER_RESOURCE: 'capacityPlannerResource',
  CAPACITY_PLANNER_ROOM: 'capacityPlannerRoom',
  DEFAULT_HOME_PAGE: 'dashboard',
  SYSTEM_CONFIG_PAGE: 'systemConfig',
  PASSWORD_PATTERN: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.,$!%*#?&-])[A-Za-z\d@.,$!%*#?&-]{8,40}$/,
  EMAIL_PATTERN: /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  CENTER: 'center',
  SERVICE: 'service',
  RESOURCE: 'resource',
  PATIENT: 'patient',
  APPOINTMENT_TYPE: 'appointmentType',
  SPECIALITY: 'speciality',
  FULL_CALENDAR_LICENSE_KEY: '0561390186-fcs-1693327700',
  SYSTEM_CONFIG_FRONTEND_ENVIRONMENT_CONFIGURATION: 'FrontendEnvironmentConfiguration',
  ORDER_BY_CLAUSE_MAX_PERMITTED: 5,
  CHANGE_LOG_OBJECT_HISTORY_READ: 'ChangeLogObjectHistoryRead',
  DAYS_IN_WEEK: 7,
  DAYS_IN_MONTH: 30,
  DUMMY_API_KEY: '*************'
};

