import { Injectable } from '@angular/core';
import {
    WorkOrderRuleType,
    Expand,
    ODataQueryObjectType,
    LocationDependentFiltersType,
    ServiceDependentFiltersType
} from 'sked-base';
import * as lodash from 'lodash';
import {
    WorkOrderRuleModalFiltersType
} from './work-order-rule.types';
import { GeneralUtils } from '../../../../shared/utils/general.utils';
import { ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType } from '../../../../data-model/general.type';
import { RulesTableHeaderOptions } from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class WorkOrderRuleUtils {

    constructor(
        private generalUtils: GeneralUtils
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'workOrderRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.service2',
            hasSort: true,
            onSortByProperty: 'service',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.center2',
            hasSort: true,
            onSortByProperty: 'center',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.workOrderRequired',
            hasSort: true,
            onSortByProperty: 'workOrderRequired',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.bookingType',
            hasSort: true,
            onSortByProperty: 'bookingType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'workOrderRuleTableId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'serviceId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'centerId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'bookingType': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'workOrderRequired': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item] !== 'false'};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'workOrderRuleTable' || item === 'service' || item === 'center'
                ) {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            WorkOrderRuleTable: {select: ['Id', 'Name']},
            Center: {select: ['Id', 'Name']},
            Service: {select: ['Id', 'Name', 'SpecialityId']}
        };
    }

    getInitialRule(): WorkOrderRuleType {
        return {
            name: undefined,
            active: false,
            service: undefined,
            serviceId: undefined,
            center: undefined,
            centerId: undefined,
            bookingType: undefined,
            workOrderRequired: undefined,
            workOrderRuleTable: undefined,
            workOrderRuleTableId: undefined
        } as WorkOrderRuleType;
    }

    getInitialModalFilters(): WorkOrderRuleModalFiltersType {
        return {
            serviceModalFilterOptions: {
                ngModel: undefined
            },
            centerModalFilterOptions: {
                ngModel: undefined
            },
            bookingTypeModalFilterOptions: {
                ngModel: undefined
            },
            workOrderRequiredModalFilterOptions: {
                ngModel: undefined
            },
            areFiltersActive: false
        };
    }

    isAnyFilterActive(modalFilters: WorkOrderRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            onlyAssignedToLocationsOfUser: false,
            includeAvailabilities: false,
            includeChannel: true,
            searchPhrase: '',
            locationId: null,
            regionId: null,
            resourceId: null,
            areaId: null,
            hasMultiResourceBluePrint: null,
            exclusionList: [],
            count: true,
        } as ServiceDependentFiltersType;
    }
}
