export const formControlNames = {
    FIRST_NAME2: 'FirstName2',
    MARITAL_STATUS: 'MaritalStatus',
    CREATED_BY_CLIENT: 'CreatedByClient',
    MODIFIED_BY: 'ModifiedBy',
    MAIN_PHONE_NUMBER: 'MainPhoneNumber',
    REMARKS: 'Remarks',
    REGION: 'Region',
    ALTERNATE_PHONE_NUMBER: 'AlternatePhoneNumber',
    MODIFIED_ON: 'ModifiedOn',
    ID: 'Id',
    REVIEW: 'Review',
    CREATED_ON: 'CreatedOn',
    PATIENT_EXTERNAL_KEYS: 'PatientExternalKeys',
    CREATED_BY_MARKETING_CAMPAIGN: 'CreatedByMarketingCampaign',
    DOCUMENT_NUMBER: 'DocumentNumber',
    FULL_NAME: 'FullName',
    PASSWORD: 'Password',
    GENDER: 'Gender',
    WEIGHT_IN_GRAMS: 'WeightInGrams',
    MODIFIED_BY_CLIENT: 'ModifiedByClient',
    CLIENT_AGREEMENTS: 'ClientAgreements',
    BIRTHDATE: 'BirthDate',
    LAST_NAME2: 'LastName2',
    NO_DOCUMENT: 'NoDocument',
    EMAIL: 'Email',
    CANCELLED: 'Cancelled',
    AGREEMENT_NOT_ACCEPTED: 'AgreementNotAccepted',
    COVERAGE_PLANS: 'CoveragePlans',
    LAST_NAME1: 'LastName1',
    ANONIMIZED: 'Anonimized',
    CREATED_BY: 'CreatedBy',
    APPOINTMENTS: 'Appointments',
    ROW_VERSION: 'RowVersion',
    DOCUMENT_NUMBER_FORMATTED: 'DocumentNumberFormatted',
    DOCUMENT_COUNTRY: 'DocumentCountry',
    ACTIVITY_PLANS: 'ActivityPlans',
    COUNTRY: 'Country',
    COUNTRY_ID: 'CountryId',
    FULL_NAME_NORMALIZED: 'FullNameNormalized',
    HEIGHT_IN_CENTIMETERS: 'HeightInCentiMeters',
    REGION_ID: 'RegionId',
    FIRST_NAME1: 'FirstName1',
    DOCUMENT_TYPE: 'DocumentType',
    ADDRESS_LOCALITY: 'Address.Locality',
    ADDRESS_STREET: 'Address.Street',
    ADDRESS_STREET_NUMBER: 'Address.StreetNumber',
    ADDRESS_FLOOR: 'Address.Floor',
    ADDRESS_APARTMENT_NUMBER: 'Address.ApartmentNumber',
    ADDRESS_ZIP_CODE: 'Address.ZipCode',
    ONLINE_ACCOUNT: 'OnlineAccount',
    WHATSAPP_NOTIFICATIONS: 'WhatsappNotifications',
    MAIN_PHONE_NUMBER_COUNTRY_CODE: 'MainPhoneNumberCountryCode',
    ALTERNATE_PHONE_NUMBER_COUNTRY_CODE: 'AlternatePhoneNumberCountryCode',
    MAIN_COVERAGE_PLAN: 'MainCoveragePlan',
    SECONDARY_COVERAGE_PLANS: 'SecondaryCoveragePlans',
    TAGS: 'Tags',
    EXTERNAL_KEYS: 'ExternalKeys',
    ORIGIN: 'Origin',
    KEY: 'Key',
    EXCEPTIONS: 'Exceptions',
    SERVICE: 'Service',
    SPECIALTY: 'Specialty',
    RESOURCE: 'Resource',
    VALID_TO: 'ValidTo',
    TIME_DEPENDENT_TAG: 'TimeDependentTag',
    TIME_DEPENDENT_TAGS: 'TimeDependentTags',
    NUMBER_OF_DAYS: 'NumberOfDays',
    DATE_TIME_FROM: 'DateTimeFrom',
    DATE_TIME_TO: 'DateTimeTo',
};

