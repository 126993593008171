<div class="container-fluid create-availabilitySplitItem-split-wrapper create-entity-md-wrapper">
    <div class="create-header">
        <h3 class="page-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase"
                translate="label.availabilitySplit"></span>
        </h3>
    </div>
    <div class="create-entity-md-section">
        <!-- Split data-->
        <div class="row split-date-container"
             [ngClass]="{'has-error':!availabilitySplitValidationItem?.isValid}">
            <div class="col-12">
                <div class="row">
                    <!--  Split From-->
                    <div class="col-5">
                        <label translate="label.splitFrom"></label>
                        <div class="date-and-time-picker-inputs-container">
                            <div class="date-picker-input-container">
                                <input class="date-picker-input"
                                       [ngClass]="{'input-element-has-error': !availabilitySplitValidationItem?.isValid}"
                                       name="dpfrom1"
                                       readonly
                                       [minDate]="dateOptions.minDate"
                                       [(ngModel)]="availabilitySplitItem.dateFrom"
                                       (dateSelect)="onDateTimeChanged($event,availabilitySplitItem.timeFrom,splitDateTypeEnum.SplitFrom)"
                                       ngbDatepicker #splitFrom="ngbDatepicker"
                                       (click)="splitFrom.toggle()">
                                <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                        [ngClass]="{'has-error': !availabilitySplitValidationItem?.isValid}"
                                        (click)="splitFrom.toggle()"
                                        type="button"></button>
                            </div>
                            <div class="time-picker-input-container" *ngIf="availabilitySplitItem.timeFrom">
                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                      [ngClass]="{'input-element-has-error': !availabilitySplitValidationItem?.isValid}"
                                                      [format]="24"
                                                      [cancelBtnTmpl]="closeBtn"
                                                      [confirmBtnTmpl]="confirmBtn"
                                                      [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilitySplitItem.timeFrom)"
                                                      (timeChanged)="onDateTimeChanged(availabilitySplitItem.dateFrom,dateTimeUtils.hourMinuteStringToNgbTimeStruct($event),splitDateTypeEnum.SplitFrom)">
                                </ngx-timepicker-field>
                            </div>
                        </div>
                    </div>
                    <!--  Split To-->
                    <div class="col-5">
                        <label translate="label.splitTo"
                               class="form-control-label"></label>
                        <div class="date-and-time-picker-inputs-container">
                            <div class="date-picker-input-container">
                                <input class="date-picker-input"
                                       [ngClass]="{'has-error': !availabilitySplitValidationItem?.isValid}"
                                       name="dpto1"
                                       readonly
                                       [minDate]="dateOptions.minDate"
                                       [(ngModel)]="availabilitySplitItem.dateTo"
                                       (dateSelect)="onDateTimeChanged($event,availabilitySplitItem.timeTo,splitDateTypeEnum.SplitTo)"
                                       ngbDatepicker #splitTo="ngbDatepicker"
                                       (click)="splitTo.toggle()">
                                <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                        [ngClass]="{'has-error': !availabilitySplitValidationItem?.isValid}"
                                        (click)="splitTo.toggle()"
                                        type="button">
                                </button>
                            </div>
                            <div class="time-picker-input-container" *ngIf="availabilitySplitItem.timeTo">
                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                      [ngClass]="{'input-element-has-error': !availabilitySplitValidationItem?.isValid}"
                                                      [format]="24"
                                                      [cancelBtnTmpl]="closeBtn"
                                                      [confirmBtnTmpl]="confirmBtn"
                                                      [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilitySplitItem.timeTo)"
                                                      (timeChanged)="onDateTimeChanged(availabilitySplitItem.dateTo,dateTimeUtils.hourMinuteStringToNgbTimeStruct($event),splitDateTypeEnum.SplitTo)">
                                </ngx-timepicker-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 my-1"
                 *ngIf="!availabilitySplitValidationItem?.isValid">
                <span class="error-message">{{availabilitySplitValidationItem?.errorMessage | translate:validationParams}}</span>
            </div>
        </div>


        <!--Availability INFORMATION-->
        <div class="row availability-container mt-3">
            <div class="col-12">

                <!--GENERAL INFORMATION-->
                <label translate="label.availability2" class="title"></label>

                <app-view-availability
                        [availability]="availabilityItem"></app-view-availability>

            </div>
        </div>
        <div class="mt-2">
            <button class="btn mr-3" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" (click)="saveAvailabilitySplit(availabilitySplitItem)"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
        </div>
    </div>
</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close"></button>
    </div>
</ng-template>
