<div class="modal-filters">
    <div class="modal-header">
        <h4 *ngIf="options.title">
            <strong [translate]="options.title"></strong>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <label translate="label.filterByDate" class="text-high-contrast"></label>
            </div>
            <div class="row">
                <div class="d-flex">
                    <div class="mr-1 d-inline-flex">
                        <div class="form-group">
                            <sbase-date-range class="input-element"
                                              [ngClass]="{'input-element-has-error': !isDateRangeValidForIntervalKind(modalFilters)}"
                                              [options]="modalFilters.dateRangeOptions"
                                              (onDateRangeChanged)="onDateRangeChanged($event)">
                            </sbase-date-range>
                            <div *ngIf="!isDateRangeValidForIntervalKind(modalFilters)"
                                 class="help-block">
                        <span [ngClass]="{'has-error': !isDateRangeValidForIntervalKind(modalFilters)}"
                              translate="label.error.requiredDatesForRequestedIntervalKind">
                        </span>
                            </div>
                        </div>
                        <div class="form-group ml-1">
                            <!-- Requested Interval Kind -->
                            <div class="mr-1 my-1">
                                <ng-select class="form-control pl-0 interval-kind p-0 custom-ng-select text-high-contrast"
                                           dropdownPosition="bottom"
                                           [multiple]="false"
                                           [searchable]="false"
                                           [clearable]="false"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="modalFilters.dateRangeOptions.requestedIntervalKind"
                                           (ngModelChange)="onRequestIntervalKindChange($event)">
                                    <ng-option [value]="IntervalTypeEnum.OVERLAP"><span translate="label.overlap"></span></ng-option>
                                    <ng-option [value]="IntervalTypeEnum.RANGE"><span translate="label.range"></span></ng-option>
                                    <ng-option [value]="IntervalTypeEnum.STRICT"><span translate="label.strict"></span></ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 p-0 d-flex online-consultation-options" *ngIf="modalFilters.onlineConsultationOptions !== undefined && modalFilters.onlineConsultationOptions !== null">
                    <div class="mr-1">
                        <label translate="label.onlineConsultationsOnly" class="text-high-contrast"></label>
                        <div class="d-block">
                            <input type="radio"
                                   class="btn-check"
                                   id="filterOnlineConsultationOnlyOptionNull"
                                   autocomplete="off"
                                   [value]="null"
                                   [(ngModel)]="modalFilters.onlineConsultationOptions.onlineConsultation">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterOnlineConsultationOnlyOptionNull"
                                   translate="label.all"
                                   [ngClass]="(modalFilters.onlineConsultationOptions.onlineConsultation === null) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   id="filterOnlineConsultationOnlyOptionYes"
                                   autocomplete="off"
                                   [value]="true"
                                   [(ngModel)]="modalFilters.onlineConsultationOptions.onlineConsultation">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterOnlineConsultationOnlyOptionYes"
                                   translate="label.yes"
                                   [ngClass]="(modalFilters.onlineConsultationOptions.onlineConsultation === true) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   id="filterOnlineConsultationOnlyOptionNo"
                                   autocomplete="off"
                                   [value]="false"
                                   [(ngModel)]="modalFilters.onlineConsultationOptions.onlineConsultation">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterOnlineConsultationOnlyOptionNo"
                                   translate="label.no"
                                   [ngClass]="(modalFilters.onlineConsultationOptions.onlineConsultation === false) ? 'selected-option': ''">
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-6 p-0 ignore-holidays-options" [featureDisplay]="'availability-holiday-ignore'"
                     *ngIf="modalFilters.ignoreHolidaysOptions !== undefined && modalFilters.ignoreHolidaysOptions !== null">
                    <div class="mr-1">
                        <label translate="label.ignoreHolidays" class="text-high-contrast"></label>
                        <div class="d-block">
                            <input type="radio"
                                   class="btn-check"
                                   name="ignoreHolidaysOptions"
                                   id="filterIgnoreHolidaysOptionNull"
                                   autocomplete="off"
                                   [value]="null"
                                   [(ngModel)]="modalFilters.ignoreHolidaysOptions.ignoreHolidays">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterIgnoreHolidaysOptionNull"
                                   translate="label.all"
                                   [ngClass]="(modalFilters.ignoreHolidaysOptions.ignoreHolidays === null) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   name="ignoreHolidaysOptions"
                                   id="filterIgnoreHolidaysOptionYes"
                                   autocomplete="off"
                                   [value]="true"
                                   [(ngModel)]="modalFilters.ignoreHolidaysOptions.ignoreHolidays">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterIgnoreHolidaysOptionYes"
                                   translate="label.yes"
                                   [ngClass]="(modalFilters.ignoreHolidaysOptions.ignoreHolidays === true) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   name="ignoreHolidaysOptions"
                                   id="filterIgnoreHolidaysOptionNo"
                                   autocomplete="off"
                                   [value]="false"
                                   [(ngModel)]="modalFilters.ignoreHolidaysOptions.ignoreHolidays">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterIgnoreHolidaysOptionNo"
                                   translate="label.no"
                                   [ngClass]="(modalFilters.ignoreHolidaysOptions.ignoreHolidays === false) ? 'selected-option': ''">
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" [featureDisplay]="'admin-room-reservation'"
                 *ngIf="modalFilters.roomReservationNeededOptions !== undefined && modalFilters.roomReservationNeededOptions !== null">
                <div class="d-flex room-reservation-needed-options">
                    <div class="mr-1">
                        <label translate="label.roomReservationNeeded" class="text-high-contrast"></label>
                        <div class="d-block">
                            <input type="radio"
                                   class="btn-check"
                                   name="roomReservationOptions"
                                   id="filterRoomReservationNeededOptionNull"
                                   autocomplete="off"
                                   [value]="null"
                                   [(ngModel)]="modalFilters.roomReservationNeededOptions.roomReservationNeeded">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterRoomReservationNeededOptionNull"
                                   translate="label.all"
                                   [ngClass]="(modalFilters.roomReservationNeededOptions.roomReservationNeeded === null) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   name="roomReservationOptions"
                                   id="filterRoomReservationNeededOptionYes"
                                   autocomplete="off"
                                   [value]="true"
                                   [(ngModel)]="modalFilters.roomReservationNeededOptions.roomReservationNeeded">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterRoomReservationNeededOptionYes"
                                   translate="label.yes"
                                   [ngClass]="(modalFilters.roomReservationNeededOptions.roomReservationNeeded === true) ? 'selected-option': ''">
                            </label>

                            <input type="radio"
                                   class="btn-check"
                                   name="roomReservationOptions"
                                   id="filterRoomReservationNeededOptionNo"
                                   autocomplete="off"
                                   [value]="false"
                                   [(ngModel)]="modalFilters.roomReservationNeededOptions.roomReservationNeeded">
                            <label class="btn radio-button font-weight-high-contrast"
                                   for="filterRoomReservationNeededOptionNo"
                                   translate="label.no"
                                   [ngClass]="(modalFilters.roomReservationNeededOptions.roomReservationNeeded === false) ? 'selected-option': ''">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn theme-btn min-width-auto"
                (click)="onCloseModal()" translate="label.ok">
        </button>
    </div>
</div>

