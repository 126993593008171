<div class="container-fluid create-patient-calculated-tag-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-patient-calculated-tag-rule-header">
        <h3 class="create-patient-calculated-tag-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.patientCalculatedTagRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.patientCalculatedTagRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.patientCalculatedTagRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.patientCalculatedTagRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.patientCalculatedTagRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           placeholder="{{'label.name'|translate}}"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule priority-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input type="number" name="priority" class="input-element"
                                           [(ngModel)]="ruleItem.priority"
                                           [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(ruleItem.priority) || !isPriorityValid(ruleItem.priority)}"
                                           min="1"
                                           step="1"
                                           max=200
                                           placeholder="{{'label.priority'|translate}}"
                                           autocomplete="off"/>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem.priority)" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem.priority) && !isPriorityValid(ruleItem.priority)"
                                         class="help-block">
                                            <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                                min: 1,
                                                max: 200
                                            } }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-6">
                                <!-- Input Tags-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.inputTags"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedInputTags($event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.inputTags"
                                                  [initialFilterValues]="ruleItem.inputTags"
                                                  [isMultipleSelectedItem]="true"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="tagProvider"
                                                  [ngClass]="{'input-element-has-error': ruleItem?.inputTags?.length <= 0}">
                                    </sbase-filter>
                                    <div *ngIf="ruleItem?.inputTags?.length <= 0"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': ruleItem?.inputTags?.length <= 0}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <!-- Match Type-->
                                        <div class="form-group"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                            <label translate="label.matchType"></label>
                                            <ng-select
                                                    class="custom-ng-select input-element"
                                                    loadingText="{{'label.loading' | translate}}"
                                                    placeholder="{{'label.choose' | translate}}"
                                                    dropdownPosition="bottom"
                                                    [(ngModel)]="ruleItem.matchType"
                                                    [multiple]="false"
                                                    [searchable]="false"
                                                    [clearable]="false"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'input-element-has-error': !ruleItem.matchType}"
                                                    [disabled]="screenTemplateLayout.action===constants.VIEW"
                                                    (ngModelChange)="sanitizeNgSelectValue('matchType')">
                                                <ng-option [value]="undefined">
                                                    <span translate="label.choose"></span>
                                                </ng-option>
                                                <ng-option [value]="item" *ngFor="let item of matchTypeEnumList">
                                                    <span>{{'enum.matchType.' + item | translate}}</span>
                                                </ng-option>
                                            </ng-select>
                                            <div *ngIf="!ruleItem.matchType" class="help-block">
                                                <span translate="label.error.required"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-6">
                                <!-- Output Tag-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.outputTag"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedOutputTag($event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.outputTag"
                                                  [initialFilterValues]="[ruleItem.outputTag]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.outputTag?.id}"
                                                  [providerInstance]="tagProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.outputTag?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.outputTag?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.patientCalculatedTagRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.priority"></td>
                                            <td class="text-right">{{ruleItem.priority}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.inputTags"></td>
                                            <td class="text-right">
                                                <span *ngFor="let tag of ruleItem?.inputTags; last as islast">
                                                    <span>{{tag?.name}}</span>
                                                    <span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.matchType"></td>
                                            <td class="text-right"
                                                *ngIf="ruleItem.matchType">{{'enum.matchType.' + ruleItem.matchType | translate}}</td>
                                            <td class="text-right"
                                                *ngIf="!ruleItem.matchType"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.outputTag"></td>
                                            <td class="text-right">{{ruleItem.outputTag?.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'PatientCalculatedTagRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
