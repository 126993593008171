import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {LongDateFormatKey} from 'moment';

@Pipe({
    name: 'fullDateFormatPipe'
})

export class FullDateFormatPipe implements PipeTransform {
    transform(date: string, ignoreTimezone: boolean = true): string {
        if (date) {
            if (!ignoreTimezone) {
                //@ts-ignore
                return moment(date).format(moment.localeData().longDateFormat('fullDate'));
            }
            //@ts-ignore
            return moment(date.substring(0, 19)).format(moment.localeData().longDateFormat('fullDate'));
        } else {
            return undefined;
        }
    }
}
