import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateRangeOptionsType} from 'sked-base';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import {DateAndTimeRangeType} from '../../features/availability/availability.types';
import {FormValidationType} from '../../data-model/general.type';
import {ConfigDataService} from '../services/config-data.service';
import {systemConfigKeys} from '../../features/system-config/system-config.constant';
import {Moment} from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateTimeUtils {
    constructor(private configDataService: ConfigDataService) {
    }

    convertNgbDateToDate(ngDate: NgbDate): Date {
        return (new Date(ngDate.year, ngDate.month - 1, ngDate.day));
    }

    convertNgbDateToDateAddOneDay(ngDate: NgbDate): Date {
        return (new Date(ngDate.year, ngDate.month - 1, ngDate.day + 1));
    }

    isInBrowserTimeZone(timezoneOffset: number): boolean {
        if (timezoneOffset === moment.parseZone(moment()).utcOffset()) {
            return true;
        }
        return false;
    }
    isYearOfDateInPast(date: NgbDateStruct): boolean {
      const today = new Date();
      return date.year < today.getFullYear();
    }

    isYearInPast(year: number): boolean {
      const today = new Date();
      return year < today.getFullYear();
    }

    getStringHourFromMinutes(minutes: number): string {
        return moment.parseZone().startOf('day').add(minutes, 'minutes').format('HH:mm');
    }

    getMinutesFromStringHours(time: string): number {
        return moment(time, 'HH:mm').hours() * 60 + moment(time, 'HH:mm').minutes();
    }

    convertDateInNgbDateStruct(date: Date): NgbDateStruct {
        return {
            year: moment.parseZone(date).year(),
            month: moment.parseZone(date).month() + 1,
            day: moment.parseZone(date).date(),
        };
    }

    convertStringInNgbDateStruct(date: string): NgbDateStruct {
        return {
            year: moment.parseZone(date).year(),
            month: moment.parseZone(date).month() + 1,
            day: moment.parseZone(date).date(),
        };
    }

    getNgbDateFromMoment(momentObject: Moment): NgbDateStruct {
        return {
            year: momentObject.year(),
            month: momentObject.month() + 1,
            day: momentObject.date(),
        } as NgbDateStruct;
    }

    // @TODO: move these to sked as well
    getStringFromNgbDate(date: NgbDateStruct): string {
        return `${date.year}-${date.month}-${date.day}`;
    }

    getMomentFromNgbDate(date: NgbDateStruct): Moment {
        return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    getMomentParseZoneFromNgbDate(date: NgbDateStruct): Moment {
        return moment.parseZone(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    getMomentUTCFromNgbDate(date: NgbDateStruct): Moment {
        return moment.utc(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    addDaysToNgbDate(date: NgbDateStruct, days: number): NgbDateStruct {
        const momentDatePlusDays = this.getMomentFromNgbDate(date).add(days, 'days');
        return this.getNgbDateFromMoment(momentDatePlusDays);
    }

    getRawDateFilter(momentDate: Moment): {type: 'raw', value: string} {
        // First format the string in the correct format, then encode the + sign, and then most importantly
        // send this value as type raw instead of type Date to avoid odata-query's automatic parsing to Date.toISOString
        return {
            type: 'raw',
            value: momentDate.format('YYYY-MM-DDTHH:mm:ssZ').replace('+', '%2B')
        };
    }
    // /////

    getStringDateAsNgbDate(date: string): NgbDate {
        return {
            year: moment(date.substring(0, 19)).year(),
            month: moment(date.substring(0, 19)).month() + 1,
            day: moment(date.substring(0, 19)).day()
        } as NgbDate;
    }

    getNgbDateWithoutOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month - 1, day: date.day};
    }

    getNgbDateWithOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month + 1, day: date.day};
    }

    getStartOfDayIgnoringOffset(date: string): string {
        const pattern = 'YYYY-MM-DD HH:mm:ss';
        return moment(date.substring(0, 19)).startOf('day').format(pattern);
    }

    isTodayOrFuture(date: Date): boolean {
        return !moment(moment(date).startOf('day')).isBefore(moment().startOf('day'));
    }

    getDateWithOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month + 1, day: date.day};
    }

    getISODateFormat(date: any) {
        const updatedDate = moment({year: date.year, month: date.month - 1, day: date.day});
        return moment(updatedDate).format();
    }

    validateDiffDates(date1: NgbDateStruct, date2: NgbDateStruct) {
        return !(date1 && date2 && moment(date1).isAfter(moment(date2), 'minutes'));
    }

    getTodayNgbDateStruct(): NgbDateStruct {
        const today = new Date();
        return this.convertDateInNgbDateStruct(today);
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        return {
            fromDate: null,
            toDate: null,
            minDate: null,
            maxDate: null,
            maximumRange: null,
            disabled: false
        };
    }

    addTimeToDate(date: string, time: string): string {
        let dateWithTime: string;

        const timeInMinutes = this.getMinutesFromStringHours(time);
        dateWithTime = moment(date).add(timeInMinutes, 'minutes').format();

        return dateWithTime;
    }

    isDateRangeValidForIntervalKind(requestedIntervalKind: IntervalTypeEnum, validFrom: string, validTo: string): boolean {
        return !(requestedIntervalKind === IntervalTypeEnum.RANGE && (!validFrom || !validTo));
    }

    isDateRangeTodayOrFuture(startDate: Date, endDate: Date): boolean {
        return this.isTodayOrFuture(startDate) && this.isTodayOrFuture(endDate);
    }

    ngbTimeStructToHourMinuteString(time: NgbTimeStruct): string {
        if (time === undefined) {
            return undefined;
        }
        return `${time.hour}:${time.minute}`;
    }

    hourMinuteStringToNgbTimeStruct(time: string): NgbTimeStruct {
        if (time.split(':')?.length < 1) {
            return undefined;
        }
        return {
            hour: parseInt(time.split(':')[0], 10),
            minute: parseInt(time.split(':')[1], 10),
            second: 0
        } as NgbTimeStruct;
    }

    getValidateDateAndTimeRange(validFrom: string, validTo: string, endOfDay: boolean, timeZoneId: string): FormValidationType {
        if (lodash.isEmpty(validFrom) || lodash.isEmpty(validTo)) {
            return {isValid: false, errorMessage: 'label.error.required'} as FormValidationType;
        }
        const actualValidTo = endOfDay ?
            this.addOrSubtractDaysFromTimeString(validTo, timeZoneId, 1) :
            validTo;
        if (!this.isDateRangeTodayOrFuture(new Date(validFrom), new Date(actualValidTo))) {
            return {isValid: false, errorMessage: 'toastr.error.dateNotInPast'} as FormValidationType;
        }
        if (moment(validFrom, 'YYYY-MM-DDTHH:mm:ss').isSameOrAfter(moment(actualValidTo, 'YYYY-MM-DDTHH:mm:ss'))) {
            return {isValid: false, errorMessage: 'label.error.invalidDiffDate2'} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }

    // If days > 0 it adds days, if days < 0 it subtracts -days
    addOrSubtractDaysFromTimeString(timeString: string, timeZoneId: string, days: number): string {
        try {
            if (days >= 0) {
                return moment.tz(
                    moment.parseZone(timeString).add(days, 'days')
                        .startOf('day').parseZone().format('YYYY-MM-DDTHH:mm:ss'),
                    timeZoneId
                ).format();
            }
            return moment.tz(
                moment.parseZone(timeString).subtract(0-days, 'days')
                    .startOf('day').parseZone().format('YYYY-MM-DDTHH:mm:ss'),
                timeZoneId
            ).format();
        } catch {
            return undefined;
        }
    }

    getInitialDateAndTimeRange(): DateAndTimeRangeType {
        const zero = {hour: 0, minute: 0, second: 0} as NgbTimeStruct;
        const today = {
            year: moment().get('year'),
            month: moment().get('month') + 1,
            day: moment().get('date')
        } as NgbDateStruct;
        return {
            dateFrom: today,
            dateTo: today,
            timeFrom: zero,
            timeTo: zero
        } as DateAndTimeRangeType;
    }

    getMomentStringFromDateAndTime(date: NgbDateStruct, time: NgbTimeStruct, timeZoneId?: string): string {
        const momentDate = moment({
            year: date.year,
            month: date.month - 1,
            day: date.day,
            hour: !!time.hour ? time.hour : 0,
            minute: !!time.minute ? time.minute : 0,
            second: !!time.second ? time.second : 0
        });

        if (!!timeZoneId) {
            return moment.tz(momentDate.format('YYYY-MM-DDTHH:mm:ss'), timeZoneId).format();
        }
        return momentDate.format();
    }

    getDateAndTimeRangeFromString(validFrom: string, validTo: string, timeZoneId: string): DateAndTimeRangeType {
        const validFromMoment = moment.tz(moment(validFrom).parseZone().format('YYYY-MM-DD HH:mm'), timeZoneId);
        const validToMoment = moment.tz(moment(validTo).parseZone().format('YYYY-MM-DD HH:mm'), timeZoneId);
        return {
            dateFrom: {
                year: validFromMoment.get('year'),
                month: validFromMoment.get('month') + 1,
                day: validFromMoment.get('date')
            } as NgbDateStruct,
            dateTo: {
                year: validToMoment.get('year'),
                month: validToMoment.get('month') + 1,
                day: validToMoment.get('date')
            } as NgbDateStruct,
            timeFrom: {
                hour: validFromMoment.get('hour'),
                minute: validFromMoment.get('minute'),
                second: validFromMoment.get('second')
            } as NgbTimeStruct,
            timeTo: {
                hour: validToMoment.get('hour'),
                minute: validToMoment.get('minute'),
                second: validToMoment.get('second')
            } as NgbTimeStruct,
        } as DateAndTimeRangeType;
    }

    getMaximumTimeWindowFromSystemConfig(): number {
        const timeWindowMaximum = lodash.find(this.configDataService?.systemConfig?.value, {name: systemConfigKeys.TIME_WINDOW_MAXIMUM});
        return isNaN(Number(timeWindowMaximum.value)) ? 366 : Number(timeWindowMaximum.value);
    }

    isExpiredDate(date: string) {
        return moment(date).isBefore(moment().toISOString());
    }
}

