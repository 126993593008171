import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { constants } from 'src/app/shared/constants/constants';
import {
    HolidayCalendarType,
    HolidayCalendarProvider,
    CenterProvider,
    CenterType,
    LocationDependentFiltersType, HolidayType, HolidaysDependentFiltersType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HolidayCalendarMdUtils} from '../holiday-calendar-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {HolidayCalendarMultiSelectTableOptionsType} from '../holiday-calendar-md.types';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-holiday-calendar',
    templateUrl: './create-holiday-calendar.component.html',
    styleUrls: ['./create-holiday-calendar.component.scss']
})
export class CreateHolidayCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('holidayTemplate', {static: true}) holidayTemplate: TemplateRef<any>;
  constants = constants;
  initialHolidayCalendar: HolidayCalendarType;
  holidayCalendarItem: HolidayCalendarType = {} as HolidayCalendarType;
  screenTemplateLayout: ScreenTemplateLayoutType;
  dependentFilters: {
    centers: LocationDependentFiltersType,
    days: HolidaysDependentFiltersType
  } = {
    centers: this.holidayCalendarMdUtils.getCentersDependentFilters(),
    days: this.holidayCalendarMdUtils.getEmptyHolidayDependentFilters()
  };
  holidayCalendarMultiSelectTableOptions: HolidayCalendarMultiSelectTableOptionsType;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private holidayCalendarMdUtils: HolidayCalendarMdUtils,
        private holidayCalendarProvider: HolidayCalendarProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        public dateTimeUtils: DateTimeUtils,
        public centerProvider: CenterProvider
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    onSelectedCenter(selectedCenters: CenterType[]): void {
        this.holidayCalendarItem.centers = selectedCenters;
    }

    onSelectedDay(selectedDays: HolidayType[]): void {
        this.holidayCalendarItem.days = selectedDays;
    }

    onYearChange() {
        // remove all holidays that have the same year as the one that just changed
        lodash.remove(this.holidayCalendarItem.days, (day) => day.year === this.dependentFilters.days.year);
        this.dependentFilters.days.year = this.holidayCalendarItem.year;
    }

    saveHolidayCalendarData(holidayCalendar: HolidayCalendarType) {
        const isTemplateValid = this.validateFields(holidayCalendar);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createHolidayCalendar(holidayCalendar);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialHolidayCalendar, holidayCalendar)) {
                    this.messagesService.success('toastr.success.holidayCalendarEdit', true);
                    this.goToParentPage();
                } else {
                    this.editHolidayCalendar(this.initialHolidayCalendar, holidayCalendar);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/holidayCalendars']);
    }

    goToEdit() {
        history.replaceState({
            holidayCalendar: this.holidayCalendarItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new HolidayCalendar
    private createHolidayCalendar(holidayCalendar: HolidayCalendarType) {
        this.ngxLoader.start();
        this.holidayCalendarProvider.addEntry(holidayCalendar)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newHolidayCalendarAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the HolidayCalendar
    private editHolidayCalendar(oldHolidayCalendar: HolidayCalendarType, newHolidayCalendar: HolidayCalendarType) {
        this.ngxLoader.start();
        this.holidayCalendarProvider.updateEntry(oldHolidayCalendar, newHolidayCalendar)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.holidayCalendarEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(holidayCalendarItem: HolidayCalendarType): boolean {
        return !!(holidayCalendarItem && holidayCalendarItem.description && holidayCalendarItem.centers.length > 0 && holidayCalendarItem.days.length > 0);
    }

  private setupInitialState() {
    if (history.state && history.state.holidayCalendar) {
      this.initialHolidayCalendar = history.state.holidayCalendar;
      this.holidayCalendarItem = lodash.cloneDeep(history.state.holidayCalendar);
      if (history.state.action === constants.VIEW) {
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
      } else if (history.state.action === constants.EDIT) {
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
        this.dependentFilters.days.year = this.holidayCalendarItem.year;
      }
    } else {
      this.holidayCalendarItem = this.holidayCalendarMdUtils.getInitialHolidayCalendar();
      this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
      this.dependentFilters.days.year = this.holidayCalendarItem.year;
    }
    this.holidayCalendarMultiSelectTableOptions = this.holidayCalendarMdUtils.getHolidayCalendarMultiSelectTableOptions(
        this.initialHolidayCalendar, this.screenTemplateLayout.action, this.dependentFilters.days.year, this.holidayTemplate
    );
  }
}
