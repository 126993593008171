import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    Expand,
    MultiAppointmentTemplateItemTypes,
    MultiAppointmentTemplateType,
    ODataQueryObjectType,
    SubServiceDependentFiltersType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';

@Injectable({
    providedIn: 'root'
})
export class MultiAppointmentTemplateUtils {
    tableFilters: TableFiltersType;

    constructor(private generalUtils: GeneralUtils) {
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'BookServicesInOrder', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            MultiAppointmentTemplateItems: {
                select: ['Id', 'Name', 'TimeBefore', 'TimePost', 'Type', 'ServiceId', 'Position'],
                orderBy: 'Position asc',
                expand: {
                    Service: {select: ['Id', 'Name', 'SpecialityId', 'HasSubServices']},
                    SubServices: {select: ['Id', 'Name']}
                }
            }
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getMultiAppointmentTemplateItem(): MultiAppointmentTemplateItemTypes {
        return {
            service: null,
            serviceId: null,
            timeBefore: null,
            timePost: null,
            subServices: []
        } as MultiAppointmentTemplateItemTypes;
    }

    getInitialMultiAppointmentTemplate(): MultiAppointmentTemplateType {
        return {
            name: '',
            multiAppointmentTemplateItems: [],
            bookServicesInOrder: false
        } as MultiAppointmentTemplateType;
    }

    getServiceDependentFilters() {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            includeChannel: false,
            resourceId: null,
            locationId: null,
            coveragePlanId: null,
            areaId: null,
            patientId: null,
            regionId: null,
            onlyAssignedToLocationsOfUser: false,
            exclusionList: [],
            count: true
        };
    }

    getSubServiceDependentFilters(): SubServiceDependentFiltersType {
        return {
            searchPhrase: '',
            serviceId: null,
            specialityId: null,
            includeAvailabilities: false,
            resourceId: null,
            locationId: null,
            areaId: null,
            exclusionList: [],
            count: true
        };
    }

    updatePosition(list: MultiAppointmentTemplateItemTypes[]) {
        if (list?.length) {
            for (let index = 0; index < list.length; index++) {
                list[index].position = index + 1;
            }
        }
    }

    getQueryFilterForSpeciality() {
        return {
            select: ['Id', 'Name'],
        };
    }
}
