import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    SlotFilterRuleProvider,
    SlotFilterRuleType,
    EventActionProvider,
    IncludeExcludeEnum,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    TagProvider,
    SearchFilterUtils,
    CoveragePlanDependentFiltersType,
    GenderEnum,
    TagDependentFiltersType,
    TagType,
    TagDependentFiltersScopeEnum,
    CoveragePlanProvider,
    CoveragePlanType,
    SlotFilterRuleOperationAgeEnum,
    PatientTypeEnum,
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {SlotFilterRuleTagsType} from '../slot-filter-rule.types';
import {SlotFilterRuleUtils} from '../slot-filter-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-slot-filter-rule',
    templateUrl: './create-slot-filter-rule.component.html',
    styleUrls: ['./create-slot-filter-rule.component.scss']
})
export class CreateSlotFilterRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: SlotFilterRuleType = {} as SlotFilterRuleType;
    initialRule: SlotFilterRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.SlotFilterRule);
    IncludeExcludeEnum = IncludeExcludeEnum;
    slotFilterRuleTags: SlotFilterRuleTagsType;
    mainDependentFilters: {
        coveragePlan: CoveragePlanDependentFiltersType
        availabilityTag: TagDependentFiltersType,
        patientTag: TagDependentFiltersType,
    };
    genderEnumList = Object.keys(GenderEnum);
    ageOperationEnumList = Object.values(SlotFilterRuleOperationAgeEnum);
    SlotFilterRuleOperationAgeEnum = SlotFilterRuleOperationAgeEnum;
    patientTypeEnumList = Object.keys(PatientTypeEnum);

    constructor(
        public slotFilterRuleUtils: SlotFilterRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public eventActionProvider: EventActionProvider,
        public tagProvider: TagProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private slotFilterRuleProvider: SlotFilterRuleProvider,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as {invalid?: boolean, pending?: boolean} as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: SlotFilterRuleType): boolean {
        return !!(rule.slotFilterRuleTableId &&
            !lodash.isEmpty(rule.slotFilterRuleTable) && rule.name);
    }

    isInputStepValid(rule: SlotFilterRuleType): boolean {
        return !!((rule.operationGender && rule.gender) || (!rule.operationGender && !rule.gender))
            && !!((rule.operationAge && rule.age
                && (rule.operationAge === SlotFilterRuleOperationAgeEnum.in ? this.isAgeRangeFormatValid(rule.age) : this.isWholeNumber(+rule.age)))
                || (!rule.operationAge && !rule.age))
            && !!((rule.operationCoveragePlan && rule.coveragePlanId) || (!rule.operationCoveragePlan && !rule.coveragePlanId))
            && !!((rule.operationTag && rule.tagId) || (!rule.operationTag && !rule.tagId))
            && !!((rule.includePatientTag && rule.patientTagId) || (!rule.includePatientTag && !rule.patientTagId))
            && !!(rule.gender || rule.age || rule.coveragePlanId || rule.tagId || rule.patientTagId);
    }

    isOutputStepValid(rule: SlotFilterRuleType): boolean {
        return !!(rule.message);
    }

    areAllStepsValid(rule: SlotFilterRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    isAgeValid(age: number): boolean {
        return age >= 0 && age <= 999;
    }

    isWholeNumber(number: number): boolean {
        return !this.generalUtils.isNullOrUndefined(number) && number % 1 === 0;
    }

    isAgeRangeFormatValid(range: string): boolean {
        const RANGE_REGEX = /^(\[|\()([0-9]{1}|[1-9]{1}[0-9]+)(\,|\.\.|\;)([0-9]{1}|[1-9]{1}[0-9]+)(\]|\))$/;
        if (RANGE_REGEX.test(range)) {
            const RANGE_SEPARATOR = range.includes('..') ? '..' : range.includes(';') ? ';' : ',';
            const FIRST_CHARACTER = range.includes('[') ? '[' : '(';
            const SECOND_CHARACTER = range.includes(']') ? ']' : ')';
            const FIRST_NUMBER = +range.split(RANGE_SEPARATOR)[0].split(FIRST_CHARACTER)[1];
            const SECOND_NUMBER = +range.split(RANGE_SEPARATOR)[1].split(SECOND_CHARACTER)[0];
            return FIRST_NUMBER < SECOND_NUMBER && SECOND_NUMBER <= 999;
        }
        return false;
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.slotFilterRuleTable = ruleTable[0];
            this.ruleItem.slotFilterRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.slotFilterRuleTable = undefined;
            this.ruleItem.slotFilterRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedGender($event) {
        this.sanitizeNgSelectValue($event, 'gender');
        if (this.ruleItem.gender) {
            this.ruleItem.operationGender = this.ruleItem.operationGender ?? IncludeExcludeEnum.include;
        } else {
            this.ruleItem.operationGender = undefined;
        }
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.ruleItem[property] = undefined;
        }
    }

    onSelectedCoveragePlan(selectedCoveragePlanList: CoveragePlanType[]): void {
        if (selectedCoveragePlanList?.length) {
            this.ruleItem.coveragePlan = selectedCoveragePlanList[0];
            this.ruleItem.operationCoveragePlan = this.ruleItem.operationCoveragePlan ?? IncludeExcludeEnum.include;
        } else {
            this.ruleItem.coveragePlan = undefined;
            this.ruleItem.operationCoveragePlan = undefined;
        }
        this.ruleItem.coveragePlanId = this.ruleItem.coveragePlan?.id;
    }

    onSelectedTag(entityName: string, tagList: TagType[]) {
        const PROPERTY_NAME = (entityName === 'patientTag' ? 'include' : 'operation') + lodash.upperFirst(entityName);
        if (tagList?.length) {
            this.ruleItem[entityName] = tagList[0];
            this.ruleItem[PROPERTY_NAME] = this.ruleItem[PROPERTY_NAME] ?? IncludeExcludeEnum.include;
        } else {
            this.ruleItem[entityName] = undefined;
            this.ruleItem[PROPERTY_NAME] = undefined;
        }
        this.ruleItem[entityName + 'Id'] = this.ruleItem[entityName]?.id;
    }

    onChangeIncludeFlag(entityName: string, includeFlag: boolean) {
        const PROPERTY_NAME = (entityName === 'patientTag' ? 'include' : 'operation') + lodash.upperFirst(entityName);
        this.ruleItem[PROPERTY_NAME] =
            includeFlag ? this.IncludeExcludeEnum.include : this.IncludeExcludeEnum.exclude;
    }
    //
    // endregion Input methods

    // region Overview methods
    //
    saveRule(rule: SlotFilterRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.slotFilterRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: SlotFilterRuleType) {
        this.ngxLoader.start();
        this.slotFilterRuleProvider.addEntry(rule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newSlotFilterRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: SlotFilterRuleType, newRule: SlotFilterRuleType) {
        this.ngxLoader.start();
        this.slotFilterRuleProvider.updateEntry(oldRule, newRule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.slotFilterRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        this.slotFilterRuleTags = this.slotFilterRuleUtils.getInitialSlotFilterRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.addSelectedTagsToTagInputs();
                this.setIncludeExcludeValues();
            }
        } else {
            this.ruleItem = this.slotFilterRuleUtils.getInitialRule();
            this.ruleItem.operationAge = undefined;
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private addSelectedTagsToTagInputs() {
        if (!lodash.isEmpty(this.ruleItem.tag)) {
            this.slotFilterRuleTags.selectedAvailabilityTags.push(this.ruleItem.tag as unknown as TagType);
            this.slotFilterRuleTags.includeAvailabilityTagFlag = this.ruleItem.operationTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.patientTag)) {
            this.slotFilterRuleTags.selectedPatientTags.push(this.ruleItem.patientTag as unknown as TagType);
            this.slotFilterRuleTags.includePatientTagFlag = this.ruleItem.includePatientTag === this.IncludeExcludeEnum.include;
        }
    }

    private setIncludeExcludeValues() {
        if (!lodash.isEmpty(this.ruleItem.gender)) {
            // @ts-ignore
            this.slotFilterRuleTags.includeGenderFlag = this.ruleItem.operationGender === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.coveragePlanId)) {
            // @ts-ignore
            this.slotFilterRuleTags.includeCoveragePlanFlag = this.ruleItem.operationCoveragePlan === this.IncludeExcludeEnum.include;
        }
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            coveragePlan: this.searchFilterUtils.getCoveragePlanDependentFilters(),
            availabilityTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
            patientTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true)
        };
    }
}
