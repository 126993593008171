<div class="container-fluid create-coverage-company-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-coverage-company-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase"
                translate="label.coverageCompany2"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <!--Name-->
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input [(ngModel)]="coverageCompanyItem.name"
                                   (ngModelChange)="onInputChanged(coverageCompanyItem)"
                                   [ngClass]="{'input-element-has-error': !coverageCompanyItem.name}"
                                   class="input-element"
                                   name="name"
                                   placeholder="{{'label.name'|translate}}"
                                   type="text">
                            <div *ngIf="!coverageCompanyItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Order Patient Access-->
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.patientPortalPlace"></label>
                            <input [(ngModel)]="coverageCompanyItem.patientPortalPlace"
                                   (ngModelChange)="onInputChanged(coverageCompanyItem)"
                                   [ngClass]="{'input-element-has-error': !coverageCompanyItem.patientPortalPlace}"
                                   class="input-element"
                                   min="1"
                                   name="patientPortalPlace"
                                   placeholder="{{'label.patientPortalPlace'|translate}}"
                                   type="number">
                            <div *ngIf="!coverageCompanyItem.patientPortalPlace" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Code-->
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.code"></label>
                            <input [(ngModel)]="coverageCompanyItem.code" class="input-element" name="code"
                                   placeholder="{{'label.code'|translate}}"
                                   type="text">
                        </div>
                    </div>
                </div>
                <!--IsPrivate-->
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.isPrivate"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="coverageCompanyItem.isPrivate"
                                           checked
                                           name="isPrivate"
                                           type="checkbox">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{coverageCompanyItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.patientPortalPlace"></td>
                                    <td class="text-right">{{coverageCompanyItem.patientPortalPlace}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.code"></td>
                                    <td class="text-right">{{coverageCompanyItem.code}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.system"></td>
                                    <td class="text-right">
                                        <span *ngIf="coverageCompanyItem.system" translate="label.yes"></span>
                                        <span *ngIf="!coverageCompanyItem.system" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.isPrivate"></td>
                                    <td class="text-right">
                                        <span *ngIf="coverageCompanyItem.isPrivate" translate="label.yes"></span>
                                        <span *ngIf="!coverageCompanyItem.isPrivate" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button (click)="goToParentPage()" class="btn mr-3"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button (click)="saveCoverageCompanyData(coverageCompanyItem)" [disabled]="!isTemplateValid"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                class="btn theme-btn"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'CoverageCompanyUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
