import {Component, Input} from '@angular/core';
import {DisplayChangeLogModalService} from '../display-change-log-modal/display-change-log-modal.service';
import {constants} from '../../constants/constants';

@Component({
    selector: 'app-display-change-log-icon',
    templateUrl: './display-change-log-icon.component.html',
    styleUrls: ['./display-change-log-icon.component.scss']
})
export class DisplayChangeLogIconComponent {
    @Input() EntityId: string;
    @Input() EntityType: string;
    @Input() EntityName?: string;
    @Input() EntityTranslationKey?: string;
    constants = constants;

    constructor(
        private changeLogService: DisplayChangeLogModalService
    ) {
    }

    displayChangeLog() {
        this.changeLogService.displayModal(this.EntityId, this.EntityType, this.EntityName, this.EntityTranslationKey);
    }
}
