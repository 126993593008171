<div class="container-fluid create-appointment-event-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-appointment-event-rule-header">
        <h3 class="create-appointment-event-rule-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase" translate="label.appointmentEventRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-5">
                                <!--rule set-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.appointmentEventRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.appointmentEventRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.appointmentEventRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.appointmentEventRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input type="number" name="priority" class="input-element"
                                           [(ngModel)]="ruleItem.priority"
                                           [ngClass]="{'input-element-has-error': !ruleItem.priority || !isPriorityValid(ruleItem.priority)}"
                                           min="1"
                                           step="1"
                                           max=200
                                           placeholder="{{'label.priority'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.priority" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="ruleItem.priority && !isPriorityValid(ruleItem.priority)"
                                         class="help-block">
                                          <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                              min: 1,
                                              max: 200
                                          } }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2" *ngIf="!isInputStepValid(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2" translate="label.appointmentEventRuleMinimumConditionForInput"></span>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                            <label translate="label.entityType"></label>
                                            <ng-select
                                                    class="custom-ng-select input-element"
                                                    loadingText="{{'label.loading' | translate}}"
                                                    autofocus
                                                    dropdownPosition="bottom"
                                                    [(ngModel)]="ruleItem.entityType"
                                                    [multiple]="false"
                                                    [searchable]="false"
                                                    [clearable]="false"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'input-element-has-error': !ruleItem.entityType}"
                                                    [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW">
                                                <ng-option [value]="'Appointment'">
                                                    <span>{{'enum.entityType.Appointment' | translate}}</span>
                                                </ng-option>
                                            </ng-select>

                                            <div *ngIf="!ruleItem.entityType" class="help-block">
                                                <span translate="label.error.required"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                            <label translate="label.eventType"></label>
                                            <ng-select
                                                    class="custom-ng-select"
                                                    loadingText="{{'label.loading' | translate}}"
                                                    autofocus
                                                    dropdownPosition="bottom"
                                                    [(ngModel)]="ruleItem.eventType"
                                                    [multiple]="false"
                                                    [searchable]="false"
                                                    [clearable]="false"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW"
                                                    (change)="onEventTypeChange($event)">
                                                <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                                <ng-option *ngFor="let eventType of eventTypeEnumList"
                                                           [value]="eventType">
                                                    {{'enum.eventType.' + eventType | translate}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                            <label translate="label.status"></label>
                                            <ng-select
                                                    class="custom-ng-select"
                                                    loadingText="{{'label.loading' | translate}}"
                                                    autofocus
                                                    dropdownPosition="bottom"
                                                    [(ngModel)]="ruleItem.appointmentStatus"
                                                    [multiple]="false"
                                                    [searchable]="false"
                                                    [clearable]="false"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW || ruleItem.eventType === EventTypeEnum.appointmentReschedule"
                                                    (change)="onAppointmentStatusChange($event)">
                                                <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                                <ng-option *ngFor="let status of appointmentStatusEnumList"
                                                           [value]="status">
                                                    {{'enum.appointmentStatus.' + status | translate}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 ml-5">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label translate="label.centerTag"></label>
                                            <div>
                                                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.CENTER, $event)"
                                                              [buttonName]='"label.newTag"'
                                                              [dependentFilters]="mainDependentFilters?.centerTags"
                                                              [initialFilterValues]="appointmentEventRuleTags.selectedCenterTags"
                                                              [isMultipleSelectedItem]="false"
                                                              [useSelectedValueAsLabel]="true"
                                                              [hideSelectedItems]="true"
                                                              [providerInstance]="tagProvider">
                                                </sbase-filter>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.centerTagId">
                                            <div class="switch">
                                                <label>
                                                    <span translate="label.exclude"></span>
                                                    <input type="checkbox"
                                                           [(ngModel)]="appointmentEventRuleTags.includeCenterTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.CENTER, appointmentEventRuleTags.includeCenterTagFlag)"
                                                           name="includeCenterTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <span translate="label.include"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label translate="label.serviceTag"></label>
                                            <div>
                                                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.SERVICE, $event)"
                                                              [buttonName]='"label.newTag"'
                                                              [dependentFilters]="mainDependentFilters?.serviceTags"
                                                              [initialFilterValues]="appointmentEventRuleTags.selectedServiceTags"
                                                              [isMultipleSelectedItem]="false"
                                                              [useSelectedValueAsLabel]="true"
                                                              [hideSelectedItems]="true"
                                                              [providerInstance]="tagProvider">
                                                </sbase-filter>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.serviceTagId">
                                            <div class="switch">
                                                <label>
                                                    <span translate="label.exclude"></span>
                                                    <input type="checkbox"
                                                           [(ngModel)]="appointmentEventRuleTags.includeServiceTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.SERVICE, appointmentEventRuleTags.includeServiceTagFlag)"
                                                           name="includeServiceTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <span translate="label.include"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label translate="label.resourceTag"></label>
                                            <div>
                                                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.RESOURCE, $event)"
                                                              [buttonName]='"label.newTag"'
                                                              [dependentFilters]="mainDependentFilters?.resourceTags"
                                                              [initialFilterValues]="appointmentEventRuleTags.selectedResourceTags"
                                                              [isMultipleSelectedItem]="false"
                                                              [useSelectedValueAsLabel]="true"
                                                              [hideSelectedItems]="true"
                                                              [providerInstance]="tagProvider">
                                                </sbase-filter>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.resourceTagId">
                                            <div class="switch">
                                                <label>
                                                    <span translate="label.exclude"></span>
                                                    <input type="checkbox"
                                                           [(ngModel)]="appointmentEventRuleTags.includeResourceTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.RESOURCE, appointmentEventRuleTags.includeResourceTagFlag)"
                                                           name="includeResourceTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <span translate="label.include"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect patient-tags">
                                            <label translate="label.patientTag"></label>
                                            <div>
                                                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.PATIENT, $event)"
                                                              [buttonName]='"label.newTag"'
                                                              [dependentFilters]="mainDependentFilters?.patientTags"
                                                              [initialFilterValues]="appointmentEventRuleTags.selectedPatientTags"
                                                              [isMultipleSelectedItem]="false"
                                                              [useSelectedValueAsLabel]="true"
                                                              [hideSelectedItems]="true"
                                                              [providerInstance]="tagProvider">
                                                </sbase-filter>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.patientTagId">
                                            <div class="switch">
                                                <label>
                                                    <span translate="label.exclude"></span>
                                                    <input type="checkbox"
                                                           [(ngModel)]="appointmentEventRuleTags.includePatientTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.PATIENT, appointmentEventRuleTags.includePatientTagFlag)"
                                                           name="includePatientTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <span translate="label.include"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-5">
                                <!--rule set-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label class="mb-2" translate="label.eventAction"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedEventAction($event)"
                                                  [buttonName]='"label.eventAction"'
                                                  [dependentFilters]="eventActionDependentFilters"
                                                  [initialFilterValues]="[ruleItem.eventAction]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.eventAction?.id}"
                                                  [providerInstance]="eventActionProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.eventAction?.id"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.eventAction?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.appointmentEventRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.priority"></td>
                                            <td class="text-right">{{ruleItem.priority}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.entityType"></td>
                                            <td class="text-right">{{'enum.entityType.' + ruleItem.entityType | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.eventType"></td>
                                            <td class="text-right" *ngIf="!generalUtils.isSelectedNoValueOption(ruleItem.eventType)">{{'enum.eventType.' + ruleItem.eventType | translate}}</td>
                                            <td class="text-right" *ngIf="generalUtils.isSelectedNoValueOption(ruleItem.eventType)"></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.status"></td>
                                            <td class="text-right" *ngIf="!generalUtils.isSelectedNoValueOption(ruleItem.appointmentStatus)">{{'enum.appointmentStatus.' + ruleItem.appointmentStatus | translate}}</td>
                                            <td class="text-right" *ngIf="generalUtils.isSelectedNoValueOption(ruleItem.appointmentStatus)"></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.centerTag"></td>
                                            <td class="text-right">
                                                <i class="fas fa-plus-circle"
                                                   ngbTooltip="{{'label.include'| translate}}"
                                                   *ngIf="rulesUtils.displayIncludeIcon(ruleItem.centerTag, ruleItem.includeCenterTag)"></i>
                                                <i class="fas fa-minus-circle"
                                                   ngbTooltip="{{'label.exclude'| translate}}"
                                                   *ngIf="rulesUtils.displayExcludeIcon(ruleItem.centerTag, ruleItem.includeCenterTag)"></i>
                                                &nbsp;{{ruleItem.centerTag?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.serviceTag"></td>
                                            <td class="text-right">
                                                <i class="fas fa-plus-circle"
                                                   ngbTooltip="{{'label.include'| translate}}"
                                                   *ngIf="rulesUtils.displayIncludeIcon(ruleItem.serviceTag, ruleItem.includeServiceTag)"></i>
                                                <i class="fas fa-minus-circle"
                                                   ngbTooltip="{{'label.exclude'| translate}}"
                                                   *ngIf="rulesUtils.displayExcludeIcon(ruleItem.serviceTag, ruleItem.includeServiceTag)"></i>
                                                &nbsp;{{ruleItem.serviceTag?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.resourceTag"></td>
                                            <td class="text-right">
                                                <i class="fas fa-plus-circle"
                                                   ngbTooltip="{{'label.include'| translate}}"
                                                   *ngIf="rulesUtils.displayIncludeIcon(ruleItem.resourceTag, ruleItem.includeResourceTag)"></i>
                                                <i class="fas fa-minus-circle"
                                                   ngbTooltip="{{'label.exclude'| translate}}"
                                                   *ngIf="rulesUtils.displayExcludeIcon(ruleItem.resourceTag, ruleItem.includeResourceTag)"></i>
                                                &nbsp;{{ruleItem.resourceTag?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.patientTag"></td>
                                            <td class="text-right">
                                                <i class="fas fa-plus-circle"
                                                   ngbTooltip="{{'label.include'| translate}}"
                                                   *ngIf="rulesUtils.displayIncludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                <i class="fas fa-minus-circle"
                                                   ngbTooltip="{{'label.exclude'| translate}}"
                                                   *ngIf="rulesUtils.displayExcludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                &nbsp;{{ruleItem.patientTag?.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.eventAction"></td>
                                            <td class="text-right">{{ruleItem.eventAction?.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    type="button"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'AppointmentEventRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
