import {Injectable} from '@angular/core';
import {
    PaymentManagementRuleType,
    Expand,
    IncludeExcludeEnum,
    ODataQueryObjectType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    ActivatePaymentModalFilterOptionsType,
    CoveragePlanModalFilterOptionsType,
    PaymentManagementRuleModalFiltersType,
    PaymentManagementRuleStateType,
    PaymentManagementRuleTagsType,
    ShowPriceModalFilterOptionsType,
    TagModalFilterOptionsType
} from './payment-management-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class PaymentManagementRuleUtils {
    overviewState: PaymentManagementRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'paymentManagementRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.serviceTag',
            hasSort: true,
            onSortByProperty: 'serviceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.centerTag',
            hasSort: true,
            onSortByProperty: 'centerTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resourceTag',
            hasSort: true,
            onSortByProperty: 'resourceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.coveragePlan2',
            hasSort: true,
            onSortByProperty: 'coveragePlan',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.activatePayment',
            hasSort: true,
            onSortByProperty: 'activatePayment',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.showPrice',
            hasSort: true,
            onSortByProperty: 'showPrice',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'paymentManagementRuleTableId':
                    case 'serviceTagId':
                    case 'centerTagId':
                    case 'resourceTagId':
                    case 'coveragePlanId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'activatePayment': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'showPrice': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item] !== 'false'};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'paymentManagementRuleTable':
                    case 'serviceTag':
                    case 'centerTag':
                    case 'resourceTag':
                    case 'coveragePlan': {
                        orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                        break;
                    }
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            CenterTag: {select: EXPAND_TAG_SELECT},
            ServiceTag: {select: EXPAND_TAG_SELECT},
            ResourceTag: {select: EXPAND_TAG_SELECT},
            CoveragePlan: {select: ['Id', 'Name']},
            PaymentManagementRuleTable: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): PaymentManagementRuleType {
        return {
            name: undefined,
            active: false,
            action: false,
            paymentManagementRuleTableId: undefined,
            operationServiceTag: IncludeExcludeEnum.exclude,
            serviceTagId: undefined,
            operationCenterTag: undefined,
            centerTagId: undefined,
            operationResourceTag: undefined,
            resourceTagId: undefined,
            coveragePlanId: undefined,
            showPrice: false,
            activatePayment: undefined
        } as PaymentManagementRuleType;
    }

    getInitialModalFilters(): PaymentManagementRuleModalFiltersType {
        return {
            centerTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            serviceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            resourceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            coveragePlanOptions: {
                ngModel: []
            } as CoveragePlanModalFilterOptionsType,
            activatePaymentOptions: {
                ngModel: undefined
            } as ActivatePaymentModalFilterOptionsType,
            showPriceOptions: {
                ngModel: undefined
            } as ShowPriceModalFilterOptionsType,
            areFiltersActive: false,
        } as PaymentManagementRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: PaymentManagementRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: PaymentManagementRuleType): PaymentManagementRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getInitialPaymentManagementRuleTags(): PaymentManagementRuleTagsType {
        return {
            selectedServiceTags: [],
            selectedCenterTags: [],
            selectedResourceTags: [],
            includeServiceTagFlag: undefined,
            includeCenterTagFlag: undefined,
            includeResourceTagFlag: undefined
        } as PaymentManagementRuleTagsType;
    }
}
