import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CapacityPlannerComponent} from './capacity-planner/capacity-planner.component';
import {SigninOidcComponent} from './login-process/signin-oidc/signin-oidc.component';
import {RequestAuthenticatedUserGuard} from '../core/guards/request-authenticated-user.guard';
import {RedirectSilentRenewComponent} from './login-process/redirect-silent-renew/redirect-silent-renew.component';
import {RoomReservationComponent} from './room-reservation/room-reservation.component';
import {ExportPageComponent} from './export/export-page.component';
import {AccessRoute} from '../core/guards/access-route.guard';
import {RoomReservationsExportComponent} from './room-reservations-export/room-reservations-export.component';
import {MultiAppointmentTemplateMDComponent} from './multi-appointment-template-md/multi-appointment-template-md.component';
import {CreateMultiAppointmentTemplateComponent} from './multi-appointment-template-md/create-multi-appointment-template/create-multi-appointment-template.component';
import {ServiceMDComponent} from './service-md/service-md.component';
import {CreateServiceMDComponent} from './service-md/create-service-md.component';
import {SubServiceMDComponent} from './sub-service-md/sub-service-md.component';
import {CreateSubServiceComponent} from './sub-service-md/create-sub-service.component';
import {SpecialityMDComponent} from './speciality-md/speciality-md.component';
import {CreateSpecialityComponent} from './speciality-md/create-speciality/create-speciality.component';
import {AreaMDComponent} from './area-md/area-md.component';
import {CreateAreaComponent} from './area-md/create-area/create-area.component';
import {CreateTeamMdComponent} from './team-md/create-team-md.component';
import {UserMDComponent} from './user-md/user-md.component';
import {CreateUserMDComponent} from './user-md/create-user-md.component';
import {TeamMDComponent} from './team-md/team-md.component';
import {CreateTagComponent} from './tag-md/create-tag/create-tag.component';
import {TagMDComponent} from './tag-md/tag-md.component';
import {ResourceCalendarComponent} from './resource-calendar/resource-calendar.component';
import {ResourceTypeMDComponent} from './resource-type-md/resource-type-md.component';
import {CreateResourceTypeComponent} from './resource-type-md/create-resource-type/create-resource-type.component';
import {AppointmentTypeMDComponent} from './appointment-type-md/appointment-type-md.component';
import {CreateAppointmentTypeComponent} from './appointment-type-md/create-appointment-type/create-appointment-type.component';
import {AppointmentTypeDefinitionMDComponent} from './appointment-type-definition-md/appointment-type-definition-md.component';
import {CreateAppointmentTypeDefinitionComponent} from './appointment-type-definition-md/create-appointment-type-definition/create-appointment-type-definition.component';
import {CoverageCompanyMDComponent} from './coverage-company-md/coverage-company-md.component';
import {CreateCoverageCompanyComponent} from './coverage-company-md/create-coverage-company/create-coverage-company.component';
import {CoveragePlanMDComponent} from './coverage-plan-md/coverage-plan-md.component';
import {CreateCoveragePlanComponent} from './coverage-plan-md/create-coverage-plan/create-coverage-plan.component';
import {CenterMDComponent} from './center-md/center-md.component';
import {CreateCenterComponent} from './center-md/create-center/create-center.component';
import {AvailabilityComponent} from './availability/availability.component';
import {CreateAvailabilityComponent} from './availability/create-availability/create-availability.component';
import {ViewApproveAvailabilityComponent} from './availability/view-approve-availability/view-approve-availability.component';
import {CreateAvailabilitySplitComponent} from './availability/availability-split/create-availability-split/create-availability-split.component';
import {AvailabilityViewPageEnum} from './availability/availability.types';
import {BlockedAppointmentsPreviewComponent} from '../shared/component/blocked-appointments-preview/blocked-appointments-preview.component';
import {AvailabilityHistoryComponent} from './availability/availability-history/availability-history.component';
import {ExclusionComponent} from './exclusion/exclusion.component';
import {ExclusionViewPageEnum} from './exclusion/exclusion.types';
import {CreateExclusionComponent} from './exclusion/create-exclusion/create-exclusion.component';
import {ViewApproveExclusionComponent} from './exclusion/view-approve-exclusion/view-approve-exclusion.component';
import {PatientAuditComponent} from './patient-audit/patient-audit.component';
import {ExclusionHistoryComponent} from './exclusion/exclusion-history/exclusion-history.component';
import {HolidayMDComponent} from './holiday-md/holiday-md.component';
import {CreateHolidayComponent} from './holiday-md/create-holiday/create-holiday.component';
import {ExclusionReasonMDComponent} from './exclusion-reason-md/exclusion-reason-md.component';
import {CreateExclusionReasonComponent} from './exclusion-reason-md/create-exclusion-reason/create-exclusion-reason.component';
import {HolidayCalendarMDComponent} from './holiday-calendar-md/holiday-calendar-md.component';
import {CreateHolidayCalendarComponent} from './holiday-calendar-md/create-holiday-calendar/create-holiday-calendar.component';
import {AppointmentStatusTransitionReasonMDComponent} from './appointment-status-transition-reason-md/appointment-status-transition-reason-md.component';
import {CreateAppointmentStatusTransitionReasonComponent} from './appointment-status-transition-reason-md/create-appointment-status-transition-reason/create-appointment-status-transition-reason.component';
import {AvailabilityExclusionExportMDComponent} from './availability-exclusion-export-md/availability-exclusion-export-md.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {SystemConfigComponent} from './system-config/system-config.component';
import {ActivityPlanTemplateMDComponent} from './activity-plan-template-md/activity-plan-template-md.component';
import {CreateActivityPlanTemplateComponent} from './activity-plan-template-md/create-activity-plan-template/create-activity-plan-template.component';
import {BookingFiltersManagementComponent} from './booking-filters-management/booking-filters-management.component';
import {SlotsManagementComponent} from './slots-management/slots-management.component';
import {EventActionMDComponent} from './event-action-md/event-action-md.component';
import {CreateEventActionComponent} from './event-action-md/create-event-action/create-event-action.component';
import {DataStoreMDComponent} from './data-store-md/data-store-md.component';
import {CreateDataStoreComponent} from './data-store-md/create-data-store/create-data-store.component';
import {PatientDocumentsComponent} from './patient-documents/patient-documents.component';
import {RelationPairsMDComponent} from './relation-pairs-md/relation-pairs-md.component';
import {CreateRelationPairsComponent} from './relation-pairs-md/create-relation-pairs/create-relation-pairs.component';
import {BookAppointmentComponent} from './slots-management/book-appointment/book-appointment.component';
import {ResourceUtilizationComponent} from './resource-utilization/resource-utilization.component';
import {ObjectDetailMDComponent} from './object-detail-md/object-detail-md.component';
import {CreateObjectDetailComponent} from './object-detail-md/create-object-detail/create-object-detail.component';
import {ResourceUtilizationManageAppointmentComponent} from './resource-utilization/resource-utilization-manage-appointment/resource-utilization-manage-appointment.component';
import {GraphicalResourcePlannerComponent} from './graphical-resource-planner/graphical-resource-planner.component';
import {GrpBookingAppointmentComponent} from './graphical-resource-planner/grp-booking-appointment/grp-booking-appointment.component';
import {GenerateSkedTaskComponent} from './graphical-resource-planner/generate-sked-task/generate-sked-task.component';
import {TaskListComponent} from './task-list/task-list.component';
import {TaskItemDetailsComponent} from './task-list/task-item-details/task-item-details.component';
import {ApiIntegrationComponent} from './api-integration/api-integration.component';
import {PatientDashboardComponent} from './patient-dashboard/patient-dashboard.component';
import {CreatePatientComponent} from './patient-dashboard/create-patient/create-patient.component';
import {PatientAppointmentListComponent} from './patient-dashboard/patient-appointment-list/patient-appointment-list.component';
import {AppointmentListComponent} from './appointment-list/appointment-list.component';
import {PatientMergeComponent} from './patient-dashboard/patient-merge/patient-merge.component';
import {TimeWindowForChannelMDComponent} from './time-window-for-channel-md/time-window-for-channel-md.component';
import {CreateTimeWindowForChannelComponent} from './time-window-for-channel-md/create-time-window-for-channel/create-time-window-for-channel.component';
import {ExcelImportComponent} from './excel-import/excel-import.component';
import {RulesComponent} from './rules-management/rules.component';
import {RuleSetComponent} from './rules-management/rule-set/rule-set.component';
import {CreateRuleOutletComponent} from './rules-management/create-rule-outlet/create-rule-outlet.component';
import {TimeWindowSpecialityAreaMDComponent} from './time-window-speciality-area-md/time-window-speciality-area-md.component';
import {CreateTimeWindowSpecialityAreaComponent} from './time-window-speciality-area-md/create-time-window-speciality-area/create-time-window-speciality-area.component';
import {ResourceChannelRestrictionMDComponent} from './resource-channel-restriction-md/resource-channel-restriction-md.component';
import {CreateResourceChannelRestrictionComponent} from './resource-channel-restriction-md/create-resource-channel-restriction/create-resource-channel-restriction.component';
import {MultiResourceBluePrintMDComponent} from './multi-resource-blue-print-md/multi-resource-blue-print-md.component';
import {CreateMultiResourceBluePrintComponent} from './multi-resource-blue-print-md/create-multi-resource-blue-print/create-multi-resource-blue-print.component';
import {MultiResourceCombinationMDComponent} from './multi-resource-combination-md/multi-resource-combination-md.component';
import {CreateMultiResourceCombinationComponent} from './multi-resource-combination-md/create-multi-resource-combination/create-multi-resource-combination.component';
import {AvailabilityOversellingDefinitionMDComponent} from './availability/availability-overselling-definition-md/availability-overselling-definition-md.component';
import {ExportAppointmentsComponent} from './export-appointments/export-appointments.component';
import {ResourceSwapComponent} from './resource-swap/resource-swap.component';
import {ResourceWorkScheduleMDComponent} from './resource-work-schedule-md/resource-work-schedule-md.component';
import {CreateResourceWorkScheduleComponent} from './resource-work-schedule-md/create-resource-work-schedule/create-resource-work-schedule.component';
import {ResourceMDComponent} from './resource-md/resource-md.component';
import {CreateResourceComponent} from './resource-md/create-resource/create-resource.component';
import {MultiAppointmentBookingComponent} from './multi-appointment-booking/multi-appointment-booking.component';
import {MabBookAppointmentComponent} from './multi-appointment-booking/mab-book-appointment/mab-book-appointment.component';
import {RulesWeightedCombinationMDComponent} from './rules-weighted-combination-md/rules-weighted-combination-md.component';
import {CreateRulesWeightedCombinationComponent} from './rules-weighted-combination-md/create-rules-weighted-combination/create-rules-weighted-combination.component';
import {ActionExecutionLogsMDComponent} from './action-execution-logs-md/action-execution-logs-md.component';
import {ChangeLogMDComponent} from './change-log-md/change-log-md.component';
import {ActivityPlanComponent} from './activity-plan/activity-plan.component';
import {AddActivityPlanComponent} from './activity-plan/add-activity-plan/add-activity-plan.component';

const routes: Routes = [
    {path: 'signin-oidc', component: SigninOidcComponent},
    {path: 'silent-callback', component: RedirectSilentRenewComponent},
    {path: 'dashboard', component: DashboardComponent, canActivate: [RequestAuthenticatedUserGuard]},
    {
        path: 'capacityPlannerResource',
        component: CapacityPlannerComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'capacityPlannerRoom',
        component: CapacityPlannerComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'roomReservation',
        component: RoomReservationComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'export',
        component: ExportPageComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'roomReservationExport',
        component: RoomReservationsExportComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'multiAppointmentsTemplate',
        component: MultiAppointmentTemplateMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createMultiAppointmentTemplate',
        component: CreateMultiAppointmentTemplateComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'services',
        component: ServiceMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createService',
        component: CreateServiceMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'subServices',
        component: SubServiceMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createSubService',
        component: CreateSubServiceComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'specialities',
        component: SpecialityMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createSpeciality',
        component: CreateSpecialityComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {path: 'areas', component: AreaMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'createArea', component: CreateAreaComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'teams', component: TeamMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'createTeam', component: CreateTeamMdComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'users', component: UserMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'createUser', component: CreateUserMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'tags', component: TagMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'createTag', component: CreateTagComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {
        path: 'resourceCalendar',
        component: ResourceCalendarComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resourceTypes',
        component: ResourceTypeMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createResourceType',
        component: CreateResourceTypeComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'appointmentTypes',
        component: AppointmentTypeMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createAppointmentType',
        component: CreateAppointmentTypeComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'appointmentTypeDefinitions',
        component: AppointmentTypeDefinitionMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createAppointmentTypeDefinition',
        component: CreateAppointmentTypeDefinitionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'coverageCompanies',
        component: CoverageCompanyMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createCoverageCompany',
        component: CreateCoverageCompanyComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'coveragePlans',
        component: CoveragePlanMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createCoveragePlan',
        component: CreateCoveragePlanComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {path: 'centers', component: CenterMDComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {path: 'createCenter', component: CreateCenterComponent, canActivate: [RequestAuthenticatedUserGuard, AccessRoute]},
    {
        path: AvailabilityViewPageEnum.AvailabilityOverview,
        component: AvailabilityComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: AvailabilityViewPageEnum.ApproveAvailabilityOverview,
        component: AvailabilityComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createAvailability',
        component: CreateAvailabilityComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createAvailabilitySplit',
        component: CreateAvailabilitySplitComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'viewApproveAvailability',
        component: ViewApproveAvailabilityComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'availabilityBlockedAppointmentsPreview',
        component: BlockedAppointmentsPreviewComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'exclusionBlockedAppointmentsPreview',
        component: BlockedAppointmentsPreviewComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'availabilityHistory',
        component: AvailabilityHistoryComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: ExclusionViewPageEnum.ExclusionOverview,
        component: ExclusionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: ExclusionViewPageEnum.ApproveExclusionOverview,
        component: ExclusionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createExclusion',
        component: CreateExclusionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'viewApproveExclusion',
        component: ViewApproveExclusionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'exclusionHistory',
        component: ExclusionHistoryComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'patientAudit',
        component: PatientAuditComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'patientDashboard',
        component: PatientDashboardComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'holidays',
        component: HolidayMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createHoliday',
        component: CreateHolidayComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'exclusionReasons',
        component: ExclusionReasonMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createExclusionReason',
        component: CreateExclusionReasonComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'holidayCalendars',
        component: HolidayCalendarMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createHolidayCalendar',
        component: CreateHolidayCalendarComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'appointmentStatusTransitionReasons',
        component: AppointmentStatusTransitionReasonMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createAppointmentStatusTransitionReason',
        component: CreateAppointmentStatusTransitionReasonComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'availabilityExclusionExport',
        component: AvailabilityExclusionExportMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'userProfile',
        component: UserProfileComponent,
        canActivate: [RequestAuthenticatedUserGuard]
    },
    {
        path: 'systemConfig',
        component: SystemConfigComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'activityPlanTemplates',
        component: ActivityPlanTemplateMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createActivityPlanTemplate',
        component: CreateActivityPlanTemplateComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'bookingFilters',
        component: BookingFiltersManagementComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'slotsManagement',
        component: SlotsManagementComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'eventActions',
        component: EventActionMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createEventAction',
        component: CreateEventActionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'dataStore',
        component: DataStoreMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createDataStore',
        component: CreateDataStoreComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'eventRules',
        component: RulesComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'patientDocuments',
        component: PatientDocumentsComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'relationPairs',
        component: RelationPairsMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createRelationPair',
        component: CreateRelationPairsComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'bookAppointment',
        component: BookAppointmentComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resourceUtilization',
        component: ResourceUtilizationComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'graphicalResourcePlanner',
        component: GraphicalResourcePlannerComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'grpBookingAppointment',
        component: GrpBookingAppointmentComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'generateSkedTask',
        component: GenerateSkedTaskComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'objectDetails',
        component: ObjectDetailMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createObjectDetail',
        component: CreateObjectDetailComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resourceUtilizationManageAppointment',
        component: ResourceUtilizationManageAppointmentComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'taskList',
        component: TaskListComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'taskDetails',
        component: TaskItemDetailsComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'apiIntegration',
        component: ApiIntegrationComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createPatient',
        component: CreatePatientComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'patientAppointmentList',
        component: PatientAppointmentListComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'appointmentList',
        component: AppointmentListComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'patientMerge',
        component: PatientMergeComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'timeWindowsForChannel',
        component: TimeWindowForChannelMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createTimeWindowForChannel',
        component: CreateTimeWindowForChannelComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'excelImport',
        component: ExcelImportComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'rules',
        component: RulesComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createRule',
        component: CreateRuleOutletComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'ruleSet',
        component: RuleSetComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'timeWindowsForResource',
        component: ResourceChannelRestrictionMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createTimeWindowForResource',
        component: CreateResourceChannelRestrictionComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'timeWindowsForSpecialitiesAndAreas',
        component: TimeWindowSpecialityAreaMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createTimeWindowForSpecialityAndArea',
        component: CreateTimeWindowSpecialityAreaComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'multiResourceBluePrints',
        component: MultiResourceBluePrintMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createMultiResourceBluePrint',
        component: CreateMultiResourceBluePrintComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'multiResourceCombinations',
        component: MultiResourceCombinationMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createMultiResourceCombination',
        component: CreateMultiResourceCombinationComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'availabilityOversellingDefinitions',
        component: AvailabilityOversellingDefinitionMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'exportAppointments',
        component: ExportAppointmentsComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resourceSwap',
        component: ResourceSwapComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resourceWorkSchedules',
        component: ResourceWorkScheduleMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createResourceWorkSchedule',
        component: CreateResourceWorkScheduleComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'resources',
        component: ResourceMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createResource',
        component: CreateResourceComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'multiAppointmentBooking',
        component: MultiAppointmentBookingComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'bookMultiAppointment',
        component: MabBookAppointmentComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'rulesWeightedCombinations',
        component: RulesWeightedCombinationMDComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'createRulesWeightedCombination',
        component: CreateRulesWeightedCombinationComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
      path: 'actionExecutionLogs',
      component: ActionExecutionLogsMDComponent,
      canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
      path: 'changeLogs',
      component: ChangeLogMDComponent,
      canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'activityPlans',
        component: ActivityPlanComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
    {
        path: 'addActivityPlan',
        component: AddActivityPlanComponent,
        canActivate: [RequestAuthenticatedUserGuard, AccessRoute]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeatureRoutingModule {
}
