import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    AreaDependentFiltersType,
    AreaProvider,
    AreaType,
    CenterProvider,
    CenterType,
    ConfirmDeleteModalService,
    CoveragePlanDependentFiltersType,
    CoveragePlanProvider,
    CoveragePlanType,
    EntityTypeEnum,
    LocationDependentFiltersType,
    ResourceProvider,
    ResourceType,
    ResourceTypeDependentFilterType,
    ResourceTypeProvider,
    ResourceTypeType,
    SearchFilterUtils,
    ServiceDependentFiltersType,
    ServiceProvider,
    ServiceType,
    SpecialityDependentFiltersType,
    SpecialityProvider,
    SpecialityType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ResourceMdUtils} from './resource-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-resource-md',
    templateUrl: './resource-md.component.html',
    styleUrls: ['./resource-md.component.scss']
})
export class ResourceMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    resourceMdList: ResourceType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    DISPLAYED_ITEMS_NUMBER = 5;
    readonly RESOURCE = 'Resource';
    readonly CONSTANTS = constants;
    mainDependentFilters: {
        resourceType: ResourceTypeDependentFilterType,
        speciality: SpecialityDependentFiltersType,
        service: ServiceDependentFiltersType,
        area: AreaDependentFiltersType,
        center: LocationDependentFiltersType,
        coveragePlan: CoveragePlanDependentFiltersType,
    };
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchResourceChanged = new Subject<string>();

    constructor(
        public resourceProvider: ResourceProvider,
        public resourceTypeProvider: ResourceTypeProvider,
        public specialityProvider: SpecialityProvider,
        public serviceProvider: ServiceProvider,
        public areaProvider: AreaProvider,
        public centerProvider: CenterProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public resourceMdUtils: ResourceMdUtils,
        private searchFilterUtils: SearchFilterUtils,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('ResourceRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createResource') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadMainDependentFilters();
        this.loadResourceMdData();
        this.subscribeSearchByResourceChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create Resource
    createResource(): void {
        this.router.navigate(['/createResource']);
    }

    editResource(resource: ResourceType) {
        this.router.navigate(['/createResource'], {state: {resource, action: constants.EDIT}});
    }

    viewResource(resource: ResourceType) {
        this.router.navigate(['/createResource'], {state: {resource, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.resourceMdUtils.tableFilters.currentPage = page;
        this.loadResourceMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.resourceMdUtils.tableFilters.currentPage = 1;
        this.resourceMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadResourceMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.resourceMdUtils.tableFilters.orderBy[property];
        if (this.resourceMdUtils.tableFilters.orderBy) {
            this.resourceMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadResourceMdData(false);
    }

    onSearchResourceChanged(value: string) {
        this.searchResourceChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.resourceMdUtils.tableFilters.orderBy[selectedItem];
        this.loadResourceMdData(false);
    }

    displayConfirmDeleteItemModal(resourceId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.resourceProvider, resourceId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(resourceId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onSelectedSpecialityFilter(selectedSpecialityList: SpecialityType[]) {
        this.resourceMdUtils.initialFilterValues.speciality = selectedSpecialityList;
        this.resourceMdUtils.tableFilters.filter.specialityId = selectedSpecialityList[0]?.id;
        this.loadResourceMdData();
    }

    onSelectedServiceFilter(selectedServiceList: ServiceType[]) {
        this.resourceMdUtils.initialFilterValues.service = selectedServiceList;
        this.resourceMdUtils.tableFilters.filter.serviceId = selectedServiceList[0]?.id;
        this.loadResourceMdData();
    }

    onSelectedAreaFilter(selectedAreaList: AreaType[]) {
        this.resourceMdUtils.initialFilterValues.area = selectedAreaList;
        this.resourceMdUtils.tableFilters.filter.areaId = selectedAreaList[0]?.id;
        this.mainDependentFilters.speciality.areaId = selectedAreaList[0]?.id;
        this.mainDependentFilters.service.areaId = selectedAreaList[0]?.id;
        this.loadResourceMdData();
    }

    onSelectedResourceTypeFilter(selectedResourceTypeList: ResourceTypeType[]) {
        this.resourceMdUtils.initialFilterValues.resourceType = selectedResourceTypeList;
        this.resourceMdUtils.tableFilters.filter.resourceTypeId = selectedResourceTypeList[0]?.id;
        this.loadResourceMdData();
    }

    onSelectedCenterFilter(selectedCenterList: CenterType[]) {
        this.resourceMdUtils.initialFilterValues.center = selectedCenterList;
        this.resourceMdUtils.tableFilters.filter.centerId = selectedCenterList[0]?.id;
        this.loadResourceMdData();
    }

    onSelectedCoveragePlanFilter(selectedCoveragePlanList: CoveragePlanType[]) {
        this.resourceMdUtils.initialFilterValues.coveragePlan = selectedCoveragePlanList;
        this.resourceMdUtils.tableFilters.filter.coveragePlanId = selectedCoveragePlanList[0]?.id;
        this.loadResourceMdData();
    }

    // function to get Resource data
    private loadResourceMdData(includeCount: boolean = true) {
        const queryFilter = this.resourceMdUtils.getQueryFilterForResourceMD(this.resourceMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.resourceProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.resourceMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.onSearchResourceChanged('');
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadResourceMdData(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.resourceMdUtils.tableFilters = this.resourceMdUtils.getInitialTableFilter();
        }
        if (!!sbaseFilters) {
            this.resourceMdUtils.initialFilterValues = this.resourceMdUtils.getInitialFilterValues();
        }
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.resourceMdUtils.tableFilters = this.resourceMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.resourceProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadResourceMdData();
                this.messagesService.success('toastr.success.resourceDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByResourceChanged() {
        const self = this;
        this.searchResourceChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.resourceMdUtils.tableFilters.filter.name = searchValue;
                self.loadResourceMdData();
            });
    }

    private loadMainDependentFilters(): void {
        this.mainDependentFilters = {
            speciality: this.searchFilterUtils.specialityServiceDependentFilters(),
            service: this.searchFilterUtils.getServiceDependentFilters(),
            area: this.searchFilterUtils.getAreaDependentFilters(),
            resourceType: this.resourceMdUtils.getResourceTypeDependentFilters(),
            center: this.searchFilterUtils.getLocationDependentFilters(),
            coveragePlan: this.searchFilterUtils.getCoveragePlanDependentFilters()
        };
    }
}
