import {Component, OnDestroy, OnInit} from '@angular/core';
import {OpenIdConnectService} from '../../../shared/services/open-id-connect.service';
import {Router} from '@angular/router';
import {LoggerService} from '../../../shared/services/logger.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {MessagesService} from '../../../shared/services/messages.service';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import * as lodash from 'lodash';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
  selector: 'app-sigin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit, OnDestroy {

  constructor(private openIdConnectService: OpenIdConnectService,
              private router: Router,
              private ngxLoader: NgxUiLoaderService,
              private configData: ConfigDataService,
              private messagesService: MessagesService,
              private translatedLanguageService: TranslatedLanguageService,
              private loggerService: LoggerService) {
  }

  ngOnInit() {
    if (this.openIdConnectService.userLoaded$) {
      this.ngxLoader.start();
      this.openIdConnectService.userLoaded$
        .subscribe((userLoaded) => {
          if (userLoaded) {
            this.getConfigData();
          } else {
            this.loggerService.log('An error happened: user wasn\' t loaded');
          }
        });
    }

    this.openIdConnectService.handleRedirectCallBack();
  }

  ngOnDestroy(): void {
  }

  private getConfigData(): void {
    // this.configLoadingService.startLoading();
    this.ngxLoader.start();
    const storageUser = this.openIdConnectService.getUserInfo();
    this.configData.getInitialConfigData(storageUser.userId)
      .subscribe(value => {
        const defaultTranslatedLanguageCode = this.translatedLanguageService.defaultLanguage;
        if (defaultTranslatedLanguageCode) {
          this.translatedLanguageService.setDefaultTranslationCode(lodash.lowerCase(defaultTranslatedLanguageCode));
          this.translatedLanguageService.onSetLanguage(lodash.lowerCase(defaultTranslatedLanguageCode));
        }
        // this.loggerService.log(value ? JSON.stringify(value) : '');
        // go to home page
        this.router.navigate(['./dashboard']);
      }, error => {
        this.messagesService.handlingErrorMessage(error);
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
  }

}
