import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateMMDDYYYYBrowserTzFormat'
})

// pipe to format the date based on language (Example: English - MM/DD/YYYY, Spanish: DD/MM/YYYY)
export class DateMMDDYYYYBrowserTzFormatPipe implements PipeTransform {
    transform(date: string): string {
        if (date) {
            //@ts-ignore
            return moment(date).format(moment.localeData().longDateFormat('L'));
        } else {
            return undefined;
        }
    }
}
