<div class="container-fluid create-multi-appointment-template-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-multi-appointment-template-header">
        <h3 class="create-multi-appointment-template-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase" translate="label.multiAppointmentTemplate"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!-- GENERAL-->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-md-3 col-12">
                                <div class="form-group">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="multiAppointmentTemplateItem.name"
                                           [ngClass]="{'input-element-has-error': !multiAppointmentTemplateItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!multiAppointmentTemplateItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-12">
                                <div class="form-group">
                                    <label translate="label.showServicesInOrder"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox"
                                                   [(ngModel)]="multiAppointmentTemplateItem.bookServicesInOrder"
                                                   name="bookServicesInOrder">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- ASSIGNED SERVICES -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.service"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-3">
                            <button (click)="openNewOrEditItemSection(undefined)"
                                    type="button"
                                    class="btn theme-btn">
                                <i class="fas fa-plus"></i>
                                <span translate="label.addService" class="ml-2"></span>
                            </button>
                        </div>
                        <div *ngIf="!multiAppointmentTemplateItem?.multiAppointmentTemplateItems?.length && !showItemDataEditSection"
                             class="row ml-1 help-block">
                            <span class="input-element-has-error" translate="label.error.multiAppointmentTemplateNoServices"></span>
                        </div>
                        <div class="row ml-1 border rounded" *ngIf="showItemDataEditSection">
                            <div class="col-4">
                                <div class="form-group customSelect service mt-1 sbase-input-element">
                                    <label translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                  [buttonName]='"label.search"'
                                                  [dependentFilters]="mainDependentFilters.service"
                                                  [initialFilterValues]="[scopedItem?.service]"
                                                  [isMultipleSelectedItem]="false"
                                                  [providerInstance]="serviceProvider"
                                                  [hideSelectedItems]="true"
                                                  [useSelectedValueAsLabel]="true"
                                                  [additionalExclusionList]="exclusionLists?.service"
                                                  [ngClass]="{'input-element-has-error': !scopedItem?.serviceId}">
                                    </sbase-filter>
                                    <div *ngIf="!scopedItem?.serviceId"
                                         class="help-block">
                                        <span class="input-element-has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group customSelect mt-1"
                                     [ngClass]="{'disabled-class': !scopedItem?.service?.hasSubServices}">
                                    <label translate="label.subService"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedSubService($event)"
                                                  [buttonName]='"label.search"'
                                                  [dependentFilters]="mainDependentFilters.subService"
                                                  [initialFilterValues]="scopedItem?.subServices"
                                                  [isMultipleSelectedItem]="true"
                                                  [providerInstance]="subServiceProvider"
                                                  [hideSelectedItems]="true"
                                                  [useSelectedValueAsLabel]="true"
                                                  [additionalExclusionList]="exclusionLists?.subService">
                                    </sbase-filter>
                                </div>
                            </div>
                            <div class="col-2 numeric-input-width">
                                <div class="form-group customSelect pt-2">
                                    <label translate="label.before"></label>
                                    <div>
                                        <input type="number"
                                               class="input-element"
                                               name="timeBefore"
                                               min="0"
                                               step="1"
                                               [(ngModel)]="scopedItem.timeBefore"
                                               [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(scopedItem?.timeBefore) || scopedItem?.timeBefore % 1 !== 0 || scopedItem?.timeBefore < 0}"
                                               autocomplete="off">
                                        <div *ngIf="generalUtils.isNullOrUndefined(scopedItem?.timeBefore)"
                                             class="help-block">
                                                <span class="input-element-has-error"
                                                      translate="label.error.required">
                                                </span>
                                        </div>
                                        <div *ngIf="scopedItem?.timeBefore % 1 !== 0 || scopedItem?.timeBefore < 0"
                                             class="help-block">
                                                <span class="input-element-has-error"
                                                      translate="label.invalidField">
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 numeric-input-width">
                                <div class="form-group customSelect pt-2">
                                    <label translate="label.post"></label>
                                    <div>
                                        <input type="number" class="input-element"
                                               name="timePost"
                                               min="0"
                                               step="1"
                                               [(ngModel)]="scopedItem.timePost"
                                               [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(scopedItem?.timePost) || scopedItem?.timePost % 1 !== 0 || scopedItem?.timePost < 0}"
                                               autocomplete="off">
                                        <div *ngIf="generalUtils.isNullOrUndefined(scopedItem?.timePost)"
                                             class="help-block">
                                                <span class="input-element-has-error"
                                                      translate="label.error.required">
                                                </span>
                                        </div>
                                        <div *ngIf="scopedItem?.timePost % 1 !== 0 || scopedItem?.timePost < 0"
                                             class="help-block">
                                                <span class="input-element-has-error"
                                                      translate="label.invalidField">
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex float-right mb-3 mt-1 update-buttons"
                             *ngIf="showItemDataEditSection">
                            <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                                    [disabled]="!isEditItemSectionValid()"
                                    translate="button.save"
                                    (click)="onSaveItem()">
                            </button>
                            <button class="btn border-0 p-0" translate="button.cancel"
                                    (click)="onCancelItem()"></button>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label class="pt-3" translate="label.service"></label>
                                </div>
                            </div>
                        </div>
                        <table class="table table-bordered grey-table">
                            <thead class="thead-light">
                            <tr>
                                <td class="text-center">#</td>
                                <td translate="label.service2"></td>
                                <td translate="label.before"></td>
                                <td translate="label.post"></td>
                                <td translate="label.subServices"></td>
                                <td class="text-center" translate="label.action"></td>
                            </tr>
                            </thead>
                            <!--display an empty row if no timeslot is added-->
                            <tbody *ngIf="!multiAppointmentTemplateItem?.multiAppointmentTemplateItems?.length ">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                            <tbody cdkDropList (cdkDropListDropped)="dropTemplate($event)" class="template-list">
                            <tr class="draggable" cdkDrag cdkDragLockAxis="y"
                                *ngFor="let item of multiAppointmentTemplateItem?.multiAppointmentTemplateItems; let index = index">
                                <td>{{item.position}}</td>
                                <td><span class="font-weight-bold">{{item.service?.speciality?.name}}</span>{{' ' +
                                item.service?.name}}</td>
                                <td>{{item.timeBefore}}</td>
                                <td>{{item.timePost}}</td>
                                <td *ngIf="item.service?.hasSubServices"><span
                                        *ngFor="let subService of item?.subServices; last as isLast">{{subService.name + (!isLast ? ', ' : '')}}</span>
                                </td>
                                <td *ngIf="!item.service?.hasSubServices"></td>
                                <td class="text-center">
                                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                            popoverClass="popover-shadow">
                                        <i class="fas fa-ellipsis-h"></i>
                                    </button>
                                    <ng-template #popContent>
                                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                                            <li>
                                                <button type="button" class="btn" translate="label.edit"
                                                        (click)="openNewOrEditItemSection(item)"></button>
                                            </li>
                                            <li>
                                                <button type="button" class="btn" translate="label.delete"
                                                        (click)="deleteItem(index)"></button>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-step>
                <mat-step [completed]="false" [stepControl]="getStepControl(2)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <label translate="label.generalInformation" class="title"></label>
                        <table class="table table-bordered grey-table">
                            <thead class="thead-light">
                            <tr>
                                <td translate="label.name"></td>
                                <td translate="label.showServicesInOrder"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{multiAppointmentTemplateItem?.name}}</td>
                                <td>{{multiAppointmentTemplateItem?.bookServicesInOrder | yesNoPipe | translate}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <label translate="label.service" class="title"></label>
                        <table class="table table-bordered grey-table">
                            <thead class="thead-light">
                            <tr>
                                <td translate="label.name"></td>
                                <td translate="label.before"></td>
                                <td translate="label.post"></td>
                                <td translate="label.subServices"></td>
                            </tr>
                            </thead>
                            <tbody *ngFor="let item of multiAppointmentTemplateItem?.multiAppointmentTemplateItems">
                            <tr>
                                <td><span class="font-weight-bold">{{item.service.speciality?.name}}</span>{{' ' +
                                item.service.name}}</td>
                                <td>{{item?.timeBefore}}</td>
                                <td>{{item?.timePost}}</td>
                                <td>
                                    <span *ngFor="let subService of item?.subServices; last as isLast">{{subService.name + (!isLast ? ', ' : '')}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button class="btn theme-btn"
                    (click)="saveMultiAppointmentTemplateData(multiAppointmentTemplateItem)"
                    *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'MinimumAppointmentsRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
