import {Component, OnInit} from '@angular/core';
import {
    GeneralConfigType,
    ResourceCalendarAppointmentModalEventType,
    ResourceCalendarAppointmentModalResponseType,
    ResourceCalendarCenterConfigType,
    ResourceCalendarResourceConfigType
} from 'sked-base/lib/components/resource-calendar/resource-calendar.types';
import {MessagesService} from '../../shared/services/messages.service';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {constants} from '../../shared/constants/constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-resource-calendar',
    templateUrl: './resource-calendar.component.html',
    styleUrls: ['./resource-calendar.component.scss']
})
export class ResourceCalendarComponent implements OnInit {

    allowAppointmentStatusChange: boolean;
    appointmentModalEventsList: ResourceCalendarAppointmentModalEventType[] = [];
    centerConfig: ResourceCalendarCenterConfigType = {} as ResourceCalendarCenterConfigType;
    fullCalendarLicenseKey = constants.FULL_CALENDAR_LICENSE_KEY;
    resourceConfig: ResourceCalendarResourceConfigType = {} as ResourceCalendarResourceConfigType;
    calendarGeneralConfig: GeneralConfigType;

    constructor(public messagesService: MessagesService,
                public translatedLanguageService: TranslatedLanguageService,
                public configDataService: ConfigDataService,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.allowAppointmentStatusChange = false;
        this.centerConfig.isVisible = true;
        this.resourceConfig.isVisible = true;

        const userInfo = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        if (userInfo && userInfo.centers.length === 1) {
            this.centerConfig.center = userInfo.centers[0];
        } else {
            this.centerConfig.center = undefined;
        }

        if (userInfo && userInfo.resource) {
            this.resourceConfig.resource = userInfo.resource;
            this.resourceConfig.isVisible = false;
        } else {
            this.resourceConfig.resource = undefined;
        }
        this.calendarGeneralConfig = {showTitle: false, showEventAdditionalInformation: false};

        this.appointmentModalEventsList = [];
    }

    onEventTriggered(event: ResourceCalendarAppointmentModalResponseType): void {
        // nothing to do yet
    }

}
