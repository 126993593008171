<div class="container-fluid create-holiday-calendar-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-holiday-calendar-header">
        <h3 class="create-holiday-calendar-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.holidayCalendarTab.holidayCalendar"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveHolidayCalendarData(holidayCalendarItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <div class="row">
                <div class="col-md-4 col-12" *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <div class="form-group">
                        <label translate="label.description"></label>
                        <input type="text" name="description" class="input-element mt-3"
                               [(ngModel)]="holidayCalendarItem.description"
                               [ngClass]="{'input-element-has-error': !holidayCalendarItem.description
                               && screenTemplateLayout.action !== constants.VIEW}"
                               placeholder="{{'label.description'|translate}}">
                        <div *ngIf="!holidayCalendarItem.description
                                    && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                            <span translate="label.error.required"></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12" *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <div class="form-group"
                         [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                        <label translate="label.holidayCalendarTab.year" class="d-block"></label>
                        <input type="number" name="year"
                               class="input-element mt-3"
                               min="1000"
                               max="9999"
                               [(ngModel)]="holidayCalendarItem.year"
                               (ngModelChange)="onYearChange()"
                               [ngClass]="{
                               'input-element-has-error': (!holidayCalendarItem.year
                               || holidayCalendarItem.year > 9999
                               || dateTimeUtils.isYearInPast(holidayCalendarItem.year))
                               && screenTemplateLayout.action !== constants.VIEW}"
                               placeholder="{{'label.holidayCalendarTab.year'|translate}}">
                        <div *ngIf="screenTemplateLayout.action !== constants.VIEW">
                            <div *ngIf="!holidayCalendarItem.year" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                            <div *ngIf="holidayCalendarItem.year && holidayCalendarItem.year > 9999" class="help-block">
                                <span translate="label.error.invalidYear"></span>
                            </div>
                            <div
                                    *ngIf="holidayCalendarItem.year && dateTimeUtils.isYearInPast(holidayCalendarItem.year)"
                                    class="help-block">
                                <span translate="toastr.error.timeNotInPast"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="days-and-centers-container px-3" *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <!-- Centers -->
                    <div class="form-group">
                        <label translate="label.centers" class="d-block"></label>
                        <div class="help-block" *ngIf="holidayCalendarItem.centers.length === 0">
                            <span translate="label.error.required"></span>
                        </div>
                        <app-multi-select-table *ngIf="!!holidayCalendarMultiSelectTableOptions"
                                                [options]="holidayCalendarMultiSelectTableOptions.centers"
                                                (itemsChange)="onSelectedCenter($event)"
                                                class="sbase-dropdown-ml"
                        ></app-multi-select-table>
                    </div>
                    <!-- Days -->
                    <div class="form-group">
                        <label translate="label.holidayCalendarTab.days" class="d-block"></label>
                        <div class="help-block" *ngIf="holidayCalendarItem.days.length === 0">
                            <span translate="label.error.required"></span>
                        </div>
                        <app-multi-select-table *ngIf="!!holidayCalendarMultiSelectTableOptions"
                                                [options]="holidayCalendarMultiSelectTableOptions.holidays"
                                                (itemsChange)="onSelectedDay($event)"
                                                class="sbase-dropdown-ml"
                        ></app-multi-select-table>
                    </div>
                </div>
                <div class="col-12" *ngIf="screenTemplateLayout.action === constants.VIEW">
                    <table class="table border overview-table">
                        <tbody>
                        <tr>
                            <td translate="label.description"></td>
                            <td class="text-right">
                                <span>{{holidayCalendarItem.description}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td translate="label.holidayCalendarTab.year"></td>
                            <td class="text-right">
                                <span>{{holidayCalendarItem.year}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td translate="label.centers"></td>
                            <td class="text-right">
                                <span *ngFor="let center of holidayCalendarItem.centers; last as islast">
                                    <span>{{center.name}}</span>
                                    <span *ngIf="!islast">, </span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td translate="label.holidayCalendarTab.days"></td>
                            <td class="text-right">
                                <span *ngFor="let day of holidayCalendarItem.days; last as islast">
                                    <span>{{day?.description}} : {{
                                        day?.type === 'Fixed' ?
                                            (day?.monthOfYear + '/' + day?.dayOfMonth | dateMMddFormatPipe) :
                                            (day?.monthOfYear + '/' + day?.dayOfMonth + '/' + day?.year | dateMMddyyyyFormatPipe)
                                        }}
                                    </span>
                                    <span *ngIf="!islast">, </span>
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    [disabled]="!holidayCalendarItem.description || (!holidayCalendarItem.year || holidayCalendarItem.year > 9999 || dateTimeUtils.isYearInPast(holidayCalendarItem.year))
                    || holidayCalendarItem.centers.length === 0 || holidayCalendarItem.days.length === 0"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button class="btn theme-btn" type="button"
                    *ngIf="screenTemplateLayout.action === constants.VIEW"
                    (click)="goToEdit()"
                    [activityDisplay]="'HolidayCalendarUpdate'">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
<ng-template let-item #holidayTemplate>
              <span>{{item?.description}} : {{
                  item?.type === 'Fixed' ?
                      (item?.monthOfYear + '/' + item?.dayOfMonth | dateMMddFormatPipe) :
                      (item?.monthOfYear + '/' + item?.dayOfMonth + '/' + item?.year | dateMMddyyyyFormatPipe)
                  }}
              </span>
</ng-template>
