<div class="container-fluid capacity-planner">
    <div class="d-flex justify-content-between align-items-center">
        <div class="name-title">
            <h3 translate="{{requestTableFilters.resourceType.label}}" class="d-inline-block"></h3>
        </div>
        <!-- ============================================================== -->
        <!-- Calendar, Planned Capacity, Center, Resource, Speciality Filters -->
        <!-- ============================================================== -->
        <div class="filter-buttons">
            <div class="export-btn btn p-0 h-100" *ngIf="requestTableFilters.resourceType.value===roomVal"
                 [activityDisplay]="['GenerateExportJobPlannedCapacityForRooms']">
                <i class="fas fa-file-export btn theme-btn min-width-auto h-100" (click)="openExportDataModel()" placement="left"
                   ngbTooltip="{{'label.export'| translate}}"></i>
            </div>
            <!-- ============================================================== -->
            <!-- Calendar Filter -->
            <!-- ============================================================== -->
            <div class="calendar-component view-calendar d-flex align-items-center">
                <form class="form-inline">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control text-high-contrast" placeholder="yyyy-mm-dd" name="dp"
                                   [ngClass]="{'is-invalid': !isDateValid}"
                                   [(ngModel)]="requestTableFilters.date"
                                   (ngModelChange)="onDateChange(requestTableFilters.date)"
                                   ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button [class.border-danger]="!isDateValid" class="btn btn-outline-secondary calendar text-high-contrast"
                                        (click)="d.toggle()" type="button">
                                    <i class="icon-calender"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="!isDateValid">
                    <div class="valid-date mt-2" translate="label.error.invalidDate"></div>
                </div>
            </div>

            <div class="h-100">
                <sbase-filter-wrapper
                        [options]="filterWrapperOptions"
                        [updateFiltersValue]="updateFiltersValue"
                        (onFiltersValueChanged)="onFilterWrapperValueChanged($event)">
                </sbase-filter-wrapper>
            </div>
        </div>
    </div>

    <div>
        <div class="form-group custom-tag-input customSelect sbase-input-element" *ngIf="requestTableFilters.resourceType.value===roomVal">
            <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                          [buttonName]='"label.newTag"'
                          [dependentFilters]="mainDependentFilters?.tags"
                          [initialFilterValues]="requestTableFilters?.tags"
                          [isMultipleSelectedItem]="true"
                          [useSelectedValueAsLabel]="true"
                          [hideSelectedItems]="true"
                          [providerInstance]="tagProvider"
                          [ngClass]="{'input-element-has-error': requestTableFilters?.tags?.length <= 0}">
            </sbase-filter>
        </div>
    </div>

    <!-- ============================================================== -->
    <!-- End Calendar, Planned Capacity, Center, Resource, Speciality Filters -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Sort, Page, View, Planned Capacity Filter -->
    <!-- ============================================================== -->
    <div class="sort-side " *ngIf="isTableFiltersValid || !isTableFiltersValid && tagsList.length> 0">
        <!-- ============================================================== -->
        <!-- Sort Filter -->
        <!-- ============================================================== -->
        <div class="btn-group order-button" *ngIf="isTableFiltersValid">
            <span class="text-high-contrast">{{'label.listActions.sorting.sort' | translate}}:</span>
            <span class="btn-group">
        <span class="dropdown-toggle sort-filter"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              (click)="showSortDropdown = !showSortDropdown;"
              [ngClass]="{'show': showSortDropdown}">
              <strong>{{"label.listActions.sorting." + tableFilters.sort | translate}}</strong>
              </span>
            <div [exclude]="'.sort-filter'" (clickOutside)=onClickedOutsideSortFilter($event)
                 [ngClass]="{'show': showSortDropdown}" class="dropdown-menu" x-placement="top-start"
                 x-placement="bottom-start">
                <a class="dropdown-item" translate=""
                   (click)="onOrderList(ASCENDING)">{{"label.listActions.sorting.ascending"| translate}}</a>
                <a class="dropdown-item"
                   (click)="onOrderList(DESCENDING)">{{"label.listActions.sorting.descending"| translate}}</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Sort Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page Filter -->
        <!-- ============================================================== -->
        <div class="btn-group" *ngIf="isTableFiltersValid">
            <span class="text-high-contrast">{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group">
        <span class="dropdown-toggle page-filter"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              (click)="showPagesDropdown = !showPagesDropdown;"
              [ngClass]="{'show': showPagesDropdown}">
        <strong>{{tableFilters.itemsPerPage}}</strong>
      </span>
            <div [exclude]="'.page-filter'" (clickOutside)=onClickedOutsideItemsPerPageFilter($event)
                 [ngClass]="{'show': showPagesDropdown}" class="dropdown-menu" x-placement="top-start"
                 x-placement="bottom-start">
                <a class="dropdown-item" (click)="changeNumberOfItemsPerPage(5)">5</a>
                <a class="dropdown-item" (click)="changeNumberOfItemsPerPage(10)">10</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- View Filter -->
        <!-- ============================================================== -->
        <div class="btn-group btn-filter" *ngIf="isTableFiltersValid">
            <span class="text-high-contrast">{{'label.listActions.displayMode.view' | translate}}:</span>
            <span class="btn-group">
        <span class="dropdown-toggle view-filter"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              (click)="showViewsDropdown = !showViewsDropdown;"
              [ngClass]="{'show': showViewsDropdown}">
        <strong>{{"label.listActions.displayMode." + tableFilters.view | translate}}</strong>
      </span>
            <div [exclude]="'.view-filter'" (clickOutside)=onClickedOutsideViewFilter($event)
                 [ngClass]="{'show': showViewsDropdown}" class="dropdown-menu" x-placement="top-start"
                 class="dropdown-menu" x-placement="bottom-start">
                <a class="dropdown-item" (click)="onSelectViewFilter(WEEKLY)"
                   translate="label.listActions.displayMode.weekly"></a>
                <a class="dropdown-item" (click)="onSelectViewFilter(MONTHLY)"
                   translate="label.listActions.displayMode.monthly"></a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End View Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Planner Capacity Filter -->
        <!-- ============================================================== -->
        <div class="btn-group view-planned-capacity" *ngIf="isTableFiltersValid">
            <span class="text-high-contrast">{{'label.listActions.plannedCapacity.title' | translate}}:</span>
            <span class="btn-group">
        <span class="dropdown-toggle plannedCapacity-filter"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              (click)="showPlannedCapacityDropdown = !showPlannedCapacityDropdown"
              [ngClass]="{'show': showPlannedCapacityDropdown}">
<!--        <strong>{{"label.listActions.sorting." + tableFilters.sort | translate}}</strong>-->
        <strong>{{"label.listActions.plannedCapacity." + tableFilters.plannedCapacity.name | translate}}</strong>
      </span>
            <div [exclude]="'.plannedCapacity-filter'" (clickOutside)=onClickedOutsidePlannedCapacityFilter($event)
                 [ngClass]="{'show': showPlannedCapacityDropdown}" class="dropdown-menu" x-placement="top-start"
                 x-placement="bottom-start">
                <a class="dropdown-item" *ngFor="let item of plannedCapacitiesFiltersValues"
                   (click)="onPlannedCapacity(item)">
                    <span
                            [ngClass]="capacityPlannerUtils.getCssClassForDailyCapacity(item.minValue? item.minValue : item.maxValue, plannedCapacitiesFiltersValues)"></span> {{"label.listActions.plannedCapacity." + item.name|translate}}
                    <span *ngIf="item.name !== 'all'">%</span>
                </a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Planned Capacity Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Legend  -->
        <!-- ============================================================== -->
        <div class="btn-group legend" *ngIf="isTableFiltersValid">
            <ng-template #infoPopoverTemplate>
                <div class="legend-item">
                    <span class="color color-box label-ok"></span>
                    <span class="color" translate="label.listActions.plannedCapacity.ok"></span>
                </div>
                <div class="legend-item">
                    <span class="color color-box label-warning"></span>
                    <span class="color" translate="label.listActions.plannedCapacity.warning"></span>
                </div>
                <div class="legend-item">
                    <span class="color color-box label-bad"></span>
                    <span class="color" translate="label.listActions.plannedCapacity.bad"></span>
                </div>
                <div class="legend-item">
                    <span class="color-box label-ok">
                        <span class="dashes">--</span>
                    </span>
                    <span class="color" translate="label.noResult"></span>
                </div>
            </ng-template>
            <span placement="bottom" [ngbPopover]="infoPopoverTemplate"
                  popoverTitle="{{'reception.resourceSlotsOverview.legend' | translate}}">
        <span class="info-icon">
          <img src="../../../assets/images/icon/info-icon.png" class="info-icon"/>
        </span>
            </span>
            <!-- ============================================================== -->
            <!-- End Legend  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Legend -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Sort, Page, View, Planned Capacity Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Tags Component -->
        <!-- ============================================================== -->
        <sbase-tags *ngIf="isTableFiltersValid"
                    (onDeleteTagOutput)="onDeleteTag($event)" [tagsList]="tagsList"></sbase-tags>
        <!-- ============================================================== -->
        <!-- End Tags Component -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Calendar -->
        <!-- ============================================================== -->
        <div *ngIf="isTableFiltersValid" class="row" id="content-table">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="m-t-20 no-wrap" [ngClass]="{'hidden-scroll': tableFilters.view === WEEKLY}">
                            <table class="table vm no-th-brd pro-of-month">
                                <thead>
                                <tr>
                                    <!-- ============================================================== -->
                                    <!-- Search Button -->
                                    <!-- ============================================================== -->
                                    <th colspan="2" class="search-button">
                                        <input type="text" placeholder='{{"label.listActions.search" | translate}}'
                                               (ngModelChange)="onChangeResource($event)"
                                               [(ngModel)]='tableFilters.resourceName'>
                                        <i class="fas fa-search"></i>
                                    </th>
                                    <!-- ============================================================== -->
                                    <!-- End Search Button -->
                                    <!-- ============================================================== -->
                                    <!-- ============================================================== -->
                                    <!-- Display header of the calendar -->
                                    <!-- ============================================================== -->
                                    <th colspan="31" class="calendar-page">
                                        <div class="row">
                                            <div class="col-md-1"
                                                 (click)="onPrevOrNextDaysPage(requestTableFilters.date, PREVIOUS, tableFilters.view)">
                                                <i class="mdi mdi-arrow-left"></i>
                                            </div>
                                            <div class="col-md-10 month">
                                                {{dateHeaderCalendar}}
                                            </div>
                                            <div class="col-md-1"
                                                 (click)="onPrevOrNextDaysPage(requestTableFilters.date, NEXT, tableFilters.view)">
                                                <i class="mdi mdi-arrow-right"></i>
                                            </div>
                                        </div>
                                    </th>
                                    <!-- ============================================================== -->
                                    <!-- End Display header of the calendar -->
                                    <!-- ============================================================== -->
                                    <br *ngIf="tableFilters.view === MONTHLY">
                                    <!-- ============================================================== -->
                                    <!-- Weekly Table Header -->
                                    <!-- ============================================================== -->
                                <tr class='table-weekly ' *ngIf="tableFilters.view === WEEKLY">
                                    <th colspan="2"></th>
                                    <th *ngFor="let day of dateCapacityObjectArray">
                                        {{day.dayName.charAt(0).toUpperCase() + day.dayName.substring(1, 3)}}
                                    </th>
                                    <th translate="label.date.total"></th>
                                </tr>
                                <tr class='table-weekly ' *ngIf="tableFilters.view === WEEKLY">
                                    <th colspan="2"></th>
                                    <th *ngFor="let day of dateCapacityObjectArray">
                                        {{day.onlyDay}}
                                    </th>
                                </tr>

                                <!-- ============================================================== -->
                                <!-- End Weekly Table Header -->
                                <!-- ============================================================== -->
                                <!-- ============================================================== -->
                                <!-- Monthly Table Header -->
                                <!-- ============================================================== -->
                                <tr class='table-monthly' *ngIf="tableFilters.view === MONTHLY">
                                    <th colspan="2"></th>
                                    <th class="header-table-grid" *ngFor="let day of dateCapacityObjectArray">
                                        {{day.dayName.charAt(0).toUpperCase()}}
                                    </th>
                                </tr>
                                <tr class='table-monthly' *ngIf="tableFilters.view === MONTHLY">
                                    <th colspan="2"></th>
                                    <th class="header-table-grid" *ngFor="let day of dateCapacityObjectArray">
                                        {{day.onlyDay}}
                                    </th>
                                </tr>
                                <!-- ============================================================== -->
                                <!-- End Monthly Table Header  -->
                                <!-- ============================================================== -->
                                </thead>
                                <!-- ============================================================== -->
                                <!-- Monthly Calendar -->
                                <!-- ============================================================== -->
                                <tbody class='table-monthly' *ngIf="tableFilters.view === MONTHLY">
                                <tr *ngFor="let item of displayedItemsPerCurrentPage(displayedItems, tableFilters.itemsPerPage, page)">
                                    <!-- ============================================================== -->
                                    <!-- Username -->
                                    <!-- ============================================================== -->
                                    <td class="user-profile"><span class="round">{{item.resourceName.charAt(0)}}</span>
                                    </td>
                                    <td>
                                        <h6>{{item.resourceName}}</h6>
                                        <!--                    <small class="text-muted">Speciality</small>-->
                                    </td>
                                    <!-- ============================================================== -->
                                    <!-- End Username -->
                                    <!-- ============================================================== -->
                                    <!-- ============================================================== -->
                                    <!-- Monthly Capacity Planner -->
                                    <!-- ============================================================== -->
                                    <td class="table-grid" *ngFor="let day of item.rangeDays">
                                        <div class="label label-rounded"
                                             [ngClass]="capacityPlannerUtils.getCssClassForDailyCapacity(day.plannedCapacity, plannedCapacitiesFiltersValues)">
                                            <span *ngIf='day.plannedCapacity !== null'>{{day.plannedCapacity}}%</span>
                                            <span *ngIf="day.plannedCapacity === null">--</span>
                                        </div>
                                    </td>
                                    <!-- ============================================================== -->
                                    <!-- End Monthly Capacity Planner -->
                                    <!-- ============================================================== -->
                                </tr>
                                <tr class="message" *ngIf="displayedItems.length === 0" translate="label.noResult"></tr>
                                </tbody>
                                <!-- ============================================================== -->
                                <!-- End Monthly Calendar -->
                                <!-- ============================================================== -->
                                <!-- ============================================================== -->
                                <!-- Weekly Calendar -->
                                <!-- ============================================================== -->
                                <tbody class='table-weekly' *ngIf="tableFilters.view === WEEKLY">
                                <tr *ngFor="let item of displayedItemsPerCurrentPage(displayedItems, tableFilters.itemsPerPage, page)">
                                    <!-- ============================================================== -->
                                    <!-- Username -->
                                    <!-- ============================================================== -->
                                    <td class="user-profile">
                                        <span class="round">{{item.resourceName.charAt(0)}}</span>
                                    </td>
                                    <td class="user-name">
                                        <h6 class="text-high-contrast">{{item.resourceName}}</h6>
                                        <!--                    <small class="text-muted">Speciality</small>-->
                                    </td>
                                    <!-- ============================================================== -->
                                    <!-- End Username -->
                                    <!-- ============================================================== -->
                                    <!-- ============================================================== -->
                                    <!-- Weekly Capacity Planner -->
                                    <!-- ============================================================== -->
                                    <td class='table-grid' *ngFor="let day of item.rangeDays">
                                            <span class="label label-rounded"
                                                  [ngClass]="capacityPlannerUtils.getCssClassForDailyCapacity(day.plannedCapacity, plannedCapacitiesFiltersValues)">
                        <span *ngIf='day.plannedCapacity !== null'>{{day.plannedCapacity}}%</span>
                                            <span *ngIf="day.plannedCapacity === null">--</span>
                                            </span>
                                    </td>
                                    <td class='table-grid'>
                                            <span class="label label-rounded"
                                                  [ngClass]="capacityPlannerUtils.getCssClassForDailyCapacity(item.overallPlannedCapacity, plannedCapacitiesFiltersValues)">
                      {{item.overallPlannedCapacity}}%
                    </span>
                                    </td>
                                    <!-- ============================================================== -->
                                    <!-- End Weekly Capacity Planner -->
                                    <!-- ============================================================== -->
                                </tr>
                                <!-- ============================================================== -->
                                <!-- Display this message if there are no data to show in the table -->
                                <!-- ============================================================== -->
                                <tr class="message" *ngIf="displayedItems.length === 0" translate="label.noResult"></tr>
                                <!-- ============================================================== -->
                                <!--  End Display this message if there are no data to show in the table -->
                                <!-- ============================================================== -->
                                <!-- ============================================================== -->
                                <!-- End Weekly Calendar -->
                                <!-- ============================================================== -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="pagination-component font-weight-high-contrast" *ngIf="displayedItems.length > 0">
                        <ngb-pagination [collectionSize]="displayedItems.length" [(page)]="page"
                                        [pageSize]="tableFilters.itemsPerPage" [maxSize]=5
                                        [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Calendar -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- Display this message if the mandatory filters do not fill -->
    <!-- ============================================================== -->
    <div class="message"
         *ngIf="!isTableFiltersValid && requestTableFilters.resourceType&& requestTableFilters.resourceType.value===resourceVal"
         translate="label.error.validSelectedCenterAndResource"></div>
    <div class="message"
         *ngIf="!isTableFiltersValid && requestTableFilters.resourceType && requestTableFilters.resourceType.value===roomVal"
         translate="label.error.invalidCapacityPlannerFilters"></div>
    <!-- ============================================================== -->
    <!-- End Display this message if the mandatory filters do not fill -->
    <!-- ============================================================== -->
</div>
