import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    MultiAppointmentTemplateType,
    MultiAppointmentTemplateProvider,
    ServiceType,
    MultiAppointmentTemplateItemTypes,
    ServiceDependentFiltersType,
    ServiceProvider,
    SubServiceProvider,
    SubServiceType,
    SubServiceDependentFiltersType,
    SpecialityProvider,
    SpecialityType, ODataQueryObjectType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MultiAppointmentTemplateUtils} from '../multi-appointment-template-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {forkJoin} from 'rxjs';
import {AbstractControl} from '@angular/forms';
import {CacheService} from '../../../shared/services/cache.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-multi-appointment-template',
    templateUrl: './create-multi-appointment-template.component.html',
    styleUrls: ['./create-multi-appointment-template.component.scss']
})
export class CreateMultiAppointmentTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    CONSTANTS = constants;
    totalStepsCount: number;
    initialMultiAppointmentTemplate: MultiAppointmentTemplateType;
    multiAppointmentTemplateItem: MultiAppointmentTemplateType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    showItemDataEditSection = false;
    scopedItem = this.multiAppointmentTemplateUtils.getMultiAppointmentTemplateItem();
    mainDependentFilters: {
        service: ServiceDependentFiltersType,
        subService: SubServiceDependentFiltersType
    };
    exclusionLists: {
        service: string[],
        subService: string[]
    } = {
        service: [],
        subService: []
    };

    constructor(
        public serviceProvider: ServiceProvider,
        public subServiceProvider: SubServiceProvider,
        public generalUtils: GeneralUtils,
        private specialityProvider: SpecialityProvider,
        private cacheService: CacheService,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private multiAppointmentTemplateUtils: MultiAppointmentTemplateUtils,
        private multiAppointmentTemplateProvider: MultiAppointmentTemplateProvider,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.stepper._steps.length;
        // we need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
        });
        if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
            // set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.stepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
    }

    ngOnDestroy(): void {
    }

    onStepHeaderClick(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean) {
        return (stepClicked: number) => {
            // Click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
            // user just clicked on stepClicked and the move was successful
            const selectedIndex = stepper?.selectedIndex;
            this.navigateFromStepToStep(stepper, areStepsValid, selectedIndex, stepClicked);
        };
    }

    navigateFromStepToStep(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean, selectedIndex: number, clickedIndex: number) {
        // Display error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!areStepsValid(step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    stepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    //BACK button
    stepperGoBack(stepper: MatStepper) {
        stepper.previous();
    }

    //NEXT button
    stepperGoForward(stepper: MatStepper) {
        stepper.next();
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    shouldDisplayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isGeneralStepValid();
            case 1:
                return this.isServiceStepValid();
            default:
                return true; // other steps which don't need validation
        }
    }

    isGeneralStepValid(): boolean {
        return !!this.multiAppointmentTemplateItem.name;
    }

    isServiceStepValid(): boolean {
        return !!this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.length && !this.showItemDataEditSection;
    }

    isEditItemSectionValid(): boolean {
        return !!(this.scopedItem.serviceId && this.isTimeValid(this.scopedItem.timeBefore) && this.isTimeValid(this.scopedItem.timePost)
            && (!!(this.scopedItem.service?.hasSubServices && this.scopedItem.subServices?.length) || !this.scopedItem.service?.hasSubServices));
    }

    isTimeValid(time: number): boolean {
        return !!(!this.generalUtils.isNullOrUndefined(time) && time >= 0 && time % 1 === 0);
    }

    // used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    openNewOrEditItemSection(selectedItem: MultiAppointmentTemplateItemTypes) {
        this.showItemDataEditSection = true;
        this.exclusionLists.subService = [];
        if (!!selectedItem) {
            this.scopedItem = lodash.cloneDeep(selectedItem);
            const EXCLUSION_LIST_SERVICE_INDEX =
                this.exclusionLists.service.findIndex(serviceId => this.scopedItem.serviceId === serviceId);
            this.exclusionLists.service.splice(EXCLUSION_LIST_SERVICE_INDEX, 1);
            this.mainDependentFilters.subService.serviceId = this.scopedItem.serviceId;
            this.exclusionLists.subService = selectedItem.subServices.map(subService => subService.id);
        }
    }

    onSelectedService([selectedService]: ServiceType[]) {
        if (!selectedService) {
            this.scopedItem.subServices = [];
        }
        this.scopedItem.service = selectedService;
        if (!!selectedService) {
            this.scopedItem.service.speciality = {
                id: selectedService.specialityId,
                name: (selectedService as any).specialityName
            } as SpecialityType;
        }
        this.scopedItem.serviceId = selectedService?.id;
        this.mainDependentFilters.subService.serviceId = this.scopedItem.serviceId;
    }

    onSelectedSubService(selectedSubServiceList: SubServiceType[]) {
        this.scopedItem.subServices = selectedSubServiceList;
        this.exclusionLists.subService = selectedSubServiceList.map(subService => subService.id);
    }

    onSaveItem() {
        if (!this.generalUtils.isNullOrUndefined(this.scopedItem.position)) {
            this.multiAppointmentTemplateItem.multiAppointmentTemplateItems[this.scopedItem.position - 1] = this.scopedItem;
        } else {
            this.scopedItem.position = this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.length + 1;
            this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.push(this.scopedItem);
        }
        this.exclusionLists.service = this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.map(item => item.serviceId);
        this.showItemDataEditSection = false;
        this.scopedItem = this.multiAppointmentTemplateUtils.getMultiAppointmentTemplateItem();
    }

    onCancelItem() {
        this.exclusionLists.service = this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.map(item => item.serviceId);
        this.showItemDataEditSection = false;
        this.scopedItem = this.multiAppointmentTemplateUtils.getMultiAppointmentTemplateItem();
    }

    deleteItem(index: number) {
        const EXCLUSION_LIST_SERVICE_INDEX = this.exclusionLists.service
            .findIndex(serviceId => this.multiAppointmentTemplateItem.multiAppointmentTemplateItems[index].serviceId === serviceId);
        this.exclusionLists.service.splice(EXCLUSION_LIST_SERVICE_INDEX, 1);
        this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.splice(index, 1);
        this.multiAppointmentTemplateUtils.updatePosition(this.multiAppointmentTemplateItem.multiAppointmentTemplateItems);
    }

    dropTemplate(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.multiAppointmentTemplateItem.multiAppointmentTemplateItems, event.previousIndex, event.currentIndex);
        this.multiAppointmentTemplateUtils.updatePosition(this.multiAppointmentTemplateItem.multiAppointmentTemplateItems);
    }

    saveMultiAppointmentTemplateData(multiAppointmentTemplate: MultiAppointmentTemplateType) {
        if (this.screenTemplateLayout.action === constants.CREATE) {
            this.createMultiAppointmentTemplate(multiAppointmentTemplate);
        } else if (this.screenTemplateLayout.action === constants.EDIT) {
            if (lodash.isEqual(this.initialMultiAppointmentTemplate, multiAppointmentTemplate)) {
                this.messagesService.success('toastr.success.multiAppointmentTemplateEdited', true);
                this.goToParentPage();
            } else {
                this.editMultiAppointmentTemplate(this.initialMultiAppointmentTemplate, multiAppointmentTemplate);
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/multiAppointmentsTemplate']);
    }

    goToEdit() {
        history.replaceState({
            multiAppointmentTemplate: this.multiAppointmentTemplateItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    // function to create the new MultiAppointmentTemplate
    private createMultiAppointmentTemplate(multiAppointmentTemplate: MultiAppointmentTemplateType) {
        this.ngxLoader.start();
        this.multiAppointmentTemplateProvider.addEntry(multiAppointmentTemplate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.multiAppointmentTemplateCreated', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the MultiAppointmentTemplate
    private editMultiAppointmentTemplate(
        oldMultiAppointmentTemplate: MultiAppointmentTemplateType, newMultiAppointmentTemplate: MultiAppointmentTemplateType
    ) {
        this.ngxLoader.start();
        this.multiAppointmentTemplateProvider.updateEntry(oldMultiAppointmentTemplate, newMultiAppointmentTemplate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.multiAppointmentTemplateEdited', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            service: this.multiAppointmentTemplateUtils.getServiceDependentFilters(),
            subService: this.multiAppointmentTemplateUtils.getSubServiceDependentFilters()
        };
        if (history.state && history.state.multiAppointmentTemplate) {
            this.initialMultiAppointmentTemplate = history.state.multiAppointmentTemplate;
            this.multiAppointmentTemplateItem = lodash.cloneDeep(history.state.multiAppointmentTemplate);
            this.exclusionLists.service = this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.map(item => item.serviceId);
            this.loadSpecialityNames();
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.multiAppointmentTemplateItem = this.multiAppointmentTemplateUtils.getInitialMultiAppointmentTemplate();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private loadSpecialityNames() {
        const SPECIALITY_IDS: string[] = [];
        const queryFilter: ODataQueryObjectType = this.multiAppointmentTemplateUtils.getQueryFilterForSpeciality();
        this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.forEach(item => {
            if (!!item.service?.specialityId) {
                SPECIALITY_IDS.push(item.service.specialityId);
            }
        });
        if (!!SPECIALITY_IDS.length) {
            this.ngxLoader.start();
            forkJoin(SPECIALITY_IDS.map(specialityId =>
                this.cacheService.getDataById('Specialities', this.specialityProvider, queryFilter, specialityId)))
                .subscribe((specialities: SpecialityType[]) => {
                    this.multiAppointmentTemplateItem.multiAppointmentTemplateItems.forEach(item => {
                        item.service.speciality = specialities.find(specialityFromList => specialityFromList.id === item.service.specialityId);
                    });
                    this.ngxLoader.stop();
                }, err => {
                    this.messagesService.handlingErrorMessage(err);
                    this.ngxLoader.stop();
                    this.goToParentPage();
                });
        }
    }
}
