import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {
    OutsideAvailabilityAppointmentModalNgModelType,
    OutsideAvailabilityAppointmentModalOptionsType,
    OutsideAvailabilityAppointmentModalResponseType
} from '../slots-management.types';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {Moment} from 'moment';
import * as moment from 'moment';
import * as lodash from 'lodash';

@Component({
    selector: 'app-outside-availability-booking-modal',
    templateUrl: './outside-availability-booking-modal.component.html',
    styleUrls: ['./outside-availability-booking-modal.component.scss']
})
export class OutsideAvailabilityBookingModalComponent implements OnInit {
    @Input() options: OutsideAvailabilityAppointmentModalOptionsType;
    ngModels: OutsideAvailabilityAppointmentModalNgModelType;
    isDataValid = false;
    isSelectedDateTimeAfterNow = false;
    nowInCenterTimezone: Moment;

    constructor(public activeModal: NgbActiveModal,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.validateNgModels();
    }

    onOutsideAvailabilityBooking(): void {
        this.validateNgModels();
        if (!this.isDataValid) {
            return;
        }
        const timeNgbDate: NgbTimeStruct = this.dateTimeUtils.hourMinuteStringToNgbTimeStruct(this.ngModels.time);
        const dateMoment: Moment = this.dateTimeUtils.getMomentFromNgbDate(this.ngModels.date);
        const dateWithTimeMoment: Moment = moment.tz(
            `${dateMoment.format('YYYY-MM-DD')}T${timeNgbDate.hour}:${timeNgbDate.minute}:00`,
            'YYYY-MM-DDTHH:mm:ss',
            this.options.centerTimeZoneId
        );
        const dateWithTimeMomentInCenterTimezone: string =
            moment.tz(dateWithTimeMoment, this.options.centerTimeZoneId).format('YYYY-MM-DDTHH:mm:ssZ');
        const response: OutsideAvailabilityAppointmentModalResponseType = {
            dateTime: dateWithTimeMomentInCenterTimezone,
            duration: this.ngModels.duration
        } as OutsideAvailabilityAppointmentModalResponseType;
        this.activeModal.close(response);
    }

    isLocalTimezoneSameAsCenterTimezone(): boolean {
        return this.options.centerTimeZoneId === moment.tz.guess();
    }

    validateNgModels(): void {
        this.updateIsSelectedDateTimeAfterNow();
        this.isDataValid = !!this.ngModels.date && !!this.ngModels.time && !!this.ngModels.duration
            && this.isSelectedDateTimeAfterNow;
    }
    private updateIsSelectedDateTimeAfterNow(): void {
        this.nowInCenterTimezone = moment().tz(this.options.centerTimeZoneId);
        const timeNgbDate: NgbTimeStruct = this.dateTimeUtils.hourMinuteStringToNgbTimeStruct(this.ngModels.time);
        const dateMoment: Moment = this.dateTimeUtils.getMomentFromNgbDate(this.ngModels.date);
        const dateWithTimeMoment: Moment = moment.tz(
            `${dateMoment.format('YYYY-MM-DD')}T${timeNgbDate.hour}:${timeNgbDate.minute}:00`,
            'YYYY-MM-DDTHH:mm:ss',
            this.options.centerTimeZoneId
        );
        this.isSelectedDateTimeAfterNow = dateWithTimeMoment.isAfter(this.nowInCenterTimezone);
    }

    private loadInitialValues(): void {
        this.nowInCenterTimezone = moment().tz(this.options.centerTimeZoneId);
        const after5Minutes = this.nowInCenterTimezone.add(5, 'minutes');
        this.ngModels = {
            date: {year: after5Minutes.year(), month: after5Minutes.month() + 1, day: after5Minutes.date()} as NgbDateStruct,
            time: `${after5Minutes.hours()}:${after5Minutes.minutes()}`,
            duration: this.options.serviceDuration
        } as OutsideAvailabilityAppointmentModalNgModelType;
    }
}
