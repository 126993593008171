import {Injectable} from '@angular/core';
import {
    SwapResourcesAppointmentListRequestFilters
} from './resource-swap-appointment-list.types';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {AppointmentStatusEnum} from 'sked-base';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {
    SwapResourcesFiltersSearchType
} from '../resource-swap-filters/resource-swap-filters.types';

@Injectable({
    providedIn: 'root'
})
export class ResourceSwapAppointmentListUtils {

    constructor(private generalUtils: GeneralUtils,
                private dateTimeUtils: DateTimeUtils) {
    }

    getAppointmentQueryFilter(swapResourcesAppointmentListRequestFilters: SwapResourcesAppointmentListRequestFilters) {
        return {
            count: true,
            skip: (swapResourcesAppointmentListRequestFilters.pageFilters.currentPage - 1) * swapResourcesAppointmentListRequestFilters.pageFilters.itemsPerPage,
            top: swapResourcesAppointmentListRequestFilters.pageFilters.itemsPerPage,
            filter: this.getAppointmentFiltersQuery(swapResourcesAppointmentListRequestFilters.searchFilters),
            expand: this.getExpandQuery(),
            orderBy: 'DateTimeFrom asc'
        };
    }

    private getAppointmentFiltersQuery(searchFilters: SwapResourcesFiltersSearchType) {
        const and: any[] = [];
        if (searchFilters?.resource?.id) {
            and.push({ ResourceAppointments: { any: { Resource: { Id: {eq: {type: 'guid', value: searchFilters.resource.id}} } } } });
        }
        if (searchFilters?.location?.id) {
            and.push({CenterId: {eq: {type: 'guid', value: searchFilters.location.id}}});
        }
        // Appointments don't have the AreaId field. We need to search by service/AreaId
        if (searchFilters && searchFilters?.area?.id) {
            and.push({'Service/AreaId': {eq: {type: 'guid', value: searchFilters.area.id}}});
        }
        if (searchFilters?.service?.id) {
            and.push({ServiceId: {eq: {type: 'guid', value: searchFilters.service.id}}});
        }
        if (!!searchFilters?.status) {
            if (searchFilters.status === Object.keys(AppointmentStatusEnum)[2]) {
                and.push({Cancelled: true});
            }
            and.push({Status: {eq: searchFilters.status}});
        }
        if (searchFilters.dateFrom) {
            const momentDateFrom = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dateFrom);
            and.push({
                DateTimeFrom: {
                    ge: {
                        type: 'raw',
                        value: momentDateFrom.startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        if (!!searchFilters?.dateTo) {
            const momentDateTo = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dateTo).add(1, 'days');
            and.push({
                DateTimeTo: {
                    lt: {
                        type: 'raw',
                        value: momentDateTo.startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        return and;
    }

    private getExpandQuery() {
        return {
            AppointmentType: {select: ['Name']},
            Patient: {
                select: ['FullName', 'Email', 'MainPhoneNumber', 'AlternatePhoneNumber', 'DocumentNumberFormatted',
                    'DocumentNumber', 'DocumentType', 'DocumentCountry', 'RowVersion']
            },
            Resource: {select: ['Name']},
            ResourceAppointments: {
                select: ['MainResource'],
                expand: {Resource: {select: ['Id', 'Name', 'ResourceTypeId']}}
            },
            Service: {
                select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices'],
                expand: {Speciality: {select: ['Id', 'Name']}}
            },
            Center: {select: ['Name']},
            CoveragePlan: {select: ['Id', 'Name']},
        };
    }
}
