<div class="container-fluid time-window-for-channel-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.timeWindowTab.timeWindowOverviewTab.title"></h3>
        <div class="filters-section d-flex no-block">
            <sbase-filter (selectedItemsOutput)=" onSelectedChannelFilter($event)"
                          [buttonName]="'label.timeWindowTab.channel' | translate"
                          [dependentFilters]="channelDependentFilters"
                          [hideSelectedItems]="true"
                          [initialFilterValues]="timeWindowMdUtils.initialChannelValues"
                          [isMultipleSelectedItem]="false"
                          [providerInstance]="channelProvider"
                          [useSelectedValueAsLabel]="true">
            </sbase-filter>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between mt-3">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(timeWindowMdUtils.tableFilters.orderBy)">
                    <span
                        *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + timeWindowMdUtils.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <div class="channel-filter-container mr-2 d-flex flex-row justify-content-end">
            <button type="button" class="btn theme-alt-btn border-0 ml-1"
                    [activityDisplay]="'Key'+TIME_WINDOW_FOR_CHANNEL+CONSTANTS.CREATE+'Activity'"
                    (click)="createTimeWindow()">{{'label.createNew'|translate}}
            </button>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('channel')">
                    {{'label.timeWindowTab.channel' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!timeWindowMdUtils.tableFilters.orderBy.channel"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="timeWindowMdUtils.tableFilters.orderBy.channel === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="timeWindowMdUtils.tableFilters.orderBy.channel === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('days')">
                    {{'label.timeWindowTab.days' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!timeWindowMdUtils.tableFilters.orderBy.days"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="timeWindowMdUtils.tableFilters.orderBy.days === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="timeWindowMdUtils.tableFilters.orderBy.days === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let timeWindow of timeWindowMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewTimeWindow(timeWindow)"
            >
                <td class="name-column">{{'enum.channel.' + timeWindow.channel | translate}}</td>
                <td class="name-column">{{timeWindow.days}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewTimeWindow(timeWindow)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        [activityDisplay]="'Key'+TIME_WINDOW_FOR_CHANNEL+CONSTANTS.UPDATE+'Activity'"
                                        (click)="editTimeWindow(timeWindow)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        [activityDisplay]="'Key'+TIME_WINDOW_FOR_CHANNEL+CONSTANTS.DELETE+'Activity'"
                                        (click)="displayConfirmDeleteItemModal(timeWindow.id)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(timeWindow.id, EntityTypeEnum.timeWindow, timeWindow.channel)"
                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="timeWindowMdUtils.tableFilters.currentPage"
                        [maxSize]="5"
                        [pageSize]="timeWindowMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{timeWindowMdUtils.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start" x-placement="bottom-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
