import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {SearchFilterUtils, TagDependentFiltersScopeEnum, TagDependentFiltersType, TagProvider} from 'sked-base';
import * as lodash from 'lodash';
import {TagsType} from 'sked-base/lib/data-model/tagTypes';
import {formControlNames} from '../../../../shared/constants/formControlNames';
import {CreatePatientUtils} from '../create-patient.utils';

@Component({
    selector: 'app-tags-form',
    templateUrl: './tags-form.component.html',
    styleUrls: ['./tags-form.component.scss']
})
export class TagsFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;

    tagsForm: FormGroup;
    allInputs: DynamicFormInputType[] = [];
    tags: TagsType[] = [];
    previousTags: TagsType[] = [];
    allTags: TagsType[] = [];
    readonly tagsLoaderId = 'tagsLoader';
    formControlNames = formControlNames;
    mainDependentFilters: {
        tags: TagDependentFiltersType
    };

    constructor(public tagProvider: TagProvider,
                public dynamicFormInputService: DynamicFormInputService,
                public createPatientUtils: CreatePatientUtils,
                private rootFormGroup: FormGroupDirective,
                private searchFilterUtils: SearchFilterUtils) {
    }

    ngOnInit(): void {
        this.tagsForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.tags = this.tagsForm?.get(formControlNames.TAGS)?.value ?? [];
        this.mainDependentFilters = {
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedPatient, true)
        };
    }

    onSelectedTags(tags: TagsType[]) {
        if (tags?.length >= 0 && !lodash.isEqual(this.previousTags, tags)) {
            this.previousTags = tags;
            this.tagsForm.get('Tags').patchValue(tags);
        }
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }
}
