import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    AppointmentProvider,
    EntityTypeEnum,
    SkedTaskProvider,
    SkedTaskType,
    SkedTaskTypeEnum,
    RequesterSourceEnum
} from 'sked-base';
import {TaskListUtils} from '../task-list-util';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {forkJoin, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {PdfImageViewerOptionsType} from '../../../shared/component/pdf-image-viewer/pdf-image-viewer.types';
import {AppointmentCommentType} from 'sked-base/lib/data-model/appointmentCommentTypes';
import {AppointmentType} from 'sked-base/lib/data-model/appointmentTypes';
import {TaskItemActionEnum, TaskItemAppointmentResourcesType} from '../task-list.types';
import {PatientAppointmentListUtils} from '../../patient-dashboard/patient-appointment-list/patient-appointment-list.utils';
import {AppointmentListUtils} from '../../appointment-list/appointment-list.utils';

@Component({
    selector: 'app-task-item-details',
    templateUrl: './task-item-details.component.html',
    styleUrls: ['./task-item-details.component.scss']
})
export class TaskItemDetailsComponent implements OnInit, AfterViewInit {
    SkedTaskTypeEnum = SkedTaskTypeEnum;
    displayPage = false;
    taskItemAppointmentResources = {} as TaskItemAppointmentResourcesType;
    requesterSourceEnum = RequesterSourceEnum;
    EntityTypeEnum = EntityTypeEnum;

    constructor(public taskListUtils: TaskListUtils,
                private skedTaskProvider: SkedTaskProvider,
                private appointmentProvider: AppointmentProvider,
                private messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                private patientAppointmentListUtils: PatientAppointmentListUtils,
                private appointmentListUtils: AppointmentListUtils) {
    }

    ngOnInit(): void {
        if (this.taskListUtils?.taskItemDetailsOptions?.taskItem?.id) {
            this.loadTaskItemDetails(this.taskListUtils?.taskItemDetailsOptions?.taskItem);
            this.taskListUtils.taskListActionContextRoute = history?.state?.redirectRoute;
        } else { // refresh situation
            this.navigateToParentWithOptions(
                false, false, false
            );
            return;
        }
        setTimeout(() => {
            if (this.taskListUtils.rescheduleOrBookStartLocation === 'taskList') {
                this.taskListUtils.rescheduleOrBookStartLocation = '';
                this.navigateToParentWithOptions(
                    true, true, false
                );
            }
        }, 0);
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    onTaskItemAction(action: TaskItemActionEnum) {
        switch (action) {
            case TaskItemActionEnum.Done: {
                this.navigateToParentWithOptions(
                    true, true, false
                );
                return;
            }
            case TaskItemActionEnum.Reject: {
                this.navigateToParentWithOptions(
                    true, true, false
                );
                return;
            }
            case TaskItemActionEnum.Book: {
                this.taskListUtils.navigateForBooking();
                return;
            }
            case TaskItemActionEnum.Reschedule: {
                this.taskListUtils.navigateForReschedule();
                return;
            }
            case TaskItemActionEnum.Cancel: {
                this.navigateToParentWithOptions(
                    true, true, false
                );
                return;
            }
        }
    }

    closeTaskItemDetails() {
        this.navigateToParentWithOptions(
            false, true, true
        );
    }

    private mapTaskItemAppointmentResources() {
        this.taskItemAppointmentResources.mainResource = this.taskListUtils?.taskItemDetailsOptions?.appointment?.resourceAppointments
            .filter(resource => resource.mainResource)[0];
        this.taskItemAppointmentResources.secondaryResources = this.taskListUtils?.taskItemDetailsOptions?.appointment?.resourceAppointments
            .filter(resource => !resource.mainResource);
    }

    private navigateToParentWithOptions(
        shouldMakeNewRequest, shouldKeepFiltersState, shouldKeepListState
    ) {
        if (this.taskListUtils.taskListActionContextRoute) {
            if (this.taskListUtils.taskListActionContextRoute === '/patientDashboard') {
                // Nothing to prepare here, but we keep this if to show possible places where reschedule / reuse can start
            }
            if (this.taskListUtils.taskListActionContextRoute === '/patientAppointmentList') {
                this.patientAppointmentListUtils.shouldMakeNewRequest = true;
                this.patientAppointmentListUtils.shouldKeepFiltersState = true;
                this.patientAppointmentListUtils.shouldKeepListState = false;
            }
            if (this.taskListUtils.taskListActionContextRoute === '/appointmentList') {
                this.appointmentListUtils.shouldMakeNewRequest = true;
                this.appointmentListUtils.shouldKeepFiltersState = true;
                this.appointmentListUtils.shouldKeepListState = false;
            }
            this.router.navigate([this.taskListUtils.taskListActionContextRoute]);
            return;
        }
        this.taskListUtils.shouldMakeNewRequest = shouldMakeNewRequest;
        this.taskListUtils.shouldKeepFiltersState = shouldKeepFiltersState;
        this.taskListUtils.shouldKeepListState = shouldKeepListState;
        this.router.navigate(['/taskList']);
    }

    private getTaskItemDetailsObservables(id: string, appointmentId: string): Observable<any>[] {
        return [
            this.skedTaskProvider.getById(id, this.taskListUtils.getSkedTaskQueryFilter()),
            appointmentId ? this.appointmentProvider.getComments(appointmentId) : of(null),
            appointmentId ? this.appointmentProvider.getById(appointmentId, this.taskListUtils.getAppointmentQueryFilter()) : of(null)
        ];
    }

    private loadTaskItemDetails({id, appointmentId}: SkedTaskType): void {
        this.ngxLoader.start();
        forkJoin(this.getTaskItemDetailsObservables(id, appointmentId))
            .subscribe(([taskItemDetails, taskItemDetailsAppointmentComments, taskItemDetailsAppointment]:
                            [SkedTaskType, { value: AppointmentCommentType[] }, AppointmentType]) => {
                this.taskListUtils.taskItemDetailsOptions.taskItem = taskItemDetails;
                this.taskListUtils.taskItemDetailsOptions.appointment = taskItemDetailsAppointment;
                this.taskListUtils.taskItemDetailsOptions.appointmentComments = taskItemDetailsAppointmentComments?.value;
                this.taskListUtils.taskItemDetailsOptions.uploadsViewerOptions = {
                    entityType: EntityTypeEnum.skedTask,
                    entityId: taskItemDetails?.id
                } as PdfImageViewerOptionsType;
                if (this.taskListUtils?.taskItemDetailsOptions?.appointment?.resourceAppointments) {
                    this.mapTaskItemAppointmentResources();
                }
                this.ngxLoader.stop();
                if (this.taskListUtils.shouldDoBooking) {
                    this.taskListUtils.rescheduleOrBookStartLocation = 'taskList';
                    this.taskListUtils.shouldDoBooking = false;
                    this.onTaskItemAction(TaskItemActionEnum.Book);
                    return;
                }
                if (this.taskListUtils.shouldDoReschedule) {
                    this.taskListUtils.rescheduleOrBookStartLocation = 'taskList';
                    this.taskListUtils.shouldDoReschedule = false;
                    this.onTaskItemAction(TaskItemActionEnum.Reschedule);
                    return;
                }
                this.displayPage = true;
            }, error => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
                this.displayPage = true;
            });
    }
}
