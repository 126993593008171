import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ObjectDetailRuleProvider,
    ObjectDetailRuleType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    TagProvider,
    AreaDependentFiltersType,
    ServiceDependentFiltersType,
    ResourceDependentFiltersType,
    ChannelDependentFiltersType,
    TagDependentFiltersType,
    ObjectDetailDependentFiltersType,
    TagDependentFiltersScopeEnum,
    SearchFilterUtils,
    ServiceProvider,
    CenterProvider,
    ResourceProvider,
    ChannelProvider,
    ObjectDetailProvider,
    FilterComponentChannelType,
    ChannelEnum,
    ResourceType,
    CenterType,
    ServiceType,
    ObjectDetailType,
    TagType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {ObjectDetailRuleAppliesToEnum, ObjectDetailRuleTagsType} from '../object-detail-rule.types';
import {ObjectDetailRuleUtils} from '../object-detail-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-object-detail-rule',
    templateUrl: './create-object-detail-rule.component.html',
    styleUrls: ['./create-object-detail-rule.component.scss']
})
export class CreateObjectDetailRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: ObjectDetailRuleType = {} as ObjectDetailRuleType;
    initialRule: ObjectDetailRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.ObjectDetailRule);
    appliesToEnumList = Object.values(ObjectDetailRuleAppliesToEnum);
    selectedAppliesToValue: ObjectDetailRuleAppliesToEnum = 'noValue' as ObjectDetailRuleAppliesToEnum;
    objectDetailRuleTags: ObjectDetailRuleTagsType;
    mainDependentFilters: {
        center: AreaDependentFiltersType,
        service: ServiceDependentFiltersType,
        resource: ResourceDependentFiltersType,
        channel: ChannelDependentFiltersType,
        tag: TagDependentFiltersType,
        objectDetail: ObjectDetailDependentFiltersType
    };
    initialChannel: FilterComponentChannelType[];

    constructor(
        public objectDetailRuleUtils: ObjectDetailRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public serviceProvider: ServiceProvider,
        public centerProvider: CenterProvider,
        public resourceProvider: ResourceProvider,
        public channelProvider: ChannelProvider,
        public tagProvider: TagProvider,
        public objectDetailProvider: ObjectDetailProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private objectDetailRuleProvider: ObjectDetailRuleProvider,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: ObjectDetailRuleType): boolean {
        return !!(rule.objectDetailRuleTableId &&
            !lodash.isEmpty(rule.objectDetailRuleTable) && rule.name);
    }

    isInputStepValid(rule: ObjectDetailRuleType): boolean {
        return !!(rule.serviceId || rule.centerId || rule.resourceId || rule.tagId);
    }

    isOutputStepValid(rule: ObjectDetailRuleType): boolean {
        return !!(rule.objectDetailId && (rule.appliesTo.center || rule.appliesTo.resource || rule.appliesTo.service));
    }

    areAllStepsValid(rule: ObjectDetailRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.objectDetailRuleTable = ruleTable[0];
            this.ruleItem.objectDetailRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.objectDetailRuleTable = undefined;
            this.ruleItem.objectDetailRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedChannel(selectedChannelList: FilterComponentChannelType[]): void {
        const IS_CHANNEL_SELECTED = selectedChannelList?.length > 0;
        this.ruleItem.channel = IS_CHANNEL_SELECTED ? selectedChannelList[0].enumValue as ChannelEnum : undefined;
        this.initialChannel = IS_CHANNEL_SELECTED ? [selectedChannelList[0]] : [];
    }

    onSelectedResource(selectedResourceList: ResourceType[]): void {
        this.ruleItem.resource = selectedResourceList?.length > 0 ? selectedResourceList[0] : undefined;
        this.ruleItem.resourceId = this.ruleItem.resource?.id;
    }

    onSelectedCenter(selectedCenterList: CenterType[]): void {
        this.ruleItem.center = selectedCenterList?.length > 0 ? selectedCenterList[0] : undefined;
        this.ruleItem.centerId = this.ruleItem.center?.id;
    }

    onSelectedService(selectedServiceList: ServiceType[]): void {
        this.ruleItem.service = selectedServiceList?.length > 0 ? selectedServiceList[0] : undefined;
        this.ruleItem.serviceId = this.ruleItem.service?.id;
    }

    onSelectedTag(selectedTagList: TagType[]): void {
        this.ruleItem.tag = selectedTagList?.length > 0 ? selectedTagList[0] : undefined;
        this.ruleItem.tagId = this.ruleItem.tag?.id;
    }
    //
    // endregion Input methods

    // region Output methods
    //
    onSelectedObjectDetail(selectedObjectDetailList: ObjectDetailType[]): void {
        this.ruleItem.objectDetail = selectedObjectDetailList?.length > 0 ? selectedObjectDetailList[0] : undefined;
        this.ruleItem.objectDetailId = this.ruleItem.objectDetail?.id;
    }

    onAppliesToChange(optionChosen: ObjectDetailRuleAppliesToEnum) {
        this.ruleItem.appliesTo = {
            resource: false,
            center: false,
            service: false
        };
        if (!optionChosen.hasOwnProperty('$ngOptionValue')) {
            this.ruleItem.appliesTo[optionChosen] = true;
        }
    }

    // endregion Output methods

    // region Overview methods
    //

    saveRule(rule: ObjectDetailRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.objectDetailRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: ObjectDetailRuleType) {
        this.ngxLoader.start();
        const ruleToSend: ObjectDetailRuleType =
            this.objectDetailRuleUtils.mapRuleForServer(rule);
        this.objectDetailRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newObjectDetailRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: ObjectDetailRuleType, newRule: ObjectDetailRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: ObjectDetailRuleType =
            this.objectDetailRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: ObjectDetailRuleType =
            this.objectDetailRuleUtils.mapRuleForServer(newRule);
        this.objectDetailRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.objectDetailRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private loadChannels() {
        this.ngxLoader.start();
        this.channelProvider.getEntries().subscribe(channels => {
            const CHANNEL_FROM_LIST = channels.value.find(channel => channel.enumValue === this.ruleItem.channel);
            this.initialChannel = [{id: CHANNEL_FROM_LIST?.id, enumValue: CHANNEL_FROM_LIST?.enumValue, name: CHANNEL_FROM_LIST?.channel}];
            this.ngxLoader.stop();
        }, error => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(error);
            this.rulesUtils.goToParentPage('createRule');
        });
    }

    private setupInitialState() {
        this.objectDetailRuleTags = this.objectDetailRuleUtils.getInitialObjectDetailRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            // Searches for option in appliesTo object that is set to true and sets the select option accordingly
            this.selectedAppliesToValue = this.objectDetailRuleUtils.getKeyOfAppliesToValue(this.ruleItem.appliesTo) ?? 'noValue' as ObjectDetailRuleAppliesToEnum;
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                // TODO-rules: check if this can be done without loadChannels
                if (!this.generalUtils.isNullOrUndefined(this.ruleItem.channel)) {
                    this.loadChannels();
                }
            }
        } else {
            this.ruleItem = this.objectDetailRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            service: this.objectDetailRuleUtils.getServiceDependentFilters(),
            center: this.objectDetailRuleUtils.getLocationDependentFilters(),
            resource: this.objectDetailRuleUtils.getResourceDependentFilters(),
            channel: this.objectDetailRuleUtils.getChannelDependentFilters(),
            tag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
            objectDetail: this.objectDetailRuleUtils.getObjectDetailDependentFilters(),
        };
    }
}
