import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ChannelDependentFiltersType,
    ChannelProvider,
    ConfirmDeleteModalService,
    EntityTypeEnum,
    TimeWindowProvider,
    TimeWindowType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TimeWindowMdUtils} from './time-window-for-channel-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-time-window-md',
    templateUrl: './time-window-for-channel-md.component.html',
    styleUrls: ['./time-window-for-channel-md.component.scss']
})
export class TimeWindowForChannelMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    timeWindowMdList: TimeWindowType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    readonly TIME_WINDOW_FOR_CHANNEL = this.timeWindowProvider.name;
    readonly CONSTANTS = constants;
    channelDependentFilters: ChannelDependentFiltersType;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchTimeWindowChanged = new Subject<string>();

    constructor(
        public timeWindowMdUtils: TimeWindowMdUtils,
        public timeWindowProvider: TimeWindowProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        private confirmDeleteService: ConfirmDeleteModalService,
        public channelProvider: ChannelProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('KeyTimeWindowReadActivity');
        this.channelDependentFilters = this.timeWindowMdUtils.getChannelDependentFilters();

        if (this.previousRouteService.getPreviousUrl() !== '/createTimeWindowForChannel') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.getTableEntries();
        this.subscribeSearchByTimeWindowChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create TimeWindow
    createTimeWindow(): void {
        this.router.navigate(['/createTimeWindowForChannel']);
    }

    editTimeWindow(timeWindow: TimeWindowType): void {
        this.router.navigate(['/createTimeWindowForChannel'], {state: {timeWindow, action: constants.EDIT}});
    }

    viewTimeWindow(timeWindow: TimeWindowType): void {
        this.router.navigate(['/createTimeWindowForChannel'], {state: {timeWindow, action: constants.VIEW}});
    }

    onSelectedChannelFilter(channelFilterList: any[]): void {
        this.timeWindowMdUtils.initialChannelValues = channelFilterList;
        this.timeWindowMdUtils.tableFilters.filter.channel = (channelFilterList && channelFilterList.length > 0) ? channelFilterList[0] : undefined;
        this.getTableEntries();
    }

    onClickedOutsideItemsPerPageFilter(e: Event): void {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number): void {
        this.timeWindowMdUtils.tableFilters.currentPage = page;
        this.getTableEntries(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number): void {
        this.timeWindowMdUtils.tableFilters.currentPage = 1;
        this.timeWindowMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getTableEntries(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string): void {
        const isAscendingMode = this.timeWindowMdUtils.tableFilters.orderBy[property];
        if (this.timeWindowMdUtils.tableFilters.orderBy) {
            this.timeWindowMdUtils.tableFilters.orderBy = {};
            this.timeWindowMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getTableEntries(false);
    }

    onClearOrderBy(selectedItem): void {
        delete this.timeWindowMdUtils.tableFilters.orderBy[selectedItem];
        this.getTableEntries(false);
    }

    displayConfirmDeleteItemModal(timeWindowId: string): void {
        this.confirmDeleteService.displayConfirmDeleteModal(this.timeWindowProvider, timeWindowId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(timeWindowId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    // function to get TimeWindow data
    private getTableEntries(includeCount: boolean = true): void {
        const queryFilter = this.timeWindowMdUtils.getQueryFilterForTimeWindowMD(this.timeWindowMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.timeWindowProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.timeWindowMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.getTableEntries(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.timeWindowMdUtils.tableFilters = this.timeWindowMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.timeWindowMdUtils.initialChannelValues = [];
        }
    }

    // method for deleting table item
    private deleteItem(id: string): void {
        this.timeWindowMdUtils.tableFilters = this.timeWindowMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.timeWindowProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getTableEntries();
                this.messagesService.success('toastr.success.timeWindowDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByTimeWindowChanged(): void {
        const self = this;
        this.searchTimeWindowChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.timeWindowMdUtils.tableFilters.filter.timeWindow = searchValue;
                self.getTableEntries();
            });
    }
}
