<div *ngIf="coveragePlansForm" [formGroup]="coveragePlansForm" class="form-container coverage-plan-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
    <!-- MAIN COVERAGE PLAN -->
    <div *ngIf="getInputByControlName(formControlNames.MAIN_COVERAGE_PLAN)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.MAIN_COVERAGE_PLAN)?.styles">
        <label translate="label.mainCoveragePlan">
            <span [style.visibility]="getInputByControlName(formControlNames.MAIN_COVERAGE_PLAN)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div class="coverage-plan-filter" [ngClass]="{'disabled-sbaseFilter' : !getInputByControlName(formControlNames.MAIN_COVERAGE_PLAN)?.enabled}">
            <sbase-filter (selectedItemsOutput)="onSelectedMainCoveragePlan($event)"
                          [buttonName]='"label.mainCoveragePlan"'
                          [dependentFilters]="mainDependentFilters?.mainCoveragePlan"
                          [initialFilterValues]="mainInitialValues"
                          [isMultipleSelectedItem]="false"
                          [useSelectedValueAsLabel]="true"
                          [hideSelectedItems]="true"
                          [providerInstance]="coveragePlanProvider"
                          [ngClass]="{'has-error':dynamicFormInputService.hasError(coveragePlansForm, formControlNames.MAIN_COVERAGE_PLAN)}"
                          #mainCoveragePlansSbaseFilter
                          (clickOutside)="mainCoveragePlansSbaseFilter.showDropdown = false"
                          class="sbase-dropdown-ml">
            </sbase-filter>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="coveragePlansForm"
                                      [controlName]="formControlNames.MAIN_COVERAGE_PLAN">
        </app-dynamic-form-input-error>
    </div>

    <!-- SECONDARY COVERAGE PLAN -->
    <div *ngIf="getInputByControlName(formControlNames.SECONDARY_COVERAGE_PLANS)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.SECONDARY_COVERAGE_PLANS)?.styles">
        <label translate="label.secondaryCoveragePlans">
            <span [style.visibility]="getInputByControlName(formControlNames.SECONDARY_COVERAGE_PLANS)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div class="coverage-plan-filter" [ngClass]="{'disabled-sbaseFilter' : !getInputByControlName(formControlNames.MAIN_COVERAGE_PLAN)?.enabled}">
            <sbase-filter (selectedItemsOutput)="onSelectedSecondaryCoveragePlans($event)"
                          [buttonName]='"label.secondaryCoveragePlans"'
                          [dependentFilters]="mainDependentFilters?.secondaryCoveragePlans"
                          [initialFilterValues]="secondaryInitialValues"
                          [isMultipleSelectedItem]="true"
                          [useSelectedValueAsLabel]="true"
                          [hideSelectedItems]="true"
                          [providerInstance]="coveragePlanProvider"
                          [ngClass]="{'has-error':dynamicFormInputService.hasError(coveragePlansForm, formControlNames.SECONDARY_COVERAGE_PLANS)}"
                          #secondaryCoveragePlansSbaseFilter
                          (clickOutside)="secondaryCoveragePlansSbaseFilter.showDropdown = false"
                          class="sbase-dropdown-ml">
            </sbase-filter>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="coveragePlansForm"
                                      [controlName]="formControlNames.SECONDARY_COVERAGE_PLANS">
        </app-dynamic-form-input-error>
    </div>

</div>


