<div class="container-fluid appointment-list-wrapper list-page-wrapper p-0 pb-1">
    <!-- Search filters -->
    <div class="row appointment-list-filters-container ml-0 mr-0 mb-1">
        <app-appointment-list-filters *ngIf="appointmentListUtils.filtersOptions.areFiltersReady"
                                              (search)="onFilterSearch($event)"
        ></app-appointment-list-filters>
    </div>

    <!-- Appointment list item -->
    <div class="appointment-list-results-container ml-0 mr-0 custom-scrollbar">
        <!-- Appointment label, refresh button and export buttons tabs row -->
        <div *ngIf="!appointmentListUtils.appointmentListItemOptions.isBeforeSearchState"
             class="row appointment-list-label-and-filters-row d-flex no-block justify-content-between mx-3 my-3">
            <!-- Appointment label -->
            <span class="appointment-list-label-container" translate="label.appointments"></span>
            <!-- Refresh button -->
            <div class="btn theme-btn float-right refresh-button"
                 ngbPopover="{{'label.refresh'| translate}}"
                 placement="left"
                 popoverClass="popover-shadow"
                 triggers="mouseenter:mouseleave"
                 (click)="refreshData()">
                <i class="fas fa-sync-alt"></i>
            </div>
            <!-- Export buttons -->
            <div>
                <span ngbPopover="{{'label.noAvailableAppointments'| translate}}"
                      [disablePopover]="appointmentListUtils?.appointmentListItemOptions?.appointmentList?.length > 0"
                      placement="bottom"
                      popoverClass="popover-shadow"
                      triggers="mouseenter:mouseleave"
                      [activityDisplay]="'AppointmentExport'">
                    <button class="btn theme-btn mr-2" [disabled]="appointmentListUtils?.appointmentListItemOptions?.appointmentList?.length <= 0" (click)="openExportAppointmentListModal()">
                        {{'label.export' | translate}}<i class="fas fa-file-export ml-2"></i>
                    </button>
                </span>
                <span ngbPopover="{{appointmentListUtils?.appointmentListItemOptions?.appointmentList?.length <= 0 ?
                      ('label.noAvailableAppointments'| translate) :
                      ('label.pleaseSelectAResourceOrCenterAndTheDateInterval'| translate)}}" [disablePopover]="isPrintPdfButtonEnabled"
                      popoverClass="popover-shadow"
                      triggers="mouseenter:mouseleave"
                      [activityDisplay]="'AppointmentListPrintPDF'">
                    <button class="btn theme-btn" [disabled]="!isPrintPdfButtonEnabled" (click)="onGetAppointmentsPdf()">
                        {{'label.printPDF' | translate}}<i class="fa-solid fa-file-pdf ml-2"></i>
                    </button>
                </span>
            </div>
        </div>
        <div *ngIf="appointmentListUtils.appointmentListItemOptions.isBeforeSearchState" class="appointment-list-message text-high-contrast" translate="label.appointmentListNotSearchedYet">
        </div>
        <div *ngIf="appointmentListUtils.appointmentListItemOptions.isNotFoundState" class="appointment-list-message text-high-contrast" translate="label.appointmentListNotFound">
        </div>
        <ng-container *ngIf="!appointmentListUtils.appointmentListItemOptions.isBeforeSearchState">
            <!-- Appointment list -->
            <div class="ml-2 my-1" *ngFor="let appointment of appointmentListUtils.appointmentListItemOptions.appointmentList; let index = index">
                <app-appointment-card [options]="{appointment: appointment, displayPatientInformation: true}"
                                      (appointmentCardAction)="onAppointmentItemAction($event, index)"></app-appointment-card>
                <div class="horizontal-line"></div>
            </div>
        </ng-container>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="appointmentListUtils.appointmentListItemOptions.totalAppointmentItems > 0" class="pagination-component px-2 py-2 text-high-contrast">
        <ngb-pagination (pageChange)="onChangePagination($event)"
                        [(page)]="appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="appointmentListUtils.appointmentListItemOptions.totalAppointmentItems" [maxSize]="5"
                        [pageSize]="appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown = !appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown;"
                          [ngClass]="{'show': appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown}"
                          aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown}"
                   class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)"
                     *ngFor="let page of appointmentListUtils.appointmentListItemOptions.itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
        </div>
    </div>
</div>

