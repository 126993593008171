import {Component, OnInit} from '@angular/core';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {Router} from '@angular/router';
import {ActionType} from '../../../data-model/general.type';
import {constants} from 'src/app/shared/constants/constants';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {CreatePatientUtils} from '../create-patient/create-patient.utils';
import {PatientDashboardUtils} from '../patient-dashboard.util';
import {TagAssignedToEntityResultType, TagProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {PatientTimeDependentTagsType} from '../patient-dashboard.types';

@Component({
    selector: 'app-patient-dashboard-details',
    templateUrl: './patient-dashboard-details.component.html',
    styleUrls: ['./patient-dashboard-details.component.scss']
})
export class PatientDashboardDetailsComponent implements OnInit {
    PATIENT = 'Patient';
    constants = constants;

    constructor(
        public patientContextService: PatientContextService,
        public patientDashboardUtils: PatientDashboardUtils,
        public createPatientUtils: CreatePatientUtils,
        private router: Router,
        private configDataService: ConfigDataService,
        private tagProvider: TagProvider,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService
    ) {
    }

    ngOnInit(): void {
       this.loadPatientTagsIfActivityActive();
    }

    onEdit(step: number = -1): void {
        this.router.navigate(['createPatient'], {
            state: {
                action: ActionType.Edit,
                patient: this.patientContextService.patient,
                ...(step !== -1 ? {step} : {}),
            }
        });
    }

    viewPatientOverviewPage() {
        const isPatientUpdateActivityActive = this.configDataService.isActivityActive('PatientUpdate');
        this.router.navigate(['createPatient'], {
            state: {
                action: isPatientUpdateActivityActive ? ActionType.Edit : ActionType.View,
                patient: this.patientContextService.patient,
                ...(isPatientUpdateActivityActive ? {step: this.createPatientUtils.getCreatePatientOverviewStep()} : {}),
                disableSaveButton: isPatientUpdateActivityActive,
                skipPatientFormValidation: true
            }
        });
    }

    areTagsActive(): boolean {
        return (this.configDataService.isFeatureActive('patient-tags') && this.configDataService.isActivityActive('PatientTagsManager'))
            || this.configDataService.isFeatureActive('patient-tags-timedependent');
    }

    private loadPatientTagsIfActivityActive() {
        const isTagReadActivityActive = this.configDataService.isActivityActive('TagRead');
        if (isTagReadActivityActive) {
            this.loadPatientTags();
        }
    }

    private loadPatientTags() {
        this.ngxLoader.start();
        this.createPatientUtils.patientTimeDependentTags = [];
        this.tagProvider.getTagsAssignedToEntity({
            entityType: 'Patient',
            entityId: this.patientContextService?.patient?.id
        })
            .subscribe(({value}) => {
                const tagsValue = value as any as TagAssignedToEntityResultType[];
                tagsValue.forEach((tag) => {
                    this.createPatientUtils.patientTimeDependentTags.push({
                        ...tag,
                        tagId: tag.id,
                        dateTimeFrom: tag.dateTimeFrom,
                        dateTimeTo: tag.dateTimeTo,
                        tagLinkId: tag.tagLinkId,
                        name: tag.name,
                        numberOfDays: this.createPatientUtils.getTimeDependentTagsDifferenceInDays(tag.dateTimeFrom, tag.dateTimeTo),
                        calculated: tag.calculated
                    } as PatientTimeDependentTagsType);
                });
                this.patientContextService.forcePatientChangeToEmit(
                    {...this.patientContextService.patient, tags: this.createPatientUtils.patientTimeDependentTags});
                this.createPatientUtils.setPatientTimeDependentTagsExclusionList();
                this.ngxLoader.stop();
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }
}
