import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserMDComponent} from './user-md.component';
import {SharedModule} from '../../shared/shared.module';
import {CreateUserMDComponent} from './create-user-md.component';
import {UserApiKeyModalComponent} from './user-api-key-modal/user-api-key-modal.component';

@NgModule({
    declarations: [
        UserMDComponent,
        CreateUserMDComponent,
        UserApiKeyModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class UserMDModule {
}
