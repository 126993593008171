<div class="container-fluid book-appointment-md-wrapper">
    <div class="book-success d-flex mb-2 p-2" *ngIf="currentPage===3">
        <i class="fas fa-check-circle ml-3 mr-2 mt-1"></i>
        <span translate="toastr.success.appointmentBooked"></span>
    </div>

    <sbase-patient-details
            [messagesService]="messagesService"
            [patientInfo]="slotsManagementMdUtils.appointmentInformationOptions.patient"
            [showRemoveButton]="false"></sbase-patient-details>

    <sbase-object-details *ngIf="currentPage===1"
                          [options]="slotsManagementMdUtils.objectDetailsOptions"
                          (action)="onObjectDetailsAction($event)"></sbase-object-details>

    <sbase-appointment-information *ngIf="currentPage===2"
                                   [options]="slotsManagementMdUtils.appointmentInformationOptions"
                                   (action)="onBookAction($event)"></sbase-appointment-information>

    <sbase-appointment-information *ngIf="currentPage===3"
                                   [ngClass]="{'appointment-info-container': currentPage===3}"
                                   [options]="slotsManagementMdUtils.appointmentInformationOverviewOptions"
                                   (action)="onDoneAction($event)"></sbase-appointment-information>
</div>
