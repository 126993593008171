import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActivityPlanExecutionTypeEnum,
    ActivityPlanScheduledEndTypeEnum,
    ActivityPlanProvider,
    ActivityPlanType,
    SearchFilterUtils,
    ServiceProvider,
    ActivityPlanTemplateProvider,
    ActivityPlanTemplateType,
    ActivityPlanTemplateServiceType,
    EntityTypeEnum
} from 'sked-base';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {ServiceType} from 'sked-base/lib/data-model/serviceTypes';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
    ActivityPlanServiceRecurrenceModalComponent
} from './activity-plan-service-recurrence-modal/activity-plan-service-recurrence-modal.component';
import {ActivityPlanFrequencyTypeEnum, ActivityPlanServiceOverviewValidation} from '../activity-plan.types';
import {ActivityPlanUtils} from '../activity-plan.utils';
import {ActivityPlanServiceType} from 'sked-base/lib/data-model/activityPlanTypes';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {DisplayChangeLogModalService} from '../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-add-activity-plan',
    templateUrl: './add-activity-plan.component.html',
    styleUrls: ['./add-activity-plan.component.scss']
})
export class AddActivityPlanComponent implements OnInit, OnDestroy {
    constants = constants;
    activityPlanExecutionTypeEnum = ActivityPlanExecutionTypeEnum;
    activityPlanScheduledEndTypeEnum = ActivityPlanScheduledEndTypeEnum;
    activityPlanFrequencyTypeEnum = ActivityPlanFrequencyTypeEnum;
    initialActivityPlan: ActivityPlanType;
    mainDependentFiltersForServiceSearch = this.searchFilterUtils.getServiceDependentFilters();
    mainDependentFiltersForActivityPlanTemplateSearch = this.activityPlanUtils.getActivityPlanTemplateDependentFilters();
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public activityPlanUtils: ActivityPlanUtils,
        private activityPlanProvider: ActivityPlanProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        private configDataService: ConfigDataService,
        public searchFilterUtils: SearchFilterUtils,
        public serviceProvider: ServiceProvider,
        private dateTimeUtils: DateTimeUtils,
        private modalService: NgbModal,
        public patientContextService: PatientContextService,
        public activityPlanTemplateProvider: ActivityPlanTemplateProvider,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        // Cannot come on this page without patient selected
        if (!this.patientContextService?.patient?.id) {
            this.messagesService.error('label.selectAPatientFirst', true);
            this.router.navigate(['patientDashboard']);
            return;
        }
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    onSelectedActivityPlanTemplate(activityPlanTemplate: ActivityPlanTemplateType[]) {
        if (!activityPlanTemplate?.length) {
            this.activityPlanUtils.activityPlanItem.services = [];
            this.activityPlanUtils.updateServiceOverviewValidations();
            return;
        }
        this.activityPlanUtils.activityPlanItem.activityPlanTemplate = activityPlanTemplate[0];
        this.activityPlanUtils.activityPlanItem.activityPlanTemplateId = activityPlanTemplate[0].id;

        this.activityPlanUtils.activityPlanItem.services = activityPlanTemplate[0].services?.map(
            (service: ActivityPlanTemplateServiceType) => service as any as ActivityPlanServiceType
        ) ?? [];
        // Add parsedFrequency field to each service from this.activityPlanItem.services so we don't have to use a pipe (better performance)
        this.activityPlanUtils.activityPlanItem =
            this.activityPlanUtils.addParsedFrequencyToItem(this.activityPlanUtils.activityPlanItem);
        this.activityPlanUtils.clearScopedService();
        this.activityPlanUtils.updateServiceOverviewValidations();
    }

    onEditScopedServiceDetails(itemIndex?: number) {
        this.activityPlanUtils.scopedServiceInEditMode = true;
        this.activityPlanUtils.scopedServiceItemIndex = itemIndex;
        this.activityPlanUtils.scopedServiceItem = lodash.cloneDeep(
            this.activityPlanUtils.activityPlanItem.services[itemIndex]
        );
        this.activityPlanUtils.scopedServiceFrequencyItem =
            this.activityPlanUtils.parseActivityPlanServiceFrequency(this.activityPlanUtils.scopedServiceItem.frequency);
        this.activityPlanUtils.scopedServiceHasValues = true;
        this.activityPlanUtils.isExecutionTypeOnceSelected =
            this.activityPlanUtils.scopedServiceItem.executionType === ActivityPlanExecutionTypeEnum.Once;
        if (this.activityPlanUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.monthDays) {
            this.activityPlanUtils.scopedServiceFrequencyItem.selectedMonthDays =
                this.activityPlanUtils.getMonthDays(this.activityPlanUtils.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.activityPlanUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.weekDays) {
            this.activityPlanUtils.scopedServiceFrequencyItem.selectedWeekDays =
                this.activityPlanUtils.getWeekDays(this.activityPlanUtils.scopedServiceFrequencyItem.selectedDays as string[]);
        }
        this.activityPlanUtils.setInitialDatesForService();

        this.activityPlanUtils.updateScopedServiceValidation();
    }

    deleteActivityPlanService(itemIndex: number) {
        this.activityPlanUtils.activityPlanItem.services.splice(itemIndex, 1);
        this.activityPlanUtils.updateServiceOverviewValidations();
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    saveScopedService() {
        const isScopedServiceValid = this.activityPlanUtils.isScopedServiceValid();
        if (!isScopedServiceValid) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
            return;
        }
        const serviceToSave = lodash.cloneDeep(this.activityPlanUtils.mapScopedServiceToSave(
            this.activityPlanUtils.scopedServiceItem,
            this.activityPlanUtils.scopedServiceFrequencyItem
        ));
        // add to or edit the services table
        if (this.activityPlanUtils.scopedServiceItemIndex === undefined) {
            this.activityPlanUtils.activityPlanItem.services.push(serviceToSave);
        } else {
            this.activityPlanUtils.activityPlanItem.services[this.activityPlanUtils.scopedServiceItemIndex] =
                serviceToSave;
        }
        // show success message after service was added / edited
        if (this.activityPlanUtils.scopedServiceInEditMode) {
            this.messagesService.success('toastr.success.serviceEdit');
        } else {
            this.messagesService.success('toastr.success.newServiceAdded');
        }
        this.activityPlanUtils.scopedServiceShowReminderToSave = false;
        this.activityPlanUtils.clearScopedService();
        this.activityPlanUtils.updateServiceOverviewValidations();
    }

    saveActivityPlanData(activityPlan: ActivityPlanType) {
        const isValid = this.validateFields(activityPlan);
        if (isValid) {
            const activityPlanToSend = this.activityPlanUtils.mapActivityPlanToSend(activityPlan);
            if (this.activityPlanUtils.screenTemplateLayout.action === constants.CREATE) {
                this.addActivityPlan(activityPlanToSend);
            } else if (this.activityPlanUtils.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialActivityPlan, activityPlanToSend)) {
                    this.messagesService.success('toastr.success.activityPlanEdit', true);
                    this.goToParentPage();
                } else {
                    this.editActivityPlan(this.initialActivityPlan, activityPlanToSend);
                }
            }
        }
    }

    onSelectedService(service: ServiceType[]) {
        this.activityPlanUtils.scopedServiceHasValues = true;
        if (!service?.length) {
            this.activityPlanUtils.scopedServiceItem.service = null;
            this.activityPlanUtils.updateScopedServiceValidation();
            return;
        }
        this.activityPlanUtils.scopedServiceItem.service = service[0];
        this.activityPlanUtils.scopedServiceItem.serviceId = service[0].id;
        this.activityPlanUtils.updateScopedServiceValidation();
    }

    goToParentPage() {
        this.router.navigate(['/activityPlans']);
    }

    goToEdit() {
        history.replaceState({
            activityPlan: this.activityPlanUtils.activityPlanItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    openServiceRecurrenceModal() {
        if (this.activityPlanUtils.isExecutionTypeOnceSelected) {
            return;
        }
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'activity-plan-service-recurrence';

        const modalRef = this.modalService
            .open(ActivityPlanServiceRecurrenceModalComponent, modalOptions);

        modalRef.componentInstance.options = {};

        modalRef.result.then(() => {
        }, (onClose) => {
            //x button was clicked, previous values needs to be displayed next time
        });
    }

    private initializeScopedService() {
        this.activityPlanUtils.scopedServiceShowReminderToSave = false;
        this.activityPlanUtils.scopedServiceItemIndex = undefined;
        this.activityPlanUtils.scopedServiceItem = this.activityPlanUtils.getInitialActivityPlanService();
        this.activityPlanUtils.scopedServiceFrequencyItem =
            this.activityPlanUtils.parseActivityPlanServiceFrequency(this.activityPlanUtils.scopedServiceItem.frequency);
        this.activityPlanUtils.isExecutionTypeOnceSelected = false;
        this.activityPlanUtils.scopedServiceHasValues = false;
        if (this.activityPlanUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.monthDays) {
            this.activityPlanUtils.scopedServiceFrequencyItem.selectedMonthDays =
                this.activityPlanUtils.getMonthDays(this.activityPlanUtils.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.activityPlanUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.weekDays) {
            this.activityPlanUtils.scopedServiceFrequencyItem.selectedWeekDays =
                this.activityPlanUtils.getWeekDays(this.activityPlanUtils.scopedServiceFrequencyItem.selectedDays as string[]);
        }
    }

    // function to create the new ActivityPlan
    private addActivityPlan(activityPlan: ActivityPlanType) {
        this.ngxLoader.start();
        this.activityPlanProvider.addEntry(activityPlan)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newActivityPlanAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ActivityPlan
    private editActivityPlan(oldActivityPlan: ActivityPlanType, newActivityPlan: ActivityPlanType) {
        this.ngxLoader.start();
        this.activityPlanProvider.updateEntry(oldActivityPlan, newActivityPlan)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.activityPlanEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(activityPlanItem: ActivityPlanType): boolean {
        if (this.activityPlanUtils.scopedServiceHasValues) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
            return false;
        }
        if (!activityPlanItem.services?.length) {
            this.messagesService.error('label.addAtLeastOneService');
            return false;
        }
        this.activityPlanUtils.updateServiceOverviewValidations();
        const isAnyServiceInvalid = this.activityPlanUtils.serviceOverviewValidations?.some(
            (serviceValidation: ActivityPlanServiceOverviewValidation) => !serviceValidation.isServiceValid
        );
        if (isAnyServiceInvalid) {
            this.messagesService.error('label.someServicesAreNotValid');
            return false;
        }
        return true;
    }

    private setupInitialState() {
        this.activityPlanUtils.scopedServiceValidation = this.activityPlanUtils.getInitialActivityServiceValidation();
        this.initializeScopedService();
        this.activityPlanUtils.maximumTimeWindow = this.dateTimeUtils.getMaximumTimeWindowFromSystemConfig();
        if (history.state && history.state.activityPlan) {
            this.initialActivityPlan = history.state.activityPlan;
            this.activityPlanUtils.activityPlanItem = lodash.cloneDeep(history.state.activityPlan);
            if (history.state.action === constants.VIEW) {
                this.activityPlanUtils.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.activityPlanUtils.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.activityPlanUtils.activityPlanItem = this.activityPlanUtils.getInitialActivityPlan();
            this.activityPlanUtils.screenTemplateLayout =
                this.generalUtils.setTemplateLayout('label.add', constants.CREATE, 'label.add', 'label.close');
            this.activityPlanUtils.scopedServiceHasValues = true;
            this.activityPlanUtils.updateScopedServiceValidation();
        }
        // Add parsedFrequency field to each service from this.activityPlanItem.services so we don't have to use a pipe (better performance)
        this.activityPlanUtils.activityPlanItem =
            this.activityPlanUtils.addParsedFrequencyToItem(this.activityPlanUtils.activityPlanItem);
        this.activityPlanUtils.updateServiceOverviewValidations();
    }
}
