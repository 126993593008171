import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RulesComponent } from './rules.component';
import { RuleSetComponent } from './rule-set/rule-set.component';
import { RulesOutletComponent } from './rules-outlet/rules-outlet.component';
import { CreateRuleOutletComponent } from './create-rule-outlet/create-rule-outlet.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormAssignmentDynamicWorkListRuleComponent } from './rules/form-assignment-dynamic-work-list-rule/form-assignment-dynamic-work-list-rule.component';
import { CreateFormAssignmentDynamicWorkListRuleComponent } from './rules/form-assignment-dynamic-work-list-rule/create-form-assignment-dynamic-work-list-rule/create-form-assignment-dynamic-work-list-rule.component';
import { MaxAppointmentRuleComponent } from './rules/max-appointment-rule/max-appointment-rule.component';
import { CreateMaxAppointmentRuleComponent } from './rules/max-appointment-rule/create-max-appointment-rule/create-max-appointment-rule.component';
import { WorkOrderRuleComponent } from './rules/work-order-rule/work-order-rule.component';
import { CreateWorkOrderRuleComponent } from './rules/work-order-rule/create-work-order-rule/create-work-order-rule.component';
import { AvailabilityChannelRuleComponent } from './rules/availability-channel-rule/availability-channel-rule.component';
import { CreateAvailabilityChannelRuleComponent } from './rules/availability-channel-rule/create-availability-channel-rule/create-availability-channel-rule.component';
import { SubServiceObjectDetailRuleComponent } from './rules/sub-service-object-detail-rule/sub-service-object-detail-rule.component';
import { CreateSubServiceObjectDetailRuleComponent } from './rules/sub-service-object-detail-rule/create-sub-service-object-detail-rule/create-sub-service-object-detail-rule.component';
import { PaymentManagementRuleComponent } from './rules/payment-management-rule/payment-management-rule.component';
import { CreatePaymentManagementRuleComponent } from './rules/payment-management-rule/create-payment-management-rule/create-payment-management-rule.component';
import { LeadTimeRuleComponent } from './rules/lead-time-rule/lead-time-rule.component';
import { CreateLeadTimeRuleComponent } from './rules/lead-time-rule/create-lead-time-rule/create-lead-time-rule.component';
import { ObjectDetailRuleComponent } from './rules/object-detail-rule/object-detail-rule.component';
import { CreateObjectDetailRuleComponent } from './rules/object-detail-rule/create-object-detail-rule/create-object-detail-rule.component';
import { PatientControlRuleComponent } from './rules/patient-control-rule/patient-control-rule.component';
import { CreatePatientControlRuleComponent } from './rules/patient-control-rule/create-patient-control-rule/create-patient-control-rule.component';
import { SlotFilterRuleComponent } from './rules/slot-filter-rule/slot-filter-rule.component';
import { CreateSlotFilterRuleComponent } from './rules/slot-filter-rule/create-slot-filter-rule/create-slot-filter-rule.component';
import { AppointmentPriceRuleComponent } from './rules/appointment-price-rule/appointment-price-rule.component';
import { CreateAppointmentPriceRuleComponent } from './rules/appointment-price-rule/create-appointment-price-rule/create-appointment-price-rule.component';
import { MinimumAppointmentsRuleComponent } from './rules/minimum-appointments-rule/minimum-appointments-rule.component';
import { CreateMinimumAppointmentsRuleComponent } from './rules/minimum-appointments-rule/create-minimum-appointments-rule/create-minimum-appointments-rule.component';
import { RevenueOptimizationRuleComponent } from './rules/revenue-optimization-rule/revenue-optimization-rule.component';
import { CreateRevenueOptimizationRuleComponent } from './rules/revenue-optimization-rule/create-revenue-optimization-rule/create-revenue-optimization-rule.component';
import { FormAssignmentAmbulatoryRuleComponent } from './rules/form-assignment-ambulatory-rule/form-assignment-ambulatory-rule.component';
import { CreateFormAssignmentAmbulatoryRuleComponent } from './rules/form-assignment-ambulatory-rule/create-form-assignment-ambulatory-rule/create-form-assignment-ambulatory-rule.component';
import {AppointmentEventRuleComponent} from './rules/appointment-event-rule/appointment-event-rule.component';
import {CreateAppointmentEventRuleComponent} from './rules/appointment-event-rule/create-appointment-event-rule/create-appointment-event-rule.component';
import { ExclusionEventRuleComponent } from './rules/exclusion-event-rule/exclusion-event-rule.component';
import { CreateExclusionEventRuleComponent } from './rules/exclusion-event-rule/create-exclusion-event-rule/create-exclusion-event-rule.component';
import { CrossAvailabilityRuleComponent } from './rules/cross-availability-rule/cross-availability-rule.component';
import { CreateCrossAvailabilityRuleComponent } from './rules/cross-availability-rule/create-cross-availability-rule/create-cross-availability-rule.component';
import { PatientCalculatedTagRuleComponent } from './rules/patient-calculated-tag-rule/patient-calculated-tag-rule.component';
import { CreatePatientCalculatedTagRuleComponent } from './rules/patient-calculated-tag-rule/create-patient-calculated-tag-rule/create-patient-calculated-tag-rule.component';
import { RuleOptionTooltipComponent } from './rules/cross-availability-rule/rule-option-tooltip/rule-option-tooltip.component';

@NgModule({
    declarations: [
        RulesComponent,
        RuleSetComponent,
        RulesOutletComponent,
        CreateRuleOutletComponent,
        FormAssignmentDynamicWorkListRuleComponent,
        CreateFormAssignmentDynamicWorkListRuleComponent,
        MaxAppointmentRuleComponent,
        CreateMaxAppointmentRuleComponent,
        WorkOrderRuleComponent,
        CreateWorkOrderRuleComponent,
        AvailabilityChannelRuleComponent,
        CreateAvailabilityChannelRuleComponent,
        SubServiceObjectDetailRuleComponent,
        CreateSubServiceObjectDetailRuleComponent,
        PaymentManagementRuleComponent,
        CreatePaymentManagementRuleComponent,
        LeadTimeRuleComponent,
        CreateLeadTimeRuleComponent,
        ObjectDetailRuleComponent,
        CreateObjectDetailRuleComponent,
        PatientControlRuleComponent,
        CreatePatientControlRuleComponent,
        SlotFilterRuleComponent,
        CreateSlotFilterRuleComponent,
        AppointmentPriceRuleComponent,
        CreateAppointmentPriceRuleComponent,
        MinimumAppointmentsRuleComponent,
        CreateMinimumAppointmentsRuleComponent,
        RevenueOptimizationRuleComponent,
        CreateRevenueOptimizationRuleComponent,
        FormAssignmentAmbulatoryRuleComponent,
        CreateFormAssignmentAmbulatoryRuleComponent,
        AppointmentEventRuleComponent,
        CreateAppointmentEventRuleComponent,
        ExclusionEventRuleComponent,
        CreateExclusionEventRuleComponent,
        CrossAvailabilityRuleComponent,
        CreateCrossAvailabilityRuleComponent,
        PatientCalculatedTagRuleComponent,
        CreatePatientCalculatedTagRuleComponent,
        RuleOptionTooltipComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        NgSelectModule,
    ]
})
export class RulesModule {
}
