import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    AppointmentTypeDefinitionType,
    Expand,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    AppointmentTypeDependentFilterType, GenericFilterOptionsType
} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AppointmentTypeDefinitionMdUtils {
    tableFilters: TableFiltersType;
    filterWrapperOptions: GenericFilterOptionsType[];

    constructor() {
    }

    getQueryFilterForAppointmentTypeDefinitionMD(tableFilters: TableFiltersType, count: boolean = true) {
        return {
            select: ['Id', 'RowVersion', 'Quantity', 'Duration'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            AppointmentType: {select: ['Id', 'Name', 'System', 'ConsumesPlannedCapacity']},
            Resource: {select: ['Id', 'Name']},
            Center: {select: ['Id', 'Name']}
        };
    }

    getSortAppointmentTypeDefinition(tableFilters: TableFiltersType) {
        return {
            filter: this.getFilterQuery(tableFilters.filter)
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'name': {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                    case 'appointmentType': {
                        filterQuery.AppointmentTypeId = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    }
                    case 'resource': {
                        filterQuery.ResourceId = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    }
                    case 'location': {
                        filterQuery.CenterId = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    }
                }

            }
        }
        return filterQuery;
    };

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialAppointmentTypeDefinition(): AppointmentTypeDefinitionType {
        const appointmentTypeDefinition: AppointmentTypeDefinitionType = {} as AppointmentTypeDefinitionType;
        return appointmentTypeDefinition;
    }

    getAppointmentTypeDependentFilters = (): AppointmentTypeDependentFilterType => ({
        searchPhrase: '',
        consumesPlannedCapacity: false,
        count: true
    })

    getListAppointmentTypeDependentFilters = (): AppointmentTypeDependentFilterType => ({
        searchPhrase: '',
        count: true
    })

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: true,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })
}
