import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    MinimumAppointmentsRuleProvider,
    MinimumAppointmentsRuleType,
    ConfirmDeleteModalService,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum, TagProvider,
    SearchFilterUtils,
    TagDependentFiltersType,
    RuleUnitEnum,
    CoveragePlanDependentFiltersType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ServiceDependentFiltersType,
    ChannelDependentFiltersType,
    CenterProvider,
    ServiceProvider,
    ResourceProvider,
    ChannelProvider,
    CoveragePlanProvider,
    CenterType,
    ServiceType,
    CoveragePlanType,
    FilterComponentChannelType,
    ResourceType,
    TagDependentFiltersScopeEnum,
    TagType,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MinimumAppointmentsRuleUtils} from './minimum-appointments-rule.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MinimumAppointmentsRuleFiltersEnum} from './minimum-appointments-rule.types';
import {RulesUtils} from '../../rules.utils';
import {RulesInitialOverviewFiltersType, RulesTableHeaderOptions} from '../../rules.types';
import {ConfigDataService} from '../../../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-minimum-appointments-rule',
    templateUrl: './minimum-appointments-rule.component.html',
    styleUrls: ['./minimum-appointments-rule.component.scss']
})
export class MinimumAppointmentsRuleComponent implements OnInit, OnDestroy {
    tableHeaderColumns: RulesTableHeaderOptions[] = this.minimumAppointmentsRuleUtils.getRuleTableHeaderOptions();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    ruleList: MinimumAppointmentsRuleType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    searchRuleByNameChanged = new Subject<string>();
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.MinimumAppointmentsRule);
    RuleUnitEnum = RuleUnitEnum;
    MinimumAppointmentsRuleFiltersEnum = MinimumAppointmentsRuleFiltersEnum;
    mainDependentFilters: {
        center: LocationDependentFiltersType,
        service: ServiceDependentFiltersType,
        resource: ResourceDependentFiltersType,
        channel: ChannelDependentFiltersType,
        coveragePlan: CoveragePlanDependentFiltersType,
        availabilityTag: TagDependentFiltersType,
        patientTag: TagDependentFiltersType,
    };
    initialFilters = {
        tableFilters: this.minimumAppointmentsRuleUtils.getInitialTableFilter(),
        modalFilters: this.minimumAppointmentsRuleUtils.getInitialModalFilters()
    } as RulesInitialOverviewFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public minimumAppointmentsRuleUtils: MinimumAppointmentsRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public tagProvider: TagProvider,
        public centerProvider: CenterProvider,
        public serviceProvider: ServiceProvider,
        public resourceProvider: ResourceProvider,
        public channelProvider: ChannelProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        private confirmDeleteService: ConfirmDeleteModalService,
        private generalUtils: GeneralUtils,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private router: Router,
        private minimumAppointmentsRuleProvider: MinimumAppointmentsRuleProvider,
        private searchFilterUtils: SearchFilterUtils,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('MinimumAppointmentsRuleRead');
        this.rulesUtils.loadFilters(RuleTypeEnum.MinimumAppointmentsRule, this.initialFilters);
        this.loadDependentFilters();
        this.subscribeSearchByRuleNameChanged();
        this.loadRules();
    }

    ngOnDestroy(): void {
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchMinimumAppointmentsRuleChanged('');
            this.rulesUtils.overviewState.previousSelectedRule = null;
            this.rulesUtils.loadFilters(RuleTypeEnum.MinimumAppointmentsRule, this.initialFilters);
            this.loadRules();
        });
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            const {modalFilters} = this.rulesUtils.overviewState;
            this.rulesUtils.overviewState.modalFilters.areFiltersActive = this.minimumAppointmentsRuleUtils.isAnyFilterActive(modalFilters);
            // Filter the data based on the new filters
            this.rulesUtils.overviewState.tableFilters.filter.centerId = modalFilters.centerOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.serviceId = modalFilters.serviceOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.resourceId = modalFilters.resourceOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.channel = modalFilters.channelOptions.ngModel[0]?.enumValue;
            this.rulesUtils.overviewState.tableFilters.filter.coveragePlanId = modalFilters.coveragePlanOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.message = modalFilters.messageOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.minimumAppointments = modalFilters.minimumAppointmentsOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.unit = modalFilters.unitOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.deactivateBefore = modalFilters.deactivateBeforeOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.availabilityTagId = modalFilters.availabilityTagOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.patientTagId = modalFilters.patientTagOptions.ngModel[0]?.id;
            this.loadRules();
        }
    }

    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        this.rulesUtils.overviewState.ruleTable = ruleTable?.length > 0 ? ruleTable[0] : undefined;
        this.rulesUtils.overviewState.tableFilters.filter['minimumAppointmentsRuleTableId'] = ruleTable?.length > 0 ? ruleTable[0].id : undefined;
        this.loadRules();
    }

    // region Navigation methods
    //
    goBack(): void {
        this.rulesUtils.selectedRule = undefined;
    }

    createRule(): void {
        this.router.navigate(['/createRule']);
    }

    editRule(rule: MinimumAppointmentsRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.EDIT}});
    }

    viewRule(rule: MinimumAppointmentsRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.VIEW}});
    }

    navigateToRuleSet(): void {
        this.router.navigate(['/ruleSet']);
    }
    //
    // endregion Navigation methods

    // region Pagination and base filters methods
    //
    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = page;
        this.loadRules(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = 1;
        this.rulesUtils.overviewState.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadRules(false);
    }

    onSortBy(property: string) {
        const orderByKeys = Object.keys(this.rulesUtils.overviewState.tableFilters.orderBy);
        const isPropertyAlreadySorted = orderByKeys.includes(property);
        if (orderByKeys.length >= constants.ORDER_BY_CLAUSE_MAX_PERMITTED && !isPropertyAlreadySorted) {
            this.messagesService.error('toastr.error.orderByLimitReached');
            return;
        }
        const isAscendingMode = this.rulesUtils.overviewState.tableFilters.orderBy[property];
        if (this.rulesUtils.overviewState.tableFilters.orderBy) {
            this.rulesUtils.overviewState.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadRules(false);
    }

    onSearchMinimumAppointmentsRuleChanged(value: string) {
        this.searchRuleByNameChanged.next(value);
    }

    onClearOrderBy(selectedItem: string) {
        delete this.rulesUtils.overviewState.tableFilters.orderBy[selectedItem];
        this.loadRules(false);
    }
    //
    // endregion

    displayConfirmDeleteItemModal(ruleId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.minimumAppointmentsRuleProvider, ruleId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteRule(ruleId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.rulesUtils.overviewState.modalFilters[property].ngModel = undefined;
        }
    }

    // region Rule specific methods
    //
    onSelectedFilterValue(
        entityName: MinimumAppointmentsRuleFiltersEnum,
        selectedValuesList: CenterType[] | ServiceType[] | ResourceType[] | FilterComponentChannelType[] | CoveragePlanType[] | TagType[]
    ) {
        this.rulesUtils.overviewState.modalFilters[entityName + 'Options'].ngModel =
            selectedValuesList?.length > 0 ? selectedValuesList : [];
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            center: this.minimumAppointmentsRuleUtils.getCenterDependentFilters(),
            service: this.minimumAppointmentsRuleUtils.getServiceDependentFilters(),
            resource: this.minimumAppointmentsRuleUtils.getResourceDependentFilters(),
            channel: this.minimumAppointmentsRuleUtils.getChannelDependentFilters(),
            coveragePlan: this.minimumAppointmentsRuleUtils.getCoveragePlanDependentFilters(),
            availabilityTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
            patientTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true),
        };
    }
    //
    // endregion Rule specific methods

    // region General methods
    //
    private loadRules(includeCount: boolean = true) {
        // Load the data
        const queryFilter = this.minimumAppointmentsRuleUtils.getQueryFilter(this.rulesUtils.overviewState.tableFilters, includeCount);
        this.ngxLoader.start();
        this.minimumAppointmentsRuleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.ruleList = response.value;
                this.rulesUtils.loadSpecialities(this.ruleList);
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private deleteRule(id: string) {
        this.rulesUtils.overviewState.tableFilters = this.minimumAppointmentsRuleUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.minimumAppointmentsRuleProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadRules();
                this.messagesService.success('toastr.success.minimumAppointmentsRuleDelete', true);
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private subscribeSearchByRuleNameChanged() {
        const self = this;
        this.searchRuleByNameChanged.pipe(
            filter(value => {
                return value.length >= 3 || value.length === 0;
            }),
            debounceTime(constants.inputDebounceTime),
            distinctUntilChanged()
        ).subscribe((searchValue) => {
            self.rulesUtils.overviewState.tableFilters.filter.name = searchValue;
            self.loadRules();
        });
    }
    //
    // endregion General methods
}
