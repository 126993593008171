import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {HolidayType, ODataQueryObjectType} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {HolidayTypeEnum} from 'sked-base';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HolidayMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    holidayTypeSubject = new Subject<HolidayTypeEnum>();
    holidayTypeSubscription: Subscription;

    constructor() {
    }

    getQueryFilterForHolidayMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Type', 'Description', 'MonthOfYear', 'DayOfMonth', 'Year', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy)
        };
    }

    getQueryForIsHolidayAssignedToAHolidayCalendar(id: string): ODataQueryObjectType {
        return {
            select: ['Id', 'Description'],
            top: 1,
            count: true,
            filter: {Days: {any: {Id: {eq: {type: 'guid', value: id}}}}} as ODataFilterQueryType
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {Description: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialHoliday(): HolidayType {
        return {
            type: 'noValue' as unknown as HolidayTypeEnum,
            description: '',
            monthOfYear: null,
            dayOfMonth: null,
            year: null,
        } as HolidayType;
    }

    getInitialHolidayDate(): NgbDateStruct {
        const today = new Date();
        return {
            day: today.getDate(),
            month: today.getMonth() + 1,
            year: today.getFullYear(),
        };
    }
}
