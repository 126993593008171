import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ChangeLogsProvider, ChangeLogsType} from 'sked-base';
import {ChangeLogsOptionsType} from './change-logs-types';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {ChangeLogsUtils} from './change-logs.util';

@Component({
    selector: 'app-change-logs',
    templateUrl: './change-logs.component.html',
    styleUrls: ['./change-logs.component.scss']
})
export class ChangeLogsComponent implements OnInit {
    @Input() public options: ChangeLogsOptionsType = {} as ChangeLogsOptionsType;
    @Input() public changeLogsEmitter: EventEmitter<ChangeLogsOptionsType> = new EventEmitter<ChangeLogsOptionsType>();
    changeLogs: ChangeLogsType[] = [];
    noResultsFound = false;

    constructor(
        public ngxLoader: NgxUiLoaderService,
        public changeLogProvider: ChangeLogsProvider,
        public messagesService: MessagesService,
        public changeLogUtils: ChangeLogsUtils
    ) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.registerChangeLogsEvent();
            if (this.options.triggerRequestOnInit) {
                this.loadChangeLogData();
            }
        });
    }

    private loadChangeLogData() {
        this.ngxLoader.start();
        this.changeLogProvider.getEntries(this.options.entityType, this.options.entityTypeId)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.changeLogs = response.value;
                this.changeLogs.forEach((item) => {
                    item.isRowCollapsed = true;
                    item.contentList = this.changeLogUtils.mapContent(item);
                });
                this.noResultsFound = (this.changeLogs.length < 1);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private registerChangeLogsEvent() {
        if (!!this.changeLogsEmitter) {
            this.changeLogsEmitter.subscribe(() => {
                this.noResultsFound = false;
                this.changeLogs = [];
                this.loadChangeLogData();
            });
        }
    }
}
