<div class="view-exclusion-component">
    <!--GENERAL INFORMATION-->
    <label translate="label.generalInformation" class="title"></label>

    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.resource"></td>
            <td translate="label.center2"></td>
            <td translate="label.validFrom"></td>
            <td translate="label.validTo"></td>
            <td translate="label.exclusionReason"></td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{{exclusion?.resource?.name}}</td>
            <td>{{exclusion?.center?.name}}</td>
            <td>{{exclusion?.validFrom | fullDateFormatPipe}}</td>
            <td *ngIf="!exclusion.endOfDay">{{exclusion?.validTo | fullDateFormatPipe}}</td>
            <td *ngIf="exclusion.endOfDay">{{exclusion?.validTo | dateEndOfDay}}
                <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoExclusion'| translate}}"></i>
            </td>
            <td>{{exclusion?.exclusionReason}}&nbsp;{{exclusion?.exclusionExtraInformation}}</td>
        </tr>
        </tbody>
    </table>

    <!--TIME SLOTS-->
    <div *ngIf="exclusion.exclusionType=== ExclusionTypeEnum.Partial">
        <label translate="label.timeSlots" class="title"></label>
        <table class="table table-bordered grey-table">
            <thead class="thead-light">
            <tr>
                <td translate="label.mon"></td>
                <td translate="label.tu"></td>
                <td translate="label.we"></td>
                <td translate="label.th"></td>
                <td translate="label.fr"></td>
                <td translate="label.sa"></td>
                <td translate="label.su"></td>
                <td translate="label.repetition"></td>
            </tr>
            </thead>
            <tbody *ngFor="let timeSlot of exclusion?.timeSlots">
            <tr>
                <td><span *ngIf="timeSlot.monday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.tuesday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.wednesday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.thursday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.friday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.saturday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td><span *ngIf="timeSlot.sunday">{{timeSlot.hourFrom | hourFromMinutes}}
                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                <td>{{exclusion?.repetition}} {{'enum.repetitionInterval.' + exclusion?.unit | translate}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
