import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../utils/general.utils';
import {ModalFiltersComponent} from './modal-filters.component';
import {ODataFilterQueryType} from '../../../data-model/general.type';
import * as lodash from 'lodash';
import {ModalFiltersType} from './modal-filters.types';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-show-modal-filters',
    templateUrl: './show-modal-filters.component.html',
    styleUrls: ['./show-modal-filters.component.scss']
})
export class ShowModalFiltersComponent implements OnInit, OnDestroy {
    @Input() options: ModalFiltersType;
    @Output() onFiltersSelected: EventEmitter<ModalFiltersType> = new EventEmitter<ModalFiltersType>();
    activeFilter = false;
    private initialOptions: ModalFiltersType;

    constructor(private modalService: NgbModal,
                private generalUtils: GeneralUtils) {
    }

    ngOnInit(): void {
        this.initialOptions = lodash.cloneDeep(this.options);
        this.activeFilter = this.hasSelectedFilter(this.options);
    }

    ngOnDestroy(): void {
    }

    openModalFilters() {
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'filters-modal';

        const modalRef = this.modalService
            .open(ModalFiltersComponent, modalOptions);

        modalRef.componentInstance.options = this.options;

        modalRef.result.then((selectedFilters) => {
            this.activeFilter = this.hasSelectedFilter(selectedFilters);
            this.initialOptions = lodash.cloneDeep(this.options);
            this.onFiltersSelected.emit(selectedFilters);
        }, (onClose) => {
            //x button was clicked, previous values needs to be displayed next time
            this.options = lodash.cloneDeep(this.initialOptions);
        });
    }

    private hasSelectedFilter(selectedFilters: ModalFiltersType) {
        return !!(selectedFilters?.dateRangeOptions?.fromDate || selectedFilters?.dateRangeOptions?.toDate ||
            selectedFilters?.onlineConsultationOptions?.onlineConsultation || selectedFilters?.roomReservationNeededOptions?.roomReservationNeeded ||
            selectedFilters?.ignoreHolidaysOptions?.ignoreHolidays);
    }

}
