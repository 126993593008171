import {Component, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {ExclusionReasonType, ExclusionReasonProvider, ExclusionProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExclusionReasonMdUtils} from '../exclusion-reason-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';

@Component({
    selector: 'app-create-exclusion-reason',
    templateUrl: './create-exclusion-reason.component.html',
    styleUrls: ['./create-exclusion-reason.component.scss']
})
export class CreateExclusionReasonComponent implements OnInit {
    constants = constants;
    initialExclusionReason: ExclusionReasonType;
    exclusionReasonItem: ExclusionReasonType = {} as ExclusionReasonType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    isAssignedToAnExclusion = false;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private exclusionReasonMdUtils: ExclusionReasonMdUtils,
        public exclusionReasonProvider: ExclusionReasonProvider,
        public router: Router,
        public generalUtils: GeneralUtils,
        public exclusionProvider: ExclusionProvider,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    saveExclusionReasonData(exclusionReason: ExclusionReasonType) {
        const isTemplateValid = this.validateFields(exclusionReason);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createExclusionReason(exclusionReason);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialExclusionReason, exclusionReason)) {
                    this.messagesService.success('toastr.success.exclusionReasonEdit', true);
                    this.goToParentPage();
                } else {
                    this.editExclusionReason(this.initialExclusionReason, exclusionReason);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/exclusionReasons']);
    }

    goToEdit() {
        history.replaceState({exclusionReason: this.exclusionReasonItem, action: constants.EDIT}, '');
        this.ngOnInit();
    }

    // function to create the new ExclusionReason
    private createExclusionReason(exclusionReason: ExclusionReasonType) {
        this.ngxLoader.start();
        this.exclusionReasonProvider.addEntry(exclusionReason)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newExclusionReasonAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ExclusionReason
    private editExclusionReason(oldExclusionReason: ExclusionReasonType, newExclusionReason: ExclusionReasonType) {
        this.ngxLoader.start();
        this.exclusionReasonProvider.updateEntry(oldExclusionReason, newExclusionReason)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.exclusionReasonEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private loadIsExclusionReasonAssignedToAnExclusion(exclusionReasonId: string) {
        this.ngxLoader.start();
        this.exclusionReasonProvider.getIsExclusionReasonAssignedToAnExclusion(exclusionReasonId)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.isAssignedToAnExclusion = false;
            }, err => {
                this.ngxLoader.stop();
                this.isAssignedToAnExclusion = true;
            });
    }

    private validateFields(exclusionReasonItem: ExclusionReasonType): boolean {
        return !!(exclusionReasonItem && exclusionReasonItem.description);
    }

    private setupInitialState() {
        if (history?.state && history?.state?.exclusionReason) {
            this.initialExclusionReason = history.state.exclusionReason;
            this.exclusionReasonItem = lodash.cloneDeep(history.state.exclusionReason);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.loadIsExclusionReasonAssignedToAnExclusion(this.exclusionReasonItem.id);
            }
        } else {
            this.exclusionReasonItem = this.exclusionReasonMdUtils.getInitialExclusionReason();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            this.isAssignedToAnExclusion = false;
        }
        this.validateFields(this.exclusionReasonItem);
    }
}
