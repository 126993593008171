<div class="modal-container">
    <div class="modal-header">
        <h6 class="modal-title font-weight-bold" translate="label.chooseDateRangeForExport"></h6>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="appointment-list-message text-high-contrast mb-2">
            <i class="fa-light fas fa-info-circle mr-2"></i>
            <span translate="label.maxRange30days"></span>
        </div>
        <sbase-date-range class="input-element"
                          [ngClass]="{'input-element-has-error': !dateRangeValidation.isValid}"
                          [options]="appointmentListUtils.dateRangeOptions"
                          [updateDateRangeValue]="updateDateRangeValue"
                          (onDateRangeChanged)="onDateRangeChanged($event)">
        </sbase-date-range>
    </div>
    <div class="modal-footer">
        <button class="btn close-btn"
                (click)="closeModal()"
                translate="label.cancel">
        </button>
        <button class="btn theme-btn"
                translate="label.ok"
                [disabled]="!dateRangeValidation.isValid"
                (click)="onGenerateExportAppointments()"
        ></button>
    </div>
</div>
