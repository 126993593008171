import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {DateRangeOptionsType, Expand, ODataQueryObjectType} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import * as moment from 'moment';
import {MessagesService} from '../../shared/services/messages.service';

@Injectable({
    providedIn: 'root'
})
export class ActionExecutionLogsMdUtils {

    constructor(
        private generalUtils: GeneralUtils,
        private dateTimeUtils: DateTimeUtils) {
    }

    getQueryFilterForActionExecutionLogsMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            ActionExecutionLog: {select: ['Id', 'ActionType', 'Status']}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.expand = {
            ActionExecutionLog: {
                select: [
                    'Id', 'ActionType', 'Status'
                ]
            }
        };
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'relatedEntityId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'relatedEntityType': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'actionType': {
                        if (!filterQuery.ActionExecutionLog) {
                            filterQuery.ActionExecutionLog = {};
                        }
                        filterQuery.ActionExecutionLog.ActionType = {eq: filter[item]};
                        break;
                    }
                    case 'status': {
                        if (!filterQuery.ActionExecutionLog) {
                            filterQuery.ActionExecutionLog = {};
                        }
                        filterQuery.ActionExecutionLog.Status = {eq: filter[item]};
                        break;
                    }
                    case 'createdOn': {
                        filterQuery[lodash.upperFirst(item)] = {
                            ge: filter[item].fromDate,
                            le: filter[item].toDate
                        };
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'actionType': {
                        orderByQuery.push('ActionExecutionLog/' + lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }

        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getDefaultDateRange(): DateRangeOptionsType {
        const todayMoment = moment();
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayMinus7DaysMoment = moment().subtract(7, 'days');
        const todayMinus7Days = this.dateTimeUtils.getNgbDateFromMoment(todayMinus7DaysMoment);
        const todayMinus3MonthsMoment = moment().subtract(3, 'months');
        const todayMinus3Months = this.dateTimeUtils.getNgbDateFromMoment(todayMinus3MonthsMoment);
        const minDate = {year: todayMinus3Months.year, month: todayMinus3Months.month, day: todayMinus3Months.day};
        const maxDate = {year: today.year, month: today.month, day: today.day};
        const toDate = {year: today.year, month: today.month, day: today.day};
        const fromDate = {year: todayMinus7Days.year, month: todayMinus7Days.month, day: todayMinus7Days.day};
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.createdDateFrom',
            toDateLabel: 'label.createdDateTo',
            minDate,
            maxDate,
            maximumRange: 7,
            disabled: false,
            initialMinDate: minDate,
            initialMaxDate: maxDate,
        } as DateRangeOptionsType;
    }
}
