<div class="container-fluid availability-overselling-definition-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.oversellingDefinition"></h3>
    </div>
    <div class="availability-overselling-definition-inner-wrapper custom-scrollbar">
        <!-- Body -->
        <!-- =========================================== -->
        <div class="overselling-definition-form">
            <form name="form" novalidate
                  (ngSubmit)="saveAvailabilityOversellingDefinitionData()">
                <div class="create-entity-md-section">
                    <div class="row">
                        <div class="col-md-6 col-12 form-group"
                             [ngClass]="{'has-error': !availabilityRangeValidation.isValid}">
                            <label translate="label.validFrom"></label>
                            <div class="date-and-time-picker-inputs-container">
                                <div class="date-picker-input-container">
                                    <input class="date-picker-input"
                                           [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                           name="dpfrom1"
                                           readonly
                                           [minDate]="dateRangeOptions.minDate"
                                           [(ngModel)]="availabilityDateAndTimeRange.dateFrom"
                                           (dateSelect)="onValidFromDateTimeChanged($event, availabilityDateAndTimeRange.timeFrom, true)"
                                           ngbDatepicker #datePickerFrom="ngbDatepicker"
                                           (click)="datePickerFrom.toggle()">
                                    <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                            [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                            (click)="datePickerFrom.toggle()"
                                            type="button"></button>
                                </div>
                                <div class="time-picker-input-container" *ngIf="availabilityDateAndTimeRange.timeFrom">
                                    <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                          [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                                          [format]="24"
                                                          [cancelBtnTmpl]="closeBtn"
                                                          [confirmBtnTmpl]="confirmBtn"
                                                          [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilityDateAndTimeRange.timeFrom)"
                                                          (timeChanged)="onValidFromDateTimeChanged(availabilityDateAndTimeRange.dateFrom, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                    </ngx-timepicker-field>
                                </div>
                            </div>
                            <span class="has-error" *ngIf="!availabilityRangeValidation.isValid"
                                  [translate]="availabilityRangeValidation.errorMessage">
                            </span>
                        </div>
                        <div class="col-md-6 col-12 form-group"
                             [ngClass]="{'has-error': !availabilityRangeValidation.isValid}">
                            <label translate="label.validTo"></label>
                            <div class="date-and-time-picker-inputs-container">
                                <div class="date-picker-input-container">
                                    <input class="date-picker-input"
                                           [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                           name="dpto1"
                                           readonly
                                           [minDate]="dateRangeOptions.minDate"
                                           [(ngModel)]="availabilityDateAndTimeRange.dateTo"
                                           (dateSelect)="onValidToDateTimeChanged($event, availabilityDateAndTimeRange.timeTo)"
                                           ngbDatepicker #datePickerTo="ngbDatepicker"
                                           (click)="datePickerTo.toggle()">
                                    <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                            [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                            (click)="datePickerTo.toggle()"
                                            type="button">
                                    </button>
                                </div>
                                <div class="time-picker-input-container" *ngIf="availabilityDateAndTimeRange.timeTo">
                                    <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                          [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                                          [format]="24"
                                                          [cancelBtnTmpl]="closeBtn"
                                                          [confirmBtnTmpl]="confirmBtn"
                                                          [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilityDateAndTimeRange.timeTo)"
                                                          (timeChanged)="onValidToDateTimeChanged(availabilityDateAndTimeRange.dateTo, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                    </ngx-timepicker-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-3 col-7">
                            <label translate="label.channelExclusions"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedChannelFilter($event)"
                                          [buttonName]="'label.channelExclusions' | translate"
                                          [dependentFilters]="mainDependentFilters.channel"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="initialFilterValues.channel"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="channelProvider"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label translate="label.active"></label>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked
                                               [(ngModel)]="availabilityOversellingDefinitionItem.active"
                                               name="active">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 numeric-input-width">
                            <div class="form-group">
                                <label translate="label.quantity"></label>
                                <input type="number" class="input-element"
                                       [ngClass]="{'input-element-has-error': generalUtils.isUndefinedOrNull(availabilityOversellingDefinitionItem.quantity) || quantity?.errors?.pattern}"
                                       name="quantity"
                                       #quantity="ngModel"
                                       min="1"
                                       [(ngModel)]="availabilityOversellingDefinitionItem.quantity"
                                       [pattern]="REGEX_WHOLE_NUMBER"
                                       autocomplete="off"
                                       [placeholder]="'label.quantity' | translate">
                                <div *ngIf="generalUtils.isUndefinedOrNull(availabilityOversellingDefinitionItem.quantity)"
                                     class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                </div>
                                <div *ngIf="quantity?.errors?.pattern"
                                     class="help-block">
                                        <span class="inValidPosition"
                                              translate="label.invalidField"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-11">
                            <div class="form-group"
                                 [ngClass]="{ 'has-error': !serviceList.length}">
                                <label class="mr-2" translate="label.service"></label>
                                <ng-select class="custom-ng-select input-element"
                                           dropdownPosition="bottom"
                                           placeholder="{{'label.choose' | translate}}"
                                           [multiple]="true"
                                           [searchable]="true"
                                           [clearable]="true"
                                           [ngModelOptions]="{standalone: true}"
                                           [ngClass]="{'input-element-has-error' : !serviceList.length}"
                                           [(ngModel)]="serviceList"
                                           (ngModelChange)="onSelectedService($event)">
                                    <ng-option *ngFor="let service of availabilityData.services"
                                               [value]="service">
                                        <span><b>{{service.specialityName}}</b>{{' ' + service.name}}</span>
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="!serviceList.length"
                                     class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Create and cancel buttons-->
                    <!-- =========================================== -->
                    <div class="mt-2">
                        <button class="btn mr-3 back-btn"
                                type="button"
                                (click)="clearForm()"
                                translate="button.cancel">
                        </button>
                        <button class="btn theme-btn"
                                type="submit"
                                [disabled]="!isFormValid() || quantity?.errors"
                                translate="button.save">
                        </button>
                    </div>
                    <!--End Create and cancel buttons-->
                    <!-- =========================================== -->
                </div>
            </form>
        </div>
        <div class="table-responsive mt-2 form-group">
            <label translate="label.oversellingDefinition"></label>
            <!-- Overselling Definitions Table -->
            <!-- ============================================================== -->
            <table class="table">
                <thead>
                <tr>
                    <th translate="label.validFrom"></th>
                    <th translate="label.validTo"></th>
                    <th translate="label.channel"></th>
                    <th translate="label.service"></th>
                    <th translate="label.quantity"></th>
                    <th translate="label.active"></th>
                    <th class="text-center" translate="label.action"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let availabilityOversellingDefinition of availabilityOversellingDefinitionMdList">
                    <td class="name-column">{{availabilityOversellingDefinition.dateTimeFrom |
                        fullDateNoTimeFormatPipe}}</td>
                    <td class="name-column">{{availabilityOversellingDefinition.dateTimeTo |
                        fullDateNoTimeFormatPipe}}</td>
                    <td class="name-column">{{availabilityOversellingDefinition.channel}}</td>
                    <td class="name-column">
                            <span
                                    *ngFor="let service of availabilityOversellingDefinition.services">{{service.name}}</span>
                    </td>
                    <td class="name-column">{{availabilityOversellingDefinition.quantity}}</td>
                    <td class="name-column">{{availabilityOversellingDefinition.active | yesNoPipe | translate}}
                    </td>
                    <td class="text-center">
                        <button type="button" class="btn" [ngbPopover]="popContent" popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.edit"
                                            (click)="setOversellingDefinitionItemDataForEdit(availabilityOversellingDefinition)"
                                            [activityDisplay]="AVAILABILITY_OVERSELLING_DEFINITION+CONSTANTS.UPDATE"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="displayConfirmDeleteItemModal(availabilityOversellingDefinition.id)"
                                            [activityDisplay]="AVAILABILITY_OVERSELLING_DEFINITION+CONSTANTS.DELETE"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
                </tbody>
            </table>
            <!-- ============================================================== -->
            <!-- End Overselling Definitions Table -->
        </div>
        <div id="availability-data-accordion">
            <!-- Availability Data -->
            <!-- ============================================================== -->
            <ngb-accordion #acc="ngbAccordion">
                <ngb-panel title="{{'label.availabilityTab.availabilityHistory.currentAvailability' | translate}}"
                           id="availability-data-panel"
                           type="light">
                    <ng-template ngbPanelHeader>
                        <button ngbPanelToggle
                                class="btn p-0 w-100 d-flex no-block shadow-none title text-left align-items-center justify-content-between">
                            <span
                                    class="font-weight-bold title ml-2 mr-2">{{'label.availabilityTab.availabilityHistory.currentAvailability'
                                | translate}}</span>
                            <span class="material-icons float-right justify-self-end"
                                  *ngIf="!acc.isExpanded('availability-data-panel')">&#xe5c5;</span>
                            <span class="material-icons float-right"
                                  *ngIf="acc.isExpanded('availability-data-panel')">&#xe5c7;</span>
                        </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-view-availability [availability]="availabilityData"></app-view-availability>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <!-- ============================================================== -->
            <!-- End Availability Data -->
        </div>
        <!-- =========================================== -->
        <!--End Body-->
    </div>
    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer mt-2 footer-position-sticky">
        <button class="btn mr-3 back-btn"
                type="button"
                (click)="goToParentPage()"
                translate="button.back">
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close">
        </button>
    </div>
</ng-template>
