import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ExclusionEventRuleProvider,
    ExclusionEventRuleType,
    ConfirmDeleteModalService,
    EventActionProvider,
    EventActionType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    ExclusionEventTypeEnum,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ExclusionEventRuleUtils} from './exclusion-event-rule.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {RulesUtils} from '../../rules.utils';
import {RulesInitialOverviewFiltersType, RulesTableHeaderOptions} from '../../rules.types';
import {ConfigDataService} from '../../../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-exclusion-event-rule',
    templateUrl: './exclusion-event-rule.component.html',
    styleUrls: ['./exclusion-event-rule.component.scss']
})
export class ExclusionEventRuleComponent implements OnInit, OnDestroy {
    tableHeaderColumns: RulesTableHeaderOptions[] = this.exclusionEventRuleUtils.getRuleTableHeaderOptions();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    ruleList: ExclusionEventRuleType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    searchRuleByNameChanged = new Subject<string>();
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.ExclusionEventRule);
    exclusionEventTypeEnumList = Object.values(ExclusionEventTypeEnum);
    mainDependentFilters = this.exclusionEventRuleUtils.getMainDependentFilters();
    initialFilters = {
        tableFilters: this.exclusionEventRuleUtils.getInitialTableFilter(),
        modalFilters: this.exclusionEventRuleUtils.getInitialModalFilters()
    } as RulesInitialOverviewFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public exclusionEventRuleUtils: ExclusionEventRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public eventActionProvider: EventActionProvider,
        private confirmDeleteService: ConfirmDeleteModalService,
        private generalUtils: GeneralUtils,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private router: Router,
        private exclusionEventRuleProvider: ExclusionEventRuleProvider,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`ExclusionEventRuleRead`);
        this.rulesUtils.loadFilters(RuleTypeEnum.ExclusionEventRule, this.initialFilters);
        this.subscribeSearchByRuleNameChanged();
        this.loadRules();
    }

    ngOnDestroy(): void {
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchExclusionEventRuleChanged('');
            this.rulesUtils.overviewState.previousSelectedRule = null;
            this.rulesUtils.loadFilters(RuleTypeEnum.ExclusionEventRule, this.initialFilters);
            this.loadRules();
        });
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            const {modalFilters} = this.rulesUtils.overviewState;
            this.rulesUtils.overviewState.modalFilters.areFiltersActive = this.exclusionEventRuleUtils.isAnyFilterActive(modalFilters);
            // Filter the data based on the new filters
            this.rulesUtils.overviewState.tableFilters.filter.eventType = modalFilters.eventTypeFilterOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.eventActionId = modalFilters.eventActionOptions.ngModel?.id;
            this.loadRules(true);
        }
    }

    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        this.rulesUtils.overviewState.ruleTable = ruleTable?.length > 0 ? ruleTable[0] : undefined;
        this.rulesUtils.overviewState.tableFilters.filter['exclusionEventRuleTableId'] = ruleTable?.length > 0 ? ruleTable[0].id : undefined;
        this.loadRules();
    }

    onSelectedEventAction(eventAction: EventActionType[]): void {
        this.rulesUtils.overviewState.modalFilters['eventActionOptions'].ngModel = eventAction?.length > 0 ? eventAction[0] : undefined;
    }

    // region Navigation methods
    //
    goBack(): void {
        this.rulesUtils.selectedRule = undefined;
    }

    createRule(): void {
        this.router.navigate(['/createRule']);
    }

    editRule(rule: ExclusionEventRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.EDIT}});
    }

    viewRule(rule: ExclusionEventRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.VIEW}});
    }

    navigateToRuleSet(): void {
        this.router.navigate(['/ruleSet']);
    }
    //
    // endregion Navigation methods

    // region Pagination and base filters methods
    //
    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = page;
        this.loadRules(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = 1;
        this.rulesUtils.overviewState.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadRules(false);
    }

    onSortBy(property: string) {
        const isAscendingMode = this.rulesUtils.overviewState.tableFilters.orderBy[property];
        if (this.rulesUtils.overviewState.tableFilters.orderBy) {
            this.rulesUtils.overviewState.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadRules(false);
    }

    onSearchExclusionEventRuleChanged(value: string) {
        this.searchRuleByNameChanged.next(value);
    }

    onClearOrderBy(selectedItem: string) {
        delete this.rulesUtils.overviewState.tableFilters.orderBy[selectedItem];
        this.loadRules(false);
    }
    //
    // endregion

    displayConfirmDeleteItemModal(ruleId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.exclusionEventRuleProvider, ruleId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteRule(ruleId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.rulesUtils.overviewState.modalFilters[property].ngModel = undefined;
        }
    }

    // endregion Rule specific methods

    // region General methods

    private loadRules(includeCount: boolean = true) {
        // Load the data
        const queryFilter = this.exclusionEventRuleUtils.getQueryFilter(this.rulesUtils.overviewState.tableFilters, includeCount);
        this.ngxLoader.start();
        this.exclusionEventRuleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.ruleList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private deleteRule(id: string) {
        this.rulesUtils.overviewState.tableFilters = this.exclusionEventRuleUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.exclusionEventRuleProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadRules();
                this.messagesService.success('toastr.success.exclusionEventRuleDelete', true);
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private subscribeSearchByRuleNameChanged() {
        const self = this;
        this.searchRuleByNameChanged.pipe(
            filter(value => {
                return value.length >= 3 || value.length === 0;
            }),
            debounceTime(constants.inputDebounceTime),
            distinctUntilChanged()
        ).subscribe((searchValue) => {
            self.rulesUtils.overviewState.tableFilters.filter.name = searchValue;
            self.loadRules();
        });
    }

    // endregion General methods
}
