<div class="assign-sub-services-modal">
    <div class="modal-header">
        <h4 class="modal-title">
            {{"label.subServicesAssignation" | translate}}
            - {{numberOfSelectedSubServices}} {{"label.assigned" | translate}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="form-group col-7">
                    <sbase-filter (selectedItemsOutput)="onSelectedSubService($event)"
                                  [buttonName]=subServiceButtonName
                                  [dependentFilters]="mainDependentFilters.subService"
                                  [initialFilterValues]="initialSubServiceValues"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="subServiceProvider">
                    </sbase-filter>
                </div>

                <div class="d-flex col-5">
                    <div class="form-group">
                        <label translate="label.showOnlyAssigned"></label>
                    </div>
                    <div class="form-group">
                        <div class="switch">
                            <label>
                                <input type="checkbox" checked
                                       [(ngModel)]="showOnlyAssigned"
                                       (ngModelChange)="onShowOnlyAssignedSubServices(showOnlyAssigned)">
                                <span class="lever switch-col-black"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <table class="table table-sm table-hover">
                <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <input type="checkbox"
                               name="selectAll"
                               class="selectAll-checkbox"
                               [ngModelOptions]="{standalone: true}"
                               [checked]="selectAll"
                               [(ngModel)]="selectAll"
                               (ngModelChange)="onSelectAll(selectAll)"/>
                    </th>
                    <th scope="col" translate="label.name"></th>
                    <th scope="col" translate="label.code"></th>
                    <th scope="col" translate="label.duration"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let subService of subServices; index as i"
                    [ngClass]="{'selected': subService.selected}"
                    (click)="onSelectSingle(subService)">
                    <td>
                        <input type="checkbox"
                               name="service"
                               class="service-checkbox"
                               [checked]="subService.selected"/>
                    </td>
                    <td>{{ subService.name }}</td>
                    <td>{{ subService.code }}</td>
                    <td>
                        <div class="form-group">
                             <span [ngClass]="{ 'has-error': subService.duration === undefined || subService.duration === null}"
                                   *ngIf="options.showDurationForMainResource">
                                <input type="number" class="form-control"
                                       placeholder="{{'label.duration'|translate}}"
                                       name="duration"
                                       #duration="ngModel"
                                       min="0"
                                       [(ngModel)]="subService.duration"
                                       [pattern]="RegexWholeNumber"
				       (click)=$event.stopPropagation()
                                       autocomplete="off">
                                    <div *ngIf="subService.duration === undefined || subService.duration === null"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': subService.duration === undefined || subService.duration === null}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="duration?.errors?.pattern" class="help-block">
                                        <span class="inValidPosition" translate="label.invalidField"></span>
                                    </div>
                            </span>
                            <span *ngIf="!options.showDurationForMainResource">
                                <app-show-availability-main-resource-duration
                                        [serviceId]="options.serviceId"
                                        [resourceId]="options.resourceId"
                                        [subServiceId]="subService.id"
                                ></app-show-availability-main-resource-duration>
                            </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="pagination-component mt-3" *ngIf="totalItems > 0">
                    <ngb-pagination [collectionSize]="totalItems"
                                    [(page)]="tableFilters.currentPage"
                                    [maxSize]="5"
                                    [pageSize]="tableFilters.itemsPerPage"
                                    [boundaryLinks]="true"
                                    (pageChange)="onChangePagination($event)">
                    </ngb-pagination>

                    <div class="btn-group items-per-page-group">
                        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
                        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{tableFilters.itemsPerPage}}</strong>
                    </span>
                      <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                           (clickOutside)=onClickedOutsideItemsPerPageFilter($event)
                           class="dropdown-menu dropdown-menu-item"
                           x-placement="top-start" x-placement="bottom-start">
                          <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                             (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                      </div>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                (click)="activeModal.dismiss('close')" translate="label.close">
        </button>
        <button type="button"
                class="btn theme-btn"
                (click)="save()"
                translate="button.save">
        </button>
    </div>
</div>

