import {Component, OnInit, OnDestroy} from '@angular/core';
import {TableFiltersType} from 'src/app/data-model/general.type';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {ConfirmDeleteModalService, ExclusionReasonProvider, ExclusionReasonType, EntityTypeEnum} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ExclusionReasonMdUtils} from './exclusion-reason-md-util';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@Component({
  selector: 'app-exclusion-reason-md',
  templateUrl: './exclusion-reason-md.component.html',
  styleUrls: ['./exclusion-reason-md.component.scss']
})
export class ExclusionReasonMDComponent implements OnInit, OnDestroy {
  tableFilters: TableFiltersType;
  itemsPerPageList: number[];
  exclusionReasonMdList: ExclusionReasonType[] = [];
  objectKeys = Object.keys;
  showItemsPerPageDropdown = false;
  totalTableItems: number;
  public ExclusionReason: string;
  public MDEntityName: string;
  public constants = constants;
  VIEW_ACTIVITY_ENABLED = false;
  EntityTypeEnum = EntityTypeEnum;

  private searchExclusionReasonChanged = new Subject<string>();

  constructor(
    private exclusionReasonMdUtils: ExclusionReasonMdUtils,
    private changeLogModalService: DisplayChangeLogModalService,
    private configDataService: ConfigDataService,
    public exclusionReasonProvider: ExclusionReasonProvider,
    public ngxLoader: NgxUiLoaderService,
    public messagesService: MessagesService,
    public router: Router,
    public generalUtils: GeneralUtils,
    public confirmDeleteService: ConfirmDeleteModalService
  ) {
  }

  ngOnInit() {
    this.MDEntityName = this.exclusionReasonProvider.name;
    this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`${this.MDEntityName}Read`);
    this.ExclusionReason = 'ExclusionReason';
    this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
    this.tableFilters = this.exclusionReasonMdUtils.getInitialTableFilter();
    this.loadExclusionReasonMdData();
    this.subscribeSearchByExclusionReasonChanged();
  }


  ngOnDestroy(): void {
  }

  // method to navigate create ExclusionReason
  createExclusionReason(): void {
    this.router.navigate(['/createExclusionReason']);
  }

  editExclusionReason(exclusionReason: ExclusionReasonType) {
    this.router.navigate(['/createExclusionReason'], {state: {exclusionReason, action: constants.EDIT}});
  }

  viewExclusionReason(exclusionReason: ExclusionReasonType) {
    this.router.navigate(['/createExclusionReason'], {state: {exclusionReason, action: constants.VIEW}});
  }

  onClickedOutsideItemsPerPageFilter(e: Event) {
    this.showItemsPerPageDropdown = false;
  }

  onChangePagination(page: number) {
    this.tableFilters.currentPage = page;
    this.loadExclusionReasonMdData(false);
  }

  changeNumberOfItemsPerPage(itemPerPage) {
    this.tableFilters.currentPage = 1;
    this.tableFilters.itemsPerPage = itemPerPage;
    this.showItemsPerPageDropdown = false;
    this.loadExclusionReasonMdData(false);
  }

  // method to sort data by ascending/descending order.
  onSortBy(property: string) {
    const isAscendingMode = this.tableFilters.orderBy[property];
    if (this.tableFilters.orderBy) {
      this.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
    }
    this.loadExclusionReasonMdData(false);
  }

  onSearchExclusionReasonChanged(value: string) {
    this.searchExclusionReasonChanged.next(value);
  }

  onClearOrderBy(selectedItem) {
    delete this.tableFilters.orderBy[selectedItem];
    this.loadExclusionReasonMdData(false);
  }

  displayConfirmDeleteItemModal(exclusionReasonId: string) {
    this.confirmDeleteService.displayConfirmDeleteModal(this.exclusionReasonProvider, exclusionReasonId).result.then(() => {
    }, (response) => {
      if (response) {
        this.deleteItem(exclusionReasonId);
      }
    });
  }

  displayChangeLog(id: string, actionType: string): void {
     this.changeLogModalService.displayModal(id, actionType);
  }

  // function to get ExclusionReason data
  private loadExclusionReasonMdData(includeCount: boolean = true) {
    const queryFilter = this.exclusionReasonMdUtils.getQueryFilterForExclusionReasonMD(this.tableFilters, includeCount);
    this.ngxLoader.start();
    this.exclusionReasonProvider.getEntries(queryFilter)
      .pipe(take(1))
      .subscribe((response: { value: ExclusionReasonType[], count?: number }) => {
        this.exclusionReasonMdList = response.value;
        if (response.count !== undefined && response.count !== null) {
          this.totalTableItems = response.count;
        }
        this.ngxLoader.stop();
      }, err => {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
      });
  }

  // method for deleting table item
  private deleteItem(id) {
    this.tableFilters = this.exclusionReasonMdUtils.getInitialTableFilter();
    this.ngxLoader.start();
    this.exclusionReasonProvider.deleteEntry(id)
      .pipe(take(1))
      .subscribe(() => {
        this.loadExclusionReasonMdData();
        this.messagesService.success('toastr.success.exclusionReasonDelete', true);
        this.ngxLoader.stop();
      }, err => {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
      });
  }

  private subscribeSearchByExclusionReasonChanged() {
    const self = this;
    this.searchExclusionReasonChanged
      .pipe(
        filter(value => {
          return value.length >= 3 || value.length === 0;
        }),
        debounceTime(constants.inputDebounceTime),
        distinctUntilChanged()
      )
      .subscribe((searchValue) => {
        self.tableFilters.filter.description = searchValue;
        self.loadExclusionReasonMdData();
      });
  }
}
