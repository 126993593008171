import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import * as lodash from 'lodash';
import {formControlNames} from '../../../../shared/constants/formControlNames';
import {
    ResourceDependentFiltersType,
    ResourceProvider,
    ResourceType,
    ServiceDependentFiltersType,
    ServiceProvider,
    ServiceType,
    SpecialityDependentFiltersType,
    SpecialityProvider,
    SpecialityType,
    EntityTypeEnum
} from 'sked-base';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {CreatePatientUtils} from '../create-patient.utils';
import * as moment from 'moment/moment';
import {constants} from '../../../../shared/constants/constants';
import {DisplayChangeLogModalService} from '../../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@Component({
    selector: 'app-exceptions-form',
    templateUrl: './exceptions-form.component.html',
    styleUrls: ['./exceptions-form.component.scss']
})
export class ExceptionsFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;
    @ViewChild('serviceSbaseFilter', {static: false}) serviceSbaseFilter;
    @ViewChild('specialitySbaseFilter', {static: false}) specialitySbaseFilter;
    @ViewChild('resourceSbaseFilter', {static: false}) resourceSbaseFilter;

    exceptionsForm: FormGroup;
    allInputs: DynamicFormInputType[] = [];
    mainDependentFilters: {
        service: ServiceDependentFiltersType,
        speciality: SpecialityDependentFiltersType,
        resource: ResourceDependentFiltersType
    };
    serviceInitialValue: ServiceType[] = [];
    specialityInitialValue: SpecialityType[] = [];
    resourceInitialValue: ResourceType[] = [];
    formControlNames = formControlNames;
    validToFormOptions: DynamicFormOptionsType;
    isSaveButtonDisabled = true;
    CONSTANTS = constants;
    EntityTypeEnum = EntityTypeEnum;

    constructor(public serviceProvider: ServiceProvider,
                public specialityProvider: SpecialityProvider,
                public resourceProvider: ResourceProvider,
                public dynamicFormInputService: DynamicFormInputService,
                public createPatientUtils: CreatePatientUtils,
                private rootFormGroup: FormGroupDirective,
                private changeLogModalService: DisplayChangeLogModalService) {
    }

    ngOnInit(): void {
        this.exceptionsForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.loadDependentFilter();
        this.loadInitialValues();
        this.listenToExceptionsFormChanges();
    }

    onSaveException() {
        const {value} = this.exceptionsForm;
        const validTo = moment(moment(moment(value[formControlNames.VALID_TO], moment.localeData().longDateFormat('L'))).format('YYYY-MM-DD'))
            .utc(true).format();
        if (this.exceptionsForm.valid) {
            this.createPatientUtils.patientControlExceptions.push({
                service: value[formControlNames.SERVICE],
                specialty: value[formControlNames.SPECIALTY],
                resource: value[formControlNames.RESOURCE],
                validTo,
                id: null
            });
            this.onCancelException();
        }
    }

    onCancelException() {
        const {value} = this.exceptionsForm;
        if (value[formControlNames.SERVICE]) {
            this.serviceSbaseFilter.onRemoveItem(value[formControlNames.SERVICE]);
        }
        if (value[formControlNames.SPECIALTY]) {
            this.specialitySbaseFilter.onRemoveItem(value[formControlNames.SPECIALTY]);
        }
        if (value[formControlNames.RESOURCE]) {
            this.resourceSbaseFilter.onRemoveItem(value[formControlNames.RESOURCE]);
        }
        this.exceptionsForm.reset();
    }

    onRemoveException(index: number) {
        this.createPatientUtils.patientControlExceptions.splice(index, 1);
    }

    displayChangeLog(id: string, actionType: string): void {
        this.changeLogModalService.displayModal(id, actionType);
    }

    onSelectedService(serviceList: ServiceType[]) {
        this.mainDependentFilters.resource.serviceId = serviceList?.length > 0 ? serviceList[0]?.id : undefined;
        this.exceptionsForm.get(formControlNames.SERVICE).patchValue(
            serviceList?.length > 0 ? serviceList[0] : []
        );
    }

    onSelectedSpeciality(specialityList: SpecialityType[]) {
        this.exceptionsForm.get(formControlNames.SPECIALTY).patchValue(
            specialityList?.length > 0 ? specialityList[0] : []
        );
    }

    onSelectedResource(resourceList: ResourceType[]) {
        this.mainDependentFilters.service.resourceId = resourceList?.length > 0 ? resourceList[0]?.id : undefined;
        this.exceptionsForm.get(formControlNames.RESOURCE).patchValue(
            resourceList?.length > 0 ? resourceList[0] : []
        );
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options?.groups?.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private listenToExceptionsFormChanges() {
        this.exceptionsForm?.valueChanges?.subscribe((exceptionsFormValue) => {
            const exceptionsFormValueKeys = Object.keys(exceptionsFormValue);
            // Check if all inputs are empty
            const areAllExceptionsFormValuesEmpty = Object.values(exceptionsFormValue)
                .every((value: any) => value === '' || value?.length <= 0 || !value);
            // Set error to all empty inputs except valid to
            exceptionsFormValueKeys.forEach(key => {
                if ((exceptionsFormValue[key] === '' || exceptionsFormValue[key]?.length <= 0 || !exceptionsFormValue[key]) && key !== formControlNames.VALID_TO) {
                    this.exceptionsForm.controls[key].setErrors({
                        requiredError: {
                            priority: 2, message: 'label.error.required', shouldTranslateMessage: true
                        }});
                }
            });
            // Set error to validTo input if this is empty
            if (!exceptionsFormValue[formControlNames.VALID_TO]) {
                this.exceptionsForm.controls[formControlNames.VALID_TO].setErrors({
                    requiredError: {
                        priority: 2, message: 'label.error.required', shouldTranslateMessage: true
                    }});
            }
            // Remove error from all inputs if all of them are empty
            if (areAllExceptionsFormValuesEmpty) {
                Object.keys(this.exceptionsForm.controls).forEach(key => this.exceptionsForm.controls[key].setErrors(null));
            }
            this.exceptionsForm.markAllAsTouched();
            // Disable save button if the form is invalid
            this.isSaveButtonDisabled = !this.exceptionsForm.valid || areAllExceptionsFormValuesEmpty;
        });
    }

    private loadDependentFilter() {
        this.mainDependentFilters = {
            service: this.getServiceDependentFilters(),
            speciality: this.getSpecialityDependentFilters(),
            resource: this.getResourceDependentFilters()
        };
    }

    private loadInitialValues() {
        this.serviceInitialValue = [];
        this.specialityInitialValue = [];
        this.resourceInitialValue = [];
        this.validToFormOptions = this.createPatientUtils.getValidToFormOptions();
    }

    private getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        onlyAssignedToLocationsOfUser: true,
        includeAvailabilities: false,
        includeChannel: true,
        searchPhrase: '',
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        hasMultiResourceBluePrint: null,
        exclusionList: [],
        count: true,
    } as ServiceDependentFiltersType)

    private getSpecialityDependentFilters(): SpecialityDependentFiltersType {
        return {
            searchPhrase: '',
            areaId: null,
            count: true,
            exclusionList: []
        };
    }

    private getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    } as ResourceDependentFiltersType)
}
