import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SlotCenterLocalFilterType, SlotsCentersOptionsType} from '../slots-management.types';
import {SlotsManagementMdUtils} from '../slots-management-util';
import * as lodash from 'lodash';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import tippy, {Instance} from 'tippy.js';

@Component({
    selector: 'app-slots-local-filters-centers',
    templateUrl: './slots-local-filters-centers.component.html',
    styleUrls: ['./slots-local-filters-centers.component.scss']
})
export class SlotsLocalFiltersCentersComponent implements OnInit, AfterViewInit {
    @Output() select: EventEmitter<void> = new EventEmitter<void>();

    slotsCenters: SlotsCentersOptionsType;
    tippyInstance: Instance;

    constructor(private ngxLoader: NgxUiLoaderService,
                public slotsManagementMdUtils: SlotsManagementMdUtils) {
    }

    ngOnInit(): void {
        this.slotsCenters = this.slotsManagementMdUtils.slotsCentersOptions;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!!this.slotsCenters) {
                this.makeTippyPopover();
            }
        });
    }

    private makeTippyPopover() {
        const button = document.getElementById('slots-local-filters-center-tippy-button');
        const popover = document.getElementById('slots-local-filters-center-tippy-popover');
        if (!!button && !!popover) {
            this.tippyInstance = tippy(button, {
                content: popover,
                hideOnClick: true,
                placement: 'right-end',
                allowHTML: true,
                interactive: true,
                trigger: 'click',
                theme: 'light-border',
                animation: false,
                popperOptions: { strategy: 'fixed' }
            });
        }
    }

    saveSlotsCentersForDisplayingSlots(centers: SlotCenterLocalFilterType[]): void {
        this.ngxLoader.start();
        this.slotsManagementMdUtils.slotsCentersOptions.selectedCenters = lodash.filter(centers, {selected: true});
        this.select.emit();
        this.tippyInstance.hide();
        this.ngxLoader.stop();
    }

    selectCenter(selectedCenter: SlotCenterLocalFilterType): void {
        selectedCenter.selected = !selectedCenter?.selected;
    }

    getDistanceFromMetersInKm(distanceMeters: string): number {
        if (Number(distanceMeters) >= 0) {
            return Number(distanceMeters) / 1000;
        }
    }

    getTodayAndTotalSlotsForCenter(centerId: number, isTotal: boolean = false): number {
        for (const key in this.slotsCenters.numberOfSlotsForEachCenter) {
            if (key === centerId.toString()) {
                if (isTotal) {
                    return this.slotsCenters.numberOfSlotsForEachCenter[centerId].totalNumberOfAvailableSlots;
                } else {
                    return this.slotsCenters.numberOfSlotsForEachCenter[centerId].numberOfSlotsAvailableToday;
                }
            }
        }
    }
}
