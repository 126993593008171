import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    MultiResourceBluePrintType,
    MultiResourceBluePrintProvider,
    ResourceTypeProvider,
    ResourceTypeType,
    MultiResourceBluePrintItemType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MultiResourceBluePrintMdUtils} from '../multi-resource-blue-print-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    MultiResourceBluePrintDependentFiltersType,
    MultiResourceBluePrintFiltersType,
    MultiResourceBluePrintItemWithNameType
} from '../multi-resource-blue-print.types';
import {forkJoin} from 'rxjs';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-multi-resource-blue-print',
    templateUrl: './create-multi-resource-blue-print.component.html',
    styleUrls: ['./create-multi-resource-blue-print.component.scss']
})
export class CreateMultiResourceBluePrintComponent implements OnInit, OnDestroy {
    CONSTANTS = constants;
    MAXIMUM_SECONDARY_TYPES: number;
    initialMultiResourceBluePrint: MultiResourceBluePrintType;
    multiResourceBluePrintItem: MultiResourceBluePrintType = this.multiResourceBluePrintMdUtils.getInitialMultiResourceBluePrint();
    screenTemplateLayout: ScreenTemplateLayoutType;
    resourceTypes: ResourceTypeType[] = [];

    mainDependentFilters: MultiResourceBluePrintDependentFiltersType;
    initialFilterValues: MultiResourceBluePrintFiltersType = this.multiResourceBluePrintMdUtils.getInitialFilterValues();
    secondaryResourceTypeExclusionList: string[] = [];

    secondaryResources: MultiResourceBluePrintItemWithNameType[];

    constructor(
        public resourceTypeProvider: ResourceTypeProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private multiResourceBluePrintMdUtils: MultiResourceBluePrintMdUtils,
        private multiResourceBluePrintProvider: MultiResourceBluePrintProvider,
        private router: Router,
        private generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveMultiResourceBluePrintData(multiResourceBluePrint: MultiResourceBluePrintType) {
        const isTemplateValid = this.validateFields(multiResourceBluePrint);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === this.CONSTANTS.CREATE) {
                this.createMultiResourceBluePrint(multiResourceBluePrint);
            } else if (this.screenTemplateLayout.action === this.CONSTANTS.EDIT) {
                this.editMultiResourceBluePrint(this.initialMultiResourceBluePrint, multiResourceBluePrint);
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/multiResourceBluePrints']);
    }

    onSelectedMainResourceTypeFilter([mainResource]: ResourceTypeType[]) {
        this.initialFilterValues.mainResourceType = mainResource ? [mainResource] : [];
        this.multiResourceBluePrintItem.mainResourceTypeId = mainResource?.id;
        this.initialFilterValues.secondaryResourceTypes = [];
        if (!this.multiResourceBluePrintItem.mainResourceTypeId) {
            this.multiResourceBluePrintItem.items = [];
            this.secondaryResourceTypeExclusionList = [];
            return;
        }
        this.multiResourceBluePrintItem.items.push({
            resourceTypeId: this.multiResourceBluePrintItem.mainResourceTypeId,
            isMainResource: true
        } as MultiResourceBluePrintItemType);
        this.secondaryResourceTypeExclusionList = [];
        this.secondaryResourceTypeExclusionList.push(mainResource.id);
    }

    onSelectedSecondaryResourceTypeFilter(secondaryResourceFilterList: ResourceTypeType[]) {
        this.initialFilterValues.secondaryResourceTypes = secondaryResourceFilterList;
        const items = this.multiResourceBluePrintItem.items.filter(resourceType => resourceType.isMainResource);
        secondaryResourceFilterList?.filter(
            resourceType => resourceType.id && resourceType.id !== this.multiResourceBluePrintItem.mainResourceTypeId
        )?.forEach(secondaryResourceType => {
            items.push({
                resourceTypeId: secondaryResourceType.id,
                isMainResource: false,
                numberOfResource: this.MAXIMUM_SECONDARY_TYPES
            } as MultiResourceBluePrintItemType);
        });
        this.multiResourceBluePrintItem.items = items;
        this.secondaryResources = this.getSecondaryResourceTypesWithNameFromItemsList(this.multiResourceBluePrintItem);
    }

    getSecondaryResourceTypesWithNameFromItemsList(multiResourceBluePrintItem: MultiResourceBluePrintType = this.multiResourceBluePrintItem): MultiResourceBluePrintItemWithNameType[] {
        return multiResourceBluePrintItem.items?.filter(resourceType => resourceType.resourceTypeId !== multiResourceBluePrintItem.mainResourceTypeId)
            .map<MultiResourceBluePrintItemWithNameType>(resourceTypeWithoutName => {
                return {
                    ...resourceTypeWithoutName,
                    name: this.resourceTypes.find(resourceType => resourceType.id === resourceTypeWithoutName.resourceTypeId)?.name
                };
            });
    }

    deleteSecondaryResource(index: number) {
        this.multiResourceBluePrintItem.items.splice(index + 1, 1);
        this.initialFilterValues.secondaryResourceTypes.splice(index, 1);
        this.secondaryResources = this.getSecondaryResourceTypesWithNameFromItemsList(this.multiResourceBluePrintItem);
    }

    isSaveButtonEnabled() {
        return this.validateFields(this.multiResourceBluePrintItem);
    }

    goToEdit() {
        history.replaceState({
            multiResourceBluePrint: this.multiResourceBluePrintItem,
            action: this.CONSTANTS.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new MultiResourceBluePrint
    private createMultiResourceBluePrint(multiResourceBluePrint: MultiResourceBluePrintType) {
        this.ngxLoader.start();
        this.multiResourceBluePrintProvider.addEntry(multiResourceBluePrint)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newMultiResourceBlueprintAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the MultiResourceBluePrint
    private editMultiResourceBluePrint(oldMultiResourceBluePrint: MultiResourceBluePrintType, newMultiResourceBluePrint: MultiResourceBluePrintType) {
        this.ngxLoader.start();
        this.multiResourceBluePrintProvider.updateEntry(oldMultiResourceBluePrint, newMultiResourceBluePrint)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.multiResourceBlueprintEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(multiResourceBluePrintItem: MultiResourceBluePrintType): boolean {
        const secondaryResources = multiResourceBluePrintItem.items.filter(resource => !resource.isMainResource);
        return !!(multiResourceBluePrintItem && multiResourceBluePrintItem.name
            && multiResourceBluePrintItem.mainResourceTypeId
            && multiResourceBluePrintItem.items?.length > 0
            && secondaryResources.length > 0);
    }

    private setupInitialState() {
        this.ngxLoader.start();
        forkJoin([
            this.resourceTypeProvider.getEntries(this.multiResourceBluePrintMdUtils.getQueryFilterForResourceTypes()),
            this.multiResourceBluePrintProvider.getMaximumSecondaryTypes()
        ]).subscribe(([resourceTypes, maximumSecondaryTypes]) => {
            this.resourceTypes = resourceTypes.value;
            this.MAXIMUM_SECONDARY_TYPES = maximumSecondaryTypes.value;
            this.setInitialFilterValues();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
            this.goToParentPage();
        });
        this.mainDependentFilters = {
            mainResourceType: this.multiResourceBluePrintMdUtils.getResourceDependentFilters(),
            secondaryResourceType: this.multiResourceBluePrintMdUtils.getResourceDependentFilters()
        };
        if (history.state && history.state.multiResourceBluePrint) {
            this.initialMultiResourceBluePrint = history.state.multiResourceBluePrint;
            this.multiResourceBluePrintItem = lodash.cloneDeep(history.state.multiResourceBluePrint);
            if (history.state.action === this.CONSTANTS.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', this.CONSTANTS.VIEW, undefined, 'button.back');
            } else if (history.state.action === this.CONSTANTS.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', this.CONSTANTS.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.multiResourceBluePrintItem = this.multiResourceBluePrintMdUtils.getInitialMultiResourceBluePrint();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', this.CONSTANTS.CREATE, 'label.create', 'label.close');
        }
    }

    private setInitialFilterValues() {
        if (history.state && history.state.multiResourceBluePrint) {
            const mainResourceType = history.state.multiResourceBluePrint.items.filter(item => item.isMainResource).map(resourceTypeWithoutName => {
                return {
                    ...resourceTypeWithoutName,
                    name: this.resourceTypes.find(resourceType => resourceType.id === resourceTypeWithoutName.resourceTypeId)?.name
                };
            });
            const secondaryResourceTypes = this.getSecondaryResourceTypesWithNameFromItemsList(history.state.multiResourceBluePrint)
                .filter(item => !item.isMainResource);
            this.secondaryResources = this.getSecondaryResourceTypesWithNameFromItemsList(history.state.multiResourceBluePrint)
                .filter(item => !item.isMainResource);
            this.initialFilterValues.mainResourceType = [{id: mainResourceType[0].id, name: mainResourceType[0].name} as ResourceTypeType];
            for (const type of secondaryResourceTypes) {
                this.initialFilterValues.secondaryResourceTypes.push({id: type.id, name: type.name} as ResourceTypeType);
            }
        }
    }
}
