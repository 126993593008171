import {Injectable} from '@angular/core';
import {
    AvailabilityDataTimeSlotType,
    AvailabilityTimeSlotType,
    ExclusionOverviewTimeSlotType,
    ExclusionTimeSlotType
} from 'sked-base';

@Injectable({
    providedIn: 'root'
})

export class TimeSlotsUtils {
    constructor() {
    }

    getWeekDays(): string[] {
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    }

    getTimeSlotSelectedDays(timeSlot: AvailabilityDataTimeSlotType | ExclusionOverviewTimeSlotType): string[] {
        const selectedDays: string[] = [];

        if (timeSlot.monday) {
            selectedDays.push('monday');
        }
        if (timeSlot.tuesday) {
            selectedDays.push('tuesday');
        }
        if (timeSlot.wednesday) {
            selectedDays.push('wednesday');
        }
        if (timeSlot.thursday) {
            selectedDays.push('thursday');
        }
        if (timeSlot.friday) {
            selectedDays.push('friday');
        }
        if (timeSlot.saturday) {
            selectedDays.push('saturday');
        }
        if (timeSlot.sunday) {
            selectedDays.push('sunday');
        }

        return selectedDays;
    }

}
