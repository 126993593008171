<!-- DONE -->
<button *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Done)"
        (click)="onTaskItemAction(taskItemActionEnum.Done)"
        type="button"
        class="btn theme-btn mt-2"
        translate="label.done">
</button>
<!-- REJECT -->
<button *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Reject)"
        (click)="onTaskItemAction(taskItemActionEnum.Reject)"
        [ngClass]="{'ml-2': displayAppointmentStatusChangeButton(taskItemActionEnum.Done)}"
        type="button"
        class="btn close-btn mt-2"
        translate="label.reject">
</button>
<!-- VERTICAL LINE -->
<span *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Book) || displayAppointmentStatusChangeButton(taskItemActionEnum.Reschedule) ||
displayAppointmentStatusChangeButton(taskItemActionEnum.Cancel)"
      class="vertical-line mx-2 mt-2"></span>
<!-- BOOK -->
<button *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Book)"
        (click)="onTaskItemAction(taskItemActionEnum.Book)"
        type="button"
        class="btn theme-btn mr-2 mt-2"
        translate="label.book">
    <i class="fas fa-calendar ml-2"></i>
</button>
<!-- RESCHEDULE -->
<button *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Reschedule)"
        (click)="onTaskItemAction(taskItemActionEnum.Reschedule)"
        type="button"
        class="btn theme-btn mr-2 mt-2"
        translate="label.reschedule">
    <i class="fas fa-calendar ml-2"></i>
</button>
<!-- CANCEL -->
<sbase-cancel-appointment *ngIf="displayAppointmentStatusChangeButton(taskItemActionEnum.Cancel)"
                          (appointmentCancelled)="onTaskItemAction(taskItemActionEnum.Cancel)"
                          [options]="cancelAppointmentOptions">
</sbase-cancel-appointment>
