import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../utils/general.utils';
import {ObjectDetailsModalComponent} from './object-details-modal/object-details-modal.component';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';

@Component({
    selector: 'app-appointment-object-details',
    templateUrl: './appointment-object-details.component.html',
    styleUrls: ['./appointment-object-details.component.scss']
})
export class AppointmentObjectDetailsComponent implements OnInit {
    @Input() options: ObjectDetailsOptionsType;

    constructor(
        private generalUtils: GeneralUtils,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
    }

    openAppointmentObjectDetails() {
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'appointment-object-details';

        const modalRef = this.modalService.open(ObjectDetailsModalComponent, modalOptions);
        modalRef.componentInstance.options = this.options;
        modalRef.result.then(() => {}, () => {});
    }

}
