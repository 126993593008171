import {Component, OnInit} from '@angular/core';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {
    AppointmentProvider,
    JobProvider
} from 'sked-base';
import {AppointmentListUtils} from './appointment-list.utils';
import {
    AppointmentActionEnum
} from '../../shared/component/appointment-action-buttons/appointment-action-buttons.types';
import * as lodash from 'lodash';
import {AppointmentListFiltersSearchType} from './appointment-list-filters/appointment-list-filters.types';
import {AppointmentCardEmittedActionType} from '../../shared/component/appointment-card/appointment-card.types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {
    AppointmentListExportModalComponent
} from './appointment-list-export-modal/appointment-list-export-modal.component';

@Component({
    selector: 'app-appointment-list',
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.scss']
})
export class AppointmentListComponent implements OnInit {
    isPrintPdfButtonEnabled: boolean;

    constructor(public appointmentListUtils: AppointmentListUtils,
                public patientContextService: PatientContextService,
                private messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService,
                private router: Router,
                private appointmentProvider: AppointmentProvider,
                private jobProvider: JobProvider,
                private generalUtils: GeneralUtils,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.isPrintPdfButtonEnabled = false;
        this.loadDataFromState();
        this.checkIfNeededToResetEverything();
        this.registerToPatientChange();
    }

    onAppointmentItemAction({action, data}: AppointmentCardEmittedActionType, index: number = -1) {
        switch (action) {
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Cancel: {
                // The appointment is cancelled in cancel-appointment component, here we only remove the appointment from the list
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentListUtils.appointmentListItemOptions.appointmentList[index]) {
                    lodash.pullAt(this.appointmentListUtils.appointmentListItemOptions.appointmentList, index);
                }
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentListUtils.appointmentListItemOptions.appointmentList[index]) {
                    if (
                        !!this.appointmentListUtils.filtersOptions.latestSearchFilterValues.status
                        && this.appointmentListUtils.filtersOptions.latestSearchFilterValues.status !== data.status
                    ) {
                        lodash.pullAt(this.appointmentListUtils.appointmentListItemOptions.appointmentList, index);
                    } else {
                        this.appointmentListUtils.appointmentListItemOptions.appointmentList[index] = {
                            ...this.appointmentListUtils.appointmentListItemOptions.appointmentList[index],
                            etag: data.etag,
                            status: data.status,
                        };
                    }
                }
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentListUtils.appointmentListItemOptions.appointmentList[index]) {
                    this.appointmentListUtils.appointmentListItemOptions.appointmentList[index] = {
                        ...this.appointmentListUtils.appointmentListItemOptions.appointmentList[index],
                        etag: data.etag,
                        paid: data.paid,
                    };
                }
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                return;
            }
        }
    }

    onFilterSearch(filterValues: AppointmentListFiltersSearchType) {
        this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.searchFilters = filterValues;
        this.searchForAppointments();
    }

    onChangePagination(page: number) {
        this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.currentPage = page;
        this.searchForAppointments();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.currentPage = 1;
        this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.pageFilters.itemsPerPage =
            itemPerPage;
        this.appointmentListUtils.appointmentListItemOptions.showItemsPerPageDropdown = false;
        this.searchForAppointments();
    }

    openExportAppointmentListModal() {
        // Open modal
        const modalOptions = this.generalUtils.getModalOptions();
        const modalRef = this.modalService.open(AppointmentListExportModalComponent, modalOptions);
        // Wait for response
        modalRef.result.then(() => {
            // On close, do nothing
        }, () => {
            // On dismiss, do nothing
        });
    }

    onGetAppointmentsPdf() {
        const queryFilter = this.appointmentListUtils.getQueryFilterForAppointmentsPdf();
        this.ngxLoader.start();
        this.appointmentProvider.getAppointmentsPdf(queryFilter).subscribe((response) => {
            this.processFileResponse(response);
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    refreshData() {
        this.searchForAppointments();
    }

    private processFileResponse(response): void {
        const downloadUrl = URL.createObjectURL(response.file);
        // open in new tab
        window.open(downloadUrl, '_blank');
    }

    private loadDataFromState(): void {
        // If shouldn't keep filters state rewrite the latest search filters
        if (!this.appointmentListUtils.shouldKeepFiltersState) {
            this.appointmentListUtils.appointmentListState.filtersOptions.latestSearchFilterValues = undefined;
        }
        // If shouldMakeNewRequest and we have filter values, do request
        if (this.appointmentListUtils.shouldMakeNewRequest) {
            this.onFilterSearch(
                this.appointmentListUtils.appointmentListState.filtersOptions?.latestSearchFilterValues
                ?? {} as AppointmentListFiltersSearchType);
            return;
        }
        // If shouldKeepListState and we have items options, load them
        const shouldKeepListState = this.appointmentListUtils.shouldKeepListState
            && !!this.appointmentListUtils.appointmentListState.appointmentListItemOptions;
        this.appointmentListUtils.appointmentListItemOptions = shouldKeepListState
            ? lodash.cloneDeep(this.appointmentListUtils.appointmentListState.appointmentListItemOptions)
            : this.appointmentListUtils.getInitialAppointmentListFiltersOptions();
        this.appointmentListUtils.shouldKeepListState = false;
    }

    private checkIfNeededToResetEverything(): void {
        // If state is empty (all shouldKeep are false), then we reset everything
        if (!this.appointmentListUtils.shouldKeepFiltersState &&
            !this.appointmentListUtils.shouldKeepListState &&
            !this.appointmentListUtils.shouldMakeNewRequest) {
            // Reset item list
            this.appointmentListUtils.appointmentListItemOptions.isBeforeSearchState = true;
            // Clear and show filters
            this.appointmentListUtils.clearFiltersEmitter?.next();
            if (this.appointmentListUtils.filtersOptions) {
                this.appointmentListUtils.filtersOptions.areFiltersCollapsed = false;
            }
        }
    }

    private searchForAppointments(autoSearch: boolean = false): void {
        this.ngxLoader.start();
        this.appointmentListUtils.shouldMakeNewRequest = false;
        this.appointmentListUtils.appointmentListItemOptions.appointmentList = [];
        const query = this.appointmentListUtils.getAppointmentQueryFilter(
            autoSearch
                ? this.appointmentListUtils.appointmentListItemOptions.latestAppointmentListRequestFilters
                : this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters
        );
        this.appointmentProvider.getByUserInformation(query, true, true).subscribe((appointmentList) => {
            this.appointmentListUtils.appointmentListItemOptions.isBeforeSearchState = false;
            this.appointmentListUtils.appointmentListItemOptions.totalAppointmentItems = appointmentList.count;
            this.appointmentListUtils.appointmentListItemOptions.appointmentList = appointmentList.value;
            this.appointmentListUtils.appointmentListItemOptions.isNotFoundState = appointmentList.value?.length <= 0;
            const {location, dateTo, dateFrom, resource} =
                this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.searchFilters;
            // If we have center or resource, date interval and appointments enable print button
            this.isPrintPdfButtonEnabled =
                (location || resource) && dateTo && dateFrom && this.appointmentListUtils.appointmentListItemOptions.appointmentList.length > 0;
            if (!autoSearch) {
                this.appointmentListUtils.appointmentListItemOptions.latestAppointmentListRequestFilters = lodash.cloneDeep(
                    this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters
                );
                this.appointmentListUtils.filtersOptions.latestSearchFilterValues = lodash.cloneDeep(
                    this.appointmentListUtils.appointmentListItemOptions.appointmentListRequestFilters.searchFilters
                );
            }
            // Update state
            this.appointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'totalAppointmentItems', 'appointmentList', 'isNotFoundState', 'latestAppointmentListRequestFilters'
            ]);
            this.appointmentListUtils.updateFilterState(['latestSearchFilterValues']);
        }, (error) => {
            this.appointmentListUtils.appointmentListItemOptions.isBeforeSearchState = true;
            this.appointmentListUtils.appointmentListItemOptions.isNotFoundState = false;
            this.appointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState'
            ]);
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private registerToPatientChange() {
        this.patientContextService.patientChange.subscribe(() => {
            // Clear and show filters
            this.appointmentListUtils.clearFiltersEmitter?.next();
            this.appointmentListUtils.filtersOptions.areFiltersCollapsed = false;
            // Reset item list
            this.appointmentListUtils.appointmentListItemOptions.totalAppointmentItems = 0;
            this.appointmentListUtils.appointmentListItemOptions.appointmentList = [];
            this.appointmentListUtils.appointmentListItemOptions.isBeforeSearchState = true;
            this.appointmentListUtils.appointmentListItemOptions.isNotFoundState = false;
            this.appointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState', 'totalAppointmentItems', 'appointmentList', 'areFiltersCollapsed'
            ]);
        });
    }

}
