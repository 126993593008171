import {ChangeLogsType} from 'sked-base';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChangeLogsUtils {

    constructor() {
    }

    public mapContent(item: ChangeLogsType) {
        return JSON.parse(item.content).map((itemItem: any) => ({
            value: itemItem.value,
            path: itemItem.path,
            op: itemItem.op
        }));
    }
}
