import {Component, OnInit, OnDestroy} from '@angular/core';
import {TableFiltersType} from 'src/app/data-model/general.type';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {ConfirmDeleteModalService, EntityTypeEnum, HolidayCalendarProvider, HolidayCalendarType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {HolidayCalendarMdUtils} from './holiday-calendar-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-holiday-calendar-md',
    templateUrl: './holiday-calendar-md.component.html',
    styleUrls: ['./holiday-calendar-md.component.scss']
})
export class HolidayCalendarMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    holidayCalendarMdList: HolidayCalendarType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    HOLIDAY_CALENDAR = 'HolidayCalendar';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchHolidayCalendarChanged = new Subject<string>();

    constructor(
        public holidayCalendarMdUtils: HolidayCalendarMdUtils,
        public holidayCalendarProvider: HolidayCalendarProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('HolidayCalendarRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createHolidayCalendar') {
            this.holidayCalendarMdUtils.tableFilters = this.holidayCalendarMdUtils.getInitialTableFilter();
        }

        this.loadHolidayCalendarMdData();
        this.subscribeSearchByHolidayCalendarChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create HolidayCalendar
    createHolidayCalendar(): void {
        this.router.navigate(['/createHolidayCalendar']);
    }

    editHolidayCalendar(holidayCalendar: HolidayCalendarType) {
        this.router.navigate(['/createHolidayCalendar'], {state: {holidayCalendar, action: constants.EDIT}});
    }

    viewHolidayCalendar(holidayCalendar: HolidayCalendarType) {
        this.router.navigate(['/createHolidayCalendar'], {state: {holidayCalendar, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.holidayCalendarMdUtils.tableFilters.currentPage = page;
        this.loadHolidayCalendarMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.holidayCalendarMdUtils.tableFilters.currentPage = 1;
        this.holidayCalendarMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadHolidayCalendarMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.holidayCalendarMdUtils.tableFilters.orderBy[property];
        if (this.holidayCalendarMdUtils.tableFilters.orderBy) {
            this.holidayCalendarMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadHolidayCalendarMdData(false);
    }

    onSearchHolidayCalendarChanged(value: string) {
        this.searchHolidayCalendarChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.holidayCalendarMdUtils.tableFilters.orderBy[selectedItem];
        this.loadHolidayCalendarMdData(false);
    }

    displayConfirmDeleteItemModal(holidayCalendarId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.holidayCalendarProvider, holidayCalendarId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(holidayCalendarId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string) {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onClearFilters() {
        this.onSearchHolidayCalendarChanged('');
        this.holidayCalendarMdUtils.tableFilters = this.holidayCalendarMdUtils.getInitialTableFilter();
        this.loadHolidayCalendarMdData(true);
    }

    // function to get HolidayCalendar data
    private loadHolidayCalendarMdData(includeCount: boolean = true) {
        const queryFilter = this.holidayCalendarMdUtils.getQueryFilterForHolidayCalendarMD(this.holidayCalendarMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.holidayCalendarProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.holidayCalendarMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.holidayCalendarMdUtils.tableFilters = this.holidayCalendarMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.holidayCalendarProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadHolidayCalendarMdData();
                this.messagesService.success('toastr.success.holidayCalendarDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByHolidayCalendarChanged() {
        const self = this;
        this.searchHolidayCalendarChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.holidayCalendarMdUtils.tableFilters.filter.description = searchValue;
                self.loadHolidayCalendarMdData();
            });
    }
}
