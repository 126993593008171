import {Injectable} from '@angular/core';
import {
    AppointmentInformationOptionsType, AppointmentType, AppointmentTypeType,
    ODataQueryObjectType,
    PatientSearchOptionsType,
    PatientSearchResultType, SkedTaskType,
    SlotsActionEnum,
    SlotsFilterNameEnum,
    SlotsFiltersSelectValueType,
    SlotsUtils
} from 'sked-base';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {
    SlotDisplayType, SlotSearchAppointmentTypesType,
    SlotSearchRequestFilterType, SlotSearchResponseType,
    SlotsExtraDetailsType,
    SlotType
} from 'sked-base/lib/data-model/slotTypes';
import {
    NumberOfSlotsType,
    SlotCenterLocalFilterType,
    SlotsCentersOptionsType,
    SlotsLocalFiltersOptionsType,
    SlotsManagementCalendarDayType,
    SlotsManagementCalendarOptionsType,
    SlotsManagementCalendarPageType,
    SlotsManagementCalendarSelectedDayType,
    SlotsManagementStateType,
    SlotsTypeEnum
} from './slots-management.types';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';
import {ConfigDataService} from '../../shared/services/config-data.service';

@Injectable({
    providedIn: 'root'
})
export class SlotsManagementMdUtils {
    slotsManagementState: SlotsManagementStateType = {};
    rawSlots: SlotType[] = [];
    slotsExtraDetails: SlotsExtraDetailsType;
    groupedSlots: { [key in string]?: SlotType[] } = {};
    // The options for the calendar
    slotsCalendarOptions: SlotsManagementCalendarOptionsType;
    // The options for local filters
    slotsLocalFiltersOptions: SlotsLocalFiltersOptionsType = this.getInitialSlotsLocalFiltersOptions();
    // The options for slots centers
    slotsCentersOptions: SlotsCentersOptionsType = this.getInitialSlotsCentersOptions();
    // The options for the result list
    previousSlotsResponse: SlotSearchResponseType;
    slotsResultsOptions: SlotDisplayType[];
    noSlotsFound = false;
    specialAppointmentBooking = false;
    latestFilterValues: SlotsFiltersSelectValueType[];
    actualFilterValues: SlotsFiltersSelectValueType[]; // Before search
    filtersChangedWithNoSearch = false;
    patientHadValueAtSearch = false;
    patientChangedSinceLastCheck = false;
    filtersJustReset = false;
    searchedFilterValues: SlotsFiltersSelectValueType[]; // Filters used in last search, updates before the request is done
    // This variable is used for slot results infinite scroll to slice the entire array.
    // Only the first slotsResultsSliceUpperBound items are shown at first.
    slotsResultsSliceUpperBound: number;
    // This is the initial value for slotsResultsSliceUpperBound, and its step: every time the user
    // scrolls down enough, INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND new items are rendered.
    INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND = 50;
    // Default values for search time windows
    // These variables are updated in the slots-management component with values from system config
    appointmentInformationOptions: AppointmentInformationOptionsType = {} as AppointmentInformationOptionsType;
    appointmentInformationOverviewOptions: AppointmentInformationOptionsType = {} as AppointmentInformationOptionsType;
    objectDetailsOptions: ObjectDetailsOptionsType = {} as ObjectDetailsOptionsType;
    timeWindowMaximum = 90;
    resourceSearchStep = 168;
    serviceSearchStep = 28;
    isResourceUsedInSearch = false;
    timeWindowInDaysBasedOnSearch: number;
    previousTimeWindowInDaysBasedOnSearch: number;
    enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek: number;
    bookingSuccessful: boolean;
    // Used when the last calendar page is filled exactly with enabled (searched) days. In other words, when
    // all days in current page are searched, and the user clicks on the next page, we use this variable
    // to navigate to the next page AFTER the search for the next page is done
    shouldNavigateOnNextCalendarAfterContinueSearch: boolean;
    // TaskList context
    slotsActionContextAction: SlotsActionEnum;
    slotsActionContextTask: SkedTaskType;
    slotsActionContextAppointment: AppointmentType;
    slotsActionContextRoute: string;
    expertBookingMode = false;

    constructor(private slotsUtils: SlotsUtils,
                private configDataService: ConfigDataService) {
    }

    getQueryForServiceDuration(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'DefaultDuration']
        } as ODataQueryObjectType;
    }

    getQueryForAppointmentTypes(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name'],
            filter: {ConsumesPlannedCapacity: {eq: true}},
            orderBy: ['Name asc'],
            count: true
        } as ODataQueryObjectType;
    }

    getQueryForCenterTimezone(centerId: string): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'TimezoneId'],
            filter: {Id: {eq: {type: 'guid', value: centerId}}}
        } as ODataQueryObjectType;
    }

    getSlotForOutsideBooking(dateTime: string,
                             duration: number,
                             appointmentTypes: AppointmentTypeType[],
                             filterValues: SlotsFiltersSelectValueType[]): SlotDisplayType {
        const serviceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Service})?.value;
        const coveragePlanFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum['Coverage Plan']})?.value;
        const centerFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value;
        const resourceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Resource})?.value;
        return {
            date: moment(dateTime).format('YYYY-MM-DD'),
            time: moment(dateTime).format('HH:mm'),
            dateTime,
            duration,
            baseDuration: duration,
            resource: resourceFilterValue,
            service: serviceFilterValue?.id ? serviceFilterValue : serviceFilterValue.service,
            coveragePlan: coveragePlanFilterValue,
            center: centerFilterValue,
            availabilityId: null,
            oversellingDefinitionId: null,
            subServices: serviceFilterValue?.id ? [] : serviceFilterValue.subServices,
            appointmentType: this.slotsLocalFiltersOptions?.selectedAppointmentType,
            appointmentTypes: appointmentTypes?.length > 0
                ? appointmentTypes as SlotSearchAppointmentTypesType
                : this.slotsLocalFiltersOptions?.appointmentTypes
        } as SlotDisplayType;
    }

    getQueryFilterForSlotSearch(filterValues: SlotsFiltersSelectValueType[], patient: PatientType): SlotSearchRequestFilterType {
        const queryFilter: SlotSearchRequestFilterType = {} as SlotSearchRequestFilterType;
        if (this.previousTimeWindowInDaysBasedOnSearch === this.timeWindowInDaysBasedOnSearch) {
            queryFilter.dateFrom = moment().format();
            queryFilter.dateTo = moment().add(this.timeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
        } else {
            queryFilter.dateFrom = moment().add(this.previousTimeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
            queryFilter.dateTo = moment().add(this.timeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
        }
        queryFilter.coveragePlanId = lodash.find(filterValues, {name: SlotsFilterNameEnum['Coverage Plan']})?.value?.id;
        const serviceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Service});
        if (!!serviceFilterValue?.value?.service?.id) {
            queryFilter.serviceId = serviceFilterValue?.value?.service?.id;
            if (!!serviceFilterValue?.value?.subServices) {
                // @ts-ignore
                queryFilter.subServices = lodash.map(serviceFilterValue?.value?.subServices, 'subServiceId');
            }
        } else {
            queryFilter.serviceId = serviceFilterValue?.value?.id;
        }

        queryFilter.resourceId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Resource})?.value?.id;
        // set centerId and distance if it is selected a center, else set regionId
        this.setLocationInformation(filterValues, queryFilter);
        const adjacentSlotsValue = lodash.find(filterValues, {name: SlotsFilterNameEnum['Number of Adjacent Slots']})?.value;
        if (!!adjacentSlotsValue) {
            queryFilter.adjacentSlots = adjacentSlotsValue;
        }
        queryFilter.expertBookingMode = lodash.find(filterValues, {name: SlotsFilterNameEnum['Expert Booking']})?.value;
        queryFilter.includeNotBookable = lodash.find(filterValues, {name: SlotsFilterNameEnum['Not Bookable Slots']})?.value;
        queryFilter.searchWithoutPlannedCapacity = lodash.find(filterValues, {name: SlotsFilterNameEnum['Special Appointment Booking']})?.value;
        queryFilter.patientId = patient?.id;
        queryFilter.includeSelfPayer = this.isSelfPayerSystemConfigActive();
        if (!!this.slotsActionContextAppointment?.id) {
            queryFilter.rescheduleAppointmentId = this.slotsActionContextAppointment.id;
        }

        return queryFilter;
    }

    loadSlotsResultsOptions() {
        let slotsForDisplay: SlotDisplayType[] = [];
        let slotsPerDay = this.groupedSlots[this.slotsCalendarOptions.selectedDay?.stringDate] ?? [];
        slotsPerDay = lodash.orderBy(slotsPerDay, 'dateTime');
        // get first AM slot and first PM slot
        if (slotsPerDay?.length > 0) {
            for (const slot of slotsPerDay) {
                const slotForDisplay = this.slotsUtils.mapSlotForDisplay(slot, this.slotsExtraDetails, this.specialAppointmentBooking);

                slotsForDisplay.push(slotForDisplay);
            }
            slotsForDisplay = lodash.orderBy(
                slotsForDisplay, [
                    'dateTime',
                    (slot: SlotDisplayType) => parseInt(slot?.resource?.priority, 10),
                    (slot: SlotDisplayType) => slot?.resource?.name],
                ['asc', 'desc', 'asc']
            );
            this.setFirstAMTimeSlotAndPMTimeSlot(slotsForDisplay);
        }
        this.slotsResultsOptions = slotsForDisplay;
        // Save slots for display to state
        this.slotsManagementState.slotsResultsOptions = lodash.cloneDeep(this.slotsResultsOptions);
    }

    getInitialCalendarOptions(numberOfPages: number): SlotsManagementCalendarOptionsType {
        // The calendar should display the current month without slots before searching
        const calendarPages: SlotsManagementCalendarPageType[] = [];
        let calendarDays: SlotsManagementCalendarDayType[] = [];
        // moment().day(x) returns the x week day starting from the current week
        // More details here: https://momentjs.com/docs/#/get-set/day/
        for (let momentDayParameter = 1; momentDayParameter <= numberOfPages * 28; momentDayParameter += 1) {
            const momentDate = moment().day(momentDayParameter);
            // Create and save the day
            const day = {
                momentDate, stringDate: momentDate.format('YYYY-MM-DD'), displayDay: momentDate.format('D')
            } as SlotsManagementCalendarDayType;
            calendarDays.push(day);
            if (momentDayParameter % 28 === 0) {
                // Create and save page
                const page = {
                    calendarDays
                } as SlotsManagementCalendarPageType;
                calendarPages.push(page);
                // Reset variables
                calendarDays = [];
            }
        }
        return {
            calendarPages,
            numberOfPages,
            slotsType: SlotsTypeEnum.Normal,
            currentPage: 0,
            displayMonth: moment().format('MMMM'), // Initially display current month and year
            displayYear: moment().format('YYYY'),
            areOptionsAfterSearch: false,
        } as SlotsManagementCalendarOptionsType;
    }

    getNumberOfPagesForCalendar(): number {
        const daysInCurrentWeekBeforeToday = moment().day() - 1;
        this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek = this.timeWindowInDaysBasedOnSearch + daysInCurrentWeekBeforeToday;
        return Math.ceil(this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek / 28);
    }

    getNumberOfSlotsToDisplayInCalendarForTheseSlots(slots: SlotType[]): number {
        // Set what slot number is displayed for each day in the calendar
        if (this.specialAppointmentBooking) {
            return this.getNumberOfSlotsForSpecialAppointmentBooking(slots);
        }
        if (this.isResourceUsedInSearch) {
            // when physician search take only the the uniq slots by dateTime
            // we do this because a physician can have more services at the same hour but perform only one
            return Object.keys(lodash.groupBy(slots, 'dateTime')).length;
        } else {
            return slots.length;
        }
    }

    loadCalendarOptions(continueSearching: boolean = false): void {
        const numberOfPages = this.getNumberOfPagesForCalendar();
        const options: SlotsManagementCalendarOptionsType = this.getInitialCalendarOptions(numberOfPages);
        // These options are after the search
        options.areOptionsAfterSearch = true;
        // Disable all days before today
        this.disableAllDaysBeforeToday(options);
        // Disable all days after the search period
        this.disableAllDaysAfterSearch(options);
        // Load number of slots for each day & options
        this.slotsCalendarOptions = this.loadNumberOfSlotsPerDay(options);
        // Check if slots are normal or without planned capacity
        this.slotsCalendarOptions.slotsType = this.specialAppointmentBooking ? SlotsTypeEnum.Special : SlotsTypeEnum.Normal;
        if (!continueSearching) {
            // Preselect today
            this.preselectFirstDayWithSlots(options);
        } else {
            const previousCurrentPage = this.slotsManagementState?.slotsCalendarOptions?.currentPage;
            const previousPreviouslySelectedDay = this.slotsManagementState?.slotsCalendarOptions?.previouslySelectedDay;
            // Set previous current page
            this.slotsCalendarOptions.currentPage = previousCurrentPage + (this.shouldNavigateOnNextCalendarAfterContinueSearch ? 1 : 0);
            this.shouldNavigateOnNextCalendarAfterContinueSearch = false;
            // Set previous selected day
            this.slotsCalendarOptions
                .calendarPages[previousPreviouslySelectedDay.pageIndex]
                .calendarDays[previousPreviouslySelectedDay.dayIndex]
                .isSelected = true;
            options.selectedDay = this.slotsCalendarOptions
                .calendarPages[previousPreviouslySelectedDay.pageIndex]
                .calendarDays[previousPreviouslySelectedDay.dayIndex];
            options.previouslySelectedDay = previousPreviouslySelectedDay;
            // Update the display month and year
            this.updateCalendarDisplayMonthAndYear();
        }
        // Load the calendar options in the state
        this.slotsManagementState.slotsCalendarOptions = lodash.cloneDeep(this.slotsCalendarOptions);
    }

    loadNumberOfSlotsPerDay(options: SlotsManagementCalendarOptionsType): SlotsManagementCalendarOptionsType {
        options.calendarPages.forEach((page: SlotsManagementCalendarPageType) => {
            page.calendarDays.forEach((day: SlotsManagementCalendarDayType) => {
                if (!!this.groupedSlots[day.stringDate]) {
                    day.displaySlots = this.getNumberOfSlotsToDisplayInCalendarForTheseSlots(this.groupedSlots[day.stringDate]);
                } else {
                    day.displaySlots = undefined;
                }
            });
        });
        return options;
    }

    loadLocalFiltersOptions(displayLocalFilters: boolean = true): void {
        this.slotsLocalFiltersOptions.appointmentTypes = lodash.clone(this.slotsExtraDetails.appointmentTypes);
        // preselect appointment type
        if (this.slotsLocalFiltersOptions.appointmentTypes?.length === 1) {
            this.slotsLocalFiltersOptions.selectedAppointmentType = this.slotsLocalFiltersOptions.appointmentTypes[0];
        }
        // preselect self payer
        if (this.isSelfPayerSystemConfigActive()) {
            const foundIndex = lodash.findIndex(this.rawSlots, {isSelfPayer: true});
            this.slotsLocalFiltersOptions.selfPayer = foundIndex > -1;
        }
        this.slotsLocalFiltersOptions.displaySlotsLocalFilters = displayLocalFilters;
        // Save local filters options to state
        this.slotsManagementState.slotsLocalFiltersOptions = lodash.cloneDeep(this.slotsLocalFiltersOptions);
    }

    loadAllAvailableCentersWithNumberOfSlots() {
        const today = moment().format('YYYY-MM-DD');
        const numberOfSlotsForEachCenter: { [key in string]?: NumberOfSlotsType[] } = this.getEmptyCentersByShortId(this.rawSlots);
        // set total number of available slots
        this.setNumberOfSlots(this.rawSlots, numberOfSlotsForEachCenter);
        // set number of slots available today
        this.setNumberOfSlots(this.groupedSlots[today], numberOfSlotsForEachCenter, true);
        const searchedCenterId = lodash.find(this.searchedFilterValues ?? [], {name: SlotsFilterNameEnum.Center})?.value?.id;
        this.slotsCentersOptions.centers = lodash.orderBy(
            this.slotsExtraDetails.centers as SlotCenterLocalFilterType[],
            [
                (center) => center.id === searchedCenterId,
                (center) => Number(center.distanceMeters)
            ],
            ['desc', 'asc']
        );
        this.slotsCentersOptions.numberOfSlotsForEachCenter = numberOfSlotsForEachCenter;
        // Save the state of slots centers options
        this.slotsManagementState.slotsCentersOptions = lodash.cloneDeep(this.slotsCentersOptions);
    }

    getFilteredSlotsByCentersAndLocalFilters(): SlotType[] {
        let filteredSlots: SlotType[] = [];

        if (this.slotsCentersOptions.selectedCenters?.length > 0 &&
            (this.slotsCentersOptions.selectedCenters?.length !== this.slotsExtraDetails.centers.length)) {
            for (const center of this.slotsCentersOptions.selectedCenters) {
                filteredSlots = lodash.concat(filteredSlots, lodash.filter(this.rawSlots, {centerId: center.shortId}));
            }
        }

        if (filteredSlots.length === 0) {
            filteredSlots = this.filterSlotsByLocalFilters(this.rawSlots);
        } else {
            filteredSlots = this.filterSlotsByLocalFilters(filteredSlots);
        }

        return filteredSlots;
    }

    resetSlotResultsScrollTop() {
        const slotResultList = document.getElementsByClassName('slots-list-per-day');
        if (!!slotResultList) {
            slotResultList[0].scrollTop = 0;
        }
    }

    resetSlotResultsSliceUpperBound() {
        this.slotsResultsSliceUpperBound = this.INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND;
    }

    calculateSlotSearchMaxTimeWindowValue(filterValues: SlotsFiltersSelectValueType[] = this.searchedFilterValues) {
        // Set number of pages based on system config TimeWindowMaximum
        this.isResourceUsedInSearch = !!lodash.find(filterValues, {name: SlotsFilterNameEnum.Resource});
        this.timeWindowInDaysBasedOnSearch = Math.min(
            this.timeWindowMaximum,
            this.isResourceUsedInSearch ? this.resourceSearchStep : this.serviceSearchStep
        );
        this.previousTimeWindowInDaysBasedOnSearch = this.timeWindowInDaysBasedOnSearch;
    }

    updateCalendarDisplayMonthAndYear() {
        // Update display month with the months and years of the current page
        const currentPageIndex = this.slotsCalendarOptions.currentPage;
        const currentPage = this.slotsCalendarOptions.calendarPages[currentPageIndex];
        const months = [];
        const years = [];
        currentPage.calendarDays.forEach((day: SlotsManagementCalendarDayType) => {
            months.push(day.momentDate.format('MMMM'));
            years.push(day.momentDate.format('YYYY'));
        });
        const uniqueMonths = lodash.uniq(months);
        const uniqueYears = lodash.uniq(years);
        this.slotsCalendarOptions.displayMonth = uniqueMonths.join(' - ');
        this.slotsCalendarOptions.displayYear = uniqueYears.join(' - ');
    }

    private filterSlotsByLocalFilters(slots: SlotType[]): SlotType[] {
        let filteredSlots: SlotType[] = [];
        let isAMChecked: boolean;

        if (this.slotsLocalFiltersOptions.selectedAppointmentType?.shortId && slots?.length > 0) {
            for (const slot of slots) {
                const foundAppointmentTypeByShortId =
                    lodash.find(slot.appointmentTypes, {appointmentTypeId: this.slotsLocalFiltersOptions.selectedAppointmentType.shortId});
                if (!!foundAppointmentTypeByShortId) {
                    slot.appointmentTypeId = this.slotsLocalFiltersOptions.selectedAppointmentType?.shortId;
                    filteredSlots.push(slot);
                }
            }
        } else {
            filteredSlots = slots;
            // If selectedAppointmentType is cleared, we remove appointmentTypeId from all appointments
            if (!this.slotsLocalFiltersOptions.selectedAppointmentType) {
                filteredSlots = slots.map((slot: SlotType) => {
                    const slotCopy = lodash.cloneDeep(slot);
                    delete slotCopy.appointmentTypeId;
                    return slotCopy;
                });
            }
        }

        if (this.slotsLocalFiltersOptions.am !== this.slotsLocalFiltersOptions.pm) {
            isAMChecked = this.slotsLocalFiltersOptions.am ? this.slotsLocalFiltersOptions.am : !this.slotsLocalFiltersOptions.pm;
            filteredSlots = lodash.filter(filteredSlots, {
                isSelfPayer: this.slotsLocalFiltersOptions.selfPayer,
                AM: isAMChecked
            });
        } else if (!this.slotsLocalFiltersOptions.selfPayer) {
            // if self payer = true => return all slots else return slots without self payer
            filteredSlots = lodash.filter(filteredSlots, {isSelfPayer: this.slotsLocalFiltersOptions.selfPayer});
        }

        return filteredSlots;
    }

    getSlotsCentersModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: false,
            size: 'sm',
            windowClass: 'slots-other-centers-modal-window'
        } as NgbModalOptions;
    }

    getSlotSearchModalOptions(): NgbModalOptions {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'slot-search-modal'
        };

        return modalOptions;
    }

    getSlotWithoutPlannedCapacityModalOptions(): NgbModalOptions {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'slot-without-planned-capacity-modal'
        };

        return modalOptions;
    }

    getInitialSlotsLocalFiltersOptions(): SlotsLocalFiltersOptionsType {
        return {
            am: false,
            pm: false,
            selfPayer: false,
            appointmentTypes: [],
            selectedAppointmentType: undefined,
            displaySlotsLocalFilters: false
        };
    }

    getInitialSlotsCentersOptions(): SlotsCentersOptionsType {
        return {
            centers: [],
            selectedCenters: [],
            numberOfSlotsForEachCenter: {}
        };
    }

    isSelfPayerSystemConfigActive(): boolean {
        const systemConfig: any = this.configDataService.systemConfig;
        const foundSystemConfig = lodash.find(systemConfig?.value, (item) => {
            return item.name === 'PatientPortalAlwaysIncludeSelfPayer';
        });

        return foundSystemConfig?.value === 'true';
    }

    private disableAllDaysBeforeToday(options: SlotsManagementCalendarOptionsType): void {
        try {
            options.calendarPages.forEach((page: SlotsManagementCalendarPageType) => {
                page.calendarDays.forEach((day: SlotsManagementCalendarDayType) => {
                    if (day.momentDate.isBefore(moment(), 'day')) {
                        day.isDisabled = true;
                    } else {
                        // Already disabled all days previous to today
                        throw {};
                    }
                });
            });
        } catch {
        }
    }

    private disableAllDaysAfterSearch(options: SlotsManagementCalendarOptionsType): void {
        if (this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28 !== 0) {
            for (let dayIndex = this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28; dayIndex < 28; dayIndex += 1) {
                options.calendarPages[options.numberOfPages - 1].calendarDays[dayIndex].isDisabled = true;
            }
        }
    }

    private preselectFirstDayWithSlots(options: SlotsManagementCalendarOptionsType): void {
        try {
            options.calendarPages.forEach((page: SlotsManagementCalendarPageType, pageIndex: number) => {
                page.calendarDays.forEach((day: SlotsManagementCalendarDayType, dayIndex: number) => {
                    if (day.displaySlots > 0) {
                        // Select this day
                        day.isSelected = true;
                        options.selectedDay = day;
                        options.previouslySelectedDay = {
                            pageIndex,
                            dayIndex
                        } as SlotsManagementCalendarSelectedDayType;
                        // Go to that page
                        options.currentPage = pageIndex;
                        // Update the display month and year
                        options.displayMonth = day.momentDate.format('MMMM');
                        options.displayYear = day.momentDate.format('YYYY');
                        // Already selected first day with slots
                        throw {};
                    }
                });
            });
        } catch {
        }
    }

    private setNumberOfSlots(availableSlots: SlotType[], numberOfSlotsForEachCenter: { [key in string]?: NumberOfSlotsType[] },
                             isNumberOfSlotsAvailableToday: boolean = false): { [key in string]?: NumberOfSlotsType[] } {
        const groupedByCenter: { [key in string]?: SlotType[] } = lodash.groupBy(availableSlots, 'centerId');

        for (const key in numberOfSlotsForEachCenter) {
            if (isNumberOfSlotsAvailableToday) {
                numberOfSlotsForEachCenter[key]['numberOfSlotsAvailableToday'] = groupedByCenter[key]?.length ? groupedByCenter[key].length : 0;
            } else {
                numberOfSlotsForEachCenter[key]['totalNumberOfAvailableSlots'] = groupedByCenter[key]?.length ? groupedByCenter[key].length : 0;
            }
        }

        return numberOfSlotsForEachCenter;
    }

    private getEmptyCentersByShortId(rawSlots: SlotType[]): { [key in string]?: NumberOfSlotsType[] } {
        const groupedByCenter: { [key in string]?: NumberOfSlotsType[] } = lodash.groupBy(rawSlots, 'centerId');

        for (const key in groupedByCenter) {
            if (groupedByCenter.hasOwnProperty(key)) {
                groupedByCenter[key] = [] as NumberOfSlotsType[];
            }
        }

        return groupedByCenter;
    }

    private setLocationInformation(filterValues: SlotsFiltersSelectValueType[], queryFilter: SlotSearchRequestFilterType) {
        const location = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center});
        if (location?.value.hasOwnProperty('isParent')) {
            queryFilter.regionId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value?.locations[0].regionId;
        } else {
            queryFilter.centerId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value?.id;
            const foundDistance = lodash.find(filterValues, {name: SlotsFilterNameEnum.Distance})?.value?.value;
            if (foundDistance > 0 && queryFilter.centerId) {
                queryFilter.distance = foundDistance;
            }
        }
    }

    private setFirstAMTimeSlotAndPMTimeSlot(slotsForDisplay) {
        lodash.find(slotsForDisplay, (item) => {
            if (item.AM === true) {
                item.isFirstAMTimeSlot = true;
                return item;
            }
        });

        lodash.find(slotsForDisplay, (item) => {
            if (item.AM === false) {
                item.isFirstPMTimeSlot = true;
                return item;
            }
        });
    }

    private getNumberOfSlotsForSpecialAppointmentBooking(slots: SlotType[]): number {
        let slotsPerDay = 0;
        for (const slot of slots) {
            if (slot.appointmentTypeId) {
                slotsPerDay += lodash.find(slot.appointmentTypes, {appointmentTypeId: slot.appointmentTypeId})?.numberOfSlots;
            } else {
                slot.appointmentTypes?.forEach((appointmentType: SlotSearchAppointmentTypesType) => {
                    slotsPerDay += appointmentType?.numberOfSlots;
                });
            }
        }
        return slotsPerDay;
    }
}
