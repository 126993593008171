<div *ngIf="tagsForm" [formGroup]="tagsForm" class="form-container tags-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
    <!-- TAGS -->
    <div *ngIf="getInputByControlName(formControlNames.TAGS)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.TAGS)?.styles">
        <label translate="label.tags"></label>
        <div class="form-group tags-input custom-tag-input customSelect sbase-input-element"
             [ngClass]="{'disabled-sbaseFilter' : !getInputByControlName(formControlNames.TAGS)?.enabled}">
            <ngx-ui-loader [loaderId]="tagsLoaderId"
                           [hasProgressBar]="false"
                           fgsSize=20
                           bgsOpacity=0.2></ngx-ui-loader>
            <sbase-filter (selectedItemsOutput)="onSelectedTags($event)"
                          [buttonName]='"label.newTag"'
                          [dependentFilters]="mainDependentFilters?.tags"
                          [initialFilterValues]="tags"
                          [isMultipleSelectedItem]="true"
                          [useSelectedValueAsLabel]="true"
                          [hideSelectedItems]="true"
                          [providerInstance]="tagProvider"
                          [customTemplate]="tagsTemplate"
                          [additionalExclusionList]="createPatientUtils?.tagsExclusionList"
                          [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(tagsForm, formControlNames.TAGS)}">
            </sbase-filter>
            <ng-template let-item #tagsTemplate>
                <span [ngClass]="{'font-weight-bold black-color':item?.recommended}">{{ item?.name }}</span>
            </ng-template>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="tagsForm"
                                      [controlName]="formControlNames.TAGS">
        </app-dynamic-form-input-error>
    </div>
</div>



