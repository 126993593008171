import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AppInsightsService} from '@markpieszak/ng-application-insights';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private translateService: TranslateService,
              private appInsightsService: AppInsightsService) {
  }

  log(stringToDisplay: string, data?: any): void {
    if (!environment.IS_PRODUCTION) {
      console.log(stringToDisplay, data ? data : '');
    }
  }

  logEventRequestFail(requestError: HttpErrorResponse): void {
    const eventName = 'RequestFail';
    const properties = {
      code: requestError.status.toString(),
      message: requestError.message,
      error: requestError.error,
      name: requestError.name,
      statusText: requestError.statusText
    };
    this.logEvent(eventName, properties);
  }

  logException(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
    if (!environment.IS_PRODUCTION) {
      console.error('loggedException: ' + error);
    } else {
      // AppInsights
      this.appInsightsService.trackException(error, null, this.addGlobalProperties(properties), measurements);
    }
  }

  private logEvent(eventName: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
    if (!environment.IS_PRODUCTION) {
      console.log('loggedEventName: ' + eventName + ',properties: ' + JSON.stringify(properties));
    } else {
      // AppInsights
      this.appInsightsService.trackEvent(eventName, this.addGlobalProperties(properties), measurements);
    }

  }

  private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }
    // add your custom properties such as app version

    return properties;
  }

}
