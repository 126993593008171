import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    ConfirmActionModalService,
    ExclusionOverviewItemType,
    ExclusionProvider,
} from 'sked-base';
import {Router} from '@angular/router';
import {constants} from '../../../shared/constants/constants';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {ExclusionUtils} from '../exclusion-utils';
import {ExclusionStatusEnum} from '../exclusion.types';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-view-approve-exclusion',
    templateUrl: './view-approve-exclusion.component.html',
    styleUrls: ['view-approve-exclusion.component.scss']
})

export class ViewApproveExclusionComponent implements OnInit, OnDestroy {
    exclusion: ExclusionOverviewItemType = {} as ExclusionOverviewItemType;
    action: string;
    constants = constants;
    textToDisplay: string;
    comingFromRoute = ['/approveExclusion'];
    readonly EXCLUSION = 'Exclusion';

    constructor(public router: Router,
                public ngxLoader: NgxUiLoaderService,
                public exclusionProvider: ExclusionProvider,
                public exclusionUtils: ExclusionUtils,
                public messagesService: MessagesService,
                private confirmActionModalService: ConfirmActionModalService) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy() {
    }

    onChangeStatusExclusion(exclusion: ExclusionOverviewItemType, action: string) {
        const {center, resourceId, id, unblockAppointments, status} = exclusion;
        if (!!center && !resourceId && (status === ExclusionStatusEnum.NEW || status === ExclusionStatusEnum.EDITED) && action === constants.APPROVE) {
            this.openConfirmActionModal(id, unblockAppointments);
        } else {
            this.onChangeStatusExclusionAfterConfirmation(id, action, unblockAppointments);
        }
    }

    onChangeStatusExclusionAfterConfirmation(exclusionId: string, action: string, unblockAppointments: boolean) {
        this.ngxLoader.start();
        this.exclusionProvider.approveOrDeclineExclusion(exclusionId, action, unblockAppointments)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.ngxLoader.stop();
                if (action === constants.APPROVE) {
                    this.messagesService.success('toastr.success.blockApproved');
                } else {
                    this.messagesService.success('toastr.success.blockDeclined');
                }
                this.goToParent();
            }, err => {
                this.onError(err);
            });
    }

    goToParent() {
        this.router.navigate(this.comingFromRoute);
    }

    private openConfirmActionModal(exclusionId: string, unblockAppointments: boolean) {
        const modalTitleText = 'label.approveText';
        const modalBodyText = 'label.allAppointmentsWillBeBlocked';
        this.confirmActionModalService.displayConfirmActionModal(modalTitleText, modalBodyText).result.then((response) => {
            if (response) {
                this.onChangeStatusExclusionAfterConfirmation(exclusionId, this.action, unblockAppointments);
            }
        });
    }

    private setupInitialState() {
        if (history.state?.exclusion) {
            this.exclusion = history.state.exclusion;
            this.action = history.state.action;
            if (history.state?.view) {
                this.comingFromRoute = [history.state?.view];
            }
            this.textToDisplay = this.action !== constants.VIEW ? this.exclusionUtils.getApproveExclusionTemplateTitle(this.action) : 'label.view';
        }
    }

    private onError(error) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(error);
    }
}
