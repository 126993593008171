import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'dateMdFormatPipe'
})
export class DateMdFormatPipe extends DatePipe implements PipeTransform {
  transform(date: string, timeZone: string = ''): any {
    // @ts-ignore
    return super.transform(date, moment.localeData().longDateFormat('Md'), timeZone);
  }
}
