import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {AvailabilityComponent} from './availability.component';
import {CreateAvailabilityComponent} from './create-availability/create-availability.component';
import {ViewApproveAvailabilityComponent} from './view-approve-availability/view-approve-availability.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import { ViewAvailabilityComponent } from './view-availability/view-availability.component';
import { CreateAvailabilitySplitComponent } from './availability-split/create-availability-split/create-availability-split.component';
import { AvailabilityHistoryComponent } from './availability-history/availability-history.component';
import {AvailabilityOversellingDefinitionMDComponent} from './availability-overselling-definition-md/availability-overselling-definition-md.component';
@NgModule({
    declarations: [AvailabilityComponent, CreateAvailabilityComponent, ViewAvailabilityComponent,
        CreateAvailabilitySplitComponent, ViewApproveAvailabilityComponent, AvailabilityHistoryComponent,
        AvailabilityOversellingDefinitionMDComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        NgxMaterialTimepickerModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ]
})
export class AvailabilityModule {
}
