import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    ResourceChannelRestrictionType,
    ODataQueryObjectType,
    Expand,
    ChannelDependentFiltersType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ServiceDependentFiltersType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ResourceChannelRestrictionFiltersType} from './resource-channel-restriction.types';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceChannelRestrictionMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialFilterValues: ResourceChannelRestrictionFiltersType = {
        channel: [],
        resource: [],
        center: [],
        service: []
    };
    constructor(
        private generalUtils: GeneralUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    getInitialFilterValues(): ResourceChannelRestrictionFiltersType {
        return {
            resource: [],
            service: [],
            channel: [],
            center: []
        };
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }

    getResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            onlyDirectlyBookable: false,
            serviceId: null,
            locationId: null,
            areaId: null,
            onlyAssignedToUser: false,
            onlyAssignedToLocationsOfUser: false,
            resourceTypeId: null,
            includeSelfPayer: false,
            exclusionList: [],
            resourceTypeExclusionList: [],
            count: true,
        } as ResourceDependentFiltersType;
    }

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            onlyAssignedToLocationsOfUser: false,
            includeAvailabilities: false,
            includeChannel: true,
            searchPhrase: '',
            locationId: null,
            regionId: null,
            resourceId: null,
            areaId: null,
            hasMultiResourceBluePrint: null,
            exclusionList: [],
            count: true,
        } as ServiceDependentFiltersType;
    }

    getLocationDependentFilters(): LocationDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            onlyAssignedToUser: false,
            isRequestAllowed: false,
            locationId: null,
            regionId: null,
            resourceId: null,
            serviceId: null,
            areaId: null,
            useOnlyAssignedToUser: false,
            exclusionList: [],
            count: true
        };
    }

    getQueryFilterForResourceChannelRestrictionMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Service: {
                select: ['Id', 'Name', 'DefaultDuration', 'SpecialityId'],
                expand: {
                    Speciality: {
                        select: ['Id', 'Name']
                    }
                }
            },
            Resource: {
                select: ['Id', 'Name', 'ResourceTypeId']
            }
        };
    }

    getQueryFilterForCenter(): ODataFilterQueryType {
        return {
            select: ['Id', 'Name']
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Channel: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'resource':
                    case 'service':
                        filterQuery[lodash.upperFirst(item) + 'Id'] = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    case 'channel':
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item].enumValue};
                        break;
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item].name};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'resource':
                    case 'service':
                        orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                        break;
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialResourceChannelRestriction(): ResourceChannelRestrictionType {
        return {
            resourceId: '',
            serviceId: '',
            channel: null,
            days: null,
            centerOverrideCount: 0,
            centerOverrides: []
        } as ResourceChannelRestrictionType;
    }
}
