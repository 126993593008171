import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {RelationPairType, RelationPairProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {RelationPairsMdUtils} from '../relation-pairs-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-relation-pairs',
    templateUrl: './create-relation-pairs.component.html',
    styleUrls: ['./create-relation-pairs.component.scss']
})
export class CreateRelationPairsComponent implements OnInit, OnDestroy {
    constants = constants;
    initialRelationPairs: RelationPairType;
    relationPairsItem: RelationPairType = {} as RelationPairType;
    screenTemplateLayout: ScreenTemplateLayoutType;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private relationPairsMdUtils: RelationPairsMdUtils,
        private relationPairsProvider: RelationPairProvider,
        private router: Router,
        private generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveRelationPairsData(relationPairs: RelationPairType) {
        const isTemplateValid = this.validateFields(relationPairs);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRelationPairs(relationPairs);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRelationPairs, relationPairs)) {
                    this.messagesService.success('toastr.success.relationPairsEdit', true);
                    this.goToParentPage();
                } else {
                    this.editRelationPairs(this.initialRelationPairs, relationPairs);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/relationPairs']);
    }

    goToEdit() {
        history.replaceState({relationPairs: this.relationPairsItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
    }

    // function to create the new RelationPairs
    private createRelationPairs(relationPairs: RelationPairType) {
        this.ngxLoader.start();
        this.relationPairsProvider.addEntry(relationPairs)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newRelationPairsAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the RelationPairs
    private editRelationPairs(oldRelationPairs: RelationPairType, newRelationPairs: RelationPairType) {
        this.ngxLoader.start();
        this.relationPairsProvider.updateEntry(oldRelationPairs, newRelationPairs)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.relationPairsEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(relationPairsItem: RelationPairType): boolean {
        return !!(relationPairsItem && relationPairsItem.firstRelation && relationPairsItem.secondRelation);
    }

    private setupInitialState() {
        if (history.state && history.state.relationPairs) {
            this.initialRelationPairs = history.state.relationPairs;
            this.relationPairsItem = lodash.cloneDeep(history.state.relationPairs);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.relationPairsItem = this.relationPairsMdUtils.getInitialRelationPairs();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }
}
