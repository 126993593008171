<div class="container-fluid create-time-window-for-channel-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-time-window-header">
        <h3 class="create-time-window-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.timeWindow"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveTimeWindowData(timeWindowItem)">
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.timeWindowTab.channel"></td>
                                    <td class="text-right">{{timeWindowItem.channel}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.timeWindowTab.days"></td>
                                    <td class="text-right">{{timeWindowItem.days}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-6 form-group sbase-input-element">
                                    <label translate="label.timeWindowTab.channel"></label>
                                    <sbase-filter (selectedItemsOutput)=" onSelectedChannelFilter($event)"
                                                  [buttonName]="'label.timeWindowTab.channel' | translate"
                                                  act
                                                  [dependentFilters]="channelDependentFilters"
                                                  [hideSelectedItems]="true"
                                                  [initialFilterValues]="initialChannelValues"
                                                  [isMultipleSelectedItem]="false"
                                                  [providerInstance]="channelProvider"
                                                  [ngClass]="{'input-element-has-error': !timeWindowItem.channel && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                                  [useSelectedValueAsLabel]="true">
                                    </sbase-filter>
                                    <div
                                        *ngIf="!timeWindowItem.channel && screenTemplateLayout.action !== CONSTANTS.VIEW"
                                        class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                                <div class="col-6 form-group">
                                    <label translate="label.timeWindowTab.days"></label>
                                    <input type="number" [min]="1" [max]="MAX_NUMBER_OF_DAYS" name="days"
                                           class="input-element"
                                           [(ngModel)]="timeWindowItem.days"
                                           [ngClass]="{'input-element-has-error': !timeWindowItem.days
                                       && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                           placeholder="{{'label.timeWindowTab.days'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                        <div *ngIf="generalUtils.isEmpty(timeWindowItem.days)" class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div
                                            *ngIf="!generalUtils.isEmpty(timeWindowItem.days) && !isNumberOfDaysValid()"
                                            class="help-block">
                                            <span
                                                translate="label.error.invalidNumberOfDays"></span>{{MAX_NUMBER_OF_DAYS}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--End Body-->
            <!-- =========================================== -->

        </div>
        <!--Footer-->
        <!-- =========================================== -->
        <div class="create-entity-footer mt-2 footer-position-sticky">
            <button class="btn mr-3"
                    type="button"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn"
                    type="submit"
                    [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                    [disabled]="isSaveButtonEnabled()"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'KeyTimeWindowUpdateActivity'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
