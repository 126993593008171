import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {AppointmentTypeType} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypeMdUtils {
  tableFilters: TableFiltersType;

  constructor() {
  }

  getQueryFilterForAppointmentTypeMD(tableFilters: TableFiltersType, count: boolean = true) {
    return {
      select: ['Id', 'ShortId', 'Name', 'System', 'ConsumesPlannedCapacity', 'RowVersion'],
      count,
      skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
      top: tableFilters.itemsPerPage,
      filter: this.getFilterQuery(tableFilters.filter),
      orderBy: this.getOrderByQuery(tableFilters.orderBy)
    };
  }

  getSortAppointmentType(tableFilters: TableFiltersType) {
    return {
      filter: this.getFilterQuery(tableFilters.filter)
    };
  }

  getInitialTableFilter(): TableFiltersType {
    return {
      itemsPerPage: constants.itemsPerPage,
      currentPage: 1,
      filter: {name: ''},
      orderBy: {},
      expand: {}
    };
  }

  getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
    const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
    for (const item in filter) {
      if (item && filter[item]) {
        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
      }
    }
    return filterQuery;
  };

  getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
    const orderByQuery: string[] = [];
    for (const item in orderBy) {
      if (orderBy.hasOwnProperty(item)) {
        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
      }
    }
    //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
    return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
  }

  getInitialAppointmentType(): AppointmentTypeType {
    const appointmentType: AppointmentTypeType = {} as AppointmentTypeType;
    appointmentType.name = '';
    return appointmentType;
  }
}
