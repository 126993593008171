import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AccessType,
    CountryProvider,
    CountryType,
    EntityTypeEnum,
    FileProvider,
    FormValidationType,
    GeneralProvider,
    SkedTaskProcessingTypeEnum,
    SystemConfigProvider,
    Validations,
} from 'sked-base';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {
    SystemConfigFieldsAccessType,
    SystemConfigNameType,
    SystemConfigDataType,
    SystemConfigNameToTabMap,
    SystemConfigTabsTypeEnum,
    SystemConfigTabDataType,
    SystemConfigurationWithNgModelType,
    SystemConfigValidationsType,
    SystemConfigImagesType,
    SystemConfigFilesType
} from './system-config.type';
import * as lodash from 'lodash';
import {forkJoin, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SystemConfigurationType} from 'sked-base/lib/data-model/systemConfigurationTypes';
import {systemConfigKeys} from './system-config.constant';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {DomSanitizer} from '@angular/platform-browser';
import {of} from 'rxjs/internal/observable/of';
import {
    HumanReadableMinutesOptionsType
} from '../../shared/component/human-readable-minutes/human-readable-minutes.type';
import {flatMap} from 'rxjs/internal/operators';
import {FileEntityType} from 'sked-base/lib/data-model/fileTypes';

@AutoUnsubscribe()
@Component({
    selector: 'app-system-config',
    templateUrl: './system-config.component.html',
    styleUrls: ['./system-config.component.scss']
})
export class SystemConfigComponent implements OnInit, OnDestroy {
    systemConfigKeys = systemConfigKeys;
    // If system config fields have feature-access fill them here
    // Example:
    //  FIELDS_FEATURE_ACCESS = {
    //    SelfServicePasswordChange: 'SelfServicePasswordChangeFeatureAccess',
    //    CenterLock: 'admin-export-view',
    //  }
    FIELDS_FEATURE_ACCESS: { [key in string]?: string } = {
        [systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES]: 'skedtask-waitlistautomated',
        [systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES]: 'skedtask-waitlistautomated',
        [systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES]: 'skedtask-waitlistautomated',
        [systemConfigKeys.SKED_TASK_TYPE_STANDARD]: 'reservations',
        [systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST]: 'backoffice-wait-list',
        [systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER]: 'advancedbookingplanner',
        [systemConfigKeys.PATIENT_ACCESS_TASK_TYPE]: 'patient-access-waitlist',
        [systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE]: 'appointmentblocking-skedtask',
        [systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE]: 'patient-access-waitlist'
    };
    FIELDS_SYSTEM_CONFIG: { [key in string]?: string } = {
        [systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA]: 'AllowTenantToDisableCaptcha',
        [systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA]: 'AllowTenantToDisableCaptcha',
        [systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA]: 'AllowTenantToDisableCaptcha',
        [systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA]: 'AllowTenantToDisableCaptcha',
    };
    //// 1. Fill systemConfigName in when adding a new tab or when adding a new field
    // example:
    //  systemConfigNameType: SystemConfigNameType = {
    //      tab: ['name', 'name2', 'GenderAllowedUnknown', 'GenderAllowedMale']
    //      general: ['SelfServicePasswordChange', 'CenterLock'],
    //      anotherTab: ['logo']
    //  }
    //// 2. You can then link the [(ngModel)] and (ngModelChange) to an input in sked-base html like this:
    // [(ngModel)]="systemConfigData.tab.name.ngModel"
    // (ngModelChange)="sendSystemConfigUpdate('name')"
    SYSTEM_CONFIG_NAME: SystemConfigNameType = {
        general: [systemConfigKeys.DEFAULT_COUNTRY, systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT],
        email: [systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CREATED, systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED,
            systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED, systemConfigKeys.ACTIVATE_ICAL, systemConfigKeys.FROM_EMAIL,
            systemConfigKeys.EMAIL_DISPLAY_NAME],
        gender: [systemConfigKeys.GENDER_ALLOWED_MALE, systemConfigKeys.GENDER_ALLOWED_FEMALE, systemConfigKeys.GENDER_ALLOWED_UNDETERMINED,
            systemConfigKeys.GENDER_ALLOWED_UNKNOWN],
        location: [systemConfigKeys.RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS, systemConfigKeys.DISABLE_ALTERNATIVE_LOCATIONS,
            systemConfigKeys.SLOT_SEARCH_WITH_RANGE_ACTIVE],
        documentType: [systemConfigKeys.DOCUMENT_TYPES_DISABLE_NATIONAL_ID, systemConfigKeys.DOCUMENT_TYPES_DISABLE_PASSPORT],
        patientAccessConfig: [systemConfigKeys.CENTER_LOCK,
            systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA, systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA,
            systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA, systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA,
            systemConfigKeys.SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT,
            systemConfigKeys.PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2, systemConfigKeys.PATIENT_ACCESS_LOGO_TOP,
            systemConfigKeys.GOOGLE_TAG_MANAGER_ID, systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS,
            systemConfigKeys.PATIENT_ACCESS_TASK_TYPE,
            systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE,
            systemConfigKeys.PATIENT_ACCESS_LAST_NAME_SPLIT,
            systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER,
            systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID,
            systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL
        ],
        taskConfiguration: [systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES,
            systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES,
            systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES,
            systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE],
        reservationLifeTimes: [systemConfigKeys.SKED_TASK_TYPE_STANDARD,
            systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST,
            systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER,
        ],
        others: [systemConfigKeys.TIME_WINDOW_MAXIMUM, systemConfigKeys.SELF_SERVICE_PASSWORD_CHANGE,
            systemConfigKeys.AUTO_APPROVE_OFFICE_365_EXCEPTIONS, systemConfigKeys.RESTRICT_RESOURCE_TO_ASSIGNED_DATA,
            systemConfigKeys.APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK, systemConfigKeys.PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER,
            systemConfigKeys.NO_PAYMENT_FOR_RESCHEDULE
        ]
    };
    //// 3. If validations are needed:
    // 1. add a new entry here
    // 2. use systemConfigValidations[name].formValidation.isValid and systemConfigValidations[name].formValidation.errorMessage in html where needed
    //// (see example with systemConfigKeys.FROM_EMAIL and systemConfigKeys.TIME_WINDOW_MAXIMUM)
    systemConfigValidations: SystemConfigValidationsType = {
        [systemConfigKeys.FROM_EMAIL]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateEmail,
            argsList: [true]
        },
        [systemConfigKeys.TIME_WINDOW_MAXIMUM]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, 366, 'label.error.invalidNumberOfDaysFull']
        },
        [systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.SKED_TASK_TYPE_STANDARD]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [1, undefined, 'label.error.duration0']
        },
        [systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT]: {
            formValidation: {isValid: true, errorMessage: ''} as FormValidationType,
            validationMethod: this.validations.getValidateIntegerBetween,
            argsList: [0, undefined, 'label.error.duration0']
        },
    };

    fieldsAccess: SystemConfigFieldsAccessType = {} as SystemConfigFieldsAccessType;
    activeTab = 0;
    systemConfigTabsTypeEnum = SystemConfigTabsTypeEnum;
    spinnerId = 'system-config-loader';
    systemConfigData: SystemConfigDataType;
    systemConfigNameToTabMap: SystemConfigNameToTabMap = {};
    countries: CountryType[] = [];
    // Have a new JSON field? Add it here in template
    jsonSystemConfigs = {
        [systemConfigKeys.RESERVATION_LIFE_TIMES]: systemConfigKeys.RESERVATION_LIFE_TIMES
    };
    // Have a new image field? Add it here and in template
    imagesSystemConfigs: SystemConfigImagesType = {
        [systemConfigKeys.LOGO_NAME]: {
            name: systemConfigKeys.LOGO_NAME,
            uploadPath: 'UploadLogoImage'
        },
        [systemConfigKeys.LOGO_NAME_PATIENT_PORTAL]: {
            name: systemConfigKeys.LOGO_NAME_PATIENT_PORTAL,
            uploadPath: 'UploadLogoImagePatientPortal'
        },
        [systemConfigKeys.PRINTOUT_HEADER_NAME]: {
            name: systemConfigKeys.PRINTOUT_HEADER_NAME,
            uploadPath: 'UploadPrintOutHeaderImage'
        },
        [systemConfigKeys.EMAIL_HEADER_NAME]: {
            name: systemConfigKeys.EMAIL_HEADER_NAME,
            uploadPath: 'UploadEmailHeaderImage'
        },
        [systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL]: {
            name: systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL,
            uploadPath: 'UploadFaviconImagePatientPortal'
        }
    };
    // Have a new file field? Add it here and in template
    filesSystemConfigs: SystemConfigFilesType = {
        [systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL]: {
            name: systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL
        }
    };
    waitListTaskTypesList = Object.values(SkedTaskProcessingTypeEnum);
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        private systemConfigProvider: SystemConfigProvider,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private configDataService: ConfigDataService,
        private generalProvider: GeneralProvider,
        public validations: Validations,
        private translatedLanguageService: TranslatedLanguageService,
        private countryProvider: CountryProvider,
        private sanitizer: DomSanitizer,
        private fileProvider: FileProvider) {
    }

    ngOnInit(): void {
        this.initialSetupForSystemConfig();
        this.loadSystemConfigDataAndMore();
    }

    ngOnDestroy(): void {
    }

    getOptionsForHumanReadableMinutesComponent(elementId: string, totalMinutes: number, iconClass?: string): HumanReadableMinutesOptionsType {
        return {
            elementId,
            totalMinutes,
            iconClass: iconClass || null
        };
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.systemConfigData.patientAccessConfig[option].ngModel.hasOwnProperty('$ngOptionValue')) {
            this.systemConfigData.patientAccessConfig[option].ngModel = undefined;
        }
    }

    // Generic method, used to validate the ngModel on the field name with the method and arguments provided in systemConfigValidations
    updateValidation(name: string) {
        const TAB: SystemConfigTabsTypeEnum = this.systemConfigNameToTabMap[name];
        const NGMODEL = `${this.systemConfigData[TAB][name].ngModel}`;
        const argsList = this.systemConfigValidations[name].argsList;
        this.systemConfigValidations[name].formValidation = this.systemConfigValidations[name].validationMethod(NGMODEL, ...argsList);
    }

    updateJSONValidation(systemConfig: SystemConfigurationWithNgModelType, selectedTab: string) {
        const NGMODEL = `${systemConfig.ngModel}`;
        const argsList = this.systemConfigValidations[selectedTab]?.argsList;
        this.systemConfigValidations[selectedTab].formValidation =
            this.systemConfigValidations[selectedTab].validationMethod(NGMODEL, ...argsList);
    }

    // Generic method, can be used for any text input, whether it has validations or not
    // Called on Cancel button press
    // Resets the text input to the value saved on backend and calls updateValidation if the key has validation (is in systemConfigValidations)
    onCancelTextInput(name: string, isJSONFormat: boolean = false) {
        const TAB: SystemConfigTabsTypeEnum = this.systemConfigNameToTabMap[name];

        // Reset ngModel value to the value gotten from backend
        if (isJSONFormat) {
            this.systemConfigData[TAB][name].ngModel = JSON.parse(this.systemConfigData[TAB][name].item.value)[name];
            this.updateJSONValidation(this.systemConfigData[TAB][name], name);
        } else {
            this.systemConfigData[TAB][name].ngModel = this.systemConfigData[TAB][name].item.value;
            this.updateValidation(name);
        }

        // Call updateValidation only if validation is provided in systemConfigValidations
        if (!this.systemConfigValidations[name]) {
            return;
        }
    }

    didUserChangeTextInput(name: string, isJSONFormat: boolean = false): boolean {
        const TAB: SystemConfigTabsTypeEnum = this.systemConfigNameToTabMap[name];
        if (isJSONFormat) {
            return this.systemConfigData[TAB][name].ngModel !== JSON.parse(this.systemConfigData[TAB][name].item.value)[name];
        } else {
            return this.systemConfigData[TAB][name].ngModel !== this.systemConfigData[TAB][name].item.value;
        }
    }

    isFieldVisible(field: string): boolean {
        if (!this.fieldsAccess) {
            return false;
        }
        if (this.fieldsAccess[field] === undefined || lodash.isEmpty(this.fieldsAccess[field])) {
            return true;
        }
        // Doesn't have access only when either of attributes is false
        return !(this.fieldsAccess[field].activityDisplay === false ||
            this.fieldsAccess[field].featureAccess === false ||
            this.fieldsAccess[field].systemConfig === false);
    }

    isTabVisible(tab: SystemConfigTabsTypeEnum): boolean {
        return this.SYSTEM_CONFIG_NAME[tab].some(field => this.isFieldVisible(field));
    }

    sendSystemConfigUpdate(name: string) {
        this.ngxLoader.startLoader(this.spinnerId);
        const TAB: SystemConfigTabsTypeEnum = this.systemConfigNameToTabMap[name];
        const NGMODEL = this.systemConfigData[TAB][name].ngModel;
        const oldItem: SystemConfigurationType = this.systemConfigData[TAB][name].item;
        const newItem: SystemConfigurationType = lodash.cloneDeep(this.systemConfigData[TAB][name].item);
        // Cast any NGMODEL value to string, be it 'true', 'false', 'value' or 123
        newItem.value = `${NGMODEL}`;
        this.systemConfigProvider.updateEntry(oldItem, newItem).subscribe((response) => {
            this.onGetSystemConfigUpdate([response]);
            this.ngxLoader.stopLoader(this.spinnerId);
            this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
        }, (error) => {
            this.ngxLoader.stopLoader(this.spinnerId);
            this.messagesService.handlingErrorMessage(error);
        });
    }

    sendJSONSystemConfigUpdate(systemConfig: SystemConfigurationWithNgModelType, selectedTab: string) {
        this.ngxLoader.startLoader(this.spinnerId);
        const NGMODEL = systemConfig.ngModel;
        const oldItem: SystemConfigurationType = systemConfig.item;
        const newItem: SystemConfigurationType = lodash.cloneDeep(systemConfig.item);
        const values = JSON.parse(newItem.value);
        values[selectedTab] = NGMODEL;
        newItem.value = JSON.stringify(values);
        this.systemConfigProvider.updateEntry(oldItem, newItem).subscribe((response) => {
            this.onGetSystemConfigJSONUpdate([response]);
            this.ngxLoader.stopLoader(this.spinnerId);
            this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
        }, (error) => {
            this.ngxLoader.stopLoader(this.spinnerId);
            this.messagesService.handlingErrorMessage(error);
        });
    }

    ////////////////////////////
    // Used only on image fields
    // vvvvvvvvvvvvvvvvvvvvvvvvv

    onImageChange(name: string, event: Event) {
        // Called whenever an image is uploaded in any image field (user pressed Upload image and selected an image)
        if ((event.target as HTMLInputElement)?.files?.length > 0) {
            const FILE = (event.target as HTMLInputElement).files[0];
            const READER = new FileReader();
            this.imagesSystemConfigs[name].blob = FILE;
            this.imagesSystemConfigs[name].error = undefined;
            READER.onload = (readerEvent) => {
                this.imagesSystemConfigs[name].src = this.sanitizer.bypassSecurityTrustUrl(readerEvent.target.result as string) as string;
            };
            READER.readAsDataURL(FILE);
        }
    }

    sendImageSystemConfigUpdate(name: string) {
        // This method gets called when the user clicks on the Save button next to image fields
        this.ngxLoader.startLoader(this.spinnerId);
        this.systemConfigProvider.uploadSystemConfigurationImages(
            this.imagesSystemConfigs[name].uploadPath, this.imagesSystemConfigs[name].blob
        ).subscribe(response => {
            this.imagesSystemConfigs[name].error = undefined;
            this.ngxLoader.stopLoader(this.spinnerId);
            this.imagesSystemConfigs[name].responseSrc = lodash.cloneDeep(this.imagesSystemConfigs[name].src);
            this.imagesSystemConfigs[name].responseBlob = lodash.cloneDeep(this.imagesSystemConfigs[name].blob);
            this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
        }, (error) => {
            this.imagesSystemConfigs[name].error = error?.error ?? undefined;
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stopLoader(this.spinnerId);
        });
    }

    didUserChangeImageInput(name: string): boolean {
        // Checks whether to keep the Save and Cancel buttons disabled or not based on whether the current src is the same
        // as the src given by the server
        return !lodash.isEqual(this.imagesSystemConfigs[name].src, this.imagesSystemConfigs[name].responseSrc);
    }

    onCancelImageInput(name: string) {
        // Called when user clicks on the Cancel button next to image fields
        this.imagesSystemConfigs[name].src = lodash.cloneDeep(this.imagesSystemConfigs[name].responseSrc);
        this.imagesSystemConfigs[name].blob = lodash.cloneDeep(this.imagesSystemConfigs[name].responseBlob);
        this.imagesSystemConfigs[name].error = undefined;
    }

    // ^^^^^^^^^^^^^^^^^^^^^^^^^
    // Used only on image fields
    ////////////////////////////

    ////////////////////////////
    // Used only on file fields
    // vvvvvvvvvvvvvvvvvvvvvvvvv

    onFileChange(name: string, event: Event) {
        if ((event.target as HTMLInputElement)?.files?.length > 0) {
            const FILE = (event.target as HTMLInputElement).files[0];
            const READER = new FileReader();
            READER.readAsBinaryString(FILE);
            READER.onload = (readerEvent) => {
                const {result} = readerEvent.target as any;
                this.filesSystemConfigs[name].src = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(FILE)) as string;
                this.filesSystemConfigs[name].fileContent = {
                    name: FILE.name,
                    content: btoa(result)
                };
            };
        }
    }

    uploadResourceDefaultImage(name: string) {
        this.ngxLoader.startLoader(this.spinnerId);
        this.fileProvider.uploadResourceDefaultImage(this.filesSystemConfigs[name].fileContent)
            .subscribe((response: FileEntityType) => {
                this.filesSystemConfigs[name].error = undefined;
                this.filesSystemConfigs[name].responseSrc = lodash.cloneDeep(this.filesSystemConfigs[name].src);
                this.filesSystemConfigs[name].responseBlob = lodash.cloneDeep(this.filesSystemConfigs[name].blob);
                this.filesSystemConfigs[name].fileId = lodash.cloneDeep(response.id);
                this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
                this.ngxLoader.stopLoader(this.spinnerId);
            }, (error) => {
                this.filesSystemConfigs[name].error = error?.error ?? undefined;
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stopLoader(this.spinnerId);
            });
    }

    deleteResourceDefaultImage(name: string) {
        if (!this.filesSystemConfigs[name].fileId) {
            this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
            this.clearFilesSystemConfig(name);
            return;
        }
        this.ngxLoader.startLoader(this.spinnerId);
        this.fileProvider.deleteFileForEntity(
            EntityTypeEnum.systemConfiguration,
            this.systemConfigData.patientAccessConfig[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].item.id,
            this.filesSystemConfigs[name].fileId
        ).subscribe(() => {
            this.clearFilesSystemConfig(name);
            this.messagesService.success('toastr.success.systemConfigUpdatedWithSuccess', true);
            this.ngxLoader.stopLoader(this.spinnerId);
        }, (error) => {
            this.filesSystemConfigs[name].error = error?.error ?? undefined;
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stopLoader(this.spinnerId);
        });
    }

    clearFilesSystemConfig(name: string) {
        this.filesSystemConfigs[name].error = undefined;
        this.filesSystemConfigs[name].name = undefined;
        this.filesSystemConfigs[name].responseSrc = undefined;
        this.filesSystemConfigs[name].responseSrc = undefined;
        this.filesSystemConfigs[name].responseBlob = undefined;
        this.filesSystemConfigs[name].fileId = undefined;
        this.filesSystemConfigs[name].fileContent = undefined;
        this.filesSystemConfigs[name].src = undefined;
    }

    didUserChangeFileInput(name: string): boolean {
        // Checks whether to keep the Save and Cancel buttons disabled or not based on whether the current src is the same
        // as the src given by the server
        return !lodash.isEqual(this.filesSystemConfigs[name].src, this.filesSystemConfigs[name].responseSrc);
    }

    onCancelFileInput(name: string) {
        // Called when user clicks on the Cancel button next to image fields
        this.filesSystemConfigs[name].src = lodash.cloneDeep(this.filesSystemConfigs[name].responseSrc);
        this.filesSystemConfigs[name].blob = lodash.cloneDeep(this.filesSystemConfigs[name].responseBlob);
        this.filesSystemConfigs[name].error = undefined;
    }

    // ^^^^^^^^^^^^^^^^^^^^^^^^^
    // Used only on file fields
    ////////////////////////////

    private initialSetupForSystemConfig() {
        this.setAccessForFields();
        this.setActiveTab();
        this.mapNamesToTabs();
    }

    private setAccessForFields() {
        // Get feature access for each field based on FIELDS_FEATURE_ACCESS
        Object.keys(this.FIELDS_FEATURE_ACCESS).forEach(field => {
            this.fieldsAccess[field] = {} as AccessType;
        });
        Object.keys(this.FIELDS_SYSTEM_CONFIG).forEach(field => {
            this.fieldsAccess[field] = {} as AccessType;
        });

        // Set field access from feature access
        for (const field of Object.keys(this.FIELDS_FEATURE_ACCESS)) {
            this.fieldsAccess[field].featureAccess = !!this.configDataService.isFeatureActive(this.FIELDS_FEATURE_ACCESS[field]);
        }
        // Set fields access from system config
        for (const field of Object.keys(this.FIELDS_SYSTEM_CONFIG)) {
            this.fieldsAccess[field].systemConfig =
                lodash.find(this.configDataService.systemConfig.value, {name: this.FIELDS_SYSTEM_CONFIG[field]}).value === 'true';
        }
    }

    private setActiveTab() {
        this.activeTab = 0;
        if (this.isTabVisible(SystemConfigTabsTypeEnum.general)) {
            this.activeTab = 1;
            return;
        }
    }

    private mapNamesToTabs() {
        Object.keys(this.SYSTEM_CONFIG_NAME).forEach((tab: SystemConfigTabsTypeEnum) => {
            this.SYSTEM_CONFIG_NAME[tab].forEach((name: string) => {
                this.systemConfigNameToTabMap[name] = tab;
            });
        });
    }

    private getInitialSystemConfigData(): SystemConfigDataType {
        const systemConfigDataType: SystemConfigDataType = {} as SystemConfigDataType;
        Object.keys(this.SYSTEM_CONFIG_NAME).forEach((tab: SystemConfigTabsTypeEnum) => {
            systemConfigDataType[tab] = {} as SystemConfigTabDataType;
            this.SYSTEM_CONFIG_NAME[tab].forEach((name: string) => {
                systemConfigDataType[tab][name] = {
                    item: {} as SystemConfigurationType,
                    ngModel: undefined
                } as SystemConfigurationWithNgModelType;
            });
        });
        return systemConfigDataType;
    }

    private loadSystemConfigDataAndMore() {
        this.systemConfigData = this.getInitialSystemConfigData();
        this.ngxLoader.startLoader(this.spinnerId);
        const translatedLanguage = this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage);
        // Send get request with filter over all names in all tabs (example: GenderAllowedMale, SelfServicePasswordChange, etc)
        const NAMES = Object.keys(this.systemConfigNameToTabMap);
        const IMAGE_NAMES = Object.keys(this.imagesSystemConfigs);
        const FILE_NAMES = Object.keys(this.filesSystemConfigs);
        const JSON_NAMES = Object.keys(this.jsonSystemConfigs);
        const ALL_NAMES = lodash.uniq(lodash.concat(NAMES, IMAGE_NAMES, JSON_NAMES));
        forkJoin([
            this.getSystemConfigByNames(ALL_NAMES),
            this.countryProvider.getCountriesForLanguage(translatedLanguage)
        ]).subscribe(([allItems, countries]) => {
            // Filter the results: separate the image fields
            const systemConfigItemsWithoutImages = allItems.filter((item: SystemConfigurationType) => lodash.includes(NAMES, item.name));
            const systemConfigImagesItems = allItems.filter((item: SystemConfigurationType) => lodash.includes(IMAGE_NAMES, item.name));
            const systemConfigFilesItems = allItems.filter((item: SystemConfigurationType) => lodash.includes(FILE_NAMES, item.name));
            const systemConfigJSONItems = allItems.filter((item: SystemConfigurationType) => lodash.includes(JSON_NAMES, item.name));
            this.countries = lodash.orderBy(countries.value, ['countryName']);
            this.onGetSystemConfigUpdate(systemConfigItemsWithoutImages);
            this.onGetSystemConfigImagesUpdate(systemConfigImagesItems);
            this.onGetSystemConfigFilesUpdate(systemConfigFilesItems);
            this.onGetSystemConfigJSONUpdate(systemConfigJSONItems);
            this.ngxLoader.stopLoader(this.spinnerId);
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stopLoader(this.spinnerId);
        });
    }

    // Used only for images fields
    private onGetSystemConfigImagesUpdate(data: SystemConfigurationType[]) {
        // For each image system configuration item that we get from back-end,
        // send a request to download the image
        const downloadImagesObservables = data.map((imageItem: SystemConfigurationType) => {
            if (imageItem.value === '') {
                // If system config doesn't have a value we don't make request
                return of(undefined);
            }
            // return this.http.get(FULL_URL, {responseType: 'blob'}).pipe(
            return this.generalProvider.downloadImage(imageItem.name).pipe(
                catchError(error => of(undefined))
            );
        });
        if (downloadImagesObservables.length > 0) {
            this.ngxLoader.startLoader(this.spinnerId);
        }
        forkJoin(downloadImagesObservables).subscribe(responses => {
            responses.forEach((response, index) => {
                if (response === undefined) {
                    return;
                }
                const BLOB = new Blob([response]);
                const READER = new FileReader();
                this.imagesSystemConfigs[data[index].name].blob = BLOB;
                this.imagesSystemConfigs[data[index].name].responseBlob = BLOB;
                READER.onload = (readerEvent) => {
                    this.imagesSystemConfigs[data[index].name].src =
                        this.sanitizer.bypassSecurityTrustUrl(readerEvent.target.result as string) as string;
                    this.imagesSystemConfigs[data[index].name].responseSrc =
                        this.sanitizer.bypassSecurityTrustUrl(readerEvent.target.result as string) as string;
                };
                READER.readAsDataURL(BLOB);
                this.ngxLoader.stopLoader(this.spinnerId);
            });
        }, (error) => {
            this.ngxLoader.stopLoader(this.spinnerId);
        });
    }

    // Used only for files fields
    private onGetSystemConfigFilesUpdate(data: SystemConfigurationType[]) {
        // For each file system configuration item that we get from back-end,
        // send a request to download the file
        const getFileEntitiesForEntityObservables = data.map((fileItem: SystemConfigurationType) => {
            if (!fileItem.id) {
                // If system config doesn't have an id we don't make request
                return of(undefined);
            }
            return this.fileProvider.getFileEntitiesForEntity(EntityTypeEnum.systemConfiguration, fileItem.id).pipe(
                flatMap((response) => {
                    const fileId = response?.value[0]?.id;
                    if (fileId) {
                        return this.fileProvider.getFileById(fileId).pipe(map((fileResponse) => ({
                            ...fileResponse,
                            fileId,
                        })));
                    } else {
                        return of({file: {}});
                    }
                }),
                catchError(error => of(undefined))
            );
        });
        if (getFileEntitiesForEntityObservables.length > 0) {
            this.ngxLoader.startLoader(this.spinnerId);
        }
        forkJoin(getFileEntitiesForEntityObservables).subscribe(responses => {
            responses.forEach((file, index) => {
                if (file === undefined) {
                    return;
                }
                const READER = new FileReader();
                this.filesSystemConfigs[data[index].name].blob = file.file;
                this.filesSystemConfigs[data[index].name].responseBlob = file.file;
                this.filesSystemConfigs[data[index].name].fileId = file.fileId;
                READER.onload = (readerEvent) => {
                    this.filesSystemConfigs[data[index].name].src = this.sanitizer.bypassSecurityTrustUrl(
                        readerEvent.target.result as string) as string;
                    this.filesSystemConfigs[data[index].name].responseSrc = this.sanitizer.bypassSecurityTrustUrl(
                        readerEvent.target.result as string) as string;
                };
                READER.readAsDataURL(file.file);
                this.ngxLoader.stopLoader(this.spinnerId);
            });
        }, (err) => {
            if (err.status === 400) {
                err?.error?.text()?.then((errorJsonString: string) => {
                    const errorJson = JSON.parse(errorJsonString);
                    if ((!!errorJson?.value && errorJson?.value !== 'File not found in storage')
                        || (!!errorJson?.error?.message && errorJson?.error?.message !== 'File not found in storage')) {
                        this.messagesService.handlingErrorMessage(err);
                    }
                });
            } else {
                this.messagesService.handlingErrorMessage(err);
            }
            this.ngxLoader.stopLoader(this.spinnerId);
        });
    }

    // Used only for JSON fields
    private onGetSystemConfigJSONUpdate(data: SystemConfigurationType[]) {
        // For each JSON system configuration item that we get from back-end
        data.forEach((systemConfigurationItem: SystemConfigurationType) => {
            const NAME: string = systemConfigurationItem.name;
            const VALUES: string = JSON.parse(systemConfigurationItem.value);
            const TAB = lodash.lowerFirst(this.jsonSystemConfigs[NAME]);
            Object.keys(VALUES).forEach(value => {
                this.systemConfigData[TAB][value] = {
                    item: systemConfigurationItem,
                    ngModel: VALUES[value]
                };
            });
        });
    }

    private onGetSystemConfigUpdate(data: SystemConfigurationType[]) {
        // for each system configuration item that we get from back-end
        data.forEach((systemConfigurationItem: SystemConfigurationType) => {
            const NAME: string = systemConfigurationItem.name;
            const VALUE: string = systemConfigurationItem.value;
            const TAB: SystemConfigTabsTypeEnum = this.systemConfigNameToTabMap[NAME];
            // ngModel is:
            //  true if value is 'true',
            //  false if value is 'false',
            //  value (a string) if value is neither 'true' or 'false'
            const NGMODEL = VALUE === 'true' || VALUE === 'false' ? VALUE === 'true' : VALUE;
            this.systemConfigData[TAB][NAME] = {
                item: systemConfigurationItem,
                ngModel: NGMODEL
            };
        });
    }

    private getSystemConfigByNames(names: string[]): Observable<SystemConfigurationType[]> {
        return this.systemConfigProvider.getAllowedConfigurations({select: ['Id', 'Name', 'Value', 'RowVersion']}).pipe(
            map((response: { value: SystemConfigurationType[], count?: number }) => {
                return lodash.filter(response.value, (systemConfigItem: SystemConfigurationType) => lodash.includes(names, systemConfigItem.name));
            })
        );
    }
}
