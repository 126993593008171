<div class="container-fluid appointment-event-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.appointmentEventRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                        [contentTemplate]="appointmentEventRuleModalFiltersContentTemplate"
                        [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                        [title]="'label.filterMore'"
                        (modalClose)="onModalClose($event)"
                ></sbase-show-template-modal-filter-container>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #appointmentEventRuleModalFiltersContentTemplate>
        <div class="appointment-event-rule-filter-more-modal-container">
            <!-- Event type -->
            <div class="task-list-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.eventTypeFilterOptions">
                <label translate="label.eventType" class="text-high-contrast"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.eventType' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.eventTypeFilterOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.eventTypeFilterOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'eventTypeFilterOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option *ngFor="let type of eventTypeEnumList"
                               [value]="type">
                        <span [translate]="'enum.eventType.' + type"></span>
                    </ng-option>
                </ng-select>
            </div>

            <!-- Center tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.centerTagOptions">
                <label translate="label.centerTag" class="text-high-contrast">
                </label>
                <div>
                    <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.CENTER, $event)"
                                  [buttonName]='"label.newTag"'
                                  [dependentFilters]="mainDependentFilters?.centerTags"
                                  [initialFilterValues]="rulesUtils.overviewState.modalFilters.centerTagOptions.ngModel"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="tagProvider">
                    </sbase-filter>
                </div>
            </div>

            <!-- Entity type -->
            <div class="task-list-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.entityTypeFilterOptions">
                <label translate="label.entityType" class="text-high-contrast"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.entityType' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.entityTypeFilterOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.entityTypeFilterOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'entityTypeFilterOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option [value]="'Appointment'">
                        <span>{{'enum.entityType.Appointment' | translate}}</span>
                    </ng-option>
                </ng-select>
            </div>

            <!-- Service tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label translate="label.serviceTag" class="text-high-contrast">
                </label>
                <div>
                    <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.SERVICE, $event)"
                                  [buttonName]='"label.newTag"'
                                  [dependentFilters]="mainDependentFilters?.centerTags"
                                  [initialFilterValues]="rulesUtils.overviewState.modalFilters.serviceTagOptions.ngModel"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="tagProvider">
                    </sbase-filter>
                </div>
            </div>

            <!-- Status -->
            <div class="task-list-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.appointmentStatusOptions">
                <label translate="label.status" class="text-high-contrast"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.status' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.appointmentStatusOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.appointmentStatusOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'appointmentStatusOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option *ngFor="let status of appointmentStatusEnumList"
                               [value]="status">
                        {{'enum.appointmentStatus.' + status | translate}}
                    </ng-option>
                </ng-select>
            </div>

            <!-- Resource tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.resourceTagOptions">
                <label translate="label.resourceTag" class="text-high-contrast">
                </label>
                <div>
                    <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.RESOURCE, $event)"
                                  [buttonName]='"label.newTag"'
                                  [dependentFilters]="mainDependentFilters?.resourceTags"
                                  [initialFilterValues]="rulesUtils.overviewState.modalFilters.resourceTagOptions.ngModel"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="tagProvider">
                    </sbase-filter>
                </div>
            </div>

            <!-- Event action type -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.eventActionTypeOptions">
                <label translate="label.eventAction" class="text-high-contrast">
                </label>
                <div>
                    <sbase-filter (selectedItemsOutput)="onSelectedEventAction($event)"
                                  [buttonName]='"label.choose"'
                                  [dependentFilters]="eventActionDependentFilters"
                                  [initialFilterValues]="rulesUtils.overviewState.modalFilters.eventActionTypeOptions.ngModel"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="eventActionProvider">
                    </sbase-filter>
                </div>
            </div>

            <!-- Patient tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.patientTagOptions">
                <label translate="label.patientTag" class="text-high-contrast">
                </label>
                <div>
                    <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.PATIENT, $event)"
                                  [buttonName]='"label.newTag"'
                                  [dependentFilters]="mainDependentFilters?.patientTags"
                                  [initialFilterValues]="rulesUtils.overviewState.modalFilters.patientTagOptions.ngModel"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="tagProvider">
                    </sbase-filter>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active sorting -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
            <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] | translate}}
                <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchAppointmentEventRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="APPOINTMENT_EVENT_RULE+CONSTANTS.CREATE"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="APPOINTMENT_EVENT_RULE+CONSTANTS.CREATE"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <!-- Table headers -->
            <tr>
                <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                <ng-container *ngFor="let column of tableHeaderColumns">
                    <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'"
                        (click)="onSortBy(column.onSortByProperty)">
                        {{column.label | translate}}
                        <button type="button" class="btn p-0"
                                *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                            <i class="fas fa-sort"></i>
                        </button>
                        <button type="button" class="btn p-0"
                                *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                            <i class="fas fa-sort-down"></i>
                        </button>
                        <button type="button" class="btn p-0"
                                *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                            <i class="fas fa-sort-up"></i>
                        </button>
                    </th>
                    <th *ngIf="!column.hasSort" [ngClass]="column.class" [translate]="column.label"></th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <!-- Table rows -->
            <tr *ngFor="let rule of ruleList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewRule(rule)">
                <td class="name-column">{{rule.appointmentEventRuleTable?.name}}</td>
                <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                <td class="name-column">{{rule.name}}</td>
                <td class="text-center">{{rule.priority}}</td>
                <td class="text-center">{{'enum.entityType.' + rule.entityType | translate}}</td>
                <td class="text-right"
                    *ngIf="rule.eventType">{{'enum.eventType.' + rule.eventType | translate}}</td>
                <td class="text-right" *ngIf="!rule.eventType"></td>
                <td class="text-right"
                    *ngIf="rule.appointmentStatus">{{'enum.appointmentStatus.' + rule.appointmentStatus | translate}}</td>
                <td class="text-right" *ngIf="!rule.appointmentStatus"></td>
                <td class="text-center">
                    <i class="fas fa-plus-circle"
                       ngbTooltip="{{'label.include'| translate}}"
                       *ngIf="rulesUtils.displayIncludeIcon(rule.centerTag, rule.includeCenterTag)"></i>
                    <i class="fas fa-minus-circle"
                       ngbTooltip="{{'label.exclude'| translate}}"
                       *ngIf="rulesUtils.displayExcludeIcon(rule.centerTag, rule.includeCenterTag)"></i>
                    &nbsp;{{rule.centerTag?.name}}</td>
                <td class="text-center">
                    <i class="fas fa-plus-circle"
                       ngbTooltip="{{'label.include'| translate}}"
                       *ngIf="rulesUtils.displayIncludeIcon(rule.serviceTag, rule.includeServiceTag)"></i>
                    <i class="fas fa-minus-circle"
                       ngbTooltip="{{'label.exclude'| translate}}"
                       *ngIf="rulesUtils.displayExcludeIcon(rule.serviceTag, rule.includeServiceTag)"></i>
                    &nbsp;{{rule.serviceTag?.name}}</td>
                <td class="text-center">
                    <i class="fas fa-plus-circle"
                       ngbTooltip="{{'label.include'| translate}}"
                       *ngIf="rulesUtils.displayIncludeIcon(rule.resourceTag, rule.includeResourceTag)"></i>
                    <i class="fas fa-minus-circle"
                       ngbTooltip="{{'label.exclude'| translate}}"
                       *ngIf="rulesUtils.displayExcludeIcon(rule.resourceTag, rule.includeResourceTag)"></i>
                    &nbsp;{{rule.resourceTag?.name}}</td>
                <td class="text-center">
                    <i class="fas fa-plus-circle"
                       ngbTooltip="{{'label.include'| translate}}"
                       *ngIf="rulesUtils.displayIncludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                    <i class="fas fa-minus-circle"
                       ngbTooltip="{{'label.exclude'| translate}}"
                       *ngIf="rulesUtils.displayExcludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                    &nbsp;{{rule.patientTag?.name}}</td>
                <td class="text-center">{{rule.eventAction?.name}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewRule(rule)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editRule(rule)"
                                        [activityDisplay]="APPOINTMENT_EVENT_RULE+CONSTANTS.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(rule.id)"
                                        [activityDisplay]="APPOINTMENT_EVENT_RULE+CONSTANTS.DELETE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(rule.id, EntityTypeEnum.appointmentEventRule, rule.name)"
                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
