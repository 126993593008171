<div class="container-fluid create-tag-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-tag-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase"
                                                                                        translate="label.tag"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div *ngIf="screenTemplateLayout.action !== constants.CREATE" class="col-3">
                        <div class="form-group">
                            <label translate="label.shortId"></label>
                            <input [(ngModel)]="tagItem.shortId" class="input-element"
                                   name="shortId"
                                   placeholder="{{'label.shortId'|translate}}"
                                   type="text">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input [(ngModel)]="tagItem.name" autocomplete="off" class="input-element"
                                   [ngClass]="{'input-element-has-error': !tagItem.name}"
                                   name="name"
                                   placeholder="{{'label.name'|translate}}"
                                   type="text">
                            <div *ngIf="!tagItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.code"></label>
                            <input [(ngModel)]="tagItem.code" autocomplete="off" class="input-element"
                                   name="code"
                                   placeholder="{{'label.code'|translate}}"
                                   type="text">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Scoped for entity -->
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.scopedForEntity"></label><br/>
                            <!-- Centers -->
                            <label translate="label.center"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.scopedCenter"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="scopedCenter"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                            <!-- Coverage plans -->
                            <label translate="label.coveragePlan"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.scopedCoveragePlan"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="scopedCoveragePlan"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                            <!-- Patients -->
                            <div [featureDisplay]="'patient-tags'" [activityDisplay]="'PatientTagsManager'">
                                <label translate="label.patients"></label>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input [(ngModel)]="tagItem.scopedPatient"
                                               [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                               checked
                                               name="scopedPatient"
                                               type="checkbox"
                                        >
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- Services -->
                            <label translate="label.service"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.scopedService"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="scopedService"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                            <!-- Specialities -->
                            <label translate="label.speciality"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.scopedSpeciality"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="scopedSpeciality"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                            <!-- Resource -->
                            <label translate="label.resources"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.scopedResource"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="scopedResource"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Enabled for rules -->
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.enabledForRules"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.enabledForRules"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="enabledForRules"
                                           type="checkbox"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.calculated"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input [(ngModel)]="tagItem.calculated"
                                           [disabled]="screenTemplateLayout.action=== constants.VIEW"
                                           checked
                                           name="calculated"
                                           type="checkbox">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{tagItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{tagItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.code"></td>
                                    <td class="text-right">{{tagItem.code}}</td>
                                </tr>
                                <tbody>
                            </table>
                            <label translate="label.scopedForEntity"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.center"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedCenter" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedCenter" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.coveragePlan"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedCoveragePlan" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedCoveragePlan" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr [featureDisplay]="'patient-tags'" [activityDisplay]="'PatientTagsManager'">
                                    <td translate="label.patients"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedPatient" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedPatient" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.service"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedService" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedService" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.speciality"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedSpeciality" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedSpeciality" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.resources"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.scopedResource" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.scopedResource" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.enabledForRules"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.enabledForRules"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.enabledForRules" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.enabledForRules" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.calculated"></td>
                                    <td class="text-right">
                                        <span *ngIf="tagItem.calculated" translate="label.yes"></span>
                                        <span *ngIf="!tagItem.calculated" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button (click)="goToParentPage()" class="btn mr-3"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button (click)="saveTagData(tagItem)" [disabled]="!tagItem.name"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                class="btn theme-btn"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'TagUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!-- =========================================== -->
    <!--End Footer-->
</div>
