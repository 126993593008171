<div class="container-fluid change-log-md-wrapper overview-screen-md pb-1">
    <div class="change-log-filters-container mb-2">
        <div class="row mb-0 ml-0 mx-1 pt-1 cursor-pointer" (click)="isFilterCollapsed = !isFilterCollapsed">
            <div class="col pl-0 text-left">
                <h3 translate="label.changeLog" class="text-left title"></h3>
            </div>
            <div class="col text-right align-self-center">
                <i class="btn btn-sm fas"
                   [ngClass]="isFilterCollapsed ? 'fa-angle-down':'fa-angle-up'"
                   [attr.aria-expanded]="isFilterCollapsed">
                </i>
            </div>
        </div>
        <div [ngbCollapse]="isFilterCollapsed" class="row mb-0 mx-1 pl-0">
            <div class="col-10 custom-col-10">
                <div class="row mt-2">
                    <div class="col-4 ml-0 custom-col-4 form-group pl-0">
                        <ng-select
                            class="form-control rounded-1 custom-ng-select"
                            loadingText="{{'label.loading' | translate}}"
                            dropdownPosition="bottom"
                            placeholder="{{'label.entityType' | translate}}"
                            [(ngModel)]="filters.entityType.ngModel"
                            [multiple]="false"
                            [searchable]="true"
                            [clearable]="true"
                            [ngClass]="!!filters.entityType.ngModel ? 'ng-select-has-value' : 'ng-select-is-invalid'"
                            [ngModelOptions]="{standalone: true}">
                            <ng-option *ngFor="let entity of EntityTypeList"
                                       [value]="entity">
                                <span [translate]="'enum.entityType.' + entity"></span>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-4 ml-0 custom-col-4 form-group pl-0">
                        <input type="text"
                               placeholder="{{'label.entityId' | translate}}"
                               [ngClass]="{'input-is-invalid': !filters.entityId.ngModel}"
                               [(ngModel)]="filters.entityId.ngModel"
                               class="input-element-all-sides-border rounded">
                    </div>
                </div>
            </div>
            <div class="col-2 custom-col-2">
                <button class="action-button mt-3 reset-button btn" (click)="onClear()">
                    <i class="far fa-trash-alt custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast"
                          translate='label.resetFilters'></span>
                </button>
            </div>
            <div class="col-1 custom-col-1 action-button change-log-filters-search no-block my-2">
                <button class="search-button"
                        [ngClass]="!filters.entityId.ngModel || !filters.entityType.ngModel ? 'disabled' : null"
                        (click)="onSearch()">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </div>

    <app-change-logs
        [options]="changeLogOptions"
        [changeLogsEmitter]="getChangeLog"
    ></app-change-logs>
</div>
