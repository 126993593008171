import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {RoomReservationUtils} from './room-reservation.utils';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {
    BaseReservationTimeSlotType,
    ConflictReservationInstanceType,
    DeleteReservationFiltersType, EditReservationFiltersType, MoveReservationFiltersType,
    ReservationInstanceType, ReservationResultType,
    RoomReservationProvider
} from 'sked-base';
import {MessagesService} from '../../shared/services/messages.service';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {constants} from '../../shared/constants/constants';
import {roomReservationConstants} from './room-reservation.constants';
import {BackOfficeChangeAssignationType} from './room-reservation.types';

@Component({
    templateUrl: './create-reservation-modal.component.html',
    styleUrls: ['./create-reservation-modal.component.scss']
})
export class CreateReservationModalComponent implements OnInit, OnDestroy {
    @Input() resourceTimeSlot;
    @Input() calendarTimeSlots;
    @Input() action;

    actionBtnLabel: string;
    showReservationConflicts = false;
    showTimeSlotAlternative = false;
    showBackButton = false;
    showActionButton = false;
    isTimeValid = true;
    isDateValid = true;
    currentPage = 1;
    assignationType: any;
    changeAssignationType: BackOfficeChangeAssignationType;
    splitOption: any;
    startDate: NgbDateStruct = {} as NgbDateStruct;
    endDate: NgbDateStruct = {} as NgbDateStruct;
    minDate: NgbDateStruct = {} as NgbDateStruct;
    startDateFormatL: string;
    endDateFormatL: string;
    successfulInstances: ReservationInstanceType[] = [];
    conflictInstances: ConflictReservationInstanceType[] = [];
    editReservationSimulationList: BaseReservationTimeSlotType[] = [];
    hourFromTime: string;
    hourToTime: string;

    reservation: any = {};

    modalTitle: string;
    modalText1: string;
    modalText2: string;
    modalText3: string;

    SINGLE_INSTANCE = roomReservationConstants.SINGLE_INSTANCE;
    ALL_INSTANCES = roomReservationConstants.ALL_INSTANCES;
    UNTIL_END_DATE = roomReservationConstants.UNTIL_END_DATE;
    DELETE_ORIGINAL_RESERVATION = roomReservationConstants.DELETE_ORIGINAL_RESERVATION;
    KEEP_ORIGINAL_RESERVATION = roomReservationConstants.KEEP_ORIGINAL_RESERVATION;
    SPLIT = roomReservationConstants.SPLIT;
    SHRINK = roomReservationConstants.SHRINK;
    CREATE = constants.CREATE;
    MOVE = constants.MOVE;
    DELETE = constants.DELETE;
    CLICK = constants.CLICK;

    roomReservationConstants = roomReservationConstants;

    constructor(public activeModal: NgbActiveModal,
                public roomReservationUtils: RoomReservationUtils,
                public ngxLoader: NgxUiLoaderService,
                public roomReservationProvider: RoomReservationProvider,
                public messagesService: MessagesService,
                public dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.reservation = lodash.cloneDeep(this.resourceTimeSlot);

        this.initDataForDisplay();

        this.initDataWhenTimeSlotsConflicts();

        this.onPageChanged(this.getCurrentPage(this.action));
    }

    ngOnDestroy(): void {
    }

    onPageChanged(page: number): void {
        switch (page) {
            case 0: {
                this.showBackButton = false;
                this.showActionButton = false;
                this.currentPage = page;
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 1: {
                this.initDataForDisplay();
                this.editReservationSimulationList = [];
                this.showBackButton = (this.action !== this.CREATE && this.action !== this.MOVE);
                this.showActionButton = true;
                this.actionBtnLabel = 'button.next';
                this.currentPage = page;
                this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 2: {
                this.showActionButton = true;
                this.actionBtnLabel = 'label.' + this.action;
                this.showBackButton = this.action === this.MOVE && !this.showReservationConflicts ? false : true;
                this.currentPage = page;
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 3: {
                this.saveByAction(true);
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 4: {
                this.saveByAction(false);
                break;
            }
        }
    }

    onActionButtonClick(action: string): void {
        this.action = action;
        this.onPageChanged(this.getCurrentPage(this.action));
    }

    onAssignationTypeChanged(checkedAssignationType: string, checked: boolean): void {
        this.reservation.endDate = undefined;
        switch (checkedAssignationType) {
            case this.SINGLE_INSTANCE: {
                if (checked) {
                    this.assignationType.allInstances = !checked;
                    this.assignationType.untilEndDate = !checked;
                    this.reservation.assignationType = this.SINGLE_INSTANCE;
                }
                break;
            }
            case this.ALL_INSTANCES: {
                if (checked) {
                    this.assignationType.singleInstance = !checked;
                    this.assignationType.untilEndDate = !checked;
                    this.reservation.assignationType = this.ALL_INSTANCES;
                }
                break;
            }
            case this.UNTIL_END_DATE: {
                if (checked) {
                    this.assignationType.singleInstance = !checked;
                    this.assignationType.allInstances = !checked;
                    this.reservation.assignationType = this.UNTIL_END_DATE;
                    this.reservation.endDate = moment(this.reservation.startDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    this.reservation.endDate = undefined;
                }
                break;
            }
            default: {
                //statements;
                break;
            }
        }
    }

    onChangeAssignationTypeChanged(checkedAssignationType: string, checked: boolean): void {
        this.reservation.endDate = undefined;
        switch (checkedAssignationType) {
            case roomReservationConstants.THIS_INSTANCE: {
                if (checked) {
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = roomReservationConstants.THIS_INSTANCE;
                }
                break;
            }
            case roomReservationConstants.ALL_INSTANCES: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = roomReservationConstants.ALL_INSTANCES;
                }
                break;
            }
            case roomReservationConstants.THIS_AND_FOLLOWING_INSTANCES: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = roomReservationConstants.THIS_AND_FOLLOWING_INSTANCES;
                }
                break;
            }
            case roomReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.reservation.assignationType = roomReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE;
                    this.reservation.endDate = moment(this.reservation.startDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    this.reservation.endDate = undefined;
                }
                break;
            }
            default: {
                //statements;
                break;
            }
        }
    }

    onSplitOptionChanged(checkedSplitOption: string, checked: boolean): void {
        switch (checkedSplitOption) {
            case this.DELETE_ORIGINAL_RESERVATION: {
                if (checked) {
                    this.splitOption.keepOriginalReservation = !checked;
                    this.reservation.splitOption = this.DELETE_ORIGINAL_RESERVATION;
                }
                break;
            }
            case this.KEEP_ORIGINAL_RESERVATION: {
                if (checked) {
                    this.splitOption.deleteOriginalReservation = !checked;
                    this.reservation.splitOption = this.KEEP_ORIGINAL_RESERVATION;
                }
                break;
            }
        }
    }

    onHourFromChanged(hourFromTime) {
        this.reservation.timeSlot.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.hourFromTime = hourFromTime;
        if (this.action === this.SPLIT) {
            this.reservation.timeSlot.hourTo = moment.duration(hourFromTime).asMinutes();
            this.hourToTime = hourFromTime;
        }
        this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
        if (this.isTimeValid && (this.action === this.SPLIT || this.action === this.SHRINK)) {
            //get resulted reservations / blockings
            this.editReservationSimulationList = this.getPreviewReservationsForEdit(this.reservation.timeSlot,
                this.resourceTimeSlot.timeSlot, this.action);
        }
    }

    onHourToChanged(hourToTime) {
        this.reservation.timeSlot.hourTo = moment.duration(hourToTime).asMinutes();
        this.hourToTime = hourToTime;
        this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
        if (this.isTimeValid && (this.action === this.SPLIT || this.action === this.SHRINK)) {
            //get resulted reservations
            this.editReservationSimulationList = this.getPreviewReservationsForEdit(this.reservation.timeSlot,
                this.resourceTimeSlot.timeSlot, this.action);
        }
    }

    onStartDateChange(startDate: NgbDateStruct) {
        if (startDate) {
            this.reservation.startDate = moment({
                year: startDate.year,
                month: startDate.month - 1,
                day: startDate.day
            }).format('YYYY-MM-DD');
            this.startDateFormatL = this.reservation.startDate;
        }
        this.isDateValid = this.isSelectedDateValid(this.reservation.startDate, this.reservation.endDate);
    }

    onEndDateChange(endDate: NgbDateStruct) {
        if (endDate) {
            this.reservation.endDate = moment({
                year: endDate.year,
                month: endDate.month - 1,
                day: endDate.day
            }).format('YYYY-MM-DD');
            this.endDateFormatL = this.reservation.endDate;
        }
        this.isDateValid = this.isSelectedDateValid(this.reservation.startDate, this.reservation.endDate);
    }

    isScreenDataValid() {
        return (this.isTimeValid && this.isDateValid &&
            (this.assignationType.singleInstance || this.assignationType.allInstances || this.assignationType.untilEndDate ||
                // tslint:disable-next-line:max-line-length
                this.changeAssignationType.thisInstance || this.changeAssignationType.allInstances || this.changeAssignationType.thisAndFollowingInstances || this.changeAssignationType.thisAndFollowingInstancesTillEndDate)) &&
            (this.splitOption.deleteOriginalReservation || this.splitOption.keepOriginalReservation) &&
            !this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, this.calendarTimeSlots);
    }

    public createRoomReservation(isSimulation): void {
        this.ngxLoader.start();
        this.reservation.isSimulation = isSimulation;

        this.roomReservationProvider.createReservation(this.reservation)
            .pipe(take(1),
            )
            .subscribe((response) => {
                this.onResponseReceived(response, isSimulation);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (!isSimulation) {
                    this.messagesService.success('toastr.success.reservationSaved', true);
                }
            });
    }

    public moveRoomReservation(isSimulation): void {
        this.ngxLoader.start();
        this.reservation.isSimulation = isSimulation;
        let reservationToBeMoved: MoveReservationFiltersType = {} as MoveReservationFiltersType;
        if (this.showReservationConflicts) {
            reservationToBeMoved = lodash.cloneDeep(this.reservation);
        } else {
            reservationToBeMoved.roomId = this.reservation.roomId;
            reservationToBeMoved.isSimulation = this.reservation.isSimulation;
            reservationToBeMoved.assignationType = this.reservation.assignationType;
            reservationToBeMoved.endDate = this.reservation.endDate;
            reservationToBeMoved.timeSlot = {hourFrom: undefined, hourTo: undefined};
        }

        reservationToBeMoved.originalReservationId = this.reservation.reservationId;

        this.roomReservationProvider.moveReservation(reservationToBeMoved)
            .pipe(take(1),
            )
            .subscribe((response) => {
                this.onResponseReceived(response, isSimulation);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (!isSimulation) {
                    this.messagesService.success('toastr.success.reservationMoved', true);
                }
            });
    }

    public editRoomReservation(action: string): void {
        this.ngxLoader.start();
        const reservationToBeEdited: EditReservationFiltersType = {} as EditReservationFiltersType;

        reservationToBeEdited.endDate = this.reservation.endDate;
        reservationToBeEdited.reservationId = this.reservation.reservationId;
        reservationToBeEdited.assignationType = this.reservation.assignationType;
        reservationToBeEdited.editType = lodash.upperFirst(action);
        reservationToBeEdited.timeSlot = this.reservation.timeSlot;

        this.roomReservationProvider.editReservation(reservationToBeEdited)
            .pipe(take(1),
            )
            .subscribe((response) => {
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (this.action === this.SPLIT) {
                    this.messagesService.success('toastr.success.reservationSplit', true);
                } else if (this.action === this.SPLIT) {
                    this.messagesService.success('toastr.success.reservationShrink', true);
                }

                this.activeModal.close({});
            });
    }

    public deleteRoomReservation(): void {
        this.ngxLoader.start();
        const reservationToBeDeleted: DeleteReservationFiltersType = {} as DeleteReservationFiltersType;
        reservationToBeDeleted.assignationType = this.reservation.assignationType;
        reservationToBeDeleted.dateTo = this.reservation.endDate;
        reservationToBeDeleted.reservationId = this.reservation.reservationId;

        this.roomReservationProvider.deleteReservation(reservationToBeDeleted)
            .pipe(take(1),
            )
            .subscribe((response) => {
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.reservationDeleted', true);
                this.activeModal.close({});
            });
    }

    private initDataForDisplay() {
        this.assignationType = {
            singleInstance: false,
            allInstances: false,
            untilEndDate: false
        };

        this.changeAssignationType = {
            thisInstance: false,
            allInstances: false,
            thisAndFollowingInstances: false,
            thisAndFollowingInstancesTillEndDate: false
        };

        if (this.action === this.CREATE) {
            this.assignationType.singleInstance = true;
            this.reservation.assignationType = this.SINGLE_INSTANCE;
        } else {
            this.changeAssignationType.thisInstance = true;
            this.reservation.assignationType = roomReservationConstants.THIS_INSTANCE;
        }

        this.splitOption = {
            deleteOriginalReservation: true,
            keepOriginalReservation: false
        };

        if (this.action === this.MOVE) {
            this.reservation.splitOption = this.DELETE_ORIGINAL_RESERVATION;
        }

        const endDate = moment.parseZone(this.reservation.startDate).add(1, 'day');
        this.startDate = this.dateTimeUtils.convertDateInNgbDateStruct(this.reservation.startDate);
        this.endDate = {
            year: moment.parseZone(this.reservation.startDate).year(),
            month: moment.parseZone(this.reservation.startDate).month() + 1,
            day: endDate.date(),
        };
        this.minDate = {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date(),
        };

        this.reservation.startDate = moment.parseZone(this.reservation.startDate).format('YYYY-MM-DD');
        this.startDateFormatL = moment.parseZone(this.reservation.startDate).format('L');
        this.endDateFormatL = endDate.format('L');

        this.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourFrom);
        this.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourTo);
    }

    private initDataWhenTimeSlotsConflicts(): void {
        if (this.calendarTimeSlots.length > 0) {
            this.calendarTimeSlots = lodash.orderBy(this.calendarTimeSlots, ['hourFrom']);
            this.showReservationConflicts = this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, this.calendarTimeSlots);

            if (this.showReservationConflicts) {
                const timeSlotsArray = this.roomReservationUtils.getAllTimeSlotsArray(this.calendarTimeSlots, this.reservation.timeSlot);

                const timeSlotGap: BaseReservationTimeSlotType = {} as BaseReservationTimeSlotType;
                for (let i = 0; i < timeSlotsArray.length + 1; i++) {
                    if (i === 0) {
                        timeSlotGap.hourFrom = 0; // start of day
                        timeSlotGap.hourTo = timeSlotsArray[i];
                    } else if (i === timeSlotsArray.length) {
                        timeSlotGap.hourFrom = timeSlotsArray[i - 1];
                        timeSlotGap.hourTo = 1440; // end of day
                    } else {
                        timeSlotGap.hourFrom = timeSlotsArray[i - 1];
                        timeSlotGap.hourTo = timeSlotsArray[i];
                    }

                    //check this is a valid gap
                    //-must be in selected time slot interval
                    //-must not overlap with other existing time slots
                    //-must overlap with resource time slot
                    this.showTimeSlotAlternative = this.isSelectedTimeValid(timeSlotGap.hourFrom, timeSlotGap.hourTo, this.action) &&
                        !this.checkForTimeSlotsOverlapping(timeSlotGap, this.calendarTimeSlots) &&
                        this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, [timeSlotGap]);
                    if (this.showTimeSlotAlternative) {
                        //find a suitable time slot
                        if (this.reservation.timeSlot.hourFrom < timeSlotGap.hourFrom) {
                            this.reservation.timeSlot.hourFrom = timeSlotGap.hourFrom;
                        }

                        if (this.reservation.timeSlot.hourTo > timeSlotGap.hourTo) {
                            this.reservation.timeSlot.hourTo = timeSlotGap.hourTo;
                        }
                        break;
                    }
                }
            }

            // if (this.showReservationConflicts) {
            //   const timeSlotGap: BaseReservationTimeSlotType = {} as BaseReservationTimeSlotType;
            //   // tslint:disable-next-line:prefer-for-of
            //   for (let i = 0; i < this.calendarTimeSlots.length + 1; i++) {
            //     if (i === 0) {
            //       timeSlotGap.hourFrom = 0; // start of day
            //       timeSlotGap.hourTo = this.calendarTimeSlots[i].hourFrom;
            //     } else if (i === this.calendarTimeSlots.length) {
            //       timeSlotGap.hourFrom = this.calendarTimeSlots[i - 1].hourTo;
            //       timeSlotGap.hourTo = 1440; // end of day
            //     } else {
            //       timeSlotGap.hourFrom = this.calendarTimeSlots[i - 1].hourTo;
            //       timeSlotGap.hourTo = this.calendarTimeSlots[i].hourFrom;
            //     }
            //     //check this is a valid gap
            //     // var isFoundTimeValid = this.isSelectedTimeValid(timeSlotGap.hourFrom, timeSlotGap.hourTo, this.action);
            //     this.showTimeSlotAlternative = this.isSelectedTimeValid(timeSlotGap.hourFrom, timeSlotGap.hourTo,
            //     this.action) && this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, [timeSlotGap]);
            //     if (this.showTimeSlotAlternative) {
            //       //find a suitable time slot
            //       if (this.reservation.timeSlot.hourFrom < timeSlotGap.hourFrom) {
            //         this.reservation.timeSlot.hourFrom = timeSlotGap.hourFrom;
            //       }
            //
            //       if (this.reservation.timeSlot.hourTo > timeSlotGap.hourTo) {
            //         this.reservation.timeSlot.hourTo = timeSlotGap.hourTo;
            //       }
            //       break;
            //     }
            //   }
            // }
            //update the time for time picker
            this.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourFrom);
            this.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourTo);
        }
    }

    private checkForTimeSlotsOverlapping(reservationTimeSlot, calendarTimeSlots) {
        let overlapFound = false;
        for (const timeSlot of calendarTimeSlots) {
            overlapFound = this.roomReservationUtils.checkIfTimeSlotIsOverlapping(reservationTimeSlot, timeSlot);
            if (overlapFound) {
                return overlapFound;
            }
        }
        return overlapFound;
    }

    private isSelectedTimeValid(hourFrom: number, hourTo: number, action: string): boolean {
        if (action === this.SPLIT) {
            return hourFrom > this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo;
        } else if (action === this.SHRINK) {
            return (hourFrom > this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo &&
                hourTo <= this.resourceTimeSlot.timeSlot.hourTo) || (hourFrom >= this.resourceTimeSlot.timeSlot.hourFrom &&
                hourTo < this.resourceTimeSlot.timeSlot.hourTo && hourTo > this.resourceTimeSlot.timeSlot.hourFrom);
        } else {
            return hourTo > hourFrom &&
                hourFrom >= this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo &&
                hourTo > this.resourceTimeSlot.timeSlot.hourFrom && hourTo <= this.resourceTimeSlot.timeSlot.hourTo;
        }
    }

    private isSelectedDateValid(dateFrom, dateTo) {
        return moment(dateTo).isAfter(moment(dateFrom)) &&
            moment(dateFrom).diff(moment(), 'days') >= 0 &&
            moment(dateTo).diff(moment(), 'days') >= 0;
    }

    private getCurrentPage(action: string): number {
        let firstPage: number;
        if (action === this.CLICK) {
            firstPage = 0;
        } else if (action === this.DELETE) {
            firstPage = 2;
        } else {
            firstPage = action === this.MOVE && !this.showReservationConflicts ? 2 : 1;
        }
        return firstPage;
    }

    private saveByAction(isSimulation: boolean) {
        switch (this.action) {
            case this.CREATE: {
                this.createRoomReservation(isSimulation);
                break;
            }
            case this.MOVE: {
                this.moveRoomReservation(isSimulation);
                break;
            }
            case this.SHRINK: {
                this.editRoomReservation(this.action);
                break;
            }
            case this.SPLIT: {
                this.editRoomReservation(this.action);
                break;
            }
            case this.DELETE: {
                this.deleteRoomReservation();
                break;
            }
        }
    }

    private onResponseReceived(response: ReservationResultType, isSimulation: boolean): void {
        this.conflictInstances = response.conflictInstances;
        this.successfulInstances = response.successfulInstances;

        this.currentPage = this.currentPage + 1;

        if (isSimulation) {
            if (this.conflictInstances.length > 0) {
                this.actionBtnLabel = 'button.saveWithConflicts';
            } else {
                this.actionBtnLabel = 'button.save';
            }
        } else {
            this.activeModal.close({reservation: this.reservation, conflicts: this.conflictInstances});
        }
    }

    private getPreviewReservationsForEdit(selectedTimeSlot, originalTimeSlot, action) {
        const editReservationSimulationList: BaseReservationTimeSlotType[] = [];
        switch (action) {
            case this.SPLIT: {
                editReservationSimulationList.push({
                    hourFrom: originalTimeSlot.hourFrom,
                    hourTo: selectedTimeSlot.hourFrom
                });
                editReservationSimulationList.push({
                    hourFrom: selectedTimeSlot.hourFrom,
                    hourTo: originalTimeSlot.hourTo
                });
                break;
            }
            case this.SHRINK: {
                if (selectedTimeSlot.hourFrom > originalTimeSlot.hourFrom && selectedTimeSlot.hourTo < originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: originalTimeSlot.hourFrom,
                        hourTo: selectedTimeSlot.hourFrom
                    });
                    editReservationSimulationList.push({
                        hourFrom: selectedTimeSlot.hourTo,
                        hourTo: originalTimeSlot.hourTo
                    });
                } else if (selectedTimeSlot.hourFrom === originalTimeSlot.hourFrom && selectedTimeSlot.hourTo < originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: selectedTimeSlot.hourTo,
                        hourTo: originalTimeSlot.hourTo
                    });
                } else if (selectedTimeSlot.hourFrom > originalTimeSlot.hourFrom && selectedTimeSlot.hourTo === originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: originalTimeSlot.hourFrom,
                        hourTo: selectedTimeSlot.hourFrom
                    });
                }
                break;
            }
        }

        return editReservationSimulationList;
    }

    private getTextsByAction(action, page) {
        switch (action) {
            case this.CREATE: {
                switch (page) {
                    case 1: {
                        if (this.showReservationConflicts) {
                            this.modalTitle = 'label.reservationConflict';
                        } else {
                            this.modalTitle = 'label.reservationConfirmation';
                        }
                        this.modalText2 = 'label.reservationInterval';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.reservationConfirmation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case this.MOVE: {
                switch (page) {
                    case 1: {
                        if (this.showReservationConflicts) {
                            this.modalTitle = 'label.reservationConflict';
                        } else {
                            this.modalTitle = 'label.reservationConfirmation';
                        }
                        this.modalText2 = 'label.reservationInterval';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.reservationConfirmation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case this.CLICK: {
                this.modalTitle = 'label.actionForReservation';
                this.modalText1 = 'label.chooseAction';
                break;
            }
            case this.SHRINK: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.shrinkReservation';
                        this.modalText2 = 'label.reservationIntervalShrink';
                        this.modalText3 = 'label.reservationPreviewShrink';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.shrinkReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case
            this.SPLIT: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.splitReservation';
                        this.modalText2 = 'label.reservationIntervalSplit';
                        this.modalText3 = 'label.reservationPreviewSplit';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.splitReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case
            this.DELETE: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.deleteReservation';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.deleteReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
        }
    }
}
