<div *ngIf="externalKeysForm" [formGroup]="externalKeysForm" class="form-container external-keys-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
    <!-- EXTERNAL KEYS -->
    <div *ngIf="getInputByControlName(formControlNames.EXTERNAL_KEYS)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.EXTERNAL_KEYS)?.styles">
        <label for="{{formControlNames.EXTERNAL_KEYS}}">
            <span translate="label.externalKeysLabel"></span>
            <span [style.visibility]="getInputByControlName(formControlNames.EXTERNAL_KEYS)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div formArrayName="ExternalKeys" class="external-keys-container">
            <div class="external-keys"
                 *ngFor="let externalKey of externalKeysFormArray?.controls; let i=index"
                 [formGroupName]="i">
                <div class="external-key-inputs"
                [ngClass]="{'has-error': hasExternalKeysFormControlErrors(i)}">
                    <input type="text"
                           class="dynamic-input"
                           placeholder="{{'label.origin'|translate}}"
                           [formControlName]="formControlNames.ORIGIN">
                    <input type="text"
                           class="dynamic-input"
                           placeholder="{{'label.key'|translate}}"
                           [formControlName]="formControlNames.KEY">
                </div>
                <i class="fa-regular fa-trash-can external-key-remove-icon cursor-pointer"
                   (click)="removeExternalKey(i)"></i>
                <span class="help-block" *ngIf="hasExternalKeysFormControlErrors(i)">{{getExternalKeyControlErrorMessage(i) | translate}}</span>
            </div>
            <span class="external-key-add-btn cursor-pointer" translate="label.addNew" (click)="addExternalKey()"></span>
        </div>
    </div>

</div>



