export const environment = {
    BASE_URL: '#{baseUrl}' + 'v2/masterdata/odata/',
    BASE_URL_API: '#{baseUrl}' + 'v2/masterdata/api/',
    BASE_URL_API_V3: '#{baseUrl}' + 'v3/main/api/',
    BASE_URL_V3: '#{baseUrl}' + 'v3/main/odata/' ,
    WEBAPP_URL: '#{webAppUrl}',
    URL_IDP: '#{urlIdp}',
    SHOW_AVAIL_SUNDAY: '#{showSundayAvailability}',
    IS_PRODUCTION: '#{isProductionEnvironment}',
    IS_PRODUCTION_BUILD: true,
    INSIGHTS_ID: '#{insightsId}',
    FULL_CALENDAR_LICENSE_KEY: '#{fullCalendarLicenceKey}',
    DEFAULT_COUNTRY_CODE: 'cl'
};
