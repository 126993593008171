import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActivityPlanProvider,
    ActivityPlanType,
    ConfirmDeleteModalService,
    ScheduleOccurrencesForActivityPlanType,
    SpecialityProvider,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {map, take} from 'rxjs/operators';
import {ActivityPlanUtils} from './activity-plan.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {CacheService} from '../../shared/services/cache.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {
    DisplayScheduleOccurrencesModalService
} from './display-schedule-occurrences-modal/display-schedule-occurrences-modal.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-activity-plan',
    templateUrl: './activity-plan.component.html',
    styleUrls: ['./activity-plan.component.scss']
})
export class ActivityPlanComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    activityPlanList: ActivityPlanType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    ACTIVITY_PLAN = 'ActivityPlan';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchActivityPlanChanged = new Subject<string>();

    constructor(
        public activityPlanUtils: ActivityPlanUtils,
        public activityPlanProvider: ActivityPlanProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public patientContextService: PatientContextService,
        private specialityProvider: SpecialityProvider,
        private cacheService: CacheService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private displayScheduleOccurrencesModalService: DisplayScheduleOccurrencesModalService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        // Cannot come on this page without patient selected
        if (!this.patientContextService?.patient?.id) {
            this.messagesService.error('label.selectAPatientFirst', true);
            this.router.navigate(['patientDashboard']);
            return;
        }
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('ActivityPlanRead');
        // If not coming from route addActivityPlan, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/addActivityPlan') {
            this.activityPlanUtils.tableFilters = this.activityPlanUtils.getInitialTableFilter();
        }
        this.loadActivityPlanData();
        this.listenToPatientChange();
    }

    ngOnDestroy(): void {
    }

    refreshData(): void {
        this.loadActivityPlanData(true, true);
    }

    // method to navigate create ActivityPlan
    addActivityPlan(): void {
        this.router.navigate(['/addActivityPlan']);
    }

    editActivityPlan(activityPlan: ActivityPlanType) {
        this.router.navigate(['/addActivityPlan'], {state: {activityPlan, action: constants.EDIT}});
    }

    viewActivityPlan(activityPlan: ActivityPlanType) {
        this.router.navigate(['/addActivityPlan'], {state: {activityPlan, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.activityPlanUtils.tableFilters.currentPage = page;
        this.loadActivityPlanData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.activityPlanUtils.tableFilters.currentPage = 1;
        this.activityPlanUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadActivityPlanData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.activityPlanUtils.tableFilters.orderBy[property];
        if (this.activityPlanUtils.tableFilters.orderBy) {
            this.activityPlanUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadActivityPlanData(false);
    }

    displayConfirmDeleteItemModal(activityPlanId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.activityPlanProvider, activityPlanId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(activityPlanId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string): void {
        this.changeLogModalService.displayModal(id, actionType);
    }

    scheduleOccurrencesAction(activityPlan: ActivityPlanType) {
        this.ngxLoader.start();
        this.activityPlanProvider.getScheduleOccurrencesForActivityPlan(activityPlan.id).pipe(
            take(1)
        ).subscribe((response: {value: ScheduleOccurrencesForActivityPlanType[]}) => {
            this.displayScheduleOccurrencesModalService.displayModal(response.value);
            this.ngxLoader.stop();
        }, (error: any) => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(error);
        });
    }

    private loadActivityPlanData(includeCount: boolean = true, refreshData: boolean = false) {
        const queryFilter = this.activityPlanUtils.getQueryFilterForActivityPlan(this.activityPlanUtils.tableFilters, includeCount);
        const specialityQueryFilter = { select: ['Id', 'Name'] };
        this.ngxLoader.start();
        forkJoin([
            this.activityPlanProvider.getEntries(queryFilter),
            this.cacheService.getData('Specialities', this.specialityProvider, specialityQueryFilter)
        ]).pipe(
            take(1),
            map((responses: [{ value: ActivityPlanType[], count?: number }, any]) =>
                [this.activityPlanUtils.replaceServicesCountToNullBasedOnScheduledEndType(responses[0]), responses[1]]
            )
        )
            .subscribe(([activityPlanResponse, specialityResponse]) => {
                this.activityPlanList = activityPlanResponse.value;
                this.activityPlanList = this.activityPlanUtils.addSpecialityNameToServices(
                    this.activityPlanList, specialityResponse.value
                );

                if (activityPlanResponse.count !== undefined && activityPlanResponse.count !== null) {
                    this.totalTableItems = activityPlanResponse.count;
                }
                this.ngxLoader.stop();
                if (refreshData) {
                    this.messagesService.success('label.dataRefreshed', true);
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.activityPlanUtils.tableFilters = this.activityPlanUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.activityPlanProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadActivityPlanData();
                this.messagesService.success('toastr.success.activityPlanDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private listenToPatientChange() {
        this.patientContextService.patientChange?.subscribe((newPatient: PatientType) => {
            if (!newPatient) {
                this.router.navigate(['patientDashboard']);
            }
        });
    }
}
