import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {
    CenterProvider,
    CenterType,
    ResourceProvider,
    ResourceType,
    ServiceProvider,
    ServiceType,
    SkedTaskProcessingTypeEnum,
    SkedTaskProvider, SkedTaskType,
} from 'sked-base';
import {DateTimeUtils} from '../../utils/dateTime.utils';
import {OptionalIntervalType} from 'sked-base/lib/data-model/optionalIntervalTypes';
import moment from 'moment';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {WaitListModalUtils} from './wait-list-modal.utils';
import {PatientActionsService} from '../../services/patient-actions/patient-actions.service';
import {PatientContextService} from '../../services/patient-context.service';

@Component({
    selector: 'app-wait-list-modal',
    templateUrl: './wait-list-modal.component.html',
    styleUrls: ['./wait-list-modal.component.scss']
})
export class WaitListModalComponent implements OnInit {
    waitListTask = {} as SkedTaskType;
    skedTaskProcessingTypeEnum = SkedTaskProcessingTypeEnum;

    constructor(public activeModal: NgbActiveModal,
                public serviceProvider: ServiceProvider,
                public resourceProvider: ResourceProvider,
                public centerProvider: CenterProvider,
                public waitListModalUtils: WaitListModalUtils,
                private dateTimeUtils: DateTimeUtils,
                private skedTaskProvider: SkedTaskProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private patientActionsService: PatientActionsService,
                private patientContextService: PatientContextService) {
    }

    ngOnInit(): void {
        this.waitListModalUtils.loadInitialWaitListTaskOptions();
        this.waitListTask = this.waitListModalUtils.getInitialWaitListTask();
        this.loadWaitListTaskFiltersFromSlotsFilters();
    }

    saveWaitListTask(waitListTask: SkedTaskType): void {
        const isTemplateValid = this.waitListModalUtils.areFiltersValidBasedOnSelectedTaskType(waitListTask);
        if (isTemplateValid) {
            this.waitListTask.patientId = this.patientContextService?.patient?.id;
            if (waitListTask.processingType === SkedTaskProcessingTypeEnum.Automated && !moment(waitListTask.dueDate).isAfter(moment(), 'day')) {
                waitListTask.dueDate = moment().format();
            }
            this.createWaitListTask(waitListTask);
        }
    }

    onPreferredIntervalChanged(): void {
        if (!this.waitListTask.preferredInterval) {
            this.waitListTask.preferredInterval = {} as OptionalIntervalType;
        } else if (this.waitListModalUtils.waitListTaskOptions.am && !this.waitListModalUtils.waitListTaskOptions.pm) {
            this.waitListTask.preferredInterval.hourFrom = 0;
            this.waitListTask.preferredInterval.hourTo = 720;
        } else if (!this.waitListModalUtils.waitListTaskOptions.am && this.waitListModalUtils.waitListTaskOptions.pm ||
            this.waitListModalUtils.waitListTaskOptions.am && this.waitListModalUtils.waitListTaskOptions.pm) {
            this.waitListTask.preferredInterval.hourFrom = 720;
            this.waitListTask.preferredInterval.hourTo = 1440;
        } else if (!this.waitListModalUtils.waitListTaskOptions.am && !this.waitListModalUtils.waitListTaskOptions.pm) {
            delete this.waitListTask.preferredInterval;
        }
    }

    onSelectedService([service]: ServiceType[]): void {
        this.waitListModalUtils.waitListTaskOptions.filters.center.dependentFilter.serviceId = service?.id;
        this.waitListModalUtils.waitListTaskOptions.filters.resource.dependentFilter.serviceId = service?.id;
        this.waitListTask.serviceId = service?.id;
    }
    onSelectedResource([resource]: ResourceType[]): void {
        this.waitListModalUtils.waitListTaskOptions.filters.center.dependentFilter.resourceId = resource?.id;
        this.waitListModalUtils.waitListTaskOptions.filters.service.dependentFilter.resourceId = resource?.id;
        this.waitListTask.resourceId = resource?.id;
    }
    onSelectedCenter([center]: CenterType[]): void {
        this.waitListModalUtils.waitListTaskOptions.filters.service.dependentFilter.locationId = center?.id;
        this.waitListModalUtils.waitListTaskOptions.filters.resource.dependentFilter.locationId = center?.id;
        this.waitListTask.centerId =  center?.id;
    }

    onSelectDueDate(selectedDueDate: NgbDate): void {
        this.waitListTask.dueDate = moment(this.dateTimeUtils.convertNgbDateToDate(selectedDueDate)).format();
    }

    private createWaitListTask(waitListTask: SkedTaskType): void {
        this.ngxLoader.start();
        this.skedTaskProvider.addEntry(waitListTask).subscribe(() => {
            this.messagesService.success('toastr.success.waitListAdded');
            this.activeModal.close();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private loadWaitListTaskFiltersFromSlotsFilters(): void {
        if (this.patientActionsService.slotsFilters) {
            this.patientActionsService.slotsFilters.forEach(filter => {
                if (filter.name === 'Service') {
                    this.waitListModalUtils.waitListTaskOptions.filters.service.initialFilter.push(filter.value.service);
                    this.waitListTask.serviceId = filter.value.service.id;
                }
                if (filter.name === 'Center') {
                    this.waitListModalUtils.waitListTaskOptions.filters.center.initialFilter.push(filter.value);
                    this.waitListTask.centerId = filter.value.id;
                }
                if (filter.name === 'Resource') {
                    this.waitListModalUtils.waitListTaskOptions.filters.resource.initialFilter.push(filter.value);
                    this.waitListTask.resourceId = filter.value.id;
                }
            });
        }
    }
}
