import {Component, OnInit} from '@angular/core';
import {
    AvailabilityChangeDocumentType, ExclusionProvider
} from 'sked-base';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {ExclusionUtils} from '../exclusion-utils';
import {ExclusionOverviewItemType} from 'sked-base/lib/data-model/exclusionTypes';

@Component({
    selector: 'app-exclusion-history',
    templateUrl: './exclusion-history.component.html',
    styleUrls: ['./exclusion-history.component.scss']
})
export class ExclusionHistoryComponent implements OnInit {
    exclusion: ExclusionOverviewItemType;
    exclusionHistoryList: AvailabilityChangeDocumentType[] = [];

    private exclusionId: string;

    constructor(public exclusionProvider: ExclusionProvider,
                public exclusionUtils: ExclusionUtils,
                public messagesService: MessagesService,
                public ngxLoader: NgxUiLoaderService,
                public router: Router) {
    }

    ngOnInit(): void {
        this.exclusion = history?.state?.exclusion;
        this.exclusionId = this.exclusion?.id;
        if (this.exclusionId === null || this.exclusionId === undefined) {
            this.goToParentPage();
        } else {
            this.loadData();
        }
    }

    goToParentPage() {
        this.router.navigate(['/exclusion']);
    }

    private loadData() {
        this.ngxLoader.start();
        this.exclusionProvider.getHistory(this.exclusionId)
            .pipe(take(1))
            .subscribe((exclusionHistory) => {
                this.exclusionHistoryList = exclusionHistory.value;
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

}
