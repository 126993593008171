<div class="upcoming-appointments-container custom-scrollbar">
    <ngx-ui-loader [hasProgressBar]="false" [loaderId]="loaderId">
    </ngx-ui-loader>
    <div class="upcoming-appointments-title text-dark">
        <h3 translate="label.upcomingAppointments"></h3>
        <div class="btn theme-btn float-right"
             ngbTooltip="{{'label.refresh'| translate}}"
             placement="left"
             (click)="refreshData()">
            <i class="fas fa-sync-alt"></i>
        </div>
    </div>
    <div class="upcoming-appointments-message" translate="label.noUpcomingAppointmentsToDisplay" *ngIf="appointmentCardOptions?.length <= 0"></div>
    <div class="upcoming-appointments-cards" *ngIf="appointmentCardOptions?.length > 0">
        <div class="appointment-card-container" *ngFor="let appointmentCardOption of appointmentCardOptions; let index = index">
            <app-appointment-card [options]="appointmentCardOption"
                                  (appointmentCardAction)="onAction($event, index)">
            </app-appointment-card>
        </div>
    </div>
    <div class="horizontal-line"></div>
    <div class="upcoming-appointments-view-links-container theme-color">
            <span class="underline font-weight-600 cursor-pointer"
                  translate="label.viewUpcomingAppointments"
                  (click)="goToAppointmentsList(appointmentsListEnum.ViewUpcomingAppointments)"></span>
        <span class="underline font-weight-600 cursor-pointer"
              translate="label.viewCancelledAppointments"
              (click)="goToAppointmentsList(appointmentsListEnum.ViewCancelledAppointments)"></span>
        <span class="underline font-weight-600 cursor-pointer"
              translate="label.viewPastAppointments"
              (click)="goToAppointmentsList(appointmentsListEnum.ViewPastAppointments)"></span>
    </div>
</div>

