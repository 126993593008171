import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType, IdNameType} from '../../data-model/general.type';
import {
    Expand,
    HolidayCalendarType,
    HolidayProvider,
    HolidaysDependentFiltersType,
    LocationDependentFiltersType,
    ODataQueryObjectType,
    HolidayType, CenterProvider
} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {
    MultiSelectTableColumnKindEnum,
    MultiSelectTableColumnType, MultiSelectTableOptionsType
} from '../../shared/component/multi-select-table/multi-select-table.types';
import {DateMMddFormatPipe} from '../../shared/pipes/date-MMdd-format.pipe';
import {DateMMddyyyyFormatPipe} from '../../shared/pipes/date-MMddyyyy-format.pipe';
import {HolidayCalendarMultiSelectTableOptionsType} from './holiday-calendar-md.types';
import {MultiSelectTableUtils} from '../../shared/component/multi-select-table/multi-select-table-util';

@Injectable({
    providedIn: 'root'
})
export class HolidayCalendarMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;

    constructor(private holidayProvider: HolidayProvider,
                private centerProvider: CenterProvider,
                private dateMMddFormatPipe: DateMMddFormatPipe,
                private dateMMddyyyyFormatPipe: DateMMddyyyyFormatPipe,
                private multiSelectTableUtils: MultiSelectTableUtils) {
    }

    getCentersDependentFilters(): LocationDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            onlyAssignedToUser: false,
            isRequestAllowed: false,
            locationId: null,
            regionId: null,
            resourceId: null,
            serviceId: null,
            areaId: null,
            useOnlyAssignedToUser: false,
            exclusionList: [],
            count: true
        };
    }

    getEmptyHolidayDependentFilters(): HolidaysDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            year: null
        };
    }

    getQueryFilterForHolidayCalendarMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Description', 'Year', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Centers: {
                select: ['Id', 'Name'],
            },
            Days: {select: ['Id', 'Description', 'Year', 'MonthOfYear', 'DayOfMonth', 'Type']}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {Description: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialHolidayCalendar(): HolidayCalendarType {
        return {
            description: '',
            year: (new Date()).getFullYear(),
            days: [],
            centers: [],
        } as HolidayCalendarType;
    }

    getHolidayCalendarMultiSelectTableOptions(
        holidayCalendar: HolidayCalendarType, screenTemplateLayoutAction: string, holidayCalendarItemYear: number, customTemplate: any
    ): HolidayCalendarMultiSelectTableOptionsType {
        const filter = {
            or: [{Year: null}, {Year: holidayCalendarItemYear}]
        };
        const select = ['Id', 'Type', 'Description', 'MonthOfYear', 'DayOfMonth', 'Year'];
        const dependentFilters = {...this.getEmptyHolidayDependentFilters(), year: holidayCalendarItemYear};
        const initialSelectedItems: IdNameType[] = holidayCalendar?.days.map(day => ({
            id: day.id,
            name: day.description
        }));
        return {
            holidays: {
                provider: this.holidayProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.holidayProvider,
                confirmDeleteParentId: holidayCalendar?.id,
                confirmDeleteRefName: 'Holidays',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(select, filter),
                initialSelectedItems,
                tableMaxHeight: '20rem',
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.holidayCalendarTab.days',
                    displayUpperHeader: false,
                    itemPropertyNameInTableRow: 'description',
                    provider: this.holidayProvider,
                    buttonName: 'label.holidayCalendarTab.days',
                    dependentFilters,
                    propertyName: 'description',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                    customFunctionText: (holiday: HolidayType) => {
                        const typeFixedText = `${holiday.monthOfYear}/${holiday.dayOfMonth}`;
                        const typeNotFixedText = `${holiday.monthOfYear}/${holiday.dayOfMonth}/${holiday.year}`;
                        const dateText = `${holiday.type === 'Fixed'
                            ? this.dateMMddFormatPipe.transform(typeFixedText)
                            : this.dateMMddyyyyFormatPipe.transform(typeNotFixedText)}`;
                        return `${holiday.description} : ${dateText}`;
                    },
                    customHTMLTemplate: customTemplate
                } as MultiSelectTableColumnType,
                ],
                screenTemplateLayoutAction
            },
            centers: {
                provider: this.centerProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.holidayProvider,
                confirmDeleteParentId: holidayCalendar?.id,
                confirmDeleteRefName: 'Centers',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(['Id', 'Name'], null, null, ['Name asc']),
                initialSelectedItems: holidayCalendar?.centers,
                tableMaxHeight: '20rem',
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.center',
                    displayUpperHeader: false,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.centerProvider,
                    buttonName: 'label.center',
                    dependentFilters: this.getLocationDependentFilters(),
                    propertyName: 'center',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id'
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType
        };
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })
}
