import {BackofficeChannelType} from '../../data-model/channel.types';
import {ExternalKeysValueType, IdNameType, RecordWithMultipleKeys} from '../../data-model/general.type';
import {SpecialitySearchType} from 'sked-base';
import {MultiSelectTableOptionsType} from '../../shared/component/multi-select-table/multi-select-table.types';

export interface BackofficeServiceMDType {
    id?: string;
    etag?: string;
    name: string;
    code: string;
    defaultDuration: number;
    area: IdNameType;
    checkinDisabled: boolean;
    onlineConsultation: boolean;
    shortId: number;
    speciality: SpecialitySearchType;
    multiResourceBluePrint: IdNameType;
    minSubServices: number;
    maxSubServices: number;
    hasSubServices: boolean;
    channelRestrictions?: BackofficeChannelType[];
    centers: IdNameType[];
    visitReasons?: IdNameType[];
    coveragePlans?: IdNameType[];
    appointmentPreconditionFlags?: IdNameType[];
    serviceExternalKeys?: ExternalKeysValueType[];
    subServices: IdNameType[];
    tags?: IdNameType[];
    objectDetails?: IdNameType[];
    enableWaitList?: boolean;
}

export interface ValidServiceObjectType {
    isNameValid: boolean;
    isDurationValid: boolean;
    isAreaValid: boolean;
    isSpecialityValid: boolean;
    isMaxSubServicesNumberValid: boolean;
    areCoveragePlansValid: boolean;
    areCentersValid: boolean;
}

export type ServiceMultiSelectTableOptionsType = RecordWithMultipleKeys<
    'centers' | 'coveragePlans' | 'subServices',
    MultiSelectTableOptionsType>;

export interface MultiResourceBluePrintOptionsType {
    ngModel?: boolean;
}

export interface MultiResourceBluePrintFilterOptionsType {
    ngModel?: IdNameType;
}

export interface ServiceModalFilterOptionsType {
    multiResourceBluePrintOptions: MultiResourceBluePrintOptionsType;
    multiResourceBluePrintFilterOptions: MultiResourceBluePrintFilterOptionsType;
    areFiltersActive: boolean;
}

export interface ServiceMDSearchByNameType {
    name: ServiceMDSearchByEnum.Name,
    value: string
}

export interface ServiceMDSearchByShortIdType {
    name: ServiceMDSearchByEnum.ShortId,
    value: number
}

export enum ServiceMDSearchByEnum {
    Name = 'name',
    ShortId = 'shortId'
}
