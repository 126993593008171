import {Injectable} from '@angular/core';
import {
    AppointmentPriceRuleType,
    CoveragePlanDependentFiltersType,
    Expand,
    LocationDependentFiltersType,
    ODataQueryObjectType,
    ServiceDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AppointmentPriceRuleModalFiltersType,
    AppointmentPriceRuleStateType,
    CenterModalFilterOptionsType,
    CoveragePlanModalFilterOptionsType,
    PriceModalFilterOptionsType,
    ServiceModalFilterOptionsType,
} from './appointment-price-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class AppointmentPriceRuleUtils {
    overviewState: AppointmentPriceRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'appointmentPriceRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.center2',
            hasSort: true,
            onSortByProperty: 'center',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.service2',
            hasSort: true,
            onSortByProperty: 'service',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.coveragePlan2',
            hasSort: true,
            onSortByProperty: 'coveragePlan',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.price',
            hasSort: true,
            onSortByProperty: 'price',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'appointmentPriceRuleTableId':
                    case 'centerId':
                    case 'serviceId':
                    case 'coveragePlanId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'price': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'appointmentPriceRuleTable' || item === 'center' || item === 'service' || item === 'coveragePlan') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            Center: {select: ['Id', 'Name']},
            Service: {select: ['Id', 'Name', 'SpecialityId']},
            CoveragePlan: {select: ['Id', 'Name']},
            AppointmentPriceRuleTable: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): AppointmentPriceRuleType {
        return {
            appointmentPriceRuleTableId: undefined,
            name: undefined,
            active: false,
            centerId: undefined,
            serviceId: undefined,
            coveragePlanId: undefined,
            price: undefined,
        } as AppointmentPriceRuleType;
    }

    getInitialModalFilters(): AppointmentPriceRuleModalFiltersType {
        return {
            centerOptions: {
                ngModel: []
            } as CenterModalFilterOptionsType,
            serviceOptions: {
                ngModel: []
            } as ServiceModalFilterOptionsType,
            coveragePlanOptions: {
                ngModel: []
            } as CoveragePlanModalFilterOptionsType,
            priceOptions: {
                ngModel: null
            } as PriceModalFilterOptionsType,
            areFiltersActive: false,
        } as AppointmentPriceRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: AppointmentPriceRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getCenterDependentFilters(): LocationDependentFiltersType {
        return {
            count: true,
            exclusionList: [],
            includeAvailabilities: false,
            onlyAssignedToUser: false,
            searchPhrase: ''
        } as LocationDependentFiltersType;
    }

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            count: true,
            exclusionList: [],
            includeAvailabilities: false,
            includeChannel: false,
            onlyAssignedToLocationsOfUser: false,
            searchPhrase: ''
        } as ServiceDependentFiltersType;
    }

    getCoveragePlanDependentFilters(): CoveragePlanDependentFiltersType {
        return {
            count: true,
            excludePrivatePlans: false,
            excludeSystemManagedPlans: false,
            exclusionList: [],
            includeChannel: false,
            onlyMainPlans: false,
            onlySecondaryPlans: false,
            searchPhrase: ''
        } as CoveragePlanDependentFiltersType;
    }
}
