<div class="container-fluid excel-import-wrapper overview-screen-md pb-1">
    <div class="w-100 border-bottom">
        <h3 translate="label.excelImporterTab.title"></h3>
    </div>
    <div class="pt-3 d-flex flex-row no-block align-items-center justify-content-between mb-3">
        <div class="w-50 d-flex flex-row no-block align-items-center">
            <ng-select class="w-50 form-control rounded-0 border-0 p-0 custom-ng-select"
                       dropdownPosition="bottom"
                       [multiple]="false"
                       [searchable]="false"
                       [placeholder]="'label.excelImporterTab.chooseTemplate' | translate"
                       [(ngModel)]="excelImporterOptions.dataType">
                <ng-option *ngFor="let importSelectOption of importSelectOptionsEnumList"
                           [value]="importSelectOption">
                    {{getLabelForOptionSelect(importSelectOption) | translate}}
                </ng-option>
            </ng-select>
            <div class="dynamic-checkbox-container ml-3">
                <input type="checkbox" [(ngModel)]="excelImporterOptions.isSimulation"/>
                <label translate="label.excelImporterTab.simulateImport" class="text-high-contrast m-0"></label>
            </div>
        </div>
        <div class="w-50 file-input-container">
            <input type="file" name="EXCEL_FILE"
                   accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   class="form-control h-100"
                   (click)="onFileInputClick($event)"
                   (change)="onFileSelected($event)">
            <button class="btn theme-btn h-100" type="submit" [disabled]="!isUploadFileButtonEnabled()"
                    translate="button.uploadFile" (click)="onUploadFile()">
            </button>
        </div>
    </div>
    <div *ngIf="errorsAfterUpload?.length > 0">
        <table class="table">
            <thead>
            <th>{{'label.excelImporterTab.errorsAfterUpload' | translate}}</th>
            </thead>
            <tbody>
            <tr *ngFor="let error of errorsAfterUpload">
                <td>
                    {{error}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
