<div class="container-fluid create-resource-type-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-resource-type-title">
            {{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase" translate="label.resourceType2"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <div *ngIf="screenTemplateLayout.action === constants.VIEW" class="view-container">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{resourceTypeItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{resourceTypeItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.isDirectlyBookable"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.isDirectlyBookable" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.isDirectlyBookable" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.isRoom"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.isRoom" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.isRoom" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.canBeRegisteredToWaitList"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.canBeRegisteredToWaitList" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.canBeRegisteredToWaitList" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.hasContactInformation"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.hasContactInformation" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.hasContactInformation" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.hasUniqueCode"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.hasUniqueCode" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.hasUniqueCode" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.hasImage"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.hasImage" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.hasImage" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.hasDescription"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.hasDescription" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.hasDescription" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.representsPerson"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.representsPerson" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.representsPerson" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.alerts"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.hasAdditionalInfo" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.hasAdditionalInfo" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.displayAlertsAsTextOnPatientAccess"></td>
                                    <td class="text-right">
                                        <span *ngIf="resourceTypeItem.displayAlwaysPatientAccessAdditionalInformation" translate="label.yes"></span>
                                        <span *ngIf="!resourceTypeItem.displayAlwaysPatientAccessAdditionalInformation" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="screenTemplateLayout.action !== constants.VIEW" class="create-edit-container">
                <div class="short-id-and-name-container">
                    <div class="form-group mr-3"
                         [ngClass]="{'disabled-input': 'true'}"
                         *ngIf="screenTemplateLayout.action !== constants.CREATE">
                        <label translate="label.shortId"></label>
                        <input type="text" name="shortId"
                               class="input-element"
                               placeholder="{{'label.shortId'|translate}}"
                               [(ngModel)]="resourceTypeItem.shortId">
                    </div>
                    <div class="form-group">
                        <label translate="label.name"></label>
                        <input type="text" name="name" class="input-element"
                               [(ngModel)]="resourceTypeItem.name"
                               (ngModelChange)="onInputChanged(resourceTypeItem)"
                               [ngClass]="{'input-element-has-error': !resourceTypeItem.name}"
                               placeholder="{{'label.name'|translate}}">
                        <div *ngIf="!resourceTypeItem.name
                             && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                            <span translate="label.error.required"></span>
                        </div>
                    </div>
                </div>
                <div class="switches-container">
                    <!--Is Directly Bookable-->
                    <div class="form-group">
                        <label translate="label.isDirectlyBookable"></label>
                        <i *ngIf="screenTemplateLayout.action !== constants.VIEW"
                           class="fas fa-exclamation-triangle ml-1 has-warning"
                           [ngbPopover]="isDirectlyBookableEffectWarning"
                           placement="right-top"
                           popoverClass="popover-shadow"
                           triggers="mouseenter:mouseleave">
                        </i>
                        <ng-template #isDirectlyBookableEffectWarning>
                            <span translate="label.isDirectlyBookableEffectWarning"></span>
                        </ng-template>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="isDirectlyBookable"
                                       [(ngModel)]="resourceTypeItem.isDirectlyBookable">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Is Room-->
                    <div class="form-group">
                        <label translate="label.isRoom"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="isRoom"
                                       [(ngModel)]="resourceTypeItem.isRoom">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Can Be Registered To Wait List-->
                    <div class="form-group">
                        <label translate="label.canBeRegisteredToWaitList"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="canBeRegisteredToWaitList"
                                       [(ngModel)]="resourceTypeItem.canBeRegisteredToWaitList">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Has Contact Information-->
                    <div class="form-group">
                        <label translate="label.hasContactInformation"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="hasContactInformation"
                                       [(ngModel)]="resourceTypeItem.hasContactInformation">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Has Unique Code-->
                    <div class="form-group">
                        <label translate="label.hasUniqueCode"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="hasUniqueCode"
                                       [(ngModel)]="resourceTypeItem.hasUniqueCode">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Has Image-->
                    <div class="form-group">
                        <label translate="label.hasImage"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="hasImage"
                                       [(ngModel)]="resourceTypeItem.hasImage">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Has Description-->
                    <div class="form-group">
                        <label translate="label.hasDescription"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="hasDescription"
                                       [(ngModel)]="resourceTypeItem.hasDescription">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--RepresentsPerson-->
                    <div class="form-group">
                        <label translate="label.representsPerson"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="representsPerson"
                                       [(ngModel)]="resourceTypeItem.representsPerson">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Has Additional Information-->
                    <div class="form-group">
                        <label translate="label.alerts"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="hasAdditionalInformation"
                                       [(ngModel)]="resourceTypeItem.hasAdditionalInfo">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                    <!--Display Always Patient Access Additional Information-->
                    <div class="form-group">
                        <label translate="label.displayAlertsAsTextOnPatientAccess"></label>
                        <div class="switch">
                            <label>
                                <span translate="label.no"></span>
                                <input type="checkbox" checked name="displayAlwaysAdditionalInformation"
                                       [(ngModel)]="resourceTypeItem.displayAlwaysPatientAccessAdditionalInformation">
                                <span class="lever switch-col-black"></span>
                                <span translate="label.yes"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <app-upload-file [options]="uploadFileOptions"></app-upload-file>
            </div>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button class="btn mr-3"
                (click)="goToParentPage()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button class="btn theme-btn"
                (click)="saveResourceTypeData(resourceTypeItem)"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                [disabled]="!isTemplateValid"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'ResourceTypeUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
