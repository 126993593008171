import {Injectable} from '@angular/core';
import {
    CrossAvailabilityRuleType,
    Expand,
    ODataQueryObjectType,
    ChannelDependentFiltersType,
    AreaDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AreaModalFilterOptionsType,
    ChannelModalFilterOptionsType,
    CrossAvailabilityRuleModalFiltersType,
    CrossAvailabilityRuleStateType,
    EnumModalFilterOptionsType,
    TagModalFilterOptionsType,
} from './cross-availability-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesUtils} from '../../rules.utils';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class CrossAvailabilityRuleUtils {
    overviewState: CrossAvailabilityRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
        private rulesUtils: RulesUtils,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'crossAvailabilityRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.priority',
            hasSort: true,
            onSortByProperty: 'priority',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.onlyWarning',
            abbreviatedLabel: 'label.onlyWarningAbbreviation',
            hasSort: true,
            onSortByProperty: 'onlyWarning',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.type',
            hasSort: true,
            onSortByProperty: 'type',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.channel',
            hasSort: true,
            onSortByProperty: 'channel',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.area2',
            hasSort: true,
            onSortByProperty: 'area',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.centerTag',
            hasSort: true,
            onSortByProperty: 'centerTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.coveragePlanTag',
            abbreviatedLabel: 'label.coveragePlanTagAbbreviation',
            hasSort: true,
            onSortByProperty: 'coveragePlanTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.specialityTag',
            abbreviatedLabel: 'label.specialityTagAbbreviation',
            hasSort: true,
            onSortByProperty: 'specialityTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.serviceTag',
            hasSort: true,
            onSortByProperty: 'serviceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resourceTag',
            hasSort: true,
            onSortByProperty: 'resourceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.availabilityTag',
            abbreviatedLabel: 'label.availabilityTagAbbreviation',
            hasSort: true,
            onSortByProperty: 'availabilityTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientTag',
            hasSort: true,
            onSortByProperty: 'patientTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.unit',
            hasSort: true,
            onSortByProperty: 'unit',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.value',
            hasSort: true,
            onSortByProperty: 'value',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.deactivateBefore',
            abbreviatedLabel: 'label.deactivateBeforeAbbreviation',
            hasSort: true,
            onSortByProperty: 'deactivateBefore',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.ruleScope',
            hasSort: true,
            onSortByProperty: 'ruleScope',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientTagException',
            hasSort: true,
            onSortByProperty: 'patientTagException',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.message',
            hasSort: true,
            onSortByProperty: 'message',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'crossAvailabilityRuleTableId':
                    case 'areaId':
                    case 'centerTagId':
                    case 'coveragePlanTagId':
                    case 'specialityTagId':
                    case 'serviceTagId':
                    case 'resourceTagId':
                    case 'availabilityTagId':
                    case 'patientTagId':
                    case 'patientTagExceptionId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'channel':
                    case 'ruleScope':
                    case 'type': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                const SEARCH_BY_NAME = [
                    'crossAvailabilityRuleTable',
                    'area',
                    'centerTag',
                    'coveragePlanTag',
                    'specialityTag',
                    'serviceTag',
                    'resourceTag',
                    'availabilityTag',
                    'patientTag',
                    'patientTagException',
                ];
                if (SEARCH_BY_NAME.includes(item)) {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            Area: {select: ['Id', 'Name']},
            CenterTag: {select: EXPAND_TAG_SELECT},
            CoveragePlanTag: {select: EXPAND_TAG_SELECT},
            SpecialityTag: {select: EXPAND_TAG_SELECT},
            ServiceTag: {select: EXPAND_TAG_SELECT},
            ResourceTag: {select: EXPAND_TAG_SELECT},
            AvailabilityTag: {select: EXPAND_TAG_SELECT},
            PatientTag: {select: EXPAND_TAG_SELECT},
            PatientTagException: {select: EXPAND_TAG_SELECT},
            CrossAvailabilityRuleTable: {select: ['Id', 'Name']},
        };
    }

    getQueryFilterForRuleTableType(id: string): ODataQueryObjectType {
        return {
            select: ['Type'],
            filter: {CrossAvailabilityRuleTableId: {eq: {type: 'guid', value: id}}}
        };
    }

    getInitialRule(): CrossAvailabilityRuleType {
        return {
            crossAvailabilityRuleTableId: undefined,
            name: undefined,
            active: false,
            type: undefined,
            channel: undefined,
            areaId: undefined,
            centerTagId: undefined,
            coveragePlanTagId: undefined,
            specialityTagId: undefined,
            serviceTagId: undefined,
            resourceTagId: undefined,
            availabilityTagId: undefined,
            patientTagId: undefined,
            patientTagExceptionId: undefined,
            unit: undefined,
            value: undefined,
            deactivateBefore: 0,
            ruleScope: undefined
        } as CrossAvailabilityRuleType;
    }

    getInitialModalFilters(): CrossAvailabilityRuleModalFiltersType {
        return {
            areaOptions: {
                ngModel: []
            } as AreaModalFilterOptionsType,
            channelOptions: {
                ngModel: []
            } as ChannelModalFilterOptionsType,
            typeOptions: {
                ngModel: undefined
            } as EnumModalFilterOptionsType,
            ruleScopeOptions: {
                ngModel: undefined
            } as EnumModalFilterOptionsType,
            centerTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            coveragePlanTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            specialityTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            serviceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            resourceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            availabilityTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            patientTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            patientTagExceptionOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            areFiltersActive: false,
        } as CrossAvailabilityRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: CrossAvailabilityRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: []
        } as ChannelDependentFiltersType;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }
}
