<div class="modal-header">
    <h4 class="modal-title">
        <strong translate="label.export"></strong>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

    <div class="modal-body">
        <app-generate-export [options]="optionsForPlannedCapacityForRooms"></app-generate-export>
    </div>

<div class="modal-footer">
    <button class="btn close-btn" (click)="closeModel()" translate="label.close"></button>
</div>
