<div class="container-fluid create-relation-pairs-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-relation-pairs-header">
        <h3 class="create-relation-pairs-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase" translate="label.relationPairs"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate (ngSubmit)="saveRelationPairsData(relationPairsItem)">
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-5">
                        <div class="form-group">
                            <label translate="label.firstRelation"></label>
                            <input type="text" name="firstRelation"
                                   class="input-element"
                                   placeholder="{{'label.firstRelation'|translate}}"
                                   [(ngModel)]="relationPairsItem.firstRelation"
                                   [ngClass]="{'input-element-has-error': !relationPairsItem.firstRelation}"
                                   autocomplete="off">
                            <div *ngIf="!relationPairsItem.firstRelation" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.secondRelation"></label>
                            <input type="text" name="secondRelation"
                                   class="input-element"
                                   placeholder="{{'label.secondRelation'|translate}}"
                                   [(ngModel)]="relationPairsItem.secondRelation"
                                   [ngClass]="{'input-element-has-error': !relationPairsItem.secondRelation}"
                                   autocomplete="off">
                            <div *ngIf="!relationPairsItem.secondRelation" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-5">
                        <div class="form-group">
                            <label translate="label.firstRelationManageData"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="relationPairsItem.firstRelationCanManageDataDefault"
                                           name="firstRelationCanManageDataDefault"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.secondRelationManageData"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="relationPairsItem.secondRelationCanManageDataDefault"
                                           name="secondRelationCanManageDataDefault"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.firstRelation"></td>
                                    <td class="text-right">{{relationPairsItem.firstRelation}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.firstRelationManageData"></td>
                                    <td class="text-right">
                                        <span *ngIf="relationPairsItem.firstRelationCanManageDataDefault"
                                              translate="label.yes"></span>
                                        <span *ngIf="!relationPairsItem.firstRelationCanManageDataDefault"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.secondRelation"></td>
                                    <td class="text-right">{{relationPairsItem.secondRelation}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.secondRelationManageData"></td>
                                    <td class="text-right">
                                        <span *ngIf="relationPairsItem.secondRelationCanManageDataDefault"
                                              translate="label.yes"></span>
                                        <span *ngIf="!relationPairsItem.secondRelationCanManageDataDefault"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--End Body-->
            <!-- =========================================== -->

            <!--Footer-->
            <!-- =========================================== -->
            <div class="mt-2 footer-position-sticky">
                <button class="btn mr-3" type="button" (click)="goToParentPage()"
                        [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                        translate="{{screenTemplateLayout.cancelOrBackButton}}">
                </button>
                <button class="btn theme-btn" type="submit"
                        [hidden]="screenTemplateLayout.action === constants.VIEW"
                        translate="{{screenTemplateLayout.createOrSaveButton}}">
                </button>
                <button (click)="goToEdit()"
                        *ngIf="screenTemplateLayout.action===constants.VIEW"
                        [activityDisplay]="'RelationPairUpdate'"
                        class="btn theme-btn"
                        type="button">
                    <span translate="label.edit"></span>
                </button>
            </div>
            <!--End Footer-->
            <!-- =========================================== -->
        </form>
    </div>
</div>
