import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {MultiAppointmentTemplateMDComponent} from './multi-appointment-template-md.component';
import {CreateMultiAppointmentTemplateComponent} from './create-multi-appointment-template/create-multi-appointment-template.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [MultiAppointmentTemplateMDComponent, CreateMultiAppointmentTemplateComponent],
    imports: [
        CommonModule,
        SharedModule,
        DragDropModule
    ]
})
export class MultiAppointmentModule {
}
