import {Injectable} from '@angular/core';
import {GraphicalResourcePlannerUtils} from 'sked-base/lib/components/graphical-resource-planner/graphical-resource-planner.utils';
import {
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider, DateRangeOptionsType,
    FilterWrapperButtonNameEnum,
    FilterWrapperNameEnum, FormValidationType,
    GenericFilterOptionsType, GenericFilterResultType, LocationDependentFiltersType,
    PatientProvider,
    ResourceDependentFiltersType,
    ResourceProvider, ResourceTypeDependentFilterType, ResourceTypeProvider, ServiceDependentFiltersType,
    ServiceProvider,
    SkedTaskTypeEnum, SpecialityDependentFiltersType,
    SpecialityProvider
} from 'sked-base';
import {
    ResourceUtilizationFiltersSearchType,
    ResourceUtilizationFiltersValidationType,
    ResourceUtilizationFilterWrapperInitialValuesType
} from './resource-utilization-filters.types';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';

@Injectable({
    providedIn: 'root'
})
export class ResourceUtilizationFiltersUtil {
    constructor(
        private resourceProvider: ResourceProvider,
        private centerProvider: CenterProvider,
        private specialityProvider: SpecialityProvider,
        private areaProvider: AreaProvider,
        private resourceTypeProvider: ResourceTypeProvider,
        private dateTimeUtils: DateTimeUtils
    ) {
    }

    getInitialFilterWrapperValuesFromPreviousValues(
        resourceUtilizationFiltersValues: ResourceUtilizationFilterWrapperInitialValuesType
    ): GenericFilterResultType[] {
        const filterWrapperValues: GenericFilterResultType[] = [];
        Object.keys(resourceUtilizationFiltersValues).forEach((property: string) => {
            if (!!resourceUtilizationFiltersValues[property]) {
                filterWrapperValues.push({
                    name: FilterWrapperNameEnum[property],
                    value: resourceUtilizationFiltersValues[property]
                } as GenericFilterResultType);
            }
        });
        return filterWrapperValues;
    }

    mapFilterValuesForSearch(
        dateRangeOptions: DateRangeOptionsType,
        resourceUtilizationFiltersValues: ResourceUtilizationFilterWrapperInitialValuesType
    ): ResourceUtilizationFiltersSearchType {
        return {
            dateFrom: dateRangeOptions.fromDate,
            dateTo: dateRangeOptions.toDate,
            resourceType: resourceUtilizationFiltersValues.resourceType,
            resource: resourceUtilizationFiltersValues.resource,
            location: resourceUtilizationFiltersValues.location,
            area: resourceUtilizationFiltersValues.area,
            speciality: resourceUtilizationFiltersValues.speciality
        } as ResourceUtilizationFiltersSearchType;
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: true,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: true,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })

    getSpecialityDependentFilters = (): SpecialityDependentFiltersType => ({
        searchPhrase: '',
        areaId: null,
        count: true,
        exclusionList: []
    })

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getResourceTypeDependentFilters(): ResourceTypeDependentFilterType {
        return {
            searchPhrase: '',
            exclusionList: []
        };
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const todayMoment = moment();
        const todayPlus30DaysMoment = moment().add(30, 'days');
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayPlus30Days = this.dateTimeUtils.getNgbDateFromMoment(todayPlus30DaysMoment);
        const fromDate = { year: today.year, month: today.month, day: today.day };
        const toDate = { year: todayPlus30Days.year, month: todayPlus30Days.month, day: todayPlus30Days.day };
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.validFrom',
            toDateLabel: 'label.validTo',
            minDate: fromDate,
            maximumRange: 30,
            disabled: false
        } as DateRangeOptionsType;
    }

    getInitialFilterValidations(): ResourceUtilizationFiltersValidationType {
        return {
            dateRange: { isValid: true, errorMessage: '' } as FormValidationType,
            resourceType: { isValid: true, errorMessage: '' } as FormValidationType,
            resource: { isValid: true, errorMessage: '' } as FormValidationType,
            location: { isValid: true, errorMessage: '' } as FormValidationType,
            area: { isValid: true, errorMessage: '' } as FormValidationType,
            speciality: { isValid: true, errorMessage: '' } as FormValidationType
        } as ResourceUtilizationFiltersValidationType;
    }

    getFilterWrapperOptions = (initialValues?: ResourceUtilizationFilterWrapperInitialValuesType): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.getResourceTypeDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resourceType,
                name: FilterWrapperNameEnum.resourceType,
                parentFilterValue: initialValues?.resourceType ? {
                    name: FilterWrapperNameEnum.resourceType,
                    value: initialValues.resourceType
                } : undefined,
                providerInstance: this.resourceTypeProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getResourceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resource,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues?.resource ? {
                    name: FilterWrapperNameEnum.resource,
                    value: initialValues.resource
                } : undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getLocationDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.location,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues?.location ? {
                    name: FilterWrapperNameEnum.location,
                    value: initialValues.location
                } : undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getAreaDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.area,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues?.area ? {
                    name: FilterWrapperNameEnum.area,
                    value: initialValues.area
                } : undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getSpecialityDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.speciality,
                name: FilterWrapperNameEnum.speciality,
                parentFilterValue: initialValues?.speciality ? {
                    name: FilterWrapperNameEnum.speciality,
                    value: initialValues.speciality
                } : undefined,
                providerInstance: this.specialityProvider,
                useSelectedValueAsLabel: true
            }
        ]
    )
}
