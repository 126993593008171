import {Router} from '@angular/router';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ServiceMdUtils} from './service-md.util';
import {
    AreaProvider,
    CenterProvider,
    ChannelProvider,
    ConfirmDeleteModalService,
    ExportFileEntityEnum,
    MultiResourceBluePrintProvider,
    ServiceProvider,
    ServiceType,
    SpecialityProvider,
    EntityTypeEnum
} from 'sked-base';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {Subject} from 'rxjs';
import {constants} from '../../shared/constants/constants';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ServiceMDSearchByEnum, ServiceMDSearchByNameType, ServiceMDSearchByShortIdType} from './service-md.types';
import * as lodash from 'lodash';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-service-md',
    templateUrl: './service-md.component.html',
    styleUrls: ['./service-md.component.scss']
})
export class ServiceMDComponent implements OnInit, AfterViewInit, OnDestroy {
    exportFileEntityEnum = ExportFileEntityEnum;
    ServiceMDSearchByEnum = ServiceMDSearchByEnum;
    objectKeys = Object.keys;
    mainDependentFilters: any;
    tableItems: ServiceType[] = [];
    centerButtonName = 'label.center';
    areaButtonName = 'label.area';
    channelButtonName = 'label.channelExclusions';
    specialityButtonName = 'label.speciality';
    multiResourceBluePrintButtonName = 'label.bluePrintName';
    totalTableItems: number;
    itemsPerPageList: number[];
    showItemsPerPageDropdown = false;
    public MDEntityName: string;
    public constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchServiceChanged = new Subject<ServiceMDSearchByNameType | ServiceMDSearchByShortIdType>(); //notify wend search input changed

    constructor(
        private serviceProvider: ServiceProvider,
        private previousRouteService: PreviousRouteService,
        public serviceMdUtils: ServiceMdUtils,
        public centerProvider: CenterProvider,
        public areaProvider: AreaProvider,
        public channelProvider: ChannelProvider,
        public multiResourceBluePrintProvider: MultiResourceBluePrintProvider,
        public specialityProvider: SpecialityProvider,
        public ngxLoader: NgxUiLoaderService,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private router: Router,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.MDEntityName = this.serviceProvider.name;
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`${this.MDEntityName}Read`);
        this.mainDependentFilters = {
            location: this.serviceMdUtils.getLocationDependentFilters(),
            area: this.serviceMdUtils.getAreaDependentFilters(),
            channel: this.serviceMdUtils.getChannelDependentFilters(),
            speciality: this.serviceMdUtils.getSpecialityDependentFilters(),
            multiResourceBluePrint: this.serviceMdUtils.getMultiResourceBluePrintDependentFilters()
        };
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();

        // If not coming from route createMd, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/createService') {
            this.loadInitialFilters({tableFilters: true, modalFilters: false, sbaseFilters: true});
        }

        this.subscribeSearchChanged();
        this.getTableEntries();
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            // If not coming from route createMd, reset filters to initial values
            if (this.previousRouteService.getPreviousUrl() !== '/createService') {
                this.loadInitialFilters({tableFilters: false, modalFilters: true, sbaseFilters: false});
            }
        });
    }

    // function to navigate create-service-md component
    createService() {
        this.router.navigate(['createService']);
    }

    viewService(service: ServiceType) {
        this.router.navigate(['/createService'], {state: {service, action: constants.VIEW}});
    }

    editService(service: ServiceType) {
        this.router.navigate(['/createService'], {state: {service, action: constants.EDIT}});
    }

    onSearchServiceChanged(name: ServiceMDSearchByEnum, value: string) {
        const SEARCH_BY_OBJECT = name === ServiceMDSearchByEnum.Name ? {name, value} : {name, value: +value};
        if (!(this.serviceMdUtils.isSearchByName(SEARCH_BY_OBJECT) || this.serviceMdUtils.isSearchByShortId(SEARCH_BY_OBJECT))) {
            return;
        }
        this.searchServiceChanged.next(SEARCH_BY_OBJECT);
    }

    onOnlineConsultationChanged(value: boolean) {
        this.serviceMdUtils.tableFilters.filter.onlineConsultation = value;
        this.getTableEntries();
    }

    onSelectedCenterFilter(centerFilterList): void {
        this.serviceMdUtils.initialCenterValues = centerFilterList;
        this.serviceMdUtils.tableFilters.filter.center = (centerFilterList && centerFilterList.length > 0) ? centerFilterList[0] : undefined;
        this.getTableEntries();
    }

    onSelectedAreaFilter(areaFilterList): void {
        this.serviceMdUtils.initialAreaValues = areaFilterList;
        this.mainDependentFilters.speciality.areaId = areaFilterList?.length > 0 ? areaFilterList[0].id : null;
        this.serviceMdUtils.tableFilters.filter.areaId = (areaFilterList && areaFilterList.length > 0) ? areaFilterList[0].id : undefined;
        this.getTableEntries();
    }

    onSelectedChannelFilter(channelFilterList): void {
        this.serviceMdUtils.initialChannelValues = channelFilterList;
        this.serviceMdUtils.tableFilters.filter.channelRestriction =
            (channelFilterList && channelFilterList.length > 0) ? channelFilterList[0] : undefined;
        this.getTableEntries();
    }

    onSelectedSpecialityFilter(specialityFilterList): void {
        this.serviceMdUtils.initialSpecialityValues = specialityFilterList;
        this.serviceMdUtils.tableFilters.filter.specialityId = specialityFilterList?.length > 0 ? specialityFilterList[0].id : undefined;
        this.getTableEntries();
    }

    onSelectedMultiResourceBluePrintFilter(multiResourceBluePrintFilterList): void {
        if (multiResourceBluePrintFilterList?.length > 0) {
            this.serviceMdUtils.modalFilters.multiResourceBluePrintFilterOptions.ngModel = multiResourceBluePrintFilterList[0];
            this.serviceMdUtils.initialMultiResourceBluePrintFilterValues = multiResourceBluePrintFilterList;
        } else {
            this.serviceMdUtils.modalFilters.multiResourceBluePrintFilterOptions.ngModel = undefined;
            this.serviceMdUtils.initialMultiResourceBluePrintFilterValues = [];
        }
    }

    // on change of page number.
    onChangePagination(page: number) {
        this.serviceMdUtils.tableFilters.currentPage = page;
        this.getTableEntries(false);
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    // function to change the size of the page.
    changeNumberOfItemsPerPage(itemPerPage) {
        this.serviceMdUtils.tableFilters.currentPage = 1;
        this.serviceMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getTableEntries(false);
    }

    onSortBy(property: string) {
        const isAscendingMode = this.serviceMdUtils.tableFilters.orderBy[property];

        if (this.serviceMdUtils.tableFilters.orderBy) {
            this.serviceMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.getTableEntries(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.serviceMdUtils.tableFilters.orderBy[selectedItem];
        this.getTableEntries(false);
    }

    displayConfirmDeleteItemModal(serviceId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.serviceProvider, serviceId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(serviceId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onClearFilters() {
        // Hide modal filters button to reset the color. The modal filters options will be set again inside loadFilters
        this.serviceMdUtils.modalFilters = undefined;
        // Load filters and data
        setTimeout(() => {
            this.loadInitialFilters({tableFilters: true, modalFilters: true, sbaseFilters: true});
            this.getTableEntries(true);
        });
    }

    private loadInitialFilters({tableFilters, modalFilters, sbaseFilters}: {tableFilters?: boolean, modalFilters?: boolean, sbaseFilters?: boolean}) {
        if (!!tableFilters) {
            this.serviceMdUtils.tableFilters = this.serviceMdUtils.getInitialTableFilter();
        }
        if (!!modalFilters) {
            this.serviceMdUtils.modalFilters = this.serviceMdUtils.getInitialModalFilters();
            this.serviceMdUtils.previousModalFilters = this.serviceMdUtils.getInitialModalFilters();
        }
        if (!!sbaseFilters) {
            this.serviceMdUtils.initialCenterValues = [];
            this.serviceMdUtils.initialAreaValues = [];
            this.serviceMdUtils.initialChannelValues = [];
            this.serviceMdUtils.initialSpecialityValues = [];
            this.serviceMdUtils.initialMultiResourceBluePrintFilterValues = [];
        }
    }

    private deleteItem(id) {
        this.ngxLoader.start();
        this.serviceProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getTableEntries();
                this.messagesService.success('toastr.success.serviceDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchChanged() {
        const self = this;
        this.searchServiceChanged
            .pipe(
                filter((searchByObject) => {
                    if (this.serviceMdUtils.isSearchByName(searchByObject)) {
                        return searchByObject.value.length >= 3 || searchByObject.value.length === 0;
                    }
                    return true;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.serviceMdUtils.tableFilters.filter[searchValue.name] = searchValue.value;
                if (this.serviceMdUtils.isSearchByShortId(searchValue) && !(Number.isInteger(searchValue.value) && searchValue.value > 0)) {
                    delete self.serviceMdUtils.tableFilters.filter.shortId;
                }
                self.getTableEntries();
            });
    }

    // get services data and display into table
    private getTableEntries(includeCount: boolean = true) {
        const queryFilter = this.serviceMdUtils.getQueryFilterForServices(this.serviceMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.serviceProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.tableItems = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            this.serviceMdUtils.modalFilters.areFiltersActive = this.serviceMdUtils.isAnyFilterActive(this.serviceMdUtils.modalFilters);
            this.serviceMdUtils.previousModalFilters = lodash.cloneDeep(this.serviceMdUtils.modalFilters);
            // Filter the data based on the new filters
            this.serviceMdUtils.tableFilters.filter.hasMultiResourceBluePrint =
                this.serviceMdUtils.modalFilters.multiResourceBluePrintOptions.ngModel;
            this.serviceMdUtils.tableFilters.filter.multiResourceBluePrintId =
                (this.serviceMdUtils.modalFilters.multiResourceBluePrintFilterOptions.ngModel as any)?.length > 0
                    ? this.serviceMdUtils.modalFilters.multiResourceBluePrintFilterOptions.ngModel[0]?.id
                    : undefined;
            this.getTableEntries();
        } else {
            this.serviceMdUtils.modalFilters = lodash.cloneDeep(this.serviceMdUtils.previousModalFilters);
        }
    }
}
