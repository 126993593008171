import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
    name: 'dateEndOfDay'
})
export class DateEndOfDayPipe implements PipeTransform {

    transform(dateTime: string): string {
        if (dateTime) {
            //@ts-ignore
            return moment.parseZone(dateTime).subtract(1, 'days').format(moment.localeData().longDateFormat('fullDateNoTime'));
        } else {
            return undefined;
        }
    }

}
