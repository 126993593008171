import {Component, Input, OnInit} from '@angular/core';
import {AvailabilityDataType} from 'sked-base';
import { AvailabilityStatusEnum } from '../availability.types';

@Component({
    selector: 'app-view-availability',
    templateUrl: './view-availability.component.html',
    styleUrls: ['./view-availability.component.scss']
})
export class ViewAvailabilityComponent implements OnInit {
    @Input() availability: AvailabilityDataType;
    AvailabilityStatusEnum = AvailabilityStatusEnum;

    constructor() {
    }

    ngOnInit(): void {

    }
}
