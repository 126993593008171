import {Component, Input} from '@angular/core';
import {CrossAvailabilityRuleOptionEnum} from 'sked-base';

@Component({
    selector: 'app-rule-option-tooltip',
    templateUrl: './rule-option-tooltip.component.html'
})
export class RuleOptionTooltipComponent {
    @Input() ruleOption: CrossAvailabilityRuleOptionEnum;
    CrossAvailabilityRuleOptionEnum = CrossAvailabilityRuleOptionEnum;
    constructor() {}
}
