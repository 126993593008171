import {Component, OnInit, OnDestroy} from '@angular/core';
import {TableFiltersType} from 'src/app/data-model/general.type';
import {constants} from 'src/app/shared/constants/constants';
import {
    AvailabilityDataServiceType,
    AvailabilityDataType,
    AvailabilityProvider,
    ChannelDependentFiltersType,
    ChannelEnum,
    ChannelProvider,
    ConfirmDeleteModalService,
    DateRangeOptionsType,
    FilterComponentChannelType,
    FormValidationType,
    OversellingDefinitionProvider,
    OversellingDefinitionType,
    ServiceProvider
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {AvailabilityOversellingDefinitionMdUtils} from './availability-overselling-definition-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateAndTimeRangeType} from '../availability.types';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {forkJoin} from 'rxjs';

@AutoUnsubscribe()
@Component({
    selector: 'app-availability-overselling-definition-md',
    templateUrl: './availability-overselling-definition-md.component.html',
    styleUrls: ['./availability-overselling-definition-md.component.scss']
})
export class AvailabilityOversellingDefinitionMDComponent implements OnInit, OnDestroy {
    tableFilters: TableFiltersType;
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    availabilityOversellingDefinitionMdList: OversellingDefinitionType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    readonly AVAILABILITY_OVERSELLING_DEFINITION = 'OversellingDefinition';
    readonly CONSTANTS = constants;
    readonly REGEX_WHOLE_NUMBER = /^[0-9]\d*$/;
    availabilityData = {} as AvailabilityDataType;
    oldOversellingDefinitionItem: OversellingDefinitionType;
    availabilityOversellingDefinitionItem = this.availabilityOversellingDefinitionMdUtils.getInitialAvailabilityOversellingDefinition(this.availabilityData.id);
    serviceList: AvailabilityDataServiceType[] = [];

    availabilityDateAndTimeRange: DateAndTimeRangeType = {} as DateAndTimeRangeType;
    availabilityRangeValidation: FormValidationType = {} as FormValidationType;
    dateRangeOptions: DateRangeOptionsType = this.availabilityOversellingDefinitionMdUtils.getInitialDateRangeOptions();
    mainDependentFilters: {
        channel: ChannelDependentFiltersType
    };
    initialFilterValues = {
        channel: []
    };

    constructor(
        private availabilityProvider: AvailabilityProvider,
        private availabilityOversellingDefinitionMdUtils: AvailabilityOversellingDefinitionMdUtils,
        public oversellingDefinitionProvider: OversellingDefinitionProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public dateTimeUtils: DateTimeUtils,
        public channelProvider: ChannelProvider,
        public serviceProvider: ServiceProvider
    ) {
    }

    ngOnInit() {
        this.setUpInitialData();
    }

    ngOnDestroy(): void {
    }

    saveAvailabilityOversellingDefinitionData() {
        const isDateTimeFromInThePast = moment(this.availabilityOversellingDefinitionItem.dateTimeFrom).isBefore(moment.now());
        if (isDateTimeFromInThePast) {
            this.messagesService.error('toastr.error.dateNotInPast', true);
            return;
        }
        if (this.isFormValid()) {
            this.availabilityOversellingDefinitionItem.services = this.serviceList.map(service => ({id: service.serviceId} as AvailabilityDataServiceType));
            if (this.availabilityOversellingDefinitionItem.id) {
                this.editAvailabilityOversellingDefinition();
            } else {
                this.createAvailabilityOversellingDefinition();
            }
        }
    }

    createAvailabilityOversellingDefinition() {
        this.ngxLoader.start();
        this.oversellingDefinitionProvider.addEntry(this.availabilityOversellingDefinitionItem).subscribe(() => {
            this.loadAvailabilityOversellingDefinitionMdData();
            this.messagesService.success('toastr.success.oversellingDefinitionCreated', true);
            this.setupInitialFormData();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    editAvailabilityOversellingDefinition() {
        this.ngxLoader.start();
        this.oversellingDefinitionProvider.updateEntry(this.oldOversellingDefinitionItem, this.availabilityOversellingDefinitionItem).subscribe(() => {
            this.loadAvailabilityOversellingDefinitionMdData();
            this.messagesService.success('toastr.success.oversellingDefinitionUpdated', true);
            this.setupInitialFormData();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    setOversellingDefinitionItemDataForEdit(availabilityOversellingDefinition: OversellingDefinitionType) {
        const oversellingDefinition = availabilityOversellingDefinition;
        this.initialFilterValues.channel = oversellingDefinition.channel ? [{name: oversellingDefinition.channel}] : [];
        this.serviceList = this.availabilityData.services
            .filter(service => oversellingDefinition.services
                .find(serviceInItem => serviceInItem.id === service.serviceId)
            );
        this.availabilityDateAndTimeRange = this.dateTimeUtils
            .getDateAndTimeRangeFromString(oversellingDefinition.dateTimeFrom, oversellingDefinition.dateTimeTo, this.availabilityData.timeZoneId);
        this.oldOversellingDefinitionItem = oversellingDefinition;
        this.availabilityOversellingDefinitionItem = lodash.cloneDeep(oversellingDefinition);
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tableFilters.currentPage = page;
        this.loadAvailabilityOversellingDefinitionMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadAvailabilityOversellingDefinitionMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.tableFilters.orderBy[property];
        if (this.tableFilters.orderBy) {
            this.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadAvailabilityOversellingDefinitionMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.tableFilters.orderBy[selectedItem];
        this.loadAvailabilityOversellingDefinitionMdData(false);
    }

    displayConfirmDeleteItemModal(availabilityOversellingDefinitionId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.oversellingDefinitionProvider, availabilityOversellingDefinitionId).result
            .then(() => {
            }, (response) => {
                if (response) {
                    this.deleteItem(availabilityOversellingDefinitionId);
                }
            });
    }

    goToParentPage() {
        this.router.navigate(['/availability']);
    }

    clearForm() {
        this.setupInitialFormData();
    }

    onValidFromDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct, dateChanged: boolean = false) {
        // if validFrom date is changed, set validFrom time to 00:00
        this.availabilityDateAndTimeRange.timeFrom = dateChanged ? {hour: 0, minute: 0, second: 0} as NgbTimeStruct : time;
        this.availabilityOversellingDefinitionItem.dateTimeFrom = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.availabilityDateAndTimeRange.timeFrom, this.availabilityData.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    onValidToDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct) {
        this.availabilityDateAndTimeRange.timeTo = time;
        this.availabilityOversellingDefinitionItem.dateTimeTo = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.availabilityDateAndTimeRange.timeTo, this.availabilityData.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    getValidateAvailabilityDateAndTimeRange(): FormValidationType {
        return this.dateTimeUtils.getValidateDateAndTimeRange(
            this.availabilityOversellingDefinitionItem.dateTimeFrom,
            this.availabilityOversellingDefinitionItem.dateTimeTo,
            this.availabilityData.endOfDay,
            this.availabilityData.timeZoneId
        );
    }

    onSelectedChannelFilter([selectedChannel]: FilterComponentChannelType[]) {
        this.initialFilterValues.channel = [selectedChannel];
        this.availabilityOversellingDefinitionItem.channel = [selectedChannel]?.length ? ChannelEnum[selectedChannel.enumValue] : undefined;

    }

    onSelectedService(selectedServiceList: AvailabilityDataServiceType[]) {
        this.availabilityOversellingDefinitionItem.services = selectedServiceList;
    }

    isFormValid(): boolean {
        return (this.availabilityOversellingDefinitionItem && this.availabilityOversellingDefinitionItem.services.length
            && this.availabilityOversellingDefinitionItem.quantity && this.availabilityRangeValidation.isValid);
    }

    private setupInitialFormData() {
        this.availabilityOversellingDefinitionItem = this.availabilityOversellingDefinitionMdUtils.getInitialAvailabilityOversellingDefinition(this.availabilityData.id);
        this.initialFilterValues.channel = [];
        this.serviceList = [];
        this.setInitialFormDateData();
    }

    private setInitialFormDateData() {
        this.availabilityDateAndTimeRange = this.dateTimeUtils
            .getDateAndTimeRangeFromString(new Date().toString(), this.availabilityData.validTo, this.availabilityData.timeZoneId);
        this.availabilityOversellingDefinitionItem.dateTimeFrom = this.availabilityOversellingDefinitionMdUtils
            .getDateStringFromNgbStruct(this.availabilityDateAndTimeRange.dateFrom, this.availabilityDateAndTimeRange.timeFrom, this.availabilityData.timeZoneId);
        this.availabilityOversellingDefinitionItem.dateTimeTo = this.availabilityOversellingDefinitionMdUtils
            .getDateStringFromNgbStruct(this.availabilityDateAndTimeRange.dateTo, this.availabilityDateAndTimeRange.timeTo, this.availabilityData.timeZoneId);
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    private setUpInitialData() {
        this.mainDependentFilters = {
            channel: this.availabilityOversellingDefinitionMdUtils.getChannelDependentFilters()
        };
        if (!history.state?.availability) {
            this.goToParentPage();
            return;
        }
        this.availabilityData = history.state.availability;
        this.loadInitialData();
        this.loadAvailabilityOversellingDefinitionMdData();
    }

    // function to get AvailabilityOversellingDefinition data
    private loadInitialData() {
        this.ngxLoader.start();
        forkJoin([
            this.availabilityProvider.getAvailabilityDataById(this.availabilityData.id),
            this.serviceProvider.getEntries({})
        ]).subscribe(([availabilityDataWithTimeZoneId, services]) => {
            this.availabilityData.timeZoneId = availabilityDataWithTimeZoneId.timeZoneId;
            this.setupInitialFormData();
            this.serviceList = this.availabilityData.services.filter(serviceInAvailability => services.value.find(service => serviceInAvailability.id === service.id));
            this.ngxLoader.stop();
        }, error => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    private loadAvailabilityOversellingDefinitionMdData(includeCount: boolean = true) {
        this.tableFilters = this.availabilityOversellingDefinitionMdUtils.getInitialTableFilter(this.availabilityData.id);
        const queryFilter = this.availabilityOversellingDefinitionMdUtils
            .getQueryFilterForAvailabilityOversellingDefinitionMD(this.tableFilters, includeCount);
        this.ngxLoader.start();
        this.oversellingDefinitionProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe(({value, count}) => {
                this.availabilityOversellingDefinitionMdList = value;
                if (count !== undefined && count !== null) {
                    this.totalTableItems = count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.tableFilters = this.availabilityOversellingDefinitionMdUtils.getInitialTableFilter(this.availabilityData.id);
        this.ngxLoader.start();
        this.oversellingDefinitionProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadAvailabilityOversellingDefinitionMdData();
                this.messagesService.success('toastr.success.oversellingDefinitionDeleted', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
