<div class="container-fluid create-patient-control-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-patient-control-rule-header">
        <h3 class="create-patient-control-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.patientControlRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.patientControlRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.patientControlRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.patientControlRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.patientControlRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule priority-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input type="number" name="priority" class="input-element"
                                           [(ngModel)]="ruleItem.priority"
                                           [ngClass]="{'input-element-has-error': !ruleItem.priority || !isPriorityValid(ruleItem.priority)}"
                                           min="1"
                                           step="1"
                                           max=200
                                           placeholder="{{'label.priority'|translate}}"
                                           autocomplete="off"/>
                                    <div *ngIf="!ruleItem.priority && ruleItem.priority !== 0" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="ruleItem.priority === 0 || ruleItem.priority > 200"
                                         class="help-block">
                                            <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                                min: 1,
                                                max: 200
                                            } }}</span>
                                    </div>
                                    <div *ngIf="ruleItem?.priority > 0 && ruleItem?.priority % 1 !== 0"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.invalidNumber">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2" *ngIf="!(ruleItem.specialityId || ruleItem.serviceId || ruleItem.resourceId)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.patientControlRuleMinimumConditionForInput"></span>
                        </div>
                        <div class="row">
                            <!-- Area -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.area2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedArea($event)"
                                                  [buttonName]="'label.area2'"
                                                  [dependentFilters]="mainDependentFilters.area"
                                                  [initialFilterValues]="[ruleItem.area]"
                                                  [providerInstance]="areaProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Speciality -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.speciality2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedSpeciality($event)"
                                                  [buttonName]="'label.speciality2'"
                                                  [dependentFilters]="mainDependentFilters.speciality"
                                                  [initialFilterValues]="[ruleItem.speciality]"
                                                  [providerInstance]="specialityProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Service -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect service">
                                    <label class="mb-1" translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                  [buttonName]='"label.service2"'
                                                  [dependentFilters]="mainDependentFilters?.service"
                                                  [initialFilterValues]="[ruleItem.service]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="serviceProvider">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Resource -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]="'label.resource'"
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [initialFilterValues]="[ruleItem.resource]"
                                                  [providerInstance]="resourceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Number of days in the past -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.numberOfDaysInThePast"></label>
                                        <input type="number" class="input-element"
                                               placeholder="{{'label.numberOfDaysInThePast' | translate}}"
                                               autocomplete="off" min="1"
                                               [ngClass]="{'input-element-has-error': !ruleItem.numberOfDaysInThePast || ruleItem.numberOfDaysInThePast < 0 || ruleItem?.numberOfDaysInThePast % 1 !== 0}"
                                               [(ngModel)]="ruleItem.numberOfDaysInThePast">
                                    </div>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.numberOfDaysInThePast)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.numberOfDaysInThePast) && (ruleItem?.numberOfDaysInThePast % 1 !== 0 || ruleItem?.numberOfDaysInThePast <= 0)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.invalidNumber">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Number of appointments -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.numberOfAppointments"></label>
                                        <input type="number" class="input-element"
                                               placeholder="{{'label.numberOfAppointments' | translate}}"
                                               autocomplete="off" min="1"
                                               [ngClass]="{'input-element-has-error': !ruleItem.numberOfAppointments ||  ruleItem.numberOfAppointments < 0 || ruleItem?.numberOfAppointments % 1 !== 0}"
                                               [(ngModel)]="ruleItem.numberOfAppointments">
                                    </div>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.numberOfAppointments)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.numberOfAppointments) && (ruleItem?.numberOfAppointments % 1 !== 0 || ruleItem?.numberOfAppointments <= 0)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.invalidNumber">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Patient type -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="mb-1 mr-2" translate="label.patientType"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               [ngClass]="!!ruleItem?.patientType ? 'ng-select-has-value' : 'input-element-has-error'"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.choose' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="ruleItem.patientType"
                                               (ngModelChange)="sanitizeNgSelectValue('patientType')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let item of patientTypeEnumList"
                                                   [value]="item">
                                            <span [translate]="'enum.patientType.' + item"></span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem?.patientType"
                                         class="help-block">
                                            <span [ngClass]="{'input-element-has-error': !ruleItem?.patientType}"
                                                  translate="label.error.required">
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.patientControlRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.priority"></td>
                                            <td class="text-right">{{ruleItem.priority}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.area2"></td>
                                            <td class="text-right">{{ruleItem.area?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.speciality2"></td>
                                            <td class="text-right">{{ruleItem.speciality?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.service2"></td>
                                            <td class="text-right">
                                                <span class="font-weight-bold"> {{ruleItem.service?.speciality?.name}} </span>
                                                {{ruleItem.service?.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.resource"></td>
                                            <td class="text-right">{{ruleItem.resource?.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.numberOfDaysInThePast"></td>
                                            <td class="text-right">{{ruleItem.numberOfDaysInThePast}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.numberOfAppointments"></td>
                                            <td class="text-right">{{ruleItem.numberOfAppointments}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.patientType"></td>
                                            <td class="text-right">{{'enum.patientType.' + ruleItem.patientType | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'PatientControlRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
