import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hourFromDate'
})
export class HourFromDatePipe implements PipeTransform {

  // The date will look like this: 8:00
  transform(date: string): string {
    if (date) {
      //@ts-ignore
      return moment(date.substring(0, 19)).format(moment.localeData().longDateFormat('hmm'));
    } else {
      return undefined;
    }
  }

}
