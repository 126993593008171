import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {SlotsManagementComponent} from './slots-management.component';
import {BookAppointmentComponent} from './book-appointment/book-appointment.component';
import {SlotsCalendarComponent} from './slots-calendar/slots-calendar.component';
import {SlotsResultsComponent} from './slots-results/slots-results.component';
import {SlotsLocalFiltersComponent} from './slots-local-filters/slots-local-filters.component';
import {SlotSearchModalComponent} from './slot-search-modal/slot-search-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SlotsLocalFiltersCentersComponent} from './slots-local-filters-centers/slots-local-filters-centers.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {
    OutsideAvailabilityBookingModalComponent
} from './outside-availability-booking-modal/outside-availability-booking-modal.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
    SlotWithoutPlannedCapacityModalComponent
} from './slot-without-planned-capacity-modal/slot-without-planned-capacity-modal.component';

@NgModule({
    declarations: [SlotsManagementComponent, BookAppointmentComponent, SlotsCalendarComponent, SlotsResultsComponent,
        SlotsLocalFiltersComponent, SlotSearchModalComponent, SlotsLocalFiltersCentersComponent, OutsideAvailabilityBookingModalComponent,
        SlotWithoutPlannedCapacityModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        MatProgressSpinnerModule,
        InfiniteScrollModule,
        NgxMaterialTimepickerModule
    ]
})
export class SlotsManagementModule {
}
