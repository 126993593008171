<div class="container-fluid exclusion-reason-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
      <h3 translate="label.exclusionReasonTab.exclusionReasonsHeader"></h3>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
      <div class="sort">
        <span translate="label.activeSorting" class="text-high-contrast"></span>:
        <span class="text-info font-weight-high-contrast">
        <span *ngFor="let key of objectKeys(tableFilters.orderBy)">
          <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + tableFilters.orderBy[key] | translate}}
          <span>
            <i class="fas fa-times cursor-pointer" (click)="onClearOrderBy(key)"></i>
          </span>
              </span>
              </span>
      </div>

      <div class="d-flex">
        <div class="d-flex search">
          <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
          <input type="text" class="form-control rounded-0"
                 placeholder="{{'label.searchBy'|translate}} {{'label.description'|translate}}" autocomplete="off"
                 [(ngModel)]="tableFilters.filter.Description" (ngModelChange)="onSearchExclusionReasonChanged($event)">
        </div>
        <div class="ml-2">
          <button type="button" class="btn theme-alt-btn border-0"
                  (click)="createExclusionReason()"
                  [activityDisplay]="ExclusionReason+constants.CREATE"
                  translate="label.createNew">
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
      <table class="table">
        <thead>
        <tr>
          <th class="id-column" translate="label.shortId"></th>
          <th class="name-column cursor-pointer" (click)="onSortBy('description')">
            {{'label.exclusionReasonTab.description' | translate}}
            <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.description"><i class="fas fa-sort"></i></button>
            <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.description === 'asc'"><i
              class="fas fa-sort-down"></i></button>
            <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.description === 'desc'"><i
              class="fas fa-sort-up"></i></button>
          </th>
          <th class="text-center" translate="label.showExtraInformation"></th>
          <th class="text-center" translate="label.extraInformationMandatory"></th>
          <th class="text-center" translate="label.exclusionReasonTab.isExternalReason"></th>
          <th class="text-center" translate="label.active"></th>
          <th class="text-center" translate="label.action"></th>
        </tr>
        </thead>
        <tbody>
        <tr [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
            *ngFor="let exclusionReason of exclusionReasonMdList"
            sbaseMarkTableRowOnHover
            (click)="viewExclusionReason(exclusionReason)">
          <td class="id-column">{{exclusionReason.shortId}}</td>
          <td class="name-column">{{exclusionReason.description}}</td>
          <td class="text-center">{{exclusionReason.showExtraInformation | yesNoPipe | translate}}</td>
          <td class="text-center">{{exclusionReason.extraInformationMandatory | yesNoPipe | translate}}</td>
          <td class="text-center">{{exclusionReason.isExternalReason | yesNoPipe | translate}}</td>
          <td class="text-center">{{exclusionReason.active | yesNoPipe | translate}}</td>
          <td class="text-center" (click)="$event.stopPropagation()">
            <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <ng-template #popContent>
              <ul class="list-style-none m-0 p-0 custom-drop-down">
                <li>
                  <button type="button" class="btn" translate="label.view" (click)="viewExclusionReason(exclusionReason)"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.edit" (click)="editExclusionReason(exclusionReason)"
                          [activityDisplay]="ExclusionReason+constants.UPDATE">
                  </button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.delete" *ngIf="!exclusionReason.isExternalReason"
                          (click)="displayConfirmDeleteItemModal(exclusionReason.id)"
                          [activityDisplay]="ExclusionReason+constants.DELETE">
                  </button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.changeLog"
                          (click)="displayChangeLog(exclusionReason.id, EntityTypeEnum.exclusionReason)"
                          [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ">
                  </button>
                </li>
              </ul>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component mt-4 text-high-contrast" *ngIf="totalTableItems > 0">
      <ngb-pagination [collectionSize]="totalTableItems" [(page)]="tableFilters.currentPage" [maxSize]="5"
                      [pageSize]="tableFilters.itemsPerPage" [boundaryLinks]="true"
                      (pageChange)="onChangePagination($event)"></ngb-pagination>

      <div class="btn-group items-per-page-group">
        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
      </div>
      <!-- ============================================================== -->
      <!-- End Page Filter -->
    </div>

  </div>
