import {ResourceSearchType, SpecialitySearchType} from 'sked-base';
import {ServiceSearchType} from 'sked-base/lib/data-model/serviceTypes';

export enum AppointmentsListEnum {
    ViewUpcomingAppointments = 'ViewUpcomingAppointments',
    ViewCancelledAppointments = 'ViewCancelledAppointments',
    ViewPastAppointments = 'ViewPastAppointments',
}

export interface PatientExceptionsType {
    service: ServiceSearchType;
    specialty: SpecialitySearchType;
    resource: ResourceSearchType;
    validTo: string;
    id: string;
}

export interface PatientTimeDependentTagsType {
    tagId: string;
    dateTimeFrom: string;
    dateTimeTo: string;
    tagLinkId: string;
    numberOfDays: number;
    name: string;
    calculated?: boolean;
}
