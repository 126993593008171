<div class="container-fluid export-page">
        <div class="name-title">
                <h3 class="d-inline-block" translate="label.exportView"></h3>
                <div class="btn theme-btn min-width-auto float-right"
                     ngbTooltip="{{'label.refresh'| translate}}"
                     placement="left"
                     (click)="getUsersJob()">
                    <i class="fas fa-sync-alt"></i>
                </div>
        </div>
    <table class="table">
        <thead>
            <tr>
                <th translate="label.type"></th>
                <th translate="label.createdOn"></th>
                <th translate="label.status"></th>
                <th translate="label.actions"></th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr *ngFor="let job of usersJobList">
                <td>{{job?.data?.FileName ? job?.data?.FileName : "enum.jobType." + job.type | translate}}</td>
                <td>{{job.createdOn | fullDateBrowserTzFormatPipe}}</td>
              <td>{{"enum.jobStatus." + job.status | translate}}</td>
                <td class="p-0">
                  <div [ngClass]="{'cursor-not-allowed': job.status !== 'FinishedSucessfully'}">
                    <div class="btn theme-btn min-width-auto ml-4"
                         [ngClass]="{'pointer-events-none disabled':job.status !== 'FinishedSucessfully'}" ngbTooltip="{{'label.download'| translate}}"
                         (click)="downloadReport(job.id)">
                        <i class="fas fa-download"></i>
                    </div>
                  </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
