import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AreaProvider,
    AreaType,
    CenterProvider,
    CenterType,
    ConfirmDeleteModalService,
    CountryProvider,
    CountryType,
    GeneralProvider,
    LocalityProvider,
    RegionProvider,
    RegionType,
    SearchFilterUtils, TagAssignedToEntityResultType,
    TagDependentFiltersScopeEnum,
    TagProvider,
    Validations
} from 'sked-base';
import {
    FormValidationType,
    IdNameType,
    ScreenTemplateLayoutType,
    TableFiltersType
} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CenterMdUtils} from '../center-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {mergeMap, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {MatStepper} from '@angular/material/stepper';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {BackofficeCenterMDType, BackofficeCenterPhoneNumberType, ValidCenterObjectType} from '../center-md.types';
import {forkJoin, Observable, of} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TagsFiltersType} from 'sked-base/lib/data-model/tagTypes';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {LocalityType} from 'sked-base/lib/data-model/localityTypes';
import {MultiSelectTableOptionsType} from '../../../shared/component/multi-select-table/multi-select-table.types';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-center',
    templateUrl: './create-center.component.html',
    styleUrls: ['./create-center.component.scss']
})
export class CreateCenterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;
    constants = constants;
    initialCenter: BackofficeCenterMDType;
    centerItem: BackofficeCenterMDType = {} as BackofficeCenterMDType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    validTemplate: ValidCenterObjectType;
    countries: CountryType[];
    selectedCountry: CountryType = {} as CountryType;
    totalStepsCount: number;
    timeZones = [];
    tags = [];
    regions: RegionType[] = [];
    mainDependentFilters: any;
    areaButtonName = 'label.area2';
    entityName = 'Center';
    countryPlaceholder = 'label.addressTemplate.country';
    localityPlaceholder = 'label.locality';
    tableFilters: TableFiltersType;
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    isMainPhoneNumberInputFocused = false;
    isAlternatePhoneNumberInputFocused = false;
    localities: LocalityType[] = [];
    selectedRegion: RegionType = {} as RegionType;
    callCenterPhoneNumber: BackofficeCenterPhoneNumberType = {} as BackofficeCenterPhoneNumberType;
    mainPhoneNumber: BackofficeCenterPhoneNumberType = {} as BackofficeCenterPhoneNumberType;
    alternatePhoneNumber: BackofficeCenterPhoneNumberType = {} as BackofficeCenterPhoneNumberType;
    selectedLocality = {} as LocalityType;
    areaMultiSelectTableOptions: MultiSelectTableOptionsType;
    systemTag = {} as IdNameType;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private centerMdUtils: CenterMdUtils,
        public countryProvider: CountryProvider,
        private configData: ConfigDataService,
        public centerProvider: CenterProvider,
        public router: Router,
        public generalUtils: GeneralUtils,
        private translatedLanguageService: TranslatedLanguageService,
        public generalProvider: GeneralProvider,
        public areaProvider: AreaProvider,
        public tagProvider: TagProvider,
        public regionProvider: RegionProvider,
        private configDataService: ConfigDataService,
        public confirmDeleteService: ConfirmDeleteModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private validations: Validations,
        private localityProvider: LocalityProvider,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadInitialData();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        // we need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(key));
        });
        if (this.myStepper?._steps?.length) {
            // set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.myStepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
        //  it’s triggered when change detection for all child components have been
        //  performed and so they all had possibility to update parent components property
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    saveCenterData(center: BackofficeCenterMDType) {
        const isCenterFormValid = this.isTheWholeFormValid(this.validTemplate);
        if (isCenterFormValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createCenter(center);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialCenter, center)) {
                    this.messagesService.success('toastr.success.centerEdit', true);
                    this.goToParentPage();
                } else {
                    this.editCenter(this.initialCenter, center);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/centers']);
    }

    goToEdit() {
        history.replaceState({center: this.centerItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    onStepHeaderClick(stepClicked: number) {
        const selectedIndex = this.myStepper.selectedIndex;
        // click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
        // user just clicked on stepClicked and the move was successful
        this.displayErrorToastr(selectedIndex, stepClicked);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        if (this.areStepsValid(this.validTemplate, stepper.selectedIndex)) {
            stepper.next();
        } else {
            this.displayErrorToastr(stepper.selectedIndex, stepper.selectedIndex + 1);
        }
    }

    onStepChange(stepper: StepperSelectionEvent) {
        if (stepper.selectedIndex === 0 || stepper.selectedIndex === 4) {
            this.setTags(this.tags, stepper.selectedIndex);
        }
    }

    isLinearStepperOrNot(validTemplate: ValidCenterObjectType): boolean {
        for (const property in validTemplate) {
            if (validTemplate[property] === false) {
                return true;
            }
        }

        return false;
    }

    areStepsValid(validTemplate: ValidCenterObjectType, currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return (validTemplate.isNameValid && validTemplate.isCountryValid && validTemplate.isRegionValid
                    && validTemplate.isTimeZoneValid && validTemplate.areAreasValid &&
                    validTemplate.isLatitudeValid && validTemplate.isLongitudeValid);
            case 1:
                return (validTemplate.mainPhoneNumberValid?.isValid
                    && validTemplate.alternatePhoneNumberValid?.isValid && validTemplate.email?.isValid);
            case 2:
                return (validTemplate.callCenterPhoneNumberValid?.isValid
                    && validTemplate.logoUrl?.isValid && validTemplate.webSiteUrl?.isValid && validTemplate.redirectUrl?.isValid);
            case 3:
                return validTemplate.isExternalKeysValid;
            default:
                return true; // other steps which don't need validation
        }
    }

    onRemoveExternalKeyItem(index: number, validTemplate: ValidCenterObjectType, centerItem: BackofficeCenterMDType) {
        this.centerItem.centerExternalKeys.splice(index, 1);
        this.validateFields(centerItem);
        this.areStepsValid(validTemplate, 2);
    }

    onAddExternalKeyItem(validTemplate: ValidCenterObjectType, centerItem: BackofficeCenterMDType) {
        this.centerItem.centerExternalKeys.push(this.centerMdUtils.getEmptyExternalKeyItem());
        this.validateFields(centerItem);
        this.areStepsValid(validTemplate, 2);
    }

    onCenterValueChanged(center: BackofficeCenterMDType) {
        this.validateFields(center);
    }

    onCountrySearch(searchPhrase) {
        this.countryPlaceholder = '';
    }

    onCountryBlur(searchPhrase) {
        this.countryPlaceholder = this.selectedCountry?.id ? '' : 'label.addressTemplate.country';
    }

    onCountryValueChanged(selectedCountry: CountryType) {
        this.centerItem.country = selectedCountry;
        this.selectedCountry = selectedCountry ? selectedCountry : {} as CountryType;
        this.countryPlaceholder = '';
        this.resetLocalityInputAndSelect();
        this.validateFields(this.centerItem);
        this.fetchRegionsForCountry(this.selectedCountry?.id);
    }

    onRegionValueChanged(selectedRegion: RegionType) {
        this.centerItem.region = selectedRegion;
        this.selectedRegion = selectedRegion ? selectedRegion : {} as RegionType;
        this.resetLocalityInputAndSelect();
        this.validateFields(this.centerItem);
        this.fetchLocalitiesForRegion(this.selectedRegion?.id);
    }

    onTimeZoneChanged(selectedTimeZoneId: string) {
        this.centerItem.timeZoneId = this.generalUtils.isSelectedNoValueOption(selectedTimeZoneId) ? 'noValue' : selectedTimeZoneId;
        this.resetLocalityInputAndSelect();
        this.validateFields(this.centerItem);
    }

    onLocalitySearch() {
        this.localityPlaceholder = '';
    }

    onLocalityBlur() {
        this.localityPlaceholder = this.selectedLocality?.id ? '' : 'label.locality';
    }

    onLocalityValueChanged(selectedLocality: LocalityType) {
        this.centerItem.address.locality = selectedLocality.name;
        this.selectedLocality = selectedLocality ? selectedLocality : {} as LocalityType;
        this.validateFields(this.centerItem);
    }


    onSelectedArea(selectedAreas: AreaType[]) {
        this.centerItem.areas = selectedAreas;
        this.validateFields(this.centerItem);
    }

    removeItemFromList(list: any[], item: any, centerItem: BackofficeCenterMDType, refObjectName: string) {
        const isItemAddedOnEditAction = this.centerMdUtils.objectAddedOnEditAction(this.initialCenter, item, refObjectName);
        if (item && centerItem) {
            if (this.screenTemplateLayout.action === constants.EDIT && isItemAddedOnEditAction) {
                this.confirmDeleteService.displayConfirmDeleteModal(this.centerProvider, centerItem.id, refObjectName,
                    item.id, false).result.then(() => {
                }, (response) => {
                    if (response) {
                        const selectedList = lodash.lowerFirst(refObjectName);
                        lodash.remove(centerItem[selectedList], {id: item.id});
                        this.validateFields(centerItem);
                    }
                });
            } else {
                lodash.remove(list, {id: item.id});
                this.validateFields(centerItem);
            }
        }
    }

    // used to check whether you can go to other steps or not
    getStepControl(step: number): { invalid?: boolean, pending?: boolean } {
        return {
            invalid: !this.areStepsValid(this.validTemplate, step)
        };
    }

    isTheWholeFormValid(validTemplate: ValidCenterObjectType): boolean {
        return (this.areStepsValid(validTemplate, 0) &&
            this.areStepsValid(validTemplate, 1) &&
            this.areStepsValid(validTemplate, 2) &&
            this.areStepsValid(validTemplate, 3));
    }

    onChangeTags(tags) {
        this.tags = tags.map(tag => ({...tag, tagId: tag.id}));
        this.centerItem.tags = tags;
    }

    onCallCenterPhoneNumberChange(callCenterPhoneNumber: string, phoneCountryCode: string) {
        this.centerItem.callCenterPhoneNumber = callCenterPhoneNumber !== '' ? `+${phoneCountryCode}${callCenterPhoneNumber}` : null;
        this.validTemplate.callCenterPhoneNumberValid = this.generalUtils
            .getPhoneNumberValidation(callCenterPhoneNumber, false, String(phoneCountryCode));
    }

    onMainPhoneNumberChange(mainPhoneNumber: string, mainPhoneCountryCode: string) {
        this.centerItem.mainPhoneNumber = mainPhoneNumber !== '' ? `+${mainPhoneCountryCode}${mainPhoneNumber}` : null;
        this.validTemplate.mainPhoneNumberValid = this.generalUtils
            .getPhoneNumberValidation(mainPhoneNumber, false, String(mainPhoneCountryCode));
    }

    onAltPhoneNumberChange(alternatePhoneNumber: string, altPhoneCountryCode: string) {
        this.centerItem.alternatePhoneNumber = alternatePhoneNumber !== '' ? `+${altPhoneCountryCode}${alternatePhoneNumber}` : null;
        this.validTemplate.alternatePhoneNumberValid = this.generalUtils
            .getPhoneNumberValidation(alternatePhoneNumber, false, String(altPhoneCountryCode));
    }

    mainPhoneNumberCountryCodeInputFocused(focused) {
        this.isMainPhoneNumberInputFocused = focused;
    }

    alternatePhoneNumberCountryCodeInputFocused(focused) {
        this.isAlternatePhoneNumberInputFocused = focused;
    }

    displayOverviewPhoneNumber(phoneType: string): string {
        const phoneNumber = `${phoneType}PhoneNumber`;
        const phoneNumberWithCountryCode = this[phoneNumber].phoneNumber
            ? `+${this[phoneNumber].countryCode}${this[phoneNumber].phoneNumber}`
            : '';
        return phoneNumberWithCountryCode;
    }

    private resetLocalityInputAndSelect() {
        this.selectedLocality = {} as LocalityType;
        this.centerItem.address.locality = '';
        this.localityPlaceholder = 'label.locality';
    }

    private displayErrorToastr(selectedIndex, clickedIndex) {
        // error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!this.areStepsValid(this.validTemplate, step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    this.myStepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    private getEmailValidation(email: string): FormValidationType {
        return this.validations.getValidateEmail(email, false) as FormValidationType;
    }

    // function to create the new Center
    private createCenter(center: BackofficeCenterMDType) {
        this.ngxLoader.start();
        const centerToSend = this.centerMdUtils.mapCenterAsProviderType(center);
        this.centerProvider.addEntry(centerToSend)
            .pipe(
                mergeMap((createdCenter: any) => {
                    const entity: TagsFiltersType = {
                        entityId: createdCenter.id,
                        entityType: 'Center'
                    };
                    return this.updateTagsForCenter(entity, center.tags);
                }),
                take(1))
            .subscribe(() => {
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newCenterAdded', true);
            });
    }

    private updateTagsForCenter(entity: TagsFiltersType, tags: IdNameType[]) {
        return this.tagProvider.updateTagsForEntity(entity, lodash.map(tags, 'id'));
    }

    // function to update the Center
    private editCenter(oldCenter: BackofficeCenterMDType, newCenter: BackofficeCenterMDType) {
        const oldCenterToSend: CenterType = this.centerMdUtils.mapCenterAsProviderType(oldCenter, this.systemTag?.id);
        const newCenterToSend: CenterType = this.centerMdUtils.mapCenterAsProviderType(newCenter, this.systemTag?.id);
        this.ngxLoader.start();
        this.centerProvider.updateEntry(oldCenterToSend, newCenterToSend)
            .pipe(
                mergeMap((createdCenter: any) => {
                    const entity: TagsFiltersType = {
                        entityId: createdCenter.id,
                        entityType: 'Center'
                    };
                    return this.updateTagsForCenter(entity, newCenter.tags);
                }),
                take(1))
            .subscribe(() => {
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.centerEdit', true);
            });
    }

    private validateFields(centerItem: BackofficeCenterMDType) {
        this.validTemplate.isNameValid = !!(centerItem && centerItem.name);
        this.validTemplate.isCountryValid = !!(centerItem && centerItem.country && centerItem.country.id);
        this.validTemplate.isRegionValid = !!(centerItem && centerItem.region && centerItem.region.id);
        this.validTemplate.isTimeZoneValid = !!(centerItem && !this.generalUtils.isSelectedNoValueOption(centerItem.timeZoneId));
        this.validTemplate.isLatitudeValid = this.generalUtils.isNumberWithRangeValid(centerItem.latitude,
            this.centerMdUtils.validationCoordinates(-90, 90, 13));
        this.validTemplate.isLongitudeValid = this.generalUtils.isNumberWithRangeValid(centerItem.longitude,
            this.centerMdUtils.validationCoordinates(-180, 180, 13));
        this.validTemplate.isExternalKeysValid = this.centerMdUtils.isExternalKeysTableValid(centerItem.centerExternalKeys);
        this.validTemplate.areAreasValid = !!(centerItem && centerItem.areas.length > 0);
        this.validTemplate.callCenterPhoneNumberValid =
            this.generalUtils.getPhoneNumberValidation(this.callCenterPhoneNumber.phoneNumber, false,
                this.callCenterPhoneNumber.countryCode);
        this.validTemplate.mainPhoneNumberValid = this.generalUtils.getPhoneNumberValidation(this.mainPhoneNumber.phoneNumber,
            false, this.mainPhoneNumber.countryCode);
        this.validTemplate.alternatePhoneNumberValid =
            this.generalUtils.getPhoneNumberValidation(this.alternatePhoneNumber.phoneNumber, false,
                this.alternatePhoneNumber.countryCode);
        this.validTemplate.logoUrl = this.generalUtils.getUrlValidation(centerItem.logoUrl, false);
        this.validTemplate.webSiteUrl = this.generalUtils.getUrlValidation(centerItem.webSiteUrl, false);
        this.validTemplate.redirectUrl = this.generalUtils.getUrlValidation(centerItem.redirectUrl, false);
        this.validTemplate.email = this.getEmailValidation(centerItem.email);
    }

    private parsePhoneNumber(phoneNumber: string, attributeName: string): void {
        if (phoneNumber?.includes('+')) {
            // assume phone number that contains '+' has country code (is of the form +56987654321)
            try {
                const libFullPhoneNumber = this.libPhoneUtil.parseAndKeepRawInput(phoneNumber);
                this[`${attributeName}Number`].phoneNumber = `${libFullPhoneNumber.getNationalNumber()}`;
                this[`${attributeName}Number`].countryCode = `${libFullPhoneNumber.getCountryCode()}`;
            } catch { // in case parseAndKeepRawInput throws error => leave current phone number as it is
            }
        }
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            area: this.centerMdUtils.getAreaDependentFilters(),
            tags: {
                ...this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedCenter, false),
                system: false
            }
        };
        this.tableFilters = this.centerMdUtils.getInitialTableFilter();
        if (history.state && history.state.center) {
            this.initialCenter = history.state.center;
            this.centerItem = lodash.cloneDeep(history.state.center);

            this.parsePhoneNumber(this.centerItem.callCenterPhoneNumber, 'callCenterPhone');
            this.parsePhoneNumber(this.centerItem.mainPhoneNumber, 'mainPhone');
            this.parsePhoneNumber(this.centerItem.alternatePhoneNumber, 'alternatePhone');

            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.centerItem = this.centerMdUtils.getInitialCenter();
            this.initialCenter = this.centerMdUtils.getInitialCenter();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }

        this.validTemplate = this.centerMdUtils.getInitValidTempleObject();
        if (!this.callCenterPhoneNumber.countryCode) {
            this.callCenterPhoneNumber.countryCode = `${this.libPhoneUtil
                .getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage)}`;
        }
        if (!this.mainPhoneNumber.countryCode) {
            this.mainPhoneNumber.countryCode = `${this.libPhoneUtil
                .getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage)}`;
        }
        if (!this.alternatePhoneNumber.countryCode) {
            this.alternatePhoneNumber.countryCode = `${this.libPhoneUtil
                .getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage)}`;
        }
        this.areaMultiSelectTableOptions = this.centerMdUtils.getAreaMultiSelectTableOptions(
            this.initialCenter, this.screenTemplateLayout.action
        );
        this.validateFields(this.centerItem);
    }

    private loadInitialData() {
        if (this.screenTemplateLayout.action !== constants.VIEW) {
            const translatedLanguage = this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage);
            this.ngxLoader.start();
            forkJoin([
                this.countryProvider.getCountriesForLanguage(translatedLanguage),
                this.getRegionsForCountryObservable(this.centerItem?.country?.id),
                this.generalProvider.getSystemTimeZones(),
                this.getSelectedTagsForCenter(this.screenTemplateLayout.action),
                this.getLocalitiesForRegionsObservable(this.centerItem?.region?.id)
            ])
                .pipe(take(1))
                .subscribe(([countries, regions, timeZones, tags, localities]) => {
                    this.processResponseData(countries, regions, timeZones, tags, localities);
                }, error => {
                    this.messagesService.handlingErrorMessage(error);
                    this.ngxLoader.stop();
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            this.ngxLoader.start();
            this.getSelectedTagsForCenter(this.screenTemplateLayout.action)
                .pipe(take(1))
                .subscribe((tags: {value: TagAssignedToEntityResultType[]}) => {
                    const systemTag = tags?.value.find(tag => tag.system);
                    this.systemTag = {...systemTag, id: systemTag.id};
                    this.initialCenter.tags = lodash.cloneDeep(tags?.value?.map(tag => ({...tag, tagId: tag.id})) ?? []);
                    this.setTags(this.initialCenter.tags);
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => {
                    this.ngxLoader.stop();
                });
        }
    }

    private processResponseData(countries: { value: CountryType[] }, regions: { value: RegionType[] }, timeZones: any,
                                tags: { value: TagAssignedToEntityResultType[], count?: number }, localities: {
            value: LocalityType[]
        }) {
        const systemTag = tags?.value.find(tag => tag.system);
        this.systemTag = {...systemTag, id: systemTag?.id};
        this.countries = lodash.orderBy(countries.value, ['countryName']);
        this.regions = lodash.orderBy(regions.value, ['name']);
        this.timeZones = timeZones;
        this.initialCenter.tags = lodash.cloneDeep(tags?.value?.map(tag => ({...tag, tagId: tag.id})) ?? []);
        this.setTags(this.initialCenter.tags);
        this.localities = lodash.orderBy(localities.value, ['name']);
        if (this.screenTemplateLayout.action === constants.EDIT) {
            // set selected time zone
            this.centerItem.timeZone = lodash.find(this.timeZones, {id: this.centerItem.timeZoneId});
            // set selected region
            this.centerItem.region = lodash.find(this.regions, {id: this.centerItem.region.id});
            // set selected country
            this.centerItem.country = lodash.find(this.countries, {id: this.centerItem.country.id});
            this.selectedCountry = this.centerItem.country as CountryType;
            this.countryPlaceholder = '';
            // set selected locality
            if (this.centerItem.address.locality) {
                this.selectedLocality = lodash.find(this.localities, {name: this.centerItem.address.locality});
                this.localityPlaceholder = 'label.locality';
            }
        }
    }

    private setTags(tags: IdNameType[], step?: number) {
        const tagsWithSystem = !!this.systemTag?.id ? lodash.uniqBy([...tags, this.systemTag], 'id') : tags;
        const tagsExceptSystem = tags.filter(tag => tag.id !== this.systemTag.id);
        if (!this.generalUtils.isNullOrUndefined(step)) {
            this.tags = step === 0 ? tagsExceptSystem : tagsWithSystem;
        } else {
            this.tags = this.screenTemplateLayout.action === constants.EDIT ? tagsExceptSystem : tagsWithSystem;
        }
        this.centerItem.tags = lodash.cloneDeep(this.tags);
    }

    private getSelectedTagsForCenter(action) {
        if (action !== constants.CREATE) {
            return this.tagProvider.getTagsAssignedToEntity({
                entityType: 'Center',
                entityId: history.state?.center?.id
            });
        } else {
            return of({value: []});
        }
    }

    private getRegionsForCountryObservable(countryId: string): Observable<{ value: RegionType[] }> {
        if (countryId) {
            const regionFilterQuery = this.centerMdUtils.getRegionFilterQuery(countryId);
            return this.regionProvider.getEntries(regionFilterQuery);
        } else {
            return of({value: [] as RegionType[]});
        }
    }

    private getLocalitiesForRegionsObservable(regionId: string): Observable<{ value: LocalityType[] }> {
        if (regionId) {
            const localityFilterQuery = this.centerMdUtils.getLocalityFilterQuery(regionId);
            return this.localityProvider.getEntries(localityFilterQuery);
        } else {
            return of({value: [] as LocalityType[]});
        }
    }

    private fetchRegionsForCountry(countryId: string) {
        if (countryId) {
            const regionFilterQuery = this.centerMdUtils.getRegionFilterQuery(countryId);
            this.ngxLoader.start();
            this.regionProvider.getEntries(regionFilterQuery)
                .pipe(take(1))
                .subscribe((regions) => {
                    this.regions = lodash.orderBy(regions.value, ['name']);
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            this.regions = [] as RegionType[];
        }
    }

    private fetchLocalitiesForRegion(regionId: string) {
        if (regionId) {
            const localityFilterQuery = this.centerMdUtils.getLocalityFilterQuery(regionId);
            this.ngxLoader.start();
            this.localityProvider.getEntries(localityFilterQuery)
                .pipe(take(1))
                .subscribe((localities) => {
                    this.localities = lodash.orderBy(localities.value, ['name']);
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            this.localities = [] as LocalityType[];
        }
    }
}
