import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormInputTemplateEnum, DynamicFormOptionsType} from './dynamic-form-input.types';
import {DynamicFormInputService} from '../../services/dynamic-form-input/dynamic-form-input.service';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';
import {formControlNames} from '../../constants/formControlNames';

@Component({
    selector: 'app-dynamic-form-input',
    templateUrl: './dynamic-form-input.component.html',
    styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent implements OnInit {
    @Input() parentFormGroup!: FormGroup;
    @Input() options: DynamicFormOptionsType;
    @Input() initialValue: any;

    dynamicFormInputTemplateEnum = DynamicFormInputTemplateEnum;
    formControlNames = formControlNames;

    constructor(public dynamicFormInputService: DynamicFormInputService) {
    }

    ngOnInit(): void {
    }

    openDatePicker(datePicker: DatePickerComponent) {
        datePicker.api.open();
    }

    onDatePickerClose(datePicker: DatePickerComponent, formControlName: string) {
        const dateText = datePicker?.inputElementContainer?.querySelector('input')?.value ?? '';
        const dateMoment = moment(dateText, moment.localeData().longDateFormat('L'), true);
        if (dateText === '' && this.parentFormGroup.get(formControlName).valid) {
            // If input has value '' set no errors
            this.parentFormGroup.get(formControlName).setErrors(null);
        } else if (!!dateText && !dateMoment.isValid()) {
            // Otherwise, if input has value but is not valid, set error invalid date
            this.parentFormGroup.get(formControlName).setErrors(
                {error: {priority: 5, message: 'label.error.invalidDate', shouldTranslateMessage: true}}
            );
        } else {
            // If input has value different from '' and is not valid, keep the current error showing
        }
    }
}
