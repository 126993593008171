<div class="resource-swap-options-container ml-0 mr-0"
     *ngIf="resourceSwapUtils?.swapResourcesOptions?.appointmentsSwapResources?.length > 0">
    <div>
        <!-- Single Resource for replacement -->
        <ng-container *ngFor="let appointmentSwapResource of resourceSwapUtils.swapResourcesOptions.appointmentsSwapResources">
            <div class="resource-swap-option-container mx-2 my-1" [style.grid-template-rows]="resourceSwapUtils?.swapResourcesAppointmentListItemOptions?.containerHeight + 'px ' + '2rem'">
                <div *ngIf="!appointmentSwapResource.display" class="resource-swap-option-resource-not-selected-message" translate="label.appointmentNotSelected"></div>
                <div *ngIf="appointmentSwapResource.display" class="resource-swap-option-container-inner ml-2">
                    <div class="resource-swap-option-single-resource-select-container ml-2" [ngClass]="appointmentSwapResource.display ? 'visible' : 'invisible'">
                        <span translate="label.replacement" class="resource-swap-option-single-resource-select-label font-weight-bold"></span>
                        <ng-select [ngClass]="{'ng-select-has-value' : !!resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[appointmentSwapResource?.appointmentId].name,
                                   'select-error' : hasSwapResourceSelectError(appointmentSwapResource, appointmentSwapResourceInputValue.selectedValues)}"
                                   class="text-high-contrast"
                                   dropdownPosition="bottom"
                                   #appointmentSwapResourceInputValue
                                   [multiple]="false"
                                   [searchable]="true"
                                   [disabled]="shouldDisableEverything"
                                   [clearable]="false"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[appointmentSwapResource?.appointmentId].name || resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[appointmentSwapResource?.appointmentId]"
                                   (ngModelChange)="sanitizeNgSelectValue($event, appointmentSwapResource.appointmentId);">
                            <ng-option [value]="'noValue'"><span translate="label.selectAReplacement"></span></ng-option>
                            <ng-option *ngFor="let resource of appointmentSwapResource?.resources"
                                       [value]="resource">
                                <span>{{resource?.name}}</span>
                            </ng-option>
                        </ng-select>
                        <!-- FAILED ERROR MESSAGE -->
                        <div *ngIf="hasSwapResourceSelectError(appointmentSwapResource, appointmentSwapResourceInputValue.selectedValues)"
                             class="help-block">
                            <span [ngClass]="{'has-error': !isSwapResourceResponseSuccess(appointmentSwapResource) && resourceSwapUtils?.swapResourcesOptions?.resourceSwapResult?.length > 0}"
                                  translate="label.error.slotWasBooked">
                            </span>
                        </div>
                        <!-- OBJECT DETAILS -->
                        <app-appointment-object-details
                                *ngIf="resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[appointmentSwapResource?.appointmentId] !== 'noValue'"
                                [options]="getObjectDetailsOptions(appointmentSwapResource.appointment, resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[appointmentSwapResource?.appointmentId])">
                        </app-appointment-object-details>
                        <!-- SUCCESS ICON -->
                        <span *ngIf="isSwapResourceResponseSuccess(appointmentSwapResource)">
                            <i class="fa-solid fa-check swap-resource-success-icon"></i>
                        </span>
                    </div>
                </div>
                <div class="horizontal-line"></div>
            </div>
        </ng-container>
    </div>
    <div class="resource-swap-options-buttons-container sticky-element ml-2">
        <button type="button" class="btn theme-btn border-0" translate="label.replace"
                [disabled]="disableReplaceButton()"
                (click)="onReplaceSwapResources()">
        </button>
        <div class="switch">
            <label class="m-0">
                <input type="checkbox" checked
                       name="active"
                       [disabled]="shouldDisableEverything"
                       [(ngModel)]="resourceSwapUtils.swapResourcesOptions.sendClassicNotificationEmailsValue">
                <span class="lever switch-col-black"></span>
                <span translate="label.sendEmails" class="font-weight-bold"></span>
            </label>
        </div>
    </div>
</div>
