import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ServiceMDComponent} from './service-md.component';
import {CreateServiceMDComponent} from './create-service-md.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [ServiceMDComponent, CreateServiceMDComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        MatStepperModule,
        MatIconModule
    ]
})
export class ServiceMDModule {
}
