import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TaskListComponent} from './task-list.component';
import { TaskListFiltersComponent } from './task-list-filters/task-list-filters.component';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TaskItemDetailsComponent } from './task-item-details/task-item-details.component';
import { TaskListFiltersViewMoreModalComponent } from './task-list-filters/task-list-filters-view-more-modal/task-list-filters-view-more-modal.component';
import { TaskItemActionsButtonsComponent } from './task-item-actions-buttons/task-item-actions-buttons.component';
import { ExportTaskListComponent } from './export-task-list/export-task-list.component';
import { ExportTaskListModalComponent } from './export-task-list/export-task-list-modal/export-task-list-modal.component';

@NgModule({
    declarations: [
        TaskListComponent,
        TaskListFiltersComponent,
        TaskListFiltersViewMoreModalComponent,
        TaskItemActionsButtonsComponent,
        TaskItemDetailsComponent,
        ExportTaskListComponent,
        ExportTaskListModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        MatProgressSpinnerModule,
    ]
})
export class TaskListModule {
}
