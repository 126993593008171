import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    TimeWindowType,
    TimeWindowProvider,
    ChannelProvider,
    GeneralUtils as SkedBaseGeneralUtils,
    FilterComponentChannelType,
    ChannelDependentFiltersType,
    Validations
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TimeWindowMdUtils} from '../time-window-for-channel-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../../shared/services/config-data.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-time-window',
    templateUrl: './create-time-window-for-channel.component.html',
    styleUrls: ['./create-time-window-for-channel.component.scss']
})
export class CreateTimeWindowForChannelComponent implements OnInit, OnDestroy {
    readonly CONSTANTS = constants;
    MAX_NUMBER_OF_DAYS: number;
    initialTimeWindow: TimeWindowType;
    timeWindowItem: TimeWindowType = {} as TimeWindowType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    initialChannelValues: FilterComponentChannelType[] = [];
    channelDependentFilters: ChannelDependentFiltersType;

    constructor(
        public channelProvider: ChannelProvider,
        public generalUtils: GeneralUtils,
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private skedBaseGeneralUtils: SkedBaseGeneralUtils,
        private timeWindowMdUtils: TimeWindowMdUtils,
        private timeWindowProvider: TimeWindowProvider,
        private validations: Validations
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveTimeWindowData(timeWindow: TimeWindowType): void {
        const isTemplateValid = this.validateFields(timeWindow);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createTimeWindow(timeWindow);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialTimeWindow, timeWindow)) {
                    this.messagesService.success('toastr.success.timeWindowEdit', true);
                    this.goToParentPage();
                } else {
                    this.editTimeWindow(this.initialTimeWindow, timeWindow);
                }
                this.editTimeWindow(this.initialTimeWindow, timeWindow);
            }
        }
    }

    goToParentPage(): void {
        this.router.navigate(['/timeWindowsForChannel']);
    }

    isNumberOfDaysValid(): boolean {
        return this.timeWindowItem.days
            && this.validations.getValidateIntegerBetween(this.timeWindowItem.days.toString(), 1, this.MAX_NUMBER_OF_DAYS, '').isValid;
    }

    isSaveButtonEnabled(): boolean {
        return !this.validateFields(this.timeWindowItem);
    }

    onSelectedChannelFilter(channelFilterList: any[]): void {
        this.initialChannelValues = channelFilterList;
        this.timeWindowItem.channel = (channelFilterList && channelFilterList.length > 0) ? channelFilterList[0].enumValue : undefined;
    }

    goToEdit() {
        history.replaceState({
            timeWindow: this.timeWindowItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new TimeWindow
    private createTimeWindow(timeWindow: TimeWindowType): void {
        this.ngxLoader.start();
        this.timeWindowProvider.addEntry(timeWindow)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newTimeWindowAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the TimeWindow
    private editTimeWindow(oldTimeWindow: TimeWindowType, newTimeWindow: TimeWindowType): void {
        this.ngxLoader.start();
        this.timeWindowProvider.updateEntry(oldTimeWindow, newTimeWindow)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.timeWindowEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(timeWindowItem: TimeWindowType): boolean {
        return !!(timeWindowItem.channel && this.isNumberOfDaysValid());
    }

    private setupInitialState(): void {
        this.channelDependentFilters = this.timeWindowMdUtils.getChannelDependentFilters();
        this.configDataService.initialConfigRequestFinish
            .subscribe(val => {
                if (val) {
                    this.MAX_NUMBER_OF_DAYS = parseInt(this.skedBaseGeneralUtils.getSystemConfigValue(
                        this.configDataService.systemConfig.value,
                        'TimeWindowMaximum',
                        true
                    ).toString());
                }
            });
        if (history.state && history.state.timeWindow) {
            this.initialTimeWindow = history.state.timeWindow;
            this.timeWindowItem = lodash.cloneDeep(history.state.timeWindow);
            this.initialChannelValues = [{name: this.timeWindowItem.channel}];
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.timeWindowItem = this.timeWindowMdUtils.getInitialTimeWindow();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }
}
