import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AppointmentActionButtonsOptionsType,
    AppointmentActionEnum
} from './appointment-action-buttons.types';
import {MessagesService} from '../../services/messages.service';
import {
    AppointmentSendEmailButtonOptionsType
} from '../appointment-send-email-button/appointment-send-email-button.types';
import {AppointmentStatusChangeButtonOptionsType} from 'sked-base/lib/components/appointment-status-change-button/appointment-status-change-button.type';
import {CancelAppointmentOptionsType} from 'sked-base/lib/components/cancel-appointment/cancel-appointment.type';
import {AppointmentCardEmittedActionType} from '../appointment-card/appointment-card.types';
import {AppointmentRescheduleReuseOptionsType, AppointmentStatusEnum, AppointmentStatusValueEnum} from 'sked-base';

@Component({
    selector: 'app-appointment-action-buttons',
    templateUrl: './appointment-action-buttons.component.html',
    styleUrls: ['./appointment-action-buttons.component.scss']
})
export class AppointmentActionButtonsComponent implements OnInit {
    @Input() options: AppointmentActionButtonsOptionsType;
    @Output() patientAppointmentAction = new EventEmitter<AppointmentCardEmittedActionType>();

    patientAppointmentActionEnum = AppointmentActionEnum;
    cancelAppointmentOptions: CancelAppointmentOptionsType;
    rescheduleReuseAppointmentOptions: AppointmentRescheduleReuseOptionsType;
    appointmentSendEmailButtonOptions: AppointmentSendEmailButtonOptionsType;
    appointmentStatusChangeButtonOptions: AppointmentStatusChangeButtonOptionsType;
    appointmentStatusEnum = AppointmentStatusEnum;
    appointmentStatusValueEnum = Object.keys(AppointmentStatusValueEnum);

    constructor(
        private messagesService: MessagesService,
    ) {
    }

    ngOnInit(): void {
        this.loadCancelAppointmentButtonOptions();
        this.loadRescheduleReuseAppointmentOptions();
        this.loadAppointmentSendEmailButtonOptions();
        this.loadAppointmentStatusChangeButtonOptions();
    }

    onAction({action, data}: AppointmentCardEmittedActionType) {
        switch (action) {
            case AppointmentActionEnum.Cancel: {
                // Send the action directly to the parent
                this.patientAppointmentAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Reuse: {
                // Reuse is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only send the action to the parent
                this.patientAppointmentAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only send the action to the parent
                this.patientAppointmentAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                // This action is done entirely in appointment-send-email-button component
                return;
            }
        }
    }

    private loadAppointmentSendEmailButtonOptions() {
        this.appointmentSendEmailButtonOptions = {
            appointmentId: this.options?.appointmentId,
            appointmentDate: this.options?.appointmentDate,
            cancelled: this.options?.cancelled
        } as AppointmentSendEmailButtonOptionsType;
    }

    private loadCancelAppointmentButtonOptions() {
        this.cancelAppointmentOptions = {
            translatedLabel: 'label.cancel',
            appointmentId: this.options?.appointmentId,
            messagesServiceInstance: this.messagesService,
        } as CancelAppointmentOptionsType;
    }

    private loadRescheduleReuseAppointmentOptions() {
        this.rescheduleReuseAppointmentOptions = {
            appointment: this.options?.appointment,
        } as AppointmentRescheduleReuseOptionsType;
    }

    private loadAppointmentStatusChangeButtonOptions() {
        this.appointmentStatusChangeButtonOptions = {
            appointmentId: this.options?.appointmentId,
            cancelled: this.options?.cancelled,
            appointment: this.options.appointment,
            status: this.options.appointment?.status,
            appointmentAllowedStatuses: this.options.appointmentAllowedStatuses,
            etag: this.options.etag,
            messagesServiceInstance: this.messagesService,
            onActionLoaderId: this.options.onActionLoaderId,
        };
    }
}
