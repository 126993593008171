import * as moment from 'moment';
import {AppointmentPriceFromExternalServiceResponseType, AppointmentType, PatientSearchOptionsType} from 'sked-base';
import {
    SlotCenterType,
    SlotDisplayType,
    SlotSearchAppointmentTypesType, SlotSearchResponseType, SlotsExtraDetailsType, SlotType
} from 'sked-base/lib/data-model/slotTypes';
import {SlotsFiltersSelectValueType} from 'sked-base';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';
import {FormValidationType} from '../../data-model/general.type';
import {InputValidationResultType} from '../../data-model/tenant-customizing.type';
import {IMessagesService} from 'sked-base/lib/data-model/messages-service.interface';
import {IConfigDataService} from 'sked-base/lib/data-model/config-data-service.interface';
import {ProcessedTenantCustomizingGroupedByControlNameType} from 'sked-base/lib/data-model/tenantCustomizingTypes';
import {
    AdvancedAppointmentInformation,
    AppointmentInformationAdditionalDataType
} from 'sked-base/lib/components/appointment-information/appointment-information.types';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export interface MultiAppointmentBookingCalendarDayType {
    momentDate: moment.Moment;
    stringDate: string;
    displayDay: string;
    displaySlots?: number;
    isSelected?: boolean;
    isDisabled?: boolean;
    dayType?: MultiAppointmentBookingCalendarDayTypeEnum;
}

export enum MultiAppointmentBookingCalendarDayTypeEnum {
    Normal = 'Normal', // All searches have slots this day (blue)
    Special = 'Special', // All searches have special slots (purple)
    Partial = 'Partial', // Only some searches have slots (light orange)
}

export enum MultiAppointmentBookingLocalFilterTypeEnum {
    AM = 'AM',
    PM = 'PM',
    IncludeSelfPayer = 'IncludeSelfPayer',
    AppointmentType = 'AppointmentType',
}

export interface MultiAppointmentBookingCalendarSelectedDayType {
    pageIndex: number;
    dayIndex: number;
}

export interface MultiAppointmentBookingCalendarPageType {
    calendarDays: MultiAppointmentBookingCalendarDayType[];
}

export interface MultiAppointmentBookingCalendarOptionsType {
    calendarPages: MultiAppointmentBookingCalendarPageType[];
    numberOfPages: number;
    currentPage: number;
    displayMonth: string;
    displayYear: string;
    searchStartDate?: NgbDateStruct;
    searchEndDate?: NgbDateStruct;
    selectedDay?: MultiAppointmentBookingCalendarDayType;
    previouslySelectedDay?: MultiAppointmentBookingCalendarSelectedDayType;
    areOptionsAfterSearch?: boolean;
}

export interface SlotCenterLocalFilterType extends SlotCenterType {
    selected: boolean;
}

export interface NumberOfSlotsType {
    numberOfSlotsAvailableToday: number;
    totalNumberOfAvailableSlots: number;
    selected: boolean;
}

export interface FirstTimeSlots {
    isFirstAMTimeSlot: boolean;
    isFirstPMTimeSlot: boolean;
}

export interface SlotsLocalFiltersOptionsType {
    selfPayer: boolean;
    am: boolean;
    pm: boolean;
    appointmentTypes: SlotSearchAppointmentTypesType[];
    selectedAppointmentType: SlotSearchAppointmentTypesType;
    appointmentTypeChangedByUser?: boolean;
    displaySlotsLocalFilters: boolean;
}

export interface MabSlotDisplayType extends SlotDisplayType {
    marked?: boolean;
    hovered?: boolean;
}

export interface MabSlotListWrapperOutputType {
    slot: MabSlotDisplayType;
    listId: number;
}

export interface MabSlotListOptionsType {
    label: string;
    translateLabel?: boolean;
    hovered?: boolean;
    isEmpty?: boolean;
    listId?: number;
    displaySlots?: MabSlotDisplayType[];
    rawSlots?: SlotType[];
    slotsExtraDetails?: SlotsExtraDetailsType;
    groupedSlots?: { [key in string]?: SlotType[] };
    noSlotsFound?: boolean;
    noSlotsOnSelectedDay?: boolean;
    specialAppointmentBooking?: boolean;
    expertBookingMode?: boolean;
    wasResourceUsedForSearch?: boolean;
    numberOfSlots?: number;
    hasReservation?: boolean;
    // Used to keep track of and update lists when combining slots responses
    filterValues: SlotsFiltersSelectValueType[];
    // This variable is used for slot results infinite scroll to slice the entire array.
    // Only the first slotsResultsSliceUpperBound items are shown at first.
    slotsResultsSliceUpperBound?: number;
    specialityName?: string;
}

export interface MultiAppointmentBookingSlotsListWrapperOptionsType {
    slotsListsOptions: MabSlotListOptionsType[];
    isEmpty: boolean;
    nextId: number;
}

export interface MultiAppointmentBookingStateType {
    patientSearchOptions?: PatientSearchOptionsType;
    slotsCalendarOptions?: MultiAppointmentBookingCalendarOptionsType;
    slotsLocalFiltersOptions?: SlotsLocalFiltersOptionsType;
    slotsResultsOptions?: SlotDisplayType[];
    slotsListsWrapperOptions?: MultiAppointmentBookingSlotsListWrapperOptionsType;
    reservedSlotsOptions?: MultiAppointmentBookingReservedSlotsOptionsType;
}

export interface SlotSearchModalOptionsType {
    filterValues: SlotsFiltersSelectValueType[];
    patient: PatientType;
}

export interface SlotContinueSearchModalOptionsType {
    patient: PatientType;
}

export interface MultiAppointmentBookingSubServiceType {
    subServiceId: string;
    id: string;
    shortId: number;
    duration: number;
    name: string;
}

export interface MultiAppointmentBookingReservationType {
    slot: MabSlotDisplayType;
    listId: number;
    reservationId: string;
    hovered?: boolean;
    specialityName?: string;
}

export interface MultiAppointmentBookingReservedSlotsOptionsType {
    reservations: MultiAppointmentBookingReservationType[];
    displayedReservations: MultiAppointmentBookingReservationType[];
}

export interface AppointmentTypeAndObjectDetailsModalOptions {
    objectDetailsOptions: ObjectDetailsOptionsType;
    slot: MabSlotDisplayType;
    listId: number;
    specialBooking?: boolean;
    numberOfSlots?: number;
}

export enum MabBookingActionEnum {
    Book = 'Book',
    Cancel = 'Cancel',
    Print = 'Print',
    Done = 'Done',
}

export interface MabAppointmentInformationFormData {
    mainPhoneNumber: string;
    mainPhoneNumberCountryCode: string;
    alternatePhoneNumber: string;
    alternatePhoneNumberCountryCode: string;
    email: string;
    comment: string;
}

export interface MabAppointmentInformationActionType {
    actionName: MabBookingActionEnum;
    formData?: MabAppointmentInformationFormData;
    otherData?: any;
}

export interface MabAppointmentFormDataValidationType {
    mainPhoneNumber: InputValidationResultType;
    alternatePhoneNumber: InputValidationResultType;
    email: InputValidationResultType;
    price: InputValidationResultType;
}

export interface MabAppointmentOptionsType {
    slot: SlotDisplayType;
    appointment: AppointmentType;
    servicePriceSpinnerId: string;
    actualPriceSpinnerId: string;
    priceInformation: AppointmentPriceFromExternalServiceResponseType;
    shouldShowPaymentSections: boolean;
    index: number;
    priceErrorMessage?: string;
    priceIsValid?: boolean;
    additionalData?: AppointmentInformationAdditionalDataType;
    isCollapsed?: boolean;
    objectDetailsOptions?: ObjectDetailsOptionsType;
}

export interface MabAppointmentInformationAdditionalDataType extends AppointmentInformationAdditionalDataType{
    specialityName?: string;
}

export interface MabAppointmentsInformationSlotOptionsType {
    slot: SlotDisplayType;
    additionalData?: MabAppointmentInformationAdditionalDataType;
    appointment?: AppointmentType;
}

export interface MabAppointmentsInformationOptionsType {
    patient: PatientType;
    displayPrintButtons: boolean;
    readOnly: boolean;
    tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType;
    defaultPhoneNumberCountryCode?: string;
    slotsOptions: MabAppointmentsInformationSlotOptionsType[];
}
