import {Component, Input, OnInit} from '@angular/core';
import {AppointmentProvider} from 'sked-base';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../services/messages.service';
import {AppointmentCommentType} from 'sked-base/lib/data-model/appointmentCommentTypes';
import {AppointmentCommentsOptionsType} from '../appointment-comments.types';
import {constants} from '../../../constants/constants';
import {ConfigDataService} from '../../../services/config-data.service';
import {mergeMap, take, tap} from 'rxjs/operators';

@Component({
    selector: 'app-comments-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {
    @Input() options: AppointmentCommentsOptionsType;

    allComments: AppointmentCommentType[];
    comments: AppointmentCommentType[];
    spinnerId = 'appointment-comments';
    comment: string;
    currentPage = 1;
    itemsPerPage = 3;

    private userInfo;

    constructor(public activeModal: NgbActiveModal,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private appointmentProvider: AppointmentProvider,
                private configDataService: ConfigDataService) {
    }

    ngOnInit(): void {
        this.loadAppointmentComments();
    }

    onAddNewComment(comment: string) {
        this.ngxLoader.startLoader(this.spinnerId);
        this.appointmentProvider.addComment(this.options?.appointmentId, this.options.eTag, this.getDataForNewComment(comment))
            .pipe(
                tap(appointment => {
                        this.options.commentsCount = appointment.commentsCount; //update total comments
                        this.options.eTag = appointment.etag; //update eTag in case of a new comment
                    }
                ), mergeMap(response => {
                    return this.appointmentProvider.getComments(this.options?.appointmentId);
                }),
                take(1))
            .subscribe((appointmentComments) => {
                this.allComments = appointmentComments.value ?? [];
                this.comments = this.getCommentsForCurrentPage();
                this.comment = '';
                this.ngxLoader.stopLoader(this.spinnerId);
            }, err => {
                this.ngxLoader.stopLoader(this.spinnerId);
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onChangePagination(page: number) {
        this.comments = this.getCommentsForCurrentPage();
    }

    onCloseModal() {
        this.activeModal.close();
    }

    private loadAppointmentComments() {
        this.ngxLoader.startLoader(this.spinnerId);
        this.appointmentProvider.getComments(this.options?.appointmentId)
            .subscribe((appointmentComments) => {
                this.allComments = appointmentComments.value ?? [];
                this.comments = this.getCommentsForCurrentPage();
                this.ngxLoader.stopLoader(this.spinnerId);
            }, err => {
                this.ngxLoader.stopLoader(this.spinnerId);
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private getCommentsForCurrentPage() {
        const start = this.currentPage - 1; // because pages logically start with 1, but technically with 0
        return this.allComments.slice(start * this.itemsPerPage, (start + 1) * this.itemsPerPage);
    }

    private getDataForNewComment(comment: string): AppointmentCommentType {
        const userInfo = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        const newComment: AppointmentCommentType = {
            content: comment,
            writerId: userInfo?.id,
            writerType: 'User'
        } as AppointmentCommentType;

        return newComment;
    }

}
