<div class="resource-utilization-appointment-list-container">
    <!-- Title, > -->
    <div (click)="resourceUtilizationUtils.changeCollapseAppointmentListSection()"
         [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'row cursor-pointer vertical-div':'row cursor-pointer  mr-0'">
        <div [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'col d-flex justify-content-center':'col d-flex justify-content-center ml-3'">
            <h5 translate="label.appointmentsToReview"
                [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'vertical-text mb-3':'vertical-text-collapsed mx-auto mb-0 mt-3 align-self-center'"></h5>
        </div>
        <div [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'col-12 order-first align-top text-center':'col-2 d-flex justify-content-end'">
            <i class="btn btn-sm fas"
               [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'fa-angle-right mt-3':'fa-angle-left mx-auto mb-0 mt-3'"
               [attr.aria-expanded]="!resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed"></i>
        </div>
    </div>
    <!-- Pending appointments list -->
    <div class="resource-utilization-pending-appointment-list custom-scrollbar"
         *ngIf="resourceUtilizationUtils.appointmentsListOptions?.pendingAppointmentsPerPage?.length > 0">
        <div [ngbCollapse]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed" class="row mr-0 ml-0"
             *ngFor="let appointment of resourceUtilizationUtils.appointmentsListOptions.pendingAppointmentsPerPage">
            <div class="col">
                <hr>
                <div class="container cursor-pointer resource-utilization-list-appointment-container" (click)="selectAppointment(appointment)">
                    <div class="row ml-0 mr-0 d-flex justify-content-start">
                        <div class="ml-2 text-left">
                            <span class="font-weight-bold custom-appointment-text">{{appointment?.dateTimeFrom | fullDateFormatPipe: false }}
                                , {{appointment?.duration}} {{'label.minuteShort' | translate}}</span>
                            <br>
                            <span>{{appointment?.mainResourceName}}</span>
                            <br>
                            <span>{{'label.tasksDone' | translate}} {{appointment?.confirmedTasksLength}}
                                / {{appointment?.activities.length}}</span>
                            <br>
                            <span>{{'label.resourcesConfirmed' | translate}} {{appointment?.confirmedResourcesLength}}
                                / {{appointment?.optionalResources.length}}</span>
                            <br>
                            <span class="custom-underline custom-appointment-text cursor-pointer view-in-calendar"
                                  translate="label.viewInCalendar" (click)="viewInCalendar(appointment, $event)">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- No appointment message -->
    <div [ngbCollapse]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed" class="row ml-0 mr-0">
        <div class="col">
            <hr>
            <div class="col" *ngIf="resourceUtilizationUtils.appointments?.length === 0">
                <h6 translate="label.noAvailableAppointments" class="m-0 pb-3 text-center"></h6>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <div [ngbCollapse]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed" class="row pr-0 pl-0 ml-0 mr-0"
         *ngIf="resourceUtilizationUtils.appointmentsListOptions.pendingAppointments?.length > 0">
        <div class="col d-flex justify-content-center pr-0 pl-0">
            <ngb-pagination
                    [page]="resourceUtilizationUtils.appointmentsListOptions.currentPage"
                    [pageSize]="resourceUtilizationUtils.appointmentsListOptions.pageSize"
                    [collectionSize]="resourceUtilizationUtils.appointmentsListOptions.pendingAppointments?.length"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [ellipses]="false"
                    [rotate]="true"
                    (pageChange)="changePagination($event)"></ngb-pagination>
        </div>
    </div>
</div>
