<div class="container-fluid create-appointment-status-transition-reason-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-appointment-status-transition-reason-header">
        <h3 class="create-appointment-status-transition-reason-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="entities.appointmentStatusTransitionReason"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->
    <form name="form" novalidate (ngSubmit)="saveAppointmentStatusTransitionReasonData(appointmentStatusTransitionReasonItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{appointmentStatusTransitionReasonItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.transitionReasonTab.others"></td>
                                    <td class="text-right">
                                        <span *ngIf="appointmentStatusTransitionReasonItem.others" translate="label.yes"></span>
                                        <span *ngIf="!appointmentStatusTransitionReasonItem.others" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.transitionReasonTab.category"></td>
                                    <td class="text-right">{{appointmentStatusTransitionReasonItem.category}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.transitionReasonTab.system"></td>
                                    <td class="text-right">
                                        <span *ngIf="appointmentStatusTransitionReasonItem.system" translate="label.yes"></span>
                                        <span *ngIf="!appointmentStatusTransitionReasonItem.system" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.transitionReasonTab.situation"></td>
                                    <td class="text-right">{{appointmentStatusTransitionReasonItem.situation}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   [(ngModel)]="appointmentStatusTransitionReasonItem.name"
                                   [ngClass]="{'input-element-has-error': !appointmentStatusTransitionReasonItem.name
                               && screenTemplateLayout.action !== constants.VIEW}"
                                   placeholder="{{'label.name'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="!appointmentStatusTransitionReasonItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.transitionReasonTab.others"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="appointmentStatusTransitionReasonItem.others"
                                           name="others">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.transitionReasonTab.category"></label>
                            <ng-select
                                class="custom-ng-select input-element"
                                loadingText="{{'label.loading' | translate}}"
                                dropdownPosition="bottom"
                                [(ngModel)]="appointmentStatusTransitionReasonItem.category"
                                [multiple]="false"
                                [searchable]="false"
                                [clearable]="false"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'input-element-has-error': generalUtils.isSelectedNoValueOption(appointmentStatusTransitionReasonItem.category)
                                            && screenTemplateLayout.action !== constants.VIEW}"
                                (change)="onCategoryValueChanged($event)">
                                <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                <ng-option *ngFor="let category of categoryEnumList"
                                           [value]="category">
                                    {{'enum.transitionReasonCategory.' + category | translate}}
                                </ng-option>
                            </ng-select>

                            <div *ngIf="generalUtils.isSelectedNoValueOption(appointmentStatusTransitionReasonItem.category)
                             && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'AppointmentStatusTransitionReasonUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
