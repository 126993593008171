<div class="main-resource-service-duration-modal">
    <div class="modal-header">
        <h4 class="modal-title">
            {{"label.mainResourceServiceDurationTitle" | translate}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <ngb-accordion #acc="ngbAccordion" [activeIds]="activeCardsIds"
                           *ngFor="let resource of mainResourceServiceDurationList;index as i">
                <ngb-panel id="ngb-panel-{{i}}">
                    <ng-template ngbPanelTitle>
                        <span>{{resource.resourceName}}</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <table class="mainResourceDurationTable">
                            <thead>
                            <tr>
                                <th translate="label.availabilityTab.availabilityHistory.currentPeriod"></th>
                                <th translate="label.availabilityTab.duration"></th>
                            </tr>
                            </thead>
                            <tr *ngFor="let availability of resource.availabilities">
                                <td class="pr-2"> {{availability.validFrom | shortDateFormatPipe}}
                                    - {{availability.validTo| shortDateFormatPipe}}</td>
                                <td>{{availability.duration}}
                                    <span>{{'label.availabilityTab.minuteShort' | translate}}</span>
                                </td>
                            </tr>
                            <tbody></tbody>
                        </table>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                (click)="activeModal.dismiss('close')"
                translate="label.close"></button>
    </div>
</div>

