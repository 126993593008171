<div class="container-fluid create-time-window-speciality-area-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-time-window-speciality-area-header">
        <h3 class="create-time-window-speciality-area-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase" translate="label.timeWindow"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveTimeWindowSpecialityAreaData(timeWindowSpecialityAreaItem)">
        <div class="create-entity-md-section">
            <!--Body-->
            <!-- =========================================== -->
            <ng-container *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.area"></td>
                                    <td class="text-right">{{timeWindowSpecialityAreaItem.area.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.speciality"></td>
                                    <td class="text-right">{{timeWindowSpecialityAreaItem.speciality.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.channel"></td>
                                    <td class="text-right">{{timeWindowSpecialityAreaItem.channel}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.days"></td>
                                    <td class="text-right">{{timeWindowSpecialityAreaItem.days}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group sbase-input-element">
                            <label translate="label.area"></label>
                            <sbase-filter class="mr-1" (selectedItemsOutput)="onSelectedAreaFilter($event)"
                                          [buttonName]="'label.area' | translate"
                                          [dependentFilters]="mainDependentFilters.area"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="initialFilterValues.area"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="areaProvider"
                                          [ngClass]="{'input-element-has-error': !timeWindowSpecialityAreaItem.areaId}"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                            <div *ngIf="!timeWindowSpecialityAreaItem.areaId" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="form-group sbase-input-element">
                            <label translate="label.speciality"></label>
                            <sbase-filter class="mr-2" (selectedItemsOutput)="onSelectedSpecialityFilter($event)"
                                          [buttonName]="'label.speciality' | translate"
                                          [dependentFilters]="mainDependentFilters.speciality"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="initialFilterValues.speciality"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="specialityProvider"
                                          [ngClass]="{'input-element-has-error': !timeWindowSpecialityAreaItem.specialityId
                                          && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                            <div *ngIf="!timeWindowSpecialityAreaItem.specialityId
                                 && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="form-group sbase-input-element">
                            <label translate="label.channel"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedChannelFilter($event)"
                                          [buttonName]="'label.timeWindowTab.channel' | translate"
                                          [dependentFilters]="mainDependentFilters.channel"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="initialFilterValues.channel"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="channelProvider"
                                          [ngClass]="{'input-element-has-error': !timeWindowSpecialityAreaItem.channel
                                          && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                            <div *ngIf="!timeWindowSpecialityAreaItem.channel
                                 && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.days"></label>
                            <input type="number" name="days" class="input-element"
                                   [(ngModel)]="timeWindowSpecialityAreaItem.days"
                                   [ngClass]="{'input-element-has-error': !isNumberOfDaysValid(timeWindowSpecialityAreaItem.days)
                               && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                   placeholder="{{'label.days'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                <div *ngIf="!timeWindowSpecialityAreaItem.days" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                                <div
                                    *ngIf="!generalUtils.isEmpty(timeWindowSpecialityAreaItem.days) && !isNumberOfDaysValid(timeWindowSpecialityAreaItem.days)"
                                    class="help-block">
                                    <span translate="label.error.invalidNumberOfDays"></span>{{MAX_NUMBER_OF_DAYS}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--End Body-->
            <!-- =========================================== -->
        </div>
        <!--Footer-->
        <!-- =========================================== -->
        <div class="create-entity-footer mt-2 footer-position-sticky">
            <button class="btn mr-3"
                    type="button"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn"
                    type="submit"
                    [disabled]="!isSaveButtonEnabled()"
                    [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'TimeWindowSpecialityAreaUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
