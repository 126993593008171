import {DirectiveModule} from './directives/directive.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {SkedBaseModule} from 'sked-base';
import {ClickOutsideModule} from 'ng-click-outside';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {HourFromMinutesPipe} from './pipes/hour-from-minutes.pipe';
import {TagInputModule} from 'ngx-chips';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {VersionInfoMessageComponent} from './component/version-info-message/version-info-message.component';
import {GenerateExportComponent} from './component/generate-export/generate-export.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {ShortDateFormatPipe} from './pipes/short-date-format.pipe';
import {AssignSubServicesComponent} from './component/assign-sub-services/assign-sub-services.component';
import {AssignSubServicesModalComponent} from './component/assign-sub-services/assign-sub-services-modal';
import {ShowAvailabilityMainResourceDurationComponent} from './component/show-availability-main-resource-duration/show-availability-main-resource-duration.component';
import {BlockedAppointmentsPreviewComponent} from './component/blocked-appointments-preview/blocked-appointments-preview.component';
import {MainResourceServiceDurationModalComponent} from './component/show-availability-main-resource-duration/main-resource-service-duration-modal.component';
import {FullDateFormatPipe} from './pipes/full-date-format.pipe';
import {ModalFiltersComponent} from './component/modal-filters/modal-filters.component';
import {ShowModalFiltersComponent} from './component/modal-filters/show-modal-filters.component';
import {FullDateBrowserTzFormatPipe} from './pipes/full-date-browser-tz-format.pipe';
import {DateMMddFormatPipe} from './pipes/date-MMdd-format.pipe';
import {DateMMddyyyyFormatPipe} from './pipes/date-MMddyyyy-format.pipe';
import {DateMdFormatPipe} from './pipes/date-Md-format.pipe';
import {DateEndOfDayPipe} from './pipes/date-end-of-day.pipe';
import {FullDateNoTimeFormatPipe} from './pipes/full-date-no-time-format.pipe';
import {DateBrowserTzFormatPipe} from './pipes/date-browser-tz-format.pipe';
import {DateddddFormatPipe} from './pipes/date-dddd-format.pipe';
import {DateMMDDcommaYYYYFormatPipe} from './pipes/date-MMDDcommaYYYY-format.pipe';
import {PdfImageViewerComponent} from './component/pdf-image-viewer/pdf-image-viewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {WaitListModalComponent} from './component/wait-list-modal/wait-list-modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MultiSelectTableComponent} from './component/multi-select-table/multi-select-table.component';
import {HumanReadableMinutesComponent} from './component/human-readable-minutes/human-readable-minutes.component';
import {GenerateExportFileComponent} from './component/generate-export-file/generate-export-file.component';
import {FullDateWithTimeFormatPipe} from './pipes/full-date-with-time-format.pipe';
import {DateMMMMDoFormatPipe} from './pipes/date-mmmmdo-format.pipe';
import {HourFromDatePipe} from './pipes/hour-from-date.pipe';
import {DateMMMMDoYYYYFormatPipe} from './pipes/date-MMMMDo-YYYY-format.pipe';
import {AppointmentDetailsComponent} from './component/appointment-details/appointment-details.component';
import { DetailsModalComponent } from './component/appointment-details/details-modal/details-modal.component';
import { DynamicFormInputComponent } from './component/dynamic-form-input/dynamic-form-input.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import { AppointmentCommentsComponent } from './component/appointment-comments/appointment-comments.component';
import { CommentsModalComponent } from './component/appointment-comments/comments-modal/comments-modal.component';
import {
    AppointmentActionButtonsComponent
} from './component/appointment-action-buttons/appointment-action-buttons.component';
import {AppointmentObjectDetailsComponent} from './component/appointment-object-details/appointment-object-details.component';
import {ObjectDetailsModalComponent} from './component/appointment-object-details/object-details-modal/object-details-modal.component';
import { AppointmentPaidStatusChangeButtonComponent } from './component/appointment-paid-status-change-button/appointment-paid-status-change-button.component';
import { PrintBulletinPdfButtonComponent } from './component/print-bulletin-pdf-button/print-bulletin-pdf-button.component';
import { AppointmentSendEmailButtonComponent } from './component/appointment-send-email-button/appointment-send-email-button.component';
import { DynamicFormInputErrorComponent } from './component/dynamic-form-input-error/dynamic-form-input-error.component';
import { DateMMDDYYYYBrowserTzFormatPipe } from './pipes/date-MMDDYYYY-browser-tz-format.pipe';
import { UploadFileComponent } from './component/upload-file/upload-file.component';
import {ngfModule} from 'angular-file';
import {CommaDotSwapPipe} from './pipes/comma-dot-swap.pipe';
import {CommaDotSwapLocalizedPipe} from './pipes/comma-dot-swap-localized.pipe';
import {ChangeLogsComponent} from './component/change-logs/change-logs.component';
import {PhoneNumberInputComponent} from './component/phone-number-input/phone-number-input.component';
import { DisplayChangeLogModalComponent } from './component/display-change-log-modal/display-change-log-modal.component';
import {
    SlotResourceAdditionalInfoModalComponent
} from './component/slot-resource-additional-info/slot-resource-additional-info-modal/slot-resource-additional-info-modal.component';
import {
    SlotResourceAdditionalInfoComponent
} from './component/slot-resource-additional-info/slot-resource-additional-info.component';
import { DisplayChangeLogIconComponent } from './component/display-change-log-icon/display-change-log-icon.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/language_', '.json');
}

@NgModule({
    declarations: [HourFromMinutesPipe, DateFormatPipe, ShortDateFormatPipe, VersionInfoMessageComponent,
        GenerateExportComponent, YesNoPipe, AssignSubServicesComponent, AssignSubServicesModalComponent,
        BlockedAppointmentsPreviewComponent, ShowAvailabilityMainResourceDurationComponent, MainResourceServiceDurationModalComponent,
        FullDateFormatPipe, ModalFiltersComponent, ShowModalFiltersComponent, FullDateBrowserTzFormatPipe, DateMMddFormatPipe,
        DateMMddyyyyFormatPipe, DateMdFormatPipe, DateEndOfDayPipe, FullDateNoTimeFormatPipe, DateBrowserTzFormatPipe, DateddddFormatPipe,
        DateMMDDcommaYYYYFormatPipe, PdfImageViewerComponent, WaitListModalComponent, MultiSelectTableComponent, HumanReadableMinutesComponent,
        GenerateExportFileComponent, FullDateWithTimeFormatPipe, DateMMMMDoFormatPipe, HourFromDatePipe, DateMMMMDoYYYYFormatPipe,
        AppointmentDetailsComponent, DetailsModalComponent, DynamicFormInputComponent, AppointmentCommentsComponent, CommentsModalComponent,
        AppointmentActionButtonsComponent, AppointmentObjectDetailsComponent, ObjectDetailsModalComponent,
        PrintBulletinPdfButtonComponent, AppointmentSendEmailButtonComponent,
        AppointmentPaidStatusChangeButtonComponent, DynamicFormInputErrorComponent, DateMMDDYYYYBrowserTzFormatPipe, UploadFileComponent,
        CommaDotSwapPipe, CommaDotSwapLocalizedPipe, ChangeLogsComponent, PhoneNumberInputComponent,
        DisplayChangeLogModalComponent, SlotResourceAdditionalInfoModalComponent, SlotResourceAdditionalInfoComponent, DisplayChangeLogIconComponent
    ],
    imports: [
        DirectiveModule,
        CommonModule,
        NgbModule,
        BrowserAnimationsModule,
        NgxUiLoaderModule,
        SkedBaseModule,
        ClickOutsideModule,
        NgSelectModule,
        FormsModule,
        NgxPaginationModule,
        MatStepperModule,
        MatIconModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TagInputModule,
        NgbCarouselModule,
        PdfViewerModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        DpDatePickerModule,
        ngfModule
    ],
    exports: [NgbModule, CommonModule, TranslateModule, FormsModule, NgxUiLoaderModule, SkedBaseModule, ClickOutsideModule,
        HourFromMinutesPipe, DirectiveModule, TagInputModule, DateFormatPipe, ShortDateFormatPipe, GenerateExportComponent,
        MatStepperModule, MatIconModule, YesNoPipe, AssignSubServicesComponent, AssignSubServicesModalComponent, BlockedAppointmentsPreviewComponent,
        ShowAvailabilityMainResourceDurationComponent, MainResourceServiceDurationModalComponent, FullDateFormatPipe, ShowModalFiltersComponent,
        FullDateBrowserTzFormatPipe, DateMMddFormatPipe, DateMMddyyyyFormatPipe, DateMdFormatPipe, DateEndOfDayPipe, FullDateNoTimeFormatPipe,
        DateBrowserTzFormatPipe, DateddddFormatPipe, DateMMDDcommaYYYYFormatPipe, PdfImageViewerComponent, MultiSelectTableComponent,
        HumanReadableMinutesComponent, GenerateExportFileComponent, FullDateWithTimeFormatPipe, DateMMMMDoFormatPipe, HourFromDatePipe,
        HourFromDatePipe, DateMMMMDoYYYYFormatPipe, AppointmentDetailsComponent, DynamicFormInputComponent, AppointmentCommentsComponent,
        AppointmentActionButtonsComponent, AppointmentObjectDetailsComponent, DynamicFormInputErrorComponent,
        UploadFileComponent, DateMMDDYYYYBrowserTzFormatPipe, CommaDotSwapPipe, CommaDotSwapLocalizedPipe, ChangeLogsComponent,
        PhoneNumberInputComponent, SlotResourceAdditionalInfoComponent, DisplayChangeLogIconComponent]
})
export class SharedModule {
}
