<div class="slot-search-modal">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" translate="reception.booking.dataLoadingModalTitle"></h4>
    </div>
    <div class="modal-body">
        <div class="d-flex">
            <label class="mr-3 font-weight-bold">{{'reception.booking.slotsFoundForPeriod' | translate : {
                dateTimeFrom: dateFrom | fullDateNoTimeFormatPipe,
                dateTimeTo: dateTo | fullDateNoTimeFormatPipe
            } }}
            </label>
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                (click)="onCloseModal()" translate="label.close">
        </button>
    </div>
</div>
