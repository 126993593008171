import {Router} from '@angular/router';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
    ConfirmDeleteModalService,
    ExportFileEntityEnum,
    ServiceDependentFiltersType,
    ServiceProvider,
    ServiceSearchType,
    SubServiceProvider,
    SubServiceType,
    TagTypeComponent,
    EntityTypeEnum
} from 'sked-base';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {SubServiceMDUtils} from './sub-service-md.util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {constants} from '../../shared/constants/constants';
import {Subject} from 'rxjs';
import {
    BackOfficeSubServiceType
} from '../../data-model/sub-service.types';
import * as lodash from 'lodash';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-sub-service-md',
    templateUrl: './sub-service-md.component.html',
    styleUrls: ['./sub-service-md.component.scss']
})
export class SubServiceMDComponent implements OnInit, AfterViewInit, OnDestroy {
    exportFileEntityEnum = ExportFileEntityEnum;
    subServicesList: SubServiceType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    itemsPerPageList: number[];
    serviceButtonName = 'label.service';
    mainDependentFilters: { service: ServiceDependentFiltersType };
    tagsList: TagTypeComponent[] = [];
    public MDEntityName: string;
    public constants = constants;
    VIEW_ACTIVITY_ENABLED: boolean;
    EntityTypeEnum = EntityTypeEnum;

    private searchSubServiceNameChanged = new Subject<string>(); //notify wend search input changed

    constructor(
        public subServiceProvider: SubServiceProvider,
        public subServiceMDUtils: SubServiceMDUtils,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public serviceProvider: ServiceProvider,
        private previousRouteService: PreviousRouteService,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.MDEntityName = this.subServiceProvider.name;
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`${this.MDEntityName}Read`);
        this.mainDependentFilters = this.subServiceMDUtils.getServiceMainDependentFilters();
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
        // If not coming from route createMd, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/createSubService') {
            this.loadInitialFilters({tableFilters: true, modalFilters: false, sbaseFilters: true});
        }
        this.subscribeSearchByNameChanged();
        this.loadTableEntries();
    }

    ngAfterViewInit(): void {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            // If not coming from route createMd, reset filters to initial values
            if (this.previousRouteService.getPreviousUrl() !== '/createSubService') {
                this.loadInitialFilters({tableFilters: false, modalFilters: true, sbaseFilters: false});
            }
        });
    }

    ngOnDestroy(): void {
    }

    onClearFilters() {
        // Hide modal filters button to reset the color. The modal filters options will be set again inside loadFilters
        this.subServiceMDUtils.modalFilters = undefined;
        // Load filters and data
        setTimeout(() => {
            this.onSearchSubServiceNameChanged('');
            this.loadInitialFilters({tableFilters: true, modalFilters: true, sbaseFilters: true});
            this.loadTableEntries(true);
        });
    }

    onSelectedServiceFilter(serviceFilterList: ServiceSearchType[]): void {
        this.subServiceMDUtils.initialServiceValues = serviceFilterList;
        this.subServiceMDUtils.tableFilters.filter.service = (serviceFilterList && serviceFilterList.length > 0) ? serviceFilterList[0] : undefined;
        this.loadTableEntries();
    }

    createSubService(): void {
        this.router.navigate(['/createSubService']);
    }

    viewSubService(subService: BackOfficeSubServiceType) {
        this.router.navigate(['/createSubService'], {state: {subService, action: constants.VIEW}});
    }

    editSubService(subService: SubServiceType) {
        this.router.navigate(['/createSubService'], {state: {subService, action: constants.EDIT}});
    }

    // method for deleting table item
    deleteItem(id) {
        this.ngxLoader.start();
        this.subServiceProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadTableEntries();
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.subServiceDeleted', true);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onSearchSubServiceNameChanged(value: string) {
        this.searchSubServiceNameChanged.next(value);
    }

    onChangePagination(page: number) {
        this.subServiceMDUtils.tableFilters.currentPage = page;
        this.loadTableEntries(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.subServiceMDUtils.tableFilters.currentPage = 1;
        this.subServiceMDUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadTableEntries(false);
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onSortBy(property: string) {
        // initial isAscendingMode is undefined => ascending sorting
        const isAscendingMode = this.subServiceMDUtils.tableFilters.orderBy[property];

        if (this.subServiceMDUtils.tableFilters.orderBy) {
            this.subServiceMDUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.loadTableEntries(false);
    }

    private subscribeSearchByNameChanged() {
        const self = this;
        this.searchSubServiceNameChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.subServiceMDUtils.tableFilters.filter.name = searchValue;
                self.loadTableEntries();
            });
    }

    // get services data and display into table
    loadTableEntries(includeCount: boolean = true) {
        const queryFilter = this.subServiceMDUtils.getQueryFilterForSubServiceMD(this.subServiceMDUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.subServiceProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.subServicesList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.subServiceMDUtils.tableFilters.orderBy[selectedItem];
        this.loadTableEntries(false);
    }

    displayConfirmDeleteItemModal(subServiceId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.subServiceProvider, subServiceId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(subServiceId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            this.subServiceMDUtils.modalFilters.areFiltersActive = this.subServiceMDUtils.isAnyFilterActive(this.subServiceMDUtils.modalFilters);
            this.subServiceMDUtils.previousModalFilters = lodash.cloneDeep(this.subServiceMDUtils.modalFilters);
            // Filter the data based on the new filters
            this.subServiceMDUtils.tableFilters.filter.enabledOnPatientAccess =
                this.subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel;
            this.subServiceMDUtils.tableFilters.filter.favorite = this.subServiceMDUtils.modalFilters.favoriteOptions.ngModel;
            this.loadTableEntries();
        } else {
            this.subServiceMDUtils.modalFilters = lodash.cloneDeep(this.subServiceMDUtils.previousModalFilters);
        }
    }

    private loadInitialFilters({tableFilters, modalFilters, sbaseFilters}: {tableFilters?: boolean, modalFilters?: boolean, sbaseFilters?: boolean}) {
        if (!!tableFilters) {
            this.subServiceMDUtils.tableFilters = this.subServiceMDUtils.getInitialTableFilter();
        }
        if (!!modalFilters) {
            this.subServiceMDUtils.modalFilters = this.subServiceMDUtils.getInitialModalFilters();
            this.subServiceMDUtils.previousModalFilters = this.subServiceMDUtils.getInitialModalFilters();
        }
        if (!!sbaseFilters) {
            this.subServiceMDUtils.initialServiceValues = [];
        }
    }
}
