import {Injectable} from '@angular/core';
import {
    ODataFilterQueryType,
    ODataOrderByQueryType,
    ScreenTemplateLayoutType,
    TableFiltersType
} from '../../data-model/general.type';
import {
    ActivityPlanExecutionTypeEnum,
    ActivityPlanScheduledEndTypeEnum,
    ActivityPlanServiceType,
    ActivityPlanTemplateDependentFiltersType,
    EnqueueUnitEnum,
    Expand,
    ODataQueryObjectType,
    SpecialityType,
    Validations
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {Subject} from 'rxjs';
import {ActivityPlanType} from 'sked-base/lib/data-model/activityPlanTypes';
import {
    ActivityPlanFrequency,
    ActivityPlanFrequencyTypeEnum,
    ActivityPlanFrequencyValue,
    ActivityPlanServiceOverviewValidation,
    ActivityPlanServiceValidation,
    FrequencyMonthDayType,
    FrequencyWeekDayType
} from './activity-plan.types';
import * as moment from 'moment';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {constants} from '../../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ActivityPlanUtils {
    tableFilters: TableFiltersType;
    WEEKDAYS: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    MONTHDAYS: number[] = [...Array(32).keys()].slice(1); // array of numbers from 1 to 31

    screenTemplateLayout: ScreenTemplateLayoutType;
    activityPlanItem: ActivityPlanType = {} as ActivityPlanType;
    scopedServiceItem: ActivityPlanServiceType;
    scopedServiceFrequencyItem: ActivityPlanFrequency;
    scopedServiceItemIndex: number;
    scopedServiceHasValues = false;
    scopedServiceValidation: ActivityPlanServiceValidation;
    scopedServiceInEditMode = false;
    scopedServiceShowReminderToSave = false;
    maximumTimeWindow: number;
    triggerSimulationSubject: Subject<void> = new Subject<void>();
    triggerClearCalendarPageSubject: Subject<void> = new Subject<void>();
    isExecutionTypeOnceSelected: boolean;
    serviceOverviewValidations: ActivityPlanServiceOverviewValidation[];
    simulationRefreshButton: boolean;
    startDateNgModel: NgbDateStruct;
    startDateMinDate: NgbDateStruct = this.dateTimeUtils.getTodayNgbDateStruct();
    endDateNgModel: NgbDateStruct;
    endDateMinDate: NgbDateStruct = this.dateTimeUtils.getTodayNgbDateStruct();

    constructor(
        private generalUtils: GeneralUtils,
        private patientContextService: PatientContextService,
        private validations: Validations,
        private dateTimeUtils: DateTimeUtils,
    ) {
    }

    isScopedServiceValid(): boolean {
        this.updateScopedServiceValidation();
        return this.isServiceValid(this.scopedServiceValidation, this.scopedServiceItem);
    }

    isServiceValid(serviceValidation: ActivityPlanServiceValidation, service: ActivityPlanServiceType): boolean {
        return serviceValidation.service.isValid
            && serviceValidation.startTime.isValid
            && (service.executionType === ActivityPlanExecutionTypeEnum.Once || serviceValidation.recurrenceModal.isValid);
    }

    updateScopedServiceValidation() {
        this.scopedServiceValidation = this.getValidateActivityService(
            this.scopedServiceItem,
            this.scopedServiceFrequencyItem
        );
        this.triggerClearCalendarPageSubject.next();
    }

    onExecutionTypeChange() {
        this.scopedServiceHasValues = true;
        if (this.isExecutionTypeOnceSelected) {
            this.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Once;
        } else {
            this.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Scheduled;
        }
        this.updateScopedServiceValidation();
    }

    onStartDateChange(date: NgbDateStruct) {
        this.endDateMinDate = date;
        this.scopedServiceItem.startTime = this.dateTimeUtils.getMomentParseZoneFromNgbDate(date).format('YYYY-MM-DD');
        this.updateScopedServiceValidation();
    }

    onFrequencyTypeChange() {
        this.scopedServiceHasValues = true;
        if (this.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.monthDays) {
            this.scopedServiceFrequencyItem.selectedMonthDays =
                this.getMonthDays(this.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.weekDays) {
            this.scopedServiceFrequencyItem.selectedWeekDays =
                this.getWeekDays(this.scopedServiceFrequencyItem.selectedDays as string[]);
        }
        this.scopedServiceFrequencyItem.selectedDays = [];
        this.updateScopedServiceValidation();
    }

    onFrequencyWeekDayClick(weekDay: FrequencyWeekDayType) {
        this.scopedServiceHasValues = true;
        weekDay.selected = !weekDay.selected;
        if (weekDay.selected) {
            (this.scopedServiceFrequencyItem.selectedDays as string[]).push(weekDay.name);
        } else {
            lodash.pull(this.scopedServiceFrequencyItem.selectedDays, weekDay.name);
        }
        this.updateScopedServiceValidation();
    }

    onFrequencyMonthDayClick(monthDay: FrequencyMonthDayType) {
        this.scopedServiceHasValues = true;
        monthDay.selected = !monthDay.selected;
        if (monthDay.selected) {
            (this.scopedServiceFrequencyItem.selectedDays as number[]).push(monthDay.name);
        } else {
            lodash.pull(this.scopedServiceFrequencyItem.selectedDays, monthDay.name);
        }
        this.updateScopedServiceValidation();
    }

    addSpecialityNameToServices(activityPlanList: ActivityPlanType[], specialityData: SpecialityType[]): ActivityPlanType[] {
        const activityPlanListToMap = lodash.cloneDeep(activityPlanList);
        // for all templates, map an input template to a template whose services.service have the specialityName field added
        activityPlanListToMap.map(activityPlan => {
            activityPlan.services = activityPlan.services.map((service: ActivityPlanServiceType) => {
                // if the ActivityPlanServiceType service doesn't have a ServiceType service, don't change anything
                if (!service?.service) {
                    return service;
                }
                // otherwise, add the speciality name to its ServiceType service
                const serviceWithSpecialityName = lodash.cloneDeep(service);
                const specialityItem = lodash.find(specialityData, {id: serviceWithSpecialityName.service.specialityId});
                serviceWithSpecialityName.service.specialityName = specialityItem?.name;
                return serviceWithSpecialityName;
            });
            return activityPlan;
        });
        return activityPlanListToMap;
    }

    addParsedFrequencyToItem(activityPlan: ActivityPlanType): ActivityPlanType {
        const activityPlanWithParsedFrequency = lodash.cloneDeep(activityPlan);
        activityPlanWithParsedFrequency.services = activityPlanWithParsedFrequency.services.map((
            service: ActivityPlanServiceType
        ) => {
            const serviceWithParsedFrequency = lodash.cloneDeep(service);
            serviceWithParsedFrequency.parsedFrequency = this.parseActivityPlanServiceFrequency(serviceWithParsedFrequency.frequency);
            return serviceWithParsedFrequency;
        });
        return activityPlanWithParsedFrequency;
    }

    getQueryFilterForActivityPlan(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'PatientId', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Services: {
                expand: {
                    Service: {
                        select: ['Id', 'Name', 'SpecialityId', 'OnlineConsultation'],
                    }
                }
            },
            ActivityPlanTemplate: {select: ['Id', 'Name']},
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {PatientId: this.patientContextService.patient?.id ?? ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'PatientId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialActivityPlan(): ActivityPlanType {
        return {
            patientId: this.patientContextService.patient?.id,
            nextSkedTaskEnqueueDate: undefined,
            lastSkedTaskEnqueueDate: undefined,
            activityPlanTemplateId: undefined,
            services: [],
        } as ActivityPlanType;
    }

    getInitialActivityServiceValidation(): ActivityPlanServiceValidation {
        return {
            frequencyType: {isValid: true, errorMessage: ''},
            frequencyDays: {isValid: true, errorMessage: ''},
            interval: {isValid: true, errorMessage: ''},
            count: {isValid: true, errorMessage: ''},
            enqueue: {isValid: true, errorMessage: ''},
            startTime: {isValid: true, errorMessage: ''},
            endTime: {isValid: true, errorMessage: ''},
            service: {isValid: true, errorMessage: ''},
            recurrenceModal: {isValid: true, errorMessage: ''},
            scheduledEndType: {isValid: true, errorMessage: ''},
        };
    }

    getInitialActivityPlanService(): ActivityPlanServiceType {
        return {
            serviceId: '',
            frequency: '{}',
            interval: null,
            count: null,
            enqueuePeriod: 2,
            enqueueUnit: EnqueueUnitEnum.Weeks,
            executionType: ActivityPlanExecutionTypeEnum.Scheduled,
            scheduledEndType: ActivityPlanScheduledEndTypeEnum.EndsNever,
            startTime: undefined,
            endTime: undefined,
        } as ActivityPlanServiceType;
    }

    getEmptyActivityPlanServiceFrequency(): ActivityPlanFrequency {
        return {
            frequencyType: ActivityPlanFrequencyTypeEnum.weekDays,
            selectedDays: []
        } as ActivityPlanFrequency;
    }

    parseActivityPlanServiceFrequency(frequency: string): ActivityPlanFrequency {
        let frequencyJSON;
        try {
            frequencyJSON = JSON.parse(frequency);
        } catch {
            return this.getEmptyActivityPlanServiceFrequency();
        }
        let keys;
        try {
            keys = Object.keys(frequencyJSON);
        } catch {
            return this.getEmptyActivityPlanServiceFrequency();
        }
        if (!lodash.has(keys, 'length') || keys.length < 0 || keys.length > 1 ||
            (keys[0] !== ActivityPlanFrequencyTypeEnum.monthDays && keys[0] !== ActivityPlanFrequencyTypeEnum.weekDays)) {
            return this.getEmptyActivityPlanServiceFrequency();
        }
        const frequencyType = keys[0] === ActivityPlanFrequencyTypeEnum.monthDays ?
            ActivityPlanFrequencyTypeEnum.monthDays :
            ActivityPlanFrequencyTypeEnum.weekDays;
        return {
            frequencyType,
            selectedDays: frequencyJSON[keys[0]]
        } as ActivityPlanFrequency;
    }

    stringifyActivityPlanServiceFrequency(frequency: ActivityPlanFrequency): string {
        const selectedDaysAsString = (frequency.selectedDays as any[]).map((day: ActivityPlanFrequencyValue) => {
            if (typeof day === 'string') {
                return `"${day}"`;
            }
            else {
                return `${day}`;
            }
        }).join(',');
        return `{"${frequency.frequencyType}":[${selectedDaysAsString}]}`;
    }

    getDaysFromEnqueuePeriodAndUnit(period: number, unit: EnqueueUnitEnum): number {
        if (unit === EnqueueUnitEnum.Days) {
            return period;
        }
        if (unit === EnqueueUnitEnum.Weeks) {
            return period * constants.DAYS_IN_WEEK;
        }
        if (unit === EnqueueUnitEnum.Months) {
            return period * constants.DAYS_IN_MONTH;
        }
    }

    mapScopedServiceToSave(service: ActivityPlanServiceType, frequency: ActivityPlanFrequency): ActivityPlanServiceType {
        const serviceToSave = lodash.cloneDeep(service);
        // sort the selected days
        const frequencyOrdered = lodash.cloneDeep(frequency);
        if (frequencyOrdered.frequencyType === ActivityPlanFrequencyTypeEnum.weekDays) {
            frequencyOrdered.selectedDays = lodash.orderBy(frequencyOrdered.selectedDays, (day) => lodash.indexOf(this.WEEKDAYS, day));
        } else { // monthDays
            frequencyOrdered.selectedDays = lodash.orderBy(frequencyOrdered.selectedDays);
        }
        serviceToSave.frequency = this.stringifyActivityPlanServiceFrequency(frequencyOrdered);
        serviceToSave.parsedFrequency = frequencyOrdered;
        return serviceToSave as ActivityPlanServiceType;
    }

    mapActivityPlanToSend(activityPlan: ActivityPlanType): ActivityPlanType {
        const activityPlanToSend = lodash.cloneDeep(activityPlan);
        activityPlanToSend.services = activityPlanToSend.services.map((service: ActivityPlanServiceType) => {
            if (service.executionType === ActivityPlanExecutionTypeEnum.Once) {
                const serviceToSendForOnce: ActivityPlanServiceType = {
                    serviceId: service.serviceId,
                    executionType: ActivityPlanExecutionTypeEnum.Once,
                    startTime: service.startTime
                } as ActivityPlanServiceType;
                if (!!activityPlanToSend.id && !service.activityPlanId) {
                    serviceToSendForOnce.activityPlanId = activityPlanToSend.id;
                }
                return serviceToSendForOnce;
            }
            const serviceToSend: ActivityPlanServiceType = lodash.cloneDeep(service);
            if (!!activityPlanToSend.id && !service.activityPlanId) {
                serviceToSend.activityPlanId = activityPlanToSend.id;
            }
            if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences) {
                serviceToSend.count = service.count;
                serviceToSend.endTime = null;
            } else if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate) {
                serviceToSend.count = null;
                serviceToSend.endTime = service.endTime;
            } else if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsNever) {
                serviceToSend.count = null;
                serviceToSend.endTime = null;
            }
            return serviceToSend;
        });
        return activityPlanToSend;
    }

    getWeekDays(selectedDays: string[]): FrequencyWeekDayType[] {
        return this.WEEKDAYS.map((weekday: string) => {
            return {
                name: weekday,
                selected: (lodash.indexOf(selectedDays, weekday) > -1)
            } as FrequencyWeekDayType;
        });
    }

    getMonthDays(selectedDays: number[]): FrequencyMonthDayType[] {
        return this.MONTHDAYS.map((monthday: number) => {
            return {
                name: monthday,
                selected: (lodash.indexOf(selectedDays, monthday) > -1)
            } as FrequencyMonthDayType;
        });
    }

    updateServiceOverviewValidations() {
        this.serviceOverviewValidations = this.activityPlanItem?.services?.map((service: ActivityPlanServiceType) => {
            const scopedServiceFrequencyItem = this.parseActivityPlanServiceFrequency(service.frequency);
            if (scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.monthDays) {
                scopedServiceFrequencyItem.selectedMonthDays = this.getMonthDays(scopedServiceFrequencyItem.selectedDays as number[]);
            } else if (scopedServiceFrequencyItem.frequencyType === ActivityPlanFrequencyTypeEnum.weekDays) {
                scopedServiceFrequencyItem.selectedWeekDays = this.getWeekDays(scopedServiceFrequencyItem.selectedDays as string[]);
            }
            const scopedServiceValidation = this.getValidateActivityService(service, scopedServiceFrequencyItem);
            return {
                isServiceValid: this.isServiceValid(scopedServiceValidation, service),
                isStartTimeValid: scopedServiceValidation?.startTime?.isValid,
            } as ActivityPlanServiceOverviewValidation;
        }) ?? [];
    }

    isSimulationCalendarEnabled(): boolean {
        return this.scopedServiceHasValues && this.scopedServiceValidation.recurrenceModal.isValid;
    }

    getActivityPlanTemplateDependentFilters(): ActivityPlanTemplateDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
        } as ActivityPlanTemplateDependentFiltersType;
    }

    replaceServicesCountToNullBasedOnScheduledEndType(
        response: { value: ActivityPlanType[], count?: number }
    ): { value: ActivityPlanType[], count?: number } {
        return {
            value: response.value?.map((activityPlan: ActivityPlanType) => ({
                ...activityPlan,
                services: activityPlan.services.map((service: ActivityPlanServiceType) => ({
                    ...service,
                    count: service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences ? service.count : null,
                })) as ActivityPlanServiceType[],
            })) ?? [] as ActivityPlanType[],
            count: response.count,
        } as { value: ActivityPlanType[], count?: number };
    }

    setInitialDatesForService() {
        this.startDateNgModel = this.scopedServiceItem.startTime
            ? this.dateTimeUtils.getNgbDateFromMoment(
                moment.parseZone(this.scopedServiceItem.startTime)
            )
            : null;
        this.endDateNgModel = this.scopedServiceItem.endTime
            ? this.dateTimeUtils.getNgbDateFromMoment(
                moment.parseZone(this.scopedServiceItem.endTime)
            )
            : null;
        this.endDateMinDate = this.startDateNgModel;
    }

    clearScopedService() {
        this.scopedServiceItemIndex = undefined;
        this.isExecutionTypeOnceSelected = false;
        this.scopedServiceItem = this.getInitialActivityPlanService();
        this.scopedServiceFrequencyItem = this.getEmptyActivityPlanServiceFrequency();
        this.scopedServiceFrequencyItem.selectedWeekDays = this.getWeekDays([]);
        this.scopedServiceFrequencyItem.selectedMonthDays = this.getMonthDays([]);
        this.scopedServiceHasValues = false;
        this.scopedServiceInEditMode = false;
        this.scopedServiceValidation = this.getInitialActivityServiceValidation();
        this.startDateNgModel = null;
        this.startDateMinDate = this.dateTimeUtils.getTodayNgbDateStruct();
        this.endDateNgModel = null;
        this.endDateMinDate = this.dateTimeUtils.getTodayNgbDateStruct();
        this.scopedServiceShowReminderToSave = false;
    }

    private getValidateActivityService(
        service: ActivityPlanServiceType, frequency: ActivityPlanFrequency
    ): ActivityPlanServiceValidation {
        const serviceValidation = {
            frequencyType: {isValid: true, errorMessage: ''},
            frequencyDays: {isValid: true, errorMessage: ''},
            interval: {isValid: true, errorMessage: ''},
            count: {isValid: true, errorMessage: ''},
            enqueue: {isValid: true, errorMessage: ''},
            startTime: {isValid: true, errorMessage: ''},
            endTime: {isValid: true, errorMessage: ''},
            service: {isValid: true, errorMessage: ''},
            recurrenceModal: {isValid: true, errorMessage: ''},
            scheduledEndType: {isValid: true, errorMessage: ''},
        } as ActivityPlanServiceValidation;

        // Count is not required; if any value is inserted it must be > 0
        if (!this.generalUtils.isUndefinedOrNull(service.count)) {
            serviceValidation.count = this.validations.getValidateIntegerBetween(`${service.count}`, 1, undefined, 'label.error.greaterThanZero');
        }

        // Interval is required and > 0
        if (this.generalUtils.isUndefinedOrNull(service.interval)) {
            serviceValidation.interval = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.interval = this.validations.getValidateIntegerBetween(
                `${service.interval}`, 1, undefined, 'label.error.greaterThanZero'
            );
        }

        // FrequencyType is required: type should be selected
        if (this.generalUtils.isUndefinedOrNull(frequency.frequencyType) || lodash.isEmpty(frequency.frequencyType)) {
            serviceValidation.frequencyType = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.frequencyType = {isValid: true, errorMessage: ''};
        }

        // FrequencyDays is required: should have at least one element selected
        if (this.generalUtils.isUndefinedOrNull(frequency.selectedDays) || lodash.isEmpty(frequency.selectedDays)) {
            serviceValidation.frequencyDays = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.frequencyDays = {isValid: true, errorMessage: ''};
        }

        // Enqueue is required, > 0 and <= maximum time window (from system config)
        if (this.generalUtils.isUndefinedOrNull(service.enqueueUnit) || this.generalUtils.isUndefinedOrNull(service.enqueuePeriod)) {
            serviceValidation.enqueue = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            const enqueuePeriodInDays = this.getDaysFromEnqueuePeriodAndUnit(service.enqueuePeriod, service.enqueueUnit);
            // check for lower bound first
            serviceValidation.enqueue = this.validations.getValidateIntegerBetween(`${enqueuePeriodInDays}`, 1, undefined, 'label.error.greaterThanZero');
            if (serviceValidation.enqueue.isValid === true) {
                // if lower bound is fine, check for upper bound
                serviceValidation.enqueue = this.validations.getValidateIntegerBetween(`${enqueuePeriodInDays}`, undefined, this.maximumTimeWindow, 'label.error.activityPlanInvalidEnqueue');
            }
        }

        // Service is required
        if (this.generalUtils.isUndefinedOrNull(service.service) || this.generalUtils.isUndefinedOrNull(service.service)) {
            serviceValidation.service = {isValid: false, errorMessage: 'label.error.required'};
        }

        if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences) {
            if (this.generalUtils.isUndefinedOrNull(service.count)) {
                serviceValidation.count = {isValid: false, errorMessage: 'label.error.required'};
            } else {
                const countNumber = Number(service.count ?? 0) ?? 0;
                if (isNaN(countNumber) || countNumber < 1) {
                    serviceValidation.count = {isValid: false, errorMessage: 'label.error.valueMustBeAtLeastOne'};
                }
            }
        }

        if (!service.startTime || !moment.parseZone(service.startTime).isValid()) {
            serviceValidation.startTime = {isValid: false, errorMessage: 'label.error.required'};
        } else if (moment.parseZone(service.startTime).startOf('d').isBefore(moment.parseZone().startOf('d'))) {
            serviceValidation.startTime = {isValid: false, errorMessage: 'toastr.error.dateNotInPast'};
        }

        if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate) {
            if (this.generalUtils.isUndefinedOrNull(service.endTime)) {
                serviceValidation.endTime = {isValid: false, errorMessage: 'label.error.required'};
            } else if (moment.parseZone(service.endTime).isBefore(moment.parseZone(service.startTime))) {
                serviceValidation.endTime = {isValid: false, errorMessage: 'label.endDateMustBeAfterStartDate'};
            }
        }

        if (!service.scheduledEndType) {
            serviceValidation.scheduledEndType = {isValid: false, errorMessage: 'label.error.required'};
        }

        const isModalValid = service.executionType === ActivityPlanExecutionTypeEnum.Once
            ? true
            : serviceValidation.frequencyType.isValid
            && serviceValidation.frequencyDays.isValid
            && serviceValidation.interval.isValid
            && serviceValidation.enqueue.isValid
            && serviceValidation.startTime.isValid
            && serviceValidation.endTime.isValid
            && serviceValidation.count.isValid
            && serviceValidation.scheduledEndType.isValid;
        serviceValidation.recurrenceModal = {
            isValid: isModalValid,
            errorMessage: isModalValid ? '' : 'label.thereAreErrorsInside',
        };

        return serviceValidation;
    }
}
