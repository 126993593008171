import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {ConfirmDeleteModalService, EntityTypeEnum, HolidayProvider, HolidayType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {HolidayMdUtils} from './holiday-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-holiday-md',
    templateUrl: './holiday-md.component.html',
    styleUrls: ['./holiday-md.component.scss']
})
export class HolidayMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    holidayMdList: HolidayType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    HOLIDAY = 'Holiday';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchHolidayChanged = new Subject<string>();

    constructor(
        public holidayMdUtils: HolidayMdUtils,
        public holidayProvider: HolidayProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        private router: Router,
        private generalUtils: GeneralUtils,
        private confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('HolidayRead');
        if (this.previousRouteService.getPreviousUrl() !== '/createHoliday') {
            this.holidayMdUtils.tableFilters = this.holidayMdUtils.getInitialTableFilter();
        }
        this.getHolidayMdData();
        this.subscribeSearchByHolidayChanged();
    }


    ngOnDestroy(): void {
    }

    // method to navigate create Holiday
    createHoliday(): void {
        this.router.navigate(['/createHoliday']);
    }

    editHoliday(holiday: HolidayType) {
        this.router.navigate(['/createHoliday'], {state: {holiday, action: constants.EDIT}});
    }

    viewHoliday(holiday: HolidayType) {
        this.router.navigate(['/createHoliday'], {state: {holiday, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.holidayMdUtils.tableFilters.currentPage = page;
        this.getHolidayMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.holidayMdUtils.tableFilters.currentPage = 1;
        this.holidayMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getHolidayMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.holidayMdUtils.tableFilters.orderBy[property];
        if (this.holidayMdUtils.tableFilters.orderBy) {
            this.holidayMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getHolidayMdData(false);
    }

    onSearchHolidayChanged(value: string) {
        this.searchHolidayChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.holidayMdUtils.tableFilters.orderBy[selectedItem];
        this.getHolidayMdData(false);
    }

    displayConfirmDeleteItemModal(holidayId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.holidayProvider, holidayId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(holidayId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string) {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onClearFilters() {
        this.onSearchHolidayChanged('');
        this.holidayMdUtils.tableFilters = this.holidayMdUtils.getInitialTableFilter();
        this.getHolidayMdData(true);
    }


    // function to get Holiday data
    private getHolidayMdData(includeCount: boolean = true) {
        const queryFilter = this.holidayMdUtils.getQueryFilterForHolidayMD(this.holidayMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.holidayProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: { value: HolidayType[], count?: number }) => {
                this.holidayMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.holidayMdUtils.tableFilters = this.holidayMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.holidayProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getHolidayMdData();
                this.messagesService.success('toastr.success.holidayDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByHolidayChanged() {
        const self = this;
        this.searchHolidayChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.holidayMdUtils.tableFilters.filter.description = searchValue;
                self.getHolidayMdData();
            });
    }
}
