import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {HolidayCalendarProvider, HolidayProvider, HolidayType, HolidayTypeEnum} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HolidayMdUtils} from '../holiday-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgbDateFixedOrMovableFormatter} from './holiday.dateFormatter';
import {HolidayCalendarType} from 'sked-base/lib/data-model/holidayCalendarTypes';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-holiday',
    templateUrl: './create-holiday.component.html',
    styleUrls: ['./create-holiday.component.scss'],
    providers: [
        {provide: NgbDateParserFormatter, useClass: NgbDateFixedOrMovableFormatter}
    ]
})
export class CreateHolidayComponent implements OnInit, OnDestroy {
    constants = constants;
    initialHoliday: HolidayType;
    holidayTypes = Object.values(HolidayTypeEnum);
    holidayTypesEnum = HolidayTypeEnum;
    holidayItem: HolidayType = {} as HolidayType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    holidayItemDate: NgbDateStruct;
    isHolidayAssignedToHolidayCalendar = false;
    initialYear: number;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private holidayMdUtils: HolidayMdUtils,
        private holidayProvider: HolidayProvider,
        private holidayCalendarProvider: HolidayCalendarProvider,
        private router: Router,
        public generalUtils: GeneralUtils,
        public dateTimeUtils: DateTimeUtils,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    onTypeChange(type) {
        this.holidayMdUtils.holidayTypeSubject.next(type);
        this.holidayItemDate = lodash.cloneDeep(this.holidayItemDate); // trigger formatter
        this.holidayItemDate.year = this.initialYear;
        this.holidayItem.type = this.generalUtils.isSelectedNoValueOption(type) ? 'noValue' : type;
    }

    saveHolidayData(holiday: HolidayType) {
        holiday.dayOfMonth = this.holidayItemDate.day;
        holiday.monthOfYear = this.holidayItemDate.month;
        holiday.year = this.holidayItemDate.year;
        if (holiday.type === HolidayTypeEnum.Fixed) {
            holiday.year = null;
        }
        const isTemplateValid = this.validateFields(holiday);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createHoliday(holiday);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialHoliday, holiday)) {
                    this.messagesService.success('toastr.success.holidayEdit', true);
                    this.goToParentPage();
                } else {
                    this.editHoliday(this.initialHoliday, holiday);
                }
            }
        }
    }

    goToParentPage() {
        this.holidayMdUtils.holidayTypeSubscription.unsubscribe();
        this.router.navigate(['/holidays']);
    }

    goToEdit() {
        history.replaceState({
            holiday: this.holidayItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    private loadIsHolidayAssignedToAHolidayCalendar(holidayId: string) {
        const queryFilter = this.holidayMdUtils.getQueryForIsHolidayAssignedToAHolidayCalendar(holidayId);
        this.ngxLoader.start();
        this.holidayCalendarProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: { value: HolidayCalendarType[], count?: number }) => {
                this.isHolidayAssignedToHolidayCalendar = response.count > 0;
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to create the new Holiday
    private createHoliday(holiday: HolidayType) {
        this.ngxLoader.start();
        this.holidayProvider.addEntry(holiday)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newHolidayAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the Holiday
    private editHoliday(oldHoliday: HolidayType, newHoliday: HolidayType) {
        this.ngxLoader.start();
        this.holidayProvider.updateEntry(oldHoliday, newHoliday)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.holidayEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(holidayItem: HolidayType): boolean {
        return !!(holidayItem && holidayItem.description && holidayItem.type &&
            holidayItem.dayOfMonth && holidayItem.monthOfYear &&
            (holidayItem.type === HolidayTypeEnum.Fixed
                || (holidayItem.type === HolidayTypeEnum.Movable && holidayItem.year &&
                    holidayItem.year >= (new Date()).getFullYear())));
    }

    private setupInitialState() {
        if (history.state && history.state.holiday) {
            this.initialHoliday = history.state.holiday;
            this.holidayItem = lodash.cloneDeep(history.state.holiday);
            this.holidayItemDate = {
                year: this.holidayItem.year,
                month: this.holidayItem.monthOfYear,
                day: this.holidayItem.dayOfMonth
            } as NgbDateStruct;
            if (this.holidayItem.type === HolidayTypeEnum.Fixed) {
                this.holidayItemDate.year = (new Date()).getFullYear();
            }
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.loadIsHolidayAssignedToAHolidayCalendar(this.holidayItem.id);
                this.initialYear = this.holidayItemDate.year;
            }
        } else {
            this.isHolidayAssignedToHolidayCalendar = false;
            this.holidayItem = this.holidayMdUtils.getInitialHoliday();
            this.holidayItemDate = this.holidayMdUtils.getInitialHolidayDate();
            this.initialYear = this.holidayItemDate.year;
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.holidayMdUtils.holidayTypeSubject.next(this.holidayItem.type);
    }

    ngOnDestroy(): void {
    }
}
