import {Injectable} from '@angular/core';
import {
    AppointmentListTemplateName,
    AppointmentsPdfQueryType,
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider, DateRangeOptionsType, ExportAppointmentsType,
    FilterWrapperButtonNameEnum,
    FilterWrapperNameEnum, FormValidationType,
    GenericFilterOptionsType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ResourceProvider,
    ServiceDependentFiltersType,
    ServiceProvider
} from 'sked-base';
import {
    ExportAppointmentsFilterOptions,
    ExportAppointmentsFiltersValidationType,
    ExportAppointmentsFilterWrapperInitialValuesType
} from './export-appointments.types';
import * as moment from 'moment/moment';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class ExportAppointmentsUtils {
    exportAppointmentsFilterOptions: ExportAppointmentsFilterOptions = this.getEmptyExportAppointmentsFiltersOptions();

    constructor(private areaProvider: AreaProvider,
                private serviceProvider: ServiceProvider,
                private resourceProvider: ResourceProvider,
                private centerProvider: CenterProvider,
                private dateTimeUtils: DateTimeUtils,
                private translatedLanguageService: TranslatedLanguageService) {
    }

    getQueryFilterForAppointmentsPdf(): AppointmentsPdfQueryType {
        const {location, service, resource, area, status} =
            this.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues;
        const {toDate, fromDate} = this.exportAppointmentsFilterOptions.dateRangeOptions;
        const dateFromMoment = moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format();
        const dateToMoment = moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).endOf('day').format(); //date appears on PDF so we cannot increment to 1 day start of day

        return {
            resourceId: resource?.id,
            centerId: location?.id,
            areaId: area?.id,
            serviceId: service?.id,
            dateFrom: dateFromMoment.replace('+', '%2B'),
            dateTo: dateToMoment.replace('+', '%2B'),
            status,
            title: '',
            language: this.translatedLanguageService.getUsedLanguage(),
            templateName: AppointmentListTemplateName.AppointmentOverview
        };
    }

    getQueryFilterForExportAppointmentsPdf(): ExportAppointmentsType {
        const {location, service, resource, area, status} =
            this.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues;
        const {toDate, fromDate} = this.exportAppointmentsFilterOptions.dateRangeOptions;
        const dateFromMoment = moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format();
        const dateToMoment = moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).format();
        const dateToMomentPlus1Day = moment(dateToMoment).add(1, 'days').format();

        return {
            dateFrom: dateFromMoment,
            dateTo: dateToMomentPlus1Day,
            centerId: location?.id,
            resourceId: resource?.id,
            serviceId: service?.id,
            areaId: area?.id,
            status,
            language: this.translatedLanguageService.getUsedLanguage()
        } as ExportAppointmentsType;
    }

    getInitialFilterValidations(): ExportAppointmentsFiltersValidationType {
        return {
            area: {isValid: true, errorMessage: ''} as FormValidationType,
            resource: {isValid: true, errorMessage: ''} as FormValidationType,
            service: {isValid: true, errorMessage: ''} as FormValidationType,
            location: {isValid: true, errorMessage: ''} as FormValidationType,
            dateRange: {isValid: true, errorMessage: ''} as FormValidationType,
            statusSelect: { isValid: true, errorMessage: '' } as FormValidationType,
        };
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const todayMoment = moment();
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayMinus1YearMoment = moment().subtract(1, 'years');
        const todayMinus1Year = this.dateTimeUtils.getNgbDateFromMoment(todayMinus1YearMoment);
        const fromDate = {year: today.year, month: today.month, day: today.day};
        const minDate = {year: todayMinus1Year.year, month: todayMinus1Year.month, day: todayMinus1Year.day};
        const toDate = {year: today.year, month: today.month, day: today.day};
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.from',
            toDateLabel: 'label.to',
            minDate,
            disabled: false,
            maximumRange: 30
        } as DateRangeOptionsType;
    }

    getEmptyExportAppointmentsFiltersOptions(): ExportAppointmentsFilterOptions {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            dateRangeOptions: {},
            exportAppointmentsFiltersValues: {},
            areFiltersValid: false,
            statusSelectNgModel: undefined,
        } as ExportAppointmentsFilterOptions;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        onlyAssignedToLocationsOfUser: true,
        includeAvailabilities: false,
        includeChannel: true,
        searchPhrase: '',
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        hasMultiResourceBluePrint: null,
        exclusionList: [],
        count: true,
    } as ServiceDependentFiltersType)

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    } as ResourceDependentFiltersType)

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: true,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: true,
        exclusionList: [],
        count: true
    })

    getFilterWrapperOptions = (initialValues?: ExportAppointmentsFilterWrapperInitialValuesType): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.getAreaDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.area,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues?.area ? {
                    name: FilterWrapperNameEnum.area,
                    value: initialValues.area
                } : undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getServiceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.service,
                name: FilterWrapperNameEnum.service,
                parentFilterValue: initialValues?.service ? {
                    name: FilterWrapperNameEnum.service,
                    value: initialValues.service
                } : undefined,
                providerInstance: this.serviceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getResourceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resource,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues?.resource ? {
                    name: FilterWrapperNameEnum.resource,
                    value: initialValues.resource
                } : undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getLocationDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.location,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues?.location ? {
                    name: FilterWrapperNameEnum.location,
                    value: initialValues.location
                } : undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            },
        ]
    )
}
