<div class="slots-results-container pl-1 pt-1" *ngIf="!!slotsManagementMdUtils.slotsResultsOptions">
    <h6 class="font-weight-bold mb-0">
        <span translate="label.timeSlots" class="pr-1"
              [ngClass]="{'has-warning': slotsManagementMdUtils.filtersChangedWithNoSearch}"></span>
        <i *ngIf="slotsManagementMdUtils.filtersChangedWithNoSearch"
           class="fas fa-exclamation-triangle ml-1 has-warning"
           [ngbPopover]="filtersChangedWithNoSearchWarningPopover"
           placement="bottom"
           popoverClass="popover-shadow"
           triggers="mouseenter:mouseleave"
        ></i>
        <ng-template #filtersChangedWithNoSearchWarningPopover>
            <span translate="label.filtersChangedWithNoSearch"></span>
        </ng-template>
    </h6>
    <ul class="list-group list-group-flush slots-list-per-day mt-2"
        infinite-scroll
        [infiniteScrollDistance]="10"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        [alwaysCallback]="true"
        (scrolled)="onScrollDown()">
        <li class="no-slots-error"
            translate="label.thereAreNoResultsForYourSearch"
            *ngIf="slotsManagementMdUtils.noSlotsFound">
        </li>
        <li class="list-group-item text-high-contrast"
            *ngFor="let slot of slotsManagementMdUtils.slotsResultsOptions.slice(0, slotsManagementMdUtils.slotsResultsSliceUpperBound); let isFirstSlot = first; let isLastSlot = last"
            [ngClass]="!slot.bookable ? 'not-bookable' : null"
            (click)="onSelectSlot(slot)">
            <!-- SUN FOR FIRST AM SLOT AND PM SLOT -->
            <span *ngIf="slot?.isFirstAMTimeSlot" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <img src="./assets/images/icon/Sun.png">
            </span>
            <span *ngIf="slot?.isFirstPMTimeSlot" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <img src="./assets/images/icon/Sundown.png">
            </span>
            <span *ngIf="!slot?.isFirstAMTimeSlot && !slot?.isFirstPMTimeSlot" class="empty-space pr-1"></span>
            <!-- WARNINGS FOR RULE MESSAGES -->
            <span class="pr-1" *ngIf="slot?.messages?.length > 0">
                <div [ngbPopover]="messages"
                     triggers="mouseenter:mouseleave"
                     popoverClass="popover-shadow"
                     [placement]="isFirstSlot ? 'bottom-left' : isLastSlot ? 'top-left' : 'right'"
                     class="fa fa-exclamation-triangle"
                     [ngClass]="{'has-error': !slot?.bookable, 'has-warning': slot?.bookable}">
                </div>
                <ng-template #messages>
                        <div *ngFor="let message of slot.messages; isLast as last">
                            {{message?.name}}
                            <br *ngIf="!last">
                        </div>
                </ng-template>
            </span>
            <span *ngIf="slot?.messages?.length === 0" class="empty-space pr-1"></span>
            <!-- TIME SLOT Normal Slots-->
            <span class="time-container font-weight-bold pr-1" *ngIf="!slotsManagementMdUtils.specialAppointmentBooking"
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.time}}</span>
            <!-- TIME SLOT Special Appointment-->
            <span class="time-container font-weight-bold pr-1" *ngIf="slotsManagementMdUtils.specialAppointmentBooking"
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.timeInterval}}</span>
            <!-- CENTER NAME -->
            <span class="center-container pr-1" [ngClass]="{'op-5': !slot.bookable}">{{slot.center?.name}},</span>
            <!-- RESOURCE NAME Normal Slots-->
            <span class="pr-1" *ngIf=!slotsManagementMdUtils.specialAppointmentBooking
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.resource?.name}}</span>
            <!-- RESOURCE NAME Special Appointment-->
            <span class="pr-1" *ngIf=slotsManagementMdUtils.specialAppointmentBooking
                  [ngClass]="{'op-5': !slot.bookable, 'available': slot.isCurrentlyAvailable, 'not-available': !slot.isCurrentlyAvailable}">
                {{slot.resource?.name}}</span>
            <!-- ADDITIONAL INFORMATION -->
            <app-slot-resource-additional-info [options]="getSlotResourceAdditionalInfoOptions(slot, true, isFirstSlot, isLastSlot)">
            </app-slot-resource-additional-info>
            <!-- SECONDARY RESOURCE -->
            <span *ngIf="slot.secondaryResources?.length === 1" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <!-- NAME -->
                <span class="pr-1">{{slot.secondaryResources[0]?.name}}</span>
                <!-- ADDITIONAL INFORMATION -->
                <app-slot-resource-additional-info [options]="getSlotResourceAdditionalInfoOptions(slot, false, isFirstSlot, isLastSlot)">
                </app-slot-resource-additional-info>
            </span>
            <!-- ONLINE CONSULTATION -->
            <span *ngIf="slot.service?.onlineConsultation" class="pr-1 float-right online-consultation-container"
                  [ngClass]="{'op-5': !slot.bookable}">
                <i class="fas fa-desktop"
                   [ngbPopover]="'label.onlineConsultation' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot.service?.onlineConsultation" class="empty-space pr-1 float-right"></span>
            <!-- IS SELF PAYER -->
            <span *ngIf="slot.isSelfPayer" class="pr-1 float-right" [ngClass]="{'op-5': !slot.bookable}">
                <i class="fas fa-dollar-sign"
                   [ngbPopover]="'label.selfPayer' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot.isSelfPayer" class="empty-space pr-1 float-right"></span>
            <!-- OVERSOLD -->
            <span *ngIf="slot?.oversellingDefinitionId && slotsManagementMdUtils?.expertBookingMode"
                  class="pr-1 float-right"
                  [ngClass]="{'op-5': !slot.bookable}">
                <i class="fa-regular fa-file-invoice-dollar"
                   [ngbPopover]="'label.oversold' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot?.oversellingDefinitionId" class="empty-space pr-1 float-right"></span>
            <!-- SECONDARY RESOURCES ADDITIONAL INFORMATION -->
            <app-slot-resource-additional-info *ngIf="slot?.secondaryResources?.length > 1"
                                               [options]="getSlotResourceAdditionalInfoOptions(slot, false, isFirstSlot, isLastSlot)">
            </app-slot-resource-additional-info>
            <span *ngIf="slot?.secondaryResources?.length === 0" class="empty-space pr-1 float-right"></span>
            <div *ngIf="slotsManagementMdUtils.specialAppointmentBooking"
                 [ngClass]="{'ml-5': slot?.messages?.length > 0, 'ml-4': slot?.messages?.length <= 0 }">
                <span class="pl-1 pr-1" *ngIf="slot.appointmentType?.duration">{{'label.duration' | translate}}
                    : {{slot.appointmentType?.duration}} </span>
                <span class="pl-1 pr-1" *ngIf="!slot.appointmentType?.duration">{{'label.duration' | translate}}
                    : - </span>
                <span class="empty-space pr-1"></span>
                <span class="pr-1" *ngIf="slot.appointmentType?.numberOfSlots">{{'label.quantity' | translate}}
                    : {{slot.appointmentType?.numberOfSlots}} </span>
                <span class="pr-1" *ngIf="!slot.appointmentType?.numberOfSlots">{{'label.quantity' | translate}}
                    : - </span>
            </div>
        </li>
    </ul>
</div>
