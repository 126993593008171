<div class="container-fluid cross-availability-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.crossAvailabilityRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                                                            [contentTemplate]="crossAvailabilityRuleModalFiltersContentTemplate"
                                                            [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                                                            [title]="'label.filterMore'"
                                                            (modalClose)="onModalClose($event)"></sbase-show-template-modal-filter-container>
            </div>

            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #crossAvailabilityRuleModalFiltersContentTemplate>
        <div class="cross-availability-rule-filter-more-modal-container">
            <!-- Area -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.areaOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.area2">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.area, $event)"
                              [buttonName]='"label.area2"'
                              [dependentFilters]="mainDependentFilters?.area"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.areaOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="areaProvider">
                </sbase-filter>
            </div>
            <!-- Channel -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.channelOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.channel">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.channel, $event)"
                              [buttonName]='"label.channel"'
                              [dependentFilters]="mainDependentFilters?.channel"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.channelOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="channelProvider">
                </sbase-filter>
            </div>
            <!-- Type -->
            <div class="rules-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.typeOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.type"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.type' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.typeOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.typeOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'typeOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option *ngFor="let enumValue of crossAvailabilityRuleTypeEnumList" [value]="enumValue">
                        <span>{{'enum.crossAvailabilityRuleType.' + enumValue | translate}}</span>
                    </ng-option>
                </ng-select>
            </div>
            <!-- Rule scope -->
            <div class="rules-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.ruleScopeOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.ruleScope"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.ruleScope' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.ruleScopeOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.ruleScopeOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'ruleScopeOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option *ngFor="let enumValue of ruleScopeEnumList" [value]="enumValue">
                        <span>{{'enum.ruleScope.' + enumValue | translate}}</span>
                    </ng-option>
                </ng-select>
            </div>
            <!-- Center tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.centerTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.centerTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.centerTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.centerTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.centerTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Coverage plan tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.coveragePlanTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.coveragePlanTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.coveragePlanTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.coveragePlanTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.coveragePlanTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Speciality tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.specialityTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.specialityTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.specialityTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.specialityTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.specialityTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Service tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.serviceTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.serviceTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.serviceTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.serviceTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Resource tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.resourceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.resourceTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.resourceTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.resourceTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.resourceTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Availability tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.availabilityTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.availabilityTag">
                </label>
                <sbase-filter
                              (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.availabilityTag, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.availabilityTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.availabilityTagOptions.ngModel"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Patient tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.patientTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.patientTag">
                </label>
                <sbase-filter
                        (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.patientTag, $event)"
                        [buttonName]='"label.newTag"'
                        [dependentFilters]="mainDependentFilters?.patientTag"
                        [initialFilterValues]="rulesUtils.overviewState.modalFilters.patientTagOptions.ngModel"
                        [isMultipleSelectedItem]="false"
                        [useSelectedValueAsLabel]="true"
                        [hideSelectedItems]="true"
                        [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
            <!-- Patient tags exception -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.patientTagExceptionOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.patientTagException">
                </label>
                <sbase-filter
                        (selectedItemsOutput)="onSelectedFilterValue(CrossAvailabilityRuleFiltersEnum.patientTagException, $event)"
                        [buttonName]='"label.newTag"'
                        [dependentFilters]="mainDependentFilters?.patientTagException"
                        [initialFilterValues]="rulesUtils.overviewState.modalFilters.patientTagExceptionOptions.ngModel"
                        [isMultipleSelectedItem]="false"
                        [useSelectedValueAsLabel]="true"
                        [hideSelectedItems]="true"
                        [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active soring -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key | translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-2" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchCrossAvailabilityRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="'CrossAvailabilityRuleCreate'"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="'CrossAvailabilityRuleCreate'"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
                <!-- Table headers -->
                <tr>
                    <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                    <ng-container *ngFor="let column of tableHeaderColumns">
                        <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'"
                            (click)="onSortBy(column.onSortByProperty)">
                            <span *ngIf="column.abbreviatedLabel" translate="{{column.abbreviatedLabel}}"
                                  ngbTooltip="{{column.label | translate}}"
                                  placement="bottom"
                                  container="body">
                            </span>
                            <span *ngIf="!column.abbreviatedLabel" translate="{{column.label}}"></span>
                            <button type="button" class="btn p-0"
                                    *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                                <i class="fas fa-sort"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                                <i class="fas fa-sort-down"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                                <i class="fas fa-sort-up"></i>
                            </button>
                        </th>
                        <th *ngIf="!column.hasSort" [ngClass]="column.class">
                            <span *ngIf="column.abbreviatedLabel" translate="{{column.abbreviatedLabel}}"
                                  ngbTooltip="{{column.label | translate}}"
                                  placement="bottom"
                                  container="body">
                            </span>
                            <span *ngIf="!column.abbreviatedLabel" translate="{{column.label}}"></span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows -->
                <tr *ngFor="let rule of ruleList"
                    [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                    sbaseMarkTableRowOnHover
                    (click)="viewRule(rule)">
                    <td class="name-column">{{rule.crossAvailabilityRuleTable?.name}}</td>
                    <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                    <td class="name-column">{{rule.name}}</td>
                    <td class="name-column">{{rule.priority}}</td>
                    <td class="name-column">{{rule.onlyWarning | yesNoPipe | translate}}</td>
                    <td class="text-center">{{'enum.crossAvailabilityRuleType.' + rule.type | translate}}</td>
                    <td class="text-center">
                        <app-rule-option-tooltip [ruleOption]="rule.channelRuleOption"></app-rule-option-tooltip>
                        {{rule.channel ? ('enum.channel.' + rule.channel | translate) : ''}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.areaRuleOption"></app-rule-option-tooltip>
                        {{rule.area?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.centerTagRuleOption"></app-rule-option-tooltip>
                        {{rule.centerTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.coveragePlanTagRuleOption"></app-rule-option-tooltip>
                        {{rule.coveragePlanTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.specialityTagRuleOption"></app-rule-option-tooltip>
                        {{rule.specialityTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.serviceTagRuleOption"></app-rule-option-tooltip>
                        {{rule.serviceTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.resourceTagRuleOption"></app-rule-option-tooltip>
                        {{rule.resourceTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.availabilityTagRuleOption"></app-rule-option-tooltip>
                        {{rule.availabilityTag?.name}}
                    </td>
                    <td class="name-column">
                        <app-rule-option-tooltip [ruleOption]="rule.patientTagRuleOption"></app-rule-option-tooltip>
                        {{rule.patientTag?.name}}
                    </td>
                    <td class="name-column">{{'enum.revenueOptimizationRuleUnit.' + rule.unit.toLowerCase() | translate}}</td>
                    <td class="name-column">{{rule.value}}</td>
                    <td class="name-column">{{rule.deactivateBefore}}</td>
                    <td class="text-center">{{'enum.ruleScope.' + rule.ruleScope | translate}}</td>
                    <td class="name-column">{{rule.patientTagException?.name}}</td>
                    <td class="name-column">{{rule.message}}</td>
                    <!-- Last column, used for action buttons -->
                    <td class="text-center" (click)="$event.stopPropagation()">
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.view"
                                            (click)="viewRule(rule)"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.edit"
                                            (click)="editRule(rule)"
                                            [activityDisplay]="'CrossAvailabilityRuleUpdate'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="displayConfirmDeleteItemModal(rule.id)"
                                            [activityDisplay]="'CrossAvailabilityRuleDelete'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.changeLog"
                                            (click)="displayChangeLog(rule.id, EntityTypeEnum.crossAvailabilityRule, rule.name)"
                                            [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Page filters -->
    <!-- ============================================================== -->
    <div class="pagination-component mt-4 text-high-contrast" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start" x-placement="bottom-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
    </div>
</div>
