import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {MultiAppointmentBookingMdUtils} from './multi-appointment-booking.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    AppointmentTypeProvider,
    CenterProvider,
    PatientSearchOptionsType,
    PatientSearchResultType,
    PayloadType,
    ServiceProvider,
    SlotProvider,
    SlotsFilterNameEnum,
    SlotsFiltersSelectValueType,
} from 'sked-base';
import {
    SlotDisplayType,
    SlotSearchResponseType,
    SlotType
} from 'sked-base/lib/data-model/slotTypes';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {SlotsFiltersOptionsType} from 'sked-base/lib/components/slots-filters/slots-filters.type';
import {systemConfigKeys} from '../system-config/system-config.constant';
import * as lodash from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MabSlotSearchModalComponent} from './mab-slot-search-modal/mab-slot-search-modal.component';
import {
    AppointmentTypeAndObjectDetailsModalOptions,
    MabAppointmentInformationAdditionalDataType,
    MabAppointmentsInformationOptionsType,
    MabAppointmentsInformationSlotOptionsType,
    MabSlotListOptionsType,
    MabSlotListWrapperOutputType,
    MultiAppointmentBookingReservationType,
    MultiAppointmentBookingSubServiceType,
} from './multi-appointment-booking.types';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {TaskListUtils} from '../task-list/task-list-util';
import {PatientActionsService} from '../../shared/services/patient-actions/patient-actions.service';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {
    PatientAppointmentListUtils
} from '../patient-dashboard/patient-appointment-list/patient-appointment-list.utils';
import {AppointmentListUtils} from '../appointment-list/appointment-list.utils';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {SystemConfigurationType} from 'sked-base/lib/data-model/systemConfigurationTypes';
import {
    MabAppointmentTypeAndObjectDetailsModalComponent
} from './mab-appointment-type-and-object-details-modal/mab-appointment-type-and-object-details-modal.component';
import {SlotsManagementMdUtils} from '../slots-management/slots-management-util';
import {
    MabSlotContinueSearchModalComponent
} from './mab-slot-continue-search-modal/mab-slot-continue-search-modal.component';
import * as moment from 'moment';

@AutoUnsubscribe()
@Component({
    selector: 'app-multi-appointment-booking-md',
    templateUrl: './multi-appointment-booking.component.html',
    styleUrls: ['./multi-appointment-booking.component.scss']
})
export class MultiAppointmentBookingComponent implements OnInit, OnDestroy {
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    selectedPatient: PatientSearchResultType;
    patientSearchOptions: PatientSearchOptionsType;
    slotsFiltersOptions: SlotsFiltersOptionsType;
    displayLocalFilters = false;
    displayMultiAppointmentBooking = false;
    slotsFiltersExternalUpdatePatientEmitter: EventEmitter<PatientType> = new EventEmitter<PatientType>();
    private userInfoStorage;
    private lastSearchedFilterValues: SlotsFiltersSelectValueType[];

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public configDataService: ConfigDataService,
        private slotProvider: SlotProvider,
        private modalService: NgbModal,
        private centerProvider: CenterProvider,
        private appointmentTypeProvider: AppointmentTypeProvider,
        private serviceProvider: ServiceProvider,
        private previousRouteService: PreviousRouteService,
        private taskListUtils: TaskListUtils,
        private patientActionsService: PatientActionsService,
        public patientContextService: PatientContextService,
        private patientAppointmentListUtils: PatientAppointmentListUtils,
        private appointmentListUtils: AppointmentListUtils,
        private translatedLanguageService: TranslatedLanguageService,
        private slotsManagementUtils: SlotsManagementMdUtils,
    ) {
    }

    ngOnInit() {
        // Push state comingFromRoute to bookAppointment if previous route is bookAppointment to remember the state
        const previousUrl = this.previousRouteService.getPreviousUrl();
        if (previousUrl === '/bookMultiAppointment') {
            history.pushState({
                comingFromRoute: 'bookMultiAppointment',
                ...(!!history?.state?.bookingSuccessful || this.multiAppointmentBookingMdUtils.bookingSuccessful
                    ? {bookingSuccessful: true} : {})
            }, '');
        }
        this.multiAppointmentBookingMdUtils.bookingSuccessful = false;
        // Get user from storage
        this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        this.displayMultiAppointmentBooking = !!this.userInfoStorage?.team?.organizationalRole;
        if (!this.displayMultiAppointmentBooking) {
            return;
        }
        this.loadSearchTimeWindowSystemConfigVariables();
        this.loadOtherSystemConfigVariables();
        // Clear cache state if coming from a successful booking
        if (!!history?.state?.bookingSuccessful) {
            // On successful booking delete everything except the patient
            this.multiAppointmentBookingMdUtils.multiAppointmentBookingState = {
                patientSearchOptions: this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions,
            };
            this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.forEach(list => {
                list.specialAppointmentBooking = false;
            });
            this.multiAppointmentBookingMdUtils.reservedSlotsOptions = this.multiAppointmentBookingMdUtils.getInitialReservedSlotsOptions();
        }
        // Load options for components
        this.loadPatientSearchOptions();
        this.loadSlotsFiltersOptions();
        this.loadSlotsCalendarOptions();
        this.loadSlotsResultsWrapperOptions();
        this.loadReservedSlotsOptions();
        this.loadSlotsLocalFiltersOptions();
    }

    ngOnDestroy(): void {
    }

    //PATIENT
    onSelectedPatient(selectedPatient: { $value: PayloadType }) {
        this.selectedPatient = selectedPatient?.$value?.value;
        this.patientSearchOptions.initialSelectedPatient = this.selectedPatient;
        this.patientActionsService.patientId = this.selectedPatient?.id; // used in wait list modal
        // Save the loaded patient in the state, to be reused as initialSelectedPatient
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions.initialSelectedPatient = this.selectedPatient;
        // Clear all data from screen and cache
        this.clearLocalFiltersSlotResultsAndCalendarData();
        // Emit external patient change for filters
        this.slotsFiltersExternalUpdatePatientEmitter.next(selectedPatient?.$value?.value as PatientType);
        this.patientContextService.patient = this.selectedPatient;
    }

    // BOOKING FILTERS
    onSearch(filterValues: SlotsFiltersSelectValueType[]) {
        // If there already is the maximum number of lists on screen, close the filters, display a toaster and disable the search button
        if (
            this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.length
            >= this.multiAppointmentBookingMdUtils.maximumMultiAppointmentBookings
        ) {
            this.messagesService.error('label.removeASlotListBeforeSearchingAnother', true);
            return;
        }
        this.patientActionsService.slotsFilters = filterValues; // used for wait list modal
        // this.calculateSlotSearchMaxTimeWindowValue(filterValues); // Don't reset these values anymore
        // this.clearLocalFiltersSlotResultsAndCalendarData();
        this.openSlotSearchModal(filterValues, this.selectedPatient);
    }

    // CALENDAR
    onSlotsCalendarSelectValue(): void {
        // Keep the state of the calendar up to date
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsCalendarOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsCalendarOptions);
        // Reset scrollTop of list containers
        this.multiAppointmentBookingMdUtils.resetSlotResultsScrollTop();
        this.multiAppointmentBookingMdUtils.resetSlotResultsSliceUpperBound();
        // Update list wrapper display lists
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotsListsOptions: MabSlotListOptionsType) => {
            this.multiAppointmentBookingMdUtils.loadSlotsOnSelectedDay(slotsListsOptions.listId);
        });
    }

    onSlotsCalendarContinueSearching() {
        this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage = true;
        this.openSlotSearchModalForContinueSearch();
    }

    // LOCAL FILTERS: AM / PM / SELF PAYER
    onSelectedLocalFilters() {
        // Keep the state of the local filters up to date
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsLocalFiltersOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions);
        // Filter the grouped slots and Update the calendar
        this.updateInnerSlotsArraysAndAdjustCalendarOptions();
        // Update the display slots
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotsListOptions: MabSlotListOptionsType) => {
            this.multiAppointmentBookingMdUtils.loadSlotsOnSelectedDay(slotsListOptions.listId);
        });
        // Update slots state
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsListsWrapperOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions);
    }

    // LIST WRAPPER
    onSelectSlot({slot, listId}: MabSlotListWrapperOutputType): void {
        // If clicked on a marked slot, open the object details modal
        if (slot.marked) {
            this.multiAppointmentBookingMdUtils.openObjectDetailsModal(slot);
            return;
        }
        // If already selected a slot from this list, do nothing
        const foundList = lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
        if (foundList.hasReservation) {
            return;
        }
        // If patient not selected, show error
        if (!this.patientContextService.patient?.id) {
            this.messagesService.error('toastr.error.patientNotSelected', true);
            return;
        }
        // Otherwise open the appointment type + object details modal
        const listOptions = lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
        if (!listOptions) {
            return;
        }
        this.openAppointmentTypeAndObjectDetailsModal(slot, listId, listOptions.specialAppointmentBooking, listOptions.numberOfSlots);
    }

    onBookAppointments(reservations: MultiAppointmentBookingReservationType[]): void {
        this.loadAppointmentInformationOptions(reservations);
        this.loadAppointmentInformationOverviewOptions();
        this.router.navigate(['/bookMultiAppointment']);
    }

    onSlotsListRemoved(listId: number): void {
        // Remove reservations related to the selected slots in this list
        const reservedSlotsWithListId: MultiAppointmentBookingReservationType[] = lodash.filter(
            this.multiAppointmentBookingMdUtils.reservedSlotsOptions?.reservations ?? [],
            {listId}
        );
        reservedSlotsWithListId?.forEach((reservedSlot: MultiAppointmentBookingReservationType) => {
            this.multiAppointmentBookingMdUtils.removeReservedSlot(reservedSlot.slot, reservedSlot.listId);
        });

        // If removed the only list, reset local filters, slot results and calendar data
        if (this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.length === 0) {
            this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage = false;
            this.clearLocalFiltersSlotResultsAndCalendarData(false);
            return;
        }

        // Otherwise, adjust number of slots in calendar
        this.multiAppointmentBookingMdUtils.adjustCalendarOptionsAfterFirstSearch();
        // Adjust local filters (update appointment types list)
        this.multiAppointmentBookingMdUtils.loadLocalFiltersOptions(false);
    }

    onCalendarCollapse(value?: boolean) {
        if (!this.multiAppointmentBookingMdUtils.slotsCalendarOptions?.areOptionsAfterSearch) {
            return;
        }
        this.multiAppointmentBookingMdUtils.isCalendarCollapsed = value === undefined
            ? !this.multiAppointmentBookingMdUtils.isCalendarCollapsed
            : value;
    }

    openAppointmentTypeAndObjectDetailsModal(
        slot: SlotDisplayType, listId: number, specialBooking: boolean = false, numberOfSlots: number = 1,
    ) {
        const modalOptions = this.multiAppointmentBookingMdUtils.getAppointmentTypeAndObjectDetailsModalOptions();
        const modalRef = this.modalService.open(MabAppointmentTypeAndObjectDetailsModalComponent, modalOptions);
        modalRef.componentInstance.options = {
            listId,
            slot,
            specialBooking,
            numberOfSlots,
            objectDetailsOptions: this.multiAppointmentBookingMdUtils.getObjectDetailsModalOptionsObject(slot)
        } as AppointmentTypeAndObjectDetailsModalOptions;

        modalRef.result.then(() => {
            // Add reservation and slot to reserved items
            const slotCopy = lodash.cloneDeep(slot);
            this.multiAppointmentBookingMdUtils.addReservedSlot(slotCopy, listId, specialBooking);
            // Reset the appointment type back to the selected local filters value
            slot.appointmentType = this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selectedAppointmentType;
        }, () => {
            // If appointment type is not selected in local filters, remove the appointment type of the slot
            if (!this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selectedAppointmentType) {
                delete slot.appointmentType;
            }
        });
    }

    filtersAreLoaded(areFiltersLoaded: boolean) {
        if (areFiltersLoaded) {
            // If
            //  1. we shouldn’t forget the state (ie. we come on this page by backing from booking), and
            //  2. a patient is already in context
            // we send the selected patient to the slots filters
            if (this.shouldRememberState(history?.state?.comingFromRoute)) {
                setTimeout(() => {
                    this.slotsFiltersExternalUpdatePatientEmitter.next(this.selectedPatient as PatientType);
                });
            }
            // If patient is in context, update patient externally for slot filters
            if (!!this.patientContextService.patient) {
                this.selectedPatient = this.patientContextService.patient;
                setTimeout(() => {
                    this.slotsFiltersExternalUpdatePatientEmitter.next(this.patientContextService.patient as PatientType);
                });
            }
        }
    }

    private clearLocalFiltersSlotResultsAndCalendarData(cancelReservations: boolean = true) {
        // Clear all data from local filters, slot results and calendar
        this.displayLocalFilters = false;
        if (cancelReservations) {
            // Cancel all reservations
            this.multiAppointmentBookingMdUtils.reservedSlotsOptions?.reservations?.forEach((
                reservedSlot: MultiAppointmentBookingReservationType
            ) => {
                this.multiAppointmentBookingMdUtils.removeReservedSlot(reservedSlot.slot, reservedSlot.listId);
            });
        }
        // Reset options
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions = this.multiAppointmentBookingMdUtils.getInitialSlotsListsWrapperOptions();
        this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions = this.multiAppointmentBookingMdUtils.getInitialSlotsLocalFiltersOptions();
        this.multiAppointmentBookingMdUtils.slotsCalendarOptions = this.multiAppointmentBookingMdUtils.getInitialCalendarOptions(1);
        this.multiAppointmentBookingMdUtils.isCalendarCollapsed = false;
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsLocalFiltersOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions);
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsCalendarOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsCalendarOptions);
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsListsWrapperOptions =
            lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions);
    }

    private loadPatientSearchOptions() {
        // If coming back from bookAppointment and patient search options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.multiAppointmentBookingMdUtils.multiAppointmentBookingState?.patientSearchOptions) {
            // For time efficiency, deep clone properties without messagesService and configDataService
            const {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay} =
                this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions;
            this.patientSearchOptions = lodash.cloneDeep(
                {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay,
                    allowToNavigateToPatientDashboardRoute: true}
            );
            this.patientSearchOptions.messagesService = this.messagesService;
            this.patientSearchOptions.configDataService = this.configDataService;
            this.selectedPatient = this.multiAppointmentBookingMdUtils.multiAppointmentBookingState
                .patientSearchOptions.initialSelectedPatient as PatientSearchResultType;
        } else {
            // Otherwise initialize the options and save them to the state
            this.patientSearchOptions = {
                ...this.patientContextService.getPatientSearchOptions(),
                actionsButtonsToDisplay: {
                    hideAddMultiAppointment: true,
                }
            };
            // For time efficiency, deep clone properties without messagesService and configDataService
            const {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay} = this.patientSearchOptions;
            this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions = lodash.cloneDeep(
                {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay}
            );
            this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions.messagesService = this.messagesService;
            this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions.configDataService = this.configDataService;
        }
    }

    private loadSlotsFiltersOptions() {
        // If
        //  1. coming back from successful booking, or
        //  2. shouldn't remember state, or
        //  3. we are in flow for task list booking / rescheduling, then
        // we also send shouldForgetOptions = true
        this.slotsFiltersOptions = {
            configDataServiceInstance: this.configDataService,
            messagesServiceInstance: this.messagesService,
            shouldForgetOptions: !!history?.state?.bookingSuccessful || !this.shouldRememberState(history?.state?.comingFromRoute),
            isPatientExternal: true,
            showOutsideAvailabilityButton: false,
            ...((this.previousRouteService.getPreviousUrl() === '/slotsManagement' && !!this.slotsManagementUtils.actualFilterValues)
                ? {initialFiltersValues: this.getInitialFilterValuesFromSlotsManagement(this.slotsManagementUtils.actualFilterValues)}
                : {})
        } as SlotsFiltersOptionsType;
    }

    private getInitialFilterValuesFromSlotsManagement(filterValues: SlotsFiltersSelectValueType[]): { [key in SlotsFilterNameEnum]?: any } {
        const values: { [key in SlotsFilterNameEnum]?: any } = {};
        filterValues?.forEach((filterValue: SlotsFiltersSelectValueType) => {
            values[SlotsFilterNameEnum[filterValue.name]] = filterValue.value;
        });
        const service = (values[SlotsFilterNameEnum.Service])?.hasOwnProperty('service')
            ? values[SlotsFilterNameEnum.Service]?.service
            : values[SlotsFilterNameEnum.Service];
        const serviceId = service?.id;
        const area = values[SlotsFilterNameEnum.Area];
        const areaId = area?.id;
        const coveragePlan = values[SlotsFilterNameEnum['Coverage Plan']];
        const resource = values[SlotsFilterNameEnum.Resource];
        const resourceId = resource?.id;
        const center = values[SlotsFilterNameEnum.Center];
        const centerId = center?.id;
        const subServices: MultiAppointmentBookingSubServiceType[] =
            values[SlotsFilterNameEnum.Service]?.subServices as any as MultiAppointmentBookingSubServiceType[];
        const otherKeys: SlotsFilterNameEnum[] = [
            SlotsFilterNameEnum.Distance,
            SlotsFilterNameEnum['Number of Adjacent Slots'],
            SlotsFilterNameEnum['Expert Booking'],
            SlotsFilterNameEnum['Not Bookable Slots'],
            SlotsFilterNameEnum['Special Appointment Booking'],
        ];
        let otherValuesMap = {};
        otherKeys.map((key: SlotsFilterNameEnum) => {
            otherValuesMap = {
                ...otherValuesMap,
                ...(values[key] ? {[key]: values[key]} : {}),
            };
        });
        return {
            ...(!!area
                ? {
                    [SlotsFilterNameEnum.Area]: {
                        id: areaId,
                        ...area,
                    }
                }
                : {}),
            ...(!!coveragePlan
                ? {
                    [SlotsFilterNameEnum['Coverage Plan']]: {
                        ...coveragePlan, // Coverage plan already has the id property if the object exists
                    }
                }
                : {}),
            ...(!!service
                ? {
                    [SlotsFilterNameEnum.Service]: {
                        ...service,
                        id: serviceId,
                        ...(subServices?.length > 0 ? {
                            subServices: subServices.map((subService: MultiAppointmentBookingSubServiceType) => ({
                                subServiceId: subService.subServiceId,
                                id: subService.subServiceId,
                                shortId: (subService as any)?.subService?.shortId ?? subService?.shortId,
                                duration: (subService as any)?.subService?.duration ?? subService?.duration,
                                code: (subService as any)?.subService?.code ?? (subService as any)?.code,
                                name: (subService as any)?.subService?.name ?? subService?.name,
                            }))
                        } : {}),
                    }
                }
                : {}),
            ...(!!resource
                ? {
                    [SlotsFilterNameEnum.Resource]: {
                        ...resource,
                        id: resourceId,
                    }
                }
                : {}),
            ...(!!center
                ? {
                    [SlotsFilterNameEnum.Center]: {
                        ...center,
                        id: centerId,
                    }
                }
                : {}),
            ...otherValuesMap,
        } as { [key in SlotsFilterNameEnum]?: any};
    }

    private loadSlotsCalendarOptions() {
        // If coming back from bookAppointment and slots calendar options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.multiAppointmentBookingMdUtils.multiAppointmentBookingState?.slotsCalendarOptions) {
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions =
                lodash.cloneDeep(this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsCalendarOptions);
        } else {
            // Otherwise initialize the options and save them to the state
            this.multiAppointmentBookingMdUtils.slotsCalendarOptions = this.multiAppointmentBookingMdUtils.getInitialCalendarOptions(1);
            this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsCalendarOptions =
                lodash.cloneDeep(this.multiAppointmentBookingMdUtils.slotsCalendarOptions);
        }
    }

    private loadSlotsResultsWrapperOptions() {
        // If coming back from bookAppointment and slots results options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.multiAppointmentBookingMdUtils.multiAppointmentBookingState?.slotsListsWrapperOptions) {
            this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions =
                lodash.cloneDeep(this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsListsWrapperOptions);
        } else {
            // Otherwise initialize with empty array
            this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions = this.multiAppointmentBookingMdUtils.getInitialSlotsListsWrapperOptions();
        }
        // Reset slice upper bound
        this.multiAppointmentBookingMdUtils.resetSlotResultsSliceUpperBound();
    }

    private loadReservedSlotsOptions() {
        // If coming back from bookMultiAppointments and reserved slots is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.multiAppointmentBookingMdUtils.multiAppointmentBookingState?.reservedSlotsOptions) {
            this.multiAppointmentBookingMdUtils.reservedSlotsOptions =
                lodash.cloneDeep(this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.reservedSlotsOptions);
        } else {
            // Otherwise initialize the reserved slots
            this.multiAppointmentBookingMdUtils.reservedSlotsOptions = this.multiAppointmentBookingMdUtils.getInitialReservedSlotsOptions();
        }
    }

    private loadSlotsLocalFiltersOptions() {
        // If coming back from bookMultiAppointments and slots local filters options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.multiAppointmentBookingMdUtils.multiAppointmentBookingState?.slotsLocalFiltersOptions) {
            this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions =
                lodash.cloneDeep(this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.slotsLocalFiltersOptions);
            this.displayLocalFilters = this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.displaySlotsLocalFilters;
        } else {
            // Otherwise initialize the local filters
            this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions = this.multiAppointmentBookingMdUtils.getInitialSlotsLocalFiltersOptions();
        }
    }

    private loadAppointmentInformationOptions(reservations: MultiAppointmentBookingReservationType[]) {
        this.multiAppointmentBookingMdUtils.appointmentInformationOptions = {
            patient: this.selectedPatient as PatientType,
            displayPrintButtons: false,
            readOnly: false,
            defaultPhoneNumberCountryCode: this.libPhoneUtil.getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage),
            slotsOptions: reservations.map((reservation: MultiAppointmentBookingReservationType) => ({
                slot: {
                    ...reservation.slot,
                    reservationId: reservation.reservationId,
                },
                additionalData: {
                    expertBooking: lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions
                        ?.slotsListsOptions ?? [], {listId: reservation.listId})?.expertBookingMode,
                    isOutsideAvailabilityBooking: false,
                    specialAppointmentBooking: lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions
                        ?.slotsListsOptions ?? [], {listId: reservation.listId})?.specialAppointmentBooking,
                    specialityName: lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions
                        ?.slotsListsOptions ?? [], {listId: reservation.listId})?.specialityName,
                } as MabAppointmentInformationAdditionalDataType,
            } as MabAppointmentsInformationSlotOptionsType)),
        } as unknown as MabAppointmentsInformationOptionsType;
        // We need to update the etag after the patient is updated
        if (this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions?.patient?.etag) {
            this.multiAppointmentBookingMdUtils.appointmentInformationOptions.patient.etag =
                this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.patient.etag;
        }
    }

    private loadAppointmentInformationOverviewOptions() {
        this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions =
            lodash.clone(this.multiAppointmentBookingMdUtils.appointmentInformationOptions);
        this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.readOnly = true;
        this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.displayPrintButtons =
            this.configDataService.activeActivities.indexOf('ViewAppointmentBulletinPdf') !== -1;
    }

    private openSlotSearchModal(filterValues: SlotsFiltersSelectValueType[], patient: PatientSearchResultType) {
        const modalOptions = this.multiAppointmentBookingMdUtils.getSlotSearchModalOptions();
        const modalRef = this.modalService.open(MabSlotSearchModalComponent, modalOptions);
        modalRef.componentInstance.options = {filterValues, patient};
        this.lastSearchedFilterValues = lodash.cloneDeep(filterValues);

        modalRef.result.then((response) => {
            this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage = true;
            this.processSlotsResponse(response);
            // Navigate to next calendar page if required
            if (this.multiAppointmentBookingMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch) {
                this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage += 1;
                this.multiAppointmentBookingMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch = false;
            }
            // After a search, if the current page still has disabled days, we start another search automatically
            const hasReachedTimeWindowMax = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch >= this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum;
            if (this.doesCurrentCalendarPageHaveDisabledDays() && !hasReachedTimeWindowMax) {
                this.onSlotsCalendarContinueSearching();
            }
        }, () => {
        });
    }

    private openSlotSearchModalForContinueSearch() {
        const modalOptions = this.multiAppointmentBookingMdUtils.getSlotSearchModalOptions();
        const modalRef = this.modalService.open(MabSlotContinueSearchModalComponent, modalOptions);
        modalRef.componentInstance.options = {patient: this.selectedPatient};

        modalRef.result.then((response: { slotsResponse: SlotSearchResponseType, listId: number }[]) => {
            this.processSlotsResponsesOnContinueSearching(response);
            // Navigate to next calendar page if required
            if (this.multiAppointmentBookingMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch) {
                this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage += 1;
                this.multiAppointmentBookingMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch = false;
            }
            // After a search, if the current page still has disabled days, we start another search automatically
            const hasReachedTimeWindowMax = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch >= this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum;
            if (this.doesCurrentCalendarPageHaveDisabledDays() && !hasReachedTimeWindowMax) {
                this.onSlotsCalendarContinueSearching();
            }
        }, () => {
        });
    }

    private doesCurrentCalendarPageHaveDisabledDays(): boolean {
        if (this.multiAppointmentBookingMdUtils.slotsCalendarOptions?.currentPage === undefined) {
            return false;
        }
        const currentPage = this.multiAppointmentBookingMdUtils.slotsCalendarOptions
            .calendarPages[this.multiAppointmentBookingMdUtils.slotsCalendarOptions.currentPage];
        if (!currentPage?.calendarDays) {
            return false;
        }
        for (const day of currentPage.calendarDays) {
            // Don't count disabled days before today
            if (day.isDisabled && day.momentDate?.isAfter(moment())) {
                return true;
            }
        }
        return false;
    }

    private updateInnerSlotsArraysAndAdjustCalendarOptions() {
        // Filter the grouped slots
        this.updateGroupedSlotsForAllListsConsideringActiveLocalFilters();
        // Update the calendar
        this.multiAppointmentBookingMdUtils.adjustCalendarOptionsAfterFirstSearch();
    }

    private updateGroupedSlotsForAllListsConsideringActiveLocalFilters() {
        if (!this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions) {
            return;
        }
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions.slotsListsOptions?.forEach((slotsListOptions: MabSlotListOptionsType) => {
            let filteredRawSlots = slotsListOptions.rawSlots;
            // Filter by AM / PM
            filteredRawSlots = this.multiAppointmentBookingMdUtils.filterSlotsByAMPM(filteredRawSlots);
            // Filter by Appointment type
            filteredRawSlots = this.multiAppointmentBookingMdUtils.filterSlotsByAppointmentType(filteredRawSlots);
            // Filter by Include self payer
            filteredRawSlots = this.multiAppointmentBookingMdUtils.filterSlotsByIncludeSelfPayer(filteredRawSlots);
            // Update grouped slots
            slotsListOptions.groupedSlots = lodash.groupBy(filteredRawSlots, (slot) => slot.date);
        });
    }

    private processSlotsResponse(slotsResponse: SlotSearchResponseType) {
        const noSlotsFound = slotsResponse.slots?.length === 0;

        const expertBookingMode = lodash.find(this.lastSearchedFilterValues, {name: SlotsFilterNameEnum['Expert Booking']})?.value;
        const specialAppointmentBooking =
            lodash.find(this.lastSearchedFilterValues, {name: SlotsFilterNameEnum['Special Appointment Booking']})?.value;
        const slotsExtraDetails = slotsResponse.slotsExtraDetails;
        const wasResourceUsedForSearch = !!lodash.find(this.lastSearchedFilterValues, {name: SlotsFilterNameEnum.Resource});
        const numberOfSlotsString = lodash.find(
            this.lastSearchedFilterValues,
            {name: SlotsFilterNameEnum['Number of Adjacent Slots']}
        )?.value;
        const numberOfSlots = numberOfSlotsString ? parseInt(numberOfSlotsString, 10) : 1;
        const rawSlots: SlotType[] = slotsResponse.slots;
        const groupedSlots = lodash.groupBy(rawSlots, (slot) => slot.date);
        const isFirstSearch = this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.isEmpty;
        const serviceValue = lodash.find(this.lastSearchedFilterValues, {name: SlotsFilterNameEnum.Service})?.value;
        const specialityName = serviceValue?.specialityName ?? serviceValue?.service?.specialityName ?? '';
        const serviceName = slotsExtraDetails?.services[0]?.name ?? serviceValue?.service?.name ?? serviceValue?.name ?? '';
        const filterValues = lodash.cloneDeep(this.lastSearchedFilterValues);

        const listId = this.multiAppointmentBookingMdUtils.addListToSlotsListsWrapperAndReturnListId(
            expertBookingMode,
            specialAppointmentBooking,
            slotsExtraDetails,
            rawSlots,
            groupedSlots,
            noSlotsFound,
            wasResourceUsedForSearch,
            numberOfSlots,
            specialityName,
            serviceName,
            filterValues
        );
        this.displayLocalFilters = true;
        if (isFirstSearch) {
            this.multiAppointmentBookingMdUtils.loadCalendarOptions();
        } else {
            this.multiAppointmentBookingMdUtils.adjustCalendarOptionsAfterFirstSearch();
        }
        this.multiAppointmentBookingMdUtils.loadLocalFiltersOptions(isFirstSearch);
        if (!isFirstSearch) {
            this.updateInnerSlotsArraysAndAdjustCalendarOptions();
        }
        // Update list wrapper display lists
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotsListsOptions: MabSlotListOptionsType) => {
            this.multiAppointmentBookingMdUtils.loadSlotsOnSelectedDay(slotsListsOptions.listId);
        });
    }

    private processSlotsResponseOnContinueSearching(slotsResponse: SlotSearchResponseType, listId: number) {
        if (!this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.length) {
            return;
        }
        const foundList = lodash.find(this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
        if (!foundList) {
            return;
        }

        // Combine previous slot response with current one
        const previousSlotsExtraDetails = foundList?.slotsExtraDetails;
        const idF = (item) => item.id;
        foundList.slotsExtraDetails = {
            numberOfSkippedCenters: previousSlotsExtraDetails?.numberOfSkippedCenters,
            availabilities: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.availabilities, slotsResponse?.slotsExtraDetails?.availabilities), idF),
            centers: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.centers, slotsResponse?.slotsExtraDetails?.centers), idF),
            resources: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.resources, slotsResponse?.slotsExtraDetails?.resources), idF),
            services: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.services, slotsResponse?.slotsExtraDetails?.services), idF),
            coverageplans: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.coverageplans, slotsResponse?.slotsExtraDetails?.coverageplans), idF),
            messages: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.messages, slotsResponse?.slotsExtraDetails?.messages), idF),
            appointmentTypes: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.appointmentTypes, slotsResponse?.slotsExtraDetails?.appointmentTypes), idF),
            subServices: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.subServices, slotsResponse?.slotsExtraDetails?.subServices), idF),
            oversellingDefinitions: lodash.uniqBy(lodash.concat(
                previousSlotsExtraDetails?.oversellingDefinitions, slotsResponse?.slotsExtraDetails?.oversellingDefinitions), idF),
            type: previousSlotsExtraDetails?.type,
        };

        // Adjust slots properties (rawSlots, groupedSlots) with the combined slot responses
        foundList.rawSlots?.push(...slotsResponse.slots);
        foundList.groupedSlots = lodash.groupBy(foundList.rawSlots, (slot) => slot.date);
        foundList.noSlotsFound = foundList.rawSlots?.length === 0;
    }

    private processSlotsResponsesOnContinueSearching(responses: {slotsResponse: SlotSearchResponseType, listId: number}[]) {
        // Update grouped slots and list wrapper options for each list
        responses?.forEach(({slotsResponse, listId}) => {
            this.processSlotsResponseOnContinueSearching(slotsResponse, listId);
        });

        // Add calendar days, load local filters
        this.multiAppointmentBookingMdUtils.addCalendarDaysForContinueSearching();
        this.multiAppointmentBookingMdUtils.loadLocalFiltersOptions(false);
        this.updateInnerSlotsArraysAndAdjustCalendarOptions();

        // Update list wrapper display lists
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotsListsOptions: MabSlotListOptionsType) => {
            this.multiAppointmentBookingMdUtils.loadSlotsOnSelectedDay(slotsListsOptions.listId);
        });
    }

    private loadSearchTimeWindowSystemConfigVariables() {
        // Load timeWindowMaximum
        const timeWindowMaximumValueString =
            lodash.find(this.configDataService?.systemConfig?.value, {name: systemConfigKeys.MULTI_APPOINTMENT_BOOKING_CALENDAR_SLOT_SEARCH_MAX_TIME_WINDOW})?.value;
        const timeWindowMaximumValue = this.generalUtils.getNumberFromStringOtherwiseUndefined(timeWindowMaximumValueString);
        if (timeWindowMaximumValue !== undefined) {
            this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum = timeWindowMaximumValue;
        }
        // Load multiAppointmentBookingMaxTimeWindow
        const multiAppointmentBookingMaxTimeWindowValueString = lodash.find(this.configDataService?.systemConfig?.value,
            {name: systemConfigKeys.SLOT_SEARCH_SERVICE_STEP})?.value;
        const multiAppointmentBookingMaxTimeWindowValue =
            this.generalUtils.getNumberFromStringOtherwiseUndefined(multiAppointmentBookingMaxTimeWindowValueString);
        if (multiAppointmentBookingMaxTimeWindowValue !== undefined) {
            this.multiAppointmentBookingMdUtils.mabSearchStep = multiAppointmentBookingMaxTimeWindowValue;
        }
    }

    private loadOtherSystemConfigVariables() {
        // Maximum multi appointment bookings
        const systemConfigList = this.configDataService.systemConfig?.value ?? [];
        this.multiAppointmentBookingMdUtils.maximumMultiAppointmentBookings =
            lodash.find(systemConfigList, {name: 'MaximumMultiAppointmentBookings'})?.value;

        // Reservation life span
        const systemConfigValues: SystemConfigurationType[] = this.configDataService.systemConfig?.value;
        const reservationLifeSpanObject = this.generalUtils.getSystemConfigValue(systemConfigValues, 'ReservationLifeTimes', true);
        const mabReservationLifeSpan = this.generalUtils.getNumberFromStringOtherwiseUndefined(reservationLifeSpanObject['MultiAppointmentBooking']);
        this.multiAppointmentBookingMdUtils.mabReservationLifeSpanInMinutes = mabReservationLifeSpan ?? 5;
    }

    private shouldRememberState(comingFromRoute: string): boolean {
        if (!comingFromRoute) {
            return false;
        }
        return comingFromRoute === 'bookMultiAppointment';
    }
}
