import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ConfirmDeleteModalService,
    EntityTypeEnum,
    MultiResourceCombinationProvider,
    MultiResourceCombinationType,
    ResourceDependentFiltersType,
    ResourceProvider,
    ResourceType,
    ResourceTypeProvider,
    ResourceTypeType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {MultiResourceCombinationMdUtils} from './multi-resource-combination-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {forkJoin, Observable} from 'rxjs';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-multi-resource-combination-md',
    templateUrl: './multi-resource-combination-md.component.html',
    styleUrls: ['./multi-resource-combination-md.component.scss']
})
export class MultiResourceCombinationMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    multiResourceCombinationMdList: MultiResourceCombinationType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    VIEW_ACTIVITY_ENABLED = false;
    readonly MULTI_RESOURCE_COMBINATION = 'MultiResourceCombination';
    readonly CONSTANTS = constants;
    resourceTypes: ResourceTypeType[];
    resourceDependentFilters: ResourceDependentFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public confirmDeleteService: ConfirmDeleteModalService,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public multiResourceCombinationProvider: MultiResourceCombinationProvider,
        public ngxLoader: NgxUiLoaderService,
        public resourceProvider: ResourceProvider,
        public router: Router,
        public multiResourceCombinationMdUtils: MultiResourceCombinationMdUtils,
        private resourceTypeProvider: ResourceTypeProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('MultiResourceCombinationActivityRead');
        this.resourceDependentFilters = this.multiResourceCombinationMdUtils.getResourceDependentFilters();

        if (this.previousRouteService.getPreviousUrl() !== '/createMultiResourceCombination') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadInitialData();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create MultiResourceCombination
    createMultiResourceCombination(): void {
        this.router.navigate(['/createMultiResourceCombination']);
    }

    editMultiResourceCombination(multiResourceCombination: MultiResourceCombinationType) {
        this.router.navigate(['/createMultiResourceCombination'], {
            state: {
                multiResourceCombination,
                action: this.CONSTANTS.EDIT
            }
        });
    }

    viewMultiResourceCombination(multiResourceCombination: MultiResourceCombinationType) {
        this.router.navigate(['/createMultiResourceCombination'], {
            state: {
                multiResourceCombination,
                action: this.CONSTANTS.VIEW
            }
        });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.multiResourceCombinationMdUtils.tableFilters.currentPage = page;
        this.loadMultiResourceCombinationMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.multiResourceCombinationMdUtils.tableFilters.currentPage = 1;
        this.multiResourceCombinationMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadMultiResourceCombinationMdData(false);
    }

    onSelectedResourceFilter(resourceFilterList: ResourceType[]) {
        this.multiResourceCombinationMdUtils.initialResourceFilterValues = resourceFilterList;
        this.multiResourceCombinationMdUtils.tableFilters.filter.resource =
            (resourceFilterList && resourceFilterList.length > 0) ? resourceFilterList[0].id : undefined;
        this.loadMultiResourceCombinationMdData();
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.multiResourceCombinationMdUtils.tableFilters.orderBy[property];
        if (this.multiResourceCombinationMdUtils.tableFilters.orderBy) {
            this.multiResourceCombinationMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadMultiResourceCombinationMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.multiResourceCombinationMdUtils.tableFilters.orderBy[selectedItem];
        this.loadMultiResourceCombinationMdData(false);
    }

    displayConfirmDeleteItemModal(multiResourceCombinationId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.multiResourceCombinationProvider, multiResourceCombinationId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(multiResourceCombinationId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    getResourceNameAndTypeToDisplay(multiResourceCombination: MultiResourceCombinationType, isMainResource: boolean): string[] {
        let resources;
        resources = [{resource: multiResourceCombination.items.find(item => item.isMainResource).resource}];
        if (!isMainResource) {
            resources = multiResourceCombination.items.filter(item => !item.isMainResource);
        }
        return resources.map(item => {
            // if resource has no type associated with it, returns only its name
            return this.getResourceTypeNameForId(item.resource.resourceTypeId) ?
                item.resource.name + ' (' + this.getResourceTypeNameForId(item.resource.resourceTypeId) + ')'
                : item.resource.name;
        });
    }

    private getResourceTypeNameForId(resourceTypeId: string): string {
        return this.resourceTypes.find(resourceType => resourceTypeId === resourceType.id)?.name;
    }

    private loadInitialData() {
        this.ngxLoader.start();
        forkJoin([
            this.getMultiResourceCombinationRequest(),
            this.getResourceTypeRequest()
        ]).subscribe(([multiResourceCombinations, resourceTypes]) => {
            this.resourceTypes = resourceTypes.value;
            this.multiResourceCombinationMdList = multiResourceCombinations.value;
            if (multiResourceCombinations.count !== undefined && multiResourceCombinations.count !== null) {
                this.totalTableItems = multiResourceCombinations.count;
            }
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private getResourceTypeRequest(): Observable<{ value: ResourceTypeType[]; count?: number; }> {
        return this.resourceTypeProvider.getEntries({}).pipe(take(1));
    }

    private getMultiResourceCombinationRequest(includeCount: boolean = true): Observable<{ value: MultiResourceCombinationType[]; count?: number }> {
        const queryFilter = this.multiResourceCombinationMdUtils.getQueryFilterForMultiResourceCombinationMD(
            this.multiResourceCombinationMdUtils.tableFilters, includeCount);
        return this.multiResourceCombinationProvider.getEntries(queryFilter).pipe(take(1));
    }

    // function to get MultiResourceCombination data
    private loadMultiResourceCombinationMdData(includeCount: boolean = true) {
        this.ngxLoader.start();
        this.getMultiResourceCombinationRequest(includeCount)
            .subscribe(({value, count}) => {
                this.multiResourceCombinationMdList = value;
                if (count !== undefined && count !== null) {
                    this.totalTableItems = count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadMultiResourceCombinationMdData(true);
    }
    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.multiResourceCombinationMdUtils.tableFilters = this.multiResourceCombinationMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.multiResourceCombinationMdUtils.initialResourceFilterValues = [];
        }
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.multiResourceCombinationMdUtils.tableFilters = this.multiResourceCombinationMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.multiResourceCombinationProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadMultiResourceCombinationMdData();
                this.messagesService.success('toastr.success.multiResourceCombinationDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
