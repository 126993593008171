import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {DataStoreMDComponent} from './data-store-md.component';
import {CreateDataStoreComponent} from './create-data-store/create-data-store.component';

@NgModule({
    declarations: [DataStoreMDComponent, CreateDataStoreComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class DataStoreMDModule { }
