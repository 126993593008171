import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {
    AppointmentProvider,
    AppointmentStatusEnum,
    DateRangeResponseType,
    DateRangeType,
    GenericFilterResultType, JobProvider
} from 'sked-base';
import {ExportAppointmentsUtils} from './export-appointments.utils';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {
    AppointmentListFilterWrapperInitialValuesType
} from '../appointment-list/appointment-list-filters/appointment-list-filters.types';

@Component({
    selector: 'app-export-appointments',
    templateUrl: './export-appointments.component.html',
    styleUrls: ['./export-appointments.component.scss']
})
export class ExportAppointmentsComponent implements OnInit, AfterViewInit {
    isPrintPdfButtonEnabled: boolean;
    appointmentStatusEnumList = Object.keys(AppointmentStatusEnum);
    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR =
        '#export-appointments-filters-container sbase-filter-wrapper .filter-wrapper-component';

    constructor(public exportAppointmentUtils: ExportAppointmentsUtils,
                private jobProvider: JobProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private dateTimeUtils: DateTimeUtils,
                private translatedLanguageService: TranslatedLanguageService,
                private appointmentProvider: AppointmentProvider) {
    }

    ngOnInit(): void {
        this.isPrintPdfButtonEnabled = false;
        this.loadInitialValues();
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    // Filter values changed
    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues).forEach((filterName: string) => {
            this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues[filter.name] = filter.value;
        }
        this.validatePrintPdfButton();
        // Validate
        this.updateFiltersValidation();
    }

    // Filter values changed
    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions.fromDate = dateRange.fromDate;
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions.toDate = dateRange.toDate;
        this.validateDateRange(dateRange.isValid);
        this.validatePrintPdfButton();
    }

    // Reset filters action
    onClear() {
        // Reset select filters
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues.status = undefined;
        // Reset initial validations and date range options
        this.resetValidationsAndDateRanges();
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
        // Disable print PDF button
        this.isPrintPdfButtonEnabled = false;
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.exportAppointmentUtils.exportAppointmentsFilterOptions[option].hasOwnProperty('$ngOptionValue')) {
            this.exportAppointmentUtils.exportAppointmentsFilterOptions[option] = undefined;
        }
    }

    onGenerateExportAppointments() {
        const queryFilter = this.exportAppointmentUtils.getQueryFilterForExportAppointmentsPdf();
        this.ngxLoader.start();
        this.jobProvider.exportAppointments(queryFilter).subscribe(() => {
            this.messagesService.success('label.generateExportWaitMessageGoToExports');
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    onGetAppointmentsPdf() {
        const queryFilter = this.exportAppointmentUtils.getQueryFilterForAppointmentsPdf();
        this.ngxLoader.start();
        this.appointmentProvider.getAppointmentsPdf(queryFilter).subscribe((response) => {
            this.processFileResponse(response);
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private processFileResponse(response): void {
        const downloadUrl = URL.createObjectURL(response.file);
        // open in new tab
        window.open(downloadUrl, '_blank');
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#export-appointments-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    // Validations
    private updateFiltersValidation() {
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.areFiltersValid =
            this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterValidations.dateRange.isValid;
    }

    private validateDateRange(isValidInner: boolean = true) {
        const {fromDate, toDate} = this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions;
        const dateFromMoment = fromDate ? moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format() : undefined;
        const dateToMoment = toDate ? moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).format() : undefined;
        const isFromDateBeforeOrEqualWithToDate = moment(dateFromMoment).isSameOrBefore(dateToMoment);
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterValidations.dateRange = {
            isValid: isValidInner && !!fromDate && !!toDate && isFromDateBeforeOrEqualWithToDate,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    private validatePrintPdfButton() {
        // If we have a resource or a center and date range is valid enable print pdf button
        const {location, resource} = this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues;
        this.isPrintPdfButtonEnabled = (!!location || !!resource) &&
            this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterValidations.dateRange.isValid;
    }

    // Load data
    private resetValidationsAndDateRanges() {
        // Reset validations
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterValidations = this.exportAppointmentUtils.getInitialFilterValidations();
        // Reset options for date ranges
        this.loadOptionsForDateRange();
        // Reset values for search event
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues =
            this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterWrapperInitialValues)
                : {} as AppointmentListFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.updateFiltersValidation();
    }

    private loadOptionsForDateRange() {
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions = this.exportAppointmentUtils.getInitialDateRangeOptions();
        this.updateDateRangeValue.next({
            fromDate: this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions.fromDate,
            toDate: this.exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions.toDate
        } as DateRangeType);
        this.validateDateRange();
    }

    private loadInitialValues() {
        // Reset filter options
        this.exportAppointmentUtils.exportAppointmentsFilterOptions = this.exportAppointmentUtils.getEmptyExportAppointmentsFiltersOptions();
        // Load options for the filter wrapper component
        this.exportAppointmentUtils.exportAppointmentsFilterOptions.filterWrapperOptions = this.exportAppointmentUtils.getFilterWrapperOptions();
        // Load initial validations and date ranges options
        this.resetValidationsAndDateRanges();
    }
}
