import {Component, OnInit, OnDestroy, EventEmitter} from '@angular/core';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ChangeLogsType, EntityTypeEnum} from 'sked-base';
import {ChangeLogsFiltersType, EntityFilterOptionsType, EntityIdFilterOptionType} from './change-log-md.types';
import {ChangeLogsOptionsType} from '../../shared/component/change-logs/change-logs-types';

@AutoUnsubscribe()
@Component({
    selector: 'app-change-log-md',
    templateUrl: './change-log-md.component.html',
    styleUrls: ['./change-log-md.component.scss']
})
export class ChangeLogMDComponent implements OnInit, OnDestroy {
    changeLogs: ChangeLogsType[] = [];
    filters: ChangeLogsFiltersType = {} as ChangeLogsFiltersType;
    isFilterCollapsed = false;
    areFiltersValid = false;
    changeLogOptions: ChangeLogsOptionsType = {} as ChangeLogsOptionsType;
    getChangeLog: EventEmitter<ChangeLogsOptionsType> = new EventEmitter<ChangeLogsOptionsType>();
    EntityTypeList = Object.values(EntityTypeEnum);

    constructor(
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.filters = this.getInitialFilters();
    }

    getInitialFilters(): ChangeLogsFiltersType {
        return {
            entityId: {
                ngModel: undefined as EntityIdFilterOptionType
            } as EntityIdFilterOptionType,
            entityType: {
                ngModel: undefined as EntityFilterOptionsType
            } as EntityFilterOptionsType
        } as ChangeLogsFiltersType;
    }

    onClear() {
        this.filters = this.getInitialFilters();
    }

    onSearch() {
        this.areFiltersValid = !!(this.filters.entityId.ngModel && this.filters.entityType.ngModel);
        if (this.areFiltersValid) {
            this.changeLogOptions.entityTypeId = this.filters.entityId.ngModel;
            this.changeLogOptions.entityType = this.filters.entityType.ngModel;
            this.getChangeLog.emit(this.changeLogOptions);
        }
    }

    ngOnDestroy(): void {
    }
}
