export const roomReservationConstants = {
    RESOURCE_OVERVIEW: 'resourceOverview',
    ROOM_OVERVIEW: 'roomOverview',
    WEEKLY: 'weekly',
    SINGLE_INSTANCE: 'SingleInstance',
    ALL_INSTANCES: 'AllInstances',
    UNTIL_END_DATE: 'UntilEndDate',
    THIS_INSTANCE: 'ThisInstance',
    THIS_AND_FOLLOWING_INSTANCES: 'ThisAndFollowingInstances',
    THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE: 'ThisAndFollowingInstancesTillEndDate',
    DELETE_ORIGINAL_RESERVATION: 'DeleteOriginalReservation',
    KEEP_ORIGINAL_RESERVATION: 'KeepOriginalReservation',
    SPLIT: 'split',
    SHRINK: 'shrink'
};
