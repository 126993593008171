import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {CoverageCompanyType, CoverageCompanyProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CoverageCompanyMdUtils} from '../coverage-company-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-coverage-company',
    templateUrl: './create-coverage-company.component.html',
    styleUrls: ['./create-coverage-company.component.scss']
})
export class CreateCoverageCompanyComponent implements OnInit, OnDestroy {
    constants = constants;
    initialCoverageCompany: CoverageCompanyType;
    coverageCompanyItem: CoverageCompanyType = {} as CoverageCompanyType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    isTemplateValid: boolean;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private coverageCompanyMdUtils: CoverageCompanyMdUtils,
        public coverageCompanyProvider: CoverageCompanyProvider,
        public router: Router,
        public generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveCoverageCompanyData(coverageCompany: CoverageCompanyType) {
        this.isTemplateValid = this.validateFields(coverageCompany);
        if (this.isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createCoverageCompany(coverageCompany);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialCoverageCompany, coverageCompany)) {
                    this.messagesService.success('toastr.success.coverageCompanyEdit', true);
                    this.goToParentPage();
                } else {
                    this.editCoverageCompany(this.initialCoverageCompany, coverageCompany);
                }
            }
        }
    }

    onInputChanged(coverageCompany: CoverageCompanyType) {
        this.isTemplateValid = this.validateFields(coverageCompany);
    }

    goToParentPage() {
        this.router.navigate(['/coverageCompanies']);
    }

    goToEdit() {
        history.replaceState({coverageCompany: this.coverageCompanyItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
    }

    // function to create the new CoverageCompany
    private createCoverageCompany(coverageCompany: CoverageCompanyType) {
        this.ngxLoader.start();
        this.coverageCompanyProvider.addEntry(coverageCompany)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newCoverageCompanyAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the CoverageCompany
    private editCoverageCompany(oldCoverageCompany: CoverageCompanyType, newCoverageCompany: CoverageCompanyType) {
        this.ngxLoader.start();
        this.coverageCompanyProvider.updateEntry(oldCoverageCompany, newCoverageCompany)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.coverageCompanyEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(coverageCompanyItem: CoverageCompanyType): boolean {
        return !!(coverageCompanyItem && coverageCompanyItem.name && coverageCompanyItem.patientPortalPlace);
    }

    private setupInitialState() {
        if (history.state && history.state.coverageCompany) {
            this.initialCoverageCompany = history.state.coverageCompany;
            this.coverageCompanyItem = lodash.cloneDeep(history.state.coverageCompany);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.coverageCompanyItem = this.coverageCompanyMdUtils.getInitialCoverageCompany();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.validateFields(this.coverageCompanyItem);
    }
}
