<div class="change-log-modal">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold">
            {{'label.changeLog' | translate}}
            <ng-container *ngIf="entityName && !entityTranslationKey"> {{'label.for' | translate}} {{entityName}}</ng-container>
            <ng-container *ngIf="entityTranslationKey"> {{'label.for' | translate}} {{entityTranslationKey | translate}}</ng-container>
        </h4>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body custom-scrollbar pt-0">
        <app-change-logs
            [options]="changeLogsOptions"
        ></app-change-logs>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                translate="label.close"
                (click)="activeModal.dismiss()">
        </button>
    </div>
</div>
