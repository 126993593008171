import {TableFiltersType} from '../../../data-model/general.type';
import {AppointmentType} from 'sked-base';
import {
    PatientAppointmentListFiltersOptionsType,
    PatientAppointmentListFiltersSearchType
} from './patient-appointment-list-filters/patient-appointment-list-filters.types';

export enum AppointmentTypeEnum {
    Upcoming = 'Upcoming',
    Past = 'Past',
    Cancelled = 'Cancelled',
}

export interface PatientAppointmentListStateType {
    filtersOptions?: PatientAppointmentListFiltersOptionsType;
    patientAppointmentListItemOptions?: PatientAppointmentListItemOptionsType;
    patientAppointmentItemDetailsOptions?: PatientAppointmentDetailsOptionsType;
}

export interface PatientAppointmentListRequestFilters {
    searchFilters: PatientAppointmentListFiltersSearchType;
    pageFilters: TableFiltersType;
}

export interface PatientAppointmentListItemOptionsType {
    isBeforeSearchState: boolean;
    isNotFoundState: boolean;
    showItemsPerPageDropdown: boolean;
    itemsPerPageList: number[];
    patientAppointmentListRequestFilters: PatientAppointmentListRequestFilters;
    latestPatientAppointmentListRequestFilters: PatientAppointmentListRequestFilters;
    totalPatientAppointmentItems: number;
    appointmentList: AppointmentType[];
}

export interface PatientAppointmentDetailsOptionsType {
    appointment: AppointmentType;
}
