<div class="schedule-occurrences-modal">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold">
            {{'label.occurrences' | translate}}
        </h4>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
        <div class="occurrences-table-container flex-grow-1">
            <div class="row mx-0 occurrences-table-container-row overflow-auto custom-scrollbar">
                <table class="table table-bordered grey-table">
                    <thead class="thead-light">
                    <tr>
                        <td translate="label.service2"></td>
                        <td translate="label.targetDate"></td>
                        <td translate="label.enqueueDate"></td>
                        <td translate="label.status"></td>
                    </tr>
                    </thead>
                    <tbody *ngIf="!occurrences?.length">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                    <tbody *ngFor="let occurrence of occurrences">
                    <tr class="text-dark">
                        <td>
                            <span class="font-weight-bold" *ngIf="occurrence.specialityName">{{occurrence.specialityName}} </span>
                            <span *ngIf="occurrence.serviceName">{{occurrence.serviceName}}</span>
                        </td>
                        <td>
                            <span *ngIf="occurrence.targetDate">
                                {{occurrence.targetDate | dateMMddyyyyFormatPipe}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="occurrence.enqueueDate">
                                {{occurrence.enqueueDate | dateMMddyyyyFormatPipe}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="occurrence.statusTranslated">
                                {{occurrence.statusTranslated}}
                            </span>
                            <span *ngIf="!occurrence.statusTranslated && occurrence.statusCode">
                                {{occurrence.statusCode}}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                translate="label.close"
                (click)="activeModal.dismiss()">
        </button>
    </div>
</div>
