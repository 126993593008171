import {constants} from '../../../shared/constants/constants';
import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AreaProvider,
    AreaType,
    ChannelProvider,
    ChannelType,
    ConfirmDeleteModalService,
    EntityTypeEnum,
    FileProvider,
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import * as lodash from 'lodash';
import {map, take} from 'rxjs/operators';
import {AreaMdUtil} from '../area-md.util';
import {MessagesService} from '../../../shared/services/messages.service';
import {Router} from '@angular/router';
import {ScreenTemplateLayoutType, TableFiltersType} from '../../../data-model/general.type';
import {BackofficeAreaMDType} from '../area-md.types';
import {BackofficeChannelType} from '../../../data-model/channel.types';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {UploadFileOptionsType} from '../../../shared/component/upload-file/upload-file.types';
import {UploadFileUtils} from '../../../shared/component/upload-file/upload-file.utils';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-area',
    templateUrl: './create-area.component.html',
    styleUrls: ['./create-area.component.scss']
})
export class CreateAreaComponent implements OnInit, OnDestroy {
    initialAreaMD: AreaType;
    VIEW = constants.VIEW;
    constants = constants;
    areaMDItem: BackofficeAreaMDType = {} as BackofficeAreaMDType;
    isTemplateValid: boolean;
    tableFilters: TableFiltersType;
    channelRestrictions: Array<any> = [];
    channels: BackofficeChannelType[] = [];
    screenTemplateLayout: ScreenTemplateLayoutType;
    uploadFileOptions: UploadFileOptionsType;

    constructor(
        public areaMdUtil: AreaMdUtil,
        public areaProvider: AreaProvider,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public messagesService: MessagesService,
        public http: HttpClient,
        public channelProvider: ChannelProvider,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public uploadFileUtils: UploadFileUtils,
        private fileProvider: FileProvider,
        private translatedLanguageService: TranslatedLanguageService
    ) {
    }

    ngOnInit() {
        this.setInitialSetup();
    }

    ngOnDestroy(): void {
    }

    // function to get the channel list
    getChannelsList() {
        this.channelProvider.getEntries(this.translatedLanguageService.translatedLanguage)
            .pipe(take(1),
                map((result) => {
                    const response = result.value;
                    return this.addSelectedValue(response);
                }))
            .subscribe((response) => {
                this.channels = response;
                if (this.initialAreaMD && this.initialAreaMD.channelRestrictions && this.initialAreaMD.channelRestrictions.length > 0) {
                    this.channels = this.updateChannelRestrictions(response, this.initialAreaMD.channelRestrictions);
                }
            });
    }

    // get services data and display into table
    getAreaMDPosition() {
        const queryFilter = this.areaMdUtil.getQueryFilterForPosition(this.tableFilters);
        this.ngxLoader.start();
        this.areaProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.areaMDItem.position = response.value[0].position + 1;
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onChannelRestrictionChange(channel: BackofficeChannelType) {
        if (channel.selected) {
            this.areaMDItem.channelRestrictions.push(channel);
        } else {
            lodash.remove(this.areaMDItem.channelRestrictions, (item) => {
                return item.enumValue === channel.enumValue;
            });
        }
    }

    onInputChanged(areaMD: BackofficeAreaMDType) {
        this.isTemplateValid = this.areFieldsValid(areaMD);
    }

    // function to send data to provider
    saveAreaMDData(areaMD: BackofficeAreaMDType) {
        this.isTemplateValid = this.areFieldsValid(areaMD);
        if (this.isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createAreaMD(areaMD);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialAreaMD, areaMD) && lodash.isEqual(this.uploadFileUtils.initialUploadedFile, this.uploadFileUtils.uploadedFile)) {
                    this.messagesService.success('toastr.success.areaEdit', true);
                    this.goToParentPage();
                } else {
                    this.editAreaMD(this.initialAreaMD, areaMD);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/areas']);
    }

    goToEdit() {
        history.replaceState({areaMD: this.areaMDItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
    }

    // function to create new area record
    createAreaMD(areaMD: BackofficeAreaMDType) {
        this.ngxLoader.start();
        this.areaProvider.addEntry(this.getAreaForSend(areaMD))
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.area);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.areaCreated', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update exist area record
    editAreaMD(oldAreaMD: AreaType, newAreaMD: BackofficeAreaMDType) {
        this.ngxLoader.start();
        this.areaProvider.updateEntry(oldAreaMD, this.getAreaForSend(newAreaMD))
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.area);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.areaEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private areFieldsValid(areaMDItem: BackofficeAreaMDType): boolean {
        return !!((areaMDItem && areaMDItem.name) && (areaMDItem && areaMDItem.position));
    }

    private updateChannelRestrictions(channelsList: BackofficeChannelType[], selectedChannels: ChannelType[]): BackofficeChannelType[] {
        for (const item of channelsList) {
            if (lodash.find(selectedChannels, {channel: item.enumValue})) {
                item.selected = true;
            }
        }
        return channelsList;
    }

    private addSelectedValue(channels: ChannelType[]): BackofficeChannelType[] {
        return lodash.map(channels, (channel) => {
            channel.selected = false;
            return channel;
        });
    }

    private getAreaForSend(area: BackofficeAreaMDType): AreaType {
        let newArea: AreaType = {} as AreaType;
        // @ts-ignore
        newArea = area;

        return newArea;
    }

    private setInitialSetup() {
        this.tableFilters = this.areaMdUtil.getInitialTableFilter();
        if (history.state && history.state.areaMD) {
            this.initialAreaMD = history.state.areaMD;
            this.initialAreaMD.channelRestrictions =
                this.initialAreaMD.channelRestrictions.map((channel) => ({...channel, enumValue: channel.channel}));
            this.areaMDItem = lodash.cloneDeep(history.state.areaMD);
            this.uploadFileUtils.getUploadedFile(this.areaMDItem.id, EntityTypeEnum.area);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.areaMDItem.channelRestrictions = this.areaMdUtil.setSelectedPropertyForChannels(this.areaMDItem.channelRestrictions);
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.areaMDItem = this.areaMdUtil.getInitialArea();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            this.getAreaMDPosition();
        }
        this.uploadFileOptions = {
            entityType: EntityTypeEnum.area,
            disabled: this.screenTemplateLayout.action === constants.VIEW,
            itemId: this.areaMDItem?.id,
            labelText: 'label.iconForPatientAccess'
        };
        this.isTemplateValid = this.areFieldsValid(this.areaMDItem);
        this.getChannelsList();
    }
}
