import {Injectable} from '@angular/core';
import {PatientControlRuleExceptionType, PatientType} from 'sked-base/lib/data-model/patientTypes';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ClientAgreementType} from 'sked-base/lib/data-model/clientAgreementTypes';
import {systemConfigKeys} from '../../system-config/system-config.constant';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {PatientClientAgreementsEnum} from './create-patient-overview/create-patient-overview.types';
import {
    DynamicFormInputTemplateEnum,
    DynamicFormOptionsType,
    DynamicSelectInputDataType
} from '../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {formControlNames} from '../../../shared/constants/formControlNames';
import {CoveragePlanType} from 'sked-base/lib/data-model/coveragePlanTypes';
import {
    AddressType,
    CountryType,
    ObjectTagType,
    ODataQueryObjectType, PatientAccountStatusEnum, PatientMPIType, PatientProvider,
    RegionType, VerifyPatientType
} from 'sked-base';
import {LocalityType} from 'sked-base/lib/data-model/localityTypes';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {TagsType} from 'sked-base/lib/data-model/tagTypes';
import {PatientExternalKeyType} from 'sked-base/lib/data-model/patientExternalKeyTypes';
import {Filter} from 'sked-base/lib/data-model/oDataObjectTypes';
import {ActionType, PhoneNumberType} from '../../../data-model/general.type';
import {of, Subject} from 'rxjs';
import {PatientExceptionsType, PatientTimeDependentTagsType} from '../patient-dashboard.types';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {PhoneNumberUtil} from 'google-libphonenumber';

@Injectable({
    providedIn: 'root'
})
export class CreatePatientUtils {
    defaultCountryCode = lodash.find(this.configDataService?.systemConfig?.value,
        {name: systemConfigKeys.DEFAULT_COUNTRY})?.value;
    clientIdentificationAvailableDocumentTypes: string[] = [];
    clientIdentificationCountries: CountryType[];
    addressRegions: RegionType[];
    addressLocalities: LocalityType[];
    initialPatient: PatientType;
    patientControlExceptions: PatientExceptionsType[] = [];
    patientTimeDependentTags: PatientTimeDependentTagsType[] = [];
    tagsExclusionList: string[] = [];
    childComponentRequests = new Subject<boolean>();
    childComponentRequestsDone: string[] = [];
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

    constructor(
        private generalUtils: GeneralUtils,
        private configDataService: ConfigDataService,
        private tenantCustomizingService: TenantCustomizingService,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private patientProvider: PatientProvider,
    ) {
    }

    verifyPatientDocumentNumberAndMPI(patientForm: FormGroup) {
        const clientIdentificationForm = patientForm.get('clientIdentification') as FormGroup;
        const {value} = clientIdentificationForm;
        const documentCountry = this.clientIdentificationCountries
            ?.find(country => country.countryName === value[formControlNames.DOCUMENT_COUNTRY]);
        const clientIdentification: VerifyPatientType = {
            documentCountry: documentCountry?.countryCode,
            documentType: value[formControlNames.DOCUMENT_TYPE],
            documentValue: value[formControlNames.DOCUMENT_NUMBER]
        };
        const patientMPI: PatientMPIType = {
            documentType: clientIdentification.documentType,
            documentCountry: clientIdentification.documentCountry,
            documentNumber: clientIdentification.documentValue
        };
        this.resetDataBeforeVerifyPatient(patientForm);
        this.ngxLoader.start();
        this.patientProvider.verifyPatient(clientIdentification).pipe(
            catchError(err => {
                // Add error to document number input
                clientIdentificationForm.get(formControlNames.DOCUMENT_NUMBER).setErrors(
                    {error: {priority: 5, message: 'label.error.invalidDocumentNumber', shouldTranslateMessage: true}});
                clientIdentificationForm.markAllAsTouched();
                return of(null);
            }),
            tap(({status}) => {
                if (status === PatientAccountStatusEnum.Exists) {
                    clientIdentificationForm.controls[formControlNames.DOCUMENT_NUMBER].setErrors(
                        {error: {priority: 5, message: 'label.error.patientExist', shouldTranslateMessage: true}}
                    );
                    clientIdentificationForm.markAllAsTouched();
                }
            }),
            mergeMap(({status}) => {
                if (status === PatientAccountStatusEnum.Exists) {
                    return of(null);
                } else {
                    return this.patientProvider.searchForPatientInMPI(patientMPI);
                }
            })
        ).subscribe((patient) => {
            if (patient) {
                this.prefillPatientDataFromMPI(patient, patientForm);
            }
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
        });
    }

    resetDataBeforeVerifyPatient(patientForm: FormGroup) {
        // Set initial address country (by default)
        const defaultAddressCountry = this.clientIdentificationCountries?.find(({countryCode}) =>
            countryCode.toUpperCase() === this.defaultCountryCode.toUpperCase());
        // Set initial phone number country code based on language
        const countryCodeForRegion = this.libPhoneUtil.getCountryCodeForRegion(this.defaultCountryCode);
        patientForm.patchValue({
            [formControlNames.FIRST_NAME1]: '',
            [formControlNames.FIRST_NAME2]: '',
            [formControlNames.LAST_NAME1]: '',
            [formControlNames.LAST_NAME2]: '',
            [formControlNames.BIRTHDATE]: '',
            [formControlNames.EMAIL]: '',
            [formControlNames.GENDER]: '',
            [formControlNames.REMARKS]: '',
            addressInformation: {
                [formControlNames.COUNTRY]: defaultAddressCountry.countryName,
                [formControlNames.REGION]: '',
                [formControlNames.ADDRESS_LOCALITY]: '',
                [formControlNames.ADDRESS_STREET]: '',
                [formControlNames.ADDRESS_STREET_NUMBER]: '',
                [formControlNames.ADDRESS_FLOOR]: '',
                [formControlNames.ADDRESS_APARTMENT_NUMBER]: '',
                [formControlNames.ADDRESS_ZIP_CODE]: '',
            },
            mainPhoneNumber: {
                [formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]: countryCodeForRegion,
                [formControlNames.MAIN_PHONE_NUMBER]: '',
            },
            alternatePhoneNumber: {
                [formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]: countryCodeForRegion,
                [formControlNames.ALTERNATE_PHONE_NUMBER]: '',
            }
        });
    }

    prefillPatientDataFromMPI(patient: PatientType, patientForm: FormGroup) {
        const birthdate = patient?.birthDate ? moment.utc(patient.birthDate).format(moment.localeData().longDateFormat('L')) : '';
        const addressCountry: CountryType = this.clientIdentificationCountries?.find(
            (country: CountryType) => country?.id === patient?.countryId
        );
        const mainPhoneNumber: PhoneNumberType = this.generalUtils.parsePhoneNumber(patient?.mainPhoneNumber);
        const alternatePhoneNumber: PhoneNumberType = this.generalUtils.parsePhoneNumber(patient?.alternatePhoneNumber);
        patientForm.patchValue({
            [formControlNames.FIRST_NAME1]: patient?.firstName1,
            [formControlNames.FIRST_NAME2]: patient?.firstName2,
            [formControlNames.LAST_NAME1]: patient?.lastName1,
            [formControlNames.LAST_NAME2]: patient?.lastName2,
            [formControlNames.BIRTHDATE]: birthdate,
            [formControlNames.EMAIL]: patient?.email,
            [formControlNames.GENDER]: patient?.gender,
            [formControlNames.REMARKS]: patient?.remarks,
            addressInformation: {
                [formControlNames.COUNTRY]: addressCountry?.countryName ?? null,
                [formControlNames.REGION]: patient?.region?.name,
                [formControlNames.ADDRESS_LOCALITY]: patient?.address?.locality,
                [formControlNames.ADDRESS_STREET]: patient?.address?.street,
                [formControlNames.ADDRESS_STREET_NUMBER]: patient?.address?.streetNumber,
                [formControlNames.ADDRESS_FLOOR]: patient?.address?.floor,
                [formControlNames.ADDRESS_APARTMENT_NUMBER]: patient?.address?.apartmentNumber,
                [formControlNames.ADDRESS_ZIP_CODE]: patient?.address?.zipCode,
            },
            mainPhoneNumber: {
                [formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]: mainPhoneNumber?.countryCode,
                [formControlNames.MAIN_PHONE_NUMBER]: mainPhoneNumber?.phoneNumber,
            },
            alternatePhoneNumber: {
                [formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]: alternatePhoneNumber?.countryCode,
                [formControlNames.ALTERNATE_PHONE_NUMBER]: alternatePhoneNumber?.phoneNumber,
            }
        });
    }

    getCreatePatientOverviewStep(): number {
        const isCreatePatientExceptionsActive = this.isCreatePatientExceptionsActive();
        const isTimeDependentTagsActive = this.isTimeDependentTagsActive();
        if (!isCreatePatientExceptionsActive && !isTimeDependentTagsActive) {
            return 3;
        }
        if ((isCreatePatientExceptionsActive && !isTimeDependentTagsActive) || (!isCreatePatientExceptionsActive && isTimeDependentTagsActive)) {
            return 4;
        }
        if (isCreatePatientExceptionsActive && isTimeDependentTagsActive) {
            return 5;
        }
    }

    isCreatePatientExceptionsActive(): boolean {
        const isPatientControlRuleExceptionActivityActive = this.configDataService.isActivityActive('PatientControlRuleException');
        const isRuleControlPatientFeatureActive = this.configDataService.isFeatureActive('rule-control-patient');

        return isPatientControlRuleExceptionActivityActive && isRuleControlPatientFeatureActive;
    }

    isTimeDependentTagsActive(): boolean {
        return this.configDataService.isFeatureActive('patient-tags-timedependent');
    }

    getGenderSelectData(): DynamicSelectInputDataType[] {
        const systemConfigList = this.configDataService.systemConfig?.value ?? [];
        const allowedGenderList = [];
        if (lodash.find(systemConfigList, {name: 'GenderAllowedMale'})?.value === 'true') {
            allowedGenderList.push({
                value: 'Male',
                text: 'enum.gender.Male',
                shouldTranslate: true
            } as DynamicSelectInputDataType);
        }
        if (lodash.find(systemConfigList, {name: 'GenderAllowedFemale'})?.value === 'true') {
            allowedGenderList.push({
                value: 'Female',
                text: 'enum.gender.Female',
                shouldTranslate: true
            } as DynamicSelectInputDataType);
        }
        if (lodash.find(systemConfigList, {name: 'GenderAllowedUndetermined'})?.value === 'true') {
            allowedGenderList.push({
                value: 'Undetermined',
                text: 'enum.gender.Undetermined',
                shouldTranslate: true
            } as DynamicSelectInputDataType);
        }
        if (lodash.find(systemConfigList, {name: 'GenderAllowedUnknown'})?.value === 'true') {
            allowedGenderList.push({
                value: 'Unknown',
                text: 'enum.gender.Unknown',
                shouldTranslate: true
            } as DynamicSelectInputDataType);
        }
        return allowedGenderList;
    }

    getPatientQueryFilter(): ODataQueryObjectType {
        const queryFilter: ODataQueryObjectType = {} as ODataQueryObjectType;
        queryFilter.expand = [
            {CoveragePlans: {select: ['Id', 'Name', 'CoverageCompanyId', 'IsPrivate', 'System', 'MainCoveragePlan']}},
            {Country: {select: ['Id', 'CountryCode', 'CountryName']}},
            {Region: {select: ['Id', 'Name']}},
            {ClientAgreements: {select: ['Id', 'Version', 'Cancelled']}},
            {PatientExternalKeys: {select: ['Id', 'Origin', 'Key']}},
            {PatientControlExceptions: {select: ['Id', 'ServiceId', 'SpecialtyId', 'ResourceId', 'ValidTo']}}
        ];
        return queryFilter;
    }

    areFormControlsValid(form: FormGroup, formControlKeys: string[]): boolean {
        if (!form?.controls) {
            return false;
        }

        // This method splits the key strings by `->` to reach inner form group controls
        // Example: addressInformation->Address.Street
        let isValid = true;
        formControlKeys.forEach((key: string) => {
            const parts = key.split('->');
            if (parts?.length === 1) {
                isValid = isValid && this.isFormControlValidDisabledOrInvisible(form.controls[key], parts[0]);
            }
            if (parts?.length === 2) {
                isValid = isValid && this.isFormControlValidDisabledOrInvisible((form.controls[parts[0]] as FormGroup)?.controls[parts[1]], parts[1]);
            }
        });
        return isValid;
    }

    isFormControlValidDisabledOrInvisible(formControl: AbstractControl, key: string): boolean {
        // In reactive forms, a field can either be valid OR disabled. However, in our cases, if
        // a field is disabled then it is also valid, so for our validations we check both of these cases.
        // Similarly, if a field is not visible it is also valid.
        if (!formControl) {
            return false;
        }
        // For MainCoveragePlan and SecondaryCoveragePlans we need to check the property CoveragePlans because in tenant config
        // we have CoveragePlans property
        if (key === formControlNames.MAIN_COVERAGE_PLAN || key === formControlNames.SECONDARY_COVERAGE_PLANS) {
            key = formControlNames.COVERAGE_PLANS;
        }
        return formControl.valid || !this.tenantCustomizingService.isVisible(key) || formControl.disabled;
    }

    getPatientDocumentType(countryCode: string) {
        return countryCode === this.defaultCountryCode ? 'NationalId' : 'Passport';
    }

    mapPatientFormToPatientType(patientForm: FormGroup, initialPatient?: PatientType, actionType?: ActionType): PatientType {
        // If you make changes in this method make sure creating and editing a patient still works correctly especially for the modified field.
        const {value} = patientForm;
        // In case client identification fields are disabled, they won't appear on the value property
        value.clientIdentification = {
            [formControlNames.NO_DOCUMENT]: initialPatient?.noDocument,
            [formControlNames.DOCUMENT_COUNTRY]: lodash.find(
                this.clientIdentificationCountries ?? [],
                {countryCode: initialPatient?.documentCountry}
            )?.countryName ?? '',
            [formControlNames.DOCUMENT_TYPE]: initialPatient?.documentType,
            [formControlNames.DOCUMENT_NUMBER]: initialPatient?.documentNumber,
            ...(value.clientIdentification ?? {}),
        };
        const clientAgreements: ClientAgreementType[] = this.getPatientClientAgreements(patientForm);
        const address = this.mapPatientAddress(patientForm, initialPatient, actionType);
        const mainPhoneNumberValue = value.mainPhoneNumber[formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]
        && value.mainPhoneNumber[formControlNames.MAIN_PHONE_NUMBER]
            ? `+${value.mainPhoneNumber[formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]}${value.mainPhoneNumber[formControlNames.MAIN_PHONE_NUMBER]}`
            : null;
        const alternatePhoneNumberValue = value.alternatePhoneNumber[formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]
        && value.alternatePhoneNumber[formControlNames.ALTERNATE_PHONE_NUMBER]
            ? `+${value.alternatePhoneNumber[formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]}${value.alternatePhoneNumber[formControlNames.ALTERNATE_PHONE_NUMBER]}`
            : null;
        const coveragePlans: CoveragePlanType[] = value?.coveragePlans ? [
            ...(value?.coveragePlans[formControlNames.MAIN_COVERAGE_PLAN] ?? []),
            ...(value?.coveragePlans[formControlNames.SECONDARY_COVERAGE_PLANS] ?? []),
        ] : null;
        const tags = this.mapPatientTags(patientForm);
        const noDocument: boolean = initialPatient?.noDocument === null && !value.clientIdentification[formControlNames.NO_DOCUMENT]
            ? null
            : !!value.clientIdentification[formControlNames.NO_DOCUMENT];
        const documentCountry: CountryType = lodash.find(
            this.clientIdentificationCountries ?? [],
            {countryName: value.clientIdentification[formControlNames.DOCUMENT_COUNTRY]}
        );
        const addressCountry: CountryType = lodash.find(
            this.clientIdentificationCountries ?? [],
            {countryName: value.addressInformation[formControlNames.COUNTRY]}
        );
        const addressRegion: RegionType =
            this.mapAddressRegion(patientForm, initialPatient?.region, actionType);
        const {
            countryId, regionId, firstName1, firstName2, lastName1, lastName2, mainPhoneNumber,
            alternatePhoneNumber, ...initialPatientWithoutSomeProperties
        } = (initialPatient ?? {});
        const externalKeys = value?.externalKeys[formControlNames.EXTERNAL_KEYS] ?
            this.mapPatientExternalKeys(value?.externalKeys[formControlNames.EXTERNAL_KEYS]) :
            null;
        const birthDate = (!!value[formControlNames.BIRTHDATE] && value[formControlNames.BIRTHDATE] !== '')
            ? moment(moment(moment(value[formControlNames.BIRTHDATE], moment.localeData().longDateFormat('L'))).format('YYYY-MM-DD'))
                .utc(true).format()
            : null;
        const patientControlExceptions = this.mapPatientControlExceptions();

        return {
            ...(initialPatientWithoutSomeProperties ?? {}),
            ...(formControlNames.BIRTHDATE ? {birthDate} : null),
            ...(formControlNames.FIRST_NAME1
                ? {firstName1: value[formControlNames.FIRST_NAME1] !== '' ? value[formControlNames.FIRST_NAME1] : null} : null),
            ...(formControlNames.FIRST_NAME2
                ? {firstName2: value[formControlNames.FIRST_NAME2] !== '' ? value[formControlNames.FIRST_NAME2] : null} : null),
            ...(formControlNames.LAST_NAME1
                ? {lastName1: value[formControlNames.LAST_NAME1] !== '' ? value[formControlNames.LAST_NAME1] : null} : null),
            ...(formControlNames.LAST_NAME2
                ? {lastName2: value[formControlNames.LAST_NAME2] !== '' ? value[formControlNames.LAST_NAME2] : null} : null),
            ...(formControlNames.GENDER
                ? {gender: value[formControlNames.GENDER] !== '' ? value[formControlNames.GENDER] : null} : null),
            ...(formControlNames.NO_DOCUMENT
                ? {noDocument} : null),
            ...(formControlNames.DOCUMENT_COUNTRY
                ? {documentCountry: documentCountry?.countryCode ?? null} : null),
            ...(formControlNames.DOCUMENT_TYPE
                ? {documentType: value.clientIdentification[formControlNames.DOCUMENT_TYPE] ?? null} : null),
            ...(formControlNames.DOCUMENT_NUMBER
                ? {documentNumber: value.clientIdentification[formControlNames.DOCUMENT_NUMBER] ?? ''} : null),
            ...(formControlNames.CLIENT_AGREEMENTS ? {clientAgreements} : null),
            ...(formControlNames.MAIN_PHONE_NUMBER
                ? {mainPhoneNumber: mainPhoneNumberValue === null && (initialPatient?.mainPhoneNumber ?? null) !== null ? '' : mainPhoneNumberValue}
                : null),
            ...(formControlNames.ALTERNATE_PHONE_NUMBER
                ? {alternatePhoneNumber: alternatePhoneNumberValue === null && (initialPatient?.alternatePhoneNumber ?? null) !== null ? '' : alternatePhoneNumberValue}
                : null),
            ...(formControlNames.COUNTRY_ID ? {countryId: addressCountry?.id ?? null} : null),
            ...(formControlNames.REGION_ID ? {regionId: addressRegion?.id ?? null} : null),
            ...(Object.keys(address).length > 0 ? {address} : null),
            ...(formControlNames.COUNTRY ? {country: addressCountry ?? null} : null),
            ...(formControlNames.REGION ? {region: addressRegion ?? null} : null),
            ...(formControlNames.EMAIL
                ? {email: value[formControlNames.EMAIL] !== '' ? value[formControlNames.EMAIL] : null} : null),
            ...(formControlNames.COVERAGE_PLANS ? {coveragePlans: lodash.orderBy(coveragePlans, 'name') ?? null} : null),
            ...(this.arePatientTagsActive() ? {tags: tags.length > 0 ? tags : null} : null),
            ...(formControlNames.REMARKS
                ? {remarks: value[formControlNames.REMARKS] !== '' ? value[formControlNames.REMARKS] : null} : null),
            ...(formControlNames.PATIENT_EXTERNAL_KEYS
                ? {patientExternalKeys: externalKeys.length > 0 ? externalKeys : null} : null),
            ...(this.isCreatePatientExceptionsActive() ?
                {patientControlExceptions: patientControlExceptions.length > 0 ? patientControlExceptions : null}
                : null)
        } as PatientType;
    }

    getTimeDependentTagsDifferenceInDays(dateTimeFrom: string, dateTimeTo: string): number | '' {
        const dateFrom = dateTimeFrom ? moment(dateTimeFrom).format(moment.localeData().longDateFormat('L')) : '';
        const dateTo = dateTimeTo ? moment(dateTimeTo).format(moment.localeData().longDateFormat('L')) : '';
        const datesDifferenceInDays = moment(dateTimeTo).diff(moment(dateTimeFrom), 'days');
        return !!dateFrom && !!dateTo ? datesDifferenceInDays : '';
    }

    mapPatientTags(patientForm: FormGroup): TagsType[] | ObjectTagType[] {
        if (this.isTimeDependentTagsActive()) {
            return this.patientTimeDependentTags.length > 0
            ? this.patientTimeDependentTags.map((tag) => ({
                    ...tag,
                    tagId: tag.tagId,
                    dateTimeFrom: tag.dateTimeFrom,
                    dateTimeTo: tag.dateTimeTo,
                    tagLinkId: tag.tagLinkId
                })) : [];
        } else if (this.configDataService.isFeatureActive('patient-tags') && this.configDataService.isActivityActive('PatientTagsManager')) {
            const tagsFormValue = patientForm.value?.tags[formControlNames.TAGS];
            return tagsFormValue.length > 0 ? tagsFormValue.map((tag) => ({...tag, tagId: tag?.id, name: tag?.name})) : [];
        }
        return [];
    }

    arePatientTagsActive(): boolean {
        return this.isTimeDependentTagsActive() || (this.configDataService.isFeatureActive('patient-tags') && this.configDataService.isActivityActive('PatientTagsManager'));
    }

    mapPatientControlExceptions(): PatientControlRuleExceptionType[] {
        return this.patientControlExceptions.map((exception) => {
            const patientControlException = {
                serviceId: exception.service.id,
                specialtyId: exception.specialty.id,
                resourceId: exception.resource.id,
                validTo: exception.validTo
            } as PatientControlRuleExceptionType;
            if (exception?.id) {
                patientControlException.id = exception.id;
            }
            return patientControlException;
        });
    }

    isFieldVisibleAndEnabled(fieldName: string): boolean {
        return this.tenantCustomizingService.isVisible(fieldName) && this.tenantCustomizingService.isEnabled(fieldName);
    }

    mapPatientExternalKeys(newPatientExternalKeys: { Origin: string, Key: string, Id: string }[])
        : PatientExternalKeyType[] {

        return newPatientExternalKeys.filter(({Origin, Key}) => Origin !== '' && Key !== '')
            .map(({Origin, Key, Id}) => ({
                origin: Origin,
                key: Key,
                id: Id
            })) as PatientExternalKeyType[];

    }

    mapAddressRegion(patientForm: FormGroup, initialPatientRegion: RegionType, actionType: ActionType): RegionType {
        const patientRegionForm = patientForm.get('addressInformation').get(formControlNames.REGION) as FormGroup;
        const hasRegionInputChangedOrDisabled = patientRegionForm.dirty || patientRegionForm.disabled;
        const patientFormRegionValue = patientRegionForm.value;
        if (this.addressRegions?.length > 0) {
            if (patientFormRegionValue) {
                return lodash.find(this.addressRegions, {name: patientFormRegionValue});
            } else if (!hasRegionInputChangedOrDisabled && initialPatientRegion && actionType === ActionType.Edit) {
                return initialPatientRegion;
            }
        }
    }

    mapAddressLocality(patientForm: FormGroup, initialPatientAddressLocalityName: string, actionType: ActionType): string {
        const addressInformationForm = patientForm.get('addressInformation') as FormGroup;
        const addressLocalityForm = addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY] as FormGroup;
        const hasLocalityInputChangedOrDisabled = addressLocalityForm.dirty || addressLocalityForm.disabled;
        const patientFormAddressLocalityValue = addressLocalityForm.value;
        if (patientFormAddressLocalityValue) {
            return this.addressLocalities?.length > 0
                ? lodash.find(this.addressLocalities, {name: patientFormAddressLocalityValue})?.name
                : patientFormAddressLocalityValue;
        } else if (!hasLocalityInputChangedOrDisabled && initialPatientAddressLocalityName && actionType === ActionType.Edit) {
            return initialPatientAddressLocalityName;
        }
        return null;
    }

    mapPatientAddress(patientForm: FormGroup, initialPatient?: PatientType, actionType?: ActionType): AddressType {
        // If you make changes in this method make sure creating and editing a patient still works correctly especially for the modified field.
        const {value} = patientForm;
        const addressLocality: string =
            this.mapAddressLocality(patientForm, initialPatient?.address?.locality, actionType);
        const isAddressStreetNullOrEmptyString = this.generalUtils.isNullOrEmptyString(value.addressInformation[formControlNames.ADDRESS_STREET]);
        const shouldAddressStreetHaveNoValue = (initialPatient?.address?.street ?? null) !== null && isAddressStreetNullOrEmptyString;
        const isAddressStreetNumberNullOrEmptyString =
            this.generalUtils.isNullOrEmptyString(value.addressInformation[formControlNames.ADDRESS_STREET_NUMBER]);
        const shouldAddressStreetNumberHaveNoValue =
            (initialPatient?.address?.streetNumber ?? null) !== null && isAddressStreetNumberNullOrEmptyString;
        const isAddressFloorNullOrEmptyString = this.generalUtils.isNullOrEmptyString(value.addressInformation[formControlNames.ADDRESS_FLOOR]);
        const shouldAddressFloorHaveNoValue = (initialPatient?.address?.floor ?? null) !== null && isAddressFloorNullOrEmptyString;
        const isAddressApartmentNumberNullOrEmptyString =
            this.generalUtils.isNullOrEmptyString(value.addressInformation[formControlNames.ADDRESS_APARTMENT_NUMBER]);
        const shouldAddressApartmentNumberHaveNoValue =
            (initialPatient?.address?.apartmentNumber ?? null) !== null && isAddressApartmentNumberNullOrEmptyString;
        const isAddressZipCodeNullOrEmptyString = this.generalUtils.isNullOrEmptyString(value.addressInformation[formControlNames.ADDRESS_ZIP_CODE]);
        const shouldAddressZipCodeHaveNoValue = (initialPatient?.address?.zipCode ?? null) !== null && isAddressZipCodeNullOrEmptyString;
        const address: AddressType = {
            // ...(initialPatient?.address ?? {}),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_LOCALITY) ? {locality: addressLocality} : null),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_STREET) ? (shouldAddressStreetHaveNoValue ? {} : {
                street: isAddressStreetNullOrEmptyString ? null : value.addressInformation[formControlNames.ADDRESS_STREET]
            }) : null),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_STREET_NUMBER) ? (shouldAddressStreetNumberHaveNoValue ? {} : {
                streetNumber: isAddressStreetNumberNullOrEmptyString ? null : value.addressInformation[formControlNames.ADDRESS_STREET_NUMBER]
            }) : null),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_FLOOR) ? (shouldAddressFloorHaveNoValue ? {} : {
                floor: isAddressFloorNullOrEmptyString ? null : value.addressInformation[formControlNames.ADDRESS_FLOOR]
            }) : null),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_APARTMENT_NUMBER) ? (shouldAddressApartmentNumberHaveNoValue ? {} : {
                apartmentNumber: isAddressApartmentNumberNullOrEmptyString
                    ? null
                    : value.addressInformation[formControlNames.ADDRESS_APARTMENT_NUMBER]
            }) : null),
            ...(this.isFieldVisibleAndEnabled(formControlNames.ADDRESS_ZIP_CODE) ? (shouldAddressZipCodeHaveNoValue ? {} : {
                zipCode: isAddressZipCodeNullOrEmptyString
                    ? null
                    : value.addressInformation[formControlNames.ADDRESS_ZIP_CODE]
            }) : null),
        } as AddressType;
        return address;
    }

    // Options for step 1
    getCreatePatientStepOneFormOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem',
            },
            groups: [
                {
                    containerStyles: {
                        numberOfColumns: 2,
                        columnsGap: '1rem'
                    },
                    inputs: [
                        {
                            formControlName: formControlNames.FIRST_NAME1,
                            template: DynamicFormInputTemplateEnum.Text,
                            labelText: 'label.firstNames',
                            placeholder: 'label.firstName',
                            required: this.tenantCustomizingService.isRequired(formControlNames.FIRST_NAME1),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.FIRST_NAME1),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.FIRST_NAME1),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                            // Custom validators example
                            // customValidators: [
                            //     ((ctrl) => {
                            //         if (ctrl?.value?.length >= 0 && ctrl?.value?.length < 20) {
                            //             return {customEr: {priority: 10, message: 'HAHA IT WORKS'}};
                            //         } else {
                            //             return {};
                            //         }
                            //     }) as ValidatorFn,
                            //     ((ctrl) => {
                            //         if (ctrl?.value?.length > 0 && ctrl?.value?.length < 5) {
                            //             return {customEr: {priority: 1, message: 'label.sendEmail', shouldTranslateMessage: true}};
                            //         } else {
                            //             return {};
                            //         }
                            //     }) as ValidatorFn,
                            // ],
                        },
                        {
                            formControlName: formControlNames.FIRST_NAME2,
                            template: DynamicFormInputTemplateEnum.Text,
                            placeholder: 'label.firstName',
                            required: this.tenantCustomizingService.isRequired(formControlNames.FIRST_NAME2),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.FIRST_NAME2),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.FIRST_NAME2),
                            styles: {
                                gridColumn: '2/3',
                                gridRow: '1/2',
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                    ],
                },
                {
                    containerStyles: {
                        numberOfColumns: 2,
                        columnsGap: '1rem'
                    },
                    inputs: [
                        {
                            formControlName: formControlNames.LAST_NAME1,
                            template: DynamicFormInputTemplateEnum.Text,
                            labelText: 'label.lastNames',
                            placeholder: 'label.lastName',
                            required: this.tenantCustomizingService.isRequired(formControlNames.LAST_NAME1),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.LAST_NAME1),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.LAST_NAME1),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.LAST_NAME2,
                            template: DynamicFormInputTemplateEnum.Text,
                            placeholder: 'label.lastName',
                            required: this.tenantCustomizingService.isRequired(formControlNames.LAST_NAME2),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.LAST_NAME2),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.LAST_NAME2),
                            styles: {
                                gridColumn: '2/3',
                                gridRow: '1/2',
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                    ],
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.GENDER,
                            template: DynamicFormInputTemplateEnum.Select,
                            labelText: 'label.gender',
                            placeholder: 'label.choose',
                            selectInputData: this.getGenderSelectData(),
                            required: true,
                            visible: this.tenantCustomizingService.isVisible(formControlNames.GENDER),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.GENDER),
                        },
                    ],
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.BIRTHDATE,
                            template: DynamicFormInputTemplateEnum.Date,
                            labelText: 'label.birthDate',
                            placeholder: 'label.birthDate',
                            required: this.tenantCustomizingService.isRequired(formControlNames.BIRTHDATE),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.BIRTHDATE),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.BIRTHDATE),
                            datePickerConfig: {
                                firstDayOfWeek: 'mo',
                                max: moment(),
                                format: moment.localeData().longDateFormat('L')
                            }
                        }
                    ]
                },
                {
                    containerStyles: {
                        numberOfColumns: 4,
                        columnsGap: '1rem',
                    },
                    inputs: [
                        {
                            formControlName: formControlNames.ONLINE_ACCOUNT,
                            template: DynamicFormInputTemplateEnum.Checkbox,
                            labelText: 'label.onlineAccount',
                            visible: this.configDataService.isFeatureActive('backoffice-patient-online-account')
                        },
                        {
                            formControlName: formControlNames.WHATSAPP_NOTIFICATIONS,
                            template: DynamicFormInputTemplateEnum.Checkbox,
                            labelText: 'label.whatsAppNotifications',
                            visible: this.configDataService.isFeatureActive('backoffice-patient-whatsapp-notifications')
                        },
                    ],
                },
            ]
        };
    }

    getClientIdentificationSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem',
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.NO_DOCUMENT,
                            required: false,
                            visible: this.tenantCustomizingService.isVisible(formControlNames.NO_DOCUMENT),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.NO_DOCUMENT),
                        },
                        {
                            formControlName: formControlNames.DOCUMENT_TYPE,
                            required: true,
                            visible: this.tenantCustomizingService.isVisible(formControlNames.DOCUMENT_TYPE),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.DOCUMENT_TYPE),
                        },
                        {
                            formControlName: formControlNames.DOCUMENT_NUMBER,
                            required: true,
                            visible: this.tenantCustomizingService.isVisible(formControlNames.DOCUMENT_NUMBER),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.DOCUMENT_NUMBER),
                        },
                        {
                            formControlName: formControlNames.DOCUMENT_COUNTRY,
                            required: true,
                            visible: this.tenantCustomizingService.isVisible(formControlNames.DOCUMENT_COUNTRY),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.DOCUMENT_COUNTRY),
                        },
                    ],
                },
            ],
        };
    }

    // Options for step 2

    getCreatePatientStepTwoFormOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem'
            },
            groups: [
                {
                    containerStyles: {
                        numberOfColumns: 1
                    },
                    inputs: [{
                        formControlName: formControlNames.EMAIL,
                        template: DynamicFormInputTemplateEnum.Email,
                        placeholder: 'label.email',
                        required: this.tenantCustomizingService.isRequired(formControlNames.EMAIL),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.EMAIL),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.EMAIL),
                        labelText: 'label.email'
                    }]
                }
            ]
        };
    }

    getAddressInformationSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem',
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.COUNTRY,
                            required: this.tenantCustomizingService.isRequired(formControlNames.COUNTRY_ID),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.COUNTRY_ID),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.COUNTRY_ID),
                        },
                        {
                            formControlName: formControlNames.REGION,
                            required: this.tenantCustomizingService.isRequired(formControlNames.REGION_ID),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.REGION_ID),
                            enabled: false,
                        },
                        {
                            formControlName: formControlNames.ADDRESS_LOCALITY,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_LOCALITY),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_LOCALITY),
                            enabled: false,
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.ADDRESS_STREET,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_STREET),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_STREET),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.ADDRESS_STREET),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.ADDRESS_STREET_NUMBER,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_STREET_NUMBER),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_STREET_NUMBER),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.ADDRESS_STREET_NUMBER),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.ADDRESS_FLOOR,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_FLOOR),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_FLOOR),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.ADDRESS_FLOOR),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.ADDRESS_APARTMENT_NUMBER,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_APARTMENT_NUMBER),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_APARTMENT_NUMBER),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.ADDRESS_APARTMENT_NUMBER),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        },
                        {
                            formControlName: formControlNames.ADDRESS_ZIP_CODE,
                            required: this.tenantCustomizingService.isRequired(formControlNames.ADDRESS_ZIP_CODE),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.ADDRESS_ZIP_CODE),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.ADDRESS_ZIP_CODE),
                            styles: {
                                gridTemplateRows: '1rem max-content max-content'
                            }
                        }
                    ],
                },
            ],
        };
    }

    getMainPhoneNumberSubformOptions(): DynamicFormOptionsType {
        return {
            groups: [{
                inputs: [
                    {
                        formControlName: formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE,
                        template: DynamicFormInputTemplateEnum.PhoneNumberCountryCode,
                        required: this.tenantCustomizingService.isRequired(formControlNames.MAIN_PHONE_NUMBER),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.MAIN_PHONE_NUMBER),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.MAIN_PHONE_NUMBER),
                        labelText: 'label.centerTab.mainPhoneNumber'
                    },
                    {
                        formControlName: formControlNames.MAIN_PHONE_NUMBER,
                        template: DynamicFormInputTemplateEnum.PhoneNumber,
                        required: this.tenantCustomizingService.isRequired(formControlNames.MAIN_PHONE_NUMBER),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.MAIN_PHONE_NUMBER),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.MAIN_PHONE_NUMBER),
                    }
                ]
            }]
        };
    }

    getAlternatePhoneNumberSubformOptions(): DynamicFormOptionsType {
        return {
            groups: [{
                inputs: [
                    {
                        formControlName: formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE,
                        template: DynamicFormInputTemplateEnum.PhoneNumberCountryCode,
                        required: this.tenantCustomizingService.isRequired(formControlNames.ALTERNATE_PHONE_NUMBER),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.ALTERNATE_PHONE_NUMBER),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.ALTERNATE_PHONE_NUMBER),
                        labelText: 'label.alternatePhoneNumber'
                    },
                    {
                        formControlName: formControlNames.ALTERNATE_PHONE_NUMBER,
                        template: DynamicFormInputTemplateEnum.PhoneNumber,
                        required: this.tenantCustomizingService.isRequired(formControlNames.ALTERNATE_PHONE_NUMBER),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.ALTERNATE_PHONE_NUMBER),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.ALTERNATE_PHONE_NUMBER),
                    }
                ]
            }]
        };
    }

    // Options for step 3

    getCoveragePlansSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem'
            },
            groups: [
                {
                    containerStyles: {
                        numberOfColumns: 1
                    },
                    inputs: [
                        {
                            formControlName: formControlNames.MAIN_COVERAGE_PLAN,
                            required: this.tenantCustomizingService.isRequired(formControlNames.COVERAGE_PLANS),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.COVERAGE_PLANS),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.COVERAGE_PLANS),
                        },
                        {
                            formControlName: formControlNames.SECONDARY_COVERAGE_PLANS,
                            required: this.tenantCustomizingService.isRequired(formControlNames.COVERAGE_PLANS),
                            visible: this.tenantCustomizingService.isVisible(formControlNames.COVERAGE_PLANS),
                            enabled: this.tenantCustomizingService.isEnabled(formControlNames.COVERAGE_PLANS),
                        },
                    ],
                },
            ],
        };
    }

    getTagsSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.TAGS,
                            visible: this.configDataService.isFeatureActive('patient-tags') && !this.isTimeDependentTagsActive(),
                            enabled: this.configDataService.isActivityActive('PatientTagsManager'),
                        }
                    ],
                },
            ],
        };
    }

    getExternalKeysSubformOptions(isEditMode: boolean): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.EXTERNAL_KEYS,
                            visible: isEditMode,
                        }
                    ],
                },
            ],
        };
    }

    getCreatePatientStepThreeFormOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 1
            },
            groups: [
                {
                    containerStyles: {
                        numberOfColumns: 1
                    },
                    inputs: [{
                        formControlName: formControlNames.REMARKS,
                        template: DynamicFormInputTemplateEnum.Textarea,
                        placeholder: 'label.specialComments',
                        required: this.tenantCustomizingService.isRequired(formControlNames.REMARKS),
                        visible: this.tenantCustomizingService.isVisible(formControlNames.REMARKS),
                        enabled: this.tenantCustomizingService.isEnabled(formControlNames.REMARKS),
                        labelText: 'label.specialComments'
                    }]
                }
            ]
        };
    }

    // Options for step 4

    getExceptionsSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem'
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.SERVICE,
                            required: false,
                            visible: this.isCreatePatientExceptionsActive(),
                            enabled: this.isCreatePatientExceptionsActive(),
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.SPECIALTY,
                            required: false,
                            visible: this.isCreatePatientExceptionsActive(),
                            enabled: this.isCreatePatientExceptionsActive(),
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.RESOURCE,
                            required: false,
                            visible: this.isCreatePatientExceptionsActive(),
                            enabled: this.isCreatePatientExceptionsActive(),
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.VALID_TO,
                            template: DynamicFormInputTemplateEnum.Date,
                            labelText: 'label.validTo',
                            placeholder: 'label.validTo',
                            required: false,
                            visible: this.isCreatePatientExceptionsActive(),
                            enabled: this.isCreatePatientExceptionsActive(),
                        }
                    ],
                }
            ]
        };
    }

    getValidToFormOptions(): DynamicFormOptionsType {
        return {
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.VALID_TO,
                            template: DynamicFormInputTemplateEnum.Date,
                            labelText: 'label.validTo',
                            placeholder: 'label.validTo',
                            required: false,
                            visible: this.isCreatePatientExceptionsActive(),
                            enabled: this.isCreatePatientExceptionsActive(),
                            datePickerConfig: {
                                firstDayOfWeek: 'mo',
                                min: moment(),
                                format: moment.localeData().longDateFormat('L')
                            }
                        }
                    ]
                }
            ]
        };
    }

    // Options for step 5

    getTimeDependentTagsSubformOptions(): DynamicFormOptionsType {
        return {
            containerStyles: {
                numberOfColumns: 2,
                columnsGap: '3rem',
                rowsGap: '1rem'
            },
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.TIME_DEPENDENT_TAG,
                            required: false,
                            visible: this.isTimeDependentTagsActive(),
                            enabled: this.isTimeDependentTagsActive(),
                        }
                    ],
                }
            ]
        };
    }

    getTimeDependentTagsDynamicInputFormOptions(): DynamicFormOptionsType {
        return {
            groups: [
                {
                    inputs: [
                        {
                            formControlName: formControlNames.DATE_TIME_FROM,
                            template: DynamicFormInputTemplateEnum.Date,
                            labelText: 'label.validFrom',
                            placeholder: 'label.validFrom',
                            required: false,
                            visible: this.isTimeDependentTagsActive(),
                            enabled: this.isTimeDependentTagsActive(),
                            datePickerConfig: {
                                firstDayOfWeek: 'mo',
                                min: moment(),
                                format: moment.localeData().longDateFormat('L')
                            }
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.DATE_TIME_TO,
                            template: DynamicFormInputTemplateEnum.Date,
                            labelText: 'label.validTo',
                            placeholder: 'label.validTo',
                            required: false,
                            visible: this.isTimeDependentTagsActive(),
                            enabled: this.isTimeDependentTagsActive(),
                            datePickerConfig: {
                                firstDayOfWeek: 'mo',
                                min: moment(),
                                format: moment.localeData().longDateFormat('L')
                            }
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            formControlName: formControlNames.NUMBER_OF_DAYS,
                            template: DynamicFormInputTemplateEnum.Number,
                            labelText: 'label.numberOfDays',
                            placeholder: 'label.numberOfDays',
                            required: false,
                            visible: this.isTimeDependentTagsActive(),
                            enabled: this.isTimeDependentTagsActive(),
                        }
                    ]
                }
            ]
        };
    }


    getQueryFilterForTags(patientId: string): ODataQueryObjectType {
        return {
            count: true,
            filter: this.getFilterQueryForTags(patientId)
        };
    }

    getMappedPatientOnlyWithValuesThatAreEnabledAndVisible(mappedPatient: PatientType, patientForm: FormGroup, actionType: ActionType): PatientType {
        // Get only the keys that are enabled and visible true
        const mappedPatientKeys = this.getMappedPatientKeysThatAreEnabledAndVisible(patientForm, actionType);
        const newMappedPatient = {
            address: {}
        } as PatientType;
        // We map the properties based on the filtered mappedPatientKeys, so we send only the properties that we have in the patientForm
        mappedPatientKeys.forEach(key => {
            // Here we need to make this check because the form properties for address are like this Address.Locality and we need to send only locality
            if (key.includes('Address')) {
                const lowerFirstKey = lodash.lowerFirst(key.replace('Address.', ''));
                newMappedPatient.address[lowerFirstKey] = mappedPatient?.address[lowerFirstKey];
            } else if (key === formControlNames.ONLINE_ACCOUNT || key === formControlNames.WHATSAPP_NOTIFICATIONS) {
                newMappedPatient.clientAgreements = mappedPatient?.clientAgreements;
            } else if (key === formControlNames.EXTERNAL_KEYS) {
                newMappedPatient.patientExternalKeys = mappedPatient?.patientExternalKeys;
            } else if (key === formControlNames.EXCEPTIONS) {
                newMappedPatient.patientControlExceptions = mappedPatient?.patientControlExceptions;
            } else if (key === formControlNames.TIME_DEPENDENT_TAGS) {
                newMappedPatient.tags = mappedPatient?.tags;
            } else {
                const lowerFirstKey = lodash.lowerFirst(key);
                newMappedPatient[lowerFirstKey] = mappedPatient[lowerFirstKey];
            }
        });
        // We need this because for region and country we use the regionId and countryId instead of region and country objects
        newMappedPatient.countryId = mappedPatient?.countryId;
        newMappedPatient.regionId = mappedPatient?.regionId;
        // If the patient has documentType, documentNumber and documentCountry and we want to set noDocument to true
        // the fields become disabled so the properties will no longer be available in the newMappedPatient
        if (mappedPatient?.noDocument) {
            newMappedPatient.documentType = mappedPatient?.documentType;
            newMappedPatient.documentNumber = mappedPatient?.documentNumber;
            newMappedPatient.documentCountry = mappedPatient?.documentCountry;
        }
        if (mappedPatient?.address?.locality === null) {
            newMappedPatient.address.locality =  mappedPatient?.address?.locality;
        }
        if (mappedPatient?.id) {
            newMappedPatient.id = mappedPatient.id;
        }
        return newMappedPatient;
    }

    getQueryFilterForExceptionsService(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name'],
            expand: {Speciality: {select: ['Id', 'Name']}}
        };
    }

    getQueryFilterForExceptionsSpeciality(): ODataQueryObjectType {
        return {select: ['Id', 'Name']};
    }

    getQueryFilterForExceptionsResource(): ODataQueryObjectType {
        return {select: ['Id', 'Name']};
    }

    setPatientTimeDependentTagsExclusionList(): void {
        this.tagsExclusionList = this.patientTimeDependentTags.map(tag => tag.tagId);
    }

    private getFilterQueryForTags(patientId: string): Filter {
        return patientId
            ? {
                ScopedPatient: true,
                TagLinks: {
                    any: {
                        EntityId: {type: 'guid', value: patientId},
                        EntityType: 'Patient'
                    }
                }
            }
            : {ScopedPatient: true};
    }

    private getPatientClientAgreements(patientForm: FormGroup): ClientAgreementType[] {
        // If you make changes in this method make sure creating and editing a patient still works correctly especially for the modified field.
        const clientAgreements: ClientAgreementType[] = [];
        Object.keys(patientForm.value).forEach(key => {
            if (PatientClientAgreementsEnum[key] && patientForm.value[key]) {
                clientAgreements.push({
                    version: PatientClientAgreementsEnum[key]
                } as ClientAgreementType);
            }
        });
        return clientAgreements;
    }

    private getMappedPatientKeys(patientForm: FormGroup): string[] {
        // We need this method so we can also get the deep keys like the ones from address ex: locality
        const objectDeepKeys = (obj, keys = []) => {
            for (const key of Object.keys(obj)) {
                keys.push(key);
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    objectDeepKeys(obj[key], keys);
                }
            }
            return keys;
        };

        const mappedPatientKeys = objectDeepKeys(patientForm.value);

        return mappedPatientKeys.map(key => lodash.upperFirst(key));
    }

    private getMappedPatientKeysThatAreEnabledAndVisible(patientForm: FormGroup, actionType: ActionType): string[] {
        const mappedPatientKeys = this.getMappedPatientKeys(patientForm);
        // We filter the keys, so we can use only the keys that are enabled and visible true
        return mappedPatientKeys.filter(key => {
            // We have a few keys that we need to make a different check than the one from tenant-config
            if (key === formControlNames.TAGS) {
                return this.configDataService.isFeatureActive('patient-tags');
            }
            if (key === formControlNames.ONLINE_ACCOUNT) {
                return this.configDataService.isFeatureActive('backoffice-patient-online-account');
            }
            if (key === formControlNames.WHATSAPP_NOTIFICATIONS) {
                return this.configDataService.isFeatureActive('backoffice-patient-whatsapp-notifications');
            }
            if (key === formControlNames.EXTERNAL_KEYS) {
                return actionType === ActionType.Edit;
            }
            if (key === formControlNames.EXCEPTIONS) {
                return this.isCreatePatientExceptionsActive();
            }
            if (key === formControlNames.TIME_DEPENDENT_TAGS) {
                return this.isTimeDependentTagsActive();
            }
            return this.isFieldVisibleAndEnabled(key);
        });
    }
}
