import {
    Directive,
    ElementRef,
    EventEmitter,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {ResizedEvent} from './resized.event';

@Directive({
    selector: '[appResized]'
})
export class ResizeEventDirective implements OnInit, OnDestroy {
    private observer: ResizeObserver;
    private oldRect?: DOMRectReadOnly;
    @Output()
    readonly appResized = new EventEmitter<ResizedEvent>();

    constructor(private readonly element: ElementRef,
                private readonly zone: NgZone) {
        this.observer = new ResizeObserver(entries => this.zone.run(() => this.observe(entries)));
    }

    ngOnInit(): void {
        this.observer.observe(this.element.nativeElement);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    private observe(entries: ResizeObserverEntry[]): void {
        const domSize = entries[0];
        const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
        this.oldRect = domSize.contentRect;
        this.appResized.emit(resizedEvent);
    }
}
