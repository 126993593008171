import {Component, OnInit, OnDestroy} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    AvailabilityOrExclusionEnum,
    JobProvider,
    AvailabilityJobFiltersType, ExclusionJobFiltersType
} from 'sked-base';
import {Observable} from 'rxjs';
import * as lodash from 'lodash';

@AutoUnsubscribe()
@Component({
    selector: 'app-availability-exclusion-export-md',
    templateUrl: './availability-exclusion-export-md.component.html',
    styleUrls: ['./availability-exclusion-export-md.component.scss']
})

export class AvailabilityExclusionExportMDComponent implements OnInit, OnDestroy {
    queryFilters: AvailabilityJobFiltersType | ExclusionJobFiltersType;
    availabilityOrExclusionEnumValues: AvailabilityOrExclusionEnum[] = Object.values(AvailabilityOrExclusionEnum);
    exportType: AvailabilityOrExclusionEnum = AvailabilityOrExclusionEnum.availability;
    spinnerId = 'export-loader';
    disableFilters = false;

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        public jobProvider: JobProvider,
        public generalUtils: GeneralUtils,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy() {
    }

    sendExportData(exportType: AvailabilityOrExclusionEnum, queryFilters: AvailabilityJobFiltersType | ExclusionJobFiltersType) {
        if (!this.areFiltersValid(exportType, queryFilters)) {
            return;
        }
        this.ngxLoader.startLoader(this.spinnerId);
        this.disableFilters = true;
        this.getGenerateExportJobAvailabilityOrExclusion(exportType, queryFilters).pipe(take(1)).subscribe(
            (response: any) => this.exportOnResponse(response), (error) => this.exportOnError(error), () => this.exportOnComplete()
        );
    }

    areFiltersValid(exportType: AvailabilityOrExclusionEnum, queryFilters: AvailabilityJobFiltersType | ExclusionJobFiltersType): boolean {
        if (exportType === AvailabilityOrExclusionEnum.availability) {
            return this.exportAvailabilityValidate(queryFilters);
        }
        if (exportType === AvailabilityOrExclusionEnum.exclusion) {
            return this.exportExclusionValidate(queryFilters);
        }
    }

    private getGenerateExportJobAvailabilityOrExclusion(exportType: AvailabilityOrExclusionEnum,
                                                        queryFilters: AvailabilityJobFiltersType | ExclusionJobFiltersType): Observable<any> {
        if (exportType === AvailabilityOrExclusionEnum.availability) {
            return this.jobProvider.getGenerateExportJobAvailability(queryFilters);
        } else if (exportType === AvailabilityOrExclusionEnum.exclusion) {
            return this.jobProvider.getGenerateExportJobExclusion(queryFilters);
        }
    }

    private exportAvailabilityValidate(queryFilters: AvailabilityJobFiltersType): boolean {
        // todo change this when we add filters to availability / exclusion exports
        return queryFilters !== null && typeof queryFilters === 'object' && lodash.isEmpty(queryFilters);
    }

    private exportExclusionValidate(queryFilters: ExclusionJobFiltersType): boolean {
        // todo change this when we add filters to availability / exclusion exports
        return queryFilters !== null && typeof queryFilters === 'object' && lodash.isEmpty(queryFilters);
    }

    private exportOnResponse(data: any) {
        this.messagesService.success('label.generateExportWaitMessageGoToExports');
        this.disableFilters = false;
    }

    private exportOnError(error: any) {
        this.messagesService.handlingErrorMessage(error);
        this.ngxLoader.stopLoader(this.spinnerId);
        this.disableFilters = false;
    }

    private exportOnComplete() {
        this.ngxLoader.stopLoader(this.spinnerId);
    }

    private setupInitialState() {
        // todo change this when we add filters to availability / exclusion exports
        this.queryFilters = {};
    }
}
