import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    Expand,
    MultiResourceBluePrintType,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    ResourceTypeType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {MultiResourceBluePrintFiltersType} from './multi-resource-blue-print.types';

@Injectable({
    providedIn: 'root'
})
export class MultiResourceBluePrintMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialResourceTypeFilterValues: ResourceTypeType[] = [];

    constructor(private generalUtils: GeneralUtils) {
    }

    getResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            resourceTypeId: null,
            exclusionList: [],
            resourceTypeExclusionList: [],
            count: true,
        } as ResourceDependentFiltersType;
    }

    getInitialFilterValues(): MultiResourceBluePrintFiltersType {
        return {
            mainResourceType: [],
            secondaryResourceTypes: []
        };
    }

    getQueryFilterForResourceTypes() {
        return {
            select: ['Id', 'Name', 'RowVersion'],
        };
    }

    getQueryFilterForMultiResourceBluePrintMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'ShortId', 'Name', 'MainResourceTypeId', 'Active', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Items: {
                select: ['Id', 'ResourceTypeId', 'IsMainResource', 'NumberOfResource']
            },
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        tableFilters.itemsPerPage = 10;
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'resourceType': {
                        filterQuery['Items'] = {any: {'ResourceType/Id': {eq: {type: 'guid', value: filter[item]}}}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item.includes('ResourceType')) {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialMultiResourceBluePrint(): MultiResourceBluePrintType {
        return {
            name: '',
            active: true,
            items: []
        } as MultiResourceBluePrintType;
    }
}
