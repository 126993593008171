import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, TemplateRef} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ResourceType,
    ResourceProvider,
    ResourceTypeProvider,
    ResourceTypeType,
    ResourceExternalKeyTypes,
    EntityTypeEnum,
    Validations,
    CenterType,
    CoveragePlanType,
    ServiceType,
    TagDependentFiltersType,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagProvider,
    ObjectTagType,
    ResourceTypeDependentFilterType, TagAssignedToEntityResultType
} from 'sked-base';
import {FormValidationType, ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ResourceMdUtils} from '../resource-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ResourceMultiSelectTableOptionsType, ValidResourceObjectType} from '../resource-md.types';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {AbstractControl} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {UploadFileOptionsType} from '../../../shared/component/upload-file/upload-file.types';
import {UploadFileUtils} from '../../../shared/component/upload-file/upload-file.utils';
import {
    PhoneNumberOutputEventType,
    PhoneNumberInputOptionsType
} from '../../../shared/component/phone-number-input/phone-number-input.types';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Subject} from 'rxjs';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-resource',
    templateUrl: './create-resource.component.html',
    styleUrls: ['./create-resource.component.scss']
})
export class CreateResourceComponent implements OnInit, AfterViewInit, OnDestroy {
    CONSTANTS = constants;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    @ViewChild('serviceTemplate', {static: true}) serviceTemplate: TemplateRef<any>;
    validTemplate: ValidResourceObjectType;
    totalStepsCount: number;
    initialResource: ResourceType;
    resourceItem: ResourceType = {} as ResourceType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    uploadFileOptions: UploadFileOptionsType;
    phoneNumberInputOptions: PhoneNumberInputOptionsType;
    textEditorConfig: AngularEditorConfig;
    resourceMultiSelectTableOptions: ResourceMultiSelectTableOptionsType;
    mainDependentFilters: {
        resourceType: ResourceTypeDependentFilterType,
        tags: TagDependentFiltersType
    };
    tags: ObjectTagType[] = [];
    triggerForceUpdateSubject: Subject<string[]> = new Subject<string[]>();
    triggerClearItemsSubject: Subject<boolean> = new Subject<boolean>();
    selectedCoveragePlansIds: string[] = [];
    systemTag = {} as ObjectTagType;

    constructor(
        public resourceTypeProvider: ResourceTypeProvider,
        public tagProvider: TagProvider,
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private resourceMdUtils: ResourceMdUtils,
        private resourceProvider: ResourceProvider,
        private router: Router,
        private uploadFileUtils: UploadFileUtils,
        private validations: Validations,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.stepper._steps.length;
        // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
        });
        if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
            // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.stepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
    }

    ngOnDestroy(): void {
    }

    onChangeTags(tags: TagAssignedToEntityResultType[]) {
        this.tags = tags.map(tag => ({...tag, tagId: tag.id}));
        this.resourceItem.tags = lodash.cloneDeep(this.tags);
    }

    onSelectedService(selectedServices: ServiceType[]) {
        this.resourceItem.services = selectedServices;
        this.validateFields(this.resourceItem);
    }

    onSelectedCenter(selectedCenter: CenterType[]) {
        this.resourceItem.centers = [...selectedCenter];
        this.validateFields(this.resourceItem);
    }

    onSelectedCoveragePlan(selectedCoveragePlan: CoveragePlanType[]) {
        this.resourceItem.coveragePlans = selectedCoveragePlan;
        this.validateFields(this.resourceItem);
    }

    onResourceValueChanged(resource: ResourceType): void {
        this.validateFields(resource);
    }

    onPhoneNumberChange(phoneNumber: PhoneNumberOutputEventType) {
        this.resourceItem.phoneNumber = phoneNumber.isValid ? phoneNumber.phoneNumberWithCountryCode : null;
        this.validTemplate.isPhoneNumberValid = phoneNumber.isValid;
        this.validateFields(this.resourceItem);
    }

    saveResourceData(resource: ResourceType) {
        this.resourceItem.tags = this.tags;
        if (this.screenTemplateLayout.action === constants.CREATE) {
            this.createResource(resource);
        } else if (this.screenTemplateLayout.action === constants.EDIT) {
            if (lodash.isEqual(this.initialResource, resource) && lodash.isEqual(this.uploadFileUtils.initialUploadedFile, this.uploadFileUtils.uploadedFile)) {
                this.messagesService.success('toastr.success.resourceEdit', true);
                this.goToParentPage();
            } else {
                this.editResource(this.initialResource, resource);
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/resources']);
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
        // When we move to step 3, if we have selected coverage plans from step 2 we make another request to filter the services
        // based on the selected coverage plans
        if (stepper.selectedIndex === 2 && this.selectedCoveragePlansIds?.length !== this.resourceItem?.coveragePlans?.length
            && this.resourceItem?.coveragePlans?.length >= 0) {
            this.selectedCoveragePlansIds = this.resourceItem?.coveragePlans?.map(coveragePlan => coveragePlan.id);
            this.triggerForceUpdateSubject.next(this.selectedCoveragePlansIds);
        }
        if (stepper.selectedIndex === 0 || stepper.selectedIndex === 4) {
            this.setTags(this.tags, stepper.selectedIndex);
        }
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    onStepHeaderClick(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean) {
        return (stepClicked: number) => {
            // Click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
            // user just clicked on stepClicked and the move was successful
            const selectedIndex = stepper?.selectedIndex;
            this.navigateFromStepToStep(stepper, areStepsValid, selectedIndex, stepClicked);
        };
    }

    navigateFromStepToStep(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean, selectedIndex: number, clickedIndex: number) {
        // Display error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!areStepsValid(step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    stepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    stepperGoBack(stepper: MatStepper) {
        stepper.previous();
    }

    stepperGoForward(stepper: MatStepper) {
        stepper.next();
    }

    shouldDisplayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isStepOneValid();
            case 1:
                return this.isStepTwoValid();
            case 2:
                return true;
            case 3:
                return this.isExternalKeysStepValid(this.resourceItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isStepOneValid(): boolean {
        const {
            isResourceTypeValid, isNameValid, isPriorityValid, uniqueCode, isPhoneNumberValid, email,
            location, additionalInformation, externalUrlForDescription, description
        } = this.validTemplate;
        return !!(isResourceTypeValid && isNameValid && isPriorityValid && uniqueCode.isValid && isPhoneNumberValid && email.isValid
            && location.isValid && additionalInformation.isValid && externalUrlForDescription.isValid && description.isValid);
    }

    isStepTwoValid(): boolean {
        return this.validTemplate.areCentersValid;
    }

    isExternalKeysStepValid(resource: ResourceType): boolean {
        return !!(this.isCurrentExternalKeysItemValid(resource));
    }

    //
    // endregion Validation methods

    // region Resource Details Step methods
    //
    onSelectedResourceTypeFilter(selectedResourceTypeList: ResourceTypeType[]) {
        // If selected resource type is Room limit the centers selection to 1
        if (!!this.resourceMultiSelectTableOptions?.centers) {
            this.resourceMultiSelectTableOptions.centers.limitNumberOfSelectedItemsTo1 = selectedResourceTypeList[0]?.isRoom;
        }
        // If selected resource type is changed to Room and some centers were selected in the table, clear the selected centers
        if (selectedResourceTypeList[0]?.isRoom && this.resourceItem?.centers?.length > 0) {
            this.resourceItem.centers = [];
            this.triggerClearItemsSubject.next(true);
        }
        this.resourceItem.resourceType = selectedResourceTypeList[0] ?? undefined;
        this.resourceItem.resourceTypeId = this.resourceItem.resourceType?.id;
        this.resourceItem.isDirectlyBookable = this.resourceItem?.resourceType?.isDirectlyBookable;
        this.validateFields(this.resourceItem);
    }

    isPriorityValid(priority: number): boolean {
        return priority > 0 && priority < 201 && priority % 1 === 0;
    }

    //
    // endregion Resource Details Step methods

    // region External Keys Step methods
    //
    onRemoveExternalKey(index: number) {
        this.resourceItem.resourceExternalKeys.splice(index, 1);
    }

    onAddExternalKeysRow() {
        this.resourceItem.resourceExternalKeys.push({
            key: '',
            origin: '',
            entityId: this.resourceItem.id
        } as ResourceExternalKeyTypes);
    }

    isCurrentExternalKeysItemValid(resource: ResourceType = this.resourceItem): boolean {
        if (resource.resourceExternalKeys.length) {
            const CURRENT_EXTERNAL_KEYS_ITEM = resource.resourceExternalKeys[resource.resourceExternalKeys.length - 1];
            return !!CURRENT_EXTERNAL_KEYS_ITEM.origin && !!CURRENT_EXTERNAL_KEYS_ITEM.key;
        }
        return true;
    }

    //
    // endregion External Keys Step methods

    goToEdit() {
        history.replaceState({
            resource: this.resourceItem,
            action: this.CONSTANTS.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    // function to create the new Resource
    private createResource(resource: ResourceType) {
        this.ngxLoader.start();
        this.resourceProvider.createObjectWithTags(resource)
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.resource);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newResourceAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the Resource
    private editResource(oldResource: ResourceType, newResource: ResourceType) {
        const oldResourceToSend: ResourceType = this.resourceMdUtils.mapResourceForServer(oldResource);
        const newResourceToSend: ResourceType = this.resourceMdUtils.mapResourceForServer(newResource);
        this.ngxLoader.start();
        this.resourceProvider.editObjectWithTags(oldResourceToSend, newResourceToSend)
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.resource);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.resourceEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private setupInitialState() {
        this.loadDependentFilters();
        if (history.state && history.state.resource) {
            this.initialResource = history.state.resource;
            this.resourceItem = lodash.cloneDeep(history.state.resource);
            this.uploadFileUtils.getUploadedFile(this.resourceItem.id, EntityTypeEnum.resource);
            this.loadTags(this.resourceItem.id);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.resourceItem = this.resourceMdUtils.getInitialResource();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.loadInitialOptions();
        this.textEditorConfig = this.resourceMdUtils.getInitialTextEditorConfig();
        this.validTemplate = this.resourceMdUtils.getInitValidTempleObject();
        this.validateFields(this.resourceItem);
    }

    private loadDependentFilters(): void {
        this.mainDependentFilters = {
            resourceType: this.resourceMdUtils.getResourceTypeDependentFilters(),
            tags: {
                ...this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedResource, false),
                system: false
            }
        };
    }

    private loadInitialOptions(): void {
        this.phoneNumberInputOptions = {
            labelText: 'label.phoneNumber',
            isRequired: false,
            initialPhoneNumberValue: !!this.resourceItem?.phoneNumber ? this.resourceItem?.phoneNumber : null,
            phoneNumberInputName: 'phoneNumber',
            countryCodeInputName: 'countryCode',
            isEditMode: this.screenTemplateLayout.action === this.CONSTANTS.EDIT
        };
        this.uploadFileOptions = {
            entityType: EntityTypeEnum.resource,
            disabled: this.screenTemplateLayout.action === constants.VIEW,
            itemId: this.resourceItem?.id,
            labelText: 'label.imageForPatientAccess'
        };
        this.resourceMultiSelectTableOptions = this.resourceMdUtils.getResourceMultiSelectTableOptions(
            this.resourceItem, this.screenTemplateLayout.action, this.serviceTemplate
        );
        if (!!this.resourceItem?.resourceType && !!this.resourceMultiSelectTableOptions?.centers) {
            this.resourceMultiSelectTableOptions.centers.limitNumberOfSelectedItemsTo1 = this.resourceItem?.resourceType?.isRoom;
        }
    }

    private validateFields(resource: ResourceType): void {
        this.validTemplate.isResourceTypeValid = !!resource.resourceType;
        this.validTemplate.isNameValid = !!resource.name;
        this.validTemplate.isPriorityValid = this.isPriorityValid(resource.priority);
        this.validTemplate.email = this.getEmailValidation(resource.email);
        this.validTemplate.externalUrlForDescription = this.generalUtils.getUrlValidation(resource.externalUrlForDescription, false);
        this.validTemplate.areCentersValid = this.resourceItem?.resourceType?.isRoom ? this.resourceItem?.centers?.length > 0 : true;
    }

    private getEmailValidation(email: string): FormValidationType {
        return this.validations.getValidateEmail(email, false) as FormValidationType;
    }

    private loadTags(resourceId: string) {
        this.ngxLoader.start();
        this.tagProvider.getTagsAssignedToEntity({entityType: 'Resource', entityId: resourceId})
            .subscribe((tags: { value: TagAssignedToEntityResultType[] }) => {
                const systemTag = tags?.value.find(tag => tag.system);
                this.systemTag = {...systemTag, tagId: systemTag?.id};
                this.initialResource.tags = lodash.cloneDeep(tags?.value.map(tag => ({...tag, tagId: tag.id})) ?? []);
                this.setTags(this.initialResource.tags);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
                this.goToParentPage();
            });
    }

    private setTags(tags: ObjectTagType[], step?: number) {
        const tagsWithSystem = !!this.systemTag?.tagId ? lodash.uniqBy([...tags, this.systemTag], 'id') : tags;
        const tagsExceptSystem = tags.filter(tag => tag.tagId !== this.systemTag?.tagId);
        if (!this.generalUtils.isNullOrUndefined(step)) {
            this.tags = step === 0 ? tagsExceptSystem : tagsWithSystem;
        } else {
            this.tags = this.screenTemplateLayout.action === constants.EDIT ? tagsExceptSystem : tagsWithSystem;
        }
        this.resourceItem.tags = lodash.cloneDeep(this.tags);
    }
}
