import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ResourceType} from 'sked-base';

@Component({
    selector: 'app-slot-resource-additional-info-modal',
    templateUrl: './slot-resource-additional-info-modal.component.html',
    styleUrls: ['./slot-resource-additional-info-modal.component.scss']
})
export class SlotResourceAdditionalInfoModalComponent implements OnInit, AfterViewInit {
    resources: ResourceType[] = [];
    resourceTabToPreselect: string;

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.scrollToElement(this.resourceTabToPreselect);
            this.setTabsActiveClass();
        });
    }

    closeModal(): void {
        this.activeModal.dismiss('close');
    }

    goToExternalUrl(externalUrl: string): void {
        window.open(externalUrl, '_blank');
    }

    scrollToElement(elementId: string): void {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({behavior: 'smooth'});
    }

    setTabsActiveClass(): void {
        this.removeActiveClassFromAllTabs();
        this.getAllCardsThatAreOnScreen()?.forEach((card, index) => {
            const currentCard = card as HTMLElement;
            const correspondingCardTab = document.getElementById(`nav-${currentCard.id}`);
            if (index === 0) {
                correspondingCardTab.classList.add('active');
            } else {
                correspondingCardTab.classList.remove('active');
            }
        });
    }

    private removeActiveClassFromAllTabs(): void {
        const allTabs = document.querySelectorAll('.tab');
        allTabs?.forEach(tab => {
            const tabElement = tab as HTMLElement;
            tabElement.classList.remove('active');
        });
    }

    private getAllCardsThatAreOnScreen(): HTMLElement[] {
        const allCardsElements = document.querySelectorAll('.card');
        const allCardsThatAreOnTheScreen: HTMLElement[] = [];
        allCardsElements.forEach((element, cardIndex) => {
            const card = element as HTMLElement;
            const cardParent = card.parentElement;
            const cardRect = card.getBoundingClientRect();
            const cardParentRect = cardParent.getBoundingClientRect();
            if ((cardRect.top < cardParentRect.top && cardRect.bottom < cardParentRect.top)
                || (cardRect.top > cardParentRect.bottom && cardRect.bottom < cardParentRect.bottom)) {
                return;
            }
            if (cardRect.bottom < cardParentRect.bottom || cardRect.top < cardParentRect.bottom) {
                allCardsThatAreOnTheScreen.push(card);
            }
        });
        return allCardsThatAreOnTheScreen;
    }
}
