<div class="exceptions-container">
    <div *ngIf="exceptionsForm" [formGroup]="exceptionsForm" class="form-container exceptions-form-wrapper"
         [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
         [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
         [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
         [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
         [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
        <!-- SPECIALITY -->
        <div *ngIf="getInputByControlName(formControlNames.SPECIALTY)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.SPECIALTY)?.styles">
            <label translate="label.speciality2">
            <span [style.visibility]="getInputByControlName(formControlNames.SPECIALTY)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
            </label>
            <div class="exceptions-filter sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedSpeciality($event)"
                              [buttonName]='"label.speciality2"'
                              [dependentFilters]="mainDependentFilters?.speciality"
                              [initialFilterValues]="specialityInitialValue"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="specialityProvider"
                              [ngClass]="{'input-element-has-error':dynamicFormInputService.hasError(exceptionsForm, formControlNames.SPECIALTY)}"
                              #specialitySbaseFilter
                              (clickOutside)="specialitySbaseFilter.showDropdown = false">
                </sbase-filter>
            </div>
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="exceptionsForm"
                                          [controlName]="formControlNames.SPECIALTY">
            </app-dynamic-form-input-error>
        </div>

        <!-- SERVICE -->
        <div *ngIf="getInputByControlName(formControlNames.SERVICE)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.SERVICE)?.styles">
            <label translate="label.service2">
            <span [style.visibility]="getInputByControlName(formControlNames.SERVICE)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
            </label>
            <div class="exceptions-filter sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                              [buttonName]='"label.service2"'
                              [dependentFilters]="mainDependentFilters?.service"
                              [initialFilterValues]="serviceInitialValue"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="serviceProvider"
                              [ngClass]="{'input-element-has-error':dynamicFormInputService.hasError(exceptionsForm, formControlNames.SERVICE)}"
                              #serviceSbaseFilter
                              (clickOutside)="serviceSbaseFilter.showDropdown = false"
                              [customTemplate]="serviceTemplate">
                </sbase-filter>
                <ng-template let-item #serviceTemplate>
                    <span *ngIf="!!item.specialityName">
                        <span class="font-weight-bold">{{item.specialityName}} </span>
                    </span>
                    <!-- in select -->
                    <span *ngIf="!item.nameWithoutSpeciality">
                        <span>{{item.name}}</span>
                    </span>
                    <!-- in dropdown -->
                    <span *ngIf="!!item.nameWithoutSpeciality">
                        <span>{{item.nameWithoutSpeciality}}</span>
                    </span>
                    <i *ngIf="item.onlineConsultation" class="fas fa-desktop ml-1"
                       ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                </ng-template>
            </div>
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="exceptionsForm"
                                          [controlName]="formControlNames.SERVICE">
            </app-dynamic-form-input-error>
        </div>

        <!-- RESOURCE -->
        <div *ngIf="getInputByControlName(formControlNames.RESOURCE)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.RESOURCE)?.styles">
            <label translate="label.resource">
            <span [style.visibility]="getInputByControlName(formControlNames.RESOURCE)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
            </label>
            <div class="exceptions-filter sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                              [buttonName]='"label.resource"'
                              [dependentFilters]="mainDependentFilters?.resource"
                              [initialFilterValues]="resourceInitialValue"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="resourceProvider"
                              [ngClass]="{'input-element-has-error':dynamicFormInputService.hasError(exceptionsForm, formControlNames.RESOURCE)}"
                              #resourceSbaseFilter
                              (clickOutside)="resourceSbaseFilter.showDropdown = false">
                </sbase-filter>
            </div>
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="exceptionsForm"
                                          [controlName]="formControlNames.RESOURCE">
            </app-dynamic-form-input-error>
        </div>

        <!-- VALID TO -->
        <app-dynamic-form-input
                [parentFormGroup]="exceptionsForm"
                [options]="validToFormOptions"
                [initialValue]="exceptionsForm?.get(formControlNames.VALID_TO)?.value"
        ></app-dynamic-form-input>
    </div>
    <div class="d-flex float-right mb-3 mt-1 update-buttons">
        <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                translate="button.save"
                (click)="onSaveException()"
                [disabled]="isSaveButtonDisabled">
        </button>
        <button class="btn border-0 p-0"
                translate="button.cancel"
                (click)="onCancelException()"></button>
    </div>

    <table class="table table-bordered grey-table" *ngIf="createPatientUtils?.patientControlExceptions?.length > 0">
        <thead class="thead-light">
        <tr>
            <td translate="label.service2" [style.width]="'28%'"></td>
            <td translate="label.speciality2" [style.width]="'28%'"></td>
            <td translate="label.resource" [style.width]="'28%'"></td>
            <td translate="label.validTo" [style.width]="'10%'"></td>
            <td translate="label.action" [style.width]="'6%'"></td>
        </tr>
        </thead>
        <tbody *ngFor="let exception of createPatientUtils.patientControlExceptions; let index = index">
        <tr>
            <td [style.width]="'28%'">
                <span class="font-weight-bold">{{exception?.service?.specialityName}} </span>
                <span>{{exception?.service?.name}}</span>
            </td>
            <td [style.width]="'28%'">
                <span>{{exception?.specialty?.name}}</span>
            </td>
            <td [style.width]="'28%'">
                <span>{{exception?.resource?.name}}</span>
            </td>
            <td [style.width]="'10%'">
                <span>{{exception?.validTo | dateMMDDYYYYBrowserTzFormat}}</span>
            </td>
            <td class="text-center" [style.width]="'6%'">
                <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                        popoverClass="popover-shadow">
                    <i class="fas fa-ellipsis-h"></i>
                </button>
                <ng-template #popContent>
                    <ul class="list-style-none m-0 p-0 custom-drop-down">
                        <li>
                            <button type="button" class="btn" translate="label.delete"
                                    (click)="onRemoveException(index)">
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn" translate="label.changeLog"
                                    (click)="displayChangeLog(exception.id, EntityTypeEnum.patientControlRuleException)"
                                    [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"
                            ></button>
                        </li>
                    </ul>
                </ng-template>
            </td>
        </tr>
        </tbody>
    </table>
</div>
