import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    AddressType,
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider,
    CenterType,
    ConfirmDeleteModalService,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CenterMdUtils} from './center-md-util';
import {AreaType} from 'sked-base/lib/data-model/areaTypes';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-center-md',
    templateUrl: './center-md.component.html',
    styleUrls: ['./center-md.component.scss']
})
export class CenterMDComponent implements OnInit, OnDestroy {

    constructor(
        public centerMdUtils: CenterMdUtils,
        public centerProvider: CenterProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public areaProvider: AreaProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    itemsPerPageList: number[];
    centerMdList: CenterType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    areaButtonName = 'label.area';
    areaFilter: AreaDependentFiltersType = {} as AreaDependentFiltersType;
    areas: AreaType[] = [];
    CENTER = 'Center';
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchCenterChanged = new Subject<string>();

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('CenterRead');
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
        this.areaFilter = this.centerMdUtils.getAreaDependentFilters();

        if (this.previousRouteService.getPreviousUrl() !== '/createCenter') {
            this.loadInitialFilter({tableFilters: true, sbaseFilters: true});
        }

        this.getCenterMdData();
        this.subscribeSearchByCenterChanged();
    }

    ngOnDestroy() {
    }

    // method to navigate create Center
    createCenter() {
        this.router.navigate(['/createCenter']);
    }

    editCenter(center: CenterType) {
        this.router.navigate(['/createCenter'], {state: {center, action: constants.EDIT}});
    }

    viewCenter(center: CenterType) {
        this.router.navigate(['/createCenter'], {state: {center, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.centerMdUtils.tableFilters.currentPage = page;
        this.getCenterMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.centerMdUtils.tableFilters.currentPage = 1;
        this.centerMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getCenterMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.centerMdUtils.tableFilters.orderBy[property];

        if (this.centerMdUtils.tableFilters.orderBy) {
            this.centerMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.getCenterMdData(false);
    }

    onSearchCenterChanged(value: string) {
        this.searchCenterChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.centerMdUtils.tableFilters.orderBy[selectedItem];
        this.getCenterMdData(false);
    }

    displayConfirmDeleteItemModal(centerId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.centerProvider, centerId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(centerId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onSelectedAreaFilter(areaFilterList) {
        this.centerMdUtils.initialAreaValues = areaFilterList;
        this.centerMdUtils.tableFilters.filter.area = (areaFilterList && areaFilterList.length > 0) ? areaFilterList[0] : undefined;
        this.getCenterMdData();
    }

    displayCenterAddress(address: AddressType): string {
        return this.generalUtils.displayItemsWithComma([
            address?.street, address?.streetNumber,
            address?.floor, address?.apartmentNumber
        ]);
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchCenterChanged('');
            this.loadInitialFilter({tableFilters: true, sbaseFilters: true});
            this.getCenterMdData();
        });
    }

    // function to get Center data
    private getCenterMdData(includeCount: boolean = true) {
        const queryFilter = this.centerMdUtils.getQueryFilterForCenterMD(this.centerMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.centerProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((centers) => {
                this.centerMdList = centers.value;
                if (centers.count !== undefined && centers.count !== null) {
                    this.totalTableItems = centers.count;
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.centerMdUtils.tableFilters = this.centerMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.centerProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getCenterMdData();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.centerDelete', true);
            });
    }

    private subscribeSearchByCenterChanged() {
        const self = this;
        this.searchCenterChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.centerMdUtils.tableFilters.filter.name = searchValue;
                self.getCenterMdData();
            });
    }

    private loadInitialFilter({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.centerMdUtils.tableFilters = this.centerMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.centerMdUtils.initialAreaValues = [];
        }
    }
}
