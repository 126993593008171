<div class="container-fluid room-reservation-export-wrapper">
    <h3 translate="label.exportReservation"></h3>
    <div class="room-reservation-export row">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs room-reservation-export-left col-5 col-xl-4"
                orientation="vertical">
                <li [ngbNavItem]="1">
                    <a ngbNavLink translate="label.exportReservation" class="font-weight-high-contrast"></a>
                    <ng-template ngbNavContent>
                        <div class="room-reservation-export-template">
                            <app-generate-export [options]="optionsForRoomReservation">
                            </app-generate-export>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" [activityDisplay]="'GenerateExportJobAvailabilitiesWithoutReservations'">
                    <a ngbNavLink translate="label.exportAvailabilitiesWithoutReservation"></a>
                    <ng-template ngbNavContent>
                        <app-generate-export [options]="optionsForAvailabilitiesWithoutReservation">
                        </app-generate-export>
                    </ng-template>
                </li>
            </ul>
        <div [ngbNavOutlet]="nav" class="room-reservation-export-right col-7 col-xl-8"></div>
    </div>
</div>
