export interface ValidRuleManagementWeightedCombinationObjectType {
    isAreaValid: boolean;
    isSpecialityValid: boolean;
    isServiceValid: boolean;
    isResourceValid: boolean;
    isCenterValid: boolean;
    isCoveragePlanValid: boolean;
    isChannelValid: boolean;
    isPatientTagValid: boolean;
}

export enum ValidTemplateRuleManagementWeightedCombinationEnum {
    Area = 'area',
    Speciality = 'speciality',
    Service = 'service',
    Resource = 'resource',
    Center = 'center',
    CoveragePlan = 'coveragePlan',
    Channel = 'channel',
    PatientTag = 'patientTag'
}
