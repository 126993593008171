import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {MultiResourceCombinationMDComponent} from './multi-resource-combination-md.component';
import {CreateMultiResourceCombinationComponent} from './create-multi-resource-combination/create-multi-resource-combination.component';

@NgModule({
    declarations: [MultiResourceCombinationMDComponent, CreateMultiResourceCombinationComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class MultiResourceCombinationMDModule { }
