import {Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {ObjectDetailType, ObjectDetailProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ObjectDetailMdUtils} from '../object-detail-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-object-detail',
    templateUrl: './create-object-detail.component.html',
    styleUrls: ['./create-object-detail.component.scss']
})
export class CreateObjectDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    constants = constants;
    initialObjectDetail: ObjectDetailType;
    objectDetailItem: ObjectDetailType = {} as ObjectDetailType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    textEditorConfig: AngularEditorConfig;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private objectDetailMdUtils: ObjectDetailMdUtils,
        private objectDetailProvider: ObjectDetailProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    saveObjectDetailData(objectDetail: ObjectDetailType) {
        const isTemplateValid = this.validateFields(objectDetail);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createObjectDetail(objectDetail);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialObjectDetail, objectDetail)) {
                    this.messagesService.success('toastr.success.objectDetailEdit', true);
                    this.goToParentPage();
                } else {
                    this.editObjectDetail(this.initialObjectDetail, objectDetail);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/objectDetails']);
    }

    goToEdit() {
        history.replaceState({objectDetail: this.objectDetailItem, action: constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    // function to create the new ObjectDetail
    private createObjectDetail(objectDetail: ObjectDetailType) {
        this.ngxLoader.start();
        this.objectDetailProvider.addEntry(objectDetail)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newObjectDetailAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ObjectDetail
    private editObjectDetail(oldObjectDetail: ObjectDetailType, newObjectDetail: ObjectDetailType) {
        this.ngxLoader.start();
        this.objectDetailProvider.updateEntry(oldObjectDetail, newObjectDetail)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.objectDetailEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(objectDetailItem: ObjectDetailType): boolean {
        return !!(objectDetailItem && objectDetailItem.name);
    }

    private setupInitialState() {
        this.loadInitialTextEditorConfig();
        if (history.state && history.state.objectDetail) {
            this.initialObjectDetail = history.state.objectDetail;
            this.objectDetailItem = lodash.cloneDeep(history.state.objectDetail);
            if (history.state.action === constants.VIEW) {
                this.disableTextEditor();
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.objectDetailItem = this.objectDetailMdUtils.getInitialObjectDetail();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private loadInitialTextEditorConfig() {
        this.textEditorConfig = {
            editable: true,
            height: 'calc(100vh - 400px)',
            width: 'auto',
            showToolbar: true,
            toolbarPosition: 'top',
            defaultFontName: 'Arial'
        };
    }

    private disableTextEditor() {
        this.textEditorConfig.editable = false;
        this.textEditorConfig.showToolbar = false;
    }
}
