import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    PaymentManagementRuleProvider,
    PaymentManagementRuleType,
    IncludeExcludeEnum,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    TagProvider,
    SearchFilterUtils,
    CoveragePlanDependentFiltersType,
    ActivatePaymentEnum,
    CoveragePlanType,
    TagDependentFiltersScopeEnum,
    CoveragePlanProvider
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {TagDependentFiltersType, TagType} from 'sked-base/lib/data-model/tagTypes';
import {PaymentManagementRuleTagsType} from '../payment-management-rule.types';
import {PaymentManagementRuleUtils} from '../payment-management-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-payment-management-rule',
    templateUrl: './create-payment-management-rule.component.html',
    styleUrls: ['./create-payment-management-rule.component.scss']
})
export class CreatePaymentManagementRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: PaymentManagementRuleType = {} as PaymentManagementRuleType;
    initialRule: PaymentManagementRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.PaymentManagementRule);

    IncludeExcludeEnum = IncludeExcludeEnum;
    activatePaymentEnumList = Object.values(ActivatePaymentEnum);
    paymentManagementRuleTags: PaymentManagementRuleTagsType;
    mainDependentFilters: {
        centerTags: TagDependentFiltersType,
        serviceTags: TagDependentFiltersType,
        resourceTags: TagDependentFiltersType,
        coveragePlan: CoveragePlanDependentFiltersType
    };
    initialCoveragePlan: CoveragePlanType[] = [];

    constructor(
        public paymentManagementRuleUtils: PaymentManagementRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private paymentManagementRuleProvider: PaymentManagementRuleProvider,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.ruleItem[option].hasOwnProperty('$ngOptionValue')) {
            this.ruleItem[option] = undefined;
        }
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as {invalid?: boolean, pending?: boolean} as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: PaymentManagementRuleType): boolean {
        return !!(rule.paymentManagementRuleTableId &&
            !lodash.isEmpty(rule.paymentManagementRuleTable) && rule.name);
    }

    isInputStepValid(rule: PaymentManagementRuleType): boolean {
        return !!(rule.serviceTagId && !this.generalUtils.isNullOrUndefined(rule.operationServiceTag)
            && (rule.centerTagId && !this.generalUtils.isNullOrUndefined(rule.operationCenterTag) || !rule.centerTagId && this.generalUtils.isNullOrUndefined(rule.operationCenterTag))
            && (rule.resourceTagId && !this.generalUtils.isNullOrUndefined(rule.operationResourceTag) || !rule.resourceTagId && this.generalUtils.isNullOrUndefined(rule.operationResourceTag))
        );
    }

    isOutputStepValid(rule: PaymentManagementRuleType): boolean {
        return !this.generalUtils.isUndefinedOrNull(rule.activatePayment) && !this.generalUtils.isUndefinedOrNull(rule.showPrice);
    }

    areAllStepsValid(rule: PaymentManagementRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.paymentManagementRuleTable = ruleTable[0];
            this.ruleItem.paymentManagementRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.paymentManagementRuleTable = undefined;
            this.ruleItem.paymentManagementRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedTag(entityName: string, tagList: TagType[]) {
        if (tagList?.length > 0) {
            this.ruleItem[entityName + 'Tag'] = tagList[0];
            this.ruleItem[entityName + 'TagId'] = tagList[0].id;
            this.ruleItem['operation' + lodash.upperFirst(entityName) + 'Tag'] = IncludeExcludeEnum.exclude;
        } else {
            this.ruleItem[entityName + 'Tag'] = undefined;
            this.ruleItem[entityName + 'TagId'] = undefined;
            this.ruleItem['operation' + lodash.upperFirst(entityName) + 'Tag'] = undefined;

        }
    }

    onChangeIncludeFlag(entityName: string, includeFlag: boolean) {
        this.ruleItem['operation' + lodash.upperFirst(entityName) + 'Tag'] =
            includeFlag ? this.IncludeExcludeEnum.include : this.IncludeExcludeEnum.exclude;
    }

    onSelectedCoveragePlan(coveragePlanList: CoveragePlanType[]): void {
        this.ruleItem.coveragePlan = coveragePlanList?.length > 0 ? coveragePlanList[0] : undefined;
        this.ruleItem.coveragePlanId = coveragePlanList?.length > 0 ? coveragePlanList[0].id : undefined;
    }
    // endregion Input methods

    // region Overview methods
    //
    saveRule(rule: PaymentManagementRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.paymentManagementRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: PaymentManagementRuleType) {
        this.ngxLoader.start();
        const ruleToSend: PaymentManagementRuleType =
            this.paymentManagementRuleUtils.mapRuleForServer(rule);
        this.paymentManagementRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newPaymentManagementRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: PaymentManagementRuleType, newRule: PaymentManagementRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: PaymentManagementRuleType =
            this.paymentManagementRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: PaymentManagementRuleType =
            this.paymentManagementRuleUtils.mapRuleForServer(newRule);
        this.paymentManagementRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.paymentManagementRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        this.paymentManagementRuleTags = this.paymentManagementRuleUtils.getInitialPaymentManagementRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.initialCoveragePlan = this.ruleItem.coveragePlanId ? [{id: this.ruleItem.coveragePlan?.id, name: this.ruleItem.coveragePlan?.name} as any as CoveragePlanType] : [];
                this.addSelectedTagsToTagInputs();
            }
        } else {
            this.ruleItem = this.paymentManagementRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private addSelectedTagsToTagInputs() {
        if (!lodash.isEmpty(this.ruleItem.centerTag)) {
            this.paymentManagementRuleTags.selectedCenterTags.push(this.ruleItem.centerTag as unknown as TagType);
            this.paymentManagementRuleTags.includeCenterTagFlag = this.ruleItem.operationCenterTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.serviceTag)) {
            this.paymentManagementRuleTags.selectedServiceTags.push(this.ruleItem.serviceTag as unknown as TagType);
            this.paymentManagementRuleTags.includeServiceTagFlag = this.ruleItem.operationServiceTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.resourceTag)) {
            this.paymentManagementRuleTags.selectedResourceTags.push(this.ruleItem.resourceTag as unknown as TagType);
            this.paymentManagementRuleTags.includeResourceTagFlag =
                this.ruleItem.operationResourceTag === this.IncludeExcludeEnum.include;
        }
    }

    loadDependentFilters() {
        this.mainDependentFilters = {
            centerTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedCenter, false, true),
            serviceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedService, false, true),
            resourceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedResource, false, true),
            coveragePlan: this.searchFilterUtils.getCoveragePlanDependentFilters()
        };
    }
}
