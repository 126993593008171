import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {
    CountryProvider,
    CountryType,
    LocalityProvider,
    ODataQueryObjectType,
    RegionProvider,
    RegionType
} from 'sked-base';
import {TranslatedLanguageService} from '../../../../shared/services/translated-language.service';
import * as lodash from 'lodash';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../../shared/services/messages.service';
import {LocalityType} from 'sked-base/lib/data-model/localityTypes';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {formControlNames} from '../../../../shared/constants/formControlNames';
import {CreatePatientUtils} from '../create-patient.utils';

@Component({
    selector: 'app-address-information-form',
    templateUrl: './address-information-form.component.html',
    styleUrls: ['./address-information-form.component.scss']
})
export class AddressInformationFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;

    addressInformationForm: FormGroup;
    countryPlaceholder = 'label.addressTemplate.country';
    regionPlaceholder = 'label.region';
    localityPlaceholder = 'label.locality';
    regions: RegionType[] = [];
    localities: LocalityType[] = [];
    allInputs: DynamicFormInputType[] = [];
    formControlNames = formControlNames;

    constructor(
        public dynamicFormInputService: DynamicFormInputService,
        public createPatientUtils: CreatePatientUtils,
        public regionProvider: RegionProvider,
        private translatedLanguageService: TranslatedLanguageService,
        private countryProvider: CountryProvider,
        private rootFormGroup: FormGroupDirective,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private localityProvider: LocalityProvider,
    ) {
    }

    ngOnInit(): void {
        this.addressInformationForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.preselectCountry();
    }

    onCountryValueChanged(selectedCountry: CountryType, preselectValueFromPatient: boolean = false) {
        const value = selectedCountry ? selectedCountry : {} as CountryType;
        this.addressInformationForm.get(formControlNames.COUNTRY).patchValue(value.countryName);
        if (!lodash.isEmpty(this.addressInformationForm.get(formControlNames.COUNTRY).value)) {
            this.resetRegionInput();
            if (preselectValueFromPatient && this.createPatientUtils.initialPatient?.regionId) {
                this.addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY].enable();
            }
            this.loadRegionsForCountry(selectedCountry?.id, preselectValueFromPatient);
            this.addressInformationForm.get(formControlNames.REGION).enable();
        } else {
            this.resetRegionInput();
        }
    }

    onRegionValueChanged(selectedRegion: RegionType, preselectValueFromPatient: boolean = false) {
        const value = selectedRegion ? selectedRegion : {} as RegionType;
        this.addressInformationForm.get(formControlNames.REGION).patchValue(value.name);
        this.loadLocalitiesForRegion(selectedRegion?.id, preselectValueFromPatient);
        if (!lodash.isEmpty(this.addressInformationForm.get(formControlNames.REGION).value)) {
            this.resetLocalityInput();
            this.addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY].enable();
        } else {
            this.resetLocalityInput();
        }
    }

    onLocalityValueChanged(selectedLocality: LocalityType | string) {
        const value = selectedLocality ? selectedLocality : {} as LocalityType;
        this.addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY].patchValue(
            (value as LocalityType)?.name ?? (typeof value === 'string' ? value : '')
        );
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private preselectCountry(): void {
        if (this.createPatientUtils.initialPatient?.countryId) {
            const country: CountryType = this.createPatientUtils.clientIdentificationCountries.find(
                ({id}) => id === this.createPatientUtils.initialPatient.countryId
            );
            this.onCountryValueChanged(country, true);
        } else if (this.addressInformationForm?.value[formControlNames.COUNTRY]) {
            const country: CountryType = this.createPatientUtils.clientIdentificationCountries.find(
                ({countryName}) => countryName === this.addressInformationForm.value[formControlNames.COUNTRY]
            );
            this.onCountryValueChanged(country, true);
        } else if (!this.addressInformationForm?.value[formControlNames.COUNTRY] && !this.addressInformationForm?.value[formControlNames.REGION]) {
            this.addressInformationForm?.patchValue({[formControlNames.COUNTRY]: '', [formControlNames.REGION]: ''});
        }
    }

    private resetRegionInput(): void {
        this.addressInformationForm.get(formControlNames.REGION)?.patchValue('');
        this.addressInformationForm.get(formControlNames.REGION)?.disable();
        this.resetLocalityInput();
    }

    private resetLocalityInput(): void {
        this.addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY]?.patchValue('');
        this.addressInformationForm.controls[formControlNames.ADDRESS_LOCALITY]?.disable();
    }

    private loadRegionsForCountry(countryId: string, preselectValueFromPatient: boolean = false) {
        if (countryId) {
            const regionFilterQuery = this.getRegionFilterQuery(countryId);
            this.ngxLoader.start();
            this.regionProvider.getEntries(regionFilterQuery)
                .pipe(take(1))
                .subscribe((regions) => {
                    this.regions = lodash.orderBy(regions.value, ['name']);
                    this.createPatientUtils.addressRegions = this.regions;
                    if (preselectValueFromPatient && this.createPatientUtils.initialPatient?.regionId) {
                        const region: RegionType = this.regions.find(
                            ({id}) => id === this.createPatientUtils.initialPatient.regionId
                        );
                        if (!region) {
                            // Region is incompatible with country. Let user through but don't prefill region.
                            // Without this the create patient page is blank
                            this.createPatientUtils.childComponentRequests.next();
                        }
                        this.onRegionValueChanged(region, preselectValueFromPatient);
                    }
                    // this is used in parent component in order to know when and which requests are done
                    this.createPatientUtils.childComponentRequestsDone.push(formControlNames.REGION);
                    const {regionId, address} = this.createPatientUtils.initialPatient;
                    if (!regionId && !address?.locality) {
                        this.createPatientUtils.childComponentRequests.next();
                    }
                    if (!regionId && !!address?.locality) {
                        // Region is not selected but locality is. Let user through but don't prefill region / locality.
                        // Without this the create patient page is blank
                        this.createPatientUtils.childComponentRequests.next();
                    }
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            this.regions = [] as RegionType[];
        }
    }

    private loadLocalitiesForRegion(regionId: string, preselectValueFromPatient: boolean = false) {
        if (regionId) {
            const localityFilterQuery = this.getLocalityFilterQuery(regionId);
            this.ngxLoader.start();
            this.localityProvider.getEntries(localityFilterQuery)
                .pipe(take(1))
                .subscribe((localities) => {
                    this.localities = lodash.orderBy(localities.value, ['name']);
                    this.createPatientUtils.addressLocalities = this.localities;
                    if (preselectValueFromPatient && this.createPatientUtils.initialPatient?.address?.locality) {
                        const locality: LocalityType | string = this.localities?.length > 0
                            ? this.localities.find(
                                ({name}) => name === this.createPatientUtils.initialPatient.address.locality
                            )
                            : this.createPatientUtils.initialPatient.address.locality;
                        this.onLocalityValueChanged(locality);
                    }
                    // this is used in parent component in order to know when and which requests are done
                    this.createPatientUtils.childComponentRequestsDone.push(formControlNames.ADDRESS_LOCALITY);
                    this.createPatientUtils.childComponentRequests.next();
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            this.localities = [] as LocalityType[];
        }
    }

    private getRegionFilterQuery(countryId: string): ODataQueryObjectType {
        return {
            count: true,
            select: ['Id', 'Name'],
            filter: {CountryId: {eq: {type: 'guid', value: countryId}}},
        };
    }

    private getLocalityFilterQuery(regionId: string): ODataQueryObjectType {
        return {
            count: true,
            select: ['Id', 'Name'],
            filter: {RegionId: {eq: {type: 'guid', value: regionId}}},
        };
    }
}
