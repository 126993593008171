import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {CenterType, FilterComponentChannelType, ObjectDetailRuleType, ObjectDetailType, ResourceType, RuleTableType, ServiceType} from 'sked-base';
import {TableFiltersType} from '../../../../data-model/general.type';

export interface ObjectDetailRuleTagsType {
    selectedTags: TagType[];
}

export interface ServiceModalFilterOptionsType {
    ngModel?: ServiceType;
}

export interface CenterModalFilterOptionsType {
    ngModel?: CenterType;
}

export interface ResourceModalFilterOptionsType {
    ngModel?: ResourceType;
}

export interface ChannelModalFilterOptionsType {
    ngModel?: FilterComponentChannelType;
}

export interface TagModalFilterOptionsType {
    ngModel?: TagType;
}

export interface ObjectDetailModalFilterOptionsType {
    ngModel?: ObjectDetailType;
}

export interface ObjectDetailRuleModalFiltersType {
    serviceOptions: ServiceModalFilterOptionsType;
    centerOptions: CenterModalFilterOptionsType;
    resourceOptions: ResourceModalFilterOptionsType;
    channelOptions: ChannelModalFilterOptionsType;
    tagOptions: TagModalFilterOptionsType;
    objectDetailOptions: ObjectDetailModalFilterOptionsType;
    areFiltersActive: boolean;
}

export interface ObjectDetailRuleStateType {
    tableFilters?: TableFiltersType;
    modalFilters?: ObjectDetailRuleModalFiltersType;
    ruleTableFilterItem?: RuleTableType;
}

export enum ObjectDetailRuleAppliesToEnum {
    resource = 'resource',
    service = 'service',
    center = 'center'
}
