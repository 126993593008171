import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BookingFiltersProvider,
    BookingFiltersTemplateType,
    EntityTypeEnum,
    GeneralProvider
} from 'sked-base';
import {take} from 'rxjs/operators';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem
} from '@angular/cdk/drag-drop';
import * as lodash from 'lodash';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {BookingFiltersManagementUtils} from './booking-filters-management.utils';
import {BackofficeBookingFiltersTemplateType} from './booking-filters-management.types';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-booking-filters-management',
    templateUrl: './booking-filters-management.component.html',
    styleUrls: ['./booking-filters-management.component.scss']
})
export class BookingFiltersManagementComponent implements OnInit, OnDestroy {
    scopedBookingFilters: BackofficeBookingFiltersTemplateType = this.bookingFiltersManagementUtils.getInitialBookingFilters();
    initialBookingFilters: BackofficeBookingFiltersTemplateType = {} as BackofficeBookingFiltersTemplateType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(private generalProvider: GeneralProvider,
                private messagesService: MessagesService,
                private router: Router,
                private bookingFiltersProvider: BookingFiltersProvider,
                private bookingFiltersManagementUtils: BookingFiltersManagementUtils,
                private ngxLoader: NgxUiLoaderService) {
    }

    ngOnInit() {
        this.getActiveFilters();
    }

    ngOnDestroy(): void {
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    dropMainFilters(event: CdkDragDrop<string[]>) {
        const list = new BehaviorSubject(this.scopedBookingFilters.mainFilters);
        const currentValue = list.getValue();
        if (event.currentIndex <= 1) {
            event.currentIndex = 2;
        }
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        list.next(currentValue);
    }

    goToParentPage() {
        this.router.navigate(['/dashboard']);
    }

    sendData(newBookingFilters: BackofficeBookingFiltersTemplateType) {
        const isFormValid = this.validateFields(newBookingFilters);

        if (isFormValid) {
            const oldBookingFiltersToSend: BookingFiltersTemplateType =
                this.bookingFiltersManagementUtils.mapBookingFiltersToSend(this.initialBookingFilters);
            const newBookingFiltersToSend: BookingFiltersTemplateType =
                this.bookingFiltersManagementUtils.mapBookingFiltersToSend(newBookingFilters);

            this.ngxLoader.start();
            this.bookingFiltersProvider.updateEntry(oldBookingFiltersToSend, newBookingFiltersToSend)
                .pipe(take(1))
                .subscribe(() => {
                    this.ngxLoader.stop();
                    this.messagesService.success('toastr.success.changesWereSavedWithSuccess', true);
                    this.goToParentPage();
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                });
        }
    }

    private getActiveFilters() {
        this.ngxLoader.start();
        forkJoin([this.bookingFiltersProvider.getActiveFilters(),
            this.bookingFiltersProvider.getEntries({})])
                .pipe(take(1))
                .subscribe(([{value: activeFilterList}, {value: bookingTemplate}]) => {
                    // at the moment, we have only single variant saved in the database and no new ones can be created
                    this.scopedBookingFilters = this.bookingFiltersManagementUtils.mapBookingFilters(activeFilterList, bookingTemplate[0]);
                    this.initialBookingFilters = lodash.cloneDeep(this.scopedBookingFilters);
                }, error => {
                    this.messagesService.handlingErrorMessage(error);
                    this.ngxLoader.stop();
                }, () => {
                    this.ngxLoader.stop();
                });
    }

    private validateFields(scopedBookingFilters: BackofficeBookingFiltersTemplateType): boolean {
        const foundMandatoryItemInAvailableFilters = lodash.find(scopedBookingFilters.availableFilters, {mandatory: true});
        const foundMandatoryItemInMoreFilters = lodash.find(scopedBookingFilters.moreFilters, {mandatory: true});
        const foundItem = lodash.find(scopedBookingFilters.mainFilters, (item) => {
            if (item.name === 'Center' || item.name === 'Resource') {
                return item;
            }
        });

        // Area, coverage plan and service are always the mandatory inputs
        // Area and coverage plan are not movable
        // So we only check the service if it is exist
        if (foundMandatoryItemInAvailableFilters || foundMandatoryItemInMoreFilters) {
            this.messagesService.warning('toastr.warning.serviceIsMandatory');
            return false;
        }

        if (!foundItem) {
            this.messagesService.warning('toastr.warning.minimumConditionForSlotSearch');
            return false;
        }

        return true;
    }
}
