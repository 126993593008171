<div class="container-fluid resource-utilization-manage-appointment-container overview-screen-md p-4 overflow-auto custom-scrollbar"
     *ngIf="displayManageAppointment">
    <!--MANAGE APPOINTMENT-->
    <div class="resource-utilization-manage-appointment-container">
        <div class="row">
            <div class="col-6 mt-2 text-dark">
                <h4 translate="label.manageAppointment"></h4>
            </div>
            <div class="col-6">
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn theme-btn border-0" *ngIf="false">
                        <span translate="label.manageResources"></span>
                    </button>
                    <sbase-cancel-appointment *ngIf="!!cancelAppointmentOptions"
                                              class="mr-2 d-flex"
                                              [options]="cancelAppointmentOptions"
                                              (appointmentCancelled)="onAppointmentCancelled()"
                    ></sbase-cancel-appointment>
                    <button type="button"
                            class="btn theme-alt-btn border-0 mr-2"
                            (click)="onReschedule()">
                        <span translate="label.reschedule"></span>
                    </button>
                    <sbase-appointment-status-change-button
                            [options]="appointmentStatusChangeOptions"
                            (appointmentStatusChangeAction)="onAppointmentStatusChange($event)">
                    </sbase-appointment-status-change-button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 py-3">
                <h5 class="font-weight-bold text-dark" translate="label.appointmentInfo"></h5>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <!--PATIENT NAME-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.patientName"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.patient?.name}}</div>
                    </div>
                </div>
                <!--PHONE NUMBERS-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.phoneNumbers"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.patient?.mainPhoneNumber}}</div>
                        <div>{{resourceUtilizationAppointment?.patient?.alternatePhoneNumber}}</div>
                    </div>
                </div>
                <!--RESOURCES-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.resources"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div *ngFor="let resource of resourceUtilizationAppointment?.resourceAppointments"
                             [ngClass]="resource?.mainResource ? 'font-weight-bold text-dark' : ''">
                            {{resource?.name}}
                        </div>
                    </div>
                </div>
                <!--SERVICE-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.service2"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.service?.name}}</div>
                    </div>
                </div>
                <!--SUB SERVICES-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.subServices"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div *ngFor="let subService of resourceUtilizationAppointment?.subServices | slice:0:3">
                            {{subService.name}}
                        </div>
                        <ng-template #subServicesContent>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let subService of resourceUtilizationAppointment?.subServices">{{subService.name}}</div>
                            </div>
                        </ng-template>
                        <div *ngIf="resourceUtilizationAppointment?.subServices && resourceUtilizationAppointment?.subServices.length>3">
                            <div [ngbPopover]="subServicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                            </div>
                        </div>
                    </div>
                </div>
                <!--CENTER-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.center2"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.center?.name}}</div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <!--STATUS-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.status"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{"enum.appointmentStatus." + resourceUtilizationAppointment?.status | translate}}</div>
                    </div>
                </div>
                <!--APPOINTMENT TIME-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.appointmentTime"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.dateTimeFrom?.slice(11, 16)}}</div>
                    </div>
                </div>
                <!--DURATION-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.duration"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.duration}} {{'label.minuteShort' | translate}}</div>
                    </div>
                </div>
                <!--APPOINTMENT TYPE-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.appointmentType"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.appointmentType?.name}}</div>
                    </div>
                </div>
                <!--COVERAGE PLAN-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.coveragePlan"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div>{{resourceUtilizationAppointment?.coveragePlan?.name}}</div>
                    </div>
                </div>
                <!--PATIENT COVERAGE PLANS-->
                <div class="row pb-5">
                    <div class="col-5 font-weight-bold text-dark" translate="label.patientCoveragePlans"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div *ngFor="let coveragePlan of resourceUtilizationAppointment?.patientCoveragePlans; last as isLast">
                            <ngx-ui-loader class="local-spinner position-relative mt-2" [hasProgressBar]="false"
                                           [loaderId]="patientCoveragePlansSpinnerId"
                                           [fgsSize]="20"
                            ></ngx-ui-loader>
                            {{coveragePlan?.name}}<span *ngIf="!isLast">, </span>
                        </div>
                    </div>
                </div>
                <!--COMMENTS-->
                <div class="row pb-2">
                    <div class="col-5 font-weight-bold text-dark" translate="label.comments"></div>
                    <div class="col-7 text-dark font-weight-normal">
                        <div *ngFor="let comment of resourceUtilizationAppointment?.comments; last as isLast">
                            <ngx-ui-loader class="local-spinner position-relative mt-2" [hasProgressBar]="false"
                                           [loaderId]="appointmentCommentsSpinnerId"
                                           [fgsSize]="20"
                            ></ngx-ui-loader>
                            {{comment.content}}<br *ngIf="!isLast"></div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="optionalResourceManagementActivity">
            <!--APPOINTMENT ACTIVITIES-->
            <div class="col-6 pr-5 resource-utilization-manage-appointment-activities-container">
                <h4 translate="label.appointmentActivities" class="font-weight-bold text-dark"></h4>
                <!--APPOINTMENT ACTIVITIES INPUT-->
                <form name="form" novalidate class="pt-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input type="text" name="name"
                                       class="form-control rounded-0 resource-utilization-manage-appointment-activities-input p-0"
                                       [(ngModel)]="newActivityName"
                                       placeholder="{{'label.addNewActivity'|translate}}"
                                       autocomplete="off"
                                       (keyup.enter)="addActivity(newActivityName)">
                            </div>
                        </div>
                    </div>
                    <!--APPOINTMENT ACTIVITIES ADD AND CLEAR BUTTONS-->
                    <div class="row">
                        <div class="col-9"></div>
                        <div class="col-1 text-themecolor font-weight-bold cursor-pointer"
                             (click)="addActivity(newActivityName)" translate="label.add"></div>&nbsp;
                        <div class="col-1 text-dark font-weight-bold cursor-pointer"
                             (click)="clearNewActivityInput()"
                             translate="label.clear"></div>
                    </div>
                </form>
                <!--APPOINTMENT ACTIVITIES TO DO SECTION-->
                <div *ngIf="resourceUtilizationAppointment?.activities?.length > 0" translate="label.todo"
                     class="font-weight-bold text-dark pt-2 text-uppercase"></div>
                <div *ngFor="let activity of resourceUtilizationAppointment?.activities" class="mt-2">
                    <div *ngIf="activity?.status === appointmentActivityStatusEnum.Todo"
                         class="resource-utilization-item-container border border-1 p-1">
                        <div class="text-dark font-weight-bold pl-3">{{activity?.name}}
                            , {{'label.todo' | translate | lowercase}}</div>
                        <button type="button" class="btn theme-alt-btn border-0"
                                (click)="changeActivityStatus(activity)"
                                translate="label.done">
                        </button>
                        <div class="text-center cursor-pointer" (click)="deleteActivityFromList(activity?.name)">
                            <i class="fas fa-times ng-value-icon"></i>
                        </div>
                    </div>
                </div>
                <!--APPOINTMENT ACTIVITIES DONE SECTION-->
                <div *ngIf="resourceUtilizationAppointment?.activities?.length > 0" translate="label.done"
                     class="font-weight-bold text-dark pt-2 text-uppercase"></div>
                <div *ngFor="let activity of resourceUtilizationAppointment?.activities" class="mt-2">
                    <div *ngIf="activity?.status === appointmentActivityStatusEnum.Done"
                         class="resource-utilization-item-container border border-1 p-1">
                        <div class="pl-3 text-dark resource-utilization-item-done-btn">{{activity?.name}}
                            , {{'label.done' | translate | lowercase}}</div>
                        <button type="button" class="btn theme-btn border-0"
                                (click)="changeActivityStatus(activity)"
                                translate="label.todo">
                        </button>
                        <div class="text-center cursor-pointer" (click)="deleteActivityFromList(activity?.name)">
                            <i class="fas fa-times ng-value-icon"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!--OPTIONAL RESOURCES-->
            <div class="col-6 pl-5">
                <h4 translate="label.optionalResources" class="font-weight-bold text-dark"></h4>
                <!--OPTIONAL RESOURCES SELECT-->
                <div class="row pt-2">
                    <div class="col-12 resource-utilization-optional-resources-select">
                        <sbase-filter (selectedItemsOutput)="addOptionalResource($event)"
                                      [buttonName]="'label.selectResource2'"
                                      [dependentFilters]="optionalResourcesDependentFilter"
                                      [initialFilterValues]="resourceUtilizationAppointment?.optionalResources"
                                      [additionalExclusionList]="optionalResourceAdditionalExclusionList"
                                      [isMultipleSelectedItem]="true"
                                      [useSelectedValueAsLabel]="false"
                                      [hideSelectedItems]="true"
                                      [providerInstance]="resourceProvider">
                        </sbase-filter>
                    </div>
                </div>
                <!--OPTIONAL RESOURCES UNCONFIRMED SECTION-->
                <div *ngIf="resourceUtilizationAppointment?.optionalResources?.length > 0"
                     translate="label.unconfirmed"
                     class="font-weight-bold text-dark pt-2 text-uppercase"></div>
                <div *ngFor="let resource of resourceUtilizationAppointment?.optionalResources" class="mt-2">
                    <div *ngIf="resource?.optionalResourceStatus === optionalResourceStatusEnum.Pending || resource?.optionalResourceStatus === optionalResourceStatusEnum.Rejected"
                         [ngClass]="resource?.optionalResourceStatus === optionalResourceStatusEnum.Rejected ?
                         'rejected-btn-container resource-utilization-item-container p-1' : 'resource-utilization-item-container border border-1 p-1'">
                        <div class="font-weight-bold text-dark pl-3">{{resource?.name}}</div>
                        <button *ngIf="resource?.optionalResourceStatus === optionalResourceStatusEnum.Pending"
                                type="button" class="btn theme-alt-btn border-0"
                                (click)="changeResourceStatus(resource)"
                                translate="label.confirmLabel">
                        </button>
                        <button *ngIf="resource?.optionalResourceStatus === optionalResourceStatusEnum.Rejected"
                                type="button" class="btn alert-btn border-0 font-weight-bold"
                                translate="label.rejected">
                        </button>
                        <div class="text-center cursor-pointer" (click)="deleteResourceFromList(resource)">
                            <i class="fas fa-times ng-value-icon"></i>
                        </div>
                    </div>
                </div>
                <!--OPTIONAL RESOURCES CONFIRMED SECTION-->
                <div *ngIf="resourceUtilizationAppointment?.optionalResources?.length > 0"
                     translate="label.confirmed"
                     class="font-weight-bold text-dark pt-2 text-uppercase"></div>
                <div *ngFor="let resource of resourceUtilizationAppointment?.optionalResources" class="mt-2">
                    <div *ngIf="resource?.optionalResourceStatus === optionalResourceStatusEnum.Accepted"
                         class="resource-utilization-item-container border border-1 p-1">
                        <div class="font-weight-bold text-dark pl-3">{{resource?.name}}</div>
                        <button type="button" class="btn alert-btn border-0"
                                (click)="changeResourceStatus(resource)"
                                translate="label.reject">
                        </button>
                        <div class="text-center cursor-pointer"
                             (click)="deleteResourceFromList(resource)">
                            <i class="fas fa-times ng-value-icon align-self-center"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 pt-3">
            <button type="button" class="btn back-btn mr-3" translate="label.back"
                    (click)="goToParentPage(actionNameEnum.Back)">
            </button>
            <button *ngIf="optionalResourceManagementActivity" type="button"
                    class="btn theme-btn border-0 mr-3"
                    translate="label.save"
                    (click)="saveManageAppointmentData(resourceUtilizationAppointment)">
            </button>
        </div>
    </div>
</div>
