import {Component, EventEmitter, OnInit} from '@angular/core';
import {TableFiltersType} from '../../../data-model/general.type';
import {Router} from '@angular/router';
import {AvailabilityUtils} from '../../../features/availability/availability-utils';
import {
    AvailabilityProvider,
    BlockedAppointmentPreviewType,
    BlockedAppointmentsPreviewStatusType,
    BlockedAppointmentsPreviewType,
    CenterProvider,
    ExclusionProvider,
    FilterWrapperNameEnum,
    GenericFilterOptionsType,
    GenericFilterResultType,
    ResourceProvider, SearchFilterUtils
} from 'sked-base';
import {switchMap, take} from 'rxjs/operators';
import {MessagesService} from '../../services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Observable, of} from 'rxjs';
import * as lodash from 'lodash';
import {AvailabilityTypeEnum, AvailabilityViewPageEnum} from '../../../features/availability/availability.types';
import {ExclusionViewPageEnum} from '../../../features/exclusion/exclusion.types';

@Component({
    selector: 'app-blocked-appointments-preview',
    templateUrl: './blocked-appointments-preview.component.html',
    styleUrls: ['./blocked-appointments-preview.component.scss']
})
export class BlockedAppointmentsPreviewComponent implements OnInit {
    filterWrapperOptions: GenericFilterOptionsType[] = [];
    updateFiltersValue: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    statusForBlockedAppointments: BlockedAppointmentsPreviewStatusType;
    blockedAppointmentsPreview: BlockedAppointmentsPreviewType;
    filteredBlockedAppointments: BlockedAppointmentPreviewType[] = [];

    private id: string;
    private type: AvailabilityTypeEnum;
    private view: AvailabilityViewPageEnum | ExclusionViewPageEnum;
    private tableFilters: TableFiltersType = {} as TableFiltersType;

    private filterWrapperValues: GenericFilterResultType[] = [];
    private resourceButtonName = 'label.resource';
    private centerButtonName = 'label.center';

    constructor(public availabilityProvider: AvailabilityProvider,
                private exclusionProvider: ExclusionProvider,
                public availabilityUtils: AvailabilityUtils,
                public centerProvider: CenterProvider,
                public messagesService: MessagesService,
                public ngxLoader: NgxUiLoaderService,
                public resourceProvider: ResourceProvider,
                public router: Router,
                public searchFilterUtils: SearchFilterUtils) {
    }

    ngOnInit(): void {
        this.id = history?.state?.id;
        this.type = history?.state?.type;
        this.view = history?.state?.view;
        if (this.id === null || this.id === undefined) {
            this.goToParentPage();
        } else {
            this.filterWrapperOptions = this.getFilterWrapperOptions();
            this.tableFilters.filter = {};

            this.loadData();
        }
    }

    runSimulation() {
        this.ngxLoader.start();
        this.startPreviewForBlockedAppointmentsByType(this.type)
            .pipe(take(1))
            .subscribe(response => {
                if (this.type) {
                    this.messagesService.success('toastr.success.simulationHasStarted');
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    goToParentPage() {
        this.view = this.view ?? ExclusionViewPageEnum.ExclusionOverview;
        if (this.view) {
            this.router.navigate(['/' + this.view]);
        }
    }

    onFilterWrapperValueChanged(filtersValue): void {
        this.filterWrapperValues = filtersValue;

        for (const item of this.filterWrapperValues) {
            this.tableFilters.filter[item.name] = item.value;
        }
        this.filteredBlockedAppointments = this.getFilteredBlockedAppointments(this.tableFilters);
    }

    private getFilterWrapperOptions = (): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.searchFilterUtils.getResourceDependentFilters(),
                disableFilter: false,
                label: this.resourceButtonName,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.searchFilterUtils.getLocationDependentFilters(),
                disableFilter: false,
                label: this.centerButtonName,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            }]
    )

    loadData() {
        this.ngxLoader.start();
        this.getStatusForPreviewBlockedAppointmentsByType(this.type).pipe(
            take(1),
            switchMap((statusForBlockedAppointments: BlockedAppointmentsPreviewStatusType) => {
                this.statusForBlockedAppointments = statusForBlockedAppointments;
                if (statusForBlockedAppointments.status === 'FinishedSucessfully') {
                    return this.getPreviewForBlockedAppointmentsByType(this.type).pipe(take(1));
                }
                return of(undefined);
            }),
        ).subscribe((blockedAppointmentsPreview: BlockedAppointmentsPreviewType) => {
            if (blockedAppointmentsPreview) {
                this.blockedAppointmentsPreview = blockedAppointmentsPreview;
                this.filteredBlockedAppointments = this.blockedAppointmentsPreview.appointments;
            }
        }, err => {
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private getFilteredBlockedAppointments(tableFilters: TableFiltersType): BlockedAppointmentPreviewType[] {
        let filteredBlockedAppointments: BlockedAppointmentPreviewType[] = [];
        filteredBlockedAppointments = lodash.cloneDeep(this.blockedAppointmentsPreview?.appointments);

        if (tableFilters?.filter?.resource?.id) {
            filteredBlockedAppointments = lodash.filter(filteredBlockedAppointments, {resourceId: tableFilters.filter.resource.id});
        }

        if (tableFilters?.filter?.location?.id) {
            filteredBlockedAppointments = lodash.filter(filteredBlockedAppointments, {centerId: tableFilters.filter.location.id});
        }

        return filteredBlockedAppointments;
    }

    private startPreviewForBlockedAppointmentsByType(type: AvailabilityTypeEnum) {
        if (type === AvailabilityTypeEnum.Availability) {
            return this.availabilityProvider.startPreviewForBlockedAppointments(this.id);
        } else if (type === AvailabilityTypeEnum.Exclusion) {
            return this.exclusionProvider.startPreviewForBlockedAppointments(this.id);
        } else {
            return of(undefined);
        }
    }

    private getStatusForPreviewBlockedAppointmentsByType(type: AvailabilityTypeEnum): Observable<BlockedAppointmentsPreviewStatusType> {
        if (type === AvailabilityTypeEnum.Availability) {
            return this.availabilityProvider.getStatusForPreviewBlockedAppointments(this.id);
        } else if (type === AvailabilityTypeEnum.Exclusion) {
            return this.exclusionProvider.getStatusForPreviewBlockedAppointments(this.id);
        }
    }

    private getPreviewForBlockedAppointmentsByType(type: AvailabilityTypeEnum): Observable<BlockedAppointmentsPreviewType> {
        if (type === AvailabilityTypeEnum.Availability) {
            return this.availabilityProvider.getPreviewForBlockedAppointments(this.id);
        } else if (type === AvailabilityTypeEnum.Exclusion) {
            return this.exclusionProvider.getPreviewForBlockedAppointments(this.id);
        }
    }

}
