import {Injectable} from '@angular/core';
import {
    SlotFilterRuleType,
    Expand,
    IncludeExcludeEnum,
    ODataQueryObjectType,
    GenderEnum,
    CoveragePlanType,
    TagType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AgeModalFilterOptionsType,
    CoveragePlanModalFilterOptionsType,
    GenderModalFilterOptionsType,
    MessageModalFilterOptionsType,
    SlotFilterRuleModalFiltersType,
    SlotFilterRuleStateType,
    SlotFilterRuleTagsType,
    TagModalFilterOptionsType,
} from './slot-filter-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class SlotFilterRuleUtils {
    overviewState: SlotFilterRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'slotFilterRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.onlyWarning',
            hasSort: true,
            onSortByProperty: 'onlyWarning',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.gender',
            hasSort: true,
            onSortByProperty: 'gender',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.age',
            hasSort: true,
            onSortByProperty: 'age',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.coveragePlan2',
            hasSort: true,
            onSortByProperty: 'coveragePlan',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.availabilityTag',
            hasSort: true,
            onSortByProperty: 'tag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientTag',
            hasSort: true,
            onSortByProperty: 'patientTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.message',
            hasSort: true,
            onSortByProperty: 'message',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'slotFilterRuleTableId':
                    case 'coveragePlanId':
                    case 'tagId':
                    case 'patientTagId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'gender':
                    case 'age': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'slotFilterRuleTable' || item === 'coveragePlan' || item === 'tag' || item === 'patientTag') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            Tag: {select: EXPAND_TAG_SELECT},
            PatientTag: {select: EXPAND_TAG_SELECT},
            CoveragePlan: {select: ['Id', 'Name']},
            SlotFilterRuleTable: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): SlotFilterRuleType {
        return {
            name: undefined,
            active: false,
            slotFilterRuleTableId: undefined,
            onlyWarning: false,
            gender: undefined,
            operationGender: undefined,
            age: undefined,
            operationAge: undefined,
            coveragePlanId: undefined,
            operationCoveragePlan: undefined,
            tagId: undefined,
            operationTag: undefined,
            patientTagId: undefined,
            includePatientTag: undefined,
            patientType: undefined,
            message: undefined,
        } as SlotFilterRuleType;
    }

    getInitialModalFilters(): SlotFilterRuleModalFiltersType {
        return {
            genderOptions: {
                ngModel: undefined
            } as GenderModalFilterOptionsType,
            ageOptions: {
                ngModel: null
            } as AgeModalFilterOptionsType,
            coveragePlanOptions: {
                ngModel: []
            } as CoveragePlanModalFilterOptionsType,
            availabilityTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            patientTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            messageOptions: {
                ngModel: ''
            } as MessageModalFilterOptionsType,
            areFiltersActive: false,
        } as SlotFilterRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: SlotFilterRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getInitialSlotFilterRuleTags(): SlotFilterRuleTagsType {
        return {
            selectedAvailabilityTags: [],
            selectedPatientTags: [],
            includeAvailabilityTagFlag: true,
            includePatientTagFlag: true,
            includeCoveragePlanFlag: true,
            includeGenderFlag: true
        } as SlotFilterRuleTagsType;
    }

    displayIncludeIcon(property: GenderEnum | CoveragePlanType | TagType, operationProperty: IncludeExcludeEnum): boolean {
        return !!(property && operationProperty === IncludeExcludeEnum.include);
    }

    displayExcludeIcon(property: GenderEnum | CoveragePlanType | TagType, operationProperty: IncludeExcludeEnum): boolean {
        return !!(property && operationProperty === IncludeExcludeEnum.exclude);
    }
}
