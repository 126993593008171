<!-- Main Resource -->
<ng-container *ngIf="!!options?.mainResource && options?.isMainResource">
    <!-- ADDITIONAL INFORMATION -->
    <span class="pr-1"
          *ngIf="options?.mainResource?.additionalInformation"
          [ngClass]="{'op-5': !options.bookable}">
                <i [ngClass]="options.additionalInfoIconClass ? options.additionalInfoIconClass : 'fa-light fa-circle-info'"
                   [ngbPopover]="options?.mainResource?.additionalInformation"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   container="body"
                   [placement]="options?.isFirstSlot ? 'bottom' : options?.isLastSlot ? 'top' : 'right'"></i>
            </span>
    <span *ngIf="!options?.mainResource?.additionalInformation" class="empty-space pr-1"></span>
    <!-- DESCRIPTION AND EXTERNAL URL FOR DESCRIPTION -->
    <span class="pr-1"
          [ngClass]="{'op-5': !options.bookable}"
          *ngIf="generalUtils.isValueTrue(options?.mainResource?.hasDescription) || options?.mainResource?.externalUrlForDescription"
          (click)="$event.stopPropagation(); openModalOrNewTab(options?.mainResource)">
        <i class="fas fa-external-link-alt"></i>
    </span>
    <span *ngIf="!generalUtils.isValueTrue(options?.mainResource?.hasDescription) || options?.mainResource?.externalUrlForDescription"
          class="empty-space pr-1"></span>
</ng-container>
<!-- Single Secondary Resource -->
<ng-container *ngIf="options?.secondaryResources?.length === 1 && !options?.isMainResource">
    <!-- ADDITIONAL INFORMATION -->
    <span class="pr-1"
          *ngIf="options?.secondaryResources[0]?.additionalInformation"
          [ngClass]="{'op-5': !options.bookable}">
                <i [ngClass]="options.additionalInfoIconClass ? options.additionalInfoIconClass : 'fa-light fa-circle-info'"
                   [ngbPopover]="options?.secondaryResources[0]?.additionalInformation"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   container="body"
                   [placement]="options?.isFirstSlot ? 'bottom' : options?.isLastSlot ? 'top' : 'right'"></i>
            </span>
    <span *ngIf="!options?.secondaryResources[0]?.additionalInformation" class="empty-space pr-1"></span>
    <!-- DESCRIPTION AND EXTERNAL URL FOR DESCRIPTION -->
    <span class="pr-1"
          *ngIf="generalUtils.isValueTrue(options?.secondaryResources[0]?.hasDescription) || options?.secondaryResources[0]?.externalUrlForDescription"
          (click)="$event.stopPropagation(); openModalOrNewTab(options?.secondaryResources[0])">
        <i class="fas fa-external-link-alt"></i>
    </span>
    <span *ngIf="options?.secondaryResources[0]?.hasDescription === 'False' || options?.secondaryResources[0]?.externalUrlForDescription"
          class="empty-space pr-1"></span>
</ng-container>
<!-- Multiple Secondary Resources -->
<ng-container *ngIf="options?.secondaryResources?.length > 1 && !options?.isMainResource">
    <span class="pr-1 float-right">
        <span>
            <div [ngbPopover]="secondaryResourceContent"
                 triggers="mouseenter:mouseleave"
                 popoverClass="popover-shadow"
                 [placement]="options?.isFirstSlot ? 'bottom' : options?.isLastSlot ? 'top' : 'left'"
                 (click)="$event.stopPropagation(); openModalWithMultipleSecondaryResources()"
                 class="fas fa-plus-square">
            </div>
        </span>
        <ng-template #secondaryResourceContent>
            <div class="table-tooltip-container">
                <div *ngFor="let secondaryResource of options?.secondaryResources; last as isLast">
                            {{secondaryResource?.name}}
                    <br *ngIf="!isLast">
                </div>
            </div>
        </ng-template>
    </span>
</ng-container>
