import {Component, Input, OnInit} from '@angular/core';
import {RuleTypeEnum} from 'sked-base';
import {RulesUtils} from '../rules.utils';

@Component({
    selector: 'app-rules-outlet',
    templateUrl: './rules-outlet.component.html',
    styleUrls: ['./rules-outlet.component.scss']
})
export class RulesOutletComponent implements OnInit {
    ruleTypeEnum = RuleTypeEnum;

    constructor(
        public rulesUtils: RulesUtils,
    ) {
    }

    ngOnInit(): void {
    }

}
