<div class="pdf-image-viewer-container">

    <!-- Controls -->
    <div class="viewer-controls-container" *ngIf="!!files">
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.ZoomIn)"
                ngbTooltip="{{'label.zoomIn'| translate}}"
                placement="bottom">
            <i class="fa fa-plus"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.ZoomOut)"
                ngbTooltip="{{'label.zoomOut'| translate}}"
                placement="bottom">
            <i class="fa fa-minus"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.RotateLeft)"
                ngbTooltip="{{'label.rotateLeft'| translate}}"
                placement="bottom">
            <i class="fa fa-undo"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.RotateRight)"
                ngbTooltip="{{'label.rotateRight'| translate}}"
                placement="bottom">
            <i class="fa fa-redo"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.Reset)"
                ngbTooltip="{{'label.reset'| translate}}"
                placement="bottom">
            <i class="fas fa-sync-alt"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.Download)"
                ngbTooltip="{{'label.download'| translate}}"
                placement="bottom">
            <i class="fas fa-download"></i>
        </button>
        <button class="btn theme-btn min-width-auto control-btn"
                (click)="onControlAction(viewerActionTypeEnum.Print)"
                ngbTooltip="{{'label.print'| translate}}"
                placement="bottom">
            <i class="fas fa-print"></i>
        </button>
    </div>

    <!-- Carousel -->
    <ngb-carousel [interval]="false" *ngIf="!!files; else errorBlock" (slide)="onCarouselSlide($event)">
        <ng-container *ngFor="let file of files; let index = index">

            <!-- PDF Viewer tab -->
            <ng-container *ngIf="file.type === viewerFileTypeEnum.PDF">
                <ng-template ngbSlide [id]="'ngb-slide-' + index">
                    <div class="pdf-image-viewer-tab-wrapper">
                        <pdf-viewer
                                [src]="file.blobSrc"
                                [rotation]="!!files[currentCarouselIndex]?.options?.pdfOptions?.rotation ? files[currentCarouselIndex].options.pdfOptions.rotation : 0"
                                [original-size]="false"
                                [show-all]="true"
                                [fit-to-page]="false"
                                [zoom]="!!files[currentCarouselIndex]?.options?.pdfOptions?.zoom ? files[currentCarouselIndex].options.pdfOptions.zoom : 1"
                                [zoom-scale]="'page-width'"
                                [stick-to-page]="false"
                                [render-text]="true"
                                [external-link-target]="'blank'"
                                [autoresize]="true"
                                [show-borders]="false"
                        ></pdf-viewer>
                    </div>
                </ng-template>
            </ng-container>

            <!-- IMAGE Viewer tab -->
            <ng-container *ngIf="file.type === viewerFileTypeEnum.IMG">
                <ng-template ngbSlide [id]="'ngb-slide-' + index">
                    <div class="pdf-image-viewer-tab-wrapper">
                        <div class="image-viewer-container"
                             [ngStyle]="!!file.options?.imageOptions?.styles ? file.options.imageOptions.styles : null"
                             (wheel)="onImageControlScroll($event)"
                             (dragover)="onImageControlDragOver($event)">
                            <img [src]="file.blobSrc"
                                 [alt]="file.name"
                                 (dragstart)="onImageControlDragStart($event)">
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    </ngb-carousel>

    <!-- Error block -->
    <ng-template #errorBlock>
        <ng-container *ngIf="displayError">
            <div class="pdf-image-viewer-error-container">
                <span translate="label.pdfImageViewerErrorText"></span>
                <button class="btn theme-btn min-width-auto"
                        (click)="loadFiles()">
                    <i class="fas fa-sync-alt"></i>
                </button>
            </div>
        </ng-container>
    </ng-template>

    <!-- Loader -->
    <ngx-ui-loader
            [loaderId]="loaderId"
            [hasProgressBar]="false"
    ></ngx-ui-loader>
</div>
