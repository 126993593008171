import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AreaProvider,
    ChannelProvider,
    FilterWrapperAreaSearchType,
    FilterWrapperSpecialitySearchType,
    GeneralUtils as SkedBaseGeneralUtils,
    SpecialityProvider,
    TimeWindowSpecialityAreaProvider,
    TimeWindowSpecialityAreaType,
    Validations,
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TimeWindowSpecialityAreaMdUtils} from '../time-window-speciality-area-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    TimeWindowForAreaAndSpecialityFiltersType,
    TimeWindowForAreaAndSpecialityMainDependentFiltersType
} from '../time-window-speciality-area.types';
import {ConfigDataService} from '../../../shared/services/config-data.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-time-window-speciality-area',
    templateUrl: './create-time-window-speciality-area.component.html',
    styleUrls: ['./create-time-window-speciality-area.component.scss']
})
export class CreateTimeWindowSpecialityAreaComponent implements OnInit, OnDestroy {
    readonly CONSTANTS = constants;
    MAX_NUMBER_OF_DAYS: number;
    initialTimeWindowSpecialityArea: TimeWindowSpecialityAreaType;
    timeWindowSpecialityAreaItem: TimeWindowSpecialityAreaType = {} as TimeWindowSpecialityAreaType;
    screenTemplateLayout: ScreenTemplateLayoutType;

    mainDependentFilters: TimeWindowForAreaAndSpecialityMainDependentFiltersType;
    initialFilterValues: TimeWindowForAreaAndSpecialityFiltersType = this.timeWindowSpecialityAreaMdUtils.getInitialFilterValues();

    constructor(
        public areaProvider: AreaProvider,
        public channelProvider: ChannelProvider,
        public generalUtils: GeneralUtils,
        public specialityProvider: SpecialityProvider,
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private skedBaseGeneralUtils: SkedBaseGeneralUtils,
        private timeWindowSpecialityAreaMdUtils: TimeWindowSpecialityAreaMdUtils,
        private timeWindowSpecialityAreaProvider: TimeWindowSpecialityAreaProvider,
        private validations: Validations
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveTimeWindowSpecialityAreaData(timeWindowSpecialityArea: TimeWindowSpecialityAreaType) {
        const isTemplateValid = this.validateFields(timeWindowSpecialityArea);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createTimeWindowSpecialityArea(timeWindowSpecialityArea);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialTimeWindowSpecialityArea, timeWindowSpecialityArea)) {
                    this.messagesService.success('toastr.success.timeWindowSpecialityAreaEdit', true);
                    this.goToParentPage();
                } else {
                    this.editTimeWindowSpecialityArea(this.initialTimeWindowSpecialityArea, timeWindowSpecialityArea);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/timeWindowsForSpecialitiesAndAreas']);
    }

    onSelectedAreaFilter(areaFilterList: any[]) {
        this.initialFilterValues.area = areaFilterList;
        this.timeWindowSpecialityAreaItem.areaId = (areaFilterList && areaFilterList.length > 0) ? areaFilterList[0].id : undefined;
    }

    onSelectedSpecialityFilter(specialityFilterList: any[]) {
        this.initialFilterValues.speciality = specialityFilterList;
        this.timeWindowSpecialityAreaItem.specialityId = (specialityFilterList && specialityFilterList.length > 0) ? specialityFilterList[0].id : undefined;
    }

    onSelectedChannelFilter(channelFilterList: any[]) {
        this.initialFilterValues.channel = channelFilterList;
        this.timeWindowSpecialityAreaItem.channel = (channelFilterList && channelFilterList.length > 0) ? channelFilterList[0].enumValue : undefined;
    }

    isNumberOfDaysValid(days: number): boolean {
        return !this.generalUtils.isEmpty(days)
            && this.validations.getValidateIntegerBetween(days.toString(), 1, this.MAX_NUMBER_OF_DAYS, '').isValid;
    }

    isSaveButtonEnabled() {
        return this.validateFields(this.timeWindowSpecialityAreaItem);
    }

    goToEdit() {
        history.replaceState({
            timeWindowSpecialityArea: this.timeWindowSpecialityAreaItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new TimeWindowSpecialityArea
    private createTimeWindowSpecialityArea(timeWindowSpecialityArea: TimeWindowSpecialityAreaType) {
        this.ngxLoader.start();
        this.timeWindowSpecialityAreaProvider.addEntry(timeWindowSpecialityArea)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newTimeWindowSpecialityAreaAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the TimeWindowSpecialityArea
    private editTimeWindowSpecialityArea(oldTimeWindowSpecialityArea: TimeWindowSpecialityAreaType, newTimeWindowSpecialityArea: TimeWindowSpecialityAreaType) {
        this.ngxLoader.start();
        this.timeWindowSpecialityAreaProvider.updateEntry(oldTimeWindowSpecialityArea, newTimeWindowSpecialityArea)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.timeWindowSpecialityAreaEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(timeWindowSpecialityAreaItem: TimeWindowSpecialityAreaType): boolean {
        return !!(timeWindowSpecialityAreaItem && timeWindowSpecialityAreaItem.areaId && timeWindowSpecialityAreaItem.specialityId
            && timeWindowSpecialityAreaItem.channel && this.isNumberOfDaysValid(timeWindowSpecialityAreaItem.days));
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            area: this.timeWindowSpecialityAreaMdUtils.getAreaDependentFilters(),
            speciality: this.timeWindowSpecialityAreaMdUtils.getSpecialityDependentFilters(),
            channel: this.timeWindowSpecialityAreaMdUtils.getChannelDependentFilters()
        };
        this.MAX_NUMBER_OF_DAYS = parseInt(this.skedBaseGeneralUtils.getSystemConfigValue(
            this.configDataService.systemConfig.value,
            'TimeWindowMaximum',
            true
        ).toString(), 10);
        if (history.state && history.state.timeWindowSpecialityArea) {
            this.initialTimeWindowSpecialityArea = history.state.timeWindowSpecialityArea;
            this.timeWindowSpecialityAreaItem = lodash.cloneDeep(history.state.timeWindowSpecialityArea);
            this.initialFilterValues = {
                area: [{
                    id: this.timeWindowSpecialityAreaItem.areaId,
                    name: this.timeWindowSpecialityAreaItem.area.name
                } as FilterWrapperAreaSearchType],
                speciality: [{
                    id: this.timeWindowSpecialityAreaItem.specialityId,
                    name: this.timeWindowSpecialityAreaItem.speciality.name
                } as FilterWrapperSpecialitySearchType],
                channel: [{name: this.timeWindowSpecialityAreaItem.channel}]
            };
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.timeWindowSpecialityAreaItem = this.timeWindowSpecialityAreaMdUtils.getInitialTimeWindowSpecialityArea();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }
}
