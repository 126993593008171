import {Injectable} from '@angular/core';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {TableFiltersType} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import {
    AvailabilityDataTimeSlotType, AvailabilityTimeSlotType, DateRangeOptionsType,
    ExclusionOverviewFilterType, ExclusionOverviewItemType, ExclusionReasonDependentFiltersType,
    ExclusionType,
    ExclusionTypeEnum,
    ODataQueryObjectType, RepetitionIntervalEnum,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AvailabilityExclusionIntraPageContextStateFiltersType,
    AvailabilityStatusEnum,
    BackofficeAvailabilityTimeSlotType
} from '../availability/availability.types';
import {
    BackofficeExclusionDataType,
    BackofficeExclusionTimeSlotType,
    ExclusionStatusEnum,
    ExclusionViewPageEnum
} from './exclusion.types';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import * as moment from 'moment-timezone';
import {MessagesService} from '../../shared/services/messages.service';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {TimeSlotsUtils} from '../../shared/utils/time-slots.utils';
import {ConfigDataService} from '../../shared/services/config-data.service';

@Injectable({
    providedIn: 'root'
})
export class ExclusionUtils {
    filtersState: AvailabilityExclusionIntraPageContextStateFiltersType = {};

    constructor(
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private dateTimeUtils: DateTimeUtils,
        private timeSlotsUtils: TimeSlotsUtils,
        private configDataService: ConfigDataService
    ) {
    }

    getFilterByViewType(exclusionViewPage: ExclusionViewPageEnum) {
        return {
            name: '',
            requestedIntervalKind: IntervalTypeEnum.OVERLAP,
            statuses: this.getStatusesFilterByViewType(exclusionViewPage)
        };
    }

    getStatusesFilterByViewType(availabilityPage: ExclusionViewPageEnum) {
        switch (availabilityPage) {
            case ExclusionViewPageEnum.ExclusionOverview:
                return [AvailabilityStatusEnum.APPROVED, AvailabilityStatusEnum.MARKED_FOR_DELETE];
            case ExclusionViewPageEnum.ApproveExclusionOverview:
                return [ExclusionStatusEnum.NEW, ExclusionStatusEnum.EDITED, ExclusionStatusEnum.MARKED_FOR_DELETE];
            default:
                return undefined;
        }
    }

    getInitialExclusionData(): BackofficeExclusionDataType {

        const exclusionData: BackofficeExclusionDataType = {} as BackofficeExclusionDataType;

        exclusionData.resource = undefined;
        exclusionData.center = undefined;
        exclusionData.exclusionReasonData = undefined;
        exclusionData.exclusionReason = undefined;
        exclusionData.timeZoneId = 'undefined';
        exclusionData.exclusionType = ExclusionTypeEnum.WholeDay;
        exclusionData.repetition = 1;
        exclusionData.unit = RepetitionIntervalEnum.Weekly;
        exclusionData.timeSlots = [];
        exclusionData.hourFromTime = '08:00';
        exclusionData.hourToTime = '18:00';
        exclusionData.endOfDay = true;
        exclusionData.validFrom = moment().format('YYYY-MM-DD');
        exclusionData.validTo = moment().format('YYYY-MM-DD');

        return exclusionData;
    }

    isGeneralInfoStepValid(exclusion: BackofficeExclusionDataType, isExclusionCenterActivityActive: boolean): boolean {
        // if Center Exclusion activity doesn't assigned for the current user, the user can not create an exclusion for center only
        if (isExclusionCenterActivityActive) {
            return !!(((exclusion.resourceId && !exclusion?.resource?.isRoom && exclusion.timeZoneId) || exclusion.centerId || (exclusion.resourceId && exclusion.centerId)) &&
                !!(exclusion?.exclusionReasonData?.id && exclusion?.exclusionReasonData?.active && !exclusion?.exclusionReasonData?.cancelled) &&
                !!(!exclusion?.exclusionReasonData.extraInformationMandatory || (exclusion.exclusionReasonData.extraInformationMandatory && exclusion.exclusionExtraInformation)));
        }

        return !!((exclusion.resourceId && exclusion.timeZoneId && exclusion.centerId) &&
            !!(exclusion?.exclusionReasonData?.id && exclusion?.exclusionReasonData?.active && !exclusion?.exclusionReasonData?.cancelled) &&
            !!(!exclusion?.exclusionReasonData.extraInformationMandatory || (exclusion.exclusionReasonData.extraInformationMandatory && exclusion.exclusionExtraInformation)));
    }

    isTimeSlotsStepValid(exclusion: BackofficeExclusionDataType): boolean {

        switch (exclusion.exclusionType) {
            case ExclusionTypeEnum.WholeDay:
                return !!(exclusion?.validFrom && exclusion?.validTo &&
                    this.dateTimeUtils.isDateRangeTodayOrFuture(new Date(exclusion?.validFrom), new Date(exclusion?.validTo)));
            case ExclusionTypeEnum.Partial:
                return !!(exclusion?.validFrom && exclusion?.validTo &&
                    this.dateTimeUtils.isDateRangeTodayOrFuture(new Date(exclusion?.validFrom), new Date(exclusion?.validTo))) &&
                    exclusion?.timeSlots?.length > 0;
            case ExclusionTypeEnum.Range:
                const validRangeValidation = this.dateTimeUtils.getValidateDateAndTimeRange(
                    exclusion.validFrom,
                    exclusion.validTo,
                    exclusion.endOfDay,
                    exclusion.timeZoneId
                );
                return !!(exclusion?.validFrom && exclusion?.validTo && validRangeValidation.isValid);
            default:
                return false;
        }
    }

    getQueryFilterForExclusionOverview(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            orderBy: this.generalUtils.getOrderByQuery(tableFilters.orderBy)
        };
    }

    getQueryFilterForExclusionData(exclusionId: string): ODataQueryObjectType {
        return {
            filter: {ShortId: {eq: {type: 'guid', value: exclusionId}}},
        };
    }

    getQueryFilterForTimeZone(centerId: string, resourceId: string): ODataQueryObjectType {
        const filter = {} as ODataQueryObjectType;

        if (centerId) {
            filter.filter = {Id: {eq: {type: 'guid', value: centerId}}};
        }

        if (resourceId && !centerId) {
            filter.filter = {Resources: {any: {Id: {eq: {type: 'guid', value: resourceId}}}}};
        }
        filter.select = ['Id', 'TimeZoneId'];

        return filter;
    }

    getQueryFilterForExclusionReason(exclusionReasonId: string): ODataQueryObjectType {
        const filter = {} as ODataQueryObjectType;

        if (exclusionReasonId) {
            filter.filter = {
                Id: {eq: {type: 'guid', value: exclusionReasonId}},
                or: [{Cancelled: true}, {Cancelled: false}]
            };
        }

        return filter;
    }

    getRequestBodyForExclusionOverview(filters: any): ExclusionOverviewFilterType {
        const body: ExclusionOverviewFilterType = {} as ExclusionOverviewFilterType;

        body.resourceId = filters?.resource?.id;
        body.centerId = filters?.location?.id;
        body.validFrom = filters?.validFrom;
        body.validTo = filters?.validTo;
        if (filters.validFrom || filters.validTo) {
            body.requestedIntervalKind = filters.requestedIntervalKind;
        }
        body.statuses = filters?.statuses;
        body.shortId = filters?.shortId;

        return body;
    }

    getInitialTimeSlot(): BackofficeAvailabilityTimeSlotType {
        const initialTimeSlot = {} as BackofficeAvailabilityTimeSlotType;
        initialTimeSlot.selectedDays = [];
        initialTimeSlot.hourFromTime = '08:00';
        initialTimeSlot.hourFrom = 480;
        initialTimeSlot.hourToTime = '18:00';
        initialTimeSlot.hourTo = 1080;
        initialTimeSlot.appointmentTypes = [];
        return initialTimeSlot;
    }

    validateTimeSlot(newTimeSlot: BackofficeExclusionTimeSlotType): boolean {
        let valid = true;
        if (!newTimeSlot) {
            //timeSlot is not filled
            valid = false;
            this.messagesService.error('toastr.error.noTimeSlot');
        } else if (!newTimeSlot.hourFromTime || !newTimeSlot.hourToTime) {
            //timeSlot intervalinvalid
            valid = false;
            this.messagesService.error('toastr.error.invalidTimeSlot');
        } else if (newTimeSlot?.selectedDays?.length === 0) {
            //days are not checked
            valid = false;
            this.messagesService.error('toastr.error.slotNoDaySelected');
        } else if (moment(newTimeSlot.hourFromTime, 'HH:mm').diff(newTimeSlot.hourToTime, 'minutes') >= 0) {
            //validFrom > validTo
            valid = false;
            this.messagesService.error('toastr.error.slotInvalidPeriod');
        }
        return valid;
    }

    mapTimeSlotFromViewToSave(timeSlot: BackofficeExclusionTimeSlotType): AvailabilityDataTimeSlotType {
        const updatedTimeSlot = {} as AvailabilityDataTimeSlotType;
        const weekDays: string[] = this.timeSlotsUtils.getWeekDays();
        updatedTimeSlot.hourFrom = this.dateTimeUtils.getMinutesFromStringHours(timeSlot.hourFromTime);
        updatedTimeSlot.hourTo = this.dateTimeUtils.getMinutesFromStringHours(timeSlot.hourToTime);
        for (const day of weekDays) {
            updatedTimeSlot[day] = (lodash.indexOf(timeSlot.selectedDays, day) > -1);
        }
        updatedTimeSlot.appointmentTypes = [];
        return updatedTimeSlot;
    }

    mapExclusionForSend(exclusion: BackofficeExclusionDataType): ExclusionType {
        const exclusionToAdd: ExclusionType = lodash.cloneDeep(exclusion) as unknown as ExclusionType;

        exclusionToAdd.validFrom = moment.tz(moment(exclusion.validFrom).parseZone()
            .format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format();

        const actualValidTo = exclusion.validTo; // transformed already in onStepChange
        // We need to format by YYYY-MM-DDT00:00:00Z here, because moment's .startOf('day') doesn't work, as
        // it also changes the timezone, which we don't want.
        exclusionToAdd.validTo = exclusion.endOfDay
            ? moment.tz(moment(actualValidTo).parseZone()
                .format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format('YYYY-MM-DDT00:00:00Z')
            : moment.tz(moment(actualValidTo).parseZone()
                .format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format('YYYY-MM-DDTHH:mm:00Z');

        // because we have center id, we don't need to send timeZoneId
        if (exclusion.centerId) {
            delete exclusionToAdd.timeZoneId;
        }

        //map timeslots for send
        if (exclusion.timeSlots?.length > 0) {
            exclusionToAdd.timeSlots = [];
            for (const timeSlot of exclusion.timeSlots) {
                const timeSlotToSend = timeSlot as unknown as AvailabilityTimeSlotType;
                timeSlotToSend.interval = {hourFrom: timeSlot.hourFrom, hourTo: timeSlot.hourTo};
                exclusionToAdd.timeSlots.push(timeSlotToSend);
            }
        }

        return exclusionToAdd;
    }

    getApproveExclusionTemplateTitle(action: string): string {
        if (action === constants.APPROVE) {
            return 'label.approveText';
        } else if (action === constants.DECLINE) {
            return 'label.declineText';
        }
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const dateRangeOptions: DateRangeOptionsType = this.dateTimeUtils.getInitialDateRangeOptions();
        dateRangeOptions.fromDateLabel = 'label.validFrom';
        dateRangeOptions.toDateLabel = 'label.validTo';
        return dateRangeOptions;
    }

    // Obsolete, we map this before sending to back-end
    addTimeToDateByExclusionType(exclusion: BackofficeExclusionDataType): BackofficeExclusionDataType {
        // keep the validFrom / validTo date without timezone before adding time and timezone
        exclusion.validFrom = moment(exclusion.validFrom.substring(0, 19)).format();
        exclusion.validTo = moment(exclusion.validTo.substring(0, 19)).format();
        if (exclusion.exclusionType === ExclusionTypeEnum.WholeDay || exclusion.exclusionType === ExclusionTypeEnum.Partial) {
            exclusion.validFrom = moment.tz(moment(exclusion.validFrom).startOf('day')
                .parseZone().format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format();
            //exclusions of type wholeDay and partial day don't have a time input - we preset the 23:59:59.999 default value
            exclusion.validTo = moment.tz((moment(moment(exclusion.validTo).startOf('day').add(1, 'day').subtract(1, 'millisecond'))
                .parseZone().format('YYYY-MM-DD HH:mm:ss.SSS')), exclusion.timeZoneId).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        } else if (exclusion.exclusionType === ExclusionTypeEnum.Range) {
            // add time to date
            const hourFromMinutes = this.dateTimeUtils.getMinutesFromStringHours(exclusion.hourFromTime);
            const hourToMinutes = this.dateTimeUtils.getMinutesFromStringHours(exclusion.hourToTime);
            exclusion.validFrom = moment.tz((moment(moment(exclusion.validFrom).startOf('day').add(hourFromMinutes, 'minutes'))
                .parseZone().format('YYYY-MM-DD HH:mm')), exclusion.timeZoneId).format();
            exclusion.validTo = moment.tz((moment(moment(exclusion.validTo).startOf('day').add(hourToMinutes, 'minutes'))
                .parseZone().format('YYYY-MM-DD HH:mm')), exclusion.timeZoneId).format();
        } else {
            exclusion.validFrom = moment.tz(moment(exclusion.validFrom).parseZone().format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format();
            exclusion.validTo = moment.tz(moment(exclusion.validTo).parseZone().format('YYYY-MM-DD HH:mm'), exclusion.timeZoneId).format();
        }

        return exclusion;
    }

    getTimeFromDateTime(dateTime: string): string {
        let time: string;

        time = dateTime.substring(11, 16);

        return time;
    }

    getEmptyExclusionReasonDependentFilters(): ExclusionReasonDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            orderBy: 'Description'
        };
    }

    getExclusionCenterActivity() {
        const activities = this.configDataService.getConfigFromStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME);
        return lodash.includes(activities, 'ExclusionCenter');
    }

    validateExclusionCenterActivity(exclusion, isExclusionCenterActivityActive) {
        if (isExclusionCenterActivityActive) {
            return true;
        } else {
            return !!((exclusion?.resourceId && exclusion?.centerId) || exclusion?.resourceId);
        }
    }

    isExclusionFromMSGraphIntegration(exclusion: ExclusionOverviewItemType): boolean {
        return lodash.findIndex(exclusion.externalKeys, (externalKey) => {
            return (externalKey.origin === 'MSGraphIntegration' || externalKey.origin === 'O365');
        }) > -1;
    }
}
