import {Component, OnInit} from '@angular/core';
import {GraphicalResourcePlannerOptionsType} from 'sked-base/lib/components/graphical-resource-planner/graphical-resource-planner.types';
import {Router} from '@angular/router';
import {MessagesService} from '../../../shared/services/messages.service';

@Component({
    selector: 'app-generate-sked-task',
    templateUrl: './generate-sked-task.component.html',
    styleUrls: ['./generate-sked-task.component.scss']
})
export class GenerateSkedTaskComponent implements OnInit {

    constructor(public routerInstance: Router,
                public messagesServiceInstance: MessagesService) {
    }

    ngOnInit(): void {

    }

}
