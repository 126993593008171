import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateMMMMDoFormat'
})
export class DateMMMMDoFormatPipe implements PipeTransform {

    // The date will look like this: May 20th
    transform(date: string): string {
        if (date) {
            //@ts-ignore
            return moment(date.substring(0, 19)).format(moment.localeData().longDateFormat('MMMMDo'));
        } else {
            return undefined;
        }
    }

}
