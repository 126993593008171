<div *ngIf="phoneNumberForm"
     [formGroup]="phoneNumberForm" class="form-container phone-number-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
    <div *ngFor="let group of options?.groups" class="phone-number-form">
        <ng-container *ngFor="let input of group.inputs">
            <ng-container [ngSwitch]="input.template" *ngIf="input.visible">
                <label for="{{input.formControlName}}"
                       *ngIf="input.template === dynamicFormInputTemplateEnum.PhoneNumberCountryCode"
                       class="phone-number-form-label">
                    <span>{{!input.labelText ? '&nbsp;' : input.labelText | translate}}</span>
                    <span [style.visibility]="input?.required ? 'visible' : 'hidden'"
                          class="input-required-asterisk">*</span>
                </label>
                <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.PhoneNumberCountryCode">
                    <div class="d-flex phone-number-form-input"
                         [ngClass]="{'is-disabled': phoneNumberForm?.controls[input.formControlName]?.disabled}">
                        <span class="plus-sign"
                              [ngClass]="{'input-element-has-error': !!(phoneNumberForm.touched && phoneNumberForm.errors),
                              'phone-number-form-input-focused': isPhoneNumberInputFocused}">
                            +
                        </span>
                        <input type="text"
                               class="input-element"
                               [id]="input.formControlName"
                               placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                               [formControlName]="input.formControlName"
                               (focus)="isPhoneNumberInputFocused = true"
                               (blur)="isPhoneNumberInputFocused = false"
                               [ngClass]="{'cursor-not-allowed': phoneNumberForm?.controls[input.formControlName]?.disabled,
                                           'input-element-has-error': !!(phoneNumberForm.touched && phoneNumberForm.errors)}">
                    </div>
                    <!-- Error message -->
                    <app-dynamic-form-input-error [formGroup]="parentFormGroup"
                                                  [controlName]="formGroupName">
                    </app-dynamic-form-input-error>
                </ng-template>
                <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.PhoneNumber">
                    <input type="tel"
                           class="phone-number-form-input input-element"
                           [id]="input.formControlName"
                           placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
                           [formControlName]="input.formControlName"
                           [ngClass]="{
                           'input-element-has-error': !!(phoneNumberForm.touched && phoneNumberForm.errors),
                           'cursor-not-allowed is-disabled': phoneNumberForm?.controls[input.formControlName]?.disabled
                           }">
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</div>
