import {Injectable} from '@angular/core';
import {
    PatientSearchActionsCallbackType,
    PatientSearchOptionsType,
    PatientSearchResultType,
    PayloadType
} from 'sked-base';
import {MessagesService} from './messages.service';
import {ConfigDataService} from './config-data.service';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {PatientActionsService} from './patient-actions/patient-actions.service';
import {BehaviorSubject} from 'rxjs';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PatientContextService {
    get patient(): PatientSearchResultType | PatientType {
        return this._patient;
    }

    set patient(value: PatientSearchResultType | PatientType) {
        const isEqual = lodash.isEqual(value, this._patient);
        this._patient = value;
        if (!isEqual) {
            this.patientChange.next(value);
        }
    }

    // tslint:disable-next-line:variable-name
    private _patient: PatientSearchResultType | PatientType;

    patientChange: BehaviorSubject<PatientSearchResultType | PatientType> =
        new BehaviorSubject<PatientSearchResultType | PatientType>(undefined);

    constructor(
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
        private patientActionsService: PatientActionsService,
    ) {
    }

    getPatientSearchOptions(preselectPatient?: boolean, selectedPatient?: PatientSearchResultType | PatientType): PatientSearchOptionsType {
        if (preselectPatient) {
            this.patient = selectedPatient;
        }
        return {
            initialSelectedPatient: {} as PatientSearchResultType,
            parentPropertyName: 'patientSearchForBooking',
            patientSearchInput: '',
            configDataService: this.configDataService,
            messagesService: this.messagesService,
            allowPatientDashboardRoute: true,
            allowToNavigateToPatientDashboardRoute: true,
            ...(!!this.patient ? {
                initialSelectedPatient: {
                    ...this.patient,
                    id: this.patient.id
                }
            } : {})
        } as PatientSearchOptionsType;
    }

    onPatientSearchActions(actionCallback: PatientSearchActionsCallbackType): void {
        this.patientActionsService.onAction(actionCallback);
    }

    onSelectedPatient(selectedPatient: { $value: PayloadType }) {
        this.patient = selectedPatient?.$value?.value;
    }

    forcePatientChangeToEmit(value?: PatientSearchResultType | PatientType): void {
        if (value) {
            this._patient = value;
        }
        this.patientChange.next(this.patient);
    }
}
