<div class="container-fluid create-appointment-type-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-appointment-type-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase" translate="label.appointmentType2"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{appointmentTypeItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{appointmentTypeItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.system"></td>
                                    <td class="text-right">
                                        <span *ngIf="appointmentTypeItem.system" translate="label.yes"></span>
                                        <span *ngIf="!appointmentTypeItem.system" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.consumesPlannedCapacity"></td>
                                    <td class="text-right">
                                        <span *ngIf="appointmentTypeItem.consumesPlannedCapacity"
                                              translate="label.yes"></span>
                                        <span *ngIf="!appointmentTypeItem.consumesPlannedCapacity"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3" *ngIf="screenTemplateLayout.action !== constants.CREATE">
                        <div class="form-group" [ngClass]="{'disabled-input': 'true'}">
                            <label translate="label.shortId"></label>
                            <input type="text" name="shortId"
                                   class="input-element"
                                   placeholder="{{'label.shortId'|translate}}"
                                   [(ngModel)]="appointmentTypeItem.shortId">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   [(ngModel)]="appointmentTypeItem.name"
                                   [ngClass]="{'input-element-has-error': !appointmentTypeItem.name}"
                                   placeholder="{{'label.name'|translate}}">
                            <div *ngIf="!appointmentTypeItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--ConsumesPlannedCapacity-->
                <div class="row">
                    <div class="col-3">
                        <div class="form-group"
                             [ngClass]="{'disabled-input': appointmentTypeItem.system}">
                            <label translate="label.consumesPlannedCapacity"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked name="consumesPlannedCapacity"
                                           [(ngModel)]="appointmentTypeItem.consumesPlannedCapacity"
                                           [disabled]="appointmentTypeItem.system">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button class="btn mr-3"
                (click)="goToParentPage()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button class="btn theme-btn"
                (click)="saveAppointmentTypeData(appointmentTypeItem)"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                [disabled]="!appointmentTypeItem.name"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'AppointmentTypeUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
