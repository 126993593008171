import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PatientDashboardComponent} from './patient-dashboard.component';
import {PatientDashboardDetailsComponent} from './patient-dashboard-details/patient-dashboard-details.component';
import {UpcomingAppointmentsComponent} from './upcoming-appointments/upcoming-appointments.component';
import {SharedModule} from '../../shared/shared.module';
import {AppointmentCardComponent} from '../../shared/component/appointment-card/appointment-card.component';
import {AppointmentDetailsComponent} from '../../shared/component/appointment-details/appointment-details.component';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import {ReactiveFormsModule} from '@angular/forms';
import { ClientIdentificationFormComponent } from './create-patient/client-identification-form/client-identification-form.component';
import { AddressInformationFormComponent } from './create-patient/address-information-form/address-information-form.component';
import { CreatePatientOverviewComponent } from './create-patient/create-patient-overview/create-patient-overview.component';
import { PatientAppointmentListComponent } from './patient-appointment-list/patient-appointment-list.component';
import { PatientAppointmentListFiltersComponent } from './patient-appointment-list/patient-appointment-list-filters/patient-appointment-list-filters.component';
import { PhoneNumberFormComponent } from './create-patient/phone-number-form/phone-number-form.component';
import { CoveragePlansFormComponent } from './create-patient/coverage-plans-form/coverage-plans-form.component';
import { TagsFormComponent } from './create-patient/tags-form/tags-form.component';
import { PatientMergeComponent } from './patient-merge/patient-merge.component';
import { ExternalKeysFormComponent } from './create-patient/external-keys-form/external-keys-form.component';
import { ExceptionsFormComponent } from './create-patient/exceptions-form/exceptions-form.component';
import { TimeDependentTagsFormComponent } from './create-patient/time-dependent-tags-form/time-dependent-tags-form.component';

@NgModule({
    declarations: [PatientDashboardComponent, PatientDashboardDetailsComponent, UpcomingAppointmentsComponent, AppointmentCardComponent,
        CreatePatientComponent, ClientIdentificationFormComponent, AddressInformationFormComponent, CreatePatientOverviewComponent,
        PatientAppointmentListComponent, PatientAppointmentListFiltersComponent, PhoneNumberFormComponent, CoveragePlansFormComponent, TagsFormComponent, ExternalKeysFormComponent, PatientMergeComponent, ExceptionsFormComponent, TimeDependentTagsFormComponent],
    exports: [
        AppointmentCardComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule
    ]
})
export class PatientDashboardModule {
}
