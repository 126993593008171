import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    AppointmentEventRuleProvider,
    AppointmentEventRuleType,
    AppointmentStatusValueEnum,
    ConfirmDeleteModalService,
    EventActionDependentFiltersType,
    EventActionProvider,
    EventActionType,
    EventTypeEnum,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagDependentFiltersType,
    TagProvider,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {AppointmentEventRuleUtils} from './appointment-event-rule.utils';
import {RulesUtils} from '../../rules.utils';
import {RulesInitialOverviewFiltersType, RulesTableHeaderOptions} from '../../rules.types';
import {ConfigDataService} from '../../../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-appointment-event-rule',
    templateUrl: './appointment-event-rule.component.html',
    styleUrls: ['./appointment-event-rule.component.scss']
})
export class AppointmentEventRuleComponent implements OnInit, OnDestroy {
    tableHeaderColumns: RulesTableHeaderOptions[] = this.appointmentEventRuleUtils.getRuleTableHeaderOptions();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    ruleList: AppointmentEventRuleType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    APPOINTMENT_EVENT_RULE = 'AppointmentEventRule';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.appointmentEventRuleUtils.getEmptyRuleTableDependentFilters();
    eventTypeEnumList = Object.values(EventTypeEnum);
    appointmentStatusEnumList = Object.values(AppointmentStatusValueEnum);
    eventActionDependentFilters: EventActionDependentFiltersType = this.appointmentEventRuleUtils.getEmptyEventActionDependentFilters();
    mainDependentFilters: {
        centerTags: TagDependentFiltersType,
        serviceTags: TagDependentFiltersType,
        resourceTags: TagDependentFiltersType,
        patientTags: TagDependentFiltersType,
    };
    initialFilters = {
        tableFilters: this.appointmentEventRuleUtils.getInitialTableFilter(),
        modalFilters: this.appointmentEventRuleUtils.getInitialModalFilters()
    } as RulesInitialOverviewFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    private searchAppointmentEventRuleChanged = new Subject<string>();

    constructor(
        public appointmentEventRuleUtils: AppointmentEventRuleUtils,
        public rulesUtils: RulesUtils,
        private appointmentEventRuleProvider: AppointmentEventRuleProvider,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private router: Router,
        private generalUtils: GeneralUtils,
        private confirmDeleteService: ConfirmDeleteModalService,
        public ruleTableProvider: RuleTableProvider,
        public eventActionProvider: EventActionProvider,
        public tagProvider: TagProvider,
        private searchFilterUtils: SearchFilterUtils,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`AppointmentEventRuleRead`);
        this.rulesUtils.loadFilters(RuleTypeEnum.AppointmentEventRule, this.initialFilters);
        this.mainDependentFilters = {
            centerTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedCenter, false, true),
            serviceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedService, false, true),
            resourceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedResource, false, true),
            patientTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true)
        };
        this.loadInitialData();
        this.subscribeSearchByAppointmentEventRuleChanged();
    }

    ngOnDestroy(): void {
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            const {modalFilters} = this.rulesUtils.overviewState;
            this.rulesUtils.overviewState.modalFilters.areFiltersActive = this.appointmentEventRuleUtils.isAnyFilterActive(modalFilters);
            // Filter the data based on the new filters
            this.rulesUtils.overviewState.tableFilters.filter.eventType = modalFilters.eventTypeFilterOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.entityType = modalFilters.entityTypeFilterOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.appointmentStatus = modalFilters.appointmentStatusOptions.ngModel;
            this.rulesUtils.overviewState.tableFilters.filter.centerTagId = modalFilters.centerTagOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.serviceTagId = modalFilters.serviceTagOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.resourceTagId = modalFilters.resourceTagOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.patientTagId = modalFilters.patientTagOptions.ngModel[0]?.id;
            this.rulesUtils.overviewState.tableFilters.filter.eventActionId = modalFilters.eventActionTypeOptions.ngModel[0]?.id;
            this.loadRules(true);
        }
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchAppointmentEventRuleChanged('');
            this.rulesUtils.overviewState.previousSelectedRule = null;
            this.rulesUtils.loadFilters(RuleTypeEnum.AppointmentEventRule, this.initialFilters);
            this.loadRules();
        });
    }

    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        this.rulesUtils.overviewState.ruleTable = ruleTable?.length > 0 ? ruleTable[0] : undefined;
        this.rulesUtils.overviewState.tableFilters.filter['appointmentEventRuleTableId'] = ruleTable?.length > 0 ? ruleTable[0].id : undefined;
        this.loadRules();
    }

    // region Navigation methods
    //
    goBack(): void {
        this.rulesUtils.selectedRule = undefined;
    }

    createRule(): void {
        this.router.navigate(['/createRule']);
    }

    editRule(rule: AppointmentEventRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.EDIT}});
    }

    viewRule(rule: AppointmentEventRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.VIEW}});
    }

    navigateToRuleSet(): void {
        this.router.navigate(['/ruleSet']);
    }
    //
    // endregion Navigation methods

    // region Pagination and base filters methods
    //
    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = page;
        this.loadRules(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.rulesUtils.overviewState.tableFilters.currentPage = 1;
        this.rulesUtils.overviewState.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadRules(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const orderByKeys = Object.keys(this.rulesUtils.overviewState.tableFilters.orderBy);
        const isPropertyAlreadySorted = orderByKeys.includes(property);
        if (orderByKeys.length >= constants.ORDER_BY_CLAUSE_MAX_PERMITTED && !isPropertyAlreadySorted) {
            this.messagesService.error('toastr.error.orderByLimitReached');
            return;
        }
        const isAscendingMode = this.rulesUtils.overviewState.tableFilters.orderBy[property];
        if (this.rulesUtils.overviewState.tableFilters.orderBy) {
            this.rulesUtils.overviewState.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadRules(false);
    }

    onSearchAppointmentEventRuleChanged(value: string) {
        this.searchAppointmentEventRuleChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.rulesUtils.overviewState.tableFilters.orderBy[selectedItem];
        this.loadRules(false);
    }
    //
    // endregion Pagination and base filters methods

    displayConfirmDeleteItemModal(appointmentEventRuleId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.appointmentEventRuleProvider, appointmentEventRuleId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(appointmentEventRuleId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.rulesUtils.overviewState.modalFilters[property].ngModel = undefined;
        }
    }

    // region Rule specific methods
    //
    onSelectedEventAction(eventAction: EventActionType[]): void {
        this.rulesUtils.overviewState.modalFilters['eventActionTypeOptions'].ngModel = eventAction[0] ? eventAction : [];
    }

    onSelectedTag(entityName: string, tagList: TagType[]) {
        this.rulesUtils.overviewState.modalFilters[entityName + 'TagOptions'].ngModel = tagList?.length ? tagList : [];
    }
    //
    // endregion Rule specific methods

    // region General methods
    //
    private loadInitialData(includeCount: boolean = true) {
        const queryFilter = this.appointmentEventRuleUtils.getQueryFilter(this.rulesUtils.overviewState.tableFilters, includeCount);
        this.ngxLoader.start();
        this.appointmentEventRuleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((appointmentEventRuleResponse) => {
                this.ruleList = appointmentEventRuleResponse.value;
                if (appointmentEventRuleResponse.count !== undefined && appointmentEventRuleResponse.count !== null) {
                    this.totalTableItems = appointmentEventRuleResponse.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to get AppointmentEventRule data
    private loadRules(includeCount: boolean = true) {
        // Load the data
        const queryFilter = this.appointmentEventRuleUtils.getQueryFilter(this.rulesUtils.overviewState.tableFilters, includeCount);
        this.ngxLoader.start();
        this.appointmentEventRuleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.ruleList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.rulesUtils.overviewState.tableFilters = this.appointmentEventRuleUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.appointmentEventRuleProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadRules();
                this.messagesService.success('toastr.success.appointmentEventRuleDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByAppointmentEventRuleChanged() {
        const self = this;
        this.searchAppointmentEventRuleChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.rulesUtils.overviewState.tableFilters.filter.name = searchValue;
                self.loadRules();
            });
    }
    //
    // endregion General methods
}
