<div class="container-fluid activity-plan-template-md-wrapper overview-screen-md pb-1">
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.activityPlanTemplate"></h3>
        <div class="filters-section d-flex no-block">
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex no-block justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
            <span *ngFor="let key of objectKeys(activityPlanTemplateMdUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + activityPlanTemplateMdUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex no-block">
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="activityPlanTemplateMdUtils.tableFilters.filter.Name"
                       (ngModelChange)="onSearchActivityPlanTemplateChanged($event)">
            </div>
            <div class="ml-2">
                <button type="button" class="btn theme-alt-btn border-0 text-white"
                        [activityDisplay]="ACTIVITY_PLAN_TEMPLATE+CONSTANTS.CREATE"
                        (click)="createActivityPlanTemplate()"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.name' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!activityPlanTemplateMdUtils.tableFilters.orderBy.name"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="activityPlanTemplateMdUtils.tableFilters.orderBy.name === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="activityPlanTemplateMdUtils.tableFilters.orderBy.name === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column" translate="label.services">
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let activityPlanTemplate of activityPlanTemplateMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewActivityPlanTemplate(activityPlanTemplate)">
                <td class="name-column">{{activityPlanTemplate.name}}</td>
                <td class="name-column">
                    <div *ngFor="let service of activityPlanTemplate.services | slice:0:3">
                        <!-- field specialityName is added in addSpecialityNameToServices (utils method) -->
                        <span class="font-weight-bold" *ngIf="service.service.specialityName">{{service.service.specialityName}} </span>
                        <span *ngIf="service.service.name">{{service.service.name}}</span>
                        <i *ngIf="service.service.onlineConsultation" class="fas fa-desktop ml-1" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                    </div>
                    <ng-template #servicesContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let service of activityPlanTemplate.services">
                                <!-- field specialityName is added in addSpecialityNameToServices (utils method) -->
                                <span class="font-weight-bold" *ngIf="service.service.specialityName">{{service.service.specialityName}} </span>
                                <span *ngIf="service.service.name">{{service.service.name}}</span>
                                <i *ngIf="service.service.onlineConsultation" class="fas fa-desktop ml-1" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                            </div>
                        </div>
                    </ng-template>
                    <div *ngIf="activityPlanTemplate.services && activityPlanTemplate.services.length > 3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="servicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewActivityPlanTemplate(activityPlanTemplate)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editActivityPlanTemplate(activityPlanTemplate)"
                                        [activityDisplay]="ACTIVITY_PLAN_TEMPLATE+CONSTANTS.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(activityPlanTemplate.id)"
                                        [activityDisplay]="ACTIVITY_PLAN_TEMPLATE+CONSTANTS.DELETE"></button>
                            </li>
<!--                            TODO: Commented out because no changelog is generated-->
<!--                            <li>  -->
<!--                                <button type="button" class="btn" translate="label.changeLog"-->
<!--                                        (click)="displayChangeLog(activityPlanTemplate.id, EntityTypeEnum.activityPlanTemplateService, activityPlanTemplate.name)"-->
<!--                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>-->
<!--                            </li>-->
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="activityPlanTemplateMdUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="activityPlanTemplateMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{activityPlanTemplateMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
