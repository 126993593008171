import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ChangeLogMDComponent} from './change-log-md.component';

@NgModule({
    declarations: [ChangeLogMDComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class ChangeLogMDModule { }
