import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'dateddddFormatPipe'
})
export class DateddddFormatPipe extends DatePipe implements PipeTransform {
  transform(date: NgbDateStruct | any): any {
    return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD')
        // @ts-ignore
        .format(moment.localeData().longDateFormat('dddd'));
  }
}
