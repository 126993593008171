import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {SubServiceMDUtils} from './sub-service-md.util';
import {constants} from '../../shared/constants/constants';
import {flatMap, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {
    SearchFilterUtils,
    SubServiceProvider,
    SubServiceType,
    TagDependentFiltersScopeEnum, TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {IdNameType, ScreenTemplateLayoutType} from '../../data-model/general.type';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {BackOfficeSubServiceType, ValidSubServiceObjectType} from '../../data-model/sub-service.types';
import {TagsFiltersType, TagsType} from 'sked-base/lib/data-model/tagTypes';

@Component({
    selector: 'app-create-sub-service',
    templateUrl: './create-sub-service.component.html',
    styleUrls: ['./create-sub-service.component.scss']
})
export class CreateSubServiceComponent implements OnInit {
    initialSubService: BackOfficeSubServiceType;
    subServiceItem: BackOfficeSubServiceType = {} as BackOfficeSubServiceType;
    constants = constants;
    VIEW = constants.VIEW;
    validTemplate: ValidSubServiceObjectType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    historyStateValue: any = history.state;
    tags: TagsType[] = [];
    mainDependentFilters: {
        tags: TagDependentFiltersType
    };

    constructor(
        public subServiceProvider: SubServiceProvider,
        public subServiceMDUtils: SubServiceMDUtils,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.setInitialSetup();
    }

    // function to send data to provider
    saveSubServiceData(subService: SubServiceType) {
        this.validTemplate = this.subServiceMDUtils.getValidatedFields(subService);
        if (this.subServiceMDUtils.areAllTemplateInputsValid(this.validTemplate)) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createSubService(subService);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialSubService, subService)) {
                    this.messagesService.success('toastr.success.subServiceEdited', true);
                    this.goToParentPage();
                } else {
                    this.editSubService(this.initialSubService, subService);
                }
            }
        }
    }

    cancelSubServiceData() {
        this.goToParentPage();
    }

    goToParentPage() {
        this.router.navigate(['/subServices']);
    }

    onIncrement() {
        this.subServiceItem.subServiceExternalKeys.push(this.subServiceMDUtils.getEmptyExternalKeyItem());
        this.validTemplate = this.subServiceMDUtils.getValidatedFields(this.subServiceItem);
    }

    onDecrement(index: number) {
        this.subServiceItem.subServiceExternalKeys.splice(index, 1);
        this.validTemplate = this.subServiceMDUtils.getValidatedFields(this.subServiceItem);
    }

    onInputChanged(subService: SubServiceType) {
        this.validTemplate = this.subServiceMDUtils.getValidatedFields(subService);
    }

    onEnableFavoritesOnPatientAccessInputChanged(subService: SubServiceType) {
        if (!subService.enabledOnPatientAccess) {
            this.subServiceItem.favorite = false;
        }
    }

    onDisplayFavoritesOnPatientAccessInputChanged(subService: SubServiceType) {
        if (subService.favorite) {
            this.subServiceItem.enabledOnPatientAccess = true;
        }
    }

    onChangeTags(tags) {
        this.subServiceItem.tags = tags;
    }

    goToEdit() {
        history.replaceState({subService: this.subServiceItem, action: constants.EDIT}, '');
        this.ngOnInit();
    }

    private createSubService(subService: BackOfficeSubServiceType) {
        this.ngxLoader.start();
        this.subServiceProvider.addEntry(subService)
            .pipe(
                flatMap((createdSubService) => {
                    const entity: TagsFiltersType = {
                        entityId: createdSubService.id,
                        entityType: 'SubService'
                    };
                    return this.updateTagsForSubService(entity, subService.tags);
                }),
                take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.subServiceCreated', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private editSubService(oldSubService: BackOfficeSubServiceType, newSubService: BackOfficeSubServiceType) {
        this.ngxLoader.start();
        this.subServiceProvider.updateEntry(oldSubService, newSubService)
            .pipe(
                flatMap((createdSubService) => {
                    const entity: TagsFiltersType = {
                        entityId: createdSubService.id,
                        entityType: 'SubService'
                    };
                    return this.updateTagsForSubService(entity, newSubService.tags);
                }),
                take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.subServiceEdited', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private loadTags() {
        this.ngxLoader.start();
        this.tagProvider.getTagsAssignedToEntity({entityType: 'SubService', entityId: history.state?.subService?.id})
            .pipe(take(1))
            .subscribe((tags) => {
                const tagsExceptSystem = tags?.value?.filter(tag => !tag.system);
                this.tags = tagsExceptSystem?.map(tag => ({...tag, tagId: tag.id})) ?? [];
                this.subServiceItem.tags = lodash.cloneDeep(this.tags);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private updateTagsForSubService(entity: TagsFiltersType, tags: IdNameType[]) {
        return this.tagProvider.updateTagsForEntity(entity, lodash.map(tags, 'id'));
    }

    private setInitialSetup() {
        this.historyStateValue = history.state;
        this.mainDependentFilters = {
            tags: {
                ...this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedNone, false),
                system: false
            }
        };
        if (this.historyStateValue && this.historyStateValue.subService) {
            this.initialSubService = this.historyStateValue.subService;
            this.loadTags();
            if (this.historyStateValue.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (this.historyStateValue.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
            this.subServiceItem = lodash.cloneDeep(this.historyStateValue.subService);
        } else {
            this.subServiceItem = this.subServiceMDUtils.getInitialSubService();
            this.initialSubService = this.subServiceMDUtils.getInitialSubService();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }

        this.validTemplate = this.subServiceMDUtils.getValidatedFields(this.subServiceItem);
    }
}
