import {Injectable} from '@angular/core';
import * as lodash from 'lodash';
import {GenericObjectType, ODataQueryObjectType, ResourceTypeType, TagProvider, Validations} from 'sked-base';
import {
    FormValidationType,
    ODataOrderByQueryType, PhoneNumberType,
    ScreenTemplateLayoutType,
    TableFiltersType
} from '../../data-model/general.type';
import {constants} from '../constants/constants';
import {ValidationNumberWithRange} from '../../features/center-md/center-md.types';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {SystemConfigurationType} from 'sked-base/lib/data-model/systemConfigurationTypes';

@Injectable({
    providedIn: 'root'
})
export class GeneralUtils {
    constructor(private tagProvider: TagProvider,
                private validations: Validations) {
    }

    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    RegexURL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{1,256}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;

    parsePhoneNumber(phoneNumberString: string): PhoneNumberType {
        if (!phoneNumberString?.includes('+')) {
            // Assume phone number that contains '+' has country code (is of the form +56987654321)
            return {phoneNumber: phoneNumberString} as PhoneNumberType;
        }
        const phoneNumber = {} as PhoneNumberType;
        try {
            const libFullPhoneNumber = this.libPhoneUtil.parseAndKeepRawInput(phoneNumberString);
            phoneNumber.phoneNumber = `${libFullPhoneNumber.getNationalNumber()}`;
            phoneNumber.countryCode = `${libFullPhoneNumber.getCountryCode()}`;
        } catch {
            // In case parseAndKeepRawInput throws error => leave current phone number as it is
            phoneNumber.phoneNumber = phoneNumberString;
        }
        return phoneNumber;
    }

    isNullOrEmptyString(value: any): boolean {
        return value === null || value === '';
    }

    isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }

    isOverMaxLength(value: string, maxLength: number): boolean {
        return value?.length > maxLength;
    }

    getDefaultGeoSearchRanges() {
        return [0, 5, 10, 15, 25];
    }

    isValueTrue(stringValue: string | boolean): boolean {
        return stringValue === 'true' || stringValue === 'True' || stringValue === true;
    }

    getResourceTypeRoomId(resourceTypes: ResourceTypeType[]) {
        const roomType: ResourceTypeType = lodash.find(resourceTypes, {isRoom: true});
        if (roomType) {
            return roomType.id;
        }
    }

    getItemsPerPageList(): number[] {
        return [5, 10];
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {},
            orderBy: {},
            expand: {}
        };
    }

    setTemplateLayout(pageTitle: string, action: string, createOrSaveButton?: string, cancelOrBackButton?: string): ScreenTemplateLayoutType {
        const template: ScreenTemplateLayoutType = {} as ScreenTemplateLayoutType;
        template.pageTitle = pageTitle;
        template.action = action;
        if (createOrSaveButton !== undefined) {
            template.createOrSaveButton = createOrSaveButton;
        }
        if (cancelOrBackButton) {
            template.cancelOrBackButton = cancelOrBackButton;
        }

        return template;
    }

    addSelectedValueFalseToObjectList(objectList: any[]): any[] {
        return lodash.map(objectList, (object) => {
            object.selected = false;
            return object;
        });
    }

    addSelectedValueTrueToObjectList(objectList: any[]): any[] {
        return lodash.map(objectList, (object) => {
            object.selected = true;
            return object;
        });
    }

    countDecimals(value: number): number {
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            return 0;
        }

        return value.toString().split('.')[1].length || 0;
    }

    validateRangeOfNumber(inputNumber: number, validation: ValidationNumberWithRange): boolean {
        if (!inputNumber && inputNumber !== 0) {
            return false;
        } else if ((inputNumber < Number(validation.errorProperties.minimumCoordinate))
            || (inputNumber > Number(validation.errorProperties.maximumCoordinate))
            || (this.countDecimals(inputNumber) > validation.errorProperties.decimals)) {
            return false;
        }

        return true;
    }

    isNumberWithRangeValid(input: number, validation: ValidationNumberWithRange): boolean {
        if (isNaN(Number(input))) {
            return false;
        } else {
            return this.validateRangeOfNumber(input, validation);
        }
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
        };
    }

    getTags(scopedResource: boolean) {
        const filterQuery: ODataQueryObjectType = {
            select: ['Id', 'Name'],
            filter: {ScopedResource: scopedResource}
        };
        return this.tagProvider.getEntries(filterQuery);
    }

    getPhoneNumberValidation(phoneNumber: string, isRequired: boolean, phoneCountryCode: string): FormValidationType {
        return this.validations.getValidatePhoneNumberLibPhone(phoneNumber, isRequired, phoneCountryCode) as FormValidationType;
    }

    getUrlValidation(url: string, isRequired: boolean): FormValidationType {
        if (this.isEmpty(url)) {
            return {isValid: !isRequired, errorMessage: isRequired ? 'label.pleaseAddUrl' : ''} as FormValidationType;
        }
        if (!url.match(this.RegexURL)) {
            return {isValid: false, errorMessage: 'label.invalidUrl'} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }

    isEmpty(value: any): boolean {
        return !!(value === undefined || value === null || value === '' || value === 'undefined');
    }

    isUndefinedOrNull(obj: any): boolean {
        return obj === undefined || obj === null;
    }

    getNumberFromStringOtherwiseUndefined(stringNumber: string): number {
        // Returns the number within a string. If the result is not a number, return undefined instead
        return isNaN(Number(stringNumber)) ? undefined : Number(stringNumber);
    }

    isSelectedNoValueOption(value: any): boolean {
        return (value === 'noValue');
    }

    displayItemsWithComma(values: any[]) {
        return values.filter(value => value).join(', ');
    }

    isAnyValueTruthy(values: any[]): boolean {
        return values.some(value => value);
    }

    isValueTypeOfNumber(value: any): boolean {
        // value = undefined || null || 'test' || {} || [] the method return false
        // value = '1' || 1 the method return true
        return /^-?\d+$/.test(value);
    }

    getSystemConfigValue(systemConfigValues: SystemConfigurationType[], key: string, parseResponse = false): string | GenericObjectType {
        if (systemConfigValues) {
            const systemConfigurationRaw = lodash.find(systemConfigValues, {name: key});

            if (systemConfigurationRaw?.value) {
                return parseResponse ? JSON.parse(systemConfigurationRaw.value) : systemConfigurationRaw.value;
            }
        }

        return '';
    }

    areEitherBothTruthyOrBothFalsy(a: any, b: any): boolean {
        return !!a === !!b;
    }

    isWholePositiveNumber(number: number, canBeZero: boolean = true): boolean {
        return number % 1 === 0 && (canBeZero ? number >= 0 : number > 0);
    }

    copyTextToClipboard(textToCopy: string): Promise<boolean> {
        return new Promise((resolve) => {
            const textarea = this.createTextAreaWithTextThatNeedsToBeCopied(textToCopy);
            document.body.appendChild(textarea);
            textarea.select();
            const successfullyCopied = document.execCommand('copy');
            document.body.removeChild(textarea);
            resolve(successfullyCopied);
        });
    }

    private createTextAreaWithTextThatNeedsToBeCopied(textToCopy: string) {
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        textarea.style.position = 'fixed';
        textarea.style.left = '-9999px';
        textarea.style.top = '-9999px';

        return textarea;
    }
}
