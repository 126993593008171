import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree, Router,
} from '@angular/router';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MessagesService} from '../../shared/services/messages.service';
import {constants} from '../../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class AccessRoute implements CanActivate {
    private isAfterLogin = true;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean
        | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkHaveAccess(state.url);
    }

    constructor(private router: Router,
                public configDataService: ConfigDataService,
                public messagesService: MessagesService) {

    }

    private checkHaveAccess(currentUrl: string): boolean {
        currentUrl = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
        const featureAccessActive = this.configDataService.checkFeatureAccessByRoute(currentUrl);
        const activityActive = this.configDataService.checkActivityByRoute(currentUrl);

        if (!featureAccessActive || !activityActive) {
            if (this.isAfterLogin) {
                // After login redirect user to the default page
                this.router.navigate([constants.DEFAULT_HOME_PAGE]);
            } else {
                this.messagesService.error('label.error.accessForbidden', true);
            }
            this.isAfterLogin = false;
            return false;
        }
        this.isAfterLogin = false;
        return true;
    }
}
