import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AppointmentStatusEnum, DateRangeResponseType, DateRangeType, GenericFilterResultType} from 'sked-base';
import {ResourceSwapUtils} from '../resource-swap.utils';
import {
    SwapResourcesFiltersSearchType,
    SwapResourcesFilterWrapperInitialValuesType
} from './resource-swap-filters.types';
import {ResourceSwapFiltersUtils} from './resource-swap-filters.utils';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';

@Component({
    selector: 'app-resource-swap-filters',
    templateUrl: './resource-swap-filters.component.html',
    styleUrls: ['./resource-swap-filters.component.scss']
})
export class ResourceSwapFiltersComponent implements OnInit, AfterViewInit {
    @Output() search: EventEmitter<SwapResourcesFiltersSearchType> = new EventEmitter<SwapResourcesFiltersSearchType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    appointmentStatusEnumList = Object.keys(AppointmentStatusEnum);

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR =
        '#resource-swap-filters-container sbase-filter-wrapper .filter-wrapper-component';

    constructor(public resourceSwapUtils: ResourceSwapUtils,
                private resourceSwapFiltersUtils: ResourceSwapFiltersUtils,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.registerToClearFilters();
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    // Filter values changed
    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues).forEach((filterName: string) => {
            this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues[filter.name] = filter.value;
        }
        // Validate
        this.validateFilterWrapperFilters();
        // Update state
        this.resourceSwapUtils.updateFilterState(['swapResourcesAppointmentListFiltersValues']);
    }

    // Filter values changed
    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.resourceSwapUtils.filtersOptions.dateRangeOptions.fromDate = dateRange.fromDate;
        this.resourceSwapUtils.filtersOptions.dateRangeOptions.toDate = dateRange.toDate;
        if (dateRange.fromDate && dateRange.toDate) {
            this.validateDateRange(dateRange.isValid);
        }
        // Update state
        this.resourceSwapUtils.updateFilterState(['dateRangeOptions']);
    }

    // Reset filters action
    onClear() {
        // Reset select filters
        this.resourceSwapUtils.filtersOptions.statusSelectNgModel = undefined;
        // Reset initial validations and date range options
        this.resetValidationsAndDateRanges();
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
        // Update state
        this.resourceSwapUtils.updateFilterState([
            'statusSelectNgModel', 'swapResourcesAppointmentListFiltersValues'
        ]);
    }

    // Search action
    onSearch() {
        const filterValues: SwapResourcesFiltersSearchType = this.resourceSwapFiltersUtils.mapFilterValuesForSearch(
            this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues,
            this.resourceSwapUtils.filtersOptions.dateRangeOptions,
            this.resourceSwapUtils.filtersOptions.statusSelectNgModel
        );
        this.validateDateRange();
        this.validateFilterWrapperFilters();
        if (this.resourceSwapUtils.filtersOptions.areFiltersValid) {
            this.search.emit(filterValues);
            this.changeCollapseFilterSection(true);
        }
        // Reset selected appointments
        this.resourceSwapUtils.appointmentsIds = [];
    }

    changeCollapseFilterSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.resourceSwapUtils.filtersOptions.areFiltersCollapsed =
            value ?? !this.resourceSwapUtils.filtersOptions.areFiltersCollapsed;
        // Update state
        this.resourceSwapUtils.updateFilterState(['areFiltersCollapsed']);
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.resourceSwapUtils.filtersOptions[option].hasOwnProperty('$ngOptionValue')) {
            this.resourceSwapUtils.filtersOptions[option] = undefined;
        }
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#resource-swap-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    // Validations
    private validateFilterWrapperFilters() {
        // Resource is required
        this.resourceSwapUtils.filtersOptions.filterValidations.resource.isValid =
            !!this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues.resource;
        this.updateFiltersValidation();
    }

    private updateFiltersValidation() {
        this.resourceSwapUtils.filtersOptions.areFiltersValid =
            this.resourceSwapUtils.filtersOptions.filterValidations.resource.isValid
            && this.resourceSwapUtils.filtersOptions.filterValidations.dateRange.isValid;
        // Update state
        this.resourceSwapUtils.updateFilterState(['filterValidations']);
    }

    private validateDateRange(isValidInner: boolean = true) {
        const {fromDate, toDate} = this.resourceSwapUtils.filtersOptions.dateRangeOptions;
        const dateFromMoment = moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format();
        const dateToMoment = moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).format();
        const isFromDateBeforeOrEqualWithToDate = moment(dateFromMoment).isSameOrBefore(dateToMoment);
        this.resourceSwapUtils.filtersOptions.filterValidations.dateRange = {
            isValid: isValidInner && !!fromDate && !!toDate && isFromDateBeforeOrEqualWithToDate,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    // Load data
    private resetValidationsAndDateRanges() {
        // Reset validations
        this.resourceSwapUtils.filtersOptions.filterValidations = this.resourceSwapFiltersUtils.getInitialFilterValidations();
        // Reset options for date ranges
        this.loadOptionsForDateRange();
        // Reset values for search event
        this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues =
            this.resourceSwapUtils.filtersOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.resourceSwapUtils.filtersOptions.filterWrapperInitialValues)
                : {} as SwapResourcesFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.validateFilterWrapperFilters();
        // Update state
        this.resourceSwapUtils.updateFilterState(['filterValidations', 'swapResourcesAppointmentListFiltersValues']);
    }

    private loadOptionsForDateRange() {
        this.resourceSwapUtils.filtersOptions.dateRangeOptions = this.resourceSwapUtils.getInitialDateRangeOptions();
        this.updateDateRangeValue.next({
            fromDate: this.resourceSwapUtils.filtersOptions.dateRangeOptions.fromDate,
            toDate: this.resourceSwapUtils.filtersOptions.dateRangeOptions.toDate
        } as DateRangeType);
        this.validateDateRange();
        // Update state
        this.resourceSwapUtils.updateFilterState(['dateRangeOptions']);
    }

    private loadOptionsFromState() {
        // Load options from state (filter wrapper component needs special attention)
        this.resourceSwapUtils.filtersOptions = {
            ...lodash.cloneDeep(this.resourceSwapUtils.swapResourcesAppointmentListState.filtersOptions),
            // Need initial values to show them on filters
            filterWrapperOptions: this.resourceSwapFiltersUtils.getFilterWrapperOptions(
                this.resourceSwapUtils.swapResourcesAppointmentListState.filtersOptions.swapResourcesFiltersValues
            )
        };
        // Tell filter wrapper component to preselect values from state
        const previousValues = Object.keys(this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues).length !== 0
            ? this.resourceSwapUtils.filtersOptions.swapResourcesFiltersValues
            : this.resourceSwapUtils.filtersOptions.latestSearchFilterValues;
        this.updateFilterWrapperValues.next(
            this.resourceSwapFiltersUtils.getInitialFilterWrapperValuesFromPreviousValues(previousValues)
        );
        this.resourceSwapUtils.shouldKeepFiltersState = false;
    }

    private registerToClearFilters() {
        this.resourceSwapUtils?.clearFiltersEmitter?.subscribe(() => {
            this.onClear();
        });
    }

    private loadInitialValues() {
        if (
            !!this.resourceSwapUtils.shouldKeepFiltersState
            && !!this.resourceSwapUtils.swapResourcesAppointmentListState?.filtersOptions
        ) {
            this.loadOptionsFromState();
            return;
        }
        // Reset filter options
        this.resourceSwapUtils.filtersOptions = this.resourceSwapUtils.getEmptyFiltersOptions();
        // Load options for the filter wrapper component
        this.resourceSwapUtils.filtersOptions.filterWrapperOptions = this.resourceSwapFiltersUtils.getFilterWrapperOptions(
            this.resourceSwapUtils.filtersOptions.filterWrapperInitialValues
        );
        // Load initial validations and date ranges options
        this.resetValidationsAndDateRanges();
        // Initialize select filters
        this.resourceSwapUtils.filtersOptions.statusSelectNgModel = undefined;
        // Reset the initial values so the reset button will clear the filters instead of resetting to these initial values
        this.resourceSwapUtils.filtersOptions.filterWrapperInitialValues = undefined;
        // Update state
        this.resourceSwapUtils.updateFilterState([
            'filterWrapperOptions', 'statusSelectNgModel', 'filterWrapperInitialValues'
        ]);
    }
}
