import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PatientActionsService} from '../../shared/services/patient-actions/patient-actions.service';
import {PatientDocumentsOptionsType} from 'sked-base/lib/data-model/patientDocumentsTypes';
import {PatientSearchActionsCallbackType} from 'sked-base';
import {PatientContextService} from '../../shared/services/patient-context.service';

@Component({
    selector: 'app-patient-documents',
    templateUrl: './patient-documents.component.html',
    styleUrls: ['./patient-documents.component.scss']
})

export class PatientDocumentsComponent implements OnInit {
    patientDocumentsOptions: PatientDocumentsOptionsType;

    constructor(
        public messagesService: MessagesService,
        public configDataService: ConfigDataService,
        private patientActionsService: PatientActionsService,
        private patientContextService: PatientContextService
    ) {
    }

    ngOnInit(): void {
        this.patientDocumentsOptions = {
            configDataService: this.configDataService,
            messagesService: this.messagesService,
            ...(this.patientContextService.patient ? {initialPatientId: this.patientContextService.patient.id} : {})
        };
    }

    onPatientSearchActions(actionCallback: PatientSearchActionsCallbackType) {
        this.patientActionsService.patientId = actionCallback?.patientId;
        this.patientActionsService.onAction(actionCallback);
    }
}
