import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    AppointmentStatusEnum,
    DateRangeResponseType,
    DateRangeType,
    GenericFilterResultType,
    SkedTaskStatusEnum
} from 'sked-base';
import {AppointmentListUtils} from '../appointment-list.utils';
import {
    AppointmentListFiltersSearchType,
    AppointmentListFilterWrapperInitialValuesType
} from './appointment-list-filters.types';
import * as lodash from 'lodash';
import {AppointmentListFiltersUtils} from './appointment-list-filters.utils';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';

@Component({
    selector: 'app-appointment-list-filters',
    templateUrl: './appointment-list-filters.component.html',
    styleUrls: ['./appointment-list-filters.component.scss']
})
export class AppointmentListFiltersComponent implements OnInit, AfterViewInit {
    @Output() search: EventEmitter<AppointmentListFiltersSearchType> = new EventEmitter <AppointmentListFiltersSearchType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    appointmentStatusEnumList = Object.keys(AppointmentStatusEnum);

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR =
        '#appointment-list-filters-container sbase-filter-wrapper .filter-wrapper-component';

    constructor(public appointmentListUtils: AppointmentListUtils,
                public appointmentListFiltersUtils: AppointmentListFiltersUtils,
                private patientContextService: PatientContextService,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.registerToClearFilters();
        this.registerToPatientChange();
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    // Filter values changed
    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.appointmentListUtils.filtersOptions.appointmentListFiltersValues).forEach((filterName: string) => {
            this.appointmentListUtils.filtersOptions.appointmentListFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.appointmentListUtils.filtersOptions.appointmentListFiltersValues[filter.name] = filter.value;
        }
        // Validate
        this.validateFilterWrapperFilters();
        // Update state
        this.appointmentListUtils.updateFilterState(['appointmentListFiltersValues']);
    }

    // Filter values changed
    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.appointmentListUtils.filtersOptions.dateRangeOptions.fromDate = dateRange.fromDate;
        this.appointmentListUtils.filtersOptions.dateRangeOptions.toDate = dateRange.toDate;
        this.validateDateRange(dateRange.isValid);
        // Update state
        this.appointmentListUtils.updateFilterState(['dateRangeOptions']);
    }

    // Reset filters action
    onClear() {
        // Reset select filters
        this.appointmentListUtils.filtersOptions.statusSelectNgModel = undefined;
        // Reset initial validations and date range options
        this.resetValidationsAndDateRanges();
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
        // Update state
        this.appointmentListUtils.updateFilterState([
            'statusSelectNgModel', 'appointmentListFiltersValues'
        ]);
    }

    // Search action
    onSearch() {
        const filterValues: AppointmentListFiltersSearchType = this.appointmentListFiltersUtils.mapFilterValuesForSearch(
            this.appointmentListUtils.filtersOptions.appointmentListFiltersValues,
            this.appointmentListUtils.filtersOptions.dateRangeOptions,
            this.appointmentListUtils.filtersOptions.statusSelectNgModel
        );
        this.validateDateRange();
        this.validateFilterWrapperFilters();
        if (this.appointmentListUtils.filtersOptions.areFiltersValid) {
            this.search.emit(filterValues);
            this.changeCollapseFilterSection(true);
        }
    }

    changeCollapseFilterSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.appointmentListUtils.filtersOptions.areFiltersCollapsed =
            value ?? !this.appointmentListUtils.filtersOptions.areFiltersCollapsed;
        // Update state
        this.appointmentListUtils.updateFilterState(['areFiltersCollapsed']);
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.appointmentListUtils.filtersOptions[option].hasOwnProperty('$ngOptionValue') || this.appointmentListUtils.filtersOptions[option] === 'undefined') {
            this.appointmentListUtils.filtersOptions[option] = undefined;
        }
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#appointment-list-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    // Validations
    private validateFilterWrapperFilters() {
        this.updateFiltersValidation();
    }

    private updateFiltersValidation() {
        // here add validations for search
        this.appointmentListUtils.filtersOptions.areFiltersValid = this.appointmentListUtils.filtersOptions.filterValidations.dateRange.isValid;
        // Update state
        this.appointmentListUtils.updateFilterState(['filterValidations']);
    }

    private validateDateRange(isValidInner: boolean = true) {
        const {fromDate, toDate} = this.appointmentListUtils.filtersOptions.dateRangeOptions;
        const dateFromMoment = fromDate ? moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format() : undefined;
        const dateToMoment = toDate ? moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).format() : undefined;
        const isFromDateBeforeOrEqualWithToDate = moment(dateFromMoment).isSameOrBefore(dateToMoment);
        this.appointmentListUtils.filtersOptions.filterValidations.dateRange = {
            isValid: isValidInner && !!fromDate && !!toDate && isFromDateBeforeOrEqualWithToDate,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    // Load data
    private resetValidationsAndDateRanges() {
        // Reset validations
        this.appointmentListUtils.filtersOptions.filterValidations = this.appointmentListFiltersUtils.getInitialFilterValidations();
        // Reset options for date ranges
        this.loadOptionsForDateRange();
        // Reset values for search event
        this.appointmentListUtils.filtersOptions.appointmentListFiltersValues =
            this.appointmentListUtils.filtersOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.appointmentListUtils.filtersOptions.filterWrapperInitialValues)
                : {} as AppointmentListFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.validateFilterWrapperFilters();
        // Update state
        this.appointmentListUtils.updateFilterState(['filterValidations', 'appointmentListFiltersValues']);
    }

    private loadOptionsForDateRange() {
        this.appointmentListUtils.filtersOptions.dateRangeOptions = this.appointmentListUtils.getInitialDateRangeOptions();
        this.updateDateRangeValue.next({
            fromDate: this.appointmentListUtils.filtersOptions.dateRangeOptions.fromDate,
            toDate: this.appointmentListUtils.filtersOptions.dateRangeOptions.toDate
        } as DateRangeType);
        this.validateDateRange();
        // Update state
        this.appointmentListUtils.updateFilterState(['dateRangeOptions']);
    }

    private loadOptionsFromState() {
        // Load options from state (filter wrapper component needs special attention)
        this.appointmentListUtils.filtersOptions = {
            ...lodash.cloneDeep(this.appointmentListUtils.appointmentListState.filtersOptions),
            // Need initial values to show them on filters
            filterWrapperOptions: this.appointmentListFiltersUtils.getFilterWrapperOptions(
                this.appointmentListUtils.appointmentListState.filtersOptions.appointmentListFiltersValues
            )
        };
        // Tell filter wrapper component to preselect values from state
        const previousValues = Object.keys(this.appointmentListUtils.filtersOptions.appointmentListFiltersValues).length !== 0
            ? this.appointmentListUtils.filtersOptions.appointmentListFiltersValues
            : this.appointmentListUtils.filtersOptions.latestSearchFilterValues;
        this.updateFilterWrapperValues.next(
            this.appointmentListFiltersUtils.getInitialFilterWrapperValuesFromPreviousValues(previousValues)
        );
        this.appointmentListUtils.shouldKeepFiltersState = false;
    }

    private registerToClearFilters() {
        this.appointmentListUtils?.clearFiltersEmitter?.subscribe(() => {
            this.onClear();
        });
    }

    private registerToPatientChange() {
        this.patientContextService?.patientChange?.subscribe(() => {
            this.updateFiltersValidation();
        });
    }

    private loadInitialValues() {
        if (
            !!this.appointmentListUtils.shouldKeepFiltersState
            && !!this.appointmentListUtils.appointmentListState?.filtersOptions
        ) {
            this.loadOptionsFromState();
            return;
        }
        // Reset filter options
        this.appointmentListUtils.filtersOptions = this.appointmentListUtils.getEmptyFiltersOptions();
        // Load options for the filter wrapper component
        this.appointmentListUtils.filtersOptions.filterWrapperOptions = this.appointmentListFiltersUtils.getFilterWrapperOptions(
            this.appointmentListUtils.filtersOptions.filterWrapperInitialValues
        );
        // Load initial validations and date ranges options
        this.resetValidationsAndDateRanges();
        // Initialize select filters
        this.appointmentListUtils.filtersOptions.statusSelectNgModel = undefined;
        // Reset the initial values so the reset button will clear the filters instead of resetting to these initial values
        this.appointmentListUtils.filtersOptions.filterWrapperInitialValues = undefined;
        // Update state
        this.appointmentListUtils.updateFilterState([
            'filterWrapperOptions', 'statusSelectNgModel', 'filterWrapperInitialValues'
        ]);
    }
}
