<div class="container-fluid resource-channel-restriction-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.timeWindowTab.timeWindowResourceService"></h3>
        <div class="filters-wrapper-component d-flex no-block float-right">
            <sbase-filter class="mr-1" (selectedItemsOutput)="onSelectedResourceFilter($event)"
                          [buttonName]="'label.resource' | translate"
                          [dependentFilters]="mainDependentFilters.resource"
                          [hideSelectedItems]="true"
                          [initialFilterValues]="resourceChannelRestrictionMdUtils.initialFilterValues.resource" [isMultipleSelectedItem]="false"
                          [providerInstance]="resourceProvider"
                          [useSelectedValueAsLabel]="true">
            </sbase-filter>
            <div class="service">
                <sbase-filter class="mr-2" (selectedItemsOutput)="onSelectedServiceFilter($event)"
                              [buttonName]="'label.service' | translate"
                              [dependentFilters]="mainDependentFilters.service"
                              [hideSelectedItems]="true"
                              [initialFilterValues]="resourceChannelRestrictionMdUtils.initialFilterValues.service" [isMultipleSelectedItem]="false"
                              [providerInstance]="serviceProvider"
                              [useSelectedValueAsLabel]="true">
                </sbase-filter>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between mt-3">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(resourceChannelRestrictionMdUtils.tableFilters.orderBy)">
                    <span *ngIf="key">
                        {{'label.' + (key !== 'centerOverrideCount' ? key : 'centerOverrides') |translate}}
                    </span> - {{'label.' + resourceChannelRestrictionMdUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <div class="channel-filter-container mr-2 d-flex flex-row justify-content-end">
            <sbase-filter (selectedItemsOutput)="onSelectedChannelFilter($event)"
                          [buttonName]="'label.timeWindowTab.channel' | translate"
                          [dependentFilters]="mainDependentFilters.channel"
                          [hideSelectedItems]="true"
                          [initialFilterValues]="resourceChannelRestrictionMdUtils.initialFilterValues.channel" [isMultipleSelectedItem]="false"
                          [providerInstance]="channelProvider"
                          [useSelectedValueAsLabel]="true">
            </sbase-filter>
            <button type="button" class="btn theme-alt-btn border-0 ml-1"
                    [activityDisplay]="'Key'+RESOURCE_CHANNEL_RESTRICTION+CONSTANTS.CREATE+'Activity'"
                    (click)="createResourceChannelRestriction()">{{'label.createNew'|translate}}
            </button>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('resource')">
                    {{'label.resource' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceChannelRestrictionMdUtils.tableFilters.orderBy.resource">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.resource === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.resource === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('service')">
                    {{'label.timeWindowTab.timeWindowPhysicianServiceTab.service' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceChannelRestrictionMdUtils.tableFilters.orderBy.service">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.service === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.service === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('channel')">
                    {{'label.timeWindowTab.channel' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceChannelRestrictionMdUtils.tableFilters.orderBy.channel">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.channel === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.channel === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('centerOverrideCount')">
                    {{'label.timeWindowTab.timeWindowPhysicianServiceTab.centersRestriction' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceChannelRestrictionMdUtils.tableFilters.orderBy.centersRestriction">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.centersRestriction === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.centersRestriction === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('days')">
                    {{'label.days' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceChannelRestrictionMdUtils.tableFilters.orderBy.days">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.days === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="resourceChannelRestrictionMdUtils.tableFilters.orderBy.days === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                *ngFor="let resourceChannelRestriction of resourceChannelRestrictionMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewResourceChannelRestriction(resourceChannelRestriction)">
                <td class="name-column">{{resourceChannelRestriction.resource.name}}</td>
                <td class="name-column">
                    <span *ngIf="resourceChannelRestriction.service.speciality?.name" class="font-weight-bold">
                        {{resourceChannelRestriction.service.speciality.name}}
                    </span>
                    {{resourceChannelRestriction.service.name}}
                </td>
                <td class="name-column">{{'enum.channel.' + resourceChannelRestriction.channel | translate}}</td>
                <td class="name-column">{{resourceChannelRestriction.centerOverrideCount}}</td>
                <td class="name-column">{{resourceChannelRestriction.days}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewResourceChannelRestriction(resourceChannelRestriction)">
                                </button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editResourceChannelRestriction(resourceChannelRestriction)"
                                        [activityDisplay]="'Key'+RESOURCE_CHANNEL_RESTRICTION+CONSTANTS.UPDATE+'Activity'">
                                </button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(resourceChannelRestriction.id)"
                                        [activityDisplay]="'Key'+RESOURCE_CHANNEL_RESTRICTION+CONSTANTS.DELETE+'Activity'">
                                </button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(resourceChannelRestriction.id, EntityTypeEnum.resourceChannelRestriction, resourceChannelRestriction.resource.name)"
                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ">
                                </button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="resourceChannelRestrictionMdUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="resourceChannelRestrictionMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)">
        </ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{resourceChannelRestrictionMdUtils.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start" x-placement="bottom-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}
                    </a>
                </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
