import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    CenterOverviewFiltersType,
    CenterProvider,
    HttpBaseProvider,
    LocationDependentFiltersType,
    ODataQueryObjectType,
    ReservationInstanceType,
    ResourceDependentFiltersType,
    ResourceProvider,
    ResourceTimeSlotsFiltersType,
    ResourceTypeProvider,
    RoomReservationProvider, SearchFilterUtils, TagDependentFiltersScopeEnum, TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {NgbDatepicker, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RoomReservationUtils} from './room-reservation.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    ConflictInstances,
    CalendarResourceType,
    RoomReservationFilterType,
    FullCalendarEventDropType,
    FullCalendarEventLeaveReceiveType,
    FullCalendarEventRenderType,
    FullCalendarEventEventDragOrClickType
} from './room-reservation.types';
import {CustomButtonInput, CalendarOptions} from '@fullcalendar/core';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {MessagesService} from '../../shared/services/messages.service';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {CreateReservationModalComponent} from './create-reservation-modal.component';
import {forkJoin, of} from 'rxjs';
import {Calendar} from '@fullcalendar/core';
import {constants} from '../../shared/constants/constants';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {roomReservationConstants} from './room-reservation.constants';
import {environment} from '../../../environments/environment';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {EventInput} from '@fullcalendar/common';
import {GeneralUtils} from '../../shared/utils/general.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-room-reservation',
    templateUrl: './room-reservation.component.html',
    styleUrls: ['./room-reservation.component.scss']
})

export class RoomReservationComponent implements OnInit, OnDestroy {
    // references the #calendar in the template
    @ViewChild('resourceCalendar', {static: false}) resourceCalendarComponent: FullCalendarComponent;
    @ViewChild('roomsCalendar', {static: false}) roomsCalendarComponent: FullCalendarComponent;
    @ViewChild('datepicker', {static: false}) datepicker: NgbDatepicker;

    resourceCalendarOptions: CalendarOptions;
    roomsCalendarOptions: CalendarOptions;
    private resourceCalendarApi: Calendar;
    private roomsCalendarApi: Calendar;
    private generalCalendarOptions: CalendarOptions;
    private eventsModelForResourceCalendar: EventInput[] = [];
    private eventsModelForRoomsCalendar: EventInput[] = [];
    private resourcesForResourceCalendar: CalendarResourceType[] = [];
    private resourcesForRoomsCalendar: CalendarResourceType[] = [];
    private fullCalendarLicenseKey = constants.FULL_CALENDAR_LICENSE_KEY;
    private translatedLanguage: string;
    private localConflict: ReservationInstanceType[] = [];
    private customButtons: { [name: string]: CustomButtonInput };
    private translatedText: { [key: string]: string } = {};
    areMoreAvailableRooms = false;
    showCalendars = false;
    //Filters: resource, center, selected currentDate filters
    mainDependentFilters: {
        location: LocationDependentFiltersType,
        resource: ResourceDependentFiltersType,
        room: ResourceDependentFiltersType,
        tags: TagDependentFiltersType
    };
    requestTableFilters: RoomReservationFilterType;

    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    initialRoomValues: any[] = [];
    centerButtonName = 'entities.center';
    resourceButtonName = 'entities.resource';
    roomButtonName = 'entities.room';
    calendarSpinnerId = 'calendarSpinner';
    RESOURCE_OVERVIEW = roomReservationConstants.RESOURCE_OVERVIEW;
    ROOM_OVERVIEW = roomReservationConstants.ROOM_OVERVIEW;
    showOverviewDropdown = false;
    displayedGrouppedConflicts = [];
    grouppedConflictsArray: { [key: string]: ConflictInstances[] } = {};
    conflictsArray: ConflictInstances[] = [];
    tags = [];

    constructor(public centerProvider: CenterProvider,
                public resourceProvider: ResourceProvider,
                public roomReservationUtils: RoomReservationUtils,
                private httpBaseProvider: HttpBaseProvider,
                public messagesService: MessagesService,
                public ngxLoader: NgxUiLoaderService,
                public translatedLanguageService: TranslatedLanguageService,
                private modalService: NgbModal,
                public roomReservationProvider: RoomReservationProvider,
                public tagProvider: TagProvider,
                public resourceTypeProvider: ResourceTypeProvider,
                private dateTimeUtils: DateTimeUtils,
                private changeDetectorRef: ChangeDetectorRef,
                private translateService: TranslateService,
                private generalUtils: GeneralUtils,
                private searchFilterUtils: SearchFilterUtils) {
    }

    /*************lifeCycles***********************/
    ngOnInit(): void {
        this.translatedLanguage = this.translatedLanguageService.getUsedLanguage().substring(0, 2);
        this.requestTableFilters = this.roomReservationUtils.getInitialTableFilters();

        this.translateLabels();
        this.defineFullCalendarOptions();


        this.mainDependentFilters = {
            location: this.roomReservationUtils.getLocationDependentFilters(),
            resource: this.roomReservationUtils.getResourceDependentFilters(),
            room: this.roomReservationUtils.getResourceDependentFilters(),
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedResource, true)

        };

        this.startTagsAndResourceTypesRequest();
    }

    ngOnDestroy(): void {
    }

    /*************filters***********************/
    onChangeOverview(selectedItem) {
        this.showOverviewDropdown = false;
        this.requestTableFilters.roomOrResourceOverview = selectedItem;
    }

    onSelectedCenterFilter(centerFilterList): void {
        this.initialCenterValues = centerFilterList;
        if (centerFilterList.length > 0) {
            this.mainDependentFilters.resource.locationId = centerFilterList[0].id;
            this.mainDependentFilters.room.locationId = centerFilterList[0].id;
            this.requestTableFilters.center = centerFilterList[0];
        } else {
            this.initialResourceValues = [];
            this.initialRoomValues = [];
            this.mainDependentFilters.resource.locationId = null;
            this.mainDependentFilters.room.locationId = null;
            this.requestTableFilters.center = null;
            this.requestTableFilters.resource = null;
            this.requestTableFilters.room = null;
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onSelectedResourceFilter(resourceFilterList): void {
        this.initialResourceValues = resourceFilterList;
        if (resourceFilterList.length > 0) {
            this.requestTableFilters.resource = resourceFilterList[0];
        } else {
            this.requestTableFilters.resource = null;
            this.areMoreAvailableRooms = false;
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onSelectedRoomFilter(roomFilterList): void {
        this.initialRoomValues = roomFilterList;
        if (roomFilterList.length > 0) {
            // this.mainDependentFilters.location.resourceId = roomFilterList[0].id;
            this.requestTableFilters.room = roomFilterList[0];
        } else {
            this.requestTableFilters.room = null;
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onChangeTags(tags) {
        this.requestTableFilters.tags = tags;
        this.getTablesInformation(this.requestTableFilters);
    }

    onShowFullyBookedRooms() {
        this.requestTableFilters.showFullyBookedRooms = !this.requestTableFilters.showFullyBookedRooms;
        this.getTablesInformation(this.requestTableFilters);
    }

    onSelectedDateFilter(date: NgbDateStruct): ConflictInstances[] {
        const formattedDate: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).format('YYYY-MM-DD');
        this.requestTableFilters.currentDate = date;
        const newStartDate = this.roomReservationUtils.getStartDate(this.requestTableFilters.currentDate, this.requestTableFilters.viewType);
        const newEndDate = this.roomReservationUtils.getEndDate(this.requestTableFilters.currentDate, this.requestTableFilters.viewType);

        // make a new request when is a new time range and if the time interval changed
        if (!lodash.isEqual(this.requestTableFilters.startDate, newStartDate) ||
            !lodash.isEqual(this.requestTableFilters.endDate, newEndDate)) {
            this.requestTableFilters.startDate = newStartDate;
            this.requestTableFilters.endDate = newEndDate;

            // tslint:disable-next-line:max-line-length
            this.resourceCalendarApi.gotoDate(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(this.requestTableFilters.startDate)).format('YYYY-MM-DD'));
            // tslint:disable-next-line:max-line-length
            this.roomsCalendarApi.gotoDate(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(this.requestTableFilters.startDate)).format('YYYY-MM-DD'));

            this.getTablesInformation(this.requestTableFilters);

            if (!lodash.isEmpty(this.grouppedConflictsArray)) {
                return this.conflictsArray = this.roomReservationUtils.getConflictDateList(this.grouppedConflictsArray[formattedDate]);
            }
        }
    }

    onClickedOutsideOverviewFilter(e: Event) {
        this.showOverviewDropdown = false;
    }

    // initialize the full calendars
    private defineFullCalendarOptions() {
        //general options for both calendars
        this.generalCalendarOptions = this.roomReservationUtils.getGeneralCalendarOptions();
        this.generalCalendarOptions.locales = [esLocale, enLocale, ptLocale]; // available languages
        this.generalCalendarOptions.locale = this.translatedLanguage; // calendar language
        this.generalCalendarOptions.plugins = [momentPlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimelinePlugin];

        //resource calendar options (first calender)
        this.resourceCalendarOptions = {
            aspectRatio: this.generalCalendarOptions.aspectRatio,
            contentHeight: this.generalCalendarOptions.contentHeight,
            customButtons: this.getResourceCalendarCustomButtons(), //Defines custom buttons that can be used in the header/footer.
            displayEventTime: this.generalCalendarOptions.displayEventTime, //boolean
            // tslint:disable-next-line:max-line-length
            dragRevertDuration: this.generalCalendarOptions.dragRevertDuration,
            droppable: this.generalCalendarOptions.droppable, // events droppable in calendar: boolean
            duration: this.generalCalendarOptions.duration, // calendar duration per view
            editable: this.generalCalendarOptions.editable, // events editable in calendar: boolean
            eventTimeFormat: this.generalCalendarOptions.eventTimeFormat, // Determines the time-text that will be displayed on each event.
            events: [], //events list
            //Triggered when event dragging starts.
            eventDragStart: (info) => this.onResourceCalendarEventDragStart(info),
            eventDidMount: (info) => this.onResourceCalendarEventRender(info),
            // Exact programmatic control over where an event can be dropped.
            eventAllow: (dropLocation, draggedEvent) => {
                return false;
            },
            firstDay: this.generalCalendarOptions.firstDay, //The day that each week begins. default: 0 (Sunday)
            headerToolbar: this.roomReservationUtils.getResourceCalendarHeaderOption(), //Defines the buttons and title at the top of the calendar.
            initialView: this.generalCalendarOptions.initialView, // Set initial view
            locales: this.generalCalendarOptions.locales, // all translated languages
            locale: this.generalCalendarOptions.locale, // language in use
            plugins: this.generalCalendarOptions.plugins,
            resourceAreaColumns: this.generalCalendarOptions.resourceAreaColumns, // property for displayed resource name
            resources: [], // resources list
            resourceAreaWidth: this.generalCalendarOptions.resourceAreaWidth, //Determines the width of the area that contains the list of resources.
            resourceAreaHeaderContent: this.translatedText.resourceTranslation, // resource column header name (translated)
            schedulerLicenseKey: this.fullCalendarLicenseKey,
            slotLabelFormat: this.generalCalendarOptions.slotLabelFormat, // text that will be displayed within a time slot
            slotDuration: this.generalCalendarOptions.slotDuration, //The frequency for displaying time slots
            slotLabelDidMount: (info) => this.onResourceCalendarDatesRender(info)
        };

        //rooms time-slots (second calender)
        this.roomsCalendarOptions = {
            resourceOrder: '',
            aspectRatio: 2,
            displayEventTime: this.generalCalendarOptions.displayEventTime, //boolean
            // tslint:disable-next-line:max-line-length
            dragRevertDuration: this.generalCalendarOptions.dragRevertDuration, //Time it takes for an event to revert to its original position after an unsuccessful drag.
            droppable: this.generalCalendarOptions.droppable, // events droppable in calendar: boolean
            duration: this.generalCalendarOptions.duration, // calendar duration per view
            editable: this.generalCalendarOptions.editable, // events editable in calendar: boolean
            eventTimeFormat: this.generalCalendarOptions.eventTimeFormat, // Determines the time-text that will be displayed on each event.
            events: [], //events list
            // Called when an external draggable element with associated event data was dropped onto the calendar.
            // Or drag an event from another calendar.
            eventReceive: (info) => this.onRoomsCalendarEventReceive(info),
            // this event is triggered when an event is moved in the same calendar
            eventDrop: (info) => this.onRoomsCalendarEventDrop(info),
            //Triggered when the user clicks an event.
            eventClick: (eventClickInfo) => this.onRoomsCalendarEventClick(eventClickInfo),
            eventDragStart: (info) => this.onRoomsCalendarEventDragStart(info), //Triggered when an event start dragging
            eventDidMount: (info) => this.onRoomsCalendarEventRender(info), //Triggered when an event was dragged
            // Exact programmatic control over where an event can be dropped.
            eventAllow: (dropLocation: any, draggedEvent: any) => {
                //check if event has reservationId and if it is moved in other room
                //do not allow moving the event in the same room
                if (draggedEvent._def.extendedProps.reservationId && dropLocation.resource._resource.id === draggedEvent._def.resourceIds[0]) {
                    return false;
                }
                //do not allow creations in past
                if (this.roomReservationUtils.isEventInPast(draggedEvent._def.extendedProps.eventDate, draggedEvent._def.extendedProps.hourTo)) {
                    return false;
                }
                return true;
            },
            //Determines if events being dragged and resized are allowed to overlap each other.
            eventOverlap: (stillEvent, movingEvent) => {
                return true;
            },
            firstDay: this.generalCalendarOptions.firstDay, //The day that each week begins. default: 0 (Sunday)
            headerToolbar: false, //Defines the buttons and title at the top of the calendar.
            initialView: this.generalCalendarOptions.initialView, // Set initial view
            locales: this.generalCalendarOptions.locales, // all translated languages
            locale: this.generalCalendarOptions.locale, // language in use
            plugins: this.generalCalendarOptions.plugins,
            resourceAreaHeaderContent: this.translatedText.roomsTranslation, // resource column header name (translated)
            resourceAreaColumns: this.generalCalendarOptions.resourceAreaColumns, // property for displayed resource name
            resourceAreaWidth: this.generalCalendarOptions.resourceAreaWidth,  //Determines the width of the area that contains the list of resources.
            resources: [], // resources list
            resourceLabelDidMount: (info) => this.onRoomsCalendarResourceNameRender(info),
            schedulerLicenseKey: this.fullCalendarLicenseKey,
            slotDuration: this.generalCalendarOptions.slotDuration //The frequency for displaying time slots
        };
    }

    /****************************************/
    // Calendars options
    // Resource Calendar options (first calender:#resourcecalendar)
    /****************************************/
    private getResourceCalendarCustomButtons(): { [name: string]: CustomButtonInput; } {
        this.customButtons = {
            customPreviousButton: {
                text: '<',
                click: () => {
                    this.resourceCalendarApi.prev();
                    this.roomsCalendarApi.prev();
                }
            },
            customNextButton: {
                text: '>',
                click: () => {
                    this.resourceCalendarApi.next();
                    this.roomsCalendarApi.next();
                }
            },
            customTodayButton: {
                text: this.translatedText.todayTranslation,
                click: () => {
                    const today = moment().format('YYYY-MM-DD');
                    this.resourceCalendarApi.gotoDate(today);
                    this.roomsCalendarApi.gotoDate(today);
                    this.reRenderCalenders();
                }
            },
            customRefreshButton: {
                text: '↻',
                click: () => {
                    this.getTablesInformation(this.requestTableFilters);
                }
            }

        };
        return this.customButtons;
    }

    // Triggered when a new set of dates has been rendered.(next, previous, gotoDate)
    private onResourceCalendarDatesRender(info) {
        // get tables information for the new interval
        const currentDate = this.dateTimeUtils.convertDateInNgbDateStruct(info.date);
        const newStartDate = this.roomReservationUtils.getStartDate(currentDate, this.requestTableFilters.viewType);
        const newEndDate = this.roomReservationUtils.getEndDate(currentDate, this.requestTableFilters.viewType);

        // make a new request when is a new time range
        if (!lodash.isEqual(this.requestTableFilters.startDate, newStartDate) ||
            !lodash.isEqual(this.requestTableFilters.endDate, newEndDate)) {
            this.requestTableFilters.startDate = newStartDate;
            this.requestTableFilters.endDate = newEndDate;

            this.getTablesInformation(this.requestTableFilters);
        }
    }

    // Triggered while an event is being rendered. A hook for modifying its DOM.
    private onResourceCalendarEventRender(info) {
        // add blocked svg picture in front of title (in this case: title = time interval)
        if (info.event._def.extendedProps.status === constants.BLOCKED) {
            const titleEl = info.el.querySelector('.fc-event-title.fc-sticky');
            if (titleEl) {
                titleEl.innerHTML = this.roomReservationUtils.getSvgPicture(info.event._def.extendedProps.status) + info.event._def.title;
            }
        }
    }

    // Triggered when event dragging begins.
    private onResourceCalendarEventDragStart(info: FullCalendarEventEventDragOrClickType) {
        // adjust event styles (the only way to do that)
        const timeEl = info.el.querySelector('.fc-event-title');
        info.el.style.backgroundColor = '#f8f9fd';
        info.el.style.borderColor = '#f8f9fd';
        info.el.style.opacity = '1';
        info.el.style.borderRadius = '5px 5px 5px 5px';
        info.el.style['-moz-border-radius'] = '5px 5px 5px 5px';
        info.el.style['-webkit-border-radius'] = '5px 5px 5px 5px';
        // @ts-ignore
        timeEl.style.color = '#000000';
    }

    /****************************************/
    // Rooms Calendar options (second calender:#roomscalendar)
    /****************************************/

    // Resource name did mount (rendered)
    private onRoomsCalendarResourceNameRender(info) {
        // get text element
        const roomNameEl: any = info.el.querySelector('.fc-datagrid-cell-main');
        // check if the resource has reservation
        if (info.resource._resource.extendedProps.isResourceHaveReservation) {
            this.messagesService.translateMessage('label.roomHasReservation')
                .subscribe(translatedMessage => {
                    // add hasReservation bullet and tooltip
                    roomNameEl.classList.add('bullet-point-rooms');
                    roomNameEl.setAttribute('title', translatedMessage);
                });
        }
    }

    // Triggered when an event is rendered. A hook for modifying its DOM.
    private onRoomsCalendarEventRender(info: FullCalendarEventRenderType) {
        // add blocked svg picture in front of title (in this case in title = time interval)
        if (info.event._def.extendedProps.status === constants.BLOCKED) {
            const titleEl = info.el.querySelector('.fc-event-title.fc-sticky');
            if (titleEl) {
                titleEl.innerHTML = this.roomReservationUtils.getSvgPicture(info.event._def.extendedProps.status) + info.event._def.title;
            }
        }
    }

    // Triggered when an event is dragged on other row
    private onRoomsCalendarEventDragStart(info: FullCalendarEventEventDragOrClickType) {
        // adjust event styles (the only way to do that)
        info.el.style.borderRadius = '5px 5px 5px 5px';
        info.el.style['white-space'] = 'initial';
    }

    // Triggered when an event is on final position
    private onRoomsCalendarEventDrop(info: FullCalendarEventDropType) {
        this.openCreateReservationModal(info, constants.MOVE);
    }

    // The event is dragged from other calendar
    private onRoomsCalendarEventReceive(info: FullCalendarEventLeaveReceiveType) {
        this.openCreateReservationModal(info, constants.CREATE);
    }

    // Click event
    private onRoomsCalendarEventClick(info: FullCalendarEventEventDragOrClickType) {
        this.openCreateReservationModal(info, constants.CLICK);
    }

    /****************************************/
    // end calendars options
    /****************************************/

    //get all the tags for tag filter and add resourceTypeId in resourceTypeExclusionList if resourceType is 'room'
    private startTagsAndResourceTypesRequest() {
        const filter: ODataQueryObjectType = {
            select: ['Id', 'Name', 'ScopedResource'],
            filter: {ScopedResource: true}
        };

        this.ngxLoader.start();
        forkJoin([
            this.tagProvider.getEntries(filter),
            this.resourceTypeProvider.getEntries({})
        ])
            .pipe(take(1))
            .subscribe(([tags, resourceType]) => {
                this.tags = tags.value;
                const resourceTypeRoomId = this.roomReservationUtils.getIdOfResourceTypeRoom(resourceType.value);
                if (resourceTypeRoomId) {
                    this.mainDependentFilters.resource.resourceTypeExclusionList.push(resourceTypeRoomId);
                    this.mainDependentFilters.room.resourceTypeId = resourceTypeRoomId;
                }
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    //make request data
    public getTablesInformation(filter: RoomReservationFilterType) {
        const areFiltersValid = this.roomReservationUtils.areTableFiltersValid(filter);
        //show full calendar if filters are valid
        this.showCalendars = areFiltersValid;
        if (this.showCalendars) {
            this.getCalendarInstance();
        } else {
            this.cleanCalendars();
        }
        if (areFiltersValid) {
            const centerOverviewRequestFilter: CenterOverviewFiltersType = this.roomReservationUtils.getCenterOverviewRequestFilter(filter);
            const resourceTimeSlotsRequestFilter: ResourceTimeSlotsFiltersType = this.roomReservationUtils.getResourceTimeSlotsRequestFilter(filter);

            //start spinner
            this.ngxLoader.startLoader(this.calendarSpinnerId);

            //get new set of events
            this.getProviderListForTableFilter(this.requestTableFilters.resource, centerOverviewRequestFilter, resourceTimeSlotsRequestFilter)
                .pipe(take(1))
                .subscribe(([centerTimeSlots, resourceTimeSlots]) => {
                    this.cleanCalendars();
                    //add events and resources in calenders
                    this.addResourcesAndEventsInRoomsCalendar(centerTimeSlots.items, filter.resource);
                    this.areMoreAvailableRooms = this.roomReservationUtils.haveMoreAvailableRooms(centerTimeSlots.count);
                    if (resourceTimeSlots) {
                        this.addResourcesAndEventsInResourceCalendar(resourceTimeSlots.value, filter.resource);
                    }
                    //rerender calendars in order to display the data
                    this.reRenderCalenders();
                }, (message) => {
                    //stop spinner
                    this.ngxLoader.stopLoader(this.calendarSpinnerId);
                    //show error message
                    this.messagesService.handlingErrorMessage(message);
                }, () => {
                    //stop spinner
                    this.ngxLoader.stopLoader(this.calendarSpinnerId);
                });

        }
    }

    private addResourcesAndEventsInResourceCalendar(response, selectedResource) {
        const resources: CalendarResourceType[] = [{
            id: selectedResource.id,
            name: selectedResource.name,
            isResourceHaveReservation: selectedResource.isResourceHaveReservation
        }];
        const events: EventInput[] = this.roomReservationUtils.getResourceCalendarEvents(response, selectedResource);

        this.resourceCalendarOptions.resources = resources;
        this.resourceCalendarOptions.events = events;
        // fullCalendar needs to have the same instance of resources and events
        // resources and events cannot be initialized with empty array
        // for (const resource of resources) {
        //     this.resourcesForResourceCalendar.push(resource);
        // }
        // for (const event of events) {
        //     this.eventsModelForResourceCalendar.push(event);
        // }

    }

    private addResourcesAndEventsInRoomsCalendar(response, selectedResource) {
        const rooms: CalendarResourceType[] = this.roomReservationUtils.getResourcesForRoomsCalendar(response);
        const events: EventInput[] = this.roomReservationUtils.getRoomsCalendarEvents(response, selectedResource);

        this.roomsCalendarOptions.resources = rooms;
        this.roomsCalendarOptions.events = events;

        // fullCalendar needs to have the same instance of resources and events
        // resources and events cannot be initialized with empty array
        // for (const room of rooms) {
        //     this.resourcesForRoomsCalendar.push(room);
        // }
        // this.eventsModelForRoomsCalendar = lodash.cloneDeep(events);
        // for (const event of events) {
        //     this.eventsModelForRoomsCalendar.push(event);
        // }
    }

    /*******************************/
    // Modal operations
    /*******************************/

    // Create Reservation section
    openCreateReservationModal(draggedEvent: any, action: string) {
        let room: any | null;
        let events: any[];
        let calendarTimeSlots = [];
        // this.reRenderCalenders();
        //get resourceTimeSlot
        // tslint:disable-next-line:max-line-length
        const resourceTimeSlot: any = this.roomReservationUtils.getInformationForModal(draggedEvent.event, draggedEvent.event._def.extendedProps.eventDate, this.requestTableFilters.resource.id);
        if (action !== constants.DELETE) {
            //get the room where the event is dropped
            room = this.roomsCalendarApi.getResourceById(resourceTimeSlot.roomId);
            //get all the events for selected room
            events = room.getEvents();
            //filter events by roomId and date
            calendarTimeSlots = this.roomReservationUtils.getExistingEventsIntervals(draggedEvent.event, events);
        }
        const modalRef = this.modalService
            .open(CreateReservationModalComponent, this.generalUtils.getModalOptions());

        modalRef.componentInstance.resourceTimeSlot = resourceTimeSlot;
        modalRef.componentInstance.calendarTimeSlots = calendarTimeSlots;
        modalRef.componentInstance.action = action;

        modalRef.result.then((result) => {
            //remove event from roomsCalendar
            this.onModalReturnReservation();
            //add all the conflicts in this.localConflicts
            if (action !== constants.CLICK) {
                this.localConflict = lodash.uniq(lodash.concat(this.localConflict, result.conflicts));
                this.setConflictsInformation(this.localConflict);
            }
        }, (onClose) => {
            this.onCloseModal(draggedEvent, action);
        });
    }

    private onModalReturnReservation(): void {
        this.getTablesInformation(this.requestTableFilters);
    }

    private onCloseModal(draggedEvent: any, action: string): void {
        if (action === constants.CREATE) {
            //remove event from roomsCalendar
            draggedEvent.event.remove();
            //reverse event to initial position in resource calendar
            this.reRenderCalenders();
            // this.eventsModelForResourceCalendar = this.eventsModelForResourceCalendar.concat(draggedEvent.event);
        } else if (action === constants.MOVE) {
            //reverse event to initial position in rooms calendar
            draggedEvent.revert();
        }
    }

    private setConflictsInformation(localConflict: ReservationInstanceType[]) {
        // this is used to display red dots in the calendar
        this.displayedGrouppedConflicts = lodash.uniqBy(localConflict, 'date');

        //grouped the conflicts by date
        this.grouppedConflictsArray = lodash.groupBy(this.roomReservationUtils.getConflictDateList(this.displayedGrouppedConflicts), 'formattedDate');
        this.grouppedConflictsArray = lodash.groupBy(this.roomReservationUtils.getConflictDateList(this.displayedGrouppedConflicts), 'formattedDate');

        // this is used to display all the conflicts
        this.conflictsArray = this.roomReservationUtils.getConflictDateList(localConflict);
    }

    private translateLabels() {
        // translate today, resource and rooms
        this.setTranslatedTexts(); // translate when the language didn't change
        // translate when language changed (and on refresh)
        this.translateService.onLangChange
            .subscribe((newLanguage) => {
                // set new language
                this.translatedLanguage = this.translatedLanguageService.getUsedLanguage().substring(0, 2);
                this.datepicker.focus();
                if (this.resourceCalendarApi) {
                    this.resourceCalendarApi.setOption('locale', this.translatedLanguage);
                }
                this.setTranslatedTexts();
            });

    }

    private setTranslatedTexts() {
        forkJoin([this.messagesService.translateMessage('label.today'),
            this.messagesService.translateMessage('label.resource'),
            this.messagesService.translateMessage('label.rooms')])
            .subscribe(translatedMessages => {
                this.translatedText.todayTranslation = translatedMessages[0];
                this.translatedText.resourceTranslation = translatedMessages[1];
                this.translatedText.roomsTranslation = translatedMessages[2];

                if (this.resourceCalendarOptions) {
                    this.resourceCalendarOptions.resourceAreaHeaderContent = this.translatedText.resourceTranslation;
                }
                if (this.roomsCalendarOptions) {
                    this.roomsCalendarOptions.resourceAreaHeaderContent = this.translatedText.roomsTranslation;
                }
                if (this.customButtons) {
                    this.customButtons.customTodayButton.text = this.translatedText.todayTranslation;
                }
            });
    }

    private reRenderCalenders() {
        this.resourceCalendarApi.refetchResources();

        this.roomsCalendarApi.refetchResources();

        const resourceEvents = this.resourceCalendarApi.getEventSources();
        const roomsEvents = this.roomsCalendarApi.getEventSources();

        // refetch events
        for (const event of resourceEvents) {
            event.refetch();
        }

        for (const event of roomsEvents) {
            event.refetch();
        }
    }

    public getProviderListForTableFilter(resource, centerOverviewRequestFilter, resourceTimeSlotsRequestFilter): any {
        if (resource) {
            return forkJoin([this.roomReservationProvider.getCenterOverview(centerOverviewRequestFilter),
                this.roomReservationProvider.getResourceTimeSlots(resourceTimeSlotsRequestFilter)]);
        } else {
            return forkJoin([this.roomReservationProvider.getCenterOverview(centerOverviewRequestFilter),
                of(undefined)]);
        }
    }

    private getCalendarInstance() {
        this.changeDetectorRef.detectChanges();
        //get calendars instance
        this.resourceCalendarApi = this.resourceCalendarComponent.getApi();
        this.roomsCalendarApi = this.roomsCalendarComponent.getApi();
    }

    private cleanCalendars() {
        //clean calendars resources and events
        // @ts-ignore
        this.resourceCalendarOptions.resources.splice(0, this.resourceCalendarOptions.resources.length);
        // @ts-ignore
        this.resourceCalendarOptions.events.splice(0, this.resourceCalendarOptions.events.length);
        // @ts-ignore
        this.roomsCalendarOptions.resources.splice(0, this.roomsCalendarOptions.resources.length);
        // @ts-ignore
        this.roomsCalendarOptions.events.splice(0, this.roomsCalendarOptions.events.length);

        if (this.resourceCalendarApi) {
            this.resourceCalendarApi.removeAllEvents();
        }
        if (this.roomsCalendarApi) {
            this.roomsCalendarApi.removeAllEvents();
        }

        // this.resourcesForResourceCalendar.splice(0, this.resourcesForResourceCalendar.length);
        // this.eventsModelForResourceCalendar.splice(0, this.eventsModelForResourceCalendar.length);
        // this.resourcesForRoomsCalendar.splice(0, this.resourcesForRoomsCalendar.length);
        // this.eventsModelForRoomsCalendar.splice(0, this.eventsModelForRoomsCalendar.length);

        // const resourceCalendarEvents = this.resourceCalendarApi.getEvents();
        // for (const event of resourceCalendarEvents) {
        //     event.remove();
        // }
        // const roomsCalendarEvents = this.roomsCalendarApi.getEvents();
        // for (const event of roomsCalendarEvents) {
        //     event.remove();
        // }
    }
}
