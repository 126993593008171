import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    BackofficeExclusionDataType,
    BackofficeExclusionTimeSlotType, ExclusionStatusEnum,
    ExclusionValidationType,
    ValidCreateExclusionType
} from '../exclusion.types';
import {MatStepper} from '@angular/material/stepper';
import {ActionType, FormValidationType, ScreenTemplateLayoutType} from '../../../data-model/general.type';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {ExclusionUtils} from '../exclusion-utils';
import {
    CenterProvider,
    CenterType,
    DateRangeOptionsType,
    DateRangeResponseType,
    DateRangeType,
    ExclusionOverviewTimeSlotType,
    ExclusionProvider,
    ExclusionReasonProvider,
    ExclusionTimeSlotType,
    ExclusionType,
    ExclusionTypeEnum,
    FilterWrapperNameEnum,
    LocationSearchType,
    ResourceProvider,
    ResourceSearchType,
    SearchFilterUtils
} from 'sked-base';
import {take} from 'rxjs/operators';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {TimeSlotsUtils} from '../../../shared/utils/time-slots.utils';
import {forkJoin} from 'rxjs';
import {DateAndTimeRangeType} from '../../availability/availability.types';
import {NgxTimepickerFieldComponent} from 'ngx-material-timepicker';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-exclusion',
    templateUrl: './create-exclusion.component.html',
    styleUrls: ['./create-exclusion.component.scss']
})
export class CreateExclusionComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly VIEW = ActionType.View;
    readonly CREATE = ActionType.Create;
    readonly EDIT = ActionType.Edit;
    ExclusionTypeEnum = ExclusionTypeEnum;
    action: ActionType;
    validateExclusionLoaderId = 'validateExclusionLoader';
    validTemplate: ValidCreateExclusionType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;
    @ViewChild('timePickerTimeFrom') timePickerTimeFrom: NgxTimepickerFieldComponent;
    totalStepsCount: number;
    exclusionData: BackofficeExclusionDataType = {} as BackofficeExclusionDataType;
    mainDependentFilters: any;
    dateRangeOptions: DateRangeOptionsType;
    timeZones = [];
    isExclusionCenterActivityActive: boolean;
    exclusionStatusEnum = ExclusionStatusEnum;

    scopedTimeSlot: BackofficeExclusionTimeSlotType;
    timeSlotDaysList: string[] = this.timeSlotsUtils.getWeekDays();
    updateExclusionTimeSlots: boolean;

    exclusionValidation = {} as ExclusionValidationType;

    updateDateRange: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    isTimeValid = true;
    isTimeSlotTimeValid = true;

    exclusionDateAndTimeRange: DateAndTimeRangeType;
    exclusionRangeValidation: FormValidationType;

    constructor(
        private exclusionProvider: ExclusionProvider,
        public exclusionUtils: ExclusionUtils,
        public exclusionReasonProvider: ExclusionReasonProvider,
        public dateTimeUtils: DateTimeUtils,
        private searchFilterUtils: SearchFilterUtils,
        public centerProvider: CenterProvider,
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public resourceProvider: ResourceProvider,
        private timeSlotsUtils: TimeSlotsUtils,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        // we need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(key));
        });
        if (this.action === ActionType.Edit && this.myStepper?._steps?.length) {
            // set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.myStepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
    }

    ngOnDestroy(): void {
    }

    goBack(stepper: MatStepper) {
        if (this.updateExclusionTimeSlots) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
        } else {
            stepper.previous();
        }
    }

    goForward(stepper: MatStepper) {
        if (this.updateExclusionTimeSlots) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
        } else if (this.areStepsValid(this.validTemplate, stepper.selectedIndex)) {
            stepper.next();
        }
    }

    onStepHeaderClick(stepClicked: number) {
        const selectedIndex = this.myStepper.selectedIndex;
        if (stepClicked < selectedIndex) {
            this.goBack(this.myStepper);
        }
        if (stepClicked + 1 < selectedIndex) {
            this.goBack(this.myStepper);
        }
        if (stepClicked > selectedIndex) {
            this.goForward(this.myStepper);
        }
        if (stepClicked > selectedIndex + 1) {
            this.goForward(this.myStepper);
        }
        // click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
        // user just clicked on stepClicked and the move was successful
        this.displayErrorToastr(selectedIndex, stepClicked);
    }

    onStepChange(stepper: StepperSelectionEvent) {
        const currentStep = stepper.selectedIndex;
        const previousStep = stepper.previouslySelectedIndex;
        if (currentStep === 2) {
            // this.exclusionData = this.exclusionUtils.addTimeToDateByExclusionType(this.exclusionData);
            // Prepare data to be shown correctly
            if (this.exclusionData.endOfDay) {
                this.exclusionData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                    this.exclusionData.validTo, this.exclusionData.timeZoneId, 1
                );
            }
        } else {
            if (previousStep === 2 && this.exclusionData.endOfDay) {
                // When we leave the last step, Subtract back one day (added when we reached last step)
                this.exclusionData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                    this.exclusionData.validTo, this.exclusionData.timeZoneId, -1
                );
            }
        }
    }

    displayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    // used to check whether you can go to other steps or not
    getStepControl(step: number): { invalid?: boolean, pending?: boolean } {
        return {
            invalid: !this.areStepsValid(this.validTemplate, step)
        };
    }

    areStepsValid(validTemplate: ValidCreateExclusionType, currentStep): boolean {
        switch (currentStep) {
            case 0:
                return this.exclusionUtils.isGeneralInfoStepValid(this.exclusionData, this.isExclusionCenterActivityActive);
            case 1:
                return this.exclusionUtils.isTimeSlotsStepValid(this.exclusionData) && this.exclusionRangeValidation.isValid;
            default:
                return true; // other steps which don't need validation
        }
    }

    //GENERAL
    onSelectedResource(resourceList) {
        if (resourceList?.length > 0) {
            this.exclusionData.resource = resourceList[0];
            this.exclusionData.resourceId = resourceList[0].id;
            this.mainDependentFilters.location.resourceId = resourceList[0].id;
            this.loadTimeZoneId(this.exclusionData.centerId, this.exclusionData.resourceId);
        } else {
            this.exclusionData.resource = undefined;
            this.exclusionData.resourceId = undefined;
            this.mainDependentFilters.location.resourceId = undefined;
        }
    }

    onSelectedCenter(centerList) {
        if (centerList?.length > 0) {
            this.exclusionData.center = centerList[0];
            this.exclusionData.centerId = centerList[0].id;
            this.mainDependentFilters.resource.locationId = centerList[0].id;
            this.loadTimeZoneId(this.exclusionData.centerId, this.exclusionData.resourceId);
        } else {
            this.exclusionData.center = undefined;
            this.exclusionData.centerId = undefined;
            this.mainDependentFilters.resource.locationId = undefined;
        }
    }

    onSelectedExclusionReason(exclusionReasonList) {
        if (exclusionReasonList?.length > 0) {
            this.exclusionData.exclusionReasonData = exclusionReasonList[0];
            this.exclusionData.exclusionReasonId = exclusionReasonList[0].id;
            this.exclusionData.exclusionReason = exclusionReasonList[0].description;
            if (!exclusionReasonList[0].showExtraInformation) {
                this.exclusionData.exclusionExtraInformation = undefined;
            }
        } else {
            this.exclusionData.exclusionReasonData = undefined;
            this.exclusionData.exclusionReasonId = undefined;
            this.exclusionData.exclusionReason = undefined;
            this.exclusionData.exclusionExtraInformation = undefined;
        }
    }

    // TIMESLOT - Range type
    onEndOfDayChange() {
        this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();
        if (this.exclusionData.endOfDay === false) {
            // If we disable the endOfDay flag we set the time to what it previously was
            this.onValidToDateTimeChanged(this.exclusionDateAndTimeRange.dateTo, this.exclusionDateAndTimeRange.timeTo);
        }
    }

    onValidFromDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct, dateChanged: boolean = false) {
        // if validFrom date is changed, set validFrom time to 00:00
        if (dateChanged) {
            time = {hour: 0, minute: 0, second: 0} as NgbTimeStruct;
            this.timePickerTimeFrom.changeHour(0);
            this.timePickerTimeFrom.changeMinute(0);
        }
        this.exclusionDateAndTimeRange.timeFrom = time;
        this.exclusionData.validFrom = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.exclusionDateAndTimeRange.timeFrom, this.exclusionData.timeZoneId
        );
        this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();
    }

    onValidToDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct) {
        this.exclusionDateAndTimeRange.timeTo = time;
        this.exclusionData.validTo = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.exclusionDateAndTimeRange.timeTo, this.exclusionData.timeZoneId
        );
        this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();
    }

    getValidateExclusionDateAndTimeRange(): FormValidationType {
        return this.dateTimeUtils.getValidateDateAndTimeRange(
            this.exclusionData.validFrom,
            this.exclusionData.validTo,
            this.exclusionData.endOfDay,
            this.exclusionData.timeZoneId);
    }

    onExclusionTypeChange() {
        if (this.exclusionData.exclusionType === ExclusionTypeEnum.WholeDay) {
            this.exclusionData.endOfDay = true;
            // Reset time
            this.resetTime();
            // Reset timeslots
            this.exclusionData.timeSlots = [];
            this.clearScopedTimeSlot();
        } else if (this.exclusionData.exclusionType === ExclusionTypeEnum.Range) {
            this.exclusionData.endOfDay = true;
            // Reset timeslots
            this.exclusionData.timeSlots = [];
            this.clearScopedTimeSlot();
        } else if (this.exclusionData.exclusionType === ExclusionTypeEnum.Partial) {
            this.exclusionData.endOfDay = true;
            // Reset time
            this.resetTime();
        }
        this.updateDateRangeOptions();
        this.updateDateAndTimeRange();
    }

    // returns true if error was displayed
    private displayErrorToastr(selectedIndex, clickedIndex): boolean {
        if (this.updateExclusionTimeSlots) {
            // if clicked on previous or next step headers while having the timeslot create open:
            // set selectedIndex by hand
            this.myStepper.selectedIndex = 1;
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
            return;
        }
        // error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!this.areStepsValid(this.validTemplate, step)) {
                if (step === 1) {
                    this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                }
                if (selectedIndex !== step) {
                    this.myStepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    private resetTime() {
        this.exclusionData.validFrom = !!this.exclusionData.validFrom
            ? moment.parseZone(this.exclusionData.validFrom).startOf('day').format()
            : null;
        this.exclusionData.validTo = !!this.exclusionData.validTo
            ? moment.parseZone(this.exclusionData.validTo).startOf('day').format()
            : null;
    }

    private updateDateRangeOptions() {
        const dateRange: DateRangeType = {} as DateRangeType;
        dateRange.fromDate = !!this.exclusionData?.validFrom ?
            this.dateTimeUtils.convertStringInNgbDateStruct(this.exclusionData?.validFrom) as NgbDate :
            undefined;
        dateRange.toDate = !!this.exclusionData?.validTo ?
            this.dateTimeUtils.convertStringInNgbDateStruct(this.exclusionData?.validTo) as NgbDate :
            undefined;
        this.dateRangeOptions = {
            fromDate: dateRange.fromDate,
            toDate: dateRange.toDate,
            minDate: this.dateTimeUtils.getTodayNgbDateStruct() as NgbDate,
            maxDate: null,
            maximumRange: null,
            disabled: false
        };
    }

    private updateDateAndTimeRange() {
        this.exclusionDateAndTimeRange = this.dateTimeUtils.getDateAndTimeRangeFromString(
            this.exclusionData.validFrom, this.exclusionData.validTo, this.exclusionData.timeZoneId
        );
        this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();
    }

    //TIME SLOTS
    onDateRangeChanged(dateRangeObject: DateRangeResponseType) {
        if (dateRangeObject.isValid) {
            // tslint:disable-next-line:max-line-length
            this.exclusionData.validFrom = dateRangeObject.fromDate === null ? null : moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRangeObject.fromDate)).format();
            // tslint:disable-next-line:max-line-length
            this.exclusionData.validTo = dateRangeObject.toDate === null ? null : moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRangeObject.toDate)).format();
            this.updateDateAndTimeRange();
        }
    }

    onHourFromChanged(hourFromTime) {
        this.exclusionData.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.exclusionData.hourFromTime = hourFromTime;
        this.isTimeValid = !!(this.exclusionData.hourFrom && this.exclusionData.hourTo && this.exclusionData.hourTo > this.exclusionData.hourFrom);
    }

    onHourToChanged(hourToTime) {
        this.exclusionData.hourTo = moment.duration(hourToTime).asMinutes();
        this.exclusionData.hourToTime = hourToTime;
        this.isTimeValid = !!(this.exclusionData?.hourFrom && this.exclusionData?.hourTo && this.exclusionData.hourTo > this.exclusionData.hourFrom);
    }

    openTimeSlotsSection(action: ActionType, selectedTimeSlots: ExclusionTimeSlotType | undefined) {
        this.updateExclusionTimeSlots = !this.updateExclusionTimeSlots;
        this.scopedTimeSlot = this.exclusionUtils.getInitialTimeSlot();
    }

    onTimeSlotHourFromChanged(hourFromTime) {
        this.scopedTimeSlot.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.scopedTimeSlot.hourFromTime = hourFromTime;
        this.isTimeSlotTimeValid = !!(this.scopedTimeSlot.hourFrom && this.scopedTimeSlot.hourTo &&
            this.scopedTimeSlot.hourTo > this.scopedTimeSlot.hourFrom);
    }

    onTimeSlotHourToChanged(hourToTime) {
        this.scopedTimeSlot.hourTo = moment.duration(hourToTime).asMinutes();
        this.scopedTimeSlot.hourToTime = hourToTime;
        this.isTimeSlotTimeValid = !!(this.scopedTimeSlot?.hourFrom && this.scopedTimeSlot?.hourTo &&
            this.scopedTimeSlot.hourTo > this.scopedTimeSlot.hourFrom);
    }

    onSaveTimeSlot(scopedTimeSlot: BackofficeExclusionTimeSlotType) {
        const isTimeSlotValid = this.exclusionUtils.validateTimeSlot(scopedTimeSlot);
        if (isTimeSlotValid) {
            const timeSlotToSave = lodash.cloneDeep(this.exclusionUtils.mapTimeSlotFromViewToSave(scopedTimeSlot));
            //add or edit the services table
            if (scopedTimeSlot?.editedIndex >= 0) {
                this.exclusionData.timeSlots[scopedTimeSlot.editedIndex] = timeSlotToSave;
            } else {
                this.exclusionData.timeSlots.push(timeSlotToSave);
            }
            //show success message after timeslot was added
            this.messagesService.success('toastr.success.timeSlotAdded');

            // initialize scoped time slot
            this.clearScopedTimeSlot();
        }
    }

    onCancelTimeSlot() {
        this.clearScopedTimeSlot();
    }

    editExclusionTimeSlot(timeSlot: ExclusionOverviewTimeSlotType, index: number) {
        this.scopedTimeSlot = lodash.cloneDeep(timeSlot) as unknown as BackofficeExclusionTimeSlotType;
        this.scopedTimeSlot.editedIndex = index;
        // We need to force rerender hourFromTime and hourToTime, because if they already have values, and we want to change them they are not updated
        this.scopedTimeSlot.hourFromTime = null;
        this.scopedTimeSlot.hourToTime = null;
        this.changeDetectorRef.detectChanges();
        this.scopedTimeSlot.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.scopedTimeSlot.hourFrom);
        this.scopedTimeSlot.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.scopedTimeSlot.hourTo);
        this.scopedTimeSlot.selectedDays = this.timeSlotsUtils.getTimeSlotSelectedDays(timeSlot);
        this.updateExclusionTimeSlots = true;
    }

    deleteExclusionTimeSlot(timeSlot: ExclusionOverviewTimeSlotType, index: number) {
        this.exclusionData.timeSlots.splice(index, 1);
        this.onCancelTimeSlot();
    }

    areDatesValid(validFrom: string, validTo: string): boolean {
        return this.dateTimeUtils.isDateRangeTodayOrFuture(new Date(validFrom), new Date(validTo));
    }

    //OVERVIEW
    saveExclusionData(exclusionData: BackofficeExclusionDataType) {
        if (this.isTheWholeFormValid(this.validTemplate)) {
            const exclusionToSend = this.exclusionUtils.mapExclusionForSend(exclusionData);
            if (this.screenTemplateLayout.action === ActionType.Create || this.screenTemplateLayout.action === ActionType.Copy) {
                this.addExclusion(exclusionToSend);
            } else if (this.screenTemplateLayout.action === ActionType.Edit) {
                this.editExclusion(exclusionToSend);
            }
        }
    }

    goToParentPage() {
        if (!history?.state?.parentPageLink) {
            this.router.navigate(['/exclusion']);
        } else {
            this.router.navigate([history?.state?.parentPageLink]);
        }
    }

    goToEdit() {
        history.replaceState({exclusion: this.exclusionData, action: ActionType.Edit}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    isTheWholeFormValid(validTemplate: ValidCreateExclusionType): boolean {
        if (!this.areStepsValid(validTemplate, 0)) {
            return false;
        }

        if (!this.areStepsValid(validTemplate, 1)) {
            return false;
        }

        if (!this.areStepsValid(validTemplate, 2)) {
            return false;
        }

        return true;
    }

    hasResourceError(exclusion): boolean {
        return !!(!exclusion?.resource?.id && !exclusion?.center?.id && this.isExclusionCenterActivityActive) ||
            (!exclusion?.resource?.id && !this.isExclusionCenterActivityActive);
    }

    hasCenterError(exclusion): boolean {
        return !!((!exclusion?.resource?.id && !exclusion?.center?.id && this.isExclusionCenterActivityActive) ||
            (!exclusion?.center?.id && exclusion?.resource?.isRoom && this.isExclusionCenterActivityActive) ||
            (!exclusion?.center?.id && !this.isExclusionCenterActivityActive));
    }

    isTimeZoneValid(exclusion): boolean {
        return !!(exclusion.resourceId && exclusion?.resource?.id && !this.generalUtils.isEmpty(exclusion.timeZoneId));
    }

    private addExclusion(exclusionToSend: ExclusionType) {
        this.ngxLoader.start();
        this.exclusionProvider.addEntry(exclusionToSend)
            .pipe(take(1))
            .subscribe(response => {
                this.messagesService.success('toastr.success.newExclusionAdded');
                this.goToParentPage();
            }, err => {
                this.onErrorResponse(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private editExclusion(exclusionToSend: ExclusionType) {
        this.ngxLoader.start();
        this.exclusionProvider.editEntry(exclusionToSend)
            .pipe(take(1))
            .subscribe(response => {
                this.messagesService.success('toastr.success.exclusionEdit');
                this.goToParentPage();
            }, err => {
                this.onErrorResponse(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private setupInitialState() {
        this.isExclusionCenterActivityActive = this.exclusionUtils.getExclusionCenterActivity();
        this.mainDependentFilters = {
            location: this.searchFilterUtils.getLocationDependentFilters(),
            resource: this.searchFilterUtils.getResourceDependentFilters(),
            exclusionReason: this.exclusionUtils.getEmptyExclusionReasonDependentFilters()
        };
        this.exclusionDateAndTimeRange = this.dateTimeUtils.getInitialDateAndTimeRange();
        this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();

        if (!history?.state?.action) {
            this.action = ActionType.Create;
        } else {
            this.action = history?.state?.action;
        }
        if (this.action === ActionType.View) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', ActionType.View, undefined, 'button.back');
        } else if (this.action === ActionType.Edit) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', ActionType.Edit, 'button.save', 'label.close');
        } else if (this.action === ActionType.Copy) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.copy', ActionType.Copy, 'button.save', 'label.close');
        } else if (this.action === ActionType.Create) {
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', ActionType.Create, 'label.create', 'label.close');
            this.exclusionData = this.exclusionUtils.getInitialExclusionData();
            this.exclusionRangeValidation = this.getValidateExclusionDateAndTimeRange();
            this.checkForInitialFiltersValueForCreate(history?.state?.filters);
        }

        if (this.action !== ActionType.Create) {
            this.exclusionData = history?.state?.exclusion;
            if (this.exclusionData?.centerId) {
                this.exclusionData.center.id = this.exclusionData.centerId;
                this.mainDependentFilters.resource.locationId = this.exclusionData.centerId;
            }
            if (this.exclusionData?.resourceId) {
                this.exclusionData.resource.id = this.exclusionData.resourceId;
                this.mainDependentFilters.location.resourceId = this.exclusionData.resourceId;
            }
            this.loadAdditionalData(this.exclusionData);

            this.exclusionData.hourFromTime = this.exclusionUtils.getTimeFromDateTime(this.exclusionData.validFrom);
            this.exclusionData.hourToTime = this.exclusionUtils.getTimeFromDateTime(this.exclusionData.validTo);
            this.exclusionData.hourFrom = this.dateTimeUtils.getMinutesFromStringHours(this.exclusionData.hourFromTime);
            this.exclusionData.hourTo = this.dateTimeUtils.getMinutesFromStringHours(this.exclusionData.hourToTime);

            if (this.action !== ActionType.View && this.exclusionData.endOfDay) {
                this.exclusionData.validTo = this.dateTimeUtils.addOrSubtractDaysFromTimeString(
                    this.exclusionData.validTo, this.exclusionData.timeZoneId, -1
                );
            }
            this.updateDateRangeOptions();
            this.updateDateAndTimeRange();

            // this.updateDateRange.emit(dateRange);
        } else {
            this.dateRangeOptions = {
                ...this.dateTimeUtils.getInitialDateRangeOptions(),
                minDate: this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate,
                fromDate: this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate,
                toDate: this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate,
            };
        }
    }

    private checkForInitialFiltersValueForCreate(filters) {
        if (filters) {
            for (const filter of filters) {
                switch (filter.name) {
                    case FilterWrapperNameEnum.resource: {
                        this.exclusionData.resource = filter.value;
                        this.exclusionData.resourceId = filter?.value?.id;
                        this.mainDependentFilters.location.resourceId = filter?.value?.id;
                        this.loadTimeZoneId(this.exclusionData.centerId, this.exclusionData.resourceId);
                        break;
                    }
                    case FilterWrapperNameEnum.location: {
                        this.exclusionData.center = filter.value;
                        this.exclusionData.centerId = filter?.value?.id;
                        this.mainDependentFilters.resource.locationId = filter?.value?.id;
                        this.loadTimeZoneId(this.exclusionData.centerId, this.exclusionData.resourceId);
                        break;
                    }
                }
            }
        }
    }

    private onErrorResponse(err) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
    }

    private loadAdditionalData(exclusionData: BackofficeExclusionDataType) {
        this.ngxLoader.start();
        this.timeZones = [];
        forkJoin([this.centerProvider.getEntries(this.exclusionUtils.getQueryFilterForTimeZone(exclusionData.centerId, exclusionData.resourceId)),
            this.exclusionReasonProvider.getEntries(this.exclusionUtils.getQueryFilterForExclusionReason(exclusionData.exclusionReasonId))])
            .pipe(take(1))
            .subscribe(([center, exclusionReason]) => {
                this.handleTimeZoneResponse(center.value);
                this.exclusionData.exclusionReasonData = exclusionReason.value[0];
            }, err => {
                this.onErrorResponse(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private loadTimeZoneId(centerId: string, resourceId: string) {
        this.ngxLoader.start();
        this.timeZones = [];
        this.centerProvider.getEntries(this.exclusionUtils.getQueryFilterForTimeZone(centerId, resourceId))
            .pipe(take(1))
            .subscribe((center) => {
                this.handleTimeZoneResponse(center.value);
            }, err => {
                this.onErrorResponse(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private clearScopedTimeSlot() {
        this.scopedTimeSlot = {} as unknown as BackofficeExclusionTimeSlotType;
        this.updateExclusionTimeSlots = false;
    }

    private handleTimeZoneResponse(center: CenterType[]) {
        this.timeZones = lodash.uniqBy(center, 'timeZoneId');
        if (!this.generalUtils.isEmpty(this.exclusionData.timeZoneId)) {
            // check if the selected timeZoneId is among the new time zones
            const foundIndex = lodash.findIndex(this.timeZones, {timeZoneId: this.exclusionData.timeZoneId});
            if (foundIndex === -1) {
                this.exclusionData.timeZoneId = 'undefined';
            }
        }
        if (center?.length === 1 && this.generalUtils.isEmpty(this.exclusionData.timeZoneId)) {
            this.exclusionData.timeZoneId = center[0].timeZoneId;
        }
    }
}
