import * as moment from 'moment';
import {AppointmentInformationOptionsType, PatientSearchOptionsType} from 'sked-base';
import {
    SlotCenterType,
    SlotDisplayType,
    SlotExtraInformationType,
    SlotSearchAppointmentTypesType
} from 'sked-base/lib/data-model/slotTypes';
import {SlotsFiltersSelectValueType} from 'sked-base';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export interface SlotsManagementCalendarDayType {
    momentDate: moment.Moment;
    stringDate: string;
    displayDay: string;
    displaySlots?: number;
    isSelected?: boolean;
    isDisabled?: boolean;
}

export enum SlotsTypeEnum {
    Normal = 'Normal',
    Special = 'Special'
}

export enum SlotsManagementLocalFilterTypeEnum {
    AM = 'AM',
    PM = 'PM',
    IncludeSelfPayer = 'IncludeSelfPayer',
    AppointmentType = 'AppointmentType',
}

export interface SlotsManagementCalendarSelectedDayType {
    pageIndex: number;
    dayIndex: number;
}

export interface SlotsManagementCalendarPageType {
    calendarDays: SlotsManagementCalendarDayType[];
}

export interface SlotsManagementCalendarOptionsType {
    calendarPages: SlotsManagementCalendarPageType[];
    numberOfPages: number;
    slotsType: SlotsTypeEnum;
    currentPage: number;
    displayMonth: string;
    displayYear: string;
    searchStartDate?: NgbDateStruct;
    searchEndDate?: NgbDateStruct;
    selectedDay?: SlotsManagementCalendarDayType;
    previouslySelectedDay?: SlotsManagementCalendarSelectedDayType;
    areOptionsAfterSearch?: boolean;
}

export interface SlotsCentersOptionsType {
    centers: SlotCenterLocalFilterType[];
    selectedCenters: SlotCenterLocalFilterType[];
    numberOfSlotsForEachCenter: { [key in string]?: NumberOfSlotsType[] } | NumberOfSlotsType[];
}

export interface SlotCenterLocalFilterType extends SlotCenterType {
    selected: boolean;
}

export interface NumberOfSlotsType {
    numberOfSlotsAvailableToday: number;
    totalNumberOfAvailableSlots: number;
    selected: boolean;
}

export interface FirstTimeSlots {
    isFirstAMTimeSlot: boolean;
    isFirstPMTimeSlot: boolean;
}

export interface SlotsLocalFiltersOptionsType {
    selfPayer: boolean;
    am: boolean;
    pm: boolean;
    appointmentTypes: SlotSearchAppointmentTypesType[];
    selectedAppointmentType: SlotSearchAppointmentTypesType;
    displaySlotsLocalFilters: boolean;
}

export interface SlotsManagementStateType {
    patientSearchOptions?: PatientSearchOptionsType;
    slotsCalendarOptions?: SlotsManagementCalendarOptionsType;
    slotsLocalFiltersOptions?: SlotsLocalFiltersOptionsType;
    slotsCentersOptions?: SlotsCentersOptionsType;
    slotsResultsOptions?: SlotDisplayType[];
}

export interface SlotSearchModalOptionsType {
    filterValues: SlotsFiltersSelectValueType[];
    patient: PatientType;
    continueSearching?: boolean;
}

export interface OutsideAvailabilityAppointmentModalOptionsType {
    centerTimeZoneId: string;
    serviceDuration: number;
}

export interface OutsideAvailabilityAppointmentModalNgModelType {
    date: NgbDateStruct;
    time: string; // 'HH:MM'
    duration: number;
}

export interface OutsideAvailabilityAppointmentModalResponseType {
    dateTime: string;
    duration: number;
}

export interface SlotsManagementSubServiceType {
    subServiceId: string;
    id: string;
    shortId: number;
    duration: number;
    name: string;
}
