<div class="container-fluid create-max-appointment-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-max-appointment-rule-header">
        <h3 class="create-max-appointment-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.maxAppointmentRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.maxAppointmentRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.maxAppointmentRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.maxAppointmentRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.maxAppointmentRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule only warning-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.onlyWarning"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.onlyWarning"
                                                   name="onlyWarning">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Channel -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.channel"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedChannel($event)"
                                                  [buttonName]="'label.channel'"
                                                  [dependentFilters]="mainDependentFilters.channel"
                                                  [initialFilterValues]="initialChannel"
                                                  [providerInstance]="channelProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.channel}"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.channel"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.channel}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Rule scope -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="mb-1 mr-2" translate="label.ruleScope"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               [ngClass]="!!ruleItem.ruleScope ? 'ng-select-has-value' : 'input-element-has-error'"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.choose' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="ruleItem.ruleScope"
                                               (ngModelChange)="sanitizeNgSelectValue('ruleScope')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let item of ruleScopeEnumList"
                                                   [value]="item">
                                            <span [translate]="'enum.ruleScope.' + item"></span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem?.ruleScope"
                                         class="help-block">
                                            <span class="has-error"
                                                  translate="label.error.required">
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Resource -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]="'label.resource'"
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [initialFilterValues]="[ruleItem.resource]"
                                                  [providerInstance]="resourceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Center -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                                  [buttonName]="'label.center2'"
                                                  [dependentFilters]="mainDependentFilters.location"
                                                  [initialFilterValues]="[ruleItem.center]"
                                                  [providerInstance]="centerProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Area -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.area2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedArea($event)"
                                                  [buttonName]="'label.area2'"
                                                  [dependentFilters]="mainDependentFilters.area"
                                                  [initialFilterValues]="[ruleItem.area]"
                                                  [providerInstance]="areaProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Service tag -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label class="mb-1" translate="label.serviceTag"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedTag(constants.SERVICE, $event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.serviceTags"
                                                  [initialFilterValues]="maxAppointmentRuleTags.selectedServiceTags"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="tagProvider">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Speciality -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.speciality2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedSpeciality($event)"
                                                  [buttonName]="'label.speciality2'"
                                                  [dependentFilters]="mainDependentFilters.speciality"
                                                  [initialFilterValues]="[ruleItem.speciality]"
                                                  [providerInstance]="specialityProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Patient tag -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label translate="label.patientTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag(constants.PATIENT, $event)"
                                                            [buttonName]='"label.newTag"'
                                                            [dependentFilters]="mainDependentFilters?.patientTags"
                                                            [initialFilterValues]="maxAppointmentRuleTags.selectedPatientTags"
                                                            [isMultipleSelectedItem]="false"
                                                            [useSelectedValueAsLabel]="true"
                                                            [hideSelectedItems]="true"
                                                            [providerInstance]="tagProvider">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.patientTagId">
                                            <div class="switch">
                                                <label class="d-flex no-block justify-content-center">
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="maxAppointmentRuleTags.includePatientTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(constants.PATIENT, maxAppointmentRuleTags.includePatientTagFlag)"
                                                           name="includeCenterTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Maximum allowed appointments -->
                            <!-- =========================================== -->
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.maxAppointments"></label>
                                        <span>
                                            <input type="number" class="input-element"
                                                   placeholder="{{'label.maxAppointments' | translate}}"
                                                   autocomplete="off" min="1" max="999"
                                                   [ngClass]="{'input-element-has-error':
                                                   generalUtils.isNullOrUndefined(ruleItem?.maxAppointments)
                                                   || (!generalUtils.isNullOrUndefined(ruleItem?.maxAppointments) && ruleItem?.maxAppointments % 1 !== 0)
                                                   || (!generalUtils.isNullOrUndefined(ruleItem?.maxAppointments) && ruleItem?.maxAppointments % 1 === 0 && !isMaxAppointmentsValid(ruleItem.maxAppointments))}"
                                                   [(ngModel)]="ruleItem.maxAppointments">
                                        </span>
                                    </div>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.maxAppointments)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.maxAppointments) && ruleItem?.maxAppointments % 1 !== 0"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.invalidNumber">
                                        </span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.maxAppointments) && ruleItem?.maxAppointments % 1 === 0 && !isMaxAppointmentsValid(ruleItem.maxAppointments)"
                                         class="help-block">
                                            <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                                min: 1,
                                                max: 999
                                            } }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Message -->
                            <!-- =========================================== -->
                            <div class="col-9">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.message"></label>
                                        <input type="text" class="input-element"
                                               placeholder="{{'label.message' | translate}}"
                                               autocomplete="off"
                                               [ngClass]="{'input-element-has-error': !ruleItem?.message}"
                                               [(ngModel)]="ruleItem.message">
                                    </div>
                                    <div *ngIf="!ruleItem?.message"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.message}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.maxAppointmentRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.onlyWarning"></td>
                                            <td class="text-right">{{ruleItem.onlyWarning | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.channel"></td>
                                            <td class="text-right">{{('label.channels.' + ruleItem.channel) | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleScope"></td>
                                            <td class="text-right">{{('enum.ruleScope.' + ruleItem.ruleScope) | translate}}</td>
                                        </tr>
                                        <tr *ngIf="!!ruleItem.resource">
                                            <td class="font-weight-bold" translate="label.resource"></td>
                                            <td class="text-right">{{ruleItem.resource?.name}}</td>
                                        </tr>
                                        <tr *ngIf="!!ruleItem.center">
                                            <td class="font-weight-bold" translate="label.center2"></td>
                                            <td class="text-right">{{ruleItem.center?.name}}</td>
                                        </tr>
                                        <tr *ngIf="!!ruleItem.area">
                                            <td class="font-weight-bold" translate="label.area2"></td>
                                            <td class="text-right">{{ruleItem.area?.name}}</td>
                                        </tr>
                                        <tr *ngIf="!!ruleItem.speciality">
                                            <td class="font-weight-bold" translate="label.speciality2"></td>
                                            <td class="text-right">{{ruleItem.speciality?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.serviceTag"></td>
                                            <td class="text-right">{{ruleItem.tag?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.patientTag"></td>
                                            <td class="text-right">
                                                <i class="fas fa-plus-circle"
                                                   ngbTooltip="{{'label.include'| translate}}"
                                                   *ngIf="rulesUtils.displayIncludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                <i class="fas fa-minus-circle"
                                                   ngbTooltip="{{'label.exclude'| translate}}"
                                                   *ngIf="rulesUtils.displayExcludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                &nbsp;{{ruleItem.patientTag?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.maxAppointments"></td>
                                            <td class="text-right">{{ruleItem.maxAppointments}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.message"></td>
                                            <td class="text-right">{{ruleItem.message}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'MaxAppointmentRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
