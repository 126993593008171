import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import * as lodash from 'lodash';
import {
    CoveragePlanDependentFiltersType,
    CoveragePlanProvider, CoveragePlanSearchResultType, CoveragePlanType
} from 'sked-base';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import { formControlNames } from 'src/app/shared/constants/formControlNames';

@Component({
    selector: 'app-coverage-plans-form',
    templateUrl: './coverage-plans-form.component.html',
    styleUrls: ['./coverage-plans-form.component.scss']
})
export class CoveragePlansFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;

    coveragePlansForm: FormGroup;
    allInputs: DynamicFormInputType[] = [];
    mainDependentFilters: { mainCoveragePlan: CoveragePlanDependentFiltersType, secondaryCoveragePlans: CoveragePlanDependentFiltersType };
    formControlNames = formControlNames;
    mainInitialValues: CoveragePlanType[] = [];
    secondaryInitialValues: CoveragePlanType[] = [];

    constructor(public coveragePlanProvider: CoveragePlanProvider,
                public dynamicFormInputService: DynamicFormInputService,
                private rootFormGroup: FormGroupDirective) {
    }

    ngOnInit(): void {
        this.coveragePlansForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.loadDependentFilter();
        this.loadInitialValues();
    }

    onSelectedMainCoveragePlan(coveragePlanList: CoveragePlanSearchResultType[]) {
        this.coveragePlansForm.get(formControlNames.MAIN_COVERAGE_PLAN).patchValue(
            coveragePlanList?.length > 0 ? coveragePlanList : ''
        );
    }

    onSelectedSecondaryCoveragePlans(coveragePlanList: CoveragePlanSearchResultType[]) {
        this.coveragePlansForm.get(formControlNames.SECONDARY_COVERAGE_PLANS).patchValue(
            coveragePlanList?.length > 0 ? coveragePlanList : ''
        );
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private loadInitialValues() {
        this.mainInitialValues = this.coveragePlansForm?.get(formControlNames.MAIN_COVERAGE_PLAN)?.value ?? [];
        this.secondaryInitialValues = this.coveragePlansForm?.get(formControlNames.SECONDARY_COVERAGE_PLANS)?.value ?? [];
    }

    private loadDependentFilter() {
        this.mainDependentFilters = {
            mainCoveragePlan: this.getCoveragePlanDependentFilters(),
            secondaryCoveragePlans: this.getCoveragePlanDependentFilters(),
        };

        this.mainDependentFilters.mainCoveragePlan.onlyMainPlans = true;
        this.mainDependentFilters.mainCoveragePlan.onlySecondaryPlans = false;
        this.mainDependentFilters.secondaryCoveragePlans.onlyMainPlans = false;
        this.mainDependentFilters.secondaryCoveragePlans.onlySecondaryPlans = true;
    }

    private getCoveragePlanDependentFilters = (): CoveragePlanDependentFiltersType => ({
        searchPhrase: '',
        serviceId: null,
        onlyMainPlans: false,
        onlySecondaryPlans: false,
        excludeSystemManagedPlans: true,
        excludePrivatePlans: true,
        includeChannel: true,
        patientId: null,
        resourceId: null,
        areaId: null,
        exclusionList: [],
        count: true
    })

}
