<div class="resource-utilization-container">
    <!-- FILTERS -->
    <app-resource-utilization-filters *ngIf="resourceUtilizationUtils.filtersOptions.areFiltersReady"
                                      (search)="onFilterSearch($event)"
    ></app-resource-utilization-filters>
    <div class="resource-utilization-appointment-list-and-calendar-container mt-2">
        <!-- APPOINTMENT LIST -->
        <div *ngIf="resourceUtilizationUtils.isAppointmentListVisible"
             [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'appointment-list-container':'appointment-list-container-collapsed'">
            <app-appointment-list
                    (selectedAppointment)="onSelectedAppointmentFromList($event)"
                    (viewAppointmentInCalendar)="onViewAppointmentInCalendar($event)"
            ></app-appointment-list>
        </div>
        <!-- CALENDAR -->
        <div class="resource-utilization-calendar-container ml-2 p-3"
             *ngIf="displayNoResultsMessage">
            <span class="no-results-error">{{"label.thereAreNoResultsForYourSearch" | translate}}</span>
        </div>
        <div class="resource-utilization-calendar-container ml-2 p-3"
             [ngClass]="resourceUtilizationUtils.appointmentsListOptions.isAppointmentListCollapsed ? 'w-97':'w-70'"
             *ngIf="resourceUtilizationUtils.isCalendarVisible && resourceUtilizationUtils.appointments?.length > 0">
            <app-resource-utilization-calendar
                    (selectCalendarAppointment)="onSelectedAppointmentFromCalendar($event)"
            ></app-resource-utilization-calendar>
        </div>
    </div>
</div>
