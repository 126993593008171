<div class="container-fluid exclusion-overview-screen overview-screen-md pb-1">
    <div class="justify-content-between">
        <div class="row">
            <div class="col-4">
                <h3 translate="{{screenTemplateLayout.pageTitle}}"></h3>
            </div>
            <div class="col-8">
                <div class="d-flex availability-filters float-right" id="exclusion-filters-container">
                    <sbase-filter-wrapper
                            class="d-flex my-1"
                            [options]="filterWrapperOptions"
                            [updateFiltersValue]="updateFiltersValue"
                            (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"></sbase-filter-wrapper>
                    <!-- Status Filter -->
                    <div class="d-none" id="exclusion-filters-to-move" *ngIf="displayExclusionFiltersToMove">
                        <mat-form-field appearance="outline"
                                        class="form-control p-0 m-0">
                            <mat-select matNativeControl required
                                        disableOptionCentering
                                        (selectionChange)="onExclusionStatusFilterChange($event)"
                                        [(value)]="exclusionStatusFilterInitialValue">
                                <mat-option [value]="'undefined'"><span translate="label.status"></span>
                                </mat-option>
                                <mat-option *ngFor="let status of exclusionStatusesList"
                                            [value]="status"><span>{{'label.' + status|translate}}</span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="d-inline-block my-1 ml-2 mr-1">
                        <app-show-modal-filters
                                *ngIf="!!modalFilters"
                                [options]="modalFilters"
                                (onFiltersSelected)="onModalFiltersSelected($event)">
			            </app-show-modal-filters>
                    </div>

                    <button class="reset-button btn" (click)="onClearFilters()">
                        <i class="far fa-trash-alt"></i>
                        <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="tags-and-new-item-section d-flex justify-content-between mt-3">
        <!-- Tags Component -->
        <!-- ============================================================== -->
        <div>
            <sbase-tags *ngIf="(tagsList?.length > 0)"
                        (onDeleteTagOutput)="onDeleteTag($event)"
                        [tagsList]="tagsList"></sbase-tags>
        </div>
        <!-- ============================================================== -->
        <!-- End Tags Component -->
    </div>

    <div class="d-flex float-right"
         *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview">
        <button type="button" class="btn theme-alt-btn border-0"
                [activityDisplay]="EXCLUSION+constants.CREATE"
                (click)="createExclusion()" translate="label.createNew">
        </button>
    </div>

    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort">
            <span translate="label.activeSorting" class="text-high-contrast"></span>:
            <span class="text-info font-weight-high-contrast">
                <span *ngFor="let key of objectKeys(tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + tableFilters.orderBy[key] | translate}}
                    <span class="mr-1">
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex search mr-2">
            <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
            <input type="number" class="form-control rounded-0"
                   placeholder="{{'label.searchBy'|translate}} {{'label.shortId'|translate}}" autocomplete="off"
                   [(ngModel)]="tableFilters.filter.shortId" (ngModelChange)="onSearchByChanged($event)">
        </div>
    </div>
    <div class="table-responsive overview-table-container availability-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="id-column cursor-pointer" (click)="onSortBy('shortId')">
                    {{'label.shortId' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.shortId">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.shortId === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.shortId === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="name-column cursor-pointer" translate="label.name" (click)="onSortBy('resource')">
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.resourceName">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.resourceName === 'asc'">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.resourceName === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="working-hours-column" translate="label.timeSlots"></th>
                <th class="starting-column cursor-pointer" translate="label.validFrom" (click)="onSortBy('validFrom')">
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.validFrom">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.validFrom === 'asc'">
                        <i class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.validFrom === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="ending-column cursor-pointer" translate="label.validTo" (click)="onSortBy('validTo')">
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.validTo">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.validTo === 'asc'">
                        <i class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.validTo === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="status-column cursor-pointer" translate="label.status" (click)="onSortBy('status')">
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.status">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.status === 'asc'">
                        <i class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="tableFilters.orderBy.status === 'desc'">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="repetition-column" translate="label.repetition"></th>
                <th class="reason-column" translate="label.exclusionReason"></th>
                <th [featureDisplay]="'msgraph-calendar-integration'" class="reason-column" translate="label.mSGraphCalendar"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}" *ngFor="let exclusion of exclusionList"
                (click)="viewExclusion(exclusion)" sbaseMarkTableRowOnHover
            >
                <td class="id-column">{{exclusion.shortId}}</td>
                <td class="name-column">
                    <div class="font-bold">{{exclusion?.resource?.name}}</div>
                    <div>{{exclusion?.center?.name}}</div>
                </td>
                <td class="working-hours-column">
                  <span *ngFor="let timeSlot of exclusion?.timeSlots">
                    <span class="mr-1" *ngIf="timeSlot.monday" translate="label.mon"></span>
                    <span class="mr-1" *ngIf="timeSlot.tuesday" translate="label.tu"></span>
                    <span class="mr-1" *ngIf="timeSlot.wednesday" translate="label.we"></span>
                    <span class="mr-1" *ngIf="timeSlot.thursday" translate="label.th"></span>
                    <span class="mr-1" *ngIf="timeSlot.friday" translate="label.fr"></span>
                    <span class="mr-1" *ngIf="timeSlot.saturday" translate="label.sa"></span>
                    <span class="mr-1" *ngIf="timeSlot.sunday" translate="label.su"></span>
                    <span class="mr-1">:</span>
                    <span>{{timeSlot.hourFrom | hourFromMinutes}} - {{timeSlot.hourTo | hourFromMinutes}}</span>
                  </span>
                </td>
                <td class="starting-column">{{exclusion.validFrom | fullDateFormatPipe}}</td>
                <td *ngIf="!exclusion.endOfDay" class="ending-column">{{exclusion.validTo | fullDateFormatPipe}}</td>
                <td *ngIf="exclusion.endOfDay" class="ending-column">{{exclusion.validTo | dateEndOfDay}}
                    <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoExclusion'| translate}}"></i>
                </td>
                <td class="status-column">{{'label.' + exclusion.status | translate}}</td>
                <td class="repetition-column">{{exclusion.repetition}} <span translate="label.week"></span></td>
                <td class="reason-column">{{exclusion.exclusionReason}}&nbsp;{{exclusion?.exclusionExtraInformation}}</td>
                <td [featureDisplay]="'msgraph-calendar-integration'" class="office-column">
                    <span *ngIf="!exclusionUtils.isExclusionFromMSGraphIntegration(exclusion)">
                        {{exclusionUtils.isExclusionFromMSGraphIntegration(exclusion) | yesNoPipe | translate}}
                    </span>
                    <i *ngIf="exclusionUtils.isExclusionFromMSGraphIntegration(exclusion)" class="fab fa-microsoft"
                       ngbTooltip="{{'label.disabledByMSGraphIntegration'| translate}}">
                    </i>
                </td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <div class="d-flex flex-row justify-content-center w-100">
                        <button class="btn" (click)="copyExclusion(exclusion)"
                                *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview"
                                [activityDisplay]="EXCLUSION+constants.CREATE"
                                ngbTooltip="{{'label.copy'| translate}}">
                            <i class="fa-regular fa-copy"></i>
                        </button>
                        <button class="btn" (click)="editExclusion(exclusion)"
                                *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview"
                                [disabled]="exclusion.status !== exclusionStatusEnum.APPROVED || exclusionUtils.isExclusionFromMSGraphIntegration(exclusion) || dateTimeUtils.isExpiredDate(exclusion.validTo)"
                                [activityDisplay]="EXCLUSION+constants.UPDATE"
                                ngbTooltip="{{'label.edit'| translate}}">
                            <i class="fa-regular fa-pen"></i>
                        </button>
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview && hasExclusionCenterActivityActive(exclusion)"
                                    [ngClass]="(exclusion.status !== 'Approved') ? 'disabled-button': ''">
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="deleteExclusion(exclusion.id)"
                                            [activityDisplay]="EXCLUSION+constants.DELETE"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview"
                                    [ngClass]="(exclusion.status === 'Edited') ? 'disabled-button': ''">
                                    <button type="button" class="btn" translate="label.history"
                                            (click)="historyExclusion(exclusion)"
                                            [activityDisplay]="EXCLUSION+constants.HISTORY"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ApproveExclusionOverview && hasExclusionCenterActivityActive(exclusion) ||
                                    (this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview &&
                                        (exclusion.status === ExclusionStatusEnum.NEW || exclusion.status === ExclusionStatusEnum.EDITED || exclusion.status === ExclusionStatusEnum.MARKED_FOR_DELETE))">
                                    <button type="button" class="btn" translate="label.availabilityTab.approve"
                                            (click)="approveOrDeclineExclusion(exclusion, constants.APPROVE)"
                                            [activityDisplay]="EXCLUSION+constants.APPROVE"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ApproveExclusionOverview && hasExclusionCenterActivityActive(exclusion) ||
                                    (this.screenTemplateLayout.viewType===ExclusionViewPageEnum.ExclusionOverview &&
                                        (exclusion.status === ExclusionStatusEnum.NEW || exclusion.status === ExclusionStatusEnum.EDITED || exclusion.status === ExclusionStatusEnum.MARKED_FOR_DELETE))">
                                    <button type="button" class="btn" translate="label.availabilityTab.decline"
                                            (click)="approveOrDeclineExclusion(exclusion, constants.DECLINE)"
                                            [activityDisplay]="EXCLUSION+constants.APPROVE"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.blockingSimulation"
                                            (click)="blockingSimulationExclusion(exclusion.id)"
                                            [activityDisplay]="EXCLUSION+constants.BLOCKING_SIMULATION"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.view"
                                            (click)="viewExclusion(exclusion)"
                                            [activityDisplay]="EXCLUSION+constants.READ"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component mt-4 text-high-contrast" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>

