import {Component, Input, OnInit} from '@angular/core';
import {AssignSubServicesDisplayType, AssignSubServicesOptionsTypes} from './assign-sub-services.types';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    AvailabilityDataSubServiceType,
    SubServiceProvider,
    SubServiceSearchType,
    SubServiceType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {take} from 'rxjs/operators';
import {MessagesService} from '../../services/messages.service';
import {TableFiltersType} from '../../../data-model/general.type';
import {AssignSubServicesUtils} from './assign-sub-services-utils';
import {GeneralUtils} from '../../utils/general.utils';
import * as lodash from 'lodash';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-assign-sub-services-modal',
    templateUrl: './assign-sub-services-modal.html',
    styleUrls: ['./assign-sub-services.component.scss']
})
export class AssignSubServicesModalComponent implements OnInit {
    @Input() options: AssignSubServicesOptionsTypes;

    subServices: AssignSubServicesDisplayType[] = [];
    selectedSubServices: AssignSubServicesDisplayType[] = [];
    tableFilters: TableFiltersType;
    numberOfSelectedSubServices = 0;
    totalItems: number;
    selectAll = false;
    showOnlyAssigned = false;
    showItemsPerPageDropdown = false;
    itemsPerPageList: number[] = [10, 25, 50, 100];
    subServiceButtonName = 'label.searchForSubService';
    mainDependentFilters: any;
    initialSubServiceValues: SubServiceSearchType[] = [];
    RegexWholeNumber = /^[0-9]\d*$/;

    constructor(public activeModal: NgbActiveModal,
                public assignSubServicesUtils: AssignSubServicesUtils,
                public generalUtils: GeneralUtils,
                public messagesService: MessagesService,
                public ngxLoader: NgxUiLoaderService,
                public subServiceProvider: SubServiceProvider) {
    }

    ngOnInit(): void {
        this.setupInitialData();
        this.loadSubServices();
    }

    save(): void {
        const foundEmptyDuration = lodash.find(this.selectedSubServices, {duration: null});
        if (!foundEmptyDuration) {
            let assignedSubServices: AvailabilityDataSubServiceType[];
            assignedSubServices = this.selectedSubServices as unknown as AvailabilityDataSubServiceType[];
            this.activeModal.close(assignedSubServices);
        }
    }

    onSelectedSubService(subServiceList: any[]): void {
        this.initialSubServiceValues = subServiceList;
        if (subServiceList?.length > 0) {
            this.tableFilters.filter.subService = subServiceList[0];
        } else {
            this.tableFilters.filter.subService = undefined;
        }
        this.loadSubServices();
    }

    onShowOnlyAssignedSubServices(showOnlyAssigned: boolean) {
        this.tableFilters.filter.showOnlyAssigned = showOnlyAssigned;
        this.tableFilters.currentPage = 1;

        this.loadSubServices();
    }

    onSelectAll(selectAll: boolean) {
        if (selectAll) {
            this.subServices = this.generalUtils.addSelectedValueTrueToObjectList(this.subServices);
            this.selectedSubServices = lodash.uniqBy(lodash.concat(this.selectedSubServices, this.subServices), 'id');
        } else {
            this.subServices = this.generalUtils.addSelectedValueFalseToObjectList(this.subServices);
            this.selectedSubServices = lodash.pullAllBy(this.selectedSubServices, this.subServices, 'id');
        }
        this.numberOfSelectedSubServices = this.selectedSubServices.length;
    }

    onSelectSingle(subService: any) {
        subService.selected = !subService.selected;
        if (subService.selected) {
            this.selectedSubServices.push(subService);
        } else {
            lodash.remove(this.selectedSubServices, {id: subService.id});
            lodash.remove(this.selectedSubServices, {subServiceId: subService.id});
        }

        this.selectedSubServices = lodash.uniqBy(this.selectedSubServices, 'id');
        this.numberOfSelectedSubServices = this.selectedSubServices.length;
    }

    onChangePagination(page: number) {
        this.selectAll = false;
        this.tableFilters.currentPage = page;

        this.loadSubServices();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.selectAll = false;

        this.loadSubServices();
    }

    private setupInitialData(): void {
        this.tableFilters = this.assignSubServicesUtils.getInitialTableFilter();
        this.mainDependentFilters = {
            subService: this.assignSubServicesUtils.getSubServiceDependentFilters(),
        };
        this.mainDependentFilters.subService.serviceId = this.options.serviceId;
        this.selectedSubServices =  this.generalUtils.addSelectedValueTrueToObjectList(this.options.subServices);
    }

    private loadSubServices() {
        this.ngxLoader.start();
        this.getSubServicesObservable(this.tableFilters)
            .pipe(take(1))
            .subscribe((subServices) => {
                    this.totalItems = subServices.count;
                    this.subServices = this.assignSubServicesUtils.mapSubServicesForDisplay(subServices.value, this.options.subServices);
                    this.subServices = this.assignSubServicesUtils.matchAssignedSubServices(this.subServices, this.selectedSubServices);
                    this.numberOfSelectedSubServices = this.selectedSubServices.length;
                },
                err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                }, () => this.ngxLoader.stop());
    }

    private getSubServicesObservable(tableFilters: TableFiltersType): Observable<{ value: SubServiceType[], count?: number }> {
        if (this.showOnlyAssigned) {
            return of(this.assignSubServicesUtils.getOnlyAssignedSubServices(tableFilters, this.selectedSubServices));
        } else {
            // tslint:disable-next-line:max-line-length
            return this.subServiceProvider.getEntries(this.assignSubServicesUtils.getQueryFilterForSubServices(this.options.serviceId, this.tableFilters));
        }
    }
}
