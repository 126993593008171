<div class="container-fluid create-team-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-team-header">
        <h3 class="create-user-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase">{{'label.userManagementTab.team'|translate}}</span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row">
        <div class="col-12">
            <div class="text-info mb-3 mt-2">
                <i class="fa-solid fa-circle-exclamation"></i>&nbsp;
                <span translate="label.changesAfterRelogMessage"></span>
            </div>
        </div>
        <div class="col-12" *ngIf="!teamMDUtils.isAnyActivitySelected(teamItem, teamItemClone)">
            <div class="mb-3 mt-2">
                <i class="fa-solid fa-circle-exclamation"></i>&nbsp;
                <span class="text-high-contrast" translate="label.error.teamActivityError"></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-6 col-lg-3">
            <!--  team name-->
            <div class="align-items-center justify-content-between">
                <div class="form-group"
                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                    <label class="text-high-contrast" translate="label.name"></label>
                    <input type="text"
                           name="name"
                           class="input-element text-medium-contrast"
                           autocomplete="off"
                           placeholder="{{'label.name'|translate}}"
                           [(ngModel)]="teamItem.name"
                           [disabled]="screenTemplateLayout.action === constants.VIEW"
                           [ngClass]="{'input-element-has-error': !teamItem.name}"
                           required>
                    <div *ngIf="!teamItem.name" class="help-block">
                        <span translate="label.error.required"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-lg-3">
            <!--  organizational roles -->
            <div class="create-team-organizational-roles-select-container">
                <div class="form-group"
                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                    <label class="text-high-contrast" translate="label.organizationalRole"></label>
                    <i class="ml-2 fa fa-exclamation-triangle has-warning"
                       *ngIf="screenTemplateLayout.action !== constants.VIEW"
                       id="{{'organizational-roles-id'}}"
                       #organizationalRolesIcon
                       (mouseenter)="makeTippiesIfNotAlreadyMade(
                           'organizationalRoles',
                           organizationalRolesIcon.id,
                           popoverOrganizationalRolesText.textContent,
                           'organizational-roles')">
                    </i>
                    <ng-select
                        class="custom-ng-select input-element"
                        loadingText="{{'label.loading' | translate}}"
                        dropdownPosition="bottom"
                        [(ngModel)]="teamItem.organizationalRole"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="screenTemplateLayout.action === constants.VIEW"
                        (change)="onOrganizationalRoleChange($event)">
                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                        <ng-option *ngFor="let role of OrganizationalRoleEnum"
                                   [value]="role">
                            {{'enum.organizationalRoleType.' + role | translate}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <div class="row activity-row py-2">
        <div class="ml-3 mr-3">
            <label class="pt-2 pb-2 m-0" translate="label.activity"></label>
        </div>
        <div class="ml-3">
            <div class="form-group m-0 search-input">
                <input type="text"
                       class="form-control"
                       autocomplete="off"
                       placeholder="{{'label.searchForAGroupOrActivity'|translate}}"
                       [(ngModel)]="pageFilter.searchTerm"
                       (ngModelChange)="searchForGroupOrActivity()">
            </div>
        </div>
        <div *ngIf="screenTemplateLayout.action !== constants.VIEW" class="ml-3">
            <div class="mt-2 dynamic-checkbox-container">
                <label>
                    <input
                            type="checkbox"
                            [(ngModel)]="pageFilter.showAssignedActivities"
                            (ngModelChange)="showAssignedActivitiesOnly()">
                    <span class="ml-1 text-high-contrast">{{'label.showAssignedOnly' | translate}}</span>
                </label>
            </div>
        </div>
        <div *ngIf="screenTemplateLayout.action !== constants.VIEW" class="ml-3">
            <div class="mt-2 dynamic-checkbox-container">
                <label>
                    <input
                            type="checkbox"
                            [(ngModel)]="pageFilter.showUnassignedActivities"
                            (ngModelChange)="showUnassignedActivitiesOnly()">
                    <span class="ml-1 text-high-contrast">{{'label.showUnassignedActivitiesOnly' | translate}}</span>
                </label>
            </div>
        </div>
    </div>

    <div class="row tree-container-row">
        <div class="container-fluid tree-container custom-scrollbar pt-0 pb-o">
            <table class="table">
                <tbody>
                <ng-container *ngFor="let feature of teamItem.features | keyvalue">
                    <tr class="parent-row">
                        <td>
                            <label class="form-check-label text-high-contrast">
                                <input *ngIf="screenTemplateLayout.action !== constants.VIEW"
                                       (ngModelChange)="selectAllActivitiesFromFeature($event, feature.key)"
                                       [ngModel]="checkIfAllActivitiesSelected(feature.key)"
                                       type="checkbox">
                                {{ feature.key }}
                            </label>
                            <button class="btn btn-link"
                                    (click)="feature.value[0].isCollapsed = !feature.value[0].isCollapsed">
                                <i *ngIf="feature.value[0].isCollapsed" class="fa fa-chevron-up"
                                   aria-hidden="true"></i>
                                <i *ngIf="!feature.value[0].isCollapsed" class="fa fa-chevron-down"
                                   aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                    <tr [(ngbCollapse)]="feature.value[0].isCollapsed">
                        <td class="pt-0 pb-0">
                            <table class="table m-0">
                                <tbody>
                                <tr *ngFor="let activity of feature.value" class="child-row">
                                    <td>
                                        <label class="form-check-label text-medium-contrast">
                                            <input
                                                *ngIf="screenTemplateLayout.action !== constants.VIEW"
                                                [(ngModel)]="activity['selected']"
                                                (ngModelChange)="handleActivityCheckedChange()"
                                                type="checkbox">
                                            {{ activity['translatedName']}}
                                        </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="area-md-buttons mt-3 pb-3 ml-0">
        <button class="btn mr-3"
                translate="{{screenTemplateLayout.cancelOrBackButton}}"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                (click)="goToParentPage()"></button>
        <button class="btn theme-btn" translate="{{screenTemplateLayout.createOrSaveButton}}"
                [disabled]="!teamMDUtils.isTemplateValid(teamItem, teamItemClone)"
                (click)="saveTeam(teamItem)"
                [hidden]="screenTemplateLayout.action === constants.VIEW">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'TeamUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
    <!-- POPOVER TEXT -->
    <span class="d-none"
          id="popoverOrganizationalRolesText"
          #popoverOrganizationalRolesText>{{'label.legacyInfo' | translate}}
    </span>
</div>
