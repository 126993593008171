import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ExclusionComponent} from './exclusion.component';
import {CreateExclusionComponent} from './create-exclusion/create-exclusion.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {ViewExclusionComponent} from './view-exclusion/view-exclusion.component';
import {ViewApproveExclusionComponent} from './view-approve-exclusion/view-approve-exclusion.component';
import {ExclusionHistoryComponent} from './exclusion-history/exclusion-history.component';

@NgModule({
    declarations: [ExclusionComponent, CreateExclusionComponent, ViewExclusionComponent, ViewApproveExclusionComponent, ExclusionHistoryComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        NgxMaterialTimepickerModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ]
})
export class ExclusionModule { }
