import {ConfigDataService} from './../services/config-data.service';
import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import * as lodash from 'lodash';

@Directive({
    selector: '[activityDisplay]'
})
export class ActivityDisplayDirective implements OnInit {

    constructor(private el: ElementRef, private configDataService: ConfigDataService) {
    }

    //fetching the activity value from directive input
    @Input('activityDisplay') activityName: string | string[];

    ngOnInit() {
        this.checkActivityAccess();
    }

    //function to display the element based on access given
    private checkActivityAccess() {
        //do nothing if the session storage value is null
        if (this.configDataService.activeActivities) {
            //fetching ngx-access from session storage
            if (typeof this.activityName === 'string') {
                const foundActivity = this.getFoundActivity(this.configDataService.activeActivities, this.activityName);
                //display html element if the activity is found through the user activities
                // feature-access and activity-display manipulate the same html element
                // add delay in order to be the last operation
                setTimeout(() => {
                    // show element only if display!==none. That means other directive hide this element
                    if (foundActivity && this.el.nativeElement.style.display !== 'none') {
                        this.el.nativeElement.style.display = '';
                    } else {
                        this.el.nativeElement.style.display = 'none';
                    }
                }, 10);
            } else {
                if (this.activityName && this.activityName.length > 0) {
                    setTimeout(() => {
                        if (this.el.nativeElement.style.display !== 'none') {
                            this.el.nativeElement.style.display = 'none';
                            for (const activity of this.activityName) {
                                const foundActivity = this.getFoundActivity(this.configDataService.activeActivities, activity);
                                if (!!foundActivity) {
                                    this.el.nativeElement.style.display = '';
                                    return;
                                }
                            }
                        }
                    }, 10);
                }
            }
        }
    }

    private getFoundActivity(userActivitiesList: string[], currentActivity: string): string | undefined {
        // create a local constant because inside of lodash function  this.activityName is undefined
        const activityName = currentActivity;
        const foundActivity = lodash.find(userActivitiesList, (item) => {
            return (item.toLowerCase() === activityName.toLowerCase());
        });
        return foundActivity;
    }
}




