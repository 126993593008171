<div class="no-organizational-role container-fluid" *ngIf="!organizationalRole">
    <div class="row px-3">
        <div class="col p-2 error-no-organizational-role">
            <i class="fas fa-exclamation-circle mx-2"></i>
            <span translate="toastr.error.noOrganizationalRoleAssignedForTeam"></span>
        </div>
    </div>
</div>
<div class="graphical-resource-planner-page" *ngIf="organizationalRole">
    <sbase-graphical-resource-planner
            [options]="graphicalResourcePlannerOptions"
    ></sbase-graphical-resource-planner>
</div>
