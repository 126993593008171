import {TaskListFiltersOptionsType, TaskListFiltersSearchType} from './task-list-filters/task-list-filters.types';
import {TableFiltersType} from '../../data-model/general.type';
import {AppointmentType, SkedTaskType} from 'sked-base';
import {AppointmentCommentType} from 'sked-base/lib/data-model/appointmentCommentTypes';
import {PdfImageViewerOptionsType} from '../../shared/component/pdf-image-viewer/pdf-image-viewer.types';
import {ResourceAppointmentType} from "sked-base/lib/data-model/resourceTypes";

export interface TaskListStateType {
    items?: TaskListItemOptionsType[];
    filtersOptions?: TaskListFiltersOptionsType;
    taskListItemOptions?: TaskListItemOptionsType;
    taskItemDetailsOptions?: TaskDetailsOptionsType;
}

export interface TaskListRequestFilters {
    searchFilters: TaskListFiltersSearchType;
    pageFilters: TableFiltersType;
    sortBy: string;
}

export interface TaskListItemOptionsType {
    isBeforeSearchState: boolean;
    isNotFoundState: boolean;
    taskListSortByList: string[];
    showItemsPerPageDropdown: false;
    itemsPerPageList: number[];
    taskListRequestFilters: TaskListRequestFilters;
    latestTaskListRequestFilters: TaskListRequestFilters;
    totalTaskItems: number;
    taskList: SkedTaskType[];
}

export interface ActionsButtonsOptionsType {
    taskListItem: SkedTaskType;
}

export interface TaskDetailsOptionsType {
    taskItem: SkedTaskType;
    appointment: AppointmentType;
    appointmentComments: AppointmentCommentType[];
    uploadsViewerOptions: PdfImageViewerOptionsType;
}

export enum TaskListSortByEnum {
    // don't change this values -> they are used directly in query
    NoActiveSorting = 'NoActiveSorting',
    DueDateDescending = 'DueDate desc',
    DueDateAscending = 'DueDate asc',
    CreateDateDescending = 'CreatedOn desc',
    CreateDateAscending = 'CreatedOn asc'
}

export enum TaskItemActionEnum {
    Done = 'Done',
    Reject = 'Reject',
    Book = 'Book',
    Reschedule = 'Reschedule',
    Cancel = 'Cancel'
}

export interface TaskItemAppointmentResourcesType {
    mainResource: ResourceAppointmentType;
    secondaryResources: ResourceAppointmentType[];
}
