<div class="appointment-action-buttons-container">
    <!-- CANCEL -->
    <sbase-cancel-appointment
            *ngIf="!options?.cancelled && options?.status !== appointmentStatusValueEnum[appointmentStatusEnum.ServicePerformed]"
            [options]="cancelAppointmentOptions"
            (appointmentCancelled)="onAction({action: patientAppointmentActionEnum.Cancel, data: $event})"
            [activityDisplay]="'AppointmentDelete'">
    </sbase-cancel-appointment>
    <!-- RESCHEDULE / REUSE -->
    <sbase-appointment-reschedule-reuse-button
            *ngIf="!options.cancelled && options?.status !== appointmentStatusValueEnum[appointmentStatusEnum.ServicePerformed]"
            [options]="rescheduleReuseAppointmentOptions"
            [activityDisplay]="'AppointmentBooking'">
    </sbase-appointment-reschedule-reuse-button>
    <!-- STATUS -->
    <sbase-appointment-status-change-button
            *ngIf="!options.cancelled"
            [options]="appointmentStatusChangeButtonOptions"
            (appointmentStatusChangeAction)="onAction({action: patientAppointmentActionEnum.Status, data: $event})"
            [activityDisplay]="'AppointmentUpdate'">
    </sbase-appointment-status-change-button>
    <!-- PRINT BULLETIN PDF -->
    <app-print-bulletin-pdf-button
            *ngIf="!options.cancelled"
            [activityDisplay]="'ViewAppointmentBulletinPdf'"
            [appointmentId]="options?.appointmentId"></app-print-bulletin-pdf-button>
    <!-- SET PAID -->
    <app-appointment-paid-status-change-button
            [activityDisplay]="'ExpertBooking'"
            [options]="options"
            (patientAppointmentAction)="onAction({action: patientAppointmentActionEnum.SetPaid, data: $event})">
    </app-appointment-paid-status-change-button>
    <!-- SEND EMAIL -->
    <app-appointment-send-email-button
            [activityDisplay]="'AppointmentResendEmail'"
            [options]="appointmentSendEmailButtonOptions">
    </app-appointment-send-email-button>
</div>

