import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {SlotsManagementMdUtils} from './slots-management-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    AppointmentInformationOptionsType,
    AppointmentTypeProvider,
    AppointmentTypeType,
    CenterProvider,
    CenterType,
    FiltersChangeEventType,
    PatientSearchOptionsType,
    PatientSearchResultType,
    PayloadType,
    ResourceType,
    ServiceProvider,
    ServiceType,
    SlotProvider,
    SlotsActionEnum,
    SlotsFilterNameEnum,
    SlotsFiltersSelectValueType,
    SubServiceType
} from 'sked-base';
import {SlotDisplayType, SlotSearchResponseType, SlotType} from 'sked-base/lib/data-model/slotTypes';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {SlotsFiltersOptionsType} from 'sked-base/lib/components/slots-filters/slots-filters.type';
import {systemConfigKeys} from '../system-config/system-config.constant';
import * as lodash from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SlotSearchModalComponent} from './slot-search-modal/slot-search-modal.component';
import {
    AppointmentInformationAdditionalDataType
} from 'sked-base/lib/components/appointment-information/appointment-information.types';
import {
    SlotWithoutPlannedCapacityModalComponent
} from './slot-without-planned-capacity-modal/slot-without-planned-capacity-modal.component';
import {
    OutsideAvailabilityBookingModalComponent
} from './outside-availability-booking-modal/outside-availability-booking-modal.component';
import {
    OutsideAvailabilityAppointmentModalOptionsType,
    OutsideAvailabilityAppointmentModalResponseType, SlotCenterLocalFilterType,
    SlotsManagementSubServiceType
} from './slots-management.types';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {TaskListUtils} from '../task-list/task-list-util';
import {PatientActionsService} from '../../shared/services/patient-actions/patient-actions.service';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {
    PatientAppointmentListUtils
} from '../patient-dashboard/patient-appointment-list/patient-appointment-list.utils';
import {AppointmentListUtils} from '../appointment-list/appointment-list.utils';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {PhoneNumberUtil} from 'google-libphonenumber';

@AutoUnsubscribe()
@Component({
    selector: 'app-slots-management-md',
    templateUrl: './slots-management.component.html',
    styleUrls: ['./slots-management.component.scss']
})
export class SlotsManagementComponent implements OnInit, OnDestroy {
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    selectedPatient: PatientSearchResultType;
    patientSearchOptions: PatientSearchOptionsType;
    slotsFiltersOptions: SlotsFiltersOptionsType;
    displayLocalFilters = false;
    displaySlotsManagement = false;
    slotsFiltersExternalUpdatePatientEmitter: EventEmitter<PatientType> = new EventEmitter<PatientType>();
    private userInfoStorage;

    constructor(
        private slotsManagementMdUtils: SlotsManagementMdUtils,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public configDataService: ConfigDataService,
        private slotProvider: SlotProvider,
        private modalService: NgbModal,
        private centerProvider: CenterProvider,
        private appointmentTypeProvider: AppointmentTypeProvider,
        private serviceProvider: ServiceProvider,
        private previousRouteService: PreviousRouteService,
        private taskListUtils: TaskListUtils,
        private patientActionsService: PatientActionsService,
        public patientContextService: PatientContextService,
        private patientAppointmentListUtils: PatientAppointmentListUtils,
        private appointmentListUtils: AppointmentListUtils,
        private translatedLanguageService: TranslatedLanguageService
    ) {
    }

    ngOnInit() {
        // Clear actual filters for MAB
        this.slotsManagementMdUtils.actualFilterValues = [];
        // Push state comingFromRoute to bookAppointment if previous route is bookAppointment to remember the state
        const previousUrl = this.previousRouteService.getPreviousUrl();
        if (previousUrl === '/bookAppointment') {
            history.pushState({
                comingFromRoute: 'bookAppointment',
                ...(!!history?.state?.bookingSuccessful || this.slotsManagementMdUtils.bookingSuccessful
                    ? {bookingSuccessful: true} : {})
            }, '');
        }
        this.slotsManagementMdUtils.bookingSuccessful = false;
        // If coming from a successful booking and we are in flow of book / reschedule / reuse we redirect back to route
        if (!!history?.state?.bookingSuccessful && (
            !!this.slotsManagementMdUtils.slotsActionContextTask || this.slotsManagementMdUtils.slotsActionContextAppointment
        )) {
            this.slotsManagementMdUtils.slotsActionContextAction = undefined;
            this.slotsManagementMdUtils.slotsActionContextTask = undefined;
            this.slotsManagementMdUtils.slotsActionContextAppointment = undefined;
            // In case of taskList, additional settings must be set
            if (this.slotsManagementMdUtils.slotsActionContextRoute === '/taskList') {
                this.taskListUtils.shouldMakeNewRequest = true;
                this.taskListUtils.shouldKeepFiltersState = true;
                this.taskListUtils.shouldKeepListState = false;
            }
            if (this.slotsManagementMdUtils.slotsActionContextRoute === '/patientDashboard') {
                // Nothing to prepare here, but we keep this if to show possible places where reschedule / reuse can start
            }
            if (this.slotsManagementMdUtils.slotsActionContextRoute === '/patientAppointmentList') {
                this.patientAppointmentListUtils.shouldMakeNewRequest = true;
                this.patientAppointmentListUtils.shouldKeepFiltersState = true;
                this.patientAppointmentListUtils.shouldKeepListState = false;
            }
            if (this.slotsManagementMdUtils.slotsActionContextRoute === '/appointmentList') {
                this.appointmentListUtils.shouldMakeNewRequest = true;
                this.appointmentListUtils.shouldKeepFiltersState = true;
                this.appointmentListUtils.shouldKeepListState = false;
            }
            this.router.navigate([this.slotsManagementMdUtils.slotsActionContextRoute]);
            return;
        }
        if (previousUrl !== '/bookAppointment') {
            // Remove the task received from task list for book / reschedule from context
            this.slotsManagementMdUtils.slotsActionContextAction = undefined;
            this.slotsManagementMdUtils.slotsActionContextTask = undefined;
            this.slotsManagementMdUtils.slotsActionContextAppointment = undefined;
            this.slotsManagementMdUtils.slotsActionContextRoute = undefined;
        }
        // Get user from storage
        this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        this.displaySlotsManagement = !!this.userInfoStorage?.team?.organizationalRole;
        if (!this.displaySlotsManagement) {
            return;
        }
        this.loadSearchTimeWindowSystemConfigVariables();
        // Clear cache state if coming from a successful booking
        if (!!history?.state?.bookingSuccessful) {
            // On successful booking delete everything except the patient
            this.slotsManagementMdUtils.slotsManagementState = {
                patientSearchOptions: this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions,
            };
            this.slotsManagementMdUtils.specialAppointmentBooking = false;
        }
        if (previousUrl !== '/bookAppointment') {
            // Load data in state for task list book and reschedule when coming from task list
            this.loadStateDataOnOutsideAction();
        }
        // Load options for components
        this.loadPatientSearchOptions();
        this.loadSlotsFiltersOptions();
        this.loadSlotsCalendarOptions();
        this.loadSlotsResultsOptions();
        this.loadSlotsLocalFiltersOptions();
        this.loadSlotsCentersOptions();
        this.listenToPatientChanges();
    }

    ngOnDestroy(): void {
    }

    //PATIENT
    onSelectedPatient(selectedPatient: { $value: PayloadType }) {
        this.slotsManagementMdUtils.patientChangedSinceLastCheck = true;
        this.selectedPatient = selectedPatient?.$value?.value;
        this.patientSearchOptions.initialSelectedPatient = this.selectedPatient;
        this.patientActionsService.patientId = this.selectedPatient?.id; // used in wait list modal
        // Save the loaded patient in the state, to be reused as initialSelectedPatient
        this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions.initialSelectedPatient = this.selectedPatient;
        // Clear all data from screen and cache
        this.clearLocalFiltersSlotResultsAndCalendarData();
        // Emit external patient change for filters
        this.slotsFiltersExternalUpdatePatientEmitter.next(selectedPatient?.$value?.value as PatientType);
        this.patientContextService.patient = this.selectedPatient;
    }

    //BOOKING FILTERS
    onSearch(filterValues: SlotsFiltersSelectValueType[]) {
        this.slotsManagementMdUtils.searchedFilterValues = lodash.cloneDeep(filterValues);
        this.patientActionsService.slotsFilters = filterValues; // used for wait list modal
        this.slotsManagementMdUtils.expertBookingMode = lodash.find(filterValues, {name: SlotsFilterNameEnum['Expert Booking']})?.value;
        this.slotsManagementMdUtils.specialAppointmentBooking = lodash.find(filterValues,
            {name: SlotsFilterNameEnum['Special Appointment Booking']})?.value;
        this.clearLocalFiltersSlotResultsAndCalendarData();
        this.openSlotSearchModal(filterValues, this.selectedPatient);
    }

    onOutsideAvailabilityBooking(filterValues: SlotsFiltersSelectValueType[]) {
        // Do requests to :
        // 1. get timezoneId for selected center
        // 2. get appointment types
        // 3. get service duration
        if (!this.checkBookDataIsValid()) {
            return;
        }
        this.ngxLoader.start();
        const centerId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value?.id;
        const centerQuery = this.slotsManagementMdUtils.getQueryForCenterTimezone(centerId);
        const appointmentTypeQuery = this.slotsManagementMdUtils.getQueryForAppointmentTypes();
        const serviceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Service})?.value;
        const serviceId = serviceFilterValue.id ?? serviceFilterValue.service.id;
        const serviceDurationQuery = this.slotsManagementMdUtils.getQueryForServiceDuration();
        forkJoin([
            this.centerProvider.getEntries(centerQuery),
            this.appointmentTypeProvider.getEntries(appointmentTypeQuery),
            this.serviceProvider.getById(serviceId, serviceDurationQuery)
        ]).pipe(take(1)).subscribe((
            [centerResponse, appointmentTypesResponse, serviceResponse]:
                [{ count?: number, value: CenterType[] }, { count?: number, value: AppointmentTypeType[] }, ServiceType]
        ) => {
            const timeZoneId: string = lodash.isEmpty(centerResponse.value)
                ? moment.tz.guess()
                : centerResponse.value[0]?.timeZoneId;
            const serviceDuration = serviceResponse?.defaultDuration;
            this.openOutsideAvailabilityModal(timeZoneId, appointmentTypesResponse?.value, serviceDuration, filterValues);
            this.ngxLoader.stop();
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    onFiltersChange({filterValues}: FiltersChangeEventType): void {
        this.slotsManagementMdUtils.actualFilterValues = lodash.cloneDeep(filterValues);
        if (lodash.isEmpty(this.slotsManagementMdUtils.latestFilterValues)) {
            return;
        }
        const patientChanged = this.slotsManagementMdUtils.patientChangedSinceLastCheck ||
            this.slotsManagementMdUtils.patientHadValueAtSearch !== !!this.patientContextService.patient;
        this.slotsManagementMdUtils.patientChangedSinceLastCheck = false;
        this.slotsManagementMdUtils.filtersChangedWithNoSearch = !this.slotsManagementMdUtils.filtersJustReset &&
            !lodash.isEmpty(this.slotsManagementMdUtils.slotsResultsOptions) && (patientChanged ||
                !lodash.isEqual(this.slotsManagementMdUtils.actualFilterValues, this.slotsManagementMdUtils.latestFilterValues));
    }

    onFiltersReset() {
        // Filters were already reset, we only need to clear everything else
        this.clearLocalFiltersSlotResultsAndCalendarData();
        // Clear the filters from wait list modal
        this.patientActionsService.slotsFilters = [];
        // Don't show warning that filters changed
        this.slotsManagementMdUtils.filtersJustReset = true;
    }

    //CALENDAR
    onSlotsCalendarSelectValue(): void {
        // Keep the state of the calendar up to date
        this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsCalendarOptions);
        // Reset scrollTop of list container
        this.slotsManagementMdUtils.resetSlotResultsScrollTop();
        this.slotsManagementMdUtils.resetSlotResultsSliceUpperBound();
        // Load slot items
        this.slotsManagementMdUtils.loadSlotsResultsOptions();
    }

    onSlotsCalendarContinueSearching() {
        this.openSlotSearchModal(this.slotsManagementMdUtils.searchedFilterValues, this.selectedPatient, true);
    }

    //LOCAL FILTERS: AM / PM / SELF PAYER
    onSelectedLocalFilters() {
        // Keep the state of the local filters up to date
        this.slotsManagementMdUtils.slotsManagementState.slotsLocalFiltersOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsLocalFiltersOptions);
        // Filter the slots
        const filteredSlots = this.slotsManagementMdUtils.getFilteredSlotsByCentersAndLocalFilters();
        this.filterAllSlotDependentComponents(filteredSlots);
    }

    //CENTERS
    onSlotsCentersOkClick() {
        const filteredSlots = this.slotsManagementMdUtils.getFilteredSlotsByCentersAndLocalFilters();
        this.filterAllSlotDependentComponents(filteredSlots);
        // Save the state of slots centers options
        this.slotsManagementMdUtils.slotsManagementState.slotsCentersOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsCentersOptions);
    }

    //LIST
    onSelectSlot(slot: SlotDisplayType): void {
        if (this.checkBookDataIsValid()) {
            if (this.slotsManagementMdUtils.specialAppointmentBooking) {
                this.openSlotWithoutPlannedCapacityModal(slot);
            } else {
                this.goToBookAppointment(slot);
            }
        }
    }

    filtersAreLoaded(areFiltersLoaded: boolean) {
        if (areFiltersLoaded) {
            // If
            //  1. we shouldn’t forget the state (ie. we come on this page by backing from booking), and
            //  2. a patient is already in context
            // we send the selected patient to the slots filters
            if (!!this.slotsManagementMdUtils.slotsActionContextAction || this.shouldRememberState(history?.state?.comingFromRoute)) {
                setTimeout(() => {
                    this.slotsFiltersExternalUpdatePatientEmitter.next(this.selectedPatient as PatientType);
                });
            }
            // If patient is in context, update patient externally for slot filters
            if (!!this.patientContextService.patient) {
                this.selectedPatient = this.patientContextService.patient;
                setTimeout(() => {
                    this.slotsFiltersExternalUpdatePatientEmitter.next(this.patientContextService.patient as PatientType);
                });
            }
        }
    }

    private loadStateDataOnOutsideAction() {
        // When coming from task list for booking or reschedule, we preselect the patient and filters we get from the task
        // Guard to not do anything in case the action is missing or is not in the enum
        if (!Object.values(SlotsActionEnum).includes(history?.state?.slotsAction)) {
            return;
        }
        this.slotsManagementMdUtils.filtersChangedWithNoSearch = false;
        this.slotsManagementMdUtils.slotsActionContextAction = SlotsActionEnum[history?.state?.slotsAction];
        this.slotsManagementMdUtils.slotsActionContextRoute = history?.state?.redirectRoute;
        this.slotsManagementMdUtils.slotsActionContextTask = history?.state?.taskItem;
        this.slotsManagementMdUtils.slotsActionContextAppointment = history?.state?.appointmentItem;
        const patient = this.slotsManagementMdUtils.slotsActionContextTask?.patient
            ? this.slotsManagementMdUtils.slotsActionContextTask?.patient
            : this.slotsManagementMdUtils.slotsActionContextAppointment?.patient;
        const patientId = this.slotsManagementMdUtils.slotsActionContextTask?.patientId
            ? this.slotsManagementMdUtils.slotsActionContextTask?.patientId
            : this.slotsManagementMdUtils.slotsActionContextAppointment?.patientId;
        this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions = this.patientContextService.getPatientSearchOptions(
            true, {
                ...patient,
                id: patientId,
            });
    }

    private openOutsideAvailabilityModal(timezoneId: string,
                                         appointmentTypes: AppointmentTypeType[],
                                         serviceDuration: number,
                                         filterValues: SlotsFiltersSelectValueType[]) {
        // Open modal
        const modalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'outside-availability-booking-modal-dialog';
        const modalRef = this.modalService.open(OutsideAvailabilityBookingModalComponent, modalOptions);

        // Load options
        modalRef.componentInstance.options = {
            centerTimeZoneId: timezoneId,
            serviceDuration
        } as OutsideAvailabilityAppointmentModalOptionsType;

        // Wait for response
        modalRef.result.then((response: OutsideAvailabilityAppointmentModalResponseType) => {
            // On close, navigate to book process
            const slot = this.slotsManagementMdUtils.getSlotForOutsideBooking(response.dateTime, response.duration, appointmentTypes, filterValues);
            this.goToBookAppointment(slot, true);
        }, () => {
            // On dismiss, do nothing
        });
    }

    private clearLocalFiltersSlotResultsAndCalendarData() {
        // Clear all data from local filters, slot results and calendar
        this.displayLocalFilters = false;
        this.slotsManagementMdUtils.slotsResultsOptions = [];
        this.slotsManagementMdUtils.noSlotsFound = false;
        this.slotsManagementMdUtils.slotsCentersOptions = this.slotsManagementMdUtils.getInitialSlotsCentersOptions();
        this.slotsManagementMdUtils.slotsLocalFiltersOptions = this.slotsManagementMdUtils.getInitialSlotsLocalFiltersOptions();
        this.slotsManagementMdUtils.slotsCalendarOptions = this.slotsManagementMdUtils.getInitialCalendarOptions(1);
        this.slotsManagementMdUtils.slotsManagementState.slotsResultsOptions = lodash.cloneDeep(this.slotsManagementMdUtils.slotsResultsOptions);
        this.slotsManagementMdUtils.slotsManagementState.slotsCentersOptions = lodash.cloneDeep(this.slotsManagementMdUtils.slotsCentersOptions);
        this.slotsManagementMdUtils.slotsManagementState.slotsLocalFiltersOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsLocalFiltersOptions);
        this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions = lodash.cloneDeep(this.slotsManagementMdUtils.slotsCalendarOptions);
    }

    private loadPatientSearchOptions() {
        // If coming back from bookAppointment and patient search options is already in state cache, load from there
        if ((!!this.slotsManagementMdUtils.slotsActionContextAction || this.shouldRememberState(history?.state?.comingFromRoute)) &&
            !!this.slotsManagementMdUtils.slotsManagementState?.patientSearchOptions) {
            // For time efficiency, deep clone properties without messagesService and configDataService
            const {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay} =
                this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions;
            this.patientSearchOptions = lodash.cloneDeep(
                {
                    initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay,
                    allowToNavigateToPatientDashboardRoute: true
                }
            );
            this.patientSearchOptions.messagesService = this.messagesService;
            this.patientSearchOptions.configDataService = this.configDataService;
            this.selectedPatient =
                this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions.initialSelectedPatient as PatientSearchResultType;
        } else {
            // Otherwise initialize the options and save them to the state
            this.patientSearchOptions = {
                ...this.patientContextService.getPatientSearchOptions(),
                actionsButtonsToDisplay: {
                    hideAddAppointment: true,
                }
            };
            // For time efficiency, deep clone properties without messagesService and configDataService
            const {
                initialSelectedPatient,
                patientSearchInput,
                parentPropertyName,
                actionsButtonsToDisplay
            } = this.patientSearchOptions;
            this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions = lodash.cloneDeep(
                {initialSelectedPatient, patientSearchInput, parentPropertyName, actionsButtonsToDisplay}
            );
            this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions.messagesService = this.messagesService;
            this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions.configDataService = this.configDataService;
        }
    }

    private loadSlotsFiltersOptions() {
        // If
        //  1. coming back from successful booking, or
        //  2. shouldn't remember state, or
        //  3. we are in flow for task list booking / rescheduling, then
        // we also send shouldForgetOptions = true
        this.slotsFiltersOptions = {
            configDataServiceInstance: this.configDataService,
            messagesServiceInstance: this.messagesService,
            shouldForgetOptions: !!history?.state?.bookingSuccessful ||
                !!this.slotsManagementMdUtils.slotsActionContextAction || !this.shouldRememberState(history?.state?.comingFromRoute),
            isPatientExternal: true,
            showOutsideAvailabilityButton: true,
            ...(!!this.slotsManagementMdUtils.slotsActionContextAction ? {initialFiltersValues: this.getInitialFilterValuesForOutsideAction()} : {}),
            ...(!!this.slotsManagementMdUtils.slotsActionContextAction ? {doInitialSubServicesRequest: true} : {}),
        } as SlotsFiltersOptionsType;
    }

    private getInitialFilterValuesForOutsideAction(): { [key in SlotsFilterNameEnum]?: any } {
        const service = this.slotsManagementMdUtils.slotsActionContextTask?.service
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.service;
        const serviceId = this.slotsManagementMdUtils.slotsActionContextTask?.serviceId
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.serviceId;
        const area = this.slotsManagementMdUtils.slotsActionContextTask?.service?.area
            ?? this.slotsManagementMdUtils.slotsActionContextTask?.area
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.service?.area;
        const areaId = area?.id
            ?? this.slotsManagementMdUtils.slotsActionContextTask?.service?.area?.id
            ?? (this.slotsManagementMdUtils.slotsActionContextTask as any)?.areaId
            ?? this.slotsManagementMdUtils.slotsActionContextTask?.area?.id
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.service?.area?.id;
        const coveragePlan = this.slotsManagementMdUtils.slotsActionContextAppointment?.coveragePlan;
        const resource = this.slotsManagementMdUtils.slotsActionContextTask?.resource
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.resource;
        const resourceId = this.slotsManagementMdUtils.slotsActionContextTask?.resourceId
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.resourceId;
        const center = this.slotsManagementMdUtils.slotsActionContextTask?.center
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.center;
        const centerId = this.slotsManagementMdUtils.slotsActionContextTask?.centerId
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.centerId;
        const subServices: SlotsManagementSubServiceType[] =
            this.slotsManagementMdUtils.slotsActionContextTask?.subServices as any as SlotsManagementSubServiceType[]
            ?? this.slotsManagementMdUtils.slotsActionContextAppointment?.subServices as any as SlotsManagementSubServiceType[];
        return {
            ...(!!area
                ? {
                    [SlotsFilterNameEnum.Area]: {
                        id: areaId,
                        ...area,
                    }
                }
                : {}),
            ...(!!coveragePlan
                ? {
                    [SlotsFilterNameEnum['Coverage Plan']]: {
                        ...coveragePlan, // Coverage plan already has the id property if the object exists
                    }
                }
                : {}),
            ...(!!service
                ? {
                    [SlotsFilterNameEnum.Service]: {
                        ...service,
                        id: serviceId,
                        ...(subServices?.length > 0 ? {
                            subServices: subServices.map((subService: SlotsManagementSubServiceType) => ({
                                subServiceId: subService.subServiceId,
                                id: subService.subServiceId,
                                shortId: (subService as any)?.subService?.shortId,
                                duration: (subService as any)?.subService?.duration,
                                code: (subService as any)?.subService?.code,
                                name: (subService as any)?.subService?.name,
                            }))
                        } : {}),
                    }
                }
                : {}),
            ...(!!resource
                ? {
                    [SlotsFilterNameEnum.Resource]: {
                        ...resource,
                        id: resourceId,
                    }
                }
                : {}),
            ...(!!center
                ? {
                    [SlotsFilterNameEnum.Center]: {
                        ...center,
                        id: centerId,
                    }
                }
                : {}),
        } as { [key in SlotsFilterNameEnum]?: any };
    }

    private loadSlotsCalendarOptions() {
        // If coming back from bookAppointment and slots calendar options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.slotsManagementMdUtils.slotsManagementState?.slotsCalendarOptions) {
            this.slotsManagementMdUtils.slotsCalendarOptions =
                lodash.cloneDeep(this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions);
        } else {
            // Otherwise initialize the options and save them to the state
            this.slotsManagementMdUtils.slotsCalendarOptions = this.slotsManagementMdUtils.getInitialCalendarOptions(1);
            this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions =
                lodash.cloneDeep(this.slotsManagementMdUtils.slotsCalendarOptions);
        }
    }

    private loadSlotsResultsOptions() {
        // If coming back from bookAppointment and slots results options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.slotsManagementMdUtils.slotsManagementState?.slotsResultsOptions) {
            this.slotsManagementMdUtils.slotsResultsOptions =
                lodash.cloneDeep(this.slotsManagementMdUtils.slotsManagementState.slotsResultsOptions);
        } else {
            // Otherwise initialize with empty array
            this.slotsManagementMdUtils.slotsResultsOptions = [];
        }
        // Reset slice upper bound
        this.slotsManagementMdUtils.resetSlotResultsSliceUpperBound();
    }

    private loadSlotsLocalFiltersOptions() {
        // If coming back from bookAppointment and slots local filters options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.slotsManagementMdUtils.slotsManagementState?.slotsLocalFiltersOptions) {
            this.slotsManagementMdUtils.slotsLocalFiltersOptions =
                lodash.cloneDeep(this.slotsManagementMdUtils.slotsManagementState.slotsLocalFiltersOptions);
            this.displayLocalFilters = this.slotsManagementMdUtils.slotsLocalFiltersOptions.displaySlotsLocalFilters;
        } else {
            // Otherwise initialize the local filters
            this.slotsManagementMdUtils.slotsLocalFiltersOptions = this.slotsManagementMdUtils.getInitialSlotsLocalFiltersOptions();
        }
    }

    private loadSlotsCentersOptions() {
        // If coming back from bookAppointment and slots centers options is already in state cache, load from there
        if (this.shouldRememberState(history?.state?.comingFromRoute) &&
            !!this.slotsManagementMdUtils.slotsManagementState?.slotsCentersOptions) {
            this.slotsManagementMdUtils.slotsCentersOptions =
                lodash.cloneDeep(this.slotsManagementMdUtils.slotsManagementState.slotsCentersOptions);
        } else {
            // Otherwise initialize the local filters
            this.slotsManagementMdUtils.slotsCentersOptions = this.slotsManagementMdUtils.getInitialSlotsCentersOptions();
        }
    }

    private loadObjectDetailsOptions(slot: SlotDisplayType) {
        this.slotsManagementMdUtils.objectDetailsOptions = {
            messagesService: this.messagesService,
            displayBackButton: false,
            service: slot?.service as ServiceType,
            center: slot?.center as unknown as CenterType,
            resource: slot?.resource as unknown as ResourceType,
            subServices: slot?.subServices as SubServiceType[]
        };
    }

    private loadAppointmentInformationOptions(slot: SlotDisplayType, isOutsideAvailabilityBooking: boolean = false) {
        this.selectedPatient = this.patientContextService?.patient;
        this.slotsManagementMdUtils.appointmentInformationOptions = {
            slot,
            patient: this.selectedPatient as PatientType,
            displayBackButton: true,
            messagesService: this.messagesService,
            configDataService: this.configDataService,
            readOnly: false,
            displayPrintButton: false,
            additionalData: {
                expertBooking: this.slotsManagementMdUtils.expertBookingMode,
                isOutsideAvailabilityBooking,
                specialAppointmentBooking: this.slotsManagementMdUtils.specialAppointmentBooking
            } as AppointmentInformationAdditionalDataType,
            defaultPhoneNumberCountryCode: this.libPhoneUtil.getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage)
        } as unknown as AppointmentInformationOptionsType;
    }

    private loadAppointmentInformationOverviewOptions() {
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions = lodash.clone(this.slotsManagementMdUtils.appointmentInformationOptions);
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions.readOnly = true;
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions.displayBackButton = false;
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions.displayPrintButton =
            this.configDataService.activeActivities.indexOf('ViewAppointmentBulletinPdf') !== -1;
    }

    private goToBookAppointment(slot: SlotDisplayType, isOutsideAvailabilityBooking: boolean = false) {
        this.loadObjectDetailsOptions(slot);
        this.loadAppointmentInformationOptions(slot, isOutsideAvailabilityBooking);
        this.loadAppointmentInformationOverviewOptions();
        this.router.navigate(['/bookAppointment']);
    }

    private checkBookDataIsValid(): boolean {
        if (this.isBookAppointmentActivityAssigned()) {
            if (!this.selectedPatient?.id) {
                this.messagesService.error('toastr.error.patientNotSelected', true);
                return false;
            }
            return true;
        } else {
            this.messagesService.error('toastr.error.notAuthorized', true);
            return false;
        }
    }

    private openSlotSearchModal(filterValues: SlotsFiltersSelectValueType[], patient: PatientSearchResultType, continueSearching: boolean = false) {
        const modalOptions = this.slotsManagementMdUtils.getSlotSearchModalOptions();
        const modalRef = this.modalService.open(SlotSearchModalComponent, modalOptions);
        modalRef.componentInstance.options = {filterValues, patient, continueSearching};

        modalRef.result.then((response) => {
            if (!continueSearching) {
                this.processSlotsResponse(response, filterValues);
            } else {
                this.processSlotsResponseOnContinueSearching(response, filterValues);
            }
            // After a search, if the current page still has disabled days, we start another search automatically
            const hasReachedTimeWindowMax = this.slotsManagementMdUtils.timeWindowInDaysBasedOnSearch >= this.slotsManagementMdUtils.timeWindowMaximum;
            if (this.doesCurrentCalendarPageHaveDisabledDays() && !hasReachedTimeWindowMax) {
                this.onSlotsCalendarContinueSearching();
            }
        }, () => {
        });
    }

    private doesCurrentCalendarPageHaveDisabledDays(): boolean {
        if (this.slotsManagementMdUtils.slotsCalendarOptions?.currentPage === undefined) {
            return false;
        }
        const currentPage = this.slotsManagementMdUtils.slotsCalendarOptions
            .calendarPages[this.slotsManagementMdUtils.slotsCalendarOptions.currentPage];
        if (!currentPage?.calendarDays) {
            return false;
        }
        for (const day of currentPage.calendarDays) {
            // Don't count disabled days before today
            if (day.isDisabled && day.momentDate?.isAfter(moment())) {
                return true;
            }
        }
        return false;
    }

    private openSlotWithoutPlannedCapacityModal(slot: SlotDisplayType) {
        const selectedSlot = slot;
        const modalOptions = this.slotsManagementMdUtils.getSlotWithoutPlannedCapacityModalOptions();
        const modalRef = this.modalService.open(SlotWithoutPlannedCapacityModalComponent, modalOptions);
        modalRef.componentInstance.options = selectedSlot;

        modalRef.result.then((response) => {
            this.goToBookAppointment(response);
        }, () => {
        });
    }

    private processSlotsResponse(
        slotsResponse: SlotSearchResponseType, filterValues?: SlotsFiltersSelectValueType[], continueSearching: boolean = false
    ) {
        this.slotsManagementMdUtils.previousSlotsResponse = lodash.cloneDeep(slotsResponse);
        this.slotsManagementMdUtils.slotsExtraDetails = slotsResponse.slotsExtraDetails;
        this.displayLocalFilters = true;
        this.slotsManagementMdUtils.rawSlots = slotsResponse.slots;
        this.slotsManagementMdUtils.groupedSlots = lodash.groupBy(slotsResponse.slots, (slot) => slot.date);
        this.slotsManagementMdUtils.noSlotsFound = slotsResponse.slots?.length === 0;
        this.slotsManagementMdUtils.latestFilterValues = lodash.cloneDeep(filterValues);
        this.slotsManagementMdUtils.actualFilterValues = lodash.cloneDeep(filterValues);
        this.slotsManagementMdUtils.patientHadValueAtSearch = !!this.patientContextService.patient;
        this.slotsManagementMdUtils.filtersChangedWithNoSearch = false;
        this.slotsManagementMdUtils.filtersJustReset = false;
        if (this.slotsManagementMdUtils.noSlotsFound) {
            // If no slots found, clear all data from screen and state
            this.clearLocalFiltersSlotResultsAndCalendarData();
            this.slotsManagementMdUtils.noSlotsFound = slotsResponse.slots?.length === 0;
            return;
        }
        this.slotsManagementMdUtils.loadCalendarOptions(continueSearching);
        this.slotsManagementMdUtils.loadLocalFiltersOptions(this.displayLocalFilters);
        this.slotsManagementMdUtils.loadAllAvailableCentersWithNumberOfSlots();
        this.slotsManagementMdUtils.loadSlotsResultsOptions();
        this.preselectCentersIfNeeded();
    }

    private processSlotsResponseOnContinueSearching(slotsResponse: SlotSearchResponseType, filterValues?: SlotsFiltersSelectValueType[]) {
        // Combine previous slot response with current one
        const previousSlotsResponse = this.slotsManagementMdUtils.previousSlotsResponse;
        const idF = (item) => item.id;
        const combinedSlotsResponse: SlotSearchResponseType = {
            slots: lodash.concat(previousSlotsResponse?.slots, slotsResponse?.slots),
            slotsExtraDetails: {
                numberOfSkippedCenters: previousSlotsResponse?.slotsExtraDetails?.numberOfSkippedCenters,
                availabilities: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.availabilities, slotsResponse?.slotsExtraDetails?.availabilities), idF),
                centers: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.centers, slotsResponse?.slotsExtraDetails?.centers), idF),
                resources: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.resources, slotsResponse?.slotsExtraDetails?.resources), idF),
                services: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.services, slotsResponse?.slotsExtraDetails?.services), idF),
                coverageplans: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.coverageplans, slotsResponse?.slotsExtraDetails?.coverageplans), idF),
                messages: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.messages, slotsResponse?.slotsExtraDetails?.messages), idF),
                appointmentTypes: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.appointmentTypes, slotsResponse?.slotsExtraDetails?.appointmentTypes), idF),
                subServices: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.subServices, slotsResponse?.slotsExtraDetails?.subServices), idF),
                oversellingDefinitions: lodash.uniqBy(lodash.concat(
                    previousSlotsResponse?.slotsExtraDetails?.oversellingDefinitions, slotsResponse?.slotsExtraDetails?.oversellingDefinitions), idF),
                type: previousSlotsResponse?.slotsExtraDetails?.type,
            }
        };
        // Call processSlotResponse with all the slot responses
        this.processSlotsResponse(combinedSlotsResponse, filterValues, true);
    }

    private preselectCentersIfNeeded() {
        const searchedCenter = lodash.find(this.slotsManagementMdUtils.searchedFilterValues, {name: SlotsFilterNameEnum.Center})?.value;
        if (!!searchedCenter) {
            const foundCenterIndex = lodash.findIndex(
                this.slotsManagementMdUtils.slotsCentersOptions.centers,
                {id: searchedCenter.id}
            );
            // If center is found in local filters popup
            if (foundCenterIndex > -1) {
                // Simulate selecting the searched center and clicking on ok
                this.slotsManagementMdUtils.slotsCentersOptions.centers[foundCenterIndex].selected = true;
                this.slotsManagementMdUtils.slotsCentersOptions.selectedCenters = lodash.filter(
                    this.slotsManagementMdUtils.slotsCentersOptions.centers, {selected: true}
                );
                this.onSlotsCentersOkClick();
                return;
            }
            // If the center was not found, do not select anything
            return;
        }
        // If search was not done with a center, preselect all the centers
        this.slotsManagementMdUtils.slotsCentersOptions.centers.forEach((center: SlotCenterLocalFilterType) => {
            center.selected = true;
        });
        this.slotsManagementMdUtils.slotsCentersOptions.selectedCenters = lodash.cloneDeep(
            this.slotsManagementMdUtils.slotsCentersOptions.centers
        );
        this.onSlotsCentersOkClick();
    }

    private loadSearchTimeWindowSystemConfigVariables() {
        // Load timeWindowMaximum
        const timeWindowMaximumValueString =
            lodash.find(this.configDataService?.systemConfig?.value, {name: systemConfigKeys.TIME_WINDOW_MAXIMUM})?.value;
        const timeWindowMaximumValue = this.generalUtils.getNumberFromStringOtherwiseUndefined(timeWindowMaximumValueString);
        if (timeWindowMaximumValue !== undefined) {
            this.slotsManagementMdUtils.timeWindowMaximum = timeWindowMaximumValue;
        }
        // Load resourceMaxTimeWindow
        const resourceMaxTimeWindowValueString = lodash.find(this.configDataService?.systemConfig?.value,
            {name: systemConfigKeys.SLOT_SEARCH_RESOURCE_STEP})?.value;
        const resourceMaxTimeWindowValue = this.generalUtils.getNumberFromStringOtherwiseUndefined(resourceMaxTimeWindowValueString);
        if (resourceMaxTimeWindowValue !== undefined) {
            this.slotsManagementMdUtils.resourceSearchStep = resourceMaxTimeWindowValue;
        }
        // Load serviceMaxTimeWindow
        const serviceMaxTimeWindowValueString = lodash.find(this.configDataService?.systemConfig?.value,
            {name: systemConfigKeys.SLOT_SEARCH_SERVICE_STEP})?.value;
        const serviceMaxTimeWindowValue = this.generalUtils.getNumberFromStringOtherwiseUndefined(serviceMaxTimeWindowValueString);
        if (serviceMaxTimeWindowValue !== undefined) {
            this.slotsManagementMdUtils.serviceSearchStep = serviceMaxTimeWindowValue;
        }
    }

    private filterAllSlotDependentComponents(filteredSlots: SlotType[]) {
        this.slotsManagementMdUtils.groupedSlots = lodash.groupBy(filteredSlots, (slot) => slot.date);
        // pass filtered slots to calendar
        this.slotsManagementMdUtils.slotsCalendarOptions = this.slotsManagementMdUtils.loadNumberOfSlotsPerDay(
            this.slotsManagementMdUtils.slotsCalendarOptions);
        // Keep the state of the calendar up to date
        this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsCalendarOptions);
        // pass filtered slots to list
        this.slotsManagementMdUtils.loadSlotsResultsOptions();
    }

    private shouldRememberState(comingFromRoute: string): boolean {
        if (!comingFromRoute) {
            return false;
        }
        return comingFromRoute === 'bookAppointment';
    }

    private isBookAppointmentActivityAssigned(): boolean {
        // todo remove this method since AppointmentCreate is added as a dependent activity
        // const activities = this.configDataService.getConfigFromStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME);
        // return !!lodash.includes(activities, 'AppointmentCreate');
        return true;
    }

    private listenToPatientChanges() {
        this.selectedPatient = this.patientContextService.patient;
        this.patientContextService.patientChange?.subscribe((patient) => {
            this.selectedPatient = patient;
        });
    }
}
