import {Injectable} from '@angular/core';
import {CalendarOptions, CustomButtonInput, EventContentArg} from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import * as moment from 'moment';
import {AppointmentStatusValueEnum, RUAppointmentsType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {environment} from '../../../../environments/environment';
import {ResourceUtilizationCalendarEventTypesEnum} from './resource-utilization-calendar.types';
import tippy from 'tippy.js';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {forkJoin} from 'rxjs';
import {MessagesService} from '../../../shared/services/messages.service';
import {TranslateService} from '@ngx-translate/core';
import {ResourceUtilizationUtils} from '../resource-utilization.utils';
import momentPlugin from '@fullcalendar/moment';
import {constants} from '../../../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ResourceUtilizationCalendarUtil {
    calendarSpinnerId = 'resourceUtilizationCalendarSpinner';

    constructor(
        private translatedLanguageService: TranslatedLanguageService,
        private ngxLoader: NgxUiLoaderService,
        private dateTimeUtils: DateTimeUtils,
        private messagesService: MessagesService,
        private translateService: TranslateService,
        private resourceUtilizationUtils: ResourceUtilizationUtils,
    ) {
    }

    navigateToAppointment(appointment: RUAppointmentsType, shouldNavigateToTime: boolean = true) {
        this.ngxLoader.startLoader(this.calendarSpinnerId);
        // Scroll to resource of appointment (vertical)
        const resourceRow = document.querySelector(`td[data-resource-id="${appointment.resourceId}"]`);
        resourceRow?.scrollIntoView();
        // After scrolling vertically,
        setTimeout(() => {
            // Go to date of appointment
            this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi.gotoDate(appointment.dateTimeFrom);
            // Scroll to time of appointment (horizontal)
            if (shouldNavigateToTime) {
                this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi
                    .scrollToTime(moment(appointment.dateTimeFrom).format('HH:mm'));
            }
            // Update small header calendar
            this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel =
                this.dateTimeUtils.getNgbDateFromMoment(moment(appointment.dateTimeFrom));
            // Update state
            this.resourceUtilizationUtils.calendarOptions.lastSelectedAppointment = appointment;
            this.resourceUtilizationUtils.updateCalendarState(['lastSelectedAppointment']);
            this.ngxLoader.stopLoader(this.calendarSpinnerId);
        });
    }


    // //////////////////
    // Resource and events options for fullcalendar
    // //////////////////

    getEventTypeFromAppointment(appointment: RUAppointmentsType): ResourceUtilizationCalendarEventTypesEnum {
        // Given an appointment, returns its type from the enum
        if (appointment.pendingAppointment) {
            return ResourceUtilizationCalendarEventTypesEnum.Pending;
        }
        if (appointment.blocked) {
            return ResourceUtilizationCalendarEventTypesEnum.Blocked;
        }
        if (appointment.overcapacity) {
            return ResourceUtilizationCalendarEventTypesEnum.Overcapacity;
        }
        if (appointment.outsideAvailabilityHours) {
            return ResourceUtilizationCalendarEventTypesEnum.OutsideBookingHours;
        }
        return ResourceUtilizationCalendarEventTypesEnum.Normal;
    }

    getEventFontAwesomeIconClassFromAppointment(appointment: RUAppointmentsType): string[] {
        // Given an appointment, returns its fontawesome icons
        // Possible options:
        // 'fas fa-credit-card', 'fas fa-desktop', 'far fa-calendar-check', 'fas fa-check', 'far fa-check-circle', 'fas fa-exclamation'
        const iconList = [];
        if (appointment.pendingAppointment) {
            iconList.push('fas fa-exclamation');
        }
        if (appointment.paid === true) {
            iconList.push('fas fa-credit-card');
        }
        if (appointment.service?.onlineConsultation === true) {
            iconList.push('fas fa-desktop');
        }
        if (appointment.status === AppointmentStatusValueEnum.Confirmed) {
            iconList.push('far fa-calendar-check');
        } else if (appointment.status === AppointmentStatusValueEnum.CheckedIn) {
            iconList.push('fas fa-check');
        } else if (appointment.status === AppointmentStatusValueEnum.ServicePerformed) {
            iconList.push('far fa-check-circle');
        }
        return iconList;
    }

    getEventTitleFromAppointment(appointment: RUAppointmentsType): string {
        // Given an appointment, return its displayed text
        return '';
    }

    getEventTippyContentFromAppointment(appointment: RUAppointmentsType): string {
        // Given an appointment, return its tippy displayed text
        const startTime = moment(appointment.dateTimeFrom).format('HH:mm');
        return `${startTime}, ${appointment.speciality.name}, ${appointment.patient.name}`;
    }

    getCustomBackgroundColor(appointment: RUAppointmentsType): string {
        // Given an appointment, return its background color (overrides color of type)
        return undefined;
    }

    getCustomFontColor(appointment: RUAppointmentsType): string {
        // Given an appointment, return its font color (overrides color of type)
        return undefined;
    }


    // //////////////////
    // Calendar options
    // //////////////////

    getCalendarOptions(): CalendarOptions {
        return {
            locales: [esLocale, enLocale, ptLocale], // Available languages
            locale: this.translatedLanguageService.getUsedLanguage().substring(0, 2), // Calendar language
            plugins: [resourceTimelinePlugin, momentPlugin],
            initialView: 'resourceTimelineDay', // The initial view when the calendar loads.
            resourceAreaWidth: 200,
            height: '100%', // Height of the entire calendar, including header and footer.
            titleFormat: ' ', // Empty title so we can custom one from scratch and place it in fullcalendar header with css
            firstDay: 1, // The day that each week begins. default: 0 (Sunday)
            expandRows: false, // If the rows of a given view don't take up the entire height, they will not expand to fit.
            nowIndicator: true, // Whether or not to display a marker indicating the current time.
            eventDisplay: 'block', // https://fullcalendar.io/docs/eventDisplay
            validRange: { // Range that fullcalendar can go between
                start: this.resourceUtilizationUtils.calendarOptions.calendarRanges.fullCalendarMinDate,
                end: this.resourceUtilizationUtils.calendarOptions.calendarRanges.fullCalendarMaxDate
            },
            scrollTime: moment().format('HH:mm'), // Determines how far forward the scroll pane is initially scrolled.
            slotDuration: // The frequency for displaying time slots (Granularity)
                `00:${this.resourceUtilizationUtils.calendarOptions.calendarGranularityNgModel ?? '30'}`,
            slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: true
            },
            schedulerLicenseKey: constants.FULL_CALENDAR_LICENSE_KEY,
            eventContent: (arg) => {
                // Called for each event, return dom elements to display
                return this.eventContent(arg);
            },
            datesSet: (dateInfo) => {
                // Called whenever fullcalendar changes dates (prev next today buttons, gotoDate api)
                // Update small calendar ngModel
                this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel =
                    this.dateTimeUtils.getNgbDateFromMoment(moment(dateInfo.start));
            },
            customButtons: this.getResourceUtilizationCalendarCustomButtons(), // Defines custom buttons that can be used in the header/footer.
            headerToolbar: this.getCalendarHeaderOption(), // Defines the header
        } as CalendarOptions;
    }

    private eventContent(arg: EventContentArg): {domNodes: HTMLElement[]} {
        // Called for each event, return dom elements to display
        // Add popover and icon from extended props to each event
        const containerElement = document.createElement('div');
        containerElement.classList.add('fc-event-custom-container');
        // Override container background color and font color if arguments provided
        if (!!arg.event.extendedProps.customBackgroundColor) {
            containerElement.style.backgroundColor = arg.event.extendedProps.customBackgroundColor;
        }
        if (!!arg.event.extendedProps.customFontColor) {
            containerElement.style.color = arg.event.extendedProps.customFontColor;
        }
        // Add font awesome icon if prop present
        if (arg.event?.extendedProps?.iconList?.length > 0) {
            arg.event.extendedProps.iconList.forEach((icon: string) => {
                const fasElement = document.createElement('i');
                fasElement.classList.add('fc-event-custom-icon');
                fasElement.classList.add(...icon.split(' '));
                containerElement.appendChild(fasElement);
            });
        }
        // Title element
        const titleElement = document.createElement('div');
        titleElement.classList.add('fc-event-custom-title');
        titleElement.innerText = arg.event.title;
        containerElement.appendChild(titleElement);

        // Add tippy popover to element so title is visible for events with small width
        tippy(containerElement, {
            content: arg.event.extendedProps.tippyContent,
            placement: 'top',
            theme: 'light-border',
            animation: false,
            popperOptions: { strategy: 'fixed' }
        });
        return {domNodes: [containerElement]};
    }

    private getCalendarHeaderOption(): { left: string; center: string; right: string; } {
        return {
            left: 'customLegendButton',
            center: 'title',
            right: 'customReloadButton customOptionsButton prev,next today'
        };
    }

    private getResourceUtilizationCalendarCustomButtons(): { [name: string]: CustomButtonInput } {
        this.resourceUtilizationUtils.calendarOptions.customButtons = {
            customLegendButton: {
                text: '‗',
                click: (event, element) => {
                    if (!!this.resourceUtilizationUtils.calendarOptions.legendHeaderTippyInstance) {
                        // Tippy already created
                        return;
                    }
                    // Create tippy with with template from the html file and show it
                    const legendTemplateElement = document.querySelector('#resource-utilization-events-legend-template');
                    this.resourceUtilizationUtils.calendarOptions.legendHeaderTippyInstance = tippy(element, {
                        content: legendTemplateElement,
                        hideOnClick: true,
                        placement: 'right-start',
                        allowHTML: true,
                        interactive: true,
                        trigger: 'click',
                        theme: 'light-border',
                        animation: false,
                        popperOptions: { strategy: 'fixed' },
                        onMount(instance) {
                            setTimeout(() => {
                                instance?.popperInstance?.update();
                            });
                        }
                    });
                    this.resourceUtilizationUtils.calendarOptions.legendHeaderTippyInstance.show();
                }
            },
            customOptionsButton: {
                text: '▼',
                click: (event, element) => {
                    if (!!this.resourceUtilizationUtils.calendarOptions.optionsHeaderTippyInstance) {
                        // Tippy already created
                        return;
                    }
                    // Create tippy with with template from the html file and show it
                    const optionsTemplateElement = document.querySelector('#resource-utilization-fullcalendar-header-options-template');
                    this.resourceUtilizationUtils.calendarOptions.optionsHeaderTippyInstance = tippy(element, {
                        content: optionsTemplateElement,
                        hideOnClick: true,
                        placement: 'bottom-end',
                        allowHTML: true,
                        interactive: true,
                        trigger: 'click',
                        theme: 'light-border',
                        animation: false,
                        popperOptions: { strategy: 'fixed' },
                        onMount(instance) {
                            setTimeout(() => {
                                instance?.popperInstance?.update();
                            });
                        }
                    });
                    this.resourceUtilizationUtils.calendarOptions.optionsHeaderTippyInstance.show();
                }
            },
            customReloadButton: {
                text: '⭮',
                click: () => {
                    this.resourceUtilizationUtils.refreshDataButKeepCalendarDay.next();
                }
            }
        };
        return this.resourceUtilizationUtils.calendarOptions.customButtons;
    }


    // //////////////////
    // Small header calendar utils
    // //////////////////

    isDayWithSlots(date: NgbDateStruct) {
        return this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarIsDayWithSlots[this.dateTimeUtils.getStringFromNgbDate(date)];
    }

    loadSmallCalendarIsDayWithSlots() {
        this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarIsDayWithSlots = {};
        this.resourceUtilizationUtils.appointments.forEach((appointment: RUAppointmentsType) => {
            const ngbDate = this.dateTimeUtils.getNgbDateFromMoment(moment(appointment.dateTimeFrom));
            const stringDate = this.dateTimeUtils.getStringFromNgbDate(ngbDate);
            this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarIsDayWithSlots[stringDate] = true;
        });
    }


    // //////////////////
    // Translations utils
    // //////////////////

    translateLabels() {
        // Translate texts
        this.setTranslatedTexts(); // translate when the language didn't change
        // Translate when language changed (and on refresh)
        this.translateService.onLangChange.subscribe(() => {
            // Set new language and translate texts
            this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi
                ?.setOption('locale', this.translatedLanguageService.getUsedLanguage().substring(0, 2));
            this.setTranslatedTexts();
        });
    }

    private setTranslatedTexts() {
        forkJoin([
            this.messagesService.translateMessage('label.resource')
        ]).subscribe(translatedMessages => {
            this.resourceUtilizationUtils.calendarOptions.translatedText.resourceTranslation = translatedMessages[0];

            if (this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions) {
                this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions.resourceAreaHeaderContent =
                    this.resourceUtilizationUtils.calendarOptions.translatedText.resourceTranslation;
            }
        });
    }
}
