import {Injectable} from '@angular/core';
import {
    AppointmentProvider,
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider,
    DateRangeOptionsType,
    FilterWrapperButtonNameEnum,
    FilterWrapperNameEnum,
    FormValidationType,
    GenericFilterOptionsType,
    GenericFilterResultType,
    PatientProvider,
    ResourceDependentFiltersType,
    ResourceProvider,
    ServiceDependentFiltersType,
    ServiceProvider,
    SubServiceProvider
} from 'sked-base';
import {DateTimeUtils} from '../../../../shared/utils/dateTime.utils';
import {
    PatientAppointmentListFiltersSearchType, PatientAppointmentListFiltersValidationType,
    PatientAppointmentListFilterWrapperInitialValuesType
} from './patient-appointment-list-filters.types';

@Injectable({
    providedIn: 'root'
})
export class PatientAppointmentListFiltersUtils {
    constructor(
        private areaProvider: AreaProvider,
        private patientProvider: PatientProvider,
        private resourceProvider: ResourceProvider,
        private serviceProvider: ServiceProvider,
        private subServiceProvider: SubServiceProvider,
        private appointmentProvider: AppointmentProvider,
        private centerProvider: CenterProvider,
        private dateTimeUtils: DateTimeUtils
    ) {
    }

    getInitialFilterWrapperValuesFromPreviousValues(
        patientAppointmentListFiltersValues: PatientAppointmentListFilterWrapperInitialValuesType
    ): GenericFilterResultType[] {
        const filterWrapperValues: GenericFilterResultType[] = [];
        Object.keys(patientAppointmentListFiltersValues).forEach((property: string) => {
            if (!!patientAppointmentListFiltersValues[property]) {
                filterWrapperValues.push({
                    name: FilterWrapperNameEnum[property],
                    value: patientAppointmentListFiltersValues[property]
                } as GenericFilterResultType);
            }
        });
        return filterWrapperValues;
    }

    mapFilterValuesForSearch(
        patientAppointmentListFiltersValues: PatientAppointmentListFilterWrapperInitialValuesType,
    ): PatientAppointmentListFiltersSearchType {
        return {
            area: patientAppointmentListFiltersValues.area,
            resource: patientAppointmentListFiltersValues.resource,
            service: patientAppointmentListFiltersValues.service,
        } as PatientAppointmentListFiltersSearchType;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    } as ResourceDependentFiltersType)

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        onlyAssignedToLocationsOfUser: true,
        includeAvailabilities: false,
        includeChannel: true,
        searchPhrase: '',
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        hasMultiResourceBluePrint: null,
        exclusionList: [],
        count: true,
    } as ServiceDependentFiltersType)

    getInitialFilterValidations(): PatientAppointmentListFiltersValidationType {
        return {
            area: { isValid: true, errorMessage: '' } as FormValidationType,
            resource: { isValid: true, errorMessage: '' } as FormValidationType,
            service: { isValid: true, errorMessage: '' } as FormValidationType,
        } as PatientAppointmentListFiltersValidationType;
    }

    getFilterWrapperOptions = (initialValues?: PatientAppointmentListFilterWrapperInitialValuesType): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.getAreaDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.area,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues?.area ? {
                    name: FilterWrapperNameEnum.area,
                    value: initialValues.area
                } : undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getResourceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resource,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues?.resource ? {
                    name: FilterWrapperNameEnum.resource,
                    value: initialValues.resource
                } : undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getServiceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.service,
                name: FilterWrapperNameEnum.service,
                parentFilterValue: initialValues?.service ? {
                    name: FilterWrapperNameEnum.service,
                    value: initialValues.service
                } : undefined,
                providerInstance: this.serviceProvider,
                useSelectedValueAsLabel: true
            },
        ]
    )
}
