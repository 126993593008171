<div class="change-log-modal">
    <div class="modal-header">
        <h4 class="modal-title font-weight-bold" translate="label.apiKey"></h4>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body custom-scrollbar pt-0">
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col-3">
                    <label class="title-label" translate="label.userManagementTab.fullName"></label>
                </div>
                <div class="col-6">
                    <label class="value-label">{{userFullName}}</label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-3">
                    <label class="title-label" translate="label.userManagementTab.userName"></label>
                </div>
                <div class="col-6">
                    <label class="value-label">{{userName}}</label>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-3">
                    <label class="title-label mt-1" translate="label.apiKey"></label>
                </div>
                <div class="col-7">
                    <input
                        disabled
                        class="input-element cursor-not-allowed"
                        [ngModel]="apiKey?.apiKey"
                        type="text">
                </div>
                <div class="col-12" *ngIf="apiKey.apiKey && apiKey.apiKey !== constants.DUMMY_API_KEY">
                    <div class="text-warning text-high-contrast mb-3 mt-2">
                        <i class="fa-solid fa-triangle-exclamation"></i>&nbsp;
                        <span translate="label.apiKeyWillOnlyBeDisplayedOnce"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="apiKey?.apiKeyHint" class="row mt-2">
                <div class="col-3">
                    <label class="title-label mt-1" translate="label.apiKeyHint"></label>
                </div>
                <div class="col-7">
                    <input
                        disabled
                        class="input-element cursor-not-allowed"
                        [ngModel]="apiKey?.apiKeyHint"
                        type="text">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
                *ngIf="!apiKey?.apiKeyId && !apiKey.apiKey && (apiKey.apiKey !== constants.DUMMY_API_KEY) && !apiKey.apiKeyHint"
                type="button"
                class="btn theme-btn"
                translate="label.generateApiKey"
                (click)="generateApiKey()">
        </button>
        <button *ngIf="apiKey?.apiKeyId"
                type="button"
                class="btn alert-btn"
                translate="label.deleteApiKey"
                (click)="deleteApiKey()">
        </button>
        <button type="button"
                class="btn close-btn"
                translate="label.close"
                (click)="activeModal.dismiss()">
        </button>
    </div>
</div>
