<div class="container-fluid payment-management-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.paymentManagementRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                                                            [contentTemplate]="paymentManagementRuleModalFiltersContentTemplate"
                                                            [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                                                            [title]="'label.filterMore'"
                                                            (modalClose)="onModalClose($event)">
                </sbase-show-template-modal-filter-container>
            </div>

            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #paymentManagementRuleModalFiltersContentTemplate>
        <div class="payment-management-rule-filter-more-modal-container">
            <!-- Service tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.serviceTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.SERVICE, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.serviceTags"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.serviceTagOptions.ngModel ? [rulesUtils.overviewState.modalFilters.serviceTagOptions.ngModel] : []"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>

            <!-- Center tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.centerTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.CENTER, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.centerTags"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.centerTagOptions.ngModel ? [rulesUtils.overviewState.modalFilters.centerTagOptions.ngModel] : []"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>

            <!-- Resource tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.resourceTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.RESOURCE, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.resourceTags"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.resourceTagOptions.ngModel ? [rulesUtils.overviewState.modalFilters.resourceTagOptions.ngModel] : []"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>

            <!-- Coverage plan -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.coveragePlan2">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedCoveragePlan($event)"
                              [buttonName]='"label.coveragePlan2"'
                              [dependentFilters]="mainDependentFilters?.coveragePlan"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.coveragePlanOptions.ngModel ? [rulesUtils.overviewState.modalFilters.coveragePlanOptions.ngModel] : []"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="coveragePlanProvider"
                              class="sbase-dropdown-ml">
                </sbase-filter>
            </div>

            <!-- Activate payment -->
            <div class="rules-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.activatePaymentOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.activatePayment"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.choose' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.activatePaymentOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.activatePaymentOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'activatePaymentOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.choose"></span>
                    </ng-option>
                    <ng-option *ngFor="let item of activatePaymentEnumList"
                               [value]="item">
                        <span [translate]="'enum.activatePayment.' + item"></span>
                    </ng-option>
                </ng-select>
            </div>

            <!-- Show price -->
            <div class="rules-select-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.showPriceOptions">
                <label translate="label.showPrice" class="text-high-contrast"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.choose' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.showPriceOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.showPriceOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'showPriceOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.choose"></span>
                    </ng-option>
                    <ng-option [value]="true">
                        <span translate="label.yes"></span>
                    </ng-option>
                    <ng-option [value]="'false'">
                        <span translate="label.no"></span>
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active soring -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key + (key === 'coveragePlan' ? '2' : '') | translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchPaymentManagementRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="'PaymentManagementRuleCreate'"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="'PaymentManagementRuleCreate'"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
                <!-- Table headers -->
                <tr>
                    <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                    <ng-container *ngFor="let column of tableHeaderColumns">
                        <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'"
                            (click)="onSortBy(column.onSortByProperty)">
                            {{column.label | translate}}
                            <button type="button" class="btn p-0"
                                    *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                                <i class="fas fa-sort"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                                <i class="fas fa-sort-down"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                                <i class="fas fa-sort-up"></i>
                            </button>
                        </th>
                        <th *ngIf="!column.hasSort" [ngClass]="column.class" [translate]="column.label"></th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows -->
                <tr *ngFor="let rule of ruleList"
                    [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                    sbaseMarkTableRowOnHover
                    (click)="viewRule(rule)">
                    <td class="name-column">{{rule.paymentManagementRuleTable?.name}}</td>
                    <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                    <td class="name-column">{{rule.name}}</td>
                    <td>{{rule.serviceTag?.name}}</td>
                    <td>{{rule.centerTag?.name}}</td>
                    <td>{{rule.resourceTag?.name}}</td>
                    <td>{{rule.coveragePlan?.name}}</td>
                    <td>{{'enum.activatePayment.' + rule.activatePayment | translate}}</td>
                    <td>{{rule.showPrice | yesNoPipe | translate}}</td>
                    <!-- Last column, used for action buttons -->
                    <td class="text-center" (click)="$event.stopPropagation()">
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.view"
                                            (click)="viewRule(rule)"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.edit"
                                            (click)="editRule(rule)"
                                            [activityDisplay]="'PaymentManagementRuleUpdate'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="displayConfirmDeleteItemModal(rule.id)"
                                            [activityDisplay]="'PaymentManagementRuleDeleteActivity'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.changeLog"
                                            (click)="displayChangeLog(rule.id, EntityTypeEnum.paymentManagementRule, rule.name)"
                                            [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Page filters -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-2" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start" x-placement="bottom-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
    </div>
</div>
