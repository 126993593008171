<div class="container-fluid sub-service-wrapper overview-screen-md pb-1">
    <div class="sub-service-header d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between mb-1">
            <h3 class="sub-service-title" translate="label.subServices"></h3>
            <div class="d-flex align-items-center">
                <div class="service-filter-container mr-2">
                    <sbase-filter (selectedItemsOutput)=" onSelectedServiceFilter($event)"
                                  [buttonName]=serviceButtonName
                                  [dependentFilters]="mainDependentFilters.service"
                                  [initialFilterValues]="subServiceMDUtils.initialServiceValues"
                                  [isMultipleSelectedItem]="false"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [providerInstance]="serviceProvider">
                    </sbase-filter>
                </div>
                <app-generate-export-file [exportFileEntity]="exportFileEntityEnum.SubServices"></app-generate-export-file>
                <div class="d-inline-block my-1 ml-2 mr-1" *ngIf="subServiceMDUtils.modalFilters">
                    <sbase-show-template-modal-filter-container
                            [contentTemplate]="subServiceModalFiltersContentTemplate"
                            [areFiltersActive]="subServiceMDUtils.modalFilters.areFiltersActive"
                            [title]="'label.filterMore'"
                            (modalClose)="onModalClose($event)"
                    ></sbase-show-template-modal-filter-container>
                    <ng-template #subServiceModalFiltersContentTemplate>
                        <div class="row mr-0 ml-0" *ngIf="subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions !== undefined && subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions !== null">
                            <div class="d-flex enabled-on-patient-access-options">
                                <div class="mr-1">
                                    <label translate="label.enabledOnPatientAccess" class="text-high-contrast"></label>
                                    <div class="d-block">
                                        <input type="radio"
                                               class="btn-check"
                                               name="enabledOnPatientAccessOptions"
                                               id="filterEnabledOnPatientAccessOptionNull"
                                               autocomplete="off"
                                               [value]="null"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterEnabledOnPatientAccessOptionNull"
                                               translate="label.all"
                                               [ngClass]="(subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel === null) ? 'selected-option': ''">
                                        </label>

                                        <input type="radio"
                                               class="btn-check text-high-contrast"
                                               name="enabledOnPatientAccessOptions"
                                               id="filterEnabledOnPatientAccessOptionYes"
                                               autocomplete="off"
                                               [value]="true"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterEnabledOnPatientAccessOptionYes"
                                               translate="label.yes"
                                               [ngClass]="(subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel === true) ? 'selected-option': ''">
                                        </label>

                                        <input type="radio"
                                               class="btn-check"
                                               name="enabledOnPatientAccessOptions"
                                               id="filterEnabledOnPatientAccessOptionNo"
                                               autocomplete="off"
                                               [value]="false"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterEnabledOnPatientAccessOptionNo"
                                               translate="label.no"
                                               [ngClass]="(subServiceMDUtils.modalFilters.enabledOnPatientAccessOptions.ngModel === false) ? 'selected-option': ''">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mr-0 ml-0" *ngIf="subServiceMDUtils.modalFilters.favoriteOptions !== undefined && subServiceMDUtils.modalFilters.favoriteOptions !== null">
                            <div class="d-flex favorite-options">
                                <div class="mr-1">
                                    <label translate="label.favoriteForPatientAccess" class="text-high-contrast"></label>
                                    <div class="d-block">
                                        <input type="radio"
                                               class="btn-check text-high-contrast"
                                               name="favoriteOptions"
                                               id="filterFavoriteOptionNull"
                                               autocomplete="off"
                                               [value]="null"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.favoriteOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterFavoriteOptionNull"
                                               translate="label.all"
                                               [ngClass]="(subServiceMDUtils.modalFilters.favoriteOptions.ngModel === null) ? 'selected-option': ''">
                                        </label>

                                        <input type="radio"
                                               class="btn-check text-high-contrast"
                                               name="favoriteOptions"
                                               id="filterFavoriteOptionYes"
                                               autocomplete="off"
                                               [value]="true"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.favoriteOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterFavoriteOptionYes"
                                               translate="label.yes"
                                               [ngClass]="(subServiceMDUtils.modalFilters.favoriteOptions.ngModel === true) ? 'selected-option': ''">
                                        </label>

                                        <input type="radio"
                                               class="btn-check text-high-contrast"
                                               name="favoriteOptions"
                                               id="filterFavoriteOptionNo"
                                               autocomplete="off"
                                               [value]="false"
                                               [(ngModel)]="subServiceMDUtils.modalFilters.favoriteOptions.ngModel">
                                        <label class="btn radio-button text-high-contrast"
                                               for="filterFavoriteOptionNo"
                                               translate="label.no"
                                               [ngClass]="(subServiceMDUtils.modalFilters.favoriteOptions.ngModel === false) ? 'selected-option': ''">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <button class="reset-button btn" (click)="onClearFilters()">
                    <i class="far fa-trash-alt"></i>
                    <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
              <span *ngFor="let key of objectKeys(subServiceMDUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + subServiceMDUtils.tableFilters.orderBy[key] | translate}}
                  <span><i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
                </span>
              </span>
            </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input (ngModelChange)="onSearchSubServiceNameChanged($event)" [(ngModel)]="subServiceMDUtils.tableFilters.filter.Name"
                       autocomplete="off" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" type="text">
            </div>
            <div class="ml-2">
                <button (click)='createSubService()' [activityDisplay]="MDEntityName+constants.CREATE"
                        class="btn theme-alt-btn border-0"
                        type="button"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column" translate="label.shortId"></th>
                <th (click)="onSortBy('name')" class="name-column cursor-pointer">
                    {{'label.name' | translate}}
                    <button *ngIf="!subServiceMDUtils.tableFilters.orderBy.name" class="btn p-0" type="button"><i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="subServiceMDUtils.tableFilters.orderBy.name === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="subServiceMDUtils.tableFilters.orderBy.name === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.duration"></th>
                <th class="text-center" translate="label.code"></th>
                <th class="text-center">{{'label.serviceTab.externalKeysTab' | translate}}
                    ( {{'label.externalKeys.origin' | translate}}
                    - {{'label.externalKeys.valueOfExternalKey' | translate}} )
                </th>
                <th class="text-center" translate="label.enabledOnPatientAccess"></th>
                <th class="text-center" translate="label.favoriteForPatientAccess"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                *ngFor="let subService of subServicesList" sbaseMarkTableRowOnHover (click)="viewSubService(subService)">
                <td class="name-column">{{subService.shortId}}</td>
                <td class="name-column max-width-table-column">{{subService.name}}</td>
                <td class="text-center"> {{subService.defaultDuration}}</td>
                <td class="text-center"> {{subService.code}}</td>
                <td class="text-center">
                    <div *ngFor="let externalKey of subService.subServiceExternalKeys| slice:0:3">
                        {{externalKey.origin}} - {{externalKey.key}}
                    </div>

                    <ng-template #externalKeysContent>
                        <div>
                            <div *ngFor="let externalKey of subService.subServiceExternalKeys">
                                {{externalKey.origin}} - {{externalKey.key}}
                            </div>
                        </div>
                    </ng-template>
                    <button *ngIf="subService.subServiceExternalKeys && subService.subServiceExternalKeys.length>3"
                            class="btn text-center w-100"
                            (click)="$event.stopPropagation()"
                            type="button">
                        <div
                                [ngbPopover]="externalKeysContent"
                                class="fas fa-ellipsis-h">
                        </div>
                    </button>
                </td>
                <td class="text-center">{{subService?.enabledOnPatientAccess | yesNoPipe | translate}}</td>
                <td class="text-center">{{subService?.favorite | yesNoPipe | translate}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button [ngbPopover]="popContent" class="btn" placement="left" popoverClass="popover-shadow"
                            type="button">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>

                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button (click)="viewSubService(subService)" class="btn" translate="label.view"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="editSubService(subService)"
                                        [activityDisplay]="MDEntityName+constants.UPDATE" class="btn"
                                        translate="label.edit"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="displayConfirmDeleteItemModal(subService.id)"
                                        [activityDisplay]="MDEntityName+constants.DELETE" class="btn"
                                        translate="label.delete"
                                        type="button"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(subService.id, EntityTypeEnum.subService, subService.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="subServiceMDUtils.tableFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="subServiceMDUtils.tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                  <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                        [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                        aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                        data-toggle="dropdown">
                      <strong>{{subServiceMDUtils.tableFilters.itemsPerPage}}</strong>
                  </span>
            <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                 [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                   class="dropdown-item">{{page}}</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
