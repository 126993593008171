import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import {
    MabSlotListOptionsType,
    MabSlotListWrapperOutputType
} from '../multi-appointment-booking.types';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-mab-slots-lists-wrapper',
    templateUrl: './mab-slots-lists-wrapper.component.html',
    styleUrls: ['./mab-slots-lists-wrapper.component.scss']
})
export class MabSlotsListsWrapperComponent implements OnInit {
    @Output() selectValue: EventEmitter<MabSlotListWrapperOutputType> = new EventEmitter<MabSlotListWrapperOutputType>();
    @Output() slotsListRemoved: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils,
        private ngxLoader: NgxUiLoaderService,
    ) {
    }

    ngOnInit(): void {
    }

    onRemoveList(listId: number) {
        this.ngxLoader.start();
        // Remove list with the listId provided
        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions.slotsListsOptions =
            this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions.slotsListsOptions
                .filter((slotsListOptions: MabSlotListOptionsType) => slotsListOptions.listId !== listId);
        this.slotsListRemoved.emit(listId);
        this.ngxLoader.stop();
    }

    onSelectSlot(slotWithListId: MabSlotListWrapperOutputType) {
        this.selectValue.emit(slotWithListId);
    }
}
