import {CapacityPlannerFilterTypeForExport} from '../../../features/capacity-planner/capacity-planner.types';
import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    CenterProvider,
    JobProvider,
    LocationDependentFiltersType,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    ResourceProvider, SearchFilterUtils, TagDependentFiltersScopeEnum, TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CapacityPlannerUtils} from '../../../features/capacity-planner/capacity-planner.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MessagesService} from '../../services/messages.service';
import {of} from 'rxjs';
import {DateTimeUtils} from '../../utils/dateTime.utils';
import {ExportTypeEnum, GenerateExportOptionsType} from '../../../features/room-reservation/room-reservation.types';
import * as moment from 'moment';

@AutoUnsubscribe()
@Component({
    selector: 'app-generate-export',
    templateUrl: './generate-export.component.html',
    styleUrls: ['./generate-export.component.scss']
})
export class GenerateExportComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: GenerateExportOptionsType;
    mainDependentFilters: {
        location: LocationDependentFiltersType,
        resource: ResourceDependentFiltersType,
        tags: TagDependentFiltersType
    };
    // service, resource, center, selected date filters
    modalFilters: CapacityPlannerFilterTypeForExport;
    isDateToValid = true;
    isDateFromValid = true;
    isDiffDatesValid = true;
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    centerButtonName = 'label.location';
    resourceButtonName = 'entities.room';
    isTableFiltersValid = false;
    tags = [];
    jobId = '';
    spinnerId = 'export-loader';
    maxDate: NgbDateStruct;

    constructor(
        private activeModal: NgbActiveModal,
        public centerProvider: CenterProvider,
        public resourceProvider: ResourceProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public capacityPlannerUtils: CapacityPlannerUtils,
        public tagProvider: TagProvider,
        public el: ElementRef,
        public jobProvider: JobProvider,
        public dateTimeUtils: DateTimeUtils,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.modalFilters = this.capacityPlannerUtils.getInitialTableFiltersForExport();
        this.mainDependentFilters = {
            location: this.capacityPlannerUtils.getLocationDependentFilters(),
            resource: this.capacityPlannerUtils.getResourceDependentFilters(),
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedResource, false)
        };
        this.mainDependentFilters.resource.resourceTypeId = this.options?.resourceTypeId;
        this.getTags();
        if (this.options.exportType === ExportTypeEnum.AvailabilitiesWithoutReservation) {
            this.setMaxDate();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.resourceTypeId && !changes.resourceTypeId.firstChange) {
            this.mainDependentFilters.resource.resourceTypeId = changes.resourceTypeId.currentValue;
        }
    }

    ngOnDestroy(): void {
    }

    onSelectedResourceFilter(resourceFilterList): void {
        this.initialResourceValues = resourceFilterList;
        if (resourceFilterList.length > 0) {
            this.mainDependentFilters.location.resourceId = resourceFilterList[0].id;
            this.modalFilters.resourceId = resourceFilterList[0].id;
        } else {
            this.mainDependentFilters.location.resourceId = null;
            this.modalFilters.resourceId = null;
        }
        this.areTableFiltersValid();
    }


    onSelectedCenterFilter(centerFilterList): void {
        this.initialCenterValues = centerFilterList;
        if (centerFilterList.length > 0) {
            this.mainDependentFilters.resource.locationId = centerFilterList[0].id;
            this.modalFilters.centerId = centerFilterList[0].id;
        } else {
            this.mainDependentFilters.resource.locationId = null;
            this.modalFilters.centerId = null;
        }
        this.areTableFiltersValid();
    }

    areTableFiltersValid(): boolean {
        this.isDateToValid = this.capacityPlannerUtils.isValidDate(this.modalFilters.dateTo);
        this.isDateFromValid = this.capacityPlannerUtils.isValidDate(this.modalFilters.dateFrom);
        this.isDiffDatesValid = this.dateTimeUtils.validateDiffDates(this.modalFilters.dateFrom, this.modalFilters.dateTo);
        this.isTableFiltersValid = !!(this.modalFilters.centerId && this.isDateFromValid && this.isDateToValid && this.isDiffDatesValid);
        return this.isTableFiltersValid;
    }

    // create export request and send to backend
    makeRequestForExport(requestTableFilters, exportType: ExportTypeEnum) {
        const isFilterValid = this.areTableFiltersValid();
        if (isFilterValid) {
            const queryFilter = this.capacityPlannerUtils.getQueryFilterForExport(requestTableFilters, exportType);
            this.ngxLoader.start();
            this.getExportObservable(exportType, queryFilter).pipe(take(1)).subscribe(() => {
                this.messagesService.success('label.generateExportWaitMessageGoToExports');
                if (exportType === ExportTypeEnum.PlannedCapacityForRooms) {
                    this.activeModal.close();
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
        }
    }


    onDateToChange(date: NgbDateStruct) {
        this.areTableFiltersValid();
    }

    onDateFromChange(date: NgbDateStruct) {
        if (this.options.exportType === ExportTypeEnum.AvailabilitiesWithoutReservation) {
            this.setMaxDate();
        }
        this.areTableFiltersValid();
    }

    private setMaxDate() {
        const minDate = this.dateTimeUtils.getMomentFromNgbDate(this.modalFilters.dateFrom);
        this.maxDate = this.dateTimeUtils.convertDateInNgbDateStruct(moment(minDate).add(3, 'months').toDate());
    }

    private getExportObservable(exportType: ExportTypeEnum, queryFilter) {
        switch (exportType) {
            case ExportTypeEnum.PlannedCapacityForRooms: {
                return this.jobProvider.getGenerateExportJobPlannedCapacityForRooms(queryFilter);
            }
            case ExportTypeEnum.RoomReservations: {
                return this.jobProvider.getGenerateExportJobRoomsReservations(queryFilter);
            }
            case ExportTypeEnum.AvailabilitiesWithoutReservation: {
                return this.jobProvider.generateExportJobAvailabilitiesWithoutReservation(queryFilter);
            }
            default: {
                return of({});
            }
        }
    }

    private getTags() {
        const filter: ODataQueryObjectType = {
            select: ['Id', 'Name', 'ScopedResource'],
            filter: {ScopedResource: true}
        };

        this.ngxLoader.start();
        this.tagProvider.getEntries(filter)
            .pipe(take(1))
            .subscribe((response) => {
                this.tags = response.value;
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    onChangeTags(tags) {
        this.modalFilters.tags = tags;
        this.areTableFiltersValid();
    }
}
