<div class="container-fluid create-center-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-center-header">
        <h3 class="create-center-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.center2"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-center-section create-entity-md-section">
        <div class="col-12 p-0">
            <mat-horizontal-stepper labelPosition="bottom"
                                    class="h-100"
                                    (selectionChange)="onStepChange($event)" #stepper
                                    [linear]="true">
                <!-- General-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="getStepControl(0)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <!-- center name-->
                                <div class="form-group">
                                    <label translate="label.name"></label>
                                    <input type="text" placeholder="{{'label.name'|translate}}"
                                           [(ngModel)]="centerItem.name"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [ngClass]="{'input-element-has-error': !validTemplate.isNameValid}"
                                           class="input-element" required>
                                    <div *ngIf="!validTemplate.isNameValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!-- countries -->
                                <div class="form-group">
                                    <label translate="label.addressTemplate.country"></label>
                                    <ng-select
                                            placeholder="{{countryPlaceholder | translate}}"
                                            bindLabel="countryName"
                                            bindValue="countryName"
                                            class="custom-ng-select input-element"
                                            notFoundText="{{'toastr.error.notFound' | translate}}"
                                            [(ngModel)]="selectedCountry.countryName"
                                            [disabled]="screenTemplateLayout.action===constants.VIEW"
                                            [items]="countries"
                                            [ngClass]="{'input-element-has-error': !validTemplate.isCountryValid}"
                                            [ngModelOptions]="{standalone: true}"
                                            (search)="onCountrySearch($event)"
                                            (focus)="onCountrySearch($event)"
                                            (blur)="onCountryBlur($event)"
                                            (change)="onCountryValueChanged($event)">
                                    </ng-select>
                                    <span *ngIf="!validTemplate.isCountryValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </span>
                                </div>

                                <!-- regions -->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': !centerItem?.country?.id}">
                                    <label translate="label.addressTemplate.region"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="centerItem.region"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngClass]="{'input-element-has-error': !validTemplate.isRegionValid}"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="screenTemplateLayout.action===constants.VIEW"
                                            (change)="onRegionValueChanged($event)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let region of regions"
                                                   [value]="region">
                                            {{region.name}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!validTemplate.isRegionValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!-- time zones -->
                                <div class="form-group">
                                    <label translate="label.centerTab.timeZone"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="centerItem.timeZoneId"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngClass]="{'input-element-has-error': !validTemplate.isTimeZoneValid}"
                                            [disabled]="screenTemplateLayout.action===constants.VIEW"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="onTimeZoneChanged($event)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let timeZone of timeZones"
                                                   [value]="timeZone.id">
                                            {{timeZone.name}}
                                        </ng-option>
                                    </ng-select>

                                    <div *ngIf="!validTemplate.isTimeZoneValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!-- latitude -->
                                <div class="form-group latitude-input">
                                    <label translate="label.centerTab.latitude"></label>
                                    <input [(ngModel)]='centerItem.latitude'
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.isLatitudeValid}"
                                           placeholder="{{'label.centerTab.latitude' | translate}}"
                                           type="number"
                                           min="0"
                                           class="input-element" required>
                                    <div *ngIf="!validTemplate.isLatitudeValid" class="help-block">
                                        <span>{{ "tenantConfig.invalidNumericWithRange" | translate: {
                                            minimum: -90,
                                            maximum: 90,
                                            decimals: 13
                                        } }}</span>
                                    </div>
                                </div>

                                <!-- longitude -->
                                <div class="form-group">
                                    <label translate="label.centerTab.longitude"></label>
                                    <input [(ngModel)]='centerItem.longitude'
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.isLongitudeValid}"
                                           placeholder="{{'label.centerTab.longitude' | translate}}"
                                           type="number"
                                           min="0"
                                           class="input-element" required>
                                    <div *ngIf="!validTemplate.isLongitudeValid" class="help-block">
                                        <span>{{ "tenantConfig.invalidNumericWithRange" | translate: {
                                            minimum: -180,
                                            maximum: 180,
                                            decimals: 13
                                        } }}</span>
                                    </div>
                                </div>

                                <!-- tags -->
                                <div class="form-group customSelect">
                                    <label translate="label.tags"></label>
                                    <div>
                                        <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                                      [buttonName]='"label.newTag"'
                                                      [dependentFilters]="mainDependentFilters?.tags"
                                                      [initialFilterValues]="centerItem.tags"
                                                      [isMultipleSelectedItem]="true"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="tagProvider">
                                        </sbase-filter>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <!-- areas -->
                                <app-multi-select-table *ngIf="!!areaMultiSelectTableOptions"
                                                        [options]="areaMultiSelectTableOptions"
                                                        (itemsChange)="onSelectedArea($event)"
                                                        class="sbase-dropdown-ml"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Contact Details-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="getStepControl(1)">
                    <ng-template matStepLabel><span translate="label.contactDetails"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <!-- Locality -->
                                <div class="form-group">
                                    <label translate="label.locality"></label>
                                    <ng-select *ngIf="localities.length > 0"
                                               [disabled]="screenTemplateLayout.action===constants.VIEW"
                                               [items]="localities"
                                               placeholder="{{localityPlaceholder | translate}}"
                                               bindLabel="name"
                                               bindValue="id"
                                               class="custom-ng-select"
                                               [(ngModel)]="selectedLocality.id"
                                               (search)="onLocalitySearch()"
                                               (focus)="onLocalitySearch()"
                                               (blur)="onLocalityBlur()"
                                               (change)="onLocalityValueChanged($event)"
                                               notFoundText="{{'toastr.error.notFound' | translate}}">
                                    </ng-select>
                                    <input *ngIf="localities.length <= 0"
                                           [(ngModel)]='centerItem.address.locality'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.locality' | translate"
                                           class="form-control pl-0 rounded-0">
                                    <div *ngIf="!validTemplate.locality" class="help-block">
                                        {{validTemplate.locality?.errorMessage | translate}}
                                    </div>
                                </div>
                                <!-- Street -->
                                <div class="form-group">
                                    <label translate="label.addressTemplate.street" class="pr-1"></label>
                                    <input [(ngModel)]='centerItem.address.street'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.addressTemplate.street' | translate"
                                           class="form-control pl-0 rounded-0">
                                    <span *ngIf="!validTemplate.street?.isValid"
                                          class="help-block">
                                        {{validTemplate.street?.errorMessage | translate}}
                                    </span>
                                </div>
                                <!-- Street Number -->
                                <div class="form-group">
                                    <label translate="label.addressTemplate.streetNumber"></label>
                                    <input [(ngModel)]='centerItem.address.streetNumber'
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [placeholder]="'label.addressTemplate.streetNumber' | translate"
                                           type="text"
                                           class="form-control pl-0 rounded-0">
                                    <span *ngIf="!validTemplate.streetNumber?.isValid"
                                          class="help-block">
                                        {{validTemplate.streetNumber?.errorMessage | translate}}
                                    </span>
                                </div>
                                <!-- Floor -->
                                <div class="form-group">
                                    <label translate="label.addressTemplate.floor"></label>
                                    <input [(ngModel)]='centerItem.address.floor'
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [placeholder]="'label.addressTemplate.floor' | translate"
                                           type="text"
                                           class="form-control pl-0 rounded-0">
                                    <span *ngIf="!validTemplate.floor?.isValid"
                                          class="help-block">
                                        {{validTemplate.floor?.errorMessage | translate}}
                                    </span>
                                </div>
                                <!-- Apartment Number -->
                                <div class="form-group">
                                    <label translate="label.addressTemplate.apartmentNumber" class="pr-1"></label>
                                    <input [(ngModel)]='centerItem.address.apartmentNumber'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.addressTemplate.apartmentNumber' | translate"
                                           class="form-control pl-0 rounded-0">
                                    <span *ngIf="!validTemplate.apartmentNumber?.isValid"
                                          class="help-block">
                                        {{validTemplate.apartmentNumber?.errorMessage | translate}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-6">
                                <!-- Main Phone Number -->
                                <div class="form-group">
                                    <label class="form-label m-0 form-label-client-customization" for="mainPhoneNumber"
                                           translate="label.centerTab.mainPhoneNumber"></label>
                                    <div class="position-relative phone-number-container">
                                        <div class="input-group input-group-phone-number-code">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text input-group-text-phone-number-code input-element"
                                                      [ngClass]="{'input-group-text-phone-number-code-focused' : isMainPhoneNumberInputFocused,
                                                      'input-element-has-error': !validTemplate.mainPhoneNumberValid?.isValid}">+</span>
                                            </div>
                                            <input [(ngModel)]="mainPhoneNumber.countryCode"
                                                   (ngModelChange)="onMainPhoneNumberChange(mainPhoneNumber.phoneNumber, mainPhoneNumber.countryCode)"
                                                   [ngClass]="{'input-element-has-error': !validTemplate.mainPhoneNumberValid?.isValid}"
                                                   autocomplete="off"
                                                   class="input-element form-control form-input p-0"
                                                   id="mainPhoneNumberCountryCode"
                                                   name="mainPhoneNumberCountryCode"
                                                   (focus)="mainPhoneNumberCountryCodeInputFocused(true)"
                                                   (blur)="mainPhoneNumberCountryCodeInputFocused(false)"
                                                   placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                                                   type="text">
                                        </div>
                                        <input [(ngModel)]="mainPhoneNumber.phoneNumber"
                                               (ngModelChange)="onMainPhoneNumberChange(mainPhoneNumber.phoneNumber, mainPhoneNumber.countryCode)"
                                               [ngClass]="{'input-element-has-error': !validTemplate.mainPhoneNumberValid?.isValid}"
                                               autocomplete="off"
                                               class="form-input-phone-number input-element"
                                               id="mainPhoneNumber"
                                               name="mainPhoneNumber"
                                               placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
                                               type="tel">
                                        <span *ngIf="!validTemplate.mainPhoneNumberValid?.isValid"
                                              class="help-block">
                                            {{validTemplate.mainPhoneNumberValid?.errorMessage | translate}}
                                        </span>
                                    </div>
                                </div>
                                <!-- Alternate Phone Number -->
                                <div class="form-group">
                                    <label class="form-label m-0 form-label-client-customization" for="altPhoneNumber"
                                           translate="label.centerTab.alternatePhoneNumber"></label>
                                    <div class="position-relative phone-number-container">
                                        <div class="input-group input-group-phone-number-code">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text input-group-text-phone-number-code input-element"
                                                      [ngClass]="{'input-group-text-phone-number-code-focused' : isAlternatePhoneNumberInputFocused,
                                                      'input-element-has-error': !validTemplate.alternatePhoneNumberValid?.isValid}">+</span>
                                            </div>
                                            <input [(ngModel)]="alternatePhoneNumber.countryCode"
                                                   (ngModelChange)="onAltPhoneNumberChange(alternatePhoneNumber.phoneNumber, alternatePhoneNumber.countryCode)"
                                                   [ngClass]="{'input-element-has-error': !validTemplate.alternatePhoneNumberValid?.isValid}"
                                                   autocomplete="off"
                                                   class="input-element form-control form-input p-0"
                                                   id="altPhoneNumberCountryCode"
                                                   name="altPhoneNumberCountryCode"
                                                   (focus)="alternatePhoneNumberCountryCodeInputFocused(true)"
                                                   (blur)="alternatePhoneNumberCountryCodeInputFocused(false)"
                                                   placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                                                   type="text">
                                        </div>
                                        <input [(ngModel)]="alternatePhoneNumber.phoneNumber"
                                               (ngModelChange)="onAltPhoneNumberChange(alternatePhoneNumber.phoneNumber, alternatePhoneNumber.countryCode)"
                                               [ngClass]="{'input-element-has-error': !validTemplate.alternatePhoneNumberValid?.isValid}"
                                               autocomplete="off"
                                               class="input-element form-control form-input form-input-phone-number p-0"
                                               id="altPhoneNumber"
                                               name="altPhoneNumber"
                                               placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
                                               type="tel">
                                        <span *ngIf="!validTemplate.alternatePhoneNumberValid?.isValid"
                                              class="help-block">
                                            {{validTemplate.alternatePhoneNumberValid?.errorMessage | translate}}
                                        </span>
                                    </div>
                                </div>
                                <!-- Email -->
                                <div class="form-group">
                                    <label translate="label.email"></label>
                                    <input #email="ngModel"
                                           [(ngModel)]="centerItem.email"
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.email?.isValid}"
                                           autocomplete="off"
                                           class="input-element"
                                           name="email"
                                           placeholder="{{'label.emailPlaceholder'|translate}}"
                                           type="text">
                                    <div *ngIf="!validTemplate.email?.isValid" class="help-block">
                                        <span [ngClass]="{'has-error': !validTemplate.email?.isValid}"
                                              translate="{{validTemplate.email?.errorMessage}}">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Extra Information -->
                <mat-step *ngIf="screenTemplateLayout.action !== constants.VIEW" [completed]="false"
                          [stepControl]="getStepControl(2)">
                    <ng-template matStepLabel><span translate="label.extraInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-5">
                                <!-- logo url -->
                                <div class="form-group">
                                    <label translate="label.logoURL" class="pr-1"></label>
                                    <i class="fas fa-info-circle cursor-pointer"
                                       ngbTooltip="{{'label.fieldUsedInPatientAccessInfo'| translate}}"
                                       placement="bottom"
                                    ></i>
                                    <input [(ngModel)]='centerItem.logoUrl'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.logoUrl?.isValid}"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.urlPlaceholder' | translate"
                                           class="input-element">
                                    <span *ngIf="!validTemplate.logoUrl?.isValid"
                                          class="help-block">
                                        {{validTemplate.logoUrl?.errorMessage | translate}}
                                    </span>
                                </div>

                                <!-- website url -->
                                <div class="form-group">
                                    <label translate="label.websiteURL" class="pr-1"></label>
                                    <i class="fas fa-info-circle cursor-pointer"
                                       ngbTooltip="{{'label.fieldUsedInPatientAccessInfo'| translate}}"
                                       placement="bottom"></i>
                                    <input [(ngModel)]='centerItem.webSiteUrl'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.webSiteUrl?.isValid}"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.urlPlaceholder' | translate"
                                           class="input-element">
                                    <span *ngIf="!validTemplate.webSiteUrl?.isValid"
                                          class="help-block">
                                        {{validTemplate.webSiteUrl?.errorMessage | translate}}
                                    </span>
                                </div>

                                <!-- redirect url -->
                                <div class="form-group">
                                    <label translate="label.redirectURL" class="pr-1"></label>
                                    <i class="fas fa-info-circle cursor-pointer"
                                       ngbTooltip="{{'label.fieldUsedInPatientAccessInfo'| translate}}"
                                       placement="bottom"></i>
                                    <input [(ngModel)]='centerItem.redirectUrl'
                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                           [ngClass]="{'input-element-has-error': !validTemplate.redirectUrl?.isValid}"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [placeholder]="'label.urlPlaceholder' | translate"
                                           class="input-element">
                                    <span *ngIf="!validTemplate.redirectUrl?.isValid"
                                          class="help-block">
                                        {{validTemplate.redirectUrl?.errorMessage | translate}}
                                    </span>
                                </div>

                                <!-- Call center phone number -->
                                <div class="form-group">
                                    <label class="form-label m-0 form-label-client-customization pr-1" for="phoneNumber"
                                           translate="label.callCenterPhoneNumber"></label>
                                    <i class="fas fa-info-circle cursor-pointer"
                                       ngbTooltip="{{'label.fieldUsedInPatientAccessInfo'| translate}}"
                                       placement="bottom"></i>
                                    <div class="position-relative phone-number-container">
                                        <div class="input-group input-group-phone-number-code">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text input-group-text-phone-number-code input-element"
                                                      [ngClass]="{'input-group-text-phone-number-code-focused' : isMainPhoneNumberInputFocused,
                                                      'input-element-has-error': !validTemplate.callCenterPhoneNumberValid?.isValid}">+</span>
                                            </div>
                                            <input [(ngModel)]="callCenterPhoneNumber.countryCode"
                                                   (ngModelChange)="onCallCenterPhoneNumberChange(callCenterPhoneNumber.phoneNumber, callCenterPhoneNumber.countryCode)"
                                                   [ngClass]="{'input-element-has-error': !validTemplate.callCenterPhoneNumberValid?.isValid}"
                                                   autocomplete="off"
                                                   class="form-control form-input p-0 input-element"
                                                   id="phoneNumberCountryCode"
                                                   name="phoneNumberCountryCode"
                                                   (focus)="mainPhoneNumberCountryCodeInputFocused(true)"
                                                   (blur)="mainPhoneNumberCountryCodeInputFocused(false)"
                                                   placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                                                   type="text">
                                        </div>
                                        <input [(ngModel)]="callCenterPhoneNumber.phoneNumber"
                                               (ngModelChange)="onCallCenterPhoneNumberChange(callCenterPhoneNumber.phoneNumber, callCenterPhoneNumber.countryCode)"
                                               [ngClass]="{'input-element-has-error': !validTemplate.callCenterPhoneNumberValid?.isValid}"
                                               autocomplete="off"
                                               class="form-control form-input form-input-phone-number p-0 input-element"
                                               id="phoneNumber"
                                               name="phoneNumber"
                                               placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
                                               type="tel">
                                        <span *ngIf="!validTemplate.callCenterPhoneNumberValid?.isValid"
                                              class="help-block">
                                            {{validTemplate.callCenterPhoneNumberValid?.errorMessage | translate}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- External keys-->
                <mat-step *ngIf="screenTemplateLayout.action !== constants.VIEW" [completed]="false"
                          [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.externalKeysLabel"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="external-keys-title">
                            <label *ngIf="screenTemplateLayout.action === constants.EDIT"
                                   translate="label.externalKeysLabel"></label>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button (click)="onAddExternalKeyItem(validTemplate, centerItem)"
                                        *ngIf="screenTemplateLayout.action !== constants.VIEW"
                                        class="btn addTableRow-btn btn-block text-left py-2"
                                        type="button">
                                    <i class="fas fa-plus-circle"></i>
                                    <span class="name-button" translate="label.addTableRow"></span>
                                </button>
                                <div class="container-external-keys-table">
                                    <table [ngClass]="{'disabled-input' : screenTemplateLayout.action === constants.VIEW}"
                                           class="table mt-3 table-bordered border-0">
                                        <thead>
                                        <tr>
                                            <th class="border-0 p-0" translate="label.origin"></th>
                                            <th class="border-0 p-0" translate="label.key"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of centerItem.centerExternalKeys; let i = index">
                                            <td>
                                                <div class="form-group m-0">
                                                    <input type="text"
                                                           name="{{item.origin}}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.origin"
                                                           [ngModelOptions]="{standalone: true}"
                                                           placeholder="{{'label.origin'|translate}}"
                                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                                           [disabled]="screenTemplateLayout.action === constants.VIEW">

                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group m-0 d-flex">
                                                    <input type="text"
                                                           name="{{item.key}}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.key"
                                                           [ngModelOptions]="{standalone: true}"
                                                           (ngModelChange)="onCenterValueChanged(centerItem)"
                                                           placeholder="{{'label.key'|translate}}"
                                                           [disabled]="screenTemplateLayout.action === constants.VIEW">
                                                    <button type="button" class="btn float-right p-0 pl-2"
                                                            *ngIf="screenTemplateLayout.action !== constants.VIEW"><i
                                                            class="fas fa-times"
                                                            (click)="onRemoveExternalKeyItem(i, validTemplate, centerItem)"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div>
                            <span class="help-block"
                                  *ngIf="!validTemplate.isExternalKeysValid && centerItem.centerExternalKeys.length > 0"
                                  [ngClass]="{ 'has-error': !validTemplate.isExternalKeysValid }"
                                  translate="label.error.invalidExternalKeysTable">
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Overview-->
                <mat-step [completed]="false">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.generalInformation"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.name"></td>
                                            <td class="text-right">{{centerItem.name}}</td>
                                        </tr>
                                        <tr *ngIf="screenTemplateLayout.action !== constants.CREATE">
                                            <td translate="label.shortId"></td>
                                            <td class="text-right">{{centerItem.shortId}}</td>
                                        </tr>
                                        <tr *ngIf="screenTemplateLayout.action !== constants.CREATE">
                                            <td translate="label.id"></td>
                                            <td class="text-right">{{centerItem.id}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.country"></td>
                                            <td class="text-right">{{centerItem?.country?.countryName}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.region"></td>
                                            <td class="text-right">{{centerItem?.region?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerTab.timeZone"></td>
                                            <td class="text-right">{{centerItem.timeZoneId}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.area"></td>
                                            <td class="text-right">
                                                    <span *ngFor="let area of centerItem.areas; last as islast">
                                                        {{area.name}}<span *ngIf="!islast">, </span>
                                                    </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerTab.latitude"></td>
                                            <td class="text-right">{{centerItem.latitude}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerTab.longitude"></td>
                                            <td class="text-right">{{centerItem.longitude}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.tags"></td>
                                            <td class="text-right">
                                                    <span *ngFor="let tag of centerItem.tags; last as islast">
                                                        {{tag.name}}<span *ngIf="!islast">, </span>
                                                    </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group">
                                    <label translate="label.contactDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.locality"></td>
                                            <td class="text-right">{{centerItem?.address?.locality}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.street"></td>
                                            <td class="text-right">{{centerItem?.address?.street}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.streetNumber"></td>
                                            <td class="text-right">{{centerItem?.address?.streetNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.floor"></td>
                                            <td class="text-right">{{centerItem?.address?.floor}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.addressTemplate.apartmentNumber"></td>
                                            <td class="text-right">{{centerItem?.address.apartmentNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerTab.mainPhoneNumber"></td>
                                            <td class="text-right">{{displayOverviewPhoneNumber('main')}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerTab.alternatePhoneNumber"></td>
                                            <td class="text-right">{{displayOverviewPhoneNumber('alternate')}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.email"></td>
                                            <td class="text-right">{{centerItem?.email}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.extraInformation"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.logoURL"></td>
                                            <td class="text-right">{{centerItem.logoUrl}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.websiteURL"></td>
                                            <td class="text-right">{{centerItem.webSiteUrl}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.redirectURL"></td>
                                            <td class="text-right">{{centerItem.redirectUrl}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.callCenterPhoneNumber"></td>
                                            <td class="text-right">{{displayOverviewPhoneNumber('callCenter')}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group">
                                    <label translate="label.externalKeysLabel"></label>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <td translate="label.origin"></td>
                                            <td translate="label.key"></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of centerItem.centerExternalKeys">
                                            <td>
                                                {{item.origin}}
                                            </td>
                                            <td>
                                                {{item.key}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button (click)="goBack(stepper)" type="button"
                    [disabled]="stepper.selectedIndex === 0"
                    *ngIf="screenTemplateLayout.action !== constants.VIEW"
                    class="btn back-btn mr-3"
                    translate="button.back">
            </button>
            <button (click)="goForward(stepper)" type="button"
                    [disabled]="!areStepsValid(validTemplate, stepper.selectedIndex)"
                    *ngIf="(stepper.selectedIndex < totalStepsCount-1)"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveCenterData(centerItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'CenterUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
