import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {TimeWindowSpecialityAreaMDComponent} from './time-window-speciality-area-md.component';
import {CreateTimeWindowSpecialityAreaComponent} from './create-time-window-speciality-area/create-time-window-speciality-area.component';

@NgModule({
    declarations: [TimeWindowSpecialityAreaMDComponent, CreateTimeWindowSpecialityAreaComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class TimeWindowSpecialityAreaMDModule {
}
