import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssignSubServicesOptionsTypes} from './assign-sub-services.types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AvailabilityDataSubServiceType} from 'sked-base';
import {AssignSubServicesModalComponent} from './assign-sub-services-modal';
import {GeneralUtils} from '../../utils/general.utils';

@Component({
    selector: 'app-assign-sub-services',
    templateUrl: './assign-sub-services.component.html',
    styleUrls: ['./assign-sub-services.component.scss']
})
export class AssignSubServicesComponent implements OnInit {
    @Input() options: AssignSubServicesOptionsTypes;
    @Output() onSubServicesChanged: EventEmitter<AvailabilityDataSubServiceType[]> = new EventEmitter<AvailabilityDataSubServiceType[]>();

    constructor(public modalService: NgbModal,
                private generalUtils: GeneralUtils) {
    }

    ngOnInit(): void {
    }

    openAssignSubServicesModal() {
        const modalRef = this.modalService
            .open(AssignSubServicesModalComponent, this.generalUtils.getModalOptions());

        modalRef.componentInstance.options = this.options;

        modalRef.result.then((subServices) => {
            this.onSubServicesChanged.emit(subServices);
        }, (onClose) => {
        });
    }

}
