import {Injectable} from '@angular/core';
import {
    EntityTypeEnum,
    EventActionDependentFiltersType,
    EventActionTypeEnum,
    ExclusionEventRuleType,
    Expand,
    ODataQueryObjectType,
    ExclusionEventTypeEnum
} from 'sked-base';
import * as lodash from 'lodash';
import {
    EventActionModalFiltersType,
    EventTypeModalFilterOptionsType,
    ExclusionEventRuleModalFiltersType,
    ExclusionEventRuleStateType,
} from './exclusion-event-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class ExclusionEventRuleUtils {
    overviewState: ExclusionEventRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'exclusionEventRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.priority',
            hasSort: true,
            onSortByProperty: 'priority',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.entityType',
            hasSort: false,
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.eventType',
            hasSort: true,
            onSortByProperty: 'eventType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.eventAction',
            hasSort: true,
            onSortByProperty: 'eventAction',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'exclusionEventRuleTableId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'entityType':
                    case 'eventType':
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    case 'eventActionId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'exclusionEventRuleTable' || item === 'eventAction') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            ExclusionEventRuleTable: {select: ['Id', 'Name']},
            EventAction: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): ExclusionEventRuleType {
        return {
            exclusionEventRuleTableId: undefined,
            name: undefined,
            active: undefined,
            priority: undefined,
            entityType: EntityTypeEnum.exclusion,
            eventType: 'noValue' as ExclusionEventTypeEnum,
            eventActionId: undefined
        } as ExclusionEventRuleType;
    }

    getInitialModalFilters(): ExclusionEventRuleModalFiltersType {
        return {
            eventTypeFilterOptions: {
                ngModel: undefined
            } as EventTypeModalFilterOptionsType,
            eventActionOptions: {
                ngModel: undefined
            } as EventActionModalFiltersType,
            areFiltersActive: false,
        } as ExclusionEventRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: ExclusionEventRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getMainDependentFilters() {
        return {
            eventAction: {
                searchPhrase: '',
                active: true,
                type: EventActionTypeEnum.mSGraphCalendar,
                exclusionList: []
            } as EventActionDependentFiltersType,
        };
    }
}
