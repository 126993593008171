<div class="container-fluid resource-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.resource"></h3>
        <!-- Resource type filter -->
        <div class="filters-section d-flex no-block">
            <div class="d-flex no-block search">
                <sbase-filter class="mr-1"
                              (selectedItemsOutput)="onSelectedSpecialityFilter($event)"
                              [buttonName]="'label.speciality2'"
                              [dependentFilters]="mainDependentFilters.speciality"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.speciality"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="specialityProvider">
                </sbase-filter>
                <sbase-filter class="mr-1"
                              (selectedItemsOutput)="onSelectedServiceFilter($event)"
                              [buttonName]="'label.service2'"
                              [dependentFilters]="mainDependentFilters.service"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.service"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="serviceProvider">
                </sbase-filter>
                <sbase-filter class="mr-1"
                              (selectedItemsOutput)="onSelectedAreaFilter($event)"
                              [buttonName]="'label.area2'"
                              [dependentFilters]="mainDependentFilters.area"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.area"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="areaProvider">
                </sbase-filter>
                <sbase-filter class="mr-1"
                              (selectedItemsOutput)="onSelectedResourceTypeFilter($event)"
                              [buttonName]="'label.resourceType.title'"
                              [dependentFilters]="mainDependentFilters.resourceType"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.resourceType"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="resourceTypeProvider">
                </sbase-filter>
                <sbase-filter class="mr-1"
                              (selectedItemsOutput)="onSelectedCenterFilter($event)"
                              [buttonName]="'label.center'"
                              [dependentFilters]="mainDependentFilters.center"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.center"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="centerProvider">
                </sbase-filter>
                <sbase-filter (selectedItemsOutput)="onSelectedCoveragePlanFilter($event)"
                              [buttonName]="'label.coveragePlans'"
                              [dependentFilters]="mainDependentFilters.coveragePlan"
                              [initialFilterValues]="resourceMdUtils.initialFilterValues.coveragePlan"
                              [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="coveragePlanProvider">
                </sbase-filter>
                <button class="reset-button btn" (click)="onClearFilters()">
                    <i class="far fa-trash-alt"></i>
                    <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(resourceMdUtils.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + resourceMdUtils.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-2" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex search">
            <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
            <input type="text" class="form-control rounded-0"
                   placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                   autocomplete="off"
                   [(ngModel)]="resourceMdUtils.tableFilters.filter.Name"
                   (ngModelChange)="onSearchResourceChanged($event)">
            <button type="button" class="btn theme-alt-btn border-0 ml-1"
                    [activityDisplay]="RESOURCE+CONSTANTS.CREATE"
                    (click)="createResource()">{{'label.create'|translate}}
            </button>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('shortId')">
                    {{'label.shortId' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceMdUtils.tableFilters.orderBy.shortId"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.shortId === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.shortId === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.name' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceMdUtils.tableFilters.orderBy.name"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.name === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.name === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('type')">
                    {{'label.type' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceMdUtils.tableFilters.orderBy.type"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.type === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.type === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer" (click)="onSortBy('priority')">
                    {{'label.priority' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!resourceMdUtils.tableFilters.orderBy.priority"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.priority === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="resourceMdUtils.tableFilters.orderBy.priority === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer">
                    {{'label.service' | translate}}
                </th>
                <th class="name-column cursor-pointer">
                    {{'label.center' | translate}}
                </th>
                <th class="name-column cursor-pointer">
                    {{'label.coveragePlan' | translate}}
                </th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let resource of resourceMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewResource(resource)">
                <td class="name-column">{{resource.shortId}}</td>
                <td class="name-column">{{resource.name}}</td>
                <td class="name-column">{{resource.resourceType?.name | translate}}</td>
                <td class="name-column">{{resource.priority}}</td>
                <td class="name-column">
                    <div *ngFor="let service of resource?.services | slice: 0 : DISPLAYED_ITEMS_NUMBER">
                        <div class="max-width-table-column">
                            {{service.name}}
                        </div>
                    </div>
                    <div *ngIf="resource?.services?.length > DISPLAYED_ITEMS_NUMBER">
                        <div [ngbPopover]="moreServices"
                             class="fas fa-ellipsis-h cursor-pointer"
                             placement="bottom top"
                             popoverClass="popover-shadow"
                             (click)="$event.stopPropagation()">
                        </div>
                        <ng-template #moreServices>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let service of resource?.services"
                                     class="px-2 py-1 table-tooltip-container">
                                    {{service.name}}
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td class="name-column">
                    <div *ngFor="let center of resource?.centers | slice: 0 : DISPLAYED_ITEMS_NUMBER">
                        <div class="max-width-table-column">
                            {{center.name}}
                        </div>
                    </div>
                    <div *ngIf="resource?.centers?.length > DISPLAYED_ITEMS_NUMBER">
                        <div [ngbPopover]="moreCenters"
                             class="fas fa-ellipsis-h cursor-pointer"
                             placement="bottom top"
                             popoverClass="popover-shadow"
                             (click)="$event.stopPropagation()">
                        </div>
                        <ng-template #moreCenters>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let center of resource?.centers"
                                     class="px-2 py-1 table-tooltip-container">
                                    {{center.name}}
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td class="name-column">
                    <div *ngFor="let coveragePlan of resource?.coveragePlans | slice: 0 : DISPLAYED_ITEMS_NUMBER">
                        <div class="max-width-table-column">
                            {{coveragePlan.name}}
                        </div>
                    </div>
                    <div *ngIf="resource?.coveragePlans?.length > DISPLAYED_ITEMS_NUMBER">
                        <div [ngbPopover]="moreCoveragePlans"
                             class="fas fa-ellipsis-h cursor-pointer"
                             placement="bottom top"
                             popoverClass="popover-shadow"
                             (click)="$event.stopPropagation()">
                        </div>
                        <ng-template #moreCoveragePlans>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let coveragePlan of resource?.coveragePlans"
                                     class="px-2 py-1 table-tooltip-container">
                                    {{coveragePlan.name}}
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewResource(resource)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editResource(resource)"
                                        [activityDisplay]="RESOURCE+CONSTANTS.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(resource.id)"
                                        [activityDisplay]="RESOURCE+CONSTANTS.DELETE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(resource.id, EntityTypeEnum.resource, resource.name)"
                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="resourceMdUtils.tableFilters.currentPage"
                        [maxSize]="5"
                        [pageSize]="resourceMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{resourceMdUtils.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
