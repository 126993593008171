<div class="container-fluid create-multi-resource-blue-print-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-multi-resource-blue-print-header">
        <h3 class="create-multi-resource-blue-print-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.multiResourceBlueprintTab.title"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->
    <form name="form" novalidate (ngSubmit)="saveMultiResourceBluePrintData(multiResourceBluePrintItem)">
        <div class="create-entity-md-section">
            <!--Body-->
            <!-- =========================================== -->
            <div class="create-multi-resource-blue-print-inner-container custom-scrollbar pr-2">
                <ng-container *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                    <div class="row mt-4">
                        <div class="col-6">
                            <div class="form-group">
                                <label translate="label.generalInformation"></label>
                                <table class="table border mt-2">
                                    <tbody>
                                    <tr>
                                        <td translate="label.shortId"></td>
                                        <td class="text-right">{{multiResourceBluePrintItem.shortId}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.name"></td>
                                        <td class="text-right">{{multiResourceBluePrintItem.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.multiResourceBlueprintTab.mainResourceType"></td>
                                        <td class="text-right">{{initialFilterValues.mainResourceType[0]?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.multiResourceBlueprintTab.activateBlueprint"></td>
                                        <td class="text-right">
                                            <span *ngIf="multiResourceBluePrintItem.active"
                                                  translate="label.yes"></span>
                                            <span *ngIf="!multiResourceBluePrintItem.active"
                                                  translate="label.no"></span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label translate="label.secondaryResources"></label>
                                <table class="table border mt-2">
                                    <thead>
                                    <tr>
                                        <th translate="label.resourceType.name"></th>
                                        <th class="text-right" translate="label.numberOfResources"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let resource of secondaryResources">
                                        <td>{{resource.name}}</td>
                                        <td class="text-right">{{resource.numberOfResource}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <div class="row">
                        <div class="col-md-3 col-12" *ngIf="screenTemplateLayout.action !== CONSTANTS.CREATE">
                            <div class="form-group" [ngClass]="{'disabled-input': 'true'}">
                                <label translate="label.shortId"></label>
                                <input type="text" name="shortId"
                                       class="input-element"
                                       [(ngModel)]="multiResourceBluePrintItem.shortId">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5 col-12">
                            <div class="form-group">
                                <label translate="label.multiResourceBlueprintTab.name"></label>
                                <input type="text" name="name" class="input-element"
                                       [(ngModel)]="multiResourceBluePrintItem.name"
                                       placeholder="{{'label.multiResourceBlueprintTab.name'|translate}}"
                                       [ngClass]="{'input-element-has-error': !multiResourceBluePrintItem.name}"
                                       autocomplete="off">
                                <div *ngIf="!multiResourceBluePrintItem.name
                                 && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12">
                            <div class="form-group sbase-input-element"
                                 [ngClass]="{'disabled-input': screenTemplateLayout.action !== CONSTANTS.CREATE}">
                                <label translate="label.multiResourceBlueprintTab.mainResourceType"></label>
                                <div [ngClass]="{'disabled-class': screenTemplateLayout.action !== CONSTANTS.CREATE }">
                                    <sbase-filter (selectedItemsOutput)="onSelectedMainResourceTypeFilter($event)"
                                                  [buttonName]="'label.multiResourceBlueprintTab.mainResourceType' | translate"
                                                  [dependentFilters]="mainDependentFilters.mainResourceType"
                                                  [hideSelectedItems]="true"
                                                  [initialFilterValues]="initialFilterValues.mainResourceType"
                                                  [isMultipleSelectedItem]="false"
                                                  [providerInstance]="resourceTypeProvider"
                                                  [ngClass]="{'input-element-has-error': !multiResourceBluePrintItem.mainResourceTypeId}"
                                                  [useSelectedValueAsLabel]="true">
                                    </sbase-filter>
                                    <div *ngIf="!multiResourceBluePrintItem.mainResourceTypeId
                                     && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-12">
                            <div class="form-group">
                                <label translate="label.multiResourceBlueprintTab.activateBlueprint"></label>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked
                                               [(ngModel)]="multiResourceBluePrintItem.active"
                                               name="active">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <div class="form-group sbase-input-element"
                                 [ngClass]="{'disabled-input': screenTemplateLayout.action !== CONSTANTS.CREATE || !multiResourceBluePrintItem.mainResourceTypeId}">
                                <label translate="label.multiResourceBlueprintTab.secondaryResourceType"></label>
                                <div
                                    [ngClass]="{'disabled-class': screenTemplateLayout.action !== CONSTANTS.CREATE || !multiResourceBluePrintItem.mainResourceTypeId}">
                                    <sbase-filter (selectedItemsOutput)="onSelectedSecondaryResourceTypeFilter($event)"
                                                  [buttonName]="'label.multiResourceBlueprintTab.secondaryResourceType' | translate"
                                                  [dependentFilters]="mainDependentFilters.secondaryResourceType"
                                                  [initialFilterValues]="initialFilterValues.secondaryResourceTypes"
                                                  [additionalExclusionList]="secondaryResourceTypeExclusionList"
                                                  [isMultipleSelectedItem]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !!multiResourceBluePrintItem.mainResourceTypeId && !getSecondaryResourceTypesWithNameFromItemsList().length}"
                                                  [providerInstance]="resourceTypeProvider">
                                    </sbase-filter>
                                    <div *ngIf="!!multiResourceBluePrintItem.mainResourceTypeId
                                            && !getSecondaryResourceTypesWithNameFromItemsList().length
                                            && screenTemplateLayout.action !== CONSTANTS.VIEW"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-8 col-11">
                            <div class="flex flex-row no-block">
                                <label
                                    translate="label.multiResourceBlueprintTab.selectedSecondaryResourceType"></label>
                            </div>
                            <div class="pt-0 pb-0 flex-shrink-1 overview-table-container custom-scrollbar">
                                <ng-container>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th class="text-center">{{'label.resourceType.name' | translate}}</th>
                                            <th class="text-center">{{'label.numberOfResources' | translate}}</th>
                                            <th class="text-center" translate="label.actions"
                                                *ngIf="screenTemplateLayout.action === CONSTANTS.CREATE"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            *ngFor="let secondaryResourceType of secondaryResources, let i = index">
                                            <td class="text-center">
                                                <span>{{secondaryResourceType.name}}</span>
                                            </td>
                                            <td class="text-center">
                                                <span>{{secondaryResourceType.numberOfResource}}</span>
                                            </td>
                                            <td class="text-center"
                                                *ngIf="screenTemplateLayout.action === CONSTANTS.CREATE">
                                                <button type="button" class="btn"
                                                        title="{{'label.delete' | translate}}"
                                                        (click)="deleteSecondaryResource(i)">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--End Body-->
            <!-- =========================================== -->
            <!--Footer-->
            <!-- =========================================== -->
            <div class="mt-2 footer-position-sticky">
                <button class="btn mr-3"
                        type="button"
                        (click)="goToParentPage()"
                        [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                        translate="{{screenTemplateLayout.cancelOrBackButton}}">
                </button>
                <button class="btn theme-btn"
                        type="submit"
                        [disabled]="!isSaveButtonEnabled()"
                        [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                        translate="{{screenTemplateLayout.createOrSaveButton}}">
                </button>
                <button (click)="goToEdit()"
                        *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                        [activityDisplay]="'MultiResourceBluePrintUpdate'"
                        class="btn theme-btn"
                        type="button">
                    <span translate="label.edit"></span>
                </button>
            </div>
            <!--End Footer-->
            <!-- =========================================== -->
        </div>
    </form>
</div>
