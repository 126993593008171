import {AppointmentStatusEnum, AppointmentType} from 'sked-base';
export enum AppointmentActionEnum {
    Cancel = 'Cancel',
    Reschedule = 'Reschedule',
    Status = 'Status',
    BulletinPDF = 'BulletinPDF',
    SetPaid = 'SetPaid',
    SendEmail = 'SendEmail',
    Reuse = 'Reuse'
}

export interface AppointmentActionButtonsOptionsType {
    appointmentId: string;
    status: AppointmentStatusEnum;
    appointmentAllowedStatuses?: string[];
    cancelled: boolean;
    appointmentDate: string;
    etag: string;
    paid: boolean;
    appointment: AppointmentType;
    onActionLoaderId?: string;
}
