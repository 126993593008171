import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {AppointmentTypeAndObjectDetailsModalOptions} from '../multi-appointment-booking.types';
import {ObjectDetailsActionType} from 'sked-base/lib/components/object-details/object-details.types';

@Component({
    selector: 'app-mab-appointment-type-and-object-details-modal',
    templateUrl: './mab-appointment-type-and-object-details-modal.component.html',
    styleUrls: ['./mab-appointment-type-and-object-details-modal.component.scss']
})
export class MabAppointmentTypeAndObjectDetailsModalComponent implements OnInit {
    @Input() options: AppointmentTypeAndObjectDetailsModalOptions;
    time: NgbTimeStruct;
    isTimeValid = true;
    showAppointmentType = true;
    displayNotFoundObjectDetailsMessage: boolean;

    private timeInMinutes: number;

    constructor(public activeModal: NgbActiveModal,
                public dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        if (!this.options.numberOfSlots) {
            this.options.numberOfSlots = 1;
        }
        if (this.options.slot?.appointmentType?.id) {
            this.showAppointmentType = false;
        }
        this.displayNotFoundObjectDetailsMessage = false;
        if (this.options.specialBooking) {
            this.time = this.dateTimeUtils.hourMinuteStringToNgbTimeStruct(this.options.slot?.time);
            this.timeInMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.slot?.time);
        }
    }

    onObjectDetailsAction(objectDetailsInfo: ObjectDetailsActionType) {
        if (!objectDetailsInfo.areObjectDetailsFound) {
            this.displayNotFoundObjectDetailsMessage = true;
        }
    }

    onTimeChanged(time: NgbTimeStruct) {
        this.timeInMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.dateTimeUtils.ngbTimeStructToHourMinuteString(time));
        const startTimeMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.slot?.time);
        const endTimeMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.slot?.timeInterval.split('- ')[1]);

        this.isTimeValid =
            this.timeInMinutes >= startTimeMinutes && this.timeInMinutes <= (endTimeMinutes - this.options.slot?.appointmentType.duration);
    }

    onSaveModal() {
        // Save new date time & duration if special booking
        if (this.options.specialBooking) {
            // TODO-MAB-duration: investigate if we should do this calculation (increase duration based on number of
            //  slots), or if backend does it automatically
            this.options.slot.duration = this.options.slot?.appointmentType.duration * this.options.numberOfSlots;
            this.options.slot.baseDuration = this.options.slot?.appointmentType.duration;
            this.options.slot.time = this.dateTimeUtils.getStringHourFromMinutes(this.timeInMinutes);
            this.options.slot.dateTime = `${this.options.slot?.dateTime.substring(0, 11)}${this.options.slot?.time}:00${this.options.slot?.dateTime.substring(19, 33)}`;
        }

        this.activeModal.close(this.options);
    }

    onCloseModal() {
        this.activeModal.dismiss('cancel');
    }

    isSlotValid(): boolean {
        return this.options.slot?.appointmentType && (!this.options.specialBooking || this.isTimeValid);
    }

    sanitizeNgSelectValue() {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.options.slot.appointmentType.hasOwnProperty('$ngOptionValue')) {
            this.options.slot.appointmentType = undefined;
        }
    }
}
