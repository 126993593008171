import {FormValidationType} from 'sked-base';
import * as moment from 'moment';

export enum ActivityPlanFrequencyTypeEnum {
    weekDays = 'weekDays',
    monthDays = 'monthDays'
}

export type ActivityPlanFrequencyValue = string[] | number[];

export interface ActivityPlanServiceOverviewValidation {
    isServiceValid: boolean;
    isStartTimeValid: boolean;
}

export interface ActivityPlanFrequency {
    frequencyType: ActivityPlanFrequencyTypeEnum;
    selectedDays: ActivityPlanFrequencyValue;
    selectedWeekDays: FrequencyWeekDayType[];
    selectedMonthDays: FrequencyMonthDayType[];
}

export interface ActivityPlanServiceValidation {
    frequencyType: FormValidationType;
    frequencyDays: FormValidationType;
    interval: FormValidationType;
    count: FormValidationType;
    enqueue: FormValidationType;
    startTime: FormValidationType;
    endTime: FormValidationType;
    service: FormValidationType;
    recurrenceModal: FormValidationType;
    scheduledEndType: FormValidationType;
}

export interface FrequencyWeekDayType {
    name: string;
    selected: boolean;
}

export interface FrequencyMonthDayType {
    name: number;
    selected: boolean;
}

export interface ActivityPlanDisplayCalendarPageType {
    days: ActivityPlanDisplayCalendarDayType[];
    displayMonth: string;
    displayYear: string;
}

export interface ActivityPlanDisplayCalendarDayType {
    displayDay: string;
    hasBanner: boolean;
    enqueueDate?: string;
    isToday: boolean;
    isStartDate: boolean;
    isAfterToday: boolean;
    momentDate: moment.Moment;
    stringDate: string;
}

export interface DaysWithBannerType {
    targetDate: string; // 'YYYY-MM-DD'
    enqueueDate: string; // 'YYYY-MM-DD'
}
