import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {AppointmentTypeProvider, AppointmentTypeType, ConfirmDeleteModalService, EntityTypeEnum} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AppointmentTypeMdUtils} from './appointment-type-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-appointment-type-md',
    templateUrl: './appointment-type-md.component.html',
    styleUrls: ['./appointment-type-md.component.scss']
})
export class AppointmentTypeMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[];
    appointmentTypeMdList: any = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    public AppointmentType: string;
    public constants = constants;
    private searchAppointmentTypeChanged = new Subject<string>();
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public appointmentTypeMdUtils: AppointmentTypeMdUtils,
        public appointmentTypeProvider: AppointmentTypeProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('AppointmentTypeRead');
        this.AppointmentType = 'AppointmentType';
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
        // If not coming from route createAppointmentType, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/createAppointmentType') {
            this.loadInitialTableFilters();
        }
        this.getAppointmentTypeMdData();
        this.subscribeSearchByAppointmentTypeChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create AppointmentType
    createAppointmentType(): void {
        this.router.navigate(['/createAppointmentType']);
    }

    editAppointmentType(appointmentType: AppointmentTypeType) {
        this.router.navigate(['/createAppointmentType'], {state: {appointmentType, action: constants.EDIT}});
    }

    viewAppointmentType(appointmentType: AppointmentTypeType) {
        this.router.navigate(['/createAppointmentType'], {state: {appointmentType, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.appointmentTypeMdUtils.tableFilters.currentPage = page;
        this.getAppointmentTypeMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.appointmentTypeMdUtils.tableFilters.currentPage = 1;
        this.appointmentTypeMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getAppointmentTypeMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.appointmentTypeMdUtils.tableFilters.orderBy[property];
        if (this.appointmentTypeMdUtils.tableFilters.orderBy) {
            this.appointmentTypeMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getAppointmentTypeMdData(false);
    }

    onSearchAppointmentTypeChanged(value: string) {
        this.searchAppointmentTypeChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.appointmentTypeMdUtils.tableFilters.orderBy[selectedItem];
        this.getAppointmentTypeMdData(false);
    }

    displayConfirmDeleteItemModal(appointmentTypeId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.appointmentTypeProvider, appointmentTypeId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(appointmentTypeId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchAppointmentTypeChanged('');
            this.loadInitialTableFilters();
            this.getAppointmentTypeMdData();
        });
    }

    // function to get AppointmentType data
    private getAppointmentTypeMdData(includeCount: boolean = true) {
        const queryFilter = this.appointmentTypeMdUtils.getQueryFilterForAppointmentTypeMD(this.appointmentTypeMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.appointmentTypeProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.appointmentTypeMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.appointmentTypeMdUtils.tableFilters = this.appointmentTypeMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.appointmentTypeProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getAppointmentTypeMdData();
                this.messagesService.success('toastr.success.appointmentTypeDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByAppointmentTypeChanged() {
        const self = this;
        this.searchAppointmentTypeChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.appointmentTypeMdUtils.tableFilters.filter.name = searchValue;
                self.getAppointmentTypeMdData();
            });
    }

    private loadInitialTableFilters(): void {
        this.appointmentTypeMdUtils.tableFilters = this.appointmentTypeMdUtils.getInitialTableFilter();
    }
}
