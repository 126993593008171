<div class="create-patient-overview-wrapper custom-scrollbar">
    <label translate="label.patientInfo" class="create-patient-overview-label"></label>
    <table class="table border">
        <tbody>
        <tr>
            <td class="col-6">
                <span>{{'label.documentType' | translate}},&nbsp;</span>
                <span>{{'label.documentNumber' | translate}},&nbsp;</span>
                <span>{{'label.country' | translate}}</span>
            </td>
            <td class="text-right col-6" *ngIf="!patient?.noDocument">
                <span *ngIf="patient?.documentType">{{'enum.documentType.' + patient?.documentType | translate}}
                    ,&nbsp;</span>
                <span *ngIf="patient?.documentNumber">{{patient?.documentNumber}},&nbsp;</span>
                <span *ngIf="patient?.documentCountry">{{patient?.documentCountry | translate}}</span>
            </td>
            <td class="text-right col-6" *ngIf="patient?.noDocument">
                <span>{{'label.noDocument' | translate}}</span>
            </td>
        </tr>
        <tr *ngIf="patient?.fullName">
            <td class="col-6">
                <span>{{'label.fullName' | translate}}</span>
            </td>
            <td class="text-right col-6">
                <span>{{patient?.fullName}}</span>
            </td>
        </tr>
        <tr *ngIf="patient?.gender || patient?.birthDate">
            <td class="col-6">
                <span *ngIf="patient?.gender">{{'label.gender' | translate}}</span>
                <span *ngIf="patient?.birthDate">,&nbsp;</span>
                <span *ngIf="patient?.birthDate">{{'label.birthDate' | translate}}</span>
            </td>
            <td class="text-right col-6">
                <span *ngIf="patient?.gender">{{'enum.gender.' + patient?.gender | translate}}</span>
                <span *ngIf="patient?.birthDate">,&nbsp;</span>
                <span *ngIf="patient?.birthDate">{{patient?.birthDate | fullDateNoTimeFormatPipe}}</span>
            </td>
        </tr>
        </tbody>
    </table>
    <label translate="label.contact" class="create-patient-overview-label"></label>
    <table class="table border">
        <tbody>
        <tr *ngIf="generalUtils.isAnyValueTruthy(
        [patient?.country, patient?.region, patient?.address?.locality, patient?.address?.street, patient?.address?.streetNumber, patient?.address?.floor, patient?.address?.apartmentNumber, patient?.address?.zipCode])">
            <td class="col-6">
                <span>{{'label.address' | translate}}</span>
            </td>
            <td class="text-right col-6">
                <span>
                    {{patientDashboardUtils.displayPatientAddress(patient)}}
                </span>
            </td>
        </tr>
        <tr *ngIf="patient?.mainPhoneNumber || patient?.alternatePhoneNumber">
            <td class="col-6">
                <span *ngIf="patient?.mainPhoneNumber">{{'label.phone' | translate}}</span>
                <span *ngIf="patient?.mainPhoneNumber && patient?.alternatePhoneNumber">,&nbsp;</span>
                <span *ngIf="patient?.alternatePhoneNumber">{{'label.alternatePhoneNumber' | translate}}</span>
            </td>
            <td class="text-right col-6">
                <span *ngIf="patient?.mainPhoneNumber">{{patient?.mainPhoneNumber}}</span>
                <span *ngIf="patient?.alternatePhoneNumber">,&nbsp;</span>
                <span *ngIf="patient?.alternatePhoneNumber">{{patient?.alternatePhoneNumber}}</span>
            </td>
        </tr>
        <tr *ngIf="patient?.email">
            <td class="col-6">
                <span>{{'label.email' | translate}}</span>
            </td>
            <td class="text-right col-6">
                <span>{{patient?.email}}</span>
            </td>
        </tr>
        </tbody>
    </table>
    <label translate="label.additionalInfo" class="create-patient-overview-label"
           *ngIf="displayAdditionalInfoLabel"></label>
    <div>
        <table class="table border">
            <tbody>
            <tr *ngIf="patientCoveragePlans?.mainCoveragePlan">
                <td class="col-6">
                    <span>{{'label.mainCoveragePlan' | translate}}</span>
                </td>
                <td class="text-right col-6">
                    <span>{{patientCoveragePlans?.mainCoveragePlan}}</span>
                </td>
            </tr>
            <tr *ngIf="patientCoveragePlans?.secondaryCoveragePlans?.length > 0">
                <td class="col-6">
                    <span>{{'label.secondaryCoveragePlans' | translate}}</span>
                </td>
                <td class="text-right col-6">
                <span *ngFor="let coveragePlan of patientCoveragePlans?.secondaryCoveragePlans; last as islast">
                    <span>{{coveragePlan}}</span>
                    <span *ngIf="!islast">, </span>
                </span>
                </td>
            </tr>
            <tr *ngIf="patient?.tags?.length > 0 && !createPatientUtils.isTimeDependentTagsActive()"
                [featureDisplay]="'patient-tags'"
                [activityDisplay]="'PatientTagsManager'">
                <td class="col-6">
                    <span>{{'label.tags' | translate}}</span>
                </td>
                <td class="text-right col-6">
                <span *ngFor="let tag of patient?.tags; last as islast">
                    <span>{{tag?.name}}</span>
                    <span *ngIf="!islast">, </span>
                </span>
                </td>
            </tr>
            <tr *ngIf="patientClientAgreements?.onlineAccount || patientClientAgreements?.whatsappNotifications">
                <td class="border col-6">
                    <span>{{'label.onlineAccount' | translate}}</span>
                    <span class="float-right">
                   {{patientClientAgreements?.onlineAccount ? ('label.yes' | translate) : ('label.no' | translate)}}
                </span>
                </td>
                <td class="border text-left col-6">
                    <span>{{'label.whatsAppNotifications' | translate}}</span>
                    <span class="float-right">
                   {{patientClientAgreements?.whatsappNotifications ? ('label.yes' | translate) : ('label.no' | translate)}}
                </span>
                </td>
            </tr>
            <tr *ngIf="patient?.remarks">
                <td class="col-6">
                    <span>{{'label.specialComments' | translate}}</span>
                </td>
                <td class="text-right col-6">{{patient?.remarks}}</td>
            </tr>
            <tr *ngIf="patient?.id">
                <td class="col-6">
                    <span>{{'label.id' | translate}}</span>
                </td>
                <td class="text-right col-6">{{patient?.id}}</td>
            </tr>
            </tbody>
        </table>
        <label *ngIf="patient?.patientExternalKeys?.length > 0"
               class="create-patient-overview-label"
               translate="label.externalKeysLabel">
        </label>
        <table class="table table-bordered" *ngIf="patient?.patientExternalKeys?.length > 0">
            <tbody>
            <tr>
                <td translate="label.origin" class="col-6"></td>
                <td translate="label.key" class="col-6"></td>
            </tr>
            <tr *ngFor="let externalKey of patient?.patientExternalKeys">
                <td class="col-6">
                    {{externalKey.origin}}
                </td>
                <td class="col-6">
                    {{externalKey.key}}
                </td>
            </tr>
            </tbody>
        </table>
        <label *ngIf="createPatientUtils?.patientControlExceptions?.length > 0 && createPatientUtils.isCreatePatientExceptionsActive()"
               class="create-patient-overview-label"
               translate="label.exceptions">
        </label>
        <table class="table table-bordered" *ngIf="createPatientUtils?.patientControlExceptions?.length > 0 && createPatientUtils.isCreatePatientExceptionsActive()">
            <thead class="thead-light">
            <tr class="font-weight-bold">
                <td translate="label.service2" [style.width]="'28%'"></td>
                <td translate="label.speciality2" [style.width]="'28%'"></td>
                <td translate="label.resource" [style.width]="'28%'"></td>
                <td translate="label.validTo" [style.width]="'16%'"></td>
            </tr>
            </thead>
            <tbody *ngFor="let exception of createPatientUtils.patientControlExceptions; let index = index">
            <tr>
                <td [style.width]="'30%'">
                    <span class="font-weight-bold">{{exception?.service?.specialityName}} </span>
                    <span>{{exception?.service?.name}}</span>
                </td>
                <td [style.width]="'30%'">
                    <span>{{exception?.specialty?.name}}</span>
                </td>
                <td [style.width]="'30%'">
                    <span>{{exception?.resource?.name}}</span>
                </td>
                <td [style.width]="'10%'">
                    <span>{{exception?.validTo | dateMMDDYYYYBrowserTzFormat}}</span>
                </td>
            </tr>
            </tbody>
        </table>
        <label *ngIf="createPatientUtils?.patientTimeDependentTags?.length > 0 && createPatientUtils.isTimeDependentTagsActive()"
               class="create-patient-overview-label"
               translate="label.tags">
        </label>
        <table class="table table-bordered" *ngIf="createPatientUtils?.patientTimeDependentTags?.length > 0 && createPatientUtils.isTimeDependentTagsActive()">
            <thead class="thead-light">
            <tr class="font-weight-bold">
                <td translate="label.tag2" [style.width]="'28%'"></td>
                <td translate="label.dateTimeFrom" [style.width]="'28%'"></td>
                <td translate="label.dateTimeTo" [style.width]="'28%'"></td>
                <td translate="label.numberOfDays" [style.width]="'16%'"></td>
            </tr>
            </thead>
            <tbody *ngFor="let patientTimeDependentTag of createPatientUtils.patientTimeDependentTags; let index = index">
            <tr>
                <td [style.width]="'30%'">
                    <span>{{patientTimeDependentTag?.name}}</span>
                </td>
                <td [style.width]="'30%'">
                    <span>{{patientTimeDependentTag?.dateTimeFrom | dateMMDDYYYYBrowserTzFormat}}</span>
                </td>
                <td [style.width]="'30%'">
                    <span>{{patientTimeDependentTag?.dateTimeTo | dateMMDDYYYYBrowserTzFormat}}</span>
                </td>
                <td [style.width]="'10%'">
                    <span>{{patientTimeDependentTag?.numberOfDays}}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
