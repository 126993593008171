<div class="container-fluid create-coverage-plan-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-coverage-plan-header">
        <h3 class="create-coverage-plan-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.coveragePlan2"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0">
            <mat-horizontal-stepper labelPosition="bottom"
                                    (selectionChange)="onStepChange($event)" #stepper
                                    [linear]="screenTemplateLayout.action === constants.EDIT ? isStepperLinear(screenTemplateValidator) : true">
                <!--STEP 1: GENERAL-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="areStepsValid(screenTemplateValidator, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-4">
                                <!-- Coverage Plan Name-->
                                <div class="form-group">
                                    <label translate="label.name"></label>
                                    <input type="text" placeholder="{{'label.coveragePlanName'|translate}}"
                                           [(ngModel)]="coveragePlanItem.name"
                                           (ngModelChange)="onCoveragePlanValueChanged(coveragePlanItem)"
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           [ngClass]="{'input-element-has-error': !screenTemplateValidator.isNameValid}"
                                           class="input-element" required>
                                    <div *ngIf="!screenTemplateValidator.isNameValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!--Priority-->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input [(ngModel)]='coveragePlanItem.priority'
                                           [disabled]="screenTemplateLayout.action===constants.VIEW"
                                           (ngModelChange)="onCoveragePlanValueChanged(coveragePlanItem)"
                                           [ngClass]="{'input-element-has-error': !screenTemplateValidator.isPriorityValid}"
                                           placeholder="{{'label.priority'|translate}}"
                                           type="number"
                                           min="1"
                                           class="input-element" required>
                                    <div *ngIf="!screenTemplateValidator.isPriorityValid && (coveragePlanItem.priority === null || coveragePlanItem.priority === undefined)"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!screenTemplateValidator.isPriorityValid && coveragePlanItem.priority && (coveragePlanItem.priority < 1 || coveragePlanItem.priority > 200)"
                                         class="help-block">
                                        <span> {{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:200}' }}</span>
                                    </div>
                                </div>

                                <!--Type-->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.type"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            autofocus
                                            dropdownPosition="bottom"
                                            [(ngModel)]="coveragePlanItem.type"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [disabled]="screenTemplateLayout.action===constants.VIEW || (screenTemplateLayout.action===constants.EDIT && coveragePlanItem.isPrivate)"
                                            (change)="onCoveragePlanValueChanged(coveragePlanItem)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let type of coveragePlanTypes"
                                                   [value]="type">
                                            {{'label.' + type | translate}}
                                        </ng-option>
                                    </ng-select>
                                </div>

                                <!--Tags-->
                                <div class="form-group position-relative customSelect"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.tags"></label>
                                    <div>
                                        <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                                      [buttonName]='"label.newTag"'
                                                      [dependentFilters]="mainDependentFilters?.tags"
                                                      [initialFilterValues]="coveragePlanItem.tags"
                                                      [isMultipleSelectedItem]="true"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="tagProvider">
                                        </sbase-filter>
                                    </div>
                                </div>
                            </div>

                            <div class="col-4">
                                <!--Coverage Company-->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.coverageCompany2"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            autofocus
                                            dropdownPosition="bottom"
                                            placeholder="{{coverageCompanyPlaceholder | translate}}"
                                            [(ngModel)]="coveragePlanItem.coverageCompany"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngClass]="{'input-element-has-error': !screenTemplateValidator.isCoverageCompanyValid}"
                                            [disabled]="screenTemplateLayout.action===constants.VIEW"
                                            (change)="onCoveragePlanValueChanged(coveragePlanItem)"
                                            (focus)="onCoverageCompanySearch()"
                                            (blur)="onCoverageCompanyBlur()">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let coverageCompany of coverageCompanies"
                                                   [value]="coverageCompany">
                                            {{coverageCompany.name}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!screenTemplateValidator.isCoverageCompanyValid" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>

                                <!--Main Coverage Plan-->
                                <div class="form-group">
                                    <label translate="label.mainCoveragePlan"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="coveragePlanItem.mainCoveragePlan"
                                                   [disabled]="screenTemplateLayout.action===constants.VIEW">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                                <!--Private Coverage Plan-->
                                <div class="form-group" [ngClass]="{'disabled-input': true}">
                                    <label translate="label.isPrivate"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="coveragePlanItem.isPrivate">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                                <!--System Coverage Plan-->
                                <div class="form-group">
                                    <label translate="label.system"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="coveragePlanItem.system"
                                                   [disabled]="screenTemplateLayout.action===constants.VIEW">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-4">
                                <!--Code-->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.code"></label>
                                    <input type="text"
                                           name="code"
                                           class="input-element"
                                           [(ngModel)]="coveragePlanItem.code"
                                           placeholder="{{'label.code'|translate}}">
                                </div>

                                <!--Channel Exclusions-->
                                <div class="form-group">
                                    <label translate="label.channelExclusions"></label>
                                    <div class="d-flex justify-content-between pr-5">
                                        <div>
                                            <label class="checkbox d-flex align-items-center"
                                                   *ngFor="let channel of channels">
                                                <input type="checkbox" name="channelExclusions"
                                                       [disabled]="screenTemplateLayout.action===constants.VIEW"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [checked]="channel.selected"
                                                       [(ngModel)]="channel.selected"
                                                       (ngModelChange)="onChannelExclusionsChanged(channel)"/>
                                                <span>{{('label.channels.' + channel.enumValue) | translate}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--STEP 2: SERVICES-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="areStepsValid(screenTemplateValidator, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.services"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="form-group col-4">
                                <label>{{numberOfSelectedServices}} {{"label.assignedServices" | translate | lowercase}}</label>
                            </div>

                            <div class="form-group">
                                <label translate="label.showOnlyAssigned"></label>
                            </div>
                            <div class="form-group">
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked
                                               [(ngModel)]="showOnlyAssigned"
                                               (ngModelChange)="onShowOnlyAssignedServices(showOnlyAssigned)">
                                        <span class="lever switch-col-black"></span>
                                    </label>
                                </div>
                            </div>
                            <table class="service-table table table-sm table-hover">
                                <thead>
                                <tr>
                                    <th scope="col" style="width: 6%"></th>
                                    <th scope="col" style="width: 47%" translate="label.serviceTab.speciality"></th>
                                    <th scope="col" style="width: 47%" translate="label.serviceTab.service"></th>
                                </tr>
                                <tr>
                                    <th scope="col" style="width: 6%">
                                        <input type="checkbox"
                                               name="selectAll"
                                               class="selectAll-checkbox"
                                               [ngModelOptions]="{standalone: true}"
                                               [checked]="selectAll"
                                               [(ngModel)]="selectAll"
                                               (ngModelChange)="onSelectAll(selectAll)"/>
                                    </th>
                                    <th scope="col" style="width: 47%">
                                        <sbase-filter (selectedItemsOutput)="onSelectedSpecialityFilter($event)"
                                                      [buttonName]=specialityButtonName
                                                      [dependentFilters]="mainDependentFilters.speciality"
                                                      [initialFilterValues]="initialSpecialityValues"
                                                      [isMultipleSelectedItem]="false"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="specialityProvider">
                                        </sbase-filter>
                                    </th>
                                    <th scope="col" style="width: 47%" class="services-filter">
                                        <sbase-filter (selectedItemsOutput)="onSelectedServiceFilter($event)"
                                                      [buttonName]=serviceButtonName
                                                      [dependentFilters]="mainDependentFilters.service"
                                                      [initialFilterValues]="initialServiceValues"
                                                      [isMultipleSelectedItem]="false"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="serviceProvider">
                                        </sbase-filter>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let service of services; index as i"
                                    [ngClass]="{'selected': service.selected}"
                                    (click)="onSelectSingle(service, $event)">
                                    <td>
                                        <input type="checkbox"
                                               name="service"
                                               class="service-checkbox"
                                               [checked]="service.selected"/>
                                    </td>
                                    <td>{{service.speciality?.name}}</td>
                                    <td>{{ service.name }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="pagination-component mt-3" *ngIf="totalServices > 0">
                                <ngb-pagination [collectionSize]="totalServices"
                                                [(page)]="serviceTableFilters.currentPage"
                                                [maxSize]="5"
                                                [pageSize]="serviceTableFilters.itemsPerPage"
                                                [boundaryLinks]="true"
                                                (pageChange)="onChangePagination($event)">
                                </ngb-pagination>

                                <div class="btn-group items-per-page-group">
                                    <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
                                    <span class="btn-group ml-2">
                                        <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                                              aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                                              [ngClass]="{'show': showItemsPerPageDropdown}">
                                            <strong>{{serviceTableFilters.itemsPerPage}}</strong>
                                        </span>
                                        <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                                            (clickOutside)=onClickedOutsideItemsPerPageFilter($event)
                                            class="dropdown-menu dropdown-menu-item"
                                             x-placement="top-start" x-placement="bottom-start">
                                            <a *ngFor="let page of servicesPerPageList" class="dropdown-item"
                                                (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--STEP 3: EXTERNAL KEYS-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="areStepsValid(screenTemplateValidator, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.externalKeysLabel"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn addTableRow-btn btn-block text-left py-2"
                                        (click)="onAddExternalKeyItem()"
                                        *ngIf="screenTemplateLayout.action!==constants.VIEW">
                                    <i class="fas fa-plus-circle"></i>
                                    <span translate="label.addTableRow" class="name-button"></span>
                                </button>
                                <div class="container-external-keys-table">
                                    <table class="table mt-3 table-bordered border-0"
                                           [ngClass]="{'disabled-input' : screenTemplateLayout.action===constants.VIEW}">
                                        <thead>
                                        <tr>
                                            <th class="border-0 p-0" translate="label.origin"></th>
                                            <th class="border-0 p-0" translate="label.key"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of coveragePlanItem.coveragePlanExternalKeys; let i = index">
                                            <td>
                                                <div class="form-group m-0">
                                                    <input type="text" name="{{item.origin}}" autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.origin"
                                                           [ngModelOptions]="{standalone: true}"
                                                           placeholder="{{'label.origin'|translate}}"
                                                           [disabled]="screenTemplateLayout.action===constants.VIEW">

                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group m-0 d-flex">
                                                    <input type="text"
                                                           name="{{item.key}}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.key"
                                                           [ngModelOptions]="{standalone: true}"
                                                           placeholder="{{'label.key'|translate}}"
                                                           [disabled]="screenTemplateLayout.action===constants.VIEW">
                                                    <button type="button" class="btn float-right p-0 pl-2"
                                                            *ngIf="screenTemplateLayout.action!==constants.VIEW"><i
                                                            class="fas fa-times"
                                                            (click)="onRemoveExternalKeyItem(i)"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--STEP 4: OVERVIEW-->
                <mat-step [completed]="false"
                          [stepControl]="areStepsValid(screenTemplateValidator, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <table class="table border service-details">
                                    <tbody>
                                    <tr *ngIf="coveragePlanItem?.name">
                                        <td translate="label.name"></td>
                                        <td class="text-right">{{coveragePlanItem.name}}</td>
                                    </tr>
                                    <tr *ngIf="screenTemplateLayout.action !== constants.ADD">
                                        <td translate="label.shortId"></td>
                                        <td class="text-right">{{coveragePlanItem.shortId}}</td>
                                    </tr>
                                    <tr *ngIf="screenTemplateLayout.action !== constants.ADD">
                                        <td translate="label.id"></td>
                                        <td class="text-right">{{coveragePlanItem.id}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.coverageCompany2"></td>
                                        <td class="text-right">
                                            <span *ngIf="coveragePlanItem.coverageCompany">{{coveragePlanItem.coverageCompany.name}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate="label.priority"></td>
                                        <td class="text-right">{{coveragePlanItem.priority}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.type"></td>
                                        <td class="text-right" *ngIf="!generalUtils.isSelectedNoValueOption(coveragePlanItem.type)">{{'label.' + coveragePlanItem.type | translate}}</td>
                                        <td class="text-right" *ngIf="generalUtils.isSelectedNoValueOption(coveragePlanItem.type)"></td>
                                    </tr>
                                    <tr>
                                        <td translate="label.mainCoveragePlan"></td>
                                        <td class="text-right">{{ coveragePlanItem.mainCoveragePlan |yesNoPipe | translate}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.isPrivate"></td>
                                        <td class="text-right">{{ coveragePlanItem.isPrivate |yesNoPipe | translate}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.system"></td>
                                        <td class="text-right">{{ coveragePlanItem.system |yesNoPipe | translate}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.channelExclusions"></td>
                                        <td class="text-right">
                                            <span *ngFor="let channel of coveragePlanItem.channelRestrictions; last as islast">
                                                  {{('label.channels.' + channel.enumValue) | translate}}<span *ngIf="!islast">, </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate="label.code"></td>
                                        <td class="text-right">
                                            <span>{{coveragePlanItem.code}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate="label.tags"></td>
                                        <td class="text-right">
                                            <span *ngFor="let tag of tags; last as islast">
                                                  {{tag.name}}<span *ngIf="!islast">, </span>
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6">
                                <div class="form-group"
                                     *ngIf="coveragePlanItem.services && coveragePlanItem.services.length>0">
                                    <label translate="label.services"></label>
                                    <table class="table border service-details">
                                        <tbody>
                                        <tr>
                                            <td translate="label.assignedServices"></td>
                                            <td class="text-right">
                                                <span>{{coveragePlanItem.services.length}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group"
                                     *ngIf="coveragePlanItem.coveragePlanExternalKeys && coveragePlanItem.coveragePlanExternalKeys.length>0">
                                    <label translate="label.externalKeysLabel"></label>
                                    <table class="table table-bordered service-details">
                                        <thead>
                                        <tr>
                                            <td translate="label.origin"></td>
                                            <td translate="label.key"></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of coveragePlanItem.coveragePlanExternalKeys">
                                            <td>
                                                {{item.origin}}
                                            </td>
                                            <td>
                                                {{item.key}}
                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-step>

            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button (click)="goBack(stepper)" type="button" [disabled]="stepper.selectedIndex === 0"
                    *ngIf="screenTemplateLayout.action !== constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="goForward(stepper)"
                    *ngIf="displayNext(stepper, totalStepsCount)" type="button"
                    [disabled]="!areStepsValid(screenTemplateValidator, stepper.selectedIndex)"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button class="btn theme-btn"
                    (click)="saveCoveragePlanData(coveragePlanItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'CoveragePlanUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
