import {Component, OnInit} from '@angular/core';
import {RuleTypeEnum} from 'sked-base';
import {RulesUtils} from '../rules.utils';
import {Router} from '@angular/router';

@Component({
    selector: 'app-create-rule-outlet',
    templateUrl: './create-rule-outlet.component.html',
    styleUrls: ['./create-rule-outlet.component.scss']
})
export class CreateRuleOutletComponent implements OnInit {
    ruleTypeEnum = RuleTypeEnum;

    constructor(
        public rulesUtils: RulesUtils,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        if (!this.rulesUtils.selectedRule) {
            this.rulesUtils.goToParentPage();
        }
    }

}
