<div class="modal-container">
    <div class="modal-header">
        <h6 class="modal-title font-weight-bold" translate="label.additionalInformation"></h6>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [style.grid-template-rows]="resources?.length > 1 ? '20rem' : 'max-content'">
        <div *ngIf="resources?.length > 1" class="tabs-container">
            <span *ngFor="let resource of resources"
                  (click)="scrollToElement(resource?.name)"
                  id="{{'nav-' + resource?.name}}"
                  class="tab text-medium-contrast cursor-pointer">
                {{resource?.name}}
            </span>
        </div>
        <div class="cards-container custom-scrollbar"
             [style.grid-column]="resources?.length > 1 ? '2/3' : '1/3'"
             (scroll)="setTabsActiveClass()">
            <div *ngFor="let resource of resources"
                 id="{{resource?.name}}" class="card">
                <div *ngIf="resource?.name" class="bg-light card-name text-high-contrast">
                    <span>{{resource?.name}}</span>
                    <span *ngIf="resource.isDirectlyBookable">
                        {{' - ' + ('label.mainResource' | translate)}}
                    </span>
                </div>
                <div class="card-content">
                    <div *ngIf="resource?.additionalInformation"
                         class="card-content-icon-container">
                        <i class="fa-light fa-circle-info card-content-icon"></i>
                        <span class="text-medium-contrast">{{resource?.additionalInformation}}</span>
                    </div>
                    <span *ngIf="resource?.description" class="text-medium-contrast"
                          [innerHTML]="resource?.description"></span>
                    <span *ngIf="!resource?.additionalInformation && !resource?.description && !resource?.externalUrlForDescription"
                          class="text-high-contrast"
                          translate="label.noAdditionaInfoToDisplay"></span>
                    <button *ngIf="resource?.externalUrlForDescription"
                            class="card-content-external-link-btn btn theme-btn"
                            translate="label.readMore"
                            (click)="goToExternalUrl(resource?.externalUrlForDescription)">
                        <i class="fas fa-external-link-alt ml-3"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn close-btn"
                (click)="closeModal()"
                translate="label.cancel">
        </button>
    </div>
</div>
