import {RUAppointmentsType} from 'sked-base';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';
import {ResourceUtilizationFiltersOptionsType} from './resource-utilization-filters/resource-utilization-filters.types';
import {ResourceUtilizationAppointmentListOptionsType} from './resource-appointment-list/resource-appointment-list.types';
import {ResourceUtilizationCalendarOptionsType} from './resource-utilization-calendar/resource-utilization-calendar.types';

export interface ResourceUtilizationStateType {
    appointments?: RUAppointmentsType[];
    resources?: IdNameType[];
    filtersOptions?: ResourceUtilizationFiltersOptionsType;
    appointmentsListOptions?: ResourceUtilizationAppointmentListOptionsType;
    calendarOptions?: ResourceUtilizationCalendarOptionsType;
}

export enum ResourceUtilizationStateComponentsEnum {
    filtersOptions = 'filtersOptions',
    calendarOptions = 'calendarOptions',
    appointmentsListOptions = 'appointmentsListOptions'
}