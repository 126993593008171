<div class="container-fluid availability-overview-screen overview-screen-md pb-1">
    <div class="justify-content-between">
        <div class="row">
            <div class="col-4">
                <h3 translate="{{screenTemplateLayout.pageTitle}}"></h3>
            </div>
            <div class="col-8">
                <div class="d-flex availability-filters float-right" id="availability-filters-container">
                    <sbase-filter-wrapper
                            (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"
                            [options]="filterWrapperOptions"
                            [updateFiltersValue]="updateFiltersValue"
                            class="d-flex my-1"></sbase-filter-wrapper>
                    <!-- Status Filter -->
                    <div class="d-none" id="availability-filters-to-move" *ngIf="displayAvailabilityFiltersToMove">
                        <mat-form-field appearance="outline"
                                        class="form-control p-0 m-0">
                            <mat-select (selectionChange)="onAvailabilityStatusFilterChange($event)"
                                        [(value)]="availabilityStatusFilterInitialValue"
                                        matNativeControl
                                        disableOptionCentering
                                        required>
                                <mat-option [value]="'undefined'"><span translate="label.status"></span>
                                </mat-option>
                                <mat-option *ngFor="let status of availabilityStatusesList"
                                            [value]="status"><span>{{'label.' + status|translate}}</span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="d-inline-block my-1 ml-2 mr-1">
                        <app-show-modal-filters
                                *ngIf="!!modalFilters"
                                (onFiltersSelected)="onModalFiltersSelected($event)"
                                [options]="modalFilters"
                        ></app-show-modal-filters>
                    </div>

                    <button class="reset-button btn" (click)="onClearFilters()">
                        <i class="far fa-trash-alt"></i>
                        <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
                    </button>
                </div>
            </div>
        </div>

    </div>
<!--Since we don't have multi select on filters we decided to hide this just on this page for now--->
    <div class="tags-and-new-item-section d-flex justify-content-between mt-3" *ngIf="false">
        <!-- Tags Component -->
        <!-- ============================================================== -->
        <div class="d-flex">
            <sbase-tags (onDeleteTagOutput)="onDeleteTag($event)"
                        *ngIf="(tagsList?.length > 0)"
                        [tagsList]="tagsList"></sbase-tags>
        </div>
        <!-- ============================================================== -->
        <!-- End Tags Component -->

    </div>
    <div *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
         class="d-flex float-right">
        <button (click)="createAvailability()" [activityDisplay]="AVAILABILITY+constants.CREATE"
                class="btn theme-alt-btn border-0"
                type="button"
                translate="label.createNew">
        </button>
    </div>

    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort">
            <span translate="label.activeSorting" class="text-high-contrast"></span>:
            <span class="text-info font-weight-high-contrast">
                <span *ngFor="let key of objectKeys(tableFilters.orderBy)">
                    <span *ngIf="key === 'resource'" translate="label.resourceCenter"></span>
                    <span *ngIf="key && key !== 'resource'">{{'label.' + key |translate}}</span> - {{'label.' + tableFilters.orderBy[key] | translate}}
                    <span class="mr-1">
                        <i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex search mr-2">
            <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
            <input [(ngModel)]="tableFilters.filter.shortId" (ngModelChange)="onSearchByChanged($event)"
                   min="1" autocomplete="off" class="form-control rounded-0 w-auto d-inline"
                   placeholder="{{'label.searchBy'|translate}} {{'label.shortId'|translate}}" type="number">
        </div>
    </div>
    <div class="table-responsive overview-table-container availability-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th (click)="onSortBy('shortId')" class="id-column cursor-pointer">
                    {{'label.shortId' | translate}}
                    <button *ngIf="!tableFilters.orderBy.shortId" class="btn p-0" type="button">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy.shortId === 'asc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy.shortId === 'desc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th (click)="onSortBy('resource')" class="name-column cursor-pointer" translate="label.name">
                    <button *ngIf="!tableFilters.orderBy['resource']" class="btn p-0" type="button">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy['resource'] === 'asc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-down"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy['resource'] === 'desc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="services-duration-column" translate="label.servicesAndDuration"></th>
                <th class="working-hours-column" translate="label.timeSlots"></th>
                <th (click)="onSortBy('validFrom')" class="starting-column cursor-pointer" translate="label.validFrom">
                    <button *ngIf="!tableFilters.orderBy.validFrom" class="btn p-0" type="button">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy.validFrom === 'asc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-down"></i></button>
                    <button *ngIf="tableFilters.orderBy.validFrom === 'desc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th (click)="onSortBy('validTo')" class="ending-column cursor-pointer" translate="label.validTo">
                    <button *ngIf="!tableFilters.orderBy.validTo" class="btn p-0" type="button">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy.validTo === 'asc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-down"></i></button>
                    <button *ngIf="tableFilters.orderBy.validTo === 'desc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th (click)="onSortBy('status')" class="status-column cursor-pointer" translate="label.status">
                    <button *ngIf="!tableFilters.orderBy.status" class="btn p-0" type="button">
                        <i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tableFilters.orderBy.status === 'asc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-down"></i></button>
                    <button *ngIf="tableFilters.orderBy.status === 'desc'" class="btn p-0" type="button">
                        <i class="fas fa-sort-up"></i>
                    </button>
                </th>
                <th class="repetition-column" translate="label.repetition"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}" *ngFor="let availability of availabilityList"
                (click)="viewAvailability(availability.id, availability.status)" sbaseMarkTableRowOnHover
            >
                <td class="id-column">{{availability.shortId}}</td>
                <td class="name-column">
                    <div class="font-bold">{{availability?.resource?.name}}</div>
                    <div>{{availability?.center?.name}}</div>
                </td>
                <td class="services-duration-column">
                    <div *ngFor="let service of availability?.services | slice: 0 : DISPLAYED_SERVICES_NUMBER">
                        <div class="max-width-table-column">
                            <span class="font-bold">
                                {{service.specialityName}}
                            </span>
                            {{service.name}}
                            <span *ngIf="service.multiResourceBluePrintId"
                                  ngbTooltip="{{'label.blueprint' | translate}}"
                                  class="mr-1">
                                 <i class="fa-regular fa-feather-pointed"></i>
                            </span>
                            <span *ngIf="service?.isMainResourceInBluePrint">| {{service?.duration}}
                                <span class="mr-1"
                                      translate="label.availabilityTab.minuteShort">
                                </span>
                            </span>
                            <span *ngIf="!service?.isMainResourceInBluePrint"
                                  class="mr-1">
                                <i class="far fa-clock"
                                   ngbTooltip="{{'label.noDurationSecondaryResource'| translate}}"></i>
                            </span>
                            <span *ngIf="service?.hasSubServices"
                                         class="mr-1">
                                <i class="fas fa-cog"
                                   ngbTooltip="{{'label.hasSubServices'| translate}}"></i>
                            </span>
                            <span *ngIf="service?.onlineConsultation"
                                class="mr-1">
                                <i class="fas fa-desktop"
                                   ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="availability?.services?.length > DISPLAYED_SERVICES_NUMBER">
                        <div [ngbPopover]="moreServices"
                             class="fas fa-ellipsis-h cursor-pointer"
                             placement="bottom top"
                             popoverClass="popover-shadow"
                             (click)="$event.stopPropagation()">
                        </div>
                        <ng-template #moreServices>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let service of availability?.services"
                                     class="px-2 py-1 table-tooltip-container">
                                    <span class="font-bold">{{service.specialityName}}</span> {{service.name}}
                                    <span *ngIf="service.multiResourceBluePrintId"
                                          ngbTooltip="{{'label.blueprint' | translate}}"
                                          class="mr-1">
                                        <i class="fa-regular fa-feather-pointed"></i>
                                    </span>
                                    <span *ngIf="service?.isMainResourceInBluePrint"> | {{service.duration}}
                                        <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>
                                            </span>
                                    <span *ngIf="!service?.isMainResourceInBluePrint"
                                          class="mr-1">
                                                <i class="far fa-clock"
                                                   ngbTooltip="{{'label.noDurationSecondaryResource'| translate}}"></i>
                                            </span>
                                    <i *ngIf="service.hasSubServices" class="fas fa-cog"
                                       ngbTooltip="{{'label.hasSubServices'| translate}}">
                                    </i>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td class="working-hours-column">
                      <span *ngFor="let timeSlot of availability?.timeSlots">
                          <span class="mr-1">{{timeSlot.hourFrom | hourFromMinutes}} - {{timeSlot.hourTo | hourFromMinutes}}</span>
                          <span *ngIf="timeSlot.monday" class="mr-1" translate="label.mon"></span>
                          <span *ngIf="timeSlot.tuesday" class="mr-1" translate="label.tu"></span>
                          <span *ngIf="timeSlot.wednesday" class="mr-1" translate="label.we"></span>
                          <span *ngIf="timeSlot.thursday" class="mr-1" translate="label.th"></span>
                          <span *ngIf="timeSlot.friday" class="mr-1" translate="label.fr"></span>
                          <span *ngIf="timeSlot.saturday" class="mr-1" translate="label.sa"></span>
                          <span *ngIf="timeSlot.sunday" class="mr-1" translate="label.su"></span>
                          <i *ngIf="availability.onTheFly" class="far fa-external-link-square-alt"
                             ngbTooltip="{{'label.onTheFly'| translate}}">
                          </i><br/>
                      </span>
                </td>
                <td class="starting-column">{{availability.validFrom | fullDateNoTimeFormatPipe}}</td>
                <td *ngIf=!availability.endOfDay class="ending-column">{{availability.validTo | fullDateNoTimeFormatPipe}}</td>
                <td *ngIf=availability.endOfDay class="ending-column">{{availability.validTo | dateEndOfDay}}
                    <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoAvailability'| translate}}"></i>
                </td>
                <td class="status-column">{{'label.' + availability.status | translate}}</td>
                <td class="repetition-column">{{availability.repetition}}
                    <span class="text-lowercase">
                        {{'label.' + (availability.repetition === 1 ? 'week2' : 'weeks') | translate}}
                    </span>
                </td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <div class="d-flex flex-row justify-content-center w-100">
                        <button class="btn" (click)="copyAvailability(availability.id)"
                                *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
                                [activityDisplay]="AVAILABILITY+constants.CREATE"
                                ngbTooltip="{{'label.copy'| translate}}">
                            <i class="fa-regular fa-copy"></i>
                        </button>
                        <button class="btn" (click)="editAvailability(availability.id)"
                                *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
                                [activityDisplay]="AVAILABILITY+constants.UPDATE"
                                ngbTooltip="{{'label.edit'| translate}}"
                                [disabled]="availability.onTheFly || availability.status !== AvailabilityStatusEnum.APPROVED || dateTimeUtils.isExpiredDate(availability.validTo)">
                            <i class="fa-regular fa-pen"></i>
                        </button>
                        <button [ngbPopover]="popContent" class="btn" placement="auto" popoverClass="popover-shadow"
                                type="button">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
                                    [ngClass]="(availability.status !== 'Approved') ? 'disabled-button': ''">
                                    <button (click)="deleteAvailability(availability.id)"
                                            [activityDisplay]="AVAILABILITY+constants.DELETE" class="btn"
                                            translate="label.delete"
                                            type="button"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
                                    [ngClass]="(availability.onTheFly || availability.status === 'Edited') ? 'disabled-button': ''">
                                    <button (click)="historyAvailability(availability.id)"
                                            [activityDisplay]="AVAILABILITY+constants.HISTORY" class="btn"
                                            translate="label.history"
                                            type="button"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview"
                                    [ngClass]="{'disabled-button': (availability.onTheFly || availability.status !== 'Approved' ||
                                                                    dateTimeUtils.isExpiredDate(availability.validTo))}">
                                    <!--activityDisplay is CREATE because split is basically a create-->
                                    <button (click)="splitAvailability(availability)"
                                            [activityDisplay]="AVAILABILITY+constants.CREATE" class="btn"
                                            translate="label.split"
                                            type="button"
                                    ></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.ApproveAvailabilityOverview ||
                                    (this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview &&
                                        (availability.status === AvailabilityStatusEnum.NEW || availability.status === AvailabilityStatusEnum.EDITED || availability.status === AvailabilityStatusEnum.MARKED_FOR_DELETE))">
                                    <button (click)="approveOrDeclineAvailability(availability.id, constants.APPROVE)"
                                            [activityDisplay]="AVAILABILITY+constants.APPROVE" class="btn"
                                            translate="label.availabilityTab.approve"
                                            type="button">
                                    </button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.ApproveAvailabilityOverview ||
                                (this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview &&
                                    (availability.status === AvailabilityStatusEnum.NEW || availability.status === AvailabilityStatusEnum.EDITED || availability.status === AvailabilityStatusEnum.MARKED_FOR_DELETE))">
                                    <button (click)="approveOrDeclineAvailability(availability.id, constants.DECLINE)"
                                            [activityDisplay]="AVAILABILITY+constants.APPROVE" class="btn"
                                            translate="label.availabilityTab.decline"
                                            type="button"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview
                                            || ( this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.ApproveAvailabilityOverview
                                                && availability.status !== AvailabilityStatusEnum.NEW)">
                                    <button (click)="blockingSimulationAvailability(availability.id)"
                                            [activityDisplay]="AVAILABILITY+constants.BLOCKING_SIMULATION" class="btn"
                                            translate="label.blockingSimulation"
                                            type="button"></button>
                                </li>
                                <li *ngIf="this.screenTemplateLayout.viewType===AvailabilityViewPageEnum.AvailabilityOverview">
                                    <button (click)="oversellingDefinitionAvailability(availability)"
                                            [activityDisplay]="'OversellingDefinitionRead'" class="btn"
                                            translate="label.oversellingDefinition"
                                            type="button"></button>
                                </li>
                                <li>
                                    <button (click)="viewAvailability(availability.id, availability.status)"
                                            [activityDisplay]="AVAILABILITY+constants.READ" class="btn"
                                            translate="label.view"
                                            type="button"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="tableFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{tableFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>

