import {Injectable} from '@angular/core';
import {
    AppointmentPriceFromExternalServiceFilterType,
    AppointmentTypeType, FormValidationType,
    ODataQueryObjectType, ReservationProvider,
    SlotSecondaryResourceType,
    SlotsFilterNameEnum,
    SlotsFiltersSelectValueType,
    SlotsUtils, SubServiceType, Validations
} from 'sked-base';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {
    SlotDisplayType,
    SlotSearchAppointmentTypesType,
    SlotSearchRequestFilterType,
    SlotsExtraDetailsType,
    SlotType
} from 'sked-base/lib/data-model/slotTypes';
import {
    MabAppointmentFormDataValidationType,
    MabAppointmentInformationFormData, MabAppointmentOptionsType, MabAppointmentsInformationOptionsType,
    MabSlotDisplayType,
    MabSlotListOptionsType,
    MultiAppointmentBookingCalendarDayType,
    MultiAppointmentBookingCalendarDayTypeEnum,
    MultiAppointmentBookingCalendarOptionsType,
    MultiAppointmentBookingCalendarPageType,
    MultiAppointmentBookingCalendarSelectedDayType,
    MultiAppointmentBookingReservationType,
    MultiAppointmentBookingReservedSlotsOptionsType,
    MultiAppointmentBookingSlotsListWrapperOptionsType,
    MultiAppointmentBookingStateType,
    SlotsLocalFiltersOptionsType
} from './multi-appointment-booking.types';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {PatientPhoneNumberType, PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {MessagesService} from '../../shared/services/messages.service';
import {catchError, take} from 'rxjs/operators';
import {
    CreateMabReservationsResourceItemType,
    MultiAppointmentBookingReservationObjectType,
    ReservationType
} from 'sked-base/lib/data-model/reservationTypes';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {
    ObjectDetailsModalComponent
} from '../../shared/component/appointment-object-details/object-details-modal/object-details-modal.component';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ServiceType} from 'sked-base/lib/data-model/serviceTypes';
import {CenterType} from 'sked-base/lib/data-model/centerTypes';
import {ResourceType} from 'sked-base/lib/data-model/resourceTypes';
import {
    InputValidationResultType,
    ProcessedTenantCustomizingGroupedByControlNameType
} from '../../data-model/tenant-customizing.type';
import {TenantCustomizingService} from '../../shared/services/tenant-customizing.service';
import {Observable, of} from 'rxjs';
import {SlotsManagementCalendarDayType} from '../slots-management/slots-management.types';

@Injectable({
    providedIn: 'root'
})
export class MultiAppointmentBookingMdUtils {
    multiAppointmentBookingState: MultiAppointmentBookingStateType = {};

    // The options for the calendar
    slotsCalendarOptions: MultiAppointmentBookingCalendarOptionsType;
    isCalendarCollapsed = false;
    searchesAreDoneForMoreThanOnePage = false;

    // The options for local filters
    slotsLocalFiltersOptions: SlotsLocalFiltersOptionsType = this.getInitialSlotsLocalFiltersOptions();

    // The options for the slots lists wrapper
    slotsListsWrapperOptions: MultiAppointmentBookingSlotsListWrapperOptionsType;
    // This is the initial value for slotsResultsSliceUpperBound, and its step: every time the user
    // scrolls down enough, INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND new items are rendered.
    INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND = 50;

    // The options for reserved slots
    reservedSlotsOptions: MultiAppointmentBookingReservedSlotsOptionsType = this.getInitialReservedSlotsOptions();

    // Default values for search time windows
    // These variables are updated in the multi-appointment-booking component with values from system config
    mabTimeWindowMaximum = 84;
    mabSearchStep = 28;
    timeWindowInDaysBasedOnSearch: number;
    previousTimeWindowInDaysBasedOnSearch: number;
    enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek: number;
    // Used when the last calendar page is filled exactly with enabled (searched) days. In other words, when
    // all days in current page are searched, and the user clicks on the next page, we use this variable
    // to navigate to the next page AFTER the search for the next page is done
    shouldNavigateOnNextCalendarAfterContinueSearch: boolean;

    // Other system config variables
    maximumMultiAppointmentBookings: number;
    mabReservationLifeSpanInMinutes: number;

    // Booking appointments options
    bookAppointmentListOptions: MabAppointmentOptionsType[];
    appointmentInformationOptions: MabAppointmentsInformationOptionsType = {} as MabAppointmentsInformationOptionsType;
    appointmentInformationOverviewOptions: MabAppointmentsInformationOptionsType = {} as MabAppointmentsInformationOptionsType;
    bookingSuccessful: boolean;
    bookAppointmentsError: {index: number, message: string} = undefined;

    constructor(
        private slotsUtils: SlotsUtils,
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
        private reservationProvider: ReservationProvider,
        private patientContextService: PatientContextService,
        private ngxLoader: NgxUiLoaderService,
        private generalUtils: GeneralUtils,
        private modalService: NgbModal,
        private tenantCustomizingService: TenantCustomizingService,
        private validations: Validations,
    ) {
    }

    // region Slots Lists Methods

    // Use this method to retrieve the reference of a slot from the slots lists wrapper
    getSlotRefInSlotsList(listId: number, slot: MabSlotDisplayType): MabSlotDisplayType {
        if (!!listId && this.slotsListsWrapperOptions?.slotsListsOptions?.length > 0) {
            const foundList = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
            if (!!foundList) {
                return lodash.find(
                    foundList.displaySlots,
                    (slotInList) => this.areSlotsTheSame(slotInList, slot)
                );
            }
        }
        return undefined;
    }

    // This method is called in order to add / remove hovered / marked design on the slot in the slot lists
    setHoverOrMarkedToSlotInSlotsList(listId: number, slot: MabSlotDisplayType, {hovered, marked}: {hovered?: boolean, marked?: boolean}): void {
        const foundSlot = this.getSlotRefInSlotsList(listId, slot);
        if (foundSlot) {
            if (hovered !== undefined) {
                foundSlot.hovered = hovered;
            }
            if (marked !== undefined) {
                foundSlot.marked = marked;
            }
        }
    }

    // This method is called in order to add / remove hovered design on the slot lists header (service name)
    setHoverToSlotListHeader(listId: number, {hovered}: {hovered?: boolean}): void {
        const foundList = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
        if (foundList) {
            if (hovered !== undefined) {
                foundList.hovered = hovered;
            }
        }
    }

    addListToSlotsListsWrapperAndReturnListId(
        expertBookingMode: boolean,
        specialAppointmentBooking: boolean,
        slotsExtraDetails: SlotsExtraDetailsType,
        rawSlots: SlotType[],
        groupedSlots: { [key in string]?: SlotType[] },
        noSlotsFound: boolean,
        wasResourceUsedForSearch: boolean,
        numberOfSlots: number,
        specialityName: string,
        serviceName: string,
        filterValues: SlotsFiltersSelectValueType[]
    ): number {
        if (!this.slotsListsWrapperOptions) {
            return;
        }
        const newList = {
            label: `${specialityName}, ${serviceName}`,
            translateLabel: false,
            expertBookingMode,
            specialAppointmentBooking,
            slotsExtraDetails,
            rawSlots,
            groupedSlots,
            noSlotsFound,
            wasResourceUsedForSearch,
            listId: this.slotsListsWrapperOptions.nextId,
            slotsResultsSliceUpperBound: this.INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND,
            numberOfSlots,
            specialityName,
            filterValues
        } as MabSlotListOptionsType;
        if (this.slotsListsWrapperOptions.isEmpty) {
            this.slotsListsWrapperOptions.slotsListsOptions = [];
        }
        this.slotsListsWrapperOptions.slotsListsOptions = [
            ...this.slotsListsWrapperOptions.slotsListsOptions,
            newList,
        ];
        this.slotsListsWrapperOptions.isEmpty = false;
        this.slotsListsWrapperOptions.nextId++;
        return newList.listId;
    }

    // endregion Slots Lists Methods

    // region Reserved Slots Methods

    // Use this method to retrieve the reference of a slot from the slots reserved slots list
    getSlotRefInReservedSlots(listId: number, slot: MabSlotDisplayType): MultiAppointmentBookingReservationType {
        if (!!listId && this.slotsListsWrapperOptions?.slotsListsOptions?.length > 0) {
            return lodash.find(
                this.reservedSlotsOptions?.displayedReservations ?? [],
                (reservedSlot) => reservedSlot.listId === listId && this.areSlotsTheSame(reservedSlot.slot, slot)
            );
        }
        return undefined;
    }

    // This method is called in order to add / remove hovered / marked design on the slot in the reserved slots
    setHoverToReservedSlot(listId: number, slot: MabSlotDisplayType, {hovered}: {hovered?: boolean}): void {
        const foundSlot = this.getSlotRefInReservedSlots(listId, slot);
        if (foundSlot) {
            if (hovered !== undefined) {
                foundSlot.hovered = hovered;
            }
        }
    }

    addReservedSlot(slot: MabSlotDisplayType, listId: number, specialBooking: boolean = false) {
        if (!this.reservedSlotsOptions) {
            return;
        }

        // Check patient is in context (need patient in order to make reservation)
        if (!this.patientContextService?.patient?.id) {
            this.messagesService.error('toastr.error.patientNotSelected', true);
            return;
        }

        // Check the maximum number of reservations
        if (this.reservedSlotsOptions.reservations?.length >= this.maximumMultiAppointmentBookings) {
            this.messagesService.error('label.removeAReservationBeforeAddingAnother', true);
            return;
        }

        // Check that the reservation isn't already in the lists
        const foundReservation = lodash.find(this.reservedSlotsOptions.reservations, (reservation: MultiAppointmentBookingReservationType) => {
            return reservation?.listId === listId && this.areSlotsTheSame(reservation?.slot, slot);
        });
        if (foundReservation) {
            this.messagesService.error('label.slotAlreadyReserved', true);
            return;
        }

        // Do request to create the reservation
        this.ngxLoader.start();
        const mabReservationObject: MultiAppointmentBookingReservationObjectType = this.getMabReservationObject(slot, listId);
        this.getCreateReservationObservable(mabReservationObject, specialBooking)
            .pipe(take(1)).subscribe((response: {value: ReservationType[]}) => {
                const mainResourceReservation = lodash.find(response?.value ?? [], {mainResource: true});
                const reservationId = specialBooking ? 'specialBooking' : mainResourceReservation?.id;

                // Double check objects are initialized correctly
                if (!this.reservedSlotsOptions.reservations?.length) {
                    this.reservedSlotsOptions.reservations = [];
                    this.reservedSlotsOptions.displayedReservations = [];
                }

                // Set style for slot list wrapper slot
                let specialityName: string;
                this.setHoverOrMarkedToSlotInSlotsList(listId, slot, {marked: true});
                // Set hasReservation to true
                const foundSlotsList = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
                if (foundSlotsList) {
                    foundSlotsList.hasReservation = true;
                    specialityName = foundSlotsList.specialityName;
                }

                // Add the reservation to display
                const newReservation = {
                    slot, listId, reservationId, specialityName
                } as MultiAppointmentBookingReservationType;
                this.reservedSlotsOptions.reservations.push(newReservation);
                this.reservedSlotsOptions.displayedReservations.push(newReservation);
                this.reservedSlotsOptions.displayedReservations = lodash.sortBy(
                    this.reservedSlotsOptions.displayedReservations,
                    (reservation: MultiAppointmentBookingReservationType) => reservation?.slot?.dateTime
                );

                // Adjust state
                this.multiAppointmentBookingState.reservedSlotsOptions = lodash.cloneDeep(this.reservedSlotsOptions);
                this.multiAppointmentBookingState.slotsListsWrapperOptions = lodash.cloneDeep(this.slotsListsWrapperOptions);

                this.ngxLoader.stop();
        }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
        });
    }

    removeReservedSlot(slot: MabSlotDisplayType, listId: number, event?: MouseEvent) {
        // Stop propagation so click on parent is not emitted
        event?.stopPropagation();

        if (!this.reservedSlotsOptions) {
            return;
        }

        const reservationList: MultiAppointmentBookingReservationType[] = lodash.filter(
            this.reservedSlotsOptions.reservations,
            (reservation: MultiAppointmentBookingReservationType) =>
                reservation.listId === listId && this.areSlotsTheSame(reservation?.slot, slot)
        );

        // Check there is only one reservation for the given slot and list id that will be deleted
        if (!reservationList?.length || reservationList?.length !== 1 || !reservationList[0] || !reservationList[0]?.reservationId) {
            this.messagesService.error('label.reservationDoesNotExist', true);
            return;
        }

        // Do request to cancel the reservation
        this.ngxLoader.start();
        const reservationId = reservationList[0].reservationId;
        this.getDeleteReservationObservable(reservationId).pipe(
            catchError((error) => {
                return of({});
            }), take(1)).subscribe((response) => {

            // Remove the reservation from lists
            this.reservedSlotsOptions.reservations = lodash.filter(
                this.reservedSlotsOptions.reservations,
                (reservation: MultiAppointmentBookingReservationType) =>
                    reservation.listId !== listId || !this.areSlotsTheSame(reservation?.slot, slot)
            );
            this.reservedSlotsOptions.displayedReservations = lodash.filter(
                this.reservedSlotsOptions.displayedReservations,
                (reservation: MultiAppointmentBookingReservationType) =>
                    reservation.listId !== listId || !this.areSlotsTheSame(reservation?.slot, slot)
            );

            // Adjust list slot style
            this.setHoverToSlotListHeader(listId, {hovered: false});
            this.setHoverOrMarkedToSlotInSlotsList(listId, slot, {marked: false, hovered: false});
            // Set hasReservation to false
            const foundSlotsList = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
            if (foundSlotsList) {
                foundSlotsList.hasReservation = false;
            }

            // Adjust state
            this.multiAppointmentBookingState.reservedSlotsOptions = lodash.cloneDeep(this.reservedSlotsOptions);
            this.multiAppointmentBookingState.slotsListsWrapperOptions = lodash.cloneDeep(this.slotsListsWrapperOptions);

            this.ngxLoader.stop();
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    areSlotsTheSame(slot1: MabSlotDisplayType, slot2: MabSlotDisplayType): boolean {
        return slot1?.availabilityId === slot2?.availabilityId
            && slot1?.dateTime === slot2?.dateTime
            && slot1?.center?.id === slot2?.center?.id
            && slot1?.resource?.id === slot2?.resource?.id;
    }

    getMabReservationObject(slot: MabSlotDisplayType, listId: number): MultiAppointmentBookingReservationObjectType {
        const secondaryResources = slot.secondaryResources?.length > 0
            ? slot.secondaryResources?.map((resource: SlotSecondaryResourceType) => ({
                resourceId: resource.id,
                isMainResource: resource.isMainResource
            }))
            : [];
        const resources: CreateMabReservationsResourceItemType[] = [
            {
                resourceId: slot?.resource?.id,
                isMainResource: true,
            } as CreateMabReservationsResourceItemType,
            ...secondaryResources,
        ];
        const listOptions = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});

        return {
            dateFrom: slot.dateTime,
            dateTo: moment.parseZone(slot.dateTime).add(slot.duration, 'minutes').format(),
            serviceId: slot.service?.id ?? null,
            subServices: slot.subServices?.map((subService: SubServiceType) => subService.id) ?? [],
            centerId: slot.center?.id ?? null,
            patientId: this.patientContextService?.patient?.id ?? null,
            patientCoveragePlanId: slot.coveragePlan?.id ?? null,
            appointmentTypeId: slot.appointmentType?.id ?? null,
            resources,
            numberOfSlots: listOptions?.numberOfSlots ?? 1,
            searchWithoutPlannedCapacity: listOptions?.specialAppointmentBooking ?? false,
        } as MultiAppointmentBookingReservationObjectType;
    }

    getCreateReservationObservable(
        mabReservationObject: MultiAppointmentBookingReservationObjectType, specialBooking: boolean
    ): Observable<{ value: ReservationType[]; }> {
        if (specialBooking) {
            // In case of special booking, reservations are not supported yet
            return of({value: []});
        }
        return this.reservationProvider.createMultiAppointmentBookingReservations(mabReservationObject);
    }

    getDeleteReservationObservable(reservationId: string): Observable<any> {
        if (reservationId === 'specialBooking') {
            return of({});
        }
        return this.reservationProvider.deleteEntryByGroupId(reservationId);
    }

    // endregion Reserved Slots Methods

    // region Booking Methods

    getPriceFromExternalServiceFilter(slot: SlotDisplayType, patient: PatientType): AppointmentPriceFromExternalServiceFilterType {
        return {
            patientId: patient.id,
            serviceId: slot.serviceId ?? slot.service?.id,
            resourceId: slot.resourceId ?? slot.resource?.id,
            centerId: slot.centerId ?? slot.center?.id,
            coveragePlanId: slot.coveragePlanId ?? slot.coveragePlan?.id,
            appointmentDate: slot.dateTime,
        } as AppointmentPriceFromExternalServiceFilterType;
    }

    getMabAppointmentInformationFormData(
        patient: PatientType, appointmentListOptions?: MabAppointmentOptionsType[]
    ): MabAppointmentInformationFormData {
        const appointmentInformationFormData: MabAppointmentInformationFormData = {} as MabAppointmentInformationFormData;

        appointmentInformationFormData.mainPhoneNumber = patient?.mainPhoneNumber ?
            patient.mainPhoneNumber : '';
        appointmentInformationFormData.alternatePhoneNumber = patient?.alternatePhoneNumber ?
            patient.alternatePhoneNumber : '';
        appointmentInformationFormData.email = patient?.email ? patient.email : '';

        const hasComments = appointmentListOptions.some(
            (appointmentOptions: MabAppointmentOptionsType) => appointmentOptions?.appointment?.comments?.length > 0
        );
        if (hasComments) {
            const comments = appointmentListOptions.filter(
                (appointmentOptions: MabAppointmentOptionsType) => appointmentOptions?.appointment?.comments?.length > 0
            ).map(
                (appointmentOptions: MabAppointmentOptionsType) => appointmentOptions?.appointment?.comments[0]
            );
            if (comments?.length > 0) {
                appointmentInformationFormData.comment = comments[0]?.content;
            }
        }

        return appointmentInformationFormData;
    }

    getMabAppointmentInformationFormDataValidation(
        appointmentInformationFormData: MabAppointmentInformationFormData,
        tenantCustomizingData: ProcessedTenantCustomizingGroupedByControlNameType,
        mainPhoneNumber: PatientPhoneNumberType,
        alternatePhoneNumber: PatientPhoneNumberType
    ): MabAppointmentFormDataValidationType {
        return {
            email: this.tenantCustomizingService['getValidationResult'](
                appointmentInformationFormData.email, 'Email', tenantCustomizingData
            ),
            mainPhoneNumber: this.getPhoneNumberFormDataValidation(
                mainPhoneNumber, tenantCustomizingData, 'MainPhoneNumber'
            ),
            alternatePhoneNumber: this.getPhoneNumberFormDataValidation(
                alternatePhoneNumber, tenantCustomizingData, 'AlternatePhoneNumber'
            ),
            price: {isValid: true, message: ''} as InputValidationResultType,
        };
    }

    getPhoneNumberFormDataValidation(
        { phoneNumber, countryCode }: PatientPhoneNumberType,
        tenantCustomizingData: ProcessedTenantCustomizingGroupedByControlNameType,
        propertyName: string
    ): InputValidationResultType {
        const isMainPhoneNumberRequired = this.tenantCustomizingService.isRequired(propertyName, tenantCustomizingData);
        const phoneNumberValidation: FormValidationType = this.validations
            .getValidatePhoneNumberLibPhone(phoneNumber ?? '', isMainPhoneNumberRequired, countryCode ?? '');
        return {
            isValid: phoneNumberValidation.isValid,
            message: phoneNumberValidation.errorMessage,
        } as InputValidationResultType;
    }

    // endregion Booking Methods

    // region Continue Searching

    addCalendarDaysForContinueSearching() {
        // Enable the previously disabled days within the new search period
        if (this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28 !== 0) {
            for (let dayIndex = this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28; dayIndex < 28; dayIndex += 1) {
                this.slotsCalendarOptions.calendarPages[this.slotsCalendarOptions.numberOfPages - 1].calendarDays[dayIndex].isDisabled = false;
            }
        }

        // Update number of enabled days
        const previousNumberOfPages = this.slotsCalendarOptions?.calendarPages?.length ?? 2;
        const newNumberOfPages = this.getNumberOfPagesForCalendar();

        // Add more pages and days if needed
        let calendarDays: MultiAppointmentBookingCalendarDayType[] = [];
        // moment().day(x) returns the x week day starting from the current week
        // More details here: https://momentjs.com/docs/#/get-set/day/
        for (let momentDayParameter = previousNumberOfPages * 28 + 1; momentDayParameter <= newNumberOfPages * 28; momentDayParameter += 1) {
            const momentDate = moment().day(momentDayParameter);
            // Create and save the day
            const day = {
                momentDate, stringDate: momentDate.format('YYYY-MM-DD'), displayDay: momentDate.format('D')
            } as MultiAppointmentBookingCalendarDayType;
            calendarDays.push(day);
            if (momentDayParameter % 28 === 0) {
                // Create and save page
                const page = {
                    calendarDays
                } as MultiAppointmentBookingCalendarPageType;
                this.slotsCalendarOptions.calendarPages.push(page);
                this.slotsCalendarOptions.numberOfPages += 1;
                // Reset variables
                calendarDays = [];
            }
        }

        // Disable all days after the search period
        this.disableAllDaysAfterSearch(this.slotsCalendarOptions);
        // // Load number of slots for each day & options
        // this.slotsCalendarOptions = this.loadNumberOfSlotsPerDay(this.slotsCalendarOptions);
        // // Load calendar day types (Normal, Special, Partial) - basically colors
        // this.slotsCalendarOptions = this.loadCalendarDayColors(this.slotsCalendarOptions);
        // Load the calendar options in the state
        this.multiAppointmentBookingState.slotsCalendarOptions = lodash.cloneDeep(this.slotsCalendarOptions);
    }

    // regionend

    openObjectDetailsModal(slot: MabSlotDisplayType): void {
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'appointment-object-details';

        const modalRef = this.modalService.open(ObjectDetailsModalComponent, modalOptions);
        modalRef.componentInstance.options = this.getObjectDetailsModalOptionsObject(slot);
        modalRef.result.then(() => {}, () => {});
    }

    getObjectDetailsModalOptionsObject(slot: MabSlotDisplayType): ObjectDetailsOptionsType {
        return {
            messagesService: this.messagesService,
            displayBackButton: false,
            service: slot.service as ServiceType,
            center: slot.center as any as CenterType,
            resource: slot.resource as any as ResourceType,
            subServices: slot.subServices as SubServiceType[],
            hideButtons: true,
            hideVerticalLine: true,
        } as ObjectDetailsOptionsType;
    }

    getQueryForServiceDuration(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'DefaultDuration']
        } as ODataQueryObjectType;
    }

    getQueryForAppointmentTypes(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name'],
            filter: {ConsumesPlannedCapacity: {eq: true}},
            orderBy: ['Name asc'],
            count: true
        } as ODataQueryObjectType;
    }

    getQueryForCenterTimezone(centerId: string): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'TimezoneId'],
            filter: {Id: {eq: {type: 'guid', value: centerId}}}
        } as ODataQueryObjectType;
    }

    getSlotForOutsideBooking(dateTime: string,
                             duration: number,
                             appointmentTypes: AppointmentTypeType[],
                             filterValues: SlotsFiltersSelectValueType[]): SlotDisplayType {
        const serviceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Service})?.value;
        const coveragePlanFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum['Coverage Plan']})?.value;
        const centerFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value;
        const resourceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Resource})?.value;
        return {
            date: moment(dateTime).format('YYYY-MM-DD'),
            time: moment(dateTime).format('HH:mm'),
            dateTime,
            duration,
            baseDuration: duration,
            resource: resourceFilterValue,
            service: serviceFilterValue?.id ? serviceFilterValue : serviceFilterValue?.service,
            coveragePlan: coveragePlanFilterValue,
            center: centerFilterValue,
            availabilityId: null,
            oversellingDefinitionId: null,
            subServices: serviceFilterValue?.id ? [] : serviceFilterValue?.subServices,
            appointmentType: this.slotsLocalFiltersOptions?.selectedAppointmentType,
            appointmentTypes: appointmentTypes?.length > 0
                ? appointmentTypes as SlotSearchAppointmentTypesType
                : this.slotsLocalFiltersOptions?.appointmentTypes
        } as SlotDisplayType;
    }

    getQueryFilterForSlotSearch(filterValues: SlotsFiltersSelectValueType[], patient: PatientType, searchesAreDoneForMoreThanOnePage: boolean = false): SlotSearchRequestFilterType {
        const queryFilter: SlotSearchRequestFilterType = {} as SlotSearchRequestFilterType;
        if (this.previousTimeWindowInDaysBasedOnSearch === this.timeWindowInDaysBasedOnSearch || searchesAreDoneForMoreThanOnePage) {
            queryFilter.dateFrom = moment().format();
            queryFilter.dateTo = moment().add(this.timeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
        } else {
            queryFilter.dateFrom = moment().add(this.previousTimeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
            queryFilter.dateTo = moment().add(this.timeWindowInDaysBasedOnSearch, 'days').startOf('d').format();
        }
        queryFilter.coveragePlanId = lodash.find(filterValues, {name: SlotsFilterNameEnum['Coverage Plan']})?.value?.id;
        const serviceFilterValue = lodash.find(filterValues, {name: SlotsFilterNameEnum.Service});
        if (!!serviceFilterValue?.value?.service?.id) {
            queryFilter.serviceId = serviceFilterValue?.value?.service?.id;
            if (!!serviceFilterValue?.value?.subServices) {
                // @ts-ignore
                queryFilter.subServices = lodash.map(serviceFilterValue?.value?.subServices, 'subServiceId');
            }
        } else {
            queryFilter.serviceId = serviceFilterValue?.value?.id;
        }

        queryFilter.resourceId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Resource})?.value?.id;
        // set centerId and distance if it is selected a center, else set regionId
        this.setLocationInformation(filterValues, queryFilter);
        const adjacentSlotsValue = lodash.find(filterValues, {name: SlotsFilterNameEnum['Number of Adjacent Slots']})?.value;
        if (!!adjacentSlotsValue) {
            queryFilter.adjacentSlots = adjacentSlotsValue;
        }
        queryFilter.expertBookingMode = lodash.find(filterValues, {name: SlotsFilterNameEnum['Expert Booking']})?.value;
        queryFilter.includeNotBookable = lodash.find(filterValues, {name: SlotsFilterNameEnum['Not Bookable Slots']})?.value;
        queryFilter.searchWithoutPlannedCapacity = lodash.find(filterValues, {name: SlotsFilterNameEnum['Special Appointment Booking']})?.value;
        queryFilter.patientId = patient?.id;
        queryFilter.includeSelfPayer = this.isSelfPayerSystemConfigActive();

        return queryFilter;
    }

    loadSlotsOnSelectedDay(listId: number) {
        if (!this.slotsListsWrapperOptions.slotsListsOptions) {
            return;
        }
        const foundList = lodash.find(this.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId});
        if (!foundList?.groupedSlots) {
            return;
        }
        const slotsPerDay = foundList.groupedSlots[this.slotsCalendarOptions?.selectedDay?.stringDate] ?? [];
        foundList.displaySlots = this.mapSlotsForDisplay(slotsPerDay, foundList.slotsExtraDetails, foundList.specialAppointmentBooking);
        foundList.noSlotsOnSelectedDay = foundList.displaySlots?.length === 0;
        // Set marked to slots in reservation list
        if (this.reservedSlotsOptions?.reservations?.length > 0) {
            this.reservedSlotsOptions.reservations.forEach((reservedSlot: MultiAppointmentBookingReservationType) => {
                this.setHoverOrMarkedToSlotInSlotsList(reservedSlot.listId, reservedSlot.slot, {marked: true});
            });
        }
        // Save slots for display to state
        this.multiAppointmentBookingState.slotsListsWrapperOptions = lodash.cloneDeep(this.slotsListsWrapperOptions);
    }

    mapSlotsForDisplay(slots: SlotType[], slotsExtraDetails: SlotsExtraDetailsType, specialAppointmentBooking: boolean): MabSlotDisplayType[] {
        const mappedSlots = lodash.orderBy(
            slots, [
                'dateTime',
                (slot: SlotDisplayType) => parseInt(slot?.resource?.priority, 10),
                (slot: SlotDisplayType) => slot?.resource?.name
            ], ['asc', 'desc', 'asc']
        )?.map((slot) => ({
            ...this.slotsUtils.mapSlotForDisplay(slot, slotsExtraDetails, specialAppointmentBooking),
            marked: slot.marked,
        }));
        this.setFirstAMTimeSlotAndPMTimeSlot(mappedSlots);
        return mappedSlots;
    }

    getInitialCalendarOptions(numberOfPages: number): MultiAppointmentBookingCalendarOptionsType {
        // The calendar should display the current month without slots before searching
        const calendarPages: MultiAppointmentBookingCalendarPageType[] = [];
        let calendarDays: MultiAppointmentBookingCalendarDayType[] = [];
        // moment().day(x) returns the x week day starting from the current week
        // More details here: https://momentjs.com/docs/#/get-set/day/
        for (let momentDayParameter = 1; momentDayParameter <= numberOfPages * 28; momentDayParameter += 1) {
            const momentDate = moment().day(momentDayParameter);
            // Create and save the day
            const day = {
                momentDate, stringDate: momentDate.format('YYYY-MM-DD'), displayDay: momentDate.format('D')
            } as MultiAppointmentBookingCalendarDayType;
            calendarDays.push(day);
            if (momentDayParameter % 28 === 0) {
                // Create and save page
                const page = {
                    calendarDays
                } as MultiAppointmentBookingCalendarPageType;
                calendarPages.push(page);
                // Reset variables
                calendarDays = [];
            }
        }
        return {
            calendarPages,
            numberOfPages,
            currentPage: 0,
            displayMonth: moment().format('MMMM'), // Initially display current month and year
            displayYear: moment().format('YYYY'),
            areOptionsAfterSearch: false,
        } as MultiAppointmentBookingCalendarOptionsType;
    }

    getInitialSlotsListsWrapperOptions(): MultiAppointmentBookingSlotsListWrapperOptionsType {
        return {
            slotsListsOptions: [
                {
                    label: 'label.timeSlots',
                    translateLabel: true,
                    isEmpty: true,
                    listId: 0,
                } as MabSlotListOptionsType
            ],
            isEmpty: true,
            nextId: 1,
        } as MultiAppointmentBookingSlotsListWrapperOptionsType;
    }

    getNumberOfPagesForCalendar(): number {
        const daysInCurrentWeekBeforeToday = moment().day() - 1;
        this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek = this.timeWindowInDaysBasedOnSearch + daysInCurrentWeekBeforeToday;
        return Math.max(1, Math.ceil(this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek / 28));
    }

    getNumberOfSlotsToDisplayInCalendarForTheseSlots(
        slots: SlotType[], wasResourceUsedForSearch: boolean, specialAppointmentBooking: boolean
    ): number {
        // Set what slot number is displayed for each day in the calendar
        if (specialAppointmentBooking) {
            return this.getNumberOfSlotsForSpecialAppointmentBooking(slots);
        }
        if (wasResourceUsedForSearch) {
            // when physician search take only the uniq slots by dateTime
            // we do this because a physician can have more services at the same hour but perform only one
            return Object.keys(lodash.groupBy(slots, 'dateTime')).length;
        } else {
            return slots.length;
        }
    }

    loadCalendarOptions(): void {
        const numberOfPages = this.getNumberOfPagesForCalendar();
        const options: MultiAppointmentBookingCalendarOptionsType = this.getInitialCalendarOptions(numberOfPages);
        // These options are after the search
        options.areOptionsAfterSearch = true;
        // Disable all days before today
        this.disableAllDaysBeforeToday(options);
        // Disable all days after the search period
        this.disableAllDaysAfterSearch(options);
        // Load number of slots for each day & options
        this.slotsCalendarOptions = this.loadNumberOfSlotsPerDay(options);
        // Load calendar day types (Normal, Special, Partial) - basically colors
        this.slotsCalendarOptions = this.loadCalendarDayColors(options);
        // Preselect today
        this.preselectFirstDayWithSlots(options);
        // Load the calendar options in the state
        this.multiAppointmentBookingState.slotsCalendarOptions = lodash.cloneDeep(this.slotsCalendarOptions);
    }

    adjustCalendarOptionsAfterFirstSearch(): void {
        // Adjust number of slots for each day
        this.slotsCalendarOptions = this.loadNumberOfSlotsPerDay(this.slotsCalendarOptions);
        // Load calendar day types (Normal, Special, Partial) - basically colors
        this.slotsCalendarOptions = this.loadCalendarDayColors(this.slotsCalendarOptions);
        // Update state
        this.multiAppointmentBookingState.slotsCalendarOptions = lodash.cloneDeep(this.slotsCalendarOptions);
    }

    loadCalendarDayColors(options: MultiAppointmentBookingCalendarOptionsType): MultiAppointmentBookingCalendarOptionsType {
        const numberOfSearchesWithSlots = {};
        const numberOfSpecialAppointmentSearches = {};
        const searches = this.slotsListsWrapperOptions?.slotsListsOptions?.length;
        this.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotListsOptions: MabSlotListOptionsType) => {
            if (!slotListsOptions.groupedSlots) {
                return;
            }
            Object.keys(slotListsOptions.groupedSlots).forEach((groupedSlotsKey: string) => {
                if (!numberOfSearchesWithSlots[groupedSlotsKey]) {
                    numberOfSearchesWithSlots[groupedSlotsKey] = 0;
                    numberOfSpecialAppointmentSearches[groupedSlotsKey] = 0;
                }
                numberOfSearchesWithSlots[groupedSlotsKey] += 1;
                numberOfSpecialAppointmentSearches[groupedSlotsKey] += (slotListsOptions.specialAppointmentBooking ? 1 : 0);
            });
        });
        options.calendarPages.forEach((page: MultiAppointmentBookingCalendarPageType) => {
            page.calendarDays.forEach((day: MultiAppointmentBookingCalendarDayType) => {
                const allSearchesHaveSlots = numberOfSearchesWithSlots[day.stringDate] === searches;
                const allSearchesAreSpecialAppointment = numberOfSpecialAppointmentSearches[day.stringDate] === searches;
                day.dayType = allSearchesHaveSlots && allSearchesAreSpecialAppointment
                    ? MultiAppointmentBookingCalendarDayTypeEnum.Special
                    : (allSearchesHaveSlots
                        ? MultiAppointmentBookingCalendarDayTypeEnum.Normal
                        : MultiAppointmentBookingCalendarDayTypeEnum.Partial);
            });
        });
        return options;
    }

    loadNumberOfSlotsPerDay(options: MultiAppointmentBookingCalendarOptionsType): MultiAppointmentBookingCalendarOptionsType {
        const numberOfGroupedSlots = {};
        this.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotListsOptions: MabSlotListOptionsType) => {
            if (!slotListsOptions.groupedSlots) {
                return;
            }
            Object.keys(slotListsOptions.groupedSlots).forEach((groupedSlotsKey: string) => {
                if (!numberOfGroupedSlots[groupedSlotsKey]) {
                    numberOfGroupedSlots[groupedSlotsKey] = 0;
                }
                numberOfGroupedSlots[groupedSlotsKey] = numberOfGroupedSlots[groupedSlotsKey] +
                    this.getNumberOfSlotsToDisplayInCalendarForTheseSlots(
                        slotListsOptions.groupedSlots[groupedSlotsKey],
                        slotListsOptions.wasResourceUsedForSearch,
                        slotListsOptions.specialAppointmentBooking
                    );
            });
        });
        options.calendarPages.forEach((page: MultiAppointmentBookingCalendarPageType) => {
            page.calendarDays.forEach((day: MultiAppointmentBookingCalendarDayType) => {
                day.displaySlots = numberOfGroupedSlots[day.stringDate] ?? undefined;
            });
        });
        return options;
    }

    loadLocalFiltersOptions(isFirstSearch: boolean = true): void {
        // Hide local filters to reset the flow
        this.slotsLocalFiltersOptions.displaySlotsLocalFilters = false;

        // Update appointment types
        const appointmentTypes = [];
        this.slotsListsWrapperOptions?.slotsListsOptions?.forEach((slotsListOptions: MabSlotListOptionsType) => {
            appointmentTypes.push(...(slotsListOptions?.slotsExtraDetails?.appointmentTypes ?? []));
        });
        this.slotsLocalFiltersOptions.appointmentTypes = lodash.clone(lodash.uniqBy(appointmentTypes, 'id'));

        // Only if it's the first search
        if (isFirstSearch) {
            // Preselect appointment type
            if (this.slotsLocalFiltersOptions.appointmentTypes?.length === 1) {
                this.slotsLocalFiltersOptions.selectedAppointmentType = this.slotsLocalFiltersOptions.appointmentTypes[0];
            } else {
                // Remove the selected appointment type
                this.slotsLocalFiltersOptions.selectedAppointmentType = undefined;
            }
            // Preselect self payer with value true -- At the moment we always display this field
            this.slotsLocalFiltersOptions.selfPayer = true;
        } else {
            // Otherwise check if we should remove the appointment type that was preselected at first search
            if (!this.slotsLocalFiltersOptions.appointmentTypeChangedByUser && this.slotsLocalFiltersOptions.appointmentTypes?.length > 1) {
                this.slotsLocalFiltersOptions.selectedAppointmentType = undefined;
            }
        }

        // Show local filters
        this.slotsLocalFiltersOptions.displaySlotsLocalFilters = true;

        // Save local filters options to state
        this.multiAppointmentBookingState.slotsLocalFiltersOptions = lodash.cloneDeep(this.slotsLocalFiltersOptions);
    }

    resetSlotResultsScrollTop() {
        const slotResultLists: HTMLCollectionOf<Element> = document.getElementsByClassName('slots-list-per-day');
        for (const key of Object.keys(slotResultLists)) {
            slotResultLists[key].scrollTop = 0;
        }
    }

    filterSlotsByAMPM(slots: SlotType[]): SlotType[] {
        const am = this.slotsLocalFiltersOptions.am;
        const pm = this.slotsLocalFiltersOptions.pm;
        const onlyShowAm = am && !pm;
        const onlyShowPm = !am && pm;
        if (onlyShowAm) {
            return lodash.filter(slots, (slot: SlotType) => slot.AM);
        } else if (onlyShowPm) {
            return lodash.filter(slots, (slot: SlotType) => !slot.AM);
        }
        return slots;
    }

    filterSlotsByAppointmentType(slots: SlotType[]): SlotType[] {
        let filteredSlots = slots;
        // If appointmentType local filter is selected
        const appointmentTypeShortId = this.slotsLocalFiltersOptions.selectedAppointmentType?.shortId;
        if (appointmentTypeShortId) {
            // Filter only the slots that have the selected appointment type in their appointment types list
            filteredSlots = lodash.filter(slots, (slot: SlotType) => {
                return lodash.find(slot.appointmentTypes, {appointmentTypeId: appointmentTypeShortId});
            });
            // Add appointmentTypeId to these slots, so the sked-base mapper correctly maps the appointmentType
            filteredSlots.forEach((slot: SlotType) => {
                slot.appointmentTypeId = appointmentTypeShortId;
            });
        } else {
            filteredSlots.forEach((slot: SlotType) => {
                delete slot.appointmentTypeId;
            });
        }
        return filteredSlots;
    }

    filterSlotsByIncludeSelfPayer(slots: SlotType[]): SlotType[] {
        const includeSelfPayer = this.slotsLocalFiltersOptions.selfPayer;
        if (!includeSelfPayer) {
            // Return only the slots that have self payer false
            return lodash.filter(slots, (slot: SlotType) => !slot.isSelfPayer);
        }
        return slots;
    }

    resetSlotResultsSliceUpperBound() {
        this.slotsListsWrapperOptions?.slotsListsOptions?.forEach(list => {
            list.slotsResultsSliceUpperBound = this.INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND;
        });
    }

    calculateSlotSearchMaxTimeWindowValue() {
        // Set number of pages based on system config TimeWindowMaximum
        this.timeWindowInDaysBasedOnSearch = Math.min(
            this.mabTimeWindowMaximum,
            this.mabSearchStep
        );
        this.previousTimeWindowInDaysBasedOnSearch = this.timeWindowInDaysBasedOnSearch;
    }

    updateCalendarDisplayMonthAndYear() {
        // Update display month with the months and years of the current page
        const currentPageIndex = this.slotsCalendarOptions.currentPage;
        const currentPage = this.slotsCalendarOptions.calendarPages[currentPageIndex];
        const months = [];
        const years = [];
        currentPage.calendarDays.forEach((day: SlotsManagementCalendarDayType) => {
            months.push(day.momentDate.format('MMMM'));
            years.push(day.momentDate.format('YYYY'));
        });
        const uniqueMonths = lodash.uniq(months);
        const uniqueYears = lodash.uniq(years);
        this.slotsCalendarOptions.displayMonth = uniqueMonths.join(' - ');
        this.slotsCalendarOptions.displayYear = uniqueYears.join(' - ');
    }

    getSlotSearchModalOptions(): NgbModalOptions {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'slot-search-modal'
        };

        return modalOptions;
    }

    getAppointmentTypeAndObjectDetailsModalOptions(): NgbModalOptions {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'slot-without-planned-capacity-modal',
            size: 'lg',
        };

        return modalOptions;
    }

    getInitialSlotsLocalFiltersOptions(): SlotsLocalFiltersOptionsType {
        return {
            am: false,
            pm: false,
            selfPayer: true,
            appointmentTypes: [],
            selectedAppointmentType: undefined,
            displaySlotsLocalFilters: false
        };
    }

    getInitialReservedSlotsOptions(): MultiAppointmentBookingReservedSlotsOptionsType {
        return {
            reservations: [],
            displayedReservations: [],
        } as MultiAppointmentBookingReservedSlotsOptionsType;
    }

    isSelfPayerSystemConfigActive(): boolean {
        const systemConfig: any = this.configDataService.systemConfig;
        const foundSystemConfig = lodash.find(systemConfig?.value, (item) => {
            return item.name === 'PatientPortalAlwaysIncludeSelfPayer';
        });

        return foundSystemConfig?.value === 'true';
    }

    private disableAllDaysBeforeToday(options: MultiAppointmentBookingCalendarOptionsType): void {
        try {
            options.calendarPages.forEach((page: MultiAppointmentBookingCalendarPageType) => {
                page.calendarDays.forEach((day: MultiAppointmentBookingCalendarDayType) => {
                    if (day.momentDate.isBefore(moment(), 'day')) {
                        day.isDisabled = true;
                    } else {
                        // Already disabled all days previous to today
                        throw {};
                    }
                });
            });
        } catch {
        }
    }

    private disableAllDaysAfterSearch(options: MultiAppointmentBookingCalendarOptionsType): void {
        if (this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28 !== 0) {
            for (let dayIndex = this.enabledDaysPlusDisabledDaysStartingAtBeginningOfCurrentWeek % 28; dayIndex < 28; dayIndex += 1) {
                options.calendarPages[options.numberOfPages - 1].calendarDays[dayIndex].isDisabled = true;
            }
        }
    }

    private preselectFirstDayWithSlots(options: MultiAppointmentBookingCalendarOptionsType): void {
        try {
            options.calendarPages.forEach((page: MultiAppointmentBookingCalendarPageType, pageIndex: number) => {
                page.calendarDays.forEach((day: MultiAppointmentBookingCalendarDayType, dayIndex: number) => {
                    if (day.displaySlots > 0) {
                        // Select this day
                        day.isSelected = true;
                        options.selectedDay = day;
                        options.previouslySelectedDay = {
                            pageIndex,
                            dayIndex
                        } as MultiAppointmentBookingCalendarSelectedDayType;
                        // Go to that page
                        options.currentPage = pageIndex;
                        // Update the display month and year
                        options.displayMonth = day.momentDate.format('MMMM');
                        options.displayYear = day.momentDate.format('YYYY');
                        // Already selected first day with slots
                        throw {};
                    }
                });
            });
        } catch {
        }
    }

    private setLocationInformation(filterValues: SlotsFiltersSelectValueType[], queryFilter: SlotSearchRequestFilterType) {
        const location = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center});
        if (location?.value.hasOwnProperty('isParent')) {
            queryFilter.regionId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value?.locations[0].regionId;
        } else {
            queryFilter.centerId = lodash.find(filterValues, {name: SlotsFilterNameEnum.Center})?.value?.id;
            const foundDistance = lodash.find(filterValues, {name: SlotsFilterNameEnum.Distance})?.value?.value;
            if (foundDistance > 0 && queryFilter.centerId) {
                queryFilter.distance = foundDistance;
            }
        }
    }

    private setFirstAMTimeSlotAndPMTimeSlot(slotsForDisplay) {
        lodash.find(slotsForDisplay, (item) => {
            if (item.AM === true) {
                item.isFirstAMTimeSlot = true;
                return item;
            }
        });

        lodash.find(slotsForDisplay, (item) => {
            if (item.AM === false) {
                item.isFirstPMTimeSlot = true;
                return item;
            }
        });
    }

    private getNumberOfSlotsForSpecialAppointmentBooking(slots: SlotType[]): number {
        let slotsPerDay = 0;
        for (const slot of slots) {
            if (slot.appointmentTypeId) {
                slotsPerDay += lodash.find(slot.appointmentTypes, {appointmentTypeId: slot.appointmentTypeId})?.numberOfSlots;
            } else {
                slot.appointmentTypes?.forEach((appointmentType: SlotSearchAppointmentTypesType) => {
                    slotsPerDay += appointmentType?.numberOfSlots;
                });
            }
        }
        return slotsPerDay;
    }
}
