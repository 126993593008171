<div class="container-fluid create-user-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-user-header">
        <h3 class="create-user-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase">{{'label.userManagementTab.user'|translate}}</span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-user-section create-entity-md-section">
        <div [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}"
             class="col-12 p-0">
            <mat-horizontal-stepper #stepper
                                    class="h-100"
                                    (selectionChange)="onStepChange($event)"
                                    [linear]="screenTemplateLayout.action === constants.EDIT ? isLinearStepperOrNot(validTemplate) : true"
                                    labelPosition="bottom">
                <!-- General-->
                <mat-step *ngIf="screenTemplateLayout.action !== constants.VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-4">
                                <!-- username-->
                                <div class="form-group">
                                    <label translate="label.userManagementTab.userName"></label>
                                    <input [(ngModel)]="userItem.username"
                                           (ngModelChange)="onInputChanged(userItem)"
                                           [disabled]='screenTemplateLayout.action===constants.VIEW'
                                           [ngClass]="{'input-element-has-error': !validTemplate.isUsernameValid}"
                                           autocomplete="off"
                                           class="input-element"
                                           name="username"
                                           placeholder="{{'label.userManagementTab.userName'|translate}}"
                                           type="text">
                                    <div *ngIf="!validTemplate.isUsernameValid" class="help-block">
                                        <span [ngClass]="{'has-error': !validTemplate.isUsernameValid}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                                <!-- password-->
                                <div class="form-group">
                                    <label translate="label.userManagementTab.password"></label>
                                    <input #password="ngModel"
                                           [(ngModel)]="userItem.password"
                                           (ngModelChange)="onInputChanged(userItem)"
                                           [disabled]='screenTemplateLayout.action===constants.VIEW'
                                           [ngClass]="{'input-element-has-error': !validTemplate.isPasswordValid}"
                                           autocomplete="off"
                                           class="input-element"
                                           name="password"
                                           pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.,$!%*#?&-])[A-Za-z\d@.,$!%*#?&-]{8,40}$"
                                           placeholder="{{'label.userManagementTab.password'|translate}}"
                                           type="password">
                                    <div *ngIf="!userItem.password && screenTemplateLayout.action === constants.CREATE"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !userItem.password}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="password.invalid" class="help-block">
                                        <span [ngClass]="{'has-error': password.errors.pattern}"
                                              translate="label.error.invalidPassword">
                                        </span>
                                    </div>
                                </div>
                                <!-- user type -->
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': checkIfUserIsTypeApiAndHasApiKeys()}">
                                    <label translate="label.userManagementTab.type"></label>
                                    <ng-select
                                        class="custom-ng-select input-element"
                                        loadingText="{{'label.loading' | translate}}"
                                        dropdownPosition="bottom"
                                        placeholder="{{'label.type' | translate}}"
                                        [(ngModel)]="userItem.type"
                                        [multiple]="false"
                                        [searchable]="false"
                                        [clearable]="false"
                                        [disabled]="checkIfUserIsTypeApiAndHasApiKeys()"
                                        [ngClass]="{'input-element-has-error': !userItem.type}"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onUserTypeChanged(userItem.type)">
                                        <ng-option *ngFor="let userType of UserTypeList"
                                                   [value]="userType">
                                            <span [translate]="'enum.userType.' + userType"></span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!userItem.type" class="help-block">
                                         <span [ngClass]="{'has-error': !userItem.type}"
                                               translate="label.error.required">
                                         </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <!-- firstname-->
                                <div class="form-group">
                                    <label translate="label.userManagementTab.firstName"></label>
                                    <input [(ngModel)]="userItem.firstName"
                                           (ngModelChange)="onInputChanged(userItem)"
                                           [disabled]='screenTemplateLayout.action===constants.VIEW'
                                           autocomplete="off"
                                           class="input-element"
                                           name="firstname"
                                           placeholder="{{'label.userManagementTab.firstName'|translate}}"
                                           type="text">
                                </div>
                                <!-- lastname-->
                                <div class="form-group">
                                    <label translate="label.userManagementTab.lastName"></label>
                                    <input [(ngModel)]="userItem.lastName"
                                           (ngModelChange)="onInputChanged(userItem)"
                                           [disabled]='screenTemplateLayout.action===constants.VIEW'
                                           autocomplete="off"
                                           class="input-element"
                                           name="lastname"
                                           placeholder="{{'label.userManagementTab.lastName'|translate}}"
                                           type="text">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <!-- email-->
                                    <div class="form-group">
                                        <label translate="label.userManagementTab.email"></label>
                                        <input #email="ngModel"
                                               [(ngModel)]="userItem.email"
                                               (ngModelChange)="onInputChanged(userItem)"
                                               [disabled]='screenTemplateLayout.action===constants.VIEW'
                                               [ngClass]="{'input-element-has-error': !validTemplate.email.isValid}"
                                               autocomplete="off"
                                               class="input-element"
                                               name="email"
                                               placeholder="{{'label.userManagementTab.email'|translate}}"
                                               type="text">
                                        <div *ngIf="!validTemplate.email.isValid" class="help-block">
                                        <span [ngClass]="{'has-error': !validTemplate.email.isValid}"
                                              translate="{{validTemplate.email.errorMessage}}">
                                        </span>
                                        </div>
                                    </div>
                                    <!-- team-->
                                    <div class="form-group customSelect sbase-input-element">
                                        <label translate="label.userManagementTab.team"></label>
                                        <div [ngClass]="{'disabled-class': screenTemplateLayout.action===constants.VIEW}">
                                            <sbase-filter (selectedItemsOutput)="onSelectedTeam($event)"
                                                          [buttonName]=teamButtonName
                                                          [dependentFilters]="{}" [hideSelectedItems]="true"
                                                          [initialFilterValues]="initialTeamValues"
                                                          [isMultipleSelectedItem]="false"
                                                          [providerInstance]="teamProvider"
                                                          [ngClass]="{'input-element-has-error': !userItem.teamId}"
                                                          [useSelectedValueAsLabel]="true">
                                            </sbase-filter>
                                        </div>
                                        <div *ngIf="!userItem.teamId" class="help-block">
                                         <span [ngClass]="{'has-error': !userItem.teamId}"
                                               translate="label.error.required">
                                         </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Resource, Centers and External Keys -->
                <mat-step *ngIf="screenTemplateLayout.action !== constants.VIEW" [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel>
                        <span translate="label.resource"></span>,&nbsp;
                        <span translate="label.centers"></span>&nbsp;
                        <span class="text-lowercase"><span translate="label.and"></span></span>&nbsp;
                        <span translate="label.externalKeysLabel"></span>
                    </ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <!-- Resource-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.resource"></label>
                                    <div [ngClass]="{'disabled-class': screenTemplateLayout.action===constants.VIEW}">
                                        <sbase-filter (selectedItemsOutput)=" onSelectedResource($event)"
                                                      [buttonName]=resourceButtonName
                                                      [dependentFilters]="mainDependentFilters.resource"
                                                      [hideSelectedItems]="true"
                                                      [initialFilterValues]="initialResourceValues"
                                                      [isMultipleSelectedItem]="false"
                                                      [providerInstance]="resourceProvider"
                                                      [ngClass]="{'input-element-has-error': userItem.mSGraphCalendar && !userItem.resourceId}"
                                                      [useSelectedValueAsLabel]="true">
                                        </sbase-filter>
                                    </div>
                                    <div *ngIf="userItem.mSGraphCalendar && !userItem.resourceId" class="help-block">
                                        <span [ngClass]="{'has-error': userItem.mSGraphCalendar && !userItem.resourceId}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                                <!-- MSGraphCalendar -->
                                <div class="form-group" [featureDisplay]="'msgraph-calendar-integration'">
                                    <label translate="label.mSGraphCalendar"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="userItem.mSGraphCalendar"
                                                   (ngModelChange)="onInputChanged(userItem)">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                                <!-- External Keys -->
                                <div class="form-group">
                                    <label translate="label.externalKeysLabel"></label>
                                    <button (click)="onAddExternalKeyItem()"
                                            *ngIf="screenTemplateLayout.action !== constants.VIEW"
                                            class="btn addTableRow-btn btn-block text-left py-2"
                                            type="button">
                                        <i class="fas fa-plus-circle"></i>
                                        <span class="name-button" translate="label.addTableRow"></span>
                                    </button>
                                    <div class="container-external-keys-table custom-scrollbar">
                                        <table [ngClass]="{'disabled-input' : screenTemplateLayout.action === constants.VIEW}"
                                               class="table mt-3 table-bordered border-0">
                                            <thead>
                                            <tr>
                                                <th class="border-0 p-0" translate="label.origin"></th>
                                                <th class="border-0 p-0" translate="label.key"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let item of userItem.userExternalKeys; let i = index">
                                                <td>
                                                    <div class="form-group m-0">
                                                        <input [(ngModel)]="item.origin"
                                                               [disabled]="screenTemplateLayout.action===constants.VIEW"
                                                               (ngModelChange)="onInputChanged(userItem)"
                                                               autocomplete="off"
                                                               class="form-control border-0 p-0"
                                                               name="{{item.origin}}"
                                                               placeholder="{{'label.origin'|translate}}"
                                                               type="text">
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group m-0 d-flex">
                                                        <input [(ngModel)]="item.key"
                                                               (ngModelChange)="onInputChanged(userItem)"
                                                               [disabled]="screenTemplateLayout.action===constants.VIEW"
                                                               autocomplete="off"
                                                               class="form-control border-0 p-0"
                                                               name="{{item.key}}"
                                                               placeholder="{{'label.key'|translate}}"
                                                               type="text">
                                                        <button *ngIf="screenTemplateLayout.action!==constants.VIEW"
                                                                class="btn float-right p-0 pl-2"
                                                                type="button">
                                                            <i (click)="onRemoveExternalKeyItem(i)" class="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="userItem?.userExternalKeys?.length === 0">
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <span class="help-block"
                                              *ngIf="!validTemplate.areExternalKeysValid && userItem?.userExternalKeys?.length > 0"
                                              [ngClass]="{ 'has-error': !validTemplate.areExternalKeysValid }"
                                              translate="label.error.invalidExternalKeysTable">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <!-- Centers-->
                                <div class="form-group">
                                    <label translate="label.centers"></label>
                                    <app-multi-select-table *ngIf="!!centerMultiSelectTableOptions"
                                                            [options]="centerMultiSelectTableOptions"
                                                            (itemsChange)="onSelectedCenter($event)"
                                                            class="sbase-dropdown-ml"
                                    ></app-multi-select-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Overview -->
                <mat-step [completed]="false"
                          [stepControl]="areStepsValid(validTemplate, stepper.selectedIndex)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-6">
                                <table class="table border service-details">
                                    <tbody>
                                    <tr *ngIf="screenTemplateLayout.action !== constants.CREATE">
                                        <td translate="label.id"></td>
                                        <td class="text-right">{{userItem.id}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.username"></td>
                                        <td class="text-right">{{userItem.username}}</td>
                                    </tr>
                                    <tr *ngIf="userItem.type">
                                        <td translate="label.type"></td>
                                        <td class="text-right">{{userItem.type | translate}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.userManagementTab.firstName"></td>
                                        <td class="text-right">{{userItem.firstName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.userManagementTab.lastName"></td>
                                        <td class="text-right">{{userItem.lastName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.userManagementTab.email"></td>
                                        <td class="text-right">{{userItem.email}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.userManagementTab.team"></td>
                                        <td class="text-right">{{userItem?.team?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.physician2"></td>
                                        <td class="text-right">{{userItem?.resource?.name}}</td>
                                    </tr>
                                    <tr [featureDisplay]="'msgraph-calendar-integration'">
                                        <td translate="label.mSGraphCalendar"></td>
                                        <td class="text-right">{{userItem?.mSGraphCalendar | yesNoPipe | translate}}</td>
                                    </tr>
                                    <tr *ngIf="userItem?.id">
                                        <td translate="label.lastLogin"></td>
                                        <td class="text-right">{{userItem?.lastLogin | fullDateBrowserTzFormatPipe}}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div *ngIf="userItem?.centers?.length>0" class="form-group">
                                    <label translate="label.centers"></label>
                                    <table class="table border service-details">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <span *ngFor="let center of userItem.centers; last as islast">
                                                    {{center.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div *ngIf="userItem?.userExternalKeys?.length>0"
                                     class="form-group">
                                    <label translate="label.externalKeysLabel"></label>
                                    <table class="table table-bordered service-details">
                                        <thead>
                                        <tr>
                                            <td translate="label.origin"></td>
                                            <td translate="label.key"></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of userItem.userExternalKeys">
                                            <td>
                                                {{item.origin}}
                                            </td>
                                            <td>
                                                {{item.key}}
                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button (click)="goToParentPage()"
                    class="btn mr-3"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button (click)="goBack(stepper)"
                    *ngIf="screenTemplateLayout.action !== constants.VIEW"
                    [disabled]="stepper.selectedIndex === 0"
                    class="btn back-btn mr-3"
                    translate="button.back"
                    type="button">
            </button>
            <button (click)="goForward(stepper)"
                    *ngIf="(stepper.selectedIndex < totalStepsCount-1)"
                    [disabled]="!areStepsValid(validTemplate, stepper.selectedIndex)"
                    class="btn theme-btn"
                    type="button">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveUserData(userItem)"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn"
                    type="button">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'UserUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
