import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ResourceMDComponent} from './resource-md.component';
import {CreateResourceComponent} from './create-resource/create-resource.component';
import {AngularEditorModule} from '@kolkov/angular-editor';

@NgModule({
    declarations: [ResourceMDComponent, CreateResourceComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        AngularEditorModule,
    ]
})
export class ResourceMDModule {
}
