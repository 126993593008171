import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    MultiResourceCombinationType,
    MultiResourceCombinationProvider,
    ResourceProvider,
    MultiResourceBluePrintProvider,
    MultiResourceCombinationItemType,
    MultiResourceBluePrintType,
    ResourceType, MultiResourceBluePrintItemType, ResourceTypeProvider, ResourceTypeType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MultiResourceCombinationMdUtils} from '../multi-resource-combination-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    MultiResourceCombinationDependentFiltersType,
    SecondaryResourcesOptionsType
} from '../multi-resource-combination.types';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-multi-resource-combination',
    templateUrl: './create-multi-resource-combination.component.html',
    styleUrls: ['./create-multi-resource-combination.component.scss']
})
export class CreateMultiResourceCombinationComponent implements OnInit, OnDestroy {
    readonly CONSTANTS = constants;
    initialMultiResourceCombination: MultiResourceCombinationType;
    multiResourceCombinationItem: MultiResourceCombinationType = this.multiResourceCombinationMdUtils.getInitialMultiResourceCombination();
    screenTemplateLayout: ScreenTemplateLayoutType;

    displayResourceControls = false;

    mainDependentFilters: MultiResourceCombinationDependentFiltersType;
    initialFilterValues = this.multiResourceCombinationMdUtils.getCreateMultiResourceCombinationInitialFilterValues();
    mainResourceResourceTypeId: ResourceTypeType;

    secondaryResourcesOptions: SecondaryResourcesOptionsType[] = [];
    resourceTypesById: {[key in string]?: ResourceTypeType} = {};

    constructor(
        public multiResourceBlueprintProvider: MultiResourceBluePrintProvider,
        public resourceProvider: ResourceProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private multiResourceCombinationMdUtils: MultiResourceCombinationMdUtils,
        private multiResourceCombinationProvider: MultiResourceCombinationProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        private resourceTypeProvider: ResourceTypeProvider,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveMultiResourceCombinationData(multiResourceCombination: MultiResourceCombinationType) {
        const isTemplateValid = this.validateFields(multiResourceCombination);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createMultiResourceCombination(multiResourceCombination);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialMultiResourceCombination, multiResourceCombination)) {
                    this.messagesService.success('toastr.success.multiResourceCombinationEdit', true);
                    this.goToParentPage();
                } else {
                    this.editMultiResourceCombination(this.initialMultiResourceCombination, multiResourceCombination);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/multiResourceCombinations']);
    }

    onSelectedMultiResourceBlueprintFilter(multiResourceBlueprintList: MultiResourceBluePrintType[]) {
        this.initialFilterValues.multiResourceBlueprint = multiResourceBlueprintList;
        this.multiResourceCombinationItem.bluePrintId = (multiResourceBlueprintList?.length > 0) ? multiResourceBlueprintList[0].id : undefined;
        if (!this.multiResourceCombinationItem.bluePrintId) {
            this.initialFilterValues = this.multiResourceCombinationMdUtils.getCreateMultiResourceCombinationInitialFilterValues();
            this.secondaryResourcesOptions = [];
            this.multiResourceCombinationItem.mainResourceId = null;
            this.multiResourceCombinationItem.items = [];
            this.displayResourceControls = false;
            return;
        }
        this.getMultiResourceBlueprintRequest().subscribe(({value}) => {
            this.displayResourceControls = !!this.multiResourceCombinationItem.bluePrintId;
            this.setResourceMainDependentFilters(value[0]);
            this.initializeSecondaryResourceFilters(value[0]);
            this.mainResourceResourceTypeId = lodash.find(value[0]?.items ?? [], {isMainResource: true})?.resourceTypeId;
        });
    }

    onSelectedMainResourceFilter(mainResourceList: ResourceType[]) {
        this.initialFilterValues.mainResource = mainResourceList;
        this.multiResourceCombinationItem.mainResourceId = (mainResourceList?.length > 0) ? mainResourceList[0].id : undefined;
        if (!this.multiResourceCombinationItem.mainResourceId) {
            this.secondaryResourcesOptions.forEach((options: SecondaryResourcesOptionsType) => {
                options.resourceExclusionList = [];
                options.initialFilterValues = [];
            });
        } else {
            this.secondaryResourcesOptions.forEach((options: SecondaryResourcesOptionsType) => {
                options.resourceExclusionList = [this.multiResourceCombinationItem.mainResourceId];
            });
        }
        this.updateItems();
    }

    onSelectedSecondaryResourceFilter(secondaryResourceList: ResourceType[], index: number) {
        if (!this.secondaryResourcesOptions[index]) {
            return;
        }
        this.secondaryResourcesOptions[index].initialFilterValues = secondaryResourceList;
        this.updateItems();
    }

    isSaveButtonEnabled() {
        return this.validateFields(this.multiResourceCombinationItem);
    }

    goToEdit() {
        history.replaceState({
            multiResourceCombination: this.multiResourceCombinationItem,
            action: this.CONSTANTS.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new MultiResourceCombination
    private createMultiResourceCombination(multiResourceCombination: MultiResourceCombinationType) {
        this.ngxLoader.start();
        this.multiResourceCombinationProvider.addEntry(multiResourceCombination)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newMultiResourceCombinationAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the MultiResourceCombination
    // tslint:disable-next-line:max-line-length
    private editMultiResourceCombination(oldMultiResourceCombination: MultiResourceCombinationType, newMultiResourceCombination: MultiResourceCombinationType) {
        this.ngxLoader.start();
        this.multiResourceCombinationProvider.updateEntry(oldMultiResourceCombination, newMultiResourceCombination)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.multiResourceCombinationEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private getMultiResourceBlueprintRequest() {
        return this.multiResourceBlueprintProvider
            .getEntries(this.multiResourceCombinationMdUtils.getQueryFilterForMultiResourceBlueprint(this.multiResourceCombinationItem.bluePrintId));
    }

    private validateFields(multiResourceCombinationItem: MultiResourceCombinationType): boolean {
        return !!(multiResourceCombinationItem && multiResourceCombinationItem.bluePrintId && multiResourceCombinationItem.mainResourceId
            && this.secondaryResourcesOptions.every((options: SecondaryResourcesOptionsType) => options.initialFilterValues?.length > 0));
    }

    private setResourceMainDependentFilters(multiResourceBlueprint: MultiResourceBluePrintType) {
        this.mainDependentFilters.mainResource.resourceTypeId = multiResourceBlueprint.items.find(item => item.isMainResource).resourceTypeId;
    }

    private loadInitialData() {
        this.ngxLoader.start();
        this.getMultiResourceBlueprintRequest().subscribe(({value}) => {
            this.setResourceMainDependentFilters(value[0]);
            this.initialFilterValues = {
                multiResourceBlueprint: value,
                mainResource: [this.multiResourceCombinationItem.mainResource],
            };
            this.initializeSecondaryResourceFilters(value[0]);
            this.mainResourceResourceTypeId = lodash.find(value[0]?.items ?? [], {isMainResource: true})?.resourceTypeId;
            this.ngxLoader.stop();
        }, err => {
            this.messagesService.handlingErrorMessage(err);
            this.ngxLoader.stop();
            this.goToParentPage();
        });
    }

    private initializeSecondaryResourceFilters(multiResourceBlueprint: MultiResourceBluePrintType) {
        this.secondaryResourcesOptions = [];
        const multiResourceBlueprintSecondary = multiResourceBlueprint?.items?.filter(item => !item.isMainResource);
        multiResourceBlueprintSecondary.forEach((bluePrintItem: MultiResourceBluePrintItemType) => {
            // Load secondary resource filter
            this.secondaryResourcesOptions.push({
                mainDependentFilters: {
                    ...this.multiResourceCombinationMdUtils.getResourceDependentFilters(),
                    resourceTypeId: bluePrintItem.resourceTypeId
                },
                initialFilterValues: this.multiResourceCombinationItem.items
                    .filter((item: MultiResourceCombinationItemType) => !item.isMainResource && item.resource?.resourceTypeId === bluePrintItem?.resourceTypeId)
                    .map(item => ({id: item.resourceId, name: item.resource.name})) as ResourceType[],
                resourceExclusionList: [this.multiResourceCombinationItem.mainResourceId],
                bluePrintItem,
            } as SecondaryResourcesOptionsType);
        });
    }

    private loadResourceTypes() {
        this.ngxLoader.start();
        this.resourceTypeProvider.getEntries({select: ['Id', 'Name', 'RowVersion']}).subscribe((
            response: {count?: number, value: ResourceTypeType[]}
        ) => {
            this.resourceTypesById = {};
            response.value?.forEach((resourceType: ResourceTypeType) => {
                this.resourceTypesById[resourceType.id] = resourceType;
            });
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private updateItems() {
        const secondaryResourceItems = [];
        this.secondaryResourcesOptions.forEach((options: SecondaryResourcesOptionsType) => {
            options.initialFilterValues.forEach((resourceValue: ResourceType) => {
                secondaryResourceItems.push(resourceValue);
            });
        });
        this.multiResourceCombinationItem.items = [
            ...this.initialFilterValues?.mainResource?.map((resource: ResourceType) => ({
                isMainResource: true,
                resourceId: resource.id,
                name: resource.name
            } as any as MultiResourceCombinationItemType)),
            ...secondaryResourceItems.map((resource: ResourceType) => ({
                isMainResource: false,
                resourceId: resource.id,
                name: resource.name
            } as any as MultiResourceCombinationItemType)),
        ];
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            multiResourceBlueprint: this.multiResourceCombinationMdUtils.getMultiResourceBlueprintDependentFilters(),
            mainResource: this.multiResourceCombinationMdUtils.getResourceDependentFilters()
        };
        this.loadResourceTypes();
        if (history.state && history.state.multiResourceCombination) {
            this.initialMultiResourceCombination = history.state.multiResourceCombination;
            this.multiResourceCombinationItem = lodash.cloneDeep(history.state.multiResourceCombination);
            this.loadInitialData();
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
            this.displayResourceControls = true;
        } else {
            this.multiResourceCombinationItem = this.multiResourceCombinationMdUtils.getInitialMultiResourceCombination();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }
}
