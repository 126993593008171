import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AppointmentTypeDefinitionType, AppointmentTypeDefinitionProvider,
    LocationDependentFiltersType, ResourceDependentFiltersType,
    ResourceProvider, CenterProvider, AppointmentTypeProvider,
    AppointmentTypeDependentFilterType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AppointmentTypeDefinitionMdUtils} from '../appointment-type-definition-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-appointment-type-definition',
    templateUrl: './create-appointment-type-definition.component.html',
    styleUrls: ['./create-appointment-type-definition.component.scss']
})
export class CreateAppointmentTypeDefinitionComponent implements OnInit, OnDestroy {
    constants = constants;
    initialAppointmentTypeDefinition: AppointmentTypeDefinitionType;
    appointmentTypeDefinitionItem: AppointmentTypeDefinitionType = {} as AppointmentTypeDefinitionType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    appointmentTypeButtonName = 'label.appointmentType';
    resourceButtonName = 'label.resource';
    centerButtonName = 'label.center2';
    mainDependentFilters: {
        appointmentType: AppointmentTypeDependentFilterType,
        location: LocationDependentFiltersType,
        resource: ResourceDependentFiltersType
    };
    initialAppointmentTypeValues: any[] = [];
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    RegexWholeNumber = /^[1-9]\d*$/;
    isTemplateValid: boolean;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private appointmentTypeDefinitionMdUtils: AppointmentTypeDefinitionMdUtils,
        public appointmentTypeDefinitionProvider: AppointmentTypeDefinitionProvider,
        public router: Router,
        public generalUtils: GeneralUtils,
        public appointmentTypeProvider: AppointmentTypeProvider,
        public resourceProvider: ResourceProvider,
        public centerProvider: CenterProvider
    ) {
    }

    ngOnInit() {
        this.setupInitialState();

        this.mainDependentFilters = {
            appointmentType: this.appointmentTypeDefinitionMdUtils.getAppointmentTypeDependentFilters(),
            location: this.appointmentTypeDefinitionMdUtils.getLocationDependentFilters(),
            resource: this.appointmentTypeDefinitionMdUtils.getResourceDependentFilters(),
        };
    }

    ngOnDestroy(): void {
    }

    onSelectedAppointmentType(filterList): void {
        if (filterList.length > 0) {
            this.appointmentTypeDefinitionItem.appointmentTypeId = filterList[0].id;
            this.appointmentTypeDefinitionItem.appointmentType = filterList[0];
        } else {
            this.appointmentTypeDefinitionItem.appointmentTypeId = undefined;
            this.appointmentTypeDefinitionItem.appointmentType = undefined;
        }
        this.isTemplateValid = this.validateFields(this.appointmentTypeDefinitionItem);
    }

    onSelectedResource(filterList): void {
        if (filterList.length > 0) {
            this.appointmentTypeDefinitionItem.resourceId = filterList[0].id;
            this.appointmentTypeDefinitionItem.resource = filterList[0];
        } else {
            this.appointmentTypeDefinitionItem.resourceId = undefined;
            this.appointmentTypeDefinitionItem.resource = undefined;
        }
        this.isTemplateValid = this.validateFields(this.appointmentTypeDefinitionItem);
    }

    onQuantityChanged(appointmentTypeDefinitionItem: AppointmentTypeDefinitionType) {
        this.isTemplateValid = this.validateFields(appointmentTypeDefinitionItem);
    }

    onDurationChanged(appointmentTypeDefinitionItem: AppointmentTypeDefinitionType) {
        this.isTemplateValid = this.validateFields(appointmentTypeDefinitionItem);
    }

    onSelectedCenter(filterList): void {
        if (filterList.length > 0) {
            this.appointmentTypeDefinitionItem.centerId = filterList[0].id;
            this.appointmentTypeDefinitionItem.center = filterList[0];
        } else {
            this.appointmentTypeDefinitionItem.centerId = undefined;
            this.appointmentTypeDefinitionItem.center = undefined;
        }
    }

    saveAppointmentTypeDefinitionData(appointmentTypeDefinition: AppointmentTypeDefinitionType) {
        this.isTemplateValid = this.validateFields(appointmentTypeDefinition);
        if (this.isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createAppointmentTypeDefinition(appointmentTypeDefinition);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialAppointmentTypeDefinition, appointmentTypeDefinition)) {
                    this.messagesService.success('toastr.success.appointmentTypeDefinitionEdit', true);
                    this.goToParentPage();
                } else {
                    this.editAppointmentTypeDefinition(this.initialAppointmentTypeDefinition, appointmentTypeDefinition);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/appointmentTypeDefinitions']);
    }

    isOvercapacity(): boolean {
        return this.appointmentTypeDefinitionItem.appointmentType !== undefined &&
            this.appointmentTypeDefinitionItem.appointmentType.system &&
            !this.appointmentTypeDefinitionItem.appointmentType.consumesPlannedCapacity;
    }

    validateWholeNumber(number): boolean {
        if (number !== undefined && number !== null) {
            return number.toString().match(this.RegexWholeNumber);
        } else {
            return true;
        }
    }

    goToEdit() {
        history.replaceState({
            appointmentTypeDefinition: this.appointmentTypeDefinitionItem,
            action: this.constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new AppointmentTypeDefinition
    private createAppointmentTypeDefinition(appointmentTypeDefinition: AppointmentTypeDefinitionType) {
        this.ngxLoader.start();
        this.appointmentTypeDefinitionProvider.addEntry(appointmentTypeDefinition)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newAppointmentTypeDefinitionAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the AppointmentTypeDefinition
    private editAppointmentTypeDefinition(oldAppointmentTypeDefinition: AppointmentTypeDefinitionType, newAppointmentTypeDefinition: AppointmentTypeDefinitionType) {
        this.ngxLoader.start();
        this.appointmentTypeDefinitionProvider.updateEntry(oldAppointmentTypeDefinition, newAppointmentTypeDefinition)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.appointmentTypeDefinitionEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(appointmentTypeDefinitionItem: AppointmentTypeDefinitionType): boolean {
        return !!(appointmentTypeDefinitionItem &&
            appointmentTypeDefinitionItem.appointmentType &&
            appointmentTypeDefinitionItem.resource &&
            appointmentTypeDefinitionItem.quantity &&
            this.validateWholeNumber(appointmentTypeDefinitionItem.quantity) &&
            this.validateWholeNumber(appointmentTypeDefinitionItem.duration));
    }

    private setupInitialState() {
        if (history.state && history.state.appointmentTypeDefinition) {
            this.initialAppointmentTypeDefinition = history.state.appointmentTypeDefinition;
            this.setInitialFilterComponentArrays(this.initialAppointmentTypeDefinition);
            this.appointmentTypeDefinitionItem = lodash.cloneDeep(history.state.appointmentTypeDefinition);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.appointmentTypeDefinitionItem = this.appointmentTypeDefinitionMdUtils.getInitialAppointmentTypeDefinition();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.isTemplateValid = this.validateFields(this.appointmentTypeDefinitionItem);
    }

    private setInitialFilterComponentArrays(initialAppointmentTypeDefinition: AppointmentTypeDefinitionType) {
        if (initialAppointmentTypeDefinition.appointmentType) {
            this.initialAppointmentTypeValues.push(initialAppointmentTypeDefinition.appointmentType);
        }
        if (initialAppointmentTypeDefinition.resource) {
            this.initialResourceValues.push(initialAppointmentTypeDefinition.resource);
        }
        if (initialAppointmentTypeDefinition.center) {
            this.initialCenterValues.push(initialAppointmentTypeDefinition.center);
        }
    }
}
