import {Component, Input, OnInit} from '@angular/core';
import {AppointmentProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {AppointmentSendEmailButtonOptionsType} from './appointment-send-email-button.types';
import {DateTimeUtils} from '../../utils/dateTime.utils';

@Component({
    selector: 'app-appointment-send-email-button',
    templateUrl: './appointment-send-email-button.component.html',
    styleUrls: ['./appointment-send-email-button.component.scss']
})
export class AppointmentSendEmailButtonComponent implements OnInit {
    @Input() options: AppointmentSendEmailButtonOptionsType;

    displayButton = false;

    constructor(private appointmentProvider: AppointmentProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.setDisplayButton();
    }

    sendAppointmentEmail() {
        this.ngxLoader.start();
        this.appointmentProvider.resendEmail(this.options?.appointmentId).subscribe(() => {
            this.messagesService.success('toastr.success.emailSent');
            this.ngxLoader.stop();
        }, err => {
            this.messagesService.handlingErrorMessage(err);
            this.ngxLoader.stop();
        });
    }

    private setDisplayButton() {
        if (!this.dateTimeUtils.isExpiredDate(this.options?.appointmentDate) && !this.options?.cancelled) {
            this.displayButton = true;
        }
    }
}
