import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'commaDotSwap'
})
// This pipe swaps dots and commas in the given string or number
export class CommaDotSwapPipe implements PipeTransform {
    transform(value: string | number): string {
        return `${value}`.replace(/[.,]/, c => c === '.' ? ',' : '.');
    }
}
