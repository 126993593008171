import {Injectable} from '@angular/core';
import {
    ODataQueryObjectType
} from 'sked-base';
import {forkJoin, Observable, Subject} from 'rxjs';
import * as lodash from 'lodash';
import {of} from 'rxjs/internal/observable/of';
import {concatMap, map, switchMap, take, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    cachedData: { [key: string]: any } = {};
    // If more than REVALIDATION_TIME_IN_SECONDS seconds passed since the request, force another request
    cachedDataWithRevalidation: { [key: string]: { id?: { timestamp: number, value: any } } } = {};
    REVALIDATION_TIME_IN_SECONDS = 60 * 15; // 15 minutes
    requestWithRevalidationDone: Subject<void> = new Subject<void>();
    doingRequestWithRevalidation = false;

    constructor() {
    }


    clearCache() {
        this.cachedData = {};
        this.cachedDataWithRevalidation = {};
    }

    // key: the key used to cache the data (can be anything)
    // provider: the provider used to make the call to getEntries, only if there is no data cached already
    // filterQuery: the query used to make the call to getEntries, only used uf there is no data cached already
    // maximumItemsPerRequest: the maximum number of items that are returned from back-end on a single request
    //// Note: provider must have the getEntries method
    getData(key: string, provider: any, filterQuery: ODataQueryObjectType, maximumItemsPerRequest = 100): Observable<any> {
        // If cache has item at key, return observable from this item
        if (lodash.has(this.cachedData, key)) {
            return of(this.cachedData[key]);
        }
        // Otherwise return a call to the provided provider with the provided filterQuery
        return provider.getEntries(filterQuery).pipe(
            take(1),
            concatMap((firstResponse: { count: number, value: any }) => {
                // Take the first response which may or may not have a count > 100,
                // and if the count > 100, we need to prepare more requests to be sent
                // The getAllObservables method basically returns requests with skip in order to get all the items.
                // Example: if count = 462, getAllObservables returns a list of similar observables, each with the
                //  following difference: first has skip: 100, second has skip: 200, ..., forth has skip: 400
                const nextObservables = firstResponse.count > maximumItemsPerRequest ?
                    this.getAllObservables(firstResponse.count, provider, filterQuery, maximumItemsPerRequest) :
                    [];
                // We then merge the first response, which has the total count with the next observables
                const allObservables = [of(firstResponse), ...nextObservables];
                // And use a forkJoin to send each request
                return forkJoin(allObservables).pipe(take(1));
            }),
            // Then we map the responses
            map((allResponses) => {
                // We merge the values from each response into the allValues variable, cache this result and return it
                const allValues = lodash.flatten(lodash.map(allResponses, singleResponse => singleResponse.value));
                this.cachedData[key] = {count: allResponses[0].count, value: allValues};
                return this.cachedData[key];
            })
        );
    }

    getDataById(key: string, provider: any, filterQuery: ODataQueryObjectType, id: string): Observable<any> {
        // If cache has item at key.id, return observable from this item
        if (lodash.has(this.cachedData, key) && lodash.has(this.cachedData[key], id)) {
            return of(this.cachedData[key][id]);
        }
        // If cache does not have the key property we need to declare it
        if (!lodash.has(this.cachedData, key)) {
            this.cachedData[key] = {};
        }
        // Otherwise return a call to the provided provider with the provided filterQuery
        return provider.getById(id, filterQuery).pipe(
            take(1),
            tap((response) => this.cachedData[key][id] = response)
        );
    }

    // Flows for getDataByIdWithRevalidation and getDataWithRevalidation work like this:
    //
    // 1. Call getDataWithRevalidation:
    //  a. if cache is not empty -> return cache value
    //  b. if cache is empty or expired -> make new request, return all data, save each data by id
    //
    // 2. Call getDataByIdWithRevalidation:
    //  a. if cache is not empty -> return cache value
    //  b. if cache is empty or expired:
    //   b1. if parameter getAllDataIfExpired is false -> make new request for id, save data for the id, return data for requested id
    //   b2. if parameter getAllDataIfExpired is true (default) -> call getDataWithRevalidation (flow 1-b), return data for requested id
    //   b3. if parameter getAllDataIfExpired is true (default) AND request 2-b-b2 is in progress -> wait for request to finish, then return data for requested id
    //
    // Example flows:
    //  - rules: on rules overview screen, loadSpecialities method is called to load the specialities for all rule items.
    //  loadSpecialities calls getDataWithRevalidation (flow 1-b). if the user navigates to page 2, loadSpecialities calls
    //  getDataWithRevalidation again, but enters flow 1-a instead.
    //  - theoretical: we could make a call to getDataWithRevalidation after the user logs in (flow 1-b). let's assume on
    //  page X there is only one service, so we only need one speciality. when loading this speciality we could call
    //  getDataByIdWithRevalidation. if cache is not expired yet, the cache value is returned. if cache is expired, we
    //  can choose either flow 2-b-b1 or 2-b-b2 based on the getAllDataIfExpired parameter. on flow 2-b-b1, a new request
    //  is made only for the requested speciality id, and only that value is saved in cache. on flow 2-b-b2, a new request
    //  is made on getEntries, so all values are requested and saved, and then only the requested speciality is returned
    //

    // Parameters are similar to getData method
    getDataWithRevalidation(key: string, provider: any, filterQuery: ODataQueryObjectType, maximumItemsPerRequest = 100): Observable<any> {
        // If cache has item at key, return observable from this item
        if (this.isCachedDataWithRevalidationValid(key, 'all')) {
            return of(this.cachedDataWithRevalidation[key]['all']);
        }
        this.doingRequestWithRevalidation = true;
        if (!lodash.has(this.cachedDataWithRevalidation, key)) {
            this.cachedDataWithRevalidation[key] = {};
        }
        // Otherwise return a call to the provided provider with the provided filterQuery
        return provider.getEntries(filterQuery).pipe(
            take(1),
            concatMap((firstResponse: { count: number, value: any }) => {
                // Take the first response which may or may not have a count > 100,
                // and if the count > 100, we need to prepare more requests to be sent
                // The getAllObservables method basically returns requests with skip in order to get all the items.
                // Example: if count = 462, getAllObservables returns a list of similar observables, each with the
                //  following difference: first has skip: 100, second has skip: 200, ..., forth has skip: 400
                const nextObservables = firstResponse.count > maximumItemsPerRequest ?
                    this.getAllObservables(firstResponse.count, provider, filterQuery, maximumItemsPerRequest) :
                    [];
                // We then merge the first response, which has the total count with the next observables
                const allObservables = [of(firstResponse), ...nextObservables];
                // And use a forkJoin to send each request
                return forkJoin(allObservables).pipe(take(1));
            }),
            // Then we map the responses
            map((allResponses) => {
                const now = Date.now();
                // We merge the values from each response into the allValues variable, cache this result and return it
                const allValues = lodash.flatten(lodash.map(allResponses, singleResponse => singleResponse.value));
                // Save the response under 'all' id
                this.cachedDataWithRevalidation[key]['all'] = {count: allResponses[0].count, value: allValues, timestamp: now};
                // Save each value under its own id
                allValues.forEach((value) => {
                    if (!value.id) {
                        return;
                    }
                    this.cachedDataWithRevalidation[key][value.id] = {
                        value,
                        timestamp: now,
                    };
                });
                this.doingRequestWithRevalidation = false;
                this.requestWithRevalidationDone.next();
                return this.cachedDataWithRevalidation[key]['all'];
            })
        );
    }

    getDataByIdWithRevalidation(key: string, provider: any, filterQuery: ODataQueryObjectType, id: string, getAllDataIfExpired: boolean = true): Observable<any> {
        // If cache has item at key.id and not enough time passed for revalidation
        if (this.isCachedDataWithRevalidationValid(key, id)) {
            return of(this.cachedDataWithRevalidation[key][id]['value']);
        }
        if (!lodash.has(this.cachedDataWithRevalidation, key)) {
            this.cachedDataWithRevalidation[key] = {};
        }
        // Otherwise return a call to the provided provider with the provided filterQuery
        if (!getAllDataIfExpired) {
            // Case 1: call get by id separately
            return provider.getById(id, filterQuery).pipe(
                take(1),
                tap((response) => this.cachedDataWithRevalidation[key][id] = {
                    value: response,
                    timestamp: Date.now(),
                })
            );
        }
        // Case 2: call getEntries method
        // Case 2a: if not doing a request already, call getDataWithRevalidation and return the value for the requested id
        if (!this.doingRequestWithRevalidation) {
            return this.getDataWithRevalidation(key, provider, filterQuery).pipe(take(1), map(() => {
                return this.cachedDataWithRevalidation[key][id]['value'];
            }));
        }
        // Case 2b: if doing a request already, wait for request to finish and return the value for the requested id
        return this.requestWithRevalidationDone.pipe(take(1), map(() => {
            return this.cachedDataWithRevalidation[key][id]['value'];
        }));
    }

    // Returns an array of observables, one item is a request of 100 objects; this array is then used in a forkJoin
    private getAllObservables(allCount: number, provider: any, filterQuery: ODataQueryObjectType, maximumItemsPerRequest = 100):
        Observable<{ value: any[], count?: number }>[] {
        let skip = 0;
        const obsArray = [];
        for (let index = 1; index < lodash.ceil(allCount / maximumItemsPerRequest); index++) {
            skip = skip + maximumItemsPerRequest;
            try {
                obsArray.push(provider.getEntries({...filterQuery, skip: skip, count: false}));
            } catch (err) {
                // Print error, but keep adding items to obsArray, return the empty array if all calls result in errors
                console.error(err);
            }
        }
        return obsArray;
    }

    private isTimestampPassed(timestamp: number): boolean {
        const now = Date.now();
        return (now - timestamp) > this.REVALIDATION_TIME_IN_SECONDS * 1000;
    }

    private isCachedDataWithRevalidationValid(key: string, id: string): boolean {
        return lodash.has(this.cachedDataWithRevalidation, key) && lodash.has(this.cachedDataWithRevalidation[key], id)
            && lodash.has(this.cachedDataWithRevalidation[key][id], 'timestamp') && lodash.has(this.cachedDataWithRevalidation[key][id], 'value')
            && !this.isTimestampPassed(this.cachedDataWithRevalidation[key][id]['timestamp']);
    }
}
