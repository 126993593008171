import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ConfirmDeleteModalService,
    CoveragePlanProvider,
    CoveragePlanType,
    EntityTypeEnum,
    ServiceProvider
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CoveragePlanMdUtils} from './coverage-plan-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-coverage-plan-md',
    templateUrl: './coverage-plan-md.component.html',
    styleUrls: ['./coverage-plan-md.component.scss']
})
export class CoveragePlanMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[];
    coveragePlanMdList: any = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    mainDependentFilters: any;
    serviceButtonName = 'label.service';
    COVERAGE_PLAN = 'CoveragePlan';
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchCoveragePlanChanged = new Subject<string>();

    constructor(
        public coveragePlanMdUtils: CoveragePlanMdUtils,
        public coveragePlanProvider: CoveragePlanProvider,
        public serviceProvider: ServiceProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('CoveragePlanRead');
        this.mainDependentFilters = {
            service: this.coveragePlanMdUtils.getServiceDependentFilters()
        };
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();

        if (this.previousRouteService.getPreviousUrl() !== '/createCoveragePlan') {
            this.loadInitialFilter({tableFilters: true, sbaseFilters: true});
        }

        this.getCoveragePlanMdData();
        this.subscribeSearchByCoveragePlanChanged();
    }


    ngOnDestroy(): void {
    }

    // method to navigate create CoveragePlan
    createCoveragePlan(): void {
        this.router.navigate(['/createCoveragePlan']);
    }

    editCoveragePlan(coveragePlan: CoveragePlanType) {
        this.router.navigate(['/createCoveragePlan'], {state: {coveragePlan, action: constants.EDIT}});
    }

    viewCoveragePlan(coveragePlan: CoveragePlanType) {
        this.router.navigate(['/createCoveragePlan'], {state: {coveragePlan, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.coveragePlanMdUtils.tableFilters.currentPage = page;
        this.getCoveragePlanMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.coveragePlanMdUtils.tableFilters.currentPage = 1;
        this.coveragePlanMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getCoveragePlanMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.coveragePlanMdUtils.tableFilters.orderBy[property];
        if (this.coveragePlanMdUtils.tableFilters.orderBy) {
            this.coveragePlanMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getCoveragePlanMdData(false);
    }

    onSearchCoveragePlanChanged(value: string) {
        this.searchCoveragePlanChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.coveragePlanMdUtils.tableFilters.orderBy[selectedItem];
        this.getCoveragePlanMdData(false);
    }

    displayConfirmDeleteItemModal(coveragePlanId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.coveragePlanProvider, coveragePlanId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(coveragePlanId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onSelectedServiceFilter(serviceFilterList): void {
        this.coveragePlanMdUtils.initialServiceValues = serviceFilterList;
        this.coveragePlanMdUtils.tableFilters.filter.service = (serviceFilterList && serviceFilterList.length > 0) ? serviceFilterList[0] : undefined;
        this.getCoveragePlanMdData();
    }

    private loadInitialFilter({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.coveragePlanMdUtils.tableFilters = this.coveragePlanMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.coveragePlanMdUtils.initialServiceValues = [];
        }
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchCoveragePlanChanged('');
            this.loadInitialFilter({tableFilters: true, sbaseFilters: true});
            this.getCoveragePlanMdData();
        });
    }

    // function to get CoveragePlan data
    private getCoveragePlanMdData(includeCount: boolean = true) {
        const queryFilter = this.coveragePlanMdUtils.getQueryFilterForCoveragePlanMD(this.coveragePlanMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.coveragePlanProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.coveragePlanMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.coveragePlanMdUtils.tableFilters = this.coveragePlanMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.coveragePlanProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getCoveragePlanMdData();
                this.messagesService.success('toastr.success.coveragePlanDelete', true);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private subscribeSearchByCoveragePlanChanged() {
        const self = this;
        this.searchCoveragePlanChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.coveragePlanMdUtils.tableFilters.filter.name = searchValue;
                self.getCoveragePlanMdData();
            });
    }
}
