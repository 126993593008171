<div class="container-fluid create-resource-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-resource-header">
        <h3 class="create-resource-title">
            {{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase" translate="label.resource"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <form name="form" novalidate>
        <div class="create-entity-md-section">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form">
                        <div class="always-display-inputs-container">
                            <!-- Short Id -->
                            <div class="form-group short-id-container"
                                 [ngClass]="{'disabled-input': 'true'}"
                                 *ngIf="screenTemplateLayout.action !== CONSTANTS.CREATE">
                                <label translate="label.shortId"></label>
                                <input type="text" name="shortId"
                                       class="input-element"
                                       placeholder="{{'label.shortId'|translate}}"
                                       [(ngModel)]="resourceItem.shortId">
                            </div>
                            <!-- Resource Type -->
                            <div class="form-group customSelect sbase-input-element resource-type-container"
                                 [ngClass]="{'has-error': !validTemplate.isResourceTypeValid}">
                                <label translate="label.resourceType.title"></label>
                                <div [ngClass]="{'disabled-sbaseFilter': screenTemplateLayout.action === CONSTANTS.EDIT}">
                                    <sbase-filter (selectedItemsOutput)="onSelectedResourceTypeFilter($event)"
                                                  [buttonName]='"label.resourceType.title"'
                                                  [dependentFilters]="mainDependentFilters.resourceType"
                                                  [initialFilterValues]="[this.resourceItem.resourceType]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="resourceTypeProvider"
                                                  [ngClass]="{'has-error': !resourceItem.resourceTypeId}">
                                    </sbase-filter>
                                </div>
                                <div *ngIf="!validTemplate.isResourceTypeValid" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                            <!-- Name -->
                            <div class="form-group name-container">
                                <label translate="label.name"></label>
                                <input type="text" name="name" class="input-element"
                                       [(ngModel)]="resourceItem.name"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.isNameValid}"
                                       placeholder="{{'label.name'|translate}}"
                                       autocomplete="off">
                                <div *ngIf="!validTemplate.isNameValid" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                            <!-- Priority -->
                            <div class="form-group priority-container">
                                <label translate="label.priority"></label>
                                <input type="number" name="priority" class="input-element"
                                       [(ngModel)]="resourceItem.priority"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.isPriorityValid}"
                                       min="1"
                                       step="1"
                                       max="200"
                                       placeholder="{{'label.priority'|translate}}"
                                       autocomplete="off"/>
                                <div *ngIf="generalUtils.isNullOrUndefined(resourceItem.priority)"
                                     class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                                <div *ngIf="!generalUtils.isNullOrUndefined(resourceItem.priority) && !validTemplate.isPriorityValid"
                                     class="help-block">
                                    <span>
                                        {{ "label.error.invalidNumberForInterval" | translate: {min: 1, max: 200} }}
                                    </span>
                                </div>
                            </div>
                            <!-- Tags -->
                            <div class="form-group customSelect sbase-input-element tags-container"
                                 [ngClass]="{'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                <label translate="label.tags"></label>
                                <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                              [buttonName]='"label.newTag"'
                                              [dependentFilters]="mainDependentFilters?.tags"
                                              [initialFilterValues]="resourceItem.tags"
                                              [isMultipleSelectedItem]="true"
                                              [useSelectedValueAsLabel]="true"
                                              [hideSelectedItems]="true"
                                              [providerInstance]="tagProvider">
                                </sbase-filter>
                            </div>
                        </div>
                        <div class="dynamic-display-inputs-container">
                            <!-- Unique Code  -->
                            <div class="form-group unique-code-container"
                                 *ngIf="resourceItem?.resourceType?.hasUniqueCode">
                                <label translate="label.uniqueCode"></label>
                                <input type="text" name="uniqueCode" class="input-element"
                                       [(ngModel)]="resourceItem.uniqueCode"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.uniqueCode}"
                                       placeholder="{{'label.uniqueCode'|translate}}"
                                       autocomplete="off">
                                <div *ngIf="!validTemplate.uniqueCode" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="form-group email-container"
                                 *ngIf="resourceItem?.resourceType?.hasContactInformation">
                                <label translate="label.email"></label>
                                <input [(ngModel)]="resourceItem.email"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.email?.isValid}"
                                       autocomplete="off"
                                       class="input-element"
                                       name="email"
                                       placeholder="{{'label.emailPlaceholder'|translate}}"
                                       type="text">
                                <div *ngIf="!validTemplate.email?.isValid" class="help-block">
                                    <span [ngClass]="{'has-error': !validTemplate.email?.isValid}"
                                          translate="{{validTemplate.email?.errorMessage}}">
                                    </span>
                                </div>
                            </div>
                            <!-- Phone Number -->
                            <div class="phone-number-container"
                                 *ngIf="resourceItem?.resourceType?.hasContactInformation">
                                <app-phone-number-input [options]="phoneNumberInputOptions"
                                                        (phoneNumberChanged)="onPhoneNumberChange($event)"
                                ></app-phone-number-input>
                            </div>
                            <!-- Location -->
                            <div class="form-group location-container"
                                 *ngIf="resourceItem?.resourceType?.hasContactInformation">
                                <label translate="label.location2"></label>
                                <input type="text" name="location" class="input-element"
                                       [(ngModel)]="resourceItem.location"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.location}"
                                       placeholder="{{'label.location2'|translate}}"
                                       autocomplete="off">
                                <div *ngIf="!validTemplate.location" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                            <!-- Additional Information -->
                            <div class="form-group additional-information-container"
                                 *ngIf="resourceItem?.resourceType?.hasAdditionalInfo">
                                <label translate="label.alerts"></label>
                                <textarea [(ngModel)]="resourceItem.additionalInformation"
                                          (ngModelChange)="onResourceValueChanged(resourceItem)"
                                          [ngClass]="{'input-element-has-error': !validTemplate.additionalInformation}"
                                          class="input-element-all-sides-border"
                                          name="additionalInformation"
                                          placeholder="{{'label.alerts'|translate}}"
                                          autocomplete="off"
                                          [rows]="3"></textarea>
                                <div *ngIf="!validTemplate.additionalInformation" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                            <!-- External Url For Description  -->
                            <div class="form-group external-url-for-description-container"
                                 *ngIf="resourceItem?.resourceType?.hasDescription">
                                <label translate="label.externalUrlForDescription"></label>
                                <input type="text" name="externalUrlForDescription" class="input-element"
                                       [(ngModel)]="resourceItem.externalUrlForDescription"
                                       (ngModelChange)="onResourceValueChanged(resourceItem)"
                                       [ngClass]="{'input-element-has-error': !validTemplate.externalUrlForDescription.isValid}"
                                       placeholder="{{'label.externalUrlForDescription'|translate}}"
                                       autocomplete="off">
                                <span *ngIf="!validTemplate.externalUrlForDescription.isValid" class="help-block">
                                    {{validTemplate.externalUrlForDescription?.errorMessage | translate}}
                                </span>
                            </div>
                            <!-- Enable Wait List -->
                            <div class="form-group enable-wait-list-container"
                                 *ngIf="resourceItem?.resourceType?.canBeRegisteredToWaitList">
                                <label translate="label.enableWaitListForPatientAccess"></label>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked
                                               [(ngModel)]="resourceItem.enableWaitList"
                                               name="enableWaitList">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="form-group description-container"
                                 *ngIf="!!textEditorConfig && resourceItem?.resourceType?.hasDescription">
                                <label translate="label.description"></label>
                                <angular-editor [placeholder]="('label.textEditorPlh'| translate )"
                                                [(ngModel)]="resourceItem.description"
                                                [config]="textEditorConfig"
                                                name="resourceTextEditor">
                                </angular-editor>
                            </div>
                            <!-- Image -->
                            <div class="image-container">
                                <app-upload-file [options]="uploadFileOptions"
                                                 *ngIf="resourceItem?.resourceType?.hasImage"
                                ></app-upload-file>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.centersAndCoveragePlans"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-6">
                                <div>
                                    <div class="help-block" *ngIf="!resourceItem?.centers?.length && resourceItem?.resourceType?.isRoom">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <app-multi-select-table *ngIf="!!this.resourceMultiSelectTableOptions?.centers"
                                                            [options]="this.resourceMultiSelectTableOptions.centers"
                                                            (itemsChange)="onSelectedCenter($event)"
                                                            [clearItems]="triggerClearItemsSubject"
                                                            class="sbase-dropdown-ml"
                                    ></app-multi-select-table>
                                </div>
                            </div>
                            <div class="col-6">
                                <app-multi-select-table *ngIf="!!this.resourceMultiSelectTableOptions?.coveragePlans"
                                                        [options]="this.resourceMultiSelectTableOptions.coveragePlans"
                                                        (itemsChange)="onSelectedCoveragePlan($event)"
                                                        class="sbase-dropdown-ml"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.services"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-12">
                                <div class="info-message">
                                    <ngb-alert type="custom-info" [dismissible]="false">
                                        <i class="fas fa-info-circle mr-3"></i>
                                        <span translate="label.servicesAreFilteredBasedOnCoveragePlans"></span>
                                    </ngb-alert>
                                </div>
                                <app-multi-select-table *ngIf="!!this.resourceMultiSelectTableOptions?.services"
                                                        [options]="this.resourceMultiSelectTableOptions.services"
                                                        (itemsChange)="onSelectedService($event)"
                                                        [forceLoadData]="triggerForceUpdateSubject"
                                                        class="sbase-dropdown-ml"
                                ></app-multi-select-table>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [completed]="false" [stepControl]="getStepControl(3)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.externalKeysLabel"></span></ng-template>
                    <div class="step-form">
                        <div class="external-keys-title">
                            <label *ngIf="screenTemplateLayout.action === CONSTANTS.EDIT"
                                   translate="label.externalKeysLabel"></label>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button (click)="onAddExternalKeysRow()"
                                        [disabled]="resourceItem?.resourceExternalKeys?.length && !isCurrentExternalKeysItemValid()"
                                        *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW"
                                        class="btn addTableRow-btn btn-block text-left py-2"
                                        type="button">
                                    <i class="fas fa-plus-circle"></i>
                                    <span class="name-button" translate="label.addTableRow"></span>
                                </button>
                                <div class="container-external-keys-table">
                                    <table [ngClass]="{'disabled-input' : screenTemplateLayout.action === CONSTANTS.VIEW}"
                                           class="table mt-3 table-bordered border-0">
                                        <thead>
                                        <tr>
                                            <th class="border-0 p-0" translate="label.origin"></th>
                                            <th class="border-0 p-0" translate="label.key"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                *ngFor="let item of resourceItem.resourceExternalKeys; let i = index; let isLast = last">
                                            <td>
                                                <div class="form-group m-0">
                                                    <input type="text"
                                                           name="{{item.origin}}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.origin"
                                                           [ngModelOptions]="{standalone: true}"
                                                           placeholder="{{'label.origin'|translate}}"
                                                           [disabled]="screenTemplateLayout.action === CONSTANTS.VIEW || !isLast">

                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group m-0 d-flex">
                                                    <input type="text"
                                                           name="{{item.key}}"
                                                           autocomplete="off"
                                                           class="form-control border-0 p-0"
                                                           [(ngModel)]="item.key"
                                                           [ngModelOptions]="{standalone: true}"
                                                           placeholder="{{'label.key'|translate}}"
                                                           [disabled]="screenTemplateLayout.action === CONSTANTS.VIEW || !isLast">
                                                    <button type="button" class="btn float-right p-0 pl-2"
                                                            *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW"><i
                                                            class="fas fa-times"
                                                            (click)="onRemoveExternalKey(i)"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="help-block"
                                         *ngIf="!isCurrentExternalKeysItemValid()">
                                        <span class="has-error"
                                              translate="label.error.invalidExternalKeysTable">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [completed]="false" [stepControl]="getStepControl(2)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.generalInformation"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.name"></td>
                                            <td class="text-right">{{resourceItem?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.resourceType.title"></td>
                                            <td class="text-right">{{resourceItem?.resourceType?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.priority"></td>
                                            <td class="text-right">{{resourceItem?.priority}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.isDirectlyBookable"></td>
                                            <td class="text-right">{{resourceItem.isDirectlyBookable | yesNoPipe | translate}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.tags"></td>
                                            <td class="text-right">
                                                <span *ngFor="let tag of resourceItem?.tags; last as islast">
                                                    {{tag.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.enableWaitListForPatientAccess"></td>
                                            <td class="text-right">{{resourceItem.enableWaitList | yesNoPipe |
                                                translate}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.uniqueCode"></td>
                                            <td class="text-right">{{resourceItem.uniqueCode}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.email"></td>
                                            <td class="text-right">{{resourceItem.email}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.phoneNumber"></td>
                                            <td class="text-right">{{resourceItem.phoneNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.location2"></td>
                                            <td class="text-right">{{resourceItem.location}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.alerts"></td>
                                            <td class="text-right">{{resourceItem.additionalInformation}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.externalUrlForDescription"></td>
                                            <td class="text-right">{{resourceItem.externalUrlForDescription}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.description"></td>
                                            <td class="text-right" [innerHTML]="resourceItem.description"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.centersAndCoveragePlans"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.centers"></td>
                                            <td class="text-right">
                                                <span *ngFor="let center of resourceItem?.centers; last as islast">
                                                    {{center.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td translate="label.coveragePlans"></td>
                                            <td class="text-right">
                                                <span *ngFor="let coveragePlan of resourceItem?.coveragePlans; last as islast">
                                                    {{coveragePlan.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.services"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.services"></td>
                                            <td class="text-right">
                                                <span *ngFor="let service of resourceItem?.services; last as islast">
                                                    {{service.name}}<span *ngIf="!islast">, </span>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group"
                                     *ngIf="!!resourceItem?.resourceExternalKeys?.length">
                                    <label translate="label.externalKeysLabel"></label>
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td translate="label.origin"></td>
                                            <td translate="label.key"></td>
                                        </tr>
                                        <tr *ngFor="let item of resourceItem.resourceExternalKeys">
                                            <td>{{item.origin}}</td>
                                            <td>{{item.key}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </form>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer mt-2 footer-position-sticky">
        <button class="btn mr-3"
                type="button"
                (click)="goToParentPage()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button type="button"
                (click)="stepperGoBack(stepper)"
                *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                class="btn back-btn mr-3" translate="button.back">
        </button>
        <button (click)="stepperGoForward(stepper)"
                *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && shouldDisplayNext(stepper, totalStepsCount)"
                [disabled]="!areStepsValid(stepper.selectedIndex)"
                type="button"
                class="btn theme-btn">
            <span translate="button.next"></span>
        </button>
        <button class="btn theme-btn"
                (click)="saveResourceData(resourceItem)"
                [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW || stepper.selectedIndex !== totalStepsCount-1"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                [activityDisplay]="'ResourceUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
<ng-template let-item #serviceTemplate>
    <span *ngIf="!!item.specialityName">
        <span class="font-weight-bold">{{item.specialityName}} </span>
    </span>
    <!-- in select -->
    <span *ngIf="!item.nameWithoutSpeciality">
        <span>{{item.name}}</span>
    </span>
    <!-- in dropdown -->
    <span *ngIf="!!item.nameWithoutSpeciality">
        <span>{{item.nameWithoutSpeciality}}</span>
    </span>
    <i *ngIf="item.onlineConsultation" class="fas fa-desktop ml-1"
       ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
</ng-template>
