<ng-container [ngSwitch]="rulesUtils.selectedRule">
    <ng-template [ngSwitchCase]="ruleTypeEnum.FormAssignmentDynamicWorkListRule">
        <app-form-assignment-dynamic-work-list-rule></app-form-assignment-dynamic-work-list-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.MaxAppointmentRule">
        <app-max-appointment-rule></app-max-appointment-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.WorkOrderRule">
        <app-work-order-rule></app-work-order-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AvailabilityChannelRule">
        <app-availability-channel-rule></app-availability-channel-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.SubServiceObjectDetailRule">
        <app-sub-service-object-detail-rule></app-sub-service-object-detail-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PaymentManagementRule">
        <app-payment-management-rule></app-payment-management-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.LeadTimeRule">
        <app-lead-time-rule></app-lead-time-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.ObjectDetailRule">
        <app-object-detail-rule></app-object-detail-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PatientControlRule">
        <app-patient-control-rule></app-patient-control-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.SlotFilterRule">
        <app-slot-filter-rule></app-slot-filter-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AppointmentPriceRule">
        <app-appointment-price-rule></app-appointment-price-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.FormAssignmentAmbulatoryRule">
        <app-form-assignment-ambulatory-rule></app-form-assignment-ambulatory-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.MinimumAppointmentsRule">
        <app-minimum-appointments-rule></app-minimum-appointments-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.RevenueOptimizationRule">
        <app-revenue-optimization-rule></app-revenue-optimization-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AppointmentEventRule">
        <app-appointment-event-rule></app-appointment-event-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.ExclusionEventRule">
        <app-exclusion-event-rule></app-exclusion-event-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.CrossAvailabilityRule">
        <app-cross-availability-rule></app-cross-availability-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PatientCalculatedTagRule">
        <app-patient-calculated-tag-rule></app-patient-calculated-tag-rule>
    </ng-template>
</ng-container>
