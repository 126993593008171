import {Injectable} from '@angular/core';
import {
    AppointmentProvider, AppointmentStatusEnum,
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider,
    DateRangeOptionsType,
    FilterWrapperButtonNameEnum,
    FilterWrapperNameEnum,
    FormValidationType,
    GenericFilterOptionsType,
    GenericFilterResultType, LocationDependentFiltersType,
    PatientProvider,
    ResourceDependentFiltersType,
    ResourceProvider,
    ServiceDependentFiltersType,
    ServiceProvider,
    SubServiceProvider
} from 'sked-base';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {
    AppointmentListFiltersSearchType, AppointmentListFiltersValidationType,
    AppointmentListFilterWrapperInitialValuesType
} from './appointment-list-filters.types';
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class AppointmentListFiltersUtils {
    constructor(
        private areaProvider: AreaProvider,
        private patientProvider: PatientProvider,
        private resourceProvider: ResourceProvider,
        private serviceProvider: ServiceProvider,
        private subServiceProvider: SubServiceProvider,
        private appointmentProvider: AppointmentProvider,
        private centerProvider: CenterProvider,
        private dateTimeUtils: DateTimeUtils
    ) {
    }

    getInitialFilterWrapperValuesFromPreviousValues(
        appointmentListFiltersValues: AppointmentListFilterWrapperInitialValuesType
    ): GenericFilterResultType[] {
        const filterWrapperValues: GenericFilterResultType[] = [];
        Object.keys(appointmentListFiltersValues).forEach((property: string) => {
            if (!!appointmentListFiltersValues[property]) {
                filterWrapperValues.push({
                    name: FilterWrapperNameEnum[property],
                    value: appointmentListFiltersValues[property]
                } as GenericFilterResultType);
            }
        });
        return filterWrapperValues;
    }

    mapFilterValuesForSearch(
        appointmentListFiltersValues: AppointmentListFilterWrapperInitialValuesType,
        dateRangeOptions: DateRangeOptionsType,
        statusSelectNgModel: AppointmentStatusEnum
    ): AppointmentListFiltersSearchType {
        return {
            area: appointmentListFiltersValues.area,
            resource: appointmentListFiltersValues.resource,
            service: appointmentListFiltersValues.service,
            location: appointmentListFiltersValues.location,
            dateFrom: dateRangeOptions.fromDate,
            dateTo: dateRangeOptions.toDate,
            status: statusSelectNgModel
        } as AppointmentListFiltersSearchType;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    } as ResourceDependentFiltersType)

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        onlyAssignedToLocationsOfUser: true,
        includeAvailabilities: false,
        includeChannel: true,
        searchPhrase: '',
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        hasMultiResourceBluePrint: null,
        exclusionList: [],
        count: true,
    } as ServiceDependentFiltersType)

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: true,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: true,
        exclusionList: [],
        count: true
    })

    getInitialFilterValidations(): AppointmentListFiltersValidationType {
        return {
            area: {isValid: true, errorMessage: ''} as FormValidationType,
            resource: {isValid: true, errorMessage: ''} as FormValidationType,
            service: {isValid: true, errorMessage: ''} as FormValidationType,
            location: {isValid: true, errorMessage: ''} as FormValidationType,
            dateRange: {isValid: true, errorMessage: ''} as FormValidationType,
            statusSelect: { isValid: true, errorMessage: '' } as FormValidationType,
        } as AppointmentListFiltersValidationType;
    }

    getFilterWrapperOptions = (initialValues?: AppointmentListFilterWrapperInitialValuesType): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.getAreaDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.area,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues?.area ? {
                    name: FilterWrapperNameEnum.area,
                    value: initialValues.area
                } : undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getServiceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.service,
                name: FilterWrapperNameEnum.service,
                parentFilterValue: initialValues?.service ? {
                    name: FilterWrapperNameEnum.service,
                    value: initialValues.service
                } : undefined,
                providerInstance: this.serviceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getResourceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resource,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues?.resource ? {
                    name: FilterWrapperNameEnum.resource,
                    value: initialValues.resource
                } : undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getLocationDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.location,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues?.location ? {
                    name: FilterWrapperNameEnum.location,
                    value: initialValues.location
                } : undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            },
        ]
    )
}
