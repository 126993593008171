<sbase-resource-calendar
        (onEventTriggered)="onEventTriggered($event)"
        [allowAppointmentStatusChange]="allowAppointmentStatusChange"
        [appointmentModalEventsList]="appointmentModalEventsList"
        [centerConfig]="centerConfig"
        [fullCalendarLicenseKey]="fullCalendarLicenseKey"
        [generalConfig]="calendarGeneralConfig"
        [messagesService]="messagesService"
        [resourceConfig]="resourceConfig"
        [translatedLanguageService]="translatedLanguageService"
        [translateService]="translateService"
        [configDataService]="configDataService">
</sbase-resource-calendar>
