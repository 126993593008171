import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {ODataQueryObjectType, PatientType} from 'sked-base';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {GeneralUtils} from '../../shared/utils/general.utils';

@Injectable({
    providedIn: 'root'
})
export class PatientDashboardUtils {

    constructor(
        private patientContextService: PatientContextService,
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils
    ) {
    }

    getQueryFilterForPatientAppointments(): ODataQueryObjectType {
        return {
            count: true,
            filter: {
                PatientId: {eq: {type: 'guid', value: this.patientContextService.patient.id}},
                DateTimeFrom: {
                    ge: {
                        type: 'raw',
                        value: moment().startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            },
            expand: {
                Resource: {select: ['Name']},
                Service: {
                    select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices', 'AreaId'],
                    expand: {Speciality: {select: ['Name']}, Area: {select: ['Id', 'Name']}}
                },
                SubServices: {expand: {SubService: {select: ['Name', 'Code', 'Id']}}},
                AppointmentType: {select: ['Name']},
                Center: {select: ['Name']},
                ResourceAppointments: {
                    select: ['MainResource'],
                    expand: {
                        Resource: {select: ['Id', 'Name', 'ResourceTypeId']}
                    }
                },
                SkedTasks: {},
                Patient: {
                    select: ['RowVersion', 'Id', 'FullName', 'FirstName1', 'FirstName2', 'LastName1', 'LastName2',
                        'BirthDate', 'Email', 'MainPhoneNumber', 'AlternatePhoneNumber', 'Gender',
                        'DocumentNumberFormatted', 'DocumentNumber', 'DocumentType', 'DocumentCountry']
                },
                CoveragePlan: {
                    select: ['Id', 'Name'],
                },
            },
            orderBy: 'DateTimeFrom asc'
        };
    }

    displayPatientAddress(patient: PatientType): string {
        return this.generalUtils.displayItemsWithComma([
            patient?.country?.countryName || patient?.country, patient?.region?.name || patient?.region,
            patient?.address?.locality, patient?.address?.street, patient?.address?.streetNumber,
            patient?.address?.floor, patient?.address?.apartmentNumber, patient?.address?.zipCode
        ]);
    }
}

