import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    LeadTimeRuleProvider,
    LeadTimeRuleType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    ChannelDependentFiltersType,
    AreaDependentFiltersType,
    ServiceDependentFiltersType,
    ResourceDependentFiltersType,
    ChannelProvider,
    AreaProvider,
    ServiceProvider,
    ResourceProvider,
    ChannelEnum,
    AreaType,
    ServiceType,
    ResourceType,
    FilterComponentChannelType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {LeadTimeRuleUtils} from '../lead-time-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-lead-time-rule',
    templateUrl: './create-lead-time-rule.component.html',
    styleUrls: ['./create-lead-time-rule.component.scss']
})
export class CreateLeadTimeRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: LeadTimeRuleType = {} as LeadTimeRuleType;
    initialRule: LeadTimeRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    constants = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.LeadTimeRule);
    mainDependentFilters: {
        channel: ChannelDependentFiltersType,
        area: AreaDependentFiltersType,
        service: ServiceDependentFiltersType,
        resource: ResourceDependentFiltersType,
    };
    initialChannel: FilterComponentChannelType[] = [];

    constructor(
        public leadTimeRuleUtils: LeadTimeRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public channelProvider: ChannelProvider,
        public areaProvider: AreaProvider,
        public serviceProvider: ServiceProvider,
        public resourceProvider: ResourceProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private leadTimeRuleProvider: LeadTimeRuleProvider
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: LeadTimeRuleType): boolean {
        return !!(rule.leadTimeRuleTableId && rule.name);
    }

    isInputStepValid(rule: LeadTimeRuleType): boolean {
        return !!(rule.channel || rule.areaId || rule.serviceId || rule.resourceId);
    }

    isOutputStepValid(rule: LeadTimeRuleType): boolean {
        return !!(!this.generalUtils.isNullOrUndefined(rule.minutes) && this.isMinutesValid(rule.minutes) && rule.message);
    }

    areAllStepsValid(rule: LeadTimeRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    isMinutesValid(minutes: number): boolean {
        return minutes >= 0 && minutes <= 525000;
    }

    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.leadTimeRuleTable = ruleTable[0];
            this.ruleItem.leadTimeRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.leadTimeRuleTable = undefined;
            this.ruleItem.leadTimeRuleTableId = undefined;
        }
    }

    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedChannel(selectedChannelList: FilterComponentChannelType[]): void {
        const IS_CHANNEL_SELECTED = selectedChannelList?.length > 0;
        this.ruleItem.channel = IS_CHANNEL_SELECTED ? selectedChannelList[0].enumValue as ChannelEnum : undefined;
        this.initialChannel = IS_CHANNEL_SELECTED ? [selectedChannelList[0]] : [];
    }

    onSelectedArea(selectedAreaList: AreaType[]) {
        this.ruleItem.area = selectedAreaList?.length > 0 ? selectedAreaList[0] : undefined;
        this.ruleItem.areaId = this.ruleItem.area?.id;
    }

    onSelectedService(selectedServiceList: ServiceType[]) {
        this.ruleItem.service = selectedServiceList?.length > 0 ? selectedServiceList[0] : undefined;
        this.ruleItem.serviceId = this.ruleItem.service?.id;
    }

    onSelectedResource(selectedResourceList: ResourceType[]) {
        this.ruleItem.resource = selectedResourceList?.length > 0 ? selectedResourceList[0] : undefined;
        this.ruleItem.resourceId = this.ruleItem.resource?.id;
    }

    //
    // endregion Input methods

    // region Overview methods
    //
    saveRule(rule: LeadTimeRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.leadTimeRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private createRule(rule: LeadTimeRuleType) {
        this.ngxLoader.start();
        const ruleToSend: LeadTimeRuleType =
            this.leadTimeRuleUtils.mapRuleForServer(rule);
        this.leadTimeRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newLeadTimeRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: LeadTimeRuleType, newRule: LeadTimeRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: LeadTimeRuleType =
            this.leadTimeRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: LeadTimeRuleType =
            this.leadTimeRuleUtils.mapRuleForServer(newRule);
        this.leadTimeRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.leadTimeRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    //
    // endregion Overview methods

    private setupInitialState() {
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                if (!this.generalUtils.isNullOrUndefined(this.ruleItem.channel)) {
                    // TODO-rules: check if this can be done without loadChannels
                    this.loadChannels();
                }
            }
        } else {
            this.ruleItem = this.leadTimeRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private loadChannels() {
        this.ngxLoader.start();
        this.channelProvider.getEntries().subscribe(({value, count}) => {
            const CHANNEL = value.find(channel => channel.enumValue === this.ruleItem.channel);
            this.initialChannel = [{id: CHANNEL?.id, name: CHANNEL.channel, enumValue: CHANNEL.enumValue}];
            this.ngxLoader.stop();
        }, error => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
            this.rulesUtils.goToParentPage('createRule');
        });
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            channel: this.leadTimeRuleUtils.getChannelDependentFilters(),
            area: this.leadTimeRuleUtils.getAreaDependentFilters(),
            service: this.leadTimeRuleUtils.getServiceDependentFilters(),
            resource: this.leadTimeRuleUtils.getResourceDependentFilters()
        };
    }
}
