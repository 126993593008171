import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {TeamMDComponent} from './team-md.component';
import {CreateTeamMdComponent} from './create-team-md.component';
import {TranslatePipe} from '@ngx-translate/core';

@NgModule({
    declarations: [TeamMDComponent, CreateTeamMdComponent],
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [TranslatePipe]
})
export class TeamMDModule {
}
