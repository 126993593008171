import {Component, OnInit} from '@angular/core';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExportTaskListModalComponent} from './export-task-list-modal/export-task-list-modal.component';

@Component({
    selector: 'app-export-task-list',
    templateUrl: './export-task-list.component.html',
    styleUrls: ['./export-task-list.component.scss']
})
export class ExportTaskListComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal,
                private generalUtils: GeneralUtils,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    openExportTaskListModal() {
        // Open modal
        const modalOptions = this.generalUtils.getModalOptions();
        const modalRef = this.modalService.open(ExportTaskListModalComponent, modalOptions);

        // Wait for response
        modalRef.result.then(() => {
            // On close, do nothing
        }, () => {
            // On dismiss, do nothing
        });
    }
}
