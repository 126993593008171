import {Injectable} from '@angular/core';
import {
    ODataFilterQueryType,
    ODataOrderByQueryType,
    TableFiltersType
} from '../../data-model/general.type';
import {ActivityType, Expand, ODataQueryObjectType, OrganizationalRoleTypeEnum, TeamType} from 'sked-base';
import {BackOfficeActivityType, BackOfficeFeatureType, BackOfficeTeamType, ValidTeamType} from './team.types';
import {TranslatePipe} from '@ngx-translate/core';
import {GeneralUtils} from '../../shared/utils/general.utils';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class TeamMDUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;

    constructor(private translatePipe: TranslatePipe,
                private generalUtils: GeneralUtils) {
    }

    getQueryFilterForTeamMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Users: {
                select: ['Id', 'FirstName', 'LastName', 'Username'],
            }
        };
    }

    getValidatedFields(teamItem: TeamType): ValidTeamType {
        const validTemplate: ValidTeamType = {} as ValidTeamType;

        validTemplate.isNameValid = !!(teamItem && teamItem.name);
        validTemplate.isActivityValid = !lodash.isEmpty(teamItem.activities);

        return validTemplate;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item)) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }

        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    isTemplateValid(teamTemplate: BackOfficeTeamType, teamItemClone: BackOfficeTeamType): boolean {
        const hasSelectedItemHiddenByFilter = this.hasSelectedActivities(teamItemClone);
        const hasSelectedItem = this.hasSelectedActivities(teamTemplate);

        return !!(teamTemplate.name && (hasSelectedItem || hasSelectedItemHiddenByFilter));
    }

    isAnyActivitySelected(teamTemplate: BackOfficeTeamType, teamItemClone: BackOfficeTeamType): boolean {
        const hasSelectedItemHiddenByFilter = this.hasSelectedActivities(teamItemClone);
        const hasSelectedItem = this.hasSelectedActivities(teamTemplate);

        return !!(hasSelectedItem || hasSelectedItemHiddenByFilter);
    }

    getTeamInitialValue(): BackOfficeTeamType {
        const initialValue: BackOfficeTeamType = {} as BackOfficeTeamType;
        initialValue.name = '';
        initialValue.features = {};
        initialValue.organizationalRole = 'noValue' as OrganizationalRoleTypeEnum;

        return initialValue;
    }

    getGroupedActivitiesByFeature(activities: ActivityType[], valueOfSelectedProperty: boolean): BackOfficeFeatureType {
        //add selected property
        const updatedActivities = this.addSelectedValue(activities, valueOfSelectedProperty);
        // sort activities by translatedName
        const sortedActivitiesByTranslatedName = lodash.sortBy(updatedActivities, 'translatedName');
        const sortedActivitiesByTranslatedFeatureNameDeburr = lodash.sortBy(sortedActivitiesByTranslatedName, 'translatedFeatureNameDeburr');
        const groupedActivitiesByFeature = lodash.groupBy(sortedActivitiesByTranslatedFeatureNameDeburr, 'translatedFeatureNameDeburr');

        return groupedActivitiesByFeature;
    }

    mapTeamItemForCreate(teamItem: BackOfficeTeamType): TeamType {
        const newTeam: TeamType = {} as TeamType;

        newTeam.name = teamItem.name;
        newTeam.id = teamItem.id;
        newTeam.etag = teamItem.etag;

        if (teamItem && teamItem.features) {
            newTeam.activities = this.mapActivitiesForCreate(teamItem.features);
        }

        newTeam.users = teamItem.users;
        if (!this.generalUtils.isSelectedNoValueOption(teamItem.organizationalRole)) {
            newTeam.organizationalRole = teamItem.organizationalRole;
        }

        return newTeam;
    }

    mapActivitiesForCreate(features: BackOfficeFeatureType): string[] {
        const newActivities: string[] = [];

        for (const featureName in features) {
            if (features.hasOwnProperty(featureName)) {
                for (const activity of features[featureName]) {
                    if (activity.selected) {
                        newActivities.push(activity.name);
                    }
                }
            }
        }

        return newActivities;
    }

    isActivityName(activity: BackOfficeActivityType, actionTypeName: string): boolean {
        const foundActivity = lodash.includes(activity.name, lodash.upperFirst(actionTypeName));

        return !!foundActivity;
    }

    getFeatureActivityByName(activities: BackOfficeActivityType[], actionTypeName: string): BackOfficeActivityType {
        return lodash.find(activities, (activity) => {
            return this.isActivityName(activity, actionTypeName);
        });
    }

    isActivityActive(activities: BackOfficeActivityType[], actionTypeName: string): boolean {
        const foundActivity = this.getFeatureActivityByName(activities, actionTypeName);

        return !!(foundActivity && foundActivity.selected);
    }

    getUpdatedTeamActivities(allActivities: ActivityType[], teamActivities: string[]): ActivityType[] {
        const updatedTeamActivities: ActivityType[] = [];
        for (const activityName of teamActivities) {
            const foundActivity = lodash.find(allActivities, {name: activityName});
            if (foundActivity) {
                updatedTeamActivities.push(foundActivity);
            }
        }
        return updatedTeamActivities;
    }

    mapBackofficeTeamItem(teamItem: TeamType): BackOfficeTeamType {
        const backofficeTeamItem: BackOfficeTeamType = {} as BackOfficeTeamType;
        backofficeTeamItem.name = teamItem.name;
        backofficeTeamItem.id = teamItem.id;
        backofficeTeamItem.etag = teamItem.etag;
        backofficeTeamItem.features = {};
        backofficeTeamItem.organizationalRole = teamItem.organizationalRole;
        return backofficeTeamItem;
    }

    private hasSelectedActivities(teamItem: BackOfficeTeamType): boolean {
        return Object.values(teamItem.features).some((activities) => {
            return activities.some(item => item.selected === true);
        });
    }

    private translateLanguage(language: string, labelProperty?: string): string {
        const languageToTranslate = labelProperty ? `label.${labelProperty}.${language}` : `label.${language}`;
        return this.translatePipe.transform(languageToTranslate);
    }

    private addSelectedValue(activities: ActivityType[], valueOfMasterDataSelected: boolean): BackOfficeActivityType[] {
        const updatedActivities: BackOfficeActivityType[] = [];
        let updatedActivity: BackOfficeActivityType = {} as BackOfficeActivityType;
        for (const activity of activities) {
            updatedActivity = {
                ...activity,
                isCollapsed: false,
                selected: valueOfMasterDataSelected,
                translatedFeatureName: this.translateLanguage(activity.featureName, 'featureName'),
                translatedName: this.translateLanguage(activity.name, 'activityName'),
                translatedFeatureNameDeburr: lodash.deburr(this.translateLanguage(activity.featureName, 'featureName')),
            };
            updatedActivities.push(updatedActivity);
        }
        return updatedActivities;
    }
}
