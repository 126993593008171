import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppointmentProvider, AppointmentType, ConfirmActionModalService} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {
    AppointmentActionButtonsOptionsType,
} from '../appointment-action-buttons/appointment-action-buttons.types';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../utils/general.utils';
import {DateTimeUtils} from '../../utils/dateTime.utils';

@Component({
    selector: 'app-appointment-paid-status-change-button',
    templateUrl: './appointment-paid-status-change-button.component.html',
    styleUrls: ['./appointment-paid-status-change-button.component.scss']
})
export class AppointmentPaidStatusChangeButtonComponent implements OnInit, OnChanges {
    @Output() patientAppointmentAction = new EventEmitter<AppointmentType>();
    @Input() options: AppointmentActionButtonsOptionsType;

    displayButton = false;

    constructor(private appointmentProvider: AppointmentProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private modalService: NgbModal,
                private generalUtils: GeneralUtils,
                private confirmActionModalService: ConfirmActionModalService,
                private activeModal: NgbActiveModal,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.setDisplayButton();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options?.currentValue) {
            this.setDisplayButton();
        }
    }

    private setDisplayButton() {
        this.displayButton =
            !this.options?.paid && !this.dateTimeUtils.isExpiredDate(this.options?.appointmentDate) && !this.options?.cancelled;
    }

    openConfirmActionModal() {
        this.confirmActionModalService.displayConfirmActionModal().result.then((response) => {
            if (response) {
                this.setAppointmentToPaid();
            }
        });
    }

    private setAppointmentToPaid() {
        this.loaderAction({start: true});
        const oldAppointment = {
            id: this.options?.appointmentId,
            etag: this.options?.etag,
            paid: this.options?.paid
        } as AppointmentType;
        const newAppointment = {
            id: this.options?.appointmentId,
            etag: this.options?.etag,
            paid: true
        } as AppointmentType;
        // @ts-ignore
        this.appointmentProvider.updateEntry(oldAppointment, newAppointment).subscribe((response: AppointmentType) => {
            this.activeModal.dismiss();
            this.messagesService.success('toastr.success.appointmentUpdated');
            this.patientAppointmentAction.next(response);
            this.loaderAction({start: false});
        }, err => {
            this.loaderAction({start: false});
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private loaderAction({start}: {start: boolean}) {
        if (start) {
            if (this.options.onActionLoaderId) {
                this.ngxLoader.startLoader(this.options.onActionLoaderId);
            } else {
                this.ngxLoader.start();
            }
        } else {
            if (this.options.onActionLoaderId) {
                this.ngxLoader.stopLoader(this.options.onActionLoaderId);
            } else {
                this.ngxLoader.stop();
            }
        }
    }
}
