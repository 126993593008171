import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
    ResourceUtilizationFiltersSearchType,
    ResourceUtilizationFilterWrapperInitialValuesType
} from './resource-utilization-filters.types';
import {DateRangeResponseType, DateRangeType, GenericFilterResultType} from 'sked-base';
import {ResourceUtilizationFiltersUtil} from './resource-utilization-filters-util';
import * as lodash from 'lodash';
import {ResourceUtilizationUtils} from '../resource-utilization.utils';
import {ResourceUtilizationStateComponentsEnum} from '../resource-utilization.types';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-resource-utilization-filters',
    templateUrl: './resource-utilization-filters.component.html',
    styleUrls: ['./resource-utilization-filters.component.scss']
})
export class ResourceUtilizationFiltersComponent implements OnInit, OnDestroy {
    @Output() search: EventEmitter<ResourceUtilizationFiltersSearchType> = new EventEmitter<ResourceUtilizationFiltersSearchType>();

    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();

    constructor(
        public resourceUtilizationUtils: ResourceUtilizationUtils,
        private resourceUtilizationFiltersUtil: ResourceUtilizationFiltersUtil
    ) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.registerToClearFilters();
    }

    ngOnDestroy(): void {
    }

    // Filter values changed
    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.fromDate = dateRange.fromDate;
        this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.toDate = dateRange.toDate;
        this.validateDateRange(dateRange.isValid);
    }

    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues).forEach((filterName: string) => {
            this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues[filter.name] = filter.value;
        }
        // Validate
        this.validateFilterWrapperFilters();
    }

    // Reset filters action
    onClear() {
        // Reset initial validations and date range options
        this.resetValidationsAndDateRange();
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
    }

    // Search action
    onSearch() {
        const filterValues: ResourceUtilizationFiltersSearchType = this.resourceUtilizationFiltersUtil.mapFilterValuesForSearch(
            this.resourceUtilizationUtils.filtersOptions.dateRangeOptions,
            this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues
        );
        this.validateDateRange();
        this.validateFilterWrapperFilters();
        if (this.resourceUtilizationUtils.filtersOptions.areFiltersValid) {
            this.search.emit(filterValues);
            this.changeCollapseFilterSection(true);
        }
    }

    changeCollapseFilterSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.resourceUtilizationUtils.filtersOptions.areFiltersCollapsed =
            value ?? !this.resourceUtilizationUtils.filtersOptions.areFiltersCollapsed;
        // Update state
        this.resourceUtilizationUtils.updateFilterState(['areFiltersCollapsed']);
    }

    // Validations
    private validateDateRange(isValidInner: boolean = true) {
        this.resourceUtilizationUtils.filtersOptions.filterValidations.dateRange = {
            isValid: isValidInner && !!this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.fromDate &&
                !!this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.toDate,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    private validateFilterWrapperFilters() {
        // Center is required
        this.resourceUtilizationUtils.filtersOptions.filterValidations.location.isValid =
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.location;
        // Either Resource or (Resource type and Speciality) is required
        this.resourceUtilizationUtils.filtersOptions.filterValidations.resource.isValid =
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resource ||
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resourceType;
        this.resourceUtilizationUtils.filtersOptions.filterValidations.resourceType.isValid =
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resource ||
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resourceType;
        this.resourceUtilizationUtils.filtersOptions.filterValidations.speciality.isValid =
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.speciality ||
            !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resource ||
            (!!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.resourceType &&
                !!this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues.speciality);
        this.updateFiltersValidation();
    }

    private updateFiltersValidation() {
        this.resourceUtilizationUtils.filtersOptions.areFiltersValid =
            this.resourceUtilizationUtils.filtersOptions.filterValidations.resource.isValid &&
            this.resourceUtilizationUtils.filtersOptions.filterValidations.resourceType.isValid &&
            this.resourceUtilizationUtils.filtersOptions.filterValidations.speciality.isValid &&
            this.resourceUtilizationUtils.filtersOptions.filterValidations.location.isValid &&
            this.resourceUtilizationUtils.filtersOptions.filterValidations.dateRange.isValid;
    }

    // Load data
    private loadOptionsForDateRange() {
        this.resourceUtilizationUtils.filtersOptions.dateRangeOptions = this.resourceUtilizationFiltersUtil.getInitialDateRangeOptions();
        this.updateDateRangeValue.next({
            fromDate: this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.fromDate,
            toDate: this.resourceUtilizationUtils.filtersOptions.dateRangeOptions.toDate
        } as DateRangeType);
        this.validateDateRange();
    }

    private resetValidationsAndDateRange() {
        // Reset validations
        this.resourceUtilizationUtils.filtersOptions.filterValidations = this.resourceUtilizationFiltersUtil.getInitialFilterValidations();
        // Reset options for date range
        this.loadOptionsForDateRange();
        // Reset values for search event
        this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues =
            this.resourceUtilizationUtils.filtersOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.resourceUtilizationUtils.filtersOptions.filterWrapperInitialValues)
                : {} as ResourceUtilizationFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.validateFilterWrapperFilters();
    }

    private loadOptionsFromState() {
        // Load options from state (filter wrapper component needs special attention)
        this.resourceUtilizationUtils.filtersOptions = {
            ...lodash.cloneDeep(this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions),
            // Need initial values to show them on filters
            filterWrapperOptions: this.resourceUtilizationFiltersUtil.getFilterWrapperOptions(
                this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions.resourceUtilizationFiltersValues
            )
        };
        // Tell filter wrapper component to preselect values from state
        this.updateFilterWrapperValues.next(
            this.resourceUtilizationFiltersUtil.getInitialFilterWrapperValuesFromPreviousValues(
                this.resourceUtilizationUtils.filtersOptions.resourceUtilizationFiltersValues
            )
        );
    }

    private registerToClearFilters() {
        this.resourceUtilizationUtils?.clearFiltersEmitter?.subscribe(() => {
            this.onClear();
        });
    }

    private loadInitialValues() {
        if (this.resourceUtilizationUtils.shouldLoadState(ResourceUtilizationStateComponentsEnum.filtersOptions)) {
            this.loadOptionsFromState();
            return;
        }
        // Load options for the filter wrapper component
        this.resourceUtilizationUtils.filtersOptions.filterWrapperOptions =
            this.resourceUtilizationFiltersUtil.getFilterWrapperOptions(this.resourceUtilizationUtils.filtersOptions.filterWrapperInitialValues);
        // Load initial validations and date range options
        this.resetValidationsAndDateRange();
        // Reset the initial values so the reset button will clear the filters instead of resetting to these initial values
        this.resourceUtilizationUtils.filtersOptions.filterWrapperInitialValues = undefined;
    }
}
