import {Injectable} from "@angular/core";
import {Filter, ODataQueryObjectType, PatientDependentFiltersType} from "sked-base";
import {TableFiltersType} from "../../../data-model/general.type";
import {PatientAppointmentListRequestFilters, PatientMergeAppointmentTablePaginationInfo} from "./patient-merge.types";

@Injectable({
    providedIn: 'root'
})
export class PatientMergeUtils {

    constructor() { }

    getInitialPaginationConfig(): PatientMergeAppointmentTablePaginationInfo {
        return {
            tableFilters: this.getInitialTableFilters(),
            itemsPerPageList: [5],
            showItemsPerPageDropdown: false,
            totalTableItems: 0
        }
    }

    getPatientQueryFilter(): ODataQueryObjectType {
        const queryFilter: ODataQueryObjectType = {} as ODataQueryObjectType;
        queryFilter.expand = [
            {Country: {select: ['Id', 'CountryCode', 'CountryName']}},
            {Region: {select: ['Id', 'Name']}}
        ];
        return queryFilter;
    }

    getAppointmentListQuery(tableFilters: TableFiltersType): ODataQueryObjectType {
        const appointmentListRequestFilters: PatientAppointmentListRequestFilters = {pageFilters: tableFilters};
        return {
            count: true,
            skip: (appointmentListRequestFilters.pageFilters.currentPage - 1) * appointmentListRequestFilters.pageFilters.itemsPerPage,
            top: appointmentListRequestFilters.pageFilters.itemsPerPage,
            filter: this.getAppointmentListQueryFilter(tableFilters.filter.patientId)
        };
    }

    getPatientDependentFilters(): PatientDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: []
        };
    }

    private getAppointmentListQueryFilter(patientId: string): Filter {
        return [{
            PatientId: {
                eq: {
                    type: 'guid',
                    value: patientId
                }
            }
        }];
    }

    private getInitialTableFilters(): TableFiltersType {
        return {
            itemsPerPage: 5,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }
}
