import {IGenericProvider} from 'sked-base/lib/data-model/genericProviderInterface';
import {Subject} from 'rxjs';
import {ODataQueryObjectType} from 'sked-base';
import {IdNameType, ODataFilterQueryType, ODataOrderByQueryType} from '../../../data-model/general.type';
import {IgnoredDependency} from '@angular/compiler-cli/ngcc/src/dependencies/dependency_resolver';

export interface MultiSelectTableOptionsType {
    provider: IGenericProvider;
    providerMethod: string;
    confirmDeleteParentId: string;
    confirmDeleteRefName: string;
    confirmDeleteProvider: IGenericProvider;
    getQueryForItems: (skip?: number, count?: boolean, filter?: ODataFilterQueryType, top?: number, orderBy?: string[]) => ODataQueryObjectType;
    initialSelectedItems?: IdNameType[];
    assignedItemsLabel?: string;
    columns: MultiSelectTableColumnType[];
    screenTemplateLayoutAction: string;
    filterBySpecialProperty?: string;
    tableMaxHeight?: string;
    limitNumberOfSelectedItemsTo1?: boolean;
}

export interface MultiSelectTableColumnType {
    type: MultiSelectTableColumnKindEnum;
    headerLabel: string;
    displayUpperHeader?: boolean;
    itemPropertyNameInTableRow: string;
    itemPropertyExpandName?: string;
    useYesNoPipe?: boolean;
    provider?: IGenericProvider;
    buttonName?: string;
    dependentFilters?: any;
    propertyName?: string;
    propertyFilterIdName?: string;
    propertyFilterIdNameMapped?: string;
    customFunctionText?: any;
    customHTMLTemplate?: any;
    orderByProperty?: string;
}

export enum MultiSelectTableColumnKindEnum {
    FILTER = 'FILTER',
    DISPLAY = 'DISPLAY'
}
