import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../utils/general.utils';
import {CommentsModalComponent} from './comments-modal/comments-modal.component';
import {AppointmentCommentsOptionsType} from './appointment-comments.types';

@Component({
    selector: 'app-appointment-comments',
    templateUrl: './appointment-comments.component.html',
    styleUrls: ['./appointment-comments.component.scss']
})
export class AppointmentCommentsComponent implements OnInit {
    @Input() options: AppointmentCommentsOptionsType;

    constructor(private generalUtils: GeneralUtils,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    openAppointmentComments() {
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'appointment-comments';

        const modalRef = this.modalService
            .open(CommentsModalComponent, modalOptions);

        modalRef.componentInstance.options = this.options;

        modalRef.result.then(() => {
        }, (onClose) => {
            //x button was clicked, previous values needs to be displayed next time
        });
    }

}
