<div class="container-fluid create-speciality-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-speciality-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase" translate="label.speciality2"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{specialityItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{specialityItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.code"></td>
                                    <td class="text-right">{{specialityItem?.code}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.tags"></td>
                                    <td class="text-right">
                                        <span *ngFor="let tag of specialityItem.tags; last as islast">
                                            {{tag.name}}<span *ngIf="!islast">, </span>
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3" *ngIf="screenTemplateLayout.action !== constants.CREATE">
                        <div class="form-group" [ngClass]="{'disabled-input': screenTemplateLayout.action !== constants.CREATE}">
                            <label translate="label.shortId"></label>
                            <input type="text" name="shortId"
                                   class="input-element"
                                   placeholder="{{'label.shortId'|translate}}"
                                   [(ngModel)]="specialityItem.shortId">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   [(ngModel)]="specialityItem.name"
                                   [ngClass]="{'input-element-has-error': !specialityItem.name}"
                                   placeholder="{{'label.name'|translate}}">
                            <div *ngIf="!specialityItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.code"></label>
                            <input type="text" name="code"
                                   class="input-element"
                                   placeholder="{{'label.code'|translate}}"
                                   [(ngModel)]="specialityItem.code"
                                   [ngClass]="{'input-element-has-error': generalUtils.isOverMaxLength(specialityItem?.code, 100)}">
                            <div *ngIf="generalUtils.isOverMaxLength(specialityItem?.code, 100)" class="help-block">
                                <span>{{"label.error.maxLength" | translate: { max: 100 } }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!--Tags-->
                    <div class="col-6">
                        <div class="form-group customSelect">
                            <label translate="label.tags"></label>
                            <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                          [buttonName]='"label.newTag"'
                                          [dependentFilters]="mainDependentFilters?.tags"
                                          [initialFilterValues]="specialityItem.tags"
                                          [isMultipleSelectedItem]="true"
                                          [useSelectedValueAsLabel]="true"
                                          [hideSelectedItems]="true"
                                          [providerInstance]="tagProvider">
                            </sbase-filter>
                        </div>
                    </div>
                </div>
            </ng-container>

        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button class="btn mr-3"
                (click)="goToParentPage()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button class="btn theme-btn"
                (click)="saveSpecialityData(specialityItem)"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                translate="{{screenTemplateLayout.createOrSaveButton}}"
                [disabled]="!specialityItem.name">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'SpecialityUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!-- =========================================== -->
    <!--End Footer-->
</div>
