import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {SlotDisplayType} from 'sked-base/lib/data-model/slotTypes';
import * as moment from 'moment';

@Component({
    selector: 'app-slot-without-planned-capacity-modal',
    templateUrl: './slot-without-planned-capacity-modal.component.html',
    styleUrls: ['./slot-without-planned-capacity-modal.component.scss']
})
export class SlotWithoutPlannedCapacityModalComponent implements OnInit {
    @Input() options: SlotDisplayType;
    time: NgbTimeStruct;
    isTimeValid = true;
    showAppointmentType = true;

    private timeInMinutes: number;

    constructor(public activeModal: NgbActiveModal,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        if (this.options.appointmentType?.id) {
            this.showAppointmentType = false;
        }
        this.time = this.dateTimeUtils.hourMinuteStringToNgbTimeStruct(this.options.time);
        this.timeInMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.time);
    }

    onTimeChanged(time: NgbTimeStruct) {
        this.timeInMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.dateTimeUtils.ngbTimeStructToHourMinuteString(time));
        const startTimeMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.time);
        const endTimeMinutes = this.dateTimeUtils.getMinutesFromStringHours(this.options.timeInterval.split('- ')[1]);

        this.isTimeValid = this.timeInMinutes >= startTimeMinutes && this.timeInMinutes <= (endTimeMinutes - this.options.appointmentType.duration);
    }

    onSaveModal() {
        //save new date time & duration
        this.options.duration = this.options.appointmentType.duration;
        this.options.baseDuration = this.options.appointmentType.duration;
        this.options.time = this.dateTimeUtils.getStringHourFromMinutes(this.timeInMinutes);
        this.options.dateTime = `${this.options.dateTime.substring(0, 11)}${this.options.time}:00${this.options.dateTime.substring(19, 33)}`;

        this.activeModal.close(this.options);
    }

    onCloseModal() {
        this.activeModal.dismiss('cancel');
    }

    sanitizeAppointmentTypeValue() {
        if (this.options?.appointmentType?.hasOwnProperty('$ngOptionValue')) {
            this.options.appointmentType = undefined;
        }
    }

}
