import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    CenterProvider,
    CenterType,
    ConfirmDeleteModalService,
    EntityTypeEnum,
    ResourceProvider,
    ResourceType,
    ResourceWorkScheduleProvider,
    ResourceWorkScheduleTimeSlotType,
    ResourceWorkScheduleType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {ResourceWorkScheduleMdUtils} from './resource-work-schedule-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TimeSlotsUtils} from '../../shared/utils/time-slots.utils';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-resource-work-schedule-md',
    templateUrl: './resource-work-schedule-md.component.html',
    styleUrls: ['./resource-work-schedule-md.component.scss']
})
export class ResourceWorkScheduleMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    resourceWorkScheduleMdList: ResourceWorkScheduleType[] = [];
    mainDependentFilters = this.resourceWorkScheduleMdUtils.getMainDependentFilters();
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;
    readonly RESOURCE_WORK_SCHEDULE = this.resourceWorkScheduleProvider.name;
    readonly CONSTANTS = constants;
    readonly DAYS_OF_WEEK = this.timeSlotsUtils.getWeekDays();

    constructor(
        private timeSlotsUtils: TimeSlotsUtils,
        public resourceWorkScheduleMdUtils: ResourceWorkScheduleMdUtils,
        public resourceWorkScheduleProvider: ResourceWorkScheduleProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public resourceProvider: ResourceProvider,
        public centerProvider: CenterProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('ResourceWorkScheduleRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createResourceWorkSchedule') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadResourceWorkScheduleMdData();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create ResourceWorkSchedule
    createResourceWorkSchedule(): void {
        this.router.navigate(['/createResourceWorkSchedule']);
    }

    editResourceWorkSchedule(resourceWorkSchedule: ResourceWorkScheduleType) {
        this.router.navigate(['/createResourceWorkSchedule'], {state: {resourceWorkSchedule, action: constants.EDIT}});
    }

    viewResourceWorkSchedule(resourceWorkSchedule: ResourceWorkScheduleType) {
        this.router.navigate(['/createResourceWorkSchedule'], {state: {resourceWorkSchedule, action: constants.VIEW}});
    }

    onSelectedResourceFilter(resourceList: ResourceType[]) {
        this.resourceWorkScheduleMdUtils.initialFilterValues.resource = resourceList;
        this.mainDependentFilters.center.resourceId = resourceList?.length > 0 ? resourceList[0].id : null;
        this.resourceWorkScheduleMdUtils.tableFilters.filter.resourceId = resourceList?.length > 0 ? resourceList[0].id : undefined;
        this.loadResourceWorkScheduleMdData();
    }

    onSelectedCenterFilter(centerList: CenterType[]) {
        this.resourceWorkScheduleMdUtils.initialFilterValues.center = centerList;
        this.mainDependentFilters.resource.locationId = centerList?.length > 0 ? centerList[0].id : null;
        this.resourceWorkScheduleMdUtils.tableFilters.filter.centerId = centerList?.length > 0 ? centerList[0].id : undefined;
        this.loadResourceWorkScheduleMdData();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.resourceWorkScheduleMdUtils.tableFilters.currentPage = page;
        this.loadResourceWorkScheduleMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.resourceWorkScheduleMdUtils.tableFilters.currentPage = 1;
        this.resourceWorkScheduleMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadResourceWorkScheduleMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.resourceWorkScheduleMdUtils.tableFilters.orderBy[property];
        if (this.resourceWorkScheduleMdUtils.tableFilters.orderBy) {
            this.resourceWorkScheduleMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadResourceWorkScheduleMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.resourceWorkScheduleMdUtils.tableFilters.orderBy[selectedItem];
        this.loadResourceWorkScheduleMdData(false);
    }

    displayConfirmDeleteItemModal(resourceWorkScheduleId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.resourceWorkScheduleProvider, resourceWorkScheduleId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(resourceWorkScheduleId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    getTimeSlotInfoString(timeSlot: ResourceWorkScheduleTimeSlotType): string[] {
        const DAYS_ARRAY = [];
        Object.entries(timeSlot).forEach(([key, value]) => {
            if (this.DAYS_OF_WEEK.find(day => day === key) && !!value) {
                DAYS_ARRAY.push((key));
            }
        });
        return DAYS_ARRAY;
    }

    // function to get ResourceWorkSchedule data
    private loadResourceWorkScheduleMdData(includeCount: boolean = true) {
        const queryFilter = this.resourceWorkScheduleMdUtils.getQueryFilterForResourceWorkScheduleMD(this.resourceWorkScheduleMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.resourceWorkScheduleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.resourceWorkScheduleMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.resourceWorkScheduleMdUtils.tableFilters = this.resourceWorkScheduleMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.resourceWorkScheduleProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadResourceWorkScheduleMdData();
                this.messagesService.success('toastr.success.resourceWorkScheduleDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadResourceWorkScheduleMdData(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.resourceWorkScheduleMdUtils.tableFilters = this.resourceWorkScheduleMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.resourceWorkScheduleMdUtils.initialFilterValues.center = [];
            this.resourceWorkScheduleMdUtils.initialFilterValues.resource = [];
        }
    }
}
