import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhoneNumberOutputEventType, PhoneNumberInputOptionsType, PhoneNumberType} from './phone-number-input.types';
import {GeneralUtils} from '../../utils/general.utils';
import {FormValidationType} from '../../../data-model/general.type';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {TranslatedLanguageService} from '../../services/translated-language.service';

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit {
    @Input() options: PhoneNumberInputOptionsType;
    @Output() phoneNumberChanged = new EventEmitter<PhoneNumberOutputEventType>();
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    isCountryCodeFocused = false;
    phoneNumber = {} as PhoneNumberType;
    phoneNumberValidation: FormValidationType = {
        isValid: false,
        errorMessage: ''
    };

    constructor(private generalUtils: GeneralUtils,
                private translatedLanguageService: TranslatedLanguageService) {
    }

    ngOnInit(): void {
        this.setInitialCountryCode();
        this.parsePhoneNumber();
        this.setPhoneNumberValidation();
        // If we are in edit mode we need to force the output event to be triggered in order to validate the input in the parent
        if (this.options?.isEditMode) {
            this.onPhoneNumberChanged(this.phoneNumber);
        }
    }

    onCountryCodeInputFocused(focused: boolean) {
        this.isCountryCodeFocused = focused;
    }

    onPhoneNumberChanged(phoneNumberValue: PhoneNumberType) {
        const {phoneNumber, countryCode} = phoneNumberValue;
        const phoneNumberInputValue = !!phoneNumber ? `+${countryCode}${phoneNumber}` : null;
        this.setPhoneNumberValidation();
        this.phoneNumberChanged.emit({
            phoneNumberWithCountryCode: phoneNumberInputValue,
            isValid: this.phoneNumberValidation.isValid
        });
    }

    private setInitialCountryCode() {
        if (!this.phoneNumber?.countryCode && !this.options?.initialPhoneNumberValue) {
            this.phoneNumber.countryCode = `${this.libPhoneUtil.getCountryCodeForRegion(this.translatedLanguageService.translatedLanguage)}`;
        }
    }

    private setPhoneNumberValidation() {
        const {phoneNumber, countryCode} = this.phoneNumber;
        this.phoneNumberValidation = this.generalUtils.getPhoneNumberValidation(phoneNumber, this.options?.isRequired, String(countryCode));
    }

    private parsePhoneNumber(): void {
        if (this.options?.initialPhoneNumberValue?.includes('+')) {
            // assume phone number that contains '+' has country code (is of the form +56987654321)
            try {
                const libFullPhoneNumber = this.libPhoneUtil.parseAndKeepRawInput(this.options.initialPhoneNumberValue);
                this.phoneNumber.phoneNumber = `${libFullPhoneNumber.getNationalNumber()}`;
                this.phoneNumber.countryCode = `${libFullPhoneNumber.getCountryCode()}`;
            } catch { // in case parseAndKeepRawInput throws error => leave current phone number as it is
            }
        }
    }
}
