import {Injectable} from '@angular/core';
import {
    PatientCalculatedTagRuleType,
    Expand,
    ODataQueryObjectType,
    MatchTypeEnum
} from 'sked-base';
import * as lodash from 'lodash';
import {
    MatchTypeModalFilterOptionsType,
    OutputTagModalFilterOptionsType,
    PatientCalculatedTagRuleModalFiltersType
} from './patient-calculated-tag-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';
import {TagModalFilterOptionsType} from '../slot-filter-rule/slot-filter-rule.types';

@Injectable({
    providedIn: 'root'
})
export class PatientCalculatedTagRuleUtils {

    constructor(private generalUtils: GeneralUtils) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'patientCalculatedTagRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name'
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.priority',
            hasSort: true,
            onSortByProperty: 'priority',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.inputTags',
            hasSort: false,
            onSortByProperty: 'inputTags',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.matchType',
            hasSort: true,
            onSortByProperty: 'matchType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.outputTag',
            hasSort: true,
            onSortByProperty: 'outputTag'
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'inputTags': {
                        filterQuery[lodash.upperFirst(item)] = {any: {Id: {eq: {type: 'guid', value: filter[item]}}}};
                        break;
                    }
                    case 'patientCalculatedTagRuleTableId':
                    case 'outputTagId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'matchType': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'patientCalculatedTagRuleTable' || item === 'outputTag') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            OutputTag: {select: ['Id', 'Name']},
            PatientCalculatedTagRuleTable: {select: ['Id', 'Name']},
            InputTags: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): PatientCalculatedTagRuleType {
        return {
            matchType: MatchTypeEnum.MatchAll,
            outputTag: undefined,
            patientCalculatedTagRuleTableId: undefined,
            inputTags: []
        } as PatientCalculatedTagRuleType;
    }

    getInitialModalFilters(): PatientCalculatedTagRuleModalFiltersType {
        return {
            inputTagsOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            matchTypeOptions: {
                ngModel: undefined
            } as MatchTypeModalFilterOptionsType,
            outputTagOptions: {
                ngModel: {}
            } as OutputTagModalFilterOptionsType,
            areFiltersActive: false,
        } as PatientCalculatedTagRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: PatientCalculatedTagRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: PatientCalculatedTagRuleType): PatientCalculatedTagRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }
}
