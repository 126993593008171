import {Injectable} from '@angular/core';
import {
    BackofficeBookingFiltersTemplateType
} from './booking-filters-management.types';
import * as lodash from 'lodash';
import {BookingFiltersTemplateType, BookingFiltersType} from 'sked-base';

@Injectable({
    providedIn: 'root'
})
export class BookingFiltersManagementUtils {
    constructor() {
    }

    mapBookingFiltersToSend(newBookingFilters: BackofficeBookingFiltersTemplateType): BookingFiltersTemplateType {
        const bookingFiltersToSend: BookingFiltersTemplateType = {} as BookingFiltersTemplateType;

        bookingFiltersToSend.name = newBookingFilters.name;
        bookingFiltersToSend.etag = newBookingFilters.etag;
        bookingFiltersToSend.id = newBookingFilters.id;

        bookingFiltersToSend.mainFilters = [];
        for (const item of newBookingFilters.mainFilters) {
            bookingFiltersToSend.mainFilters.push(item.name);
        }

        bookingFiltersToSend.moreFilters = [];
        for (const item of newBookingFilters.moreFilters) {
            bookingFiltersToSend.moreFilters.push(item.name);
        }

        return bookingFiltersToSend;
    }


    getInitialBookingFilters() {
        return {
            name: '',
            mainFilters: [],
            moreFilters: [],
            availableFilters: [],
            id: undefined
        };
    }

    mapBookingFilters(activeFilterList: BookingFiltersType[], bookingFiltersTemplate: BookingFiltersTemplateType):
        BackofficeBookingFiltersTemplateType {
        let scopedBookingFilters: BackofficeBookingFiltersTemplateType = {} as BackofficeBookingFiltersTemplateType;
        scopedBookingFilters = lodash.cloneDeep(bookingFiltersTemplate);
        // initialize the 3 filters arrays
        scopedBookingFilters.availableFilters = [];
        scopedBookingFilters.mainFilters = [];
        scopedBookingFilters.moreFilters = [];
        // check if mainFilters are found on activeFilters => this means the user can see them on screen
        for (const name of bookingFiltersTemplate.mainFilters) {
            const found = lodash.find(activeFilterList, {name});
            if (found) {
                scopedBookingFilters.mainFilters.push(found);
            }
        }
        // check if moreFilters are found on activeFilters => this means the user can see them on screen
        for (const name of bookingFiltersTemplate.moreFilters) {
            const found = lodash.find(activeFilterList, {name});
            if (found) {
                scopedBookingFilters.moreFilters.push(found);
            }
        }
        // move the remaining filters to available filters
        const filtersWithoutMain = lodash.differenceBy(activeFilterList, scopedBookingFilters.mainFilters, 'name');
        scopedBookingFilters.availableFilters = lodash.differenceBy(filtersWithoutMain, scopedBookingFilters.moreFilters, 'name');
        return scopedBookingFilters;
    }
}
