import {MatStepper} from '@angular/material/stepper';
import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {
    CenterProvider,
    TagProvider,
    ODataQueryObjectType,
    SpecialityProvider,
    AreaProvider,
    ServiceProvider,
    ServiceType,
    SubServiceProvider,
    ChannelType,
    CenterType,
    ChannelProvider,
    CoveragePlanProvider,
    MultiResourceBluePrintProvider,
    MultiResourceBluePrintType,
    ConfirmDeleteModalService, TagDependentFiltersScopeEnum, SearchFilterUtils
} from 'sked-base';
import {TagsType} from 'sked-base/lib/data-model/tagTypes';
import {forkJoin, Observable, of} from 'rxjs';
import {flatMap, take} from 'rxjs/operators';
import {BackofficeServiceMDType, ServiceMultiSelectTableOptionsType, ValidServiceObjectType} from './service-md.types';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {ServiceMdUtils} from './service-md.util';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AreaType} from 'sked-base/lib/data-model/areaTypes';
import {constants} from '../../shared/constants/constants';
import {Router} from '@angular/router';
import * as lodash from 'lodash';
import {BackofficeChannelType} from '../../data-model/channel.types';
import {IdNameType, ScreenTemplateLayoutType} from '../../data-model/general.type';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {SpecialitySearchType} from 'sked-base/lib/data-model/specialityTypes';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-service-md',
    templateUrl: './create-service-md.component.html',
    styleUrls: ['./create-service-md.component.scss']
})
export class CreateServiceMDComponent implements OnInit, AfterViewInit, OnDestroy {
    VIEW = constants.VIEW;
    CREATE = constants.CREATE;
    EDIT = constants.EDIT;
    SUB_SERVICE_FEATURE_NAME = 'admin-subServices';
    PATIENT_ACCESS_WAIT_LIST_FEATURE_NAME = 'patient-access-waitlist';
    isSubServiceFeatureActive = false;
    isPatientAccessWaitListFeatureActive = false;
    validTemplate: ValidServiceObjectType;
    channels: BackofficeChannelType[] = [];
    areas: AreaType[] = [];
    multiResourceBluePrints: MultiResourceBluePrintType[] = [];
    changeMultiResourceBluePrintNeedsCancelGraphCheck = false;
    mainDependentFilters: any;
    tags: TagsType[] = [];
    initialCenterValues: CenterType[] = [];
    centerButtonName = 'label.centers';
    specialityButtonName = 'label.speciality2';
    serviceItem: BackofficeServiceMDType;
    initialService: BackofficeServiceMDType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;
    totalStepsCount: number;
    messageErrorForMaxSubServices = '';
    serviceMultiSelectTableOptions: ServiceMultiSelectTableOptionsType;
    systemTag = {} as IdNameType;

    constructor(
        public centerProvider: CenterProvider,
        public specialityProvider: SpecialityProvider,
        public channelProvider: ChannelProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        public serviceProvider: ServiceProvider,
        public subServiceProvider: SubServiceProvider,
        public multiResourceBlueprintProvider: MultiResourceBluePrintProvider,
        public tagProvider: TagProvider,
        public areaProvider: AreaProvider,
        public messagesService: MessagesService,
        public serviceMdUtils: ServiceMdUtils,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public configDataService: ConfigDataService,
        public confirmDeleteService: ConfirmDeleteModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private translatedLanguageService: TranslatedLanguageService,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.isSubServiceFeatureActive = this.configDataService.isFeatureActive(this.SUB_SERVICE_FEATURE_NAME);
        this.isPatientAccessWaitListFeatureActive = this.configDataService.isFeatureActive(this.PATIENT_ACCESS_WAIT_LIST_FEATURE_NAME);
        this.setupInitialState();
        this.loadInitialData();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    onChannelRestrictionChange(channel: BackofficeChannelType) {
        if (channel.selected) {
            this.serviceItem.channelRestrictions.push(channel);
        } else {
            lodash.remove(this.serviceItem.channelRestrictions, (item) => {
                return item.enumValue === channel.enumValue;
            });
        }
    }

    getChannelsList(): Observable<{ value: ChannelType[], count?: number }> {
        return this.channelProvider.getEntries(this.translatedLanguageService.translatedLanguage);
    }

    getSortedCoveragePlanNameList(): string[] {
        const COVERAGE_PLAN_NAMES = this.serviceItem.coveragePlans.map(coveragePlan => coveragePlan.name);
        return COVERAGE_PLAN_NAMES.sort();
    }

    onChangeTags(tags) {
        this.tags = tags.map(tag => ({...tag, tagId: tag.id}));
        this.serviceItem.tags = tags;
    }

    onChangeBluePrint(item: MultiResourceBluePrintType) {
        if (!!this.initialService.multiResourceBluePrint?.id && this.screenTemplateLayout.action === constants.EDIT
            && this.changeMultiResourceBluePrintNeedsCancelGraphCheck) {
            // Don't change multiResourceBluePrint item yet
            this.changeDetectorRef.detectChanges();
            this.serviceItem.multiResourceBluePrint = lodash.find(this.multiResourceBluePrints,
                {id: this.initialService.multiResourceBluePrint.id});
            this.changeDetectorRef.detectChanges();
            // Display modal for cancelGraphRef
            this.confirmDeleteService.displayConfirmDeleteModal(this.serviceProvider, this.serviceItem.id,
                'MultiResourceBluePrint', this.initialService.multiResourceBluePrint.id, false,
                'label.confirmChangeEntry', 'label.cannotChangeEntry').result.then(() => {
            }, (response) => {
                if (response) {
                    // Update the dropdown
                    this.serviceItem.multiResourceBluePrint = item;
                    this.changeDetectorRef.detectChanges();
                    this.changeMultiResourceBluePrintNeedsCancelGraphCheck = false;
                }
            });
        }
    }

    onAddExternalKeyItem() {
        this.serviceItem.serviceExternalKeys.push(this.serviceMdUtils.getEmptyExternalKeyItem());
    }

    onRemoveExternalKeyItem(index: number) {
        this.serviceItem.serviceExternalKeys.splice(index, 1);
    }

    onSelectedCenter(selectedCenters): void {
        this.serviceItem.centers = selectedCenters;
        this.validateFields(this.serviceItem);
    }

    onSelectedSubService(subServicesList): void {
        this.serviceItem.subServices = subServicesList;
        this.validateFields(this.serviceItem);
    }

    onSelectedCoveragePlan(coveragePlanList): void {
        this.serviceItem.coveragePlans = coveragePlanList;
        this.validateFields(this.serviceItem);
    }

    onSelectedSpecialityFilter(specialityFilterList: SpecialitySearchType[]): void {
        if (specialityFilterList.length > 0) {
            this.serviceItem.speciality = specialityFilterList[0];
        } else {
            this.serviceItem.speciality = undefined;
        }
        this.validateFields(this.serviceItem);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        stepper.next();
    }

    onStepChange(stepper: StepperSelectionEvent) {
        if (stepper.selectedIndex === 0 || stepper.selectedIndex === 5) {
            this.setTags(this.tags, stepper.selectedIndex);
        }
    }

    saveServiceData(service: BackofficeServiceMDType) {
        if (this.isTheWholeFormValid(this.validTemplate)) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createService(service);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialService, service)) {
                    this.messagesService.success('toastr.success.serviceEdit', true);
                    this.goToParentPage();
                } else {
                    this.editService(this.initialService, service);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/services']);
    }

    displayErrorMessageForMaxSubServices(serviceItem: BackofficeServiceMDType) {
        if (serviceItem.maxSubServices === null) {
            this.messageErrorForMaxSubServices = '';
            return;
        }
        if (serviceItem.maxSubServices <= 0) {
            this.messageErrorForMaxSubServices = 'mustBePositiveNumber';
            return;
        }
        if (serviceItem.minSubServices > serviceItem.maxSubServices) {
            this.messageErrorForMaxSubServices = 'maxSubServicesInvalid';
            return;
        }
        this.messageErrorForMaxSubServices = '';
    }

    areStepsValid(validTemplate: ValidServiceObjectType, currentStep): boolean {
        switch (currentStep) {
            case 0:
                return (validTemplate.isNameValid && validTemplate.isAreaValid && validTemplate.isSpecialityValid && validTemplate.isDurationValid);
            case 1:
                return validTemplate.areCentersValid;
            case 2:
                return validTemplate.areCoveragePlansValid;
            case 3:
                return (validTemplate.isMaxSubServicesNumberValid);
            default:
                return true; // other steps which don't need validation
        }
    }

    isLinearStepperOrNot(validTemplate: ValidServiceObjectType) {
        for (const property in validTemplate) {
            if (validTemplate[property] === false) {
                return true;
            }
        }

        return false;
    }

    isTheWholeFormValid(validTemplate: ValidServiceObjectType): boolean {
        if (!this.areStepsValid(validTemplate, 0)) {
            return false;
        }

        if (!this.areStepsValid(validTemplate, 1)) {
            return false;
        }

        if (!this.areStepsValid(validTemplate, 2)) {
            return false;
        }

        return true;
    }

    removeItemFromList(list: any[], item: any, serviceItem: BackofficeServiceMDType, refObjectName: string) {
        const isItemAddedOnEditAction = this.serviceMdUtils.objectAddedOnEditAction(this.initialService, item, refObjectName);
        if (item && serviceItem) {
            if (this.screenTemplateLayout.action === this.EDIT && isItemAddedOnEditAction) {
                this.confirmDeleteService.displayConfirmDeleteModal(this.serviceProvider, serviceItem.id,
                    refObjectName, item.id, false).result.then(() => {
                }, (response) => {
                    if (response) {
                        const selectedList = lodash.lowerFirst(refObjectName);
                        lodash.remove(serviceItem[selectedList], {id: item.id});
                        this.validateFields(serviceItem);
                    }
                });
            } else {
                lodash.remove(list, {id: item.id});
                this.validateFields(serviceItem);
            }
        }
    }

    onServiceValueChanged(service: BackofficeServiceMDType) {
        this.validateFields(service);
    }

    onMaxSubServicesValueChanged(service: BackofficeServiceMDType) {
        this.displayErrorMessageForMaxSubServices(service);
        this.validateFields(service);
    }

    onMinSubServicesValueChanged(service: BackofficeServiceMDType) {
        this.displayErrorMessageForMaxSubServices(service);
        this.validateFields(service);
    }

    goToEdit() {
        history.replaceState({service: this.serviceItem, action: constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private loadInitialData() {
        this.ngxLoader.start();
        forkJoin([
            this.getAreasList(),
            this.getSelectedTagsForService(this.screenTemplateLayout.action),
            this.getChannelsList(),
            this.getMultiResourceBlueprintList()])
            .subscribe(([areas, tags, channels, multiResourceBluePrint]) => {
                this.areas = areas.value;
                const systemTag = tags?.value.find(tag => tag.system);
                this.systemTag = {...systemTag, tagId: systemTag?.id};
                this.initialService.tags = lodash.cloneDeep(tags?.value.map(tag => ({...tag, tagId: tag.id})) ?? []);
                this.setTags(this.initialService.tags);
                this.channels = this.addSelectedValue(channels.value);
                this.multiResourceBluePrints = multiResourceBluePrint.value;
                if (this.screenTemplateLayout.action !== this.CREATE) {
                    if (this.initialService.area && this.initialService.area.id) {
                        this.serviceItem.area = lodash.find(this.areas, {id: this.initialService.area.id});
                    }
                    if (this.initialService.multiResourceBluePrint && this.initialService.multiResourceBluePrint.id) {
                        this.serviceItem.multiResourceBluePrint = lodash.find(this.multiResourceBluePrints,
                            {id: this.initialService.multiResourceBluePrint.id});
                    }
                    if (this.initialService.channelRestrictions && this.initialService.channelRestrictions.length > 0) {
                        this.channels = this.updateChannelRestrictions(this.channels, this.initialService.channelRestrictions);
                    }
                }
                this.serviceMultiSelectTableOptions = this.serviceMdUtils.getServiceMultiSelectTableOptions(
                    this.initialService, this.screenTemplateLayout.action
                );
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private setTags(tags: IdNameType[], step?: number) {
        const tagsWithSystem = !!this.systemTag?.id ? lodash.uniqBy([...tags, this.systemTag], 'id') : tags;
        const tagsExceptSystem = tags.filter(tag => tag.id !== this.systemTag?.id);
        if (!this.generalUtils.isNullOrUndefined(step)) {
            this.tags = step === 0 ? tagsExceptSystem : tagsWithSystem;
        } else {
            this.tags = this.screenTemplateLayout.action === constants.EDIT ? tagsExceptSystem : tagsWithSystem;
        }
        this.serviceItem.tags = lodash.cloneDeep(this.tags);
    }

    private createService(service: BackofficeServiceMDType) {
        this.ngxLoader.start();
        const serviceToSend = this.serviceMdUtils.mapServiceToSend(service);
        this.serviceProvider.addEntry(serviceToSend)
            .pipe(
                flatMap((createdService: any) => {
                    return this.updateTagsForService(createdService.id, service.tags);
                }),
                take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newServiceAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private editService(oldService: BackofficeServiceMDType, newService: BackofficeServiceMDType) {
        const oldServiceToSend: ServiceType = this.serviceMdUtils.mapServiceToSend(oldService, this.systemTag?.id);
        const newServiceToSend: ServiceType = this.serviceMdUtils.mapServiceToSend(newService, this.systemTag?.id);

        this.ngxLoader.start();
        this.serviceProvider.updateEntry(oldServiceToSend, newServiceToSend)
            .pipe(
                flatMap((createdService: any) => {
                    return this.updateTagsForService(createdService.id, newService.tags);
                }),
                take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.serviceEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(serviceItem: BackofficeServiceMDType): void {
        this.validTemplate.isNameValid = !!(serviceItem && serviceItem.name);
        // the duration must be a positive number
        this.validTemplate.isDurationValid = this.serviceMdUtils.getDurationValidation(serviceItem.defaultDuration);
        this.validTemplate.isAreaValid = !!(serviceItem && serviceItem.area && serviceItem.area.id);
        this.validTemplate.isSpecialityValid = !!(serviceItem && serviceItem.speciality && serviceItem.speciality.id);
        if (this.isSubServiceFeatureActive) {
            this.validTemplate.isMaxSubServicesNumberValid = this.serviceMdUtils.getIsMaxSubServicesNumberValid(serviceItem);
        }
        this.validTemplate.areCentersValid = !!(serviceItem && serviceItem.centers.length > 0);
        this.validTemplate.areCoveragePlansValid = !!(serviceItem && serviceItem.coveragePlans.length > 0);
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            location: this.serviceMdUtils.getLocationDependentFilters(),
            subService: this.serviceMdUtils.getSubServiceDependentFilters(),
            coveragePlan: this.serviceMdUtils.getCoveragePlanDependentFilters(),
            speciality: this.serviceMdUtils.getSpecialityDependentFilters(),
            tags: {
                ...this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedService, false),
                system: false
            }
        };
        this.serviceItem = this.serviceMdUtils.getInitialServiceValue();
        if (history.state && history.state.service) {
            this.initialService = history.state.service;
            this.initialService.channelRestrictions =
                this.initialService.channelRestrictions.map((channel) => ({...channel, enumValue: channel.channel}));
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.changeMultiResourceBluePrintNeedsCancelGraphCheck = true;
            }
            this.serviceItem = lodash.cloneDeep(history.state.service);
            if (this.generalUtils.isEmpty(this.serviceItem.multiResourceBluePrint)) {
                this.serviceItem.multiResourceBluePrint = 'noValue' as unknown as IdNameType;
            }
        } else {
            this.initialService = this.serviceMdUtils.getInitialServiceValue();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }

        this.validTemplate = this.serviceMdUtils.getInitValidTempleObject();
        this.validateFields(this.serviceItem);
    }

    private addSelectedValue(channels: ChannelType[]): BackofficeChannelType[] {
        return lodash.map(channels, (channel) => {
            channel.selected = false;
            return channel;
        });
    }

    private updateChannelRestrictions(channelsList: BackofficeChannelType[], selectedChannels: ChannelType[]): BackofficeChannelType[] {
        for (const item of channelsList) {
            if (lodash.find(selectedChannels, {enumValue: item.enumValue})) {
                item.selected = true;
            }
        }
        return channelsList;
    }

    private getMultiResourceBlueprintList(): Observable<{ value: MultiResourceBluePrintType[], count?: number }> {
        const filterQuery: ODataQueryObjectType = {
            select: ['Id', 'Name']
        };
        return this.multiResourceBlueprintProvider.getEntries(filterQuery);
    }

    private getSelectedTagsForService(action) {
        if (action !== this.CREATE) {
            return this.tagProvider.getTagsAssignedToEntity({
                entityType: 'Service',
                entityId: history.state?.service?.id
            });
        } else {
            return of({value: []});
        }
    }

    private getAreasList(): Observable<{ value: AreaType[], count?: number }> {
        const filterQuery: ODataQueryObjectType = {
            select: ['Id', 'Name']
        };
        return this.areaProvider.getEntries(filterQuery);
    }

    private updateTagsForService(serviceId: string, tags: IdNameType[]) {
        return this.tagProvider.updateTagsForService(serviceId, lodash.map(tags, 'id'));
    }
}
