<div class="appointment-card-container font-weight-high-contrast">
    <ngx-ui-loader *ngIf="loaderId" [hasProgressBar]="false" [loaderId]="loaderId">
    </ngx-ui-loader>
    <div class="appointment-card-data">
        <ng-container *ngIf="options?.displayPatientInformation">
            <!-- PATIENT NAME -->
            <div class="appointment-card-item font-weight-bold text-dark"
                 *ngIf="options?.appointment?.patient?.fullName">
                <i class="fas fa-user"></i>
                <span>
                <span>{{options?.appointment?.patient?.fullName}}</span>
                <span class="vertical-line mx-2"></span>
            </span>
            </div>
            <!-- PATIENT PHONE NUMBER -->
            <div class="appointment-card-item text-dark"
                 *ngIf="(options?.appointment?.patient?.mainPhoneNumber || options?.appointment?.patient?.alternatePhoneNumber)">
                <i class="fas fa-phone"></i>
                <span>
                <span *ngIf="options?.appointment?.patient?.mainPhoneNumber">
                    {{options?.appointment?.patient?.mainPhoneNumber}}
                </span>
                 <span *ngIf="options?.appointment?.patient?.alternatePhoneNumber">,&nbsp;</span>
                <span *ngIf="options?.appointment?.patient?.alternatePhoneNumber">
                    {{options?.appointment?.patient?.alternatePhoneNumber}}
                </span>
                <span class="vertical-line-small mx-2"></span>
            </span>
            </div>
            <!-- PATIENT EMAIL -->
            <div class="appointment-card-item text-dark"
                 *ngIf="options?.appointment?.patient?.email">
                <i class="fas fa-envelope"></i>
                <span>
                <span>{{options?.appointment?.patient?.email}}</span>
                <span class="vertical-line mx-2"></span>
            </span>
            </div>
        </ng-container>
        <!-- SERVICE -->
        <div class="appointment-card-item theme-color"
             *ngIf="options?.appointment?.service?.name">
            <i class="fas fa-tv"></i>
            <span>
            <span class="font-weight-bold">{{options?.appointment?.service?.name}}&nbsp;</span>
            <span>{{options?.appointment?.service?.speciality?.name}},&nbsp;</span>
        </span>
        </div>
        <!-- DURATION -->
        <div class="appointment-card-item theme-color font-weight-bold"
             *ngIf="options?.appointment?.duration">
            <span>{{options?.appointment?.duration}}</span>
            <span>
            <span>{{'label.minuteShort' | translate}}</span>
            <span *ngIf="options?.appointment?.subServices?.length > 0">,&nbsp;</span>
            <span class="vertical-line-small mx-2" *ngIf="options?.appointment?.subServices?.length <= 0"></span>
        </span>
        </div>
        <!-- SUB SERVICES -->
        <div class="theme-color cursor-pointer"
             *ngIf="options?.appointment?.subServices?.length > 0">
        <span class="underline font-weight-600"
              translate="label.subServices"
              [ngbPopover]="subServices"
              placement="bottom auto"
              popoverClass="popover-shadow"
              triggers="mouseenter:mouseleave">
        </span>
            <ng-template #subServices>
                <div *ngFor="let subService of options?.appointment?.subServices">
                    {{subService.subService.name}} <br>
                </div>
            </ng-template>
            <span class="vertical-line-small mx-2"></span>
        </div>
        <!-- DATE TIME FROM -->
        <div class="appointment-card-item font-weight-bold text-dark"
             *ngIf="options?.appointment?.dateTimeFrom">
            <i class="fa-regular fa-clock"></i>
            <span>
            <span>
                <span>{{options?.appointment?.dateTimeFrom | dateMMMMDoYYYYFormat}}, </span>
                <span *ngIf="isDateTimeFromInDifferentTimeZone">
                    {{options?.appointment?.dateTimeFrom | hourFromDate}}
                </span>
                <span *ngIf="!isDateTimeFromInDifferentTimeZone"
                      class="cursor-pointer isDifferentTimeZone"
                      [ngbPopover]="dateTimeFrom"
                      placement="bottom"
                      popoverClass="popover-shadow"
                      triggers="mouseenter:mouseleave">
                     <i class="fas fa-info-circle"></i>
                    {{options?.appointment?.dateTimeFrom | hourFromDate}}
                    <ng-template #dateTimeFrom>
                        <span translate="label.appointmentInDifferentTimeZone"></span>
                    </ng-template>
                </span>
            </span>
            <span class="vertical-line mx-2"></span>
        </span>
        </div>
        <!-- MAIN RESOURCE -->
        <div class="appointment-card-item text-dark font-weight-bold cursor-pointer"
             *ngIf="patientAppointmentResources?.mainResource"
             [ngbPopover]="patientAppointmentResources?.mainResource?.resourceTypeName"
             placement="bottom"
             popoverClass="popover-shadow"
             triggers="mouseenter:mouseleave">
            <i class="fas fa-user-md"></i>
            <span>
                <span>{{patientAppointmentResources?.mainResource?.resource?.name}}</span>
                <span *ngIf="patientAppointmentResources?.secondaryResources?.length > 0">,&nbsp;</span>
                <span *ngIf="patientAppointmentResources?.secondaryResources?.length <= 0"
                      class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- SECONDARY RESOURCES -->
        <div class="text-dark cursor-pointer"
             *ngIf="patientAppointmentResources?.secondaryResources?.length > 0">
            <span>
                <span *ngFor="let resource of patientAppointmentResources?.secondaryResources"
                      [ngbPopover]="resource.resourceTypeName"
                      placement="bottom"
                      popoverClass="popover-shadow"
                      triggers="mouseenter:mouseleave">
                    {{resource?.resource?.name}}
                </span>
                <span class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- CENTER -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.center?.name">
            <i class="fas fa-map-marker-alt"></i>
            <span>
            <span>{{options?.appointment?.center?.name}}</span>
            <span class="vertical-line-small mx-2"></span>
        </span>
        </div>
        <!-- COVERAGE PLAN -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.coveragePlan?.name">
            <i class="fa-solid fa-shield-heart"></i>
            <span>
                <span>{{options?.appointment?.coveragePlan?.name}}</span>
                <span class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- STATUS -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.status">
            <i class={{appointmentStatusIconClass}}></i>
            <span>
            <span>{{"enum.appointmentStatus." + options?.appointment?.status | translate}}</span>
            <span class="vertical-line-small mx-2"></span>
        </span>
        </div>
        <!-- Cancel reason with Others popover -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.cancelled && !!cancelReason && !!cancelReason?.others"
             ngbPopover="{{cancelReason.others}}"
             placement="bottom"
             popoverClass="popover-shadow"
             triggers="mouseenter:mouseleave"
        >
            <i class="fa-regular fa-rectangle-xmark"></i>
            <span>
                <span>{{cancelReason.name}}</span>
                <span class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- Cancel reason without Others popover -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.cancelled && !!cancelReason && !cancelReason?.others"
        >
            <i class="fa-regular fa-rectangle-xmark"></i>
            <span>
                <span>{{cancelReason.name}}</span>
                <span class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- APPOINTMENT TYPE -->
        <div class="text-dark"
             *ngIf="options?.appointment?.appointmentType?.name">
        <span>
            <span [ngClass]="{'appointment-type-overcapacity': options?.appointment?.isOvercapacity}">{{options?.appointment?.appointmentType?.name}}</span>
            <span class="vertical-line-small mx-2"></span>
        </span>
        </div>
        <!-- PAID -->
        <div class="appointment-card-item text-dark">
            <i class="fas fa-credit-card"></i>
            <span>
            <span [translate]="options?.appointment?.paid ? 'label.paid' : 'label.notPaid'"></span>
            <span class="vertical-line-small mx-2" *ngIf="options?.appointment?.oversellingDefinitionId"></span>
        </span>
        </div>
        <!-- APPOINTMENT DETAILS -->
        <span class="vertical-line-small mx-2"></span>
        <app-appointment-details [appointmentId]="options.appointment?.id"></app-appointment-details>
        <!-- OVERSOLD -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.oversellingDefinitionId">
            <i class="fa-regular fa-file-invoice-dollar"></i>
            <span>
                <span translate="label.oversold"></span>
                <span class="vertical-line-small mx-2"></span>
            </span>
        </div>
        <!-- RESCHEDULED FLAG -->
        <div class="appointment-card-item text-dark"
             *ngIf="options?.appointment?.rescheduleAppointmentId">
            <i class="fa-solid fa-calendar-exclamation"></i>
            <span>
            <span>{{'label.rescheduled' | translate}}</span>
            <span class="vertical-line-small mx-2"></span>
        </span>
        </div>
        <!-- SKED TASKS -->
        <div class="appointment-card-item theme-color cursor-pointer"
             [activityDisplay]="['SkedTaskRead']"
             *ngIf="options?.appointment?.skedTasks?.length > 0"
             (click)="onViewSkedTaskDetails()">
            <i class="fas fa-list"></i>
            <span>
            <span class="underline font-weight-600" translate="label.task"></span>
             <span class="vertical-line-small mx-2"></span>
        </span>
        </div>
        <!-- OBJECT DETAILS -->
        <app-appointment-object-details *ngIf="objectDetailsOptions"
                                        [options]="objectDetailsOptions"></app-appointment-object-details>
        <!-- COMMENTS -->
        <app-appointment-comments
                [options]="{appointmentId: options.appointment?.id, eTag: options.appointment?.etag, commentsCount: options.appointment?.commentsCount}"></app-appointment-comments>
        <!-- LAST MODIFIED -->
        <div class="appointment-card-item text-dark cursor-pointer"
             *ngIf="options?.appointment?.modifiedOn"
             [ngbPopover]="lastModified"
             placement="bottom"
             container="body"
             popoverClass="popover-shadow"
             triggers="mouseenter:mouseleave">
            <i class="fas fa-user-clock"></i>
            <span>
            <span>{{'label.lastModified' | translate}}: {{options?.appointment?.modifiedOn | fullDateBrowserTzFormatPipe}}</span>
        </span>
            <ng-template #lastModified>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column mb-2">
                    <span *ngIf="options?.appointment?.createdOn">
                        {{'label.createdOn' | translate}}: {{options?.appointment?.createdOn | fullDateBrowserTzFormatPipe}}
                    </span>
                        <span *ngIf="options?.appointment?.createdByUser">
                            {{'label.createdBy' | translate}}: {{options?.appointment?.createdByUser}}
                    </span>
                        <span *ngIf="options?.appointment?.createdByClient">
                        {{'label.channel' | translate}}: {{options?.appointment?.createdByClient}}
                    </span>
                    </div>
                    <div class="d-flex flex-column">
                    <span *ngIf="options?.appointment?.modifiedOn">
                        {{'label.modifiedOn' | translate}}
                        : {{options?.appointment?.modifiedOn | fullDateBrowserTzFormatPipe}}
                    </span>
                        <span *ngIf="options?.appointment?.modifiedByUser">
                        {{'label.modifiedBy' | translate}}: {{options?.appointment?.modifiedByUser}}
                    </span>
                        <span *ngIf="options?.appointment?.modifiedByClient">
                        {{'label.channel' | translate}}: {{options?.appointment?.modifiedByClient}}
                    </span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <app-appointment-action-buttons
            [options]="appointmentActionButtonsOptions"
            (patientAppointmentAction)="onAction($event)">
    </app-appointment-action-buttons>
</div>
