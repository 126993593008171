import {EventEmitter, Injectable} from '@angular/core';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {
    TaskDetailsOptionsType,
    TaskListItemOptionsType,
    TaskListRequestFilters,
    TaskListSortByEnum,
    TaskListStateType
} from './task-list.types';
import {
    TaskListFiltersOptionsType,
    TaskListFiltersSearchType
} from './task-list-filters/task-list-filters.types';
import * as lodash from 'lodash';
import {GenericFilterOptionsType, SkedTaskTypeEnum, SlotsActionEnum} from 'sked-base';
import * as moment from 'moment';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {Router} from '@angular/router';
import {OptionalIntervalType} from 'sked-base/lib/data-model/optionalIntervalTypes';

@Injectable({
    providedIn: 'root'
})
export class TaskListUtils {
    // State management
    taskListState: TaskListStateType = this.getEmptyState();
    shouldMakeNewRequest = false;
    shouldKeepFiltersState = false;
    shouldKeepListState = false;
    shouldDoBooking = false;
    shouldDoReschedule = false;
    rescheduleOrBookStartLocation = '';
    taskListActionContextRoute: string;

    // Options for filters
    filtersOptions: TaskListFiltersOptionsType = this.getEmptyFiltersOptions();
    clearFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();

    // Options for task list item
    taskListItemOptions: TaskListItemOptionsType = this.getInitialTaskListFiltersOptions();

    // Options for task list details
    taskItemDetailsOptions: TaskDetailsOptionsType = this.getInitialItemDetailsOptions();

    constructor(
        private router: Router,
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
    ) {
    }

    // Booking and reschedule

    navigateForBooking() {
        this.router.navigate(['/slotsManagement'], {
            state: {
                slotsAction: SlotsActionEnum.Book,
                redirectRoute: '/taskList',
                taskItem: this.taskItemDetailsOptions?.taskItem,
                appointmentItem: {
                    id: this.taskItemDetailsOptions?.taskItem?.appointmentId,
                    ...this.taskItemDetailsOptions?.taskItem?.appointment,
                    ...this.taskItemDetailsOptions?.appointment,
                },
            }
        });
    }

    navigateForReschedule() {
        this.router.navigate(['/slotsManagement'], {
            state: {
                slotsAction: SlotsActionEnum.Reschedule,
                redirectRoute: '/taskList',
                taskItem: this.taskItemDetailsOptions?.taskItem,
                appointmentItem: {
                    id: this.taskItemDetailsOptions?.taskItem?.appointmentId,
                    ...this.taskItemDetailsOptions?.taskItem?.appointment,
                    ...this.taskItemDetailsOptions?.appointment,
                },
            }
        });
    }

    // State management

    updateFilterState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.filtersOptions.hasOwnProperty(property)) {
                return;
            }
            if (property === 'filterWrapperOptions') {
                // Don't cloneDeep providerInstance
                this.taskListState.filtersOptions.filterWrapperOptions =
                    this.filtersOptions?.filterWrapperOptions?.map((options: GenericFilterOptionsType) => {
                        const {providerInstance, ...restOfFilterOptions} = options;
                        return {
                            providerInstance,
                            ...lodash.cloneDeep(restOfFilterOptions),
                        };
                    });
                return;
            }
            this.taskListState.filtersOptions[property] = lodash.cloneDeep(this.filtersOptions[property]);
        });
    }

    updateItemsState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.taskListItemOptions.hasOwnProperty(property)) {
                return;
            }
            this.taskListState.taskListItemOptions[property] = lodash.cloneDeep(this.taskListItemOptions[property]);
        });
    }

    getEmptyState(): TaskListStateType {
        return {
            filtersOptions: this.getEmptyFiltersOptions() as TaskListFiltersOptionsType,
            taskListItemOptions: this.getInitialTaskListFiltersOptions() as TaskListItemOptionsType,
            taskItemDetailsOptions: this.getInitialItemDetailsOptions() as TaskDetailsOptionsType,
        } as TaskListStateType;
    }

    // ///

    getInitialItemDetailsOptions(): TaskDetailsOptionsType {
        return {
            taskItem: {},
            appointment: {},
            appointmentComments: [],
            uploadsViewerOptions: undefined,
        } as TaskDetailsOptionsType;
    }

    getEmptyFiltersOptions(): TaskListFiltersOptionsType {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            dueDateRangeOptions: {},
            taskListFiltersValues: {},
            areFiltersValid: false,
            areFiltersCollapsed: false,
            onlyTasksWithAppointmentsCheckboxNgModel: false,
            onlyExpiringTasksCheckboxNgModel: false,
            latestSearchFilterValues: undefined,
            createdDateRangeOptions: {},
            processTypeSelectNgModel: undefined,
            statusSelectNgModel: undefined,
        } as TaskListFiltersOptionsType;
    }

    getInitialTaskListFiltersOptions(): TaskListItemOptionsType {
        return {
            isBeforeSearchState: true,
            isNotFoundState: false,
            taskList: [],
            itemsPerPageList: [5, 10, 25, 50],
            taskListSortByList: Object.keys(TaskListSortByEnum),
            latestTaskListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
                sortBy: Object.keys(TaskListSortByEnum)[0]
            } as TaskListRequestFilters,
            taskListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
                sortBy: Object.keys(TaskListSortByEnum)[0]
            } as TaskListRequestFilters,
            totalTaskItems: null,
            showItemsPerPageDropdown: false
        };
    }

    // This is used in task-item-details component
    getSkedTaskQueryFilter() {
        return {
            expand: {
                SubServices: {expand: {SubService: {select: ['Name', 'Id', 'Code', 'ShortId']}}},
                Patient: {
                    select: ['RowVersion', 'FullName', 'Email', 'MainPhoneNumber', 'DocumentNumberFormatted', 'DocumentNumber', 'DocumentType',
                        'DocumentCountry']
                },
                Center: {select: ['Name']},
                Resource: {select: ['Name']},
                Service: {
                    select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices'],
                    expand: {Area: {select: ['Id', 'Name']}},
                },
                Appointment: {select: ['Status']}
            }
        };
    }

    // This is used in task-item-details component
    getAppointmentQueryFilter() {
        return {
            select: ['DateTimeFrom', 'Duration'],
            expand: {
                AppointmentType: {select: ['Name']},
                Patient: {select: ['RowVersion', 'FullName', 'Email', 'MainPhoneNumber', 'AlternatePhoneNumber', 'DocumentNumberFormatted',
                        'DocumentNumber', 'DocumentType', 'DocumentCountry']},
                Resource: {select: ['Name']},
                ResourceAppointments: {select: ['MainResource'], expand: {Resource: {select: ['Name']}}},
                Service: {select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices']},
                Center: {select: ['Name']},
                CoveragePlan: {select: ['Id', 'Name']},
                SubServices: {select: ['Id', 'SubServiceId', 'Duration'], expand: {SubService: {select: ['Id', 'Name']}}},
            }
        };
    }

    getTaskQueryFilter(taskListRequestFilters: TaskListRequestFilters) {
        return {
            count: true,
            skip: (taskListRequestFilters.pageFilters.currentPage - 1) * taskListRequestFilters.pageFilters.itemsPerPage,
            top: taskListRequestFilters.pageFilters.itemsPerPage,
            filter: this.getTaskFiltersQuery(taskListRequestFilters.searchFilters),
            expand: this.getExpandQuery(),
            orderBy: this.getOrderByQuery(taskListRequestFilters.sortBy),
        };
    }

    private getOrderByQuery(sortBy: string): any[] | undefined {
        const orderBy: any[] = [];
        if (sortBy === TaskListSortByEnum['NoActiveSorting']) {
            return undefined;
        } else {
            orderBy.push(TaskListSortByEnum[sortBy]);
        }
        return orderBy;
    }

    private getTaskFiltersQuery(searchFilters: TaskListFiltersSearchType) {
        const and: any[] = [];

        if (searchFilters?.onlyTasksWithAppointments) {
            and.push({AppointmentId: {ne: {type: 'guid', value: null}}});
        }
        if (searchFilters?.patient?.id) {
            and.push({PatientId: {eq: {type: 'guid', value: searchFilters.patient.id}}});
        }
        if (searchFilters?.location?.id) {
            and.push({CenterId: {eq: {type: 'guid', value: searchFilters.location.id}}});
        }

        if (searchFilters?.resource?.id) {
            and.push({ResourceId: {eq: {type: 'guid', value: searchFilters.resource.id}}});
        }

        // appointments don't have areaId so we filter by service/areaId
        if (searchFilters && searchFilters?.area?.id) {
            and.push({'Service/AreaId': {eq: {type: 'guid', value: searchFilters.area.id}}});
        }
        if (searchFilters?.service?.id) {
            and.push({ServiceId: {eq: {type: 'guid', value: searchFilters.service.id}}});
        }
        if (searchFilters?.taskType?.name) {
            and.push({Type: {eq: searchFilters.taskType.name}});
        } else {
            and.push({Type: {ne: SkedTaskTypeEnum.Unknown}});
        }
        if (searchFilters?.subService?.id) {
            and.push({
                or: ['SubServices/all(SubServices:false)',
                    {SubServices: {any: {Id: {eq: {type: 'guid', value: searchFilters.subService.id}}}}},
                ]
            });
        }
        if (!!searchFilters?.taskStatus) {
            and.push({Status: {eq: searchFilters.taskStatus}});
        }
        if (!!searchFilters?.taskProcessType) {
            and.push({ProcessingType: {eq: searchFilters.taskProcessType}});
        }
        if (!!searchFilters?.onlyExpiringTasks) {
            // TODO After back end is ready with the system config change the amount of added days to be set from there
            const todayPlusNumberOfExpiringDaysMoment = moment().add(3, 'days').add(1, 'days');
            and.push({
                DueDate: {
                    ge: this.dateTimeUtils.getRawDateFilter(moment())
                }
            });
            and.push({
                DueDate: {
                    lt: this.dateTimeUtils.getRawDateFilter(todayPlusNumberOfExpiringDaysMoment)
                }
            });
        } else {
            if (!!searchFilters?.dueDateFrom) {
                const momentDueDateFrom = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dueDateFrom);
                and.push({
                    DueDate: {
                        ge: this.dateTimeUtils.getRawDateFilter(momentDueDateFrom)
                    }
                });
            }
            if (!!searchFilters?.dueDateTo) {
                const momentDueDateTo = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dueDateTo).add(1, 'days');
                and.push({
                    DueDate: {
                        lt: this.dateTimeUtils.getRawDateFilter(momentDueDateTo)
                    }
                });
            }
        }
        if (!!searchFilters?.createdDateFrom) {
            const momentCreatedDateFrom = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.createdDateFrom);
            and.push({
                CreatedOn: {
                    ge: this.dateTimeUtils.getRawDateFilter(momentCreatedDateFrom)
                }
            });
        }
        if (!!searchFilters?.createdDateTo) {
            const momentCreatedDateTo = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.createdDateTo).add(1, 'days');
            and.push({
                CreatedOn: {
                    lt: this.dateTimeUtils.getRawDateFilter(momentCreatedDateTo)
                }
            });
        }
        return and;
    }

    private getExpandQuery() {
        return {
            Patient: {
                select: ['RowVersion', 'Id', 'FullName', 'FirstName1', 'FirstName2', 'LastName1', 'LastName2',
                    'BirthDate', 'Email', 'MainPhoneNumber', 'AlternatePhoneNumber', 'Gender',
                    'DocumentNumberFormatted', 'DocumentNumber', 'DocumentType', 'DocumentCountry']
            },
            Service: {
                select: ['Id', 'Name', 'Code', 'HasSubServices', 'OnlineConsultation', 'SpecialityId', 'MinSubServices',
                    'MaxSubServices', 'AreaId'],
                expand: {Speciality: {select: ['Id', 'Name']}}
            },
            Area: {select: ['Id', 'Name']},
            Resource: {select: ['Id', 'Name', 'IsDirectlyBookable', 'IsRoom']},
            Center: {select: ['Id', 'Name', 'Latitude', 'Longitude', 'Name', 'RegionId']},
            SubServices: {select: ['Id', 'SubServiceId', 'Duration'], expand: {SubService: {select: ['Id', 'Name']}}},
            Appointment: {select: ['Status', 'OversellingDefinitionId']}
        };
    }

    doesTaskHavePreferredInterval({hourFrom, hourTo}: OptionalIntervalType): boolean {
        return this.generalUtils.isValueTypeOfNumber(hourFrom) && this.generalUtils.isValueTypeOfNumber(hourTo);
    }

    getPreferredIntervalDisplayValue({hourFrom, hourTo}: OptionalIntervalType): string {
        if (hourFrom === 0 && hourTo === 720) {
            return '00:00 - 12:00';
        }
        if (hourFrom === 720 && hourTo === 1440) {
            return '12:00 - 24:00';
        }
    }
}
