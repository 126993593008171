import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OpenIdConnectService} from '../../shared/services/open-id-connect.service';
import {SessionStorageService} from '@efaps/ngx-store';
import {IdleTimerService} from '../../shared/services/idle-timer.service';

@Injectable({
  providedIn: 'root'
})
export class RequestAuthenticatedUserGuard implements CanActivate {

  constructor(private openIdConnectService: OpenIdConnectService,
              private router: Router,
              private sessionStorageService: SessionStorageService,
              private idleTimerService: IdleTimerService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
      Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.openIdConnectService.userAvailable) {
      return true;
    } else {
      // No user logged in, so we clean up the idle timer, so when the user logs in we don't consider it a past session
      this.idleTimerService.destroy();
      // Save url to redirect to
      if (route?.url[0]?.path) {
        this.sessionStorageService.set('redirectUrlAfterSignIn', route.url[0].path);
      }
      // trigger signIn
      this.openIdConnectService.triggerSignIn();
      return false;
    }
  }
}
