import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GraphicalResourcePlannerOptionsType, GRPRescheduleOptionsType} from 'sked-base';
import {Router} from '@angular/router';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {systemConfigKeys} from '../../features/system-config/system-config.constant';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {constants} from '../../shared/constants/constants';
import {GraphicalResourcePlannerUtil} from './graphical-resource-planner-util';
import * as lodash from 'lodash';

@Component({
    selector: 'app-graphical-resource-planner',
    templateUrl: './graphical-resource-planner.component.html',
    styleUrls: ['./graphical-resource-planner.component.scss']
})
export class GraphicalResourcePlannerComponent implements OnInit {
    graphicalResourcePlannerOptions: GraphicalResourcePlannerOptionsType;
    organizationalRole: string;
    constructor(
        public messagesService: MessagesService,
        public translatedLanguageService: TranslatedLanguageService,
        public translateService: TranslateService,
        public ngxLoader: NgxUiLoaderService,
        private router: Router,
        public configDataService: ConfigDataService,
        private grpUtil: GraphicalResourcePlannerUtil
    ) {
    }

    ngOnInit() {
        this.loadInitialOptionsForUserProfile();
    }

    private loadInitialOptionsForUserProfile() {

        // Load user: if user already loaded we don't send request
        this.ngxLoader.start();
        this.configDataService.getUserByToken().subscribe(
            (response) => {
                this.organizationalRole = response.team?.organizationalRole;
                if (this.organizationalRole) {
                    this.setGRPOptions(response.id);
                }
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });

    }

    private setGRPOptions(userId) {
        const rescheduleOptions = history.state?.rescheduleAppointment
            ? this.getGRPRescheduleOptions()
            : (history.state?.comingFromRoute === 'bookAppointment'
                ? lodash.cloneDeep(this.grpUtil.rescheduleAppointmentState)
                : undefined);
        if (rescheduleOptions === undefined) {
            this.grpUtil.rescheduleAppointmentState = undefined;
        }
        this.graphicalResourcePlannerOptions = {
            messagesServiceInstance: this.messagesService,
            configDataServiceInstance: this.configDataService,
            systemConfigKeys,
            loggedUserId: userId,
            routing: {
                routerInstance: this.router,
                graphicalResourcePlannerRoute: 'graphicalResourcePlanner',
                generateTaskRoute: 'generateSkedTask',
                isGenerateTaskActionActive: this.configDataService.isActivityActive('AdvancedBookingPlannerTaskManager'),
                grpBookingAppointmentRoute: 'grpBookingAppointment',
                isBookingAppointmentActionActive: true
            },
            fullCalendar: {
                licenseKey: constants.FULL_CALENDAR_LICENSE_KEY
            },
            translateService: this.translateService,
            translatedLanguageService: this.translatedLanguageService,
            rescheduleOptions
        };
    }

    private getGRPRescheduleOptions(): GRPRescheduleOptionsType {
        this.grpUtil.rescheduleAppointmentState = {
            rescheduleAppointment: history.state?.rescheduleAppointment,
            parentRouterLink: '/resourceUtilization',
            routerState: {
                shouldKeepFiltersState: true,
                shouldMakeNewRequest: true,
                shouldKeepCalendarState: true
            },
        } as GRPRescheduleOptionsType;
        return lodash.cloneDeep(this.grpUtil.rescheduleAppointmentState);
    }
}
