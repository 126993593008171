<div class="time-dependent-tags-container">
    <div *ngIf="timeDependentTagsForm" [formGroup]="timeDependentTagsForm"
         class="form-container time-dependent-tags-form-wrapper"
         [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
         [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
         [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
         [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
         [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">

        <!-- TAGS -->
        <div *ngIf="getInputByControlName(formControlNames.TIME_DEPENDENT_TAG)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.TIME_DEPENDENT_TAG)?.styles">
            <label translate="label.tags"></label>
            <div class="tags-input custom-tag-input customSelect sbase-input-element">
                <ngx-ui-loader [loaderId]="timeDependentTagsLoaderId"
                               [hasProgressBar]="false"
                               fgsSize=20
                               bgsOpacity=0.2></ngx-ui-loader>
                <sbase-filter (selectedItemsOutput)="onSelectedTags($event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.tags"
                              [initialFilterValues]="initialTimeDependentTags"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider"
                              [customTemplate]="tagsTemplate"
                              [additionalExclusionList]="createPatientUtils?.tagsExclusionList"
                              #tagsSbaseFilter
                              [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(timeDependentTagsForm, formControlNames.TIME_DEPENDENT_TAG)}">
                </sbase-filter>
                <ng-template let-item #tagsTemplate>
                    <span [ngClass]="{'font-weight-bold black-color':item?.recommended}">{{ item?.name }}</span>
                </ng-template>
            </div>
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="timeDependentTagsForm"
                                          [controlName]="formControlNames.TIME_DEPENDENT_TAG">
            </app-dynamic-form-input-error>
        </div>
        <!-- DATE FROM -->
        <app-dynamic-form-input
                [parentFormGroup]="timeDependentTagsForm"
                [options]="dateTimeFromOptions"
                [initialValue]="dateTimeFromOptions?.groups[0]?.inputs[0]?.datePickerConfig?.min"
        ></app-dynamic-form-input>
        <!-- DATE TO -->
        <app-dynamic-form-input
                [parentFormGroup]="timeDependentTagsForm"
                [options]="dateTimeToOptions"
        ></app-dynamic-form-input>
        <!-- NUMBER OF DAYS -->
        <div class="number-of-days-container">
            <app-dynamic-form-input
                    [parentFormGroup]="timeDependentTagsForm"
                    [options]="numberOfDaysOptions"
            ></app-dynamic-form-input>
        </div>
    </div>
    <div class="d-flex float-right mb-3 mt-1 update-buttons">
        <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                translate="button.save"
                (click)="onSaveTimeDependentTag()"
                [disabled]="isSaveButtonDisabled">
        </button>
        <button class="btn border-0 p-0"
                translate="button.cancel"
                (click)="onCancelTimeDependentTag()"></button>
    </div>

    <table class="table table-bordered grey-table" *ngIf="createPatientUtils?.patientTimeDependentTags?.length > 0">
        <thead class="thead-light">
        <tr>
            <td translate="label.tag2" [style.width]="'28%'"></td>
            <td translate="label.validFrom" [style.width]="'28%'"></td>
            <td translate="label.validTo" [style.width]="'28%'"></td>
            <td translate="label.numberOfDays" [style.width]="'10%'"></td>
            <td translate="label.action" [style.width]="'6%'"></td>
        </tr>
        </thead>
        <tbody *ngFor="let patientTimeDependentTag of createPatientUtils?.patientTimeDependentTags; let index = index">
        <tr>
            <td [style.width]="'28%'">
                <span>{{patientTimeDependentTag?.name}}</span>
            </td>
            <td [style.width]="'28%'">
                <span>{{patientTimeDependentTag?.dateTimeFrom | dateMMDDYYYYBrowserTzFormat}}</span>
            </td>
            <td [style.width]="'28%'">
                <span>{{patientTimeDependentTag?.dateTimeTo | dateMMDDYYYYBrowserTzFormat}}</span>
            </td>
            <td [style.width]="'10%'">
                <span>{{patientTimeDependentTag?.numberOfDays}}</span>
            </td>
            <td class="text-center" [style.width]="'6%'">
                <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                        popoverClass="popover-shadow"
                        *ngIf="!patientTimeDependentTag.calculated">
                    <i class="fas fa-ellipsis-h"></i>
                </button>
                <i class="fas fa-info-circle mx-1" *ngIf="!!patientTimeDependentTag.calculated"
                   ngbTooltip="{{'label.calculatedTagsCannotBeEditedOrDeleted' | translate}}"
                   placement="bottom"></i>
                <ng-template #popContent>
                    <ul class="list-style-none m-0 p-0 custom-drop-down">
                        <li>
                            <button type="button" class="btn" translate="label.edit"
                                    (click)="editTimeDependentTag(patientTimeDependentTag)"></button>
                        </li>
                        <li>
                            <button type="button" class="btn" translate="label.delete"
                                    (click)="deleteTimeDependentTag(index)"></button>
                        </li>
                    </ul>
                </ng-template>
            </td>
        </tr>
        </tbody>
    </table>
</div>
