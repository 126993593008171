import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {ODataQueryObjectType, RuleManagementWeightedCombinationType} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ValidRuleManagementWeightedCombinationObjectType} from "./rules-weighted-combination-md.types";

@Injectable({
    providedIn: 'root'
})
export class RulesWeightedCombinationMdUtils {

    constructor(private generalUtils: GeneralUtils) {
    }

    getQueryFilterForRulesWeightedCombinationMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Area', 'Speciality', 'Service', 'Resource', 'Center', 'CoveragePlan', 'Channel', 'PatientTag', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return this.generalUtils.getInitialTableFilter();
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialRulesWeightedCombination(): RuleManagementWeightedCombinationType {
        return {
            id: '',
            area: 1,
            speciality: 1,
            service: 1,
            resource: 1,
            center: 1,
            coveragePlan: 1,
            channel: 1,
            patientTag: 1
        } as RuleManagementWeightedCombinationType;
    }

    getInitialValidTemplateObject(): ValidRuleManagementWeightedCombinationObjectType {
        return {
            isAreaValid: false,
            isSpecialityValid: false,
            isServiceValid: false,
            isResourceValid: false,
            isCenterValid: false,
            isCoveragePlanValid: false,
            isChannelValid: false,
            isPatientTagValid: false
        };
    }
}
