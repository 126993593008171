import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessagesService} from '../../../shared/services/messages.service';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {
    ProcessedTenantCustomizingGroupedByControlNameType,
    TenantCustomizingFilterInputType
} from '../../../data-model/tenant-customizing.type';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {constants} from 'src/app/shared/constants/constants';
import * as lodash from 'lodash';

@Component({
    selector: 'app-grp-booking-appointment',
    templateUrl: './grp-booking-appointment.component.html',
    styleUrls: ['./grp-booking-appointment.component.scss']
})
export class GrpBookingAppointmentComponent implements OnInit {

    userInfoStorage;
    tenantCustomizingData: ProcessedTenantCustomizingGroupedByControlNameType;

    constructor(
        public routerInstance: Router,
        public messagesServiceInstance: MessagesService,
        public configDataService: ConfigDataService,
        public translatedLanguageService: TranslatedLanguageService,
        private tenantCustomizingService: TenantCustomizingService
    ) {
    }

    ngOnInit(): void {
        this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        this.loadTenantCustomizing();
    }

    private loadTenantCustomizing() {
        this.tenantCustomizingService.getTenantCustomizingValuesFor(
            this.getTenantCustomizingFilterObject()
        ).subscribe((response: ProcessedTenantCustomizingGroupedByControlNameType) => {
            this.tenantCustomizingData = lodash.pick(response, ['Email', 'MainPhoneNumber', 'AlternatePhoneNumber']);
        });
    }

    private getTenantCustomizingFilterObject(): TenantCustomizingFilterInputType {
        return {
            ui: 'reception.patientOverview',
            entity: 'PatientDTO',
            situation: 'Edit',
            role: this.userInfoStorage?.team?.organizationalRole
        } as TenantCustomizingFilterInputType;
    }
}
