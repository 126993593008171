<div class="container-fluid API-integration-wrapper overview-screen-md pb-1 overflow-auto custom-scrollbar">
    <div class="d-flex justify-content-between mb-3">
        <h3 translate="label.apiIntegration"></h3>
    </div>
    <div class="row">
        <!-- APIM INTEGRATION CONFIGURATION -->
        <div class="col-xl-6 col-lg-12">
            <label translate="label.apimIntegrationConfiguration" class="text-dark font-weight-bold"></label>
            <table class="table border text-high-contrast">
                <tbody>
                <tr>
                    <td translate="label.linkToDocumentation"></td>
                    <td class="text-right">
                        <span class="mr-2">{{apimIntegrationInformation?.linkToDocumentation}}</span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.linkToDocumentation"
                              (mouseenter)="makeTippyIfNotAlreadyMade('linkToDocumentation', 'linkToDocumentation-link-icon', linkToDocumentation.id)"
                              (click)="openDocumentationLink()"
                              id="linkToDocumentation-link-icon">
                            <i class="fas fa-external-link-alt"></i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td translate="label.apimUrl"></td>
                    <td class="text-right">
                        <span class="mr-2">{{apimIntegrationInformation?.apimUrl}}</span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.apimUrl"
                              [cdkCopyToClipboard]="apimIntegrationInformation?.apimUrl"
                              id="apimUrl-copy-icon"
                              (mouseenter)="makeTippyIfNotAlreadyMade('apimUrl', 'apimUrl-copy-icon', popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade('apimUrl', 'apimUrl-copy-icon', popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td translate="label.apiMUrlV2"></td>
                    <td class="text-right">
                        <span class="mr-2">{{apimIntegrationInformation?.apimUrlV2 ?? ''}}</span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.apimUrlV2"
                              [cdkCopyToClipboard]="apimIntegrationInformation?.apimUrlV2"
                              id="apiMUrlV2-copy-icon"
                              (mouseenter)="makeTippyIfNotAlreadyMade('apiMUrlV2', 'apiMUrlV2-copy-icon', popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade('apiMUrlV2', 'apiMUrlV2-copy-icon', popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td translate="label.apiMUrlV2Swagger"></td>
                    <td class="text-right">
                        <span class="mr-2">
                            {{apimIntegrationInformation?.apimUrlV2 && apimIntegrationInformation?.apimSubscriptionKey
                            ? apimIntegrationInformation?.apimUrlV2 + '/index.html?subscription-key=' + apimIntegrationInformation?.apimSubscriptionKey
                            : ''}}
                        </span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.apimUrlV2 && apimIntegrationInformation?.apimSubscriptionKey"
                              [cdkCopyToClipboard]="apimIntegrationInformation?.apimUrlV2 + '/index.html?subscription-key=' + apimIntegrationInformation?.apimSubscriptionKey"
                              id="apiMUrlV2Swagger-copy-icon"
                              (mouseenter)="makeTippyIfNotAlreadyMade('apiMUrlV2Swagger', 'apiMUrlV2Swagger-copy-icon', popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade('apiMUrlV2Swagger', 'apiMUrlV2Swagger-copy-icon', popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="apimIntegrationInformation?.apimSubscriptionKey">
                    <td translate="label.apimSubscriptionKey"></td>
                    <td class="text-right">
                        <span class="mr-2">{{apimIntegrationInformation?.apimSubscriptionKey | slice:0:3}}{{'**********'}}</span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.apimSubscriptionKey"
                              [cdkCopyToClipboard]="apimIntegrationInformation?.apimSubscriptionKey"
                              id="apimSubscriptionKey-copy-icon"
                              (mouseenter)="makeTippyIfNotAlreadyMade('apimSubscriptionKey', 'apimSubscriptionKey-copy-icon', popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade('apimSubscriptionKey', 'apimSubscriptionKey-copy-icon', popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="apimIntegrationInformation?.apimMed360SubscriptionKey">
                    <td translate="label.apimMed360SubscriptionKey"></td>
                    <td class="text-right">
                        <span class="mr-2">{{apimIntegrationInformation?.apimMed360SubscriptionKey | slice:0:3}}{{'**********'}}</span>
                        <span class="cursor-pointer"
                              *ngIf="apimIntegrationInformation?.apimMed360SubscriptionKey"
                              [cdkCopyToClipboard]="apimIntegrationInformation?.apimMed360SubscriptionKey"
                              id="apimMed360SubscriptionKey-copy-icon"
                              (mouseenter)="makeTippyIfNotAlreadyMade('apimMed360SubscriptionKey', 'apimMed360SubscriptionKey-copy-icon', popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade('apimMed360SubscriptionKey', 'apimMed360SubscriptionKey-copy-icon', popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- SECURITY API KEYS -->
        <div class="col-xl-6 col-lg-12" *ngIf="securityAPIKeys?.length > 0">
            <label translate="label.securityAPIKeys" class="text-dark font-weight-bold"></label>
            <table class="table border text-high-contrast">
                <tbody>
                <tr *ngFor="let securityKey of securityAPIKeys">
                    <td *ngIf="securityKey?.key && securityKey?.name">{{securityKey?.name}}</td>
                    <td *ngIf="securityKey?.key && securityKey?.name" class="text-right">
                        <span class="mr-2">{{securityKey?.key | slice:0:3}}{{'**********'}}</span>
                        <span class="cursor-pointer"
                              [cdkCopyToClipboard]="securityKey?.key"
                              id="{{securityKey.id}}"
                              (mouseenter)="makeTippyIfNotAlreadyMade(securityKey.name, securityKey.id, popoverBeforeCopyText.id)"
                              (click)="makeTippyIfNotAlreadyMade(securityKey.name, securityKey.id, popoverAfterCopyText.id, true)">
                            <i class="far fa-copy"></i>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- POPOVER TEXT -->
        <span class="d-none" id="popoverBeforeCopyText"
              #popoverBeforeCopyText>{{'label.copyToClipboard' | translate}}
        </span>
        <span class="d-none" id="popoverAfterCopyText"
              #popoverAfterCopyText>{{'label.textCopiedSuccessfully' | translate}}
        </span>
        <span class="d-none" id="linkToDocumentation"
              #linkToDocumentation>{{'label.goToDocumentationLink' | translate}}
        </span>
    </div>
</div>
