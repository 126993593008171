import {UserType} from 'sked-base/lib/data-model/userTypes';
import {Injectable} from '@angular/core';
import {
    ExternalKeysValueType, FormValidationType,
    ODataFilterQueryType,
    ODataOrderByQueryType,
    TableFiltersType
} from '../../data-model/general.type';
import * as lodash from 'lodash';
import {
    ODataQueryObjectType,
    Expand,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    TeamType, Validations, CenterProvider, UserProvider
} from 'sked-base';
import {ValidUserType} from './user.types';
import {constants} from '../../shared/constants/constants';
import {ExternalKeysType} from 'sked-base/lib/data-model/externalKeysTypes';
import {
    MultiSelectTableColumnKindEnum, MultiSelectTableColumnType,
    MultiSelectTableOptionsType
} from '../../shared/component/multi-select-table/multi-select-table.types';
import {MultiSelectTableUtils} from '../../shared/component/multi-select-table/multi-select-table-util';

@Injectable({
    providedIn: 'root'
})
export class UserMDUtils {
    public tableFilters: TableFiltersType = {} as TableFiltersType;
    public initialTeamValues: any[] = [];

    constructor(private validations: Validations,
                private centerProvider: CenterProvider,
                private userProvider: UserProvider,
                private multiSelectTableUtils: MultiSelectTableUtils) {
    }

    getQueryFilterForUserMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Centers: {select: ['Id', 'Name']},
            Team: {select: ['Id', 'Name']},
            Resource: {select: ['Id', 'Name']},
            UserExternalKeys: {select: ['Id', 'Key', 'Origin', 'EntityId']}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'username': {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                    case 'team': {
                        filterQuery.TeamId = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    }
                    case 'userType': {
                        filterQuery.Type = {eq: filter[item]};
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getValidatedFields(userItem: UserType): ValidUserType {
        const validTemplate: ValidUserType = {} as ValidUserType;
        validTemplate.isUsernameValid = !!(userItem && userItem.username);

        validTemplate.email = this.getEmailValidation(userItem.email);

        validTemplate.isPasswordValid = this.isValidPassword(userItem.password);

        validTemplate.isTeamValid = !!(userItem && userItem.teamId);

        validTemplate.areExternalKeysValid = this.areExternalKeysTableValid(userItem.userExternalKeys);

        validTemplate.isResourceValid = !userItem.mSGraphCalendar || !!(userItem.mSGraphCalendar && userItem.resourceId);

        return validTemplate;
    }

    isTemplateValid(validTemplate) {
        return !!(validTemplate.email.isValid && validTemplate.isUsernameValid && validTemplate.isPasswordValid && validTemplate.isTeamValid);
    }

    isValidPassword(password: string): boolean {
        let isPasswordValid: boolean;
        if (password === undefined) {
            isPasswordValid = true;
        } else if (password === '') {
            isPasswordValid = false;
        } else {
            isPasswordValid = constants.PASSWORD_PATTERN.test(password);
        }

        return isPasswordValid;
    }

    getInitialUser(): UserType {
        const user: UserType = {} as UserType;
        user.username = '';
        user.password = '';
        user.firstName = '';
        user.lastName = '';
        user.email = '';
        user.resourceId = null;
        user.teamId = null;
        user.centers = [];
        user.userExternalKeys = [];
        user.team = {} as TeamType;
        user.mSGraphCalendar = false;
        return user;
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })

    getEmailValidation(email: string): FormValidationType {
        return this.validations.getValidateEmail(email, true) as FormValidationType;
    }

    objectAddedOnEditAction(initialUser, selectedItem, refObjectName): boolean {
        const listName = lodash.lowerFirst(refObjectName);
        const foundItem = lodash.find(initialUser[listName], {id: selectedItem.id});

        return !!foundItem;
    }

    getEmptyExternalKeyItem(): ExternalKeysValueType {
        return {
            id: '',
            origin: '',
            key: '',
            entityId: ''
        };
    }

    areExternalKeysTableValid(externalKeysList: ExternalKeysType[]): boolean {
        if (externalKeysList && externalKeysList.length > 0) {
            const externalKeyItem = lodash.find(externalKeysList, (item) => {
                if (item.key === '' || item.origin === '') {
                    return item;
                }
            });

            return !(externalKeyItem !== undefined);
        } else {
            return true;
        }
    }

    getCenterMultiSelectTableOptions(user: UserType, screenTemplateLayoutAction: string): MultiSelectTableOptionsType {
        return {
            provider: this.centerProvider,
            providerMethod: 'getEntries',
            confirmDeleteProvider: this.userProvider,
            confirmDeleteParentId: user?.id,
            confirmDeleteRefName: 'Centers',
            getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(['Id', 'Name'], null, null, ['Name asc']),
            initialSelectedItems: user?.centers,
            tableMaxHeight: '400px',
            columns: [{
                type: MultiSelectTableColumnKindEnum.FILTER,
                headerLabel: 'label.center',
                displayUpperHeader: false,
                itemPropertyNameInTableRow: 'name',
                provider: this.centerProvider,
                buttonName: 'label.center',
                dependentFilters: this.getLocationDependentFilters(),
                propertyName: 'center',
                propertyFilterIdName: 'Id',
                propertyFilterIdNameMapped: 'id'
            } as MultiSelectTableColumnType],
            screenTemplateLayoutAction
        };
    }
}
