import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './features/page-not-found/page-not-found.component';
import {RequestAuthenticatedUserGuard} from './core/guards/request-authenticated-user.guard';
import {AccessRoute} from './core/guards/access-route.guard';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent, canActivate: [RequestAuthenticatedUserGuard]}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
