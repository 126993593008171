import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    TimeWindowSpecialityAreaType,
    ODataQueryObjectType,
    Expand,
    SpecialityDependentFiltersType,
    AreaDependentFiltersType,
    ChannelDependentFiltersType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {TimeWindowForAreaAndSpecialityFiltersType} from './time-window-speciality-area.types';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class TimeWindowSpecialityAreaMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialFilterValues: TimeWindowForAreaAndSpecialityFiltersType = {
        area: [],
        speciality: [],
        channel: []
    };

    constructor(
        private generalUtils: GeneralUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    getInitialFilterValues(): TimeWindowForAreaAndSpecialityFiltersType {
        return {
            area: [],
            speciality: [],
            channel: [],
        };
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: []
        } as AreaDependentFiltersType;
    }

    getSpecialityDependentFilters(): SpecialityDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            count: true
        } as SpecialityDependentFiltersType;
    }

    getQueryFilterForTimeWindowSpecialityAreaMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'AreaId', 'SpecialityId', 'Channel', 'Days', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Area: {select: ['Id', 'Name']},
            Speciality: {select: ['Id', 'Name']},
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {AreaId: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'area':
                    case 'speciality':
                        filterQuery[lodash.upperFirst(item) + 'Id'] = {eq: {type: 'guid', value: filter[item].id}};
                        break;
                    case 'channel': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item].enumValue};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'area':
                    case 'speciality':
                        orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                        break;
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialTimeWindowSpecialityArea(): TimeWindowSpecialityAreaType {
        return {
            areaId: '',
        } as TimeWindowSpecialityAreaType;
    }
}
