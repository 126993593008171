<div class="container-fluid task-list-wrapper list-page-wrapper p-0 pb-1">
    <!-- Search filters -->
    <div class="row task-list-filters-container ml-0 mr-0 mb-1">
        <app-task-list-filters *ngIf="taskListUtils.filtersOptions.areFiltersReady"
                               (search)="onFilterSearch($event)"
        ></app-task-list-filters>
    </div>

    <!-- Sort by filter -->
    <div class="row task-list-sort-container list-sort-container ml-0 mr-0 mb-1">
        <div class="ml-2 my-1 d-flex no-block" *ngIf="taskListUtils.taskListItemOptions.totalTaskItems > 0">
            <label translate="label.sortBy" class="mt-2 mr-3 font-weight-bold nowrap"></label>
            <ng-select name="sortBy"
                       class="form-control custom-ng-select"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="taskListUtils.taskListItemOptions.taskListRequestFilters.sortBy"
                       (ngModelChange)="onSortByChange($event)">
                <ng-option *ngFor="let item of taskListUtils.taskListItemOptions.taskListSortByList"
                           [value]="item">{{'enum.sortByTaskList.' + item | translate}}</ng-option>
            </ng-select>
        </div>
    </div>

    <!-- Task list item -->
    <div class="task-list-results-container ml-0 mr-0 custom-scrollbar">
        <div class="d-flex justify-content-between">
            <div *ngIf="taskListUtils.taskListItemOptions.isBeforeSearchState" class="task-list-message text-high-contrast"
                 translate="label.taskListNotSearchedYet">
            </div>
            <div *ngIf="taskListUtils.taskListItemOptions.isNotFoundState" class="task-list-message text-high-contrast"
                 translate="label.taskListNotFound">
            </div>
            <div *ngIf="!taskListUtils.taskListItemOptions.isBeforeSearchState && !taskListUtils.taskListItemOptions.isNotFoundState"
                 class="task-list-message text-high-contrast font-weight-bold"
                 translate="label.tasks">
            </div>
            <app-export-task-list></app-export-task-list>
        </div>
        <ng-container
                *ngIf="!taskListUtils.taskListItemOptions.isBeforeSearchState && !taskListUtils.taskListItemOptions.isNotFoundState">
            <div class="ml-2 my-1" *ngFor="let task of taskListUtils.taskListItemOptions.taskList; let index = index">
                <div class="task-list-item-details font-weight-high-contrast text-dark">
                    <div class="task-list-item-details-unbreakable-chunk">
                        <span class="font-weight-bold black-color">{{"label.status" | translate}}:</span>
                        <span>
                            <span class="theme-color"> {{"enum.taskStatus." + task?.status | translate}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="task-list-item-details-unbreakable-chunk"
                         *ngIf="task?.appointment?.oversellingDefinitionId">
                        <i class="fa-regular fa-file-invoice-dollar"></i>
                        <span>
                            <span>{{'label.oversold' | translate}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="task-list-item-details-unbreakable-chunk">
                        <span class="font-weight-bold">{{"label.dueDate" | translate}}:&nbsp;</span>
                        <span>
                            <span>{{task?.dueDate | fullDateNoTimeFormatPipe}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="font-weight-bold task-list-item-details-unbreakable-chunk">
                        <i class="fas fa-user"></i>
                        <span>{{task?.patient?.fullName}},&nbsp;</span>
                    </div>
                    <div class="d-flex align-items-center task-list-item-details-unbreakable-chunk"
                         *ngIf="!!task?.patient?.documentNumberFormatted">
                        <i class="fa fa-id-card"></i>
                        <span>
                            <span>{{task?.patient?.documentNumberFormatted}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="task-list-item-details-unbreakable-chunk">
                        <i class="fas fa-phone"></i>
                        <span>
                            <span>{{task?.patient?.mainPhoneNumber}}</span>
                            <span *ngIf="!!task?.patient?.alternatePhoneNumber">,&nbsp;</span>
                            <span *ngIf="!!task?.patient?.alternatePhoneNumber">
                                {{task?.patient?.alternatePhoneNumber}}
                            </span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="task-list-item-details-unbreakable-chunk">
                        <span class="font-weight-bold">{{"label.type" | translate}}:</span>
                        <span>
                            <span class="theme-color">{{"enum.taskType." + task?.type | translate}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div class="task-list-item-details-unbreakable-chunk theme-color">
                        <span *ngIf="task?.service?.speciality?.name" class="font-weight-bold">
                            {{task?.service?.speciality?.name}}
                        </span>
                        <span *ngIf="task?.service?.name">
                            <span>{{task?.service?.name}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div *ngIf="task?.subServices?.length > 0" class="task-list-item-details-unbreakable-chunk theme-color">
                        <span class="underline ml-1 cursor-pointer"
                              translate="label.subServices"
                              [ngbPopover]="subServices" placement="top"
                              popoverClass="popover-shadow"></span>
                        <ng-template #subServices>
                            <div *ngFor="let taskSubService of task?.subServices">
                                {{taskSubService.subService.name}} <br>
                            </div>
                        </ng-template>
                        <span class="vertical-line mx-2">&nbsp;</span>
                    </div>
                    <div *ngIf="task?.resource?.name"
                         class="task-list-item-details-unbreakable-chunk font-weight-bold">
                        <i class="fas fa-user-md"></i>
                        <span>
                            <span>{{task?.resource?.name}}</span>
                            <span class="vertical-line mx-2"></span>
                        </span>
                    </div>
                    <div *ngIf="task?.observations" class="task-list-item-details-unbreakable-chunk theme-color">
                        <span class="underline cursor-pointer font-weight-bolder"
                              translate="label.observations"
                              [ngbPopover]="observations" placement="top"
                              popoverClass="popover-shadow"></span>
                        <ng-template #observations>
                            <div>{{task?.observations}}</div>
                        </ng-template>
                        <span class="vertical-line mx-2">&nbsp;</span>
                    </div>
                    <div class="theme-color cursor-pointer task-list-item-details-unbreakable-chunk"
                         (click)="onViewDetails(task)">
                        <i *ngIf="task?.appointmentId" class="fas fa-calendar mr-2 d-flex align-items-center"></i>
                        <span class="underline font-weight-600" translate="label.viewAllDetails"></span>
                    </div>
                </div>
                <div class="d-flex flex-row flex-wrap">
                    <app-task-item-actions-buttons
                            class="d-flex no-block flex-wrap"
                            [options]="{taskListItem: task}"
                            (taskItemAction)="onTaskItemAction($event, index)">
                    </app-task-item-actions-buttons>
                </div>
                <div class="horizontal-line"></div>
            </div>
        </ng-container>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="taskListUtils.taskListItemOptions.totalTaskItems > 0"
         class="pagination-component px-2 py-2 text-high-contrast">
        <ngb-pagination (pageChange)="onChangePagination($event)"
                        [(page)]="taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="taskListUtils.taskListItemOptions.totalTaskItems" [maxSize]="5"
                        [pageSize]="taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="taskListUtils.taskListItemOptions.showItemsPerPageDropdown = !taskListUtils.taskListItemOptions.showItemsPerPageDropdown;"
                          [ngClass]="{'show': taskListUtils.taskListItemOptions.showItemsPerPageDropdown}"
                          aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': taskListUtils.taskListItemOptions.showItemsPerPageDropdown}"
                   class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)"
                     *ngFor="let page of taskListUtils.taskListItemOptions.itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
        </div>
    </div>
</div>
