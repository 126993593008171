import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActivityPlanTemplateFrequency,
    ActivityPlanTemplateFrequencyTypeEnum
} from '../../activity-plan-template-md.types';
import {
    ActivityPlanScheduledEndTypeEnum,
    EnqueueUnitEnum,
    ActivityPlanExecutionTypeEnum,
    ActivityPlanTemplateServiceType
} from 'sked-base';
import {ActivityPlanTemplateMdUtils} from '../../activity-plan-template-md-util';
import * as lodash from 'lodash';

@Component({
    selector: 'app-activity-plan-template-service-recurrence-modal',
    templateUrl: './activity-plan-template-service-recurrence-modal.component.html',
    styleUrls: ['./activity-plan-template-service-recurrence-modal.component.scss']
})
export class ActivityPlanTemplateServiceRecurrenceModalComponent implements OnInit {
    activityPlanTemplateFrequencyTypeEnum = ActivityPlanTemplateFrequencyTypeEnum;
    enqueueUnitEnumList = Object.values(EnqueueUnitEnum);
    frequencyTypeEnumList = Object.values(ActivityPlanTemplateFrequencyTypeEnum);
    activityPlanScheduledEndTypeEnum = ActivityPlanScheduledEndTypeEnum;
    initialScopedServiceItemValue: ActivityPlanTemplateServiceType;
    initialScopedServiceFrequencyItem: ActivityPlanTemplateFrequency;
    spinnerId = 'activity-plan-template-service-recurrence';
    constants = constants;

    constructor(
        public activeModal: NgbActiveModal,
        public activityPlanTemplateUtils: ActivityPlanTemplateMdUtils,
    ) {
    }

    ngOnInit(): void {
        this.saveInitialModalData();
    }

    onEndTypeChange() {
        this.activityPlanTemplateUtils.scopedServiceHasValues = true;
        if (this.activityPlanTemplateUtils.scopedServiceItem.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsNever) {
            this.activityPlanTemplateUtils.scopedServiceItem.count = null;
        } else if (this.activityPlanTemplateUtils.scopedServiceItem.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate) {
            this.activityPlanTemplateUtils.scopedServiceItem.count = null;
        }
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
    }

    onEndOccurrencesChange() {
        this.activityPlanTemplateUtils.scopedServiceHasValues = true;
        this.activityPlanTemplateUtils.scopedServiceItem.scheduledEndType = ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences;
        if ((this.activityPlanTemplateUtils.scopedServiceItem?.count ?? 0) > 1) {
            this.activityPlanTemplateUtils.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Scheduled;
        }
        this.onEndTypeChange();
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
    }

    onCloseModal() {
        this.loadInitialModalData();
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
        this.activeModal.close();
    }

    onSaveModal() {
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
        if (this.activityPlanTemplateUtils.scopedServiceValidation?.recurrenceModal?.isValid) {
            this.activeModal.close();
        }
    }

    private saveInitialModalData() {
        this.initialScopedServiceItemValue = lodash.cloneDeep(this.activityPlanTemplateUtils.scopedServiceItem);
        this.initialScopedServiceFrequencyItem = lodash.cloneDeep(this.activityPlanTemplateUtils.scopedServiceFrequencyItem);
    }

    private loadInitialModalData() {
        this.activityPlanTemplateUtils.scopedServiceItem = lodash.cloneDeep(this.initialScopedServiceItemValue);
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem = lodash.cloneDeep(this.initialScopedServiceFrequencyItem);
    }
}
