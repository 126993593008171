import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ResourceChannelRestrictionType,
    ResourceChannelRestrictionProvider,
    GeneralUtils as SkedBaseGeneralUtils,
    ChannelEnum,
    ChannelProvider,
    ResourceProvider,
    ServiceProvider,
    CenterOverrideType,
    CenterProvider,
    CenterType,
    Validations,
    ResourceType,
    ServiceType,
    FilterComponentChannelType,
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ResourceChannelRestrictionMdUtils} from '../resource-channel-restriction-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {forkJoin, Observable} from 'rxjs';
import {ResourceChannelRestrictionMainDependentFiltersType} from '../resource-channel-restriction.types';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-resource-channel-restriction',
    templateUrl: './create-resource-channel-restriction.component.html',
    styleUrls: ['./create-resource-channel-restriction.component.scss']
})
export class CreateResourceChannelRestrictionComponent implements OnInit, OnDestroy {
    CONSTANTS = constants;
    MAX_NUMBER_OF_DAYS: number;
    initialResourceChannelRestriction: ResourceChannelRestrictionType;
    resourceChannelRestrictionItem: ResourceChannelRestrictionType = {} as ResourceChannelRestrictionType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    mainDependentFilters: ResourceChannelRestrictionMainDependentFiltersType;
    initialFilterValues = this.resourceChannelRestrictionMdUtils.getInitialFilterValues();

    constructor(
        public centerProvider: CenterProvider,
        public channelProvider: ChannelProvider,
        public generalUtils: GeneralUtils,
        public resourceProvider: ResourceProvider,
        public serviceProvider: ServiceProvider,
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private resourceChannelRestrictionMdUtils: ResourceChannelRestrictionMdUtils,
        private resourceChannelRestrictionProvider: ResourceChannelRestrictionProvider,
        private router: Router,
        private skedBaseGeneralUtils: SkedBaseGeneralUtils,
        private validations: Validations
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveResourceChannelRestrictionData(resourceChannelRestriction: ResourceChannelRestrictionType) {
        const isTemplateValid = this.validateFields(resourceChannelRestriction);
        if (isTemplateValid) {
            resourceChannelRestriction.centerOverrideCount = resourceChannelRestriction.centerOverrides.length;
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createResourceChannelRestriction(resourceChannelRestriction);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialResourceChannelRestriction, resourceChannelRestriction)) {
                    this.messagesService.success('toastr.success.resourceChannelRestrictionEdit', true);
                    this.goToParentPage();
                } else {
                    this.editResourceChannelRestriction(this.initialResourceChannelRestriction, resourceChannelRestriction);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/timeWindowsForResource']);
    }

    isSaveButtonEnabled() {
        return this.validateFields(this.resourceChannelRestrictionItem);
    }

    onSelectedChannelFilter(channelFilterList: FilterComponentChannelType[]): void {
        this.initialFilterValues.channel = channelFilterList;
        this.resourceChannelRestrictionItem.channel = (channelFilterList?.length > 0) ? ChannelEnum[channelFilterList[0].enumValue] : undefined;
    }

    onSelectedResourceFilter(resourceFilterList: ResourceType[]): void {
        this.initialFilterValues.resource = resourceFilterList;
        this.resourceChannelRestrictionItem.resourceId = (resourceFilterList?.length > 0) ? resourceFilterList[0].id : undefined;
        this.mainDependentFilters.service.resourceId = this.resourceChannelRestrictionItem.resourceId;
        this.mainDependentFilters.center.resourceId = this.resourceChannelRestrictionItem.resourceId;
    }

    onSelectedServiceFilter(serviceFilterList: ServiceType[]): void {
        this.initialFilterValues.service = serviceFilterList;
        this.resourceChannelRestrictionItem.serviceId = (serviceFilterList?.length > 0) ? serviceFilterList[0].id : undefined;
        this.mainDependentFilters.resource.serviceId = this.resourceChannelRestrictionItem.serviceId;
        this.mainDependentFilters.center.serviceId = this.resourceChannelRestrictionItem.serviceId;
    }

    onSelectedCenterFilter(centerFilterList: CenterType[]): void {
        this.initialFilterValues.center = centerFilterList;
        this.resourceChannelRestrictionItem.centerOverrides.push({centerId: centerFilterList[centerFilterList.length - 1].id} as CenterOverrideType);
    }

    deleteCenterOverride(index: number): void {
        this.resourceChannelRestrictionItem.centerOverrides.splice(index, 1);
        this.initialFilterValues.center.splice(index, 1);
    }

    isNumberOfDaysValid(days: number): boolean {
        return !this.generalUtils.isEmpty(days)
            && this.validations.getValidateIntegerBetween(days.toString(), 1, this.MAX_NUMBER_OF_DAYS, '').isValid;
    }

    isCenterOverrideFilterEnabled(): boolean {
        for (const centerOverride of this.resourceChannelRestrictionItem.centerOverrides) {
            if (!centerOverride.centerId || !this.isNumberOfDaysValid(centerOverride.days)) {
                return false;
            }
        }
        return true;
    }

    goToEdit() {
        history.replaceState({
            resourceChannelRestriction: this.resourceChannelRestrictionItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new ResourceChannelRestriction
    private createResourceChannelRestriction(resourceChannelRestriction: ResourceChannelRestrictionType) {
        this.ngxLoader.start();
        this.resourceChannelRestrictionProvider.addEntry(resourceChannelRestriction)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newResourceChannelRestrictionAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ResourceChannelRestriction
    private editResourceChannelRestriction(oldResourceChannelRestriction: ResourceChannelRestrictionType, newResourceChannelRestriction: ResourceChannelRestrictionType) {
        this.ngxLoader.start();
        this.resourceChannelRestrictionProvider.updateEntry(oldResourceChannelRestriction, newResourceChannelRestriction)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.resourceChannelRestrictionEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(resourceChannelRestrictionItem: ResourceChannelRestrictionType): boolean {
        let isChannelOverridesValid = true;
        for (const channelOverride of resourceChannelRestrictionItem.centerOverrides) {
            if (!channelOverride.centerId || !channelOverride.days || !this.isNumberOfDaysValid(channelOverride.days)) {
                isChannelOverridesValid = false;
                break;
            }
        }
        return !!(resourceChannelRestrictionItem &&
            resourceChannelRestrictionItem.channel &&
            resourceChannelRestrictionItem.days &&
            resourceChannelRestrictionItem.resourceId &&
            resourceChannelRestrictionItem.serviceId &&
            isChannelOverridesValid
        );
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            resource: this.resourceChannelRestrictionMdUtils.getResourceDependentFilters(),
            service: this.resourceChannelRestrictionMdUtils.getServiceDependentFilters(),
            channel: this.resourceChannelRestrictionMdUtils.getChannelDependentFilters(),
            center: this.resourceChannelRestrictionMdUtils.getLocationDependentFilters(),
        };
        this.MAX_NUMBER_OF_DAYS = parseInt(this.skedBaseGeneralUtils.getSystemConfigValue(
            this.configDataService.systemConfig.value,
            'TimeWindowMaximum',
            true
        ).toString(), 10);
        if (history.state?.resourceChannelRestriction) {
            this.initialResourceChannelRestriction = history.state.resourceChannelRestriction;
            this.resourceChannelRestrictionItem = lodash.cloneDeep(history.state.resourceChannelRestriction);
            this.initialFilterValues.resource = [{
                id: this.resourceChannelRestrictionItem.resourceId,
                name: this.resourceChannelRestrictionItem.resource.name
            } as ResourceType];
            this.initialFilterValues.service = [{
                id: this.resourceChannelRestrictionItem.serviceId,
                name: this.resourceChannelRestrictionItem.service.name
            } as ServiceType];
            this.initialFilterValues.channel = [{name: this.resourceChannelRestrictionItem.channel}];
            this.loadCentersData();
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.resourceChannelRestrictionItem = this.resourceChannelRestrictionMdUtils.getInitialResourceChannelRestriction();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private loadCentersData() {
        if (this.resourceChannelRestrictionItem.centerOverrides.length > 0) {
            this.ngxLoader.start();
            const array: Observable<CenterType>[] = [];
            const queryFilter = this.resourceChannelRestrictionMdUtils.getQueryFilterForCenter();
            for (const center of this.resourceChannelRestrictionItem.centerOverrides) {
                array.push(this.centerProvider.getById(center.centerId, queryFilter));
            }
            forkJoin(array).subscribe(centers => {
                for (const center of centers) {
                    this.initialFilterValues.center.push(center);
                }
                this.ngxLoader.stop();
            }, err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stop();
                this.goToParentPage();
            });
        }
    }
}
