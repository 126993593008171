<div class="container-fluid rules-overview-wrapper rule-type-select-container custom-scrollbar pb-1" *ngIf="!rulesUtils.selectedRule">
    <div class="rule-type-select-header d-flex no-block justify-content-center align-items-center">
        <i class="fas fa-info-circle mr-2"></i>
        <span translate="label.ruleTypeSelectHeader"></span>
    </div>
    <div class="rule-type-category-list" *ngIf="!!rulesUtils.rulesOptions && !!rulesUtils.rulesCategories">
        <ng-container *ngFor="let category of rulesUtils.rulesCategories">
            <div *ngIf="category.visible" class="rule-type-category-item">
                <div class="rule-type-category-label" [translate]="category.label"></div>
                <div class="rule-type-container-list">
                    <ng-container *ngFor="let ruleTypeEnum of category.ruleTypes">
                        <div class="rule-type-container-item" *ngIf="!!rulesUtils.rulesOptions[ruleTypeEnum]?.visible" (click)="onRuleTypeSelect(ruleTypeEnum)">
                            <i class="item-icon" [ngClass]="rulesUtils.rulesOptions[ruleTypeEnum].icon"></i>
                            <span class="item-text" [translate]="rulesUtils.rulesOptions[ruleTypeEnum].label"></span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="container-fluid rules-overview-wrapper overview-screen-md pb-1 custom-scrollbar" *ngIf="!!rulesUtils.selectedRule">
    <app-rules-outlet></app-rules-outlet>
</div>
