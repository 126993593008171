<div class="task-list-filters-container p-1">
    <div class="row mb-0 mx-1 pt-1 cursor-pointer" (click)="changeCollapseFilterSection()">
        <div class="col ml-1 text-left">
            <h5 class="text-left title">{{'label.filters' | translate | uppercase}}</h5>
        </div>
        <div class="col mr-1 text-right align-self-center">
            <i class="btn btn-sm fas"
               [ngClass]="taskListUtils.filtersOptions.areFiltersCollapsed ? 'fa-angle-down':'fa-angle-up'"
               [attr.aria-expanded]="!taskListUtils.filtersOptions.areFiltersCollapsed">
            </i>
        </div>
    </div>
    <div [ngbCollapse]="taskListUtils.filtersOptions.areFiltersCollapsed" class="row mb-0 mx-1 pl-2">
        <div class="col-10 custom-col-10 form-group pl-2 pr-0 list-filters-container" id="task-list-filters-container">
            <sbase-filter-wrapper
                    class="d-flex my-1 list-filter list-filter-sbase-filter-wrapper"
                    [options]="taskListUtils.filtersOptions.filterWrapperOptions"
                    [updateFiltersValue]="updateFilterWrapperValues"
                    (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"
            ></sbase-filter-wrapper>
        </div>
        <div class="col-1 custom-col-2 action-button task-list-filters-search no-block my-2 flex-column">
            <div class="row">
                <button class="action-button more-button btn" (click)="onViewMore()">
                    <i class="fas fa-filter custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.viewMore'></span>
                </button>
            </div>
            <div class="row">
                <button class="action-button reset-button btn" (click)="onClear()">
                    <i class="far fa-trash-alt custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
        <div class="col-1 custom-col-1 action-button task-list-filters-search no-block my-2">
            <button class="search-button"
                    [ngClass]="!taskListUtils.filtersOptions.areFiltersValid ? 'disabled' : null"
                    [ngbCollapse]="taskListUtils.filtersOptions.areFiltersCollapsed"
                    (click)="onSearch()">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>

    <div class="d-none" id="task-list-filters-to-move">
        <!-- These elements are moved next to the filters above from filter wrapper on ngAfterViewInit -->
        <div class="form-group mb-0 task-list-checkbox-filter list-filter list-filter-checkbox">
            <label class="checkbox d-inline-flex align-items-center"
                   [ngbPopover]="checkboxOnlyTasksWithAppointmentsDisplayValue"
                   placement="bottom"
                   popoverClass="popover-shadow"
                   triggers="mouseenter:mouseleave">
                <input type="checkbox"
                       name="task-list-filters-checkbox-onlyTasksWithAppointments"
                       [(ngModel)]='taskListUtils.filtersOptions.onlyTasksWithAppointmentsCheckboxNgModel'
                       (ngModelChange)="taskListUtils.updateFilterState(['onlyTasksWithAppointmentsCheckboxNgModel'])"
                       [ngModelOptions]="{standalone: true}">
                <ng-template [ngIf]="true" #checkboxOnlyTasksWithAppointmentsDisplayValue>
                    <span class="ml-2 text-high-contrast" translate="label.onlyTasksWithAppointments"></span>
                </ng-template>
            </label>
        </div>
        <div class="form-group mb-0 task-list-checkbox-filter list-filter list-filter-checkbox">
            <label class="checkbox d-inline-flex align-items-center"
                   [ngbPopover]="checkboxOnlyExpiringTasksDisplayValue"
                   placement="bottom"
                   popoverClass="popover-shadow"
                   triggers="mouseenter:mouseleave">
                <input type="checkbox"
                       name="task-list-filters-checkbox-onlyExpiringTasks"
                       [(ngModel)]='taskListUtils.filtersOptions.onlyExpiringTasksCheckboxNgModel'
                       (ngModelChange)="onOnlyExpiringTasksCheckboxChange()"
                       [ngModelOptions]="{standalone: true}">
                <ng-template [ngIf]="true" #checkboxOnlyExpiringTasksDisplayValue>
                    <span class="ml-2 text-high-contrast" translate="label.onlyExpiringTasks"></span>
                </ng-template>
            </label>
        </div>
        <div class="form-group mb-0 task-list-date-range-container list-filter list-filter-date-range">
            <sbase-date-range class="input-element"
                              [ngClass]="{'input-element-has-error': !taskListUtils.filtersOptions.filterValidations?.dueDateRange?.isValid}"
                              [options]="taskListUtils.filtersOptions.dueDateRangeOptions"
                              [updateDateRangeValue]="updateDueDateRangeValue"
                              (onDateRangeChanged)="onDueDateRangeChanged($event)">
            </sbase-date-range>
        </div>
    </div>

    <ng-template #taskListFiltersViewMoreTemplate>
        <div class="form-group mb-3 task-list-select-filter">
            <ng-select class="form-control rounded-1 custom-ng-select"
                       [ngClass]="!!taskListUtils.filtersOptions.processTypeSelectNgModel ? 'ng-select-has-value' : null"
                       dropdownPosition="bottom"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{'label.selectTaskProcessType' | translate}}"
                       [(ngModel)]="taskListUtils.filtersOptions.processTypeSelectNgModel"
                       (ngModelChange)="sanitizeNgSelectValue('processTypeSelectNgModel'); taskListUtils.updateFilterState(['processTypeSelectNgModel'])">
                <ng-option [value]="undefined">
                    <span translate="label.selectTaskProcessType"></span>
                </ng-option>
                <ng-option *ngFor="let item of skedTaskProcessingTypeEnumList"
                        [value]="item">
                    <span [translate]="'enum.taskProcessingType.' + item"></span>
                </ng-option>
            </ng-select>
        </div>
        <div class="form-group mb-3 task-list-select-filter">
            <ng-select class="form-control rounded-1 custom-ng-select"
                       [ngClass]="!!taskListUtils.filtersOptions.statusSelectNgModel ? 'ng-select-has-value' : null"
                       dropdownPosition="bottom"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{'label.selectStatus' | translate}}"
                       [(ngModel)]="taskListUtils.filtersOptions.statusSelectNgModel"
                       (ngModelChange)="sanitizeNgSelectValue('statusSelectNgModel'); taskListUtils.updateFilterState(['statusSelectNgModel'])">
                <ng-option [value]="undefined">
                    <span translate="label.selectStatus"></span>
                </ng-option>
                <ng-option *ngFor="let item of skedTaskStatusEnumList"
                        [value]="item">
                    <span [translate]="'enum.taskStatus.' + item"></span>
                </ng-option>
            </ng-select>
        </div>
        <div class="form-group mb-0 task-list-date-range-container task-list-create-date-range-container">
            <sbase-date-range class="input-element"
                              [ngClass]="{'input-element-has-error': !taskListUtils.filtersOptions.filterValidations?.createdDateRange?.isValid}"
                              [options]="taskListUtils.filtersOptions.createdDateRangeOptions"
                              [updateDateRangeValue]="updateCreatedDateRangeValue"
                              (onDateRangeChanged)="onCreatedDateRangeChanged($event)">
            </sbase-date-range>
        </div>
    </ng-template>
</div>
