import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    AppointmentStatusTransitionReasonCategoryEnum,
    AppointmentStatusTransitionReasonType,
    ODataQueryObjectType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';

@Injectable({
    providedIn: 'root'
})
export class AppointmentStatusTransitionReasonMdUtils {
    tableFilters: TableFiltersType;

    constructor(private generalUtils: GeneralUtils) {
    }

    getQueryFilterForAppointmentStatusTransitionReasonMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'Others', 'Category', 'System', 'Situation', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialAppointmentStatusTransitionReason(): AppointmentStatusTransitionReasonType {
        return {
            name: '',
            others: false,
            category: 'noValue' as AppointmentStatusTransitionReasonCategoryEnum,
            system: false,
            situation: undefined,
        } as AppointmentStatusTransitionReasonType;
    }
}
