import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ConfirmDeleteModalService,
    EntityTypeEnum,
    EventActionProvider,
    EventActionType,
    EventActionTypeEnum,
    EventGroupEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {EventActionMdUtils} from './event-action-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import * as lodash from 'lodash';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-event-action-md',
    templateUrl: './event-action-md.component.html',
    styleUrls: ['./event-action-md.component.scss']
})
export class EventActionMDComponent implements OnInit, AfterViewInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    eventActionMdList: EventActionType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    EVENT_ACTION = 'EventAction';
    constants = constants;
    EventActionTypeList = Object.values(EventActionTypeEnum);
    EventGroupList = Object.values(EventGroupEnum);
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    private searchEventActionChanged = new Subject<string>();

    constructor(
        public eventActionMdUtils: EventActionMdUtils,
        public eventActionProvider: EventActionProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('EventActionRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createEventAction') {
            this.loadInitialFilters({tableFilters: true, modalFilters: false});
        }

        this.loadEventActionMdData();
        this.subscribeSearchByEventActionChanged();
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            if (this.previousRouteService.getPreviousUrl() !== '/createEventAction') {
                this.loadInitialFilters({tableFilters: false, modalFilters: true});
            }
        });
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            this.eventActionMdUtils.modalFilters.areFiltersActive = this.eventActionMdUtils.isAnyFilterActive();
            this.eventActionMdUtils.previousModalFilters = lodash.cloneDeep(this.eventActionMdUtils.modalFilters);
            // Filter the data based on the new filters
            this.eventActionMdUtils.tableFilters.filter.type = this.eventActionMdUtils.modalFilters.eventTypeFilterOptions.ngModel;
            this.eventActionMdUtils.tableFilters.filter.eventGroup = this.eventActionMdUtils.modalFilters.eventGroupFilterOptions.ngModel;
            this.loadEventActionMdData(true);
        } else {
            this.eventActionMdUtils.modalFilters = lodash.cloneDeep(this.eventActionMdUtils.previousModalFilters);
        }
    }

    // method to navigate create EventAction
    createEventAction(): void {
        this.router.navigate(['/createEventAction']);
    }

    displayChangeLog(id: string, actionType: string, name: string) {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    editEventAction(eventAction: EventActionType) {
        this.router.navigate(['/createEventAction'], {state: {eventAction, action: constants.EDIT}});
    }

    viewEventAction(eventAction: EventActionType) {
        this.router.navigate(['/createEventAction'], {state: {eventAction, action: constants.VIEW}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.eventActionMdUtils.tableFilters.currentPage = page;
        this.loadEventActionMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.eventActionMdUtils.tableFilters.currentPage = 1;
        this.eventActionMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadEventActionMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.eventActionMdUtils.tableFilters.orderBy[property];
        if (this.eventActionMdUtils.tableFilters.orderBy) {
            this.eventActionMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadEventActionMdData(false);
    }

    onSearchEventActionChanged(value: string) {
        this.searchEventActionChanged.next(value);
    }

    onClearOrderBy(selectedItem) {
        delete this.eventActionMdUtils.tableFilters.orderBy[selectedItem];
        this.loadEventActionMdData(false);
    }

    displayConfirmDeleteItemModal(eventActionId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.eventActionProvider, eventActionId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(eventActionId);
            }
        });
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.eventActionMdUtils.modalFilters[property].ngModel = undefined;
        }
    }

    // function to get EventAction data
    private loadEventActionMdData(includeCount: boolean = true) {
        const queryFilter = this.eventActionMdUtils.getQueryFilterForEventActionMD(this.eventActionMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.eventActionProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.eventActionMdList = response.value;
                if (response?.count) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        setTimeout(() => {
            this.onSearchEventActionChanged('');
            this.loadInitialFilters({tableFilters: true, modalFilters: true});
            this.loadEventActionMdData(true);
        });
    }

    private loadInitialFilters({tableFilters, modalFilters}: { tableFilters?: boolean, modalFilters?: boolean }) {
        if (!!tableFilters) {
            this.eventActionMdUtils.tableFilters = this.eventActionMdUtils.getInitialTableFilter();
        }
        if (!!modalFilters) {
            this.eventActionMdUtils.modalFilters = this.eventActionMdUtils.getInitialModalFilters();
            this.eventActionMdUtils.previousModalFilters = this.eventActionMdUtils.getInitialModalFilters();
        }
    }

    // method for deleting table item
    private deleteItem(id) {
        this.eventActionMdUtils.tableFilters = this.eventActionMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.eventActionProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadEventActionMdData();
                this.messagesService.success('toastr.success.eventActionDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByEventActionChanged() {
        const self = this;
        this.searchEventActionChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.eventActionMdUtils.tableFilters.filter.name = searchValue;
                self.loadEventActionMdData();
            });
    }
}
