import {Injectable} from '@angular/core';
import {
    ODataQueryObjectType,
    SubServiceDependentFiltersType,
    SubServiceType
} from 'sked-base';
import {TableFiltersType} from '../../../data-model/general.type';
import {constants} from '../../constants/constants';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';
import * as lodash from 'lodash';
import {AssignSubServicesDisplayType} from './assign-sub-services.types';

@Injectable({
    providedIn: 'root'
})

export class AssignSubServicesUtils {
    constructor() {
    }

    getSubServiceDependentFilters = (): SubServiceDependentFiltersType => ({
        searchPhrase: '',
        areaId: null,
        count: true,
        serviceId: null,
        locationId: null,
        includeAvailabilities: false,
        resourceId: null,
        specialityId: null,
        exclusionList: []
    })

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getQueryFilterForSubServices(serviceId: string, tableFilters: TableFiltersType): ODataQueryObjectType {
        const query: ODataQueryObjectType = {} as ODataQueryObjectType;

        query.count = true;
        query.skip = (tableFilters.currentPage - 1) * tableFilters.itemsPerPage;
        query.top = tableFilters.itemsPerPage;
        query.select = ['Id', 'Name', 'DefaultDuration', 'ShortId', 'Code'];
        query.filter = {Services: {any: {Id: {eq: {type: 'guid', value: serviceId}}}}};

        for (const item in tableFilters.filter) {
            if (tableFilters.filter.hasOwnProperty(item) && tableFilters.filter[item]) {
                switch (item) {
                    case 'subService': {
                        query.filter.Id = {eq: {type: 'guid', value: tableFilters.filter[item].subServiceId}};
                        break;
                    }
                }
            }
        }

        return query;
    }

    mapSubServicesForDisplay(subServices: SubServiceType[], availabilitySubServices): AssignSubServicesDisplayType[] {
        const subServicesToDisplay: AssignSubServicesDisplayType[] = [];

        for (const subService of subServices) {
            let itemForDisplay: AssignSubServicesDisplayType = {} as AssignSubServicesDisplayType;
            const itemFromAvailability = lodash.find(availabilitySubServices, {subServiceId: subService.id});

            itemForDisplay = subService as unknown as AssignSubServicesDisplayType;
            // @ts-ignore
            itemForDisplay.duration = itemFromAvailability?.duration ? itemFromAvailability.duration : subService?.duration ? subService.duration : subService.defaultDuration;
            itemForDisplay.selected = false;

            subServicesToDisplay.push(itemForDisplay);
        }

        return subServicesToDisplay;
    }

    matchAssignedSubServices(subServices: AssignSubServicesDisplayType[], assignedSubServices: IdNameType[]): AssignSubServicesDisplayType[] {
        if (assignedSubServices && assignedSubServices.length > 0) {
            for (const item of subServices) {
                const foundServiceById = lodash.find(assignedSubServices, {id: item.id});
                const foundServiceBySubServiceId = lodash.find(assignedSubServices, {subServiceId: item.id});
                if (!!foundServiceById || !!foundServiceBySubServiceId) {
                    item.selected = true;
                }
            }
        }
        return subServices;
    }

    getOnlyAssignedSubServices(tableFilters: TableFiltersType, selectedSubServices: AssignSubServicesDisplayType[]): { value: SubServiceType[], count?: number } {
        const filteredSelectedSubServices: { value: SubServiceType[], count?: number } = {} as { value: SubServiceType[], count?: number };

        let filteredSubServices = lodash.cloneDeep(selectedSubServices);
        if (tableFilters.filter.service) {
            filteredSubServices = lodash.filter(filteredSubServices, {id: tableFilters.filter.service.id});
        }
        if (tableFilters.filter.speciality) {
            filteredSubServices = lodash.filter(filteredSubServices, {specialityId: tableFilters.filter.speciality.id});
        }
        filteredSelectedSubServices.value = this.paginate(filteredSubServices, tableFilters.itemsPerPage, tableFilters.currentPage);
        filteredSelectedSubServices.count = filteredSubServices.length;

        return filteredSelectedSubServices;
    }

    paginate(array: any[], pageSize: number, pageNumber: number): any[] {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
}
