import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    CenterProvider,
    ChannelProvider,
    ConfirmDeleteModalService,
    EntityTypeEnum,
    ResourceChannelRestrictionProvider,
    ResourceChannelRestrictionType,
    ResourceProvider,
    ServiceProvider,
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {ResourceChannelRestrictionMdUtils} from './resource-channel-restriction-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    ResourceChannelRestrictionMainDependentFiltersType
} from './resource-channel-restriction.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-resource-channel-restriction-md',
    templateUrl: './resource-channel-restriction-md.component.html',
    styleUrls: ['./resource-channel-restriction-md.component.scss']
})
export class ResourceChannelRestrictionMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    resourceChannelRestrictionMdList: ResourceChannelRestrictionType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    readonly RESOURCE_CHANNEL_RESTRICTION = this.resourceChannelRestrictionProvider.name;
    readonly CONSTANTS = constants;
    mainDependentFilters: ResourceChannelRestrictionMainDependentFiltersType;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public resourceChannelRestrictionMdUtils: ResourceChannelRestrictionMdUtils,
        public resourceChannelRestrictionProvider: ResourceChannelRestrictionProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public centerProvider: CenterProvider,
        public serviceProvider: ServiceProvider,
        public channelProvider: ChannelProvider,
        public resourceProvider: ResourceProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }


    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('KeyResourceChannelRestrictionReadActivity');
        this.mainDependentFilters = {
            resource: this.resourceChannelRestrictionMdUtils.getResourceDependentFilters(),
            service: this.resourceChannelRestrictionMdUtils.getServiceDependentFilters(),
            channel: this.resourceChannelRestrictionMdUtils.getChannelDependentFilters(),
        } as ResourceChannelRestrictionMainDependentFiltersType;

        if (this.previousRouteService.getPreviousUrl() !== '/createTimeWindowForChannel') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadResourceChannelRestrictionMdData();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create ResourceChannelRestriction
    createResourceChannelRestriction(): void {
        this.router.navigate(['/createTimeWindowForResource']);
    }

    editResourceChannelRestriction(resourceChannelRestriction: ResourceChannelRestrictionType) {
        this.router.navigate(['/createTimeWindowForResource'], {
            state: {
                resourceChannelRestriction,
                action: constants.EDIT
            }
        });
    }

    viewResourceChannelRestriction(resourceChannelRestriction: ResourceChannelRestrictionType) {
        this.router.navigate(['/createTimeWindowForResource'], {
            state: {
                resourceChannelRestriction,
                action: constants.VIEW
            }
        });
    }

    onSelectedChannelFilter(channelFilterList: any[]): void {
        this.resourceChannelRestrictionMdUtils.initialFilterValues.channel = channelFilterList;
        this.resourceChannelRestrictionMdUtils.tableFilters.filter.channel = (channelFilterList && channelFilterList.length > 0)
            ? channelFilterList[0] : undefined;
        this.loadResourceChannelRestrictionMdData();
    }

    onSelectedResourceFilter(resourceFilterList: any[]): void {
        this.resourceChannelRestrictionMdUtils.initialFilterValues.resource = resourceFilterList;
        this.resourceChannelRestrictionMdUtils.tableFilters.filter.resource = (resourceFilterList && resourceFilterList.length > 0)
            ? resourceFilterList[0] : undefined;
        this.loadResourceChannelRestrictionMdData();
    }

    onSelectedServiceFilter(serviceFilterList: any[]): void {
        this.resourceChannelRestrictionMdUtils.initialFilterValues.service = serviceFilterList;
        this.resourceChannelRestrictionMdUtils.tableFilters.filter.service = (serviceFilterList && serviceFilterList.length > 0)
            ? serviceFilterList[0] : undefined;
        this.loadResourceChannelRestrictionMdData();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.resourceChannelRestrictionMdUtils.tableFilters.currentPage = page;
        this.loadResourceChannelRestrictionMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.resourceChannelRestrictionMdUtils.tableFilters.currentPage = 1;
        this.resourceChannelRestrictionMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadResourceChannelRestrictionMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.resourceChannelRestrictionMdUtils.tableFilters.orderBy[property];
        if (this.resourceChannelRestrictionMdUtils.tableFilters.orderBy) {
            this.resourceChannelRestrictionMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadResourceChannelRestrictionMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.resourceChannelRestrictionMdUtils.tableFilters.orderBy[selectedItem];
        this.loadResourceChannelRestrictionMdData(false);
    }

    displayConfirmDeleteItemModal(resourceChannelRestrictionId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.resourceChannelRestrictionProvider, resourceChannelRestrictionId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(resourceChannelRestrictionId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    // function to get ResourceChannelRestriction data
    private loadResourceChannelRestrictionMdData(includeCount: boolean = true) {
        const queryFilter = this.resourceChannelRestrictionMdUtils.getQueryFilterForResourceChannelRestrictionMD(
            this.resourceChannelRestrictionMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.resourceChannelRestrictionProvider.getEntries(queryFilter).pipe(take(1))
            .subscribe((response: { value: ResourceChannelRestrictionType[], count: number }) => {
                this.resourceChannelRestrictionMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadResourceChannelRestrictionMdData(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.resourceChannelRestrictionMdUtils.tableFilters = this.resourceChannelRestrictionMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.resourceChannelRestrictionMdUtils.initialFilterValues = this.resourceChannelRestrictionMdUtils.getInitialFilterValues();
        }
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.resourceChannelRestrictionMdUtils.tableFilters = this.resourceChannelRestrictionMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.resourceChannelRestrictionProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadResourceChannelRestrictionMdData();
                this.messagesService.success('toastr.success.resourceChannelRestrictionDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
