import {Component, Input, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {SwapResourcesFiltersSearchType} from '../resource-swap-filters/resource-swap-filters.types';
import {
    AppointmentProvider,
    AppointmentStatusEnum,
    AppointmentStatusValueEnum,
    ResourceSwapSearchResultType,
    ResourceSwapProvider,
    SwapResourcesType, AppointmentType
} from 'sked-base';
import {ResourceSwapAppointmentListUtils} from './resource-swap-appointment-list.utils';
import {Observable, of} from 'rxjs';
import * as lodash from 'lodash';
import {ResourceSwapUtils} from '../resource-swap.utils';
import {AppointmentCardEmittedActionType} from '../../../shared/component/appointment-card/appointment-card.types';
import {
    AppointmentActionEnum
} from '../../../shared/component/appointment-action-buttons/appointment-action-buttons.types';
import {mergeMap, take} from 'rxjs/operators';
import {ResizedEvent} from "../../../shared/directives/resize-event/resized.event";

@Component({
    selector: 'app-resource-swap-appointment-list',
    templateUrl: './resource-swap-appointment-list.component.html',
    styleUrls: ['./resource-swap-appointment-list.component.scss']
})
export class ResourceSwapAppointmentListComponent implements OnInit {
    @Input() swapResourcesFiltersSearch: Observable<SwapResourcesFiltersSearchType>;

    appointmentStatusEnumList = Object.keys(AppointmentStatusEnum);
    pagesWhereOnSearchForReplacementsHasBeenCalled: number[] = [];
    shouldDisableEverything = false;

    constructor(public resourceSwapAppointmentListUtils: ResourceSwapAppointmentListUtils,
                public resourceSwapUtils: ResourceSwapUtils,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private appointmentProvider: AppointmentProvider,
                private resourceSwapProvider: ResourceSwapProvider) {
    }

    ngOnInit(): void {
        this.loadDataFromState();
        this.checkIfNeededToResetEverything();
        this.swapResourcesFiltersSearch?.subscribe((filterValues) => {
            this.onFilterSearch(filterValues);
        });
        this.resourceSwapUtils.toggleAllAppointmentsSubject?.subscribe((checked: boolean) => {
            // Filter only the appointments that are nor disabled because of the status
            const onlyEnabledAppointments = this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList
                .filter(appointment => !this.isAppointmentDisabled(appointment.status));
            // Get only the first 10 appointments
            const firstTenAppointments = onlyEnabledAppointments.slice(0, 10);
            // Check or uncheck all appointments
            firstTenAppointments.forEach((appointment: AppointmentType) => {
                this.onAppointmentInputChanged(appointment.id, {target: { checked }});
            });
        });
        this.resourceSwapUtils.disableEverythingSubject?.subscribe((disable) => {
            this.shouldDisableEverything = disable;
        });
    }

    listenToAppointmentCardContainerHeightChanges(event: ResizedEvent) {
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.containerHeight = event.newRect.height;
    }

    onAppointmentItemAction({action, data}: AppointmentCardEmittedActionType, index: number = -1) {
        switch (action) {
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Cancel: {
                // The appointment is cancelled in cancel-appointment component, here we only remove the appointment from the list
                if (index === -1) {
                    return;
                }
                if (!!this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index]) {
                    lodash.pullAt(this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList, index);
                }
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index]) {
                    if (
                        !!this.resourceSwapUtils.filtersOptions.latestSearchFilterValues.status
                        && this.resourceSwapUtils.filtersOptions.latestSearchFilterValues.status !== data.status
                    ) {
                        lodash.pullAt(this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList, index);
                    } else {
                        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index] = {
                            ...this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index],
                            etag: data.etag,
                            status: data.status,
                        };
                    }
                }
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index]) {
                    this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index] = {
                        ...this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList[index],
                        etag: data.etag,
                        paid: data.paid,
                    };
                }
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                return;
            }
        }
    }

    onAppointmentInputChanged(appointmentId: string, event: any) {
        // If the input is unchecked remove the appointment id from appointmentsIds
        if (!event.target.checked) {
            this.resourceSwapUtils.appointmentsIds = this.resourceSwapUtils.appointmentsIds.filter(id => id !== appointmentId);
        }
        // If appointmentsIds.length is === 10 and the input is checked display a message
        if (event.target.checked && this.resourceSwapUtils.appointmentsIds.length === 10) {
            // Set event.target.checked to false so the checkbox is unchecked
            event.target.checked = false;
            this.messagesService.warning('toastr.warning.max10AppointmentsSelected');
        }
        // If the input is checked and appointmentsIds.length is < 10 add the appointment id and the appointmentId is not already added into the array
        if (event.target.checked && this.resourceSwapUtils.appointmentsIds.length < 10 && !this.resourceSwapUtils.appointmentsIds.includes(appointmentId)) {
            this.resourceSwapUtils.appointmentsIds.push(appointmentId);
        }
    }

    onSearchForReplacements() {
        this.ngxLoader.start();
        this.resourceSwapProvider.searchForResourceSwap(this.getResourceAndAppointmentIdsForSearchForResourceSwap())
            .subscribe(({value}) => {
                this.setSwapResourcesOptions(value);
                const currentPage = this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
                    .pageFilters.currentPage;
                this.pagesWhereOnSearchForReplacementsHasBeenCalled.push(currentPage);
                this.ngxLoader.stop();
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }

    private getResourceAndAppointmentIdsForSearchForResourceSwap(): SwapResourcesType {
        const resourceId = this.resourceSwapUtils?.filtersOptions?.swapResourcesFiltersValues?.resource?.id;
        const currentPageAppointmentsIds = this.resourceSwapUtils?.swapResourcesAppointmentListItemOptions?.swapResourcesAppointmentList
            ?.map(({id}) => id);
        const appointmentIds = this.resourceSwapUtils?.appointmentsIds?.filter(appointmentId => currentPageAppointmentsIds.includes(appointmentId));

        return {
            resourceId,
            appointmentIds
        };
    }

    isAppointmentDisabled(appointmentStatus: AppointmentStatusEnum) {
        const status = AppointmentStatusValueEnum[appointmentStatus];
        return status === AppointmentStatusValueEnum.ServicePerformed
            || status === AppointmentStatusValueEnum.NotPerformed
            || status === AppointmentStatusValueEnum.Blocked
            || status === AppointmentStatusValueEnum.Cancelled;
    }

    onChangePagination(page: number) {
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
            .pageFilters.currentPage = page;
        this.searchForAppointmentsAndReplacements();
    }

    isAppointmentInputAlreadyChecked(appointmentId): boolean {
        return this.resourceSwapUtils.appointmentsIds.includes(appointmentId);
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
            .pageFilters.currentPage = 1;
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
            .pageFilters.itemsPerPage =
            itemPerPage;
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown = false;
        this.searchForAppointmentsAndReplacements();
    }

    onFilterSearch(filterValues: SwapResourcesFiltersSearchType) {
        this.resourceSwapUtils.disableEverythingSubject.next(false);
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters.searchFilters =
            filterValues;
        this.searchForAppointmentsAndReplacements();
    }

    onPageChange() {
        if (this.shouldDisableEverything) {
            return;
        }
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown =
            !this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown;
    }

    private setSwapResourcesOptions(resourceSwapSearchResult: ResourceSwapSearchResultType[]) {
        this.resourceSwapUtils.swapResourcesOptions.appointmentsSwapResources =
            this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList?.map(({id}) => (
                {
                    display: this.resourceSwapUtils.appointmentsIds.includes(id),
                    resources: this.resourceSwapUtils.appointmentsIds.includes(id) ? resourceSwapSearchResult
                        .find(({appointmentId}) => appointmentId === id)?.resources : [] as any,
                    appointmentId: id,
                    appointment: this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList
                        .find(appointment => appointment.id === id)
                }));

        const sortedAllAvailableSwapResources = lodash.sortBy(lodash.flatten(resourceSwapSearchResult.map(({resources}) => resources)), 'name');
        this.resourceSwapUtils.swapResourcesOptions.allAvailableSwapResources = lodash.uniqBy(sortedAllAvailableSwapResources, 'id');
    }

    private loadDataFromState(): void {
        // If shouldn't keep filters state rewrite the latest search filters
        if (!this.resourceSwapUtils.shouldKeepFiltersState) {
            this.resourceSwapUtils.swapResourcesAppointmentListState.filtersOptions.latestSearchFilterValues = undefined;
        }
        // If shouldMakeNewRequest and we have filter values, do request
        if (this.resourceSwapUtils.shouldMakeNewRequest) {
            this.onFilterSearch(
                this.resourceSwapUtils.swapResourcesAppointmentListState.filtersOptions?.latestSearchFilterValues
                ?? {} as SwapResourcesFiltersSearchType);
            return;
        }
        // If shouldKeepListState and we have items options, load them
        const shouldKeepListState = this.resourceSwapUtils.shouldKeepListState
            && !!this.resourceSwapUtils.swapResourcesAppointmentListState.swapResourcesAppointmentListItemOptions;
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions = shouldKeepListState
            ? lodash.cloneDeep(this.resourceSwapUtils.swapResourcesAppointmentListState.swapResourcesAppointmentListItemOptions)
            : this.resourceSwapUtils.getInitialSwapResourcesAppointmentListFiltersOptions();
        this.resourceSwapUtils.shouldKeepListState = false;
    }

    private checkIfNeededToResetEverything(): void {
        // If state is empty (all shouldKeep are false), then we reset everything
        if (!this.resourceSwapUtils.shouldKeepFiltersState &&
            !this.resourceSwapUtils.shouldKeepListState &&
            !this.resourceSwapUtils.shouldMakeNewRequest) {
            // Reset item list
            this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState = true;
            // Clear and show filters
            this.resourceSwapUtils.clearFiltersEmitter?.next();
            if (this.resourceSwapUtils.filtersOptions) {
                this.resourceSwapUtils.filtersOptions.areFiltersCollapsed = false;
            }
        }
    }

    private searchForAppointmentsAndReplacements(autoSearch: boolean = false): void {
        this.ngxLoader.start();
        this.resourceSwapUtils.shouldMakeNewRequest = false;
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList = [];
        const query = this.resourceSwapAppointmentListUtils.getAppointmentQueryFilter(
            autoSearch
                ? this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.latestSwapResourcesAppointmentListRequestFilters
                : this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
        );
        this.appointmentProvider.getByUserInformation(query, true, true)
            .pipe(
                mergeMap((appointmentList) => {
                    this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState = false;
                    this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.totalSwapResourcesAppointmentItems = appointmentList.count;
                    this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList = appointmentList.value;
                    this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState = appointmentList.value?.length <= 0;
                    if (!autoSearch) {
                        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.latestSwapResourcesAppointmentListRequestFilters =
                            lodash.cloneDeep(
                                this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
                            );
                        this.resourceSwapUtils.filtersOptions.latestSearchFilterValues =
                            lodash.cloneDeep(this.resourceSwapUtils.swapResourcesAppointmentListItemOptions
                                .swapResourcesAppointmentListRequestFilters.searchFilters
                            );
                    }
                    // Update state
                    this.resourceSwapUtils.updateItemsState([
                        'isBeforeSearchState', 'totalSwapResourcesAppointmentItems', 'swapResourcesAppointmentList', 'isNotFoundState', 'latestSwapResourcesAppointmentListRequestFilters'
                    ]);
                    this.resourceSwapUtils.updateFilterState(['latestSearchFilterValues']);
                    return this.getSearchForResourceSwapObservable();
                }),
                take(1)
            ).subscribe(({value}) => {
            if (value) {
                this.setSwapResourcesOptions(value);
            }
        }, (error) => {
            this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState = true;
            this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState = false;
            this.resourceSwapUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState'
            ]);
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private getSearchForResourceSwapObservable(): Observable<{ value: ResourceSwapSearchResultType[] }> {
        const swapResourcesAppointmentListIds = this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList
            .map(({id}) => id);
        const areSomeAppointmentsAlreadyChecked = swapResourcesAppointmentListIds.some(id => {
            return this.resourceSwapUtils.appointmentsIds.includes(id);
        });
        const currentPage = this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters
            .pageFilters.currentPage;
        const isCurrentPageIncluded = this.pagesWhereOnSearchForReplacementsHasBeenCalled.includes(currentPage);
        if (areSomeAppointmentsAlreadyChecked && isCurrentPageIncluded) {
            return this.resourceSwapProvider.searchForResourceSwap(this.getResourceAndAppointmentIdsForSearchForResourceSwap());
        } else {
            return of({} as { value: ResourceSwapSearchResultType[] });
        }
    }
}
