<div class="container-fluid create-object-detail-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-object-detail-rule-header">
        <h3 class="create-object-detail-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.objectDetailRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.objectDetailRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.objectDetailRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.objectDetailRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.objectDetailRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2" *ngIf="!isInputStepValid(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.objectDetailRuleMinimumConditionForInput"></span>
                        </div>
                        <div class="row">
                            <!-- Service -->
                            <div class="col-6">
                                <div class="form-group customSelect service">
                                    <label translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                  [buttonName]="'label.service2'"
                                                  [dependentFilters]="mainDependentFilters?.service"
                                                  [initialFilterValues]="[ruleItem?.service]"
                                                  [providerInstance]="serviceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Center -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                                  [buttonName]="'label.center2'"
                                                  [dependentFilters]="mainDependentFilters?.center"
                                                  [initialFilterValues]="[ruleItem?.center]"
                                                  [providerInstance]="centerProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Resource -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]="'label.resource'"
                                                  [dependentFilters]="mainDependentFilters?.resource"
                                                  [initialFilterValues]="[ruleItem?.resource]"
                                                  [providerInstance]="resourceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Tag -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.tag"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedTag($event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.tag"
                                                  [initialFilterValues]="[ruleItem?.tag]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="tagProvider">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Channel -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.channel"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedChannel($event)"
                                                  [buttonName]="'label.channel'"
                                                  [dependentFilters]="mainDependentFilters?.channel"
                                                  [initialFilterValues]="initialChannel"
                                                  [providerInstance]="channelProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Object Detail -->
                            <div class="col-6">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.objectDetail"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedObjectDetail($event)"
                                                  [buttonName]='"label.objectDetail"'
                                                  [dependentFilters]="mainDependentFilters?.objectDetail"
                                                  [initialFilterValues]="[ruleItem?.objectDetail]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.objectDetail?.id}"
                                                  [providerInstance]="objectDetailProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.objectDetail?.id"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Applies to -->
                            <div class="col-6">
                                <div class="form-group customSelect"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.appliesTo"></label>
                                    <ng-select
                                            class="custom-ng-select input-element"
                                            [ngClass]="selectedAppliesToValue === 'noValue' ? 'input-element-has-error' : 'ng-select-has-value'"
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="selectedAppliesToValue"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW"
                                            (change)="onAppliesToChange($event)">
                                        <ng-option value="noValue">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let option of appliesToEnumList"
                                                   [value]="option">
                                            {{'label.' + option + (['center', 'service'].includes(option) ? '2' : '') | translate}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="selectedAppliesToValue === 'noValue'"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.objectDetailRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.service2"></td>
                                            <td class="text-right">
                                                <span class="font-weight-bold"> {{ruleItem.service?.speciality?.name}} </span>
                                                {{ruleItem.service?.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.center2"></td>
                                            <td class="text-right">{{ruleItem.center?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.resource"></td>
                                            <td class="text-right">{{ruleItem.resource?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.channel"></td>
                                            <td class="text-right">{{ruleItem.channel ? ('enum.channel.' +
                                            ruleItem.channel | translate) : ''}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.objectDetail"></td>
                                            <td class="text-right">{{ruleItem.objectDetail?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.appliesTo"></td>
                                            <td class="text-right">{{'label.' + selectedAppliesToValue + (['center', 'service'].includes(selectedAppliesToValue) ? '2' : '') | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'ObjectDetailRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
