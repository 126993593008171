import {AppointmentType, PatientType} from 'sked-base';
import {TableFiltersType} from '../../../data-model/general.type';

export enum PatientMergeSide {
    LEFT,
    RIGHT
}

export interface PatientMergeSideOptions {
    appointmentsTablePagination: PatientMergeAppointmentTablePaginationInfo;
    patient?: PatientType;
    appointments?: AppointmentType[];
    keepPatient: boolean;
    keepCancelledAppointments: boolean;
}

export type PatientMergeOptions = {
    [key in PatientMergeSide]: PatientMergeSideOptions;
};

export interface PatientMergeAppointmentTablePaginationInfo {
    tableFilters: TableFiltersType;
    itemsPerPageList: number[];
    showItemsPerPageDropdown: boolean;
    totalTableItems: number;
}

export interface PatientAppointmentListRequestFilters {
    pageFilters: TableFiltersType;
}
