<div class="activity-plan-template-display-calendar">
    <!-- Calendar header (Month year, arrows) -->
    <div class="calendar-header">
        <div class="calendar-month-year-label">
            {{calendarPage.displayMonth}} {{calendarPage.displayYear}}
        </div>
        <div class="calendar-arrows">
            <div class="calendar-arrow text-high-contrast" (click)="onArrowClick(LEFT)"><</div>
            <div class="calendar-arrow text-high-contrast" (click)="onArrowClick(RIGHT)">></div>
            <div class="calendar-arrow text-high-contrast" [ngClass]="{'disabled': !isCalendarEnabled()}" (click)="onRefreshCalendar()">↻</div>
        </div>
    </div>
    <div *ngIf="calendarPage.startDate" class="text-high-contrast my-1">
        <span translate="label.simulationIsFrom"></span>&nbsp;
        {{calendarPage?.startDate?.stringDate | dateMMddyyyyFormatPipe}}&nbsp;
        <span *ngIf="activityPlanTemplateUtils.scopedServiceItem.scheduledEndType === activityPlanScheduledEndTypeEnum.EndsOnSpecificDate">
            <span translate="label.to"></span>&nbsp;
            {{calendarPage?.endDateMoment?.format('YYYY-MM-DD') | dateMMddyyyyFormatPipe}}&nbsp;
            <i class="fas fa-info-circle"
               [ngbPopover]="'label.threeMonthsAfterStartDate' | translate"
               triggers="mouseenter:mouseleave"
               popoverClass="popover-shadow"
               placement="left"></i>
        </span>
    </div>
    <!-- Calendar table -->
    <table class="calendar-table">
        <!-- Table header -->
        <tr class="calendar-table-head-row">
            <th *ngFor="let day of ddFormatWeekDays">{{day}}</th>
        </tr>
        <!-- Table rows -->
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: calendarPage.days.slice(0, 7), rowNumber: 0}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: calendarPage.days.slice(7, 14), rowNumber: 1}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: calendarPage.days.slice(14, 21), rowNumber: 2}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: calendarPage.days.slice(21, 28), rowNumber: 3}"></ng-container>
        <!-- Template for table rows -->
        <ng-template #tableRowTemplate let-dayItems>
            <tr class="calendar-table-row">
                <td class="calendar-table-cell" *ngFor="let dayItem of dayItems" (click)="onCalendarDayClick(dayItem)">
                    <!-- With popover -->
                    <span *ngIf="isCalendarEnabled() && dayItem.hasBanner && dayItem.enqueueDate; else calendarTableCellDisplayContainerWithoutPopover"
                          class="calendar-table-cell-display-container"
                          [ngbPopover]="calendarTableCellPopover"
                          placement="left"
                          popoverClass="popover-shadow"
                          triggers="mouseenter:mouseleave"
                          [ngClass]="{'today': dayItem.isToday, 'after-today': dayItem.isAfterToday, 'start-day': dayItem.isStartDate, 'disabled': !isCalendarEnabled()}">
                        <span class="calendar-table-cell-display-day">{{dayItem.displayDay}}</span>
                        <span class="calendar-table-cell-display-banner"
                              [ngClass]="{'empty': !dayItem.hasBanner}">
                        </span>
                    </span>
                    <ng-template #calendarTableCellPopover>
                        <span translate="label.enqueueDate"></span>:&nbsp;
                        <span>{{dayItem.enqueueDate | dateMMddyyyyFormatPipe}}</span>
                    </ng-template>

                    <!-- Without popover -->
                    <ng-template #calendarTableCellDisplayContainerWithoutPopover>
                        <span class="calendar-table-cell-display-container"
                              [ngClass]="{'today': dayItem.isToday, 'after-today': dayItem.isAfterToday, 'start-day': dayItem.isStartDate, 'disabled': !isCalendarEnabled()}">
                            <span class="calendar-table-cell-display-day">{{dayItem.displayDay}}</span>
                            <span class="calendar-table-cell-display-banner"
                                  [ngClass]="{'empty': !dayItem.hasBanner}">
                            </span>
                        </span>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </table>
</div>
