import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {FormArray, FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {formControlNames} from 'src/app/shared/constants/formControlNames';
import * as lodash from 'lodash';
import {CreatePatientUtils} from '../create-patient.utils';

@Component({
    selector: 'app-external-keys-form',
    templateUrl: './external-keys-form.component.html',
    styleUrls: ['./external-keys-form.component.scss']
})
export class ExternalKeysFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;

    externalKeysForm: FormGroup;
    externalKeysFormArray: FormArray;
    formControlNames = formControlNames;
    allInputs: DynamicFormInputType[] = [];

    constructor(public dynamicFormInputService: DynamicFormInputService,
                private rootFormGroup: FormGroupDirective,
                private formBuilder: FormBuilder,
                private createPatientUtils: CreatePatientUtils,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.externalKeysForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.externalKeysFormArray = this.externalKeysForm?.get(formControlNames.EXTERNAL_KEYS) as FormArray;
        if (this.externalKeysFormArray?.length === 0 && this.getInputByControlName(formControlNames.EXTERNAL_KEYS).visible) {
            this.addExternalKey();
        }
        this.externalKeysForm?.valueChanges?.subscribe(() => {
            this.changeDetectorRef.detectChanges();
            this.setExternalKeysErrorMessages();
        });
    }

    hasExternalKeysFormControlErrors(index: number): boolean {
        return this.dynamicFormInputService.hasError(this.externalKeysFormArray, String(index));
    }

    getExternalKeyControlErrorMessage(index: number): string {
        return this.externalKeysFormArray?.controls[index]?.errors?.error?.message;
    }

    addExternalKey(): void {
        const isAlreadyARowWithEmptyValues = this.externalKeysFormArray.getRawValue().some(({Origin, Key}) => Origin === '' && Key === '');
        if (!isAlreadyARowWithEmptyValues) {
            this.externalKeysFormArray.push(this.formBuilder.group({
                [formControlNames.ORIGIN]: '',
                [formControlNames.KEY]: '',
                // The id is not used as input in html, he makes the mapping easier because we need to also send the id to get the patch correctly
                [formControlNames.ID]: ''
            }));
        }
    }

    removeExternalKey(index: number): void {
        this.externalKeysFormArray.removeAt(index);
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options?.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private setExternalKeysErrorMessages() {
        // Remove all errors
        this.externalKeysFormArray.controls.map((control, index) => this.externalKeysFormArray.controls[index].setErrors(null));
        // Get all the indexes
        const externalKeysNotUniqueIndexes = this.getIndexesOfExternalKeysThatAreNotUnique();
        const externalKeysWithEmptyValuesIndexes = this.getIndexesOfExternalKeysThatHaveEmptyValues();

        if (externalKeysWithEmptyValuesIndexes.length > 0) {
            // If we have external keys with empty values
            externalKeysWithEmptyValuesIndexes.forEach(index => {
                this.externalKeysFormArray.controls[index]?.setErrors({
                    error: {message: 'label.error.invalidExternalKeysTable'}
                });
            });
        } else if (externalKeysNotUniqueIndexes.length > 0) {
            // If we not have external keys with empty values, but we have not unique external keys
            externalKeysNotUniqueIndexes.forEach(index => {
                this.externalKeysFormArray.controls[index]?.setErrors({
                    error: {message: 'label.error.externalKeysAreNotUnique'}
                });
            });
        }
    }

    private getIndexesOfExternalKeysThatAreNotUnique(): number[] {
        // Remove external keys id-s so we can find the duplicates
        const externalKeysWithoutId = this.externalKeysFormArray.getRawValue().map(({Origin, Key}) => ({Origin: Origin.trim(), Key: Key.trim()}));
        // Convert external keys to strings so we can compare them
        const externalKeysAsStrings = externalKeysWithoutId.map((externalKey) => JSON.stringify(externalKey));
        // Find all external keys duplicates
        const duplicatesExternalKeys = externalKeysAsStrings.filter((item, index) => index !== externalKeysAsStrings.indexOf(item));
        // Find all indexes of the external keys that are duplicates
        const duplicatesExternalKeysIndexes = [];
        duplicatesExternalKeys.forEach(duplicate => {
            externalKeysAsStrings.forEach((externalKey, index) => {
                if (externalKey === duplicate) {
                    duplicatesExternalKeysIndexes.push(index);
                }
            });
        });

        return [...new Set(duplicatesExternalKeysIndexes)];
    }

    private getIndexesOfExternalKeysThatHaveEmptyValues(): number[] {
        const externalKeys = this.externalKeysFormArray.getRawValue();
        // Find all external keys with empty values
        const externalKeysWithEmptyValues = externalKeys.filter(({Origin, Key}) => Origin?.trim() === '' || Key?.trim() === '');
        // Find all indexes of the external keys that have empty values
        const indexes = [];
        externalKeysWithEmptyValues.forEach(duplicate => {
            externalKeys.forEach((externalKey, index) => {
                if (externalKey === duplicate) {
                    indexes.push(index);
                }
            });
        });

        return [...new Set(indexes)];
    }
}
