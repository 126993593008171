import {Component, Input, OnInit} from '@angular/core';
import {AppointmentBulletinPdfQueryType, AppointmentProvider} from 'sked-base';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {TranslatedLanguageService} from '../../services/translated-language.service';

@Component({
    selector: 'app-print-bulletin-pdf-button',
    templateUrl: './print-bulletin-pdf-button.component.html',
    styleUrls: ['./print-bulletin-pdf-button.component.scss']
})
export class PrintBulletinPdfButtonComponent implements OnInit {
    @Input() appointmentId: string;

    constructor(private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private translatedLanguageService: TranslatedLanguageService,
                private appointmentProvider: AppointmentProvider) {
    }

    ngOnInit(): void {
    }

    printAppointmentBulletinPDF(): void {
        this.ngxLoader.start();
        const queryFilter: AppointmentBulletinPdfQueryType = {
            appointmentId: this.appointmentId,
            languageCode: this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage)
        };

        this.appointmentProvider.getAppointmentBulletinPdf(queryFilter)
            .pipe(take(1))
            .subscribe((response: {file: Blob, fileName: string}) => {
                this.processFileResponse(response);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private processFileResponse(response: {file: Blob, fileName: string}): void {
        const downloadUrl = URL.createObjectURL(response.file);
        // open in new tab
        window.open(downloadUrl, '_blank');
    }
}
