import {Injectable} from '@angular/core';
import {
    PatientControlRuleType,
    Expand,
    ODataQueryObjectType,
    SpecialityDependentFiltersType,
    AreaDependentFiltersType,
    ResourceDependentFiltersType,
    ServiceDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AreaModalFilterOptionsType,
    PatientControlRuleModalFiltersType,
    PatientControlRuleStateType,
    PatientTypeModalFilterOptionsType,
    ResourceModalFilterOptionsType,
    ServiceModalFilterOptionsType, SpecialityModalFilterOptionsType,
} from './patient-control-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesUtils} from '../../rules.utils';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class PatientControlRuleUtils {
    overviewState: PatientControlRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
        private rulesUtils: RulesUtils,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'patientControlRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.priority',
            hasSort: true,
            onSortByProperty: 'priority',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.area2',
            hasSort: true,
            onSortByProperty: 'area',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.speciality2',
            hasSort: true,
            onSortByProperty: 'speciality',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.service2',
            hasSort: true,
            onSortByProperty: 'service',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resource',
            hasSort: true,
            onSortByProperty: 'resource',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.numberOfDaysInThePast',
            abbreviatedLabel: 'label.numberOfDaysInThePastAbbreviation',
            hasSort: true,
            onSortByProperty: 'numberOfDaysInThePast',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.numberOfAppointments',
            abbreviatedLabel: 'label.numberOfAppointmentsAbbreviation',
            hasSort: true,
            onSortByProperty: 'numberOfAppointments',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientType',
            hasSort: true,
            onSortByProperty: 'patientType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'areaId':
                    case 'specialityId':
                    case 'serviceId':
                    case 'resourceId':
                    case 'patientControlRuleTableId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'patientType': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'patientControlRuleTable' || item === 'area' || item === 'speciality'
                    || item === 'service' || item === 'resource') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            PatientControlRuleTable: {select: ['Id', 'Name']},
            Area: {select: ['Id', 'Name']},
            Speciality: {select: ['Id', 'Name']},
            Service: {select: ['Id', 'Name', 'SpecialityId']},
            Resource: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): PatientControlRuleType {
        return {
            name: undefined,
            active: false,
            area: undefined,
            areaId: undefined,
            speciality: undefined,
            specialityId: undefined,
            service: undefined,
            serviceId: undefined,
            resource: undefined,
            resourceId: undefined,
            patientType: undefined,
            numberOfAppointments: undefined,
            numberOfDaysInThePast: undefined,
        } as PatientControlRuleType;
    }

    getInitialModalFilters(): PatientControlRuleModalFiltersType {
        return {
            areaModalFilterOptions: {
                ngModel: undefined,
            } as AreaModalFilterOptionsType,
            specialityModalFilterOptions: {
                ngModel: undefined,
            } as SpecialityModalFilterOptionsType,
            serviceModalFilterOptions: {
                ngModel: undefined,
            } as ServiceModalFilterOptionsType,
            resourceModalFilterOptions: {
                ngModel: undefined,
            } as ResourceModalFilterOptionsType,
            patientTypeModalFilterOptions: {
                ngModel: undefined,
            } as PatientTypeModalFilterOptionsType,
            areFiltersActive: false,
        } as PatientControlRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: PatientControlRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: PatientControlRuleType): PatientControlRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getSpecialityDependentFilters(): SpecialityDependentFiltersType {
        return {
            searchPhrase: '',
            areaId: null,
            count: true,
            exclusionList: []
        };
    }

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            onlyAssignedToLocationsOfUser: false,
            includeAvailabilities: false,
            includeChannel: true,
            searchPhrase: '',
            locationId: null,
            regionId: null,
            resourceId: null,
            areaId: null,
            coveragePlanId: null,
            patientId: null,
            applyOnlineConsultationCondition: false,
            hasMultiResourceBluePrint: null,
            exclusionList: [],
            count: true,
        };
    }

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })
}
