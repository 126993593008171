import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { ExclusionReasonMDComponent } from './exclusion-reason-md.component';
import { CreateExclusionReasonComponent } from './create-exclusion-reason/create-exclusion-reason.component';

@NgModule({
  declarations: [ExclusionReasonMDComponent, CreateExclusionReasonComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
  ]
})
export class ExclusionReasonMDModule { }
