<div class="patient-appointment-list-filters-container p-1">
    <div class="row mb-0 mx-1 pt-1 cursor-pointer" (click)="changeCollapseFilterSection()">
        <div class="col ml-1 text-left">
            <h5 class="text-left title">{{'label.filters' | translate | uppercase}}</h5>
        </div>
        <div class="col mr-1 text-right align-self-center">
            <i class="btn btn-sm fas"
               [ngClass]="patientAppointmentListUtils.filtersOptions.areFiltersCollapsed ? 'fa-angle-down':'fa-angle-up'"
               [attr.aria-expanded]="!patientAppointmentListUtils.filtersOptions.areFiltersCollapsed">
            </i>
        </div>
    </div>
    <div [ngbCollapse]="patientAppointmentListUtils.filtersOptions.areFiltersCollapsed" class="row mb-0 mx-1 pl-2">
        <div class="col-10 custom-col-10 form-group pl-2 pr-0 list-filters-container" id="patient-appointment-list-filters-container">
            <sbase-filter-wrapper
                    class="d-flex my-1 list-filter list-filter-sbase-filter-wrapper"
                    [options]="patientAppointmentListUtils.filtersOptions.filterWrapperOptions"
                    [updateFiltersValue]="updateFilterWrapperValues"
                    (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"
            ></sbase-filter-wrapper>
        </div>
        <div class="col-1 custom-col-2 action-button patient-appointment-list-filters-search no-block my-2 flex-column">
            <div class="row">
                <button class="action-button reset-button btn" (click)="onClear()">
                    <i class="far fa-trash-alt custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
        <div class="col-1 custom-col-1 action-button patient-appointment-list-filters-search no-block">
            <button class="search-button"
                    [ngClass]="!patientAppointmentListUtils.filtersOptions.areFiltersValid ? 'disabled' : null"
                    [ngbCollapse]="patientAppointmentListUtils.filtersOptions.areFiltersCollapsed"
                    (click)="onSearch()">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</div>
