import {Component, OnInit} from '@angular/core';
import {SlotsManagementMdUtils} from '../slots-management-util';
import * as lodash from 'lodash';
import {Router} from '@angular/router';
import {
    ActionNameEnum,
    AppointmentBulletinPdfQueryType,
    AppointmentInformationActionType,
    AppointmentInformationBookType,
    AppointmentProvider,
    PatientProvider,
    SlotsUtils,
    AppointmentType,
    AppointmentInformationFormData,
    SlotsFilterNameEnum,
    SlotsActionEnum
} from 'sked-base';
import {concatMap, take} from 'rxjs/operators';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ObjectDetailsActionType} from 'sked-base/lib/components/object-details/object-details.types';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {
    ProcessedTenantCustomizingGroupedByControlNameType,
    TenantCustomizingFilterInputType
} from '../../../data-model/tenant-customizing.type';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {constants} from 'src/app/shared/constants/constants';
import {PatientActionsService} from '../../../shared/services/patient-actions/patient-actions.service';
import {PatientContextService} from '../../../shared/services/patient-context.service';

@Component({
    selector: 'app-book-appointment',
    templateUrl: './book-appointment.component.html',
    styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit {
    currentPage = 1;
    userInfoStorage;
    constants = constants;
    private areObjectDetailsFound = false;
    private appointmentId: string;

    constructor(public slotsManagementMdUtils: SlotsManagementMdUtils,
                public router: Router,
                private configDataService: ConfigDataService,
                private appointmentProvider: AppointmentProvider,
                public messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService,
                private slotUtils: SlotsUtils,
                private translatedLanguageService: TranslatedLanguageService,
                private patientProvider: PatientProvider,
                private tenantCustomizingService: TenantCustomizingService,
                private patientActionsService: PatientActionsService,
                private patientContextService: PatientContextService) {
    }

    ngOnInit(): void {
        if (lodash.isEmpty(this.slotsManagementMdUtils.appointmentInformationOptions?.patient) ||
            lodash.isEmpty(this.slotsManagementMdUtils.appointmentInformationOptions?.slot)) {
            this.router.navigate(['/slotsManagement'],
            );
        } else {
            // Get user from storage
            this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
            this.loadTenantCustomizing();
        }
        this.setAppointmentSpecialityName();
    }

    onObjectDetailsAction(objectDetailsInfo: ObjectDetailsActionType) {
        switch (objectDetailsInfo.actionName) {
            case ActionNameEnum.Cancel :
                this.router.navigate(
                    ['/slotsManagement'],
                    {state: {comingFromRoute: 'bookAppointment'}});
                break;
            case ActionNameEnum.Back :
                this.router.navigate(
                    ['/slotsManagement'],
                    {state: {comingFromRoute: 'bookAppointment'}});
                break;
            case ActionNameEnum.Next:
                this.currentPage += 1;
                this.areObjectDetailsFound = objectDetailsInfo.areObjectDetailsFound;
                break;
            default:
                this.router.navigate(['/slotsManagement'],
                );
        }
    }

    onBookAction(appointmentInfo: AppointmentInformationActionType) {
        switch (appointmentInfo?.actionName) {
            case ActionNameEnum.Cancel :
                this.router.navigate(
                    ['/slotsManagement'],
                    {state: {comingFromRoute: 'bookAppointment'}});
                break;
            case ActionNameEnum.Book:
                this.updatePatientAndBookAppointment(appointmentInfo);
                break;
            case ActionNameEnum.Back:
                if (this.areObjectDetailsFound) {
                    this.currentPage -= 1;
                } else {
                    this.router.navigate(
                        ['/slotsManagement'],
                        {state: {comingFromRoute: 'bookAppointment'}});
                }
                break;
            default:
                this.router.navigate(['/slotsManagement'],
                );
        }
    }

    onDoneAction(appointmentInfo: AppointmentInformationActionType) {
        switch (appointmentInfo?.actionName) {
            case ActionNameEnum.Done :
                this.router.navigate(['/slotsManagement'], {state: {bookingSuccessful: true}});
                break;
            case ActionNameEnum.Print:
                this.printAppointment(this.appointmentId);
                break;
            default:
                this.router.navigate(['/slotsManagement'],
                );
        }
    }

    private setAppointmentSpecialityName() {
        const serviceFilterValue = this.patientActionsService?.slotsFilters?.find(({name}) => name === 'Service')?.value?.service?.specialityName;

        if (!this.slotsManagementMdUtils.appointmentInformationOverviewOptions.appointment?.service?.speciality) {
            this.slotsManagementMdUtils.appointmentInformationOverviewOptions.appointment = {
                ...this.slotsManagementMdUtils?.appointmentInformationOverviewOptions?.appointment,
                service: {
                    ...this.slotsManagementMdUtils?.appointmentInformationOverviewOptions?.appointment?.service,
                    speciality: {
                        name: serviceFilterValue
                    }
                }
            } as AppointmentType;
        }

        if (!this.slotsManagementMdUtils.appointmentInformationOptions.appointment?.service?.speciality) {
            this.slotsManagementMdUtils.appointmentInformationOptions.appointment = {
                ...this.slotsManagementMdUtils?.appointmentInformationOptions?.appointment,
                service: {
                    ...this.slotsManagementMdUtils?.appointmentInformationOptions?.appointment?.service,
                    speciality: {
                        name: serviceFilterValue
                    }
                }
            } as AppointmentType;
        }
    }

    private updatePatientAndBookAppointment(appointmentInfo: AppointmentInformationActionType): void {
        this.ngxLoader.start();
        const appointmentInformationBook: AppointmentInformationBookType = {
            patient: this.slotsManagementMdUtils.appointmentInformationOptions?.patient,
            slot: this.slotsManagementMdUtils.appointmentInformationOptions?.slot,
            formData: appointmentInfo.formData,
            additionalData: this.slotsManagementMdUtils.appointmentInformationOptions?.additionalData
        };
        const appointmentId = this.slotsManagementMdUtils.slotsActionContextAction !== SlotsActionEnum.Reuse
            ? this.slotsManagementMdUtils.slotsActionContextAppointment?.id
            : undefined;
        const taskId = this.slotsManagementMdUtils.slotsActionContextAction !== SlotsActionEnum.Reuse
            ? this.slotsManagementMdUtils.slotsActionContextTask?.id
            : undefined;
        const numberOfSlotsString = lodash.find(
            this.slotsManagementMdUtils.latestFilterValues,
            {name: SlotsFilterNameEnum['Number of Adjacent Slots']}
        )?.value;
        const numberOfSlots = numberOfSlotsString ? parseInt(numberOfSlotsString, 10) : 1;
        const bookAppointmentBody = this.slotUtils.mapSlotDataForBookAppointment(
            appointmentInformationBook,
            appointmentId,
            taskId,
            this.slotsManagementMdUtils.appointmentInformationOptions?.additionalData?.isOutsideAvailabilityBooking,
            numberOfSlots
        );

        // Patient data
        const etag = (this.patientContextService.patient as PatientType)?.etag;
        const oldPatient = {
            ...this.slotsManagementMdUtils.appointmentInformationOptions?.patient,
            etag,
        };
        const newPatient = {
            ...this.slotsManagementMdUtils.appointmentInformationOptions?.patient,
            ...appointmentInfo.formData,
            etag,
        };
        // If the patient data is the same as before only book the appointment
        if (this.isPatientDataTheSameAsBefore(oldPatient, newPatient)) {
            this.bookAppointment(bookAppointmentBody);
        } else {
            // If the patient data was changed than update patient data and book the appointment
            this.bookAppointmentAndUpdatePatientData(oldPatient, newPatient, bookAppointmentBody);
        }
    }

    private bookAppointment(bookAppointmentBody: AppointmentType) {
        this.appointmentProvider.bookAppointment(bookAppointmentBody).pipe(
            take(1)).subscribe(response => {
            this.bookAppointmentSuccessResponse(response);
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private bookAppointmentAndUpdatePatientData(oldPatient: PatientType, newPatient: PatientType, bookAppointmentBody: AppointmentType) {
        // 1. Update patient data
        this.patientProvider.updateEntry(oldPatient, newPatient).pipe(
            take(1),
            concatMap((response) => {
                // 2. Book appointment
                this.messagesService.success('toastr.success.patientUpdated', true);
                // Update patient information with the new data
                const updatedPatient = {
                    ...newPatient,
                    ...response as unknown as PatientType
                } as PatientType;
                this.updatePatientInSearchAndDisplayComponents(updatedPatient);
                this.patientContextService.patient = updatedPatient;
                return this.appointmentProvider.bookAppointment(bookAppointmentBody);
            }))
            .pipe(take(1)).subscribe(response => {
            this.bookAppointmentSuccessResponse(response);
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private bookAppointmentSuccessResponse(response: AppointmentType) {
        this.ngxLoader.stop();
        this.messagesService.success('toastr.success.appointmentBooked', true);
        this.appointmentId = response?.id;
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions.appointment = response;
        this.setAppointmentSpecialityName();
        this.currentPage += 1;
        this.slotsManagementMdUtils.bookingSuccessful = true;
    }

    private isPatientDataTheSameAsBefore(oldPatient: PatientType, newPatient: PatientType): boolean {
        const oldPatientFormData = {
            mainPhoneNumber: lodash.isEmpty(oldPatient.mainPhoneNumber) ? undefined : oldPatient.mainPhoneNumber,
            alternatePhoneNumber: lodash.isEmpty(oldPatient.alternatePhoneNumber) ? undefined : oldPatient.alternatePhoneNumber,
            email: lodash.isEmpty(oldPatient.email) ? undefined : oldPatient.email,
        } as AppointmentInformationFormData;
        const newPatientFormData = {
            mainPhoneNumber: lodash.isEmpty(newPatient.mainPhoneNumber) ? undefined : newPatient.mainPhoneNumber,
            alternatePhoneNumber: lodash.isEmpty(newPatient.alternatePhoneNumber) ? undefined : newPatient.alternatePhoneNumber,
            email: lodash.isEmpty(newPatient.email) ? undefined : newPatient.email,
        } as AppointmentInformationFormData;

        return lodash.isEqual(oldPatientFormData, newPatientFormData);
    }

    private updatePatientInSearchAndDisplayComponents(newPatient: PatientType): void {
        // Update patient data from sbase-patient-details
        this.slotsManagementMdUtils.appointmentInformationOptions.patient = newPatient;
        // Update patient data from sbase-patient-search
        this.slotsManagementMdUtils.slotsManagementState.patientSearchOptions.initialSelectedPatient = newPatient;
        // Update patient data from overview
        this.slotsManagementMdUtils.appointmentInformationOverviewOptions.patient = newPatient;
    }

    private printAppointment(appointmentId: string): void {
        this.ngxLoader.start();
        const queryFilter: AppointmentBulletinPdfQueryType = {
            appointmentId,
            languageCode: this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage)
        };

        this.appointmentProvider.getAppointmentBulletinPdf(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.processFileResponse(response);
                this.ngxLoader.stop();
                this.router.navigate(['/slotsManagement'], {state: {bookingSuccessful: true}});
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private processFileResponse(response): void {
        const downloadUrl = URL.createObjectURL(response.file);
        // open in new tab
        window.open(downloadUrl, '_blank');
    }

    private loadTenantCustomizing() {
        this.tenantCustomizingService.getTenantCustomizingValuesFor(
            this.getTenantCustomizingFilterObject()
        ).subscribe((response: ProcessedTenantCustomizingGroupedByControlNameType) => {
            this.slotsManagementMdUtils.appointmentInformationOptions.tenantCustomizingData = lodash.pick(response, ['Email', 'MainPhoneNumber', 'AlternatePhoneNumber']);
            this.slotsManagementMdUtils.appointmentInformationOverviewOptions.tenantCustomizingData = lodash.pick(response, ['Email', 'MainPhoneNumber', 'AlternatePhoneNumber']);
        });
    }

    private getTenantCustomizingFilterObject(): TenantCustomizingFilterInputType {
        return {
            ui: 'reception.patientOverview',
            entity: 'PatientDTO',
            situation: 'Edit',
            role: this.userInfoStorage?.team?.organizationalRole
        } as TenantCustomizingFilterInputType;
    }
}
