<div class="patient-dashboard-container d-flex no-block flex-column">
    <div class="patient-search-component-container p-2">
        <sbase-patient-search
                [options]="patientContextService.getPatientSearchOptions()"
                (onSelectedPatientOutput)="patientContextService.onSelectedPatient($event)"
                (patientSearchActions)="patientContextService.onPatientSearchActions($event)">
        </sbase-patient-search>
    </div>

    <!-- Message to select a patient -->
    <div class="container-fluid no-patient-in-context-message-container pb-1 flex-grow-1 d-flex no-block justify-content-center align-items-center"
         *ngIf="!patientContextService.patient?.id">
        <div class="no-patient-in-context-message">
            <i class="fas fa-info-circle mr-3"></i>
            <span translate="label.searchForAPatientToDisplayData"></span>
        </div>
    </div>

    <!-- Patient details and Upcoming appointments -->
    <div class="patient-dashboard-inner-container pb-1 flex-grow-1 d-flex no-block flex-row"
         *ngIf="!!patientContextService.patient?.id">
        <div class="patient-details-container">
            <app-patient-dashboard-details></app-patient-dashboard-details>
        </div>
        <div class="upcoming-appointments-container flex-grow-1">
            <app-upcoming-appointments></app-upcoming-appointments>
        </div>
    </div>
</div>
