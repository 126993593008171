<div *ngIf="clientIdentificationForm"
     [formGroup]="clientIdentificationForm" class="form-container client-identification-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">

    <!-- DOCUMENT TYPE -->
    <div *ngIf="getInputByControlName(formControlNames.DOCUMENT_TYPE)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.DOCUMENT_TYPE)?.styles">
        <label [for]="formControlNames.DOCUMENT_TYPE" translate="label.documentType">
            <span [style.visibility]="getInputByControlName(formControlNames.DOCUMENT_TYPE)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div [ngClass]="{'cursor-not-allowed is-disabled': clientIdentificationForm?.controls[formControlNames.DOCUMENT_TYPE]?.disabled}">
            <ng-select [id]="formControlNames.DOCUMENT_TYPE"
                       [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(clientIdentificationForm, formControlNames.DOCUMENT_TYPE)}"
                       appendTo="body"
                       [formControlName]="formControlNames.DOCUMENT_TYPE"
                       class="custom-ng-select input-element"
                       loadingText="{{'label.loading' | translate}}"
                       dropdownPosition="bottom"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       (change)="onDocumentTypeValueChanged()">
                <ng-option value=""><span translate="label.choose"></span></ng-option>
                <ng-option [value]="documentType"
                           *ngFor="let documentType of createPatientUtils.clientIdentificationAvailableDocumentTypes">
                    <span>{{'enum.documentType.' + documentType | translate}}</span>
                </ng-option>
            </ng-select>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="clientIdentificationForm"
                                      [controlName]="formControlNames.DOCUMENT_TYPE">
        </app-dynamic-form-input-error>
    </div>

    <!-- NO DOCUMENT -->
    <div *ngIf="getInputByControlName(formControlNames.NO_DOCUMENT)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.NO_DOCUMENT)?.styles">
        <div class="dynamic-checkbox-container">
            <input type="checkbox" [id]="formControlNames.NO_DOCUMENT" [formControlName]="formControlNames.NO_DOCUMENT">
            <label [for]="formControlNames.NO_DOCUMENT" translate="label.noDocument">
                <span [style.visibility]="getInputByControlName(formControlNames.NO_DOCUMENT)?.required ? 'visible' : 'hidden'"
                      class="input-required-asterisk">*</span>
            </label>
        </div>
    </div>

    <!-- DOCUMENT NUMBER -->
    <div *ngIf="getInputByControlName(formControlNames.DOCUMENT_NUMBER)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.DOCUMENT_NUMBER)?.styles">
        <label [for]="formControlNames.DOCUMENT_NUMBER" translate="label.documentNumber">
            <span [style.visibility]="getInputByControlName(formControlNames.DOCUMENT_NUMBER)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <input type="text"
               [id]="formControlNames.DOCUMENT_NUMBER"
               [placeholder]="('label.documentNumber' | translate)"
               [formControlName]="formControlNames.DOCUMENT_NUMBER"
               class="dynamic-input"
               [ngClass]="{'cursor-not-allowed is-disabled': clientIdentificationForm?.controls[formControlNames.DOCUMENT_NUMBER]?.disabled,
               'has-error': dynamicFormInputService.hasError(clientIdentificationForm, formControlNames.DOCUMENT_NUMBER)}">
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="clientIdentificationForm"
                                      [controlName]="formControlNames.DOCUMENT_NUMBER">
        </app-dynamic-form-input-error>
    </div>

    <!-- DOCUMENT COUNTRY -->
    <div *ngIf="getInputByControlName(formControlNames.DOCUMENT_COUNTRY)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.DOCUMENT_COUNTRY)?.styles">
        <label [for]="formControlNames.DOCUMENT_COUNTRY" translate="label.documentCountry">
            <span [style.visibility]="getInputByControlName(formControlNames.DOCUMENT_COUNTRY)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div [ngClass]="{'cursor-not-allowed is-disabled': clientIdentificationForm?.controls[formControlNames.DOCUMENT_COUNTRY]?.disabled}">
            <ng-select [id]="formControlNames.DOCUMENT_COUNTRY"
                       [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(clientIdentificationForm, formControlNames.DOCUMENT_COUNTRY)}"
                       appendTo="body"
                       [formControlName]="formControlNames.DOCUMENT_COUNTRY"
                       bindLabel="countryName"
                       bindValue="countryName"
                       class="custom-ng-select input-element"
                       notFoundText="{{'toastr.error.notFound' | translate}}"
                       (change)="onCountryValueChanged($event)">
                <ng-option value=""><span translate="label.addressTemplate.country"></span></ng-option>
                <ng-option [value]="country" *ngFor="let country of createPatientUtils.clientIdentificationCountries">
                    <span>{{country.countryName}}</span>
                </ng-option>
            </ng-select>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="clientIdentificationForm"
                                      [controlName]="formControlNames.DOCUMENT_COUNTRY">
        </app-dynamic-form-input-error>
    </div>
</div>
