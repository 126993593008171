import {Injectable} from '@angular/core';
import {
    FormAssignmentAmbulatoryRuleType,
    Expand,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    ServiceDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    FormAssignmentAmbulatoryRuleModalFiltersType,
    FormAssignmentAmbulatoryRuleStateType,
} from './form-assignment-ambulatory-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class FormAssignmentAmbulatoryRuleUtils {
    overviewState: FormAssignmentAmbulatoryRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'formAssignmentAmbulatoryRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.allowedAppointmentStatuses',
            abbreviatedLabel: 'label.allowedAppointmentStatusesAbbreviation',
            hasSort: false,
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.service2',
            hasSort: true,
            onSortByProperty: 'service',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resource',
            hasSort: true,
            onSortByProperty: 'resource',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.pastAppointmentAllowed',
            abbreviatedLabel: 'label.pastAppointmentAllowedAbbreviation',
            hasSort: true,
            onSortByProperty: 'pastAppointmentAllowed',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.onlyOneSubmissionPerAppointment',
            abbreviatedLabel: 'label.onlyOneSubmissionPerAppointmentAbbreviation',
            hasSort: true,
            onSortByProperty: 'onlyOneSubmissionPerAppointment',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.formId',
            hasSort: true,
            onSortByProperty: 'formId',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.formDisplayName',
            hasSort: true,
            onSortByProperty: 'formDisplayName',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'formAssignmentAmbulatoryRuleTableId':
                    case 'serviceId':
                    case 'resourceId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'formAssignmentAmbulatoryRuleTable' || item === 'service' || item === 'resource') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            Resource: {select: ['Id', 'Name']},
            Service: {select: ['Id', 'Name', 'SpecialityId']},
            FormAssignmentAmbulatoryRuleTable: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): FormAssignmentAmbulatoryRuleType {
        return {
            name: undefined,
            active: false,
            serviceId: undefined,
            resourceId: undefined,
            pastAppointmentAllowed: false,
            onlyOneSubmissionPerAppointment: false,
            formId: undefined,
            formDisplayName: undefined,
            formAssignmentAmbulatoryRuleTableId: undefined,
            service: undefined,
            resource: undefined,
            formAssignmentAmbulatoryRuleTable: undefined,
            allowedAppointmentStatus: []
        } as FormAssignmentAmbulatoryRuleType;
    }

    getInitialModalFilters(): FormAssignmentAmbulatoryRuleModalFiltersType {
        return {
            serviceOptions: {
                ngModel: []
            },
            resourceOptions: {
                ngModel: []
            },
            formIdOptions: {
                ngModel: ''
            },
            formDisplayNameOptions: {
                ngModel: ''
            },
            areFiltersActive: false,
        } as FormAssignmentAmbulatoryRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: FormAssignmentAmbulatoryRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            count: true,
            includeAvailabilities: false,
            includeChannel: false,
            onlyAssignedToLocationsOfUser: false
        } as ServiceDependentFiltersType;
    }

    getResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            count: true,
            resourceTypeExclusionList: [],
            includeAvailabilities: false,
            onlyDirectlyBookable: false,
            onlyAssignedToUser: false,
            onlyAssignedToLocationsOfUser: false,
            includeSelfPayer: false
        } as ResourceDependentFiltersType;
    }
}
