<div class="container-fluid create-data-store-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-data-store-header">
        <h3 class="create-data-store-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase"
                                                                                               translate="label.secret"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate (ngSubmit)="saveDataStoreData(dataStoreItem)">
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{dataStoreItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.secret"></td>
                                    <td class="text-right">{{dataStoreItem.userReadDisplay}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   [(ngModel)]="dataStoreItem.name"
                                   [ngClass]="{'input-element-has-error': !dataStoreItem.name}"
                                   placeholder="{{'label.name'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="!dataStoreItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="form-group">
                            <label translate="label.secret"></label>
                            <input type="text" name="data" class="input-element"
                                   [(ngModel)]="dataStoreItem.data"
                                   [ngClass]="{'input-element-has-error': !dataStoreItem.data || dataStoreItem.data?.length<8}"
                                   placeholder="{{'label.secret'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="!dataStoreItem.data" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                            <div *ngIf="dataStoreItem.data && dataStoreItem.data?.length<8" class="help-block">
                                <span translate="label.minimumLength8"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--End Body-->
            <!-- =========================================== -->

            <!--Footer-->
            <!-- =========================================== -->
            <div class="mt-2 footer-position-sticky">
                <button class="btn mr-3" type="button" (click)="goToParentPage()"
                        [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                        translate="{{screenTemplateLayout.cancelOrBackButton}}">
                </button>
                <button class="btn theme-btn" type="submit"
                        [hidden]="screenTemplateLayout.action === constants.VIEW"
                        translate="{{screenTemplateLayout.createOrSaveButton}}">
                </button>
                <button (click)="goToEdit()"
                        *ngIf="screenTemplateLayout.action===constants.VIEW"
                        [activityDisplay]="'DataStoreUpdate'"
                        class="btn theme-btn"
                        type="button">
                    <span translate="label.edit"></span>
                </button>
            </div>
            <!--End Footer-->
            <!-- =========================================== -->
        </form>
    </div>
</div>
