import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    SlotsLocalFiltersOptionsType, SlotsManagementLocalFilterTypeEnum
} from '../slots-management.types';
import {SlotsManagementMdUtils} from '../slots-management-util';
import tippy, {Instance} from 'tippy.js';

@Component({
    selector: 'app-slots-local-filters',
    templateUrl: './slots-local-filters.component.html',
    styleUrls: ['./slots-local-filters.component.scss']
})
export class SlotsLocalFiltersComponent implements OnInit, AfterViewInit {
    @Output() localFiltersSelected: EventEmitter<SlotsLocalFiltersOptionsType> = new EventEmitter();
    displaySelfPayer = false;
    tippyInstances: { [key: string]: Instance } = {};
    slotsManagementLocalFilterTypeEnum = SlotsManagementLocalFilterTypeEnum;

    constructor(public slotsManagementMdUtils: SlotsManagementMdUtils) {
    }

    ngOnInit(): void {
        this.displaySelfPayer = this.slotsManagementMdUtils.isSelfPayerSystemConfigActive();
        if (this.displaySelfPayer && this.slotsManagementMdUtils.slotsLocalFiltersOptions.selfPayer) {
            this.onLocalFiltersChanged();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.makeTippyPopovers();
        });
    }

    onLocalFiltersChanged(filterType?: SlotsManagementLocalFilterTypeEnum): void {
        // Sanitize selectedAppointmentType
        if (
            filterType === SlotsManagementLocalFilterTypeEnum.AppointmentType
            && this.slotsManagementMdUtils.slotsLocalFiltersOptions?.selectedAppointmentType?.hasOwnProperty('$ngOptionValue')
        ) {
            this.slotsManagementMdUtils.slotsLocalFiltersOptions.selectedAppointmentType = undefined;
        }
        // Emit to parent
        this.localFiltersSelected.emit(this.slotsManagementMdUtils.slotsLocalFiltersOptions);
    }

    private makeTippyPopovers() {
        this.makeTippyPopover('selfPayer', 'slots-local-filters-self-payer-element', 'slots-local-filters-self-payer-popover');
        this.makeTippyPopover('amFlag', 'slots-local-filters-am-element', 'slots-local-filters-am-popover');
        this.makeTippyPopover('pmFlag', 'slots-local-filters-pm-element', 'slots-local-filters-pm-popover');
    }

    private makeTippyPopover(tippyInstanceKey: string, elementClassName: string, popoverClassName: string) {
        const element = document.getElementsByClassName(elementClassName)[0];
        const popover = document.getElementsByClassName(popoverClassName)[0];
        if (!!element && !!popover) {
            this.tippyInstances[tippyInstanceKey] = tippy(element, {
                content: popover,
                placement: 'top',
                theme: 'light-border',
                animation: false,
                popperOptions: {strategy: 'fixed'}
            });
        }
    }
}
