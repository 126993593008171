import {ExclusionOverviewItemType, ExclusionReasonType, ResponseValidationType} from 'sked-base';

export enum ExclusionStatusEnum {
    NEW = 'New',
    EDITED = 'Edited',
    MARKED_FOR_DELETE = 'MarkedForDelete',
    APPROVED = 'Approved',
    DELETED = 'Deleted',
    PENDING_SPLIT = 'PendingSplit',
}

export enum ExclusionViewPageEnum {
    ExclusionOverview = 'exclusion',
    ApproveExclusionOverview = 'approveExclusion',
}

export interface ExclusionTemplateLayoutType {
    pageTitle: string;
    viewType: ExclusionViewPageEnum;
}

export interface BackofficeExclusionDataType extends ExclusionOverviewItemType {
    isValid: boolean;
    hourFromTime: string;
    hourToTime: string;
    hourFrom: number;
    hourTo: number;
    otherExclusionReason: string;
}

export interface ExclusionValidationType extends ResponseValidationType {
    isValid: boolean;
}

export interface BackofficeExclusionTimeSlotType {
    id: string;
    hourFrom: number;
    hourTo: number;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    selectedDays: string[];
    hourFromTime: string;
    hourToTime: string;
    editedIndex: number;
}

export interface ValidCreateExclusionType {
    isCenterValid: boolean;
    isResourceValid: boolean;
}

export interface BackofficeExclusionReasonType extends ExclusionReasonType {
    exclusionReason: string;
    exclusionReasonId: string;
    exclusionExtraInformation: string;
}
