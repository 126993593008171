import {Component, OnInit} from '@angular/core';
import {ResourceSwapUtils} from './resource-swap.utils';
import {SwapResourcesFiltersSearchType} from './resource-swap-filters/resource-swap-filters.types';
import {Subject} from 'rxjs';
import * as lodash from 'lodash';

@Component({
    selector: 'app-resource-swap',
    templateUrl: './resource-swap.component.html',
    styleUrls: ['./resource-swap.component.scss']
})
export class ResourceSwapComponent implements OnInit {
    searchSwapResourcesAppointmentsFilterValues: Subject<SwapResourcesFiltersSearchType> = new Subject<SwapResourcesFiltersSearchType>();
    shouldDisableEverything = false;
    latestFilters: SwapResourcesFiltersSearchType;

    constructor(public resourceSwapUtils: ResourceSwapUtils) {
    }

    ngOnInit(): void {
        // Register to should disable
        this.resourceSwapUtils.disableEverythingSubject.subscribe((disable) => {
            this.shouldDisableEverything = disable;
        });
        // Reset appointmentsSwapResources
        if (!!this.resourceSwapUtils?.swapResourcesOptions?.appointmentsSwapResources) {
            this.resourceSwapUtils.swapResourcesOptions.appointmentsSwapResources = [];
        }
    }

    onToggleAllAppointments(event: any) {
        this.resourceSwapUtils.toggleAllAppointmentsSubject.next(!!event?.target?.checked);
    }

    onSearch(filterValues: SwapResourcesFiltersSearchType) {
        this.latestFilters = lodash.cloneDeep(filterValues);
        this.searchSwapResourcesAppointmentsFilterValues.next(filterValues);
    }

    onSelectResourceForAllReplacement(resource: any) {
        // Reset all preselected select inputs
        if (resource === 'noValue') {
            this.resourceSwapUtils.appointmentsIds?.forEach(appointmentId => {
                const isInputValueEqualWithTheLastValue = lodash.isEqual(
                    this.resourceSwapUtils.swapResourcesOptions.selectsNgValues[appointmentId],
                    this.resourceSwapUtils.lastResourceForAllReplacementsSelected
                );
                if (isInputValueEqualWithTheLastValue) {
                    this.resourceSwapUtils.swapResourcesOptions.selectsNgValues[appointmentId] = 'noValue';
                }
            });
        }
        // Preselect all select inputs that have the resource
        this.resourceSwapUtils.swapResourcesOptions.appointmentsSwapResources?.forEach(({resources, appointmentId}) => {
            const isSelectedResourceInAppointmentsSwapResources = resources.filter(({id}) => resource.id === id);
            if (isSelectedResourceInAppointmentsSwapResources.length > 0) {
                this.resourceSwapUtils.swapResourcesOptions.selectsNgValues[appointmentId] = resource;
            }
        });
        // Remember the last selected resource so we can reset the inputs
        this.resourceSwapUtils.lastResourceForAllReplacementsSelected = resource;
    }

    refreshData() {
        if (!this.latestFilters) {
            return;
        }
        this.onSearch(this.latestFilters);
    }
}
