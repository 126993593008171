<div class="resource-swap-filters-container p-1">
    <div class="row mb-0 mx-1 pt-1 cursor-pointer" (click)="changeCollapseFilterSection()">
        <div class="col ml-1 text-left">
            <h5 class="text-left title">{{'label.filters' | translate | uppercase}}</h5>
        </div>
        <div class="col mr-1 text-right align-self-center">
            <i class="btn btn-sm fas"
               [ngClass]="resourceSwapUtils.filtersOptions.areFiltersCollapsed ? 'fa-angle-down':'fa-angle-up'"
               [attr.aria-expanded]="!resourceSwapUtils.filtersOptions.areFiltersCollapsed">
            </i>
        </div>
    </div>
    <div [ngbCollapse]="resourceSwapUtils.filtersOptions.areFiltersCollapsed" class="row mb-0 mx-1 pl-2">
        <div class="col-10 custom-col-10 form-group pl-2 pr-0 resource-swap-container" id="resource-swap-filters-container">
            <sbase-filter-wrapper
                    class="d-flex my-1 list-filter list-filter-sbase-filter-wrapper"
                    [options]="resourceSwapUtils.filtersOptions.filterWrapperOptions"
                    [updateFiltersValue]="updateFilterWrapperValues"
                    (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"
            ></sbase-filter-wrapper>
        </div>
        <div class="col-1 custom-col-2 action-button resource-swap-filters-search no-block my-2 flex-column">
            <div class="row">
                <button class="action-button reset-button btn" (click)="onClear()">
                    <i class="far fa-trash-alt custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
        <div class="col-1 custom-col-1 action-button resource-swap-filters-search no-block">
            <button class="search-button"
                    [ngClass]="!resourceSwapUtils.filtersOptions.areFiltersValid ? 'disabled' : null"
                    [ngbCollapse]="resourceSwapUtils.filtersOptions.areFiltersCollapsed"
                    (click)="onSearch()">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
    <div class="d-none" id="resource-swap-filters-to-move">
        <!-- These elements are moved next to the filters above from filter wrapper on ngAfterViewInit -->
        <div class="form-group mb-0 resource-swap-select-filter list-filter list-filter-select">
            <ng-select class="form-control rounded-1 custom-ng-select"
                       [ngClass]="!!resourceSwapUtils.filtersOptions.statusSelectNgModel ? 'ng-select-has-value' : null"
                       dropdownPosition="bottom"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{'label.status' | translate}}"
                       [(ngModel)]="resourceSwapUtils.filtersOptions.statusSelectNgModel"
                       (ngModelChange)="sanitizeNgSelectValue('statusSelectNgModel')">
                <ng-option [value]="'undefined'">
                    <span translate="label.status"></span>
                </ng-option>
                <ng-option *ngFor="let item of appointmentStatusEnumList"
                           [value]="item">
                    <span [translate]="'enum.appointmentStatus.' + item"></span>
                </ng-option>
            </ng-select>
        </div>
        <div class="form-group mb-0 resource-swap-date-range-container list-filter list-filter-date-range">
            <sbase-date-range class="input-element"
                              [ngClass]="{'input-element-has-error': !resourceSwapUtils.filtersOptions.filterValidations?.dateRange?.isValid}"
                              [options]="resourceSwapUtils.filtersOptions.dateRangeOptions"
                              [updateDateRangeValue]="updateDateRangeValue"
                              (onDateRangeChanged)="onDateRangeChanged($event)">
            </sbase-date-range>
        </div>
    </div>
</div>

