import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActivityPlanScheduledEndTypeEnum,
    EnqueueUnitEnum,
    ActivityPlanExecutionTypeEnum,
    ActivityPlanServiceType
} from 'sked-base';
import {ActionType} from '../../../../data-model/general.type';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../../shared/utils/dateTime.utils';
import {ActivityPlanFrequency, ActivityPlanFrequencyTypeEnum} from '../../activity-plan.types';
import {ActivityPlanUtils} from '../../activity-plan.utils';

@Component({
    selector: 'app-activity-plan-service-recurrence-modal',
    templateUrl: './activity-plan-service-recurrence-modal.component.html',
    styleUrls: ['./activity-plan-service-recurrence-modal.component.scss']
})
export class ActivityPlanServiceRecurrenceModalComponent implements OnInit {
    activityPlanFrequencyTypeEnum = ActivityPlanFrequencyTypeEnum;
    enqueueUnitEnumList = Object.values(EnqueueUnitEnum);
    frequencyTypeEnumList = Object.values(ActivityPlanFrequencyTypeEnum);
    activityPlanExecutionTypeEnum = ActivityPlanExecutionTypeEnum;
    activityPlanScheduledEndTypeEnum = ActivityPlanScheduledEndTypeEnum;
    actionTypeEnum = ActionType;
    initialScopedServiceItemValue: ActivityPlanServiceType;
    initialScopedServiceFrequencyItem: ActivityPlanFrequency;
    spinnerId = 'activity-plan-service-recurrence';
    constants = constants;

    constructor(
        public activeModal: NgbActiveModal,
        public activityPlanUtils: ActivityPlanUtils,
        private dateTimeUtils: DateTimeUtils,
    ) {
    }

    ngOnInit(): void {
        this.saveInitialModalData();
        this.activityPlanUtils.setInitialDatesForService();
    }

    onEndTypeChange() {
        this.activityPlanUtils.scopedServiceHasValues = true;
        if (this.activityPlanUtils.scopedServiceItem.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsNever) {
            this.activityPlanUtils.scopedServiceItem.count = null;
            this.activityPlanUtils.scopedServiceItem.endTime = null;
            this.activityPlanUtils.endDateNgModel = null;
        } else if (this.activityPlanUtils.scopedServiceItem.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences) {
            this.activityPlanUtils.scopedServiceItem.endTime = null;
            this.activityPlanUtils.endDateNgModel = null;
        } else if (this.activityPlanUtils.scopedServiceItem.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate) {
            this.activityPlanUtils.scopedServiceItem.count = null;
        }
        this.activityPlanUtils.updateScopedServiceValidation();
    }

    onEndOccurrencesChange() {
        this.activityPlanUtils.scopedServiceHasValues = true;
        this.activityPlanUtils.scopedServiceItem.scheduledEndType = ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences;
        if ((this.activityPlanUtils.scopedServiceItem?.count ?? 0) > 1) {
            this.activityPlanUtils.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Scheduled;
        }
        this.onEndTypeChange();
        this.activityPlanUtils.updateScopedServiceValidation();
    }

    onEndDateChange(date: NgbDateStruct) {
        this.activityPlanUtils.scopedServiceItem.scheduledEndType = ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate;
        this.onEndTypeChange();
        this.activityPlanUtils.scopedServiceItem.endTime = this.dateTimeUtils.getMomentParseZoneFromNgbDate(date).format('YYYY-MM-DD');
        this.activityPlanUtils.updateScopedServiceValidation();
    }

    onCloseModal() {
        this.loadInitialModalData();
        this.activityPlanUtils.updateScopedServiceValidation();
        this.activeModal.close();
    }

    onSaveModal() {
        this.activityPlanUtils.updateScopedServiceValidation();
        if (this.activityPlanUtils.scopedServiceValidation?.recurrenceModal?.isValid) {
            if (this.activityPlanUtils.scopedServiceInEditMode) {
                this.activityPlanUtils.scopedServiceShowReminderToSave = true;
            }
            this.activeModal.close();
        }
    }

    onRunSimulationAction() {
        if (!this.activityPlanUtils.isSimulationCalendarEnabled()) {
            return;
        }
        this.activityPlanUtils.simulationRefreshButton = true;
        this.activityPlanUtils.triggerSimulationSubject.next();
    }

    private saveInitialModalData() {
        this.initialScopedServiceItemValue = lodash.cloneDeep(this.activityPlanUtils.scopedServiceItem);
        this.initialScopedServiceFrequencyItem = lodash.cloneDeep(this.activityPlanUtils.scopedServiceFrequencyItem);
    }

    private loadInitialModalData() {
        this.activityPlanUtils.scopedServiceItem = lodash.cloneDeep(this.initialScopedServiceItemValue);
        this.activityPlanUtils.scopedServiceFrequencyItem = lodash.cloneDeep(this.initialScopedServiceFrequencyItem);
    }
}
