import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {AppointmentListOptionsType} from './resource-appointment-list/resource-appointment-list.types';
import {
    AppointmentProvider,
    RUAppointmentsType,
    ResourceAppointmentsOverviewType
} from 'sked-base';
import {ResourceUtilizationFiltersSearchType} from './resource-utilization-filters/resource-utilization-filters.types';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ResourceUtilizationUtils} from './resource-utilization.utils';
import {ResourceUtilizationCalendarUtil} from './resource-utilization-calendar/resource-utilization-calendar-util';
import {Router} from '@angular/router';
import * as lodash from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'app-resource-utilization',
    templateUrl: './resource-utilization.component.html',
    styleUrls: ['./resource-utilization.component.scss']
})
export class ResourceUtilizationComponent implements OnInit {
    collapseAppointmentList: boolean;
    appointmentOptions: AppointmentListOptionsType;
    displayNoResultsMessage = false;
    constructor(
        public resourceUtilizationUtils: ResourceUtilizationUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private appointmentProvider: AppointmentProvider,
        private resourceUtilizationCalendarUtil: ResourceUtilizationCalendarUtil,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loadStateVariablesFromHistory();
        this.checkIfNeededToResetEverything();
        this.registerToRefreshDataButKeepCalendarDay();
    }

    onViewAppointmentInCalendar(appointment: RUAppointmentsType) {
        this.resourceUtilizationCalendarUtil.navigateToAppointment(appointment);
    }

    onSelectedAppointmentFromList(appointment: RUAppointmentsType) {
        // Update state
        const dateTimeFrom = !!this.resourceUtilizationUtils.calendarOptions?.resourceUtilizationCalendarApi
            ? moment(this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi
                .getCurrentData().currentDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD');
        this.resourceUtilizationUtils.calendarOptions.lastSelectedAppointment = {
            dateTimeFrom
        } as RUAppointmentsType;
        this.resourceUtilizationUtils.updateCalendarState(['lastSelectedAppointment']);
        // Go to appointment page
        this.goToAppointmentOverviewPage(appointment);
    }

    onSelectedAppointmentFromCalendar(appointment: RUAppointmentsType) {
        // console.log('onSelectedAppointmentFromCalendar');
        // console.log(appointment);
        this.goToAppointmentOverviewPage(appointment);
    }

    onFilterSearch(filterValues: ResourceUtilizationFiltersSearchType, autoSearch: boolean = false) {
        // console.log('onSelectedTaskFilters');
        // console.log(filterValues);
        this.loadAppointments(filterValues, autoSearch);
    }

    private goToAppointmentOverviewPage(appointment) {
        this.router.navigate(['/resourceUtilizationManageAppointment'], {state: {resourceUtilizationAppointment: appointment}});
    }

    private loadAppointments(filterValues: ResourceUtilizationFiltersSearchType, autoSearch: boolean = false) {
        this.ngxLoader.start();
        // Hide appointment list and calendar so they refresh on new request
        this.resourceUtilizationUtils.isAppointmentListVisible = false;
        this.resourceUtilizationUtils.isCalendarVisible = false;
        this.displayNoResultsMessage = false;
        // Do request
        this.appointmentProvider.getResourceAppointmentsOverview(
            this.resourceUtilizationUtils.getResourceAppointmentsOverviewQueryFilter(filterValues)
        ).subscribe((response) => {
             this.displayNoResultsMessage = (response.appointments?.length === 0);
             this.onAppointmentListResponse(filterValues, response, autoSearch);
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private onAppointmentListResponse(
        filterValues: ResourceUtilizationFiltersSearchType, response: ResourceAppointmentsOverviewType,
        autoSearch: boolean = false
    ) {
        // Reset history state variables
        this.resourceUtilizationUtils.shouldKeepFiltersState = false;
        this.resourceUtilizationUtils.shouldMakeNewRequest = false;
        this.resourceUtilizationUtils.shouldKeepCalendarState = false;
        this.resourceUtilizationUtils.shouldKeepListState = false;
        this.resourceUtilizationUtils.shouldKeepResponseData = false;
        // Keep filters shown if there's no appointment returned
        if (response.appointments?.length === 0) {
            this.resourceUtilizationUtils.filtersOptions.areFiltersCollapsed = false;
        }
        // Load response and filter data
        this.resourceUtilizationUtils.filtersOptions.latestSearchFilterValues = filterValues;
        this.resourceUtilizationUtils.appointments = this.resourceUtilizationUtils
            .mapAppointmentsForDisplay(response.appointments);
        this.resourceUtilizationUtils.resources = response.resources;
        // Initialize appointment list
        this.resourceUtilizationUtils.appointmentsListOptions = this.resourceUtilizationUtils.getEmptyAppointmentListOptions();
        this.resourceUtilizationUtils.appointmentsListOptions.pendingAppointments =
            this.resourceUtilizationUtils.getPendingAppointments(this.resourceUtilizationUtils.appointments);
        this.resourceUtilizationUtils.changeCollapseAppointmentListSection(false);
        this.resourceUtilizationUtils.isAppointmentListVisible = true;
        // Initialize calendar
        if (response.appointments?.length > 0) {
            this.resourceUtilizationUtils.loadAppointmentTimezone(response.appointments[0].dateTimeFrom);
        }
        if (!autoSearch) {
            // If the request is done automatically (autoSearch true) then the user is coming from manage appointment,
            // so we keep lastSelectedAppointment so the calendar can navigate to the appointment date
            // If user did the search (autoSearch false), reset lastSelectedAppointment
            this.resourceUtilizationUtils.calendarOptions.lastSelectedAppointment = undefined;
            this.resourceUtilizationUtils.updateCalendarState(['lastSelectedAppointment']);
        }
        this.resourceUtilizationUtils.isCalendarVisible = true;
        // Update states
        this.resourceUtilizationUtils.resourceUtilizationState.appointments = response.appointments;
        this.resourceUtilizationUtils.resourceUtilizationState.resources = response.resources;
        this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions =
            lodash.cloneDeep(this.resourceUtilizationUtils.filtersOptions);
        this.resourceUtilizationUtils.resourceUtilizationState.appointmentsListOptions =
            lodash.cloneDeep(this.resourceUtilizationUtils.appointmentsListOptions);


    }

    private checkIfNeededToResetEverything(): void {
        // If history state is empty (all shouldKeep are false), then we reset everything
        // In the future we can check previous route too
        if (!this.resourceUtilizationUtils.shouldKeepFiltersState &&
            !this.resourceUtilizationUtils.shouldKeepCalendarState &&
            !this.resourceUtilizationUtils.shouldKeepListState &&
            !this.resourceUtilizationUtils.shouldMakeNewRequest &&
            !this.resourceUtilizationUtils.shouldKeepResponseData) {
            // Hide list and calendar
            this.resourceUtilizationUtils.isAppointmentListVisible = false;
            this.resourceUtilizationUtils.isCalendarVisible = false;
            // Clear and show filters
            this.resourceUtilizationUtils.clearFiltersEmitter?.next();
            if (this.resourceUtilizationUtils.filtersOptions) {
                this.resourceUtilizationUtils.filtersOptions.areFiltersCollapsed = false;
            }
        }
    }

    private loadStateVariablesFromHistory(): void {
        this.resourceUtilizationUtils.shouldKeepFiltersState = !!history.state?.shouldKeepFiltersState;
        this.resourceUtilizationUtils.shouldKeepCalendarState = !!history.state?.shouldKeepCalendarState;
        this.resourceUtilizationUtils.shouldKeepListState = !!history.state?.shouldKeepListState;
        this.resourceUtilizationUtils.shouldMakeNewRequest = !!history.state?.shouldMakeNewRequest;
        this.resourceUtilizationUtils.shouldKeepResponseData = !!history.state?.shouldKeepResponseData;

        // If shouldMakeNewRequest and we have filter values, do request
        if (this.resourceUtilizationUtils.shouldMakeNewRequest &&
            !!this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions?.latestSearchFilterValues) {
            this.onFilterSearch(this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions.latestSearchFilterValues, true);
        }

        // If shouldKeepResponseData and have resources and appointments, load resources and appointments
        if (this.resourceUtilizationUtils.shouldKeepResponseData &&
            !!this.resourceUtilizationUtils.resourceUtilizationState.resources &&
            !!this.resourceUtilizationUtils.resourceUtilizationState.appointments) {
            this.resourceUtilizationUtils.resources =
                lodash.cloneDeep(this.resourceUtilizationUtils.resourceUtilizationState.resources);
            this.resourceUtilizationUtils.appointments = this.resourceUtilizationUtils
                .mapAppointmentsForDisplay(this.resourceUtilizationUtils.resourceUtilizationState.appointments);
        }
    }

    private registerToRefreshDataButKeepCalendarDay(): void {
        if (!!this.resourceUtilizationUtils.refreshDataButKeepCalendarDay) {
            this.resourceUtilizationUtils.refreshDataButKeepCalendarDay.subscribe(() => {
                this.onFilterSearch(this.resourceUtilizationUtils.resourceUtilizationState.filtersOptions.latestSearchFilterValues, true);
            });
        }
    }
}
