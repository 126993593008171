<div class="patient-dashboard-details-container d-flex no-block flex-column custom-scrollbar">
    <h4 class="mx-3 my-3 font-weight-bold black-color patient-dashboard-details-header-container">
        <div class="d-flex flex-column">
            <span>{{"label.patientDetails" | translate}}</span>
            <span *ngIf="patientContextService?.patient?.documentNumberFormatted">
                ID {{patientContextService?.patient?.documentNumberFormatted}}
            </span>
        </div>
        <div class="patient-details-icons-container">
            <i class="fa-solid fa-pencil fa-xs" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit()"></i>
            <i class="fa-regular fa-eye cursor-pointer" [activityDisplay]="PATIENT+constants.READ"
               (click)="viewPatientOverviewPage()"></i>
        </div>
    </h4>
    <!--BIRTHDATE-->
    <div class="details-container">
        <i class="fa-solid fa-calendar fa-bold fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.birthDate' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit(0)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast">{{patientContextService?.patient?.birthDate | fullDateNoTimeFormatPipe}}</div>
        </div>
    </div>
    <div class="horizontal-line mx-3"></div>
    <!--ADDRESS-->
    <div class="details-container">
        <i class="fa-sharp fa-solid fa-location-dot fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.address' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit(1)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast">{{patientDashboardUtils.displayPatientAddress(patientContextService?.patient)}}</div>
        </div>
    </div>
    <div class="horizontal-line mx-3"></div>
    <!--MAIN PHONE NUMBER-->
    <div class="details-container">
        <i class="fa-solid fa-phone fa-bold fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.phone' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit(1)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast">{{patientContextService?.patient?.mainPhoneNumber}}</div>
        </div>
    </div>
    <div class="horizontal-line mx-3"></div>
    <!--EMAIL-->
    <div class="details-container">
        <i class="fa-solid fa-envelope fa-bold fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.email' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit(1)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast">{{patientContextService?.patient?.email}}</div>
        </div>
    </div>
    <div class="horizontal-line mx-3"></div>
    <!--MAIN COVERAGE PLAN-->
    <div class="details-container">
        <i class="fa-solid fa-shield-heart fa-bold fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.coveragePlan2' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE" (click)="onEdit(2)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast"
                 *ngFor="let coveragePlan of patientContextService?.patient?.coveragePlans">
                <span *ngIf="coveragePlan.mainCoveragePlan"> {{coveragePlan.name}}</span>
            </div>
        </div>
    </div>
    <div class="horizontal-line mx-3"></div>
    <!--TAGS-->
    <div class="details-container"
         *ngIf="areTagsActive()">
        <i class="fa-solid fa-tag fa-bold fa-lg round-background"></i>
        <div class="details-container-text">
            <div class="font-weight-bold black-color details-name">
                <span>{{'label.tags' | translate}}</span>
                <i class="fa-solid fa-pencil ml-2" [activityDisplay]="PATIENT+constants.UPDATE"
                   (click)="onEdit(createPatientUtils.isTimeDependentTagsActive() ? 4 : 2)"></i>
            </div>
            <div class="black-color details-content text-medium-contrast">
                <span class="font-weight-bold">{{patientContextService?.patient?.tags?.length}} </span>
                <span>{{patientContextService?.patient?.tags?.length === 1 ? ('label.tag2' | translate | lowercase) : ('label.tags' | translate | lowercase)}} </span>
                <span>{{'label.assigned' | translate}}</span>
            </div>
        </div>
    </div>
</div>
