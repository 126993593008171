import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {CenterType, CoveragePlanType, FilterComponentChannelType, RuleUnitEnum, ResourceType, RuleTableType, ServiceType} from 'sked-base';
import {TableFiltersType} from '../../../../data-model/general.type';

export interface MinimumAppointmentsRuleTagsType {
    selectedAvailabilityTags: TagType[];
    selectedPatientTags: TagType[];
}

export interface CenterModalFilterOptionsType {
    ngModel?: CenterType[];
}

export interface ServiceModalFilterOptionsType {
    ngModel?: ServiceType[];
}

export interface ResourceModalFilterOptionsType {
    ngModel?: ResourceType[];
}

export interface ChannelModalFilterOptionsType {
    ngModel?: FilterComponentChannelType[];
}

export interface CoveragePlanModalFilterOptionsType {
    ngModel?: CoveragePlanType[];
}

export interface MessageModalFilterOptionsType {
    ngModel?: string;
}

export interface MinimumAppointmentsModalFilterOptionsType {
    ngModel?: number;
}

export interface UnitModalFilterOptionsType {
    ngModel?: RuleUnitEnum;
}

export interface DeactivateBeforeModalFilterOptionsType {
    ngModel?: number;
}

export interface TagModalFilterOptionsType {
    ngModel?: TagType[];
}

export interface MinimumAppointmentsRuleModalFiltersType {
    centerOptions: CenterModalFilterOptionsType;
    serviceOptions: ServiceModalFilterOptionsType;
    resourceOptions: ResourceModalFilterOptionsType;
    channelOptions: ChannelModalFilterOptionsType;
    coveragePlanOptions: CoveragePlanModalFilterOptionsType;
    messageOptions: MessageModalFilterOptionsType;
    minimumAppointmentsOptions: MinimumAppointmentsModalFilterOptionsType;
    unitOptions: UnitModalFilterOptionsType;
    deactivateBeforeOptions: DeactivateBeforeModalFilterOptionsType;
    availabilityTagOptions: TagModalFilterOptionsType;
    patientTagOptions: TagModalFilterOptionsType;
    areFiltersActive: boolean;
}

export interface MinimumAppointmentsRuleStateType {
    tableFilters?: TableFiltersType;
    modalFilters?: MinimumAppointmentsRuleModalFiltersType;
    ruleTableFilterItem?: RuleTableType;
}

export enum MinimumAppointmentsRuleFiltersEnum {
    center = 'center',
    service = 'service',
    resource = 'resource',
    channel = 'channel',
    coveragePlan = 'coveragePlan',
    availabilityTag = 'availabilityTag',
    patientTag = 'patientTag'
}
