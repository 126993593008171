import {Injectable} from '@angular/core';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {
    AppointmentEventRuleType, AppointmentStatusValueEnum,
    EventActionDependentFiltersType, EventTypeEnum,
    Expand,
    IncludeExcludeEnum,
    ODataQueryObjectType,
    RuleTableDependentFiltersType,
    RuleTableType,
    RuleTypeEnum
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AppointmentEventRuleModalFiltersType,
    AppointmentEventRuleStateType,
    AppointmentStatusModalFilterOptionsType, BackofficeAppointmentEventRuleTagsType,
    EntityTypeModalFilterOptionsType,
    EventActionModalFiltersType,
    EventTypeModalFilterOptionsType,
    TagModalFilterOptionsType
} from './appointment-event-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {RulesTableHeaderOptions} from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class AppointmentEventRuleUtils {
    overviewState: AppointmentEventRuleStateType = {};

    constructor(private generalUtils: GeneralUtils) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'appointmentEventRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.priority',
            hasSort: true,
            onSortByProperty: 'priority',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.entityType',
            hasSort: true,
            onSortByProperty: 'entityType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.eventType',
            hasSort: true,
            onSortByProperty: 'eventType',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.status',
            hasSort: true,
            onSortByProperty: 'appointmentStatus',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.centerTag',
            hasSort: true,
            onSortByProperty: 'centerTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.serviceTag',
            hasSort: true,
            onSortByProperty: 'serviceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resourceTag',
            hasSort: true,
            onSortByProperty: 'resourceTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientTag',
            hasSort: true,
            onSortByProperty: 'patientTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.eventAction',
            hasSort: true,
            onSortByProperty: 'eventAction',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            CenterTag: {select: EXPAND_TAG_SELECT},
            ServiceTag: {select: EXPAND_TAG_SELECT},
            ResourceTag: {select: EXPAND_TAG_SELECT},
            PatientTag: {select: EXPAND_TAG_SELECT},
            AppointmentEventRuleTable: {select: ['Id', 'Name', 'Description']},
            EventAction: {select: ['Id', 'Name']}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'appointmentEventRuleTableId':
                    case 'centerTagId':
                    case 'serviceTagId':
                    case 'resourceTagId':
                    case 'patientTagId':
                    case 'eventActionId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'eventType':
                    case 'entityType':
                    case 'appointmentStatus': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'appointmentEventRuleTable' || item === 'centerTag' || item === 'serviceTag'
                || item === 'resourceTag' || item === 'patientTag' || item === 'eventAction') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialRule(): AppointmentEventRuleType {
        return {
            name: undefined,
            appointmentEventRuleTableId: undefined,
            priority: null,
            active: false,
            eventType: 'noValue' as EventTypeEnum,
            entityType: 'Appointment',
            appointmentStatus: 'noValue' as AppointmentStatusValueEnum,
            includeCenterTag: IncludeExcludeEnum.include,
            includeServiceTag: IncludeExcludeEnum.include,
            includeResourceTag: IncludeExcludeEnum.include,
            includePatientTag: IncludeExcludeEnum.include,
            centerTagId: undefined,
            serviceTagId: undefined,
            resourceTagId: undefined,
            patientTagId: undefined,
            eventActionId: undefined
        } as AppointmentEventRuleType;
    }

    getInitialAppointmentEventRuleTable(): RuleTableType {
        return {
            name: undefined,
            description: undefined,
        } as RuleTableType;
    }

    getEmptyRuleTableDependentFilters(): RuleTableDependentFiltersType {
        return {
            searchPhrase: '',
            type: RuleTypeEnum.AppointmentEventRule,
            exclusionList: []
        };
    }

    getEmptyEventActionDependentFilters(): EventActionDependentFiltersType {
        return {
            searchPhrase: '',
            active: true,
            exclusionList: []
        };
    }

    getInitialAppointmentEventRuleTags(): BackofficeAppointmentEventRuleTagsType {
        return {
            selectedCenterTags: [],
            selectedServiceTags: [],
            selectedResourceTags: [],
            selectedPatientTags: [],
            includeCenterTagFlag: true,
            includeServiceTagFlag: true,
            includeResourceTagFlag: true,
            includePatientTagFlag: true
        };
    }

    getInitialModalFilters(): AppointmentEventRuleModalFiltersType {
        return {
            eventTypeFilterOptions: {
                ngModel: undefined
            } as EventTypeModalFilterOptionsType,
            entityTypeFilterOptions: {
                ngModel: undefined
            } as EntityTypeModalFilterOptionsType,
            appointmentStatusOptions: {
                ngModel: undefined
            } as AppointmentStatusModalFilterOptionsType,
            centerTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            serviceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            resourceTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            patientTagOptions: {
                ngModel: []
            } as TagModalFilterOptionsType,
            eventActionTypeOptions: {
                ngModel: []
            } as EventActionModalFiltersType,
            areFiltersActive: false
        };
    }

    isAnyFilterActive(modalFilters: AppointmentEventRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different than the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapAppointmentEventRuleForServer(appointmentEventRule: AppointmentEventRuleType): AppointmentEventRuleType {
        const appointmentEventRuleToSend: AppointmentEventRuleType = {} as AppointmentEventRuleType;

        if (appointmentEventRule.id) {
            appointmentEventRuleToSend.id = appointmentEventRule.id;
        }

        if (appointmentEventRule.etag) {
            appointmentEventRuleToSend.etag = appointmentEventRule.etag;
        }

        appointmentEventRuleToSend.name = appointmentEventRule.name;
        appointmentEventRuleToSend.centerTagId = appointmentEventRule.centerTagId;
        appointmentEventRuleToSend.serviceTagId = appointmentEventRule.serviceTagId;
        appointmentEventRuleToSend.resourceTagId = appointmentEventRule.resourceTagId;
        appointmentEventRuleToSend.patientTagId = appointmentEventRule.patientTagId;
        appointmentEventRuleToSend.entityType = appointmentEventRule.entityType;
        appointmentEventRuleToSend.eventActionId = appointmentEventRule.eventActionId;
        appointmentEventRuleToSend.active = appointmentEventRule.active;
        appointmentEventRuleToSend.onlyWarning = appointmentEventRule.onlyWarning;
        appointmentEventRuleToSend.priority = appointmentEventRule.priority;
        appointmentEventRuleToSend.cancelled = appointmentEventRule.cancelled;
        appointmentEventRuleToSend.includeServiceTag = appointmentEventRule.includeServiceTag;
        appointmentEventRuleToSend.includeResourceTag = appointmentEventRule.includeResourceTag;
        appointmentEventRuleToSend.includePatientTag = appointmentEventRule.includePatientTag;
        appointmentEventRuleToSend.includeCenterTag = appointmentEventRule.includeCenterTag;
        appointmentEventRuleToSend.appointmentEventRuleTableId = appointmentEventRule.appointmentEventRuleTableId;

        if (!this.generalUtils.isSelectedNoValueOption(appointmentEventRule.eventType)) {
            appointmentEventRuleToSend.eventType = appointmentEventRule.eventType;
        }

        if (!this.generalUtils.isSelectedNoValueOption(appointmentEventRule.appointmentStatus)) {
            appointmentEventRuleToSend.appointmentStatus = appointmentEventRule.appointmentStatus;
        }

        if (appointmentEventRule.includeCenterTag) {
            appointmentEventRuleToSend.includeCenterTag = appointmentEventRule.includeCenterTag;
        }

        if (appointmentEventRule.includeServiceTag) {
            appointmentEventRuleToSend.includeServiceTag = appointmentEventRule.includeServiceTag;
        }

        if (appointmentEventRule.includeResourceTag) {
            appointmentEventRuleToSend.includeResourceTag = appointmentEventRule.includeResourceTag;
        }

        if (appointmentEventRule.includePatientTag) {
            appointmentEventRuleToSend.includePatientTag = appointmentEventRule.includePatientTag;
        }

        return appointmentEventRuleToSend;
    }
}
