import {EventEmitter, Injectable} from '@angular/core';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {Router} from '@angular/router';

import * as lodash from 'lodash';
import * as moment from 'moment';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {
    AppointmentDetailsOptionsType,
    AppointmentListItemOptionsType,
    AppointmentListRequestFilters,
    AppointmentListStateType
} from './appointment-list.types';
import {
    AppointmentListFiltersOptionsType,
    AppointmentListFiltersSearchType
} from './appointment-list-filters/appointment-list-filters.types';
import {
    AppointmentListTemplateName,
    AppointmentsPdfQueryType, AppointmentStatusEnum,
    DateRangeOptionsType,
    ExportAppointmentsType, GenericFilterOptionsType
} from 'sked-base';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {AppointmentTypeEnum} from '../patient-dashboard/patient-appointment-list/patient-appointment-list.types';

@Injectable({
    providedIn: 'root'
})
export class AppointmentListUtils {
    // State management
    appointmentListState: AppointmentListStateType = this.getEmptyState();
    shouldMakeNewRequest = false;
    shouldKeepFiltersState = false;
    shouldKeepListState = false;

    // Options for filters
    filtersOptions: AppointmentListFiltersOptionsType = this.getEmptyFiltersOptions();
    clearFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();

    // Options for appointment list item
    appointmentListItemOptions: AppointmentListItemOptionsType = this.getInitialAppointmentListFiltersOptions();

    // Options for appointment list details
    appointmentItemDetailsOptions: AppointmentDetailsOptionsType = this.getInitialItemDetailsOptions();
    dateRangeOptions: DateRangeOptionsType;

    constructor(
        private router: Router,
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
        private patientContextService: PatientContextService,
        private translatedLanguageService: TranslatedLanguageService
    ) {
    }

    // State management

    updateFilterState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.filtersOptions.hasOwnProperty(property)) {
                return;
            }
            if (property === 'filterWrapperOptions') {
                // Don't cloneDeep providerInstance
                this.appointmentListState.filtersOptions[property] = [];
                this.filtersOptions[property].forEach((filterWrapperOptionsObject: GenericFilterOptionsType) => {
                    const {providerInstance, ...filtersOptionsWithoutProviderInstance} = filterWrapperOptionsObject;
                    this.appointmentListState.filtersOptions[property].push({
                        providerInstance,
                        ...lodash.cloneDeep(filtersOptionsWithoutProviderInstance)
                    });
                });
                return;
            }
            this.appointmentListState.filtersOptions[property] = lodash.cloneDeep(this.filtersOptions[property]);
        });
    }

    updateItemsState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.appointmentListItemOptions.hasOwnProperty(property)) {
                return;
            }
            this.appointmentListState.appointmentListItemOptions[property] =
                lodash.cloneDeep(this.appointmentListItemOptions[property]);
        });
    }

    getEmptyState(): AppointmentListStateType {
        return {
            filtersOptions: this.getEmptyFiltersOptions() as AppointmentListFiltersOptionsType,
            appointmentListItemOptions: this.getInitialAppointmentListFiltersOptions() as AppointmentListItemOptionsType,
            appointmentItemDetailsOptions: this.getInitialItemDetailsOptions() as AppointmentDetailsOptionsType,
        } as AppointmentListStateType;
    }

    // ///

    getInitialItemDetailsOptions(): AppointmentDetailsOptionsType {
        return {
            appointment: {},
        } as AppointmentDetailsOptionsType;
    }

    getEmptyFiltersOptions(): AppointmentListFiltersOptionsType {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            dateRangeOptions: {},
            appointmentListFiltersValues: {},
            areFiltersValid: false,
            areFiltersCollapsed: false,
            latestSearchFilterValues: undefined,
            statusSelectNgModel: undefined,
        } as AppointmentListFiltersOptionsType;
    }

    getInitialAppointmentListFiltersOptions(): AppointmentListItemOptionsType {
        return {
            isBeforeSearchState: true,
            isNotFoundState: false,
            appointmentList: [],
            itemsPerPageList: [5, 10, 25, 50],
            latestAppointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as AppointmentListRequestFilters,
            appointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as AppointmentListRequestFilters,
            totalAppointmentItems: null,
            showItemsPerPageDropdown: false
        };
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const todayMoment = moment();
        const todayPlus7DaysMoment = moment().add(7, 'days');
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayMinus30DaysMoment = moment().subtract(30, 'days');
        const todayMinus30Days = this.dateTimeUtils.getNgbDateFromMoment(todayMinus30DaysMoment);
        const todayPlus7Days = this.dateTimeUtils.getNgbDateFromMoment(todayPlus7DaysMoment);
        const fromDate = {year: today.year, month: today.month, day: today.day};
        const minDate = {year: todayMinus30Days.year, month: todayMinus30Days.month, day: todayMinus30Days.day};
        const toDate = {year: todayPlus7Days.year, month: todayPlus7Days.month, day: todayPlus7Days.day};
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.from',
            toDateLabel: 'label.to',
            minDate,
            maximumRange: 60,
            disabled: false
        } as DateRangeOptionsType;
    }

    getExportModalInitialDateRangeOptions(): DateRangeOptionsType {
        const {fromDate, toDate} = this.filtersOptions.dateRangeOptions;
        const todayMinus30DaysMoment = moment().subtract(30, 'days');
        const todayMinus30Days = this.dateTimeUtils.getNgbDateFromMoment(todayMinus30DaysMoment);
        const minDate = {year: todayMinus30Days.year, month: todayMinus30Days.month, day: todayMinus30Days.day};
        const fromDateMoment = this.dateTimeUtils.getMomentFromNgbDate(fromDate);
        const toDateMoment = this.dateTimeUtils.getMomentFromNgbDate(toDate);
        const differenceInDays = toDateMoment.diff(fromDateMoment, 'days');
        const initialFromDate = fromDate;
        const initialFromDatePlus30DaysMoment = fromDateMoment.add(30, 'days');
        const initialFromDatePlus30Days = this.dateTimeUtils.getNgbDateFromMoment(initialFromDatePlus30DaysMoment);
        const initialToDate = differenceInDays <= 30 ? toDate : initialFromDatePlus30Days;
        return {
            fromDate: initialFromDate,
            toDate: initialToDate,
            fromDateLabel: 'label.dueDateFrom',
            toDateLabel: 'label.dueDateTo',
            minDate,
            maximumRange: 30,
            disabled: false
        } as DateRangeOptionsType;
    }

    getAppointmentQueryFilter(appointmentListRequestFilters: AppointmentListRequestFilters) {
        return {
            count: true,
            skip: (appointmentListRequestFilters.pageFilters.currentPage - 1) * appointmentListRequestFilters.pageFilters.itemsPerPage,
            top: appointmentListRequestFilters.pageFilters.itemsPerPage,
            filter: this.getAppointmentFiltersQuery(appointmentListRequestFilters.searchFilters),
            expand: this.getExpandQuery(appointmentListRequestFilters.searchFilters),
            orderBy: 'DateTimeFrom asc'
        };
    }

    getQueryFilterForExportAppointmentsPdf(): ExportAppointmentsType {
        const {location, dateTo, dateFrom, service, resource, area, status} =
            this.appointmentListItemOptions.appointmentListRequestFilters.searchFilters;
        return {
            dateFrom: moment(this.dateTimeUtils.getStringFromNgbDate(dateFrom)).format(),
            dateTo: moment(this.dateTimeUtils.getStringFromNgbDate(dateTo)).add(1, 'days').format(),
            centerId: location?.id,
            resourceId: resource?.id,
            serviceId: service?.id,
            areaId: area?.id,
            status,
            language: this.translatedLanguageService.getUsedLanguage()
        };
    }

    getQueryFilterForAppointmentsPdf(): AppointmentsPdfQueryType {
        const {location, dateTo, dateFrom, service, resource, area, status} =
            this.appointmentListItemOptions.appointmentListRequestFilters.searchFilters;

        return {
            resourceId: resource?.id,
            centerId: location?.id,
            areaId: area?.id,
            serviceId: service?.id,
            dateFrom: moment(this.dateTimeUtils.getStringFromNgbDate(dateFrom)).format().replace('+', '%2B'),
            dateTo: moment(this.dateTimeUtils.getStringFromNgbDate(dateTo)).endOf('day').format().replace('+', '%2B'), //date appears on PDF so we cannot increment to 1 day start of day
            status,
            title: '',
            language: this.translatedLanguageService.getUsedLanguage(),
            templateName: AppointmentListTemplateName.AppointmentOverview
        };
    }

    private getAppointmentFiltersQuery(searchFilters: AppointmentListFiltersSearchType) {
        const and: any[] = [];
        if (searchFilters?.resource?.id) {
            const innerAnd: any[] = [
                { Resource: { Id: {eq: {type: 'guid', value: searchFilters.resource.id}} } },
            ];
            if (searchFilters.status === Object.keys(AppointmentStatusEnum)[2]) {
                innerAnd.push({Cancelled: {eq: true}});
            }
            and.push({ ResourceAppointments: { any: { and: innerAnd } } });
        }
        if (searchFilters?.location?.id) {
            and.push({CenterId: {eq: {type: 'guid', value: searchFilters.location.id}}});
        }
        // Appointments don't have the AreaId field. We need to search by service/AreaId
        if (searchFilters && searchFilters?.area?.id) {
            and.push({'Service/AreaId': {eq: {type: 'guid', value: searchFilters.area.id}}});
        }
        if (searchFilters?.service?.id) {
            and.push({ServiceId: {eq: {type: 'guid', value: searchFilters.service.id}}});
        }
        if (!!searchFilters?.status) {
            if (searchFilters.status === Object.keys(AppointmentStatusEnum)[2]) {
                and.push({Cancelled: true});
            }
            and.push({Status: {eq: searchFilters.status}});
        }
        if (searchFilters.dateFrom) {
            const momentDateFrom = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dateFrom);
            and.push({
                DateTimeFrom: {
                    ge: {
                        type: 'raw',
                        value: momentDateFrom.startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        if (!!searchFilters?.dateTo) {
            const momentDateTo = this.dateTimeUtils.getMomentFromNgbDate(searchFilters.dateTo).add(1, 'days');
            and.push({
                DateTimeTo: {
                    lt: {
                        type: 'raw',
                        value: momentDateTo.startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        return and;
    }

    private getExpandQuery(searchFilters: AppointmentListFiltersSearchType) {
        return {
            AppointmentType: {select: ['Name']},
            Patient: {
                expand: {CoveragePlans: {select: ['Id', 'Name', 'CoverageCompanyId', 'IsPrivate', 'System', 'MainCoveragePlan']}}
            },
            Resource: {select: ['Name']},
            ResourceAppointments: {
                ...(searchFilters.status === Object.keys(AppointmentStatusEnum)[2] ? {
                    filter: {Cancelled: {eq: true}},
                } : {}),
                expand: {Resource: {select: ['Id', 'Name', 'ResourceTypeId']}}
            },
            Service: {
                select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices', 'AreaId'],
                expand: {Speciality: {select: ['Name']}, Area: {select: ['Id', 'Name']}}
            },
            SubServices: {
                // If an appointment status is cancelled, also the SubServices for this appointment are set to cancelled
                // In order to receive them from back-end we need to add this filter
                ...(searchFilters.status === Object.keys(AppointmentStatusEnum)[2] ? {
                    filter: {Cancelled: {eq: true}},
                } : {}),
                expand: {SubService: {select: ['Name', 'Code', 'Id']}}},
            Center: {select: ['Name']},
            CoveragePlan: {select: ['Id', 'Name']},
            SkedTasks: {},
            StatusHistories: {
                select: ['Id', 'Status', 'TransitionReasonOthers'],
                expand: {AppointmentStatusTransitionReason: {select: ['Id', 'Name', 'Others']}},
            },
        };
    }
}
