<i class="fa-solid fa-floppy-disk-pen ml-2 cursor-pointer"
   [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"
   (click)="displayChangeLog()"
   [ngbPopover]="changeLogInfoPopover"
   placement="bottom"
   popoverClass="popover-shadow"
   triggers="mouseenter:mouseleave"
></i>
<ng-template #changeLogInfoPopover>
    <span translate="label.viewChangeLog"></span>
</ng-template>
