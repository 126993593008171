export enum HumanReadableMinutesEnum {
    Days = 'Days',
    Hours = 'Hours',
    Minutes = 'Minutes'
}

export interface HumanReadableMinutesPopoverTextType {
    days?: number;
    hours?: number;
    minutes?: number;
}

export interface HumanReadableMinutesOptionsType {
    elementId: string;
    totalMinutes: any;
    iconClass?: string;
}
