import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { HolidayMDComponent } from './holiday-md.component';
import { CreateHolidayComponent } from './create-holiday/create-holiday.component';

@NgModule({
  declarations: [HolidayMDComponent, CreateHolidayComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
  ]
})
export class HolidayMDModule { }
