import {CenterType, CoveragePlanType, RuleTableType, ServiceType} from 'sked-base';
import {TableFiltersType} from '../../../../data-model/general.type';

export interface CenterModalFilterOptionsType {
    ngModel?: CenterType[];
}

export interface ServiceModalFilterOptionsType {
    ngModel?: ServiceType[];
}

export interface CoveragePlanModalFilterOptionsType {
    ngModel?: CoveragePlanType[];
}

export interface PriceModalFilterOptionsType {
    ngModel?: number;
}

export interface AppointmentPriceRuleModalFiltersType {
    centerOptions: CenterModalFilterOptionsType;
    serviceOptions: ServiceModalFilterOptionsType;
    coveragePlanOptions: CoveragePlanModalFilterOptionsType;
    priceOptions: PriceModalFilterOptionsType;
    areFiltersActive: boolean;
}

export interface AppointmentPriceRuleStateType {
    tableFilters?: TableFiltersType;
    modalFilters?: AppointmentPriceRuleModalFiltersType;
    ruleTableFilterItem?: RuleTableType;
}

export enum AppointmentPriceRuleFiltersEnum {
    center = 'center',
    service = 'service',
    coveragePlan = 'coveragePlan'
}
