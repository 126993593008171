import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as lodash from 'lodash';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import {MultiAppointmentBookingReservationType} from '../multi-appointment-booking.types';

@Component({
    selector: 'app-mab-reserved-slots',
    templateUrl: './mab-reserved-slots.component.html',
    styleUrls: ['./mab-reserved-slots.component.scss']
})
export class MabReservedSlotsComponent implements OnInit {
    @Output()
    bookAppointments: EventEmitter<MultiAppointmentBookingReservationType[]> = new EventEmitter<MultiAppointmentBookingReservationType[]>();

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils
    ) {
    }

    ngOnInit(): void {
    }

    onRemoveAll(): void {
        const reservationListCopy: MultiAppointmentBookingReservationType[] = lodash.cloneDeep(
            this.multiAppointmentBookingMdUtils?.reservedSlotsOptions?.reservations
        ) ?? [];
        reservationListCopy?.forEach((reservation) => {
            this.multiAppointmentBookingMdUtils.removeReservedSlot(reservation.slot, reservation.listId);
        });
    }

    onReservedSlotClick(reservedSlot: MultiAppointmentBookingReservationType): void {
        this.multiAppointmentBookingMdUtils.openObjectDetailsModal(reservedSlot.slot);
    }

    onBookAppointments(): void {
        this.bookAppointments.emit(this.multiAppointmentBookingMdUtils?.reservedSlotsOptions?.reservations ?? []);
    }

    onMouseEnterReservation(reservedSlot: MultiAppointmentBookingReservationType) {
        reservedSlot.hovered = true;
        this.multiAppointmentBookingMdUtils.setHoverOrMarkedToSlotInSlotsList(
            reservedSlot.listId, reservedSlot.slot, {hovered: true}
        );
        this.multiAppointmentBookingMdUtils.setHoverToSlotListHeader(reservedSlot.listId, {hovered: true});
    }

    onMouseLeaveReservation(reservedSlot: MultiAppointmentBookingReservationType) {
        reservedSlot.hovered = false;
        this.multiAppointmentBookingMdUtils.setHoverOrMarkedToSlotInSlotsList(
            reservedSlot.listId, reservedSlot.slot, {hovered: false}
        );
        this.multiAppointmentBookingMdUtils.setHoverToSlotListHeader(reservedSlot.listId, {hovered: false});
    }

}
