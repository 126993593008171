<div class="container-fluid overview-screen-md appointments-information-component">
    <div class="appointment-information-header">
        <h3 class="title" translate="label.appointmentsInfo"></h3>
    </div>
    <div class="appointment-content-container custom-scrollbar">
        <div class="row mb-2">
            <!--EMAIL-->
            <div class="col-6">
                <div class="dynamic-input-container" *ngIf="options?.tenantCustomizingData?.Email?.visible">
                    <label>
                        <span translate="label.patientEmail"></span>
                        <span class="input-required-asterisk" *ngIf="tenantCustomizingService.isRequired(formControlNames.EMAIL, options?.tenantCustomizingData)">*</span>
                    </label>
                    <input type="text" name="email" class="dynamic-input"
                           [(ngModel)]="appointmentInformationFormData.email"
                           (ngModelChange)="onEmailChange(appointmentInformationFormData.email)"
                           placeholder="{{'label.patientEmail'|translate}}"
                           autocomplete="off"
                           [disabled]="options.readOnly || !options?.tenantCustomizingData?.Email?.enabled || !configDataService.isActivityActive('PatientUpdate')"
                           [required]="tenantCustomizingService.isRequired(formControlNames.EMAIL, options?.tenantCustomizingData)"
                           [ngClass]="{ 'has-error': !appointmentInformationFormDataValidation?.email?.isValid,
                                        'disabled': options.readOnly || !options?.tenantCustomizingData?.Email?.enabled || !configDataService.isActivityActive('PatientUpdate')}">
                    <div *ngIf="!appointmentInformationFormDataValidation?.email?.isValid" class="help-block">
                        <span>{{appointmentInformationFormDataValidation?.email?.message | translate:appointmentInformationFormDataValidation?.email?.params}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <!--MAIN PHONE NUMBER-->
            <div class="col-6">
                <div class="phone-number-form" *ngIf="options?.tenantCustomizingData?.MainPhoneNumber?.visible">
                    <label class="phone-number-form-label">
                        <span translate="label.patientPhoneNumber"></span>
                        <span class="input-required-asterisk" *ngIf="tenantCustomizingService.isRequired(formControlNames.MAIN_PHONE_NUMBER, options?.tenantCustomizingData)">*</span>
                    </label>
                    <div class="d-flex align-items-center phone-number-form-input"
                         [ngClass]="{ 'has-error': !appointmentInformationFormDataValidation?.mainPhoneNumber?.isValid,
                                      'disabled': options.readOnly || !options?.tenantCustomizingData?.MainPhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')}">
                        <span>+</span>
                        <input type="text"
                               placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                               [(ngModel)]="mainPhoneNumber.countryCode"
                               (ngModelChange)="onMainPhoneNumberChange(mainPhoneNumber)"
                               [disabled]="options.readOnly || !options?.tenantCustomizingData?.MainPhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')"
                               [required]="tenantCustomizingService.isRequired(formControlNames.MAIN_PHONE_NUMBER, options?.tenantCustomizingData)">
                    </div>
                    <div *ngIf="!appointmentInformationFormDataValidation?.mainPhoneNumber?.isValid" class="help-block">
                        <span [translate]="appointmentInformationFormDataValidation?.mainPhoneNumber?.message"></span>
                    </div>
                    <input type="tel"
                           name="mainPhoneNumber"
                           class="phone-number-form-input"
                           placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
                           autocomplete="off"
                           [(ngModel)]="mainPhoneNumber.phoneNumber"
                           (ngModelChange)="onMainPhoneNumberChange(mainPhoneNumber)"
                           [disabled]="options.readOnly || !options?.tenantCustomizingData?.MainPhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')"
                           [required]="tenantCustomizingService.isRequired(formControlNames.MAIN_PHONE_NUMBER, options?.tenantCustomizingData)"
                           [ngClass]="{ 'has-error': !appointmentInformationFormDataValidation?.mainPhoneNumber?.isValid,
                                        'disabled': options.readOnly || !options?.tenantCustomizingData?.MainPhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')}">
                </div>
            </div>
            <!--ALTERNATE PHONE NUMBER-->
            <div class="col-6">
                <div class="phone-number-form" *ngIf="options?.tenantCustomizingData?.AlternatePhoneNumber?.visible">
                    <label class="phone-number-form-label">
                        <span translate="label.patientAlternatePhoneNumber"></span>
                        <span class="input-required-asterisk" *ngIf="tenantCustomizingService.isRequired(formControlNames.ALTERNATE_PHONE_NUMBER, options?.tenantCustomizingData)">*</span>
                    </label>
                    <div class="d-flex align-items-center phone-number-form-input"
                         [ngClass]="{ 'has-error': !appointmentInformationFormDataValidation?.alternatePhoneNumber?.isValid,
                                      'disabled': options.readOnly || !options?.tenantCustomizingData?.AlternatePhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')}">
                        <span>+</span>
                        <input type="text"
                               placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                               [(ngModel)]="alternatePhoneNumber.countryCode"
                               (ngModelChange)="onAlternatePhoneNumberChange(alternatePhoneNumber)"
                               [disabled]="options.readOnly || !options?.tenantCustomizingData?.AlternatePhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')"
                               [required]="tenantCustomizingService.isRequired(formControlNames.ALTERNATE_PHONE_NUMBER, options?.tenantCustomizingData)">
                    </div>
                    <div *ngIf="!appointmentInformationFormDataValidation?.alternatePhoneNumber?.isValid"
                         class="help-block">
                        <span [translate]="appointmentInformationFormDataValidation?.alternatePhoneNumber?.message"></span>
                    </div>
                    <input type="tel"
                           name="alternatePhoneNumber"
                           class="phone-number-form-input"
                           placeholder="{{'label.phoneNumberPlaceholder'|translate}}"
                           autocomplete="off"
                           [(ngModel)]="alternatePhoneNumber.phoneNumber"
                           (ngModelChange)="onAlternatePhoneNumberChange(alternatePhoneNumber)"
                           [disabled]="options.readOnly || !options?.tenantCustomizingData?.AlternatePhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')"
                           [required]="tenantCustomizingService.isRequired(formControlNames.ALTERNATE_PHONE_NUMBER, options?.tenantCustomizingData)"
                           [ngClass]="{ 'has-error': !appointmentInformationFormDataValidation?.alternatePhoneNumber?.isValid,
                                        'disabled': options.readOnly || !options?.tenantCustomizingData?.AlternatePhoneNumber?.enabled || !configDataService.isActivityActive('PatientUpdate')}">
                </div>
            </div>
        </div>
        <!-- COMMENTS -->
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold font-14" translate="label.comments"></label>
                    <span class="d-flex align-items-start">
                    <textarea type="text" name="comments" class="form-control rounded-0 w-100 mr-3"
                              [disabled]="options.readOnly"
                              [(ngModel)]="appointmentInformationFormData.comment"
                              placeholder="{{'label.hereAddYourComments' | translate}}"
                              autocomplete="off">
                    </textarea>
                </span>
                </div>
            </div>
        </div>
        <!-- APPOINTMENTS -->
        <div class="row" *ngFor="let slotOptions of multiAppointmentBookingUtils.bookAppointmentListOptions; let index = index">
            <div class="col-12">
                <div class="form-group appointment-inner-container" [ngClass]="{'collapsed': slotOptions?.isCollapsed, 'mab-error': multiAppointmentBookingUtils.bookAppointmentsError?.index === index}"
                     [attr.aria-expanded]="!slotOptions?.isCollapsed">
                    <div class="appointment-heading-row">
                        <!-- Time, duration, service / speciality name -->
                        <div class="font-weight-bold font-14 appointment-heading-title">
                            <span>
                                {{slotOptions?.slot?.date | date}}
                                , {{slotOptions?.slot?.time}}
                                , {{slotOptions?.slot.duration}} {{"label.minuteShort" | translate}}
                                <span *ngIf="slotOptions?.appointment?.service?.speciality?.name" class="font-weight-bold text-dark">
                                    {{slotOptions?.appointment?.service?.speciality?.name}}
                                </span>
                                <span>{{slotOptions?.slot?.service?.name}}</span>
                            </span>
                        </div>
                        <!-- Object details -->
                        <app-appointment-object-details class="ml-3"
                                                        *ngIf="slotOptions?.objectDetailsOptions"
                                                        [options]="slotOptions?.objectDetailsOptions"></app-appointment-object-details>
                        <!-- Print button -->
                        <button *ngIf="!!options.displayPrintButtons"
                                [activityDisplay]="'ViewAppointmentBulletinPdf'"
                                (click)="onPrintButtonClick(index)" type="button"
                                class="btn theme-btn ml-3">
                            <span translate="label.print"></span>
                        </button>
                        <!-- Collapse buttons -->
                        <div class="slots-filter-collapse-action-container"
                             (click)="appointmentCollapse(index)">
                            <span *ngIf="!slotOptions?.isCollapsed">
                                <i class="fa-sharp fa-solid fa-angle-up"></i>
                            </span>
                            <span *ngIf="slotOptions?.isCollapsed">
                                <i class="fa-solid fa-angle-down"></i>
                            </span>
                        </div>
                    </div>
                    <!-- Price errors -->
                    <div *ngIf="slotOptions?.shouldShowPaymentSections && !!slotOptions?.priceErrorMessage" class="help-block mb-2">
                        <span>{{slotOptions?.priceErrorMessage}}</span>
                    </div>
                    <div class="table-responsive appointment-information-table-container mt-2">
                        <table class="table table-bordered table-sm font-weight-normal font-medium">
                            <tbody>
                            <tr>
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-4">{{"label.dateTimeDuration" | translate}}</div>
                                        <div class="col-8 text-right">{{slotOptions?.slot?.date | date}}
                                            , {{slotOptions?.slot?.time}}
                                            , {{slotOptions?.slot.duration}} {{"label.minuteShort" | translate}}
                                            <span *ngIf="slotOptions?.slot?.oversellingDefinitionId && slotOptions?.additionalData?.expertBooking">
                                                <span class="vertical-line-small mx-2"></span>
                                                <i class="fa-regular fa-file-invoice-dollar mr-1"></i>
                                                <span translate='label.oversold'></span>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <ng-template #noPrice>/////</ng-template>
                            <tr *ngIf="slotOptions?.shouldShowPaymentSections">
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-3" translate="label.servicePrice"></div>
                                        <div class="col-9 text-right">
                                            <ngx-ui-loader class="local-spinner position-relative mt-2" [hasProgressBar]="false"
                                                           [loaderId]="slotOptions?.servicePriceSpinnerId"
                                                           [fgsSize]="20"
                                            ></ngx-ui-loader>
                                            <span *ngIf="!!slotOptions?.priceInformation?.priceValue">
                                                {{slotOptions?.priceInformation.priceValue | currency: slotOptions?.priceInformation?.currency : 'symbol'}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="slotOptions?.shouldShowPaymentSections">
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-3" translate="label.actualPrice"></div>
                                        <div class="col-9 text-right">
                                            <ngx-ui-loader class="local-spinner position-relative" [hasProgressBar]="false"
                                                           [loaderId]="slotOptions?.actualPriceSpinnerId"
                                                           [fgsSize]="20"
                                            ></ngx-ui-loader>
                                            <span *ngIf="!!slotOptions?.priceInformation?.paymentAmount">
                                                {{slotOptions?.priceInformation.paymentAmount | currency: slotOptions?.priceInformation?.currency : 'symbol'}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-3" translate="label.coveragePlan2"></div>
                                        <div class="col-9 text-right">{{slotOptions?.slot?.coveragePlan?.name}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.resource"></div>
                                        <div class="col-6 text-right">{{slotOptions?.slot?.resource?.name}}
                                            <span class="pr-1" *ngIf="slotOptions?.slot?.resource?.additionalInformation">
                                        <i class="fas fa-info-circle"
                                           [ngbPopover]="slotOptions?.slot?.resource?.additionalInformation"
                                           triggers="mouseenter:mouseleave"
                                           popoverClass="popover-shadow"
                                           placement="left"></i>
                                    </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="slotOptions?.slot?.secondaryResources?.length > 0">
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.secondaryResources"></div>
                                        <div class="col-6 text-right">
                                            <ng-container *ngFor="let secondaryResource of slotOptions?.slot.secondaryResources; last as isLast">
                                                <span>{{secondaryResource?.name}}</span>
                                                <span *ngIf="!isLast">,&nbsp;</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.service2"></div>
                                        <div class="col-6 text-right">
                                            <span *ngIf="slotOptions?.appointment?.service?.speciality?.name" class="font-weight-bold text-dark">
                                                {{slotOptions?.appointment?.service?.speciality?.name}}
                                            </span>
                                            <span>{{slotOptions?.slot?.service?.name}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="slotOptions?.slot?.subServices?.length > 0">
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.subServices"></div>
                                        <div class="col-6 text-right">
                                            <ng-container *ngFor="let subService of slotOptions?.slot.subServices; last as isLast">
                                                <span>{{subService?.name}}</span>
                                                <span *ngIf="!isLast">,&nbsp;</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="false">
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.price"></div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-2">
                                    <div class="row container-fluid pt-0 pb-0">
                                        <div class="col-6" translate="label.center2"></div>
                                        <div class="col-6 text-right">{{slotOptions?.slot?.center?.name}}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-container">
        <div class="col-12 position-sticky button-area">
            <div class="justify-content-end">
                <button *ngIf="!options.readOnly" class="btn close-btn mr-3"
                        (click)="onCancelButtonClick()"
                        translate="label.close">
                </button>
                <button *ngIf="!options.readOnly && !options.displayPrintButtons"
                        (click)="onBookButtonClick()" type="button"
                        class="btn theme-btn"
                        [disabled]="!isAppointmentInformationFormDataValid()">
                    <span translate="label.book"></span>
                </button>
                <button *ngIf="!!options.readOnly"
                        (click)="onDoneButtonClick()" type="button"
                        class="btn theme-alt-btn mr-3">
                    <span translate="label.done"></span>
                </button>
                <button *ngIf="!!options.displayPrintButtons"
                        [activityDisplay]="'ViewAppointmentBulletinPdf'"
                        (click)="onPrintButtonClick()" type="button"
                        class="btn theme-btn">
                    <span translate="label.printAll"></span>
                </button>
            </div>
        </div>
    </div>
</div>
