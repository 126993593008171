<div class="container-fluid create-appointment-type-definition-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-appointment-type-definition-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase" translate="label.appointmentTypeDefinition"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.appointmentType"></td>
                                    <td class="text-right">{{appointmentTypeDefinitionItem.appointmentType.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.resource"></td>
                                    <td class="text-right">{{appointmentTypeDefinitionItem.resource.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.center"></td>
                                    <td class="text-right">{{appointmentTypeDefinitionItem.center.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.duration"></td>
                                    <td class="text-right">{{appointmentTypeDefinitionItem.duration}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.quantity"></td>
                                    <td class="text-right">{{appointmentTypeDefinitionItem.quantity}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.consumesPlannedCapacity"></td>
                                    <td class="text-right">
                                        <span
                                            *ngIf="appointmentTypeDefinitionItem.appointmentType.consumesPlannedCapacity"
                                            translate="label.yes"></span>
                                        <span
                                            *ngIf="!appointmentTypeDefinitionItem.appointmentType.consumesPlannedCapacity"
                                            translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3">
                        <!--appointment type-->
                        <div class="form-group customSelect">
                            <label translate="label.appointmentType"></label>
                            <div [ngClass]="{'is-overcapacity':isOvercapacity() && screenTemplateLayout.action!==constants.VIEW }"
                                 class="sbase-input-element">
                                <sbase-filter (selectedItemsOutput)="onSelectedAppointmentType($event)"
                                              [buttonName]=appointmentTypeButtonName
                                              [dependentFilters]="mainDependentFilters.appointmentType"
                                              [initialFilterValues]="initialAppointmentTypeValues"
                                              [isMultipleSelectedItem]="false"
                                              [useSelectedValueAsLabel]="true"
                                              [hideSelectedItems]="true"
                                              [providerInstance]="appointmentTypeProvider"
                                              [ngClass]="{'input-element-has-error':
                                        appointmentTypeDefinitionItem.appointmentType === undefined
                                         && screenTemplateLayout.action !== constants.VIEW}">
                                </sbase-filter>

                                <span *ngIf="isOvercapacity() && screenTemplateLayout.action !== constants.VIEW"
                                      class="fa fa-exclamation-triangle overcapacity-warning-triangle"
                                      ngbTooltip="{{'label.warning.overcapacityAppointmentType'| translate}}"
                                      placement="right"></span>
                            </div>


                            <div *ngIf="appointmentTypeDefinitionItem.appointmentType === undefined
                             && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                          <span
                              [ngClass]="{'input-element-has-error': appointmentTypeDefinitionItem.appointmentType === undefined}"
                              translate="label.error.required">
                          </span>
                            </div>
                        </div>

                        <!--resource-->
                        <div class="form-group customSelect">
                            <label translate="label.resource"></label>
                            <div class="sbase-input-element">
                                <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                              [buttonName]=resourceButtonName
                                              [dependentFilters]="mainDependentFilters.resource"
                                              [initialFilterValues]="initialResourceValues"
                                              [isMultipleSelectedItem]="false"
                                              [useSelectedValueAsLabel]="true"
                                              [hideSelectedItems]="true"
                                              [providerInstance]="resourceProvider"
                                              [ngClass]="{'input-element-has-error':
                                          appointmentTypeDefinitionItem.resource === undefined
                                          && screenTemplateLayout.action !== constants.VIEW}">
                                </sbase-filter>
                            </div>
                            <div
                                *ngIf="appointmentTypeDefinitionItem.resource === undefined && screenTemplateLayout.action !== constants.VIEW"
                                class="help-block">
                            <span
                                [ngClass]="{'input-element-has-error': appointmentTypeDefinitionItem.resource === undefined}"
                                translate="label.error.required">
                            </span>
                            </div>
                        </div>

                        <!--quantity-->
                        <div class="form-group">
                            <label translate="label.quantity"></label>
                            <input type="number" class="input-element"
                                   placeholder="{{'label.quantity'|translate}}"
                                   name="quantity" #quantity="ngModel" min="1"
                                   [(ngModel)]="appointmentTypeDefinitionItem.quantity" [pattern]="RegexWholeNumber"
                                   (ngModelChange)="onQuantityChanged(appointmentTypeDefinitionItem)"
                                   [ngClass]="{'input-element-has-error': !appointmentTypeDefinitionItem.quantity}"
                                   required
                                   autocomplete="off">
                            <div *ngIf="screenTemplateLayout.action !== constants.VIEW">
                                <div
                                    *ngIf="appointmentTypeDefinitionItem.quantity === undefined || appointmentTypeDefinitionItem.quantity === null"
                                    class="help-block">
                                        <span
                                            [ngClass]="{'input-element-has-error': appointmentTypeDefinitionItem.quantity === undefined || appointmentTypeDefinitionItem.quantity === null}"
                                            translate="label.error.required">
                                        </span>
                                </div>
                                <div *ngIf="quantity.errors?.pattern" class="help-block">
                                    <span class="inValidPosition" translate="label.invalidField"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <!--center-->
                        <div class="form-group customSelect">
                            <label translate="label.center2"></label>
                            <div>
                                <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                              [buttonName]=centerButtonName
                                              [dependentFilters]="mainDependentFilters.location"
                                              [initialFilterValues]="initialCenterValues"
                                              [isMultipleSelectedItem]="false"
                                              [hideSelectedItems]="true"
                                              [providerInstance]="centerProvider"
                                              [useSelectedValueAsLabel]="true"
                                              class="sbase-dropdown-ml">
                                </sbase-filter>
                            </div>
                        </div>

                        <!--duration-->
                        <div class="form-group">
                            <label translate="label.duration"></label>
                            <input type="number" class="input-element"
                                   placeholder="{{'label.duration'|translate}}"
                                   style="font-weight: 500"
                                   name="duration" #duration="ngModel"
                                   min="1"
                                   [(ngModel)]="appointmentTypeDefinitionItem.duration" [pattern]="RegexWholeNumber"
                                   (ngModelChange)="onDurationChanged(appointmentTypeDefinitionItem)"
                                   required
                                   autocomplete="off">
                            <div *ngIf="duration.errors?.pattern
                                     && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                                <span class="inValidPosition" translate="label.invalidField"></span>
                            </div>
                        </div>

                        <!--ConsumesPlannedCapacity-->
                        <div class="form-group" [ngClass]="{'disabled-input': true}">
                            <label translate="label.consumesPlannedCapacity"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked name="consumesPlannedCapacity"
                                           [(ngModel)]="appointmentTypeDefinitionItem.appointmentType !== undefined && appointmentTypeDefinitionItem.appointmentType.consumesPlannedCapacity">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <button class="btn mr-3 "
                (click)="goToParentPage()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                translate="{{screenTemplateLayout.cancelOrBackButton}}">
        </button>
        <button class="btn theme-btn"
                (click)="saveAppointmentTypeDefinitionData(appointmentTypeDefinitionItem)"
                [hidden]="screenTemplateLayout.action === constants.VIEW"
                [disabled]="!isTemplateValid"
                translate="{{screenTemplateLayout.createOrSaveButton}}">
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'AppointmentTypeDefinitionUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
