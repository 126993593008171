import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    CoveragePlanType,
    CoveragePlanProvider,
    CoverageCompanyType,
    CoverageCompanyProvider,
    ChannelProvider,
    ServiceProvider,
    SpecialityProvider,
    ServiceType,
    CoveragePlanTypeEnum,
    ConfirmDeleteModalService,
    TagProvider,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagType,
    ObjectTagType,
} from 'sked-base';
import {ScreenTemplateLayoutType, TableFiltersType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CoveragePlanMdUtils} from '../coverage-plan-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {concatMap, map, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {
    CoveragePlanMdServiceType,
    CoveragePlanMDType,
    CoveragePlanMdValidationType
} from '../coverage-plan-md.types';
import {MatStepper} from '@angular/material/stepper';
import {forkJoin, Observable, of} from 'rxjs';
import {BackofficeChannelType} from '../../../data-model/channel.types';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';
import {SpecialitySearchType} from 'sked-base/lib/data-model/specialityTypes';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TranslatedLanguageService} from 'src/app/shared/services/translated-language.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-coverage-plan',
    templateUrl: './create-coverage-plan.component.html',
    styleUrls: ['./create-coverage-plan.component.scss']
})
export class CreateCoveragePlanComponent implements OnInit, AfterViewInit, OnDestroy {
    constants = constants;

    coverageCompanies: CoverageCompanyType[] = [];
    coveragePlanTypes = Object.values(CoveragePlanTypeEnum);
    channels: BackofficeChannelType[] = [];
    services: CoveragePlanMdServiceType[] = [];
    selectedServices: CoveragePlanMdServiceType[] = [];
    initialServiceValues: any[] = [];
    initialSpecialityValues: SpecialitySearchType[] = [];
    servicesPerPageList: number[] = [10, 25, 50, 100];

    initialCoveragePlan: CoveragePlanMDType;
    coveragePlanItem: CoveragePlanMDType = {} as CoveragePlanMDType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    screenTemplateValidator: CoveragePlanMdValidationType;
    speciality: IdNameType;
    serviceTableFilters: TableFiltersType;
    mainDependentFilters: any;
    coverageCompanyPlaceholder = 'label.coverageCompany2';

    serviceButtonName = 'label.serviceSearch';
    specialityButtonName = 'label.specialitySearch';
    totalStepsCount: number;
    totalServices: number;
    numberOfSelectedServices = 0;
    selectAll = false;
    showOnlyAssigned = false;
    showItemsPerPageDropdown = false;
    systemTag = {} as ObjectTagType;

    tags: TagType[] | ObjectTagType[] = [];

    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;

    constructor(
        public channelProvider: ChannelProvider,
        public coverageCompanyProvider: CoverageCompanyProvider,
        public coveragePlanMdUtils: CoveragePlanMdUtils,
        public coveragePlanProvider: CoveragePlanProvider,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public serviceProvider: ServiceProvider,
        public specialityProvider: SpecialityProvider,
        public tagProvider: TagProvider,
        private confirmDeleteService: ConfirmDeleteModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private searchFilterUtils: SearchFilterUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.serviceTableFilters = this.coveragePlanMdUtils.getInitialTableFilter();
        this.loadInitialData();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    saveCoveragePlanData(coveragePlan: CoveragePlanMDType) {
        const isFormValid = this.areStepsValid(this.screenTemplateValidator, 0);
        coveragePlan.tags = this.tags.map(tag => {
            if (tag.tagId) {
                return tag;
            }
            return {
                tagId: tag.id,
            } as ObjectTagType;
        });
        if (isFormValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createCoveragePlan(this.coveragePlanMdUtils.mapCoveragePlanForServer(coveragePlan));
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialCoveragePlan, coveragePlan)) {
                    this.messagesService.success('toastr.success.coveragePlanEdit', true);
                    this.goToParentPage();
                } else {
                    this.editCoveragePlan(this.initialCoveragePlan, coveragePlan);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/coveragePlans']);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        stepper.next();
    }

    onStepChange(step) {
        if (step && step.previouslySelectedIndex === 1) {
            this.coveragePlanItem.services = this.coveragePlanMdUtils.mapSelectedServicesToCoveragePlanServices(this.selectedServices);
        }
        if (step.selectedIndex === 0 || step.selectedIndex === 3) {
            this.setTags(this.tags as ObjectTagType[], step.selectedIndex);
        }
    }

    areStepsValid(validTemplate: CoveragePlanMdValidationType, currentStep): boolean {
        switch (currentStep) {
            case 0:
                return (validTemplate.isNameValid && validTemplate.isCoverageCompanyValid && validTemplate.isPriorityValid);
            default:
                return true; // other steps which don't need validation
        }
    }

    isStepperLinear(validTemplate: CoveragePlanMdValidationType) {
        for (const property in validTemplate) {
            if (validTemplate[property] === false) {
                return true;
            }
        }
        return false;
    }

    displayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    onCoveragePlanValueChanged(coveragePlanItem: CoveragePlanMDType) {
        this.validateFields(coveragePlanItem);
    }

    onCoverageCompanySearch() {
        this.coverageCompanyPlaceholder = '';
    }

    onCoverageCompanyBlur() {
        this.coverageCompanyPlaceholder = this.coveragePlanItem?.coverageCompany?.id ||
        this.generalUtils.isSelectedNoValueOption(this.coveragePlanItem?.coverageCompany) ? '' : 'label.coverageCompany2';
    }

    onChannelExclusionsChanged(channel: BackofficeChannelType) {
        if (channel.selected) {
            this.coveragePlanItem.channelRestrictions.push(channel);
        } else {
            lodash.remove(this.coveragePlanItem.channelRestrictions, {enumValue: channel.enumValue});
        }
    }

    onAddExternalKeyItem() {
        this.coveragePlanItem.coveragePlanExternalKeys.push(this.coveragePlanMdUtils.getEmptyExternalKeyItem());
    }

    onRemoveExternalKeyItem(index: number) {
        this.coveragePlanItem.coveragePlanExternalKeys.splice(index, 1);
    }

    onSelectedSpecialityFilter(specialityFilterList: SpecialitySearchType[]): void {
        this.initialSpecialityValues = specialityFilterList;
        if (specialityFilterList.length > 0) {
            this.serviceTableFilters.filter.speciality = specialityFilterList[0];
        } else {
            this.serviceTableFilters.filter.speciality = undefined;
        }
        this.fetchServicesForTable(this.serviceTableFilters);
    }

    onSelectedServiceFilter(serviceFilterList): void {
        this.initialServiceValues = serviceFilterList;
        if (serviceFilterList.length > 0) {
            this.serviceTableFilters.filter.service = serviceFilterList[0];
        } else {
            this.serviceTableFilters.filter.service = undefined;
        }
        this.fetchServicesForTable(this.serviceTableFilters);
    }

    onChangeTags(tags: TagType[]) {
        this.tags = tags;
    }

    onSelectAll(selectAll: boolean) {
        if (selectAll) {
            this.services = this.generalUtils.addSelectedValueTrueToObjectList(this.services);
            this.selectedServices = lodash.uniqBy(lodash.concat(this.selectedServices, this.services), 'id');
        } else if (this.screenTemplateLayout.action === constants.EDIT) { // deselect all in edit
            const servicesIds = this.services.map(service => service.id);
            this.confirmDeleteService.displayConfirmDeleteModal(this.coveragePlanProvider, this.coveragePlanItem.id,
                'Services', servicesIds, false).result.then(() => {
            }, (response) => {
                if (response) {
                    this.services = this.generalUtils.addSelectedValueFalseToObjectList(this.services);
                    this.selectedServices = lodash.pullAllBy(this.selectedServices, this.services, 'id');
                }
            });
        } else { // deselect all in create
            this.services = this.generalUtils.addSelectedValueFalseToObjectList(this.services);
            this.selectedServices = lodash.pullAllBy(this.selectedServices, this.services, 'id');
        }
        this.numberOfSelectedServices = this.selectedServices.length;
    }

    onSelectSingle(service: CoveragePlanMdServiceType, event: any) {
        if (!service.selected) {
            this.selectedServices.push(service);
            service.selected = !service.selected;
        } else if (this.screenTemplateLayout.action === constants.EDIT) { // remove in edit
            event.preventDefault();
            this.confirmDeleteService.displayConfirmDeleteModal(this.coveragePlanProvider, this.coveragePlanItem.id,
                'Services', service.id, false).result.then(() => {
            }, (response) => {
                if (response) {
                    service.selected = !service.selected;
                    lodash.remove(this.selectedServices, {id: service.id});
                }
            });
        } else { // remove in create
            service.selected = !service.selected;
            lodash.remove(this.selectedServices, {id: service.id});
        }

        this.selectedServices = lodash.uniqBy(this.selectedServices, 'id');
        this.numberOfSelectedServices = this.selectedServices.length;
    }

    onChangePagination(page: number) {
        this.selectAll = false;
        this.serviceTableFilters.currentPage = page;

        this.fetchServicesForTable(this.serviceTableFilters, false);
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.serviceTableFilters.currentPage = 1;
        this.serviceTableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.selectAll = false;

        this.fetchServicesForTable(this.serviceTableFilters, false);
    }

    onShowOnlyAssignedServices(showOnlyAssigned: boolean) {
        this.serviceTableFilters.filter.showOnlyAssigned = showOnlyAssigned;
        this.serviceTableFilters.currentPage = 1;

        this.fetchServicesForTable(this.serviceTableFilters);
    }

    goToEdit() {
        history.replaceState({
            coveragePlan: this.coveragePlanItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private loadInitialData(): void {
        const self = this;
        self.ngxLoader.start();
        forkJoin([
            self.coverageCompanyProvider.getEntries(this.coveragePlanMdUtils.getQueryForIdNameRequest()),
            history?.state?.action === constants.EDIT || history?.state?.action === constants.VIEW
                ? this.tagProvider.getTagsAssignedToEntity({
                    entityType: 'CoveragePlan',
                    entityId: history.state?.coveragePlan?.id
                })
                : of(null),
            self.channelProvider.getEntries(this.translatedLanguageService.translatedLanguage),
            self.serviceProvider.getEntries(this.coveragePlanMdUtils.getQueryForServicesRequest(this.serviceTableFilters)),
            self.getSelectedServicesObservable(self.coveragePlanItem)])
            .pipe(
                take(1),
                concatMap(([coverageCompanies, tags, channels, services, selectedServices]) => {
                    const moreSelectedServicesParts = selectedServices.count > 100 ? self.getAllAssignedServicesObservables(selectedServices.count,
                        self.coveragePlanItem) : [];
                    const allSelectedServicesParts = [of(selectedServices), ...moreSelectedServicesParts];
                    return forkJoin([of(coverageCompanies), of(tags), of(channels), of(services), ...allSelectedServicesParts]).pipe(take(1));
                }),
                map(([coverageCompanies, tags, channels, services, ...allSelectedServicesParts]) => {
                    const allSelectedServicesValues = lodash.flatten(lodash.map(allSelectedServicesParts,
                        selectedServicesPart => self.generalUtils.addSelectedValueTrueToObjectList(selectedServicesPart.value)));
                    const allSelectedServices = {
                        count: allSelectedServicesParts[0].count,
                        value: allSelectedServicesValues
                    };
                    return [coverageCompanies, tags, channels, services, allSelectedServices];
                })
            )
            .subscribe(([coverageCompanies, tags, channels, services, selectedServices]) => {
                self.coverageCompanies = lodash.orderBy(coverageCompanies.value, ['name']);
                self.channels = self.generalUtils.addSelectedValueFalseToObjectList(channels.value);
                self.channels = self.coveragePlanMdUtils.matchAssignedChannels(this.channels, this.coveragePlanItem.channelRestrictions);
                self.onServicesForCoveragePlanResponse(services, self.coveragePlanItem.services);
                this.selectedServices = selectedServices.value;
                if (!!tags) {
                    const systemTag = tags?.value.find(tag => tag.system);
                    this.systemTag = {...systemTag, tagId: systemTag?.id};
                    this.initialCoveragePlan.tags = lodash.cloneDeep(tags?.value.map(tag => ({...tag, tagId: tag.id})) ?? []);
                    this.setTags(this.initialCoveragePlan.tags);
                }
                if (self.screenTemplateLayout.action !== constants.CREATE) {
                    if (self.initialCoveragePlan.coverageCompany && self.initialCoveragePlan.coverageCompany.id) {
                        self.coveragePlanItem.coverageCompany = lodash.find(self.coverageCompanies,
                            {id: self.initialCoveragePlan.coverageCompany.id});
                    }
                }
            }, err => {
                self.ngxLoader.stop();
                self.messagesService.handlingErrorMessage(err);
            }, () => {
                self.ngxLoader.stop();
            });
    }

    private setTags(tags: ObjectTagType[], step?: number) {
        const tagsWithSystem = !!this.systemTag?.tagId ? lodash.uniqBy([...tags, this.systemTag], 'id') : tags;
        const tagsExceptSystem = tags.filter(tag => tag.tagId !== this.systemTag?.tagId);
        if (!this.generalUtils.isNullOrUndefined(step)) {
            this.tags = step === 0 ? tagsExceptSystem : tagsWithSystem;
        } else {
            this.tags = this.screenTemplateLayout.action === constants.EDIT ? tagsExceptSystem : tagsWithSystem;
        }
        this.coveragePlanItem.tags = lodash.cloneDeep(this.tags);
    }

    // returns an array of observables, one item is a request of 100 services; this array is then used in a forkjoin
    private getAllAssignedServicesObservables(selectedServicesCount: number, coveragePlanItem: CoveragePlanMDType):
        Observable<{ value: ServiceType[], count?: number }>[] {
        let skip = 0;
        const obsArray = [];
        for (let index = 1; index < lodash.ceil(selectedServicesCount / 100); index++) {
            skip = skip + 100;
            obsArray.push(this.serviceProvider.getEntries(this.coveragePlanMdUtils.getQueryForSelectedServicesRequest(coveragePlanItem,
                skip, false)));
        }
        return obsArray;
    }

    // function to create the new CoveragePlan
    private createCoveragePlan(coveragePlan: CoveragePlanType) {
        this.ngxLoader.start();
        this.coveragePlanProvider.createObjectWithTags(coveragePlan)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newCoveragePlanAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the CoveragePlan
    private editCoveragePlan(oldCoveragePlan: CoveragePlanMDType, newCoveragePlan: CoveragePlanMDType) {
        this.ngxLoader.start();
        const oldCoveragePlanToSend: CoveragePlanType = this.coveragePlanMdUtils.mapCoveragePlanForServer(oldCoveragePlan);
        const newCoveragePlanToSend: CoveragePlanType = this.coveragePlanMdUtils.mapCoveragePlanForServer(newCoveragePlan);
        this.coveragePlanProvider.editObjectWithTags(oldCoveragePlanToSend, newCoveragePlanToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.coveragePlanEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(coveragePlanItem: CoveragePlanMDType): void {
        this.screenTemplateValidator.isNameValid = !!(coveragePlanItem && coveragePlanItem.name);
        this.screenTemplateValidator.isCoverageCompanyValid = !!(coveragePlanItem && coveragePlanItem.coverageCompany &&
            coveragePlanItem.coverageCompany.id);
        this.screenTemplateValidator.isPriorityValid = !!(coveragePlanItem && coveragePlanItem.priority &&
            coveragePlanItem.priority > 0 && coveragePlanItem.priority <= 200);
    }

    private setupInitialState() {
        if (history.state && history.state.coveragePlan) {
            this.initialCoveragePlan = history.state.coveragePlan;
            this.initialCoveragePlan.channelRestrictions =
                this.initialCoveragePlan.channelRestrictions.map((channel) => ({
                    ...channel,
                    enumValue: channel.channel
                }));
            this.coveragePlanItem = lodash.cloneDeep(history.state.coveragePlan);
            this.coveragePlanItem.type = CoveragePlanTypeEnum[lodash.lowerFirst(this.initialCoveragePlan.type)];
            this.coverageCompanyPlaceholder = '';
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.initialCoveragePlan = this.coveragePlanMdUtils.getInitialCoveragePlan();
            this.coveragePlanItem = this.coveragePlanMdUtils.getInitialCoveragePlan();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }

        this.mainDependentFilters = {
            service: this.coveragePlanMdUtils.getServiceDependentFilters(),
            speciality: this.coveragePlanMdUtils.getSpecialityDependentFilters(),
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedCoveragePlan, true)
        };

        this.screenTemplateValidator = this.coveragePlanMdUtils.getInitialScreenTemplateValidator();
        this.coveragePlanTypes = this.coveragePlanMdUtils.getCoveragePlanTypes(this.coveragePlanTypes, this.coveragePlanItem.isPrivate);
        this.validateFields(this.coveragePlanItem);
    }

    private fetchServicesForTable(serviceTableFilters: TableFiltersType, includeCount: boolean = true) {
        this.ngxLoader.start();
        this.getServicesObservable(serviceTableFilters, includeCount)
            .pipe(
                take(1)
            )
            .subscribe((services) => {
                this.onServicesForCoveragePlanResponse(services, this.selectedServices);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private getServicesObservable(serviceTableFilters, includeCount: boolean = true): Observable<{ value: ServiceType[], count?: number }> {
        if (this.showOnlyAssigned) {
            return of(this.coveragePlanMdUtils.getOnlyAssignedServices(serviceTableFilters, this.selectedServices));
        } else {
            return this.serviceProvider.getEntries(this.coveragePlanMdUtils.getQueryForServicesRequest(serviceTableFilters, includeCount));
        }
    }

    private getSelectedServicesObservable(coveragePlanItem: CoveragePlanMDType): Observable<{ value: ServiceType[], count?: number }> {
        if (this.screenTemplateLayout.action === constants.CREATE) {
            return of({value: []});
        } else {
            return this.serviceProvider.getEntries(this.coveragePlanMdUtils.getQueryForSelectedServicesRequest(coveragePlanItem));
        }
    }

    private onServicesForCoveragePlanResponse(services, selectedServices) {
        if (services.count !== undefined && services.count !== null) {
            this.totalServices = services.count;
        }
        this.services = this.generalUtils.addSelectedValueFalseToObjectList(services.value);
        this.services = this.coveragePlanMdUtils.matchAssignedServices(this.services, selectedServices);
        this.numberOfSelectedServices = selectedServices.length;
    }
}
