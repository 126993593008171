import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {TaskListUtils} from '../../../features/task-list/task-list-util';
import {AppointmentCardEmittedActionType, AppointmentCardOptionsType, AppointmentResourcesType} from './appointment-card.types';
import {
    AppointmentActionButtonsOptionsType, AppointmentActionEnum
} from '../appointment-action-buttons/appointment-action-buttons.types';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';
import {AppointmentSubServiceType, CenterType, ResourceType, ServiceType} from 'sked-base';
import {SubServiceType} from 'sked-base/lib/data-model/subServiceTypes';
import {ResourceAppointmentType} from 'sked-base/lib/data-model/resourceTypes';
import {AppointmentStatusEnum} from 'sked-base';

@Component({
    selector: 'app-appointment-card',
    templateUrl: './appointment-card.component.html',
    styleUrls: ['./appointment-card.component.scss']
})
export class AppointmentCardComponent implements OnInit {
    @Input() options: AppointmentCardOptionsType = {} as AppointmentCardOptionsType;
    @Output() appointmentCardAction = new EventEmitter<AppointmentCardEmittedActionType>();

    patientAppointmentResources = {} as AppointmentResourcesType;
    isDateTimeFromInDifferentTimeZone: boolean;
    appointmentActionButtonsOptions: AppointmentActionButtonsOptionsType;
    objectDetailsOptions: ObjectDetailsOptionsType;
    appointmentStatusIconClass: string;
    loaderId: string;
    cancelReason: {name: string, others?: string};

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private router: Router,
        private taskListUtils: TaskListUtils
    ) {
    }

    ngOnInit(): void {
        this.isDateTimeFromInDifferentTimeZone =
            moment.parseZone().utcOffset() === moment.parseZone(this.options?.appointment?.dateTimeFrom).utcOffset();
        if (this.options?.appointment) {
            this.loaderId = `appointment-card-${this.options.appointment?.id}`;
            this.appointmentActionButtonsOptions = {
                appointmentId: this.options.appointment.id,
                status: this.options.appointment.status,
                appointmentAllowedStatuses: this.options.appointment.appointmentAllowedStatuses,
                cancelled: this.options.appointment.cancelled,
                appointmentDate: this.options.appointment.dateTimeFrom,
                etag: this.options.appointment.etag,
                paid: this.options.appointment.paid,
                appointment: this.options.appointment,
                onActionLoaderId: this.loaderId,
            };
        }
        if (this.options?.appointment?.resourceAppointments?.length > 0) {
            this.mapPatientAppointmentResources();
        }
        this.appointmentStatusIconClass = this.getAppointmentStatusIconClass(this.options?.appointment?.status);
        this.loadObjectDetailsOptions();
        this.loadCancelReasons();
    }

    onViewSkedTaskDetails(): void {
        this.taskListUtils.taskItemDetailsOptions.taskItem = this.options.appointment.skedTasks[0];
        // Go to details screen
        if (!!this.taskListUtils?.taskItemDetailsOptions?.uploadsViewerOptions) {
            this.taskListUtils.taskItemDetailsOptions.uploadsViewerOptions = undefined;
        }
        this.router.navigate(['/taskDetails'], {
            state: {
                redirectRoute: this.router.url,
            }
        });
    }

    onAction({action, data}: AppointmentCardEmittedActionType) {
        switch (action) {
            case AppointmentActionEnum.Cancel: {
                // Send the action directly to the parent
                this.appointmentCardAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Reuse: {
                // Reuse is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only send the action to the parent
                this.appointmentCardAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only send the action to the parent
                this.appointmentCardAction.next({action, data});
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                // This action is done entirely in appointment-send-email-button component
                return;
            }
        }
    }

    private mapPatientAppointmentResources(): void {
        // @ts-ignore
        const appointmentResourceTypes = this.options?.appointment?.appointmentResourceTypes ?? [];
        this.patientAppointmentResources.mainResource = this.options?.appointment?.resourceAppointments
            .filter(resource => resource.mainResource)
            .map((resource: ResourceAppointmentType) => ({
                ...resource,
                resourceTypeName: resource?.resource?.resourceTypeId
                    ? (lodash.find(appointmentResourceTypes, {resourceTypeId: resource.resource.resourceTypeId})?.resourceTypeName ?? null)
                    : null,
            }))[0];
        this.patientAppointmentResources.secondaryResources = this.options?.appointment?.resourceAppointments
            .filter(resource => !resource.mainResource)
            .map((resource: ResourceAppointmentType) => ({
                ...resource,
                resourceTypeName: resource?.resource?.resourceTypeId
                    ? (lodash.find(appointmentResourceTypes, {resourceTypeId: resource.resource.resourceTypeId})?.resourceTypeName ?? null)
                    : null,
            }));
    }

    private loadObjectDetailsOptions(): void {
        this.objectDetailsOptions = {
            messagesService: this.messagesService,
            displayBackButton: false,
            hideButtons: true,
            service: {
                ...this.options?.appointment?.service,
                id: this.options?.appointment?.serviceId,
            } as ServiceType,
            center: {
                ...this.options?.appointment?.center,
                id: this.options?.appointment?.centerId,
            } as CenterType,
            resource: {
                ...this.options?.appointment?.resource,
                id: this.options?.appointment?.resourceId,
            } as ResourceType,
            subServices: this.options?.appointment?.subServices?.map(
                (appointmentSubService: AppointmentSubServiceType) => appointmentSubService.subService
            ) as SubServiceType[],
        } as ObjectDetailsOptionsType;
    }

    private getAppointmentStatusIconClass(status: AppointmentStatusEnum): string {
        switch (status) {
            case Object.keys(AppointmentStatusEnum)[0]: //Booked
                return 'fa-regular fa-clipboard-medical';
            case Object.keys(AppointmentStatusEnum)[1]: //Confirmed
                return 'far fa-calendar-check';
            case Object.keys(AppointmentStatusEnum)[3]: //CheckedIn
                return 'fas fa-check';
            case Object.keys(AppointmentStatusEnum)[4]: //ServicePerformed
                return 'far fa-check-circle';
            case Object.keys(AppointmentStatusEnum)[5]: //Blocked
                return 'fa-solid fa-ban';
            case Object.keys(AppointmentStatusEnum)[6]: //NotPerformed
                return 'fa-regular fa-calendar-xmark';
            default:
                return 'fas fa-check';
        }
    }

    private loadCancelReasons() {
        const cancelStatusHistory = lodash.find(
            this.options.appointment?.statusHistories ?? [],
            {status: 'Cancelled'}
        );
        const cancelTransitionReason = cancelStatusHistory?.appointmentStatusTransitionReason;
        if (!cancelTransitionReason) {
            return;
        }
        this.cancelReason = {
            name: cancelTransitionReason?.name,
            others: !!cancelTransitionReason?.others ? cancelStatusHistory?.transitionReasonOthers : undefined,
        }
    }
}
