import {Component, EventEmitter, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    DateRangeResponseType,
    DateRangeType,
    FormValidationType,
    JobProvider
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../../shared/services/messages.service';
import {ExportTaskListUtils} from '../export-task-list-utils';

@Component({
    selector: 'app-export-task-list-modal',
    templateUrl: './export-task-list-modal.component.html',
    styleUrls: ['./export-task-list-modal.component.scss']
})
export class ExportTaskListModalComponent implements OnInit {
    dateRangeValidation: FormValidationType;
    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();

    constructor(public exportTaskListUtils: ExportTaskListUtils,
                private activeModal: NgbActiveModal,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private jobProvider: JobProvider) {
    }

    ngOnInit(): void {
        this.loadOptionsForDateRange();
    }

    closeModal() {
        this.activeModal.dismiss('close');
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.exportTaskListUtils.dateRangeOptions.fromDate = dateRange.fromDate;
        this.exportTaskListUtils.dateRangeOptions.toDate = dateRange.toDate;
        this.validateDateRange(dateRange.isValid);
    }

    onGenerateExportTaskList() {
        const queryFilter = this.exportTaskListUtils.getQueryFilterForExportTaskList();
        this.ngxLoader.start();
        this.jobProvider.getGenerateExportSkedTask(queryFilter).subscribe(() => {
            this.messagesService.success('label.generateExportWaitMessageGoToExports');
            this.closeModal();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private loadOptionsForDateRange() {
        this.exportTaskListUtils.dateRangeOptions = this.exportTaskListUtils.getInitialDateRangeOptions();
        const {fromDate, toDate} = this.exportTaskListUtils.dateRangeOptions;
        this.updateDateRangeValue.next({
            fromDate,
            toDate
        } as DateRangeType);
        this.validateDateRange();
    }

    private validateDateRange(isValidInner: boolean = true) {
        const {fromDate, toDate} = this.exportTaskListUtils.dateRangeOptions;
        this.dateRangeValidation = {
            isValid: isValidInner && !!fromDate && !!toDate,
            errorMessage: ''
        };
    }
}
