<div *ngIf="parentFormGroup"
     [formGroup]="parentFormGroup"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', minmax(0, 1fr))' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'"
     class="dynamic-form-input-wrapper">
    <div *ngFor="let group of options?.groups"
         [ngClass]="group?.containerStyles?.customClass ? group?.containerStyles?.customClass : 'form-container'"
         [style.grid-template-columns]="group?.containerStyles?.numberOfColumns ? 'repeat(' + group?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
         [style.grid-template-rows]="group?.containerStyles?.numberOfRows ? 'repeat(' + group?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
         [style.grid-column-gap]="group?.containerStyles?.columnsGap ? group?.containerStyles?.columnsGap : '0'"
         [style.grid-row-gap]="group?.containerStyles?.rowsGap ? group?.containerStyles?.rowsGap : '0'"
         class="dynamic-form-input-wrapper">
        <ng-container *ngFor="let input of group.inputs">
            <ng-container [ngSwitch]="input.template">
                <div class="dynamic-input-container" [ngStyle]="input?.styles" *ngIf="input?.visible">
                    <label for="{{input.formControlName}}"
                           *ngIf="input.template !== dynamicFormInputTemplateEnum.Checkbox">
                        <span>{{!input.labelText ? '&nbsp;' : input.labelText | translate}}</span>
                        <span [style.visibility]="input?.required && input.labelText ? 'visible' : 'hidden'"
                              class="input-required-asterisk">*</span>
                    </label>
                    <!-- TEXT -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Text">
                        <input type="text"
                               class="dynamic-input input-element"
                               [id]="input.formControlName"
                               [placeholder]="input.placeholder | translate"
                               [formControlName]="input.formControlName"
                               [ngClass]="{
                               'input-element-has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName),
                               'cursor-not-allowed is-disabled': parentFormGroup?.controls[input.formControlName]?.disabled
                               }">
                    </ng-template>
                    <!-- NUMBER -->
                    <!-- chromium has a bug that enable credit card autocomplete on if the input contains the word numero in placeholder or label -->
                    <!-- To solve this we use autocomplete="organization" -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Number">
                        <input type="number"
                               class="dynamic-input input-element"
                               [id]="input.formControlName"
                               [placeholder]="input.placeholder | translate"
                               autocomplete="organization"
                               [formControlName]="input.formControlName"
                               [ngClass]="{
                               'input-element-has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName),
                               'cursor-not-allowed is-disabled': parentFormGroup?.controls[input.formControlName]?.disabled
                               }">
                    </ng-template>
                    <!-- SELECT -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Select">
                        <ng-select [id]="input.formControlName"
                                   appendTo="body"
                                   [formControlName]="input.formControlName"
                                   class="custom-ng-select input-element"
                                   loadingText="{{'label.loading' | translate}}"
                                   dropdownPosition="bottom"
                                   [multiple]="false"
                                   [searchable]="false"
                                   [clearable]="false"
                                   [ngClass]="{
                                   'input-element-has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName),
                                   'cursor-not-allowed is-disabled': parentFormGroup?.controls[input.formControlName]?.disabled
                                   }">
                            <ng-option value="" *ngIf="input.placeholder">
                                <span>{{input.placeholder | translate}}</span>
                            </ng-option>
                            <ng-option [value]="data.value" *ngFor="let data of input?.selectInputData">
                                <span *ngIf="data.shouldTranslate" [translate]="data.text"></span>
                                <span *ngIf="!data.shouldTranslate">{{data.text}}</span>
                            </ng-option>
                        </ng-select>
                    </ng-template>
                    <!-- DATE -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Date">
                        <div class="dynamic-input dynamic-input-datepicker"
                             [ngClass]="{'has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName)}"
                             [ngbPopover]="date"
                             placement="bottom"
                             popoverClass="popover-shadow"
                             triggers="mouseenter:mouseleave">
                            <dp-date-picker theme="dp-material"
                                            #datePicker
                                            [config]="input?.datePickerConfig"
                                            [formControlName]="input.formControlName"
                                            [id]="input.formControlName"
                                            [placeholder]="input?.datePickerConfig?.format"
                                            [displayDate]="initialValue"
                                            (close)="onDatePickerClose(datePicker, input.formControlName)">
                            </dp-date-picker>
                            <i class="fa-regular fa-calendar text-dark cursor-pointer date-picker-input-calendar-icon"
                               (click)="openDatePicker(datePicker)"
                               [ngClass]="{'has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName)}"></i>
                            <ng-template #date>
                                    <span class="p-1">
                                        {{'tooltip.dateFormat' | translate}} {{input?.datePickerConfig?.format}}
                                    </span>
                            </ng-template>
                        </div>
                    </ng-template>
                    <!-- EMAIL -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Email">
                        <input type="email"
                               class="dynamic-input input-element"
                               [id]="input.formControlName"
                               [placeholder]="input.placeholder | translate"
                               [formControlName]="input.formControlName"
                               [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName)}"/>
                    </ng-template>
                    <!-- CHECKBOX -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Checkbox">
                        <div class="dynamic-checkbox-container">
                            <input type="checkbox"
                                   [formControlName]="input.formControlName"
                                   [id]="input.formControlName">
                            <label for="{{input.formControlName}}">{{input.labelText | translate}}</label>
                        </div>
                    </ng-template>
                    <!-- TEXTAREA -->
                    <ng-template [ngSwitchCase]="dynamicFormInputTemplateEnum.Textarea">
                        <div class="dynamic-textarea-container d-flex">
                            <textarea type="text" name="comments"
                                      [id]="input.formControlName"
                                      [placeholder]="input.placeholder | translate"
                                      [formControlName]="input.formControlName"
                                      autocomplete="off"
                                      [ngClass]="{
                                      'has-error': dynamicFormInputService.hasError(parentFormGroup, input.formControlName),
                                      'cursor-not-allowed is-disabled': parentFormGroup?.controls[input.formControlName]?.disabled
                                      }">
                            </textarea>
                        </div>
                    </ng-template>
                    <!-- Error message -->
                    <div>
                        <app-dynamic-form-input-error [formGroup]="parentFormGroup"
                                                      [controlName]="input?.formControlName">
                        </app-dynamic-form-input-error>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
