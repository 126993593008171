import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {
    CountryProvider,
    CountryType,
    PatientProvider,
} from 'sked-base';
import {TranslatedLanguageService} from '../../../../shared/services/translated-language.service';
import * as lodash from 'lodash';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../../shared/services/messages.service';
import {CreatePatientUtils} from '../create-patient.utils';
import {TenantCustomizingService} from '../../../../shared/services/tenant-customizing.service';
import {formControlNames} from 'src/app/shared/constants/formControlNames';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {debounceTime} from 'rxjs/operators';
import {constants} from '../../../../shared/constants/constants';

@Component({
    selector: 'app-client-identification-form',
    templateUrl: './client-identification-form.component.html',
    styleUrls: ['./client-identification-form.component.scss']
})
export class ClientIdentificationFormComponent implements OnInit {
    @Input() formGroupName!: string;
    @Input() options: DynamicFormOptionsType;

    allInputs: DynamicFormInputType[] = [];
    clientIdentificationForm: FormGroup;
    countryPlaceholder = 'label.addressTemplate.country';
    selectedCountry: CountryType = {} as CountryType;
    formControlNames = formControlNames;

    constructor(public dynamicFormInputService: DynamicFormInputService,
                private rootFormGroup: FormGroupDirective,
                private patientProvider: PatientProvider,
                private countryProvider: CountryProvider,
                private translatedLanguageService: TranslatedLanguageService,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                public createPatientUtils: CreatePatientUtils,
                private tenantCustomizingService: TenantCustomizingService,
    ) {
    }

    ngOnInit(): void {
        this.clientIdentificationForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.listenToNoDocumentChanges();
        this.listenToDocumentNumberChanges();
    }

    onCountryValueChanged(selectedCountry: CountryType) {
        const {value} = this.clientIdentificationForm;
        this.selectedCountry = selectedCountry ? selectedCountry : {} as CountryType;
        this.clientIdentificationForm.patchValue({
            DocumentType: this.createPatientUtils.getPatientDocumentType(selectedCountry?.countryCode),
        });
        this.countryPlaceholder = '';
        if (value[formControlNames.DOCUMENT_TYPE] !== '' && value[formControlNames.DOCUMENT_NUMBER] !== '') {
            this.createPatientUtils.verifyPatientDocumentNumberAndMPI(this.clientIdentificationForm.parent as FormGroup);
            this.clientIdentificationForm?.get(formControlNames.DOCUMENT_NUMBER).updateValueAndValidity();
        }
    }

    onDocumentTypeValueChanged() {
        const {value} = this.clientIdentificationForm;
        if (value[formControlNames.DOCUMENT_COUNTRY] && value[formControlNames.DOCUMENT_NUMBER] !== '') {
            this.createPatientUtils.verifyPatientDocumentNumberAndMPI(this.clientIdentificationForm.parent as FormGroup);
            this.clientIdentificationForm?.get(formControlNames.DOCUMENT_NUMBER).updateValueAndValidity();
        }
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private listenToDocumentNumberChanges() {
        this.clientIdentificationForm?.get(formControlNames.DOCUMENT_NUMBER).valueChanges
            .pipe(debounceTime(constants.inputDebounceTime))
            .subscribe((response) => {
                this.clientIdentificationForm?.get(formControlNames.DOCUMENT_NUMBER).patchValue(response, {emitEvent: false});
                const {value} = this.clientIdentificationForm;
                if (value[formControlNames.DOCUMENT_COUNTRY] &&
                    value[formControlNames.DOCUMENT_TYPE] !== '' &&
                    value[formControlNames.DOCUMENT_NUMBER] !== '') {
                    this.createPatientUtils.verifyPatientDocumentNumberAndMPI(this.clientIdentificationForm.parent as FormGroup);
                }
            });
    }

    private listenToNoDocumentChanges() {
        this.clientIdentificationForm?.get('NoDocument').valueChanges.subscribe(noDocumentValue => {
            // Show / Hide asterisk for required
            this.getInputByControlName('DocumentType').required = !noDocumentValue;
            this.getInputByControlName('DocumentNumber').required = !noDocumentValue;
            this.getInputByControlName('DocumentCountry').required = !noDocumentValue;

            // Remove values
            if (noDocumentValue) {
                this.clientIdentificationForm.patchValue({
                    DocumentType: '',
                    DocumentNumber: '',
                    DocumentCountry: '',
                });
            }

            // Add / Remove required validators + Enable / Disable controls
            if (noDocumentValue) {
                this.clientIdentificationForm.get('DocumentType').removeValidators(this.tenantCustomizingService.getIsRequiredValidator());
                this.clientIdentificationForm.get('DocumentType').disable();
                this.clientIdentificationForm.get('DocumentNumber').removeValidators(this.tenantCustomizingService.getIsRequiredValidator());
                this.clientIdentificationForm.get('DocumentNumber').disable();
                this.clientIdentificationForm.get('DocumentCountry').removeValidators(this.tenantCustomizingService.getIsRequiredValidator());
                this.clientIdentificationForm.get('DocumentCountry').disable();
            } else {
                // Only enable and add validators if the inputs are enabled by validationRules
                if (this.getInputByControlName('DocumentType').enabled) {
                    this.clientIdentificationForm.get('DocumentType').addValidators(this.tenantCustomizingService.getIsRequiredValidator());
                    this.clientIdentificationForm.get('DocumentType').enable();
                }
                if (this.getInputByControlName('DocumentNumber').enabled) {
                    this.clientIdentificationForm.get('DocumentNumber').addValidators(this.tenantCustomizingService.getIsRequiredValidator());
                    this.clientIdentificationForm.get('DocumentNumber').enable();
                }
                if (this.getInputByControlName('DocumentCountry').enabled) {
                    this.clientIdentificationForm.get('DocumentCountry').addValidators(this.tenantCustomizingService.getIsRequiredValidator());
                    this.clientIdentificationForm.get('DocumentCountry').enable();
                }
            }
        });

        // Trigger update
        this.clientIdentificationForm?.patchValue({
            NoDocument: this.clientIdentificationForm?.get('NoDocument').value,
        });
    }
}
