<div class="container-fluid center-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.centers"></h3>
        <!-- Area filter -->
        <div class="filters-section d-flex">
            <div class="area-filter-container">
                <sbase-filter (selectedItemsOutput)="onSelectedAreaFilter($event)"
                              [buttonName]=areaButtonName
                              [dependentFilters]="areaFilter"
                              [initialFilterValues]="centerMdUtils.initialAreaValues"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="areaProvider">
                </sbase-filter>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
        <!-- End area filter -->
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
            <span *ngFor="let key of objectKeys(centerMdUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + centerMdUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" autocomplete="off"
                       [(ngModel)]="centerMdUtils.tableFilters.filter.Name" (ngModelChange)="onSearchCenterChanged($event)">
            </div>
            <div class="ml-2">
                <button type="button" class="btn theme-alt-btn border-0"
                        [activityDisplay]="CENTER+constants.CREATE"
                        (click)="createCenter()"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
                <tr>
                    <th class="name-column cursor-pointer" (click)="onSortBy('shortId')">
                        {{'label.shortId' | translate}}
                        <button type="button" class="btn p-0" *ngIf="!centerMdUtils.tableFilters.orderBy.shortId"><i class="fas fa-sort"></i>
                        </button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.shortId === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.shortId === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                    </th>
                    <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                        {{'label.name' | translate}}
                        <button type="button" class="btn p-0" *ngIf="!centerMdUtils.tableFilters.orderBy.name"><i class="fas fa-sort"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.name === 'asc'"><i
                          class="fas fa-sort-down"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.name === 'desc'"><i
                          class="fas fa-sort-up"></i></button>
                    </th>
                    <th class="name-column" translate="label.area"></th>
                    <th class="name-column cursor-pointer" (click)="onSortBy('region')">
                        {{'label.addressTemplate.region' | translate}}
                        <button type="button" class="btn p-0" *ngIf="!centerMdUtils.tableFilters.orderBy.region"><i class="fas fa-sort"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.region === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.region === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                    </th>
                    <th class="name-column cursor-pointer" (click)="onSortBy('locality')">
                        {{'label.locality' | translate}}
                        <button type="button" class="btn p-0" *ngIf="!centerMdUtils.tableFilters.orderBy.locality"><i class="fas fa-sort"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.locality === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                        <button type="button" class="btn p-0" *ngIf="centerMdUtils.tableFilters.orderBy.locality === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                    </th>
                    <th class="name-column" translate="label.address"></th>
                    <th class="name-column" translate="label.centerTab.timeZone"></th>
                    <th class="text-center" translate="label.action"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let center of centerMdList"
                    [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                    sbaseMarkTableRowOnHover
                    (click)="viewCenter(center)">
                    <td class="id-column">{{center?.shortId}}</td>
                    <td class="name-column max-width-table-column">{{center?.name}}</td>
                    <td class="max-width-table-column">
                        <div *ngFor="let area of center?.areas| slice:0:3">
                            {{area.name}}
                        </div>

                        <ng-template #areasContent>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let area of center?.areas">{{area.name}}</div>
                            </div>
                        </ng-template>
                        <div *ngIf="center?.areas && center?.areas.length>3" (click)="$event.stopPropagation()">
                            <div [ngbPopover]="areasContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                            </div>
                        </div>
                    </td>
                    <td class="name-column">{{center?.region?.name}}</td>
                    <td class="name-column">{{center?.address?.locality}}</td>
                    <td class="name-column">{{displayCenterAddress(center?.address)}}</td>
                    <td class="name-column">{{center?.timeZoneId}}</td>
                    <td class="text-center" (click)="$event.stopPropagation()">
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.view" (click)="viewCenter(center)"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.edit" (click)="editCenter(center)"
                                          [activityDisplay]="CENTER+constants.UPDATE"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                          (click)="displayConfirmDeleteItemModal(center.id)"
                                          [activityDisplay]="CENTER+constants.DELETE"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.changeLog"
                                            (click)="displayChangeLog(center.id, EntityTypeEnum.center, center.name)"
                                            [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
      <ngb-pagination [collectionSize]="totalTableItems" [(page)]="centerMdUtils.tableFilters.currentPage" [maxSize]="5"
                      [pageSize]="centerMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                      (pageChange)="onChangePagination($event)"></ngb-pagination>

      <div class="btn-group items-per-page-group">
        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{centerMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
      </div>
      <!-- ============================================================== -->
      <!-- End Page Filter -->
    </div>

  </div>
