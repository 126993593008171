import {Component, HostListener, OnInit} from '@angular/core';
import {RulesUtils} from './rules.utils';
import {RuleTableProvider, RuleTypeEnum} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';

@Component({
    selector: 'app-rules',
    templateUrl: './rules.component.html',
    styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

    constructor(
        public rulesUtils: RulesUtils,
        private ngxLoader: NgxUiLoaderService,
        private ruleTableProvider: RuleTableProvider,
        private messagesService: MessagesService,
    ) {
    }

    ngOnInit(): void {
        this.loadRuleOptions();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        // On browser back, if a rule is selected, clear it to return to rule type selection screen.
        // Set value to null to not trigger history.back
        if (this.rulesUtils.selectedRule) {
            this.rulesUtils.selectedRule = null;
        }
    }

    onRuleTypeSelect(ruleType: string): void {
        const rule: RuleTypeEnum = RuleTypeEnum[ruleType];
        const options = this.rulesUtils.getRulesOptions();
        if (!options[rule].visible) {
            this.rulesUtils.selectedRule = null;
            this.messagesService.error('label.ruleNotAvailable', true);
        } else {
            this.rulesUtils.selectedRule = rule;
            this.rulesUtils.overviewState.previousSelectedRule = null;
            // Push an empty state so on Back the user is returned to rule category select
            history.pushState({}, '');
        }
    }

    private loadRuleOptions() {
        this.rulesUtils.rulesOptions = this.rulesUtils.getRulesOptions();
        this.rulesUtils.rulesCategories = this.rulesUtils.getRulesCategories();
    }
}
