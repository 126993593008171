<div class="container-fluid coverage-plan-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
      <h3 translate="label.coveragePlans"></h3>
      <div class="filters-section d-flex">
        <div class="service-filter-container">
          <sbase-filter (selectedItemsOutput)=" onSelectedServiceFilter($event)"
                        [buttonName]=serviceButtonName
                        [dependentFilters]="mainDependentFilters.service"
                        [initialFilterValues]="coveragePlanMdUtils.initialServiceValues"
                        [isMultipleSelectedItem]="false"
                        [useSelectedValueAsLabel]="true"
                        [hideSelectedItems]="true"
                        [providerInstance]="serviceProvider">
          </sbase-filter>
        </div>
        <button class="reset-button btn" (click)="onClearFilters()">
          <i class="far fa-trash-alt"></i>
          <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
        </button>
      </div>
    </div>

    <div class="sort-search-wrapper d-flex justify-content-between">
      <div class="sort text-high-contrast">
        <span translate="label.activeSorting"></span>:
        <span class="text-info">
        <span *ngFor="let key of objectKeys(coveragePlanMdUtils.tableFilters.orderBy)">
          <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + coveragePlanMdUtils.tableFilters.orderBy[key] | translate}}
          <span>
            <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
          </span>
        </span>
        </span>
      </div>

      <div class="d-flex">
        <div class="d-flex search">
          <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
          <input type="text" class="form-control rounded-0"
                 placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" autocomplete="off"
                 [(ngModel)]="coveragePlanMdUtils.tableFilters.filter.Name" (ngModelChange)="onSearchCoveragePlanChanged($event)">
        </div>
        <div class="ml-2">
          <button type="button" class="btn theme-alt-btn border-0"
                  [activityDisplay]="COVERAGE_PLAN+constants.CREATE"
                  (click)="createCoveragePlan()"
                  translate="label.createNew">
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
      <table class="table">
        <thead>
        <tr>
          <th class="id-column cursor-pointer" (click)="onSortBy('shortId')">
            {{'label.shortId' | translate}}
            <button type="button" class="btn p-0" *ngIf="!coveragePlanMdUtils.tableFilters.orderBy.shortId"><i class="fas fa-sort"></i>
            </button>
            <button type="button" class="btn p-0" *ngIf="coveragePlanMdUtils.tableFilters.orderBy.shortId === 'asc'"><i
              class="fas fa-sort-down"></i></button>
            <button type="button" class="btn p-0" *ngIf="coveragePlanMdUtils.tableFilters.orderBy.shortId === 'desc'"><i
              class="fas fa-sort-up"></i></button>
          </th>
          <th class="name-column cursor-pointer" (click)="onSortBy('name')">
            {{'label.name' | translate}}
            <button type="button" class="btn p-0" *ngIf="!coveragePlanMdUtils.tableFilters.orderBy.name"><i class="fas fa-sort"></i></button>
            <button type="button" class="btn p-0" *ngIf="coveragePlanMdUtils.tableFilters.orderBy.name === 'asc'"><i
              class="fas fa-sort-down"></i></button>
            <button type="button" class="btn p-0" *ngIf="coveragePlanMdUtils.tableFilters.orderBy.name === 'desc'"><i
              class="fas fa-sort-up"></i></button>
          </th>
          <th class="coverage-company-column" translate="label.coverageCompany"></th>
          <th class="main-coverage-plan-column" translate="label.mainCoveragePlan"></th>
          <th class="is-private-column" translate="label.isPrivate"></th>
          <th class="code-column" translate="label.code"></th>
          <th class="priority-column" translate="label.priority"></th>
          <th translate="label.type"></th>
          <th class="services-column" translate="label.services"></th>
          <th class="channel-restriction-column" translate="label.channelRestrictions"></th>
          <th class="text-center" translate="label.action"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let coveragePlan of coveragePlanMdList"
            [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
            sbaseMarkTableRowOnHover
            (click)="viewCoveragePlan(coveragePlan)">
          <td class="id-column">{{coveragePlan.shortId}}</td>
          <td class="name-column max-width-table-column">{{coveragePlan.name}}</td>
          <td class="coverage-company-column">{{coveragePlan.coverageCompany?.name}}</td>
          <td class="main-coverage-plan-column">{{coveragePlan.mainCoveragePlan | yesNoPipe | translate}}</td>
          <td class="is-private-column">{{coveragePlan.isPrivate | yesNoPipe | translate}}</td>
          <td class="code-column">{{coveragePlan.code}}</td>
          <td class="priority-column">{{coveragePlan.priority}}</td>
          <td>{{'label.' + coveragePlan.type | translate}}</td>
          <td class="services-column">
            <div *ngFor="let service of coveragePlan.services| slice:0:3">
              {{service.name}}
            </div>
            <ng-template #servicesContent>
              <div class="table-tooltip-container px-2 py-1">
                <div *ngFor="let service of coveragePlan.services">{{service.name}}</div>
              </div>
            </ng-template>
            <div *ngIf="coveragePlan.services && coveragePlan.services.length>3" (click)="$event.stopPropagation()">
              <div [ngbPopover]="servicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
              </div>
            </div>
          </td>
          <td class="channel-restriction-column">
            <div *ngFor="let channelRestriction of coveragePlan.channelRestrictions| slice:0:3">
              {{"label.channels." + channelRestriction.channel | translate}}
            </div>
            <ng-template #channelRestrictionsContent>
              <div class="table-tooltip-container px-2 py-1">
                <div *ngFor="let channelRestriction of coveragePlan.channelRestrictions">{{"enum.channel." + channelRestriction.channel | translate}}</div>
              </div>
            </ng-template>
            <div *ngIf="coveragePlan.channelRestrictions && coveragePlan.channelRestrictions.length>3" (click)="$event.stopPropagation()">
              <div [ngbPopover]="channelRestrictionsContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
              </div>
            </div>
          </td>
          <td class="text-center" (click)="$event.stopPropagation()">
            <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <ng-template #popContent>
              <ul class="list-style-none m-0 p-0 custom-drop-down">
                <li>
                  <button type="button" class="btn" translate="label.view" (click)="viewCoveragePlan(coveragePlan)"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.edit" (click)="editCoveragePlan(coveragePlan)"
                          [activityDisplay]="COVERAGE_PLAN+constants.UPDATE"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.delete"
                          (click)="displayConfirmDeleteItemModal(coveragePlan.id)"
                          [activityDisplay]="COVERAGE_PLAN+constants.DELETE"></button>
                </li>
                <li>
                    <button type="button" class="btn" translate="label.changeLog"
                            (click)="displayChangeLog(coveragePlan.id, EntityTypeEnum.coveragePlan, coveragePlan.name)"
                            [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                </li>
              </ul>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
      <ngb-pagination [collectionSize]="totalTableItems" [(page)]="coveragePlanMdUtils.tableFilters.currentPage" [maxSize]="5"
                      [pageSize]="coveragePlanMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                      (pageChange)="onChangePagination($event)"></ngb-pagination>

      <div class="btn-group items-per-page-group">
        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{coveragePlanMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
      </div>
      <!-- ============================================================== -->
      <!-- End Page Filter -->
    </div>

  </div>
