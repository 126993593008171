import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {
    PdfImageViewerOptionsType,
    ViewerActionTypeEnum,
    ViewerFileOptionsType,
    ViewerFileType,
    ViewerFileTypeEnum
} from './pdf-image-viewer.types';
import {forkJoin} from 'rxjs';
import {FileProvider} from 'sked-base';
import {delay, map, switchMap} from 'rxjs/operators';
import {FileEntityType} from 'sked-base/lib/data-model/fileTypes';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pdf-image-viewer',
    templateUrl: './pdf-image-viewer.component.html',
    styleUrls: ['./pdf-image-viewer.component.scss']
})
export class PdfImageViewerComponent implements OnInit {
    @Input() options: PdfImageViewerOptionsType;
    files: ViewerFileType[];
    currentCarouselIndex = 0;
    displayError = false;
    viewerFileTypeEnum = ViewerFileTypeEnum;
    viewerActionTypeEnum = ViewerActionTypeEnum;
    loaderId = 'PdfImageViewerComponent';
    CONTROL_AMOUNT_PDF_ZOOM = 0.2;
    CONTROL_AMOUNT_PDF_ROTATE = 90;
    CONTROL_AMOUNT_IMG_ZOOM = 0.2;
    CONTROL_AMOUNT_IMG_ROTATE = 30;
    TRANSPARENT_PIXEL_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=';

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private fileProvider: FileProvider,
        private sanitizer: DomSanitizer,
    ) {
    }

    ngOnInit(): void {
        if (!this.options) {
            this.displayError = true;
            return;
        }
        this.loadFiles();
    }

    onCarouselSlide(event: NgbSlideEvent): void {
        this.currentCarouselIndex = parseInt(event.current.replace('ngb-slide-', ''), 10);
    }

    onControlAction(action: ViewerActionTypeEnum): void {
        if (this.files[this.currentCarouselIndex]?.type === ViewerFileTypeEnum.IMG) {
            this.onControlImageAction(action);
        } else if (this.files[this.currentCarouselIndex]?.type === ViewerFileTypeEnum.PDF) {
            this.onControlPdfAction(action);
        }
    }

    onImageControlScroll(event: any): void {
        event.preventDefault();
        if (event.deltaY > 0) {
            this.onControlImageAction(ViewerActionTypeEnum.ZoomOut);
        } else {
            this.onControlImageAction(ViewerActionTypeEnum.ZoomIn);
        }
    }

    onImageControlDragStart(event: any): void {
        if (!!event.dataTransfer?.setDragImage) {
            const img = new Image();
            img.src = this.TRANSPARENT_PIXEL_IMAGE;
            event.dataTransfer.setDragImage(img, 0, 0);
        }
        this.files[this.currentCarouselIndex].options.imageOptions.prevX = event.clientX;
        this.files[this.currentCarouselIndex].options.imageOptions.prevY = event.clientY;
    }

    onImageControlDragOver(event: any): void {
        this.files[this.currentCarouselIndex].options.imageOptions.translateX +=
            (event.clientX - this.files[this.currentCarouselIndex].options.imageOptions.prevX);
        this.files[this.currentCarouselIndex].options.imageOptions.translateY +=
            (event.clientY - this.files[this.currentCarouselIndex].options.imageOptions.prevY);
        this.files[this.currentCarouselIndex].options.imageOptions.prevX = event.clientX;
        this.files[this.currentCarouselIndex].options.imageOptions.prevY = event.clientY;
        this.updateImageStyle(this.currentCarouselIndex);
    }

    private onControlImageAction(action: ViewerActionTypeEnum): void {
        switch (action) {
            case ViewerActionTypeEnum.ZoomIn: {
                this.files[this.currentCarouselIndex].options.imageOptions.scale *= (1 + this.CONTROL_AMOUNT_IMG_ZOOM);
                this.updateImageStyle(this.currentCarouselIndex);
                return;
            }
            case ViewerActionTypeEnum.ZoomOut: {
                if (this.files[this.currentCarouselIndex].options.imageOptions.scale > this.CONTROL_AMOUNT_IMG_ZOOM) {
                    this.files[this.currentCarouselIndex].options.imageOptions.scale /= (1 + this.CONTROL_AMOUNT_IMG_ZOOM);
                }
                this.updateImageStyle(this.currentCarouselIndex);
                return;
            }
            case ViewerActionTypeEnum.RotateLeft: {
                this.files[this.currentCarouselIndex].options.imageOptions.rotation -= this.CONTROL_AMOUNT_IMG_ROTATE;
                this.updateImageStyle(this.currentCarouselIndex);
                return;
            }
            case ViewerActionTypeEnum.RotateRight: {
                this.files[this.currentCarouselIndex].options.imageOptions.rotation += this.CONTROL_AMOUNT_IMG_ROTATE;
                this.updateImageStyle(this.currentCarouselIndex);
                return;
            }
            case ViewerActionTypeEnum.Reset: {
                this.files[this.currentCarouselIndex].options = this.getInitialFileOptions();
                return;
            }
            case ViewerActionTypeEnum.Download: {
                const stringBlobSrc = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.files[this.currentCarouselIndex].blobSrc);
                this.downloadBlobSrc(stringBlobSrc);
                return;
            }
            case ViewerActionTypeEnum.Print: {
                const stringBlobSrc = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.files[this.currentCarouselIndex].blobSrc);
                this.printBlobSrc(stringBlobSrc);
                return;
            }
            default: {
                return;
            }
        }
    }

    private onControlPdfAction(action: ViewerActionTypeEnum): void {
        switch (action) {
            case ViewerActionTypeEnum.ZoomIn: {
                this.files[this.currentCarouselIndex].options.pdfOptions.zoom += this.CONTROL_AMOUNT_PDF_ZOOM;
                return;
            }
            case ViewerActionTypeEnum.ZoomOut: {
                if (this.files[this.currentCarouselIndex].options.pdfOptions.zoom > this.CONTROL_AMOUNT_PDF_ZOOM) {
                    this.files[this.currentCarouselIndex].options.pdfOptions.zoom -= this.CONTROL_AMOUNT_PDF_ZOOM;
                }
                return;
            }
            case ViewerActionTypeEnum.RotateLeft: {
                this.files[this.currentCarouselIndex].options.pdfOptions.rotation -= this.CONTROL_AMOUNT_PDF_ROTATE;
                return;
            }
            case ViewerActionTypeEnum.RotateRight: {
                this.files[this.currentCarouselIndex].options.pdfOptions.rotation += this.CONTROL_AMOUNT_PDF_ROTATE;
                return;
            }
            case ViewerActionTypeEnum.Reset: {
                this.files[this.currentCarouselIndex].options = this.getInitialFileOptions();
                return;
            }
            case ViewerActionTypeEnum.Download: {
                this.downloadBlobSrc(this.files[this.currentCarouselIndex].blobSrc);
                return;
            }
            case ViewerActionTypeEnum.Print: {
                this.printBlobSrc(this.files[this.currentCarouselIndex].blobSrc);
                return;
            }
            default: {
                return;
            }
        }
    }

    private updateImageStyle(index: number): void {
        const styleTransform =
            `translate(${this.files[index].options.imageOptions.translateX}px, ${this.files[index].options.imageOptions.translateY}px) ` +
            `rotate(${this.files[index].options.imageOptions.rotation}deg) scale(${this.files[index].options.imageOptions.scale})`;
        this.files[index].options.imageOptions.styles.transform = styleTransform;
        this.files[index].options.imageOptions.styles.msTransform = styleTransform;
        this.files[index].options.imageOptions.styles.webkitTransform = styleTransform;
        this.files[index].options.imageOptions.styles.oTransform = styleTransform;
    }

    private loadFiles(): void {
        this.displayError = false;
        this.ngxLoader.startLoader(this.loaderId);
        this.fileProvider.getFileEntitiesForEntity(this.options.entityType, this.options.entityId).pipe(
            switchMap((fileEntityObjects: {value: FileEntityType[]}) => {
                return forkJoin(
                    fileEntityObjects.value.map((fileEntityObject: FileEntityType) => {
                        return this.fileProvider.getFileById(fileEntityObject.id).pipe(
                            map((fileResponse: {file: Blob, fileName: string}) => {
                                return {
                                    fileBlob: fileResponse.file,
                                    fileEntity: fileEntityObject
                                };
                            })
                        );
                    })
                );
            }),
            map((fileObjects: {fileBlob: Blob, fileEntity: FileEntityType}[]) => {
                return fileObjects.map((fileObject: {fileBlob: Blob, fileEntity: FileEntityType}) => {
                    const fileType: ViewerFileTypeEnum = this.getFileTypeEnumFromMediaType(fileObject.fileEntity.mediaType);
                    const blobUrl: string = URL.createObjectURL(fileObject.fileBlob);
                    return {
                        id: fileObject.fileEntity.id,
                        name: fileObject.fileEntity.name,
                        type: fileType,
                        blobSrc: fileType === ViewerFileTypeEnum.IMG ? this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl) : blobUrl,
                        options: this.getInitialFileOptions(),
                    } as ViewerFileType;
                });
            })
        ).subscribe((files: ViewerFileType[]) => {
            this.files = files;
            this.currentCarouselIndex = 0;
            this.ngxLoader.stopLoader(this.loaderId);
        }, (error) => {
            this.displayError = true;
            this.ngxLoader.stopLoader(this.loaderId);
        });
    }

    private printBlobSrc(blobSrc): void {
        const iframe =  document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.contentWindow.document.title = 'Document';
        iframe.src = blobSrc;
        iframe.onload = () => {
            setTimeout(() => {
                if (!!iframe.contentWindow.document.getElementsByTagName('title')[0]?.innerText) {
                    iframe.contentWindow.document.getElementsByTagName('title')[0].innerText = 'Document';
                }
                iframe.focus();
                // Check if browser is IE 11
                if (!!(window.document as any)?.documentMode) {
                    // Print command for IE 11 is different
                    iframe.contentWindow.document.execCommand('print', false, null);
                } else {
                    iframe.contentWindow.print();
                }
            }, 0);
        };
    }

    private downloadBlobSrc(blobSrc: string): void {
        // Create a link element
        const link = document.createElement('a');
        link.href = blobSrc;
        link.download = 'file';
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }

    private getInitialFileOptions(): ViewerFileOptionsType {
        return {
            pdfOptions: {
                zoom: 1,
                rotation: 0,
            },
            imageOptions: {
                styles: { transform: '', msTransform: '', oTransform: '', webkitTransform: '' },
                scale: 1,
                rotation: 0,
                translateX: 0,
                translateY: 0,
                prevX: 0,
                prevY: 0,
            }
        } as ViewerFileOptionsType;
    }

    private getFileTypeEnumFromMediaType(mediaType: string): ViewerFileTypeEnum {
        if (mediaType === 'application/pdf') {
            return ViewerFileTypeEnum.PDF;
        } else if (mediaType.includes('image/')) {
            return ViewerFileTypeEnum.IMG;
        }
        return undefined;
    }
}
