import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    DynamicFormGroupedInputType,
    DynamicFormInputType,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {FormGroup, FormGroupDirective, ValidationErrors} from '@angular/forms';
import {
    ObjectTagType,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import * as lodash from 'lodash';
import {formControlNames} from '../../../../shared/constants/formControlNames';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {CreatePatientUtils} from '../create-patient.utils';
import * as moment from 'moment';
import {PatientTimeDependentTagsType} from '../../patient-dashboard.types';
import {distinctUntilChanged} from 'rxjs/operators';
import {Moment} from 'moment';

@Component({
    selector: 'app-time-dependent-tags-form',
    templateUrl: './time-dependent-tags-form.component.html',
    styleUrls: ['./time-dependent-tags-form.component.scss']
})
export class TimeDependentTagsFormComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() options: DynamicFormOptionsType;
    @ViewChild('tagsSbaseFilter', {static: false}) tagsSbaseFilter;

    timeDependentTagsForm: FormGroup;
    allInputs: DynamicFormInputType[] = [];
    mainDependentFilters: {
        tags: TagDependentFiltersType
    };
    formControlNames = formControlNames;
    previousTags: ObjectTagType[] = [];
    initialTimeDependentTags: ObjectTagType[] = [];
    dateTimeFromOptions: DynamicFormOptionsType = {groups: []};
    dateTimeToOptions: DynamicFormOptionsType = {groups: []};
    numberOfDaysOptions: DynamicFormOptionsType = {groups: []};
    isSaveButtonDisabled = true;

    readonly timeDependentTagsLoaderId = 'timeDependentTagsLoader';

    constructor(public tagProvider: TagProvider,
                public dynamicFormInputService: DynamicFormInputService,
                public createPatientUtils: CreatePatientUtils,
                private rootFormGroup: FormGroupDirective,
                private searchFilterUtils: SearchFilterUtils) {
    }

    ngOnInit(): void {
        this.timeDependentTagsForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        this.loadMainDependentFilters();
        this.loadTimeDependentTagsFormOptions();
        this.listenToTagsInputChanges();
        this.listenToDateTimeFromInputChanges();
        this.listenToDateTimeToInputChanges();
        this.listenToNumberOfDaysInputChanges();
        this.createPatientUtils.setPatientTimeDependentTagsExclusionList();
    }

    onSaveTimeDependentTag(): void {
        const {value} = this.timeDependentTagsForm;
        const dateFromValue = value[formControlNames.DATE_TIME_FROM];
        const dateToValue = value[formControlNames.DATE_TIME_TO];
        const numberOfDaysValue = value[formControlNames.NUMBER_OF_DAYS];
        const timeDependentTagValue = value[formControlNames.TIME_DEPENDENT_TAG];
        const dateTimeFrom = dateFromValue ? this.getFormattedDateBeforeSave(dateFromValue) : null;
        const dateTimeTo = dateToValue ? this.getFormattedDateBeforeSave(dateToValue) : null;
        if (this.timeDependentTagsForm.valid) {
            // If we have value in initialTimeDependentTags it means we want to edit the time dependent tag
            if (this.initialTimeDependentTags.length > 0) {
                const patientTimeDependentTagIndex = this.createPatientUtils.patientTimeDependentTags
                    .findIndex((tag) => tag.tagLinkId === this.initialTimeDependentTags[0].tagLinkId);
                this.createPatientUtils.patientTimeDependentTags[patientTimeDependentTagIndex] = {
                    ...this.createPatientUtils.patientTimeDependentTags[patientTimeDependentTagIndex],
                    tagId: timeDependentTagValue[0]?.id || timeDependentTagValue?.id || timeDependentTagValue[0]?.tagId || timeDependentTagValue?.tagId,
                    dateTimeFrom,
                    dateTimeTo,
                    numberOfDays: numberOfDaysValue,
                    name: timeDependentTagValue[0]?.name || timeDependentTagValue?.name
                } as PatientTimeDependentTagsType;
            } else {
                this.createPatientUtils.patientTimeDependentTags.push({
                    tagId: timeDependentTagValue[0]?.id || timeDependentTagValue?.id,
                    dateTimeFrom,
                    dateTimeTo,
                    numberOfDays: numberOfDaysValue,
                    name: timeDependentTagValue[0]?.name || timeDependentTagValue?.name,
                    tagLinkId: null
                } as PatientTimeDependentTagsType);
            }
        }
        this.createPatientUtils.setPatientTimeDependentTagsExclusionList();
        this.onCancelTimeDependentTag();
    }

    onCancelTimeDependentTag(): void {
        const {value} = this.timeDependentTagsForm;
        if (value[formControlNames.TIME_DEPENDENT_TAG]) {
            this.tagsSbaseFilter.onRemoveItem(value[formControlNames.TIME_DEPENDENT_TAG]);
        }
        this.initialTimeDependentTags = [];
        this.timeDependentTagsForm.reset();
        this.createPatientUtils.setPatientTimeDependentTagsExclusionList();
        this.isSaveButtonDisabled = true;
    }

    editTimeDependentTag(timeDependentTag: PatientTimeDependentTagsType): void {
        const dateFrom = timeDependentTag.dateTimeFrom ? moment(timeDependentTag.dateTimeFrom).format(moment.localeData().longDateFormat('L')) : '';
        const dateTo = timeDependentTag.dateTimeTo ? moment(timeDependentTag.dateTimeTo).format(moment.localeData().longDateFormat('L')) : '';
        const numberOfDaysValue = this.createPatientUtils.getTimeDependentTagsDifferenceInDays(
            timeDependentTag.dateTimeFrom, timeDependentTag.dateTimeTo
        );
        this.timeDependentTagsForm.patchValue({
            [formControlNames.TIME_DEPENDENT_TAG]: [timeDependentTag],
            [formControlNames.DATE_TIME_FROM]: dateFrom,
            [formControlNames.DATE_TIME_TO]: dateTo,
            [formControlNames.NUMBER_OF_DAYS]: numberOfDaysValue ?? ''
        });
        this.initialTimeDependentTags = lodash.cloneDeep(this.timeDependentTagsForm.get(formControlNames.TIME_DEPENDENT_TAG)?.value);
    }

    deleteTimeDependentTag(index: number): void {
        this.createPatientUtils.patientTimeDependentTags.splice(index, 1);
        this.createPatientUtils.setPatientTimeDependentTagsExclusionList();
    }

    onSelectedTags(tags: ObjectTagType[]): void {
        if (tags?.length >= 0 && !lodash.isEqual(this.previousTags, tags)) {
            this.previousTags = tags;
            this.timeDependentTagsForm.get(formControlNames.TIME_DEPENDENT_TAG).patchValue(tags[0]);
        }
    }

    getInputByControlName(formControlName: string): DynamicFormInputType {
        if (!this.allInputs?.length) {
            this.allInputs = [];
            this.options?.groups.forEach((group: DynamicFormGroupedInputType) => {
                group?.inputs?.forEach((input: DynamicFormInputType) => {
                    this.allInputs.push(input);
                });
            });
        }
        return lodash.find(this.allInputs, {formControlName});
    }

    private listenToTagsInputChanges() {
        this.timeDependentTagsForm?.get(formControlNames.TIME_DEPENDENT_TAG)?.valueChanges.pipe(distinctUntilChanged())
            .subscribe(() => {
                this.updateValueAndValidityTimeDependentTagsForm();
                this.setInputsErrors();
            });
    }

    private listenToDateTimeFromInputChanges() {
        this.timeDependentTagsForm?.get(formControlNames.DATE_TIME_FROM)?.valueChanges.pipe(distinctUntilChanged())
            .subscribe((dateTimeFromValue) => {
                this.updateValueAndValidityTimeDependentTagsForm();
                const dateTimeToValue = this.timeDependentTagsForm.value[formControlNames.DATE_TIME_TO];
                const numberOfDaysValue = this.timeDependentTagsForm.value[formControlNames.NUMBER_OF_DAYS];
                const momentDateTimeFromValue = this.getFormattedDateBeforeSave(dateTimeFromValue);
                if (!dateTimeFromValue && !!dateTimeToValue) {
                    this.timeDependentTagsForm.get(formControlNames.NUMBER_OF_DAYS).patchValue('');
                    this.setInputRequiredError(formControlNames.DATE_TIME_FROM);
                }
                if (!dateTimeToValue) {
                    this.setInputRequiredError(formControlNames.DATE_TIME_TO);
                    this.setInputRequiredError(formControlNames.NUMBER_OF_DAYS);
                }
                if (dateTimeFromValue && Number.isInteger(numberOfDaysValue)) {
                    const calculatedDateToValue = moment(momentDateTimeFromValue).add(numberOfDaysValue, 'days');
                    this.timeDependentTagsForm.get(formControlNames.DATE_TIME_TO)
                        .patchValue(moment(calculatedDateToValue).format(moment.localeData().longDateFormat('L')), {emitEvent: false});
                }
                this.setNumberOfDaysForm();
                this.setInputsErrors();
            });
    }

    private listenToDateTimeToInputChanges() {
        this.timeDependentTagsForm?.get(formControlNames.DATE_TIME_TO)?.valueChanges.pipe(distinctUntilChanged())
            .subscribe((dateTimeToValue) => {
                this.updateValueAndValidityTimeDependentTagsForm();
                const dateTimeFromValue = this.timeDependentTagsForm.value[formControlNames.DATE_TIME_FROM];
                const numberOfDaysValue = this.timeDependentTagsForm.value[formControlNames.NUMBER_OF_DAYS];
                if (!!dateTimeFromValue && !dateTimeToValue) {
                    this.timeDependentTagsForm.get(formControlNames.NUMBER_OF_DAYS).patchValue('');
                    this.setInputRequiredError(formControlNames.DATE_TIME_TO);
                    this.setInputRequiredError(formControlNames.NUMBER_OF_DAYS);
                }
                if (!dateTimeFromValue && !!dateTimeToValue) {
                    this.setInputRequiredError(formControlNames.DATE_TIME_FROM);
                }
                if (!!dateTimeFromValue && !!dateTimeToValue) {
                    this.setNumberOfDaysForm();
                }
                if (dateTimeToValue && Number.isInteger(numberOfDaysValue)) {
                    this.setCalculatedDateTimeFrom();
                }
                this.setInputsErrors();
            });
    }

    private listenToNumberOfDaysInputChanges(): void {
        this.timeDependentTagsForm?.get(formControlNames.NUMBER_OF_DAYS)?.valueChanges.pipe(distinctUntilChanged())
            .subscribe((numberOfDaysValue) => {
                this.updateValueAndValidityTimeDependentTagsForm();
                const dateTimeFromValue = this.timeDependentTagsForm.value[formControlNames.DATE_TIME_FROM];
                const dateTimeToValue = this.timeDependentTagsForm.value[formControlNames.DATE_TIME_TO];
                const timeDependentTagValue = this.timeDependentTagsForm.value[formControlNames.TIME_DEPENDENT_TAG];
                const momentDateTimeFromValue = this.getFormattedDateBeforeSave(dateTimeFromValue);
                if (dateTimeFromValue && Number.isInteger(numberOfDaysValue)) {
                    const calculatedDateToValue = moment(momentDateTimeFromValue).add(numberOfDaysValue, 'days');
                    this.timeDependentTagsForm.get(formControlNames.DATE_TIME_TO)
                        .patchValue(moment(calculatedDateToValue).format(moment.localeData().longDateFormat('L')), {emitEvent: false});
                }
                if (!timeDependentTagValue && !dateTimeFromValue) {
                    this.setInputRequiredError(formControlNames.DATE_TIME_FROM);
                }
                if (dateTimeToValue && Number.isInteger(numberOfDaysValue)) {
                    this.setCalculatedDateTimeFrom();
                }
                this.setInputsErrors();
            });
    }

    private setInputsErrors() {
        this.setInputRequiredError(formControlNames.TIME_DEPENDENT_TAG);
        this.setInputErrorsIfDateTimeFromIsGreaterThanDateTimeTo();
        this.removeErrorsIfAllInputsExceptTimeDependentTagAreEmpty();
        this.removeAllInputsErrorsIfAllAreEmpty();
        this.timeDependentTagsForm.markAllAsTouched();
        this.isSaveButtonDisabled = !this.timeDependentTagsForm.valid || this.areAllTimeDependentTagsFormValuesEmpty();
    }

    private setInputRequiredError(formControlName: string) {
        if (!this.timeDependentTagsForm.controls[formControlName].value) {
            this.timeDependentTagsForm.controls[formControlName].setErrors({
                requiredError: {priority: 2, message: 'label.error.required', shouldTranslateMessage: true}
            }, {emitEvent: false});
        }
    }

    private setCalculatedDateTimeFrom() {
        const {value} = this.timeDependentTagsForm;
        const dateTimeToValue = value[formControlNames.DATE_TIME_TO];
        const numberOfDaysValue = value[formControlNames.NUMBER_OF_DAYS];
        const momentDateTimeToValue = this.getFormattedDateBeforeSave(dateTimeToValue);
        const calculatedDateTimeFromValue = moment(momentDateTimeToValue).subtract(numberOfDaysValue, 'days');
        if (this.isCalculatedDateTimeFromInThePast(calculatedDateTimeFromValue)) {
            this.timeDependentTagsForm.get(formControlNames.NUMBER_OF_DAYS).setErrors(
                this.getErrorObject('label.error.numberOfDaysWillGenerateDateInThePast'), {emitEvent: false}
            );
        } else {
            this.timeDependentTagsForm.get(formControlNames.DATE_TIME_FROM)
                .patchValue(moment(calculatedDateTimeFromValue).format(moment.localeData().longDateFormat('L')), {emitEvent: false});
        }
    }

    private isCalculatedDateTimeFromInThePast(calculatedDateTimeFromValue: Moment): boolean {
        const momentDateTimeFromValue = moment(calculatedDateTimeFromValue).format('YYYY-MM-DD');
        const momentToday = moment().format('YYYY-MM-DD');

        return moment(momentDateTimeFromValue).isBefore(momentToday);
    }

    private setInputErrorsIfDateTimeFromIsGreaterThanDateTimeTo() {
        const {value} = this.timeDependentTagsForm;
        const dateTimeFromValue = value[formControlNames.DATE_TIME_FROM];
        const dateTimeToValue = value[formControlNames.DATE_TIME_TO];
        const momentDateTimeFromValue = this.getFormattedDateBeforeSave(dateTimeFromValue);
        const momentDateTimeToValue = this.getFormattedDateBeforeSave(dateTimeToValue);
        const isDateFromGreaterThanDateTo = this.isDateFromGreaterThanDateTo(momentDateTimeFromValue, momentDateTimeToValue);
        if (isDateFromGreaterThanDateTo && !!dateTimeFromValue && !!dateTimeToValue) {
            this.timeDependentTagsForm.get(formControlNames.DATE_TIME_FROM).setErrors(
                this.getErrorObject('label.error.dateTimeFromMustBeLower'), {emitEvent: false}
            );
            this.timeDependentTagsForm.get(formControlNames.DATE_TIME_TO).setErrors(
                this.getErrorObject('label.error.dateTimeToMustBeGreater'), {emitEvent: false}
            );
            this.timeDependentTagsForm.get(formControlNames.NUMBER_OF_DAYS).setErrors(
                this.getErrorObject('label.error.numberOfDaysMustBeGreaterOrEqualToZero'),
                {emitEvent: false}
            );
        }
        if (!isDateFromGreaterThanDateTo && !!dateTimeFromValue && !!dateTimeToValue) {
            this.timeDependentTagsForm.get(formControlNames.DATE_TIME_TO).setErrors(null, {emitEvent: false});
            this.timeDependentTagsForm.get(formControlNames.DATE_TIME_FROM).setErrors(null, {emitEvent: false});
            this.timeDependentTagsForm.get(formControlNames.NUMBER_OF_DAYS).setErrors(null, {emitEvent: false});
        }
    }

    private removeErrorsIfAllInputsExceptTimeDependentTagAreEmpty() {
        const {value} = this.timeDependentTagsForm;
        const isTimeDependentTagTheOnlyInputWithValue = Object.keys(value).filter(key => value[key]);
        if (isTimeDependentTagTheOnlyInputWithValue[0] === formControlNames.TIME_DEPENDENT_TAG && isTimeDependentTagTheOnlyInputWithValue.length === 1) {
            Object.keys(this.timeDependentTagsForm.controls).forEach(key => {
                if (key !== formControlNames.TIME_DEPENDENT_TAG) {
                    this.timeDependentTagsForm.controls[key].setErrors(null);
                }
            });
        }
    }

    private removeAllInputsErrorsIfAllAreEmpty() {
        if (this.areAllTimeDependentTagsFormValuesEmpty()) {
            Object.keys(this.timeDependentTagsForm.controls).forEach(key => {
                this.timeDependentTagsForm.controls[key].setErrors(null);
            });
        }
    }

    private areAllTimeDependentTagsFormValuesEmpty() {
        return Object.values(this.timeDependentTagsForm.value)
            .every((value: any) => value === '' || value?.length <= 0 || !value);
    }

    private setNumberOfDaysForm(): void {
        const isDateTimeFromValueValid = this.timeDependentTagsForm.get(formControlNames.DATE_TIME_FROM).valid;
        const isDateTimeToValueValid = this.timeDependentTagsForm.get(formControlNames.DATE_TIME_TO).valid;
        if (isDateTimeFromValueValid && isDateTimeToValueValid) {
            this.timeDependentTagsForm.controls[formControlNames.NUMBER_OF_DAYS]
                .patchValue(this.getDateTimeFromAndDateTimeToDifferenceInDays(), {emitEvent: false});
        }
    }

    private getDateTimeFromAndDateTimeToDifferenceInDays(): number {
        const {value} = this.timeDependentTagsForm;
        const dateTimeFromValue = value[formControlNames.DATE_TIME_FROM];
        const dateTimeToValue = value[formControlNames.DATE_TIME_TO];
        const momentDateTimeFromValue = this.getFormattedDateBeforeSave(dateTimeFromValue);
        const momentDateTimeToValue = this.getFormattedDateBeforeSave(dateTimeToValue);
        return moment(momentDateTimeToValue).diff(moment(momentDateTimeFromValue), 'days');
    }

    private isDateFromGreaterThanDateTo(dateFromValue: string, dateToValue: string): boolean {
        return moment(dateFromValue).isAfter(moment(dateToValue));
    }

    private loadMainDependentFilters(): void {
        this.mainDependentFilters = {
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedPatient, true)
        };
    }

    private loadTimeDependentTagsFormOptions(): void {
        const dynamicInputFormOptions = this.createPatientUtils.getTimeDependentTagsDynamicInputFormOptions();
        this.dateTimeFromOptions?.groups.push(dynamicInputFormOptions?.groups[0]);
        this.dateTimeToOptions?.groups.push(dynamicInputFormOptions?.groups[1]);
        this.numberOfDaysOptions?.groups.push(dynamicInputFormOptions?.groups[2]);
    }

    private getFormattedDateBeforeSave(date: string): string {
        return moment(moment(moment(date, moment.localeData().longDateFormat('L'))).format('YYYY-MM-DD'))
            .utc(true).format();
    }

    private getErrorObject(errorMessage: string): { error: ValidationErrors } {
        return {
            error: {
                priority: 5,
                message: errorMessage,
                shouldTranslateMessage: true
            },
        };
    }

    private updateValueAndValidityTimeDependentTagsForm(): void {
        this.timeDependentTagsForm.updateValueAndValidity({emitEvent: false});
    }
}
