<div class="modal-filters task-list-modal-filters">
    <div class="modal-body">
        <div class="container">
            <!-- Display the input template -->
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </div>
        <button class="btn theme-btn mr-3 mt-3"
                type="button" (click)="onCloseButtonClick()"
                translate="label.ok">
        </button>
    </div>
</div>

