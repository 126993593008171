<div class="container-fluid system-config-wrapper">
    <div class="system-config-header">
        <h3 class="system-config-title">{{'label.systemConfig'|translate}}
        </h3>
    </div>
    <div class="row system-config-section custom-loader">
        <ngx-ui-loader [hasProgressBar]="false" [loaderId]="spinnerId">
        </ngx-ui-loader>
        <div class="system-config-section-left col-5 col-xl-4">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" orientation="vertical">
                <!-- When adding a new tab, increment its ngbNavItem -->
                <!-- TAB: General -->
                <li [ngbNavItem]="1" *ngIf="isTabVisible(systemConfigTabsTypeEnum.general)">
                    <a ngbNavLink>{{'label.generalSettings' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: LOGO_NAME -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.logoName"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="logoNameWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #logoNameWarningPopover>
                                    <span translate="label.warning.uploadLogo"></span>
                                </ng-template>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="imagesSystemConfigs[systemConfigKeys.LOGO_NAME].error">
                                    {{imagesSystemConfigs[systemConfigKeys.LOGO_NAME].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="LOGO_NAME" accept="image/png" class="form-control"
                                               (change)="onImageChange(systemConfigKeys.LOGO_NAME, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="imagesSystemConfigs[systemConfigKeys.LOGO_NAME].src"
                                         [src]="imagesSystemConfigs[systemConfigKeys.LOGO_NAME].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="LOGO_NAME">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendImageSystemConfigUpdate(systemConfigKeys.LOGO_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.LOGO_NAME)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelImageInput(systemConfigKeys.LOGO_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.LOGO_NAME)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: PRINTOUT_HEADER_NAME -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.printOutHeaderName"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="printOutHeaderNameWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #printOutHeaderNameWarningPopover>
                                    <span translate="label.warning.uploadPrintOut"></span>
                                </ng-template>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="imagesSystemConfigs[systemConfigKeys.PRINTOUT_HEADER_NAME].error">
                                    {{imagesSystemConfigs[systemConfigKeys.PRINTOUT_HEADER_NAME].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="PRINTOUT_HEADER_NAME" accept="image/png"
                                               class="form-control"
                                               (change)="onImageChange(systemConfigKeys.PRINTOUT_HEADER_NAME, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="imagesSystemConfigs[systemConfigKeys.PRINTOUT_HEADER_NAME].src"
                                         [src]="imagesSystemConfigs[systemConfigKeys.PRINTOUT_HEADER_NAME].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="PRINTOUT_HEADER_NAME">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendImageSystemConfigUpdate(systemConfigKeys.PRINTOUT_HEADER_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.PRINTOUT_HEADER_NAME)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelImageInput(systemConfigKeys.PRINTOUT_HEADER_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.PRINTOUT_HEADER_NAME)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: DEFAULT_COUNTRY -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.DEFAULT_COUNTRY)">
                                <label translate="label.defaultCountry" class="mb-0">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.general[systemConfigKeys.DEFAULT_COUNTRY].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.defaultCountry'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <ng-select [items]="countries"
                                               class="w-30 custom-ng-select"
                                               bindLabel="countryName"
                                               bindValue="countryCode"
                                               name="DEFAULT_COUNTRY"
                                               notFoundText="{{'toastr.error.notFound' | translate}}"
                                               [(ngModel)]="systemConfigData.general[systemConfigKeys.DEFAULT_COUNTRY].ngModel"
                                               appendTo="body">
                                    </ng-select>
                                    <button class="btn theme-btn ml-2" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.DEFAULT_COUNTRY)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.DEFAULT_COUNTRY)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.DEFAULT_COUNTRY)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.DEFAULT_COUNTRY)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: BACKOFFICE_LOGOUT_TIMEOUT -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT)">
                                <label translate="label.systemConfigTab.BackofficeLogoutTimeout">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.general[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.BackofficeLogoutTimeout'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="BACKOFFICE_LOGOUT_TIMEOUT"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.general[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT)"
                                               placeholder="{{'label.systemConfigTab.BackofficeLogoutTimeout'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span id="BACKOFFICE_LOGOUT_TIMEOUT" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('BACKOFFICE_LOGOUT_TIMEOUT',
                                                systemConfigData.general[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].ngModel)"
                                                [textForPopoverValueZero]="'label.neverGetLoggedOutDueToInactivity'"
                                        ></app-human-readable-minutes>
                                    </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT) || !systemConfigValidations[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT].formValidation.errorMessage"></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Email -->
                <li [ngbNavItem]="2" *ngIf="isTabVisible(systemConfigTabsTypeEnum.email)">
                    <a ngbNavLink>{{'label.emailNotifications' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: EMAIL_HEADER_NAME -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.emailHeaderName"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="emailHeaderNameWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #emailHeaderNameWarningPopover>
                                    <span translate="label.warning.uploadEmailHeader"></span>
                                </ng-template>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="imagesSystemConfigs[systemConfigKeys.EMAIL_HEADER_NAME].error">
                                    {{imagesSystemConfigs[systemConfigKeys.EMAIL_HEADER_NAME].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="EMAIL_HEADER_NAME" accept="image/png"
                                               class="form-control"
                                               (change)="onImageChange(systemConfigKeys.EMAIL_HEADER_NAME, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="imagesSystemConfigs[systemConfigKeys.EMAIL_HEADER_NAME].src"
                                         [src]="imagesSystemConfigs[systemConfigKeys.EMAIL_HEADER_NAME].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="EMAIL_HEADER_NAME">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendImageSystemConfigUpdate(systemConfigKeys.EMAIL_HEADER_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.EMAIL_HEADER_NAME)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelImageInput(systemConfigKeys.EMAIL_HEADER_NAME)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.EMAIL_HEADER_NAME)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: NOTIFICATION_EMAIL_APPOINTMENT_CREATED -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CREATED)">
                                <label translate="label.notificationEmailAppointmentCreated"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CREATED].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.notificationEmailAppointmentCreated'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="notificationEmailAppointmentCreated"
                                               [(ngModel)]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CREATED].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CREATED)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED)">
                                <label translate="label.notificationEmailAppointmentConfirmed"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.notificationEmailAppointmentConfirmed'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="notificationEmailAppointmentConfirmed"
                                               [(ngModel)]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED)">
                                <label translate="label.notificationEmailAppointmentCancelled"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.notificationEmailAppointmentCancelled'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="notificationEmailAppointmentCancelled"
                                               [(ngModel)]="systemConfigData.email[systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: ACTIVATE_ICAL -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.ACTIVATE_ICAL)">
                                <label translate="label.systemConfigTab.tabs.email.activateICal"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="activateICalWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #activateICalWarningPopover>
                                    <span translate="label.warning.activateICalWarningMessage"></span>
                                </ng-template>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.ACTIVATE_ICAL].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.email.activateICal'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="activateICAL"
                                               [(ngModel)]="systemConfigData.email[systemConfigKeys.ACTIVATE_ICAL].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.ACTIVATE_ICAL)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: FROM_EMAIL -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.FROM_EMAIL)">
                                <label translate="label.systemConfigTab.fromEmail">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.FROM_EMAIL].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.fromEmail'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.FROM_EMAIL].formValidation.isValid}"
                                          class="w-50">
                                        <input type="text" name="FROM_EMAIL" class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.email[systemConfigKeys.FROM_EMAIL].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.FROM_EMAIL)"
                                               placeholder="{{'label.emailPlaceholder'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.FROM_EMAIL)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.FROM_EMAIL) || !systemConfigValidations[systemConfigKeys.FROM_EMAIL].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.FROM_EMAIL)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.FROM_EMAIL)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.FROM_EMAIL].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.FROM_EMAIL].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: EMAIL_DISPLAY_NAME -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.EMAIL_DISPLAY_NAME)">
                                <label translate="label.systemConfigTab.emailDisplayName">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.email[systemConfigKeys.EMAIL_DISPLAY_NAME].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.emailDisplayName'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <input type="text" name="EMAIL_DISPLAY_NAME" class="form-control rounded-0 w-50"
                                           [(ngModel)]="systemConfigData.email[systemConfigKeys.EMAIL_DISPLAY_NAME].ngModel"
                                           placeholder="{{'label.systemConfigTab.emailDisplayName'|translate}}"
                                           autocomplete="off">
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.EMAIL_DISPLAY_NAME)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.EMAIL_DISPLAY_NAME)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.EMAIL_DISPLAY_NAME)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.EMAIL_DISPLAY_NAME)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Gender -->
                <li [ngbNavItem]="3" *ngIf="isTabVisible(systemConfigTabsTypeEnum.gender)">
                    <a ngbNavLink>{{'label.systemConfigTab.tabs.gender.title' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: GENDER_ALLOWED_MALE -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.GENDER_ALLOWED_MALE)">
                                <label translate="label.male"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_MALE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.male'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="genderAllowedMale"
                                               [(ngModel)]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_MALE].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.GENDER_ALLOWED_MALE)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: GENDER_ALLOWED_FEMALE -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.GENDER_ALLOWED_FEMALE)">
                                <label translate="label.female"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_FEMALE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.female'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="genderAllowedFemale"
                                               [(ngModel)]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_FEMALE].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.GENDER_ALLOWED_FEMALE)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: GENDER_ALLOWED_UNDETERMINED -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.GENDER_ALLOWED_UNDETERMINED)">
                                <label translate="label.undetermined"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_UNDETERMINED].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.undetermined'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="genderAllowedUndetermined"
                                               [(ngModel)]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_UNDETERMINED].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.GENDER_ALLOWED_UNDETERMINED)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: GENDER_ALLOWED_UNKNOWN -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.GENDER_ALLOWED_UNKNOWN)">
                                <label translate="label.unknown"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_UNKNOWN].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.unknown'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="genderAllowedUnknown"
                                               [(ngModel)]="systemConfigData.gender[systemConfigKeys.GENDER_ALLOWED_UNKNOWN].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.GENDER_ALLOWED_UNKNOWN)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Location -->
                <li [ngbNavItem]="4" *ngIf="isTabVisible(systemConfigTabsTypeEnum.location)">
                    <a ngbNavLink>{{'label.systemConfigTab.tabs.restrictions.title' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS)">
                                <label translate="label.systemConfigTab.tabs.restrictions.keyRestrictReceptionToAssignedLocations"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.location[systemConfigKeys.RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.restrictions.keyRestrictReceptionToAssignedLocations'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="restrictReceptionToAssignedLocations"
                                               [(ngModel)]="systemConfigData.location[systemConfigKeys.RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: DISABLE_ALTERNATIVE_LOCATIONS -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.DISABLE_ALTERNATIVE_LOCATIONS)">
                                <label translate="label.systemConfigTab.tabs.restrictions.keyDisableAlternativeLocations"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.location[systemConfigKeys.DISABLE_ALTERNATIVE_LOCATIONS].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.restrictions.keyDisableAlternativeLocations'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="disableAlternativeLocations"
                                               [(ngModel)]="systemConfigData.location[systemConfigKeys.DISABLE_ALTERNATIVE_LOCATIONS].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.DISABLE_ALTERNATIVE_LOCATIONS)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: SLOT_SEARCH_WITH_RANGE_ACTIVE -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.SLOT_SEARCH_WITH_RANGE_ACTIVE)">
                                <label translate="label.systemConfigTab.tabs.others.keySlotSearchWithRangeActive"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.location[systemConfigKeys.SLOT_SEARCH_WITH_RANGE_ACTIVE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keySlotSearchWithRangeActive'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="slotSearchWithRangeActive"
                                               [(ngModel)]="systemConfigData.location[systemConfigKeys.SLOT_SEARCH_WITH_RANGE_ACTIVE].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.SLOT_SEARCH_WITH_RANGE_ACTIVE)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Document Type -->
                <li [ngbNavItem]="5" *ngIf="isTabVisible(systemConfigTabsTypeEnum.documentType)">
                    <a ngbNavLink>{{'label.systemConfigTab.tabs.documentType.title' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: DOCUMENT_TYPES_DISABLE_NATIONAL_ID -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.DOCUMENT_TYPES_DISABLE_NATIONAL_ID)">
                                <label translate="label.systemConfigTab.tabs.documentType.keyDocumentTypesDisableNationalId"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.documentType[systemConfigKeys.DOCUMENT_TYPES_DISABLE_NATIONAL_ID].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.documentType.keyDocumentTypesDisableNationalId'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="documentTypesDisableNationalId"
                                               [(ngModel)]="systemConfigData.documentType[systemConfigKeys.DOCUMENT_TYPES_DISABLE_NATIONAL_ID].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.DOCUMENT_TYPES_DISABLE_NATIONAL_ID)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: DOCUMENT_TYPES_DISABLE_PASSPORT -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.DOCUMENT_TYPES_DISABLE_PASSPORT)">
                                <label translate="label.systemConfigTab.tabs.documentType.keyDocumentTypesDisablePassport"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.documentType[systemConfigKeys.DOCUMENT_TYPES_DISABLE_PASSPORT].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.documentType.keyDocumentTypesDisablePassport'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="documentTypesDisablePassport"
                                               [(ngModel)]="systemConfigData.documentType[systemConfigKeys.DOCUMENT_TYPES_DISABLE_PASSPORT].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.DOCUMENT_TYPES_DISABLE_PASSPORT)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Patient Access -->
                <li [ngbNavItem]="6" *ngIf="isTabVisible(systemConfigTabsTypeEnum.patientAccessConfig)">
                    <a ngbNavLink>{{'label.systemConfigTab.tabs.patientAccessSettings.title' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: FAVICON_NAME_PATIENT_PORTAL -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.FaviconName"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="faviconNameWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #faviconNameWarningPopover>
                                    <span translate="label.warning.uploadLogo"></span>
                                </ng-template>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="imagesSystemConfigs[systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL].error">
                                    {{imagesSystemConfigs[systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="FAVICON_NAME_PATIENT_PORTAL" accept="image/png"
                                               class="form-control"
                                               (change)="onImageChange(systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="imagesSystemConfigs[systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL].src"
                                         [src]="imagesSystemConfigs[systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="FAVICON_NAME_PATIENT_PORTAL">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendImageSystemConfigUpdate(systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelImageInput(systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.FAVICON_NAME_PATIENT_PORTAL)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: LOGO_NAME_PATIENT_PORTAL -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.LogoName"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="logoNamePatientPortalWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #logoNamePatientPortalWarningPopover>
                                    <span translate="label.warning.uploadLogo"></span>
                                </ng-template>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="imagesSystemConfigs[systemConfigKeys.LOGO_NAME_PATIENT_PORTAL].error">
                                    {{imagesSystemConfigs[systemConfigKeys.LOGO_NAME_PATIENT_PORTAL].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="LOGO_NAME_PATIENT_PORTAL" accept="image/png"
                                               class="form-control"
                                               (change)="onImageChange(systemConfigKeys.LOGO_NAME_PATIENT_PORTAL, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="imagesSystemConfigs[systemConfigKeys.LOGO_NAME_PATIENT_PORTAL].src"
                                         [src]="imagesSystemConfigs[systemConfigKeys.LOGO_NAME_PATIENT_PORTAL].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="LOGO_NAME_PATIENT_PORTAL">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendImageSystemConfigUpdate(systemConfigKeys.LOGO_NAME_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.LOGO_NAME_PATIENT_PORTAL)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelImageInput(systemConfigKeys.LOGO_NAME_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeImageInput(systemConfigKeys.LOGO_NAME_PATIENT_PORTAL)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL -->
                            <div class="form-group">
                                <!-- Label -->
                                <label translate="label.systemConfigTab.ResourceDefaultImagePatientPortal"></label>
                                <!-- Info popover -->
                                <i class="fas fa-exclamation-triangle ml-1 has-warning"
                                   [ngbPopover]="resourceDefaultImagePatientPortalWarningPopover"
                                   placement="bottom"
                                   popoverClass="popover-shadow"
                                   triggers="mouseenter:mouseleave"
                                ></i>
                                <ng-template #resourceDefaultImagePatientPortalWarningPopover>
                                    <span translate="label.warning.uploadResourceDefaultImage"></span>
                                </ng-template>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.warning.uploadResourceDefaultImage'"
                                ></app-display-change-log-icon>
                                <!-- In case of wrong image types / sizes / dpi -->
                                <div class="help-block mb-1"
                                     *ngIf="filesSystemConfigs[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].error">
                                    {{filesSystemConfigs[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].error}}
                                </div>
                                <span class="d-flex align-items-center">
                                    <!-- Upload Image button -->
                                    <label class="custom-file-upload mb-0 d-flex justify-content-start align-items-center">
                                        <span translate="label.systemConfigTab.uploadImage"></span>
                                        <input type="file" name="RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL" accept="image/png" class="form-control"
                                               (change)="onFileChange(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL, $event)">
                                    </label>
                                    <!-- Image preview -->
                                    <img *ngIf="filesSystemConfigs[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].src"
                                         [src]="filesSystemConfigs[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].src"
                                         class="ml-2 mr-2 system-config-image-preview"
                                         alt="RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL">
                                    <!-- Save button -->
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="uploadResourceDefaultImage(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeFileInput(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL)"
                                            translate="button.save">
                                    </button>
                                    <!-- Cancel button -->
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelFileInput(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL)"
                                            [disabled]="!didUserChangeFileInput(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL)"
                                            translate="label.cancel">
                                    </button>
                                    <!-- Delete button -->
                                    <button *ngIf="!!filesSystemConfigs[systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL].src"
                                            class="btn alert-btn ml-1" type="button"
                                            (click)="deleteResourceDefaultImage(systemConfigKeys.RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL)"
                                            translate="label.delete">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: CENTER_LOCK -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.CENTER_LOCK)">
                                <label translate="label.lockCenter"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.CENTER_LOCK].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.lockCenter'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="lockCenter"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.CENTER_LOCK].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.CENTER_LOCK)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: CLIENT_IDENTIFICATION_DISABLE_CAPTCHA -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA)">
                                <label translate="label.clientIdentificationDisableCaptcha"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.clientIdentificationDisableCaptcha'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="clientIdentificationDisableCaptcha"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.CLIENT_IDENTIFICATION_DISABLE_CAPTCHA)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: CREATE_PORTAL_DISABLE_CAPTCHA -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA)">
                                <label translate="label.createPortalDisableCaptcha"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.createPortalDisableCaptcha'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="createPortalDisableCaptcha"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.CREATE_PORTAL_DISABLE_CAPTCHA)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: BOOKING_PORTAL_DISABLE_CAPTCHA -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA)">
                                <label translate="label.bookingPortalDisableCaptcha"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.bookingPortalDisableCaptcha'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="bookingPortalDisableCaptcha"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.BOOKING_PORTAL_DISABLE_CAPTCHA)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: WORK_ORDER_PORTAL_DISABLE_CAPTCHA -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA)">
                                <label translate="label.workOrderPortalDisableCaptcha"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.workOrderPortalDisableCaptcha'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="workOrderPortalDisableCaptcha"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.WORK_ORDER_PORTAL_DISABLE_CAPTCHA)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT)">
                                <label translate="label.servicesAndResourceSearchIncludeServiceNameSingleHit"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.servicesAndResourceSearchIncludeServiceNameSingleHit'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked
                                               name="servicesAndResourceSearchIncludeServiceNameSingleHit"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2 -->
                            <!-- TODO Starting with 2.17 all the clients will have the new slots results UI on patient access as default -->
                            <!-- TODO Remove this config starting with 2.18 -->
                            <div class="form-group"
                                 *ngIf="false">
                                <label translate="label.patientAccessActivateResultLayoutV2"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.patientAccessActivateResultLayoutV2'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="patientAccessActivateResultLayoutV2"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_LOGO_TOP -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_LOGO_TOP)">
                                <label translate="label.patientAccessLogoTop"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_LOGO_TOP].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.patientAccessLogoTop'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="patientAccessLogoTop"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_LOGO_TOP].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_LOGO_TOP)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_LAST_NAME_SPLIT -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_LAST_NAME_SPLIT)">
                                <label translate="label.lastNameSplit"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_LAST_NAME_SPLIT].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.lastNameSplit'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="patientAccessLastNameSplit"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_LAST_NAME_SPLIT].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_LAST_NAME_SPLIT)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- PATIENT_ACCESS_TASK_TYPE -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)">
                                <label translate="label.taskTypeForWaitList" class="mb-0"></label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_TASK_TYPE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.taskTypeForWaitList'"
                                ></app-display-change-log-icon>
                                <div class="d-flex align-items-center">
                                    <ng-select class="w-30"
                                               loadingText="{{'label.loading' | translate}}"
                                               dropdownPosition="bottom"
                                               [ngModelOptions]="{standalone: true}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_TASK_TYPE].ngModel"
                                               (ngModelChange)="sanitizeNgSelectValue(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)">
                                        <ng-option *ngFor="let type of waitListTaskTypesList" [value]="type">
                                            <span [translate]="'enum.taskProcessingType.' + type"></span>
                                        </ng-option>
                                    </ng-select>
                                    <button class="btn theme-btn ml-2" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_TASK_TYPE)"
                                            translate="label.cancel">
                                    </button>
                                </div>
                            </div>
                            <!-- FIELD: WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE)">
                                <label translate="label.systemConfigTab.WaitListManualDueDateExpirationTimeInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.WaitListManualDueDateExpirationTimeInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE)"
                                               placeholder="{{'label.systemConfigTab.WaitListManualDueDateExpirationTimeInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span id="WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE',
                                                systemConfigData.patientAccessConfig[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].ngModel)"
                                        ></app-human-readable-minutes>
                                    </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE) || !systemConfigValidations[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: GOOGLE_TAG_MANAGER_ID -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.GOOGLE_TAG_MANAGER_ID)">
                                <label translate="label.systemConfigTab.googleTagManagerId">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.GOOGLE_TAG_MANAGER_ID].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.googleTagManagerId'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <input type="text" name="GOOGLE_TAG_MANAGER_ID" class="form-control rounded-0 w-50"
                                           [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.GOOGLE_TAG_MANAGER_ID].ngModel"
                                           placeholder="{{'label.systemConfigTab.googleTagManagerId'|translate}}"
                                           autocomplete="off">
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.GOOGLE_TAG_MANAGER_ID)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.GOOGLE_TAG_MANAGER_ID)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.GOOGLE_TAG_MANAGER_ID)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.GOOGLE_TAG_MANAGER_ID)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)">
                                <label translate="label.systemConfigTab.ContactCenterPhoneNumber">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.ContactCenterPhoneNumber'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <input type="text" name="PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER"
                                           class="form-control rounded-0 w-50"
                                           [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER].ngModel"
                                           (ngModelChange)="updateValidation(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)"
                                           placeholder="{{'label.systemConfigTab.ContactCenterPhoneNumber'|translate}}"
                                           autocomplete="off">
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)">
                                <label translate="label.systemConfigTab.SelectedCenterShortId">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.SelectedCenterShortId'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <input type="text" name="PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID"
                                           class="form-control rounded-0 w-50"
                                           [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID].ngModel"
                                           (ngModelChange)="updateValidation(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)"
                                           placeholder="{{'label.systemConfigTab.SelectedCenterShortId'|translate}}"
                                           autocomplete="off">
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                            <!-- FIELD: PATIENT_ACCESS_CUSTOM_CSS -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS)">
                                <label translate="label.systemConfigTab.tabs.patientAccessSettings.customCssCode">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.patientAccessSettings.customCssCode'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-start">
                                    <textarea type="text" name="PATIENT_ACCESS_CUSTOM_CSS"
                                              class="form-control rounded-0 w-100 mr-3"
                                              [(ngModel)]="systemConfigData.patientAccessConfig[systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS].ngModel"
                                              placeholder="{{'label.systemConfigTab.tabs.patientAccessSettings.customCssCodeTextArea'|translate}}"
                                              autocomplete="off">
                                    </textarea>
                                </span>
                                <span class="d-flex align-items-start justify-content-end mt-2 mr-3">
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS)"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.PATIENT_ACCESS_CUSTOM_CSS)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Task Configuration -->
                <li [ngbNavItem]="7"
                    *ngIf="isTabVisible(systemConfigTabsTypeEnum.taskConfiguration)">
                    <a ngbNavLink>{{'label.taskConfiguration' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: WAITLIST_EXPIRATION_TIME_IN_MINUTES -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES)">
                                <label translate="label.systemConfigTab.WaitListExpirationTimeInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.WaitListExpirationTimeInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="WAITLIST_EXPIRATION_TIME_IN_MINUTES"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES)"
                                               placeholder="{{'label.systemConfigTab.WaitListExpirationTimeInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span id="WAITLIST_EXPIRATION_TIME_IN_MINUTES" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('WAITLIST_EXPIRATION_TIME_IN_MINUTES',
                                                systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].ngModel)"
                                        ></app-human-readable-minutes>
                                    </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES) || !systemConfigValidations[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.WAITLIST_EXPIRATION_TIME_IN_MINUTES].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)">
                                <label translate="label.systemConfigTab.WaitListMinimumTimeForFutureAppointmentsInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.WaitListMinimumTimeForFutureAppointmentsInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number"
                                               name="WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                               placeholder="{{'label.systemConfigTab.WaitListMinimumTimeForFutureAppointmentsInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span id="WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES',
                                                systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].ngModel)"
                                        ></app-human-readable-minutes>
                                    </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES) || !systemConfigValidations[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)">
                                <label translate="label.systemConfigTab.WaitListMaximumTimeForFutureAppointmentsInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.WaitListMaximumTimeForFutureAppointmentsInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number"
                                               name="WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                               placeholder="{{'label.systemConfigTab.WaitListMaximumTimeForFutureAppointmentsInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span id="WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES',
                                                systemConfigData.taskConfiguration[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].ngModel)"
                                        ></app-human-readable-minutes>
                                    </span>
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES) || !systemConfigValidations[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: APPOINTMENT_BLOCKING_DUE_DATE -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE)">
                                <label translate="label.systemConfigTab.DueDateForBlockedAppointmentTaskInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.taskConfiguration[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.DueDateForBlockedAppointmentTaskInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number"
                                               name="APPOINTMENT_BLOCKING_DUE_DATE"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.taskConfiguration[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE)"
                                               placeholder="{{'label.systemConfigTab.DueDateForBlockedAppointmentTaskInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                     <span id="APPOINTMENT_BLOCKING_DUE_DATE" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('APPOINTMENT_BLOCKING_DUE_DATE',
                                                systemConfigData.taskConfiguration[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].ngModel)"
                                        ></app-human-readable-minutes>
                                     </span>
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE) || !systemConfigValidations[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.APPOINTMENT_BLOCKING_DUE_DATE].formValidation.errorMessage"></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Reservation Life Times -->
                <li [ngbNavItem]="8" *ngIf="isTabVisible(systemConfigTabsTypeEnum.reservationLifeTimes)">
                    <a ngbNavLink>{{'label.reservationLifetime' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: SKED_TASK_TYPE_STANDARD -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.SKED_TASK_TYPE_STANDARD)">
                                <label translate="label.systemConfigTab.StandardTimeInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_STANDARD].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.StandardTimeInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_STANDARD].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="SKED_TASK_TYPE_STANDARD"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_STANDARD].ngModel"
                                               (ngModelChange)="updateJSONValidation(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_STANDARD], systemConfigKeys.SKED_TASK_TYPE_STANDARD)"
                                               placeholder="{{'label.systemConfigTab.StandardTimeInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                     <span id="SKED_TASK_TYPE_STANDARD" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('SKED_TASK_TYPE_STANDARD',
                                                systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_STANDARD].ngModel)"
                                        ></app-human-readable-minutes>
                                     </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendJSONSystemConfigUpdate(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_STANDARD], systemConfigKeys.SKED_TASK_TYPE_STANDARD)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_STANDARD, true) || !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_STANDARD].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.SKED_TASK_TYPE_STANDARD, true)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_STANDARD, true)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_STANDARD].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_STANDARD].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: SKED_TASK_TYPE_WAIT_LIST -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST)">
                                <label translate="label.systemConfigTab.WaitListTimeInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.WaitListTimeInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="SKED_TASK_TYPE_WAIT_LIST"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].ngModel"
                                               (ngModelChange)="updateJSONValidation(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST], systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST)"
                                               placeholder="{{'label.systemConfigTab.WaitListTimeInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                     <span id="SKED_TASK_TYPE_WAIT_LIST" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('SKED_TASK_TYPE_WAIT_LIST',
                                                systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].ngModel)"
                                        ></app-human-readable-minutes>
                                     </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendJSONSystemConfigUpdate(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST], systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST, true) || !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST, true)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST, true)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_WAIT_LIST].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER)">
                                <label translate="label.systemConfigTab.AppointmentRequestTimeInMinutes">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.AppointmentRequestTimeInMinutes'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].formValidation.isValid}"
                                          class="w-20">
                                        <input type="number" name="SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER"
                                               class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].ngModel"
                                               (ngModelChange)="updateJSONValidation(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER], systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER)"
                                               placeholder="{{'label.systemConfigTab.AppointmentRequestTimeInMinutes'|translate}}"
                                               autocomplete="off">
                                    </span>
                                      <span id="SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER" class="mx-2">
                                        <app-human-readable-minutes
                                                [options]="getOptionsForHumanReadableMinutesComponent('SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER',
                                                systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].ngModel)"
                                        ></app-human-readable-minutes>
                                      </span>
                                    <button class="btn theme-btn" type="submit"
                                            (click)="sendJSONSystemConfigUpdate(systemConfigData.reservationLifeTimes[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER], systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER, true) || !systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER, true)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER, true)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER].formValidation.errorMessage"></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- TAB: Others -->
                <li [ngbNavItem]="9" *ngIf="isTabVisible(systemConfigTabsTypeEnum.others)">
                    <a ngbNavLink>{{'label.systemConfigTab.tabs.others.title' | translate}}</a>
                    <ng-template ngbNavContent>
                        <div class="tab-content-body">
                            <!-- FIELD: TIME_WINDOW_MAXIMUM -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.TIME_WINDOW_MAXIMUM)">
                                <label translate="label.systemConfigTab.TimeWindowMaximum">
                                </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.TIME_WINDOW_MAXIMUM].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.TimeWindowMaximum'"
                                ></app-display-change-log-icon>
                                <span class="d-flex align-items-center">
                                    <span [ngClass]="{ 'has-error': !systemConfigValidations[systemConfigKeys.TIME_WINDOW_MAXIMUM].formValidation.isValid}"
                                          class="w-25">
                                        <input type="number" name="TIME_WINDOW_MAXIMUM" class="form-control rounded-0"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.TIME_WINDOW_MAXIMUM].ngModel"
                                               (ngModelChange)="updateValidation(systemConfigKeys.TIME_WINDOW_MAXIMUM)"
                                               placeholder="{{'label.systemConfigTab.TimeWindowMaximum'|translate}}"
                                               autocomplete="off">
                                    </span>
                                    <span class="ml-1 mr-2">{{'label.systemConfigTab.days'|translate}}</span>
                                    <button class="btn theme-btn ml-1" type="submit"
                                            (click)="sendSystemConfigUpdate(systemConfigKeys.TIME_WINDOW_MAXIMUM)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.TIME_WINDOW_MAXIMUM) || !systemConfigValidations[systemConfigKeys.TIME_WINDOW_MAXIMUM].formValidation.isValid"
                                            translate="button.save">
                                    </button>
                                    <button class="btn close-btn ml-1" type="button"
                                            (click)="onCancelTextInput(systemConfigKeys.TIME_WINDOW_MAXIMUM)"
                                            [disabled]="!didUserChangeTextInput(systemConfigKeys.TIME_WINDOW_MAXIMUM)"
                                            translate="label.cancel">
                                    </button>
                                </span>
                                <div *ngIf="!systemConfigValidations[systemConfigKeys.TIME_WINDOW_MAXIMUM].formValidation.isValid"
                                     class="help-block">
                                    <span [translate]="systemConfigValidations[systemConfigKeys.TIME_WINDOW_MAXIMUM].formValidation.errorMessage"></span>
                                </div>
                            </div>
                            <!-- FIELD: SELF_SERVICE_PASSWORD_CHANGE -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.SELF_SERVICE_PASSWORD_CHANGE)">
                                <label translate="label.changePassword"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.SELF_SERVICE_PASSWORD_CHANGE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.changePassword'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="changePassword"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.SELF_SERVICE_PASSWORD_CHANGE].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.SELF_SERVICE_PASSWORD_CHANGE)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: AUTO_APPROVE_OFFICE_365_EXCEPTIONS -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.AUTO_APPROVE_OFFICE_365_EXCEPTIONS)">
                                <label translate="label.systemConfigTab.tabs.others.keyAutoApproveOffice365Exceptions"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.AUTO_APPROVE_OFFICE_365_EXCEPTIONS].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keyAutoApproveOffice365Exceptions'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="autoApproveOffice365Exceptions"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.AUTO_APPROVE_OFFICE_365_EXCEPTIONS].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.AUTO_APPROVE_OFFICE_365_EXCEPTIONS)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: RESTRICT_RESOURCE_TO_ASSIGNED_DATA -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.RESTRICT_RESOURCE_TO_ASSIGNED_DATA)">
                                <label translate="label.systemConfigTab.tabs.others.keyRestrictResourceToAssignedData"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.RESTRICT_RESOURCE_TO_ASSIGNED_DATA].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keyRestrictResourceToAssignedData'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="restrictResourceToAssignedData"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.RESTRICT_RESOURCE_TO_ASSIGNED_DATA].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.RESTRICT_RESOURCE_TO_ASSIGNED_DATA)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK)">
                                <label translate="label.systemConfigTab.tabs.others.keyAppointmentStatusUpdateDisableTimeCheck"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keyAppointmentStatusUpdateDisableTimeCheck'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="appointmentStatusUpdateDisableTimeCheck"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER -->
                            <div class="form-group"
                                 *ngIf="isFieldVisible(systemConfigKeys.PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER)">
                                <label translate="label.systemConfigTab.tabs.others.keyPatientPortalAlwaysIncludeSelfPayer"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keyPatientPortalAlwaysIncludeSelfPayer'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="patientPortalAlwaysIncludeSelfPayer"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- FIELD: NO_PAYMENT_FOR_RESCHEDULE -->
                            <div class="form-group" *ngIf="isFieldVisible(systemConfigKeys.NO_PAYMENT_FOR_RESCHEDULE)">
                                <label translate="label.systemConfigTab.tabs.others.keyNoPaymentForReschedule"> </label>
                                <app-display-change-log-icon
                                    [EntityId]="systemConfigData.others[systemConfigKeys.NO_PAYMENT_FOR_RESCHEDULE].item.id"
                                    [EntityType]="EntityTypeEnum.systemConfiguration"
                                    [EntityTranslationKey]="'label.systemConfigTab.tabs.others.keyNoPaymentForReschedule'"
                                ></app-display-change-log-icon>
                                <div class="switch">
                                    <label>
                                        <span translate="label.no"></span>
                                        <input type="checkbox" checked name="noPaymentForReschedule"
                                               [(ngModel)]="systemConfigData.others[systemConfigKeys.NO_PAYMENT_FOR_RESCHEDULE].ngModel"
                                               (ngModelChange)="sendSystemConfigUpdate(systemConfigKeys.NO_PAYMENT_FOR_RESCHEDULE)">
                                        <span class="lever switch-col-black"></span>
                                        <span translate="label.yes"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <!-- Tabs content will be moved inside this: -->
        <div class="system-config-section-right col-7 col-xl-8">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
