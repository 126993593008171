import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    Expand,
    MultiResourceBluePrintDependentFiltersType,
    MultiResourceCombinationType,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    ResourceType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {MultiResourceCombinationFiltersType} from './multi-resource-combination.types';

@Injectable({
    providedIn: 'root'
})
export class MultiResourceCombinationMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialResourceFilterValues: ResourceType[] = [];

    constructor(private generalUtils: GeneralUtils) {
    }

    getCreateMultiResourceCombinationInitialFilterValues(): MultiResourceCombinationFiltersType {
        return {
            multiResourceBlueprint: [],
            mainResource: [],
        };
    }

    getMultiResourceBlueprintDependentFilters(): MultiResourceBluePrintDependentFiltersType {
        return {
            searchPhrase: '',
            active: true,
            exclusionList: []
        };
    }

    getResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            resourceTypeId: null,
            exclusionList: [],
            resourceTypeExclusionList: [],
            count: true,
            includeAvailabilities: false,
            onlyDirectlyBookable: false,
            onlyAssignedToUser: false,
            onlyAssignedToLocationsOfUser: false,
            includeSelfPayer: false
        } as ResourceDependentFiltersType;
    }

    getQueryFilterForMultiResourceBlueprint(blueprintId: string): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'RowVersion'],
            count: true,
            filter: this.getFilterQuery({id: blueprintId}),
            expand: {
                Items: {
                    select: ['Id', 'ResourceTypeId', 'isMainResource']
                }
            }
        };
    }

    getQueryFilterForMultiResourceCombinationMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'BluePrintId', 'MainResourceId', 'Type', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            BluePrint: {select: ['Id', 'Name']},
            MainResource: {select: ['Id', 'Name', 'ResourceTypeId']},
            Items: {
                select: ['Id', 'ResourceId', 'IsMainResource'],
                expand: {
                    Resource: {
                        select: ['Id', 'Name', 'ResourceTypeId']
                    }
                }
            }
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {BluePrintId: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'resource': {
                        filterQuery['Items'] = {any: {'Resource/Id': {eq: {type: 'guid', value: filter[item]}}}};
                        break;
                    }
                    case 'id': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + '/Name' + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialMultiResourceCombination(): MultiResourceCombinationType {
        return {
            bluePrintId: '',
            items: [],
            timeSlots: []
        } as MultiResourceCombinationType;
    }
}
