<div class="container-fluid create-lead-time-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-lead-time-rule-header">
        <h3 class="create-lead-time-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.leadTimeRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect"
                                     [ngClass]="{'has-error': !ruleItem?.leadTimeRuleTable?.id}">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.leadTimeRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.leadTimeRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.leadTimeRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.name"></label>
                                    <span [ngClass]="{ 'has-error': !ruleItem.name}">
                                        <input type="text" name="name" class="form-control rounded-0 pl-0"
                                               [(ngModel)]="ruleItem.name"
                                               placeholder="{{'label.name'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="!ruleItem.name" class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule only warning-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.onlyWarning"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.onlyWarning"
                                                   name="onlyWarning">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2 help-block" *ngIf="!isInputStepValid(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.error.leadTimeRuleInvalidMinimumConditions">
                            </span>
                        </div>
                        <div class="row">
                            <!-- Channel -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.channel"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedChannel($event)"
                                                  [buttonName]="'label.channel'"
                                                  [dependentFilters]="mainDependentFilters.channel"
                                                  [initialFilterValues]="initialChannel"
                                                  [providerInstance]="channelProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Area -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.area2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedArea($event)"
                                                  [buttonName]="'label.area2'"
                                                  [dependentFilters]="mainDependentFilters.area"
                                                  [initialFilterValues]="[ruleItem.area]"
                                                  [providerInstance]="areaProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Service -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect service">
                                    <label translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                  [buttonName]="'label.service2'"
                                                  [dependentFilters]="mainDependentFilters.service"
                                                  [initialFilterValues]="[ruleItem.service]"
                                                  [providerInstance]="serviceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                            <!-- Resource -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]="'label.resource'"
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [initialFilterValues]="[ruleItem.resource]"
                                                  [providerInstance]="resourceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Minutes -->
                            <!-- =========================================== -->
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.minutes"></label>
                                        <span [ngClass]="{'has-error': generalUtils.isNullOrUndefined(ruleItem.minutes) || !isMinutesValid(ruleItem.minutes)}">
                                            <input type="number" class="form-control rounded-0"
                                                   placeholder="{{'label.minutes' | translate}}"
                                                   autocomplete="off" min="0" max="525000"
                                                   [(ngModel)]="ruleItem.minutes">
                                        </span>
                                    </div>
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem.minutes)"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem.minutes) && !isMinutesValid(ruleItem.minutes)"
                                         class="help-block">
                                        <span translate="label.error.invalidNumber"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Message -->
                            <!-- =========================================== -->
                            <div class="col-9">
                                <div class="form-group">
                                    <div class="rules-text-filter">
                                        <label class="mb-1 mr-2" translate="label.message"></label>
                                        <span [ngClass]="{'has-error': !ruleItem?.message}">
                                            <input type="text" class="form-control rounded-0"
                                                   placeholder="{{'label.message' | translate}}"
                                                   autocomplete="off"
                                                   [(ngModel)]="ruleItem.message">
                                        </span>
                                    </div>
                                    <div *ngIf="!ruleItem?.message"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.message}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleTable"></td>
                                                <td class="text-right">{{ruleItem.leadTimeRuleTable?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.name"></td>
                                                <td class="text-right">{{ruleItem.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.active"></td>
                                                <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.onlyWarning"></td>
                                                <td class="text-right">{{ruleItem.onlyWarning | yesNoPipe | translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.channel"></td>
                                                <td class="text-right">{{'enum.channel.' + ruleItem.channel |
                                                    translate}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.area2"></td>
                                                <td class="text-right">{{ruleItem.area?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.service2"></td>
                                                <td class="text-right">
                                                    <span class="font-weight-bold"> {{ruleItem.service?.speciality?.name}} </span>
                                                    {{ruleItem.service?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.resource"></td>
                                                <td class="text-right">{{ruleItem.resource?.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.minutes"></td>
                                                <td class="text-right">{{ruleItem.minutes}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.message"></td>
                                                <td class="text-right">{{ruleItem.message}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'LeadTimeRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
