import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {
    AvailabilityDataType,
    DateRangeOptionsType,
    FilterWrapperNameEnum,
    GenericFilterOptionsType,
    GenericFilterResultType,
    IdNameType,
    ResponseValidationType,
    ServiceSearchType,
} from 'sked-base';
import {
    DateRangeModalFilterOptionsType, IgnoreHolidaysOptionType, ModalFiltersType,
    OnlineConsultationModalFilterOptionsType, RoomReservationNeededOptionType
} from '../../shared/component/modal-filters/modal-filters.types';
import {TableFiltersType} from '../../data-model/general.type';

export interface ValidCreateAvailabilityType {
    isCenterValid: boolean;
}

export interface BackofficeAvailabilityDataType extends AvailabilityDataType {
    isValid: boolean;
}

export interface BackofficeAvailabilityServiceType {
    service: ServiceSearchType;
    editedIndex: number;
    duration: number;
    controlDuration: number;
    defaultDuration: number;
    groupAppointmentSlots: number;
    availabilityId: string;
    serviceId: string;
    name: string;
    id: string;
    showDurationForMainResource: boolean;
    multiResourceBluePrintId: string;
    minSubServices: number;
    maxSubServices: number;
    tags: IdNameType[];
    coveragePlans: IdNameType[];
    subServices: BackofficeAvailabilitySubServiceType[];
    onlineConsultation?: boolean;
}

export interface BackofficeAvailabilitySubServiceType {
    duration: number;
    availabilityServiceId: string;
    subServiceId: string;
    id: string;
    name: string;
}

export interface BackofficeAvailabilityTimeSlotType {
    id: string;
    hourFrom: number;
    hourTo: number;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    selectedDays: string[];
    hourFromTime: string;
    hourToTime: string;
    editedIndex: number;
    appointmentTypes: BackofficeAvailabilityTimeSlotAppointmentTypeType[];
}

export interface BackofficeAvailabilityTimeSlotAppointmentTypeType {
    duration: number;
    quantity: number;
    consumesPlannedCapacity: boolean;
    id: string;
    name: string;
    appointmentTypeId: string;
}

export enum AvailabilityStatusEnum {
    NEW = 'New',
    EDITED = 'Edited',
    MARKED_FOR_DELETE = 'MarkedForDelete',
    APPROVED = 'Approved',
    DELETED = 'Deleted',
    PENDING_SPLIT = 'PendingSplit',
}

export enum AvailabilityViewPageEnum {
    AvailabilityOverview = 'availability',
    ApproveAvailabilityOverview = 'approveAvailability',
    ApproveAvailabilitySplitOverview = 'approveAvailabilitySplit',
}

export interface AvailabilityTemplateLayoutType {
    pageTitle: string;
    viewType: AvailabilityViewPageEnum;
}

export interface BackofficeAvailabilitySplitDataType {
    id: string;
    dateFrom: NgbDateStruct;
    dateTo: NgbDateStruct;
    timeFrom: NgbTimeStruct;
    timeTo: NgbTimeStruct;
    splitFrom: string;
    splitTo: string;
}

export enum BackofficeAvailabilitySplitDateTypeEnum {
    SplitFrom = 'SplitFrom',
    SplitTo = 'SplitTo'
}

export interface AvailabilitySplitValidationType {
    isValid: boolean;
    errorMessage: string;
}

export interface AvailabilityValidationType extends ResponseValidationType {
    isValid: boolean;
}

export enum AvailabilityTypeEnum {
    Availability = 'Availability',
    Exclusion = 'Exclusion'
}

export interface DateAndTimeRangeType {
    timeFrom: NgbTimeStruct;
    timeTo: NgbTimeStruct;
    dateFrom: NgbDateStruct;
    dateTo: NgbDateStruct;
}

export interface ModalFilterOptionsType {
    dateRangeModalFilterOptions: DateRangeModalFilterOptionsType;
    onlineConsultationModalFilterOptions: OnlineConsultationModalFilterOptionsType;
    roomReservationNeededFilterOptions: RoomReservationNeededOptionType;
    ignoreHolidaysFilterOptions: IgnoreHolidaysOptionType;
}

export interface AvailabilityExclusionIntraPageContextStateFiltersType {
    filterWrapperInitialValues?: {
        [key in FilterWrapperNameEnum]?: GenericFilterResultType
    };
    dateRangeOptions?: DateRangeOptionsType;
    modalFilters?: ModalFiltersType;
    itemsPerPageList?: number[];
    tableFilters?: TableFiltersType;
    filterWrapperOptions?: GenericFilterOptionsType[];
}

export interface AvailabilityExclusionIntraAvailabilityManagementContextStateFiltersType {
    filterWrapperInitialValues: {
        [key in FilterWrapperNameEnum]?: GenericFilterResultType
    };
}

export interface AvailabilitiesTimeInputsDefaultTimesType {
    timeFrom?: string;
    timeTo?: string;
}
