<div class="container-fluid availability-approve-view-screen overview-screen-md create-entity-md-wrapper pb-1">
    <div class="create-entity-md-section">
        <h3 class="text-danger" *ngIf="action !== constants.VIEW">
            {{textToDisplay | translate}}
        </h3>
        <h3 class="create-availability-title" *ngIf="action === constants.VIEW">{{textToDisplay | translate}}
            <span class="text-lowercase" translate="label.availability2"></span>
        </h3>

        <div class="text-danger mb-2">{{responseValidation.error}}</div>
        <div class="text-warning" *ngFor="let warning of responseValidation.warnings">{{warning}}</div>
        <div class="mb-3"></div>

        <app-view-availability [availability]="availability"></app-view-availability>
        <div class="mt-2">
            <button class="btn mr-3"
                    (click)="goToParent()"
                    [ngClass]="action === constants.VIEW ? 'back-btn' : 'close-btn'"
                    translate="{{action === constants.VIEW ? 'button.back' : 'label.no'}}">
            </button>
            <span [ngClass]="{'disabled-button': responseValidation.error}" *ngIf="action === constants.APPROVE || action === constants.DECLINE">
                <button class="btn theme-btn"
                        (click)="onChangeStatusAvailability(availability.id, action)"
                        translate="label.yes">
                </button>
            </span>
            <span [ngClass]="{'disabled-button': responseValidation.error}" *ngIf="action === constants.VIEW">
                <button class="btn theme-btn mr-3"
                        (click)="onChangeStatusAvailability(availability.id, constants.APPROVE)"
                        translate="label.availabilityTab.approve">
                </button>
            </span>
            <span *ngIf="action === constants.VIEW">
                <button class="btn close-btn"
                        (click)="onChangeStatusAvailability(availability.id, constants.DECLINE)"
                        translate="label.availabilityTab.decline">
                </button>
            </span>
        </div>
    </div>
</div>
