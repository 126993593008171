<div class="upload-image-container">
    <!-- INFO IMAGE SIZE -->
    <div *ngIf="!options?.disabled">
        <label id="upload-image-label" class="mr-2 text-dark"
               [translate]="options?.labelText">
        </label>
        <i class="fas fa-exclamation-triangle ml-1 has-warning"
           [ngbPopover]="logoNameWarningPopover"
           placement="bottom"
           popoverClass="popover-shadow"
           triggers="mouseenter:mouseleave">
        </i>
        <ng-template #logoNameWarningPopover>
            <span translate="label.uploadAreaIcon"></span>
        </ng-template>
    </div>
    <!-- UPLOAD IMAGE -->
    <div class="drop-zone-wrapper">
        <ngfFormData
                [files]="uploadedFiles"
                postName="file"
                [(FormData)]="formDataToSend">
        </ngfFormData>
        <div class="well my-drop-zone mb-2"
             ngfDrop
             multiple="1"
             selectable="1"
             [ngClass]="{'cursor-not-allowed': options?.disabled}"
             [accept]="uploadFileUtils.acceptedFormatsString"
             [maxSize]="uploadFileUtils.uploadFileOptions?.maxAllowedFileSize"
             [fileDropDisabled]="uploadFileUtils.uploadFileOptions?.disableDropArea || options?.disabled"
             [(validDrag)]="validUploadedItems"
             [(files)]="uploadedFiles"
             [(file)]="uploadFileUtils.uploadedFile"
             [(lastInvalids)]="uploadFileUtils.lastInvalids"
             [class.invalid-drag]="validUploadedItems===false"
             [class.valid-drag]="validUploadedItems"
             (filesChange)="onUploadImage()">
            <div class="drop-zone-label">
                <div *ngIf="!isFileTypeBlob(uploadFileUtils.uploadedFile)">
                    <img src="./../../../assets/images/upload.png" class="img-fluid"
                         alt="upload-img">
                    <label class="d-block mt-2" translate="uploadFile.selectOrDropFile"></label>
                </div>
                <div *ngIf="isFileTypeBlob(uploadFileUtils.uploadedFile)"
                     class="upload-image-preview">
                    <div class="previewIcon" [ngfBackground]="uploadFileUtils.uploadedFile"></div>
                </div>
            </div>
        </div>
    </div>
    <!--ACCEPTED FORMATS-->
    <strong>{{'uploadFile.acceptedFormats' | translate}} {{uploadFileUtils.acceptedFormatsString}}</strong>
    <!--INVALID FORMAT-->
    <strong *ngIf="!uploadFileUtils.uploadedFile?.name && uploadFileUtils.lastInvalids?.length > 0"
            class="has-error">{{'label.formatNotSupported' | translate}}</strong>
    <!-- DELETE BUTTON-->
    <button (click)="displayConfirmDeleteItemModal()"
            class="btn alert-btn mt-2"
            translate="label.delete"
            type="button"
            [disabled]="!isFileTypeBlob(uploadFileUtils.uploadedFile)"
            [hidden]="options?.disabled">
    </button>
</div>
