import { Injectable } from '@angular/core';
import {
    FormAssignmentDynamicWorkListRuleType,
    Expand,
    ODataQueryObjectType, ResourceDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    FormAssignmentDynamicWorkListRuleModalFiltersType,
    FormAssignmentDynamicWorkListRuleStateType,
    FormIdModalFilterOptionsType,
    FormNameModalFilterOptionsType,
    ResourceModalFilterOptionsType,
    WorkListIdModalFilterOptionsType,
} from './form-assignment-dynamic-work-list-rule.types';
import { GeneralUtils } from '../../../../shared/utils/general.utils';
import { ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType } from '../../../../data-model/general.type';
import { RulesTableHeaderOptions } from '../../rules.types';

@Injectable({
    providedIn: 'root'
})
export class FormAssignmentDynamicWorkListRuleUtils {
    overviewState: FormAssignmentDynamicWorkListRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'formAssignmentDynamicWorkListRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resource',
            hasSort: true,
            onSortByProperty: 'resource',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.workListId',
            hasSort: true,
            onSortByProperty: 'workListId',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.formId',
            hasSort: true,
            onSortByProperty: 'formId',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.formDisplayName',
            hasSort: true,
            onSortByProperty: 'formDisplayName',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'resourceId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'formAssignmentDynamicWorkListRuleTableId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'resource' || item === 'formAssignmentDynamicWorkListRuleTable') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        return {
            Resource: {select: ['Id', 'Name']},
            FormAssignmentDynamicWorkListRuleTable: {select: ['Id', 'Name', 'Description']},
        };
    }

    getInitialRule(): FormAssignmentDynamicWorkListRuleType {
        return {
            name: undefined,
            active: false,
            action: false,
            message: undefined,
            onlyWarning: false,
            workListId: undefined,
            resourceId: undefined,
            resource: undefined,
            formDisplayName: undefined,
            formId: undefined,
            formAssignmentDynamicWorkListRuleTableId: undefined,
            formAssignmentDynamicWorkListRuleTable: undefined,
        } as FormAssignmentDynamicWorkListRuleType;
    }

    getInitialModalFilters(): FormAssignmentDynamicWorkListRuleModalFiltersType {
        return {
            resourceModalFilterOptions: {
                ngModel: undefined
            } as ResourceModalFilterOptionsType,
            workListIdModalFilterOptions: {
                ngModel: ''
            } as WorkListIdModalFilterOptionsType,
            formDisplayNameModalFilterOptions: {
                ngModel: ''
            } as FormNameModalFilterOptionsType,
            formIdModalFilterOptions: {
                ngModel: ''
            } as FormIdModalFilterOptionsType,
            areFiltersActive: false,
        } as FormAssignmentDynamicWorkListRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: FormAssignmentDynamicWorkListRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: FormAssignmentDynamicWorkListRuleType): FormAssignmentDynamicWorkListRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getEmptyResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            onlyDirectlyBookable: true,
            serviceId: null,
            locationId: null,
            coveragePlanId: null,
            areaId: null,
            onlyAssignedToUser: true,
            onlyAssignedToLocationsOfUser: true,
            resourceTypeId: null,
            includeSelfPayer: false,
            exclusionList: [],
            resourceTypeExclusionList: [],
            count: true,
        } as ResourceDependentFiltersType;
    }
}
