import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    PatientControlRuleProvider,
    PatientControlRuleType,
    ConfirmDeleteModalService,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    AreaProvider,
    SpecialityProvider,
    ServiceProvider,
    ResourceProvider,
    AreaDependentFiltersType,
    SpecialityDependentFiltersType,
    ServiceDependentFiltersType,
    ResourceDependentFiltersType,
    AreaType,
    SpecialityType,
    ServiceType,
    ResourceType,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PatientControlRuleUtils} from './patient-control-rule.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {RulesUtils} from '../../rules.utils';
import {RulesInitialOverviewFiltersType, RulesTableHeaderOptions} from '../../rules.types';
import {ConfigDataService} from '../../../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-patient-control-rule',
    templateUrl: './patient-control-rule.component.html',
    styleUrls: ['./patient-control-rule.component.scss']
})
export class PatientControlRuleComponent implements OnInit, OnDestroy {
    tableHeaderColumns: RulesTableHeaderOptions[] = this.patientControlRuleUtils.getRuleTableHeaderOptions();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    ruleList: PatientControlRuleType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    VIEW_ACTIVITY_ENABLED = false;
    searchRuleByNameChanged = new Subject<string>();
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.PatientControlRule);
    mainDependentFilters: {
        area: AreaDependentFiltersType,
        speciality: SpecialityDependentFiltersType,
        service: ServiceDependentFiltersType,
        resource: ResourceDependentFiltersType,
    };
    initialFilters = {
        tableFilters: this.patientControlRuleUtils.getInitialTableFilter(),
        modalFilters: this.patientControlRuleUtils.getInitialModalFilters()
    } as RulesInitialOverviewFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public patientControlRuleUtils: PatientControlRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public areaProvider: AreaProvider,
        public specialityProvider: SpecialityProvider,
        public serviceProvider: ServiceProvider,
        public resourceProvider: ResourceProvider,
        private confirmDeleteService: ConfirmDeleteModalService,
        private generalUtils: GeneralUtils,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private router: Router,
        private patientControlRuleProvider: PatientControlRuleProvider,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(`PatientControlRuleRead`);
        this.rulesUtils.loadFilters(RuleTypeEnum.PatientControlRule, this.initialFilters);
        this.loadDependentFilters();
        this.subscribeSearchByRuleNameChanged();
        this.loadRules(true);
    }

    ngOnDestroy(): void {
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchPatientControlRuleChanged('');
            this.rulesUtils.overviewState.previousSelectedRule = null;
            this.rulesUtils.loadFilters(RuleTypeEnum.PatientControlRule, this.initialFilters);
            this.loadRules();
        });
    }

    onModalClose(filtersChanged: boolean) {
        // Method called when modal closes, be it because user pressed Ok or because user pressed on X
        if (filtersChanged) {
            const {modalFilters} = this.rulesUtils.overviewState;
            this.rulesUtils.overviewState.modalFilters.areFiltersActive = this.patientControlRuleUtils.isAnyFilterActive(modalFilters);
            // Filter the data based on the new filters
            this.rulesUtils.overviewState.tableFilters.filter.areaId = modalFilters.areaModalFilterOptions.ngModel?.id;
            this.rulesUtils.overviewState.tableFilters.filter.specialityId = modalFilters.specialityModalFilterOptions.ngModel?.id;
            this.rulesUtils.overviewState.tableFilters.filter.serviceId = modalFilters.serviceModalFilterOptions.ngModel?.id;
            this.rulesUtils.overviewState.tableFilters.filter.resourceId = modalFilters.resourceModalFilterOptions.ngModel?.id;
            this.rulesUtils.overviewState.tableFilters.filter.patientType = modalFilters.patientTypeModalFilterOptions.ngModel;
            this.loadRules(true);
        }
    }

    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        this.rulesUtils.overviewState.ruleTable = ruleTable?.length > 0 ? ruleTable[0] : undefined;
        this.rulesUtils.overviewState.tableFilters.filter['patientControlRuleTableId'] = ruleTable?.length > 0 ? ruleTable[0].id : undefined;
        this.loadRules();
    }

    // region Navigation methods
    //
    goBack(): void {
        this.rulesUtils.selectedRule = undefined;
    }

    createRule(): void {
        this.router.navigate(['/createRule']);
    }

    editRule(rule: PatientControlRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.EDIT}});
    }

    viewRule(rule: PatientControlRuleType) {
        this.router.navigate(['/createRule'], {state: {rule, action: constants.VIEW}});
    }

    navigateToRuleSet(): void {
        this.router.navigate(['/ruleSet']);
    }

    //
    // endregion Navigation methods

    // region Pagination and base filters methods
    //
    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = page;
        this.loadRules(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.rulesUtils.overviewState.tableFilters.currentPage = 1;
        this.rulesUtils.overviewState.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadRules(false);
    }

    onSortBy(property: string) {
        const orderByKeys = Object.keys(this.rulesUtils.overviewState.tableFilters.orderBy);
        const isPropertyAlreadySorted = orderByKeys.includes(property);
        if (orderByKeys.length >= constants.ORDER_BY_CLAUSE_MAX_PERMITTED && !isPropertyAlreadySorted) {
            this.messagesService.error('toastr.error.orderByLimitReached');
            return;
        }
        const isAscendingMode = this.rulesUtils.overviewState.tableFilters.orderBy[property];
        if (this.rulesUtils.overviewState.tableFilters.orderBy) {
            this.rulesUtils.overviewState.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadRules(false);
    }

    onSearchPatientControlRuleChanged(value: string) {
        this.searchRuleByNameChanged.next(value);
    }

    onClearOrderBy(selectedItem: string) {
        delete this.rulesUtils.overviewState.tableFilters.orderBy[selectedItem];
        this.loadRules(false);
    }

    //
    // endregion

    displayConfirmDeleteItemModal(ruleId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.patientControlRuleProvider, ruleId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteRule(ruleId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.rulesUtils.overviewState.modalFilters[property].ngModel = undefined;
        }
    }

    onSelectedArea(selectedAreaList: AreaType[]): void {
        this.rulesUtils.overviewState.modalFilters['areaModalFilterOptions'].ngModel =
            selectedAreaList?.length > 0 ? selectedAreaList[0] : undefined;
    }

    onSelectedSpeciality(selectedSpecialityList: SpecialityType[]): void {
        this.rulesUtils.overviewState.modalFilters['specialityModalFilterOptions'].ngModel =
            selectedSpecialityList?.length > 0 ? selectedSpecialityList[0] : undefined;
    }

    onSelectedService(selectedServiceList: ServiceType[]): void {
        this.rulesUtils.overviewState.modalFilters['serviceModalFilterOptions'].ngModel =
            selectedServiceList?.length > 0 ? selectedServiceList[0] : undefined;
    }

    onSelectedResource(selectedResourceList: ResourceType[]): void {
        this.rulesUtils.overviewState.modalFilters['resourceModalFilterOptions'].ngModel =
            selectedResourceList?.length > 0 ? selectedResourceList[0] : undefined;
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            area: this.patientControlRuleUtils.getAreaDependentFilters(),
            speciality: this.patientControlRuleUtils.getSpecialityDependentFilters(),
            service: this.patientControlRuleUtils.getServiceDependentFilters(),
            resource: this.patientControlRuleUtils.getResourceDependentFilters(),
        };
    }

    // endregion Rule specific methods

    // region General methods

    private loadRules(includeCount: boolean = true) {
        // Load the data
        const queryFilter = this.patientControlRuleUtils.getQueryFilter(this.rulesUtils.overviewState.tableFilters, includeCount);
        this.ngxLoader.start();
        this.patientControlRuleProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.ruleList = response.value;
                this.rulesUtils.loadSpecialities(this.ruleList);
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private deleteRule(id: string) {
        this.rulesUtils.overviewState.tableFilters = this.patientControlRuleUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.patientControlRuleProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadRules();
                this.messagesService.success('toastr.success.patientControlRuleDelete', true);
                this.ngxLoader.stop();
            }, (error) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private subscribeSearchByRuleNameChanged() {
        const self = this;
        this.searchRuleByNameChanged.pipe(
            filter(value => {
                return value.length >= 3 || value.length === 0;
            }),
            debounceTime(constants.inputDebounceTime),
            distinctUntilChanged()
        ).subscribe((searchValue) => {
            self.rulesUtils.overviewState.tableFilters.filter.name = searchValue;
            self.loadRules();
        });
    }

    // endregion General methods
}
