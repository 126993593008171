<div class="slot-without-planned-capacity-modal">
    <div class="modal-header">
        <h6 class="modal-title font-weight-bold" translate="label.appointmentInformation"></h6>
    </div>
    <div class="modal-body">
        <div *ngIf="options.specialBooking" class="has-warning font-weight-high-contrast special-booking-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <span class="ml-1" translate="label.overcapacitySlotsWillNotBeReservedInTheSystem"></span>
        </div>
        <div class="form-group mt-2 mb-3" *ngIf="showAppointmentType">
            <label class="font-weight-bold font-14" translate="label.appointmentType"></label>
            <ng-select class="form-control pl-0 rounded-0 p-0 b-0 custom-ng-select"
                       dropdownPosition="bottom"
                       placeholder="{{'label.choose' | translate}}"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{'has-error': !options.slot?.appointmentType}"
                       [(ngModel)]="options.slot.appointmentType"
                       (ngModelChange)="sanitizeNgSelectValue()">
                <ng-option [value]="undefined"><span translate="label.selectAppointmentType"></span></ng-option>
                <ng-option *ngFor="let appointmentType of options.slot.appointmentTypes"
                           [value]="appointmentType">
                    {{appointmentType.name}}
                </ng-option>
            </ng-select>

            <div *ngIf="!options.slot?.appointmentType" class="help-block mt-2">
                <span translate="label.error.required"></span>
            </div>
        </div>
        <div class="form-group" *ngIf="options.specialBooking">
            <label class="font-weight-bold font-14" translate="label.selectTimeInInterval">
                <span class="time-interval ml-1">{{options.slot?.timeInterval}}</span></label>
            <div class="time-picker-input-container" *ngIf="time" [style.grid-template-columns]="'max-content'">
                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                      [ngClass]="{'input-element-has-error': !isTimeValid}"
                                      [format]="24"
                                      [cancelBtnTmpl]="closeBtn"
                                      [confirmBtnTmpl]="confirmBtn"
                                      [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(time)"
                                      (timeChanged)="onTimeChanged(dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))"
                                      [disabled]="!options.slot?.appointmentType">
                </ngx-timepicker-field>
            </div>
            <div class="row pl-3" *ngIf="!isTimeValid">
                <span class="has-error" translate="label.error.timeIsNotInInterval"></span>
            </div>
            <div class="row pl-3" *ngIf="isTimeValid">
            </div>
        </div>
        <div class="form-group" *ngIf="!!options?.objectDetailsOptions">
            <div *ngIf="!displayNotFoundObjectDetailsMessage"
                 class="container d-flex no-block flex-column custom-scrollbar pr-0 pl-0">
                <sbase-object-details [options]="options.objectDetailsOptions"
                                      (action)="onObjectDetailsAction($event)"></sbase-object-details>
            </div>
            <div *ngIf="displayNotFoundObjectDetailsMessage"
                 class="container-fluid no-object-details-found-message-container pb-1 flex-grow-1 d-flex no-block justify-content-center align-items-center text-high-contrast">
                <i class="fas fa-info-circle mr-3"></i>
                <span translate="label.noObjectDetailsFound"></span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                (click)="onCloseModal()" translate="label.close">
        </button>
        <button type="button"
                class="btn theme-btn border-0 mr-3"
                translate="label.save"
                [disabled]="!isSlotValid()"
                (click)="onSaveModal()">
        </button>
    </div>
</div>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close">
        </button>
    </div>
</ng-template>
