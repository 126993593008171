import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureRoutingModule} from 'src/app/features/feature-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GraphicalResourcePlannerComponent} from './graphical-resource-planner.component';
import {GrpBookingAppointmentComponent} from './grp-booking-appointment/grp-booking-appointment.component';
import {GenerateSkedTaskComponent} from './generate-sked-task/generate-sked-task.component';

@NgModule({
    declarations: [GraphicalResourcePlannerComponent, GrpBookingAppointmentComponent, GenerateSkedTaskComponent],
    imports: [
        CommonModule,
        FeatureRoutingModule,
        SharedModule,
        NgbModule
    ],
    exports: [GraphicalResourcePlannerComponent, GrpBookingAppointmentComponent, GenerateSkedTaskComponent]
})
export class GraphicalResourcePlannerModule {
}
