import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ResourceChannelRestrictionMDComponent} from './resource-channel-restriction-md.component';
import {CreateResourceChannelRestrictionComponent} from './create-resource-channel-restriction/create-resource-channel-restriction.component';

@NgModule({
    declarations: [ResourceChannelRestrictionMDComponent, CreateResourceChannelRestrictionComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class ResourceChannelRestrictionMDModule { }
