import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CapacityPlannerComponent} from './capacity-planner.component';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ExportCapacityRoomModalComponent} from './export-capacity-room-modal/export-capacity-room-modal.component';

@NgModule({
    declarations: [CapacityPlannerComponent, ExportCapacityRoomModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        FormsModule,
        NgbModule,
    ],
    exports: [ExportCapacityRoomModalComponent],
    bootstrap: [ExportCapacityRoomModalComponent]
})
export class CapacityPlannerModule {
}
