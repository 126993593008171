import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppointmentProvider} from 'sked-base';
import {PatientDashboardUtils} from '../patient-dashboard.util';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {AppointmentsListEnum} from '../patient-dashboard.types';
import {Router} from '@angular/router';
import {AppointmentCardEmittedActionType, AppointmentCardOptionsType} from '../../../shared/component/appointment-card/appointment-card.types';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {AppointmentActionEnum} from '../../../shared/component/appointment-action-buttons/appointment-action-buttons.types';
import {PatientAppointmentListUtils} from '../patient-appointment-list/patient-appointment-list.utils';
import {AppointmentTypeEnum} from '../patient-appointment-list/patient-appointment-list.types';
import {Subscription} from 'rxjs';
import * as lodash from 'lodash';

@Component({
    selector: 'app-upcoming-appointments',
    templateUrl: './upcoming-appointments.component.html',
    styleUrls: ['./upcoming-appointments.component.scss']
})
export class UpcomingAppointmentsComponent implements OnInit, OnDestroy {
    appointmentsListEnum = AppointmentsListEnum;
    appointmentCardOptions: AppointmentCardOptionsType[] = [];
    patientChangeSubscription: Subscription;
    loaderId = 'UpcomingAppointmentsLoaderId';

    constructor(
        private appointmentProvider: AppointmentProvider,
        private patientDashboardUtils: PatientDashboardUtils,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private patientContextService: PatientContextService,
        private router: Router,
        private patientAppointmentListUtils: PatientAppointmentListUtils,
    ) {
    }

    ngOnInit(): void {
        this.registerPatientChange();
    }

    ngOnDestroy() {
        this.patientChangeSubscription?.unsubscribe();
    }

    goToAppointmentsList(action: AppointmentsListEnum) {
        switch (action) {
            case AppointmentsListEnum.ViewUpcomingAppointments:
                this.patientAppointmentListUtils.shouldMakeNewRequest = true;
                this.patientAppointmentListUtils.filtersOptions.typeFilterTab = AppointmentTypeEnum.Upcoming;
                this.patientAppointmentListUtils.preselectAppointmentType = AppointmentTypeEnum.Upcoming;
                this.router.navigate(['/patientAppointmentList']);
                break;
            case AppointmentsListEnum.ViewCancelledAppointments:
                this.patientAppointmentListUtils.shouldMakeNewRequest = true;
                this.patientAppointmentListUtils.filtersOptions.typeFilterTab = AppointmentTypeEnum.Cancelled;
                this.patientAppointmentListUtils.preselectAppointmentType = AppointmentTypeEnum.Cancelled;
                this.router.navigate(['/patientAppointmentList']);
                break;
            case AppointmentsListEnum.ViewPastAppointments:
                this.patientAppointmentListUtils.shouldMakeNewRequest = true;
                this.patientAppointmentListUtils.filtersOptions.typeFilterTab = AppointmentTypeEnum.Past;
                this.patientAppointmentListUtils.preselectAppointmentType = AppointmentTypeEnum.Past;
                this.router.navigate(['/patientAppointmentList']);
                break;
        }
    }

    onAction({action, data}: AppointmentCardEmittedActionType, index: number = -1) {
        switch (action) {
            case AppointmentActionEnum.Cancel: {
                // The appointment is cancelled in cancel-appointment component, here we only remove the appointment from the list
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentCardOptions[index].appointment) {
                    lodash.pullAt(this.appointmentCardOptions, index);
                }
                return;
            }
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Reuse: {
                // Reuse is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentCardOptions[index].appointment) {
                    this.appointmentCardOptions[index] = {
                        ...this.appointmentCardOptions[index],
                        appointment: {
                            ...this.appointmentCardOptions[index].appointment,
                            etag: data.etag,
                            status: data.status,
                        }
                    };
                }
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.appointmentCardOptions[index].appointment) {
                    this.appointmentCardOptions[index] = {
                        ...this.appointmentCardOptions[index],
                        appointment: {
                            ...this.appointmentCardOptions[index].appointment,
                            etag: data.etag,
                            paid: data.paid,
                        }
                    };
                }
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                // This action is done entirely in appointment-send-email-button component
                return;
            }
        }
    }

    refreshData() {
        this.loadPatientAppointments();
    }

    private registerPatientChange() {
        this.patientChangeSubscription = this.patientContextService.patientChange.subscribe(() => {
            this.appointmentCardOptions = [];
            if (this.patientContextService.patient?.id) {
                this.loadPatientAppointments();
            }
        });
    }

    private loadPatientAppointments() {
        const queryFilter = this.patientDashboardUtils.getQueryFilterForPatientAppointments();
        this.ngxLoader.startLoader(this.loaderId);
        this.appointmentProvider.getByUserInformation(queryFilter, true, true).subscribe(({value}) => {
            this.appointmentCardOptions = value.map(appointment => ({appointment, displayPatientInformation: false}));
            this.ngxLoader.stopLoader(this.loaderId);
        }, err => {
            this.ngxLoader.stopLoader(this.loaderId);
            this.messagesService.handlingErrorMessage(err);
        });
    }
}
