import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {OpenIdConnectService} from '../../shared/services/open-id-connect.service';
import {SessionStorageService} from '@efaps/ngx-store';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService,
              private openIdConnectService: OpenIdConnectService,
              private route: ActivatedRoute,
              private languageService: TranslatedLanguageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken = '';
    let tokenType = '';
    let displayRulesUi = this.route.snapshot['_routerState'].url.toString();
    if (displayRulesUi === '/createPatient') {
      // In order to send the same display rules ui request as in the old backoffice we need the temporary, until the backend changes this.
      displayRulesUi = 'reception.patientOverview';
    }
    if (displayRulesUi === '/excelImport') {
      // In order to work the excel import we need to send this value into X-DisplayRules-Ui header.
      displayRulesUi = 'dashboard.excelImporter';
    }

    // get accessToken and token type if the user is logged in
    if (this.openIdConnectService.user) {
      accessToken = this.openIdConnectService.user.access_token;
      tokenType = this.openIdConnectService.user.token_type;
    }

    // add headers
    request = request.clone({
      setHeaders:
        {
          Accept: 'application/json, */*',
          'X-AppTimezone': moment().utcOffset().toString(),
          'X-Slots-Debug': 'false',
          'Accept-Language': this.languageService.getUsedLanguage()
        }
    });

    // add access token in request header
    if (accessToken && tokenType) {
      request = request.clone({setHeaders: {Authorization: tokenType + ' ' + accessToken}});
    }

    // set current route in request header
    if (this.route && this.route.snapshot && this.route.snapshot['_routerState']) {
      request = request.clone({setHeaders: {'X-DisplayRules-Ui': displayRulesUi}});
    }

    return next.handle(request);
  }
}
