import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ResourceUtilizationComponent} from './resource-utilization.component';
import {ResourceAppointmentListComponent} from './resource-appointment-list/resource-appointment-list.component';
import {ResourceUtilizationFiltersComponent} from './resource-utilization-filters/resource-utilization-filters.component';
import {ResourceUtilizationCalendarComponent} from './resource-utilization-calendar/resource-utilization-calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline';
import {
    ResourceUtilizationManageAppointmentComponent
} from './resource-utilization-manage-appointment/resource-utilization-manage-appointment.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    resourceTimeLinePlugin
]);

@NgModule({
    declarations: [
        ResourceUtilizationComponent,
        ResourceAppointmentListComponent,
        ResourceUtilizationFiltersComponent,
        ResourceUtilizationCalendarComponent,
        ResourceUtilizationManageAppointmentComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        MatProgressSpinnerModule,
        FullCalendarModule,
        MatFormFieldModule,
        MatSelectModule
    ]
})
export class ResourceUtilizationModule { }
