import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UserApiKeyModalComponent} from './user-api-key-modal.component';
import {UserType} from 'sked-base/lib/data-model/userTypes';

@Injectable({
    providedIn: 'root'
})
export class UserApiKeyModalService {

    constructor(
        private modalService: NgbModal
    ) {
    }

    displayApiKeyModal(user: UserType): NgbModalRef {
        const modalOptions = this.getModalOptions();
        const modalRef = this.modalService.open(UserApiKeyModalComponent, modalOptions);
        modalRef.componentInstance.userId = user.id;
        modalRef.componentInstance.userFullName = `${user?.firstName} ${user?.lastName}`;
        modalRef.componentInstance.userName = user.username;
        modalRef.componentInstance.apiKey = user.apiKeys[0];

        return modalRef;
    }

    getModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: true,
            size: 'md'
        };
    }
}
