import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    MultiSelectTableColumnKindEnum,
    MultiSelectTableColumnType,
    MultiSelectTableOptionsType
} from './multi-select-table.types';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {ConfirmDeleteModalService} from 'sked-base';
import * as lodash from 'lodash';
import {concatMap, map, take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from '../../utils/general.utils';
import {IdNameType, TableFiltersType} from '../../../data-model/general.type';
import {MultiSelectTableUtils} from './multi-select-table-util';
import {MessagesService} from '../../services/messages.service';
import {constants} from '../../constants/constants';

@Component({
    selector: 'app-multi-select-table',
    templateUrl: './multi-select-table.component.html',
    styleUrls: ['./multi-select-table.component.scss']
})
export class MultiSelectTableComponent implements OnInit {
    @Input() options: MultiSelectTableOptionsType;
    @Input() forceLoadData?: Subject<string[]>;
    @Input() clearItems?: Subject<boolean>;
    @Output() itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    multiSelectTableColumnKindEnum = MultiSelectTableColumnKindEnum;
    allItems: any[];
    items: any[];
    count: number;
    selectedItems: any[];
    itemsPerPageList: number[] = [10, 25, 50, 100];
    selectedCount: number;
    itemTableFilters: TableFiltersType;
    selectAll = false;
    showOnlyAssigned = false;
    showItemsPerPageDropdown = false;

    constructor(
        public generalUtils: GeneralUtils,
        private multiSelectTableUtils: MultiSelectTableUtils,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private confirmDeleteService: ConfirmDeleteModalService,
    ) {
    }

    ngOnInit(): void {
        this.itemTableFilters = this.multiSelectTableUtils.getInitialTableFilter();
        this.setOrderByPropertiesToAsc();
        this.setDefaultOptions();
        this.loadData();
        this.listenToForceLoadDataSubject();
        this.listenToClearItemsSubject();
    }

    // function to sort table data by descend and ascend order
    onSortBy(property: string) {
        const isAscendingMode = this.itemTableFilters.orderBy[property];

        if (this.itemTableFilters.orderBy) {
            this.itemTableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.fetchItemsForTable(this.itemTableFilters);
    }

    onShowOnlyAssignedItems(showOnlyAssigned: boolean) {
        this.itemTableFilters.filter.showOnlyAssigned = showOnlyAssigned;
        this.itemTableFilters.currentPage = 1;

        this.fetchItemsForTable(this.itemTableFilters);
    }

    onSelectSingle(item: any, event: any) {
        if (this.options.limitNumberOfSelectedItemsTo1) {
            this.onSelectSingleForLimitNumberOfSelectedItemsTo1(item, event);
        } else {
            if (!item.selected) {
                this.selectedItems.push(item);
                item.selected = !item.selected;
                this.selectedItems = lodash.uniqBy(this.selectedItems, 'id');
                this.selectedCount = this.selectedItems.length;
                this.itemsChange.emit(this.selectedItems);
            } else if (this.options.screenTemplateLayoutAction === constants.EDIT) {
                // Remove in edit
                event.preventDefault();
                this.confirmDeleteService.displayConfirmDeleteModal(
                    this.options.confirmDeleteProvider, this.options.confirmDeleteParentId,
                    this.options.confirmDeleteRefName, item.id, false).result.then(() => {
                }, (response) => {
                    if (response) {
                        item.selected = !item.selected;
                        lodash.remove(this.selectedItems, {id: item.id});
                        this.selectedItems = lodash.uniqBy(this.selectedItems, 'id');
                        this.selectedCount = this.selectedItems.length;
                        this.itemsChange.emit(this.selectedItems);
                    }
                });
            } else {
                // Remove in create
                item.selected = !item.selected;
                lodash.remove(this.selectedItems, {id: item.id});
                this.selectedItems = lodash.uniqBy(this.selectedItems, 'id');
                this.selectedCount = this.selectedItems.length;
                this.itemsChange.emit(this.selectedItems);
            }
        }
    }

    onSelectAll(selectAll: boolean) {
        if (selectAll) {
            this.items = this.generalUtils.addSelectedValueTrueToObjectList(this.items);
            this.selectedItems = lodash.uniqBy(lodash.concat(this.selectedItems, this.items), 'id');
            this.selectedCount = this.selectedItems.length;
            this.itemsChange.emit(this.selectedItems);
        } else if (this.options.screenTemplateLayoutAction === constants.EDIT) {
            // Deselect all in edit
            const itemsIds = this.items.map(item => item.id);
            this.confirmDeleteService.displayConfirmDeleteModal(
                this.options.confirmDeleteProvider, this.options.confirmDeleteParentId,
                this.options.confirmDeleteRefName, itemsIds, false).result.then(() => {
            }, (response) => {
                if (response) {
                    this.items = this.generalUtils.addSelectedValueFalseToObjectList(this.items);
                    this.selectedItems = lodash.pullAllBy(this.selectedItems, this.items, 'id');
                    this.selectedCount = this.selectedItems.length;
                    this.itemsChange.emit(this.selectedItems);
                }
            });
        } else {
            // Deselect all in create
            this.items = this.generalUtils.addSelectedValueFalseToObjectList(this.items);
            this.selectedItems = lodash.pullAllBy(this.selectedItems, this.items, 'id');
            this.selectedCount = this.selectedItems.length;
            this.itemsChange.emit(this.selectedItems);
        }
    }

    onSelectedColumnFilter(column: MultiSelectTableColumnType, values: any[]): void {
        this.itemTableFilters.filter[column.propertyName] = values.length > 0 ? values[0] : undefined;
        this.fetchItemsForTable(this.itemTableFilters);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.itemTableFilters.currentPage = 1;
        this.itemTableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.selectAll = false;

        this.fetchItemsForTable(this.itemTableFilters, false);
    }

    onChangePagination(page: number) {
        this.selectAll = false;
        this.itemTableFilters.currentPage = page;

        this.fetchItemsForTable(this.itemTableFilters, false);
    }

    private setItemForLimitNumberOfSelectedItemsTo1(item: any) {
        this.selectedItems.push(item);
        item.selected = !item.selected;
        this.items.forEach((tableItem) => {
            if (tableItem.id !== item.id) {
                tableItem.selected = false;
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id === item.id);
            }
        });
        this.selectedCount = this.selectedItems.length;
        this.itemsChange.emit(this.selectedItems);
    }
    private onSelectSingleForLimitNumberOfSelectedItemsTo1(item: any, event: any) {
        if (!item.selected && this.options.screenTemplateLayoutAction === constants.CREATE) {
            this.setItemForLimitNumberOfSelectedItemsTo1(item);
        } else if (!item.selected && this.options.screenTemplateLayoutAction === constants.EDIT) {
            // Remove in edit
            event.preventDefault();
            this.confirmDeleteService.displayConfirmDeleteModal(
                this.options.confirmDeleteProvider, this.options.confirmDeleteParentId,
                this.options.confirmDeleteRefName, item.id, false).result.then(() => {
            }, (response) => {
                if (response) {
                    this.setItemForLimitNumberOfSelectedItemsTo1(item);
                }
            });
        } else {
            // Remove in create
            item.selected = !item.selected;
            lodash.remove(this.selectedItems, {id: item.id});
            this.selectedItems = lodash.uniqBy(this.selectedItems, 'id');
            this.selectedCount = this.selectedItems.length;
            this.itemsChange.emit(this.selectedItems);
        }
    }
    private listenToClearItemsSubject() {
        this.clearItems?.subscribe((response) => {
            if (response) {
                this.selectAll = false;
                this.items = this.generalUtils.addSelectedValueFalseToObjectList(this.items);
                this.selectedItems = lodash.cloneDeep(lodash.pullAllBy(this.selectedItems, this.items, 'id'));
                this.selectedCount = this.selectedItems.length;
                this.itemsChange.emit(this.selectedItems);
            }
        });
    }

    private listenToForceLoadDataSubject() {
        this.forceLoadData?.subscribe(selectedCoveragePlansIds => {
            this.forceLoadItems(selectedCoveragePlansIds);
        });
    }

    private forceLoadItems(selectedItems: string[]) {
        this.itemTableFilters.filter[this.options?.filterBySpecialProperty] = selectedItems;
        this.fetchItemsForTable(this.itemTableFilters);
    }

    private fetchItemsForTable(tableFilters: TableFiltersType, includeCount: boolean = true) {
        this.ngxLoader.start();
        this.getItemsObservable(tableFilters, includeCount)
            .pipe(take(1))
            .subscribe((items) => {
                this.onItemsResponse(items);
                this.ngxLoader.stop();
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }

    private onItemsResponse(items) {
        if (items.count !== undefined && items.count !== null) {
            this.count = items.count;
        }
        this.items = this.generalUtils.addSelectedValueFalseToObjectList(items.value);
        this.items = this.multiSelectTableUtils.matchAssignedItems(this.items, this.selectedItems);
        this.selectedCount = this.selectedItems.length;
    }

    private getItemsObservable(tableFilters, includeCount: boolean = true): Observable<{
        value: any[],
        count?: number
    }> {
        if (this.showOnlyAssigned) {
            return of(this.multiSelectTableUtils.getOnlyAssignedItems(tableFilters, this.selectedItems, this.options));
        }
        return this.options.provider[this.options.providerMethod](
            this.multiSelectTableUtils.getQueryForItemsRequest(tableFilters, this.options, includeCount)
        );
    }

    private loadData() {
        this.options.provider[this.options.providerMethod](
            this.options.getQueryForItems(undefined, true)
        ).pipe(
            take(1),
            concatMap((items: { count?: number, value: any[] }) => {
                const moreItemsParts = items.count > 100 ? this.getAllItemsObservables(items.count) : [];
                const allItemsParts = [of(items), ...moreItemsParts];
                return forkJoin(allItemsParts).pipe(take(1));
            }),
            map((allItemsParts: Array<{ count?: number, value: any[] }>) => {
                const allItemsValues = lodash.flatten(lodash.map(allItemsParts, (itemsPart) => itemsPart.value));
                return {count: allItemsParts[0].count, value: allItemsValues};
            })
        ).subscribe((items: { count?: number, value: any[] }) => {
            this.onInitialItemsResponse(items);
        });
    }

    private onInitialItemsResponse(items: { count?: number, value: any[] }) {
        this.allItems = items.value;
        this.count = items.count;
        this.items = this.generalUtils.addSelectedValueFalseToObjectList(items.value);
        this.selectedItems = [];
        if (this.options?.initialSelectedItems?.length > 0) {
            this.options?.initialSelectedItems.forEach((item: IdNameType) => {
                const foundIndex = lodash.findIndex(this.items, {id: item.id});
                if (foundIndex !== -1) {
                    this.items[foundIndex].selected = true;
                    this.selectedItems.push(lodash.cloneDeep(this.items[foundIndex]));
                }
            });
        }
        this.selectedCount = this.selectedItems?.length;
        this.items = this.items.slice(0, this.itemTableFilters.itemsPerPage);
    }

    // Returns an array of observables, one item is a request of 100 items; this array is then used in a forkJoin
    private getAllItemsObservables(selectedItemsCount: number):
        Observable<{ value: any[], count?: number }>[] {
        let skip = 0;
        const obsArray = [];
        for (let index = 1; index < lodash.ceil(selectedItemsCount / 100); index++) {
            skip = skip + 100;
            obsArray.push(this.options.provider[this.options.providerMethod](
                this.options.getQueryForItems(skip, false)
            ));
        }
        return obsArray;
    }

    private setDefaultOptions() {
        if (!this.options?.assignedItemsLabel) {
            this.options.assignedItemsLabel = 'label.assignedItems';
        }
        if (!this.options?.initialSelectedItems) {
            this.options.initialSelectedItems = [];
        }
    }

    private setOrderByPropertiesToAsc() {
        this.options.columns.forEach(column => {
            if (!!column.orderByProperty) {
                this.itemTableFilters.orderBy[column.orderByProperty] = 'asc';
            }
        });
    }
}
