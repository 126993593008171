import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {
    CenterProvider,
    ResourceProvider,
    ServiceProvider,
    TagTypeComponent,
    CapacityPlannerProvider,
    TagProvider,
    ResourceTypeProvider,
    ODataQueryObjectType,
    GenericFilterOptionsType,
    GenericFilterResultType,
    FilterWrapperNameEnum,
    TagDependentFiltersScopeEnum,
    SearchFilterUtils, TagDependentFiltersType
} from 'sked-base';
import * as lodash from 'lodash';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import * as moment from 'moment';
import {
    CapacityPlannerFilterType,
    CapacityPlannerTableFiltersType,
    PlannedCapacityFilterType,
} from './capacity-planner.types';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CapacityPlannerUtils} from './capacity-planner.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MessagesService} from '../../shared/services/messages.service';
import {forkJoin} from 'rxjs';
import {ExportCapacityRoomModalComponent} from './export-capacity-room-modal/export-capacity-room-modal.component';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {Router} from '@angular/router';
import {constants} from '../../shared/constants/constants';
import {LabelValueType} from '../../data-model/general.type';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-capacity-planner',
    templateUrl: './capacity-planner.component.html',
    styleUrls: ['./capacity-planner.component.scss']
})

export class CapacityPlannerComponent implements OnInit, OnDestroy {
    mainDependentFilters: {
        tags: TagDependentFiltersType
    };
    requestTableFilters: CapacityPlannerFilterType;
    items: any[] = [];
    displayedItems: any[] = [];
    showSortDropdown = false;
    showDropdownMenu = false;
    showPagesDropdown = false;
    showViewsDropdown = false;
    showPlannedCapacityDropdown = false;
    isDateValid = false;
    WEEKLY = 'weekly';
    MONTHLY = 'monthly';
    ASCENDING = 'ascending';
    DESCENDING = 'descending';
    NEXT = 'next';
    PREVIOUS = 'previous';
    tagsList: TagTypeComponent[] = [];
    displayedCapacitiesTable = [];
    // order: asc/desc, pagination, view: weekly, monthly, by capacity filters
    tableFilters: CapacityPlannerTableFiltersType;
    dateCapacityObjectArray = [];
    // pagination
    page = 1;
    plannedCapacitiesFiltersValues: PlannedCapacityFilterType[] = this.capacityPlannerUtils.getInitialPlannedCapacityFiltersValues();
    dateHeaderCalendar: any;
    isTableFiltersValid = false;
    tags = [];
    resourceVal = 'resource';
    roomVal = 'room';
    resourceTypeRoomId: string;
    viewTypes: LabelValueType[] = this.capacityPlannerUtils.getViewTypesForCapacityPlanner();

    filterWrapperOptions: GenericFilterOptionsType[];
    updateFiltersValue: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();

    private centerButtonName = 'label.center2';
    private filterWrapperValues: GenericFilterResultType[] = [];

    constructor(public centerProvider: CenterProvider,
                public resourceProvider: ResourceProvider,
                public serviceProvider: ServiceProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                public resourceTypeProvider: ResourceTypeProvider,
                public capacityPlannerUtils: CapacityPlannerUtils,
                public generalUtils: GeneralUtils,
                public capacityPlannerProvider: CapacityPlannerProvider,
                public tagProvider: TagProvider,
                public router: Router,
                private modelService: NgbModal,
                private searchFilterUtils: SearchFilterUtils) {
    }

    ngOnInit() {
        //set table filters
        this.tableFilters = this.getInitialTableFilters();

        this.requestTableFilters = this.capacityPlannerUtils.getInitialRequestTableFilters();

        this.filterWrapperOptions = this.getFilterWrapperOptions();

        //set initial request table filters
        if (this.router.url === '/' + constants.CAPACITY_PLANNER_RESOURCE) {
            this.useRoomOrResource(this.viewTypes[0]); //resource
        } else {
            this.useRoomOrResource(this.viewTypes[1]); //room
        }

        this.isDateValid = this.capacityPlannerUtils.isValidDate(this.requestTableFilters.date);

        this.startTagsAndResourceTypesRequest(this.requestTableFilters.resourceType);
        this.mainDependentFilters = {
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedResource, false)
        };
    }

    ngOnDestroy(): void {
    }

    onFilterWrapperValueChanged(filtersValue: GenericFilterResultType[]): void {
        this.page = 1;
        this.filterWrapperValues = filtersValue;
        this.tagsList = [];
        for (const filterOpt of this.filterWrapperOptions) {
            const foundFilter = lodash.find(this.filterWrapperValues, {name: filterOpt.name});
            if (foundFilter) {
                this.tagsList.push(foundFilter.value);
                lodash.uniq(this.tagsList, 'id');
                this.requestTableFilters[foundFilter.name] = foundFilter.value; //resourceId / centerId
            } else { // filter was deleted
                this.requestTableFilters[filterOpt.name] = undefined;
            }
        }

        this.getData(this.requestTableFilters);
    }

    private areTableFiltersValid() {
        this.isDateValid = this.capacityPlannerUtils.isValidDate(this.requestTableFilters.date);
        this.isTableFiltersValid = !!(this.requestTableFilters?.location?.id && this.isDateValid
            && ((this.requestTableFilters?.tags && this.requestTableFilters?.tags?.length > 0) || this.requestTableFilters?.resource?.id));
    }


    onChangeResource(resourceName: string) {
        this.tableFilters.resourceName = resourceName;
        this.displayedItems = this.capacityPlannerUtils.displayCapacitiesTable(this.items, this.tableFilters);
    }

    onDeleteTag(tagsList) {
        const remainingFilters: GenericFilterResultType[] = [];
        for (const filterValue of this.filterWrapperValues) {
            const foundFilter = lodash.find(tagsList, {id: filterValue.value.id});
            if (foundFilter) { // filter was not deleted from tag
                remainingFilters.push({name: filterValue.name, value: foundFilter});
            } else { // filter was not deleted from tag => delete it also from table filters
                this.requestTableFilters[filterValue.name] = undefined;
            }
        }
        this.filterWrapperValues = remainingFilters;
        this.updateFiltersValue.emit(remainingFilters);
        this.getData(this.requestTableFilters);
    }

    onClickedOutsideSortFilter(e: Event) {
        this.showSortDropdown = false;
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showPagesDropdown = false;
    }

    onClickedOutsideViewFilter(e: Event) {
        this.showViewsDropdown = false;
    }

    onClickedOutsidePlannedCapacityFilter(e: Event) {
        this.showPlannedCapacityDropdown = false;
    }

    onSelectViewFilter(viewFilter): void {
        this.page = 1;
        this.tableFilters.view = viewFilter;
        this.showViewsDropdown = false;
        this.getData(this.requestTableFilters);
    }

    getData(requestTableFilters) {
        this.areTableFiltersValid();
        if (this.isTableFiltersValid && this.requestTableFilters.resourceType['value'] === this.resourceVal) {
            const queryFilter = this.capacityPlannerUtils.getCapacityPlannerQueryFilter(requestTableFilters, this.tableFilters.view);
            this.ngxLoader.start();
            this.capacityPlannerProvider.getPlannedCapacity(queryFilter)
                .pipe(take(1))
                .subscribe((filters) => {
                    this.onGetDataSubscribe(filters, this.requestTableFilters, this.tableFilters);
                }, (message) => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(message);
                }, () => {
                    this.ngxLoader.stop();
                });
        } else {
            if (this.isTableFiltersValid) {
                const queryFilter = this.capacityPlannerUtils.getCapacityPlannerForRoomQueryFilter(requestTableFilters, this.tableFilters.view);
                this.ngxLoader.start();
                this.capacityPlannerProvider.getPlannedCapacityForRooms(queryFilter)
                    .pipe(take(1))
                    .subscribe((filters) => {
                        this.onGetDataSubscribe(filters, this.requestTableFilters, this.tableFilters);
                    }, (message) => {
                        this.ngxLoader.stop();
                        this.messagesService.handlingErrorMessage(message);
                    }, () => {
                        this.ngxLoader.stop();
                    });
            }

        }
    }

    onGetDataSubscribe(items, requestTableFilters, tableFilters) {
        this.page = 1;
        this.items = items;
        this.dateHeaderCalendar = '';
        if (tableFilters.view === this.WEEKLY) {
            this.tableFilters.view = this.WEEKLY;
            this.dateHeaderCalendar = this.capacityPlannerUtils.getFirstAndLastElementArray(requestTableFilters.date, tableFilters.view);
            this.dateCapacityObjectArray = this.capacityPlannerUtils.getSelectedWeek(tableFilters, requestTableFilters.date);
        } else if (tableFilters.view === this.MONTHLY) {
            this.tableFilters.view = this.MONTHLY;
            this.dateHeaderCalendar = this.capacityPlannerUtils.getFirstAndLastElementArray(requestTableFilters.date, tableFilters.view);
            this.dateCapacityObjectArray = this.capacityPlannerUtils.getSelectMonth(tableFilters, requestTableFilters.date);
        }

        for (const item of items) {
            item.rangeDays = lodash.cloneDeep(this.dateCapacityObjectArray); //all days from selected interval
            for (const day of item.rangeDays) {
                const foundCapacity = lodash.find(item.capacitiesPerDay, (element) => {
                    // we need to make sure that we compare the exact same format of the dates
                    // day.date doesn't contain the timezone thus we need to remove the timezone from the capacitiesPerDay as well
                    return moment(element.date.substring(0, 10)).isSame(moment(day.date.substring(0, 10)), 'day');
                });
                // make list of day name
                day.plannedCapacity = foundCapacity ? foundCapacity.plannedCapacity : null;
            }
        }

        this.displayedItems = this.capacityPlannerUtils.displayCapacitiesTable(this.items, this.tableFilters);
    }

    onPrevOrNextDaysPage(date: NgbDateStruct, prevOrNext: string, viewMode: string) {
        let prevOrNewDaysWeekOrMonth: NgbDateStruct;
        if (prevOrNext === this.NEXT) {
            prevOrNewDaysWeekOrMonth = this.capacityPlannerUtils.getNextWeekOrMonth(date, viewMode);
        } else if (prevOrNext === this.PREVIOUS) {
            prevOrNewDaysWeekOrMonth = this.capacityPlannerUtils.getPrevWeekOrMonth(date, viewMode);
        }

        //transform in NgbDate
        const updatedNgbDate = {
            year: moment(prevOrNewDaysWeekOrMonth).get('year'),
            month: moment(prevOrNewDaysWeekOrMonth).get('month') + 1,
            day: moment(prevOrNewDaysWeekOrMonth).get('date'),
        };

        // updated requestTableFilters
        this.requestTableFilters.date = updatedNgbDate;

        //gat data
        this.getData(this.requestTableFilters);
    }

    onDateChange(date: NgbDateStruct) {
        this.page = 1;
        if (date !== undefined) {
            this.requestTableFilters.date = date;

            //gat data
            this.getData(this.requestTableFilters);
        }
    }

    changeNumberOfItemsPerPage(itemsPerPage) {
        this.page = 1;
        this.showPagesDropdown = false;
        this.tableFilters.itemsPerPage = itemsPerPage;
    }

    onOrderList(sortMode) {
        this.page = 1;
        this.tableFilters.sort = sortMode;
        this.showSortDropdown = false;
        this.displayedItems = this.capacityPlannerUtils.displayCapacitiesTable(this.items, this.tableFilters);
    }

    onPlannedCapacity(plannedCapacity: PlannedCapacityFilterType) {
        this.page = 1;
        this.tableFilters.plannedCapacity = plannedCapacity;
        this.showPlannedCapacityDropdown = false;
        this.displayedItems = this.capacityPlannerUtils.displayCapacitiesTable(this.items, this.tableFilters);
    }

    displayedItemsPerCurrentPage(displayedItems, itemsPerPage, currentPage) {
        const page = currentPage - 1;
        const firstItemPerCurrentPage = itemsPerPage * page;
        const list = lodash.slice(displayedItems, firstItemPerCurrentPage, firstItemPerCurrentPage + itemsPerPage);

        return list;
    }

    private getInitialTableFilters(): CapacityPlannerTableFiltersType {
        return this.tableFilters = {
            sort: this.ASCENDING,
            itemsPerPage: 5,
            view: this.WEEKLY,
            plannedCapacity: this.plannedCapacitiesFiltersValues[0],
            resourceName: ''
        };
    }

    private startTagsAndResourceTypesRequest(viewTypeFilter) {
        const filter: ODataQueryObjectType = {
            select: ['Id', 'Name', 'ScopedResource'],
            filter: {ScopedResource: true}
        };

        this.ngxLoader.start();
        forkJoin([
            this.tagProvider.getEntries(filter),
            this.resourceTypeProvider.getEntries({})
        ])
            .pipe(take(1))
            .subscribe(([tags, resourceTypes]) => {
                this.tags = tags.value;
                this.resourceTypeRoomId = this.generalUtils.getResourceTypeRoomId(resourceTypes.value);
                this.onResourceTypeFilterChange(viewTypeFilter);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    // adding tags value to request table filter
    onChangeTags(tags) {
        this.requestTableFilters.tags = tags;

        //get data
        this.getData(this.requestTableFilters);
    }

    //capacity planner dropdown
    private useRoomOrResource(resourceType) {
        this.requestTableFilters.resourceType = resourceType;
        this.onResourceTypeFilterChange(this.requestTableFilters.resourceType);
    }

    private onResourceTypeFilterChange(resourceTypeObj) {
        if (this.resourceTypeRoomId) {
            if (resourceTypeObj.value === this.roomVal) {
                this.filterWrapperOptions[1].dependentFilters.resourceTypeId = this.resourceTypeRoomId;
                this.filterWrapperOptions[1].dependentFilters.resourceTypeExclusionList = [];
            } else {
                this.filterWrapperOptions[1].dependentFilters.resourceTypeId = null;
                this.filterWrapperOptions[1].dependentFilters.resourceTypeExclusionList = [this.resourceTypeRoomId];
            }
        } else {
            this.filterWrapperOptions[1].dependentFilters.resourceTypeId = null;
            this.filterWrapperOptions[1].dependentFilters.resourceTypeExclusionList = [];
        }
    }

    //pop up model for export data
    openExportDataModel() {
        const modelRef = this.modelService.open(ExportCapacityRoomModalComponent, this.generalUtils.getModalOptions());
        modelRef.componentInstance.resourceTypeId = this.resourceTypeRoomId;
    }

    private getFilterWrapperOptions = (): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.capacityPlannerUtils.getLocationDependentFilters(),
                disableFilter: false,
                label: this.centerButtonName,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.capacityPlannerUtils.getResourceDependentFilters(),
                disableFilter: false,
                label: 'label.' + this.getRoomOrResource(),
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            }
        ]
    )

    private getRoomOrResource(): string {
        if (this.router.url === '/' + constants.CAPACITY_PLANNER_RESOURCE) {
            return this.viewTypes[0].value;
        } else {
            return this.viewTypes[1].value;
        }
    }
}
