<div class="container-fluid max-appointment-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.maxAppointmentRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                        [contentTemplate]="maxAppointmentRuleModalFiltersContentTemplate"
                        [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                        [title]="'label.filterMore'"
                        (modalClose)="onModalClose($event)"
                ></sbase-show-template-modal-filter-container>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #maxAppointmentRuleModalFiltersContentTemplate>
        <div class="max-appointment-rule-filter-more-modal-container">
            <!-- Channel filter -->
            <div class="rules-text-filter channelFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.channel"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedChannel($event)"
                              [buttonName]="'label.channel'"
                              [dependentFilters]="mainDependentFilters.channel"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['channelModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['channelModalFilterOptions'].ngModel] : []"
                              [providerInstance]="channelProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <!-- Resource filter -->
            <div class="rules-text-filter resourceFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.resource"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                              [buttonName]="'label.resource'"
                              [dependentFilters]="mainDependentFilters.resource"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['resourceModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['resourceModalFilterOptions'].ngModel] : []"
                              [providerInstance]="resourceProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <!-- Center filter -->
            <div class="rules-text-filter centerFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.center2"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                              [buttonName]="'label.center2'"
                              [dependentFilters]="mainDependentFilters.location"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['centerModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['centerModalFilterOptions'].ngModel] : []"
                              [providerInstance]="centerProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              class="sbase-dropdown-ml">
                </sbase-filter>
            </div>

            <!-- Area filter -->
            <div class="rules-text-filter areaFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.area2"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedArea($event)"
                              [buttonName]="'label.area2'"
                              [dependentFilters]="mainDependentFilters.area"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['areaModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['areaModalFilterOptions'].ngModel] : []"
                              [providerInstance]="areaProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <!-- Speciality filter -->
            <div class="rules-text-filter specialityFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.speciality2"></label>
                <sbase-filter class="mt-3"
                              (selectedItemsOutput)="onSelectedSpeciality($event)"
                              [buttonName]="'label.speciality2'"
                              [dependentFilters]="mainDependentFilters.speciality"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['specialityModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['specialityModalFilterOptions'].ngModel] : []"
                              [providerInstance]="specialityProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <!-- Rule scope -->
            <div class="rules-select-filter rule-scope-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.ruleScopeModalFilterOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.ruleScope"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.choose' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.ruleScopeModalFilterOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.ruleScopeModalFilterOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'ruleScopeModalFilterOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.choose"></span>
                    </ng-option>
                    <ng-option *ngFor="let item of ruleScopeEnumList"
                               [value]="item">
                        <span [translate]="'enum.ruleScope.' + item"></span>
                    </ng-option>
                </ng-select>
            </div>

            <!-- Max appointments filter -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.maxAppointmentsModalFilterOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.maxAppointments"></label>
                <input type="number" class="form-control rounded-1"
                       placeholder="{{'label.searchBy' | translate}} {{'label.maxAppointments' | translate}}"
                       autocomplete="off" min="0" max="999"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.maxAppointmentsModalFilterOptions.ngModel">
            </div>

            <!-- Message filter -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.messageModalFilterOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.message"></label>
                <input type="text" class="form-control rounded-1"
                       placeholder="{{'label.searchBy' | translate}} {{'label.message' | translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.messageModalFilterOptions.ngModel">
            </div>

            <!-- Service tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.serviceTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.serviceTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag(constants.SERVICE, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.serviceTags"
                              [initialFilterValues]="[rulesUtils.overviewState.modalFilters.serviceTagOptions.ngModel]"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>

            <!-- Patient tags -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.patientTagOptions">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.patientTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag(constants.PATIENT, $event)"
                              [buttonName]='"label.newTag"'
                              [dependentFilters]="mainDependentFilters?.patientTags"
                              [initialFilterValues]="[rulesUtils.overviewState.modalFilters.patientTagOptions.ngModel]"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="tagProvider">
                </sbase-filter>
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active soring -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key + (['center', 'area', 'speciality'].includes(key)? '2' : '') | translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-2" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchMaxAppointmentRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="'MaxAppointmentRuleCreate'"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="'MaxAppointmentRuleCreate'"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <!-- Table headers -->
            <tr>
                <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                <ng-container *ngFor="let column of tableHeaderColumns">
                    <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'" (click)="onSortBy(column.onSortByProperty)">
                        <span *ngIf="column.abbreviatedLabel" translate="{{column.abbreviatedLabel}}"
                              ngbTooltip="{{column.label | translate}}"
                              placement="bottom"
                              container="body">
                        </span>
                        <span *ngIf="!column.abbreviatedLabel" translate="{{column.label}}"></span>
                        <button type="button" class="btn p-0" *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                            <i class="fas fa-sort"></i>
                        </button>
                        <button type="button" class="btn p-0" *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                            <i class="fas fa-sort-down"></i>
                        </button>
                        <button type="button" class="btn p-0" *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                            <i class="fas fa-sort-up"></i>
                        </button>
                    </th>
                    <th *ngIf="!column.hasSort" [ngClass]="column.class">
                        <span *ngIf="column.abbreviatedLabel" translate="{{column.abbreviatedLabel}}"
                              ngbTooltip="{{column.label | translate}}"
                              placement="bottom"
                              container="body">
                        </span>
                        <span *ngIf="!column.abbreviatedLabel" translate="{{column.label}}"></span>
                    </th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <!-- Table rows -->
            <tr *ngFor="let rule of ruleList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewRule(rule)">
                <td class="name-column">{{rule.maxAppointmentRuleTable?.name}}</td>
                <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                <td class="name-column">{{rule.name}}</td>
                <td class="text-center">{{('enum.channel.' + rule.channel) | translate}}</td>
                <td class="text-center">{{('enum.ruleScope.' + rule.ruleScope) | translate}}</td>
                <td class="text-center">{{rule.resource?.name}}</td>
                <td class="text-center">{{rule.center?.name}}</td>
                <td class="text-center">{{rule.area?.name}}</td>
                <td class="text-center">{{rule.speciality?.name}}</td>
                <td class="text-center">{{rule.tag?.name}}</td>
                <td class="text-center">
                    <i class="fas fa-plus-circle"
                       ngbTooltip="{{'label.include'| translate}}"
                       *ngIf="rulesUtils.displayIncludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                    <i class="fas fa-minus-circle"
                       ngbTooltip="{{'label.exclude'| translate}}"
                       *ngIf="rulesUtils.displayExcludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                    &nbsp;{{rule.patientTag?.name}}
                </td>
                <td class="text-center">{{rule.maxAppointments}}</td>
                <td class="text-center">{{rule.message}}</td>
                <!-- Last column, used for action buttons -->
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewRule(rule)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editRule(rule)"
                                        [activityDisplay]="'MaxAppointmentRuleUpdate'"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(rule.id)"
                                        [activityDisplay]="'MaxAppointmentRuleDelete'"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(rule.id, EntityTypeEnum.maxAppointmentRule, rule.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page filters -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-2" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
    </div>
</div>
