import {EventEmitter, Injectable} from '@angular/core';
import {
    AppointmentTypeEnum,
    PatientAppointmentDetailsOptionsType,
    PatientAppointmentListItemOptionsType,
    PatientAppointmentListRequestFilters,
    PatientAppointmentListStateType
} from './patient-appointment-list.types';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {Router} from '@angular/router';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {
    PatientAppointmentListFiltersOptionsType,
    PatientAppointmentListFiltersSearchType
} from './patient-appointment-list-filters/patient-appointment-list-filters.types';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {GenericFilterOptionsType, ODataQueryObjectType} from 'sked-base';

@Injectable({
    providedIn: 'root'
})
export class PatientAppointmentListUtils {
    // State management
    patientAppointmentListState: PatientAppointmentListStateType = this.getEmptyState();
    shouldMakeNewRequest = false;
    shouldKeepFiltersState = false;
    shouldKeepListState = false;
    preselectAppointmentType: AppointmentTypeEnum;

    // Options for filters
    filtersOptions: PatientAppointmentListFiltersOptionsType = this.getEmptyFiltersOptions();
    clearFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();

    // Options for patientAppointment list item
    patientAppointmentListItemOptions: PatientAppointmentListItemOptionsType = this.getInitialPatientAppointmentListFiltersOptions();

    // Options for patientAppointment list details
    patientAppointmentItemDetailsOptions: PatientAppointmentDetailsOptionsType = this.getInitialItemDetailsOptions();

    constructor(
        private router: Router,
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
        private patientContextService: PatientContextService,
    ) {
    }

    // State management

    updateFilterState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.filtersOptions.hasOwnProperty(property)) {
                return;
            }
            if (property === 'filterWrapperOptions') {
                // Don't cloneDeep providerInstance
                this.patientAppointmentListState.filtersOptions[property] = [];
                this.filtersOptions[property].forEach((filterWrapperOptionsObject: GenericFilterOptionsType) => {
                    const {providerInstance, ...filtersOptionsWithoutProviderInstance} = filterWrapperOptionsObject;
                    this.patientAppointmentListState.filtersOptions[property].push({
                        providerInstance,
                        ...lodash.cloneDeep(filtersOptionsWithoutProviderInstance)
                    });
                });
                return;
            }
            this.patientAppointmentListState.filtersOptions[property] = lodash.cloneDeep(this.filtersOptions[property]);
        });
    }

    updateItemsState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.patientAppointmentListItemOptions.hasOwnProperty(property)) {
                return;
            }
            this.patientAppointmentListState.patientAppointmentListItemOptions[property] =
                lodash.cloneDeep(this.patientAppointmentListItemOptions[property]);
        });
    }

    getEmptyState(): PatientAppointmentListStateType {
        return {
            filtersOptions: this.getEmptyFiltersOptions() as PatientAppointmentListFiltersOptionsType,
            patientAppointmentListItemOptions: this.getInitialPatientAppointmentListFiltersOptions() as PatientAppointmentListItemOptionsType,
            patientAppointmentItemDetailsOptions: this.getInitialItemDetailsOptions() as PatientAppointmentDetailsOptionsType,
        } as PatientAppointmentListStateType;
    }

    // ///

    getInitialItemDetailsOptions(): PatientAppointmentDetailsOptionsType {
        return {
            appointment: {},
        } as PatientAppointmentDetailsOptionsType;
    }

    getEmptyFiltersOptions(): PatientAppointmentListFiltersOptionsType {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            patientAppointmentListFiltersValues: {},
            areFiltersValid: false,
            areFiltersCollapsed: false,
            latestSearchFilterValues: undefined,
            typeFilterTab: AppointmentTypeEnum.Upcoming,
        } as PatientAppointmentListFiltersOptionsType;
    }

    getInitialPatientAppointmentListFiltersOptions(): PatientAppointmentListItemOptionsType {
        return {
            isBeforeSearchState: true,
            isNotFoundState: false,
            appointmentList: [],
            itemsPerPageList: [5, 10, 25, 50],
            latestPatientAppointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as PatientAppointmentListRequestFilters,
            patientAppointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as PatientAppointmentListRequestFilters,
            totalPatientAppointmentItems: null,
            showItemsPerPageDropdown: false
        };
    }

    getPatientAppointmentQueryFilter(patientAppointmentListRequestFilters: PatientAppointmentListRequestFilters): ODataQueryObjectType {
        return {
            count: true,
            skip: (patientAppointmentListRequestFilters.pageFilters.currentPage - 1) * patientAppointmentListRequestFilters.pageFilters.itemsPerPage,
            top: patientAppointmentListRequestFilters.pageFilters.itemsPerPage,
            filter: this.getPatientAppointmentFiltersQuery(patientAppointmentListRequestFilters.searchFilters),
            expand: this.getExpandQuery(),
            orderBy: 'DateTimeFrom ' + (this.filtersOptions?.typeFilterTab !== AppointmentTypeEnum.Upcoming ? 'desc' : 'asc')
        };
    }

    private getPatientAppointmentFiltersQuery(searchFilters: PatientAppointmentListFiltersSearchType) {
        const and: any[] = [];
        if (this.patientContextService.patient?.id) {
            and.push({PatientId: {eq: {type: 'guid', value: this.patientContextService.patient.id}}});
        }
        if (searchFilters?.resource?.id) {
            const innerAnd: any[] = [
                { Resource: { Id: {eq: {type: 'guid', value: searchFilters.resource.id}} } },
            ];
            if (this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Cancelled) {
                innerAnd.push({Cancelled: {eq: true}});
            }
            and.push({ ResourceAppointments: { any: { and: innerAnd } } });
        }
        // appointments don't have areaId so we filter by service/areaId
        if (searchFilters && searchFilters?.area?.id) {
            and.push({'Service/AreaId': {eq: {type: 'guid', value: searchFilters.area.id}}});
        }
        if (searchFilters?.service?.id) {
            and.push({ServiceId: {eq: {type: 'guid', value: searchFilters.service.id}}});
        }
        // Process Upcoming / Past / Cancelled based on the selected tab
        if (this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Upcoming) {
            and.push({
                DateTimeFrom: {
                    ge: {
                        type: 'raw',
                        value: moment().startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        else if (this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Past) {
            and.push({
                DateTimeFrom: {
                    lt: {
                        type: 'raw',
                        value: moment().startOf('day').format('YYYY-MM-DD').replace('+', '%2B')
                    }
                }
            });
        }
        else if (this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Cancelled) {
            and.push({Cancelled: true});
        }
        return and;
    }

    private getExpandQuery() {
        return {
            AppointmentType: {select: ['Name']},
            Patient: {select: ['FullName', 'Email', 'MainPhoneNumber', 'AlternatePhoneNumber', 'DocumentNumberFormatted',
                    'DocumentNumber', 'DocumentType', 'DocumentCountry']},
            Resource: {select: ['Name']},
            ResourceAppointments: {
                ...(this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Cancelled ? {
                    filter: {Cancelled: {eq: true}},
                } : {}),
                expand: {Resource: {select: ['Id', 'Name', 'ResourceTypeId']}}
            },
            Service: {
                select: ['Name', 'HasSubServices', 'MinSubServices', 'MaxSubServices', 'AreaId'],
                expand: {Speciality: {select: ['Name']}, Area: {select: ['Id', 'Name']}}
            },
            SubServices: {
                // If an appointment status is cancelled, also the SubServices for this appointment are set to cancelled
                // In order to receive them from back-end we need to add this filter
                ...(this.filtersOptions.typeFilterTab === AppointmentTypeEnum.Cancelled ? {
                    filter: {Cancelled: {eq: true}},
                } : {}),
                expand: {SubService: {select: ['Name', 'Code', 'Id']}}},
            Center: {select: ['Name']},
            CoveragePlan: {select: ['Id', 'Name']},
            SkedTasks: {},
            StatusHistories: {
                select: ['Id', 'Status', 'TransitionReasonOthers'],
                expand: {AppointmentStatusTransitionReason: {select: ['Id', 'Name', 'Others']}},
            },
        };
    }
}
