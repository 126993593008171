import {Component, OnInit} from '@angular/core';
import {ResourceSwapUtils} from '../resource-swap.utils';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {
    ResourcePublicDataType,
    ServiceType,
    CenterType,
    ResourceType,
    SubServiceType,
    AppointmentType,
    AppointmentSubServiceType,
    ResourceSwapProvider, ResourceSwapOuterDTOType, ResourceSwapType, ResourceSwapResultType
} from 'sked-base';
import {AppointmentSwapResources} from './resource-swap-options.types';
import {ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';
import * as lodash from 'lodash';

@Component({
    selector: 'app-resource-swap-options',
    templateUrl: './resource-swap-options.component.html',
    styleUrls: ['./resource-swap-options.component.scss']
})
export class ResourceSwapOptionsComponent implements OnInit {

    shouldDisableEverything = false;

    constructor(public resourceSwapUtils: ResourceSwapUtils,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private resourceSwapProvider: ResourceSwapProvider) {
    }

    ngOnInit(): void {
        this.setInitialValues();
        this.resourceSwapUtils.disableEverythingSubject?.subscribe((disable) => {
            this.shouldDisableEverything = disable;
        });
    }

    changeValueToString(value: any): string {
        return String(value);
    }

    onReplaceSwapResources() {
        const resourceSwaps: ResourceSwapOuterDTOType[] = this.resourceSwapUtils.appointmentsIds.map(AppointmentId => ({
            AppointmentId,
            NewResourceId: this.resourceSwapUtils?.swapResourcesOptions?.selectsNgValues[AppointmentId]?.id
        }));
        const resourceSwap = {
            sendClassicNotificationEmails: this.resourceSwapUtils?.swapResourcesOptions?.sendClassicNotificationEmailsValue,
            resourceId: this.resourceSwapUtils?.filtersOptions?.swapResourcesFiltersValues?.resource?.id,
            resourceSwaps: resourceSwaps.filter(({AppointmentId, NewResourceId}) => AppointmentId && NewResourceId)
        } as ResourceSwapType;
        this.ngxLoader.start();
        this.resourceSwapProvider.resourceSwap(resourceSwap).subscribe(({value}) => {
            this.resourceSwapUtils.swapResourcesOptions.resourceSwapResult = value;
            const areAllSuccess = value.every((result: ResourceSwapResultType) => result.isSuccess);
            const areAllFailure = value.every((result: ResourceSwapResultType) => !result.isSuccess);
            const isSomeFailure = value.some((result: ResourceSwapResultType) => !result.isSuccess);
            if (areAllSuccess) {
                this.messagesService.success('label.allResourceSwapsSucceeded', true);
                this.resourceSwapUtils.disableEverythingSubject.next(true);
            } else if (areAllFailure) {
                this.messagesService.error('label.allResourceSwapsFailed', true);
                this.resourceSwapUtils.disableEverythingSubject.next(false);
            } else if (isSomeFailure) {
                this.messagesService.warning('label.someResourceSwapsFailed', true);
                this.resourceSwapUtils.disableEverythingSubject.next(true);
            }
            this.ngxLoader.stop();
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    isSwapResourceResponseSuccess(appointment: AppointmentSwapResources): boolean {
        const resourceSwapResult = this.resourceSwapUtils.swapResourcesOptions.resourceSwapResult
            .find(resourceResult => appointment.appointmentId === resourceResult.appointmentId);
        return resourceSwapResult ? resourceSwapResult?.isSuccess : null;
    }

    hasSwapResourceSelectError(appointment: AppointmentSwapResources, value: any) {
        return (!this.isSwapResourceResponseSuccess(appointment) && this.isSwapResourceResponseSuccess(appointment) !== null)
        && this.resourceSwapUtils?.swapResourcesOptions?.resourceSwapResult?.length > 0
        && this.changeValueToString(value) !== 'noValue';
    }

    sanitizeNgSelectValue(option: string, appointmentId: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option === 'noValue') {
            this.resourceSwapUtils.swapResourcesOptions.selectsNgValues[appointmentId] = 'noValue';
        }
        // Remove the error result for this appointment id
        this.resourceSwapUtils.swapResourcesOptions.resourceSwapResult = lodash.filter(
            this.resourceSwapUtils.swapResourcesOptions.resourceSwapResult,
            (result: ResourceSwapResultType) => result.appointmentId !== appointmentId
        );
    }

    getObjectDetailsOptions({service, center, resource, serviceId, centerId, subServices}: AppointmentType, newResource: ResourceType): ObjectDetailsOptionsType {
        return {
            messagesService: this.messagesService,
            displayBackButton: false,
            hideButtons: true,
            service: {
                ...service,
                id: serviceId
            } as ServiceType,
            center: {
                ...center,
                id: centerId
            } as CenterType,
            resource: {
                ...newResource,
                id: newResource.id
            } as ResourceType,
            subServices: subServices?.map(
                (appointmentSubService: AppointmentSubServiceType) => appointmentSubService.subService
            ) as SubServiceType[],
            hideVerticalLine: true
        } as ObjectDetailsOptionsType;
    }

    disableReplaceButton() {
        return this.shouldDisableEverything
            || this.resourceSwapUtils?.swapResourcesOptions?.allAvailableSwapResources?.length <= 0
            || Object.values(this.resourceSwapUtils?.swapResourcesOptions?.selectsNgValues).every(value => value === 'noValue');
    }

    private setInitialValues() {
        this.resourceSwapUtils.swapResourcesOptions = this.resourceSwapUtils.getInitialSwapResourcesOptions();
        // Set initial values for select inputs
        this.resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList.forEach(({id}) => {
            this.resourceSwapUtils.swapResourcesOptions.selectsNgValues[id] = 'noValue';
        });
        this.resourceSwapUtils.swapResourcesOptions.selectsNgValues.selectResourceForAllReplacementsValue = 'noValue';
    }
}
