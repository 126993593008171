<div class="container-fluid slot-filter-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.slotFilterRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                                                            [contentTemplate]="slotFilterRuleModalFiltersContentTemplate"
                                                            [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                                                            [title]="'label.filterMore'"
                                                            (modalClose)="onModalClose($event)">
                </sbase-show-template-modal-filter-container>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #slotFilterRuleModalFiltersContentTemplate>
        <div class="slot-filter-rule-filter-more-modal-container">
            <!-- Gender -->
            <div class="rules-select-filter rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.genderOptions">
                <label class="mr-2 text-high-contrast" translate="label.gender"></label>
                <ng-select class="form-control rounded-1 custom-ng-select"
                           dropdownPosition="bottom"
                           placeholder="{{'label.all' | translate}}"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           [ngClass]="!!rulesUtils.overviewState.modalFilters.genderOptions.ngModel ? 'ng-select-has-value' : null"
                           [(ngModel)]="rulesUtils.overviewState.modalFilters.genderOptions.ngModel"
                           (ngModelChange)="sanitizeNgSelectValue($event, 'genderOptions')">
                    <ng-option [value]="undefined">
                        <span translate="label.all"></span>
                    </ng-option>
                    <ng-option *ngFor="let gender of genderEnumList" [value]="gender">
                        <span>{{'enum.gender.' + gender | translate}}</span>
                    </ng-option>
                </ng-select>
            </div>
            <!-- Age -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.ageOptions">
                <label class="mr-2 text-high-contrast" translate="label.age"></label>
                <input type="number" class="form-control rounded-1"
                       placeholder="{{'label.searchBy' | translate}} {{'label.age' | translate}}"
                       autocomplete="off" min="0" max="150"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.ageOptions.ngModel">
            </div>
            <!-- Coverage Plan -->
            <div class="rules-text-filter coveragePlanFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.coveragePlan2"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedCoveragePlan($event)"
                              [buttonName]="'label.coveragePlan2'"
                              [dependentFilters]="mainDependentFilters.coveragePlan"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.coveragePlanOptions.ngModel ?? []"
                              [providerInstance]="coveragePlanProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              class="sbase-dropdown-ml">
                </sbase-filter>
            </div>
            <!-- Availability Tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.availabilityTagOptions.ngModel">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.availabilityTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag('availability', $event)"
                              [buttonName]="'label.availabilityTag'"
                              [dependentFilters]="mainDependentFilters.availabilityTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.availabilityTagOptions.ngModel ?? []"
                              [providerInstance]="tagProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>
            <!-- Patient Tag -->
            <div *ngIf="!!rulesUtils.overviewState.modalFilters.patientTagOptions.ngModel">
                <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.patientTag">
                </label>
                <sbase-filter (selectedItemsOutput)="onSelectedTag('patient', $event)"
                              [buttonName]="'label.patientTag'"
                              [dependentFilters]="mainDependentFilters.patientTag"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters.patientTagOptions.ngModel ?? []"
                              [providerInstance]="tagProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>
            <!-- Message -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.messageOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.message"></label>
                <input type="text" class="form-control rounded-1"
                       placeholder="{{'label.searchBy' | translate}} {{'label.message' | translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.messageOptions.ngModel">
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active sorting -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key + (key === 'coveragePlan' ? '2' : '') | translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchSlotFilterRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="'SlotFilteRuleCreate'"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="'SlotFilteRuleCreate'"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
                <!-- Table headers -->
                <tr>
                    <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                    <ng-container *ngFor="let column of tableHeaderColumns">
                        <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'"
                            (click)="onSortBy(column.onSortByProperty)">
                            {{column.label | translate}}
                            <button type="button" class="btn p-0"
                                    *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                                <i class="fas fa-sort"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                                <i class="fas fa-sort-down"></i>
                            </button>
                            <button type="button" class="btn p-0"
                                    *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                                <i class="fas fa-sort-up"></i>
                            </button>
                        </th>
                        <th *ngIf="!column.hasSort" [ngClass]="column.class" [translate]="column.label"></th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows -->
                <tr *ngFor="let rule of ruleList"
                    [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                    sbaseMarkTableRowOnHover
                    (click)="viewRule(rule)">
                    <td class="name-column">{{rule.slotFilterRuleTable?.name}}</td>
                    <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                    <td class="name-column">{{rule.name}}</td>
                    <td class="text-center">{{rule.onlyWarning | yesNoPipe | translate}}</td>
                    <td class="text-center">
                        <i class="fas fa-plus-circle"
                           ngbTooltip="{{'label.include'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayIncludeIcon(rule.gender, rule.operationGender)"></i>
                        <i class="fas fa-minus-circle"
                           ngbTooltip="{{'label.exclude'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayExcludeIcon(rule.gender, rule.operationGender)"></i>
                        &nbsp;{{rule.gender ? ('enum.gender.' + rule.gender | translate) : ''}}
                    </td>
                    <td class="text-center">{{rule.age ? rule.operationAge + ' ' + rule.age: ''}}</td>
                    <td class="text-center">
                        <i class="fas fa-plus-circle"
                           ngbTooltip="{{'label.include'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayIncludeIcon(rule.coveragePlan, rule.operationCoveragePlan)"></i>
                        <i class="fas fa-minus-circle"
                           ngbTooltip="{{'label.exclude'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayExcludeIcon(rule.coveragePlan, rule.operationCoveragePlan)"></i>
                        &nbsp;{{rule.coveragePlan?.name}}
                    </td>
                    <td class="text-center">
                        <i class="fas fa-plus-circle"
                           ngbTooltip="{{'label.include'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayIncludeIcon(rule.tag, rule.operationTag)"></i>
                        <i class="fas fa-minus-circle"
                           ngbTooltip="{{'label.exclude'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayExcludeIcon(rule.tag, rule.operationTag)"></i>
                        &nbsp;{{rule.tag?.name}}
                    </td>
                    <td class="text-center">
                        <i class="fas fa-plus-circle"
                           ngbTooltip="{{'label.include'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayIncludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                        <i class="fas fa-minus-circle"
                           ngbTooltip="{{'label.exclude'| translate}}"
                           *ngIf="slotFilterRuleUtils.displayExcludeIcon(rule.patientTag, rule.includePatientTag)"></i>
                        &nbsp;{{rule.patientTag?.name}}
                    </td>
                    <td class="name-column">{{rule.message}}</td>
                    <!-- Last column, used for action buttons -->
                    <td class="text-center" (click)="$event.stopPropagation()">
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.view"
                                            (click)="viewRule(rule)"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.edit"
                                            (click)="editRule(rule)"
                                            [activityDisplay]="'SlotFilterRuleUpdate'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="displayConfirmDeleteItemModal(rule.id)"
                                            [activityDisplay]="'SlotFilterRuleDelete'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.changeLog"
                                            (click)="displayChangeLog(rule.id, EntityTypeEnum.slotFilterRule, rule.name)"
                                            [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Page filters -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-2" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start" x-placement="bottom-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
    </div>
</div>
