<div class="container-fluid create-resource-channel-restriction-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-resource-channel-restriction-header">
        <h3 class="create-resource-channel-restriction-title">{{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.timeWindow"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveResourceChannelRestrictionData(resourceChannelRestrictionItem)">
        <div class="create-entity-md-section">
            <!--Body-->
            <!-- =========================================== -->
            <div class="create-resource-channel-restriction-inner-container custom-scrollbar pr-2">
                <ng-container *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                    <div class="row mt-4">
                        <div class="col-6">
                            <div class="form-group">
                                <label translate="label.generalInformation"></label>
                                <table class="table border mt-2">
                                    <tbody>
                                    <tr>
                                        <td translate="label.resource"></td>
                                        <td class="text-right">{{resourceChannelRestrictionItem.resource.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.service"></td>
                                        <td class="text-right">
                                            <span *ngIf="resourceChannelRestrictionItem.service.speciality?.name" class="font-weight-bold">
                                                {{resourceChannelRestrictionItem.service.speciality.name}}
                                            </span>
                                            {{resourceChannelRestrictionItem.service.name}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate="label.timeWindowTab.channel"></td>
                                        <td class="text-right">{{resourceChannelRestrictionItem.channel}}</td>
                                    </tr>
                                    <tr>
                                        <td translate="label.timeWindowTab.days"></td>
                                        <td class="text-right">{{resourceChannelRestrictionItem.days}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label translate="label.centerOverrides"></label>
                                <table class="table border mt-2">
                                    <thead>
                                    <tr>
                                        <th translate="label.center"></th>
                                        <th class="text-right" translate="label.timeWindowTab.days"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let centerOverride of resourceChannelRestrictionItem.centerOverrides, let i = index">
                                        <td class="name-column">{{initialFilterValues.center[i]?.name}}</td>
                                        <td class="text-right">{{centerOverride.days}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <div class="form-group sbase-input-element">
                                <label translate="label.resource"></label>
                                <div>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResourceFilter($event)"
                                                  [buttonName]="'label.resource' | translate"
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [hideSelectedItems]="true"
                                                  [initialFilterValues]="initialFilterValues.resource"
                                                  [isMultipleSelectedItem]="false"
                                                  [providerInstance]="resourceProvider"
                                                  [ngClass]="{'input-element-has-error': !resourceChannelRestrictionItem.resourceId
                                              && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                                  [useSelectedValueAsLabel]="true">
                                    </sbase-filter>
                                    <div *ngIf="!resourceChannelRestrictionItem.resourceId
                                     && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12">
                            <div class="form-group service sbase-input-element">
                                <label translate="label.service"></label>
                                <sbase-filter (selectedItemsOutput)="onSelectedServiceFilter($event)"
                                              [buttonName]="'label.service' | translate"
                                              [dependentFilters]="mainDependentFilters.service"
                                              [hideSelectedItems]="true"
                                              [initialFilterValues]="initialFilterValues.service"
                                              [isMultipleSelectedItem]="false"
                                              [providerInstance]="serviceProvider"
                                              [ngClass]="{'input-element-has-error': !resourceChannelRestrictionItem.serviceId
                                              && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                              [useSelectedValueAsLabel]="true">
                                </sbase-filter>
                                <div *ngIf="!resourceChannelRestrictionItem.serviceId
                                     && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-12">
                            <div class="form-group sbase-input-element">
                                <label translate="label.timeWindowTab.channel"></label>
                                <sbase-filter (selectedItemsOutput)="onSelectedChannelFilter($event)"
                                              [buttonName]="'label.timeWindowTab.channel' | translate"
                                              [dependentFilters]="mainDependentFilters.channel"
                                              [hideSelectedItems]="true"
                                              [initialFilterValues]="initialFilterValues.channel"
                                              [isMultipleSelectedItem]="false"
                                              [providerInstance]="channelProvider"
                                              [ngClass]="{'input-element-has-error': !resourceChannelRestrictionItem.channel
                                              && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                              [useSelectedValueAsLabel]="true">
                                </sbase-filter>
                                <div *ngIf="!resourceChannelRestrictionItem.channel
                                     && screenTemplateLayout.action !== CONSTANTS.VIEW" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-12">
                            <div class="form-group">
                                <label translate="label.timeWindowTab.days"></label>
                                <input type="number" [min]="1" [max]="MAX_NUMBER_OF_DAYS" name="days"
                                       class="input-element"
                                       [(ngModel)]="resourceChannelRestrictionItem.days"
                                       [ngClass]="{'input-element-has-error': !isNumberOfDaysValid(resourceChannelRestrictionItem.days)
                                   && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                       placeholder="{{'label.timeWindowTab.days'|translate}}"
                                       autocomplete="off">
                                <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                    <div *ngIf="generalUtils.isEmpty(resourceChannelRestrictionItem.days)"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div
                                        *ngIf="!generalUtils.isEmpty(resourceChannelRestrictionItem.days) && !isNumberOfDaysValid(resourceChannelRestrictionItem.days)"
                                        class="help-block">
                                        <span translate="label.error.invalidNumberOfDays"></span>{{MAX_NUMBER_OF_DAYS}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4 col-12">
                            <div class="form-group sbase-input-element"
                                 [ngClass]="{'disabled-input': !isCenterOverrideFilterEnabled()}">
                                <label translate="label.center"></label>
                                <div>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenterFilter($event)"
                                                  [buttonName]="'label.center' | translate"
                                                  [dependentFilters]="mainDependentFilters.center"
                                                  [hideSelectedItems]="true"
                                                  [initialFilterValues]="initialFilterValues.center"
                                                  [isMultipleSelectedItem]="true"
                                                  [providerInstance]="centerProvider"
                                                  [ngClass]="{'input-element-has-error': !isCenterOverrideFilterEnabled()
                                              && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                                  [useSelectedValueAsLabel]="false">
                                    </sbase-filter>
                                    <div *ngIf="!isCenterOverrideFilterEnabled()
                                     && screenTemplateLayout.action !== CONSTANTS.VIEW"
                                         class="help-block">
                                        <span translate="label.error.invalidEnteredDataAnotherEntry"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-8 col-12">
                            <div class="flex flex-row no-block">
                                <label translate="label.centerOverrides"></label>
                            </div>
                            <div class="pt-0 pb-0 flex-shrink-1 overview-table-container">
                                <ng-container>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>{{'label.center' | translate}}</th>
                                            <th>{{'label.timeWindowTab.days' | translate}}</th>
                                            <th class="text-center" translate="label.actions"
                                                *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let centerOverride of resourceChannelRestrictionItem.centerOverrides, let i = index">
                                            <td class="name-column">
                                                {{initialFilterValues.center[i]?.name}}
                                            </td>
                                            <td>
                                                <div class="form-group">
                                                    <input type="number" [min]="1" [max]="MAX_NUMBER_OF_DAYS"
                                                           name="{{'centerOverride-'+i+'-days'}}"
                                                           class="input-element"
                                                           [(ngModel)]="centerOverride.days"
                                                           [ngClass]="{'input-element-has-error': !isNumberOfDaysValid(centerOverride.days)
                                                       && screenTemplateLayout.action !== CONSTANTS.VIEW}"
                                                           placeholder="{{'label.timeWindowTab.days'|translate}}"
                                                           autocomplete="off">
                                                    <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                                        <div *ngIf="generalUtils.isEmpty(centerOverride.days)"
                                                             class="help-block">
                                                            <span translate="label.error.required"></span>
                                                        </div>
                                                        <div
                                                            *ngIf="!generalUtils.isEmpty(centerOverride.days) && !isNumberOfDaysValid(centerOverride.days)"
                                                            class="help-block">
                                                            <span
                                                                translate="label.error.invalidNumberOfDays"></span>{{MAX_NUMBER_OF_DAYS}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"
                                                *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                                <button type="button" class="btn"
                                                        title="{{'label.delete' | translate}}"
                                                        (click)="deleteCenterOverride(i)">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--End Body-->
            <!-- =========================================== -->
            <!--Footer-->
            <!-- =========================================== -->
            <div class="mt-2 footer-position-sticky">
                <button class="btn mr-3"
                        type="button"
                        (click)="goToParentPage()"
                        [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                        translate="{{screenTemplateLayout.cancelOrBackButton}}">
                </button>
                <button class="btn theme-btn"
                        type="submit"
                        [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                        [disabled]="!isSaveButtonEnabled()"
                        translate="{{screenTemplateLayout.createOrSaveButton}}">
                </button>
                <button (click)="goToEdit()"
                        *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                        [activityDisplay]="'KeyResourceChannelRestrictionUpdateActivity'"
                        class="btn theme-btn"
                        type="button">
                    <span translate="label.edit"></span>
                </button>
            </div>
            <!--End Footer-->
            <!-- =========================================== -->
        </div>
    </form>
</div>
