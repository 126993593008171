import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnInit} from '@angular/core';
import {ExportTypeEnum, GenerateExportOptionsType} from '../../room-reservation/room-reservation.types';

@Component({
    selector: 'app-export-capacity-room-modal',
    templateUrl: './export-capacity-room-modal.component.html',
    styleUrls: ['./export-capacity-room-modal.component.scss']
})

export class ExportCapacityRoomModalComponent implements OnInit {
    @Input() public resourceTypeId;
    optionsForPlannedCapacityForRooms: GenerateExportOptionsType;

    constructor(private activeModal: NgbActiveModal) {
    }


    ngOnInit() {
        this.loadOptionsForGenerateExport();
    }

    loadOptionsForGenerateExport() {
        this.optionsForPlannedCapacityForRooms = {
            exportType: ExportTypeEnum.PlannedCapacityForRooms,
            resourceTypeId: this.resourceTypeId
        };
    }

    closeModel() {
        this.activeModal.dismiss('close');
    }
}
