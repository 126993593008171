<div class="container-fluid multi-resource-blue-print-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.multiResourceBlueprintTab.title"></h3>
        <div class="filters-section d-flex no-block">
            <sbase-filter (selectedItemsOutput)="onSelectedResourceTypeFilter($event)"
                          [buttonName]='"label.resourceType.title"'
                          [dependentFilters]="resourceTypeDependentFilters"
                          [initialFilterValues]="multiResourceBluePrintMdUtils.initialResourceTypeFilterValues"
                          [isMultipleSelectedItem]="false"
                          [useSelectedValueAsLabel]="true"
                          [hideSelectedItems]="true"
                          [providerInstance]="resourceTypeProvider">
            </sbase-filter>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
            <span *ngFor="let key of objectKeys(multiResourceBluePrintMdUtils.tableFilters.orderBy)">
                <span
                    *ngIf="key">{{'label.multiResourceBlueprintTab.' + (key !== 'active' ? key : 'activateBlueprint') |translate}}</span> - {{'label.' + multiResourceBluePrintMdUtils.tableFilters.orderBy[key] | translate}}
                <span>
                        <i class="fas fa-times cursor-pointer mr-2" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <div class="d-flex no-block search">
            <button type="button" class="btn theme-alt-btn border-0 ml-2"
                    [activityDisplay]="MULTI_RESOURCE_BLUE_PRINT+CONSTANTS.CREATE"
                    (click)="createMultiResourceBluePrint()">{{'label.createNew'|translate}}
            </button>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="id-column" translate="label.shortId"></th>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.multiResourceBlueprintTab.name' | translate}}
                    <button type="button" class="btn p-0"
                            *ngIf="!multiResourceBluePrintMdUtils.tableFilters.orderBy.name"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="multiResourceBluePrintMdUtils.tableFilters.orderBy.name === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="multiResourceBluePrintMdUtils.tableFilters.orderBy.name === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="name-column cursor-pointer"
                    translate="label.multiResourceBlueprintTab.mainResourceType"></th>
                <th class="name-column cursor-pointer"
                    translate="label.multiResourceBlueprintTab.secondaryResourceType"></th>
                <th class="name-column cursor-pointer" (click)="onSortBy('active')">
                    {{'label.multiResourceBlueprintTab.activateBlueprint' | translate}}
                    <button type="button" class="btn p-0"
                            *ngIf="!multiResourceBluePrintMdUtils.tableFilters.orderBy.active"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="multiResourceBluePrintMdUtils.tableFilters.orderBy.active === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="multiResourceBluePrintMdUtils.tableFilters.orderBy.active === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let multiResourceBluePrint of multiResourceBluePrintMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewMultiResourceBluePrint(multiResourceBluePrint)">
                <td class="id-column">{{multiResourceBluePrint.shortId}}</td>
                <td class="name-column">{{multiResourceBluePrint.name}}</td>
                <td class="name-column">{{getResourceName(multiResourceBluePrint, true)}}</td>
                <td class="name-column">
                    <div *ngFor="let secondaryResourceName of getResourceName(multiResourceBluePrint, false)">
                        {{secondaryResourceName}}
                    </div>
                </td>
                <td class="name-column">{{multiResourceBluePrint.active | yesNoPipe | translate}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewMultiResourceBluePrint(multiResourceBluePrint)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editMultiResourceBluePrint(multiResourceBluePrint)"
                                        [activityDisplay]="MULTI_RESOURCE_BLUE_PRINT+CONSTANTS.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(multiResourceBluePrint.id)"
                                        [activityDisplay]="MULTI_RESOURCE_BLUE_PRINT+CONSTANTS.DELETE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(multiResourceBluePrint.id, EntityTypeEnum.multiResourceBluePrint, multiResourceBluePrint.name)"
                                        [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems"
                        [(page)]="multiResourceBluePrintMdUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="multiResourceBluePrintMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{multiResourceBluePrintMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
