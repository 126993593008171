import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'shortDateFormatPipe'
})

export class ShortDateFormatPipe extends DatePipe implements PipeTransform {
  transform(date: any, timeZone?: string): any {
    // @ts-ignore
    return super.transform(date, moment.localeData().longDateFormat('shortDate'), timeZone);
  }
}
