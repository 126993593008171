import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {MultiAppointmentBookingComponent} from './multi-appointment-booking.component';
import {MabBookAppointmentComponent} from './mab-book-appointment/mab-book-appointment.component';
import {MabSlotsCalendarComponent} from './mab-slots-calendar/mab-slots-calendar.component';
import {MabSlotsLocalFiltersComponent} from './mab-slots-local-filters/mab-slots-local-filters.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MabSlotSearchModalComponent} from './mab-slot-search-modal/mab-slot-search-modal.component';
import {MabSlotsListsWrapperComponent} from './mab-slots-lists-wrapper/mab-slots-lists-wrapper.component';
import {MabSlotsListComponent} from './mab-slots-lists-wrapper/mab-slots-list/mab-slots-list.component';
import {MabReservedSlotsComponent} from './mab-reserved-slots/mab-reserved-slots.component';
import {
    MabAppointmentTypeAndObjectDetailsModalComponent
} from './mab-appointment-type-and-object-details-modal/mab-appointment-type-and-object-details-modal.component';
import { MabAppointmentsInformationComponent } from './mab-appointments-information/mab-appointments-information.component';
import {
    MabSlotContinueSearchModalComponent
} from './mab-slot-continue-search-modal/mab-slot-continue-search-modal.component';

@NgModule({
    declarations: [MultiAppointmentBookingComponent, MabBookAppointmentComponent, MabSlotsCalendarComponent,
        MabSlotSearchModalComponent, MabSlotsLocalFiltersComponent, MabSlotsListsWrapperComponent, MabSlotsListComponent,
        MabReservedSlotsComponent, MabAppointmentTypeAndObjectDetailsModalComponent, MabAppointmentsInformationComponent,
        MabSlotContinueSearchModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        MatProgressSpinnerModule,
        InfiniteScrollModule,
        NgxMaterialTimepickerModule
    ]
})
export class MultiAppointmentBookingModule {
}
