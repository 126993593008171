<div class="container-fluid room-reservation custom-scrollbar">
    <div class="sort-side">
        <div class="row">
            <!-- ============================================================== -->
            <!-- Room Reservation Calendar, Overview, Center, Resource Filters -->
            <!-- ============================================================== -->
            <div class="col-md-3 my-1">
                <div class="filter-buttons">
                    <!-- ============================================================== -->
                    <!-- Overview Filter-->
                    <!-- ============================================================== -->
                    <div class="btn btn-secondary btn-lg filter-buttons button-overview filter-component button-overview-disabled"
                         (click)="showOverviewDropdown = !showOverviewDropdown">
                        <span class="dropdown-toggle button-disabled" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="true" [ngClass]="{'show': showOverviewDropdown}">
              <strong>{{"label." + requestTableFilters.roomOrResourceOverview | translate}}</strong>
            </span>
                        <div [exclude]="'.button-overview'" (clickOutside)=onClickedOutsideOverviewFilter($event)
                             class="dropdown-menu dropdown-view-type" x-placement="top-start" x-placement="bottom-start"
                             [ngClass]="{'show': showOverviewDropdown}">
                            <a class="dropdown-item" (click)="onChangeOverview(RESOURCE_OVERVIEW)"
                               translate="label.resourceOverview">
                            </a>
                            <span>
                <a class="dropdown-item" (click)="onChangeOverview(ROOM_OVERVIEW)" translate="label.roomOverview">
                </a>
              </span>
                        </div>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Overview Filter-->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Center Filter -->
                    <!-- ============================================================== -->
                    <sbase-filter (selectedItemsOutput)=" onSelectedCenterFilter($event)" [buttonName]=centerButtonName
                                  [dependentFilters]="mainDependentFilters.location"
                                  [initialFilterValues]="initialCenterValues"
                                  [isMultipleSelectedItem]="false" [providerInstance]="centerProvider"
                                  [useSelectedValueAsLabel]="true"
                                  [hideSelectedItems]="true"
                                  [ngClass]="{'has-error':!requestTableFilters.center || !requestTableFilters.center.id, 'has-no-value-selected': !requestTableFilters.center}"
                                  class="sbase-dropdown-ml">
                    </sbase-filter>
                    <!-- ============================================================== -->
                    <!-- End Center Filter -->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Resource Filter -->
                    <!-- ============================================================== -->
                    <div [ngClass]="{'cursor-not-allowed': !requestTableFilters.center || !requestTableFilters.center.id}">
                        <sbase-filter (selectedItemsOutput)=" onSelectedResourceFilter($event)"
                                      [buttonName]=resourceButtonName
                                      [dependentFilters]="mainDependentFilters.resource"
                                      [initialFilterValues]="initialResourceValues" [isMultipleSelectedItem]="false"
                                      [providerInstance]="resourceProvider"
                                      [useSelectedValueAsLabel]="true"
                                      [hideSelectedItems]="true"
                                      [ngClass]="{'pointer-events-none':!requestTableFilters.center || !requestTableFilters.center.id, 'has-no-value-selected' : !requestTableFilters.resource}">
                        </sbase-filter>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Resource Filter -->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Room Filter -->
                    <!-- ============================================================== -->
                    <div [ngClass]="{'cursor-not-allowed': !requestTableFilters.center || !requestTableFilters.center.id}">
                        <sbase-filter (selectedItemsOutput)=" onSelectedRoomFilter($event)" [buttonName]=roomButtonName
                                      [dependentFilters]="mainDependentFilters.room"
                                      [initialFilterValues]="initialRoomValues"
                                      [isMultipleSelectedItem]="false" [providerInstance]="resourceProvider"
                                      [useSelectedValueAsLabel]="true"
                                      [hideSelectedItems]="true"
                                      [ngClass]="{'pointer-events-none':!requestTableFilters.center || !requestTableFilters.center.id, 'has-no-value-selected': !requestTableFilters.room}">
                        </sbase-filter>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Room Filter -->
                    <!-- ============================================================== -->
                    <div>
                        <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                      [buttonName]='"label.newTag"'
                                      [dependentFilters]="mainDependentFilters?.tags"
                                      [initialFilterValues]="requestTableFilters.tags"
                                      [isMultipleSelectedItem]="true"
                                      [useSelectedValueAsLabel]="true"
                                      [hideSelectedItems]="true"
                                      [providerInstance]="tagProvider">
                        </sbase-filter>
                    </div>


                    <!-- ============================================================== -->
                    <!-- Checkbox -->
                    <!-- ============================================================== -->
                    <div class="checkbox-wrapper filter-buttons" *ngIf="false" (click)="onShowFullyBookedRooms()">
                        <input type="checkbox" [ngModel]="requestTableFilters.showFullyBookedRooms">
                        <label translate="label.showFullyBookedRooms"></label>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Checkbox -->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Calendar -->
                    <!-- ============================================================== -->
                    <div [ngStyle]="{'cursor': !showCalendars ? 'not-allowed' : ''}">
                        <ngb-datepicker name="dp"
                                        ngbDatepicker
                                        [(ngModel)]="requestTableFilters.currentDate"
                                        [disabled]="!showCalendars"
                                        [dayTemplate]="customDay"
                                        #datepicker>
                        </ngb-datepicker>
                    </div>

                    <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                 let-disabled="disabled" let-focused="focused">
                        <div class="custom-date-day btn-light" [ngClass]="roomReservationUtils.getTodayCssClass(date)"
                             [class.bg-primary]="selected"
                             [class.hidden]="date.month !== currentMonth"
                             [class.focused]="focused"
                             [class.text-muted]="date.month !== currentMonth || !showCalendars"
                             (click)="onSelectedDateFilter(date)">
                            {{ date.day }}
                            <div class="conflicts-item" *ngFor="let conflictInstanceItem of displayedGrouppedConflicts">
                                <span
                                    [ngClass]="{'my-custom-dot': roomReservationUtils.hasDateConflicts(grouppedConflictsArray,conflictInstanceItem.date, date)}">
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <!-- ============================================================== -->
                    <!-- End Calendar -->
                    <!-- ============================================================== -->
                    <!-- ============================================================== -->
                    <!-- Conflicts -->
                    <!-- ============================================================== -->
                    <div class="conflicts-container" *ngIf="conflictsArray.length > 0">
                        <span class="conflicts-title" translate="label.conflicts"></span><span
                            class="conflicts-title">:</span>
                        <div class="separator"></div>
                        <div class="conflicts-list">
                            <div class="conflicts-item" *ngFor="let conflictInstanceItem of conflictsArray">
                                <div *ngIf="!conflictInstanceItem.isSuccessful">
                                    <span>{{conflictInstanceItem.formattedDate}}:&nbsp;{{conflictInstanceItem.formattedTimeInterval}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ============================================================== -->
                    <!-- End Calendar -->
                    <!-- ============================================================== -->
                </div>
            </div>

            <div class="col-md-9 my-1 room-reservation-calendar-container">
                <div class="room-reservation-calendar"
                     *ngIf="showCalendars">
                    <ngx-ui-loader class="local-spinner" [hasProgressBar]="false"
                                   [loaderId]="calendarSpinnerId"></ngx-ui-loader>
                    <full-calendar class="resource-calendar"
                                   #resourceCalendar
                                   [options]="resourceCalendarOptions"
                    ></full-calendar>

                    <full-calendar class="rooms-calendar" #roomsCalendar
                                   [options]="roomsCalendarOptions"
                    ></full-calendar>
                </div>
                <!-- Display this message if the mandatory filters do not fill -->
                <!-- ============================================================== -->
                <div class="h2 error-message" *ngIf="!roomReservationUtils.areTableFiltersValid(requestTableFilters)">
                    <span translate="label.error.invalidFiltersOnRoomReservation"></span></div>
                <!-- ============================================================== -->
                <!-- End Display this message if the mandatory filters do not fill -->
                <!-- ============================================================== -->

                <div *ngIf="areMoreAvailableRooms" class="warning-message"
                     translate="label.warning.displayAllAvailableRooms"></div>
            </div>
            <!-- ============================================================== -->
            <!-- End Room Reservation Calendar, Center, Resource Filters -->
            <!-- ============================================================== -->
        </div>
    </div>
</div>
