import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AvailabilityDataType,
    AvailabilityProvider,
    AvailabilityStatusEnum,
    AvailabilityValidationTypeEnum,
    ResponseValidationType
} from 'sked-base';
import {Router} from '@angular/router';
import {constants} from '../../../shared/constants/constants';
import {mergeMap, take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {AvailabilityUtils} from '../availability-utils';
import * as lodash from 'lodash';
import {of} from 'rxjs';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-view-approve-availability',
    templateUrl: './view-approve-availability.component.html',
    styleUrls: ['view-approve-availability.component.scss']
})

export class ViewApproveAvailabilityComponent implements OnInit, OnDestroy {
    availability: AvailabilityDataType = {} as AvailabilityDataType;
    action: string;
    constants = constants;
    textToDisplay: string;
    responseValidation: ResponseValidationType = {} as ResponseValidationType;
    comingFromRoute = ['/approveAvailability'];

    constructor(public router: Router,
                public ngxLoader: NgxUiLoaderService,
                public availabilityProvider: AvailabilityProvider,
                public availabilityUtils: AvailabilityUtils,
                public messagesService: MessagesService) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy() {
    }

    onChangeStatusAvailability(availabilityId: string, action: string) {
        if (lodash.isEmpty(this.responseValidation.error)) {
            this.ngxLoader.start();
            this.availabilityProvider.approveOrDeclineAvailability(availabilityId, action)
                .pipe(take(1))
                .subscribe((response: any) => {
                    this.ngxLoader.stop();
                    if (action === constants.APPROVE) {
                        this.messagesService.success('toastr.success.availabilityApproved');
                    } else {
                        this.messagesService.success('toastr.success.availabilityDeclined');
                    }
                    this.goToParent();
                }, err => {
                    this.onError(err);
                });
        }
    }

    goToParent() {
        this.router.navigate(this.comingFromRoute);
    }

    private setupInitialState() {
        if (history.state?.availabilityId) {
            this.action = history.state.action;
            const availabilityId = history.state.availabilityId;
            if (history.state.comingFromRoute) {
                this.comingFromRoute = [history.state.comingFromRoute];
            }
            this.ngxLoader.start();
            this.availabilityProvider.getAvailabilityDataById(availabilityId)
                .pipe(
                    mergeMap((availabilityData) => {
                        this.availability = availabilityData;
                        return this.getAvailabilityValidationObservable(this.action, availabilityData);
                    }),
                    take(1)
                )
                .subscribe((response: ResponseValidationType) => {
                    this.ngxLoader.stop();
                    this.responseValidation = response;
                }, err => {
                    this.onError(err);
                });
            this.textToDisplay = this.action !== constants.VIEW ? this.availabilityUtils.getApproveAvailabilityTemplateTitle(this.action)
                : 'label.view';
        }
    }

    private onError(error) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(error);
    }

    private getAvailabilityValidationObservable(action: string, availabilityData: AvailabilityDataType) {
        if ((action === constants.APPROVE || action === constants.VIEW) && availabilityData.status !== AvailabilityStatusEnum.MarkedForDelete) {
            const requestAvailability = this.availabilityUtils.mapItemToAvailabilityWithTags(availabilityData);
            return this.availabilityProvider.validate(requestAvailability, AvailabilityValidationTypeEnum.Approve);
        } else {
            return of({} as ResponseValidationType);
        }
    }
}
