<div class="object-details-modal">
    <div class="modal-header">
        <h4>
            <strong>{{"label.objectDetails" | translate}}</strong>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="options && !displayNotFoundMessage"
             class="container d-flex no-block flex-column custom-scrollbar">
            <sbase-object-details [options]="options"
                                  (action)="onObjectDetailsAction($event)"></sbase-object-details>
        </div>
        <div *ngIf="options && displayNotFoundMessage"
             class="container-fluid no-object-details-found-message-container pb-1 flex-grow-1 d-flex no-block justify-content-center align-items-center text-high-contrast">
            <i class="fas fa-info-circle mr-3"></i>
            <span translate="label.noObjectDetailsFound"></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn min-width-auto"
                (click)="activeModal.close()" translate="label.close">
        </button>
    </div>
</div>



