import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BookingFiltersManagementComponent} from './booking-filters-management.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [BookingFiltersManagementComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        DragDropModule,
    ]
})
export class BookingFiltersManagementModule {
}
