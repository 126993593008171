import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentProvider, AppointmentType, ODataQueryObjectType} from 'sked-base';
import * as lodash from 'lodash';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../services/messages.service';

@Component({
    selector: 'app-details-modal',
    templateUrl: './details-modal.component.html',
    styleUrls: ['./details-modal.component.scss']
})
export class DetailsModalComponent implements OnInit {
    @Input() appointmentId: string;

    appointment: AppointmentType;
    spinnerId = 'status-history';

    constructor(public activeModal: NgbActiveModal,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private appointmentProvider: AppointmentProvider) {
    }

    ngOnInit(): void {
        this.loadAppointmentDetails(this.appointmentId);
    }

    onCloseModal() {
        this.activeModal.close();
    }

    private loadAppointmentDetails(appointmentId: string) {
        const queryFilter = this.getQueryFilterForStatusHistories();
        this.ngxLoader.startLoader(this.spinnerId);
        this.appointmentProvider.getById(appointmentId, queryFilter)
            .subscribe((appointment) => {
                appointment.statusHistories = lodash.orderBy(appointment.statusHistories, ['modifiedOn'], ['desc']);
                this.appointment = appointment;
                this.ngxLoader.stopLoader(this.spinnerId);
            }, err => {
                this.ngxLoader.stopLoader(this.spinnerId);
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private getQueryFilterForStatusHistories(): ODataQueryObjectType {
        return {
            expand: {
                StatusHistories: {},
                AppointmentExternalKeys: {
                    select: ['Origin', 'Key']
                }
            }
        };
    }

}
