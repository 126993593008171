import {Component, OnInit} from '@angular/core';
import {
    AvailabilityChangeDocumentType,
    AvailabilityDataType,
    AvailabilityProvider
} from 'sked-base';
import {AvailabilityUtils} from '../availability-utils';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-availability-history',
    templateUrl: './availability-history.component.html',
    styleUrls: ['./availability-history.component.scss']
})
export class AvailabilityHistoryComponent implements OnInit {
    availabilityData: AvailabilityDataType;
    availabilityHistoryList: AvailabilityChangeDocumentType[] = [];

    private availabilityId: string;

    constructor(public availabilityProvider: AvailabilityProvider,
                public availabilityUtils: AvailabilityUtils,
                public messagesService: MessagesService,
                public ngxLoader: NgxUiLoaderService,
                public router: Router) {
    }

    ngOnInit(): void {
        this.availabilityId = history?.state?.availabilityId;
        if (this.availabilityId === null || this.availabilityId === undefined) {
            this.goToParentPage();
        } else {
            this.loadData();
        }
    }

    goToParentPage() {
        this.router.navigate(['/availability']);
    }

    private loadData() {
        this.ngxLoader.start();
        forkJoin([
            this.availabilityProvider.getAvailabilityDataById(this.availabilityId),
            this.availabilityProvider.getHistory(this.availabilityId)])
            .pipe(take(1))
            .subscribe(([availabilityData, availabilityHistory]) => {
                this.availabilityData = availabilityData;
                this.availabilityHistoryList = availabilityHistory.value;
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

}
