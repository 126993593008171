import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FeaturesModule} from './features/features.module';
import {CoreModule} from './core/core.module';

import {SharedModule} from './shared/shared.module';
import {environment} from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    FeaturesModule,
    AppRoutingModule
  ],
  providers: [
    // create a provider for using the environment constants in other places where environments file is not availabale
    // like in libraries
    // for using this provider inject decorator to include your environment file
    // example: constructor(@Inject('environment') environment) {this.environment = environment}
    {provide: 'environment', useValue: environment}
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
