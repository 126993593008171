import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import * as lodash from 'lodash';
import {
    GetPlannedCapacityFiltersType,
    GetPlannedCapacityForRoomsFiltersType,
    JobFiltersType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ServiceDependentFiltersType
} from 'sked-base';
import {
    CapacityPlannerFilterType,
    CapacityPlannerFilterTypeForExport,
    CapacityPlannerTableFiltersType,
    DateInterval,
    PlannedCapacityFilterType,
    PlannedCapacityItemsType,
} from './capacity-planner.types';
import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {extendMoment} from 'moment-range';
import {BehaviorSubject} from 'rxjs';
import {ExportTypeEnum} from '../room-reservation/room-reservation.types';

const momentRange = extendMoment(moment);

@Injectable({
    providedIn: 'root'
})
export class CapacityPlannerUtils {
    resourceVal = 'resource';
    roomVal = 'room';
    showResourceOrRoom: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(public dateTimeUtils: DateTimeUtils) {

    }

    WEEKLY = 'weekly';
    MONTHLY = 'monthly';
    ASCENDING = 'ascending';
    DESCENDING = 'descending';
    DATE_FORMAT = 'YYYY-MM-DD';
    DEFAULT = 'all';

    getCssClassForDailyCapacity(capacity: number, plannedCapacitiesFiltersValues: PlannedCapacityFilterType[]): string {
        if (capacity === null) {
            return 'label-empty';
        }
        const badInterval = lodash.find(plannedCapacitiesFiltersValues, {name: 'bad'});
        if (capacity > 0 && capacity <= badInterval.maxValue) {
            return 'label-bad';
        }
        const warningInterval = lodash.find(plannedCapacitiesFiltersValues, {name: 'warning'});
        if (capacity >= warningInterval.minValue && capacity <= warningInterval.maxValue) {
            return 'label-warning';
        }
        const okInterval = lodash.find(plannedCapacitiesFiltersValues, {name: 'ok'});
        if (capacity >= okInterval.minValue || capacity === 0) {
            return 'label-ok';
        }
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        includeChannel: false,
        resourceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        patientId: null,
        regionId: null,
        onlyAssignedToLocationsOfUser: false,
        exclusionList: [],
        count: true
    })

    getCapacityPlannerQueryFilter(requestTableFilter: CapacityPlannerFilterType, viewMode: string): GetPlannedCapacityFiltersType {
        const filter: GetPlannedCapacityFiltersType = {} as GetPlannedCapacityFiltersType;
        let dateInterval;
        filter.resourceId = requestTableFilter?.resource?.id;
        filter.centerId = requestTableFilter?.location?.id;
        dateInterval = this.getDateInterval(requestTableFilter.date, viewMode);
        filter.dateFrom = dateInterval.dateFrom;
        filter.dateTo = dateInterval.dateTo;
        filter.areaId = null;

        return filter;
    }


    getCapacityPlannerForRoomQueryFilter(requestTableFilter: CapacityPlannerFilterType, viewMode: string): GetPlannedCapacityForRoomsFiltersType {
        const filter: GetPlannedCapacityForRoomsFiltersType = {} as GetPlannedCapacityForRoomsFiltersType;
        let dateInterval;
        filter.resourceId = requestTableFilter?.resource?.id;
        filter.centerId = requestTableFilter?.location?.id;
        dateInterval = this.getDateInterval(requestTableFilter.date, viewMode);
        filter.dateFrom = dateInterval.dateFrom;
        filter.dateTo = dateInterval.dateTo;
        filter.tags = lodash.map(requestTableFilter.tags, 'id');
        return filter;
    }

    getQueryFilterForExport(requestTableFilter: JobFiltersType, exportType: ExportTypeEnum): JobFiltersType {
        const filter: JobFiltersType = {} as JobFiltersType;
        filter.centerId = requestTableFilter.centerId;
        filter.dateFrom = this.dateTimeUtils.getISODateFormat(requestTableFilter.dateFrom);
        filter.dateTo = this.dateTimeUtils.getISODateFormat(requestTableFilter.dateTo);
        if (exportType !== ExportTypeEnum.AvailabilitiesWithoutReservation) {
            filter.resourceId = requestTableFilter.resourceId;
            filter.tags = lodash.map(requestTableFilter.tags, 'id');
        }

        return filter;
    }

    getDateInterval(date: NgbDateStruct, viewMode: string): DateInterval {
        const dateInterval: DateInterval = {} as DateInterval;

        const updatedDate = moment({year: date.year, month: date.month - 1, day: date.day});
        if (viewMode === this.WEEKLY) {
            dateInterval.dateFrom = moment(updatedDate).startOf('isoWeek').format();
            dateInterval.dateTo = moment(updatedDate).endOf('isoWeek').format();
        } else if (viewMode === this.MONTHLY) {
            dateInterval.dateFrom = moment(updatedDate).startOf('month').format();
            dateInterval.dateTo = moment(updatedDate).endOf('month').format();
        }
        return dateInterval;
    }

    getFirstAndLastElementArray(dateInput: NgbDateStruct, viewMode: string): string {
        let year;
        if (viewMode === this.WEEKLY) {
            const firstDay = moment({
                year: dateInput.year,
                month: dateInput.month - 1,
                day: dateInput.day
            }).startOf('isoWeek');
            const lastDay = moment({
                year: dateInput.year,
                month: dateInput.month - 1,
                day: dateInput.day
            }).endOf('isoWeek');
            year = moment(lastDay).format('YYYY');
            const monthNameFrom = moment(firstDay).format('MMMM');
            const monthNameTO = moment(lastDay).format('MMMM');

            return moment(firstDay).format('DD') + ' ' + monthNameFrom + ' - ' + moment(lastDay)
                .format('DD') + ' ' + monthNameTO + ' ' + year;
        } else if (viewMode === this.MONTHLY) {
            const monthName = moment({
                year: dateInput.year,
                month: dateInput.month - 1,
                day: dateInput.day
            }).format('MMMM');
            year = moment({year: dateInput.year}).format('YYYY');
            return monthName + ' ' + year;
        }
    }

    // create a list with date, dayName, day
    getSelectedWeek(tableFilters: CapacityPlannerTableFiltersType, dateInput: NgbDateStruct): any[] {
        const rangeDays = [];
        const firstDayOfWeek = moment({
            year: dateInput.year,
            month: dateInput.month - 1,
            day: dateInput.day
        }).startOf('isoWeek');
        const lastDayOfWeek = moment({
            year: dateInput.year,
            month: dateInput.month - 1,
            day: dateInput.day
        }).endOf('isoWeek');
        const rangeDates = momentRange.range(firstDayOfWeek, lastDayOfWeek);
        const days = Array.from(rangeDates.by('day'));
        for (const date of days) {
            const weekDayName = date.format('dddd');
            const day = date.format('D');
            rangeDays.push({date: date.format(this.DATE_FORMAT), dayName: weekDayName, onlyDay: day});
        }

        return rangeDays;
    }

    getSelectMonth(tableFilters: CapacityPlannerTableFiltersType, dateInput: NgbDateStruct): any[] {
        const rangeDays = [];
        const firstDayOfMonth = moment({
            year: dateInput.year,
            month: dateInput.month - 1,
            day: dateInput.day
        }).startOf('month');
        const lastDayOfMonth = moment({
            year: dateInput.year,
            month: dateInput.month - 1,
            day: dateInput.day
        }).endOf('month');
        const rangeDates = momentRange.range(firstDayOfMonth, lastDayOfMonth);
        const days = Array.from(rangeDates.by('day'));
        for (const date of days) {
            const monthDayName = date.format('dddd');
            const day = date.format('D');
            rangeDays.push({date: date.format(this.DATE_FORMAT), dayName: monthDayName, onlyDay: day});
        }

        return rangeDays;
    }

    getInitialRequestTableFilters(): CapacityPlannerFilterType {
        const filters: CapacityPlannerFilterType = {} as CapacityPlannerFilterType;
        const today = moment();
        filters.location = undefined;
        filters.resource = undefined;
        filters.areaId = null;
        filters.date = {
            year: moment(today).get('year'),
            month: moment(today).get('month') + 1,
            day: moment(today).get('date'),
        };
        // default value for resource type Capacity Planner - Resource
        filters.resourceType = {label: '', value: ''};
        filters.tags = [];
        return filters;
    }

    getInitialTableFiltersForExport(): CapacityPlannerFilterTypeForExport {
        const filters: CapacityPlannerFilterTypeForExport = {} as CapacityPlannerFilterTypeForExport;
        const today = moment();
        filters.centerId = null;
        filters.resourceId = null;
        filters.dateTo = this.dateTimeUtils.convertDateInNgbDateStruct(today.toDate());
        filters.dateFrom = this.dateTimeUtils.convertDateInNgbDateStruct(today.toDate());
        filters.tags = [];
        return filters;
    }

    filterByCapacity(list: PlannedCapacityItemsType[], plannedCapacityFilter: any): PlannedCapacityItemsType[] {
        return lodash.filter(list, (item) => {
            if (plannedCapacityFilter.minValue !== undefined && plannedCapacityFilter.maxValue !== undefined) {
                if (item.overallPlannedCapacity >= plannedCapacityFilter.minValue && item.overallPlannedCapacity <= plannedCapacityFilter.maxValue) {
                    return item;
                }
            } else if (plannedCapacityFilter.minValue === undefined && plannedCapacityFilter.maxValue !== undefined) {
                if (item.overallPlannedCapacity <= plannedCapacityFilter.maxValue) {
                    return item;
                }
            } else if (plannedCapacityFilter.minValue !== undefined && plannedCapacityFilter.maxValue === undefined) {
                if (item.overallPlannedCapacity >= plannedCapacityFilter.minValue) {
                    return item;
                }
            }
        });
    }

    displayCapacitiesTable(list: PlannedCapacityItemsType[], tableFilters: CapacityPlannerTableFiltersType): PlannedCapacityItemsType[] {
        let updatedList: PlannedCapacityItemsType[] = [] as PlannedCapacityItemsType[];
        if (tableFilters.plannedCapacity.name !== this.DEFAULT) {
            updatedList = this.filterByCapacity(list, tableFilters.plannedCapacity);
        } else {
            updatedList = list;
        }

        if (tableFilters.sort === this.ASCENDING) {
            updatedList = lodash.orderBy(updatedList, 'overallPlannedCapacity', 'asc');
        } else if (tableFilters.sort === this.DESCENDING) {
            updatedList = lodash.orderBy(updatedList, 'overallPlannedCapacity', 'desc');
        }

        if (tableFilters.resourceName !== '') {
            tableFilters.resourceName = tableFilters.resourceName.toLocaleLowerCase();
            updatedList = updatedList.filter(user =>
                user.resourceName.toLocaleLowerCase().indexOf(tableFilters.resourceName) !== -1);
        }

        return updatedList;
    }

    getNextWeekOrMonth(date: NgbDateStruct, viewMode: string): NgbDateStruct {
        let nextDaysWeekOrMonth;
        if (viewMode === this.WEEKLY) {
            // add 7 days to current date
            nextDaysWeekOrMonth = moment({year: date.year, month: date.month - 1, day: date.day})
                .add(7, 'days');
        } else if (viewMode === this.MONTHLY) {
            // add a month to current date
            nextDaysWeekOrMonth = moment({year: date.year, month: date.month - 1, day: date.day})
                .add(1, 'months');
        }

        return nextDaysWeekOrMonth;
    }

    getPrevWeekOrMonth(date: NgbDateStruct, viewMode: string): NgbDateStruct {
        let prevDaysWeekOrMonth;
        if (viewMode === this.WEEKLY) {
            // add 7 days to current date
            prevDaysWeekOrMonth = moment({year: date.year, month: date.month - 1, day: date.day})
                .subtract(7, 'days');
        } else if (viewMode === this.MONTHLY) {
            // add a month to current date
            prevDaysWeekOrMonth = moment({year: date.year, month: date.month - 1, day: date.day})
                .subtract(1, 'months');
        }

        return prevDaysWeekOrMonth;
    }

    updateRequestTableFiltersOnDeleteTag(itemId: string, initialValues): string | null {
        const foundItem = lodash.find(initialValues, {id: itemId});
        if (!foundItem) {
            itemId = null;
        }

        return itemId;
    }

    isValidDate(date: NgbDateStruct): boolean {
        return date !== undefined && date != null && date.year !== undefined && date.month !== undefined &&
            date.day !== undefined;
    }

    getInitialPlannedCapacityFiltersValues(): PlannedCapacityFilterType[] {
        return [{name: 'all', minValue: 0},
            {name: 'bad', maxValue: 69},
            {name: 'warning', minValue: 70, maxValue: 89},
            {name: 'ok', minValue: 90}];
    }

    getViewTypesForCapacityPlanner() {
        return [{
            label: 'label.capacityPlannerResources', value: this.resourceVal
        }, {
            label: 'label.capacityPlannerRooms', value: this.roomVal
        }];
    }
}
