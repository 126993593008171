import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ActionType, ODataFilterQueryType, TableFiltersType} from '../../data-model/general.type';
import {
    CenterProvider,
    ConfirmDeleteModalService,
    DateRangeOptionsType,
    DateRangeResponseType,
    ExclusionOverviewItemType,
    ExclusionProvider,
    FilterWrapperNameEnum,
    GenericFilterOptionsType,
    GenericFilterResultType,
    ResourceProvider,
    SearchFilterUtils,
    TagTypeComponent
} from 'sked-base';
import {constants} from 'src/app/shared/constants/constants';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import moment from 'moment';
import {ExclusionStatusEnum, ExclusionTemplateLayoutType, ExclusionViewPageEnum} from './exclusion.types';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import {ExclusionUtils} from './exclusion-utils';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {AvailabilityTypeEnum} from '../availability/availability.types';
import {Subject} from 'rxjs';
import {
    DateRangeModalFilterOptionsType,
    ModalFiltersType
} from '../../shared/component/modal-filters/modal-filters.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {AvailabilityManagementContextService} from '../../shared/services/availability-management-context.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-exclusion',
    templateUrl: './exclusion.component.html',
    styleUrls: ['./exclusion.component.scss']
})
export class ExclusionComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
    tableFilters: TableFiltersType;
    itemsPerPageList: number[];
    exclusionList: ExclusionOverviewItemType[] = [];
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    tagsList: TagTypeComponent[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    resourceButtonName = 'label.resource';
    centerButtonName = 'label.center';
    EXCLUSION = 'Exclusion';
    constants = constants;
    dateRangeOptions: DateRangeOptionsType;
    dateRangeModalFilterOptions: DateRangeModalFilterOptionsType;
    IntervalTypeEnum = IntervalTypeEnum;
    exclusionStatusFilterInitialValue = 'undefined';
    exclusionStatusesList: ExclusionStatusEnum[];
    ExclusionStatusEnum = ExclusionStatusEnum;
    exclusionStatusEnum = ExclusionStatusEnum;
    screenTemplateLayout = {} as ExclusionTemplateLayoutType;
    ExclusionViewPageEnum = ExclusionViewPageEnum;
    exclusionReasons: string[];
    isExclusionCenterActivityActive: boolean;
    VIEW_ACTIVITY_ENABLED = false;
    displayExclusionFiltersToMove = true;

    filterWrapperOptions: GenericFilterOptionsType[];
    updateFiltersValue: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    modalFilters: ModalFiltersType;

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR = '#exclusion-filters-container sbase-filter-wrapper .filter-wrapper-component';

    private filterWrapperValues: GenericFilterResultType[] = [];
    private searchByChanged = new Subject<number>();

    constructor(
        public exclusionProvider: ExclusionProvider,
        public exclusionUtils: ExclusionUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public dateTimeUtils: DateTimeUtils,
        public searchFilterUtils: SearchFilterUtils,
        public centerProvider: CenterProvider,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public resourceProvider: ResourceProvider,
        public router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private availabilityManagementContextService: AvailabilityManagementContextService,
    ) {
    }

    ngOnInit(): void {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(this.EXCLUSION + lodash.capitalize(constants.READ));
        this.setInitialState();
        this.subscribeSearchByChanged();
        this.loadFilters();
        this.isExclusionCenterActivityActive = this.exclusionUtils.getExclusionCenterActivity();

        this.loadExclusionData(this.tableFilters);
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    ngOnDestroy(): void {
        // Save global state (intra availability management)
        this.availabilityManagementContextService.saveIntraAvailabilityManagementState(this.tableFilters);
        // Save local state (inside each availability management page)
        this.exclusionUtils.filtersState = {
            dateRangeOptions: lodash.cloneDeep(this.dateRangeOptions),
            modalFilters: lodash.cloneDeep(this.modalFilters),
            itemsPerPageList: lodash.cloneDeep(this.itemsPerPageList),
            tableFilters: lodash.cloneDeep(this.tableFilters),
            filterWrapperOptions: this.filterWrapperOptions?.map((filterWrapperOption: GenericFilterOptionsType) => ({
                ...lodash.omit(filterWrapperOption, ['providerInstance']),
                providerInstance: filterWrapperOption.providerInstance, // Don't deep clone to save time
                parentFilterValue: undefined, // Reset this as it will be checked at loading
            })),
        };
        this.forceRerenderExclusionFiltersToMove();
    }

    // This solves the expressionchangedafterithasbeencheckederror problem
    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    forceRerenderExclusionFiltersToMove(): void {
        this.displayExclusionFiltersToMove = false;
        this.changeDetectorRef.detectChanges();
        this.displayExclusionFiltersToMove = true;
    }

    onClearFilters() {
        // Hide modal filters button to reset the color. The modal filters options will be set again inside loadFilters
        this.modalFilters = undefined;
        // Clear filter wrapper values
        this.updateFiltersValue.next([]);
        // Load filters and data
        setTimeout(() => {
            this.onSearchByChanged(0);
            this.loadFilters(true);
            this.loadExclusionData(this.tableFilters);
        });
    }

    onFilterWrapperValueChanged(filtersValue: GenericFilterResultType[]): void {
        this.filterWrapperValues = filtersValue;
        this.tagsList = [];
        for (const filterOpt of this.filterWrapperOptions) {
            const foundFilter = lodash.find(this.filterWrapperValues, {name: filterOpt.name});
            if (foundFilter) {
                this.tagsList.push(foundFilter.value);
                lodash.uniq(this.tagsList, 'id');
                this.tableFilters.filter[foundFilter.name] = foundFilter.value;
            } else { // filter was deleted
                this.tableFilters.filter[filterOpt.name] = undefined;
            }
        }

        this.loadExclusionData(this.tableFilters);
    }

    onModalFiltersSelected(modalFilters) {
        this.onDateRangeChanged(modalFilters.dateRangeOptions);
        this.tableFilters.filter.requestedIntervalKind = modalFilters.dateRangeOptions.requestedIntervalKind;
        this.modalFilters = modalFilters;

        this.loadExclusionData(this.tableFilters);
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        if (dateRange.fromDate) {
            this.tableFilters.filter.validFrom = moment
                .parseZone(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRange.fromDate))
                .format('YYYY-MM-DD');
        } else {
            delete this.tableFilters.filter.validFrom;
        }
        if (dateRange.toDate) {
            this.tableFilters.filter.validTo = moment
                .parseZone(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRange.toDate))
                .format('YYYY-MM-DD');
        } else {
            delete this.tableFilters.filter.validTo;
        }
    }

    isDateRangeValidForIntervalKind(): boolean {
        return this.dateTimeUtils.isDateRangeValidForIntervalKind(this.tableFilters?.filter.requestedIntervalKind,
            this.tableFilters?.filter.validFrom,
            this.tableFilters?.filter.validTo);
    }

    // method to navigate create Exclusion
    createExclusion(): void {
        this.router.navigate(['/createExclusion'], {state: {filters: this.filterWrapperValues}});
    }

    editExclusion(exclusion: ExclusionOverviewItemType) {
        this.router.navigate(['/createExclusion'], {state: {exclusion, action: ActionType.Edit}});
    }

    viewExclusion(exclusion: ExclusionOverviewItemType) {
        const statusNewEditedOrMarkedForDelete = exclusion.status === ExclusionStatusEnum.NEW
            || exclusion.status === ExclusionStatusEnum.EDITED || exclusion.status === ExclusionStatusEnum.MARKED_FOR_DELETE;
        const routerUrlIsApproveExclusionOverview = this.router.url === '/' + ExclusionViewPageEnum.ApproveExclusionOverview;
        const hasApproveExclusionActivity = this.configDataService.isActivityActive('ExclusionApprove');

        if (routerUrlIsApproveExclusionOverview || (hasApproveExclusionActivity && statusNewEditedOrMarkedForDelete)) {
            this.router.navigate(['/viewApproveExclusion'], {
                state: {
                    id: exclusion?.id,
                    exclusion,
                    type: AvailabilityTypeEnum.Exclusion,
                    action: constants.VIEW,
                    view: this.screenTemplateLayout.viewType,
                    ...(!routerUrlIsApproveExclusionOverview ? {comingFromRoute: this.router.url} : {})
                }
            });
        } else if (this.router.url === '/' + ExclusionViewPageEnum.ExclusionOverview) {
            this.router.navigate(['/createExclusion'], {
                state: {
                    exclusion,
                    action: ActionType.View,
                    parentPageLink: this.router.url
                }
            });
        }
    }

    copyExclusion(exclusion: ExclusionOverviewItemType) {
        this.router.navigate(['/createExclusion'], {state: {exclusion, action: ActionType.Copy}});
    }

    deleteExclusion(exclusionId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.exclusionProvider, exclusionId).result.then(() => {
        }, (response) => {
            if (response) {
                this.ngxLoader.start();
                this.exclusionProvider.deleteEntry(exclusionId)
                    .pipe(take(1))
                    .subscribe(responseDelete => {
                        this.messagesService.success('toastr.success.exclusionDeleted');
                        this.loadExclusionData(this.tableFilters);
                    }, err => {
                        this.ngxLoader.stop();
                        this.messagesService.handlingErrorMessage(err);
                    }, () => {
                        this.ngxLoader.stop();
                    });
            }
        });
    }

    historyExclusion(exclusion: ExclusionOverviewItemType) {
        this.router.navigate(['/exclusionHistory'], {state: {exclusion}});
    }

    approveOrDeclineExclusion(exclusion: ExclusionOverviewItemType, action: string) {
        this.router.navigate(['/viewApproveExclusion'], {
            state: {
                id: exclusion?.id,
                exclusion,
                type: AvailabilityTypeEnum.Exclusion,
                action,
                view: this.screenTemplateLayout.viewType
            }
        });
    }

    blockingSimulationExclusion(exclusionId: string) {
        this.router.navigate(['/exclusionBlockedAppointmentsPreview'], {
            state: {
                id: exclusionId,
                type: AvailabilityTypeEnum.Exclusion,
                view: this.screenTemplateLayout.viewType
            }
        });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tableFilters.currentPage = page;
        this.loadExclusionData(this.tableFilters);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadExclusionData(this.tableFilters);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.tableFilters.orderBy[property];
        if (this.tableFilters.orderBy && property === 'resource') {
            this.tableFilters.orderBy[property + '/Name'] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        } else {
            this.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadExclusionData(this.tableFilters);
    }

    onSearchByChanged(value: number) {
        this.searchByChanged.next(value);
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#exclusion-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    private subscribeSearchByChanged() {
        this.searchByChanged
            .pipe(
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                if (!!searchValue && !!Number(searchValue) && Number.isInteger(searchValue) && Number(searchValue) > 0) {
                    this.tableFilters.filter.shortId = searchValue;
                } else {
                    delete this.tableFilters.filter.shortId;
                }
                this.loadExclusionData(this.tableFilters);
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.tableFilters.orderBy[selectedItem];
        this.loadExclusionData(this.tableFilters);
    }

    onDeleteTag(tagsList) {
        const remainingFilters: GenericFilterResultType[] = [];
        for (const filterValue of this.filterWrapperValues) {
            const foundFilter = lodash.find(tagsList, {id: filterValue.value.id});
            if (foundFilter) { // filter was not deleted from tag
                remainingFilters.push({name: filterValue.name, value: foundFilter});
            } else { // filter was not deleted from tag => delete it also from table filters
                this.tableFilters.filter[filterValue.name] = undefined;
            }
        }
        this.filterWrapperValues = remainingFilters;
        this.updateFiltersValue.emit(remainingFilters);
        this.loadExclusionData(this.tableFilters);
    }

    onExclusionStatusFilterChange(selectedStatuses: { value: string }) {
        if (!lodash.includes(selectedStatuses.value, undefined)) {
            this.tableFilters.filter.statuses = [selectedStatuses.value];
        } else {
            this.tableFilters.filter.statuses = this.exclusionUtils.getStatusesFilterByViewType(this.screenTemplateLayout.viewType);
        }
        this.loadExclusionData(this.tableFilters);
    }

    // for each object, check if the object has ExclusionCenter activity active
    // If yes, show delete action in exclusion overview page; approve and decline actions in Approve Exclusions page, else hide them
    hasExclusionCenterActivityActive(exclusion) {
        return this.exclusionUtils.validateExclusionCenterActivity(exclusion, this.isExclusionCenterActivityActive);
    }

    // function to get Exclusion data
    private loadExclusionData(tableFilters: TableFiltersType, includeCount: boolean = true) {
        if (this.isDateRangeValidForIntervalKind()) {
            const queryFilter = this.exclusionUtils.getQueryFilterForExclusionOverview(tableFilters, includeCount);
            const requestBody = this.exclusionUtils.getRequestBodyForExclusionOverview(tableFilters.filter);
            this.ngxLoader.start();
            this.exclusionProvider.getExclusionOverview(queryFilter, requestBody)
                .pipe(take(1))
                .subscribe((response: any) => {
                    // If no results on this page but page is not 1, redo the request on previous page
                    if (response.items?.length === 0 && tableFilters?.currentPage > 1) {
                        tableFilters.currentPage -= 1;
                        this.loadExclusionData(tableFilters, includeCount);
                        return;
                    }
                    this.exclusionList = response.items;
                    if (response.count !== undefined && response.count !== null) {
                      this.totalTableItems = response.count;
                    }
                    this.ngxLoader.stop();
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                });
        }
    }

    private getFilterWrapperOptions = (initialValues: {
        [key in FilterWrapperNameEnum]?: GenericFilterResultType
    } = {}): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.searchFilterUtils.getResourceDependentFilters(),
                disableFilter: false,
                label: this.resourceButtonName,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues[FilterWrapperNameEnum.resource] ?? undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            },
            {
                dependentFilters: this.searchFilterUtils.getLocationDependentFilters(),
                disableFilter: false,
                label: this.centerButtonName,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues[FilterWrapperNameEnum.location] ?? undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            }
        ]
    )

    private setInitialState() {
        if (this.router.url === '/' + ExclusionViewPageEnum.ExclusionOverview) {
            this.setInitialStateByType(ExclusionViewPageEnum.ExclusionOverview);
            this.exclusionStatusesList = [
                ExclusionStatusEnum.APPROVED,
                ExclusionStatusEnum.DELETED,
                ExclusionStatusEnum.NEW,
                ExclusionStatusEnum.MARKED_FOR_DELETE,
                ExclusionStatusEnum.EDITED
            ];
        } else if (this.router.url === '/' + ExclusionViewPageEnum.ApproveExclusionOverview) {
            this.setInitialStateByType(ExclusionViewPageEnum.ApproveExclusionOverview);
            this.exclusionStatusesList = [
                ExclusionStatusEnum.NEW,
                ExclusionStatusEnum.MARKED_FOR_DELETE,
                ExclusionStatusEnum.EDITED
            ];
        }
    }

    private setInitialStateByType(exclusionPageName: ExclusionViewPageEnum) {
        this.screenTemplateLayout.viewType = exclusionPageName;
        this.tableFilters = this.generalUtils.getInitialTableFilter();
        this.tableFilters.filter = this.exclusionUtils.getFilterByViewType(exclusionPageName);
        this.screenTemplateLayout.pageTitle = 'label.' + exclusionPageName + 's';
    }

    private getInitialDateRangeModalFilterOptions(dateRangeOptions: DateRangeOptionsType, pageFilters: ODataFilterQueryType):
        DateRangeModalFilterOptionsType {
        const dateRangeModalFilterOptions = dateRangeOptions as DateRangeModalFilterOptionsType;
        dateRangeModalFilterOptions.requestedIntervalKind = pageFilters.requestedIntervalKind;
        return dateRangeModalFilterOptions;
    }

    private getInitialModalFilters(dateRangeOptionsValue: DateRangeModalFilterOptionsType): ModalFiltersType {
        return {
            dateRangeOptions: dateRangeOptionsValue,
            title: 'label.moreFilters'
        };
    }

    private shouldKeepFilterLocalState(): boolean {
        const previousUrlsThatShouldKeepFilters = {
            '/exclusion': [
                '/createExclusion',
                '/exclusionHistory',
                '/exclusionBlockedAppointmentsPreview',
                '/viewApproveExclusion'
            ],
            '/approveExclusion': [
                '/viewApproveExclusion',
                '/exclusionBlockedAppointmentsPreview',
            ],
        };
        const previousUrlIndicatesWeShouldKeepFilters = lodash.indexOf(
            previousUrlsThatShouldKeepFilters[this.previousRouteService.getCurrentUrl()],
            this.previousRouteService.getPreviousUrl()
        ) !== -1;
        return previousUrlIndicatesWeShouldKeepFilters && !!this.exclusionUtils.filtersState;
    }

    private shouldKeepFilterGlobalState(): boolean {
        const previousUrlsThatShouldKeepFilters = [
            '/availability', '/exclusion', '/approveAvailability', '/approveExclusion'
        ];
        return lodash.indexOf(previousUrlsThatShouldKeepFilters, this.previousRouteService.getPreviousUrl()) !== -1;
    }

    private loadFilters(resetAll: boolean = false): void {
        if (!resetAll && this.shouldKeepFilterLocalState()) {
            this.dateRangeOptions = lodash.cloneDeep(this.exclusionUtils.filtersState.dateRangeOptions);
            this.modalFilters = lodash.cloneDeep(this.exclusionUtils.filtersState.modalFilters);
            this.itemsPerPageList = lodash.cloneDeep(this.exclusionUtils.filtersState.itemsPerPageList);
            this.tableFilters = lodash.cloneDeep(this.exclusionUtils.filtersState.tableFilters);
            this.filterWrapperOptions = lodash.cloneDeep(this.exclusionUtils.filtersState.filterWrapperOptions)?.map((filterOptions) => ({
                ...filterOptions,
                ...(!!this.tableFilters?.filter && !!this.tableFilters.filter[filterOptions.name] ? {
                    parentFilterValue: {
                        name: filterOptions.name,
                        value: this.tableFilters.filter[filterOptions.name],
                    }
                } : {}),
            }));
            this.exclusionStatusFilterInitialValue = this.tableFilters?.filter?.statuses?.length === 1
                ? this.tableFilters?.filter?.statuses[0] : 'undefined';
            this.filterWrapperValues = this.availabilityManagementContextService.getUpdatedFilterWrapperValues(
                [FilterWrapperNameEnum.resource, FilterWrapperNameEnum.location],
                this.tableFilters
            );
        } else {
            // Clear local state (inside each availability management page)
            this.exclusionUtils.filtersState = {};

            // Set initial filters
            if (resetAll) {
                this.setInitialState();
                this.exclusionStatusFilterInitialValue = 'undefined';
            }
            this.dateRangeOptions = this.exclusionUtils.getInitialDateRangeOptions();
            // Set initial min and max dates to null, to signify that whenever the modal is reopened, the minDate should reset back to null
            this.dateRangeOptions.initialMinDate = null;
            this.dateRangeOptions.initialMaxDate = null;
            const dateRangeModalFilterOptions = this.getInitialDateRangeModalFilterOptions(this.dateRangeOptions, this.tableFilters.filter);
            this.modalFilters = this.getInitialModalFilters(dateRangeModalFilterOptions);
            this.itemsPerPageList = [5, 10, 25, 50];

            // Check for global (intra availability management) state
            let filterWrapperInitialValues = {};
            if (!resetAll && this.shouldKeepFilterGlobalState()) {
                filterWrapperInitialValues = this.availabilityManagementContextService.filtersState?.filterWrapperInitialValues ?? {};
                if (!!this.tableFilters.filter) {
                    this.tableFilters.filter[FilterWrapperNameEnum.resource] = filterWrapperInitialValues[FilterWrapperNameEnum.resource]?.value;
                    this.tableFilters.filter[FilterWrapperNameEnum.location] = filterWrapperInitialValues[FilterWrapperNameEnum.location]?.value;
                }
            }
            this.filterWrapperOptions = this.getFilterWrapperOptions(filterWrapperInitialValues ?? {});
            this.filterWrapperValues = this.availabilityManagementContextService.getUpdatedFilterWrapperValues(
                [FilterWrapperNameEnum.resource, FilterWrapperNameEnum.location],
                this.tableFilters
            );
        }
    }
}
