<div class="container-fluid create-event-action-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-event-action-header">
        <h3 class="create-event-action-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase"
                                                                                                 translate="label.eventAction"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveEventActionData(eventActionItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{eventActionItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.eventGroup"></td>
                                    <td class="text-right">{{eventActionItem.eventGroup}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.active"></td>
                                    <td class="text-right">
                                        <span *ngIf="eventActionItem.active" translate="label.yes"></span>
                                        <span *ngIf="!eventActionItem.active" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.reTriggerable"></td>
                                    <td class="text-right">
                                        <span *ngIf="eventActionItem.reTriggerable" translate="label.yes"></span>
                                        <span *ngIf="!eventActionItem.reTriggerable" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.type"></td>
                                    <td class="text-right">{{eventActionItem.type}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.templateId"></td>
                                    <td class="text-right">{{eventActionItem.templateId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.subject"></td>
                                    <td class="text-right">{{eventActionItem.subject}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.sendTo"></td>
                                    <td class="text-right">{{eventActionItem.sendTo}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.sendgridKey"></td>
                                    <td class="text-right">{{eventActionItem.sendgridKey}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.createCommunicationLog"></td>
                                    <td class="text-right">
                                        <span *ngIf="eventActionItem.createCommunicationLog" translate="label.yes"></span>
                                        <span *ngIf="!eventActionItem.createCommunicationLog" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col right-divider">
                        <div class="row">
                            <div class="col-11">
                                <div class="form-group">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="eventActionItem.name"
                                           [ngClass]="{'input-element-has-error': !eventActionItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!eventActionItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-11">
                                <div class="form-group">
                                    <label translate="label.eventGroup"></label>
                                    <ng-select
                                        class="custom-ng-select input-element"
                                        loadingText="{{'label.loading' | translate}}"
                                        dropdownPosition="bottom"
                                        [(ngModel)]="eventActionItem.eventGroup"
                                        [multiple]="false"
                                        [searchable]="false"
                                        [clearable]="false"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngClass]="{'input-element-has-error': generalUtils.isSelectedNoValueOption(eventActionItem.eventGroup)}"
                                        (change)="onEventGroupChange($event)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let group of EventGroupEnumList"
                                                   [value]="group">
                                            {{'enum.eventGroup.' + group | translate}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="generalUtils.isSelectedNoValueOption(eventActionItem.eventGroup)"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-11">
                                <div class="form-group">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="eventActionItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-11">
                                <div class="form-group">
                                    <label translate="label.reTriggerable"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="eventActionItem.reTriggerable"
                                                   name="reTrigerable">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="row">
                            <div class="col-11">
                                <div class="form-group">
                                    <label translate="label.type"></label>
                                    <ng-select
                                        class="custom-ng-select input-element"
                                        loadingText="{{'label.loading' | translate}}"
                                        dropdownPosition="bottom"
                                        [(ngModel)]="eventActionItem.type"
                                        [multiple]="false"
                                        [searchable]="false"
                                        [clearable]="false"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngClass]="{'input-element-has-error': generalUtils.isSelectedNoValueOption(eventActionItem.type)}"
                                        [disabled]="screenTemplateLayout.action === constants.EDIT"
                                        (change)="updateEventActionTypeDetailsValidationForAllFieldsAtTypeChange(eventActionItem.type)">
                                        <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let item of activeEventActionTypeMetadataList"
                                                   [value]="item.actionType">
                                            {{'enum.eventActionType.' + item.actionType | translate}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="generalUtils.isSelectedNoValueOption(eventActionItem.type)"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--ACTION TYPE = SENDGRID-->
                        <div *ngIf="eventActionItem.type===EventActionTypeEnum.sendGrid">
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.templateId"></label>
                                        <input type="text" name="templateId" class="input-element"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['TemplateId']?.isValid}"
                                               [(ngModel)]="eventActionItem.templateId"
                                               (ngModelChange)="updateEventActionTypeDetailsValidation('TemplateId', eventActionItem.templateId)"
                                               placeholder="{{'label.templateId'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="isMandatoryFieldEmpty('TemplateId', eventActionItem.templateId)"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('TemplateId', eventActionItem.templateId)"
                                             class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.subject"></label>
                                        <input type="text" name="subject" class="input-element"
                                               [(ngModel)]="eventActionItem.subject"
                                               placeholder="{{'label.subject'|translate}}"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['Subject']?.isValid}"
                                               autocomplete="off">
                                        <div *ngIf="isMandatoryFieldEmpty('Subject', eventActionItem.subject)"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('Subject', eventActionItem.subject)" class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.sendTo"></label>&nbsp;
                                        <i class="fas fa-info-circle cursor-pointer"
                                           ngbTooltip="{{'label.validMailOrToken'| translate}}"></i>
                                        <input type="text" name="sendTo" class="input-element"
                                               [(ngModel)]="eventActionItem.sendTo"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['SendTo']?.isValid}"
                                               (ngModelChange)="updateEventActionTypeDetailsValidation('SendTo', eventActionItem.sendTo)"
                                               placeholder="{{'label.sendTo'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="isMandatoryFieldEmpty('SendTo', eventActionItem.sendTo)"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('SendTo', eventActionItem.sendTo)" class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <!-- SendgridKey -->
                                    <div class="form-group customSelect sbase-input-element">
                                        <label translate="label.sendgridKey"></label>
                                        <sbase-filter (selectedItemsOutput)="onSelectedDataStore($event, true)"
                                                      [buttonName]="'label.sendgridKey'"
                                                      [dependentFilters]="dataStoreDependentFilters"
                                                      [initialFilterValues]="[sendgridKeyObject]"
                                                      [providerInstance]="dataStoreProvider"
                                                      [isMultipleSelectedItem]="false"
                                                      [useSelectedValueAsLabel]="true"
                                                      [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['SendgridKey']?.isValid}"
                                                      [hideSelectedItems]="true">
                                        </sbase-filter>
                                        <div *ngIf="isMandatoryFieldEmpty('SendgridKey', this.eventActionItem.sendgridKey)"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('SendgridKey', this.eventActionItem.sendgridKey)"
                                             class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isCommunicationLogFeatureActive">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.createCommunicationLog"></label>
                                        <div class="switch">
                                            <label>
                                                <span translate="label.no"></span>
                                                <input type="checkbox" checked
                                                       [(ngModel)]="eventActionItem.createCommunicationLog"
                                                       name="createCommunicationLog">
                                                <span class="lever switch-col-black"></span>
                                                <span translate="label.yes"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--ACTION TYPE = WEBHOOK-->
                        <div *ngIf="eventActionItem.type===EventActionTypeEnum.webHook">
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.methodType"></label>&nbsp;
                                        <i class="fas fa-info-circle cursor-pointer"
                                           ngbTooltip="{{'label.methodTypeAcceptedValues'| translate}}"></i>
                                        <input type="text" name="methodType" class="input-element"
                                               [(ngModel)]="eventActionItem.methodType"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['MethodType']?.isValid}"
                                               (ngModelChange)="updateEventActionTypeDetailsValidation('MethodType', eventActionItem.methodType)"
                                               placeholder="{{'label.methodType'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="isMandatoryFieldEmpty('MethodType', eventActionItem.methodType)"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('MethodType', eventActionItem.methodType)"
                                             class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.uri"></label>
                                        <input type="text" name="uri" class="input-element"
                                               [(ngModel)]="eventActionItem.uri"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['Uri']?.isValid}"
                                               (ngModelChange)="updateEventActionTypeDetailsValidation('Uri', eventActionItem.uri)"
                                               placeholder="{{'label.uri'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="isMandatoryFieldEmpty('Uri', eventActionItem.uri)" class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                        <div *ngIf="!isFieldValid('Uri', eventActionItem.uri)" class="help-block">
                                            <span translate="label.invalidValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.noAuthorization"></label>
                                        <div class="switch">
                                            <label>
                                                <span translate="label.no"></span>
                                                <input type="checkbox" checked
                                                       [(ngModel)]="eventActionItem.noAuthorization"
                                                       (ngModelChange)="updateEventActionTypeDetailsValidationForNoAuthorization(eventActionItem)"
                                                       name="noAuthorization">
                                                <span class="lever switch-col-black"></span>
                                                <span translate="label.yes"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.authorizationType"></label>&nbsp;
                                        <i class="fas fa-info-circle cursor-pointer"
                                           ngbTooltip="{{'label.authorizationTypeAcceptedValues'| translate}}"></i>
                                        <input type="text" name="authorizationType" class="input-element"
                                               [(ngModel)]="eventActionItem.authorizationType"
                                               [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['AuthorizationType']?.isValid}"
                                               (ngModelChange)="updateEventActionTypeDetailsValidationForNoAuthorization(eventActionItem)"
                                               placeholder="{{'label.authorizationType'|translate}}"
                                               autocomplete="off">
                                        <div *ngIf="!eventActionTypeDetailsValidation['AuthorizationType']?.isValid"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <!-- authorizationValue -->
                                    <div class="form-group customSelect sbase-input-element">
                                        <label translate="label.authorizationValue"></label>
                                        <sbase-filter (selectedItemsOutput)="onSelectedDataStore($event, false)"
                                                      [buttonName]="'label.authorizationValue'"
                                                      [dependentFilters]="dataStoreDependentFilters"
                                                      [initialFilterValues]="[authorizationValueObject]"
                                                      [providerInstance]="dataStoreProvider"
                                                      [isMultipleSelectedItem]="false"
                                                      [useSelectedValueAsLabel]="true"
                                                      [ngClass]="{'input-element-has-error': !eventActionTypeDetailsValidation['AuthorizationValue']?.isValid}"
                                                      [hideSelectedItems]="true">
                                        </sbase-filter>
                                        <div *ngIf="!eventActionTypeDetailsValidation['AuthorizationValue']?.isValid"
                                             class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--ACTION TYPE = MSGraphCalendar-->
                        <div *ngIf="eventActionItem.type===EventActionTypeEnum.mSGraphCalendar">
                            <div class="row">
                                <div class="col-11">
                                    <div class="form-group">
                                        <label translate="label.mSGraphEventTypes"></label>
                                        <ng-select
                                            class="custom-ng-select input-element"
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            [(ngModel)]="eventActionItem.mSGraphEventTypes"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{'input-element-has-error': generalUtils.isSelectedNoValueOption(eventActionItem.mSGraphEventTypes)}"
                                            (ngModelChange)="updateEventActionTypeDetailsValidationForMSGraphEventTypes(eventActionItem)">
                                            <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                            <ng-option *ngFor="let item of mSGraphCalendarAllowedValues"
                                                       [value]="item">
                                                {{'enum.eventType.' + item | translate}}
                                            </ng-option>
                                        </ng-select>
                                        <div
                                            *ngIf="generalUtils.isSelectedNoValueOption(eventActionItem.mSGraphEventTypes) && !eventActionTypeDetailsValidation['MSGraphEventTypes']?.isValid"
                                            class="help-block">
                                            <span translate="label.error.required"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'EventActionUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
