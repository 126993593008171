<div class="container-fluid tag-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.tags"></h3>
        <div class="filters-section d-flex">
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info text-high-contrast">
                <span *ngFor="let key of objectKeys(tagMdUtils.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + tagMdUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
                    </span>
                </span>
            </span>
        </div>
        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input (ngModelChange)="onSearchTagChanged($event)" [(ngModel)]="tagMdUtils.tableFilters.filter.Name"
                       autocomplete="off" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" type="text">
            </div>
            <div class="ml-2">
                <button (click)="createTag()" [activityDisplay]="MDEntityName+constants.CREATE"
                        class="btn theme-alt-btn border-0"
                        type="button"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th (click)="onSortBy('shortId')" class="name-column cursor-pointer">
                    {{'label.shortId' | translate}}
                    <button *ngIf="!tagMdUtils.tableFilters.orderBy.shortId" class="btn p-0" type="button"><i
                            class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tagMdUtils.tableFilters.orderBy.shortId === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="tagMdUtils.tableFilters.orderBy.shortId === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th (click)="onSortBy('name')" class="name-column cursor-pointer" translate="label.name">
                    <button *ngIf="!tagMdUtils.tableFilters.orderBy.name" class="btn p-0" type="button"><i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="tagMdUtils.tableFilters.orderBy.name === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="tagMdUtils.tableFilters.orderBy.name === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="scopedEntity-column" translate="label.scopedForEntity"></th>
                <th class="enableForRules-column" translate="label.rules"></th>
                <th class="code-column" translate="label.calculated"></th>
                <th class="code-column" translate="label.code"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tag of tagMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewTag(tag)">
                <td class="id-column">{{tag.shortId}}</td>
                <td class="name-column max-width-table-column">{{tag.name}}</td>
                <td class="scopedEntity-column">
                    <span *ngFor="let label of displayScopedTagLabel(tag); let isLast = last">
                        {{(label | translate) + (isLast ? '' : ', ')}}
                    </span>
                </td>
                <td class="enableForRules-column">
                    {{tag.enabledForRules | yesNoPipe | translate}}
                </td>
                <td class="code-column">{{tag.calculated |yesNoPipe | translate}}</td>
                <td class="code-column">{{tag.code}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button [ngbPopover]="popContent" class="btn" placement="left" popoverClass="popover-shadow"
                            type="button">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button (click)="viewTag(tag)" class="btn" translate="label.view"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="editTag(tag)" [activityDisplay]="MDEntityName+constants.UPDATE"
                                        class="btn" translate="label.edit"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="displayConfirmDeleteItemModal(tag.id)"
                                        [activityDisplay]="MDEntityName+constants.DELETE" class="btn"
                                        translate="label.delete"
                                        type="button"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(tag.id, EntityTypeEnum.tag, tag.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="tagMdUtils.tableFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="tagMdUtils.tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                  <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                        [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                        aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                        data-toggle="dropdown">
                      <strong>{{tagMdUtils.tableFilters.itemsPerPage}}</strong>
                  </span>
            <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                 [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                   class="dropdown-item">{{page}}</a>
            </div>
            </span>
        </div>
    </div>
</div>
