import {EventEmitter, Injectable} from '@angular/core';
import {SwapResourcesFiltersOptionsType} from './resource-swap-filters/resource-swap-filters.types';
import {
    DateRangeOptionsType,
    GenericFilterOptionsType,
    ResourcePublicDataType,
    ResourceSwapResultType
} from 'sked-base';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {
    SwapResourcesAppointmentDetailsOptionsType,
    SwapResourcesAppointmentListItemOptionsType,
    SwapResourcesAppointmentListRequestFilters,
    SwapResourcesAppointmentListStateType
} from './resource-swap-appointment-list/resource-swap-appointment-list.types';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {SwapResourcesOptionsType} from './resource-swap-options/resource-swap-options.types';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResourceSwapUtils {
    // State management
    swapResourcesAppointmentListState: SwapResourcesAppointmentListStateType = this.getEmptyState();
    shouldMakeNewRequest = false;
    shouldKeepFiltersState = false;
    shouldKeepListState = false;

    // Options for filters
    filtersOptions: SwapResourcesFiltersOptionsType = this.getEmptyFiltersOptions();
    clearFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();

    // Options for swap resources appointment list item
    swapResourcesAppointmentListItemOptions: SwapResourcesAppointmentListItemOptionsType =
        this.getInitialSwapResourcesAppointmentListFiltersOptions();

    swapResourcesOptions: SwapResourcesOptionsType;
    appointmentsIds: string[] = [];

    lastResourceForAllReplacementsSelected: ResourcePublicDataType;

    toggleAllAppointmentsSubject: Subject<boolean> = new Subject<boolean>();

    disableEverythingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private dateTimeUtils: DateTimeUtils,
                private generalUtils: GeneralUtils) {
    }

    // State management

    updateFilterState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.filtersOptions.hasOwnProperty(property)) {
                return;
            }
            if (property === 'filterWrapperOptions') {
                // Don't cloneDeep providerInstance
                this.swapResourcesAppointmentListState.filtersOptions.filterWrapperOptions =
                    this.filtersOptions?.filterWrapperOptions?.map((options: GenericFilterOptionsType) => {
                        const {providerInstance, ...restOfFilterOptions} = options;
                        return {
                            providerInstance,
                            ...lodash.cloneDeep(restOfFilterOptions),
                        };
                    });
                return;
            }
            this.swapResourcesAppointmentListState.filtersOptions[property] = lodash.cloneDeep(this.filtersOptions[property]);
        });
    }

    updateItemsState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.swapResourcesAppointmentListItemOptions.hasOwnProperty(property)) {
                return;
            }
            this.swapResourcesAppointmentListState.swapResourcesAppointmentListItemOptions[property] =
                lodash.cloneDeep(this.swapResourcesAppointmentListItemOptions[property]);
        });
    }

    getEmptyState(): SwapResourcesAppointmentListStateType {
        return {
            filtersOptions: this.getEmptyFiltersOptions() as SwapResourcesFiltersOptionsType,
            swapResourcesAppointmentListItemOptions:
                this.getInitialSwapResourcesAppointmentListFiltersOptions() as SwapResourcesAppointmentListItemOptionsType,
            swapResourcesAppointmentItemDetailsOptions: this.getInitialItemDetailsOptions() as SwapResourcesAppointmentDetailsOptionsType,
        } as SwapResourcesAppointmentListStateType;
    }

    getInitialItemDetailsOptions(): SwapResourcesAppointmentDetailsOptionsType {
        return {
            appointment: {},
        } as SwapResourcesAppointmentDetailsOptionsType;
    }

    getInitialSwapResourcesOptions(): SwapResourcesOptionsType {
        return {
            allAvailableSwapResources: [],
            appointmentsSwapResources: [],
            selectsNgValues: {},
            sendClassicNotificationEmailsValue: false,
            resourceSwapResult: [] as ResourceSwapResultType[]
        };
    }

    getInitialSwapResourcesAppointmentListFiltersOptions(): SwapResourcesAppointmentListItemOptionsType {
        return {
            isBeforeSearchState: true,
            isNotFoundState: false,
            swapResourcesAppointmentList: [],
            itemsPerPageList: [5, 10, 25, 50],
            latestSwapResourcesAppointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as SwapResourcesAppointmentListRequestFilters,
            swapResourcesAppointmentListRequestFilters: {
                pageFilters: this.generalUtils.getInitialTableFilter(),
            } as SwapResourcesAppointmentListRequestFilters,
            totalSwapResourcesAppointmentItems: null,
            showItemsPerPageDropdown: false,
            containerHeight: 0
        };
    }

    getEmptyFiltersOptions(): SwapResourcesFiltersOptionsType {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            dateRangeOptions: {},
            swapResourcesFiltersValues: {},
            areFiltersValid: false,
            areFiltersCollapsed: false,
            latestSearchFilterValues: undefined,
            statusSelectNgModel: undefined,
        } as SwapResourcesFiltersOptionsType;
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const todayMoment = moment();
        const todayPlus7DaysMoment = moment().add(7, 'days');
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayPlus7Days = this.dateTimeUtils.getNgbDateFromMoment(todayPlus7DaysMoment);
        const fromDate = {year: today.year, month: today.month, day: today.day};
        const minDate = {year: today.year, month: today.month, day: today.day};
        const toDate = {year: todayPlus7Days.year, month: todayPlus7Days.month, day: todayPlus7Days.day};
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.from',
            toDateLabel: 'label.to',
            minDate,
            maximumRange: 60,
            disabled: false
        } as DateRangeOptionsType;
    }
}
