import {Injectable} from '@angular/core';
import * as fingerprintjs from '@fingerprintjs/botd';
import {BehaviorSubject} from 'rxjs';
import {LoggerService} from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class BotDetectionService {
    isBot = false;
    isBot$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private loggerService: LoggerService,
    ) {
    }

    init() {
        const botdPromise = fingerprintjs?.load();
        botdPromise
            ?.then((botd) => {
                return botd.detect();
            })
            ?.then((result) => {
                this.isBot = result?.bot === true;
                this.isBot$.next(this.isBot);
            })
            ?.catch((error) => {
                this.loggerService.log('Fingerprintjs error:');
                this.loggerService.log(error);
            });
    }
}
