<div class="mab-reserved-slots-container" *ngIf="!!multiAppointmentBookingMdUtils.reservedSlotsOptions">
    <div class="label-row d-flex no-block flex-row justify-content-between align-items-center">
        <h6 class="mb-0">
            <span translate="label.reservedSlots" class="font-weight-bold pr-1"></span>
            <span class="pr-1">-</span>
            <span>{{multiAppointmentBookingMdUtils.reservedSlotsOptions?.reservations
                ? multiAppointmentBookingMdUtils.reservedSlotsOptions?.reservations.length : 0}}</span>
            <span>/</span>
            <span class="pr-1">{{multiAppointmentBookingMdUtils.maximumMultiAppointmentBookings}}</span>
            <span translate="label.reserved" class="pr-1"></span>
        </h6>
    </div>
    <div class="mab-reserved-slots-before-select-container d-flex no-block justify-content-center align-items-center text-high-contrast"
         *ngIf="!multiAppointmentBookingMdUtils.slotsListsWrapperOptions?.isEmpty && !multiAppointmentBookingMdUtils.reservedSlotsOptions?.displayedReservations?.length">
        <i class="fa-light fa-circle-info mr-2"></i>
        <span>
            <span class="mr-1" translate="label.selectTimesToReserveASlot">
            </span>
            <span>
                {{'label.yourSlotsWillBeReservedForXMinutes' | translate : {minutes: multiAppointmentBookingMdUtils.mabReservationLifeSpanInMinutes ?? 5} }}
            </span>
        </span>
    </div>
    <div class="mab-reserved-slots-inner-container" *ngIf="multiAppointmentBookingMdUtils.reservedSlotsOptions?.displayedReservations?.length">
        <div class="mab-reserved-slot-list ml-3 custom-scrollbar">
            <div class="mab-reserved-slot-item"
                 [ngClass]="{'hovered': !!reservedSlot?.hovered}"
                 (mouseenter)="onMouseEnterReservation(reservedSlot)"
                 (mouseleave)="onMouseLeaveReservation(reservedSlot)"
                 (click)="onReservedSlotClick(reservedSlot)"
                 *ngFor="let reservedSlot of multiAppointmentBookingMdUtils.reservedSlotsOptions.displayedReservations; let index = index; let last = last">
                <!-- DATE AND TIME -->
                <div class="mab-reserved-slot-item-date-time-and-remove">
                    <span class="mab-reserved-slot-item-date-and-time">
                        {{reservedSlot.slot?.dateTime | dateMMMMDoFormat}},
                        {{reservedSlot.slot?.dateTime | hourFromDate}}
                    </span>
                    <span class="mab-reserved-slot-item-remove"
                          (click)="multiAppointmentBookingMdUtils.removeReservedSlot(reservedSlot.slot, reservedSlot.listId, $event)">&#10005;</span>
                </div>
                <!-- SPECIALITY + SERVICE NAME -->
                <div class="mab-reserved-slot-item-service mr-3">
                    <span *ngIf="reservedSlot.specialityName?.length > 0">{{reservedSlot.specialityName}},&nbsp;</span>
                    <span>{{reservedSlot.slot?.service?.name}}</span>
                </div>
                <!-- TIMELINE -->
                <div class="mab-reserved-slot-item-timeline">
                    <div class="mab-reserved-slot-item-line" *ngIf="!last"></div>
                    <div class="mab-reserved-slot-item-circle-with-number-container">
                        <span class="mab-reserved-slot-item-circle-with-number-item">{{index + 1}}</span>
                    </div>
                </div>
                <!-- RESOURCE -->
                <div class="mab-reserved-slot-item-resource mr-3" *ngIf="reservedSlot.slot?.resource">
                    <span class="pr-1">
                        {{reservedSlot.slot?.resource?.name}}
                    </span>
                    <span *ngIf="reservedSlot.slot?.resource?.additionalInformation">
                        <i class="fas fa-info-circle"
                           [ngbPopover]="reservedSlot.slot?.resource?.additionalInformation"
                           triggers="mouseenter:mouseleave"
                           popoverClass="popover-shadow"
                           placement="bottom"></i>
                    </span>
                </div>
                <!-- CENTER -->
                <div class="mab-reserved-slot-item-center font-weight-medium-contrast mr-3">
                    {{reservedSlot.slot?.center?.name}}
                </div>
            </div>
        </div>

        <div class="remove-all-reserved-slots mx-1">
            <button *ngIf="multiAppointmentBookingMdUtils.reservedSlotsOptions?.displayedReservations?.length > 0"
                    class="action-button reset-button btn" (click)="onRemoveAll()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1" translate='label.removeAll'></span>
            </button>
        </div>

        <button class="btn theme-btn mr-2 book-appointments-button" translate="label.bookAppointments" (click)="onBookAppointments()">
        </button>

    </div>
</div>
