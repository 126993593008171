import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {ResourceTypeProvider, TagProvider} from 'sked-base';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ExportTypeEnum, GenerateExportOptionsType} from '../room-reservation/room-reservation.types';

@Component({
    selector: 'app-room-reservations-export',
    templateUrl: './room-reservations-export.component.html',
    styleUrls: ['./room-reservations-export.component.scss']
})
export class RoomReservationsExportComponent implements OnInit {
    activeTab = 1;
    resourceTypeRoomId: string;
    optionsForAvailabilitiesWithoutReservation: GenerateExportOptionsType;
    optionsForRoomReservation: GenerateExportOptionsType;

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        public resourceTypeProvider: ResourceTypeProvider,
        public generalUtils: GeneralUtils,
        private tagProvider: TagProvider
    ) {
    }

    ngOnInit() {
        this.getResourceTypesRequest();
        this.loadOptionsForGenerateExport();
    }

    private loadOptionsForGenerateExport() {
        this.optionsForAvailabilitiesWithoutReservation = {
            exportType: ExportTypeEnum.AvailabilitiesWithoutReservation,
            resourceTypeId: this.resourceTypeRoomId,
            resourceInput: {hide: true},
            tagsInput: {hide: true}
        };
        this.optionsForRoomReservation = {
            exportType: ExportTypeEnum.RoomReservations,
            resourceTypeId: this.resourceTypeRoomId
        };
    }

    private getResourceTypesRequest() {
        this.ngxLoader.start();
        this.resourceTypeProvider.getEntries({})
            .pipe(take(1))
            .subscribe((resourceTypes) => {
                this.resourceTypeRoomId = this.generalUtils.getResourceTypeRoomId(resourceTypes.value);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
            });
    }
}
