import {Component, Input, OnInit} from '@angular/core';
import {DynamicFormInputService} from '../../services/dynamic-form-input/dynamic-form-input.service';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-dynamic-form-input-error',
    templateUrl: './dynamic-form-input-error.component.html',
    styleUrls: ['./dynamic-form-input-error.component.scss']
})
export class DynamicFormInputErrorComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() controlName: string;

    constructor(
        public dynamicFormInputService: DynamicFormInputService,
    ) {
    }

    ngOnInit(): void {
    }
}
