import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DateTimeUtils} from '../../utils/dateTime.utils';
import {DateRangeOptionsType, DateRangeResponseType} from 'sked-base';
import moment from 'moment';
import {ODataFilterQueryType} from '../../../data-model/general.type';
import {IntervalTypeEnum} from '../../../data-model/generalTypes';
import {ModalFiltersType} from './modal-filters.types';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-modal-filters',
    templateUrl: './modal-filters.component.html',
    styleUrls: ['./modal-filters.component.scss']
})
export class ModalFiltersComponent implements OnInit, OnDestroy {
    @Input() options: ModalFiltersType;
    @Input() title?: string;
    modalFilters: ModalFiltersType;
    IntervalTypeEnum = IntervalTypeEnum;

    constructor(public activeModal: NgbActiveModal,
                private dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        this.initFilters();
    }

    ngOnDestroy(): void {
    }

    isDateRangeValidForIntervalKind(modalFilters): boolean {
        const validFrom = modalFilters.dateRangeOptions.fromDate ?
            moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(modalFilters.dateRangeOptions.fromDate)).format() : undefined;
        const validTo = modalFilters.dateRangeOptions.toDate ?
            moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(modalFilters.dateRangeOptions.toDate)).format() : undefined;
        const valid = this.dateTimeUtils.isDateRangeValidForIntervalKind(modalFilters.dateRangeOptions.requestedIntervalKind,
            validFrom, validTo);
        return valid;
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        if (dateRange.isValid) {
            this.modalFilters.dateRangeOptions.fromDate = dateRange.fromDate;
            this.modalFilters.dateRangeOptions.toDate = dateRange.toDate;
        }
    }

    onRequestIntervalKindChange(selectedOption: string) {
        this.modalFilters.dateRangeOptions.requestedIntervalKind = selectedOption;
    }

    onCloseModal() {
        if (this.isDateRangeValidForIntervalKind(this.modalFilters)) {
            this.activeModal.close(this.modalFilters);
        }
    }

    private initFilters() {
        this.modalFilters = this.options;
    }

}
