import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    DataStoreDependentFiltersType,
    DataStoreProvider, DataStoreType, EventActionMSGraphCalendarType,
    EventActionProvider,
    EventActionTypeEnum,
    EventActionTypeMetadata, EventActionWebHookType,
    EventActionWithTypeDetailsType,
    EventGroupEnum
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {EventActionMdUtils} from '../event-action-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {EventActionDetailsMetadata, EventActionSendgridType} from 'sked-base/lib/data-model/eventActionTypes';
import {EventActionTypeDetailsValidationType} from '../event-action-md.types';
import {forkJoin} from 'rxjs';
import {ConfigDataService} from '../../../shared/services/config-data.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-event-action',
    templateUrl: './create-event-action.component.html',
    styleUrls: ['./create-event-action.component.scss']
})
export class CreateEventActionComponent implements OnInit, OnDestroy, AfterViewInit {
    constants = constants;
    EventActionTypeEnum = EventActionTypeEnum;
    initialEventAction: EventActionWithTypeDetailsType;
    eventActionItem: EventActionWithTypeDetailsType = {} as EventActionWithTypeDetailsType;
    activeEventActionTypeMetadataList: EventActionTypeMetadata[] = [];
    eventActionTypeDetailsValidation: EventActionTypeDetailsValidationType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    EventGroupEnumList = Object.values(EventGroupEnum);
    dataStoreDependentFilters: DataStoreDependentFiltersType = this.eventActionMdUtils.getEmptyDataStorageDependentFilters();
    sendgridKeyObject: DataStoreType = undefined;
    authorizationValueObject: DataStoreType = undefined;
    dataStoreObjectList: DataStoreType[] = [];
    isCommunicationLogFeatureActive: boolean;
    mSGraphCalendarAllowedValues: string[] = [];

    private eventActionDetailsMetadata: EventActionDetailsMetadata[] = [];
    private eventActionDetailsMetadataForSendgrid: EventActionDetailsMetadata[] = [];
    private eventActionDetailsMetadataForWebHook: EventActionDetailsMetadata[] = [];
    private eventActionDetailsMetadataForMSGraphCalendar: EventActionDetailsMetadata[] = [];

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private eventActionMdUtils: EventActionMdUtils,
        private eventActionProvider: EventActionProvider,
        private router: Router,
        public generalUtils: GeneralUtils,
        public dataStoreProvider: DataStoreProvider,
        private configDataService: ConfigDataService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadActiveEventActionTypes();
    }

    ngAfterViewInit() {
        this.isCommunicationLogFeatureActive = this.configDataService.isFeatureActive('event-framework-communication-log');
        this.changeDetectorRef.detectChanges();
    }

    onSelectedDataStore(dataStore: DataStoreType[], isThisSendgridKey: boolean): void {
        this.dataStoreDependentFilters.searchPhrase = '';
        if (isThisSendgridKey) {
            (this.eventActionItem as EventActionSendgridType).sendgridKey = dataStore[0]?.id;
            this.updateEventActionTypeDetailsValidation('SendgridKey', (this.eventActionItem as EventActionSendgridType).sendgridKey);
            (this.eventActionItem as EventActionWebHookType).authorizationValue = undefined;
            this.eventActionTypeDetailsValidation['AuthorizationValue'].isValid = true;
        } else {
            (this.eventActionItem as EventActionWebHookType).authorizationValue = dataStore[0]?.id;
            this.updateEventActionTypeDetailsValidation('AuthorizationValue', (this.eventActionItem as EventActionWebHookType).authorizationValue);
            (this.eventActionItem as EventActionSendgridType).sendgridKey = undefined;
            this.eventActionTypeDetailsValidation['SendgridKey'].isValid = true;
        }
    }

    ngOnDestroy(): void {
    }

    saveEventActionData(eventAction: EventActionWithTypeDetailsType) {
        const isTemplateValid = this.validateFields(eventAction);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createEventAction(eventAction);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialEventAction, eventAction)) {
                    this.messagesService.success('toastr.success.eventActionEdit', true);
                    this.goToParentPage();
                } else {
                    this.editEventAction(this.initialEventAction, eventAction);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/eventActions']);
    }

    onEventGroupChange(eventGroupItem) {
        this.eventActionItem.eventGroup = this.generalUtils.isSelectedNoValueOption(eventGroupItem) ? 'noValue' : eventGroupItem;
    }

    isMandatoryFieldEmpty(fieldName: string, fieldValue: string): boolean {
        // we check if a field is mandatory based on the metadata we get from backend
        // if is mandatory then we also check if we have value
        const isFieldMandatory = !!lodash.find(this.eventActionDetailsMetadata, {name: fieldName})?.mandatory;
        return isFieldMandatory && !fieldValue;
    }

    isFieldValid(fieldName: string, fieldValue: string): boolean {
        // we validate a field with the validations we get from backend in metadata
        // we have 2 types of validations: regex & allowed values
        let validRegex = true;
        let validAllowedValues = true;
        if (fieldValue) {
            const fieldValidations = lodash.find(this.eventActionDetailsMetadata, {name: fieldName})?.customValidation;
            if (fieldValidations?.regexExpression && !fieldValue.match(fieldValidations?.regexExpression)) {
                validRegex = false;
            }

            if (fieldValidations?.allowedValues && lodash.indexOf(fieldValidations?.allowedValues, fieldValue) === -1) {
                validAllowedValues = false;
            }
            if (!!fieldValidations?.regexExpression && !!fieldValidations?.allowedValues) {
                return validRegex || validAllowedValues;
            } else {
                return validRegex && validAllowedValues;
            }
        }
        return true;
    }

    updateEventActionTypeDetailsValidation(fieldName: string, fieldValue: string) {
        // when a field value changes we need to run the 2 validations from metadata in order to validate it
        this.eventActionTypeDetailsValidation[fieldName].isValid = !this.isMandatoryFieldEmpty(fieldName, fieldValue) &&
            this.isFieldValid(fieldName, fieldValue);
    }

    updateEventActionTypeDetailsValidationForMSGraphEventTypes(eventActionItem: EventActionMSGraphCalendarType) {
        this.eventActionTypeDetailsValidation.MSGraphEventTypes.isValid = !this.generalUtils.isSelectedNoValueOption(eventActionItem.mSGraphEventTypes);
    }

    updateEventActionTypeDetailsValidationForAllFieldsAtTypeChange(type: EventActionTypeEnum) {
        this.eventActionItem.type = this.generalUtils.isSelectedNoValueOption(type) ? 'noValue' as EventActionTypeEnum : type;

        // when type changed we initialize the specific object for new type
        switch (type) {
            case EventActionTypeEnum.sendGrid: {
                this.eventActionItem = this.eventActionMdUtils.getInitialEventActionSendgrid(this.eventActionItem,
                    this.isCommunicationLogFeatureActive);
                this.sendgridKeyObject = undefined;
                // then we update the validations
                for (const item of this.eventActionDetailsMetadataForSendgrid) {
                    this.updateEventActionTypeDetailsValidation(item.name, this.eventActionItem[lodash.lowerFirst(item.name)]);
                }
                break;
            }
            case EventActionTypeEnum.webHook: {
                this.eventActionItem = this.eventActionMdUtils.getInitialEventActionWebHook(this.eventActionItem);
                this.authorizationValueObject = undefined;
                // then we update the validations
                for (const item of this.eventActionDetailsMetadataForWebHook) {
                    this.updateEventActionTypeDetailsValidation(item.name, this.eventActionItem[lodash.lowerFirst(item.name)]);
                }

                // and the custom validation
                const eventActionAsWebHook = this.eventActionItem as EventActionWebHookType;
                this.updateEventActionTypeDetailsValidationForNoAuthorization(eventActionAsWebHook);
                break;
            }
            case EventActionTypeEnum.mSGraphCalendar: {
                this.eventActionItem = this.eventActionMdUtils.getInitialEventActionMSGraphCalendar(this.eventActionItem);
                // then we update the validations
                this.updateEventActionTypeDetailsValidationForMSGraphEventTypes(this.eventActionItem);
                break;
            }
        }
    }

    updateEventActionTypeDetailsValidationForNoAuthorization(eventActionItem: EventActionWebHookType) {
        // this is a custom validation for NoAuthorization flag
        // when this is false => AuthorizationType & AuthorizationValue become mandatory
        // tslint:disable-next-line:max-line-length
        this.eventActionTypeDetailsValidation.AuthorizationType.isValid = !eventActionItem.noAuthorization ? !lodash.isEmpty(eventActionItem.authorizationType) : true;
        // tslint:disable-next-line:max-line-length
        this.eventActionTypeDetailsValidation.AuthorizationValue.isValid = !eventActionItem.noAuthorization ? !lodash.isEmpty(eventActionItem.authorizationValue) : true;
    }

    goToEdit() {
        history.replaceState({
            eventAction: this.eventActionItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    // function to create the new EventAction
    private createEventAction(eventAction: EventActionWithTypeDetailsType) {
        this.ngxLoader.start();
        this.eventActionProvider.addEntry(eventAction)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newEventActionAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the EventAction
    private editEventAction(oldEventAction: EventActionWithTypeDetailsType, newEventAction: EventActionWithTypeDetailsType) {
        this.ngxLoader.start();
        this.eventActionProvider.updateEntry(oldEventAction, newEventAction)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.eventActionEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(eventActionItem: EventActionWithTypeDetailsType): boolean {
        return !!(eventActionItem && eventActionItem.name && !this.generalUtils.isSelectedNoValueOption(eventActionItem.eventGroup) &&
                !this.generalUtils.isSelectedNoValueOption(eventActionItem.type)) &&
            !lodash.find(this.eventActionTypeDetailsValidation, {isValid: false});
    }

    private setupInitialState() {
        this.isCommunicationLogFeatureActive = this.configDataService.isFeatureActive('event-framework-communication-log');
        this.eventActionTypeDetailsValidation = this.eventActionMdUtils.getInitialEventActionTypeDetailsValidation();
        if (history.state && history.state.eventAction) {
            this.initialEventAction = history.state.eventAction;
            this.eventActionItem = lodash.cloneDeep(history.state.eventAction);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.eventActionItem = this.eventActionMdUtils.getInitialEventAction();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private mapDataStoreValueToObjects() {
        if (!!(this.eventActionItem as EventActionSendgridType).sendgridKey) {
            this.sendgridKeyObject = lodash.find(this.dataStoreObjectList, {id: (this.eventActionItem as EventActionSendgridType).sendgridKey});
            this.authorizationValueObject = undefined;
        } else if (!!(this.eventActionItem as EventActionWebHookType).authorizationValue) {
            this.authorizationValueObject = lodash.find(this.dataStoreObjectList, {id: (this.eventActionItem as EventActionWebHookType).authorizationValue});
            this.sendgridKeyObject = undefined;
        }
    }

    private loadActiveEventActionTypes() {
        this.ngxLoader.start();
        forkJoin([
            this.eventActionProvider.getActiveEventActionTypes(),
            this.dataStoreProvider.getEntries({})
        ])
            .pipe(take(1))
            .subscribe(([activeEventActionMetadata, dataStoreItems]) => {
                // Add only event actions that have the feature access enabled
                this.activeEventActionTypeMetadataList = activeEventActionMetadata.value
                    .filter(({featureAccessName}) => this.configDataService.isFeatureActive(featureAccessName));
                this.dataStoreObjectList = dataStoreItems.value;
                this.mapDataStoreValueToObjects();
                this.mapEventActionDetailsMetadata();
                if (this.screenTemplateLayout.action === constants.EDIT) {
                    for (const item of this.eventActionDetailsMetadata) {
                        if (this.eventActionItem[lodash.lowerFirst(item.name)]) {
                            this.updateEventActionTypeDetailsValidation(item.name, this.eventActionItem[lodash.lowerFirst(item.name)]);
                        }
                    }
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private mapEventActionDetailsMetadata() {
        for (const item of this.activeEventActionTypeMetadataList) {
            this.eventActionDetailsMetadata = lodash.concat(this.eventActionDetailsMetadata, item.details);
            switch (item.actionType) {
                case this.EventActionTypeEnum.sendGrid: {
                    this.eventActionDetailsMetadataForSendgrid = lodash.concat(this.eventActionDetailsMetadataForSendgrid, item.details);
                    break;
                }
                case this.EventActionTypeEnum.webHook: {
                    this.eventActionDetailsMetadataForWebHook = lodash.concat(this.eventActionDetailsMetadataForWebHook, item.details);
                    break;
                }
                case this.EventActionTypeEnum.mSGraphCalendar: {
                    this.eventActionDetailsMetadataForMSGraphCalendar = lodash.concat(this.eventActionDetailsMetadataForMSGraphCalendar, item.details[0]);
                    this.mSGraphCalendarAllowedValues = item.details[0].customValidation?.allowedValues;
                    break;
                }
            }
        }
    }
}
