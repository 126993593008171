<div class="container-fluid holiday-calendar-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
      <h3 translate="label.holidayCalendarTab.title"></h3>
      <button class="reset-button btn" (click)="onClearFilters()">
         <i class="far fa-trash-alt"></i>
         <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
      </button>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between mt-3">
      <div class="sort text-high-contrast">
        <span translate="label.activeSorting"></span>:
        <span class="text-info">
            <span *ngFor="let key of objectKeys(holidayCalendarMdUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + holidayCalendarMdUtils.tableFilters.orderBy[key] | translate}}
                <span>
                    <i class="fas fa-times cursor-pointer" (click)="onClearOrderBy(key)"></i>
                </span>
            </span>
        </span>
      </div>

      <div class="d-flex">
        <div class="d-flex search">
          <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
          <input type="text" class="form-control rounded-0"
                 placeholder="{{'label.searchBy'|translate}} {{'label.description'|translate}}" autocomplete="off"
                 [(ngModel)]="holidayCalendarMdUtils.tableFilters.filter.Description" (ngModelChange)="onSearchHolidayCalendarChanged($event)">
        </div>
        <div class="ml-2">
          <button type="button" class="btn theme-alt-btn border-0"
                  [activityDisplay]="HOLIDAY_CALENDAR+CONSTANTS.CREATE"
                  (click)="createHolidayCalendar()" translate="label.createNew">
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
      <table class="table">
        <thead>
        <tr>
          <th class="name-column cursor-pointer" (click)="onSortBy('description')">
            {{'label.description' | translate}}
            <button type="button" class="btn p-0" *ngIf="!holidayCalendarMdUtils.tableFilters.orderBy.description"><i class="fas fa-sort"></i></button>
            <button type="button" class="btn p-0" *ngIf="holidayCalendarMdUtils.tableFilters.orderBy.description === 'asc'"><i
              class="fas fa-sort-down"></i></button>
            <button type="button" class="btn p-0" *ngIf="holidayCalendarMdUtils.tableFilters.orderBy.description === 'desc'"><i
              class="fas fa-sort-up"></i></button>
          </th>
          <th class="name-column" translate="label.centers"></th>
          <th class="text-center" translate="label.holidayCalendarTab.year"></th>
          <th class="name-column" translate="label.holidayCalendarTab.days"></th>
          <th class="text-center" translate="label.action"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let holidayCalendar of holidayCalendarMdList"
            [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
            sbaseMarkTableRowOnHover
            (click)="viewHolidayCalendar(holidayCalendar)">
          <td class="name-column">{{holidayCalendar.description}}</td>
          <td>
            <div *ngFor="let center of holidayCalendar.centers | slice:0:3">
              {{center.name}}
            </div>
            <ng-template #centersContent>
              <div class="table-tooltip-container px-2 py-1">
                <div *ngFor="let center of holidayCalendar.centers">{{center.name}}</div>
              </div>
            </ng-template>
            <div *ngIf="holidayCalendar.centers && holidayCalendar.centers.length>3" (click)="$event.stopPropagation()">
              <div [ngbPopover]="centersContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
              </div>
            </div>
          </td>
          <td class="text-center">{{holidayCalendar.year}}</td>
          <td>
            <div *ngFor="let holiday of holidayCalendar.days | slice:0:3">
              {{holiday.description}} : {{
                holiday.type === 'Fixed' ?
                (holiday.monthOfYear + '/' + holiday.dayOfMonth | dateMMddFormatPipe) :
                (holiday.monthOfYear + '/' + holiday.dayOfMonth + '/' + holiday.year | dateMMddyyyyFormatPipe)
              }}
            </div>
            <ng-template #daysContent>
              <div class="table-tooltip-container px-2 py-1">
                <div *ngFor="let holiday of holidayCalendar.days">
                  {{holiday.description}} : {{
                  holiday.type === 'Fixed' ?
                    (holiday.monthOfYear + '/' + holiday.dayOfMonth | dateMMddFormatPipe) :
                    (holiday.monthOfYear + '/' + holiday.dayOfMonth + '/' + holiday.year | dateMMddyyyyFormatPipe)
                  }}
                </div>
              </div>
            </ng-template>
            <div *ngIf="holidayCalendar.days && holidayCalendar.days.length>3" (click)="$event.stopPropagation()">
              <div [ngbPopover]="daysContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
              </div>
            </div>
          </td>
            <td class="text-center" (click)="$event.stopPropagation()">
            <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <ng-template #popContent>
              <ul class="list-style-none m-0 p-0 custom-drop-down">
                <li>
                  <button type="button" class="btn" translate="label.view" (click)="viewHolidayCalendar(holidayCalendar)"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.edit" (click)="editHolidayCalendar(holidayCalendar)"
                          [activityDisplay]="HOLIDAY_CALENDAR+CONSTANTS.UPDATE"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.delete"
                          (click)="displayConfirmDeleteItemModal(holidayCalendar.id)"
                          [activityDisplay]="HOLIDAY_CALENDAR+CONSTANTS.DELETE"></button>
                </li>
                  <li>
                      <button type="button" class="btn" translate="label.changeLog"
                              (click)="displayChangeLog(holidayCalendar.id, EntityTypeEnum.holidayCalendar, holidayCalendar.description)"
                              [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                  </li>
              </ul>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
      <ngb-pagination [collectionSize]="totalTableItems" [(page)]="holidayCalendarMdUtils.tableFilters.currentPage" [maxSize]="5"
                      [pageSize]="holidayCalendarMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                      (pageChange)="onChangePagination($event)"></ngb-pagination>

      <div class="btn-group items-per-page-group">
        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{holidayCalendarMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
      </div>
      <!-- ============================================================== -->
      <!-- End Page Filter -->
    </div>
</div>
