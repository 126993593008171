import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit
} from '@angular/core';
import {ActionType, ODataFilterQueryType, TableFiltersType} from '../../data-model/general.type';
import {
    AreaProvider,
    AvailabilityDataType,
    AvailabilityProvider,
    AvailabilityType,
    CenterProvider,
    ConfirmDeleteModalService,
    DateRangeOptionsType,
    DateRangeResponseType,
    FilterWrapperNameEnum,
    GenericFilterOptionsType,
    GenericFilterResultType,
    ResourceProvider,
    ResourceTypeProvider,
    ResourceTypeType,
    SearchFilterUtils,
    ServiceProvider,
    TagTypeComponent
} from 'sked-base';
import {constants} from 'src/app/shared/constants/constants';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AvailabilityUtils} from './availability-utils';
import moment from 'moment';
import {
    AvailabilityStatusEnum,
    AvailabilityTemplateLayoutType,
    AvailabilityViewPageEnum,
    ModalFilterOptionsType
} from './availability.types';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {
    DateRangeModalFilterOptionsType,
    IgnoreHolidaysOptionType,
    ModalFiltersType,
    OnlineConsultationModalFilterOptionsType,
    RoomReservationNeededOptionType
} from '../../shared/component/modal-filters/modal-filters.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {AvailabilityManagementContextService} from '../../shared/services/availability-management-context.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-availability',
    templateUrl: './availability.component.html',
    styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    tableFilters: TableFiltersType;
    itemsPerPageList: number[];
    availabilityList: any = [];
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    tagsList: TagTypeComponent[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    resourceButtonName = 'label.resource';
    centerButtonName = 'label.center';
    serviceButtonName = 'label.service';
    areaButtonName = 'label.area';
    AVAILABILITY = 'Availability';
    constants = constants;
    DISPLAYED_SERVICES_NUMBER = 5;
    dateRangeOptions: DateRangeOptionsType;
    IntervalTypeEnum = IntervalTypeEnum;
    AvailabilityStatusEnum = AvailabilityStatusEnum;
    availabilityStatusesList: AvailabilityStatusEnum[];
    availabilityStatusFilterInitialValue = 'undefined';
    screenTemplateLayout = {} as AvailabilityTemplateLayoutType;
    AvailabilityViewPageEnum = AvailabilityViewPageEnum;
    VIEW_ACTIVITY_ENABLED = false;
    displayAvailabilityFiltersToMove = true;

    filterWrapperOptions: GenericFilterOptionsType[];
    updateFiltersValue: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    modalFilters: ModalFiltersType;

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR = '#availability-filters-container sbase-filter-wrapper .filter-wrapper-component';

    private filterWrapperValues: GenericFilterResultType[] = [];
    private searchByChanged = new Subject<number>();

    constructor(
        public areaProvider: AreaProvider,
        public availabilityProvider: AvailabilityProvider,
        public availabilityUtils: AvailabilityUtils,
        public centerProvider: CenterProvider,
        public dateTimeUtils: DateTimeUtils,
        public searchFilterUtils: SearchFilterUtils,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public resourceProvider: ResourceProvider,
        public resourceTypeProvider: ResourceTypeProvider,
        public router: Router,
        public serviceProvider: ServiceProvider,
        public confirmDeleteService: ConfirmDeleteModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private availabilityManagementContextService: AvailabilityManagementContextService
    ) {
    }

    ngOnInit(): void {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive(this.AVAILABILITY + lodash.capitalize(constants.READ));
        this.setInitialState();
        this.subscribeSearchByChanged();
        this.loadFilters();

        this.loadAvailabilityData(this.tableFilters);
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    ngOnDestroy(): void {
        // Save global state (intra availability management)
        this.availabilityManagementContextService.saveIntraAvailabilityManagementState(this.tableFilters);
        // Save local state (inside each availability management page)
        this.availabilityUtils.filtersState = {
            dateRangeOptions: lodash.cloneDeep(this.dateRangeOptions),
            modalFilters: lodash.cloneDeep(this.modalFilters),
            itemsPerPageList: lodash.cloneDeep(this.itemsPerPageList),
            tableFilters: lodash.cloneDeep(this.tableFilters),
            filterWrapperOptions: this.filterWrapperOptions?.map((filterWrapperOption: GenericFilterOptionsType) => ({
                ...lodash.omit(filterWrapperOption, ['providerInstance']),
                providerInstance: filterWrapperOption.providerInstance, // Don't deep clone to save time
                parentFilterValue: undefined, // Reset this as it will be checked at loading
            })),
        };
        this.forceRerenderAvailabilityFiltersToMove();
    }

    // this solves the expressionchangedafterithasbeencheckederror problem
    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }

    forceRerenderAvailabilityFiltersToMove(): void {
        this.displayAvailabilityFiltersToMove = false;
        this.changeDetectorRef.detectChanges();
        this.displayAvailabilityFiltersToMove = true;
    }

    onClearFilters() {
        // Hide modal filters button to reset the color. The modal filters options will be set again inside loadFilters
        this.modalFilters = undefined;
        // Clear filter wrapper values
        this.updateFiltersValue.next([]);
        // Load filters and data
        setTimeout(() => {
            this.onSearchByChanged(0);
            this.loadFilters(true);
            this.loadAvailabilityData(this.tableFilters);
        });
    }

    onFilterWrapperValueChanged(filtersValue): void {
        this.filterWrapperValues = filtersValue;
        this.tagsList = [];
        for (const filterOpt of this.filterWrapperOptions) {
            const foundFilter = lodash.find(this.filterWrapperValues, {name: filterOpt.name});
            if (foundFilter) {
                this.tagsList.push(foundFilter.value);
                lodash.uniq(this.tagsList, 'id');
                this.tableFilters.filter[foundFilter.name] = foundFilter.value;
            } else { // filter was deleted
                this.tableFilters.filter[filterOpt.name] = undefined;
            }
        }
        this.loadAvailabilityData(this.tableFilters);
    }

    onModalFiltersSelected(modalFilters) {
        this.onDateRangeChanged(modalFilters.dateRangeOptions);
        this.tableFilters.filter.requestedIntervalKind = modalFilters.dateRangeOptions.requestedIntervalKind;
        this.tableFilters.filter.onlineConsultation = modalFilters.onlineConsultationOptions.onlineConsultation;
        this.tableFilters.filter.roomReservationNeeded = modalFilters.roomReservationNeededOptions.roomReservationNeeded;
        this.tableFilters.filter.ignoreHolidays = modalFilters.ignoreHolidaysOptions.ignoreHolidays;
        this.modalFilters = modalFilters;

        this.loadAvailabilityData(this.tableFilters);
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        if (dateRange.fromDate) {
            this.tableFilters.filter.validFrom = moment
                .parseZone(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRange.fromDate))
                .format('YYYY-MM-DD');
        } else {
            delete this.tableFilters.filter.validFrom;
        }
        if (dateRange.toDate) {
            this.tableFilters.filter.validTo = moment
                .parseZone(this.dateTimeUtils.getNgbDateWithoutOneMonth(dateRange.toDate))
                .format('YYYY-MM-DD');
        } else {
            delete this.tableFilters.filter.validTo;
        }
    }

    isDateRangeValidForIntervalKind(): boolean {
        return this.dateTimeUtils.isDateRangeValidForIntervalKind(this.tableFilters?.filter.requestedIntervalKind,
            this.tableFilters?.filter.validFrom,
            this.tableFilters?.filter.validTo);
    }

    // method to navigate create Availability
    createAvailability(): void {
        this.router.navigate(['/createAvailability'], {state: {filters: this.filterWrapperValues}});
    }

    editAvailability(availabilityId: string) {
        this.router.navigate(['/createAvailability'], {state: {availabilityId, action: ActionType.Edit}});
    }

    viewAvailability(availabilityId: string, status: AvailabilityStatusEnum) {
        const statusNewEditedOrMarkedForDelete = status === AvailabilityStatusEnum.NEW
            || status === AvailabilityStatusEnum.EDITED || status === AvailabilityStatusEnum.MARKED_FOR_DELETE;
        const routerUrlIsApproveAvailabilityOverview = this.router.url === '/' + AvailabilityViewPageEnum.ApproveAvailabilityOverview;
        const hasApproveAvailabilityActivity = this.configDataService.isActivityActive('AvailabilityApprove');

        if (routerUrlIsApproveAvailabilityOverview || (hasApproveAvailabilityActivity && statusNewEditedOrMarkedForDelete)) {
            this.router.navigate(['/viewApproveAvailability'], {
                state: {
                    availabilityId,
                    action: constants.VIEW,
                    ...(!routerUrlIsApproveAvailabilityOverview ? {comingFromRoute: this.router.url} : {})
                }
            });
        } else if (this.router.url === '/' + AvailabilityViewPageEnum.AvailabilityOverview) {
            this.router.navigate(['/createAvailability'], {
                state: {
                    availabilityId,
                    action: ActionType.View,
                    parentPageLink: this.router.url
                }
            });
        }
    }

    copyAvailability(availabilityId: string) {
        this.router.navigate(['/createAvailability'], {state: {availabilityId, action: ActionType.Copy}});
    }

    deleteAvailability(availabilityId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.availabilityProvider, availabilityId).result.then(() => {
        }, (response) => {
            if (response) {
                this.ngxLoader.start();
                this.availabilityProvider.deleteAvailability(availabilityId)
                    .pipe(take(1))
                    .subscribe(responseDelete => {
                        this.messagesService.success('toastr.success.availabilityDeleted');
                        this.loadAvailabilityData(this.tableFilters);
                    }, err => {
                        this.ngxLoader.stop();
                        this.messagesService.handlingErrorMessage(err);
                    }, () => {
                        this.ngxLoader.stop();
                    });
            }
        });
    }

    historyAvailability(availabilityId: string) {
        this.router.navigate(['/availabilityHistory'], {state: {availabilityId}});
    }

    splitAvailability(availability: AvailabilityType) {
        this.router.navigate(['/createAvailabilitySplit'], {state: {availabilityId: availability.id}});
    }

    approveOrDeclineAvailability(availabilityId: string, action: string) {
        this.router.navigate(['/viewApproveAvailability'], {state: {availabilityId, action, comingFromRoute: this.previousRouteService.getCurrentUrl()}});
    }

    blockingSimulationAvailability(availabilityId: string) {
        this.router.navigate(['/availabilityBlockedAppointmentsPreview'], {
            state: {
                id: availabilityId,
                type: this.AVAILABILITY,
                view: this.screenTemplateLayout.viewType
            }
        });
    }

    oversellingDefinitionAvailability(availability: AvailabilityDataType) {
        this.router.navigate(['/availabilityOversellingDefinitions'], {state: {availability}});
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tableFilters.currentPage = page;
        this.loadAvailabilityData(this.tableFilters);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadAvailabilityData(this.tableFilters);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.tableFilters.orderBy[property];

        if (this.tableFilters.orderBy) {
            this.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.loadAvailabilityData(this.tableFilters);
    }

    onSearchByChanged(value: number) {
        this.searchByChanged.next(value);
    }

    private subscribeSearchByChanged() {
        this.searchByChanged
            .pipe(
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                if (!!searchValue && !!Number(searchValue) && Number.isInteger(searchValue) && Number(searchValue) > 0) {
                    this.tableFilters.filter.shortId = searchValue;
                } else {
                    delete this.tableFilters.filter.shortId;
                }
                this.loadAvailabilityData(this.tableFilters);
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.tableFilters.orderBy[selectedItem];
        this.loadAvailabilityData(this.tableFilters);
    }

    onDeleteTag(tagsList) {
        const remainingFilters: GenericFilterResultType[] = [];
        for (const filterValue of this.filterWrapperValues) {
            const foundFilter = lodash.find(tagsList, {id: filterValue.value.id});
            if (foundFilter) { // filter was not deleted from tag
                remainingFilters.push({name: filterValue.name, value: foundFilter});
            } else { // filter was not deleted from tag => delete it also from table filters
                this.tableFilters.filter[filterValue.name] = undefined;
            }
        }
        this.filterWrapperValues = remainingFilters;
        this.updateFiltersValue.emit(remainingFilters);
        this.loadAvailabilityData(this.tableFilters);
    }

    onAvailabilityStatusFilterChange(selectedStatuses: { value: string }) {
        if (!lodash.includes(selectedStatuses.value, undefined)) {
            this.tableFilters.filter.statuses = [selectedStatuses.value];
        } else {
            this.tableFilters.filter.statuses = this.availabilityUtils.getStatusesFilterByViewType(this.screenTemplateLayout.viewType);
        }
        this.loadAvailabilityData(this.tableFilters);
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#availability-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    // function to get Availability data
    private loadAvailabilityData(tableFilters: TableFiltersType, includeCount: boolean = true) {
        if (this.isDateRangeValidForIntervalKind()) {
            const queryFilter = this.availabilityUtils.getQueryFilterForAvailabilityOverview(tableFilters, includeCount);
            const requestBody = this.availabilityUtils.getRequestBodyForAvailabilityOverview(tableFilters.filter);
            this.ngxLoader.start();
            forkJoin([this.availabilityProvider.getAvailabilityOverview(queryFilter, requestBody),
                this.getResourceTypeRoom()])
                .pipe(take(1))
                .subscribe(([response, resourceTypeRoom]) => {
                    // If no results on this page but page is not 1, redo the request on previous page
                    if (response.items?.length === 0 && tableFilters?.currentPage > 1) {
                        tableFilters.currentPage -= 1;
                        this.loadAvailabilityData(tableFilters, includeCount);
                        return;
                    }
                    this.availabilityList = response.items;
                    if (response.count !== undefined && response.count !== null) {
                        this.totalTableItems = response.count;
                    }
                    if (this.filterWrapperOptions?.length > 0 && resourceTypeRoom?.value?.length > 0
                        && this.filterWrapperOptions[0]?.dependentFilters?.resourceTypeExclusionList?.length < 1) {
                        this.filterWrapperOptions[0].dependentFilters.resourceTypeExclusionList = [resourceTypeRoom?.value[0]?.id];
                    }
                    this.ngxLoader.stop();
                }, err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                });
        }
    }

    private getFilterWrapperOptions = (initialValues: {
        [key in FilterWrapperNameEnum]?: GenericFilterResultType
    } = {}): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.searchFilterUtils.getResourceDependentFilters(),
                disableFilter: false,
                label: this.resourceButtonName,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues[FilterWrapperNameEnum.resource] ?? undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            },
            {
                dependentFilters: this.searchFilterUtils.getLocationDependentFilters(),
                disableFilter: false,
                label: this.centerButtonName,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues[FilterWrapperNameEnum.location] ?? undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            },
            {
                dependentFilters: this.searchFilterUtils.getServiceDependentFilters(),
                disableFilter: false,
                label: this.serviceButtonName,
                name: FilterWrapperNameEnum.service,
                parentFilterValue: initialValues[FilterWrapperNameEnum.service] ?? undefined,
                providerInstance: this.serviceProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            },
            {
                dependentFilters: this.searchFilterUtils.getAreaDependentFilters(),
                disableFilter: false,
                label: this.areaButtonName,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues[FilterWrapperNameEnum.area] ?? undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true,
                hideSelectedItems: true
            }]
    )

    private setInitialState() {
        if (this.router.url === '/' + AvailabilityViewPageEnum.AvailabilityOverview) {
            this.setInitialStateByType(AvailabilityViewPageEnum.AvailabilityOverview);
            // tslint:disable-next-line:max-line-length
            this.availabilityStatusesList = [AvailabilityStatusEnum.NEW, AvailabilityStatusEnum.APPROVED, AvailabilityStatusEnum.DELETED, AvailabilityStatusEnum.MARKED_FOR_DELETE, AvailabilityStatusEnum.EDITED];
        } else if (this.router.url === '/' + AvailabilityViewPageEnum.ApproveAvailabilityOverview) {
            this.setInitialStateByType(AvailabilityViewPageEnum.ApproveAvailabilityOverview);
            this.availabilityStatusesList = [AvailabilityStatusEnum.NEW, AvailabilityStatusEnum.MARKED_FOR_DELETE,
                AvailabilityStatusEnum.EDITED, AvailabilityStatusEnum.PENDING_SPLIT];
        }
    }

    private setInitialStateByType(availabilityPageName: AvailabilityViewPageEnum) {
        this.screenTemplateLayout.viewType = availabilityPageName;
        this.tableFilters = this.generalUtils.getInitialTableFilter();
        this.tableFilters.filter = this.availabilityUtils.getFilterByViewType(availabilityPageName);
        this.screenTemplateLayout.pageTitle = 'label.' +
            (availabilityPageName === AvailabilityViewPageEnum.AvailabilityOverview ? 'availabilities' : availabilityPageName);
    }

    private getInitialDateRangeModalFilterOptions(dateRangeOptions: DateRangeOptionsType, pageFilters: ODataFilterQueryType)
        : DateRangeModalFilterOptionsType {
        const dateRangeModalFilterOptions = dateRangeOptions as DateRangeModalFilterOptionsType;
        dateRangeModalFilterOptions.requestedIntervalKind = pageFilters.requestedIntervalKind;
        return dateRangeModalFilterOptions;
    }

    private getInitialOnlineConsultationModalFilterOptions(onlineConsultation: boolean): OnlineConsultationModalFilterOptionsType {
        return {onlineConsultation} as OnlineConsultationModalFilterOptionsType;
    }

    private getInitialRoomReservationNeededModalFilterOptions(roomReservationNeeded: boolean): RoomReservationNeededOptionType {
        return {roomReservationNeeded} as RoomReservationNeededOptionType;
    }

    private getInitialIgnoreHolidaysModalFilterOptions(ignoreHolidays: boolean): IgnoreHolidaysOptionType {
        return {ignoreHolidays} as IgnoreHolidaysOptionType;
    }

    private getInitialModalFilters(modalFilterOptions: ModalFilterOptionsType): ModalFiltersType {
        return {
            dateRangeOptions: modalFilterOptions.dateRangeModalFilterOptions,
            onlineConsultationOptions: modalFilterOptions.onlineConsultationModalFilterOptions,
            roomReservationNeededOptions: modalFilterOptions.roomReservationNeededFilterOptions,
            ignoreHolidaysOptions: modalFilterOptions.ignoreHolidaysFilterOptions,
            title: 'label.moreFilters'
        };
    }

    private getResourceTypeRoom(): Observable<{ value: ResourceTypeType[] }> {
        if (this.filterWrapperOptions?.length > 0 && this.filterWrapperOptions[0]?.dependentFilters?.resourceTypeExclusionList?.length > 0) {
            return of({value: undefined} as { value: ResourceTypeType[] });
        } else {
            return this.resourceTypeProvider.getResourceTypeRoom();
        }
    }

    private shouldKeepFilterLocalState(): boolean {
        const previousUrlsThatShouldKeepFilters = {
            '/availability': [
                '/createAvailability',
                '/availabilityHistory',
                '/createAvailabilitySplit',
                '/availabilityBlockedAppointmentsPreview',
                '/availabilityOversellingDefinitions',
                '/viewApproveAvailability',
            ],
            '/approveAvailability': [
                '/viewApproveAvailability',
                '/availabilityBlockedAppointmentsPreview',
            ],
        };
        const previousUrlIndicatesWeShouldKeepFilters = lodash.indexOf(
            previousUrlsThatShouldKeepFilters[this.previousRouteService.getCurrentUrl()],
            this.previousRouteService.getPreviousUrl()
        ) !== -1;
        return previousUrlIndicatesWeShouldKeepFilters && !!this.availabilityUtils.filtersState;
    }

    private shouldKeepFilterGlobalState(): boolean {
        const previousUrlsThatShouldKeepFilters = [
            '/availability', '/exclusion', '/approveAvailability', '/approveExclusion'
        ];
        return lodash.indexOf(previousUrlsThatShouldKeepFilters, this.previousRouteService.getPreviousUrl()) !== -1;
    }

    private loadFilters(resetAll: boolean = false): void {
        if (!resetAll && this.shouldKeepFilterLocalState()) {
            this.dateRangeOptions = lodash.cloneDeep(this.availabilityUtils.filtersState.dateRangeOptions);
            this.modalFilters = lodash.cloneDeep(this.availabilityUtils.filtersState.modalFilters);
            this.itemsPerPageList = lodash.cloneDeep(this.availabilityUtils.filtersState.itemsPerPageList);
            this.tableFilters = lodash.cloneDeep(this.availabilityUtils.filtersState.tableFilters);
            this.filterWrapperOptions = lodash.cloneDeep(this.availabilityUtils.filtersState.filterWrapperOptions)?.map((filterOptions) => ({
                ...filterOptions,
                ...(!!this.tableFilters?.filter && !!this.tableFilters.filter[filterOptions.name] ? {
                    parentFilterValue: {
                        name: filterOptions.name,
                        value: this.tableFilters.filter[filterOptions.name],
                    }
                } : {}),
            }));
            this.availabilityStatusFilterInitialValue = this.tableFilters?.filter?.statuses?.length === 1
                ? this.tableFilters?.filter?.statuses[0] : 'undefined';
            this.filterWrapperValues = this.availabilityManagementContextService.getUpdatedFilterWrapperValues(
                [FilterWrapperNameEnum.resource, FilterWrapperNameEnum.location, FilterWrapperNameEnum.service, FilterWrapperNameEnum.area],
                this.tableFilters
            );
        } else {
            // Clear local state (inside each availability management page)
            this.availabilityUtils.filtersState = {};

            // Set initial filters
            if (resetAll) {
                this.setInitialState();
                this.availabilityStatusFilterInitialValue = 'undefined';
            }
            this.dateRangeOptions = this.availabilityUtils.getInitialDateRangeOptions();
            // Set initial min and max dates to null, to signify that whenever the modal is reopened, the minDate should reset back to null
            this.dateRangeOptions.initialMinDate = null;
            this.dateRangeOptions.initialMaxDate = null;
            const filterOptions: ModalFilterOptionsType = {
                dateRangeModalFilterOptions: this.getInitialDateRangeModalFilterOptions(this.dateRangeOptions, this.tableFilters.filter),
                onlineConsultationModalFilterOptions: this.getInitialOnlineConsultationModalFilterOptions(null),
                roomReservationNeededFilterOptions: this.getInitialRoomReservationNeededModalFilterOptions(null),
                ignoreHolidaysFilterOptions: this.getInitialIgnoreHolidaysModalFilterOptions(null)
            };
            this.modalFilters = this.getInitialModalFilters(filterOptions);
            this.itemsPerPageList = [5, 10, 25, 50];

            // Check for global (intra availability management) state
            let filterWrapperInitialValues = {};
            if (!resetAll && this.shouldKeepFilterGlobalState()) {
                filterWrapperInitialValues = this.availabilityManagementContextService.filtersState?.filterWrapperInitialValues ?? {};
                if (!!this.tableFilters.filter) {
                    this.tableFilters.filter[FilterWrapperNameEnum.resource] = filterWrapperInitialValues[FilterWrapperNameEnum.resource]?.value;
                    this.tableFilters.filter[FilterWrapperNameEnum.location] = filterWrapperInitialValues[FilterWrapperNameEnum.location]?.value;
                }
            }
            this.filterWrapperOptions = this.getFilterWrapperOptions(filterWrapperInitialValues ?? {});
            this.filterWrapperValues = this.availabilityManagementContextService.getUpdatedFilterWrapperValues(
                [FilterWrapperNameEnum.resource, FilterWrapperNameEnum.location],
                this.tableFilters
            );
        }
    }
}
