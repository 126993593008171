export interface TableFiltersType {
    itemsPerPage: number;
    currentPage: number;
    filter: ODataFilterQueryType;
    orderBy: ODataOrderByQueryType;
    expand: {};
}

export interface ODataFilterQueryType {
    [property: string]: any;
}

export interface ODataOrderByQueryType {
    [property: string]: 'asc' | 'desc';
}

export interface IdNameType {
    id: string;
    name: string;
}

export interface IdNameSelectedType extends IdNameType {
    selected: boolean;
}

export interface ScreenTemplateLayoutType {
    pageTitle: string;
    action: string;
    createOrSaveButton?: string;
    cancelOrBackButton?: string;
}

export interface ExternalKeysValueType {
    id?: string;
    origin: string;
    key: string;
    entityId?: string;
}

export enum ActionType {
    Create = 'create',
    Edit = 'Edit',
    View = 'View',
    Approve = 'Approve',
    Copy = 'Copy'
}

export interface LabelValueType {
    label: string;
    value: string;
}

export enum TagsForEntityEnum {
    Resource = 'Resource',
    Service = 'Service'
}

export enum GenderEnum {
    Unknown = 'Unknown',
    Male = 'Male',
    Female = 'Female',
    Undetermined = 'Undetermined'
}

export interface FormValidationType {
    isValid: boolean;
    errorMessage: string;
}

export interface ErrorKeyMessageType {
    key: string;
    message: string;
}

export type RecordWithMultipleKeys<K extends keyof any, T> = {
    [P in K]: T;
};

export interface PhoneNumberType {
    countryCode?: string;
    phoneNumber: string;
}

export interface IdleTimerOptionsType {
    timeoutSeconds?: number;
    onTimeout?: () => void;
    onExpired?: () => void;
    checkIdleEverySeconds?: number;
    debounceUpdateTimeoutAfterUserInteractivityInSeconds?: number;
}
