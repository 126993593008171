import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {ResourceCalendarComponent} from './resource-calendar.component';

@NgModule({
    declarations: [ResourceCalendarComponent],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class ResourceCalendarModule {
}
