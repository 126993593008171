<div class="activity-plan-service-recurrence-modal">
    <div class="modal-header">
        <h4>
            <strong>{{"label.recurrence" | translate}}</strong>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-high-contrast">
        <div class="container d-flex no-block flex-column custom-scrollbar" *ngIf="!!activityPlanUtils.scopedServiceItem">
            <div class="row mx-0 mt-2 mb-4">
                <!-- Select Frequency type -->
                <div class="col-6 p-0 pr-4">
                    <div class="form-group mb-0 task-list-select-filter"
                         *ngIf="activityPlanUtils.scopedServiceFrequencyItem?.frequencyType"
                         [ngClass]="{'disabled-input': activityPlanUtils.screenTemplateLayout?.action === constants.VIEW}">
                        <label class="mb-1 mr-2 "
                               translate="label.activityPlanTemplateTab.frequencyType"></label>
                        <ng-select
                                class="form-control pl-0 rounded-0 w-100 border-0 p-0 custom-ng-select text-high-contrast"
                                dropdownPosition="bottom"
                                [multiple]="false"
                                [searchable]="false"
                                [clearable]="false"
                                [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'input-element-has-error' : !activityPlanUtils?.scopedServiceValidation?.frequencyType?.isValid}"
                                [disabled]="activityPlanUtils.screenTemplateLayout?.action===constants.VIEW"
                                [(ngModel)]="activityPlanUtils.scopedServiceFrequencyItem.frequencyType"
                                (ngModelChange)="activityPlanUtils.onFrequencyTypeChange()">
                            <ng-option *ngFor="let frequencyType of frequencyTypeEnumList"
                                       [value]="frequencyType">
                                {{'label.' + frequencyType | translate}}
                            </ng-option>
                        </ng-select>
                        <div *ngIf="!activityPlanUtils?.scopedServiceValidation?.frequencyType?.isValid" class="help-block">
                            <span [translate]="activityPlanUtils?.scopedServiceValidation?.frequencyType?.errorMessage"></span>
                        </div>
                    </div>
                </div>
                <!-- Select Interval -->
                <div class="col-6 p-0">
                    <div class="form-group mb-0"
                         [ngClass]="{'disabled-input': activityPlanUtils.screenTemplateLayout?.action === constants.VIEW}">
                        <label translate="label.activityPlanTemplateTab.interval"></label>
                        <div class="row">
                            <div class="col-9 d-flex no-block align-items-center">
                                <input type="number" name="interval" class="input-element text-high-contrast"
                                       [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation?.interval?.isValid}"
                                       [(ngModel)]="activityPlanUtils.scopedServiceItem.interval"
                                       (ngModelChange)="activityPlanUtils.updateScopedServiceValidation()"
                                       placeholder="{{'label.activityPlanTemplateTab.interval'|translate}}">
                            </div>
                            <div class="col-3 p-0 d-flex no-block align-items-center">
                                <span *ngIf="activityPlanUtils.scopedServiceFrequencyItem?.frequencyType === activityPlanFrequencyTypeEnum.monthDays"
                                      class="ml-2 w-100" translate="enum.enqueueUnitType.Months"></span>
                                <span *ngIf="activityPlanUtils.scopedServiceFrequencyItem?.frequencyType === activityPlanFrequencyTypeEnum.weekDays"
                                      class="ml-2 w-100" translate="label.weeks"></span>
                            </div>
                        </div>
                        <div *ngIf="!activityPlanUtils.scopedServiceValidation?.interval?.isValid" class="help-block">
                            <span [translate]="activityPlanUtils.scopedServiceValidation?.interval?.errorMessage"></span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Select Frequency days -->
            <div class="row m-0">
                <div class="col-12 m-0 p-0">
                    <div class="form-group"
                         [ngClass]="{'disabled-input': activityPlanUtils.screenTemplateLayout?.action === constants.VIEW}">
                        <label translate="label.repeatOn"
                               class="mb-0"></label>
                        <span [ngClass]="{'has-error': !activityPlanUtils.scopedServiceValidation?.frequencyDays?.isValid}">
                            <div class="frequency-select-days-container">
                                <!-- Select Frequency weekdays -->
                                <ul class="frequency-select-days-container-weekdays mb-0"
                                    *ngIf="activityPlanUtils.scopedServiceFrequencyItem?.frequencyType === activityPlanFrequencyTypeEnum.weekDays">
                                    <li class="frequency-select-days-item"
                                        *ngFor="let weekday of activityPlanUtils.scopedServiceFrequencyItem.selectedWeekDays"
                                        (click)="activityPlanUtils.onFrequencyWeekDayClick(weekday)"
                                        [ngClass]="{'selected': weekday.selected}">
                                        {{'label.date.' + (weekday.name | lowercase) + 'Med' | translate}}
                                    </li>
                                </ul>
                                <!-- Select Frequency monthdays -->
                                <ul class="frequency-select-days-container-monthdays mb-0"
                                    *ngIf="activityPlanUtils.scopedServiceFrequencyItem?.frequencyType === activityPlanFrequencyTypeEnum.monthDays">
                                    <li class="frequency-select-days-item"
                                        *ngFor="let monthday of activityPlanUtils.scopedServiceFrequencyItem.selectedMonthDays"
                                        (click)="activityPlanUtils.onFrequencyMonthDayClick(monthday)"
                                        [ngClass]="{'selected': monthday.selected}">
                                        {{monthday.name}}
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="!activityPlanUtils.scopedServiceValidation?.frequencyDays?.isValid"
                                 class="help-block">
                                <span [translate]="activityPlanUtils.scopedServiceValidation?.frequencyDays?.errorMessage"></span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-7 p-0 pr-4">
                    <!-- Start date -->
                    <div class="row m-0 mb-4">
                        <div class="col-12 p-0">
                            <label translate="label.startDate"></label>
                            <div class="date-picker-input-container">
                                <input class="date-picker-input text-high-contrast"
                                       [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation.startTime?.isValid}"
                                       name="dpStartDate"
                                       readonly
                                       [minDate]="activityPlanUtils.startDateMinDate"
                                       [(ngModel)]="activityPlanUtils.startDateNgModel"
                                       (dateSelect)="activityPlanUtils.onStartDateChange($event)"
                                       ngbDatepicker #datePickerStartDate="ngbDatepicker"
                                       (click)="datePickerStartDate.toggle()">
                                <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                        [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation.startTime?.isValid}"
                                        (click)="datePickerStartDate.toggle()"
                                        type="button"></button>
                            </div>
                            <div *ngIf="!activityPlanUtils.scopedServiceValidation.startTime?.isValid"
                                 class="help-block">
                                <span [translate]="activityPlanUtils.scopedServiceValidation.startTime?.errorMessage"></span>
                            </div>
                        </div>
                    </div>

                    <!-- Select enqueue (period and unit) -->
                    <div class="form-group"
                         [ngClass]="{'disabled-input': activityPlanUtils.screenTemplateLayout?.action === constants.VIEW}">
                        <label translate="label.activityPlanTemplateTab.enqueue"></label>
                        <div class="row m-0">
                            <!-- Select EnqueuePeriod -->
                            <div class="col-6 pl-0">
                                <input type="number" name="periodBeforeGeneratingTasksValue"
                                       class="input-element text-high-contrast"
                                       [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation.enqueue.isValid}"
                                       [(ngModel)]="activityPlanUtils.scopedServiceItem.enqueuePeriod"
                                       (ngModelChange)="activityPlanUtils.updateScopedServiceValidation()"
                                       placeholder="{{'label.activityPlanTemplateTab.enqueue'|translate}}">
                            </div>
                            <!-- Select EnqueueUnit -->
                            <div class="col-6 pl-0 pr-0">
                                <div class="form-group mb-2">
                                    <ng-select
                                            class="form-control pl-0 rounded-0 w-100 border-0 p-0 custom-ng-select input-element text-high-contrast"
                                            dropdownPosition="bottom"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation.enqueue.isValid}"
                                            [disabled]="activityPlanUtils.screenTemplateLayout?.action===constants.VIEW"
                                            [(ngModel)]="activityPlanUtils.scopedServiceItem.enqueueUnit"
                                            (ngModelChange)="activityPlanUtils.updateScopedServiceValidation()">
                                        <ng-option *ngFor="let enqueueUnit of enqueueUnitEnumList"
                                                   [value]="enqueueUnit">
                                            {{'enum.enqueueUnitType.' + enqueueUnit | translate}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!activityPlanUtils.scopedServiceValidation.enqueue.isValid" class="help-block">
                            <!-- Pass parameter to label.error.activityPlanInvalidEnqueue -->
                            <span> {{activityPlanUtils.scopedServiceValidation.enqueue.errorMessage | translate: {maximum: activityPlanUtils.maximumTimeWindow} }} </span>
                        </div>
                    </div>
                    <!-- Ends -->
                    <div class="row m-0">
                        <div class="col-12 p-0">
                            <label translate="label.ends"></label>
                            <div *ngIf="!activityPlanUtils.scopedServiceValidation.scheduledEndType.isValid" class="help-block">
                                <span> {{activityPlanUtils.scopedServiceValidation.scheduledEndType.errorMessage | translate }} </span>
                            </div>
                            <div class="ends-container">
                                <div class="ends-item">
                                    <input type="radio"
                                           class="btn-check text-high-contrast"
                                           name="endType"
                                           id="endTypeNever"
                                           autocomplete="off"
                                           [value]="activityPlanScheduledEndTypeEnum.EndsNever"
                                           [(ngModel)]="activityPlanUtils.scopedServiceItem.scheduledEndType"
                                           (ngModelChange)="onEndTypeChange()">
                                    <label class="btn radio-button text-high-contrast mr-3 mb-0 pt-0"
                                           for="endTypeNever"
                                           translate="label.never">
                                    </label>
                                </div>
                                <div class="ends-item">
                                    <input type="radio"
                                           class="btn-check text-high-contrast"
                                           name="endType"
                                           id="endTypeAfterOccurrences"
                                           autocomplete="off"
                                           [value]="activityPlanScheduledEndTypeEnum.EndsAfterOccurences"
                                           [(ngModel)]="activityPlanUtils.scopedServiceItem.scheduledEndType"
                                           (ngModelChange)="onEndTypeChange()">
                                    <label class="btn radio-button text-high-contrast mr-0 mb-0 pt-0 pr-0"
                                           translate="label.after"
                                           for="endTypeAfterOccurrences">
                                    </label>
                                    <input type="number" name="afterOccurrences" class="input-element after-occurrences-input text-high-contrast"
                                           [ngClass]="{
                                                'input-element-has-error': !activityPlanUtils.scopedServiceValidation?.count?.isValid,
                                                'disabled-input': activityPlanUtils.screenTemplateLayout?.action === constants.VIEW
                                           }"
                                           min="1"
                                           max="999"
                                           [(ngModel)]="activityPlanUtils.scopedServiceItem.count"
                                           (ngModelChange)="onEndOccurrencesChange()"
                                           required
                                           autocomplete="off">
                                    <label class="btn radio-button text-high-contrast mr-0 mb-0 pt-0 pr-0 pl-0"
                                           translate="label.occurrences"
                                           for="endTypeAfterOccurrences"></label>
                                    <div *ngIf="!activityPlanUtils.scopedServiceValidation?.count?.isValid"
                                         class="help-block end-occurrences-help-block">
                                        <span [translate]="activityPlanUtils.scopedServiceValidation?.count?.errorMessage"></span>
                                    </div>
                                </div>
                                <div class="ends-item">
                                    <input type="radio"
                                           class="btn-check text-high-contrast"
                                           name="endType"
                                           id="endTypeOnSpecificDate"
                                           autocomplete="off"
                                           [value]="activityPlanScheduledEndTypeEnum.EndsOnSpecificDate"
                                           [(ngModel)]="activityPlanUtils.scopedServiceItem.scheduledEndType"
                                           (ngModelChange)="onEndTypeChange()">
                                    <label class="btn radio-button text-high-contrast mr-3 mb-0 pt-0"
                                           for="endTypeOnSpecificDate"
                                           translate="label.onSpecificDate">
                                    </label>
                                </div>
                                <div class="date-picker-input-container">
                                    <input class="date-picker-input text-high-contrast"
                                           [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation?.endTime?.isValid}"
                                           name="dpStartDate"
                                           readonly
                                           [minDate]="activityPlanUtils.endDateMinDate"
                                           [(ngModel)]="activityPlanUtils.endDateNgModel"
                                           (dateSelect)="onEndDateChange($event)"
                                           ngbDatepicker #datePickerEndDate="ngbDatepicker"
                                           (click)="datePickerEndDate.toggle()">
                                    <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                            [ngClass]="{'input-element-has-error': !activityPlanUtils.scopedServiceValidation?.endTime?.isValid}"
                                            (click)="datePickerEndDate.toggle()"
                                            type="button"></button>
                                </div>
                                <div *ngIf="!activityPlanUtils.scopedServiceValidation?.endTime?.isValid"
                                     class="help-block">
                                    <span [translate]="activityPlanUtils.scopedServiceValidation?.endTime?.errorMessage"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5 p-0">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <button class="btn theme-btn mx-2"
                                [disabled]="!activityPlanUtils.isSimulationCalendarEnabled()"
                                (click)="onRunSimulationAction()"
                                [translate]="!activityPlanUtils.simulationRefreshButton ? 'label.runSimulation' : 'label.runSimulationAgain'">
                        </button>
                        <i class="fas fa-info-circle text-high-contrast"
                           [ngbPopover]="'label.activityPlanSimulationMaxOccurrencesInfo' | translate"
                           triggers="mouseenter:mouseleave"
                           popoverClass="popover-shadow"
                           placement="left"></i>
                    </div>
                    <app-activity-plan-display-calendar
                            [triggerSimulation]="activityPlanUtils.triggerSimulationSubject"
                            [triggerClearCalendarPage]="activityPlanUtils.triggerClearCalendarPageSubject"
                    ></app-activity-plan-display-calendar>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn min-width-auto"
                (click)="onCloseModal()" translate="label.close">
        </button>
        <button type="button"
                class="btn theme-btn min-width-auto"
                [disabled]="!activityPlanUtils.scopedServiceValidation?.recurrenceModal?.isValid"
                (click)="onSaveModal()" translate="label.save">
        </button>
    </div>
</div>



