import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {CoverageCompanyType} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class CoverageCompanyMdUtils {
    tableFilters: TableFiltersType;
    constructor() {
    }

    getQueryFilterForCoverageCompanyMD(tableFilters: TableFiltersType, count: boolean = true) {
        return {
            select: ['Id', 'Name', 'Code', 'IsPrivate', 'System', 'PatientPortalPlace', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: {CoveragePlans: {select: ['Id', 'Name']}}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialCoverageCompany(): CoverageCompanyType {
        const coverageCompany: CoverageCompanyType = {} as CoverageCompanyType;

        coverageCompany.name = '';
        coverageCompany.isPrivate = false;
        coverageCompany.code = '';
        coverageCompany.system = false;
        coverageCompany.patientPortalPlace = undefined;

        return coverageCompany;
    }
}
