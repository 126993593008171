<div class="container-fluid create-minimum-appointments-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-minimum-appointments-rule-header">
        <h3 class="create-minimum-appointments-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.minimumAppointmentsRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.minimumAppointmentsRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.minimumAppointmentsRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.minimumAppointmentsRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.minimumAppointmentsRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule priority-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.priority"></label>
                                    <input type="number" name="priority" class="input-element"
                                           [(ngModel)]="ruleItem.priority"
                                           [ngClass]="{'input-element-has-error': !ruleItem.priority || !isPriorityValid(ruleItem.priority)}"
                                           min="1"
                                           step="1"
                                           max=200
                                           placeholder="{{'label.priority'|translate}}"
                                           autocomplete="off"/>
                                    <div *ngIf="!ruleItem.priority && ruleItem.priority !== 0" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="ruleItem.priority === 0 || ruleItem.priority > 200"
                                         class="help-block">
                                            <span>{{ "label.error.invalidNumberForInterval" | translate: {
                                                min: 1,
                                                max: 200
                                            } }}</span>
                                    </div>
                                    <div *ngIf="ruleItem?.priority > 0 && ruleItem?.priority % 1 !== 0"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.invalidNumber">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule only warning-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.onlyWarning"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.onlyWarning"
                                                   name="onlyWarning">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2" *ngIf="!isInputStepValid(ruleItem)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.minimumAppointmentsRuleMinimumConditionForInput"></span>
                        </div>
                        <div class="row">
                            <!-- Center -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.center, $event)"
                                                  [buttonName]="'label.center2'"
                                                  [dependentFilters]="mainDependentFilters?.center"
                                                  [initialFilterValues]="[ruleItem.center]"
                                                  [providerInstance]="centerProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>

                            <!-- Service -->
                            <div class="col-4">
                                <div class="form-group customSelect service">
                                    <label translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.service, $event)"
                                                  [buttonName]="'label.service2'"
                                                  [dependentFilters]="mainDependentFilters?.service"
                                                  [initialFilterValues]="[ruleItem.service]"
                                                  [providerInstance]="serviceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>

                            <!-- Resource -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.resource, $event)"
                                                  [buttonName]="'label.resource'"
                                                  [dependentFilters]="mainDependentFilters?.resource"
                                                  [initialFilterValues]="[ruleItem.resource]"
                                                  [providerInstance]="resourceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>

                            <!-- Channel -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.channel"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.channel, $event)"
                                                  [buttonName]="'label.channel'"
                                                  [dependentFilters]="mainDependentFilters?.channel"
                                                  [initialFilterValues]="initialChannel"
                                                  [providerInstance]="channelProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                </div>
                            </div>

                            <!-- Coverage plan -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.coveragePlan2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.coveragePlan, $event)"
                                                  [buttonName]="'label.coveragePlan2'"
                                                  [dependentFilters]="mainDependentFilters?.coveragePlan"
                                                  [initialFilterValues]="[ruleItem.coveragePlan]"
                                                  [providerInstance]="coveragePlanProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>

                            <!-- Availability tags -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.availabilityTag"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.availabilityTag, $event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.availabilityTag"
                                                  [initialFilterValues]="[ruleItem.availabilityTag]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="tagProvider">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Minimum appointments -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.minimumAppointments"></label>
                                    <input type="number" class="input-element"
                                           placeholder="{{'label.minimumAppointments' | translate}}"
                                           autocomplete="off" min="1"
                                           [ngClass]="{'input-element-has-error': !rulesUtils.isNumberValidForUnit(ruleItem.unit, ruleItem.minimumAppointments)}"
                                           [(ngModel)]="ruleItem.minimumAppointments">
                                    <div *ngIf="!rulesUtils.isNumberValidForUnit(ruleItem.unit, ruleItem.minimumAppointments)"
                                         class="help-block">
                                        <span class="has-error">{{rulesUtils.getErrorMessageForUnitNumber(ruleItem.unit, ruleItem.minimumAppointments) | translate}}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Unit -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label class="mb-1 mr-2" translate="label.unit"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               [ngClass]="!!ruleItem.unit ? 'ng-select-has-value' : 'input-element-has-error'"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.unit' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="ruleItem.unit"
                                               (ngModelChange)="sanitizeUnitNgSelectValue($event)">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let option of RuleUnitEnumList"
                                                   [value]="option">
                                            <span>{{('enum.revenueOptimizationRuleUnit.' + option) | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem?.unit"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Deactivate before -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label translate="label.deactivateBefore"></label>
                                    <input type="number" class="input-element"
                                           placeholder="{{'label.deactivateBefore' | translate}}"
                                           autocomplete="off" min="0"
                                           [ngClass]="{'input-element-has-error': generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore)
                                               || (!generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore) && !isWholePositiveNumber(ruleItem?.deactivateBefore))}"
                                           [(ngModel)]="ruleItem.deactivateBefore">
                                    <div *ngIf="generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore)"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                    <div *ngIf="!generalUtils.isNullOrUndefined(ruleItem?.deactivateBefore) && !isWholePositiveNumber(ruleItem?.deactivateBefore)"
                                         class="help-block">
                                    <span class="has-error"
                                          translate="label.error.invalidNumber">
                                    </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Message -->
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.message"></label>
                                    <input type="text" class="input-element"
                                           placeholder="{{'label.message' | translate}}"
                                           autocomplete="off"
                                           [ngClass]="{'input-element-has-error': !ruleItem?.message}"
                                           [(ngModel)]="ruleItem.message">
                                    <div *ngIf="!ruleItem?.message"
                                         class="help-block">
                                        <span class="has-error" translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>

                            <!-- Patient tags -->
                            <div class="col-4">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.patientTag"> </label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedFilterValue(MinimumAppointmentsRuleFiltersEnum.patientTag, $event)"
                                                  [buttonName]='"label.newTag"'
                                                  [dependentFilters]="mainDependentFilters?.patientTag"
                                                  [initialFilterValues]="[ruleItem.patientTag]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.patientTag}"
                                                  [providerInstance]="tagProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.patientTag"
                                     class="help-block">
                                    <span class="has-error" translate="label.error.required"></span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleTable"></td>
                                                <td class="text-right">{{ruleItem.minimumAppointmentsRuleTable?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.name"></td>
                                                <td class="text-right">{{ruleItem.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.priority"></td>
                                                <td class="text-right">{{ruleItem.priority}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.active"></td>
                                                <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.onlyWarning"></td>
                                                <td class="text-right">{{ruleItem.onlyWarning | yesNoPipe | translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.center2"></td>
                                                <td class="text-right"> {{ruleItem.center?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.service2"></td>
                                                <td class="text-right">
                                                    <span class="font-weight-bold"> {{ruleItem.service?.speciality?.name}} </span>
                                                    {{ruleItem.service?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.resource"></td>
                                                <td class="text-right"> {{ruleItem.resource?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.channel"></td>
                                                <td class="text-right">{{ruleItem.channel ? ('enum.channel.' +
                                                    ruleItem.channel | translate) : ''}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.coveragePlan2"></td>
                                                <td class="text-right"> {{ruleItem.coveragePlan?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.availabilityTag"></td>
                                                <td class="text-right">{{ruleItem.availabilityTag?.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.minimumAppointments"></td>
                                                <td class="text-right" *ngIf="ruleItem.unit === RuleUnitEnum.percent">
                                                    {{ruleItem.minimumAppointments + '%'}}
                                                </td>
                                                <td class="text-right" *ngIf="ruleItem.unit === RuleUnitEnum.absolute">
                                                    {{ruleItem.minimumAppointments}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.deactivateBefore"></td>
                                                <td class="text-right">{{ruleItem.deactivateBefore}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.patientTag"></td>
                                                <td class="text-right">{{ruleItem.patientTag?.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'MinimumAppointmentsRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
