import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScheduleOccurrencesForActivityPlanType} from 'sked-base';

@Component({
    selector: 'app-display-schedule-occurrences-modal',
    templateUrl: './display-schedule-occurrences-modal.component.html',
    styleUrls: ['./display-schedule-occurrences-modal.component.scss']
})
export class DisplayScheduleOccurrencesModalComponent implements OnInit {
    @Input() occurrences: ScheduleOccurrencesForActivityPlanType[];

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
    }
}
