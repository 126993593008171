import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    MinimumAppointmentsRuleProvider,
    MinimumAppointmentsRuleType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    TagProvider,
    SearchFilterUtils,
    RuleUnitEnum,
    CenterProvider,
    ChannelDependentFiltersType,
    ChannelProvider,
    CoveragePlanDependentFiltersType,
    CoveragePlanProvider,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ResourceProvider,
    ServiceDependentFiltersType,
    ServiceProvider,
    TagDependentFiltersType,
    TagType,
    CenterType,
    CoveragePlanType,
    FilterComponentChannelType,
    ResourceType,
    ServiceType,
    TagDependentFiltersScopeEnum,
    ChannelEnum
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MinimumAppointmentsRuleFiltersEnum, MinimumAppointmentsRuleTagsType} from '../minimum-appointments-rule.types';
import {MinimumAppointmentsRuleUtils} from '../minimum-appointments-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-minimum-appointments-rule',
    templateUrl: './create-minimum-appointments-rule.component.html',
    styleUrls: ['./create-minimum-appointments-rule.component.scss']
})
export class CreateMinimumAppointmentsRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: MinimumAppointmentsRuleType;
    initialRule: MinimumAppointmentsRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.MinimumAppointmentsRule);
    minimumAppointmentsRuleTags: MinimumAppointmentsRuleTagsType;
    RuleUnitEnumList = Object.values(RuleUnitEnum);
    RuleUnitEnum = RuleUnitEnum;
    MinimumAppointmentsRuleFiltersEnum = MinimumAppointmentsRuleFiltersEnum;
    mainDependentFilters: {
        center: LocationDependentFiltersType,
        service: ServiceDependentFiltersType,
        resource: ResourceDependentFiltersType,
        channel: ChannelDependentFiltersType,
        coveragePlan: CoveragePlanDependentFiltersType,
        availabilityTag: TagDependentFiltersType,
        patientTag: TagDependentFiltersType,
    };
    initialChannel: FilterComponentChannelType[] = [];

    constructor(
        public minimumAppointmentsRuleUtils: MinimumAppointmentsRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        public centerProvider: CenterProvider,
        public serviceProvider: ServiceProvider,
        public resourceProvider: ResourceProvider,
        public channelProvider: ChannelProvider,
        public coveragePlanProvider: CoveragePlanProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private minimumAppointmentsRuleProvider: MinimumAppointmentsRuleProvider,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as {invalid?: boolean, pending?: boolean} as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: MinimumAppointmentsRuleType): boolean {
        return !!(rule.minimumAppointmentsRuleTableId &&
            !lodash.isEmpty(rule.minimumAppointmentsRuleTable) && rule.name &&
            this.isPriorityValid(rule.priority));
    }

    isInputStepValid(rule: MinimumAppointmentsRuleType): boolean {
        return !!(rule.centerId || rule.serviceId || rule.resourceId
            || rule.channel || rule.coveragePlanId || rule.availabilityTagId
        );
    }

    isOutputStepValid(rule: MinimumAppointmentsRuleType): boolean {
        return !!(rule.unit
            && this.rulesUtils.isNumberValidForUnit(rule.unit, rule.minimumAppointments)
            && (!this.generalUtils.isNullOrUndefined(rule.deactivateBefore) && this.isWholePositiveNumber(rule.deactivateBefore))
            && rule.message && rule.patientTagId
        );
    }

    areAllStepsValid(rule: MinimumAppointmentsRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    isWholePositiveNumber(number: number, canBeZero: boolean = true): boolean {
        return number % 1 === 0 && (canBeZero ? number >= 0 : number > 0);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.minimumAppointmentsRuleTable = ruleTable[0];
            this.ruleItem.minimumAppointmentsRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.minimumAppointmentsRuleTable = undefined;
            this.ruleItem.minimumAppointmentsRuleTableId = undefined;
        }
    }

    isPriorityValid(priority: number): boolean {
        return priority > 0 && priority < 201 && priority % 1 === 0;
    }
    //
    // endregion First step methods

    // region Input and Output methods
    //
    onSelectedFilterValue(
        entityName: MinimumAppointmentsRuleFiltersEnum,
        selectedValuesList: CenterType[] & ServiceType[] & ResourceType[] & FilterComponentChannelType[] & CoveragePlanType[] & TagType[]
    ) {
        switch (entityName) {
            case MinimumAppointmentsRuleFiltersEnum.channel: {
                this.initialChannel = selectedValuesList?.length > 0 ? selectedValuesList : [];
                this.ruleItem[entityName] = this.initialChannel[0]?.enumValue as ChannelEnum;
                break;
            }
            default: {
                this.ruleItem[entityName] =
                    selectedValuesList?.length > 0 ? selectedValuesList[0] : undefined;
                this.ruleItem[entityName + 'Id'] = this.ruleItem[entityName]?.id;
                break;
            }
        }
    }

    sanitizeUnitNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.ruleItem.unit = undefined;
        }
    }
    //
    // endregion Input and Output methods

    // region Overview methods
    //
    saveRule(rule: MinimumAppointmentsRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.minimumAppointmentsRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: MinimumAppointmentsRuleType) {
        this.ngxLoader.start();
        this.minimumAppointmentsRuleProvider.addEntry(rule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newMinimumAppointmentsRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: MinimumAppointmentsRuleType, newRule: MinimumAppointmentsRuleType) {
        this.ngxLoader.start();
        this.minimumAppointmentsRuleProvider.updateEntry(oldRule, newRule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.minimumAppointmentsRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        this.minimumAppointmentsRuleTags = this.minimumAppointmentsRuleUtils.getInitialMinimumAppointmentsRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                if (!this.generalUtils.isNullOrUndefined(this.ruleItem.channel)) {
                    // TODO-rules: check if this can be done without loadChannels
                    this.loadChannels();
                }
                this.addSelectedTagsToTagInputs();
            }
        } else {
            this.initialRule = this.minimumAppointmentsRuleUtils.getInitialRule();
            this.ruleItem = this.minimumAppointmentsRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private addSelectedTagsToTagInputs() {
        if (!lodash.isEmpty(this.ruleItem.availabilityTag)) {
            this.minimumAppointmentsRuleTags.selectedAvailabilityTags.push(this.ruleItem.availabilityTag as unknown as TagType);
        }
        if (!lodash.isEmpty(this.ruleItem.patientTag)) {
            this.minimumAppointmentsRuleTags.selectedPatientTags.push(this.ruleItem.patientTag as unknown as TagType);
        }
    }

    private loadChannels() {
        this.ngxLoader.start();
        this.channelProvider.getEntries().subscribe(({value}) => {
            const CHANNEL = value.find(channel => channel.enumValue === this.ruleItem.channel);
            this.initialChannel = [{id: CHANNEL.id, name: CHANNEL.channel, enumValue: CHANNEL.enumValue}];
            this.ngxLoader.stop();
        }, error => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
            this.rulesUtils.goToParentPage('createRule');
        });
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            center: this.minimumAppointmentsRuleUtils.getCenterDependentFilters(),
            service: this.minimumAppointmentsRuleUtils.getServiceDependentFilters(),
            resource: this.minimumAppointmentsRuleUtils.getResourceDependentFilters(),
            channel: this.minimumAppointmentsRuleUtils.getChannelDependentFilters(),
            coveragePlan: this.minimumAppointmentsRuleUtils.getCoveragePlanDependentFilters(),
            availabilityTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
            patientTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true),
        };
    }
}
