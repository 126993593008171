import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ResourceUtilizationCalendarUtil} from './resource-utilization-calendar-util';
import {FullCalendarComponent} from '@fullcalendar/angular';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {ResourceUtilizationUtils} from '../resource-utilization.utils';
import {CalendarSlotDurationEnum, RUAppointmentsType} from 'sked-base';
import {
    ResourceUtilizationCalendarEventType,
    ResourceUtilizationCalendarRangesType,
    ResourceUtilizationCalendarResourceType
} from './resource-utilization-calendar.types';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';

@Component({
    selector: 'app-resource-utilization-calendar',
    templateUrl: './resource-utilization-calendar.component.html',
    styleUrls: ['./resource-utilization-calendar.component.scss']
})
export class ResourceUtilizationCalendarComponent implements OnInit, OnDestroy {
    @Output() selectCalendarAppointment: EventEmitter<RUAppointmentsType> =
        new EventEmitter<RUAppointmentsType>();
    @ViewChild('resourceUtilizationCalendar', {static: false}) resourceUtilizationCalendarComponent: FullCalendarComponent;
    calendarGranularityList = Object.keys(CalendarSlotDurationEnum)
        .map(key => ({value: CalendarSlotDurationEnum[key], title: key}));

    constructor(
        public resourceUtilizationCalendarUtil: ResourceUtilizationCalendarUtil,
        public resourceUtilizationUtils: ResourceUtilizationUtils,
        private changeDetectorRef: ChangeDetectorRef,
        private dateTimeUtils: DateTimeUtils
    ) {
    }

    ngOnInit(): void {
        this.loadCalendarMinMaxDateRange();
        this.loadGranularityOptions();
        this.loadFullCalendarOptions();
        this.loadSmallCalendarOptions();

        this.loadPreviousStateIfRequired();
    }

    ngOnDestroy(): void {
        delete this.resourceUtilizationUtils.calendarOptions.legendHeaderTippyInstance;
        delete this.resourceUtilizationUtils.calendarOptions.optionsHeaderTippyInstance;
    }

    onSmallHeaderCalendarDateSelect(date: NgbDateStruct) {
        this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel = date;
        // Navigate to the date in fullcalendar
        this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi.gotoDate(
            this.dateTimeUtils.getMomentFromNgbDate(this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel).format('YYYY-MM-DD')
        );
    }

    onCalendarGranularityChanged() {
        this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions.slotDuration =
            `00:${this.resourceUtilizationUtils.calendarOptions.calendarGranularityNgModel}`;
    }

    private loadSmallCalendarOptions() {
        // Load ngModel value
        this.resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel =
            this.dateTimeUtils.getNgbDateFromMoment(
                moment(this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi.getCurrentData().currentDate)
            );
        // Load resourceUtilizationCalendarUtil.smallHeaderCalendarIsDayWithSlots
        this.resourceUtilizationCalendarUtil.loadSmallCalendarIsDayWithSlots();
    }

    private loadGranularityOptions() {
        this.resourceUtilizationUtils.calendarOptions.calendarGranularityNgModel = CalendarSlotDurationEnum.small;
    }

    private loadResourcesAndEvents() {
        // For calendar resources map each resources from response to one row.
        this.resourceUtilizationUtils.calendarOptions.calendarResources =
            this.resourceUtilizationUtils.resources.map((resource: IdNameType) => {
                return {
                    id: resource.id,
                    title: resource.name
                } as ResourceUtilizationCalendarResourceType;
            });
        // For calendar events map each appointment to exactly one event in the calendar
        this.resourceUtilizationUtils.calendarOptions.calendarEvents =
            this.resourceUtilizationUtils.appointments.map((appointment: RUAppointmentsType) => {
                return {
                    resourceId: appointment.resourceId,
                    title: this.resourceUtilizationCalendarUtil.getEventTitleFromAppointment(appointment),
                    tippyContent: this.resourceUtilizationCalendarUtil.getEventTippyContentFromAppointment(appointment),
                    start: appointment.dateTimeFrom,
                    end: appointment.dateTimeTo,
                    appointment,
                    className: `custom-event-type-${this.resourceUtilizationCalendarUtil.getEventTypeFromAppointment(appointment)}`,
                    iconList: this.resourceUtilizationCalendarUtil.getEventFontAwesomeIconClassFromAppointment(appointment),
                    customBackgroundColor: this.resourceUtilizationCalendarUtil.getCustomBackgroundColor(appointment),
                    customFontColor: this.resourceUtilizationCalendarUtil.getCustomFontColor(appointment)
                } as ResourceUtilizationCalendarEventType;
            });
    }

    private loadFullCalendarOptions() {
        this.loadResourcesAndEvents();
        this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions = {
            ...this.resourceUtilizationCalendarUtil.getCalendarOptions(),
            events: this.resourceUtilizationUtils.calendarOptions.calendarEvents, // Events list
            resources: this.resourceUtilizationUtils.calendarOptions.calendarResources, // Resources list
            eventClick: (info) => {
                // Update state
                this.resourceUtilizationUtils.calendarOptions.lastSelectedAppointment = info.event.extendedProps.appointment;
                this.resourceUtilizationUtils.updateCalendarState(['lastSelectedAppointment']);
                // On event click, emit to parent
                this.selectCalendarAppointment.emit(info.event.extendedProps.appointment);
            }
        };
        // Get calendar api
        this.changeDetectorRef.detectChanges();
        this.resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarApi = this.resourceUtilizationCalendarComponent.getApi();
        // Translate text
        this.resourceUtilizationCalendarUtil.translateLabels();
    }

    private loadCalendarMinMaxDateRange() {
        const minNgbDate = this.resourceUtilizationUtils.filtersOptions.latestSearchFilterValues.dateFrom;
        const maxNgbDate = this.resourceUtilizationUtils.filtersOptions.latestSearchFilterValues.dateTo;
        const minMomentDate = this.dateTimeUtils.getMomentFromNgbDate(minNgbDate);
        // Fullcalendar considers max day as ) white ngb considers it as ], so we add one more day to fullcalendar max date
        const maxMomentDate = this.dateTimeUtils.getMomentFromNgbDate(maxNgbDate).add(1, 'days');
        this.resourceUtilizationUtils.calendarOptions.calendarRanges = {
            smallCalendarMinDate: minNgbDate,
            smallCalendarMaxDate: maxNgbDate,
            fullCalendarMinDate: minMomentDate.format('YYYY-MM-DD'),
            fullCalendarMaxDate: maxMomentDate.format('YYYY-MM-DD')
        } as ResourceUtilizationCalendarRangesType;
    }

    private loadPreviousStateIfRequired() {
        if (this.resourceUtilizationUtils.shouldKeepCalendarState) {
            if (!!this.resourceUtilizationUtils.resourceUtilizationState.calendarOptions?.lastSelectedAppointment) {
                // Update component
                this.resourceUtilizationUtils.calendarOptions.lastSelectedAppointment = lodash.cloneDeep(
                    this.resourceUtilizationUtils.resourceUtilizationState.calendarOptions.lastSelectedAppointment
                );
            }
        }
        if (!!this.resourceUtilizationUtils.resourceUtilizationState.calendarOptions?.lastSelectedAppointment) {
            // Navigate to the appointment
            this.resourceUtilizationCalendarUtil.navigateToAppointment(
                this.resourceUtilizationUtils.resourceUtilizationState.calendarOptions.lastSelectedAppointment
            );
        }
    }
}
