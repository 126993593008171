import {Injectable} from '@angular/core';
import {SessionStorageService} from '@efaps/ngx-store';
import {TranslateService} from '@ngx-translate/core';
import {ConfigDataService} from './config-data.service';
import * as lodash from 'lodash';
import {ILanguage, ITranslatedLanguageService, LanguageConfigProvider} from 'sked-base';
import {LoggerService} from './logger.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
// Whenever changing this Service make sure the interface in sked is updated as well
export class TranslatedLanguageService implements ITranslatedLanguageService {
    constructor(private sessionStorageService: SessionStorageService,
                private translateService: TranslateService,
                private languageProvider: LanguageConfigProvider,
                private loggerService: LoggerService,
                private configDataService: ConfigDataService) {
    }

    private TRANSLATED_LANGUAGE_STORAGE_NAME = 'TranslatedLanguage';
    private DEFAULT_VALUE = 'cl';
    private defaultLanguageCode;

    get translatedLanguage() {
        //this is actually country code and not language (ex: cl) => needs to be refactored
        const cachedLanguage = this.sessionStorageService.get(this.TRANSLATED_LANGUAGE_STORAGE_NAME);
        return cachedLanguage || this.defaultLanguage;
    }

    get defaultLanguage() {
        return this.defaultLanguageCode || this.getDefaultLanguage();
    }

    saveTranslatedLanguage(languageCode) {
        this.sessionStorageService.set(this.TRANSLATED_LANGUAGE_STORAGE_NAME, languageCode);
    }

    onTranslatedLanguageChanged() {
        return this.translateService.onLangChange;
    }

    onSetLanguage(languageCode) {
        //save selected language
        this.saveTranslatedLanguage(languageCode);
        //save new translation
        this.setTranslationCode(languageCode);

        if (languageCode === 'us') {
            moment.locale('en');
        } else if (languageCode === 'br') {
            moment.locale('pt');
        } else {
            moment.locale('es');
        }
    }

    private getDefaultLanguage() {
        //find default language in systemConfig
        const systemConfigValue = this.configDataService.systemConfig;
        if (systemConfigValue) {
            const defaultTranslatedLanguage = lodash.find(systemConfigValue.value, (item) => {
                return item.name === 'DefaultCountry';
            });

            if (defaultTranslatedLanguage) {
                this.defaultLanguageCode = lodash.lowerCase(defaultTranslatedLanguage.value);
                this.saveTranslatedLanguage(this.defaultLanguageCode);
                return this.defaultLanguageCode;
            }
        }

        return this.DEFAULT_VALUE;
    }

    private setTranslationCode(languageCode) {
        // get languages list
        this.languageProvider.getLanguageConfig()
            .subscribe(
                (languages) => {
                    //find language based on languageCode
                    const translatedLanguage = this.getSelectedLanguage(languages, languageCode);
                    // use new language
                    this.translateService.use(translatedLanguage);
                }, (error) => {
                    this.loggerService.logException(error);
                });
    }

    //utils
    setDefaultTranslationCode(defaultLanguageCode) {
        // get languages list
        this.languageProvider.getLanguageConfig()
            .subscribe(
                (languages) => {
                    //find language based on defaultLanguageCode
                    const translatedLanguage = this.getSelectedLanguage(languages, defaultLanguageCode);
                    this.translateService.setDefaultLang(translatedLanguage);
                }, (error) => {
                    this.loggerService.logException(error);
                });
    }

    private getSelectedLanguage(languages: ILanguage[], languageCode: string): string {
        if (languageCode === undefined) {
            return languages[0].code;
        }
        const foundLanguage = lodash.find(languages, (language) => {
            return language.code.split('-')[1].indexOf(languageCode) > -1;
        });

        return (foundLanguage && foundLanguage.code) ? foundLanguage.code : languages[0].code;
    }

    getUsedLanguage() {
        return this.translateService.currentLang ? this.translateService.currentLang : 'es-cl';
    }

    getLanguageForCountryCode(countryCode: string): string {
        switch (countryCode) {
            case 'us':
                return 'en-us';
            case 'cl':
                return 'es-cl';
            case 'co':
                return 'es-co';
            case 'ar':
                return 'es-ar';
            case 'es':
                return 'es-es';
            case 'br':
                return 'pt-br';
            default:
                return 'es-cl';
        }
    }
}
