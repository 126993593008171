import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangeLogsOptionsType} from '../change-logs/change-logs-types';

@Component({
    selector: 'app-display-change-log-modal',
    templateUrl: './display-change-log-modal.component.html',
    styleUrls: ['./display-change-log-modal.component.scss']
})
export class DisplayChangeLogModalComponent implements OnInit {
    @Input() entityType: string;
    @Input() entityId: string;
    @Input() entityName?: string;
    @Input() entityTranslationKey?: string;
    changeLogsOptions: ChangeLogsOptionsType = {} as ChangeLogsOptionsType;

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.setChangeLogsOptions();
        });
    }

    setChangeLogsOptions() {
        this.changeLogsOptions.entityTypeId = this.entityId;
        this.changeLogsOptions.entityType = this.entityType;
        this.changeLogsOptions.triggerRequestOnInit = true;
    }
}
