import {constants} from '../../shared/constants/constants';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpecialityMdUtils} from './speciality-md-util';
import {ConfirmDeleteModalService, ExportFileEntityEnum, SpecialityProvider, SpecialityType, EntityTypeEnum} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {Subject} from 'rxjs';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-speciality-md',
    templateUrl: './speciality-md.component.html',
    styleUrls: ['./speciality-md.component.scss']
})
export class SpecialityMDComponent implements OnInit, OnDestroy {
    exportFileEntityEnum = ExportFileEntityEnum;
    itemsPerPageList: number[];
    specialityMdList: any = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    VIEW_ACTIVITY_ENABLED = false;
    public MDEntityName: string;
    public constants = constants;
    EntityTypeEnum = EntityTypeEnum;

    private searchSpecialityChanged = new Subject<string>();

    constructor(
        public specialityMdUtils: SpecialityMdUtils,
        public specialityProvider: SpecialityProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private previousRouteService: PreviousRouteService,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('SpecialityRead');
        this.MDEntityName = this.specialityProvider.name;
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
        // If not coming from route createSpeciality, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/createSpeciality') {
            this.specialityMdUtils.tableFilters = this.specialityMdUtils.getInitialTableFilter();
        }
        this.getSpecialityMdData();
        this.subscribeSearchBySpecialityChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create speciality
    createSpeciality(): void {
        this.router.navigate(['/createSpeciality']);
    }

    editSpeciality(speciality: SpecialityType) {
        this.router.navigate(['/createSpeciality'], {state: {speciality, action: constants.EDIT}});
    }

    viewSpeciality(speciality: SpecialityType) {
        this.router.navigate(['/createSpeciality'], {state: {speciality, action: constants.VIEW}});
    }

    // function to get speciality data
    getSpecialityMdData(includeCount: boolean = true) {
        const queryFilter = this.specialityMdUtils.getQueryFilterForSpecialityMD(this.specialityMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.specialityProvider.getEntries(queryFilter).pipe(take(1)).subscribe((response: any) => {
            this.specialityMdList = response.value;
            if (response.count !== undefined && response.count !== null) {
                this.totalTableItems = response.count;
            }
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    // method for deleting table item
    deleteItem(id) {
        this.specialityMdUtils.tableFilters = this.specialityMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.specialityProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getSpecialityMdData();
                this.messagesService.success('toastr.success.specialityDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.specialityMdUtils.tableFilters.currentPage = page;
        this.getSpecialityMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.specialityMdUtils.tableFilters.currentPage = 1;
        this.specialityMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getSpecialityMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.specialityMdUtils.tableFilters.orderBy[property];
        if (this.specialityMdUtils.tableFilters.orderBy) {
            this.specialityMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getSpecialityMdData(false);
    }

    onSearchSpecialityChanged(value: string) {
        this.searchSpecialityChanged.next(value);
    }

    private subscribeSearchBySpecialityChanged() {
        const self = this;
        this.searchSpecialityChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.specialityMdUtils.tableFilters.filter.name = searchValue;
                self.getSpecialityMdData();
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.specialityMdUtils.tableFilters.orderBy[selectedItem];
        this.getSpecialityMdData(false);
    }

    displayConfirmDeleteItemModal(specialityId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.specialityProvider, specialityId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(specialityId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchSpecialityChanged('');
            this.specialityMdUtils.tableFilters = this.specialityMdUtils.getInitialTableFilter();
            this.getSpecialityMdData();
        });
    }
}
