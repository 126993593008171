import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportPageComponent} from './export-page.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [ExportPageComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class ExportModule {
}
