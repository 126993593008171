import {Pipe} from '@angular/core';
import {CommaDotSwapPipe} from './comma-dot-swap.pipe';
import {TranslatedLanguageService} from '../services/translated-language.service';

@Pipe({
    name: 'commaDotSwapLocalized'
})
// This pipe decides whether to use the commaDotSwap pipe or not based on the selected language
export class CommaDotSwapLocalizedPipe extends CommaDotSwapPipe {
    constructor(private translatedLanguageService: TranslatedLanguageService) {
        super();
    }

    transform(value: string | number): string {
        const country = this.translatedLanguageService.translatedLanguage;
        const countryHasDotAsDecimalSeparator = country === 'us';
        return countryHasDotAsDecimalSeparator
            ? `${value}`
            : super.transform(`${value}`);
    }
}
