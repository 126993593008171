import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ResourceWorkScheduleType,
    ResourceWorkScheduleProvider,
    CenterType,
    ResourceType,
    ResourceProvider,
    CenterProvider,
    FormValidationType,
    ResourceWorkScheduleTimeSlotType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ResourceWorkScheduleMdUtils} from '../resource-work-schedule-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {TimeSlotsUtils} from '../../../shared/utils/time-slots.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-resource-work-schedule',
    templateUrl: './create-resource-work-schedule.component.html',
    styleUrls: ['./create-resource-work-schedule.component.scss']
})
export class CreateResourceWorkScheduleComponent implements OnInit, OnDestroy {
    readonly CONSTANTS = constants;
    readonly DAYS_OF_WEEK = this.timeSlotsUtils.getWeekDays();
    initialResourceWorkSchedule: ResourceWorkScheduleType;
    resourceWorkScheduleItem: ResourceWorkScheduleType = {} as ResourceWorkScheduleType;
    screenTemplateLayout: ScreenTemplateLayoutType;

    availabilityDateAndTimeRange = this.dateTimeUtils.getInitialDateAndTimeRange();
    dateRangeOptions = this.resourceWorkScheduleMdUtils.getInitialDateRangeOptions();
    availabilityRangeValidation = {} as FormValidationType;

    mainDependentFilters = this.resourceWorkScheduleMdUtils.getMainDependentFilters();
    initialFilterValues = {
        resource: [],
        center: []
    };

    scopedTimeSlotForm = this.resourceWorkScheduleMdUtils.getInitialTimeSlotForm();
    scopedTimeSlot = this.resourceWorkScheduleMdUtils.getInitialTimeSlot();
    isTimeValid = true;
    showTimeSlotForm = false;

    constructor(
        private timeSlotsUtils: TimeSlotsUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private resourceWorkScheduleMdUtils: ResourceWorkScheduleMdUtils,
        private resourceWorkScheduleProvider: ResourceWorkScheduleProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        public resourceProvider: ResourceProvider,
        public centerProvider: CenterProvider,
        public dateTimeUtils: DateTimeUtils,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveResourceWorkScheduleData(resourceWorkSchedule: ResourceWorkScheduleType) {
        const isTemplateValid = this.validateFields(resourceWorkSchedule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === this.CONSTANTS.CREATE) {
                this.createResourceWorkSchedule(resourceWorkSchedule);
            } else if (this.screenTemplateLayout.action === this.CONSTANTS.EDIT) {
                this.editResourceWorkSchedule(this.initialResourceWorkSchedule, resourceWorkSchedule);
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/resourceWorkSchedules']);
    }

    onSelectedResourceFilter(resourceList: ResourceType[]) {
        this.initialFilterValues.resource = resourceList;
        this.mainDependentFilters.center.resourceId = resourceList?.length > 0 ? resourceList[0].id : null;
        this.resourceWorkScheduleItem.resourceId = this.mainDependentFilters.center.resourceId;
    }

    onSelectedCenterFilter(centerList: CenterType[]) {
        if (!!centerList?.length) {
            this.setCenterTimeZoneId(centerList[0].id);
        }
        this.initialFilterValues.center = centerList;
        this.resourceWorkScheduleItem.center = centerList?.length > 0 ? centerList[0] as any : null;
        this.mainDependentFilters.resource.locationId = this.resourceWorkScheduleItem.center?.id;
        this.resourceWorkScheduleItem.centerId = this.mainDependentFilters.resource.locationId;
    }

    onValidFromDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct) {
        this.availabilityDateAndTimeRange.timeFrom = time;
        this.resourceWorkScheduleItem.validFrom = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, this.availabilityDateAndTimeRange.timeFrom, this.resourceWorkScheduleItem.center.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    onValidToDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct) {
        this.availabilityDateAndTimeRange.timeTo = time;
        this.resourceWorkScheduleItem.validTo = this.dateTimeUtils.getMomentStringFromDateAndTime(
            date, time, this.resourceWorkScheduleItem.center.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    onHourFromChanged(hourFromTime: string) {
        this.scopedTimeSlotForm.hourFrom = hourFromTime;
        this.scopedTimeSlot.interval.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.isTimeValid = this.scopedTimeSlot.interval.hourTo > this.scopedTimeSlot.interval.hourFrom;
    }

    onHourToChanged(hourToTime: string) {
        this.scopedTimeSlotForm.hourTo = hourToTime;
        this.scopedTimeSlot.interval.hourTo = moment.duration(hourToTime).asMinutes();
        this.isTimeValid = this.scopedTimeSlot.interval.hourTo > this.scopedTimeSlot.interval.hourFrom;
    }

    onSaveTimeSlot() {
        const TIME_SLOT = this.resourceWorkScheduleMdUtils.getInitialTimeSlot();
        this.scopedTimeSlotForm.selectedDays.forEach(dayName => {
            this.scopedTimeSlot[dayName] = true;
            TIME_SLOT[dayName] = true;
        });
        if (this.generalUtils.isUndefinedOrNull(this.scopedTimeSlotForm.timeSlotIndex) && !this.validateTimeSlotOverlapping()) {
            this.messagesService.error('toastr.error.slotOverlapping');
            return;
        }
        if (!this.validateTimeSlotOverlapping(this.scopedTimeSlotForm.timeSlotIndex)) {
            this.messagesService.error('toastr.error.slotOverlapping');
            return;
        }
        if (!this.generalUtils.isUndefinedOrNull(this.scopedTimeSlotForm.timeSlotIndex)) {
            TIME_SLOT.interval.hourFrom = moment.duration(this.scopedTimeSlotForm.hourFrom).asMinutes();
            TIME_SLOT.interval.hourTo = moment.duration(this.scopedTimeSlotForm.hourTo).asMinutes();
            this.resourceWorkScheduleItem.timeSlots[this.scopedTimeSlotForm.timeSlotIndex] = TIME_SLOT;
            this.clearTimeSlotForm();
            this.showTimeSlotForm = false;
            return;
        }
        TIME_SLOT.interval.hourFrom = this.scopedTimeSlot.interval.hourFrom;
        TIME_SLOT.interval.hourTo = this.scopedTimeSlot.interval.hourTo;
        this.resourceWorkScheduleItem.timeSlots.push(TIME_SLOT);
        this.clearTimeSlotForm();
        this.showTimeSlotForm = false;
        this.isTimeValid = this.scopedTimeSlot.interval.hourTo > this.scopedTimeSlot.interval.hourFrom;
    }

    onCancelTimeSlot() {
        this.clearTimeSlotForm();
        this.showTimeSlotForm = false;
    }

    editTimeSlot(timeSlot: ResourceWorkScheduleTimeSlotType, index: number) {
        this.scopedTimeSlotForm.timeSlotIndex = index;
        // We need to force rerender hourFrom and hourTo, because if they already have values, and we want to change them they are not updated
        this.scopedTimeSlotForm.hourFrom = null;
        this.scopedTimeSlotForm.hourTo = null;
        this.changeDetectorRef.detectChanges();
        this.scopedTimeSlotForm.hourFrom = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.interval.hourFrom);
        this.scopedTimeSlotForm.hourTo = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.interval.hourTo);
        this.scopedTimeSlotForm.selectedDays = this.resourceWorkScheduleMdUtils.getTimeSlotAsSelectedDaysStringArray(timeSlot);
        this.scopedTimeSlot = lodash.cloneDeep(timeSlot);
        this.isTimeValid = this.scopedTimeSlot.interval.hourTo > this.scopedTimeSlot.interval.hourFrom;
        this.showTimeSlotForm = true;
    }

    deleteTimeSlot(index: number) {
        this.resourceWorkScheduleItem.timeSlots.splice(index, 1);
    }

    getValidateAvailabilityDateAndTimeRange(): FormValidationType {
        return this.dateTimeUtils.getValidateDateAndTimeRange(
            this.resourceWorkScheduleItem.validFrom,
            this.resourceWorkScheduleItem.validTo,
            false,
            this.resourceWorkScheduleItem.center.timeZoneId
        );
    }

    isSaveButtonEnabled() {
        return this.validateFields(this.resourceWorkScheduleItem);
    }

    goToEdit() {
        history.replaceState({
            resourceWorkSchedule: this.resourceWorkScheduleItem,
            action: this.CONSTANTS.EDIT
        }, '');
        this.ngOnInit();
    }

    private clearTimeSlotForm() {
        this.scopedTimeSlot = this.resourceWorkScheduleMdUtils.getInitialTimeSlot();
        this.scopedTimeSlotForm = this.resourceWorkScheduleMdUtils.getInitialTimeSlotForm();
    }

    // function to create the new ResourceWorkSchedule
    private createResourceWorkSchedule(resourceWorkSchedule: ResourceWorkScheduleType) {
        this.ngxLoader.start();
        this.resourceWorkScheduleProvider.addEntry(resourceWorkSchedule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newResourceWorkScheduleAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ResourceWorkSchedule
    private editResourceWorkSchedule(oldResourceWorkSchedule: ResourceWorkScheduleType, newResourceWorkSchedule: ResourceWorkScheduleType) {
        this.ngxLoader.start();
        this.resourceWorkScheduleProvider.updateEntry(oldResourceWorkSchedule, newResourceWorkSchedule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.resourceWorkScheduleEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(resourceWorkScheduleItem: ResourceWorkScheduleType): boolean {
        return !!(resourceWorkScheduleItem && resourceWorkScheduleItem.resourceId && resourceWorkScheduleItem.centerId
            && resourceWorkScheduleItem.validFrom && resourceWorkScheduleItem.validTo && this.availabilityRangeValidation.isValid
            && !this.showTimeSlotForm && resourceWorkScheduleItem.timeSlots.length > 0);
    }

    private validateTimeSlotOverlapping(editedIndex?: number): boolean {
        let TIME_SLOTS: ResourceWorkScheduleTimeSlotType[] = lodash.cloneDeep(this.resourceWorkScheduleItem.timeSlots);
        // if editedIndex is provided, it removes the timeSlot from the list before making comparisons for overlapping
        if (!this.generalUtils.isNullOrUndefined(editedIndex)) {
            TIME_SLOTS.splice(editedIndex, 1);
        }
        // checks if there is at least one timeslot with the same day as scopedTimeSlot
        const TIME_SLOT = TIME_SLOTS.find(timeSlot =>
        ((timeSlot.monday && this.scopedTimeSlot.monday) ||
            (timeSlot.tuesday && this.scopedTimeSlot.tuesday) ||
            (timeSlot.wednesday && this.scopedTimeSlot.wednesday) ||
            (timeSlot.thursday && this.scopedTimeSlot.thursday) ||
            (timeSlot.friday && this.scopedTimeSlot.friday) ||
            (timeSlot.saturday && this.scopedTimeSlot.saturday) ||
            (timeSlot.sunday && this.scopedTimeSlot.sunday)));
        // if there isn't, there's no need to check the time intervals
        if (!TIME_SLOT) {
            return true;
        }
        // if there is, checks for interval overlapping
        return !this.resourceWorkScheduleMdUtils.areIntervalsOverlapping(this.scopedTimeSlot.interval, TIME_SLOT.interval);
    }

    private setCenterTimeZoneId(centerId: string) {
        this.ngxLoader.start();
        this.centerProvider.getById(centerId, {}).subscribe(center => {
            this.resourceWorkScheduleItem.center = center;
            this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
            this.ngxLoader.stop();
        }, error => {
            this.initialFilterValues.center = [];
            this.resourceWorkScheduleItem.centerId = undefined;
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    private setInitialFormDateData() {
        this.availabilityDateAndTimeRange = this.dateTimeUtils.getDateAndTimeRangeFromString(
            this.resourceWorkScheduleItem.validFrom,
            this.resourceWorkScheduleItem.validTo,
            this.resourceWorkScheduleItem.center.timeZoneId
        );
        this.availabilityRangeValidation = this.getValidateAvailabilityDateAndTimeRange();
    }

    private setupInitialState() {
        if (history.state?.resourceWorkSchedule) {
            this.initialResourceWorkSchedule = history.state.resourceWorkSchedule;
            this.resourceWorkScheduleItem = lodash.cloneDeep(history.state.resourceWorkSchedule);
            this.initialFilterValues = {
                center: [this.resourceWorkScheduleItem.center],
                resource: [this.resourceWorkScheduleItem.resource]
            };
            this.setInitialFormDateData();
            if (history.state.action === this.CONSTANTS.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', this.CONSTANTS.VIEW, undefined, 'button.back');
            } else if (history.state.action === this.CONSTANTS.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', this.CONSTANTS.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.resourceWorkScheduleItem = this.resourceWorkScheduleMdUtils.getInitialResourceWorkSchedule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', this.CONSTANTS.CREATE, 'label.create', 'label.close');
            this.isTimeValid = this.scopedTimeSlot.interval.hourTo > this.scopedTimeSlot.interval.hourFrom;
        }
    }
}
