import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {AreaType, FilterComponentChannelType, RuleTableType} from 'sked-base';
import {TableFiltersType} from '../../../../data-model/general.type';
import {CrossAvailabilityRuleType} from 'sked-base/lib/data-model/crossAvailabilityRuleTypes';

export interface AreaModalFilterOptionsType {
    ngModel?: AreaType[];
}

export interface ChannelModalFilterOptionsType {
    ngModel?: FilterComponentChannelType[];
}

export interface EnumModalFilterOptionsType {
    ngModel?: string;
}

export interface TagModalFilterOptionsType {
    ngModel?: TagType[];
}

export interface CrossAvailabilityRuleModalFiltersType {
    areaOptions: AreaModalFilterOptionsType;
    channelOptions: ChannelModalFilterOptionsType;
    ruleScopeOptions: EnumModalFilterOptionsType;
    typeOptions: EnumModalFilterOptionsType;
    centerTagOptions: TagModalFilterOptionsType;
    coveragePlanTagOptions: TagModalFilterOptionsType;
    specialityTagOptions: TagModalFilterOptionsType;
    serviceTagOptions: TagModalFilterOptionsType;
    resourceTagOptions: TagModalFilterOptionsType;
    availabilityTagOptions: TagModalFilterOptionsType;
    patientTagOptions: TagModalFilterOptionsType;
    patientTagExceptionOptions: TagModalFilterOptionsType;
    areFiltersActive: boolean;
}

export interface CrossAvailabilityRuleStateType {
    tableFilters?: TableFiltersType;
    modalFilters?: CrossAvailabilityRuleModalFiltersType;
    ruleTableFilterItem?: RuleTableType;
}

export enum CrossAvailabilityRuleFiltersEnum {
    area = 'area',
    channel = 'channel',
    ruleScope = 'ruleScope',
    type = 'type',
    centerTag = 'centerTag',
    coveragePlanTag = 'coveragePlanTag',
    specialityTag = 'specialityTag',
    serviceTag = 'serviceTag',
    resourceTag = 'resourceTag',
    availabilityTag = 'availabilityTag',
    patientTag = 'patientTag',
    patientTagException = 'patientTagException',
}

export enum CrossAvailabilityRuleInfoMessageTypeEnum {
    Input = 'Input',
    Output = 'Output',
    NoRuleOptionCheck = 'NoRuleOptionCheck',
}

export interface CrossAvailabilityRuleInfoMessagePropertyType {
    propertyLabel: string;
    propertyName: string;
}

export interface CrossAvailabilityRuleInfoMessageOptionsType {
    label: string;
    ruleItemProperties: CrossAvailabilityRuleInfoMessagePropertyType[];
    type: CrossAvailabilityRuleInfoMessageTypeEnum;
}


export interface CrossAvailabilityRuleInfoMessageType {
    label: string;
    ruleItemProperties: CrossAvailabilityRuleInfoMessagePropertyType[];
    visible: (ruleItem: CrossAvailabilityRuleType) => boolean;
    getPropertiesNames: (ruleItem: CrossAvailabilityRuleType) => string;
}
