import {Injectable} from '@angular/core';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import {ChannelType, ODataQueryObjectType} from 'sked-base';
import * as lodash from 'lodash';
import {BackofficeAreaMDType} from './area-md.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {BackofficeChannelType} from '../../data-model/channel.types';

@Injectable({
  providedIn: 'root'
})
export class AreaMdUtil {
  tableFilters: TableFiltersType;

  constructor(public configDataService: ConfigDataService) {
  }


  getQueryFilterForAreaMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
    return {
      expand: {
        ChannelRestrictions: {select: ['Id', 'Channel']}
      },
      select: ['Id', 'ShortId', 'Name', 'Position', 'MultipleSlots', 'RequiresWorkOrder', 'RowVersion'],
      count,
      skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
      top: tableFilters.itemsPerPage,
      orderBy: this.getOrderByQuery(tableFilters.orderBy),
      filter: this.getFilterQuery(tableFilters.filter),
    };
  }

  getQueryFilterForPosition(tableFilters: TableFiltersType): ODataQueryObjectType {
    return {
      select: ['Position'],
      top: 1,
      orderBy: ['Position desc']
    };
  }

  getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
    const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
    for (const item in filter) {
      if (filter.hasOwnProperty(item)) {
        if (filter[item]) {
          filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
        }
      }
    }
    return filterQuery;
  }

  getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
    const orderByQuery: string[] = [];
    for (const item in orderBy) {
      if (orderBy.hasOwnProperty(item)) {
        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
      }
    }
    //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
    return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
  }

  getInitialTableFilter(): TableFiltersType {
    return {
      itemsPerPage: constants.itemsPerPage,
      currentPage: 1,
      filter: {name: ''},
      orderBy: {},
      expand: {}
    };
  }

  getInitialArea(): BackofficeAreaMDType {
    const area: BackofficeAreaMDType = {} as BackofficeAreaMDType;
    area.name = '';
    area.position = 0;
    area.multipleSlots = false;
    area.channelRestrictions = [];
    if (this.configDataService.isFeatureActive('admin-WorkOrder')) {
      area.requiresWorkOrder = false;
    }

    return area;
  }

  mapChannels(channels: ChannelType[]): BackofficeChannelType[] {
    const channelList: BackofficeChannelType[] = [];
    for (const channelItem of channels) {
      const backOfficeChannel: BackofficeChannelType = {} as BackofficeChannelType;
      backOfficeChannel.channel = channelItem.channel;
      backOfficeChannel.id = channelItem.id;
      backOfficeChannel.selected = false;

      channelList.push(backOfficeChannel);
    }

    return channelList;
  }

  setSelectedPropertyForChannels(channels: BackofficeChannelType[]): BackofficeChannelType[] {
    let newChannels: BackofficeChannelType[] = [];
    if (channels && channels.length > 0) {
      for (const channelItem of channels) {
        channelItem.enumValue = channelItem.channel;
        channelItem.selected = true;
      }

      newChannels = lodash.cloneDeep(channels);
    }

    return newChannels;
  }
}
