<div class="container-fluid capacity-planner create-reservation-modal">
  <div>
    <div class="custom-loader">
      <div class="modal-filter-buttons filter-buttons">
        <div *ngIf="!options?.centerInput?.hide" class="center-button sbase-input-element">
          <sbase-filter [ngClass]="{'input-element-has-error':!modalFilters.centerId}"
            (selectedItemsOutput)=" onSelectedCenterFilter($event)" [buttonName]=centerButtonName
            [dependentFilters]="mainDependentFilters.location" [initialFilterValues]="initialCenterValues"
            [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true" [providerInstance]="centerProvider"
                        [hideSelectedItems]="true"
                        class="sbase-dropdown-ml">
          </sbase-filter>
        </div>
        <!-- ============================================================== -->
        <!-- End Center Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Resource Filter -->
        <!-- ============================================================== -->
        <div class="w-50" [ngClass]="{'invisible': options?.resourceInput?.hide}">
          <sbase-filter (selectedItemsOutput)="onSelectedResourceFilter($event)" [buttonName]=resourceButtonName
            [dependentFilters]="mainDependentFilters.resource" [initialFilterValues]="initialResourceValues"
            [isMultipleSelectedItem]="false" [useSelectedValueAsLabel]="true" [providerInstance]="resourceProvider"
                        [hideSelectedItems]="true">
          </sbase-filter>
        </div>
        <!-- ============================================================== -->
        <!-- End Resource Filter -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
      </div>
      <div>
        <!-- ============================================================== -->
        <!-- Calendar Filter -->
        <!-- ============================================================== -->
        <div class="calendar-component view-calendar">
          <form class="form-inline mt-4">
            <div *ngIf="!options?.starDateInput?.hide" class="form-group w-50">
              <div class="input-group w-100">
                <input class="form-control text-high-contrast" placeholder="yyyy-mm-dd" name="dp"
                  [ngClass]="{'is-invalid': !isDateFromValid}" [(ngModel)]="modalFilters.dateFrom"
                  (ngModelChange)="onDateFromChange(modalFilters.dateFrom)" ngbDatepicker #d="ngbDatepicker" id="dp">
                <div class="input-group-append">
                  <button [class.border-danger]="!isDateFromValid"
                          [ngClass]="{'has-error': !isDateToValid}"
                          class="btn btn-outline-secondary calendar text-high-contrast"
                    (click)="d.toggle()" type="button">
                    <i class="icon-calender"></i>
                  </button>
                </div>
                <div [ngClass]="{'invisible': isDateFromValid}" class="valid-date-container">
                  <div class="valid-date mt-2" translate="label.error.invalidDate"></div>
                </div>
              </div>
            </div>
            <div *ngIf="!options?.endDateInput?.hide" class="form-group w-50">
              <div class="input-group w-100">
                <input class="form-control text-high-contrast" placeholder="yyyy-mm-dd" name="dp1"
                  [ngClass]="{'has-error': !isDateToValid || !isDiffDatesValid}" [(ngModel)]="modalFilters.dateTo"
                  (ngModelChange)="onDateToChange(modalFilters.dateTo)" ngbDatepicker #x="ngbDatepicker"
                  [maxDate]="maxDate" [minDate]="modalFilters.dateFrom">
                <div class="input-group-append">
                  <button [class.border-danger]="!isDateToValid || !isDiffDatesValid"
                          [ngClass]="{'has-error': !isDateToValid || !isDiffDatesValid}"
                          class="btn btn-outline-secondary calendar text-high-contrast"
                    (click)="x.toggle()" type="button">
                    <i class="icon-calender"></i>
                  </button>
                </div>
                <div [ngClass]="{'invisible': isDateToValid}" class="valid-date-container">
                  <div class="valid-date mt-2" translate="label.error.invalidDate"></div>
                </div>
                <div [ngClass]="{'invisible': isDiffDatesValid}" class="valid-date-container">
                  <div class="valid-date mt-2" translate="label.error.invalidDiffDate"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
          <div *ngIf="!options?.tagsInput?.hide" class="form-group w-50">
              <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                            [buttonName]='"label.newTag"'
                            [dependentFilters]="mainDependentFilters?.tags"
                            [initialFilterValues]="modalFilters?.tags"
                            [isMultipleSelectedItem]="true"
                            [useSelectedValueAsLabel]="true"
                            [hideSelectedItems]="true"
                            [providerInstance]="tagProvider">
              </sbase-filter>
          </div>

      </div>
    </div>
  </div>

</div>
<div class="text-right">
  <button (click)="makeRequestForExport(modalFilters, options?.exportType)" class="btn theme-btn"
    [disabled]="!isTableFiltersValid" translate="label.generateExport"></button>
</div>
