<div class="wait-list-modal-container">
    <div class="modal-header">
        <span class="modal-title font-weight-bold"
              translate="label.waitList">
        </span>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form name="form" novalidate (ngSubmit)="saveWaitListTask(waitListTask)" class="wait-list-modal-form">
            <!-- WARNING LABEL -->
            <label class="mb-0 has-warning wait-list-modal-form-warning" *ngIf="waitListModalUtils.isValidForTypeAutomated(waitListTask)">
                <i class="mr-2 mb-0 fas fa-exclamation-triangle"></i>{{'label.warning.warningForAutomatedOption' | translate}}</label>
            <!-- PROCESSING TYPE -->
            <div class="wait-list-modal-form-processing-type h-100">
                <mat-form-field appearance="outline">
                    <mat-select [(value)]="waitListTask.processingType"
                                matNativeControl
                                disableOptionCentering
                                required>
                        <mat-option [value]="skedTaskProcessingTypeEnum.Manual">
                            <span translate="enum.taskProcessingType.Manual"></span>
                        </mat-option>
                        <mat-option [value]="skedTaskProcessingTypeEnum.Automated" [featureDisplay]="'skedtask-waitlistautomated'">
                            <span translate="enum.taskProcessingType.Automated"></span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- SERVICE FILTER -->
            <div class="h-100 sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                              [buttonName]='"label.service2"'
                              [dependentFilters]="waitListModalUtils.waitListTaskOptions?.filters?.service?.dependentFilter"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="serviceProvider"
                              [customTemplate]="serviceTemplate"
                              [ngClass]="{'input-element-has-error':!waitListTask.serviceId}"
                              [initialFilterValues]="waitListModalUtils.waitListTaskOptions.filters.service.initialFilter">
                </sbase-filter>
                <!-- SERVICE FILTER TEMPLATE -->
                <ng-template let-item #serviceTemplate>
                        <span *ngIf="!!item.specialityName">
                            <span class="font-weight-bold">{{item.specialityName}} </span>
                        </span>
                    <!-- in select -->
                    <span *ngIf="!item.nameWithoutSpeciality">
                            <span>{{item.name}}</span>
                        </span>
                    <!-- in dropdown -->
                    <span *ngIf="!!item.nameWithoutSpeciality">
                            <span>{{item.nameWithoutSpeciality}}</span>
                        </span>
                </ng-template>
            </div>
            <!-- RESOURCE FILTER -->
            <div class="h-100 sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                              [buttonName]='"label.resource"'
                              [dependentFilters]="waitListModalUtils.waitListTaskOptions?.filters?.resource?.dependentFilter"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="resourceProvider"
                              [ngClass]="{'input-element-has-error': waitListModalUtils.isValidForTypeAutomated(waitListTask)}"
                              [initialFilterValues]="waitListModalUtils.waitListTaskOptions.filters.resource.initialFilter">
                </sbase-filter>
            </div>
            <!-- CENTER FILTER -->
            <div class="h-100 sbase-input-element">
                <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                              [buttonName]='"label.center2"'
                              [dependentFilters]="waitListModalUtils.waitListTaskOptions?.filters?.center?.dependentFilter"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="centerProvider"
                              [ngClass]="{'input-element-has-error':waitListModalUtils.isValidForTypeAutomated(waitListTask)}"
                              [initialFilterValues]="waitListModalUtils.waitListTaskOptions.filters.center.initialFilter">
                </sbase-filter>
            </div>
            <!-- DUE DATE-->
            <div class="wait-list-modal-form-date h-100">
                <label translate="label.dueDate"></label>
                <input class="input-date-calendar"
                       name="dpfrom1"
                       readonly
                       [minDate]="waitListModalUtils.waitListTaskOptions.minDate"
                       [(ngModel)]="waitListModalUtils.waitListTaskOptions.selectedDate"
                       ngbDatepicker #datePickerFrom="ngbDatepicker"
                       (click)="datePickerFrom.toggle()"
                       (dateSelect)="onSelectDueDate($event)">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary input-date-calendar-icon"
                            (click)="datePickerFrom.toggle()"
                            type="button">
                    </button>
                </div>
            </div>
            <!-- AM / PM -->
            <div class="wait-list-modal-am-pm">
                <label>&nbsp;</label>
                <div class="d-flex align-items-center justify-content-center">
                    <label class="mr-2 mb-0 d-flex align-items-center cursor-pointer">
                        <input type="checkbox" name="am"
                               [(ngModel)]="waitListModalUtils.waitListTaskOptions.am"
                               (ngModelChange)="onPreferredIntervalChanged()"/>
                        <span class="ml-2" translate="label.am"></span>
                    </label>
                    <label class="mb-0 d-flex align-items-center cursor-pointer">
                        <input type="checkbox" name="pm"
                               [(ngModel)]="waitListModalUtils.waitListTaskOptions.pm"
                               (ngModelChange)="onPreferredIntervalChanged()"/>
                        <span class="ml-2" translate="label.pm"></span>
                    </label>
                </div>
            </div>
            <!-- OBSERVATIONS -->
            <div class="wait-list-modal-form-observations">
                <label translate="label.observations"></label>
                <textarea name="observations" [rows]="3" [(ngModel)]="waitListTask.observations"
                          class="d-block w-100"></textarea>
            </div>
            <!-- BUTTONS -->
            <div class="mt-3">
                <button class="btn close-btn mr-3"
                        type="button"
                        translate="label.close"
                        (click)="activeModal.close()">
                </button>
                <button class="btn theme-btn"
                        type="submit"
                        translate="button.save"
                        [disabled]="!waitListModalUtils.areFiltersValidBasedOnSelectedTaskType(waitListTask)"
                        [ngClass]="{'disabled-button': !waitListModalUtils.areFiltersValidBasedOnSelectedTaskType(waitListTask)}">
                </button>
            </div>
        </form>
    </div>
</div>
