import {Injectable} from '@angular/core';
import {PatientReviewFilter, PatientSearchResultExtendedType, PatientType} from 'sked-base/lib/data-model/patientTypes';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PatientAuditUtil {
    constructor() {
    }

    getInitialQueryFilter(): PatientReviewFilter {
        return {
            searchPhrase: '',
            documentCountry: '',
            documentNumber: '',
            documentType: ''
        };
    }

    hasAtLeastOneFilterValue(searchBy: PatientReviewFilter) {
        return !(lodash.isEmpty(searchBy.searchPhrase) &&
            lodash.isEmpty(searchBy.documentCountry) &&
            lodash.isEmpty(searchBy.documentNumber) &&
            lodash.isEmpty(searchBy.documentType));
    }

    removePatient(patients: PatientSearchResultExtendedType[], anonymizedPatient: PatientType) {
        lodash.remove(patients, (patient) => {
            return patient.id === anonymizedPatient.id;
        });

        return patients;
    }
}
