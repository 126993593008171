import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    ViewChild,
    ChangeDetectorRef,
    AfterViewChecked
} from '@angular/core';
import {TableFiltersType} from 'src/app/data-model/general.type';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActionExecutionLogDetailProvider,
    ActionExecutionLogDetailType,
    AreaProvider,
    DateRangeResponseType,
    EventActionTypeEnum,
    RelatedEntityTypeEnum,
    ActionExecutionStatusEnum,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {ActionExecutionLogsMdUtils} from './action-execution-logs-md-util';
import {
    ActionExecutionLogsModalFiltersType,
    ActionTypeFilterOptionsType,
    RelatedEntityIdFilterOptionType,
    RelatedEntityFilterOptionsType,
    StatusFilterOptionsType, ActionExecutionLogsFiltersType
} from './action-execution-logs-md.types';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {
    ShowTemplateModalFilterContainerComponent
} from 'sked-base/lib/components/template-modal-filter-container/show-template-modal-filter-container.component';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-action-execution-logs-md',
    templateUrl: './action-execution-logs-md.component.html',
    styleUrls: ['./action-execution-logs-md.component.scss']
})

export class ActionExecutionLogsMDComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    @ViewChild('filtersModal') modal: ShowTemplateModalFilterContainerComponent;
    tableFilters: TableFiltersType = this.actionExecutionLogsMdUtils.getInitialTableFilter();
    itemsPerPageList: number[] = [10, 25, 50, 100];
    actionExecutionLogsMdList: ActionExecutionLogDetailType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    constants = constants;
    modalFilters: ActionExecutionLogsModalFiltersType = {} as ActionExecutionLogsModalFiltersType;
    filters: ActionExecutionLogsFiltersType = {} as ActionExecutionLogsFiltersType;
    previousModalFilters: ActionExecutionLogsModalFiltersType = {} as ActionExecutionLogsModalFiltersType;
    ActionTypeList = Object.values(EventActionTypeEnum);
    RelatedEntityTypeList = Object.values(RelatedEntityTypeEnum);
    ActionExecutionStatusList = Object.values(ActionExecutionStatusEnum);
    areFiltersValid = true;
    isRelatedEntityIdValid = true;
    isRelatedEntityTypeValid = true;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        private actionExecutionLogsMdUtils: ActionExecutionLogsMdUtils,
        public actionExecutionLogsProvider: ActionExecutionLogDetailProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        private dateTimeUtils: DateTimeUtils,
        public areaProvider: AreaProvider,
        public changeDetector: ChangeDetectorRef,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.filters = this.getInitialFilters();
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
        this.modalFilters = this.getInitialModalFilters();
        this.modalFilters.areFiltersActive = this.isAnyFilterActive();
        this.tableFilters.filter.createdOn = this.getCreatedOnInterval();
        this.tableFilters.orderBy.createdOn = 'desc';
        this.previousModalFilters = lodash.clone(this.modalFilters);
        this.loadActionExecutionLogsMdData();
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }

    areEntityTypeFiltersValid(): boolean {
        this.isRelatedEntityTypeValid = Boolean(this.filters.relatedEntityId.ngModel)
            || Boolean(!this.filters.relatedEntityId.ngModel && !this.filters.relatedEntityTypeModalFilterOptions.ngModel);

        this.isRelatedEntityIdValid = Boolean(this.filters.relatedEntityTypeModalFilterOptions.ngModel)
            || Boolean(!this.filters.relatedEntityId.ngModel && !this.filters.relatedEntityTypeModalFilterOptions.ngModel);

        return this.isRelatedEntityIdValid && this.isRelatedEntityTypeValid;
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tableFilters.currentPage = page;
        this.loadActionExecutionLogsMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadActionExecutionLogsMdData(false);
    }

    onSortBy(property: string) {
        const isAscendingMode = this.tableFilters.orderBy[property];
        if (this.tableFilters.orderBy) {
            this.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadActionExecutionLogsMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.tableFilters.orderBy[selectedItem];
        this.loadActionExecutionLogsMdData(false);
    }

    applyInputFilter(option: string) {
        lodash.debounce(() => this.applyFilter(option), constants.inputDebounceTime)();
    }

    applyFilter(option: string, actionTypeFilterOptions?: string) {
        if (actionTypeFilterOptions) {
            this.sanitizeNgSelectValue(option, actionTypeFilterOptions);
        }

        this.areFiltersValid = this.areEntityTypeFiltersValid();

        if (this.areFiltersValid) {
            this.handleFilterChangeAndLoadData(true);
        }
    }

    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue') || option === 'all') {
            this.filters[property].ngModel = undefined;
        }
    }

    handleFilterChangeAndLoadData(filtersChanged: boolean) {
        if (filtersChanged) {
            this.previousModalFilters = lodash.cloneDeep(this.modalFilters);
            this.tableFilters.filter.actionType = this.filters.actionTypeFilterOptions.ngModel;
            this.tableFilters.filter.status = this.filters.statusFilterOptions.ngModel;
            this.tableFilters.filter.relatedEntityId = this.filters.relatedEntityId.ngModel;
            this.tableFilters.filter.relatedEntityType = this.filters.relatedEntityTypeModalFilterOptions.ngModel;
            this.tableFilters.filter.createdOn = this.getCreatedOnInterval();
            this.loadActionExecutionLogsMdData(true);
            this.reloadDateRangeAndPreserveValues();
        } else {
            this.modalFilters = lodash.cloneDeep(this.previousModalFilters);
        }
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        if (dateRange.isValid) {
            this.modalFilters.dateRangeOptions.fromDate = dateRange.fromDate;
            this.modalFilters.dateRangeOptions.toDate = dateRange.toDate;
        }
    }

    onResetFilters() {
        this.modalFilters = this.getInitialModalFilters();
        this.modalFilters.areFiltersActive = this.isAnyFilterActive();
        this.filters = this.getInitialFilters();
        this.tableFilters = this.actionExecutionLogsMdUtils.getInitialTableFilter();
        this.tableFilters.filter.createdOn = this.getCreatedOnInterval();
        this.isRelatedEntityTypeValid = true;
        this.isRelatedEntityIdValid = true;
        this.areFiltersValid = true;
        this.handleFilterChangeAndLoadData(true);
    }

    getShortenedText(text: string): string {
        return text?.length >= 20
            ? text.substr(0, 20)
            : text;
    }

    displayChangeLog(id: string, actionType: string): void {
        this.changeLogModalService.displayModal(id, actionType);
    }

    private loadActionExecutionLogsMdData(includeCount: boolean = true) {
        const queryFilter = this.actionExecutionLogsMdUtils.getQueryFilterForActionExecutionLogsMD(this.tableFilters, includeCount);
        this.ngxLoader.start();
        this.actionExecutionLogsProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.actionExecutionLogsMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private getInitialModalFilters(): ActionExecutionLogsModalFiltersType {
        return {
            dateRangeOptions: this.actionExecutionLogsMdUtils.getDefaultDateRange(),
            areFiltersActive: false
        } as ActionExecutionLogsModalFiltersType;
    }

    private getInitialFilters(): ActionExecutionLogsFiltersType {
        return {
            actionTypeFilterOptions: {
                ngModel: undefined as ActionTypeFilterOptionsType
            } as ActionTypeFilterOptionsType,
            statusFilterOptions: {
                ngModel: undefined as StatusFilterOptionsType
            } as StatusFilterOptionsType,
            relatedEntityId: {
                ngModel: undefined as RelatedEntityIdFilterOptionType
            } as RelatedEntityIdFilterOptionType,
            relatedEntityTypeModalFilterOptions: {
                ngModel: undefined as RelatedEntityFilterOptionsType
            } as RelatedEntityFilterOptionsType
        } as ActionExecutionLogsFiltersType;
    }

    private getCreatedOnInterval() {
        let createdOnInterval;

        if (this.modalFilters.dateRangeOptions.fromDate && this.modalFilters.dateRangeOptions.toDate) {
            createdOnInterval = {
                fromDate: this.dateTimeUtils.convertNgbDateToDate(this.modalFilters.dateRangeOptions.fromDate),
                toDate: this.dateTimeUtils.convertNgbDateToDateAddOneDay(this.modalFilters.dateRangeOptions.toDate)
            };
        }

        return createdOnInterval;
    }

    private isAnyFilterActive(): boolean {
        return !!this.modalFilters?.dateRangeOptions?.toDate && !!this.modalFilters?.dateRangeOptions?.fromDate;
    }

    private reloadDateRangeAndPreserveValues() {
        this.modalFilters = this.getInitialModalFilters();
        this.modalFilters.areFiltersActive = this.isAnyFilterActive();
        this.modalFilters.dateRangeOptions.fromDate = this.previousModalFilters.dateRangeOptions.fromDate;
        this.modalFilters.dateRangeOptions.toDate = this.previousModalFilters.dateRangeOptions.toDate;
    }

    copyText(text: string) {
        this.generalUtils.copyTextToClipboard(text)
            .then((successfullyCopied) => {
                if (successfullyCopied) {
                    this.messagesService.success('toastr.success.textSuccessfullyCopied');

                } else {
                    this.messagesService.error('toastr.error.errorCopyingText');
                }
            })
            .catch((error) => {
                this.messagesService.error(`toastr.error.errorCopyingText ${error}`);
            });
    }
}
