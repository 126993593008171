import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ObjectDetailsActionType, ObjectDetailsOptionsType} from 'sked-base/lib/components/object-details/object-details.types';

@Component({
    selector: 'app-object-details-modal',
    templateUrl: './object-details-modal.component.html',
    styleUrls: ['./object-details-modal.component.scss']
})
export class ObjectDetailsModalComponent implements OnInit {
    @Input() options: ObjectDetailsOptionsType;
    displayNotFoundMessage: boolean;

    spinnerId = 'appointment-object-details';

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    ngOnInit(): void {
        this.displayNotFoundMessage = false;
    }

    onObjectDetailsAction(objectDetailsInfo: ObjectDetailsActionType) {
        if (!objectDetailsInfo.areObjectDetailsFound) {
            this.displayNotFoundMessage = true;
        }
    }
}
