import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {PatientAuditComponent} from './patient-audit.component';

@NgModule({
    declarations: [PatientAuditComponent],
    imports: [
        CommonModule,
        SharedModule,
    ]
})
export class PatientAuditModule {
}
