<div class="slot-without-planned-capacity-modal">
    <div class="modal-header">
        <h6 class="modal-title font-weight-bold" *ngIf="!showAppointmentType" translate="label.selectStartTime"></h6>
        <h6 class="modal-title font-weight-bold" *ngIf="showAppointmentType" translate="label.selectAppointmentTypeAndStartTime"></h6>
    </div>
    <div class="modal-body">
        <div class="form-group" *ngIf="showAppointmentType">
            <label class="font-weight-bold font-14" translate="label.appointmentType"></label>
            <ng-select class="form-control pl-0 rounded-0 font-weight-normal p-0 custom-ng-select"
                       dropdownPosition="bottom"
                       placeholder="{{'label.choose' | translate}}"
                       [multiple]="false"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModelOptions]="{standalone: true}"
                       [ngClass]="{'has-error': !options.appointmentType}"
                       [(ngModel)]="options.appointmentType"
                       (ngModelChange)="sanitizeAppointmentTypeValue()">
                <ng-option [value]="undefined"><span translate="label.selectAppointmentType"></span></ng-option>
                <ng-option *ngFor="let appointmentType of options.appointmentTypes"
                           [value]="appointmentType">
                    {{appointmentType.name}}
                </ng-option>
            </ng-select>

            <div *ngIf="!options.appointmentType" class="help-block">
                <span translate="label.error.required"></span>
            </div>
        </div>
        <div class="form-group">
            <label class="font-weight-bold font-14" translate="label.selectTimeInInterval">
                <span class="time-interval ml-1">{{options.timeInterval}}</span></label>
            <ngb-timepicker [(ngModel)]="time"
                            (ngModelChange)="onTimeChanged($event)"
                            [ngClass]="{'has-error': !isTimeValid}"
                            [disabled]="!options.appointmentType"
                            [spinners]="false"
                            [hourStep]="1"
                            [minuteStep]="options.appointmentType?.duration"></ngb-timepicker>
            <div class="row pl-3" *ngIf="!isTimeValid">
                <span class="has-error" translate="label.error.timeIsNotInInterval"></span>
            </div>
            <div class="row pl-3" *ngIf="isTimeValid">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn"
                (click)="onCloseModal()" translate="label.close">
        </button>
        <button type="button"
                class="btn theme-btn border-0 mr-3"
                translate="label.save"
                [disabled]="!isTimeValid || !options.appointmentType"
                (click)="onSaveModal()">
        </button>
    </div>
</div>
