<div class="container-fluid task-item-details-wrapper overview-screen-md pb-1 list-item-details-wrapper" *ngIf="displayPage">
    <!--Header-->
    <!-- =========================================== -->
    <div class="d-flex no-block justify-content-between align-items-center mb-3">
        <h3 translate="label.taskDetails"></h3>
        <i class="fas fa-times cursor-pointer mr-3" (click)="closeTaskItemDetails()"></i>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row task-item-details" *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.id">
        <div class="col-6 custom-scrollbar overflow-auto h-100"
             [ngClass]="{'col-12': taskListUtils?.taskItemDetailsOptions?.taskItem?.type !== SkedTaskTypeEnum.WorkOrder}">
            <!-- APPOINTMENT INFORMATION -->
            <div *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.appointmentId">
                <label translate="label.appointmentInformation" class="text-dark font-weight-bold"></label>
                <table class="table border">
                    <tbody>
                    <tr *ngIf="this.taskListUtils?.taskItemDetailsOptions?.taskItem?.appointment?.status ||
                               this.taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom ||
                               this.taskListUtils?.taskItemDetailsOptions?.appointment?.duration">
                        <td>
                            <span id="appointmentDateLabel"
                                    *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom">{{'label.datePlaceholder' | translate}}</span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom">, </span>
                            <span id="appointmentTimeLabel"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom">{{'label.time' | translate}}</span>
                            <span>, </span>
                            <span id="appointmentStatusLabel"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.appointment?.status">{{'label.status' | translate}}</span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.duration">, </span>
                            <span id="appointmentDurationLabel"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.duration">{{'label.duration' | translate}}</span>
                        </td>
                        <td class="text-right">
                            <span id="appointmentDateTimeFromContent"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom">
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom | fullDateFormatPipe}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.dateTimeFrom">, </span>
                            <span id="appointmentStatusContent"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.appointment?.status">
                                {{'enum.appointmentStatus.' + taskListUtils?.taskItemDetailsOptions?.taskItem?.appointment?.status | translate}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.duration">, </span>
                            <span id="appointmentDurationContent"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.duration">
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.duration}} {{'label.minuteShort' | translate}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.appointmentType?.name">
                        <td translate="label.appointmentType"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.appointment?.appointmentType?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.fullName || taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.documentNumberFormatted">
                        <td translate="label.patientName"></td>
                        <td class="text-right">
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.fullName">
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.fullName}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.documentNumberFormatted">, </span>
                            <span id="appointmentPatientDocumentNumber"
                                  *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.documentNumberFormatted">
                                 <i class="fa fa-id-card document-number-formatted-icon"
                                    *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.documentNumberFormatted">
                                </i>&nbsp;
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.documentNumberFormatted}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.email">
                        <td translate="label.patientEmail"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.email}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.mainPhoneNumber || taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.alternatePhoneNumber">
                        <td translate="label.patientPhoneNumbers"></td>
                        <td class="text-right">
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.mainPhoneNumber">
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.mainPhoneNumber}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.alternatePhoneNumber"> | </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.alternatePhoneNumber">
                                {{taskListUtils?.taskItemDetailsOptions?.appointment?.patient?.alternatePhoneNumber}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskItemAppointmentResources?.mainResource">
                        <td translate="label.resource"></td>
                        <td class="text-right">
                             <span>
                                {{taskItemAppointmentResources?.mainResource?.resource?.name}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskItemAppointmentResources?.secondaryResources?.length > 0">
                        <td translate="label.secondaryResources"></td>
                        <td class="text-right">
                            <div *ngFor="let resource of taskItemAppointmentResources?.secondaryResources last as isLast"
                                 class="d-inline-block">
                            <span>
                                {{resource?.resource?.name}}<span *ngIf="!isLast">, </span>
                            </span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.service?.name">
                        <td translate="label.service2"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.appointment?.service?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.subServices?.length > 0">
                        <td translate="label.subServices"></td>
                        <td class="text-right">
                        <span *ngFor="let subService of taskListUtils?.taskItemDetailsOptions?.appointment?.subServices; let isLast = last">
                            <span *ngIf="subService?.name; else subServiceSubServiceName">
                                {{subService?.name}}
                            </span>
                            <ng-template #subServiceSubServiceName>
                                {{subService?.subService?.name}}
                            </ng-template>
                            <span *ngIf="!isLast">, </span>
                        </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.center?.name">
                        <td translate="label.center2"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.appointment?.center?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointment?.coveragePlan?.name">
                        <td translate="label.coveragePlan2"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.appointment?.coveragePlan?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.appointmentComments?.length > 0">
                        <td translate="label.comments"></td>
                        <td class="text-right w-75">
                        <span *ngFor="let comment of taskListUtils?.taskItemDetailsOptions?.appointmentComments last as isLast"
                              class="text-justify"
                              [ngClass]="{'d-block mb-2': !isLast}">
                            {{comment?.content}}
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!-- TASK INFORMATION -->
            <div *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem"
                 class="task-item-details-task-information-container">
                <label translate="label.taskInformation" class="text-dark font-weight-bold"></label>
                <app-display-change-log-icon
                    [EntityId]="taskListUtils?.taskItemDetailsOptions?.taskItem?.id"
                    [EntityType]="EntityTypeEnum.skedTask"
                ></app-display-change-log-icon>
                <table class="table border">
                    <tbody>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.dueDate">
                        <td translate="label.dueDate"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.dueDate | fullDateNoTimeFormatPipe}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.createdOn || taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn">
                        <td>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.createdOn"
                                  translate="label.dateCreated"></span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn">, </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn"
                                  translate="label.lastModified"></span>
                        </td>
                        <td class="text-right">
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.createdOn">
                                {{taskListUtils?.taskItemDetailsOptions?.taskItem?.createdOn | dateBrowserTzFormat}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn">, </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn">
                                {{taskListUtils?.taskItemDetailsOptions?.taskItem?.modifiedOn | dateBrowserTzFormat}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.type">
                        <td translate="label.taskType"></td>
                        <td class="text-right">
                            <span>{{"enum.taskType." + taskListUtils?.taskItemDetailsOptions?.taskItem?.type | translate}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils.doesTaskHavePreferredInterval(taskListUtils?.taskItemDetailsOptions?.taskItem?.preferredInterval)">
                        <td translate="label.preferredInterval"></td>
                        <td class="text-right">
                        <span>
                            {{taskListUtils.getPreferredIntervalDisplayValue(taskListUtils?.taskItemDetailsOptions?.taskItem?.preferredInterval)}}
                        </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.fullName">
                        <td translate="label.patientName"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.fullName}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentNumber
                        || taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentType
                        || taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentCountry">
                        <td class="col-6">
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentType">{{'label.documentType' | translate}}</span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentNumber">,&nbsp;{{'label.documentNumber' | translate}}</span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentCountry">,&nbsp;{{'label.country' | translate}}</span>
                        </td>
                        <td class="text-right col-6">
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentType">
                                {{'enum.documentType.' + taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentType | translate}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentNumber">
                                ,&nbsp;{{taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentNumber}}
                            </span>
                            <span *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentCountry">
                                ,&nbsp;{{taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.documentCountry | translate}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.email">
                        <td translate="label.patientEmail"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.email}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.mainPhoneNumber">
                        <td translate="label.patientPhone"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.patient?.mainPhoneNumber}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.processingType">
                        <td translate="label.processingType"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.processingType | translate}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.center?.name">
                        <td translate="label.center"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.center?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.resource?.name">
                        <td translate="label.resource"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.resource?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.service?.name">
                        <td translate="label.service2"></td>
                        <td class="text-right">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.service?.name}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.subServices?.length > 0">
                        <td translate="label.subServices"></td>
                        <td class="text-right">
                        <span *ngFor="let subService of taskListUtils?.taskItemDetailsOptions?.taskItem?.subServices; let isLast = last">
                            <span *ngIf="subService?.name; else subServiceSubServiceName">
                                {{subService?.name}}
                            </span>
                            <ng-template #subServiceSubServiceName>
                                {{subService?.subService?.name}}
                            </ng-template>
                            <span *ngIf="!isLast">, </span>
                        </span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.observations">
                        <td translate="label.observations"></td>
                        <td class="text-right w-75">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.observations}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterSource">
                        <td translate="label.requesterSource"></td>
                        <td class="text-right w-75">
                            <span>{{"enum.requesterSource." + taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterSource | translate}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterId && taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterSource === requesterSourceEnum.External">
                        <td translate="label.requesterId"></td>
                        <td class="text-right w-75">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterId}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterText">
                        <td translate="label.requesterText"></td>
                        <td class="text-right w-75">
                            <span>{{taskListUtils?.taskItemDetailsOptions?.taskItem?.requesterText}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- UPLOADS -->
        <div class="col-6 uploads-column h-100" *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.id">
            <label translate="label.uploads" class="text-dark font-weight-bold"></label>
            <app-pdf-image-viewer *ngIf="!!taskListUtils?.taskItemDetailsOptions?.uploadsViewerOptions"
                                  [options]="taskListUtils.taskItemDetailsOptions.uploadsViewerOptions">
            </app-pdf-image-viewer>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer mt-1 d-flex no-block flex-wrap" *ngIf="taskListUtils?.taskItemDetailsOptions?.taskItem?.id">
        <button type="button"
                class="btn close-btn mr-2 mt-2" translate="label.close"
                (click)="closeTaskItemDetails()">
        </button>
        <app-task-item-actions-buttons
                class="d-flex no-block flex-wrap"
                [options]="{taskListItem: taskListUtils.taskItemDetailsOptions.taskItem}"
                (taskItemAction)="onTaskItemAction($event)">
        </app-task-item-actions-buttons>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
