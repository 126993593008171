import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { AppointmentTypeMDComponent } from './appointment-type-md.component';
import { CreateAppointmentTypeComponent } from './create-appointment-type/create-appointment-type.component';

@NgModule({
  declarations: [AppointmentTypeMDComponent, CreateAppointmentTypeComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
  ]
})
export class AppointmentTypeMDModule { }
