import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {SpecialityMDComponent} from './speciality-md.component';
import {CreateSpecialityComponent} from './create-speciality/create-speciality.component';

@NgModule({
    declarations: [SpecialityMDComponent, CreateSpecialityComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class SpecialityMDModule {
}
