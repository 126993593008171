import {
    CenterOverviewFiltersType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    CenterOverviewType,
    ResourceTimeSlotsType,
    ResourceTimeSlotsFiltersType,
    ResourceSearchType,
    ReservationInstanceType,
    ResourceTypeType, BaseReservationTimeSlotType
} from 'sked-base';
import {Injectable} from '@angular/core';
import {CalendarResourceType, ConflictInstances, RoomReservationFilterType} from './room-reservation.types';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {IntervalType} from 'sked-base/lib/data-model/intervalTypes';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CalendarOptions} from '@fullcalendar/core';
import {DateMarker} from '@fullcalendar/core';
import {constants} from '../../shared/constants/constants';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {roomReservationConstants} from './room-reservation.constants';
import {ConfigDataService} from '../../shared/services/config-data.service';

@Injectable({
    providedIn: 'root'
})
export class RoomReservationUtils {
    constructor(public dateTimeUtils: DateTimeUtils,
                private configDataService: ConfigDataService) {
    }

    getInitialTableFilters(): RoomReservationFilterType {
        const filters: RoomReservationFilterType = {} as RoomReservationFilterType;
        const currentDay = new Date();
        filters.roomOrResourceOverview = roomReservationConstants.ROOM_OVERVIEW;
        filters.center = null;
        filters.resource = null;
        filters.room = null;
        filters.currentDate = this.dateTimeUtils.convertDateInNgbDateStruct(currentDay);
        filters.showFullyBookedRooms = false;
        filters.viewType = roomReservationConstants.WEEKLY;
        filters.startDate = this.getStartDate(filters.currentDate, filters.viewType);
        filters.endDate = this.getEndDate(filters.currentDate, filters.viewType);
        filters.tags = [];

        return filters;
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })


    checkIfTimeSlotIsOverlapping(resourceTimeSlot: IntervalType, calendarTimeSlot: IntervalType): boolean {
        // return (resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom || (resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom &&
        // resourceTimeSlot.hourFrom < calendarTimeSlot.hourTo)) || (resourceTimeSlot.hourTo > calendarTimeSlot.hourFrom ||
        // (resourceTimeSlot.hourTo > calendarTimeSlot.hourFrom && resourceTimeSlot.hourTo <= calendarTimeSlot.hourTo));
        return ((resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom && resourceTimeSlot.hourFrom < calendarTimeSlot.hourTo) ||
            (resourceTimeSlot.hourTo > calendarTimeSlot.hourFrom && resourceTimeSlot.hourTo < calendarTimeSlot.hourTo)) ||
            (resourceTimeSlot.hourFrom <= calendarTimeSlot.hourFrom && resourceTimeSlot.hourTo >= calendarTimeSlot.hourTo);
    }

    getCenterOverviewRequestFilter(filter: RoomReservationFilterType): CenterOverviewFiltersType {
        const requestFilter: CenterOverviewFiltersType = {} as CenterOverviewFiltersType;
        requestFilter.centerId = filter.center.id;
        requestFilter.dateFrom = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.startDate)).format();
        requestFilter.dateTo = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.endDate)).format();
        requestFilter.tags = filter.tags.length > 0 ? lodash.map(filter.tags, 'id') : [];
        requestFilter.resourceId = filter.resource ? filter.resource.id : null;
        requestFilter.roomId = filter.room ? filter.room.id : null;
        return requestFilter;
    }

    getResourceTimeSlotsRequestFilter(filter: RoomReservationFilterType): ResourceTimeSlotsFiltersType {
        const requestFilter: ResourceTimeSlotsFiltersType = {} as ResourceTimeSlotsFiltersType;
        requestFilter.centerId = filter.center.id;
        requestFilter.resourceId = filter.resource ? filter.resource.id : '';
        requestFilter.dateFrom = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.startDate)).format();
        requestFilter.dateTo = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.endDate)).format();
        return requestFilter;
    }

    getResourcesForRoomsCalendar(response: CenterOverviewType[]): CalendarResourceType[] {
        const rooms: CalendarResourceType[] = [];
        for (const item of response) {
            rooms.push(
                {
                    id: item.roomId,
                    name: item.name,
                    isResourceHaveReservation: item.resourceHaveReservations
                });
        }
        return rooms;
    }

    getResourceCalendarEvents(response: ResourceTimeSlotsType[], resource: ResourceSearchType): any[] {
        const events: any[] = [];
        const isCreateReservationActive = (this.configDataService.activeActivities.indexOf('CreateReservation') > -1);
        for (const item of response) {
            for (const timeSlot of item.resourceTimeSlots) {
                const event: any = {};
                const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                event.start = this.dateTimeUtils.getStartOfDayIgnoringOffset(item.date); //date will be in PC offset
                event.end = this.dateTimeUtils.getStartOfDayIgnoringOffset(item.date); //date will be in PC offset
                event.editable = false;
                event.classNames = ['custom-event-style', timeSlot.status + '-style'];
                event.resourceEditable = (timeSlot.status === constants.TO_BE_ASSIGNED) &&
                    !this.isEventInPast(event.start, timeSlot.hourTo) && isCreateReservationActive;
                event.resourceId = resource.id;
                event.title = hourFromDate + ' - ' + hourToDate;
                event.allDay = false;
                if (!event.resourceEditable) {
                    event.classNames.push('remove-click-event');
                }
                //extended params
                event.eventDate = item.date;
                event.hourFrom = timeSlot.hourFrom;
                event.hourTo = timeSlot.hourTo;
                event.hourFromDate = hourFromDate;
                event.hourToDate = hourToDate;
                event.status = timeSlot.status;
                event.resource = {};
                event.resource.name = resource.name;
                event.resource.id = resource.id;
                event.timeSlotId = timeSlot.timeSlotId;
                event.availabilityId = timeSlot.availabilityId;
                events.push(event);
            }
        }
        return events;
    }

    getRoomsCalendarEvents(response: CenterOverviewType[], selectedResource: ResourceSearchType): any[] {
        const events: any[] = [];
        const isMoveReservationActive = (this.configDataService.activeActivities.indexOf('MoveReservation') > -1);
        for (const item of response) {
            for (const date of item.dates) {
                for (const timeSlot of date.timeSlots) {
                    const event: any = {};
                    const isSelectedResource = (timeSlot.resourceId && selectedResource && timeSlot.resourceId === selectedResource.id);
                    const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    event.start = this.dateTimeUtils.getStartOfDayIgnoringOffset(date.dateTimeOffset); //date will be in PC offset
                    event.end = this.dateTimeUtils.getStartOfDayIgnoringOffset(date.dateTimeOffset); //date will be in PC offset
                    event.editable = false;
                    event.resourceEditable = isSelectedResource && !this.isEventInPast(event.start, timeSlot.hourTo) && isMoveReservationActive;
                    event.title = timeSlot.resourceName + ' ' + hourFromDate + ' - ' + hourToDate;
                    event.resourceId = item.roomId;
                    //extended params
                    event.status = constants.RESERVED;

                    event.classNames = ['custom-event-style', event.status + '-style'];
                    if (isSelectedResource) {
                        event.classNames.push('current-resource');
                    }
                    event.eventDate = date.dateTimeOffset;
                    event.hourFrom = timeSlot.hourFrom;
                    event.hourTo = timeSlot.hourTo;
                    event.hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    event.hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    event.resource = {};
                    event.resource.id = timeSlot.resourceId;
                    event.resource.type = timeSlot.resourceType;
                    event.resource.name = timeSlot.resourceName;
                    event.reservationId = timeSlot.reservationId;
                    event.modifiedOn = timeSlot.modifiedOn;
                    event.modifiedBy = timeSlot.modifiedBy;
                    event.isPartOfSeries = timeSlot.isPartOfSeries;
                    events.push(event);
                }
                //blocked time slots
                for (const timeSlot of date.exclusionTimeSlots) {
                    const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    const blockedEvent: any = {};
                    blockedEvent.start = this.dateTimeUtils.getStartOfDayIgnoringOffset(date.dateTimeOffset); //date will be in PC offset
                    blockedEvent.end = this.dateTimeUtils.getStartOfDayIgnoringOffset(date.dateTimeOffset); //date will be in PC offset
                    blockedEvent.title = hourFromDate + ' - ' + hourToDate;
                    // blockedEvent.start = moment.parseZone(date.dateTimeOffset).toDate();
                    // blockedEvent.end = moment.parseZone(date.dateTimeOffset).toDate();
                    blockedEvent.editable = false;
                    blockedEvent.resourceEditable = false;
                    blockedEvent.resourceId = item.roomId;
                    blockedEvent.status = constants.BLOCKED; //extended parameter
                    blockedEvent.classNames = ['custom-event-style', blockedEvent.status + '-style', 'remove-click-event'];
                    if (timeSlot.hourTo - timeSlot.hourFrom === 1440) {
                        blockedEvent.title = '';
                        blockedEvent.overlap = false;
                        blockedEvent.allDay = true;
                        blockedEvent.display = 'background';
                        blockedEvent.classNames.push('blocked-whole-day');
                    }
                    //extended parameters
                    blockedEvent.hourFrom = timeSlot.hourFrom;
                    blockedEvent.hourTo = timeSlot.hourTo;
                    blockedEvent.hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    blockedEvent.hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    events.push(blockedEvent);
                }

            }
        }
        return events;
    }

    public mapAvailableRoomsCalendarEvents(timeSlots) {

    }

    public getSvgPicture(status: string): string {
        if (status === constants.BLOCKED) {
            return '<span class="status-icon status-blocked-icon">' +
                '<svg viewBox="0 0 620 620">' +
                '    <path d="M436.3,75.7C388,27.401,324.101,0,256,0C115.343,0,0,115.116,0,256c0,140.958,115.075,256,256,256\n' +
                '\t\t\tc140.306,0,256-114.589,256-256C512,187.899,484.6,123.999,436.3,75.7z M256,451c-107.786,0-195-86.985-195-195\n' +
                '\t\t\tc0-42.001,13.2-81.901,37.5-114.901l272.401,272.1C337.899,437.8,298.001,451,256,451z M413.2,370.899L141.099,98.5\n' +
                '\t\t\tC174.101,74.2,213.999,61,256,61c107.789,0,195,86.985,195,195C451,297.999,437.8,337.899,413.2,370.899z"/>\n' +
                '</svg></span>';
        }
        return '';
    }

    getInformationForModal(event: any | any, date: DateMarker | string, selectedResourceId: string): any {
        const modalData = {} as any;
        modalData.resourceId = event._def.extendedProps.resource.id;
        modalData.timeSlotId = event._def.extendedProps.timeSlotId;
        modalData.roomId = event._def.resourceIds[0];
        modalData.startDate = date;
        modalData.timeSlot = {};
        modalData.timeSlot.hourFrom = event._def.extendedProps.hourFrom;
        modalData.timeSlot.hourTo = event._def.extendedProps.hourTo;
        modalData.reservationId = event._def.extendedProps.reservationId;
        modalData.modifiedBy = event._def.extendedProps.modifiedBy;
        modalData.modifiedOn = event._def.extendedProps.modifiedOn;
        modalData.isEventEditable = !this.isEventInPast(event._def.extendedProps.eventDate, event._def.extendedProps.hourTo) &&
            modalData.resourceId === selectedResourceId;
        modalData.isPartOfSeries = event._def.extendedProps.isPartOfSeries;
        return modalData;
    }

    getStartDate(date: NgbDateStruct, view: string): NgbDateStruct {
        let startDate;
        if (view === 'weekly') {
            const momentStartDate = moment({year: date.year, month: date.month - 1, day: date.day}).startOf('isoWeek');
            startDate = {
                year: momentStartDate.year(),
                month: momentStartDate.month() + 1,
                day: momentStartDate.date()
            };
        } else {
            const momentStartDate = moment({year: date.year, month: date.month - 1, day: date.day}).startOf('month');
            startDate = {
                year: momentStartDate.year(),
                month: momentStartDate.month() + 1,
                day: momentStartDate.date()
            };
        }

        return startDate;
    }

    getEndDate(date: NgbDateStruct, view: string): NgbDateStruct {
        let endDate;
        if (view === 'weekly') {
            // wee need to add one day because the time will be 00:00
            const momentEndDate = moment(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).endOf('isoWeek'));
            endDate = {
                year: momentEndDate.year(),
                month: momentEndDate.month() + 1,
                day: momentEndDate.date()
            };
        } else {
            const momentEndDate = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).endOf('month');
            endDate = {
                year: momentEndDate.year(),
                month: momentEndDate.month() + 1,
                day: momentEndDate.date()
            };
        }

        return endDate;
    }

    getExistingEventsIntervals(draggedEvent, events) {
        const slotsIntervals = events
            .filter((event) => {
                return (moment(draggedEvent._instance.range.start).startOf('day').isSame(moment(event._instance.range.start).startOf('day'))
                    && draggedEvent._def.extendedProps.reservationId !== event._def.extendedProps.reservationId);
                // && draggedEvent._def.defId !== event._def.defId);
            })
            .map((event) => {
                return {
                    hourFrom: event._def.extendedProps.hourFrom,
                    hourFromTime: this.dateTimeUtils.getStringHourFromMinutes(event._def.extendedProps.hourFrom),
                    hourTo: event._def.extendedProps.hourTo,
                    hourToTime: this.dateTimeUtils.getStringHourFromMinutes(event._def.extendedProps.hourTo)
                };
            });
        return slotsIntervals ? slotsIntervals : [];
    }

    getGeneralCalendarOptions(): CalendarOptions {
        const options: CalendarOptions = {
            resourceAreaWidth: '15%',
            dragRevertDuration: 500, //Time it takes for an event to revert to its original position after an unsuccessful drag.
            aspectRatio: 1.5, // width-to-height aspect ratio of the calendar. default: 1.35
            contentHeight: 'auto', // height of the view area of the calendar.
            height: 'auto', //height of the entire calendar, including header and footer.
            initialView: 'resourceTimelineWeek', //The initial view when the calendar loads.
            duration: {weeks: 1}, //important//The exact duration of a custom view.
            slotLabelFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true},
            // tslint:disable-next-line:max-line-length
            droppable: true, //Determines if external draggable elements or events from other calendars can be dropped onto the calendar. default: false
            editable: true, //Determines whether the events on the calendar can be modified. default: false
            eventTimeFormat: {
                //Determines the time-text that will be displayed on each event.
                hour: '2-digit',
                minute: '2-digit'
                // meridian: false
            },
            firstDay: 1, //The day that each week begins. default: 0 (Sunday)
            resourceAreaColumns: [
                //Turns the resource area from a plain list of titles into a grid of data.
                {
                    field: 'name',
                }
            ],
            slotDuration: {days: 1}, // important The frequency for displaying time slots.
            displayEventTime: false
        };
        return options;
    }

    getResourceCalendarHeaderOption(): { left: string; center: string; right: string; } {
        return {
            left: 'customPreviousButton,customNextButton customTodayButton customRefreshButton',
            // left: 'prev,next today customRefreshButton',
            center: 'title',
            right: 'resourceTimelineWeek'
            // right: 'resourceTimelineWeek, resourceTimelineMonth'
        };
    }

    areTableFiltersValid(tableFilters: RoomReservationFilterType): boolean {
        return !!((tableFilters.center && tableFilters.center.id
            && tableFilters.startDate && tableFilters.endDate)
            && ((tableFilters.resource && tableFilters.resource.id) ||
                (tableFilters.room && tableFilters.room.id) ||
                (tableFilters.tags && tableFilters.tags.length > 0)));
    }

    getTodayCssClass(date: NgbDateStruct) {
        const currentDate = moment().format('YYYY-MM-DD');
        const formattedDateCalendar: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).format('YYYY-MM-DD');
        if (currentDate === formattedDateCalendar) {
            return 'label-current-date';
        } else {
            return '';
        }
    }

    hasDateConflicts(groupedConflictsArray: { [key: string]: ConflictInstances[] }, dateWithConflicts: string, calendarDate: NgbDateStruct): boolean {
        const formattedDate: string = moment(dateWithConflicts).format('YYYY-MM-DD');
        const formattedDateCalendar: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(calendarDate)).format('YYYY-MM-DD');
        if (formattedDate === formattedDateCalendar) {
            if (groupedConflictsArray[formattedDate] && !groupedConflictsArray[formattedDate][0].isSuccessful) {
                return true;
            }
        } else {
            return false;
        }
    }

    getConflictDateList(conflicts: ReservationInstanceType[]): ConflictInstances[] {
        const conflictsArray: ConflictInstances[] = [];

        if (conflicts && conflicts.length > 0) {
            for (const conflict of conflicts) {
                conflictsArray.push(this.getConflictItem(conflict));
            }
        }

        return conflictsArray;
    }

    getConflictItem(conflict: ReservationInstanceType): ConflictInstances {
        const conflictItem: ConflictInstances = {} as ConflictInstances;
        conflictItem.date = conflict.date;
        conflictItem.formattedDate = moment(conflict.date).format('YYYY-MM-DD');
        conflictItem.hourFrom = conflict.hourFrom;
        conflictItem.hourTo = conflict.hourTo;
        conflictItem.formattedTimeInterval = this.dateTimeUtils.getStringHourFromMinutes(conflictItem.hourFrom) + '-' +
            this.dateTimeUtils.getStringHourFromMinutes(conflictItem.hourTo);
        conflictItem.isSuccessful = conflict.isSuccessful;
        conflictItem.masterId = conflict.masterId;
        return conflictItem;
    }

    getIdOfResourceTypeRoom(resourceTypes: ResourceTypeType[]): string {
        const resourceType = lodash.find(resourceTypes, {isRoom: true});
        return resourceType ? resourceType.id : undefined;
    }

    getAllTimeSlotsArray(calendarTimeSlots: BaseReservationTimeSlotType[], resourceTimeSlots: BaseReservationTimeSlotType): number[] {
        let allTimeSlotsArray = [];
        for (const timeSlot of calendarTimeSlots) {
            allTimeSlotsArray.push(timeSlot.hourFrom);
            allTimeSlotsArray.push(timeSlot.hourTo);
        }

        allTimeSlotsArray.push(resourceTimeSlots.hourFrom);
        allTimeSlotsArray.push(resourceTimeSlots.hourTo);

        allTimeSlotsArray = lodash.orderBy(allTimeSlotsArray);
        allTimeSlotsArray = lodash.uniq(allTimeSlotsArray);

        return allTimeSlotsArray;
    }

    isEventInPast(eventDate: string, eventHour: number): boolean {
        const now = moment.utc();
        const eventFullDate = moment(eventDate).add(eventHour, 'minutes').utc();
        return moment(eventFullDate).isBefore(now);
    }

    haveMoreAvailableRooms(availableRooms) {
        return availableRooms > 50;
    }

}
