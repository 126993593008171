import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { AppointmentTypeDefinitionMDComponent } from './appointment-type-definition-md.component';
import { CreateAppointmentTypeDefinitionComponent } from './create-appointment-type-definition/create-appointment-type-definition.component';

@NgModule({
  declarations: [AppointmentTypeDefinitionMDComponent, CreateAppointmentTypeDefinitionComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
  ]
})
export class AppointmentTypeDefinitionMDModule { }
