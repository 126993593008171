import {Injectable} from '@angular/core';
import {AvailabilityChannelRuleType, ChannelDependentFiltersType, Expand, ODataQueryObjectType} from 'sked-base';
import * as lodash from 'lodash';
import {
    AvailabilityChannelRuleModalFiltersType,
    AvailabilityChannelRuleStateType,
    AvailabilityChannelRuleTagsType,
    TagModalFilterOptionsType
} from './availability-channel-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';
import {TranslatedLanguageService} from '../../../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityChannelRuleUtils {
    overviewState: AvailabilityChannelRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'availabilityChannelRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.onlyWarning',
            hasSort: true,
            onSortByProperty: 'onlyWarning',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.channel',
            hasSort: true,
            onSortByProperty: 'channel',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.tag',
            hasSort: true,
            onSortByProperty: 'tag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.message',
            hasSort: true,
            onSortByProperty: 'message',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'availabilityChannelRuleTableId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'channel': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'tagId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'availabilityChannelRuleTable' || item === 'tag') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            Tag: {select: EXPAND_TAG_SELECT},
            AvailabilityChannelRuleTable: {select: ['Id', 'Name', 'Description']},
        };
    }

    getInitialRule(): AvailabilityChannelRuleType {
        return {
            name: undefined,
            active: false,
            action: false,
            tag: undefined,
            tagId: undefined,
            channel: undefined,
            message: '',
            onlyWarning: false,
            availabilityChannelRuleTableId: undefined,
            availabilityChannelRuleTable: undefined,
        } as AvailabilityChannelRuleType;
    }

    getInitialModalFilters(): AvailabilityChannelRuleModalFiltersType {
        return {
            channelModalFilterOptions: {
                ngModel: undefined,
            },
            messageModalFilterOptions: {
                ngModel: undefined,
            },
            tagOptions: {
                ngModel: undefined,
            } as TagModalFilterOptionsType,
            areFiltersActive: false,
        } as AvailabilityChannelRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: AvailabilityChannelRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: AvailabilityChannelRuleType): AvailabilityChannelRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getInitialAvailabilityChannelRuleTags(): AvailabilityChannelRuleTagsType {
        return {
            selectedTags: [],
        } as AvailabilityChannelRuleTagsType;
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }
}
