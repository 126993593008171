import {Component, OnDestroy, OnInit} from '@angular/core';
import {OpenIdConnectService} from '../../../shared/services/open-id-connect.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
  selector: 'app-redirect-silent-renew',
  templateUrl: './redirect-silent-renew.component.html',
  styleUrls: ['./redirect-silent-renew.component.scss']
})
export class RedirectSilentRenewComponent implements OnInit, OnDestroy {

  constructor(private openIdConnectService: OpenIdConnectService) {
  }

  ngOnInit() {
    this.openIdConnectService.handleSilentCallback();
  }

  ngOnDestroy(): void {
  }
}
