<div class="container-fluid availability-exclusion-export-md-wrapper overview-screen-md pb-1">
    <h3 translate="label.availabilityExclusionExportTitle"></h3>

    <form name="form" novalidate (ngSubmit)="sendExportData(exportType, queryFilters)" class="custom-loader">
        <div class="row d-flex">
            <ngx-ui-loader [hasProgressBar]="false" [loaderId]="spinnerId">
            </ngx-ui-loader>
            <div class="col-md-3 col-12">
                <div class="form-group mb-3 task-list-select-filter">
                    <label class="mb-1 mr-2" translate="label.choose"></label>
                    <ng-select class="form-control pl-0 rounded-0 p-0 custom-ng-select"
                               dropdownPosition="bottom"
                               [multiple]="false"
                               [searchable]="false"
                               [clearable]="false"
                               [ngModelOptions]="{standalone: true}"
                               [attr.disabled]="disableFilters ? '' : null"
                               [(ngModel)]="exportType">
                        <ng-option *ngFor="let value of availabilityOrExclusionEnumValues"
                                   [activityDisplay]="value + 'Export'"
                                   [value]="value">
                            <span [translate]="'label.availabilityExclusionExportSelect' + value"></span>
                        </ng-option>
                    </ng-select>

                    <!-- When export type = Availability show a warning message -->
                    <div class="text-warning m-1" *ngIf="exportType === 'Availability'">
                        <span class="exclamation-sign m-1"><i class="fas fa-exclamation-circle"></i></span>
                        <span class="font-weight-high-contrast" translate="label.availabilityExportWaitTimeMessage"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-left">
            <button type="submit" class="btn theme-btn"
                    [disabled]="!areFiltersValid(exportType, queryFilters)" translate="label.generateExport"></button>
        </div>
    </form>
</div>
