import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { CenterMDComponent } from './center-md.component';
import { CreateCenterComponent } from './create-center/create-center.component';

@NgModule({
  declarations: [CenterMDComponent, CreateCenterComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
  ]
})
export class CenterMDModule { }
