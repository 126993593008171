import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    ResourceWorkScheduleType,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    Expand,
    LocationDependentFiltersType,
    DateRangeOptionsType,
    ResourceWorkScheduleTimeSlotType,
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {TimeSlotsUtils} from '../../shared/utils/time-slots.utils';
import {IntervalType} from 'sked-base/lib/data-model/intervalTypes';
import {ResourceWorkScheduleTimeSlotFormType} from './resource-work-schedule.types';

@Injectable({
    providedIn: 'root'
})
export class ResourceWorkScheduleMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialFilterValues = {
        resource: [],
        center: []
    };
    constructor(
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
        private timeSlotsUtils: TimeSlotsUtils
    ) {
    }

    getResourceDependentFilter(): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            onlyDirectlyBookable: false,
            locationId: null,
            includeAvailabilities: false,
            onlyAssignedToUser: false,
            onlyAssignedToLocationsOfUser: false,
            includeSelfPayer: false,
            exclusionList: [],
            resourceTypeExclusionList: [],
            count: false
        } as ResourceDependentFiltersType;
    }

    getCenterDependentFilter(): LocationDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            isRequestAllowed: false,
            resourceId: null,
            locationId: null,
            regionId: null,
            onlyAssignedToUser: false,
            serviceId: null,
            areaId: null,
            useOnlyAssignedToUser: false,
            exclusionList: [],
            count: false
        };
    }

    getMainDependentFilters() {
        return {
            resource: this.getResourceDependentFilter(),
            center: this.getCenterDependentFilter()
        };
    }

    getQueryFilterForResourceWorkScheduleMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'CenterId', 'ResourceId', 'ValidFrom', 'ValidTo', 'ValidFromUTC', 'ValidToUTC', 'Unit', 'Repetition', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Center: {select: ['Id', 'Name', 'TimeZoneId']},
            Resource: {select: ['Id', 'Name']},
            TimeSlots: {}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {ResourceId: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'resource':
                    case 'center': {
                        orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                        break;
                    }
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialResourceWorkSchedule(): ResourceWorkScheduleType {
        const MOMENT_TODAY_DATE = moment(new Date()).format('YYYY-MM-DD');
        return {
            validFrom: MOMENT_TODAY_DATE,
            validTo: MOMENT_TODAY_DATE,
            timeSlots: [],
            repetition: 1
        } as ResourceWorkScheduleType;
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const initialDateRangeOptions = this.dateTimeUtils.getInitialDateRangeOptions();
        initialDateRangeOptions.minDate = this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate;
        return initialDateRangeOptions;
    }

    getInitialTimeSlot(): ResourceWorkScheduleTimeSlotType {
        return {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            interval: {
                hourFrom: 0,
                hourTo: 0
            }
        } as ResourceWorkScheduleTimeSlotType;
    }

    getInitialTimeSlotForm(): ResourceWorkScheduleTimeSlotFormType {
        return {
            selectedDays: [],
            hourFrom: '00:00',
            hourTo: '00:00'
        } as ResourceWorkScheduleTimeSlotFormType;
    }

    getTimeSlotAsSelectedDaysStringArray(timeslot: ResourceWorkScheduleTimeSlotType): string[] {
        return Object.entries(timeslot)
            .filter(([key, value]) => this.timeSlotsUtils.getWeekDays().includes(key) && value)
            .map(([key, value]) => key);
    }

    getDateStringFromNgbStruct(date: NgbDateStruct, time: NgbTimeStruct, timezone: string): string {
        return moment({
            year: date.year,
            month: date.month - 1,
            day: date.day,
            hour: time.hour,
            minute: time.minute,
            second: time.second
        }).tz(timezone).format();
    }

    areIntervalsOverlapping(interval1: IntervalType, interval2: IntervalType): boolean {
        if (interval1.hourFrom < interval1.hourTo) {
            if (interval2.hourFrom < interval2.hourTo) {
                return !((interval2.hourFrom < interval1.hourFrom
                        && interval2.hourTo <= interval1.hourFrom)
                    || (interval2.hourFrom >= interval1.hourTo
                        && interval2.hourTo > interval1.hourTo));
            } else {
                return !(interval2.hourFrom >= interval1.hourTo
                    && interval2.hourTo > interval1.hourTo
                    || interval2.hourTo <= interval1.hourFrom);
            }
        } else {
            if (interval2.hourFrom < interval2.hourTo) {
                return !(interval2.hourTo <= interval1.hourFrom
                    && interval2.hourFrom >= interval1.hourTo);
            } else {
                return true;
            }
        }
    }
}
