import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {AccessType, UserProfileOptionsType, UserProfileTabsTypeEnum} from 'sked-base';
import {UserProfileTabsAccessType} from 'sked-base/lib/components/user-profile/user-profile.type';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
    // If user need permissions to see a tab, fill them here;
    //  if any permission is false the user won't see the tab
    //  if no permissions are provided, the user will see the tab
    // Example usage for {[key in UserProfileTabsTypeEnum]?: string} (find these values in the documentation):
    //   TABS_FEATURE_ACCESS = { changePassword: 'PasswordChangeFeatureAccessValue' };
    //   TABS_ACTIVITY_DISPLAY = { changePassword: 'PasswordChangeActivityDisplayValue' };
    //   TABS_SYSTEM_CONFIG = { changePassword: 'SelfServicePasswordChange' };
    TABS_FEATURE_ACCESS: {[key in UserProfileTabsTypeEnum]?: string} = {};
    TABS_ACTIVITY_DISPLAY: {[key in UserProfileTabsTypeEnum]?: string} = {};
    TABS_SYSTEM_CONFIG: {[key in UserProfileTabsTypeEnum]?: string} = { changePassword: 'SelfServicePasswordChange' };
    userProfileTabsTypeEnum = Object.values(UserProfileTabsTypeEnum);
    userProfileOptions: UserProfileOptionsType = {} as UserProfileOptionsType;

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private configDataService: ConfigDataService) { }

    ngOnInit(): void {
        this.setAccessForTabs();
        this.loadInitialOptionsForUserProfile();
    }

    ngOnDestroy(): void {
    }

    private loadInitialOptionsForUserProfile() {
        this.userProfileOptions.messagesService = this.messagesService;
        // Load user: if user already loaded we don't send request
        this.ngxLoader.start();
        this.configDataService.getUserByToken().subscribe((response) => {
            this.userProfileOptions.user = response;
            this.ngxLoader.stop();
        }, (error) => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(error);
        });
    }

    private setAccessForTabs() {
        // Get feature access and activity displays for each tab based on TABS_FEATURE_ACCESS and TABS_ACTIVITY_DISPLAY
        this.userProfileOptions.tabsAccess = {} as UserProfileTabsAccessType;
        this.userProfileTabsTypeEnum.forEach(tab => {
            this.userProfileOptions.tabsAccess[tab] = {} as AccessType;
        });
        for (const tab of Object.keys(this.TABS_FEATURE_ACCESS)) {
            this.userProfileOptions.tabsAccess[tab].featureAccess = this.configDataService.isFeatureActive(this.TABS_FEATURE_ACCESS[tab]);
        }
        for (const tab of Object.keys(this.TABS_ACTIVITY_DISPLAY)) {
            this.userProfileOptions.tabsAccess[tab].activityDisplay = (this.configDataService.activeActivities.indexOf(this.TABS_ACTIVITY_DISPLAY[tab]) > -1);
        }
        for (const tab of Object.keys(this.TABS_SYSTEM_CONFIG)) {
            const tabSystemConfig = lodash.find(this.configDataService?.systemConfig?.value, (item) => {
                return item.name === this.TABS_SYSTEM_CONFIG[tab];
            });
            this.userProfileOptions.tabsAccess[tab].systemConfig = (tabSystemConfig === undefined ? undefined : tabSystemConfig.value === 'true');
        }
    }
}
