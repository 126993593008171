import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ObjectDetailMDComponent} from './object-detail-md.component';
import {CreateObjectDetailComponent} from './create-object-detail/create-object-detail.component';
import {AngularEditorModule} from '@kolkov/angular-editor';

@NgModule({
    declarations: [ObjectDetailMDComponent, CreateObjectDetailComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        AngularEditorModule,
    ]
})
export class ObjectDetailMDModule { }
