import {Component, OnInit, OnDestroy} from '@angular/core';
import {TableFiltersType} from 'src/app/data-model/general.type';
import {constants} from 'src/app/shared/constants/constants';
import {
    ConfirmDeleteModalService,
    EntityTypeEnum,
    RuleManagementWeightedCombinationsProvider,
    RuleManagementWeightedCombinationType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {RulesWeightedCombinationMdUtils} from './rules-weighted-combination-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-rules-weighted-combination-md',
    templateUrl: './rules-weighted-combination-md.component.html',
    styleUrls: ['./rules-weighted-combination-md.component.scss']
})
export class RulesWeightedCombinationMDComponent implements OnInit, OnDestroy {
    tableFilters: TableFiltersType = this.rulesWeightedCombinationMdUtils.getInitialTableFilter();
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    rulesWeightedCombinationMdList: RuleManagementWeightedCombinationType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    RULE_WEIGHT_COMBINATION = 'RuleWeightCombination';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        private rulesWeightedCombinationMdUtils: RulesWeightedCombinationMdUtils,
        public ruleManagementWeightedCombinationsProvider: RuleManagementWeightedCombinationsProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('RuleWeightCombinationRead');
        this.loadRulesWeightedCombinationMdData();
    }

    ngOnDestroy(): void {
    }

    editRulesWeightedCombination(rulesWeightedCombination: RuleManagementWeightedCombinationType) {
        this.router.navigate(['/createRulesWeightedCombination'], {
            state: {
                rulesWeightedCombination,
                action: constants.EDIT
            }
        });
    }

    displayChangeLog(id: string, actionType: string): void {
        this.changeLogModalService.displayModal(id, actionType);
    }

    viewRulesWeightedCombination(rulesWeightedCombination: RuleManagementWeightedCombinationType) {
        this.router.navigate(['/createRulesWeightedCombination'], {
            state: {
                rulesWeightedCombination,
                action: constants.VIEW
            }
        });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tableFilters.currentPage = page;
        this.loadRulesWeightedCombinationMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.tableFilters.currentPage = 1;
        this.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadRulesWeightedCombinationMdData(false);
    }

    // function to get RulesWeightedCombination data
    private loadRulesWeightedCombinationMdData(includeCount: boolean = true) {
        const queryFilter = this.rulesWeightedCombinationMdUtils.getQueryFilterForRulesWeightedCombinationMD(this.tableFilters, includeCount);
        this.ngxLoader.start();
        this.ruleManagementWeightedCombinationsProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.rulesWeightedCombinationMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
