import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SlotsManagementMdUtils} from '../slots-management-util';
import {SlotDisplayType} from 'sked-base';
import {
    SlotResourceAdditionalInfoOptionsType
} from '../../../shared/component/slot-resource-additional-info/slot-resource-additional-info.types';

@Component({
    selector: 'app-slots-results',
    templateUrl: './slots-results.component.html',
    styleUrls: ['./slots-results.component.scss']
})
export class SlotsResultsComponent implements OnInit {
    @Output() selectValue: EventEmitter<SlotDisplayType> = new EventEmitter<SlotDisplayType>();

    constructor(public slotsManagementMdUtils: SlotsManagementMdUtils) {
    }

    ngOnInit(): void {
    }

    onScrollDown() {
        this.slotsManagementMdUtils.slotsResultsSliceUpperBound = Math.min(
            this.slotsManagementMdUtils.slotsResultsOptions.length,
            this.slotsManagementMdUtils.slotsResultsSliceUpperBound + this.slotsManagementMdUtils.INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND
        );
    }

    // Emit changes to parent
    onSelectSlot(slot: SlotDisplayType) {
        if (slot.bookable) {
            this.selectValue.emit(slot);
        }
    }

    getSlotResourceAdditionalInfoOptions(
        {resource, secondaryResources, bookable}: SlotDisplayType, isMainResource: boolean, isFirstSlot: boolean, isLastSlot: boolean
    ): SlotResourceAdditionalInfoOptionsType {
        return {
            mainResource: resource,
            secondaryResources,
            isMainResource,
            resourceTabToPreselect: isMainResource ? resource?.name : secondaryResources[0]?.name,
            isFirstSlot,
            isLastSlot,
            bookable
        };
    }
}
