import {Component, OnInit} from '@angular/core';
import {ConfigDataService} from '../../shared/services/config-data.service';
import * as lodash from 'lodash';
import tippy, {Instance} from 'tippy.js';
import {GeneralProvider, SystemConfigProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {
    ApimIntegrationInformationDTOType,
    ApimIntegrationInformationType,
    SecurityAPIKeyType
} from 'sked-base/lib/data-model/apiIntegrationTypes';
import {MessagesService} from '../../shared/services/messages.service';

@Component({
    selector: 'app-api-integration',
    templateUrl: './api-integration.component.html',
    styleUrls: ['./api-integration.component.scss']
})
export class ApiIntegrationComponent implements OnInit {
    tippyInstances: { [key: string]: Instance } = {};
    apimIntegrationInformation: ApimIntegrationInformationType;
    securityAPIKeys: SecurityAPIKeyType[] = [];

    constructor(private configDataService: ConfigDataService,
                private generalProvider: GeneralProvider,
                private systemConfigProvider: SystemConfigProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService) {
    }

    ngOnInit(): void {
        this.apimIntegrationInformation = this.getApimIntegrationInformation();
        this.loadSecurityAPIKeys();
    }

    openDocumentationLink(): void {
        window.open(this.apimIntegrationInformation.linkToDocumentation, '_blank');
    }

    makeTippyIfNotAlreadyMade(tippyInstanceKey: string, elementIdName: string, popoverIdName: string, forceCreate: boolean = false): void {
        if (!this.tippyInstances[tippyInstanceKey]) {
            // No tippy instance yet, create one
            this.makeTippyPopover(tippyInstanceKey, elementIdName, popoverIdName);
            return;
        }
        if (forceCreate) {
            // The tippy exists already but we want to update it, so we destroy the old one and create another
            this.tippyInstances[tippyInstanceKey].destroy();
            this.makeTippyPopover(tippyInstanceKey, elementIdName, popoverIdName);
            return;
        }
        // The tippy exists already and we don't want to update it, so we don't do anything
    }

    private loadSecurityAPIKeys(): void {
        this.ngxLoader.start();
        this.generalProvider.getApiKeys().subscribe(response => {
                this.securityAPIKeys = response;
                this.ngxLoader.stop();
            },
            err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stop();
            });
    }

    private getApimIntegrationInformation(): ApimIntegrationInformationType {
        const systemConfig: any = this.configDataService.systemConfig;
        const foundSystemConfig = lodash.find(systemConfig?.value, (item) => item.name === 'ApimIntegrationInformation');
        const parsedFoundSystemConfig = foundSystemConfig?.value ? JSON.parse(foundSystemConfig?.value) : {};

        return this.mapApimIntegrationInformationDTOToEntity(parsedFoundSystemConfig);
    }

    private makeTippyPopover(tippyInstanceKey: string, elementIdName: string, popoverIdName: string): void {
        const element = document.getElementById(elementIdName);
        const popover = document.querySelector(`#${popoverIdName}`).textContent;
        if (!!element) {
            this.tippyInstances[tippyInstanceKey] = tippy(element, {
                content: popover,
                placement: 'top',
                theme: 'light-border',
                animation: false,
                popperOptions: {strategy: 'fixed'},
                onHidden: () => {
                    if (tippyInstanceKey !== 'linkToDocumentation') {
                        this.tippyInstances[tippyInstanceKey].setContent(document.querySelector('#popoverBeforeCopyText').textContent);
                    }
                }
            });
        }
        this.tippyInstances[tippyInstanceKey].show();
    }

    private mapApimIntegrationInformationDTOToEntity(apimIntegrationInformationDTO: ApimIntegrationInformationDTOType):
        ApimIntegrationInformationType {
        const apimIntegrationInformation = {} as ApimIntegrationInformationType;
        apimIntegrationInformation.apimUrl = apimIntegrationInformationDTO.ApimUrl;
        apimIntegrationInformation.apimSubscriptionKey = apimIntegrationInformationDTO.ApimSubscriptionKey;
        apimIntegrationInformation.linkToDocumentation = apimIntegrationInformationDTO.LinkToDocumentation;
        apimIntegrationInformation.apimUrlV2 = apimIntegrationInformationDTO.ApimUrlV2;
        if (apimIntegrationInformationDTO.ApimMed360SubscriptionKey) {
            apimIntegrationInformation.apimMed360SubscriptionKey = apimIntegrationInformationDTO.ApimMed360SubscriptionKey;
        }
        return apimIntegrationInformation;
    }
}
