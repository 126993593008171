import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {OrganizationalRoleTypeEnum, TeamProvider, TeamType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {
    BackOfficeActivityType,
    BackOfficeFeatureType,
    BackOfficeTeamType,
    CreateTeamFilterModel,
    ValidTeamType
} from './team.types';
import {TeamMDUtils} from './team-md.utils';
import {Router} from '@angular/router';
import {constants} from '../../shared/constants/constants';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ScreenTemplateLayoutType} from '../../data-model/general.type';
import {take} from 'rxjs/operators';
import tippy, {Instance, hideAll} from 'tippy.js';
import {TranslateService} from '@ngx-translate/core';
import * as lodash from 'lodash';

@Component({
    selector: 'app-create-team-md',
    templateUrl: './create-team-md.component.html',
    styleUrls: ['./create-team-md.component.scss']
})
export class CreateTeamMdComponent implements OnInit, OnDestroy, AfterViewInit {
    constants = constants;
    historyStateValue: any = history.state;
    screenTemplateLayout: ScreenTemplateLayoutType;
    initialTeamItem: TeamType = {} as TeamType;
    teamItem: BackOfficeTeamType = {} as BackOfficeTeamType;
    teamItemClone: BackOfficeTeamType = {} as BackOfficeTeamType;
    groupedActivitiesByFeature: BackOfficeFeatureType = {} as BackOfficeFeatureType;
    allGroupedActivitiesByFeature: BackOfficeFeatureType = {} as BackOfficeFeatureType;
    validTemplate: ValidTeamType;
    isTemplateValid: boolean;
    objectKeys = Object.keys;
    tippyInstances: { [key: string]: Instance } = {};
    OrganizationalRoleEnum = Object.values(OrganizationalRoleTypeEnum);
    pageFilter: CreateTeamFilterModel = {} as CreateTeamFilterModel;

    constructor(public ngxLoader: NgxUiLoaderService,
                public messagesService: MessagesService,
                public teamProvider: TeamProvider,
                public teamMDUtils: TeamMDUtils,
                public router: Router,
                public generalUtils: GeneralUtils,
                private translateService: TranslateService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadAvailableActivities();
        this.registerOnLanguageChange();
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    onOrganizationalRoleChange(organizationalRole: OrganizationalRoleTypeEnum) {
        this.teamItem.organizationalRole = this.generalUtils.isSelectedNoValueOption(organizationalRole) ?
            'noValue' as OrganizationalRoleTypeEnum : organizationalRole;
    }

    selectAllActivitiesFromFeature(selected: boolean, featureKey: string) {
        this.teamItem.features[featureKey].forEach((activity) => {
            return activity.selected = selected;
        });

        this.handleActivityCheckedChange();
    }

    makeTippiesIfNotAlreadyMade(activityKey: string, elementIdName: string, popover: any, instanceKeyText: string, eventType?: string) {
        const tippyInstanceKey = `${instanceKeyText}-${activityKey}`;
        if (this.tippyInstances[tippyInstanceKey]) {
            return;
        }
        this.makeTippies(tippyInstanceKey, elementIdName, popover, eventType);
    }

    makeTippies(tippyInstanceKey: string, elementIdName: string, popover: any, eventType?: string) {
        const element = document.getElementById(elementIdName);
        const tippyCommonOptionalProps = {
            content: popover,
            theme: 'light-border',
            animation: false,
            popperOptions: {strategy: 'fixed'}
        } as any;
        if (!!element) {
            if (eventType === 'click') {
                popover.addEventListener('click', () => {
                    hideAll();
                });
                this.tippyInstances[tippyInstanceKey] = tippy(element, {
                    ...tippyCommonOptionalProps,
                    placement: 'left',
                    trigger: 'click',
                    hideOnClick: true,
                    allowHTML: true,
                    interactive: true
                });
            } else {
                this.tippyInstances[tippyInstanceKey] = tippy(element, {
                    ...tippyCommonOptionalProps,
                    placement: 'top'
                });
            }
            this.tippyInstances[tippyInstanceKey].show();
        }
    }

    ngOnDestroy() {
        for (const key of this.objectKeys(this.teamItem.features)) {
            if (!lodash.isEmpty(this.tippyInstances[key])) {
                this.tippyInstances[key].destroy();
            }
        }
        this.tippyInstances = {};
    }

    searchForItems(): BackOfficeFeatureType {
        const selectedItems: BackOfficeFeatureType = {};
        for (const featureKey of Object.keys(this.teamItem.features)) {
            const activities: BackOfficeActivityType[] =
                lodash.filter(this.teamItem.features[featureKey],
                    (activity) => lodash.deburr(activity?.translatedName?.replace(/\s/g, '').toLowerCase())
                        .includes(lodash.deburr(this.pageFilter.searchTerm.replace(/\s/g, '').toLowerCase())));
            if (activities.length > 0) {
                selectedItems[featureKey] = activities;
            }
        }

        return selectedItems;
    }

    searchForGroupOrActivity() {
        if (this.pageFilter.searchTerm.length >= 3 || !this.pageFilter.searchTerm) {
            setTimeout(() => {
                this.applyFeaturesActivitiesFilter();
            }, constants.inputDebounceTime);
        }
    }

    showAssignedActivitiesOnly() {
        this.pageFilter.showUnassignedActivities = false;
        this.applyFeaturesActivitiesFilter();
    }

    showUnassignedActivitiesOnly() {
        this.pageFilter.showAssignedActivities = false;
        this.applyFeaturesActivitiesFilter();
    }

    applyFeaturesActivitiesFilter() {
        if (!lodash.isEmpty(this.teamItemClone.features)) {
            this.refreshFeatures();
        }

        if (this.pageFilter.searchTerm) {
            this.teamItem.features = this.searchForItems();
        }

        if (this.pageFilter.showAssignedActivities) {
            this.teamItem.features = this.filterSelectedActivities(true);
        }

        if (this.pageFilter.showUnassignedActivities) {
            this.teamItem.features = this.filterSelectedActivities(false);
        }

        if (!this.pageFilter.searchTerm && !this.pageFilter.showAssignedActivities && !this.pageFilter.showUnassignedActivities) {
            this.teamItem.features = lodash.cloneDeep(this.teamItemClone.features);
        }
    }

    saveTeam(teamItem: BackOfficeTeamType) {
        this.refreshFeatures();
        if (this.screenTemplateLayout.action === constants.CREATE) {
            this.createTeam(teamItem);
        } else if (this.screenTemplateLayout.action === constants.EDIT) {
            if (lodash.isEqual(this.initialTeamItem, teamItem)) {
                this.messagesService.success('toastr.success.teamEdited', true);
                this.goToParentPage();
            } else {
                this.editTeam(this.initialTeamItem, teamItem);
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/teams']);
    }

    goToEdit() {
        history.replaceState({
            team: this.teamItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    checkIfAllActivitiesSelected(featureKey: string) {
        return this.teamItem?.features[featureKey].every(feature => feature?.selected);
    }

    handleActivityCheckedChange() {
        if (this.pageFilter.showAssignedActivities) {
            this.showAssignedActivitiesOnly();
        }

        if (this.pageFilter.showUnassignedActivities) {
            this.showUnassignedActivitiesOnly();
        }
    }

    private refreshFeatures() {
        if (!lodash.isEqual(this.teamItem.features, this.teamItemClone.features)) {
            this.teamItemClone.features = this.mergeSelectedFeatures(this.teamItemClone.features, this.teamItem.features);
            this.teamItem.features = lodash.cloneDeep(this.teamItemClone.features);
        }
    }

    private registerOnLanguageChange() {
        this.translateService.onLangChange.subscribe(ev => {
            this.teamItem.features = {};
            this.teamItemClone.features = {};
            this.loadAvailableActivities();
        });
    }

    private filterSelectedActivities(selected: boolean): BackOfficeFeatureType {
        const selectedItems: BackOfficeFeatureType = {};
        for (const featureKey of Object.keys(this.teamItem.features)) {
            const activities = lodash.filter(this.teamItem.features[featureKey], (activity) => activity?.selected === selected);
            if (activities.length > 0) {
                selectedItems[featureKey] = activities;
            }
        }
        return selectedItems;
    }

    private loadAvailableActivities() {
        this.ngxLoader.start();
        this.teamProvider.getAvailableActivities('')
            .subscribe((response) => {
                this.allGroupedActivitiesByFeature = this.teamMDUtils.getGroupedActivitiesByFeature(response.value, false);
                if (this.screenTemplateLayout.action !== constants.CREATE) {
                    const updatedTeamActivities = this.teamMDUtils.getUpdatedTeamActivities(response.value, this.initialTeamItem.activities);
                    const selectedGroupedActivitiesByFeature = this.teamMDUtils.getGroupedActivitiesByFeature(updatedTeamActivities, true);
                    this.groupedActivitiesByFeature = lodash.omit(this.allGroupedActivitiesByFeature, lodash.keys(this.teamItem.features));
                    this.teamItem.features = this.screenTemplateLayout.action !== constants.VIEW
                        ? this.mergeSelectedFeatures(this.groupedActivitiesByFeature, selectedGroupedActivitiesByFeature)
                        : selectedGroupedActivitiesByFeature;
                } else {
                    this.teamItem.features = lodash.cloneDeep(this.allGroupedActivitiesByFeature);
                }
                this.teamItemClone.features = lodash.cloneDeep(this.teamItem.features);
                this.applyFeaturesActivitiesFilter();
            }, err => {
                this.onRequestError(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private mergeSelectedFeatures(source: BackOfficeFeatureType, modifications: BackOfficeFeatureType): BackOfficeFeatureType {
        const result = Object.assign({}, source);
        for (const [key, value] of Object.entries(modifications)) {
            if (key) {
                const filteredExisting = result[key].filter(
                    (existing) => !value.find(
                        (item) => item.name === existing.name)
                );

                result[key] = [...filteredExisting, ...value];
            }
        }
        return result;
    }

    private createTeam(teamItem: BackOfficeTeamType) {
        const newTeam: TeamType = this.teamMDUtils.mapTeamItemForCreate(teamItem);
        this.ngxLoader.start();
        this.teamProvider.addEntry(newTeam)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.teamCreated', true);
                this.goToParentPage();
            }, err => {
                this.onRequestError(err);
            });
    }

    private editTeam(oldTeamItem: TeamType, newTeamItem: BackOfficeTeamType) {
        const newTeamForCreate: TeamType = this.teamMDUtils.mapTeamItemForCreate(newTeamItem);
        this.ngxLoader.start();
        this.teamProvider.updateEntry(oldTeamItem, newTeamForCreate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.teamEdited', true);
                this.goToParentPage();
            }, err => {
                this.onRequestError(err);
            });
    }

    private setupInitialState() {
        this.teamItemClone = this.teamMDUtils.getTeamInitialValue();
        if (this.historyStateValue && this.historyStateValue.team) {
            this.initialTeamItem = this.historyStateValue.team;
            this.teamItem = lodash.cloneDeep(this.teamMDUtils.mapBackofficeTeamItem(this.initialTeamItem));
            if (!this.teamItem.organizationalRole) {
                this.teamItem.organizationalRole = 'noValue' as unknown as OrganizationalRoleTypeEnum;
            }
            // team activities will be updated after the loadActivities method
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.teamItem = this.teamMDUtils.getTeamInitialValue();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private onRequestError(err) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
    }
}
