<div class="multi-select-table-container"
     [ngClass]="{'multi-select-table-container-with-scrollbar': options?.tableMaxHeight}">
    <div class="row header-row">
        <div class="form-group col-4">
            <label>{{selectedCount}} {{options.assignedItemsLabel | translate}}</label>
        </div>
        <div class="form-group col-4">
            <label translate="label.showOnlyAssigned"></label>
            <div class="switch">
                <label>
                    <input type="checkbox" checked
                           [(ngModel)]="showOnlyAssigned"
                           (ngModelChange)="onShowOnlyAssignedItems(showOnlyAssigned)">
                    <span class="lever switch-col-black"></span>
                </label>
            </div>
        </div>
    </div>
    <table class="item-table table table-sm table-hover">
        <thead>
        <tr>
            <th scope="col" [style.width.%]="6"></th>
            <th scope="col"
                *ngFor="let column of options.columns"
                [style.width.%]="94 / options.columns.length">
                <span *ngIf="column.type === multiSelectTableColumnKindEnum.FILTER && !column.orderByProperty"
                      [translate]="!!column.displayUpperHeader ? column.headerLabel : ''"></span>
                <div (click)="onSortBy(column.orderByProperty)"
                     *ngIf="column.type === multiSelectTableColumnKindEnum.FILTER && !!column.orderByProperty"
                     class="cursor-pointer">
                    <span [translate]="column.headerLabel" class="mb-0 mr-1"></span>
                    <button type="button" class="btn p-0" *ngIf="!itemTableFilters.orderBy[column.orderByProperty]"><i class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0" *ngIf="itemTableFilters.orderBy[column.orderByProperty]=== 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="itemTableFilters.orderBy[column.orderByProperty]=== 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </div>
            </th>
        </tr>
        <tr>
            <th scope="col" style="width: 6%">
                <input type="checkbox"
                       name="selectAll"
                       class="selectAll-checkbox"
                       [disabled]="options.limitNumberOfSelectedItemsTo1"
                       [ngModelOptions]="{standalone: true}"
                       [checked]="selectAll"
                       [(ngModel)]="selectAll"
                       (ngModelChange)="onSelectAll(selectAll)"/>
            </th>

            <th scope="col"
                *ngFor="let column of options.columns"
                [style.width.%]="94 / options.columns.length">
                <sbase-filter *ngIf="column.type === multiSelectTableColumnKindEnum.FILTER"
                              (selectedItemsOutput)="onSelectedColumnFilter(column, $event)"
                              [buttonName]="column.buttonName"
                              [dependentFilters]="column.dependentFilters"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true"
                              [providerInstance]="column.provider"
                              [customTemplate]="column.customHTMLTemplate ? column.customHTMLTemplate : null">
                </sbase-filter>
                <label *ngIf="column.type === multiSelectTableColumnKindEnum.DISPLAY && !column.orderByProperty"
                       [translate]="column.headerLabel"
                       class="mb-0">
                </label>
                <div (click)="onSortBy(column.orderByProperty)"
                     *ngIf="column.type === multiSelectTableColumnKindEnum.DISPLAY && !!column.orderByProperty"
                     class="cursor-pointer">
                    <span [translate]="column.headerLabel" class="mb-0 mr-1"></span>
                    <button type="button" class="btn p-0" *ngIf="!itemTableFilters.orderBy[column.orderByProperty]"><i class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0" *ngIf="itemTableFilters.orderBy[column.orderByProperty]=== 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="itemTableFilters.orderBy[column.orderByProperty]=== 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </div>
            </th>
        </tr>
        </thead>
        <tbody [style.max-height]="options?.tableMaxHeight ? options?.tableMaxHeight : 'none'">
        <tr *ngFor="let item of items"
            [ngClass]="{'selected': item.selected}"
            (click)="onSelectSingle(item, $event)">
            <td>
                <input type="checkbox"
                       name="item"
                       class="item-checkbox"
                       [checked]="item.selected"/>
            </td>
            <ng-container *ngFor="let column of options.columns">
                <td *ngIf="!!column.useYesNoPipe">
                    {{item[column.itemPropertyNameInTableRow] | yesNoPipe | translate}}
                </td>
                <td *ngIf="!column.useYesNoPipe">
                    <span *ngIf="!!column.customFunctionText">
                         {{column.customFunctionText(item)}}
                    </span>
                    <span *ngIf="!column.customFunctionText">
                        {{!!column.itemPropertyExpandName ? item[column.itemPropertyExpandName][column.itemPropertyNameInTableRow] : item[column.itemPropertyNameInTableRow]}}
                    </span>
                </td>
            </ng-container>
        </tr>
        </tbody>
    </table>
    <div class="pagination-component mt-3" *ngIf="count > 0">
        <ngb-pagination [collectionSize]="count"
                        [(page)]="itemTableFilters.currentPage"
                        [maxSize]="5"
                        [pageSize]="itemTableFilters.itemsPerPage"
                        [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)">
        </ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{itemTableFilters.itemsPerPage}}</strong>
                    </span>
                    <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                         (clickOutside)="showItemsPerPageDropdown = false"
                         class="dropdown-menu dropdown-menu-item"
                         x-placement="top-start" x-placement="bottom-start">
                        <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                           (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                    </div>
                </span>
        </div>
    </div>
</div>
