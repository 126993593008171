import {Component, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ImportExcelDataTypeEnum, ImportProvider, ExcelImporterOptionsType} from 'sked-base';
import {MessagesService} from '../../shared/services/messages.service';

@Component({
    selector: 'app-excel-import',
    templateUrl: './excel-import.component.html',
    styleUrls: ['./excel-import.component.scss']
})
export class ExcelImportComponent implements OnInit {
    // We need to exclude Import Appointments because this is not ready on back-end
    readonly importSelectOptionsEnumList = Object.values(ImportExcelDataTypeEnum).filter(value => value !== ImportExcelDataTypeEnum.Appointments);
    excelImporterOptions: ExcelImporterOptionsType = {
        isSimulation: false
    } as ExcelImporterOptionsType;
    errorsAfterUpload: string[] | null;

    constructor(
        private importProvider: ImportProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService
    ) {
    }

    ngOnInit(): void {
    }

    onUploadFile() {
        this.ngxLoader.start();
        this.importProvider.importExcel(this.excelImporterOptions).subscribe(response => {
            if (!response.Errors?.length) {
                this.messagesService.success('label.excelImporterTab.importOK');
            }
            this.errorsAfterUpload = response.Errors ?? null;
            this.ngxLoader.stop();
        }, err => {
            this.messagesService.handlingErrorMessage(err);
            this.ngxLoader.stop();
        });
    }


    onFileSelected(event: Event) {
        if ((event.target as HTMLInputElement)?.files?.length > 0) {
            this.excelImporterOptions.excelFile = (event.target as HTMLInputElement).files[0];
        }
    }

    // clears input value so change event is triggered if same file is picked again
    onFileInputClick(event: Event) {
        (event.target as HTMLInputElement).value = null;
    }

    isUploadFileButtonEnabled(): boolean {
        return this.excelImporterOptions.dataType && !!this.excelImporterOptions.excelFile;
    }

    getLabelForOptionSelect(option: string): string {
        return 'label.excelImporterTab.import' + (option === 'ResourceWorkSchedules' ? 'ResourceWorkSchedule' : option);
    }

}
