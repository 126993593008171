import {Component, OnInit, OnDestroy} from '@angular/core';
import {forkJoin} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    ConfirmDeleteModalService,
    EntityTypeEnum,
    MultiResourceBluePrintProvider,
    MultiResourceBluePrintType,
    ResourceDependentFiltersType,
    ResourceTypeProvider,
    ResourceTypeType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import {MultiResourceBluePrintMdUtils} from './multi-resource-blue-print-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-multi-resource-blue-print-md',
    templateUrl: './multi-resource-blue-print-md.component.html',
    styleUrls: ['./multi-resource-blue-print-md.component.scss']
})
export class MultiResourceBluePrintMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    multiResourceBluePrintMdList: MultiResourceBluePrintType[] = [];
    resourceTypes: ResourceTypeType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    MULTI_RESOURCE_BLUE_PRINT = 'MultiResourceBluePrint';
    CONSTANTS = constants;
    VIEW_ACTIVITY_ENABLED = false;
    resourceTypeDependentFilters: ResourceDependentFiltersType = this.multiResourceBluePrintMdUtils.getResourceDependentFilters();
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public confirmDeleteService: ConfirmDeleteModalService,
        public generalUtils: GeneralUtils,
        public messagesService: MessagesService,
        public multiResourceBluePrintProvider: MultiResourceBluePrintProvider,
        public ngxLoader: NgxUiLoaderService,
        public resourceTypeProvider: ResourceTypeProvider,
        public router: Router,
        public multiResourceBluePrintMdUtils: MultiResourceBluePrintMdUtils,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('MultiResourceBluePrintRead');

        if (this.previousRouteService.getPreviousUrl() !== '/createMultiResourceBluePrint') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadMultiResourceBlueprintsAndResourceTypes();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create MultiResourceBluePrint
    createMultiResourceBluePrint(): void {
        this.router.navigate(['/createMultiResourceBluePrint']);
    }

    editMultiResourceBluePrint(multiResourceBluePrint: MultiResourceBluePrintType) {
        this.router.navigate(['/createMultiResourceBluePrint'], {
            state: {
                multiResourceBluePrint,
                action: this.CONSTANTS.EDIT
            }
        });
    }

    viewMultiResourceBluePrint(multiResourceBluePrint: MultiResourceBluePrintType) {
        this.router.navigate(['/createMultiResourceBluePrint'], {
            state: {
                multiResourceBluePrint,
                action: this.CONSTANTS.VIEW
            }
        });
    }


    getResourceName(blueprint: MultiResourceBluePrintType, isMainResource: boolean): string | string[] {
        if (isMainResource) {
            return this.resourceTypes.find(resourceType =>
                blueprint.items.find(item => item.isMainResource)?.resourceTypeId === resourceType.id
            )?.name;
        }
        return this.resourceTypes.filter(resourceType =>
            blueprint.items.filter(item => !item.isMainResource && item.resourceTypeId === resourceType.id).length > 0
        )?.map(resourceType => {
            return resourceType.name;
        });
    }

    onSelectedResourceTypeFilter(resourceFilterList: any[]) {
        this.multiResourceBluePrintMdUtils.initialResourceTypeFilterValues = resourceFilterList;
        this.multiResourceBluePrintMdUtils.tableFilters.filter.resourceType = (resourceFilterList && resourceFilterList.length > 0) ? resourceFilterList[0].id : undefined;
        this.loadMultiResourceBluePrintMdData();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.multiResourceBluePrintMdUtils.tableFilters.currentPage = page;
        this.loadMultiResourceBluePrintMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.multiResourceBluePrintMdUtils.tableFilters.currentPage = 1;
        this.multiResourceBluePrintMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadMultiResourceBluePrintMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.multiResourceBluePrintMdUtils.tableFilters.orderBy[property];
        if (this.multiResourceBluePrintMdUtils.tableFilters.orderBy) {
            this.multiResourceBluePrintMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadMultiResourceBluePrintMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.multiResourceBluePrintMdUtils.tableFilters.orderBy[selectedItem];
        this.loadMultiResourceBluePrintMdData(false);
    }

    displayConfirmDeleteItemModal(multiResourceBluePrintId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.multiResourceBluePrintProvider, multiResourceBluePrintId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(multiResourceBluePrintId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    // method to load both resourceTypes and multiResourceBluePrints simultaneously
    private loadMultiResourceBlueprintsAndResourceTypes() {
        this.ngxLoader.start();
        forkJoin([
            this.getResourceTypesQuery(),
            this.getMultiResourceBluePrintMdQuery()
        ]).subscribe(([resourceTypes, multiResourceBlueprints]) => {
            this.resourceTypes = resourceTypes.value;
            this.multiResourceBluePrintMdList = multiResourceBlueprints.value;
            if (multiResourceBlueprints.count !== undefined && multiResourceBlueprints.count !== null) {
                this.totalTableItems = multiResourceBlueprints.count;
            }
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private getResourceTypesQuery() {
        const queryFilter = this.multiResourceBluePrintMdUtils.getQueryFilterForResourceTypes();
        return this.resourceTypeProvider.getEntries(queryFilter);
    }

    private getMultiResourceBluePrintMdQuery(includeCount: boolean = true) {
        const queryFilter = this.multiResourceBluePrintMdUtils.getQueryFilterForMultiResourceBluePrintMD(this.multiResourceBluePrintMdUtils.tableFilters, includeCount);
        return this.multiResourceBluePrintProvider.getEntries(queryFilter).pipe(take(1));
    }

    // function to get MultiResourceBluePrint data
    private loadMultiResourceBluePrintMdData(includeCount: boolean = true) {
        this.ngxLoader.start();
        this.getMultiResourceBluePrintMdQuery(includeCount)
            .subscribe(({value, count}) => {
                this.multiResourceBluePrintMdList = value;
                if (count !== undefined && count !== null) {
                    this.totalTableItems = count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadMultiResourceBluePrintMdData(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.multiResourceBluePrintMdUtils.tableFilters = this.multiResourceBluePrintMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.multiResourceBluePrintMdUtils.initialResourceTypeFilterValues = [];
        }
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.multiResourceBluePrintMdUtils.tableFilters = this.multiResourceBluePrintMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.multiResourceBluePrintProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadMultiResourceBluePrintMdData();
                this.messagesService.success('toastr.success.multiResourceBlueprintDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
