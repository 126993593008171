import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    RuleManagementWeightedCombinationsProvider,
    RuleManagementWeightedCombinationType
} from 'sked-base';
import {ScreenTemplateLayoutType, TableFiltersType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {RulesWeightedCombinationMdUtils} from '../rules-weighted-combination-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {
    ValidRuleManagementWeightedCombinationObjectType,
    ValidTemplateRuleManagementWeightedCombinationEnum
} from '../rules-weighted-combination-md.types';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-rules-weighted-combination',
    templateUrl: './create-rules-weighted-combination.component.html',
    styleUrls: ['./create-rules-weighted-combination.component.scss']
})
export class CreateRulesWeightedCombinationComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;
    constants = constants;
    initialRulesWeightedCombination: RuleManagementWeightedCombinationType;
    rulesWeightedCombinationItem: RuleManagementWeightedCombinationType = {} as RuleManagementWeightedCombinationType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    totalStepsCount: number;
    validTemplate = {} as ValidRuleManagementWeightedCombinationObjectType;
    validTemplateRuleManagementWeightedCombinationEnum = ValidTemplateRuleManagementWeightedCombinationEnum;
    tableFilters: TableFiltersType;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private rulesWeightedCombinationMdUtils: RulesWeightedCombinationMdUtils,
        private ruleManagementWeightedCombinationsProvider: RuleManagementWeightedCombinationsProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        // we need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(key));
        });
        if (this.myStepper?._steps?.length) {
            // set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.myStepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
        //  it’s triggered when change detection for all child components have been
        //  performed and so they all had possibility to update parent components property
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    saveRulesWeightedCombinationData(rulesWeightedCombination: RuleManagementWeightedCombinationType): void {
        const isTemplateValid = this.isTheWholeFormValid();
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRulesWeightedCombination(rulesWeightedCombination);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRulesWeightedCombination, rulesWeightedCombination)) {
                    this.messagesService.success('toastr.success.rulesWeightedCombinationEdit', true);
                    this.goToParentPage();
                } else {
                    this.editRulesWeightedCombination(this.initialRulesWeightedCombination, rulesWeightedCombination);
                }
            }
        }
    }

    onStepHeaderClick(stepClicked: number): void {
        const selectedIndex = this.myStepper.selectedIndex;
        // click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
        // user just clicked on stepClicked and the move was successful
        this.displayErrorToastr(selectedIndex, stepClicked);
    }

    goBack(stepper: MatStepper): void {
        stepper.previous();
    }

    goForward(stepper: MatStepper): void {
        if (this.areStepsValid(stepper.selectedIndex)) {
            stepper.next();
        } else {
            this.displayErrorToastr(stepper.selectedIndex, stepper.selectedIndex + 1);
        }
    }

    onStepChange(e) {
    }

    // used to check whether you can go to other steps or not
    getStepControl(step: number): { invalid?: boolean, pending?: boolean } {
        return {
            invalid: !this.areStepsValid(step)
        };
    }

    areStepsValid(currentStep: number): any {
        switch (currentStep) {
            case 0:
                return this.isStepOneValid();
            default:
                return true; // other steps which don't need validation
        }
    }

    goToParentPage(): void {
        this.router.navigate(['/rulesWeightedCombinations']);
    }

    goToEdit() {
        history.replaceState({
            rulesWeightedCombination: this.rulesWeightedCombinationItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private isTheWholeFormValid(): boolean {
        this.isStepOneValid();
        return this.areStepsValid(0);
    }

    private displayErrorToastr(selectedIndex, clickedIndex): void {
        // error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!this.areStepsValid(step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    this.myStepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    private setValidTemplateKey(validTemplateKey: ValidTemplateRuleManagementWeightedCombinationEnum): void {
        const key = `is${lodash.upperFirst(validTemplateKey)}Valid`;
        this.validTemplate[key] = this.rulesWeightedCombinationItem[validTemplateKey] >= 0
            && this.rulesWeightedCombinationItem[validTemplateKey] <= 255 && Number.isInteger(this.rulesWeightedCombinationItem[validTemplateKey]);
    }

    // function to create the new RulesWeightedCombination
    private createRulesWeightedCombination(rulesWeightedCombination: RuleManagementWeightedCombinationType): void {
        this.ngxLoader.start();
        this.ruleManagementWeightedCombinationsProvider.addEntry(rulesWeightedCombination)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newRulesWeightedCombinationAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the RulesWeightedCombination
    private editRulesWeightedCombination(oldRulesWeightedCombination: RuleManagementWeightedCombinationType, newRulesWeightedCombination: RuleManagementWeightedCombinationType): void {
        this.ngxLoader.start();
        this.ruleManagementWeightedCombinationsProvider.updateEntry(oldRulesWeightedCombination, newRulesWeightedCombination)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.rulesWeightedCombinationEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private isStepOneValid(): boolean {
        Object.values(this.validTemplateRuleManagementWeightedCombinationEnum).forEach((value) => {
            this.setValidTemplateKey(value);
        });
        const {
            isAreaValid,
            isSpecialityValid,
            isServiceValid,
            isResourceValid,
            isCenterValid,
            isCoveragePlanValid,
            isChannelValid,
            isPatientTagValid
        } = this.validTemplate;
        return isAreaValid && isSpecialityValid && isServiceValid && isResourceValid
            && isCenterValid && isCoveragePlanValid && isChannelValid && isPatientTagValid;
    }

    private setupInitialState(): void {
        this.tableFilters = this.rulesWeightedCombinationMdUtils.getInitialTableFilter();
        if (history.state && history.state.rulesWeightedCombination) {
            this.initialRulesWeightedCombination = history.state.rulesWeightedCombination;
            this.rulesWeightedCombinationItem = lodash.cloneDeep(history.state.rulesWeightedCombination);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.rulesWeightedCombinationItem = this.rulesWeightedCombinationMdUtils.getInitialRulesWeightedCombination();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.validTemplate = this.rulesWeightedCombinationMdUtils.getInitialValidTemplateObject();
        this.isStepOneValid();
    }
}
