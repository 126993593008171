<div class="container-fluid area-md-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="area-md-header">
        <h3 class="area-md-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase">{{'label.area2'|translate}}</span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="area-md-section create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{areaMDItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.position"></td>
                                    <td class="text-right">{{areaMDItem.position}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{areaMDItem?.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.multipleSlots"></td>
                                    <td class="text-right">
                                        <span *ngIf="areaMDItem.multipleSlots" translate="label.yes"></span>
                                        <span *ngIf="!areaMDItem.multipleSlots" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.requiresWorkOrder"></td>
                                    <td class="text-right">
                                        <span *ngIf="areaMDItem.requiresWorkOrder" translate="label.yes"></span>
                                        <span *ngIf="!areaMDItem.requiresWorkOrder" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.channelRestrictions"></td>
                                    <td class="text-right">
                                        <span
                                            *ngFor="let channelRestriction of areaMDItem.channelRestrictions; last as islast">
                                            {{channelRestriction.channel}}<span *ngIf="!islast">, </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.hasAttachment"></td>
                                    <td class="text-right">
                                        <span *ngIf="uploadFileUtils.fileId" translate="label.yes"></span>
                                        <span *ngIf="!uploadFileUtils.fileId" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   placeholder="{{'label.name'|translate}}"
                                   [(ngModel)]="areaMDItem.name"
                                   (ngModelChange)="onInputChanged(areaMDItem)"
                                   [ngClass]="{'input-element-has-error': !areaMDItem.name
                               && screenTemplateLayout.action !== constants.VIEW}"
                                   required autocomplete="off">
                            <div *ngIf="!areaMDItem.name && screenTemplateLayout.action !== constants.VIEW"
                                 class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.position"></label>
                            <input type="number" class="input-element"
                                   placeholder="{{'label.position'|translate}}"
                                   name="position" #position="ngModel"
                                   min="1"
                                   [(ngModel)]="areaMDItem.position" pattern="^([1-9][0-9]{0,2})$"
                                   [ngClass]="{'input-element-has-error': (!areaMDItem.position || position.errors?.pattern)
                                   && screenTemplateLayout.action !== constants.VIEW}"
                                   required autocomplete="off">
                            <div *ngIf="screenTemplateLayout.action !== constants.VIEW">
                                <div *ngIf="areaMDItem.position === null" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                                <div *ngIf="position.errors?.pattern" class="help-block">
                                    <span class="inValidPosition" translate="label.invalidField"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'disabled-input': (screenTemplateLayout.action !== constants.CREATE) }"
                             *ngIf="screenTemplateLayout.action !== constants.CREATE">
                            <label translate="label.shortId"></label>
                            <input type="number" name="shortId" class="input-element"
                                   placeholder="{{'label.shortId'|translate}}" [(ngModel)]="areaMDItem.shortId" disabled
                                   required
                                   autocomplete="off">
                        </div>
                        <!--Multiple slots-->
                        <div class="form-group">
                            <label translate="label.multipleSlots"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked name="multipleSlots"
                                           [(ngModel)]="areaMDItem.multipleSlots">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                        <!--Requires Work Order-->
                        <div class="form-group"
                             [featureDisplay]="'admin-WorkOrder'">
                            <label translate="label.requiresWorkOrder"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked name="requiresWorkOrder"
                                           [(ngModel)]="areaMDItem.requiresWorkOrder">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.channelRestrictions"></label>
                            <div class="d-flex justify-content-between pr-5">
                                <div>
                                    <label class="checkbox d-flex align-items-center" *ngFor="let item of channels">
                                        <input type="checkbox" name="channel" value="item.selected"
                                               [(ngModel)]="item.selected"
                                               [ngModelOptions]="{standalone: true}"
                                               (ngModelChange)="onChannelRestrictionChange(item)">
                                        <span>{{('label.channels.' + item.enumValue) | translate}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-upload-file [options]="uploadFileOptions"></app-upload-file>
                </div>
            </ng-container>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="area-md-buttons mt-2 footer-position-sticky">
        <button class="btn mr-3"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                (click)="goToParentPage()"> {{screenTemplateLayout.cancelOrBackButton | translate}}
        </button>
        <button class="btn theme-btn" (click)="saveAreaMDData(areaMDItem)"
                [disabled]="!isTemplateValid"
                [hidden]="screenTemplateLayout.action == VIEW">
            <span translate="{{screenTemplateLayout.createOrSaveButton}}"></span>
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===constants.VIEW"
                [activityDisplay]="'AreaUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!-- =========================================== -->
    <!--End Footer-->
</div>
