import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {DisplayScheduleOccurrencesModalComponent} from './display-schedule-occurrences-modal.component';
import {ScheduleOccurrencesForActivityPlanType} from 'sked-base';

@Injectable({
    providedIn: 'root'
})
export class DisplayScheduleOccurrencesModalService {

    constructor(
        private modalService: NgbModal
    ) {
    }

    displayModal(occurrences: ScheduleOccurrencesForActivityPlanType[]) {
        const modalOptions = this.getModalOptions();
        const modalRef = this.modalService.open(DisplayScheduleOccurrencesModalComponent, modalOptions);
        modalRef.componentInstance.occurrences = occurrences;
    }

    private getModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: true,
            size: 'xl',
            windowClass: 'schedule-occurrences-modal',
        };
    }
}
