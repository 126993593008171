export const systemConfigKeys = {
    ACTIVATE_ICAL: 'ActivateICAL',
    APPOINTMENT_STATUS_UPDATE_DISABLE_TIME_CHECK: 'AppointmentStatusUpdateDisableTimeCheck',
    AUTO_APPROVE_OFFICE_365_EXCEPTIONS: 'AutoApproveOffice365Exceptions',
    BOOKING_PORTAL_DISABLE_CAPTCHA: 'BookingPortalDisableCaptcha',
    CENTER_LOCK: 'CenterLock',
    CLIENT_IDENTIFICATION_DISABLE_CAPTCHA: 'ClientIdentificationDisableCaptcha',
    CREATE_PORTAL_DISABLE_CAPTCHA: 'CreatePortalDisableCaptcha',
    WORK_ORDER_PORTAL_DISABLE_CAPTCHA: 'WorkOrderPortalDisableCaptcha',
    DISABLE_ALTERNATIVE_LOCATIONS: 'DisableAlternativeLocations',
    DOCUMENT_TYPES_DISABLE_NATIONAL_ID: 'DocumentTypesDisableNationalId',
    DOCUMENT_TYPES_DISABLE_PASSPORT: 'DocumentTypesDisablePassport',
    GENDER_ALLOWED_FEMALE: 'GenderAllowedFemale',
    GENDER_ALLOWED_MALE: 'GenderAllowedMale',
    GENDER_ALLOWED_UNDETERMINED: 'GenderAllowedUndetermined',
    GENDER_ALLOWED_UNKNOWN: 'GenderAllowedUnknown',
    NOTIFICATION_EMAIL_APPOINTMENT_CREATED: 'NotificationEmailAppointmentCreated',
    NOTIFICATION_EMAIL_APPOINTMENT_CONFIRMED: 'NotificationEmailAppointmentConfirmed',
    NOTIFICATION_EMAIL_APPOINTMENT_CANCELLED: 'NotificationEmailAppointmentCancelled',
    PATIENT_PORTAL_ALWAYS_INCLUDE_SELF_PAYER: 'PatientPortalAlwaysIncludeSelfPayer',
    RESTRICT_RECEPTION_TO_ASSIGNED_LOCATIONS: 'RestrictReceptionToAssignedLocations',
    RESTRICT_RESOURCE_TO_ASSIGNED_DATA: 'RestrictResourceToAssignedData',
    SELF_SERVICE_PASSWORD_CHANGE: 'SelfServicePasswordChange',
    SLOT_SEARCH_WITH_RANGE_ACTIVE: 'SlotSearchWithRangeActive',
    FROM_EMAIL: 'FromEmail',
    EMAIL_DISPLAY_NAME: 'EmailDisplayName',
    GOOGLE_TAG_MANAGER_ID: 'GoogleTagManagerId',
    PATIENT_ACCESS_CUSTOM_CSS: 'PatientAccessCustomCSS',
    TIME_WINDOW_MAXIMUM: 'TimeWindowMaximum',
    WAITLIST_EXPIRATION_TIME_IN_MINUTES: 'WaitListExpirationTimeInMinutes',
    WAITLIST_MINIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES: 'WaitListMinimumTimeForFutureAppointmentsInMinutes',
    WAITLIST_MAXIMUM_TIME_FOR_FUTURE_APPOINTMENTS_IN_MINUTES: 'WaitListMaximumTimeForFutureAppointmentsInMinutes',
    SERVICES_AND_RESOURCE_SEARCH_INCLUDE_SERVICE_NAME_SINGLE_HIT: 'ServicesAndResourceSearchIncludeServiceNameSingleHit',
    DEFAULT_COUNTRY: 'DefaultCountry',
    LOGO_NAME: 'LogoName',
    LOGO_NAME_PATIENT_PORTAL: 'LogoNamePatientPortal',
    PRINTOUT_HEADER_NAME: 'PrintoutHeaderName',
    EMAIL_HEADER_NAME: 'EmailHeaderName',
    FAVICON_NAME_PATIENT_PORTAL: 'FaviconNamePatientPortal',
    PATIENT_ACCESS_ACTIVATE_RESULT_LAYOUT_V2: 'PatientAccessActivateResultLayoutV2',
    PATIENT_ACCESS_LOGO_TOP: 'PatientAccessLogoTop',
    SLOT_SEARCH_RESOURCE_STEP: 'SlotSearchResourceStep',
    SLOT_SEARCH_SERVICE_STEP: 'SlotSearchServiceStep',
    MULTI_APPOINTMENT_BOOKING_CALENDAR_SLOT_SEARCH_MAX_TIME_WINDOW: 'MultiAppointmentBookingCalendarSlotSearchMaxTimeWindow',
    SKED_TASK_TYPE_STANDARD: 'Standard',
    SKED_TASK_TYPE_WAIT_LIST: 'WaitList',
    SKED_TASK_TYPE_ADVANCED_BOOKING_PLANNER: 'AdvancedBookingPlanner',
    RESERVATION_LIFE_TIMES: 'ReservationLifeTimes',
    ALLOW_TENANT_TO_DISABLE_CAPTCHA: 'AllowTenantToDisableCaptcha',
    PATIENT_ACCESS_TASK_TYPE: 'PatientAccessTaskType',
    APPOINTMENT_BLOCKING_DUE_DATE: 'AppointmentBlockingDueDate',
    WAIT_LIST_PATIENT_ACCESS_MANUAL_DUE_DATE: 'WaitListPatientAccessManualDueDate',
    NO_PAYMENT_FOR_RESCHEDULE: 'NoPaymentForReschedule',
    PATIENT_ACCESS_LAST_NAME_SPLIT: 'PatientAccessLastNameSplit',
    BACKOFFICE_LOGOUT_TIMEOUT: 'BackofficeLogoutTimeout',
    PATIENT_ACCESS_CONTACT_CENTER_PHONE_NUMBER: 'PatientAccessContactCenterPhoneNumber',
    PATIENT_ACCESS_SELECTED_CENTER_SHORT_ID: 'PatientAccessSelectedCenterShortId',
    RESOURCE_DEFAULT_IMAGE_PATIENT_PORTAL: 'ResourceDefaultImagePatientPortal'
};

