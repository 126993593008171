<div class="container-fluid exclusion-approve-view-screen overview-screen-md create-entity-md-wrapper pb-1">
    <div class="create-entity-md-section">
        <h3 class="text-danger" *ngIf="action !== constants.VIEW">
            {{textToDisplay | translate}}
        </h3>
        <h3 class="create-availability-title" *ngIf="action === constants.VIEW">{{textToDisplay | translate}}
            <span class="text-lowercase" translate="label.exclusion"></span>
        </h3>

        <div class="form-group"
             *ngIf="action!==constants.DECLINE && (exclusion.status==='MarkedForDelete' || exclusion.status==='Edited')"
             [featureDisplay]="'admin-availabilities-unblock'">
            <label translate="label.unblockAppointments"></label>
            <div class="switch">
                <label>
                    <span translate="label.no"></span>
                    <input type="checkbox" checked
                           [(ngModel)]="exclusion.unblockAppointments">
                    <span class="lever switch-col-black"></span>
                    <span translate="label.yes"></span>
                </label>
            </div>
        </div>
        <app-view-exclusion [exclusion]="exclusion"></app-view-exclusion>
        <div class="mt-2">
            <button class="btn mr-3"
                    (click)="goToParent()"
                    [ngClass]="action === constants.VIEW ? 'back-btn' : 'close-btn'"
                    translate="{{action === constants.VIEW ? 'button.back' : 'label.no'}}">
            </button>
            <button class="btn theme-btn" *ngIf="action === constants.APPROVE || action === constants.DECLINE"
                    (click)="onChangeStatusExclusion(exclusion, action)"
                    translate="label.yes">
            </button>
            <button class="btn theme-btn mr-3"  *ngIf="action === constants.VIEW"
                    (click)="onChangeStatusExclusion(exclusion, constants.APPROVE)"
                    translate="label.availabilityTab.approve">
            </button>
            <button class="btn close-btn" *ngIf="action === constants.VIEW"
                    (click)="onChangeStatusExclusion(exclusion, constants.DECLINE)"
                    translate="label.availabilityTab.decline">
            </button>
        </div>
    </div>
    <div class="separator mt-3" [activityDisplay]="EXCLUSION+constants.BLOCKING_SIMULATION"></div>
    <div class="blocked-appointments overflow-auto" [activityDisplay]="EXCLUSION+constants.BLOCKING_SIMULATION">
        <app-blocked-appointments-preview></app-blocked-appointments-preview>
    </div>
</div>
