<div class="container-fluid action-execution-logs-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.actionExecutionLogsTitle"></h3>
        <div class="container mr-0">
            <div class="row justify-content-end">
                <div class="col-2 pr-0 ml-auto">
                    <ng-select
                        class="form-control text-high-contrast rounded-1 custom-ng-select"
                        loadingText="{{'label.loading' | translate}}"
                        dropdownPosition="bottom"
                        placeholder="{{'label.type' | translate}}"
                        [(ngModel)]="filters.actionTypeFilterOptions.ngModel"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [ngClass]="!!filters.actionTypeFilterOptions.ngModel ? 'ng-select-has-value' : null"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="applyFilter($event, 'actionTypeFilterOptions')">
                        <ng-option [value]="'all'">
                            <span translate="label.all"></span>
                        </ng-option>
                        <ng-option *ngFor="let action of ActionTypeList"
                                   [value]="action">
                            <span [translate]="'enum.eventActionType.' + action"></span>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-2 pr-0 pl-1 ">
                    <ng-select
                        class="form-control text-high-contrast rounded-1 custom-ng-select"
                        loadingText="{{'label.loading' | translate}}"
                        dropdownPosition="bottom"
                        placeholder="{{'label.status' | translate}}"
                        [(ngModel)]="filters.statusFilterOptions.ngModel"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [ngClass]="!!filters.statusFilterOptions.ngModel ? 'ng-select-has-value' : null"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="applyFilter($event, 'statusFilterOptions')">
                        <ng-option [value]="'all'">
                            <span translate="label.all"></span>
                        </ng-option>
                        <ng-option *ngFor="let status of ActionExecutionStatusList"
                                   [value]="status">
                            <span [translate]="'enum.statusType.' + status"></span>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-2 pr-0 pl-1">
                    <input type="text"
                           class="form-control text-high-contrast rounded-1"
                           placeholder="{{'label.relatedEntityId' | translate}}"
                           [ngClass]="{'input-is-invalid': !this.isRelatedEntityTypeValid}"
                           [(ngModel)]="filters.relatedEntityId.ngModel"
                           (ngModelChange)="applyInputFilter($event)">
                </div>
                <div class="col-2 pr-0 pl-1">
                    <ng-select
                        class="form-control text-high-contrast rounded-1 custom-ng-select"
                        loadingText="{{'label.loading' | translate}}"
                        dropdownPosition="bottom"
                        placeholder="{{'label.relatedEntityType' | translate}}"
                        [(ngModel)]="filters.relatedEntityTypeModalFilterOptions.ngModel"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [ngClass]="{'ng-select-has-value': !!filters.relatedEntityTypeModalFilterOptions.ngModel,
                                    'ng-select-is-invalid': !this.isRelatedEntityIdValid}"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="applyFilter($event, 'relatedEntityTypeModalFilterOptions')">
                        <ng-option [value]="'all'">
                            <span translate="label.all"></span>
                        </ng-option>
                        <ng-option *ngFor="let relatedEntity of RelatedEntityTypeList"
                                   [value]="relatedEntity">
                            <span [translate]="'enum.entityType.' + relatedEntity"></span>
                        </ng-option>
                    </ng-select>
                </div>
                <div class="pl-2">
                    <sbase-show-template-modal-filter-container
                        [contentTemplate]="actionExecutionLogsModalFiltersContentTemplate"
                        [areFiltersActive]="modalFilters.areFiltersActive"
                        [title]="'label.filterMore'"
                        (modalClose)="handleFilterChangeAndLoadData($event)"
                    ></sbase-show-template-modal-filter-container>
                </div>
                <div class="text-center">
                    <button class="reset-button btn pt-2 pb-2 pl-3 pr-3" (click)="onResetFilters()">
                        <i class="far fa-trash-alt"></i>
                        <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
                    </button>
                </div>
            </div>
        </div>

        <ng-template #actionExecutionLogsModalFiltersContentTemplate>
            <div class="container">
                <div class="text-info mb-3 mt-2">
                    <i class="fa-solid fa-circle-exclamation"></i>&nbsp;
                    <span translate="label.dateIsRelatedToBrowserTimezoneMessage"></span>
                </div>
                <div class="row">
                    <div class="mb-2 col-12">
                        <label class="mb-2 mr-2 text-high-contrast" translate="label.createdOn"></label>
                        <div class="p-0">
                            <sbase-date-range
                                class="input-element"
                                [options]="modalFilters.dateRangeOptions"
                                (onDateRangeChanged)="onDateRangeChanged($event)">
                            </sbase-date-range>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="sort-search-wrapper text-high-contrast d-flex justify-content-between mt-3">
        <div class="sort">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(tableFilters.orderBy)">
                    <span
                        *ngIf="key">{{'label.' + key |translate}}
                    </span>
                    <span>
                        - {{'label.' + tableFilters.orderBy[key] | translate}}
                    </span>
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('actionType')">
                    {{'label.actionType' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.actionType"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="tableFilters.orderBy.actionType === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="tableFilters.orderBy.actionType === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.status"></th>
                <th class="starting-column cursor-pointer" (click)="onSortBy('createdOn')">
                    {{'label.createdOn' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!tableFilters.orderBy.createdOn"><i
                        class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="tableFilters.orderBy.createdOn === 'asc'"><i
                        class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="tableFilters.orderBy.createdOn === 'desc'"><i
                        class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.relatedEntityId"></th>
                <th class="text-center" translate="label.relatedEntityType"></th>
                <th class="text-center" translate="label.actionCallDetails"></th>
                <th class="text-center" translate="label.messageDetail"></th>
                <th class="text-center" translate="label.response"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let actionExecutionLogs of actionExecutionLogsMdList">
                <td class="name-column pt-2 pb-2">{{actionExecutionLogs?.actionExecutionLog?.actionType}}</td>
                <td class="text-center">{{'enum.statusType.' + actionExecutionLogs?.actionExecutionLog?.status | translate}}</td>
                <td class="starting-column">{{actionExecutionLogs?.createdOn | fullDateFormatPipe}}</td>
                <td class="text-center">{{actionExecutionLogs?.relatedEntityId}}</td>
                <td class="text-center">{{'enum.entityType.' + actionExecutionLogs?.relatedEntityType | translate}}</td>
                <td class="text-center">{{actionExecutionLogs?.actionCallDetail}}</td>
                <td class="text-center max-width-200">
                    {{getShortenedText(actionExecutionLogs?.messageDetail)}}
                    <div *ngIf="actionExecutionLogs?.messageDetail"
                         [ngbPopover]="messageDetailPopover"
                         class="fas fa-ellipsis-h cursor-pointer mr-1"
                         placement="bottom"
                         container="body"
                         popoverClass="popover-shadow">
                    </div>
                    <i class="fa-regular fa-copy" *ngIf="actionExecutionLogs?.messageDetail"
                       ngbTooltip="{{'label.copy'| translate}}"
                       (click)="copyText(actionExecutionLogs?.messageDetail)"
                    ></i>
                    <ng-template #messageDetailPopover>
                        <div class="popover-container">
                            {{actionExecutionLogs?.messageDetail}}
                        </div>
                    </ng-template>
                </td>
                <td class="text-center max-width-200">
                    {{getShortenedText(actionExecutionLogs?.response)}}
                    <div *ngIf="actionExecutionLogs?.response"
                         [ngbPopover]="responsePopover"
                         class="fas fa-ellipsis-h cursor-pointer mr-1"
                         placement="bottom-right"
                         container="body"
                         popoverClass="popover-shadow">
                    </div>
                    <i class="fa-regular fa-copy" *ngIf="actionExecutionLogs?.response"
                       ngbTooltip="{{'label.copy'| translate}}"
                       (click)="copyText(actionExecutionLogs?.response)"
                    ></i>
                    <ng-template #responsePopover>
                        <div class="popover-container">
                            {{actionExecutionLogs?.response}}
                        </div>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems"
                        [(page)]="tableFilters.currentPage"
                        [maxSize]="5"
                        [pageSize]="tableFilters.itemsPerPage"
                        [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"
        ></ngb-pagination>
        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
