import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as lodash from 'lodash';
import {MultiAppointmentBookingMdUtils} from '../../multi-appointment-booking.utils';
import {
    MabSlotDisplayType,
    MabSlotListOptionsType,
    MabSlotListWrapperOutputType
} from '../../multi-appointment-booking.types';
import {SlotDisplayType} from 'sked-base';
import {
    SlotResourceAdditionalInfoOptionsType
} from '../../../../shared/component/slot-resource-additional-info/slot-resource-additional-info.types';

@Component({
    selector: 'app-mab-slots-list',
    templateUrl: './mab-slots-list.component.html',
    styleUrls: ['./mab-slots-list.component.scss']
})
export class MabSlotsListComponent implements OnInit {
    @Input() listId: number;
    @Output() selectSlot: EventEmitter<MabSlotListWrapperOutputType> = new EventEmitter<MabSlotListWrapperOutputType>();
    @Output() removeList: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils
    ) {
    }

    ngOnInit(): void {
    }

    onScrollDown() {
        this.getOptions().slotsResultsSliceUpperBound = Math.min(
            this.getOptions().displaySlots.length,
            this.getOptions().slotsResultsSliceUpperBound +
            this.multiAppointmentBookingMdUtils.INITIAL_SLOTS_RESULTS_SLICE_UPPER_BOUND
        );
    }

    onSelectSlot(slot: MabSlotDisplayType) {
        if (slot.bookable) {
            this.selectSlot.emit({slot, listId: this.listId});
        }
    }

    onRemoveList() {
        this.removeList.emit(this.listId);
    }

    onMouseEnterSlot(slot: MabSlotDisplayType) {
        slot.hovered = true;
        this.multiAppointmentBookingMdUtils.setHoverToReservedSlot(this.listId, slot, {hovered: true});
    }

    onMouseLeaveSlot(slot: MabSlotDisplayType) {
        slot.hovered = false;
        this.multiAppointmentBookingMdUtils.setHoverToReservedSlot(this.listId, slot, {hovered: false});
    }

    getOptions(): MabSlotListOptionsType {
        return lodash.find(this.multiAppointmentBookingMdUtils?.slotsListsWrapperOptions?.slotsListsOptions ?? [], {listId: this.listId});
    }

    getSlotResourceAdditionalInfoOptions(
        {resource, secondaryResources, bookable}: SlotDisplayType, isMainResource: boolean, isFirstSlot: boolean, isLastSlot: boolean
    ): SlotResourceAdditionalInfoOptionsType {
        return {
            mainResource: resource,
            secondaryResources,
            isMainResource,
            resourceTabToPreselect: isMainResource ? resource?.name : secondaryResources[0]?.name,
            isFirstSlot,
            isLastSlot,
            bookable
        };
    }
}
