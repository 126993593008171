import {Injectable} from '@angular/core';
import {flatMap} from 'rxjs/internal/operators';
import {of} from 'rxjs';
import {take} from 'rxjs/operators';
import {FileContentType} from 'sked-base/lib/data-model/fileTypes';
import {EntityTypeEnum, FileProvider, FileUploadOptionsType} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';

@Injectable({
    providedIn: 'root'
})
export class UploadFileUtils {
    uploadedFile: File = {} as File; // contains only the last uploaded file
    initialUploadedFile: File = {} as File;
    lastInvalids: { file: File, type: string }[] = [];
    fileId: string;
    uploadFileOptions = {} as FileUploadOptionsType;
    acceptedFormatsString = '';

    constructor(private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private fileProvider: FileProvider) {
    }

    getUploadedFile(itemId: string, entityType: EntityTypeEnum) {
        this.ngxLoader.start();
        this.fileProvider.getFileEntitiesForEntity(entityType, itemId)
            .pipe(
                flatMap(response => {
                    if (response?.value[0]?.id) {
                        this.fileId = response.value[0].id;
                        return this.fileProvider.getFileById(this.fileId);
                    } else {
                        return of({file: {}});
                    }
                }),
                take(1))
            .subscribe((file: { file: File, fileName: string }) => {
                    this.uploadedFile = file?.file;
                    this.initialUploadedFile = this.uploadedFile;
                    this.ngxLoader.stop();
                },
                err => {
                    if (err.status === 400) {
                        err?.error?.text()?.then((errorJsonString: string) => {
                            const errorJson = JSON.parse(errorJsonString);
                            if ((!!errorJson?.value && errorJson?.value !== 'File not found in storage')
                                || (!!errorJson?.error?.message && errorJson?.error?.message !== 'File not found in storage')) {
                                this.messagesService.handlingErrorMessage(err);
                            }
                        });
                    } else {
                        this.messagesService.handlingErrorMessage(err);
                    }
                    this.ngxLoader.stop();
                });
    }

    parseAndSendFile(entityId: string, entityType: EntityTypeEnum): void {
        const reader = new FileReader();
        // we check if we have uploaded file & if the file is valid
        if (!this.uploadedFile?.name) {
            if (this.lastInvalids?.length > 0) {
                this.messagesService.error('label.error.invalidFormatFileNotSaved');
            }
            return;
        }
        // Renders Image on Page
        reader.readAsBinaryString(this.uploadedFile);

        reader.onload = ev => {
            const {result} = ev.target as any;
            const fileToUpload: any = {
                name: this.uploadedFile.name,
                content: btoa(result)
            };

            const uploadParameters: {
                files: FileContentType[];
                entityId: string;
                entityType: EntityTypeEnum
            } = {
                entityId,
                entityType,
                files: [fileToUpload]
            };
            // This is dependent of reader.onload, so it can`t be moved in another place
            this.ngxLoader.start();
            this.fileProvider.attachFilesToDocument(uploadParameters)
                .subscribe(
                    () => {
                    },
                    error => {
                        this.ngxLoader.stop();
                        this.messagesService.handlingErrorMessage(error);
                    }, () => {
                        this.ngxLoader.stop();
                        this.messagesService.success('toastr.success.fileUploadedWithSuccess', true);
                    });

        };
        reader.onerror = error => {
            this.messagesService.handlingErrorMessage(error);
        };
    }
}
