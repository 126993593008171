<div class="slots-calendar-container"
     *ngIf="!!slotsManagementMdUtils.slotsCalendarOptions"
     [ngClass]="{
        'normal-slots': slotsManagementMdUtils.slotsCalendarOptions.slotsType === slotsTypeEnum.Normal,
        'special-slots': slotsManagementMdUtils.slotsCalendarOptions.slotsType === slotsTypeEnum.Special
     }">
    <!-- Calendar header (Month year, arrows, Today button) -->
    <div class="slots-calendar-header">
        <div class="slots-calendar-month-year-label">
            {{slotsManagementMdUtils.slotsCalendarOptions.displayMonth}} {{slotsManagementMdUtils.slotsCalendarOptions.displayYear}}
        </div>
        <div class="slots-calendar-arrows">
            <div class="slots-calendar-arrow text-high-contrast"
                 (click)="onArrowClick(LEFT)"
                 [ngClass]="{'disabled': slotsManagementMdUtils.slotsCalendarOptions.currentPage <= 0,
                    'before-search': !slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch}"><</div>
            <div class="slots-calendar-arrow text-high-contrast"
                 (click)="onArrowClick(RIGHT)"
                 [ngClass]="{'disabled': !isRightArrowEnabled(),
                    'before-search': !slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch}">></div>
        </div>
        <button class="slots-calendar-today-button"
                [ngClass]="{'before-search': !slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch}"
                translate="label.today"
                (click)="onTodayButtonClick()">
        </button>
    </div>
    <!-- Calendar table -->
    <table class="slots-calendar-table">
        <!-- Table header -->
        <tr class="slots-calendar-table-head-row">
            <th *ngFor="let day of ddFormatWeekDays">{{day}}</th>
        </tr>
        <!-- Table rows -->
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: getCurrentPageOptions().calendarDays.slice(0, 7), rowNumber: 0}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: getCurrentPageOptions().calendarDays.slice(7, 14), rowNumber: 1}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: getCurrentPageOptions().calendarDays.slice(14, 21), rowNumber: 2}"></ng-container>
        <ng-container *ngTemplateOutlet="tableRowTemplate; context: {$implicit: getCurrentPageOptions().calendarDays.slice(21, 28), rowNumber: 3}"></ng-container>
        <!-- Template for table rows -->
        <ng-template #tableRowTemplate let-dayItems let-rowNumber="rowNumber">
            <tr class="slots-calendar-table-row">
                <td class="slots-calendar-table-cell" *ngFor="let dayItem of dayItems; let indexInRow = index">
                <span class="slots-calendar-table-cell-display-container"
                      [ngClass]="{'selectable': !dayItem.isDisabled, 'selected': !!dayItem.isSelected,
                        'disabled': dayItem.isDisabled,
                        'before-search': !slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch}"
                      (click)="onCalendarDaySelected(dayItem, rowNumber * 7 + indexInRow)">
                    <span class="slots-calendar-table-cell-display-day">{{dayItem.displayDay}}</span>
                    <span class="slots-calendar-table-cell-display-slots"
                          [ngClass]="{'empty': !dayItem.displaySlots}">
                        <span *ngIf="dayItem.displaySlots">
                            {{dayItem.displaySlots}}
                        </span>
                    </span>
                </span>
                </td>
            </tr>
        </ng-template>
    </table>
</div>
