import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AppointmentEventRuleProvider,
    AppointmentEventRuleType,
    AppointmentStatusValueEnum,
    EventActionDependentFiltersType,
    EventActionProvider,
    EventActionType,
    EventTypeEnum,
    IncludeExcludeEnum,
    ODataQueryObjectType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, SearchFilterUtils, TagDependentFiltersScopeEnum, TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {TagsType, TagType} from 'sked-base/lib/data-model/tagTypes';
import {BackofficeAppointmentEventRuleTagsType} from '../appointment-event-rule.types';
import {AppointmentEventRuleUtils} from '../appointment-event-rule.utils';
import {RulesUtils} from '../../../rules.utils';
import {AbstractControl} from '@angular/forms';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-appointment-event-rule',
    templateUrl: './create-appointment-event-rule.component.html',
    styleUrls: ['./create-appointment-event-rule.component.scss']
})
export class CreateAppointmentEventRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    initialRule: AppointmentEventRuleType;
    ruleItem: AppointmentEventRuleType = {} as AppointmentEventRuleType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.appointmentEventRuleUtils.getEmptyRuleTableDependentFilters();
    eventActionDependentFilters: EventActionDependentFiltersType = this.appointmentEventRuleUtils.getEmptyEventActionDependentFilters();
    eventTypeEnumList = Object.values(EventTypeEnum);
    EventTypeEnum = EventTypeEnum;
    IncludeExcludeEnum = IncludeExcludeEnum;
    appointmentStatusEnumList = Object.values(AppointmentStatusValueEnum);
    tagsScopedNone: TagsType[] = [];
    tagsScopedResource: TagsType[] = [];
    tagsScopedPatient: TagsType[] = [];
    appointmentEventRuleTags: BackofficeAppointmentEventRuleTagsType;
    mainDependentFilters: {
        centerTags: TagDependentFiltersType,
        serviceTags: TagDependentFiltersType,
        resourceTags: TagDependentFiltersType,
        patientTags: TagDependentFiltersType,
    };

    constructor(
        public ruleTableProvider: RuleTableProvider,
        public eventActionProvider: EventActionProvider,
        public rulesUtils: RulesUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public appointmentEventRuleUtils: AppointmentEventRuleUtils,
        private appointmentEventRuleProvider: AppointmentEventRuleProvider,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadInitialData();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isPriorityValid(priority: number): boolean {
        return priority > 0 && priority < 201 && priority % 1 === 0;
    }

    isRuleDetailsStepValid(rule: AppointmentEventRuleType): boolean {
        return !!(rule.appointmentEventRuleTableId &&
            !lodash.isEmpty(rule.appointmentEventRuleTable) && rule.name &&
            this.isPriorityValid(rule.priority));
    }

    isInputStepValid(rule: AppointmentEventRuleType): boolean {
        return !!(rule.entityType && (!this.generalUtils.isSelectedNoValueOption(rule.eventType) ||
            !this.generalUtils.isSelectedNoValueOption(rule.appointmentStatus) ||
            rule.centerTagId || rule.resourceTagId || rule.serviceTagId ||
            rule.patientTagId));
    }

    isOutputStepValid(rule: AppointmentEventRuleType): boolean {
        return !!(rule.eventActionId && !lodash.isEmpty(rule.eventAction));
    }

    areAllStepsValid(rule: AppointmentEventRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.appointmentEventRuleTable = ruleTable[0];
            this.ruleItem.appointmentEventRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.appointmentEventRuleTable = undefined;
            this.ruleItem.appointmentEventRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedTag(entityName: string, tagList: TagType[]) {
        if (tagList?.length > 0) {
            this.ruleItem[entityName + 'Tag'] = tagList[0];
            this.ruleItem[entityName + 'TagId'] = tagList[0].id;
        } else {
            this.ruleItem[entityName + 'Tag'] = undefined;
            this.ruleItem[entityName + 'TagId'] = undefined;
        }
    }

    onEventTypeChange(eventType: EventTypeEnum) {
        this.ruleItem.eventType = this.generalUtils.isSelectedNoValueOption(eventType) ? 'noValue' as EventTypeEnum : eventType;
        if (eventType === EventTypeEnum.appointmentReschedule) {
            this.ruleItem.appointmentStatus = 'noValue' as AppointmentStatusValueEnum;
        }
    }

    onAppointmentStatusChange(appointmentStatus: AppointmentStatusValueEnum) {
        this.ruleItem.appointmentStatus = this.generalUtils.isSelectedNoValueOption(appointmentStatus) ? 'noValue' as
            AppointmentStatusValueEnum : appointmentStatus;
    }

    onChangeIncludeFlag(entityName: string, includeFlag: boolean) {
        this.ruleItem['include' + lodash.upperFirst(entityName) + 'Tag'] =
            includeFlag ? this.IncludeExcludeEnum.include : this.IncludeExcludeEnum.exclude;
    }
    //
    // endregion Input methods

    // region Output methods
    //
    onSelectedEventAction(eventAction: EventActionType[]): void {
        if (eventAction?.length > 0) {
            this.ruleItem.eventAction = eventAction[0];
            this.ruleItem.eventActionId = eventAction[0].id;
        } else {
            this.ruleItem.eventAction = undefined;
            this.ruleItem.eventActionId = undefined;
        }
    }
    //
    // endregion Output methods

    // region Overview methods
    //
    saveRule(rule: AppointmentEventRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.appointmentEventRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    // function to create the new AppointmentEventRule
    private createRule(rule: AppointmentEventRuleType) {
        this.ngxLoader.start();
        const appointmentEventRuleToSend: AppointmentEventRuleType =
            this.appointmentEventRuleUtils.mapAppointmentEventRuleForServer(rule);
        this.appointmentEventRuleProvider.addEntry(appointmentEventRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newAppointmentEventRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the AppointmentEventRule
    private editRule(oldRule: AppointmentEventRuleType, newRule: AppointmentEventRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: AppointmentEventRuleType =
            this.appointmentEventRuleUtils.mapAppointmentEventRuleForServer(oldRule);
        const newRuleToSend: AppointmentEventRuleType =
            this.appointmentEventRuleUtils.mapAppointmentEventRuleForServer(newRule);
        this.appointmentEventRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.appointmentEventRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        this.mainDependentFilters = {
            centerTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedCenter, false, true),
            serviceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedService, false, true),
            resourceTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedResource, false, true),
            patientTags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true)
        };
        this.appointmentEventRuleTags = this.appointmentEventRuleUtils.getInitialAppointmentEventRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (this.generalUtils.isEmpty(this.ruleItem.appointmentStatus)) {
                this.ruleItem.appointmentStatus = 'noValue' as AppointmentStatusValueEnum;
            }
            if (this.generalUtils.isEmpty(this.ruleItem.eventType)) {
                this.ruleItem.eventType = 'noValue' as EventTypeEnum;
            }
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.addSelectedTagsToTagTagInput();
            }
        } else {
            this.ruleItem = this.appointmentEventRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        // Preselect ruleset if only one available
        this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
    }

    private loadInitialData() {
        this.ngxLoader.start();
        const filter: ODataQueryObjectType = {
            filter: {EnabledForRules: true}
        };
        this.tagProvider.getEntries(filter)
            .pipe(take(1))
            .subscribe((response) => {
                this.tagsScopedNone = lodash.filter(response.value, {scopedNone: true});
                this.tagsScopedResource = lodash.filter(response.value, {scopedResource: true});
                this.tagsScopedPatient = lodash.filter(response.value, {scopedPatient: true});
                this.ngxLoader.stop();
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            });
    }

    private addSelectedTagsToTagTagInput() {
        if (!lodash.isEmpty(this.ruleItem.centerTag)) {
            this.appointmentEventRuleTags.selectedCenterTags.push(this.ruleItem.centerTag as unknown as TagsType);
            this.appointmentEventRuleTags.includeCenterTagFlag = this.ruleItem.includeCenterTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.serviceTag)) {
            this.appointmentEventRuleTags.selectedServiceTags.push(this.ruleItem.serviceTag as unknown as TagsType);
            this.appointmentEventRuleTags.includeServiceTagFlag = this.ruleItem.includeServiceTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.resourceTag)) {
            this.appointmentEventRuleTags.selectedResourceTags.push(this.ruleItem.resourceTag as unknown as TagsType);
            // tslint:disable-next-line:max-line-length
            this.appointmentEventRuleTags.includeResourceTagFlag = this.ruleItem.includeResourceTag === this.IncludeExcludeEnum.include;
        }
        if (!lodash.isEmpty(this.ruleItem.patientTag)) {
            this.appointmentEventRuleTags.selectedPatientTags.push(this.ruleItem.patientTag as unknown as TagsType);
            this.appointmentEventRuleTags.includePatientTagFlag = this.ruleItem.includePatientTag === this.IncludeExcludeEnum.include;
        }
    }
}
