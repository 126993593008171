import {Injectable} from '@angular/core';
import {FormValidationType, Validations} from 'sked-base';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {
    InputValidationResultType,
    ProcessedTenantCustomizingGroupedByControlNameType,
    ProcessedTenantCustomizingType, TenantCustomizingDisplayRulesInnerType,
    TenantCustomizingFilterInputType,
    TenantCustomizingType,
    TenantCustomizingValidationSetInnerType, TenantCustomizingValidationSetInnerValidationType
} from '../../data-model/tenant-customizing.type';
import {Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ConfigDataService} from './config-data.service';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class TenantCustomizingService {
    isRequiredMap: { [key in string]?: boolean } = {};
    isRequiredValidatorReference: ValidatorFn;
    previousTenantCustomizingFilterInput: TenantCustomizingFilterInputType;
    processedTenantCustomizingByProperty: ProcessedTenantCustomizingGroupedByControlNameType;

    constructor(
        private validations: Validations,
        private configData: ConfigDataService,
    ) {
    }

    // If method called for the first time: get values from session storage and filter them by the inputs
    // Otherwise:
    //   If method called with same options as before, return the cached value.
    //   Otherwise:
    //     Get values from session storage and filter them by the new inputs
    getTenantCustomizingValuesFor(
        tenantCustomizingFilterInput: TenantCustomizingFilterInputType
    ): Observable<ProcessedTenantCustomizingGroupedByControlNameType> {
        if (this.previousTenantCustomizingFilterInput === tenantCustomizingFilterInput) {
            // Return the cached values
            return of(this.processedTenantCustomizingByProperty);
        }
        const {ui, entity, situation, role} = tenantCustomizingFilterInput;
        return this.configData.getTenantConfig().pipe(take(1), map((tenantCustomizingData: TenantCustomizingType) => {
            const processedTenantCustomizingByProperty: { [key in string]?: Partial<ProcessedTenantCustomizingType> } = {};

            // Filter and process display rules
            const filteredTenantCustomizingDisplayRules = lodash.filter(
                tenantCustomizingData.displayRules.value,
                (displayRule: TenantCustomizingDisplayRulesInnerType) => {
                    return displayRule.ui === ui && displayRule.entity === entity && displayRule.situation === situation && displayRule.role === role;
                }
            );
            filteredTenantCustomizingDisplayRules.forEach((displayRule: TenantCustomizingDisplayRulesInnerType) => {
                processedTenantCustomizingByProperty[displayRule.property] = {
                    visible: displayRule.visible,
                    enabled: displayRule.enabled,
                };
            });

            // Filter and process validations set
            const filteredTenantCustomizingValidationSet = lodash.filter(
                tenantCustomizingData.validationSet.value,
                (displayRule: TenantCustomizingValidationSetInnerType) => {
                    return displayRule.entity === entity && displayRule.situation === situation && displayRule.role === role;
                }
            );
            filteredTenantCustomizingValidationSet.forEach((validationSet: TenantCustomizingValidationSetInnerType) => {
                processedTenantCustomizingByProperty[validationSet.property] = {
                    ...processedTenantCustomizingByProperty[validationSet.property],
                    validations: validationSet.validations,
                };
            });

            // Reset the is required map
            this.isRequiredMap = {};
            // Save values in cache
            this.processedTenantCustomizingByProperty = processedTenantCustomizingByProperty as ProcessedTenantCustomizingGroupedByControlNameType;

            return this.processedTenantCustomizingByProperty;
        }));
    }

    isRequired(propertyName: string, tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType): boolean {
        if (!tenantCustomizingData) {
            tenantCustomizingData = this.processedTenantCustomizingByProperty;
        }
        if (!tenantCustomizingData || !propertyName || !tenantCustomizingData[propertyName]?.validations) {
            return false;
        }
        if (this.isRequiredMap[propertyName] === undefined) {
            const isReq = !!lodash.find(tenantCustomizingData[propertyName].validations, {regexPatternName: 'Required'});
            const isMinDif0 = !!lodash.find(
                tenantCustomizingData[propertyName].validations,
                (validation: TenantCustomizingValidationSetInnerValidationType) => {
                    return validation?.errorTemplate?.errorProperties?.minimum !== undefined &&
                        validation.errorTemplate.errorProperties.minimum !== 0;
                }
            );
            this.isRequiredMap[propertyName] = isReq || isMinDif0;
        }
        return this.isRequiredMap[propertyName];
    }

    isVisible(propertyName: string, tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType): boolean {
        if (!tenantCustomizingData) {
            tenantCustomizingData = this.processedTenantCustomizingByProperty;
        }
        if (!tenantCustomizingData || !propertyName) {
            return true;
        }
        return tenantCustomizingData[propertyName]?.visible;
    }

    isEnabled(propertyName: string, tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType): boolean {
        if (!tenantCustomizingData) {
            tenantCustomizingData = this.processedTenantCustomizingByProperty;
        }
        if (!tenantCustomizingData || !propertyName) {
            return true;
        }
        return tenantCustomizingData[propertyName]?.enabled;
    }

    getTenantCustomizingValidator(propertyName: string, tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!tenantCustomizingData) {
                tenantCustomizingData = this.processedTenantCustomizingByProperty;
            }
            const validation: InputValidationResultType = this.getValidationResult(control?.value, propertyName, tenantCustomizingData);
            if (validation.isValid) {
                return {};
            }
            const errorObject = {
                priority: 5,
                message: validation.message,
                shouldTranslateMessage: true,
                params: validation.params
            };
            return validation.params.regex
                ? {regex: errorObject}
                : validation.params.birthDate
                    ? {birthDate: errorObject}
                    : {};
        };
    }

    getIsRequiredValidator(): ValidatorFn {
        // Always returns the same reference of the validator, so we can remove it dynamically
        if (!this.isRequiredValidatorReference) {
            this.isRequiredValidatorReference = (control: AbstractControl) => {
                return !control?.value
                    ? {requiredError: {priority: 2, message: 'label.error.required', shouldTranslateMessage: true}}
                    : {};
            };
        }
        return this.isRequiredValidatorReference;
    }

    // This is a cross field validator
    getLibPhoneValidator(formControlName: string): ValidatorFn | null {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // Get the values for countryCode and phone number
            const phoneNumberGroupValues = Object.values(control.value);
            const phoneValidation: FormValidationType = this.validations.getValidatePhoneNumberLibPhone(
                String(phoneNumberGroupValues[1] ?? ''),
                this.isRequired(formControlName),
                String(phoneNumberGroupValues[0] ?? '')
            );
            if (phoneValidation.isValid) {
                return null;
            }
            return {
                phoneNumberLibPhone: {
                    priority: 3,
                    message: phoneValidation.errorMessage,
                    shouldTranslateMessage: true,
                },
            } as ValidationErrors;
        };
    }

    private getValidationResult(
        value: any, propertyName: string, tenantCustomizingData?: ProcessedTenantCustomizingGroupedByControlNameType
    ): InputValidationResultType {
        if (!tenantCustomizingData) {
            tenantCustomizingData = this.processedTenantCustomizingByProperty;
        }
        if (!tenantCustomizingData || !propertyName || !tenantCustomizingData[propertyName]?.validations) {
            return {isValid: true} as InputValidationResultType;
        }

        let validationResult: InputValidationResultType;
        tenantCustomizingData[propertyName].validations?.forEach((validation: TenantCustomizingValidationSetInnerValidationType) => {
            if (validation.type === 'Regex') {
                validationResult = this.getValidationByRegex(value, validation);
            } else if (validation.type === 'DateTime' && validation.dateTimeType === 'BirthDate') {
                validationResult = this.getValidationByDateForBirthdate(value, validation);
            }
            if (validationResult?.isValid === false) {
                return validationResult;
            }
        });

        return validationResult;
    }

    private getValidationByRegex(value: any, validation: TenantCustomizingValidationSetInnerValidationType): InputValidationResultType {
        const isValid = new RegExp(validation.regexPattern).test(value ?? '');
        return {
            isValid,
            ...(!isValid ? {message: validation.errorTemplate.messageCode} : {}),
            ...(!isValid ? {
                params: {
                    ...validation.errorTemplate.errorProperties,
                    regex: true,
                }
            } : {}),
        } as InputValidationResultType;
    }

    private getValidationByDateForBirthdate(value: any, validation: TenantCustomizingValidationSetInnerValidationType): InputValidationResultType {

        const isValid = this.validateBirthdate(value);
        return {
            isValid,
            ...(!isValid ? {message: validation.errorTemplate.debugMessage} : {}),
            ...(!isValid ? {
                params: {
                    ...validation.errorTemplate.errorProperties,
                    birthDate: true,
                }
            } : {}),
        } as InputValidationResultType;
    }

    private validateBirthdate(value: any): boolean {
        const today = moment();
        const inputContentFormatted = moment(value, moment.localeData().longDateFormat('L'));
        return !inputContentFormatted.isBefore(new Date('1850'), 'year') && !inputContentFormatted.isAfter(today);
    }
}
