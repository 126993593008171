<div class="patient-merge-wrapper">
    <div class="patient-merge-container d-flex flex-grow-1 no-block pb-1 flex-row">
        <!-- First patient panel (left) -->
        <div id="left-patient-container" class="patient-container d-flex no-block flex-column">
            <!-- Searchbar -->
            <div class="patient-search-component-container mt-3 ml-3 mr-3 mb-0">
                <label class="input-label" translate="label.patientToMerge">
                    <span class="input-required-asterisk">*</span>
                </label>
                <sbase-filter [minSearchLength]="3"
                              [clearItemListBetweenClickAndRequest]="true"
                              [initialFilterValues]="patientSearchTags[PatientMergeSide.LEFT]"
                              [providerInstance]="patientProvider"
                              [dependentFilters]="mainDependentFilters"
                              [isMultipleSelectedItem]="false"
                              [hideSelectedItems]="true" [useSelectedValueAsLabel]="true"
                              [buttonName]='"label.selectPatient"'
                              [customInitialNotFoundLabel]="'label.searchPatientByNameOrDocument'"
                              [customError400Label]="'label.searchPatientByNameOrDocument'"
                              (selectedItemsOutput)="onPatientFilterValueChanged($event, PatientMergeSide.LEFT)">
                </sbase-filter>
            </div>
            <!-- Left patient data and appointments -->
            <div class="patient-overview-container ml-3 mt-1"
                 *ngIf="!!options[PatientMergeSide.LEFT].patient; else selectPatientMessage">
                <!-- Left patient data -->
                <div class="patient-data-inner-container custom-scrollbar">
                    <div class="patient-data-container">
                        <div class="d-flex flex-row no-block">
                            <div class="dynamic-checkbox-container p-2">
                                <input type="checkbox" [(ngModel)]="options[PatientMergeSide.LEFT].keepPatient"
                                       [disabled]="options[PatientMergeSide.RIGHT].keepPatient"/>
                                <div class="d-flex align-items-center">
                                    <span class="font-weight-bold mr-1" translate="label.keepPatientForMerge"></span>
                                    <span translate="label.keepPatientForMergeAdditionalInfo"></span>
                                </div>
                            </div>
                            <button id="collapse-left-panel-header" type="button" class="btn ml-auto"
                                    (click)="collapse.toggle()" [attr.aria-expanded]="!isLeftPanelCollapsed"
                                    aria-controls="collapseExample">
                                <h6 class="mb-0 d-flex">
                                    <i class="fas fa-angle-down rotate-icon"></i>
                                </h6>
                            </button>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isLeftPanelCollapsed">
                            <div class="card">
                                <div class="card-body p-2">
                                    <div class="patient-details-container p-2">
                                        <table class="patient-detail-table text-medium-contrast"
                                               [ngClass]="{'keep-patient': options[PatientMergeSide.LEFT].keepPatient}">
                                            <tr class="first-row" *ngIf="!options[PatientMergeSide.LEFT].patient.noDocument">
                                                <td>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentType">{{'label.documentType' | translate}},&nbsp;</span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentNumberFormatted">{{'label.documentNumber' | translate}},&nbsp;</span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentCountry">{{'label.country' | translate}}</span>
                                                </td>
                                                <td class="align-end">
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentType">
                                                        {{'enum.documentType.' + options[PatientMergeSide.LEFT].patient.documentType | translate}},
                                                    </span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentNumberFormatted">
                                                        {{options[PatientMergeSide.LEFT].patient.documentNumberFormatted}},
                                                    </span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.documentCountry">
                                                        {{options[PatientMergeSide.LEFT].patient.documentCountry}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="options[PatientMergeSide.LEFT]?.patient?.fullName">
                                                <td><span translate="label.fullName"></span></td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.LEFT]?.patient?.fullName}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="options[PatientMergeSide.LEFT]?.patient?.gender || options[PatientMergeSide.LEFT]?.patient?.birthDate">
                                                <td>
                                                    <span translate="label.gender"></span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT]?.patient?.birthDate">, </span>
                                                    <span translate="label.birthDate"></span>
                                                </td>
                                                <td class="align-end">
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.gender">{{options[PatientMergeSide.LEFT].patient.gender | translate}}, </span>
                                                    <span *ngIf="options[PatientMergeSide.LEFT].patient.birthDate">{{options[PatientMergeSide.LEFT].patient.birthDate | fullDateNoTimeFormatPipe}}</span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="isAddressVisible(PatientMergeSide.LEFT)">
                                                <td><span translate="label.address"></span></td>
                                                <td class="align-end">
                                                    <span>
                                                        {{displayPatientAddress(PatientMergeSide.LEFT)}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="options[PatientMergeSide.LEFT]?.patient?.mainPhoneNumber">
                                                <td><span translate="label.phoneNumber"></span></td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.LEFT]?.patient?.mainPhoneNumber}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="options[PatientMergeSide.LEFT]?.patient?.email">
                                                <td><span translate="label.email"></span></td>
                                                <td class="align-end">{{options[PatientMergeSide.LEFT].patient.email}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Left patient appointments -->
                    <div class="dynamic-checkbox-container p-2"
                         *ngIf="isKeepAppointmentsCheckboxVisible(PatientMergeSide.LEFT)">
                        <input type="checkbox" [(ngModel)]="options[PatientMergeSide.LEFT].keepCancelledAppointments"/>
                        <label translate="label.keepCancelledAppointmentsForMerge"></label>
                    </div>
                    <div class="upcoming-appointments-container overview-table-container">
                        <ng-container>
                            <div class="ml-2 my-1"
                                 *ngFor="let appointment of options[PatientMergeSide.LEFT].appointments; let index = index">
                                <app-appointment-card class="appointment-card-no-buttons"
                                                      [options]="{appointment: appointment, displayPatientInformation: true}">
                                </app-appointment-card>
                                <div class="horizontal-line"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- Left appointments table pagination -->
                <div class="pagination-component font-weight-high-contrast"
                     *ngIf="options[PatientMergeSide.LEFT].appointmentsTablePagination.totalTableItems">
                    <ngb-pagination
                            [collectionSize]="options[PatientMergeSide.LEFT].appointmentsTablePagination.totalTableItems"
                            [(page)]="options[PatientMergeSide.LEFT].appointmentsTablePagination.tableFilters.currentPage"
                            [maxSize]="5"
                            [pageSize]="options[PatientMergeSide.LEFT].appointmentsTablePagination.tableFilters.itemsPerPage"
                            [boundaryLinks]="true"
                            (pageChange)="onChangePagination($event, PatientMergeSide.LEFT)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
        <!-- Second patient panel (right) -->
        <div id="right-patient-container" class="patient-container d-flex no-block flex-column">
            <!-- Searchbar -->
            <div class="patient-search-component-container mt-3 ml-3 mr-3 mb-0">
                <label class="input-label" translate="label.patientToMerge">
                    <span class="input-required-asterisk">*</span>
                </label>
                <sbase-filter [minSearchLength]="3"
                              [clearItemListBetweenClickAndRequest]="true"
                              [providerInstance]="patientProvider"
                              [initialFilterValues]="patientSearchTags[PatientMergeSide.RIGHT]"
                              [dependentFilters]="mainDependentFilters"
                              [isMultipleSelectedItem]="false"
                              [hideSelectedItems]="true" [useSelectedValueAsLabel]="true"
                              [buttonName]='"label.selectPatient"'
                              [customError400Label]="'label.searchPatientByNameOrDocument'"
                              [customInitialNotFoundLabel]="'label.searchPatientByNameOrDocument'"
                              (selectedItemsOutput)="onPatientFilterValueChanged($event, PatientMergeSide.RIGHT)">
                </sbase-filter>
            </div>
            <!-- Right patient data and appointments -->
            <div class="patient-overview-container ml-3 mt-1"
                 *ngIf="!!options[PatientMergeSide.RIGHT].patient; else selectPatientMessage">
                <!-- Right patient data -->
                <div class="patient-data-inner-container custom-scrollbar">
                    <div class="patient-data-container">
                        <div class="d-flex flex-row no-block">
                            <div class="dynamic-checkbox-container p-2">
                                <input type="checkbox" [(ngModel)]="options[PatientMergeSide.RIGHT].keepPatient"
                                       [disabled]="options[PatientMergeSide.LEFT].keepPatient"/>
                                <div class="d-flex align-items-center">
                                    <span class="font-weight-bold mr-1" translate="label.keepPatientForMerge"></span>
                                    <span translate="label.keepPatientForMergeAdditionalInfo"></span>
                                </div>
                            </div>
                            <button id="collapse-right-panel-header" type="button" class="btn ml-auto"
                                    (click)="collapse.toggle()" [attr.aria-expanded]="!isRightPanelCollapsed"
                                    aria-controls="collapseExample">
                                <h6 class="mb-0 d-flex">
                                    <i class="fas fa-angle-down rotate-icon"></i>
                                </h6>
                            </button>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isRightPanelCollapsed">
                            <div class="card">
                                <div class="card-body p-2">
                                    <div class="patient-details-container p-2">
                                        <table class="patient-detail-table text-medium-contrast"
                                               [ngClass]="{'keep-patient': options[PatientMergeSide.RIGHT].keepPatient}">
                                            <tr class="first-row" *ngIf="!options[PatientMergeSide.RIGHT].patient.noDocument">
                                                <td>
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentType">{{'label.documentType' | translate}},&nbsp;</span>
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentNumberFormatted">{{'label.documentNumber' | translate}},&nbsp;</span>
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentCountry">{{'label.country' | translate}}</span>
                                                </td>
                                                <td class="align-end">
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentType">
                                                        {{'enum.documentType.' + options[PatientMergeSide.RIGHT].patient.documentType | translate}},
                                                    </span>
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentNumberFormatted">
                                                        {{options[PatientMergeSide.RIGHT].patient.documentNumberFormatted}},
                                                    </span>
                                                    <span *ngIf="options[PatientMergeSide.RIGHT].patient.documentCountry">
                                                        {{options[PatientMergeSide.RIGHT].patient.documentCountry}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span translate="label.fullName"></span></td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.RIGHT].patient.fullName}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span translate="label.gender"></span>, <span
                                                        translate="label.birthDate"></span>
                                                </td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.RIGHT].patient.gender | translate}},
                                                    {{options[PatientMergeSide.RIGHT].patient.birthDate |
                                                    fullDateNoTimeFormatPipe}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span translate="label.address"></span></td>
                                                <td class="align-end">
                                                    <span
                                                            *ngIf="isAddressVisible(PatientMergeSide.RIGHT)">
                                                        {{displayPatientAddress(PatientMergeSide.RIGHT)}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span translate="label.phoneNumber"></span></td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.RIGHT].patient.mainPhoneNumber}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span translate="label.email"></span></td>
                                                <td class="align-end">
                                                    {{options[PatientMergeSide.RIGHT].patient.email}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Right patient appointments -->
                    <div class="dynamic-checkbox-container p-2"
                         *ngIf="isKeepAppointmentsCheckboxVisible(PatientMergeSide.RIGHT)">
                        <input type="checkbox" [(ngModel)]="options[PatientMergeSide.RIGHT].keepCancelledAppointments"/>
                        <label translate="label.keepCancelledAppointmentsForMerge"></label>
                    </div>
                    <div class="upcoming-appointments-container overview-table-container">
                        <ng-container>
                            <div class="ml-2 my-1"
                                 *ngFor="let appointment of options[PatientMergeSide.RIGHT].appointments; let index = index">
                                <app-appointment-card class="appointment-card-no-buttons"
                                                      [options]="{appointment: appointment, displayPatientInformation: true}">
                                </app-appointment-card>
                                <div class="horizontal-line"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- Right appointments table pagination -->
                <div class="pagination-component font-weight-high-contrast"
                     *ngIf="options[PatientMergeSide.RIGHT].appointmentsTablePagination.totalTableItems > 0">
                    <ngb-pagination
                            [collectionSize]="options[PatientMergeSide.RIGHT].appointmentsTablePagination.totalTableItems"
                            [(page)]="options[PatientMergeSide.RIGHT].appointmentsTablePagination.tableFilters.currentPage"
                            [maxSize]="5"
                            [pageSize]="options[PatientMergeSide.RIGHT].appointmentsTablePagination.tableFilters.itemsPerPage"
                            [boundaryLinks]="true"
                            (pageChange)="onChangePagination($event, PatientMergeSide.RIGHT)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>

    <!-- Close and merge buttons -->
    <div class="btn-group-sm p-2">
        <button type="button" class="btn close-btn m-1" (click)="onClose()">
            <span translate="label.cancel"></span>
        </button>
        <button type="button" class="btn theme-btn m-1" (click)="onMerge()" [disabled]="!isMergeButtonEnabled()">
            <span translate="label.merge"></span>
        </button>
    </div>

    <!-- Message to select a patient -->
    <ng-template #selectPatientMessage>
        <div class="container-fluid no-patient-in-context-message-container pb-1 flex-grow-1 d-flex no-block justify-content-center align-items-center">
            <div class="no-patient-in-context-message text-center">
                <i class="fas fa-info-circle mr-3"></i>
                <span translate="label.searchForAPatientToDisplayData"></span>
            </div>
        </div>
    </ng-template>
</div>
