import {Injectable} from '@angular/core';
import {
    ODataQueryObjectType
} from 'sked-base';
import {forkJoin, Observable} from 'rxjs';
import * as lodash from 'lodash';
import {of} from 'rxjs/internal/observable/of';
import {concatMap, map, take, tap} from 'rxjs/operators';
import {IdleTimerOptionsType} from '../../data-model/general.type';
import {OpenIdConnectService} from './open-id-connect.service';
import {ConfigDataService} from './config-data.service';
import {MessagesService} from './messages.service';
import {systemConfigKeys} from '../../features/system-config/system-config.constant';

@Injectable({
    providedIn: 'root'
})
export class IdleTimerService {
    LOCAL_STORAGE_NAME = 'expiresAt';
    EVENT_LISTENER_TYPES = ['mousemove', 'mousedown', 'scroll', 'keydown'];
    options: IdleTimerOptionsType;
    eventHandler: () => {} = null;
    interval: NodeJS.Timeout;
    timeoutTracker: NodeJS.Timeout;
    isInitialized = false;

    constructor(
        private openIdConnectService: OpenIdConnectService,
        private configDataService: ConfigDataService,
        private messagesService: MessagesService,
    ) {
    }

    init(options?: IdleTimerOptionsType) {
        if (this.isInitialized) {
            return;
        }
        this.isInitialized = true;

        // Set default values
        this.options = {
            ...this.getDefaultOptions(),
            ...options,
        };

        // Check if user re-opened the app after the session expired
        const expiresAt = parseInt(localStorage.getItem(this.LOCAL_STORAGE_NAME), 10);
        if (expiresAt > 0 && expiresAt < Date.now()) {
            this.cleanUp();
            this.options.onExpired();
            return;
        }

        // Start timing idle
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.addEventListeners();
        this.startInterval();
    }

    destroy() {
        this.cleanUp();
    }

    private startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem(this.LOCAL_STORAGE_NAME), 10);
            if (expiredTime < Date.now()) {
                this.cleanUp();
                this.options.onTimeout();
            }
        }, this.options.checkIdleEverySeconds * 1000);
    }

    private updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        const {shouldStopTimer} = this.updateTimeoutSecondsFromSystemConfig();
        if (shouldStopTimer) {
            this.cleanUp();
            return;
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem(this.LOCAL_STORAGE_NAME, `${Date.now() + this.options.timeoutSeconds * 1000}`);
        }, this.options.debounceUpdateTimeoutAfterUserInteractivityInSeconds * 1000);
    }

    private cleanUp() {
        localStorage.removeItem(this.LOCAL_STORAGE_NAME);
        clearInterval(this.interval);
        if (this.eventHandler) {
            this.EVENT_LISTENER_TYPES.forEach(type => {
                if (type === 'scroll') {
                    window.removeEventListener(type, this.eventHandler, true);
                } else {
                    window.removeEventListener(type, this.eventHandler);
                }
            });
            this.eventHandler = null;
        }
    }

    private addEventListeners() {
        this.EVENT_LISTENER_TYPES.forEach(type => {
            if (type === 'scroll') {
                // Listen to any scroll event, even if on child component
                window.addEventListener(type, this.eventHandler, true);
            } else {
                window.addEventListener(type, this.eventHandler);
            }
        });
    }

    private signOutUser() {
        this.messagesService.info('label.sessionExpired', true);
        setTimeout(() => {
            this.openIdConnectService.triggerSignOut();
        }, 1500);
    }

    private updateTimeoutSecondsFromSystemConfig(): {shouldStopTimer: boolean} {
        const backofficeLogoutTimeoutRaw =
            lodash.find(this.configDataService?.systemConfig?.value, {name: systemConfigKeys.BACKOFFICE_LOGOUT_TIMEOUT});
        if (!backofficeLogoutTimeoutRaw) {
            // Still waiting for system config to load
            return {shouldStopTimer: false};
        }

        const backofficeLogoutTimeout =
            isNaN(Number(backofficeLogoutTimeoutRaw?.value)) ? 0 : Number(backofficeLogoutTimeoutRaw.value);
        if (backofficeLogoutTimeout === 0) {
            return {shouldStopTimer: true};
        }

        // Update timeout time
        this.options.timeoutSeconds = backofficeLogoutTimeout * 60; // backofficeLogoutTimeout minutes
        return {shouldStopTimer: false};
    }

    private getDefaultOptions(): IdleTimerOptionsType {
        return {
            // Will be updated with the system config value BackofficeLogoutTimeout after system config becomes available
            timeoutSeconds: 60 * 15, // 15 minutes
            onTimeout: this.signOutUser.bind(this),
            onExpired: this.signOutUser.bind(this),
            checkIdleEverySeconds: 1,
            debounceUpdateTimeoutAfterUserInteractivityInSeconds: 0.5,
        } as IdleTimerOptionsType;
    }
}
