<div class="container-fluid create-sub-service-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-sub-service-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase">{{'label.subService'|translate}}</span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-sub-service-section create-entity-md-section">
        <form name="form" novalidate>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{subServiceItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{subServiceItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.duration"></td>
                                    <td class="text-right">{{subServiceItem.defaultDuration}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.code"></td>
                                    <td class="text-right">{{subServiceItem?.code}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.tags"></td>
                                    <td class="text-right">
                                        <span *ngFor="let tag of subServiceItem.tags; last as islast">
                                            {{tag.name}}<span *ngIf="!islast">, </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.enabledOnPatientAccess"></td>
                                    <td class="text-right">
                                        <span *ngIf="subServiceItem.enabledOnPatientAccess"
                                              translate="label.yes"></span>
                                        <span *ngIf="!subServiceItem.enabledOnPatientAccess"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.favoriteForPatientAccess"></td>
                                    <td class="text-right">
                                        <span *ngIf="subServiceItem.favorite" translate="label.yes"></span>
                                        <span *ngIf="!subServiceItem.favorite" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.details"></label>
                            <div class="container-external-keys-table">
                                <table class="table border mt-2">
                                    <thead>
                                    <tr>
                                        <th translate="label.origin"></th>
                                        <th class="text-right" translate="label.key"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of subServiceItem.subServiceExternalKeys">
                                        <td>{{item.origin}}</td>
                                        <td class="text-right" >{{item.key}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3">
                        <div *ngIf="screenTemplateLayout.action !== constants.CREATE"
                             class="form-group"
                             [ngClass]="{'disabled-input': screenTemplateLayout.action !== constants.CREATE}">
                            <label translate="label.shortId"></label>
                            <input [(ngModel)]="subServiceItem.shortId"
                                   [disabled]=true
                                   class="input-element"
                                   name="shortId"
                                   type="text">
                        </div>
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input [(ngModel)]="subServiceItem.name"
                                   (ngModelChange)="onInputChanged(subServiceItem)"
                                   [ngClass]="{'input-element-has-error': !validTemplate.isNameValid}"
                                   autocomplete="off"
                                   class="input-element"
                                   name="name"
                                   placeholder="{{'label.name'|translate}}"
                                   type="text">
                            <div *ngIf="!validTemplate.isNameValid && screenTemplateLayout.action !== constants.VIEW"
                                 class="help-block">
                                <span [ngClass]="{'has-error': !validTemplate.isNameValid}"
                                      translate="label.error.required"></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.duration"></label>
                            <input (ngModelChange)="onInputChanged(subServiceItem)"
                                   [(ngModel)]="subServiceItem.defaultDuration"
                                   [ngClass]="{'input-element-has-error': !subServiceItem.defaultDuration}"
                                   autocomplete="off"
                                   class="input-element"
                                   min="0"
                                   name="defaultDuration"
                                   placeholder="{{'label.duration'|translate}}"
                                   type="number">
                            <div
                                *ngIf="!subServiceItem.defaultDuration && screenTemplateLayout.action !== constants.VIEW"
                                class="help-block">
                            <span [ngClass]="{'has-error': !subServiceItem.defaultDuration}"
                                  translate="label.error.required"></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate="label.code"></label>
                            <input (ngModelChange)="onInputChanged(subServiceItem)"
                                   [(ngModel)]="subServiceItem.code"
                                   autocomplete="off"
                                   class="input-element"
                                   name="code"
                                   placeholder="{{'label.code'|translate}}"
                                   type="text">

                        </div>
                    </div>
                    <div class="col-5">
                        <!-- tags -->
                        <div class="form-group position-relative customSelect">
                            <label translate="label.tags"></label>
                            <sbase-filter (selectedItemsOutput)="onChangeTags($event)"
                                          [buttonName]='"label.newTag"'
                                          [dependentFilters]="mainDependentFilters?.tags"
                                          [initialFilterValues]="subServiceItem.tags"
                                          [isMultipleSelectedItem]="true"
                                          [useSelectedValueAsLabel]="true"
                                          [hideSelectedItems]="true"
                                          [providerInstance]="tagProvider">
                            </sbase-filter>
                        </div>
                        <div class="form-group"
                             [featureDisplay]="'patient-access-subservices'">
                            <label translate="label.enabledOnPatientAccess"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox"
                                           name="enableFavoritesOnPatientAccess"
                                           [(ngModel)]="subServiceItem.enabledOnPatientAccess"
                                           (ngModelChange)="onEnableFavoritesOnPatientAccessInputChanged(subServiceItem)">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group" [featureDisplay]="'patient-access-subservices'">
                            <label translate="label.favoriteForPatientAccess"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox"
                                           name="displayFavoritesInPatientAccess"
                                           [(ngModel)]="subServiceItem.favorite"
                                           (ngModelChange)="onDisplayFavoritesOnPatientAccessInputChanged(subServiceItem)">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(subServiceItem.subServiceExternalKeys.length !== 0)
                     || screenTemplateLayout.action !== VIEW"
                         class="col-4">
                        <!-- external keys -->
                        <div class="step-form">
                            <button (click)="onIncrement()" *ngIf="screenTemplateLayout.action!==VIEW"
                                    class="btn addTableRow-btn btn-block text-left py-2"
                                    type="button">
                                <i class="fas fa-plus-circle"></i>
                                <span class="name-button" translate="label.addTableRow"></span>
                            </button>
                            <div class="container-external-keys-table">
                                <table class="table mt-3 table-bordered border-0">
                                    <thead>
                                    <tr>
                                        <th class="border-0 p-0" translate="label.origin"></th>
                                        <th class="border-0 p-0" translate="label.key"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of subServiceItem.subServiceExternalKeys; let i = index">
                                        <td>
                                            <div class="form-group m-0">
                                                <input (ngModelChange)="onInputChanged(subServiceItem)"
                                                       [(ngModel)]="item.origin"
                                                       [ngModelOptions]="{standalone: true}"
                                                       autocomplete="off"
                                                       class="form-control border-0"
                                                       name="{{item.origin}}"
                                                       placeholder="{{'label.origin'|translate}}"
                                                       type="text">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group m-0 d-flex">
                                                <input (ngModelChange)="onInputChanged(subServiceItem)"
                                                       [(ngModel)]="item.key"
                                                       [ngModelOptions]="{standalone: true}"
                                                       autocomplete="off"
                                                       class="form-control border-0"
                                                       name="{{item.key}}"
                                                       placeholder="{{'label.key'|translate}}"
                                                       type="text">
                                                <button *ngIf="screenTemplateLayout.action!==VIEW"
                                                        class="btn float-right p-0 pl-2"
                                                        type="button">
                                                    <i (click)="onDecrement(i)" class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div>
                                <span
                                    *ngIf="!validTemplate.isExternalKeysValid && subServiceItem.subServiceExternalKeys.length > 0"
                                    [ngClass]="{ 'has-error': !validTemplate.isExternalKeysValid }"
                                    class="help-block"
                                    translate="label.error.invalidExternalKeysTable">
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-sub-service-buttons mt-2 footer-position-sticky">
        <button (click)="cancelSubServiceData()"
                [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                class="btn mr-3">{{screenTemplateLayout.cancelOrBackButton | translate}}
        </button>
        <button (click)="saveSubServiceData(subServiceItem)"
                *ngIf="screenTemplateLayout.action !== VIEW"
                [disabled]="!validTemplate.isNameValid"
                class="btn theme-btn">
            {{screenTemplateLayout.createOrSaveButton | translate}}
        </button>
        <button (click)="goToEdit()"
                *ngIf="screenTemplateLayout.action===VIEW"
                [activityDisplay]="'SubServiceUpdate'"
                class="btn theme-btn"
                type="button">
            <span translate="label.edit"></span>
        </button>
    </div>
    <!-- =========================================== -->
    <!--End Footer-->
</div>
