<div class="container-fluid coverage-company-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.coverageCompanies"></h3>
        <div class="filters-section d-flex">
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
        <span *ngFor="let key of objectKeys(coverageCompanyMdUtils.tableFilters.orderBy)">
          <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + coverageCompanyMdUtils.tableFilters.orderBy[key] | translate}}
            <span>
            <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
          </span>
              </span>
              </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" autocomplete="off"
                       [(ngModel)]="coverageCompanyMdUtils.tableFilters.filter.Name" (ngModelChange)="onSearchCoverageCompanyChanged($event)">
            </div>
            <div class="ml-2">
                <button type="button" class="btn theme-alt-btn border-0"
                        [activityDisplay]="COVERAGE_COMPANY+constants.CREATE"
                        (click)="createCoverageCompany()"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.name' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!coverageCompanyMdUtils.tableFilters.orderBy.name"><i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="coverageCompanyMdUtils.tableFilters.orderBy.name === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="coverageCompanyMdUtils.tableFilters.orderBy.name === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th (click)="onSortBy('patientPortalPlace')">
                    {{'label.patientPortalPlace' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!coverageCompanyMdUtils.tableFilters.orderBy.patientPortalPlace"><i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="coverageCompanyMdUtils.tableFilters.orderBy.patientPortalPlace === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="coverageCompanyMdUtils.tableFilters.orderBy.patientPortalPlace === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th translate="label.coveragePlans"></th>
                <th class="code-column" translate="label.code"></th>
                <th class="is-private-column" translate="label.isPrivate"></th>
                <th class="system-column" translate="label.system"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let coverageCompany of coverageCompanyMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewCoverageCompany(coverageCompany)">
                <td class="name-column max-width-table-column">{{coverageCompany.name}}</td>
                <td class="name-column">{{coverageCompany.patientPortalPlace}}</td>
                <td>
                    <div *ngFor="let coveragePlan of coverageCompany.coveragePlans | slice:0:3">
                        {{coveragePlan.name}}
                    </div>

                    <ng-template #coveragePlansContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let coveragePlan of coverageCompany.coveragePlans">{{coveragePlan.name}}</div>
                        </div>
                    </ng-template>
                    <div *ngIf="coverageCompany.coveragePlans && coverageCompany.coveragePlans.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="coveragePlansContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td class="code-column">{{coverageCompany.code}}</td>
                <td class="is-private-column">{{coverageCompany.isPrivate | yesNoPipe | translate}}</td>
                <td class="system-column">{{coverageCompany.system | yesNoPipe | translate}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewCoverageCompany(coverageCompany)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editCoverageCompany(coverageCompany)"
                                        [activityDisplay]="COVERAGE_COMPANY+constants.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(coverageCompany.id)"
                                        [activityDisplay]="COVERAGE_COMPANY+constants.DELETE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(coverageCompany.id, EntityTypeEnum.coverageCompany, coverageCompany.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="coverageCompanyMdUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="coverageCompanyMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{coverageCompanyMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
