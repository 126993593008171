import {Component, OnInit} from '@angular/core';
import {PatientAppointmentListUtils} from './patient-appointment-list.utils';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {AppointmentProvider} from 'sked-base';
import * as lodash from 'lodash';
import {
    AppointmentActionEnum
} from '../../../shared/component/appointment-action-buttons/appointment-action-buttons.types';
import {
    PatientAppointmentListFiltersSearchType
} from './patient-appointment-list-filters/patient-appointment-list-filters.types';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {AppointmentTypeEnum} from './patient-appointment-list.types';
import {AppointmentCardEmittedActionType} from '../../../shared/component/appointment-card/appointment-card.types';

@Component({
    selector: 'app-patient-appointment-list',
    templateUrl: './patient-appointment-list.component.html',
    styleUrls: ['./patient-appointment-list.component.scss']
})
export class PatientAppointmentListComponent implements OnInit {
    appointmentTypeEnum = AppointmentTypeEnum;

    constructor(
        public patientAppointmentListUtils: PatientAppointmentListUtils,
        public patientContextService: PatientContextService,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private appointmentProvider: AppointmentProvider,
    ) {
    }

    ngOnInit(): void {
        this.loadDataFromState();
        this.checkIfNeededToResetEverything();
        this.registerToPatientChange();
    }

    onPatientAppointmentItemAction({action, data}: AppointmentCardEmittedActionType, index: number = -1) {
        switch (action) {
            case AppointmentActionEnum.Cancel: {
                // The appointment is cancelled in cancel-appointment component, here we only remove the appointment from the list
                if (index === -1) {
                    return;
                }
                if (!!this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index]) {
                    lodash.pullAt(this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList, index);
                }
                return;
            }
            case AppointmentActionEnum.Reschedule: {
                // Reschedule is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Reuse: {
                // Reuse is entirely handled by the AppointmentRescheduleReuseButton component
                return;
            }
            case AppointmentActionEnum.Status: {
                // The status is changed in appointment-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index]) {
                    this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index] = {
                        ...this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index],
                        etag: data.etag,
                        status: data.status,
                    };
                }
                return;
            }
            case AppointmentActionEnum.BulletinPDF: {
                // This action is done entirely in print-bulletin-pdf-button component
                return;
            }
            case AppointmentActionEnum.SetPaid: {
                // The paid status is changed in appointment-paid-status-change-button component, here we only update the data we display
                if (index === -1) {
                    return;
                }
                if (!!this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index]) {
                    this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index] = {
                        ...this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList[index],
                        etag: data.etag,
                        paid: data.paid,
                    };
                }
                return;
            }
            case AppointmentActionEnum.SendEmail: {
                // This action is done entirely in appointment-send-email-button component
                return;
            }
        }
    }

    onFilterSearch(filterValues: PatientAppointmentListFiltersSearchType) {
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.searchFilters = filterValues;
        this.searchForPatientAppointments();
    }

    onChangePagination(page: number) {
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.currentPage = page;
        this.searchForPatientAppointments();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.currentPage = 1;
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.itemsPerPage =
            itemPerPage;
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown = false;
        this.searchForPatientAppointments();
    }

    onChangeAppointmentTypeFilter(type: AppointmentTypeEnum) {
        this.patientAppointmentListUtils.filtersOptions.typeFilterTab = type;
        this.patientAppointmentListUtils.updateFilterState(['typeFilterTab']);
        this.searchForPatientAppointments();
    }

    refreshData() {
        this.searchForPatientAppointments();
    }

    private loadDataFromState(): void {
        // If shouldn't keep filters state rewrite the latest search filters
        if (!this.patientAppointmentListUtils.shouldKeepFiltersState) {
            this.patientAppointmentListUtils.patientAppointmentListState.filtersOptions.latestSearchFilterValues = undefined;
        }
        // If shouldMakeNewRequest and we have filter values, do request
        if (this.patientAppointmentListUtils.shouldMakeNewRequest) {
            this.onFilterSearch(
                this.patientAppointmentListUtils.patientAppointmentListState.filtersOptions?.latestSearchFilterValues
                ?? {} as PatientAppointmentListFiltersSearchType);
            return;
        }
        // If shouldKeepListState and we have items options, load them
        const shouldKeepListState = this.patientAppointmentListUtils.shouldKeepListState
            && !!this.patientAppointmentListUtils.patientAppointmentListState.patientAppointmentListItemOptions;
        this.patientAppointmentListUtils.patientAppointmentListItemOptions = shouldKeepListState
            ? lodash.cloneDeep(this.patientAppointmentListUtils.patientAppointmentListState.patientAppointmentListItemOptions)
            : this.patientAppointmentListUtils.getInitialPatientAppointmentListFiltersOptions();
        this.patientAppointmentListUtils.shouldKeepListState = false;
    }

    private checkIfNeededToResetEverything(): void {
        // If state is empty (all shouldKeep are false), then we reset everything
        if (!this.patientAppointmentListUtils.shouldKeepFiltersState &&
            !this.patientAppointmentListUtils.shouldKeepListState &&
            !this.patientAppointmentListUtils.shouldMakeNewRequest) {
            // Reset item list
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState = true;
            // Clear and show filters
            this.patientAppointmentListUtils.clearFiltersEmitter?.next();
            if (this.patientAppointmentListUtils.filtersOptions) {
                this.patientAppointmentListUtils.filtersOptions.areFiltersCollapsed = false;
            }
        }
    }

    private searchForPatientAppointments(autoSearch: boolean = false): void {
        this.ngxLoader.start();
        this.patientAppointmentListUtils.shouldMakeNewRequest = false;
        this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList = [];
        const query = this.patientAppointmentListUtils.getPatientAppointmentQueryFilter(
            autoSearch
                ? this.patientAppointmentListUtils.patientAppointmentListItemOptions.latestPatientAppointmentListRequestFilters
                : this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters
        );
        this.appointmentProvider.getByUserInformation(query, true, true).subscribe((patientAppointmentList) => {
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState = false;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.totalPatientAppointmentItems = patientAppointmentList.count;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList = patientAppointmentList.value;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isNotFoundState = patientAppointmentList.value?.length <= 0;
            if (!autoSearch) {
                this.patientAppointmentListUtils.patientAppointmentListItemOptions.latestPatientAppointmentListRequestFilters = lodash.cloneDeep(
                    this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters
                );
                this.patientAppointmentListUtils.filtersOptions.latestSearchFilterValues = lodash.cloneDeep(
                    this.patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.searchFilters
                );
            }
            // Update state
            this.patientAppointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'totalPatientAppointmentItems', 'patientAppointmentList', 'isNotFoundState',
                'latestPatientAppointmentListRequestFilters'
            ]);
            this.patientAppointmentListUtils.updateFilterState(['latestSearchFilterValues', 'patientAppointmentListFiltersValues']);
        }, (error) => {
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState = true;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isNotFoundState = false;
            this.patientAppointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState'
            ]);
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private registerToPatientChange() {
        this.patientContextService.patientChange.subscribe(() => {
            // Clear and show filters
            this.patientAppointmentListUtils.clearFiltersEmitter?.next();
            this.patientAppointmentListUtils.filtersOptions.areFiltersCollapsed = false;
            // Reset item list
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.totalPatientAppointmentItems = 0;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList = [];
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState = true;
            this.patientAppointmentListUtils.patientAppointmentListItemOptions.isNotFoundState = false;
            this.patientAppointmentListUtils.preselectAppointmentType = this.patientAppointmentListUtils.filtersOptions.typeFilterTab;
            this.patientAppointmentListUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState', 'totalPatientAppointmentItems', 'appointmentList', 'areFiltersCollapsed'
            ]);
            // Check if the patient was selected in order to execute appointment search
            if (this.patientContextService?.patient?.id) {
                this.searchForPatientAppointments();
            }
        });
    }


}
