<div class="container-fluid create-multi-resource-combination-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-multi-resource-combination-header">
        <h3 class="create-multi-resource-combination-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase" translate="label.multiResourceCombination"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveMultiResourceCombinationData(multiResourceCombinationItem)">
        <div class="create-entity-md-section">
            <!--Body-->
            <!-- =========================================== -->
            <ng-container *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.multiResourceCombinationTab.blueprintName"></td>
                                    <td class="text-right">{{multiResourceCombinationItem.bluePrint.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.multiResourceCombinationTab.mainResource"></td>
                                    <td class="text-right">{{multiResourceCombinationItem.mainResource.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.multiResourceCombinationTab.secondaryResources"></td>
                                    <td class="text-right">
                                        <span *ngFor="let item of secondaryResourcesOptions">
                                             <span *ngFor="let value of item.initialFilterValues; last as islast">
                                                 {{value.name}}<span *ngIf="!islast">, </span>
                                             </span>
                                         </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group sbase-input-element">
                            <label translate="label.multiResourceCombinationTab.blueprintName"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedMultiResourceBlueprintFilter($event)"
                                          [buttonName]="'label.multiResourceCombinationTab.blueprintName' | translate"
                                          [dependentFilters]="mainDependentFilters.multiResourceBlueprint"
                                          [hideSelectedItems]="false"
                                          [initialFilterValues]="initialFilterValues.multiResourceBlueprint"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="multiResourceBlueprintProvider"
                                          [ngClass]="{'input-element-has-error': !multiResourceCombinationItem.bluePrintId}"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                            <div *ngIf="!multiResourceCombinationItem.bluePrintId" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12" *ngIf="displayResourceControls">
                        <div class="form-group sbase-input-element"
                             [ngClass]="{'has-error': !multiResourceCombinationItem.mainResourceId}">
                            <label>
                                {{'label.multiResourceCombinationTab.mainResource' | translate}}
                                <span *ngIf="!!resourceTypesById && !!mainResourceResourceTypeId && !!resourceTypesById[mainResourceResourceTypeId]?.name">
                                ({{resourceTypesById[mainResourceResourceTypeId]?.name}})
                            </span>
                            </label>
                            <sbase-filter (selectedItemsOutput)="onSelectedMainResourceFilter($event)"
                                          [buttonName]="'label.multiResourceCombinationTab.mainResource' | translate"
                                          [dependentFilters]="mainDependentFilters.mainResource"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="initialFilterValues.mainResource"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="resourceProvider"
                                          [ngClass]="{'input-element-has-error': !multiResourceCombinationItem.mainResourceId}"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                            <div *ngIf="!multiResourceCombinationItem.mainResourceId" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="displayResourceControls">
                    <div class="row" *ngFor="let secondaryResourceOptions of secondaryResourcesOptions; let index = index">
                        <div class="col-md-6 col-12">
                            <div class="form-group sbase-input-element"
                                 [ngClass]="{'disabled-input':!multiResourceCombinationItem.mainResourceId,
                             'has-error': !(secondaryResourceOptions?.initialFilterValues?.length > 0)}"
                            >
                                <label>
                                    {{'label.multiResourceCombinationTab.secondaryResources' | translate}}
                                    <span *ngIf="!!resourceTypesById && !!resourceTypesById[secondaryResourceOptions.bluePrintItem?.resourceTypeId]?.name">
                                    ({{resourceTypesById[secondaryResourceOptions.bluePrintItem?.resourceTypeId]?.name}})
                                </span>
                                </label>
                                <sbase-filter (selectedItemsOutput)="onSelectedSecondaryResourceFilter($event, index)"
                                              [buttonName]="'label.multiResourceCombinationTab.secondaryResources' | translate"
                                              [dependentFilters]="secondaryResourceOptions.mainDependentFilters"
                                              [hideSelectedItems]="true"
                                              [initialFilterValues]="secondaryResourceOptions.initialFilterValues"
                                              [isMultipleSelectedItem]="true"
                                              [additionalExclusionList]="secondaryResourceOptions.resourceExclusionList"
                                              [providerInstance]="resourceProvider"
                                              [useSelectedValueAsLabel]="true">
                                </sbase-filter>
                                <div *ngIf="!(secondaryResourceOptions?.initialFilterValues?.length > 0)" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <!--End Body-->
            <!-- =========================================== -->

            <!--Footer-->
            <!-- =========================================== -->
            <div class="mt-2 footer-position-sticky">
                <button class="btn mr-3"
                        type="button"
                        (click)="goToParentPage()"
                        [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                        translate="{{screenTemplateLayout.cancelOrBackButton}}">
                </button>
                <button class="btn theme-btn"
                        type="submit"
                        [disabled]="!isSaveButtonEnabled()"
                        [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                        translate="{{screenTemplateLayout.createOrSaveButton}}">
                </button>
                <button (click)="goToEdit()"
                        *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                        [activityDisplay]="'MultiResourceCombinationActivityUpdate'"
                        class="btn theme-btn"
                        type="button">
                    <span translate="label.edit"></span>
                </button>
            </div>
            <!--End Footer-->
            <!-- =========================================== -->
        </div>
    </form>
</div>
