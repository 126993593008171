import {Component, Input, OnInit} from '@angular/core';
import {
} from '../assign-sub-services/assign-sub-services.types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MainResourceServiceDurationModalComponent} from './main-resource-service-duration-modal.component';
import {GeneralUtils} from '../../utils/general.utils';

@Component({
    selector: 'app-show-availability-main-resource-duration',
    templateUrl: './show-availability-main-resource-duration.component.html',
    styleUrls: ['./show-availability-main-resource-duration.component.scss']
})
export class ShowAvailabilityMainResourceDurationComponent implements OnInit {
    @Input() serviceId: string;
    @Input() resourceId: string;
    @Input() subServiceId?: string;

    constructor(private modalService: NgbModal,
                private generalUtils: GeneralUtils) {
    }

    ngOnInit(): void {
    }

    openMainResourceServiceDurationForSecondaryResourceModal() {
        const modalRef = this.modalService
            .open(MainResourceServiceDurationModalComponent, this.generalUtils.getModalOptions());

        modalRef.componentInstance.serviceId = this.serviceId;
        modalRef.componentInstance.resourceId = this.resourceId;
        modalRef.componentInstance.subServiceId = this.subServiceId;

        modalRef.result.then((subServices) => {
        }, (onClose) => {
        });
    }
}
