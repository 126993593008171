import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GenericFilterResultType} from 'sked-base';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import * as lodash from 'lodash';
import {
    PatientAppointmentListFiltersSearchType,
    PatientAppointmentListFilterWrapperInitialValuesType
} from './patient-appointment-list-filters.types';
import {PatientAppointmentListUtils} from '../patient-appointment-list.utils';
import {PatientAppointmentListFiltersUtils} from './patient-appointment-list-filters.utils';
import {PatientContextService} from '../../../../shared/services/patient-context.service';

@Component({
    selector: 'app-patient-appointment-list-filters',
    templateUrl: './patient-appointment-list-filters.component.html',
    styleUrls: ['./patient-appointment-list-filters.component.scss']
})
export class PatientAppointmentListFiltersComponent implements OnInit {
    @Output() search: EventEmitter<PatientAppointmentListFiltersSearchType> = new EventEmitter<PatientAppointmentListFiltersSearchType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();

    constructor(
        public patientAppointmentListUtils: PatientAppointmentListUtils,
        public patientAppointmentListFiltersUtils: PatientAppointmentListFiltersUtils,
        private generalUtils: GeneralUtils,
        private patientContextService: PatientContextService,
    ) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.registerToClearFilters();
        this.registerToPatientChange();
    }

    // Filter values changed
    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues).forEach((filterName: string) => {
            this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues[filter.name] = filter.value;
        }
        // Validate
        this.validateFilterWrapperFilters();
        // Update state
        this.patientAppointmentListUtils.updateFilterState(['patientAppointmentListFiltersValues']);
    }

    // Reset filters action
    onClear() {
        // Reset initial validations and date range options
        this.resetValidations();
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
        // Update state
        this.patientAppointmentListUtils.updateFilterState(['patientAppointmentListFiltersValues']);
    }

    // Search action
    onSearch() {
        const filterValues: PatientAppointmentListFiltersSearchType = this.patientAppointmentListFiltersUtils.mapFilterValuesForSearch(
            this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues,
        );
        this.validateFilterWrapperFilters();
        if (this.patientAppointmentListUtils.filtersOptions.areFiltersValid) {
            this.search.emit(filterValues);
            this.changeCollapseFilterSection(true);
        }
    }

    changeCollapseFilterSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.patientAppointmentListUtils.filtersOptions.areFiltersCollapsed =
            value ?? !this.patientAppointmentListUtils.filtersOptions.areFiltersCollapsed;
        // Update state
        this.patientAppointmentListUtils.updateFilterState(['areFiltersCollapsed']);
    }

    // Validations
    private validateFilterWrapperFilters() {
        this.updateFiltersValidation();
    }

    private updateFiltersValidation() {
        this.patientAppointmentListUtils.filtersOptions.areFiltersValid = !!this.patientContextService.patient;
        // Update state
        this.patientAppointmentListUtils.updateFilterState(['filterValidations']);
    }

    // Load data
    private resetValidations() {
        // Reset validations
        this.patientAppointmentListUtils.filtersOptions.filterValidations = this.patientAppointmentListFiltersUtils.getInitialFilterValidations();
        // Reset values for search event
        this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues =
            this.patientAppointmentListUtils.filtersOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.patientAppointmentListUtils.filtersOptions.filterWrapperInitialValues)
                : {} as PatientAppointmentListFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.validateFilterWrapperFilters();
        // Update state
        this.patientAppointmentListUtils.updateFilterState(['filterValidations', 'patientAppointmentListFiltersValues']);
    }

    private loadOptionsFromState() {
        // Load options from state (filter wrapper component needs special attention)
        this.patientAppointmentListUtils.filtersOptions = {
            ...lodash.cloneDeep(this.patientAppointmentListUtils.patientAppointmentListState.filtersOptions),
            // Need initial values to show them on filters
            filterWrapperOptions: this.patientAppointmentListFiltersUtils.getFilterWrapperOptions(
                this.patientAppointmentListUtils.patientAppointmentListState.filtersOptions.patientAppointmentListFiltersValues
            )
        };
        // Tell filter wrapper component to preselect values from state
        const previousValues = Object.keys(this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues).length !== 0
            ? this.patientAppointmentListUtils.filtersOptions.patientAppointmentListFiltersValues
            : this.patientAppointmentListUtils.filtersOptions.latestSearchFilterValues;
        this.updateFilterWrapperValues.next(
            this.patientAppointmentListFiltersUtils.getInitialFilterWrapperValuesFromPreviousValues(previousValues)
        );
        this.patientAppointmentListUtils.shouldKeepFiltersState = false;
    }

    private registerToClearFilters() {
        this.patientAppointmentListUtils?.clearFiltersEmitter?.subscribe(() => {
            this.onClear();
        });
    }

    private registerToPatientChange() {
        this.patientContextService?.patientChange?.subscribe(() => {
            this.updateFiltersValidation();
        });
    }

    private loadInitialValues() {
        if (
            !!this.patientAppointmentListUtils.shouldKeepFiltersState
            && !!this.patientAppointmentListUtils.patientAppointmentListState?.filtersOptions
        ) {
            this.loadOptionsFromState();
            return;
        }
        // Reset filter options
        this.patientAppointmentListUtils.filtersOptions = this.patientAppointmentListUtils.getEmptyFiltersOptions();
        if (this.patientAppointmentListUtils.preselectAppointmentType) {
            this.patientAppointmentListUtils.filtersOptions.typeFilterTab = this.patientAppointmentListUtils.preselectAppointmentType;
        }
        this.patientAppointmentListUtils.preselectAppointmentType = undefined;
        // Load options for the filter wrapper component
        this.patientAppointmentListUtils.filtersOptions.filterWrapperOptions = this.patientAppointmentListFiltersUtils.getFilterWrapperOptions(
            this.patientAppointmentListUtils.filtersOptions.filterWrapperInitialValues
        );
        // Load initial validations and date ranges options
        this.resetValidations();
        // Reset the initial values so the reset button will clear the filters instead of resetting to these initial values
        this.patientAppointmentListUtils.filtersOptions.filterWrapperInitialValues = undefined;
        // Update state
        this.patientAppointmentListUtils.updateFilterState([
            'filterWrapperOptions', 'statusSelectNgModel', 'filterWrapperInitialValues'
        ]);
    }
}
