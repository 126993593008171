import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../../data-model/general.type';
import {
    OversellingDefinitionType,
    ODataQueryObjectType,
    Expand,
    ChannelDependentFiltersType,
    DateRangeOptionsType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import moment from 'moment';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityOversellingDefinitionMdUtils {

    constructor(
        private dateTimeUtils: DateTimeUtils,
        private generalUtils: GeneralUtils,
    ) {
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: []
        };
    }

    getQueryFilterForAvailabilityOversellingDefinitionMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'CurrentAvailabilityId', 'Channel', 'DateTimeFrom', 'DateTimeTo', 'Quantity', 'Active', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Services: {}
        };
    }

    getInitialTableFilter(currentAvailabilityId: string): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.itemsPerPage = 5;
        tableFilters.filter = {currentAvailabilityId};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'dateTimeTo': {
                        filterQuery[lodash.upperFirst(item)] = {gt: filter[item]};
                        break;
                    }
                    case 'currentAvailabilityId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialAvailabilityOversellingDefinition(availabilityId: string): OversellingDefinitionType {
        return {
            currentAvailabilityId: availabilityId,
            originalAvailabilityId: availabilityId,
            active: false,
            services: []
        } as OversellingDefinitionType;
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const initialDateRangeOptions = this.dateTimeUtils.getInitialDateRangeOptions();
        initialDateRangeOptions.minDate = this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate;
        return initialDateRangeOptions;
    }

    getDateStringFromNgbStruct(date: NgbDateStruct, time: NgbTimeStruct, timezone: string): string {
        return moment({year: date.year, month: date.month - 1, day: date.day, hour: time.hour, minute: time.minute, second: time.second}).tz(timezone).format();
    }
}
