import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoggerService} from '../../shared/services/logger.service';
import {OpenIdConnectService} from '../../shared/services/open-id-connect.service';
import {MessagesService} from '../../shared/services/messages.service';

@Injectable()
export class HttpRequestFailInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loggerService = this.injector.get(LoggerService);
    const openIdConnectService = this.injector.get(OpenIdConnectService);
    const messagesService = this.injector.get(MessagesService);
    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status !== 401) {
              loggerService.logEventRequestFail(error);
            } else {
            }
          }
          return throwError(error);
        }));
  }
}
