import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ActivityPlanTemplateMDComponent} from './activity-plan-template-md.component';
import {CreateActivityPlanTemplateComponent} from './create-activity-plan-template/create-activity-plan-template.component';
import {
    ActivityPlanTemplateDisplayCalendarComponent
} from './create-activity-plan-template/activity-plan-template-display-calendar/activity-plan-template-display-calendar.component';
import {
    ActivityPlanTemplateServiceRecurrenceModalComponent
} from './create-activity-plan-template/activity-plan-template-service-recurrence-modal/activity-plan-template-service-recurrence-modal.component';

@NgModule({
    declarations: [
        ActivityPlanTemplateMDComponent,
        CreateActivityPlanTemplateComponent,
        ActivityPlanTemplateServiceRecurrenceModalComponent,
        ActivityPlanTemplateDisplayCalendarComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class ActivityPlanTemplateMDModule { }
