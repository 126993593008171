import {ConfigDataService} from '../../shared/services/config-data.service';
import {Injectable} from '@angular/core';
import {
    MaxRuleSetOptions,
    RuleOptions,
    RulesCategoryType,
    RulesInitialOverviewFiltersType,
    RulesOptions,
    RulesOverviewStateType
} from './rules.types';
import {
    IncludeExcludeEnum,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTypeEnum, RuleUnitEnum, SpecialityProvider,
    SpecialityType,
    TagType
} from 'sked-base';
import {RuleTableType} from 'sked-base/lib/data-model/ruleTableTypes';
import {MatStepper} from '@angular/material/stepper';
import {MessagesService} from '../../shared/services/messages.service';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CacheService} from '../../shared/services/cache.service';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';

@Injectable({
    providedIn: 'root'
})
export class RulesUtils {
    rulesOptions: RulesOptions;
    // tslint:disable-next-line:variable-name
    _selectedRule: RuleTypeEnum;
    rulesCategories: RulesCategoryType[];
    overviewState = {} as RulesOverviewStateType;
    isUnderEventRules: boolean;

    get selectedRule(): RuleTypeEnum {
        return this._selectedRule;
    }

    set selectedRule(ruleType: RuleTypeEnum) {
        this._selectedRule = ruleType ?? undefined;
        // If selectedRule's value is set to undefined, also trigger a browser back
        if (ruleType === undefined) {
            history.back();
        }
    }

    constructor(
        private configData: ConfigDataService,
        private messagesService: MessagesService,
        private router: Router,
        private ruleTableProvider: RuleTableProvider,
        private ngxLoader: NgxUiLoaderService,
        private cacheService: CacheService,
        private specialityProvider: SpecialityProvider,
        private generalUtils: GeneralUtils
    ) {
    }

    getRulesOptions(): RulesOptions {
        const isFeatureActive = this.configData.isFeatureActive('rule-management');
        return {
            [RuleTypeEnum.AppointmentEventRule]: {
                visible: this.configData.isFeatureActive('event-management')
                    && this.configData.isActivityActive('AppointmentEventRuleRead'),
                label: 'label.appointmentEvent',
                icon: 'fa-solid fa-calendar-circle-exclamation',
            } as RuleOptions,
            [RuleTypeEnum.FormAssignmentDynamicWorkListRule]: {
                visible: this.configData.isFeatureActive('rule-formAssignment-dynamicworklist')
                    && this.configData.isActivityActive('FormAssignmentDynamicWorkListRuleRead'),
                label: 'label.formAssignmentDynamicWorkList',
                icon: 'fa-solid fa-square-list',
            } as RuleOptions,
            [RuleTypeEnum.MaxAppointmentRule]: {
                visible: isFeatureActive && this.configData.isActivityActive('MaxAppointmentRuleRead'),
                label: 'label.maxAppointment',
                icon: 'fa-solid fa-calendar-days',
            } as RuleOptions,
            [RuleTypeEnum.WorkOrderRule]: {
                visible: this.configData.isFeatureActive('admin-WorkOrder')
                    && this.configData.isActivityActive('WorkOrderRuleRead'),
                label: 'label.workOrder',
                icon: 'fa-solid fa-file-plus',
            } as RuleOptions,
            [RuleTypeEnum.AvailabilityChannelRule]: {
                visible: isFeatureActive && this.configData.isActivityActive('AvailabilityChannelRuleRead'),
                label: 'label.availabilityChannel',
                icon: 'fa-solid fa-calendar-lines',
            } as RuleOptions,
            [RuleTypeEnum.SubServiceObjectDetailRule]: {
                visible: this.configData.isFeatureActive('admin-subServices')
                    && this.configData.isActivityActive('SubServiceObjectDetailRuleRead'),
                label: 'label.subServiceObjectDetail',
                icon: 'fa-regular fa-square-plus',
            } as RuleOptions,
            [RuleTypeEnum.PaymentManagementRule]: {
                visible: isFeatureActive && this.configData.isActivityActive('PaymentManagementRuleRead'),
                label: 'label.paymentManagement',
                icon: 'fa-regular fa-credit-card',
            } as RuleOptions,
            [RuleTypeEnum.LeadTimeRule]: {
                visible: this.configData.isFeatureActive('admin-LeadTime')
                    && this.configData.isActivityActive('LeadTimeRuleRead'),
                label: 'label.leadTime',
                icon: 'fa-regular fa-stopwatch',
            } as RuleOptions,
            [RuleTypeEnum.ObjectDetailRule]: {
                visible: isFeatureActive && this.configData.isActivityActive('ObjectDetailRuleRead'),
                label: 'label.objectDetail',
                icon: 'fa-solid fa-square-ellipsis',
            } as RuleOptions,
            [RuleTypeEnum.PatientControlRule]: {
                visible: this.configData.isFeatureActive('rule-control-patient')
                    && this.configData.isActivityActive('PatientControlRuleRead'),
                label: 'label.patientControl',
                icon: 'fa-regular fa-user-lock',
            } as RuleOptions,
            [RuleTypeEnum.SlotFilterRule]: {
                visible: isFeatureActive && this.configData.isActivityActive('SlotFilterRuleRead'),
                label: 'label.slotFilter',
                icon: 'fa-solid fa-filter',
            } as RuleOptions,
            [RuleTypeEnum.AppointmentPriceRule]: {
                visible: this.configData.isFeatureActive('backoffice-appointment-price')
                    && this.configData.isActivityActive('AppointmentPriceRuleRead'),
                label: 'label.appointmentPrice',
                icon: 'fa-solid fa-tag',
            } as RuleOptions,
            [RuleTypeEnum.MinimumAppointmentsRule]: {
                visible: this.configData.isFeatureActive('rule-minimum-appointments')
                    && this.configData.isActivityActive('MinimumAppointmentsRuleRead'),
                label: 'label.minimumAppointments',
                icon: 'fa-regular fa-calendar',
            } as RuleOptions,
            [RuleTypeEnum.RevenueOptimizationRule]: {
                visible: this.configData.isFeatureActive('admin-ruleTable-revenueOptimizationRule')
                    && this.configData.isActivityActive('RevenueOptimizationRuleRead'),
                label: 'label.revenueOptimization',
                icon: 'fa-regular fa-arrow-trend-up',
            } as RuleOptions,
            [RuleTypeEnum.FormAssignmentAmbulatoryRule]: {
                visible: this.configData.isFeatureActive('appointment-form-management')
                    && this.configData.isActivityActive('FormAssignmentAmbulatoryRuleRead'),
                label: 'label.formAssignmentAmbulatory',
                icon: 'fa-regular fa-file-waveform',
            } as RuleOptions,
            [RuleTypeEnum.ExclusionEventRule]: {
                visible: this.configData.isFeatureActive('event-management') && this.configData.isActivityActive('ExclusionEventRuleRead'),
                label: 'label.exclusionEvent',
                icon: 'fa-regular fa-calendar-circle-minus',
            } as RuleOptions,
            [RuleTypeEnum.CrossAvailabilityRule]: {
                visible: this.configData.isFeatureActive('rule-crossavailability') && this.configData.isActivityActive('CrossAvailabilityRuleRead'),
                label: 'label.crossAvailability',
                icon: 'fa-solid fa-arrows-cross',
            } as RuleOptions,
            [RuleTypeEnum.PatientCalculatedTagRule]: {
                visible: this.configData.isFeatureActive('rule-patient-calculated-tags') && this.configData.isActivityActive('PatientCalculatedTagRuleRead'),
                label: 'label.patientCalculatedTag',
                icon: 'fa-regular fa-user-tag',
            } as RuleOptions,
        } as RulesOptions;
    }

    getMaxRuleSets(): MaxRuleSetOptions {
        // If a rule is missing or has value -1 then that rule doesn't have a limit
        return {
            [RuleTypeEnum.FormAssignmentDynamicWorkListRule]: -1,
            [RuleTypeEnum.MaxAppointmentRule]: 1,
            [RuleTypeEnum.WorkOrderRule]: 1,
            [RuleTypeEnum.AvailabilityChannelRule]: 1,
            [RuleTypeEnum.SubServiceObjectDetailRule]: -1,
            [RuleTypeEnum.PaymentManagementRule]: -1,
            [RuleTypeEnum.LeadTimeRule]: -1,
            [RuleTypeEnum.ObjectDetailRule]: -1,
            [RuleTypeEnum.PatientControlRule]: 1,
            [RuleTypeEnum.SlotFilterRule]: -1,
            [RuleTypeEnum.AppointmentPriceRule]: 1,
            [RuleTypeEnum.MinimumAppointmentsRule]: -1,
            [RuleTypeEnum.RevenueOptimizationRule]: -1,
            [RuleTypeEnum.FormAssignmentAmbulatoryRule]: -1,
            [RuleTypeEnum.AppointmentEventRule]: -1,
            [RuleTypeEnum.ExclusionEventRule]: -1,
            [RuleTypeEnum.CrossAvailabilityRule]: -1,
            [RuleTypeEnum.PatientCalculatedTagRule]: 1
        } as MaxRuleSetOptions;
    }

    getRulesCategories() {
        const rulesGroupedByCategory: {[key in RuleTypeEnum]?: RuleTypeEnum[]} = {
            [RuleTypeEnum.SlotFilterRule]: [
                RuleTypeEnum.SlotFilterRule,
                RuleTypeEnum.MaxAppointmentRule,
                RuleTypeEnum.MinimumAppointmentsRule,
                RuleTypeEnum.AvailabilityChannelRule,
                RuleTypeEnum.RevenueOptimizationRule,
                RuleTypeEnum.LeadTimeRule,
                RuleTypeEnum.PatientControlRule,
                RuleTypeEnum.CrossAvailabilityRule,
            ],
            [RuleTypeEnum.AppointmentPriceRule]: [
                RuleTypeEnum.AppointmentPriceRule,
                RuleTypeEnum.PaymentManagementRule,
            ],
            [RuleTypeEnum.FormAssignmentAmbulatoryRule]: [
                RuleTypeEnum.FormAssignmentAmbulatoryRule,
                RuleTypeEnum.FormAssignmentDynamicWorkListRule,
            ],
            [RuleTypeEnum.AppointmentEventRule]: [
                RuleTypeEnum.AppointmentEventRule,
                RuleTypeEnum.ExclusionEventRule,
            ],
            [RuleTypeEnum.ObjectDetailRule]: [
                RuleTypeEnum.ObjectDetailRule,
                RuleTypeEnum.SubServiceObjectDetailRule,
                RuleTypeEnum.WorkOrderRule,
                RuleTypeEnum.PatientCalculatedTagRule
            ],
        };
        return [
            {
                label: 'label.rulesCategorySlots',
                visible: this.isCategoryVisible(RuleTypeEnum.SlotFilterRule, rulesGroupedByCategory),
                ruleTypes: rulesGroupedByCategory[RuleTypeEnum.SlotFilterRule],
            } as RulesCategoryType, {
                label: 'label.rulesCategoryPayment',
                visible: this.isCategoryVisible(RuleTypeEnum.AppointmentPriceRule, rulesGroupedByCategory),
                ruleTypes: rulesGroupedByCategory[RuleTypeEnum.AppointmentPriceRule],
            } as RulesCategoryType, {
                label: 'label.rulesCategoryForm',
                visible: this.isCategoryVisible(RuleTypeEnum.FormAssignmentAmbulatoryRule, rulesGroupedByCategory),
                ruleTypes: rulesGroupedByCategory[RuleTypeEnum.FormAssignmentAmbulatoryRule],
            } as RulesCategoryType, {
                label: 'label.rulesCategoryEvent',
                visible: this.isCategoryVisible(RuleTypeEnum.AppointmentEventRule, rulesGroupedByCategory),
                ruleTypes: rulesGroupedByCategory[RuleTypeEnum.AppointmentEventRule],
            } as RulesCategoryType, {
                label: 'label.rulesCategoryOthers',
                visible: this.isCategoryVisible(RuleTypeEnum.ObjectDetailRule, rulesGroupedByCategory),
                ruleTypes: rulesGroupedByCategory[RuleTypeEnum.ObjectDetailRule],
            } as RulesCategoryType,
        ] as RulesCategoryType[];
    }

    loadFilters(ruleType: RuleTypeEnum, {tableFilters, modalFilters}: RulesInitialOverviewFiltersType) {
        if (!this.overviewState.previousSelectedRule || this.overviewState.previousSelectedRule !== ruleType) {
            this.overviewState.tableFilters = lodash.cloneDeep(tableFilters);
            this.overviewState.ruleTable = null;
            if (!!modalFilters) {
                this.overviewState.modalFilters = lodash.cloneDeep(modalFilters);
            }
        }
    }

    isCategoryVisible(ruleType: RuleTypeEnum, rulesGroupedByCategory: {[key in RuleTypeEnum]?: RuleTypeEnum[]}) {
        if (this.isUnderEventRules) {
            return ruleType === RuleTypeEnum.AppointmentEventRule;
        }
        return rulesGroupedByCategory[ruleType].some((rule: RuleTypeEnum) => this.rulesOptions[rule].visible);
    }

    shouldRememberState(comingFromRoute: string): boolean {
        if (!comingFromRoute) {
            return false;
        }
        return comingFromRoute === 'createRule' || comingFromRoute === 'ruleSet';
    }

    displayIncludeIcon(entityTag: TagType, includeEntityTag: IncludeExcludeEnum): boolean {
        return !!(entityTag?.name && includeEntityTag === IncludeExcludeEnum.include);
    }

    displayExcludeIcon(entityTag: TagType, includeEntityTag: IncludeExcludeEnum): boolean {
        return !!(entityTag?.name && includeEntityTag === IncludeExcludeEnum.exclude);
    }

    loadSpecialities(ruleList: any[]) {
        const filterQuery = {select: ['Id', 'Name']};
        this.cacheService.getDataWithRevalidation(
            'Speciality', this.specialityProvider, filterQuery
        ).subscribe((specialities: {value: SpecialityType[], count?: number}) => {
            const specialitiesById = {};
            specialities?.value?.forEach((speciality: SpecialityType) => {
                specialitiesById[speciality.id] = speciality;
            });
            ruleList.forEach((ruleItem) => {
                if (!ruleItem?.service?.specialityId) {
                    return;
                }
                const speciality = specialitiesById[ruleItem.service.specialityId];
                ruleItem.service.speciality = {
                    id: speciality?.id,
                    name: speciality?.name,
                };
                ruleItem.service.specialityName = speciality?.name;
            });
        });
    }

    /// region Create rule methods
    //
    preselectRuleSetIfOnlyOneIsAvailable(onSelectedRuleTable: (ruleTable: RuleTableType[]) => void) {
        this.ngxLoader.start();
        const query = {
            select: ['Id', 'Name', 'Description', 'Type'],
            filter: {type: this.selectedRule},
            count: true
        };
        this.ruleTableProvider.getEntries(query)
            .pipe(take(1))
            .subscribe((response: {count: number, value: RuleTableType[]}) => {
                if (response.count === 1) {
                    // Callback that should be bound to the component calling this method
                    onSelectedRuleTable(response.value);
                }
                this.ngxLoader.stop();
            }, err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stop();
            });
    }

    onStepHeaderClick(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean) {
        return (stepClicked: number) => {
            // Click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
            // user just clicked on stepClicked and the move was successful
            const selectedIndex = stepper?.selectedIndex;
            this.navigateFromStepToStep(stepper, areStepsValid, selectedIndex, stepClicked);
        };
    }

    navigateFromStepToStep(stepper: MatStepper, areStepsValid: (currentStep: number) => boolean, selectedIndex: number, clickedIndex: number) {
        // Display error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!areStepsValid(step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    stepper.selectedIndex = step;
                }
                return;
            }
        }
    }

    stepperGoBack(stepper: MatStepper) {
        stepper.previous();
    }

    stepperGoForward(stepper: MatStepper) {
        stepper.next();
    }

    shouldDisplayNext(stepper: MatStepper, totalStepsCount): boolean {
        if (totalStepsCount !== undefined) {
            return (stepper.selectedIndex < totalStepsCount - 1);
        } else {
            return true;
        }
    }

    goToParentPage(comingFromRoute?: string) {
        if (comingFromRoute === 'createRule' || comingFromRoute === 'ruleSet') {
            this.overviewState.previousSelectedRule = this.selectedRule;
            history.back();
            return;
        }
        const route = this.isUnderEventRules ? '/eventRules' : '/rules';
        if (!comingFromRoute?.length) {
            this.overviewState.previousSelectedRule = this.selectedRule;
            this.router.navigate([route]);
            return;
        }
        this.router.navigate([route], {state: {comingFromRoute}});
    }

    isNumberValidForUnit(unit: RuleUnitEnum, value: number): boolean {
        if (this.generalUtils.isNullOrUndefined(value)) {
            return false;
        }
        if (unit === RuleUnitEnum.absolute) {
            return this.generalUtils.isWholePositiveNumber(value);
        }
        if (unit === RuleUnitEnum.percent) {
            return value >= 0 && value <= 100;
        }
    }

    getErrorMessageForUnitNumber(unit: RuleUnitEnum, value: number): string {
        if (this.generalUtils.isNullOrUndefined(value)) {
            return 'label.error.required';
        }
        if (unit === RuleUnitEnum.absolute) {
            return 'label.error.invalidNumber';
        }
        if (unit === RuleUnitEnum.percent) {
            return 'label.error.invalidPercent';
        }
    }
    //
    /// endregion Create rule methods

    // region Rule set methods
    //
    getInitialRuleSet(): RuleTableType {
        return {
            name: undefined,
            description: undefined,
        } as RuleTableType;
    }

    getEmptyRuleSetDependentFilters(type: RuleTypeEnum): RuleTableDependentFiltersType {
        return {
            searchPhrase: '',
            type,
            exclusionList: []
        };
    }
    //
    // endregion Rule set methods
}
