import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AppointmentStatusEnum,
    SkedTaskAutomatedProcessingStatusEnum,
    SkedTaskProvider,
    SkedTaskStatusEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {Router} from '@angular/router';
import {ActionsButtonsOptionsType, TaskItemActionEnum} from '../task-list.types';
import {CancelAppointmentOptionsType} from 'sked-base/lib/components/cancel-appointment/cancel-appointment.type';

@Component({
    selector: 'app-task-item-actions-buttons',
    templateUrl: './task-item-actions-buttons.component.html',
    styleUrls: ['./task-item-actions-buttons.component.scss']
})
export class TaskItemActionsButtonsComponent implements OnInit {
    @Input() options: ActionsButtonsOptionsType;
    @Output() taskItemAction = new EventEmitter<TaskItemActionEnum>();

    skedTaskAutomatedProcessingStatusEnum = SkedTaskAutomatedProcessingStatusEnum;
    taskItemActionEnum = TaskItemActionEnum;
    cancelAppointmentOptions: CancelAppointmentOptionsType;
    appointmentStatusEnumList = Object.keys(AppointmentStatusEnum);

    constructor(private skedTaskProvider: SkedTaskProvider,
                private router: Router,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService) {
    }

    ngOnInit(): void {
        this.loadOptionsForCancelAppointmentComponent();
    }

    onTaskItemAction(action: TaskItemActionEnum) {
        switch (action) {
            case TaskItemActionEnum.Done: {
                this.changeTaskItemStatus(SkedTaskStatusEnum.Done, TaskItemActionEnum.Done);
                return;
            }
            case TaskItemActionEnum.Reject: {
                this.changeTaskItemStatus(SkedTaskStatusEnum.Cancelled, TaskItemActionEnum.Reject);
                return;
            }
            case TaskItemActionEnum.Book: {
                // Send the action directly to the parent
                this.taskItemAction.next(action);
                return;
            }
            case TaskItemActionEnum.Reschedule: {
                // Send the action directly to the parent
                this.taskItemAction.next(action);
                return;
            }
            case TaskItemActionEnum.Cancel: {
                // Send the action directly to the parent
                this.taskItemAction.next(action);
                return;
            }
        }
    }

    displayAppointmentStatusChangeButton(action: TaskItemActionEnum): boolean {
        switch (action) {
            case TaskItemActionEnum.Cancel: {
                // Display cancel button only if we have an appointment and the status is not equal to cancelled
                // this.appointmentStatusEnumList[2] is the Cancelled value from AppointmentStatusEnum
                return this.options?.taskListItem?.appointmentId &&
                    this.options?.taskListItem?.appointment?.status !== this.appointmentStatusEnumList[2];
            }
            case TaskItemActionEnum.Done: {
                // Display done button only if processingStatus is sleeping and the task status is equal to cancelled
                return this.options?.taskListItem?.processingStatus === SkedTaskAutomatedProcessingStatusEnum.Sleeping
                    && this.options?.taskListItem?.status !== SkedTaskStatusEnum.Done;
            }
            case TaskItemActionEnum.Reject: {
                // Display done button only if processingStatus is sleeping and the task status is equal to done
                return this.options?.taskListItem?.processingStatus === SkedTaskAutomatedProcessingStatusEnum.Sleeping
                    && this.options?.taskListItem?.status !== SkedTaskStatusEnum.Cancelled;
            }
            case TaskItemActionEnum.Book: {
                // Display reschedule button only if we have a service, don`t have an appointment and the status is not equal to cancelled
                // this.appointmentStatusEnumList[2] is the Cancelled value from AppointmentStatusEnum
                return this.options?.taskListItem?.service && !this.options.taskListItem?.appointmentId
                    && this.options.taskListItem?.appointment?.status !== this.appointmentStatusEnumList[2];
            }
            case TaskItemActionEnum.Reschedule: {
                // Display reschedule button only if we have an appointment and the status is not equal to cancelled
                // this.appointmentStatusEnumList[2] is the Cancelled value from AppointmentStatusEnum
                return this.options.taskListItem?.appointmentId &&
                    this.options.taskListItem?.appointment?.status !== this.appointmentStatusEnumList[2];
            }
            default: {
                return false;
            }
        }
    }

    private changeTaskItemStatus(status: SkedTaskStatusEnum, action: TaskItemActionEnum) {
        this.ngxLoader.start();
        this.skedTaskProvider.updateEntry(this.options?.taskListItem, {...this.options?.taskListItem, status})
            .subscribe(
                () => {
                    this.taskItemAction.next(action);
                    this.messagesService.success('toastr.success.taskStatusUpdatedSuccessfully', true);
                    this.ngxLoader.stop();
                },
                error => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(error);
                }
            );
    }

    private loadOptionsForCancelAppointmentComponent(): void {
        this.cancelAppointmentOptions = {
            appointmentId: this.options?.taskListItem?.appointmentId,
            messagesServiceInstance: this.messagesService,
            translatedLabel: 'label.cancel',
            iconClass: 'fas fa-calendar'
        } as CancelAppointmentOptionsType;
    }
}
