import {Injectable} from '@angular/core';
import {
    ExternalKeysValueType,
    ODataFilterQueryType,
    ODataOrderByQueryType,
    TableFiltersType
} from '../../data-model/general.type';
import {
    CoveragePlanExternalKeyType,
    CoveragePlanType,
    Expand,
    ODataQueryObjectType,
    ServiceDependentFiltersType,
    SpecialityDependentFiltersType
} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {CoveragePlanMdServiceType, CoveragePlanMDType, CoveragePlanMdValidationType} from './coverage-plan-md.types';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ServiceType} from 'sked-base/lib/data-model/serviceTypes';
import {CoveragePlanChannelRestrictionType} from 'sked-base/lib/data-model/channelRestrictionTypes';
import {BackofficeChannelType} from '../../data-model/channel.types';

@Injectable({
    providedIn: 'root'
})
export class CoveragePlanMdUtils {
    initialServiceValues: any[] = [];
    tableFilters: TableFiltersType;
    constructor(public generalUtils: GeneralUtils) {
    }

    getQueryFilterForCoveragePlanMD(tableFilters: TableFiltersType, count: boolean = true) {
        return {
            select: ['Id', 'ShortId', 'Name', 'RowVersion', 'MainCoveragePlan', 'IsPrivate', 'Code', 'Priority', 'Type',
                'System', 'CoverageCompanyId'],
            expand: this.getExpandFilter(),
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy)
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'name': {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                    case 'service': {
                        filterQuery.Services = {any: {Id: {eq: {type: 'guid', value: filter[item].id}}}};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialCoveragePlan(): CoveragePlanMDType {
        const coveragePlan: CoveragePlanMDType = {} as CoveragePlanMDType;
        coveragePlan.name = '';
        coveragePlan.coverageCompanyId = '';
        coveragePlan.mainCoveragePlan = false;
        coveragePlan.code = '';
        coveragePlan.isPrivate = false;
        coveragePlan.system = false;
        coveragePlan.priority = null;
        coveragePlan.shortId = null;
        coveragePlan.services = [];
        coveragePlan.type = 'noValue';
        coveragePlan.appointmentPreconditionFlags = [];
        coveragePlan.channelRestrictions = [];
        coveragePlan.coveragePlanExternalKeys = [];
        coveragePlan.tags = [];

        return coveragePlan;
    }

    getExpandFilter(): Expand {
        return {
            CoverageCompany: {select: ['Id', 'Name']},
            Services: {select: ['Id', 'Name']},
            ChannelRestrictions: {select: ['Id', 'CoveragePlanId', 'Channel']},
            AppointmentPreconditionFlags: {select: ['Id', 'CoveragePlanId']},
            CoveragePlanExternalKeys: {select: ['Id', 'Key', 'Origin', 'EntityId']}
        };
    }

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        coveragePlanId: null,
        includeChannel: false,
        onlyAssignedToLocationsOfUser: false,
        patientId: null,
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        exclusionList: [],
        count: true
    })

    getSpecialityDependentFilters = (): SpecialityDependentFiltersType => ({
        searchPhrase: '',
        areaId: null,
        count: true,
        exclusionList: []
    })


    getInitialScreenTemplateValidator(): CoveragePlanMdValidationType {
        return {
            isNameValid: false,
            isCoverageCompanyValid: false,
            isPriorityValid: false
        };
    }

    getQueryForIdNameRequest(): ODataQueryObjectType {
        const query: ODataQueryObjectType = {} as ODataQueryObjectType;

        query.select = ['Id', 'Name'];

        return query;
    }

    getQueryForServicesRequest(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        const query: ODataQueryObjectType = {} as ODataQueryObjectType;

        query.select = ['Id', 'Name', 'SpecialityId'];
        query.expand = {Speciality: {select: ['Id', 'Name']}};
        query.skip = ((tableFilters.currentPage - 1) * tableFilters.itemsPerPage);
        query.top = tableFilters.itemsPerPage;
        query.orderBy = ['Speciality/Name asc', 'Name asc'];
        if (count) {
            query.count = true;
        }

        query.filter = {} as ODataFilterQueryType;

        for (const item in tableFilters.filter) {
            if (tableFilters.filter.hasOwnProperty(item) && tableFilters.filter[item]) {
                switch (item) {
                    case 'service': {
                        query.filter.Id = {eq: {type: 'guid', value: tableFilters.filter[item].id}};
                        break;
                    }
                    case 'speciality': {
                        query.filter.SpecialityId = {eq: {type: 'guid', value: tableFilters.filter[item].id}};
                        break;
                    }
                }
            }
        }

        return query;
    }

    getQueryForSelectedServicesRequest(coveragePlanItem: CoveragePlanMDType, skip?: number, count?: boolean): ODataQueryObjectType {
        const query: ODataQueryObjectType = {} as ODataQueryObjectType;

        query.filter = {CoveragePlans: {any: {Id: {eq: {type: 'guid', value: coveragePlanItem.id}}}}};
        query.select = ['Id', 'Name', 'SpecialityId'];
        query.expand = {Speciality: {select: ['Id', 'Name']}};
        query.count = count !== undefined ? count : true;
        if (skip !== undefined) {
            query.skip = skip;
        }

        return query;
    }

    getOnlyAssignedServices(tableFilters: TableFiltersType, selectedServices: CoveragePlanMdServiceType[]): { value: ServiceType[], count?: number } {
        const filteredSelectedServices: { value: ServiceType[], count?: number } = {} as { value: ServiceType[], count?: number };

        let filteredServices = lodash.cloneDeep(selectedServices);
        if (tableFilters.filter.service) {
            filteredServices = lodash.filter(filteredServices, {id: tableFilters.filter.service.id});
        }
        if (tableFilters.filter.speciality) {
            filteredServices = lodash.filter(filteredServices, {specialityId: tableFilters.filter.speciality.id});
        }
        filteredSelectedServices.value = this.paginate(filteredServices, tableFilters.itemsPerPage, tableFilters.currentPage);
        filteredSelectedServices.count = filteredServices.length;

        return filteredSelectedServices;
    }

    paginate(array: any[], pageSize: number, pageNumber: number): any[] {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    getEmptyExternalKeyItem(): ExternalKeysValueType {
        return {
            id: '',
            origin: '',
            key: '',
            entityId: ''
        };
    }

    matchAssignedServices(services: CoveragePlanMdServiceType[], assignedServices: IdNameType[]): CoveragePlanMdServiceType[] {
        if (assignedServices && assignedServices.length > 0) {
            for (const item of services) {
                const foundService = lodash.find(assignedServices, {id: item.id});
                if (foundService) {
                    item.selected = true;
                }
            }
        }
        return services;
    }

    matchAssignedChannels(channels: BackofficeChannelType[], assignedChannels: BackofficeChannelType[]): BackofficeChannelType[] {
        if (assignedChannels && assignedChannels.length > 0) {
            for (const item of channels) {
                const foundChannel = lodash.find(assignedChannels, {channel: item.enumValue});
                if (foundChannel) {
                    item.id = foundChannel.id;
                    item.selected = true;
                }
            }
        }
        return channels;
    }

    mapSelectedServicesToCoveragePlanServices(services: CoveragePlanMdServiceType[]): IdNameType[] {
        const coveragePlanServices: IdNameType[] = [];

        for (const item of services) {
            const coveragePlanService: IdNameType = {} as IdNameType;

            coveragePlanService.id = item.id;
            coveragePlanService.name = item.name;

            coveragePlanServices.push(coveragePlanService);
        }

        return coveragePlanServices;
    }

    mapCoveragePlanForServer(coveragePlan: CoveragePlanMDType): CoveragePlanType {
        const coveragePlanToSend: CoveragePlanType = {} as CoveragePlanType;

        if (coveragePlan.id) {
            coveragePlanToSend.id = coveragePlan.id;
        }

        if (coveragePlan.etag) {
            coveragePlanToSend.etag = coveragePlan.etag;
        }

        coveragePlanToSend.name = coveragePlan.name;
        if (coveragePlan.code) {
            coveragePlanToSend.code = coveragePlan.code;
        }
        coveragePlanToSend.priority = coveragePlan.priority;
        coveragePlanToSend.isPrivate = coveragePlan.isPrivate;
        coveragePlanToSend.system = coveragePlan.system;
        if (!this.generalUtils.isSelectedNoValueOption(coveragePlan.type)) {
            coveragePlanToSend.type = coveragePlan.type;
        }
        coveragePlanToSend.mainCoveragePlan = coveragePlan.mainCoveragePlan;

        if (coveragePlan.coverageCompany) {
            coveragePlanToSend.coverageCompanyId = coveragePlan.coverageCompany.id;
        }

        coveragePlanToSend.services = [];
        coveragePlan.services = lodash.orderBy(lodash.uniq(coveragePlan.services, 'id'), 'id');
        for (const service of coveragePlan.services) {
            const serviceToSend: ServiceType = {} as ServiceType;
            serviceToSend.id = service.id;
            coveragePlanToSend.services.push(serviceToSend);
        }

        coveragePlanToSend.channelRestrictions = [];
        for (const channelRestriction of coveragePlan.channelRestrictions) {
            const channelRestrictionToSend: CoveragePlanChannelRestrictionType = {} as CoveragePlanChannelRestrictionType;
            channelRestrictionToSend.id = channelRestriction.id;
            channelRestrictionToSend.channel = channelRestriction.enumValue;
            channelRestrictionToSend.enumValue = channelRestriction.enumValue;
            coveragePlanToSend.channelRestrictions.push(channelRestrictionToSend);
        }

        coveragePlanToSend.coveragePlanExternalKeys = [];
        for (const externalKey of coveragePlan.coveragePlanExternalKeys) {
            const externalKeysToSend: CoveragePlanExternalKeyType = {} as CoveragePlanExternalKeyType;
            externalKeysToSend.id = externalKey.id;
            externalKeysToSend.origin = externalKey.origin;
            externalKeysToSend.key = externalKey.key;
            coveragePlanToSend.coveragePlanExternalKeys.push(externalKeysToSend);
        }

        coveragePlanToSend.tags = coveragePlan.tags;

        return coveragePlanToSend;
    }

    getCoveragePlanTypes(coveragePlanTypes, isPrivate: boolean) {
        if (!isPrivate) {
            lodash.remove(coveragePlanTypes, (coveragePlanType) => {
                return coveragePlanType === 'SelfPayer';
            });
        }

        return coveragePlanTypes;
    }
}
