import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {CoveragePlanMDComponent} from './coverage-plan-md.component';
import {CreateCoveragePlanComponent} from './create-coverage-plan/create-coverage-plan.component';

@NgModule({
    declarations: [CoveragePlanMDComponent, CreateCoveragePlanComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class CoveragePlanMDModule {
}
