<div class="container-fluid event-action-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.eventActions"></h3>
        <div class="filters-section d-flex no-block">
            <div class="d-inline-block my-1 ml-2" *ngIf="eventActionMdUtils.modalFilters">
                <sbase-show-template-modal-filter-container
                    [contentTemplate]="eventActionModalFiltersContentTemplate"
                    [areFiltersActive]="eventActionMdUtils.modalFilters.areFiltersActive"
                    [title]="'label.filterMore'"
                    (modalClose)="onModalClose($event)"
                ></sbase-show-template-modal-filter-container>
            </div>
            <ng-template #eventActionModalFiltersContentTemplate>
                <div class="mb-2" *ngIf="!!eventActionMdUtils.modalFilters.eventTypeFilterOptions">
                    <label class="mb-2 mr-2 text-high-contrast" translate="label.type"></label>
                    <div class="col-12 p-0">
                        <ng-select
                            loadingText="{{'label.loading' | translate}}"
                            dropdownPosition="bottom"
                            class="form-control rounded-1 custom-ng-select"
                            placeholder="{{'label.type' | translate}}"
                            [(ngModel)]="eventActionMdUtils.modalFilters.eventTypeFilterOptions.ngModel"
                            [multiple]="false"
                            [searchable]="false"
                            [clearable]="false"
                            [ngClass]="!!eventActionMdUtils.modalFilters.eventTypeFilterOptions.ngModel ? 'ng-select-has-value' : null"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="sanitizeNgSelectValue($event, 'eventTypeFilterOptions')">
                            <ng-option [value]="undefined"><span translate="label.all"></span></ng-option>
                            <ng-option *ngFor="let type of EventActionTypeList"
                                       [value]="type">
                                <span [translate]="'enum.eventActionType.' + type"></span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="mb-2" *ngIf="!!eventActionMdUtils.modalFilters.eventGroupFilterOptions">
                    <label class="mb-2 mr-2 text-high-contrast" translate="label.eventGroup"></label>
                    <div class="col-12 p-0">
                        <ng-select
                            loadingText="{{'label.loading' | translate}}"
                            class="form-control rounded-1 custom-ng-select"
                            placeholder="{{'label.eventGroup' | translate}}"
                            dropdownPosition="bottom"
                            [(ngModel)]="eventActionMdUtils.modalFilters.eventGroupFilterOptions.ngModel"
                            [multiple]="false"
                            [searchable]="false"
                            [clearable]="false"
                            [ngClass]="!!eventActionMdUtils.modalFilters.eventGroupFilterOptions.ngModel ? 'ng-select-has-value' : null"
                            [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="sanitizeNgSelectValue($event, 'eventGroupFilterOptions')">
                            <ng-option [value]="undefined"><span translate="label.all"></span></ng-option>
                            <ng-option *ngFor="let group of EventGroupList"
                                       [value]="group">
                                <span [translate]="'enum.eventGroup.' + group"></span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </ng-template>
            <button class="reset-button btn mt-1" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
            <span *ngFor="let key of objectKeys(eventActionMdUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + eventActionMdUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="sort-wrapper-right d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="eventActionMdUtils.tableFilters.filter.Name"
                       (ngModelChange)="onSearchEventActionChanged($event)">
            </div>
            <div class="ml-2">
                <button type="button" class="btn theme-alt-btn border-0"
                        [activityDisplay]="EVENT_ACTION+constants.CREATE"
                        (click)="createEventAction()" translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.name' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!eventActionMdUtils.tableFilters.orderBy.name"><i
                            class="fas fa-sort"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="eventActionMdUtils.tableFilters.orderBy.name === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0"
                            *ngIf="eventActionMdUtils.tableFilters.orderBy.name === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="text-center" translate="label.eventGroup"></th>
                <th class="text-center" translate="label.type"></th>
                <th class="text-center" translate="label.active"></th>
                <th class="text-center" translate="label.reTriggerable"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let eventAction of eventActionMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewEventAction(eventAction)">
                <td class="name-column">{{eventAction.name}}</td>
                <td class="text-center">{{'enum.eventGroup.' + eventAction.eventGroup | translate}}</td>
                <td class="text-center">{{eventAction.type}}</td>
                <td class="text-center">{{eventAction.active | yesNoPipe | translate}}</td>
                <td class="text-center">{{eventAction.reTriggerable | yesNoPipe | translate}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewEventAction(eventAction)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editEventAction(eventAction)"
                                        [activityDisplay]="EVENT_ACTION+constants.UPDATE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(eventAction.id)"
                                        [activityDisplay]="EVENT_ACTION+constants.DELETE"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(eventAction.id, EntityTypeEnum.eventAction, eventAction.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="eventActionMdUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="eventActionMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{eventActionMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
