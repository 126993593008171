import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SlotProvider} from 'sked-base';
import {map, take, tap} from 'rxjs/operators';
import {SlotSearchResponseType} from 'sked-base/lib/data-model/slotTypes';
import {
    MabSlotListOptionsType,
    SlotContinueSearchModalOptionsType
} from '../multi-appointment-booking.types';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import {MessagesService} from '../../../shared/services/messages.service';
import {forkJoin, Observable} from 'rxjs';

@Component({
    selector: 'app-mab-slot-continue-search-modal',
    templateUrl: './mab-slot-continue-search-modal.component.html',
    styleUrls: ['./mab-slot-continue-search-modal.component.scss']
})
export class MabSlotContinueSearchModalComponent implements OnInit {
    @Input() options: SlotContinueSearchModalOptionsType;
    dateFrom: string;
    dateTo: string;
    private stopProcess = false;
    private searchInProgress = false;

    constructor(public activeModal: NgbActiveModal,
                private multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils,
                private slotProvider: SlotProvider,
                private messagesService: MessagesService) {
    }

    ngOnInit(): void {
        this.calculateNewTimeWindowValuesForSlotSearch();
        this.searchForSlots();
    }

    onCloseModal() {
        this.activeModal.dismiss('cancel');
        // anytime the search can be stopped if the user press Cancel
        // in this case we need to know so we don't return the results to the component
        this.stopProcess = true;
    }

    private searchForSlots() {
        const queryFiltersMap = {};
        const listIds = [];

        this.multiAppointmentBookingMdUtils.slotsListsWrapperOptions.slotsListsOptions?.forEach((slotListOptions: MabSlotListOptionsType) => {
            listIds.push(slotListOptions.listId);
            queryFiltersMap[slotListOptions.listId] = this.multiAppointmentBookingMdUtils.getQueryFilterForSlotSearch(slotListOptions.filterValues, this.options.patient);
        });

        this.dateFrom = queryFiltersMap[listIds[0]]?.dateFrom;
        this.dateTo = queryFiltersMap[listIds[0]]?.dateTo;

        const observables: Observable<{ slotsResponse: SlotSearchResponseType, listId: number }>[] = [];
        listIds.forEach((listId: number) => {
            observables.push(
                this.slotProvider.searchForSlots(queryFiltersMap[listId]).pipe(
                    take(1),
                    map((response: SlotSearchResponseType) => {
                        return {slotsResponse: response, listId};
                    })
                )
            );
        });
        forkJoin(observables).pipe(
            take(1),
            tap((responses: { slotsResponse: SlotSearchResponseType, listId: number }[]) => {
                // When no slot search returns data but the search period is still inside search time window
                // we calculate the new search period and trigger another request
                const noResults = responses.every(({slotsResponse}) => slotsResponse?.slots?.length === 0);
                const didNotReachMaxTimeWindow = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch < this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum;
                if (!this.stopProcess && noResults && didNotReachMaxTimeWindow) {
                    this.calculateNewTimeWindowValuesForSlotSearch();
                    this.searchInProgress = true;
                    this.searchForSlots();
                    return;
                } else {
                    this.searchInProgress = false;
                    return responses;
                }
            })
        ).subscribe((responses: { slotsResponse: SlotSearchResponseType, listId: number }[]) => {
            if (!this.stopProcess && !this.searchInProgress) {
                // Send responses to parent
                this.activeModal.close(responses);
            }
        }, err => {
            this.activeModal.dismiss('error');
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private calculateNewTimeWindowValuesForSlotSearch(): void {
        const searchTimeWindow = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch
            + this.multiAppointmentBookingMdUtils.mabSearchStep;
        this.multiAppointmentBookingMdUtils.previousTimeWindowInDaysBasedOnSearch = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch;
        this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch =
            Math.min(this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum, searchTimeWindow);
    }

}
