<div class="container-fluid create-holiday-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-holiday-header">
        <h3 class="create-holiday-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase"
                                                                                            translate="label.holidayTab.holiday"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveHolidayData(holidayItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.holidayTab.description"></td>
                                    <td class="text-right">{{holidayItem.description}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.holidayTab.type"></td>
                                    <td class="text-right">{{holidayItem.type}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.holidayTab.date"></td>
                                    <td class="text-right">
                                        {{(holidayItemDate.year + '/' + holidayItemDate.month + '/' + holidayItemDate.day) | dateMMddyyyyFormatPipe}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.holidayTab.description"></label>
                            <input type="text" name="description" class="input-element"
                                   [ngClass]="{ 'input-element-has-error': !holidayItem.description
                                   && screenTemplateLayout.action !== constants.VIEW}"
                                   [(ngModel)]="holidayItem.description"
                                   placeholder="{{'label.holidayTab.description'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="!holidayItem.description && screenTemplateLayout.action !== constants.VIEW" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group"
                             [ngClass]="{'disabled-input': isHolidayAssignedToHolidayCalendar}">
                            <label translate="label.holidayTab.type"></label>
                            <ng-select
                                class="custom-ng-select input-element"
                                loadingText="{{'label.loading' | translate}}"
                                dropdownPosition="bottom"
                                [(ngModel)]="holidayItem.type"
                                [multiple]="false"
                                [ngModelOptions]="{standalone: true}"
                                [searchable]="false"
                                [clearable]="false"
                                [disabled]="isHolidayAssignedToHolidayCalendar"
                                [ngClass]="{'input-element-has-error': generalUtils.isSelectedNoValueOption(holidayItem.type)
                                && screenTemplateLayout.action !== constants.VIEW}"
                                (change)="onTypeChange(holidayItem.type)">
                                <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                                <ng-option *ngFor="let type of holidayTypes"
                                           [value]="type">
                                    {{'label.holidayTab.' + (type | lowercase) | translate}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="generalUtils.isSelectedNoValueOption(holidayItem.type)" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row date-pick-component">
                    <div class="col-md-3 col-12">
                        <div class="form-group">
                            <label translate="label.holidayTab.date" class="d-block"></label>
                            <div class="input-group">
                                <input class="form-control pl-0 text-high-contrast"
                                       name="dp"
                                       [(ngModel)]="holidayItemDate"
                                       [ngClass]="{
                                   'input-error': (!holidayItemDate
                                   || (this.holidayItem.type === holidayTypesEnum.Movable
                                   && dateTimeUtils.isYearOfDateInPast(holidayItemDate)))
                                   && screenTemplateLayout.action !== constants.VIEW}"
                                       ngbDatepicker
                                       #d="ngbDatepicker"
                                       (click)="d.toggle()"
                                       [minDate]="
                            (holidayItem.type === holidayTypesEnum.Movable) && (!isHolidayAssignedToHolidayCalendar) ? null :
                            {year: initialYear, month: 1, day: 1}"
                                       [maxDate]="
                            (holidayItem.type === holidayTypesEnum.Movable) && (!isHolidayAssignedToHolidayCalendar) ? null :
                            {year: initialYear, month: 12, day: 31}"
                                       readonly>
                                <div class="input-group-append">
                                    <button class="btn calendar text-high-contrast"
                                            (click)="d.toggle()"
                                            type="button">
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="screenTemplateLayout.action !== this.constants.VIEW">
                                <div *ngIf="!holidayItemDate" class="help-block">
                                    <span translate="label.error.required"></span>
                                </div>
                                <div
                                    *ngIf="this.holidayItem.type === holidayTypesEnum.Movable
                                && dateTimeUtils.isYearOfDateInPast(holidayItemDate)"
                                    class="help-block">
                                    <span translate="toastr.error.timeNotInPast"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button class="btn theme-btn" type="button"
                    *ngIf="screenTemplateLayout.action === constants.VIEW"
                    (click)="goToEdit()"
                    [activityDisplay]="'HolidayUpdate'">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
