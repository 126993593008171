import {Injectable} from '@angular/core';
import {UserManager, User, UserManagerSettings, UserManagerEvents} from 'oidc-client';
import {environment} from '../../../environments/environment';
import {ReplaySubject} from 'rxjs';
import {LoggerService} from './logger.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from './messages.service';
import UserUnloadedCallback = UserManagerEvents.UserUnloadedCallback;
import {ConfigDataService} from './config-data.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
// official documentation: https://github.com/IdentityModel/oidc-client-js/wiki
export class OpenIdConnectService {
    private userManager: UserManager = new UserManager(this.getOpenIdConnectSettings());
    private currentUser: User = null; // logged user

    // observable for checking if user is loaded or not
    userLoaded$ = new ReplaySubject<boolean>(1);

    //getter for is user authenticated (boolean value)
    get userAvailable(): boolean {
        return this.user != null;
    }

    //getter for authenticated user
    get user(): User {
        const userSavedValue: string = sessionStorage.getItem(this.getUserSessionStorageName());
        const formattedUserValue = JSON.parse(userSavedValue);
        return this.currentUser ? this.currentUser : formattedUserValue;
    }

    constructor(private loggerService: LoggerService,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private configDataService: ConfigDataService,
                private router: Router) {
        //Removes stale state entries in storage for incomplete authorize requests
        this.userManager.clearStaleState();

        //callback for receiving user after login
        this.userManager.events.addUserLoaded(user => {
            this.loggerService.log('User loaded.', JSON.stringify(user));
            //save user
            this.currentUser = user;
        });

        //callback for removing user after logout
        this.userManager.events.addUserUnloaded(() => {
            // this.loggerService.log('User unloaded.', user);
            if (this.currentUser) {
                //clear user
                this.currentUser = null;
                //notify that the user is no more available
                this.userLoaded$.next(false);
                //log message
                this.loggerService.log('UserUnloaded');
                //show warning message
                // this.messagesService.warning('label.sessionExpired');
                //remove saved data from browser storage
                this.configDataService.clearBrowserStorages();
                //logOut from app
                //this.triggerSignOut();
            }
        });

        //callback when user logged out
        this.userManager.events.addUserSignedOut(() => {
            this.triggerSignOut();
        });

        //callback when silent renew fails
        this.userManager.events.addSilentRenewError((ev) => {
            //log the error
            this.loggerService.log('Error on SilentRenew.', ev);
            //stop any spinner
            this.ngxLoader.stop();
            //logOut
            this.triggerSignOut();
        });
    }

    //trigger a redirect of the current window to the authorization endpoint
    triggerSignIn() {
        this.ngxLoader.start();
        this.userManager.signinRedirect()
            .then((resp) => {
                this.loggerService.log('redirection to sign triggered.', JSON.stringify(resp));
                // this.ngxLoader.stop();
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }

    //trigger a redirect of the current window to the end session endpoint
    triggerSignOut() {
        this.ngxLoader.start();
        this.userManager.signoutRedirect()
            .then((resp) => {
                this.loggerService.log('redirection to sign out triggered.', resp);
                // this.ngxLoader.stop();
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }

    //process response from the authorization endpoint.
    // The result of the promise is the authenticated User
    handleRedirectCallBack() {
        if (!this.userAvailable) {
            this.userManager.signinRedirectCallback()
                .then((user) => {
                    this.loggerService.log('Callback after signin handled.', JSON.stringify(user));
                    window.history.replaceState(
                        {},
                        window.document.title,
                        window.location.origin
                    );
                    //notify that user is loaded
                    this.userLoaded$.next(true);
                }, error => {
                    if (error.message === 'No matching state found in storage') {
                        // State was created before stale cutoff, try to restart the login flow
                        this.ngxLoader.stop();
                        this.router.navigate(['']);
                        return;
                    }
                    this.loggerService.log('Signin failed.', error);
                });
        }
    }

    //notify the parent window of response from the authorization endpoint
    //user updated
    handleSilentCallback() {
        this.userManager.signinSilentCallback()
            .then((user) => {
                //update the new value of user
                this.currentUser = user;
                this.loggerService.log('Callback after silent signin handled.', user);
            });
    }

    //Enables silent renew for the UserManager
    startSilentRenew() {
        this.userManager.startSilentRenew();
    }

    //Disables silent renew for the UserManager
    stopSilentRenew() {
        this.userManager.stopSilentRenew();
    }

    //trigger signin silent
    signinSilent() {
        this.userManager.signinSilent()
            .then((user) => {
                this.loggerService.log('Trigger silent signin', user);
            });
    }

    getUserInfo() {
        return this.userAvailable ? this.mapUserInfo(this.user.profile) : this.user;
    }

    private getUserSessionStorageName(): string {
        const openIdConnectSetting = this.getOpenIdConnectSettings();
        return 'oidc.user:' + openIdConnectSetting.authority + ':' + openIdConnectSetting.client_id;
    }

    private getOpenIdConnectSettings(): UserManagerSettings {
        return {
            authority: environment.URL_IDP,
            client_id: 'backofficenew',
            redirect_uri: environment.WEBAPP_URL + 'signin-oidc',
            post_logout_redirect_uri: environment.WEBAPP_URL,
            response_type: 'code',
            scope: 'openid profile api',
            automaticSilentRenew: true,
            silent_redirect_uri: environment.WEBAPP_URL + 'silent-callback',
            staleStateAge: 15 * 60, // default 15 minutes
        };
    }

    private mapUserInfo(decodeUser) {
        const userInfo: any = {
            firstName: decodeUser.given_name,
            lastName: decodeUser.family_name,
            userName: decodeUser.nickname,
            userId: decodeUser.sub,
        };
        userInfo.fullName = userInfo.firstName + ' ' + userInfo.lastName;
        return userInfo;
    }
}
