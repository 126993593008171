import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientContextService} from '../../shared/services/patient-context.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
    selector: 'app-patient-dashboard',
    templateUrl: './patient-dashboard.component.html',
    styleUrls: ['./patient-dashboard.component.scss']
})
export class PatientDashboardComponent implements OnInit, OnDestroy {

    constructor(
        public patientContextService: PatientContextService,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
