import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {TimeWindowForChannelMDComponent} from './time-window-for-channel-md.component';
import {CreateTimeWindowForChannelComponent} from './create-time-window-for-channel/create-time-window-for-channel.component';

@NgModule({
    declarations: [TimeWindowForChannelMDComponent, CreateTimeWindowForChannelComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class TimeWindowForChannelMDModule {
}
