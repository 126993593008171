import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {NavBarService} from './features/nav-bar/nav-bar.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {LocalJsonProvider} from './core/providers/localJson.provider';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LoggerService} from './shared/services/logger.service';
import {RefreshPageToastrService} from './shared/services/refresh-page-toastr.service';
import {ConfigDataService} from './shared/services/config-data.service';
import {MessagesService} from './shared/services/messages.service';
import {OpenIdConnectService} from './shared/services/open-id-connect.service';
import * as lodash from 'lodash';
import {TranslatedLanguageService} from './shared/services/translated-language.service';
import * as moment from 'moment';
import {GeneralUtils, HttpBaseProvider} from 'sked-base';
import {IdleTimerService} from './shared/services/idle-timer.service';
import {BotDetectionService} from './shared/services/bot-detection.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'appointment-web-app-new';
    toggledSideBar = false;
    isFullScreen;

    constructor(private jsonService: LocalJsonProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private loggerService: LoggerService,
                private openIdConnectService: OpenIdConnectService,
                private refreshPageService: RefreshPageToastrService,
                private navBarService: NavBarService,
                private httpBase: HttpBaseProvider,
                private translatedLanguageService: TranslatedLanguageService,
                private configData: ConfigDataService,
                private changeDetectorRef: ChangeDetectorRef,
                private generalUtils: GeneralUtils,
                private idleTimerService: IdleTimerService,
                private botDetectionService: BotDetectionService) {
    }

    ngOnInit(): void {
        this.botDetectionService.init();
        this.botDetectionService.isBot$.subscribe((isBot: boolean) => {
            if (isBot) {
                this.openIdConnectService.triggerSignOut();
            }
        });

        // subscribing to toggle side bar event
        this.subscribeToggleSideBar(this.navBarService.toggleSideBar$);
        this.subscribeToggleFullScreen(this.navBarService.toggleFullScreen$);
        //update moment locale date format
        this.setMomentLocaleDate();
        //get initial data only if the user is logged in
        if (this.openIdConnectService.user) {
            this.getConfigData();
            this.idleTimerService.init();
        }
        // checking the app version
        this.checkAppVersion();
        //avoid “expression has changed after it was checked”
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    private subscribeToggleSideBar(toggleSideBar$) {
        toggleSideBar$
            .subscribe(toggleEvent => {
                    this.toggledSideBar = toggleEvent;
                },
                errorMessage => {
                    this.loggerService.log(errorMessage);
                });
    }

    private subscribeToggleFullScreen(toggleFullScreen$) {
        toggleFullScreen$
            .subscribe(toggleEvent => {
                    this.isFullScreen = toggleEvent;
                },
                errorMessage => {
                    this.loggerService.log(errorMessage);
                });
    }

    private getConfigData(): void {
        this.ngxLoader.start();
        const storageUser = this.openIdConnectService.getUserInfo();
        this.configData.getInitialConfigData(storageUser.userId)
            .subscribe(response => {
                const defaultTranslatedLanguageCode = this.translatedLanguageService.translatedLanguage;
                if (defaultTranslatedLanguageCode) {
                    this.translatedLanguageService.onSetLanguage(lodash.lowerCase(defaultTranslatedLanguageCode));
                }
            }, error => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private setMomentLocaleDate(): void {
        moment.updateLocale('en', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd M/D',
                dateTime: 'MM/dd/YYYY, HH:mm', //used in dateFormatPipe
                fullDate: 'MM/DD/YYYY, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'MM/DD/YYYY', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                fullDateWithTime: 'MMMM Do YYYY, h:mm', //used in fullDateWithTimeFormat
                shortDate: 'MMM dd YY',
                MMdd: 'MM/dd', //used in DateMMddFormatPipe
                MMddYYYY: 'MM/dd/YYYY', //used in DateMMddyyyFormatPipe
                MMDD: 'MM/DD', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'MM/DD/YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'M/d', //used in room-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'MMMM DD, YYYY', // used in resource utilization
                MMMMDo: 'MMMM Do', // used in DateMMMMDoFormatPipe
                hmm: 'HH:mm', // used in HourFromDatePipe
                MMMMDoYYYY: 'MMMM Do YYYY' // used in DateMMMMDoYYYYFormatPipe
            }
        });
        moment.updateLocale('es', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd D/M',
                dateTime: 'dd-MM-YYYY, HH:mm', //used in dateFormatPipe
                fullDate: 'DD-MM-YYYY, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'DD-MM-YYYY', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                fullDateWithTime: 'Do MMMM YYYY, h:mm', //used in fullDateWithTimeFormat
                shortDate: 'dd MMM YY',
                MMdd: 'dd-MM', //used in DateMMddFormatPipe
                MMddYYYY: 'dd-MM-YYYY', //used in DateMMddyyyFormatPipe
                MMDD: 'DD-MM', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'DD-MM-YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'd-M', //used in room-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'DD MMMM, YYYY', // used in resource utilization
                MMMMDo: 'Do MMMM', // used in DateMMMMDoFormatPipe
                hmm: 'HH:mm', // used in HourFromDatePipe
                MMMMDoYYYY: 'Do MMMM YYYY' // used in DateMMMMDoYYYYFormatPipe
            }
        });
        moment.updateLocale('pt', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd D/M',
                dateTime: 'dd-MM-YYYY, HH:mm', //used in dateFormatPipe
                fullDate: 'DD-MM-YYYY, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'DD-MM-YYYY', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                fullDateWithTime: 'MMMM Do YYYY, h:mm', //used in fullDateWithTimeFormat
                shortDate: 'dd MMM YY',
                MMdd: 'dd-MM', //used in DateMMddFormatPipe
                MMddYYYY: 'dd-MM-YYYY', //used in DateMMddyyyFormatPipe
                MMDD: 'DD-MM', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'DD-MM-YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'd-M', //used in room-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'DD MMMM, YYYY', // used in resource utilization
                MMMMDo: 'Do MMMM', // used in DateMMMMDoFormatPipe
                hmm: 'HH:mm', // used in HourFromDatePipe
                MMMMDoYYYY: 'Do MMMM YYYY' // used in DateMMMMDoYYYYFormatPipe
            }
        });
    }

    private checkAppVersion() {
        setInterval(() => {
            this.refreshPageService.updateAppVersion();
        }, 3600000);
    }
}

