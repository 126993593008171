import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {constants} from '../../../shared/constants/constants';
import * as lodash from 'lodash';
import {take} from 'rxjs/operators';
import {
    ObjectTagType,
    SearchFilterUtils,
    SpecialityProvider,
    SpecialityType,
    TagAssignedToEntityResultType,
    TagDependentFiltersScopeEnum,
    TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {SpecialityMdUtils} from '../speciality-md-util';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {ScreenTemplateLayoutType} from '../../../data-model/general.type';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-speciality',
    templateUrl: './create-speciality.component.html',
    styleUrls: ['./create-speciality.component.scss']
})
export class CreateSpecialityComponent implements OnInit, OnDestroy {
    constants = constants;
    initialSpeciality: SpecialityType;
    specialityItem: SpecialityType = {} as SpecialityType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    tags: ObjectTagType[] = [];
    mainDependentFilters: {
        tags: TagDependentFiltersType
    };

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public specialityProvider: SpecialityProvider,
        public router: Router,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        private searchFilterUtils: SearchFilterUtils,
        private specialityMdUtils: SpecialityMdUtils,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    private validateFields(specialityItem: SpecialityType): boolean {
        return !!(specialityItem && specialityItem.name);
    }

    onChangeTags(tags: TagAssignedToEntityResultType[]) {
        this.tags = tags.map(tag => {return {...tag, tagId: tag.id}});
    }

    saveSpecialityData(speciality: SpecialityType) {
        const isTemplateValid = this.validateFields(speciality);
        speciality.tags = this.tags;
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createSpeciality(speciality);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialSpeciality, speciality)) {
                    this.messagesService.success('toastr.success.specialityEdit', true);
                    this.goToParentPage();
                } else {
                    this.editSpeciality(this.initialSpeciality, speciality);
                }
            }
        }
    }

    // function to create the new speciality
    public createSpeciality(speciality: SpecialityType) {
        this.ngxLoader.start();
        this.specialityProvider.createObjectWithTags(speciality)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newSpecialityAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the speciality
    public editSpeciality(oldSpeciality: SpecialityType, newSpeciality: SpecialityType) {
        this.ngxLoader.start();
        this.specialityProvider.editObjectWithTags(oldSpeciality, newSpeciality)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.specialityEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    goToParentPage() {
        this.router.navigate(['/specialities']);
    }

    goToEdit() {
        history.replaceState({speciality: this.specialityItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
    }

    setupInitialState() {
        this.mainDependentFilters = {
            tags: this.searchFilterUtils.getTagsDependentFilters(null, TagDependentFiltersScopeEnum.ScopedSpeciality, true)
        };
        if (history.state && history.state.speciality) {
            this.initialSpeciality = history.state.speciality;
            this.specialityItem = lodash.cloneDeep(history.state.speciality);
            this.loadTags();
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.specialityItem = this.specialityMdUtils.getInitialSpeciality();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.validateFields(this.specialityItem);
    }

    private loadTags() {
        this.ngxLoader.start();
        this.tagProvider.getTagsAssignedToEntity({entityType: 'Speciality', entityId: history.state?.speciality?.id})
            .subscribe((tags: {value: TagAssignedToEntityResultType[]}) => {
                const tagsExceptSystem = tags?.value?.filter(tag => !tag.system);
                this.tags = this.screenTemplateLayout.action === constants.EDIT
                    ? tagsExceptSystem?.map(tag => ({...tag, tagId: tag.id})) ?? []
                    : tags?.value?.map(tag => ({...tag, tagId: tag.id})) ?? [];
                this.initialSpeciality.tags = lodash.cloneDeep(this.tags);
                this.specialityItem.tags = lodash.cloneDeep(this.tags);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
                this.goToParentPage();
            });
    }
}
