import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {SkedTaskProvider, SkedTaskType} from 'sked-base';
import {Router} from '@angular/router';
import {TaskListUtils} from './task-list-util';
import {TaskListFiltersSearchType} from './task-list-filters/task-list-filters.types';
import * as lodash from 'lodash';
import {TaskItemActionEnum, TaskListSortByEnum} from './task-list.types';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

    constructor(
        public taskListUtils: TaskListUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private skedTaskProvider: SkedTaskProvider,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loadDataFromState();
        this.checkIfNeededToResetEverything();
    }

    onTaskItemAction(action: TaskItemActionEnum, taskIndex: number = -1) {
        switch (action) {
            case TaskItemActionEnum.Done: {
                this.searchForTasks();
                return;
            }
            case TaskItemActionEnum.Reject: {
                this.searchForTasks();
                return;
            }
            case TaskItemActionEnum.Book: {
                if (taskIndex === -1) {
                    return;
                }
                // Mark booking to be done and redirect to details component for extra requests
                this.taskListUtils.shouldDoBooking = true;
                this.taskListUtils.shouldDoReschedule = false;
                this.onViewDetails(this.taskListUtils.taskListItemOptions.taskList[taskIndex]);
                return;
            }
            case TaskItemActionEnum.Reschedule: {
                if (taskIndex === -1) {
                    return;
                }
                // Mark reschedule to be done and redirect to details component for extra requests
                this.taskListUtils.shouldDoBooking = false;
                this.taskListUtils.shouldDoReschedule = true;
                this.onViewDetails(this.taskListUtils.taskListItemOptions.taskList[taskIndex]);
                return;
            }
            case TaskItemActionEnum.Cancel: {
                this.searchForTasks();
                return;
            }
        }
    }

    onFilterSearch(filterValues: TaskListFiltersSearchType) {
        this.taskListUtils.taskListItemOptions.taskListRequestFilters.searchFilters = filterValues;
        this.searchForTasks();
    }

    onSortByChange(sortBy: TaskListSortByEnum): void {
        this.taskListUtils.taskListItemOptions.taskListRequestFilters.sortBy = sortBy;
        this.searchForTasks();
    }

    onViewDetails(task: SkedTaskType): void {
        this.taskListUtils.taskItemDetailsOptions.taskItem = task;
        // Update state
        this.taskListUtils.updateItemsState(['taskListRequestFilters']);
        // Go to details screen
        if (!!this.taskListUtils?.taskItemDetailsOptions?.uploadsViewerOptions) {
            this.taskListUtils.taskItemDetailsOptions.uploadsViewerOptions = undefined;
        }
        this.router.navigate(['/taskDetails']);
    }

    onChangePagination(page: number) {
        this.taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.currentPage = page;
        this.searchForTasks();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.taskListUtils.taskListItemOptions.showItemsPerPageDropdown = false;
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.currentPage = 1;
        this.taskListUtils.taskListItemOptions.taskListRequestFilters.pageFilters.itemsPerPage = itemPerPage;
        this.taskListUtils.taskListItemOptions.showItemsPerPageDropdown = false;
        this.searchForTasks();
    }

    sanitizeNgSelectSortByValue() {
        if (this.taskListUtils.taskListItemOptions.taskListSortByList.hasOwnProperty('$ngOptionValue')) {
            this.taskListUtils.taskListItemOptions.taskListSortByList = undefined;
        }
    }

    private loadDataFromState(): void {
        // If shouldMakeNewRequest and we have filter values, do request
        if (this.taskListUtils.shouldMakeNewRequest &&
            !!this.taskListUtils.taskListState.filtersOptions?.latestSearchFilterValues) {
            this.onFilterSearch(this.taskListUtils.taskListState.filtersOptions.latestSearchFilterValues);
            return;
        }
        // If shouldKeepListState and we have items options, load them
        if (this.taskListUtils.shouldKeepListState && !!this.taskListUtils.taskListState.taskListItemOptions) {
            this.taskListUtils.taskListItemOptions = lodash.cloneDeep(this.taskListUtils.taskListState.taskListItemOptions);
        } else {
            this.taskListUtils.taskListItemOptions = this.taskListUtils.getInitialTaskListFiltersOptions();
        }
    }

    private checkIfNeededToResetEverything(): void {
        // If state is empty (all shouldKeep are false), then we reset everything
        if (!this.taskListUtils.shouldKeepFiltersState &&
            !this.taskListUtils.shouldKeepListState &&
            !this.taskListUtils.shouldMakeNewRequest) {
            // Reset item list
            this.taskListUtils.taskListItemOptions.isBeforeSearchState = true;
            // Clear and show filters
            this.taskListUtils.clearFiltersEmitter?.next();
            if (this.taskListUtils.filtersOptions) {
                this.taskListUtils.filtersOptions.areFiltersCollapsed = false;
            }
        }
    }

    private searchForTasks(autoSearch: boolean = false): void {
        this.ngxLoader.start();
        const query = this.taskListUtils.getTaskQueryFilter(
            autoSearch
                ? this.taskListUtils.taskListItemOptions.latestTaskListRequestFilters
                : this.taskListUtils.taskListItemOptions.taskListRequestFilters
        );
        this.skedTaskProvider.getByUserInformation(query, true, true).subscribe((taskList) => {
            this.taskListUtils.taskListItemOptions.isBeforeSearchState = false;
            this.taskListUtils.taskListItemOptions.totalTaskItems = taskList.count;
            this.taskListUtils.taskListItemOptions.taskList = taskList.value;
            this.taskListUtils.taskListItemOptions.isNotFoundState = taskList.value?.length <= 0;
            if (!autoSearch) {
                this.taskListUtils.taskListItemOptions.latestTaskListRequestFilters = lodash.cloneDeep(
                    this.taskListUtils.taskListItemOptions.taskListRequestFilters
                );
                this.taskListUtils.filtersOptions.latestSearchFilterValues = lodash.cloneDeep(
                    this.taskListUtils.taskListItemOptions.taskListRequestFilters.searchFilters
                );
            }
            // Update state
            this.taskListUtils.updateItemsState([
                'isBeforeSearchState', 'totalTaskItems', 'taskList', 'isNotFoundState', 'latestTaskListRequestFilters'
            ]);
            this.taskListUtils.updateFilterState(['latestSearchFilterValues']);
        }, (error) => {
            this.taskListUtils.taskListItemOptions.isBeforeSearchState = true;
            this.taskListUtils.taskListItemOptions.isNotFoundState = false;
            this.taskListUtils.updateItemsState([
                'isBeforeSearchState', 'isNotFoundState'
            ]);
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        }, () => {
            this.ngxLoader.stop();
        });
    }
}
