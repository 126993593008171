import {Injectable} from '@angular/core';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../data-model/general.type';
import {
    DataStoreDependentFiltersType, EventActionMSGraphCalendarType,
    EventActionSendgridType, EventActionTypeEnum,
    EventActionWebHookType,
    EventActionWithTypeDetailsType, EventGroupEnum,
    ODataQueryObjectType
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {
    EventActionModalFiltersType,
    EventActionTypeDetailsValidationType, EventGroupModalFilterOptionsType,
    EventTypeModalFilterOptionsType
} from './event-action-md.types';

@Injectable({
    providedIn: 'root'
})
export class EventActionMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    modalFilters: EventActionModalFiltersType;
    previousModalFilters: EventActionModalFiltersType;

    constructor(private generalUtils: GeneralUtils) {
    }

    getQueryFilterForEventActionMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
        };
    }

    getEmptyDataStorageDependentFilters(): DataStoreDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: []
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'eventGroup': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialEventAction(): EventActionWithTypeDetailsType {
        return {
            name: '',
            eventGroup: 'noValue' as EventGroupEnum,
            type: 'noValue' as EventActionTypeEnum,
            active: false,
            reTriggerable: false
        } as EventActionWithTypeDetailsType;
    }

    getInitialEventActionSendgrid(eventAction: EventActionWithTypeDetailsType, isCommunicationLogFeatureActive: boolean): EventActionSendgridType {
        const eventTypeSendgrid = eventAction as EventActionSendgridType;
        eventTypeSendgrid.templateId = undefined;
        eventTypeSendgrid.subject = undefined;
        eventTypeSendgrid.sendTo = undefined;
        if (isCommunicationLogFeatureActive) {
            eventTypeSendgrid.createCommunicationLog = true;
        }
        eventTypeSendgrid.sendgridKey = undefined;

        return eventTypeSendgrid;
    }

    getInitialEventActionWebHook(eventAction: EventActionWithTypeDetailsType): EventActionWebHookType {
        const eventTypeWebHook = eventAction as EventActionWebHookType;
        eventTypeWebHook.methodType = undefined;
        eventTypeWebHook.uri = undefined;
        eventTypeWebHook.noAuthorization = false;
        eventTypeWebHook.authorizationType = undefined;
        eventTypeWebHook.authorizationValue = undefined;

        return eventTypeWebHook;
    }

    getInitialEventActionMSGraphCalendar(eventAction: EventActionWithTypeDetailsType) {
        const eventTypeMSGraphCalendar = eventAction as EventActionMSGraphCalendarType;
        eventTypeMSGraphCalendar.mSGraphEventTypes = 'noValue';

        return eventTypeMSGraphCalendar;
    }

    getInitialEventActionTypeDetailsValidation(): EventActionTypeDetailsValidationType {
        return {
            TemplateId: {isValid: true, errorMessage: ''},
            SendgridKey: {isValid: true, errorMessage: ''},
            Subject: {isValid: true, errorMessage: ''},
            SendTo: {isValid: true, errorMessage: ''},
            CreateCommunicationLog: {isValid: true, errorMessage: ''},
            MethodType: {isValid: true, errorMessage: ''},
            Uri: {isValid: true, errorMessage: ''},
            NoAuthorization: {isValid: true, errorMessage: ''},
            AuthorizationType: {isValid: true, errorMessage: ''},
            AuthorizationValue: {isValid: true, errorMessage: ''},
            MSGraphEventTypes: {isValid: true, errorMessage: ''},
        } as EventActionTypeDetailsValidationType;
    }

    getInitialModalFilters(): EventActionModalFiltersType {
        return {
            eventTypeFilterOptions: {
                ngModel: undefined as EventTypeModalFilterOptionsType
            } as EventTypeModalFilterOptionsType,
            eventGroupFilterOptions: {
                ngModel: undefined as EventGroupModalFilterOptionsType
            } as EventGroupModalFilterOptionsType,
            areFiltersActive: false
        } as EventActionModalFiltersType;
    }

    isAnyFilterActive(): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = this.modalFilters;
        // If initial modal filters are different than the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }
}
