import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {ConfirmDeleteModalService, RuleTableDependentFiltersType, RuleTableProvider, RuleTableType, RuleTypeEnum} from 'sked-base';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import * as lodash from 'lodash';
import {RulesUtils} from '../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-rule-set',
    templateUrl: './rule-set.component.html',
    styleUrls: ['./rule-set.component.scss']
})
export class RuleSetComponent implements OnInit, OnDestroy {
    constants = constants;
    initialRuleSet: RuleTableType = undefined;
    ruleSetItem: RuleTableType = {} as RuleTableType;
    ruleSetDependentFilters: RuleTableDependentFiltersType;
    displayRuleSetInfo = false;
    ruleType: RuleTypeEnum;
    isCreateRuleSetButtonActive = false;
    maxRuleSets: number;

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        private router: Router,
        private confirmDeleteService: ConfirmDeleteModalService
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadIsRuleSetButtonActive();
    }

    ngOnDestroy(): void {
    }

    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        this.displayRuleSetInfo = true;
        if (ruleTable?.length > 0) {
            this.initialRuleSet = ruleTable[0];
            this.ruleSetItem = lodash.clone(this.initialRuleSet);
        } else {
            this.ruleSetItem = this.rulesUtils.getInitialRuleSet();
            this.initialRuleSet = undefined;
        }
    }

    onCreateRuleTable(): void {
        this.ruleSetItem = this.rulesUtils.getInitialRuleSet();
        this.initialRuleSet = undefined;
        this.displayRuleSetInfo = true;
    }

    saveRuleTableData(ruleTable: RuleTableType) {
        const isTemplateValid = this.validateFields(ruleTable);
        if (isTemplateValid) {
            if (ruleTable.id) {
                this.editRuleTable(this.initialRuleSet, ruleTable);
            } else {
                ruleTable.type = this.ruleType;
                this.createRuleTable(ruleTable);
            }
        }
    }

    displayConfirmDeleteItemModal(ruleTableId: string) {
        if (ruleTableId) {
            this.confirmDeleteService.displayConfirmDeleteModal(this.ruleTableProvider, ruleTableId).result.then(() => {
            }, (response) => {
                if (response) {
                    this.deleteItem(ruleTableId);
                }
            });
        }
    }

    goToParentPage() {
        this.rulesUtils.overviewState.previousSelectedRule = this.rulesUtils.selectedRule;
        this.rulesUtils.goToParentPage('ruleSet');
    }

    // function to create the new RuleTable
    private createRuleTable(ruleTable: RuleTableType) {
        this.ngxLoader.start();
        this.ruleTableProvider.addEntry(ruleTable)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newRuleTableAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the RuleTable
    private editRuleTable(oldRule: RuleTableType, newRule: RuleTableType) {
        this.ngxLoader.start();
        this.ruleTableProvider.updateEntry(oldRule, newRule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.ruleTableEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.ngxLoader.start();
        this.ruleTableProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.messagesService.success('toastr.success.ruleTableDelete', true);
                this.goToParentPage();
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(ruleSetItem: RuleTableType): boolean {
        return !!(ruleSetItem && ruleSetItem.name);
    }

    private setupInitialState() {
        this.ruleSetItem = this.rulesUtils.getInitialRuleSet();
        if (this.rulesUtils.selectedRule) {
            this.ruleSetDependentFilters = this.rulesUtils.getEmptyRuleSetDependentFilters(this.rulesUtils.selectedRule);
            this.ruleType = RuleTypeEnum[this.rulesUtils.selectedRule];
        } else {
            this.router.navigate(['/rules']);
        }
    }

    private loadIsRuleSetButtonActive(): void {
        this.maxRuleSets = this.rulesUtils.getMaxRuleSets()[this.rulesUtils.selectedRule];
        // If no limit, display create button
        if (this.maxRuleSets === -1 || this.maxRuleSets === undefined) {
            this.isCreateRuleSetButtonActive = true;
            return;
        }
        // Otherwise, make request to see how many rule sets there already are for the selected rule
        this.ngxLoader.start();
        this.ruleTableProvider.getEntries({ select: ['Id'], filter: {type: this.rulesUtils.selectedRule}, count: true})
            .pipe(take(1))
            .subscribe((response: {count: number, value: RuleTableType[]}) => {
                this.isCreateRuleSetButtonActive = response.count < this.maxRuleSets;
                this.ngxLoader.stop();
            }, err => {
                this.messagesService.handlingErrorMessage(err);
                this.ngxLoader.stop();
            });
    }
}
