import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'fullDateBrowserTzFormatPipe'
})

// pipe to format the date as dd-mm-yyyy, hh:mm (18-11-2020, 15:24) in the timezone of the user's browser
export class FullDateBrowserTzFormatPipe implements PipeTransform {
    transform(date: string): string {
        if (date) {
            //@ts-ignore
            return moment(date).format(moment.localeData().longDateFormat('fullDate'));
        } else {
            return undefined;
        }
    }
}
