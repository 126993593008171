import {Injectable} from '@angular/core';
import {DateRangeOptionsType, ExportSkedTasksType} from 'sked-base';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {TaskListUtils} from '../task-list-util';

@Injectable({
    providedIn: 'root'
})
export class ExportTaskListUtils {
    dateRangeOptions: DateRangeOptionsType;

    constructor(private dateTimeUtils: DateTimeUtils,
                private taskListUtils: TaskListUtils) {
    }

    getQueryFilterForExportTaskList(): ExportSkedTasksType {
        const {fromDate, toDate} = this.dateRangeOptions;
        return {
            dateFrom: moment(this.dateTimeUtils.getStringFromNgbDate(fromDate)).format(),
            dateTo: moment(this.dateTimeUtils.getStringFromNgbDate(toDate)).endOf('day').format(),
        } as ExportSkedTasksType;
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const {fromDate, toDate} = this.taskListUtils?.filtersOptions?.dueDateRangeOptions;
        const today = this.dateTimeUtils.getNgbDateFromMoment(moment());
        const todayPlus30DaysMoment = moment().add(30, 'days');
        const todayPlus30Days = this.dateTimeUtils.getNgbDateFromMoment(todayPlus30DaysMoment);
        const todayMinus30DaysMoment = moment().subtract(30, 'days');
        const todayMinus30Days = this.dateTimeUtils.getNgbDateFromMoment(todayMinus30DaysMoment);
        const minDate = {year: todayMinus30Days.year, month: todayMinus30Days.month, day: todayMinus30Days.day};
        let initialFromDate;
        let initialToDate;
        if (fromDate && toDate) {
            const fromDateMoment = this.dateTimeUtils.getMomentFromNgbDate(fromDate);
            const toDateMoment = this.dateTimeUtils.getMomentFromNgbDate(toDate);
            const differenceInDays = toDateMoment.diff(fromDateMoment, 'days');
            const fromDatePlus30DaysMoment = fromDateMoment.add(30, 'days');
            const fromDatePlus30Days = this.dateTimeUtils.getNgbDateFromMoment(fromDatePlus30DaysMoment);
            initialFromDate = fromDate;
            initialToDate = differenceInDays <= 30 ? toDate : fromDatePlus30Days;
        } else {
            initialFromDate = today;
            initialToDate = todayPlus30Days;
        }
        return {
            fromDate: initialFromDate,
            toDate: initialToDate,
            fromDateLabel: 'label.dueDateFrom',
            toDateLabel: 'label.dueDateTo',
            minDate,
            maximumRange: 30,
            disabled: false,
            initialMinDate: minDate,
            initialMaxDate: null,
        } as DateRangeOptionsType;
    }
}
