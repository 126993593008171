import {Component, OnInit} from '@angular/core';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import * as lodash from 'lodash';
import {Router} from '@angular/router';
import {
    AppointmentBulletinPdfQueryType,
    AppointmentInformationBookType,
    AppointmentInformationFormData,
    AppointmentProvider, AppointmentType,
    BookMultipleAppointmentsBodyType,
    PatientProvider,
    SlotsUtils
} from 'sked-base';
import {concatMap, take} from 'rxjs/operators';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {
    ProcessedTenantCustomizingGroupedByControlNameType,
    TenantCustomizingFilterInputType
} from '../../../data-model/tenant-customizing.type';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {constants} from 'src/app/shared/constants/constants';;
import {
    MabAppointmentInformationActionType,
    MabAppointmentOptionsType,
    MabAppointmentsInformationSlotOptionsType,
    MabBookingActionEnum
} from '../multi-appointment-booking.types';

@Component({
    selector: 'app-mab-book-appointment',
    templateUrl: './mab-book-appointment.component.html',
    styleUrls: ['./mab-book-appointment.component.scss']
})
export class MabBookAppointmentComponent implements OnInit {
    currentPage = 1;
    userInfoStorage;
    constants = constants;
    displayAppointmentInformationComponent = false;

    constructor(
        public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils,
        public router: Router,
        private configDataService: ConfigDataService,
        private appointmentProvider: AppointmentProvider,
        public messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private slotUtils: SlotsUtils,
        private translatedLanguageService: TranslatedLanguageService,
        private patientProvider: PatientProvider,
        private tenantCustomizingService: TenantCustomizingService
    ) {
    }

    ngOnInit(): void {
        this.multiAppointmentBookingMdUtils.bookAppointmentsError = undefined;
        if (lodash.isEmpty(this.multiAppointmentBookingMdUtils.appointmentInformationOptions?.patient) ||
            lodash.isEmpty(this.multiAppointmentBookingMdUtils.appointmentInformationOptions?.slotsOptions)) {
            this.router.navigate(['/multiAppointmentBooking']);
            return;
        }
        // Get user from storage
        this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        this.loadMabTenantCustomizing();
        this.setAppointmentSpecialityName();
        this.displayAppointmentInformationComponent = true;
    }

    onBookAction(appointmentInfo: MabAppointmentInformationActionType) {
        switch (appointmentInfo?.actionName) {
            case MabBookingActionEnum.Cancel : {
                this.router.navigate(
                    ['/multiAppointmentBooking'],
                    {state: {comingFromRoute: 'bookMultiAppointment'}});
                break;
            }
            case MabBookingActionEnum.Book: {
                this.updatePatientAndBookAppointments(appointmentInfo);
                break;
            }
            default: {
                this.router.navigate(['/multiAppointmentBooking']);
            }
        }
    }

    onDoneAction(appointmentInfo: MabAppointmentInformationActionType) {
        switch (appointmentInfo?.actionName) {
            case MabBookingActionEnum.Done : {
                this.router.navigate(['/multiAppointmentBooking'], {state: {bookingSuccessful: true}});
                break;
            }
            case MabBookingActionEnum.Print: {
                if (appointmentInfo?.otherData?.printAll) {
                    this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions?.slotsOptions?.forEach((
                        slotsOptions: MabAppointmentsInformationSlotOptionsType
                    ) => {
                        this.printAppointment(slotsOptions?.appointment?.id);
                    });
                } else if (appointmentInfo?.otherData?.appointmentIndex || appointmentInfo?.otherData?.appointmentIndex === 0) {
                    const index = appointmentInfo?.otherData?.appointmentIndex;
                    const appointment = this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions?.slotsOptions[index]?.appointment;
                    if (appointment?.id) {
                        this.printAppointment(appointment.id);
                    }
                }
                break;
            }
            default: {
                this.router.navigate(['/multiAppointmentBooking']);
            }
        }
    }

    private setAppointmentSpecialityName() {
        this.multiAppointmentBookingMdUtils.appointmentInformationOptions.slotsOptions =
            this.multiAppointmentBookingMdUtils.appointmentInformationOptions.slotsOptions.map((
                slotsOptions: MabAppointmentsInformationSlotOptionsType
            ) => ({
                ...slotsOptions,
                appointment: {
                    ... slotsOptions.appointment,
                    service: {
                        ...slotsOptions.appointment?.service,
                        speciality: {
                            name: slotsOptions?.additionalData?.specialityName
                        }
                    }
                },
            } as MabAppointmentsInformationSlotOptionsType));
    }

    private updatePatientAndBookAppointments(appointmentInfo: MabAppointmentInformationActionType): void {
        this.multiAppointmentBookingMdUtils.bookAppointmentsError = undefined;
        this.ngxLoader.start();
        const bookAppointmentsBody: BookMultipleAppointmentsBodyType = {
            appointments: this.multiAppointmentBookingMdUtils.bookAppointmentListOptions.map((slotsOptions: MabAppointmentOptionsType) => {
                const appointmentInformationBook: AppointmentInformationBookType = {
                    patient: this.multiAppointmentBookingMdUtils.appointmentInformationOptions?.patient,
                    slot: slotsOptions?.slot,
                    formData: {
                        ...appointmentInfo.formData,
                        appointmentType: slotsOptions?.slot?.appointmentType
                    },
                    additionalData: slotsOptions?.additionalData
                };
                if (appointmentInformationBook.slot?.reservationId === 'specialBooking') {
                    delete appointmentInformationBook.slot?.reservationId;
                }
                return this.slotUtils.mapSlotDataForBookAppointment(appointmentInformationBook);
            })
        } as BookMultipleAppointmentsBodyType;

        // Patient data
        const oldPatient = this.multiAppointmentBookingMdUtils.appointmentInformationOptions?.patient;
        const newPatient = {
            ...this.multiAppointmentBookingMdUtils.appointmentInformationOptions?.patient,
            ...appointmentInfo.formData
        };
        // If the patient data is the same as before only book the appointment
        if (this.isPatientDataTheSameAsBefore(oldPatient, newPatient)) {
            this.bookAppointments(bookAppointmentsBody);
        } else {
            // If the patient data was changed than update patient data and book the appointment
            this.bookAppointmentsAndUpdatePatientData(oldPatient, newPatient, bookAppointmentsBody);
        }
    }

    private bookAppointments(body: BookMultipleAppointmentsBodyType) {
        this.appointmentProvider.bookMultipleAppointments(body).pipe(
            take(1)).subscribe((response: {value: AppointmentType[]}) => {
            this.bookAppointmentsSuccessResponse(response.value);
        }, err => {
            this.onBookAppointmentsError(err);
        });
    }

    private bookAppointmentsAndUpdatePatientData(oldPatient: PatientType, newPatient: PatientType, body: BookMultipleAppointmentsBodyType) {
        // 1. Update patient data
        this.patientProvider.updateEntry(oldPatient, newPatient).pipe(
            take(1),
            concatMap((response) => {
                // 2. Book appointment
                this.messagesService.success('toastr.success.patientUpdated', true);
                // Update patient information with the new data
                const updatedPatient = response as unknown as PatientType;
                newPatient.etag = updatedPatient.etag;
                this.updatePatientInSearchAndDisplayComponents(newPatient);
                return this.appointmentProvider.bookMultipleAppointments(body);
            }))
            .pipe(take(1)).subscribe((response: {value: AppointmentType[]}) => {
                this.bookAppointmentsSuccessResponse(response.value);
            }, err => {
                this.onBookAppointmentsError(err);
            });
    }

    private onBookAppointmentsError(error: any) {
        this.ngxLoader.stop();
        if (!!error?.error?.index || error?.error?.index === 0) {
            error = error.error;
        }
        if (this.shouldProcessErrorWithIndex(error)) {
            this.multiAppointmentBookingMdUtils.bookAppointmentsError = {
                index: error.index,
                message: error.message
            };
        } else {
            this.messagesService.handlingErrorMessage(error);
        }
    }

    private shouldProcessErrorWithIndex(error: any): boolean {
        return (!!error?.index || error?.index === 0)
            && error.index >= 0
            && !!error.key
            && this.messagesService.getMabTranslatedErrorKeys()?.includes(error.key)
            && !!error.message;
    }

    private bookAppointmentsSuccessResponse(appointments: AppointmentType[]) {
        this.multiAppointmentBookingMdUtils.bookAppointmentsError = undefined;
        this.ngxLoader.stop();
        this.messagesService.success('toastr.success.appointmentBooked', true);

        this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.slotsOptions?.forEach((
            slotOptions: MabAppointmentsInformationSlotOptionsType
        ) => {
            slotOptions.appointment = lodash.find(appointments, {
                dateTimeFrom: slotOptions?.slot?.dateTime,
                duration: slotOptions?.slot?.duration,
                serviceId: slotOptions?.slot?.service?.id,
                resourceId: slotOptions?.slot?.resource?.id,
                coveragePlanId: slotOptions?.slot?.coveragePlan?.id,
            });
        });
        this.currentPage += 1;
        this.multiAppointmentBookingMdUtils.bookingSuccessful = true;
    }

    private isPatientDataTheSameAsBefore(oldPatient: PatientType, newPatient: PatientType): boolean {
        const oldPatientFormData = {
            mainPhoneNumber: lodash.isEmpty(oldPatient.mainPhoneNumber) ? undefined : oldPatient.mainPhoneNumber,
            alternatePhoneNumber: lodash.isEmpty(oldPatient.alternatePhoneNumber) ? undefined : oldPatient.alternatePhoneNumber,
            email: lodash.isEmpty(oldPatient.email) ? undefined : oldPatient.email,
        } as AppointmentInformationFormData;
        const newPatientFormData = {
            mainPhoneNumber: lodash.isEmpty(newPatient.mainPhoneNumber) ? undefined : newPatient.mainPhoneNumber,
            alternatePhoneNumber: lodash.isEmpty(newPatient.alternatePhoneNumber) ? undefined : newPatient.alternatePhoneNumber,
            email: lodash.isEmpty(newPatient.email) ? undefined : newPatient.email,
        } as AppointmentInformationFormData;

        return lodash.isEqual(oldPatientFormData, newPatientFormData);
    }

    private updatePatientInSearchAndDisplayComponents(newPatient: PatientType): void {
        // Update patient data from sbase-patient-details
        this.multiAppointmentBookingMdUtils.appointmentInformationOptions.patient = newPatient;
        // Update patient data from sbase-patient-search
        this.multiAppointmentBookingMdUtils.multiAppointmentBookingState.patientSearchOptions.initialSelectedPatient = newPatient;
        // Update patient data from overview
        this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.patient = newPatient;
    }

    private printAppointment(appointmentId: string): void {
        this.ngxLoader.start();
        const queryFilter: AppointmentBulletinPdfQueryType = {
            appointmentId,
            languageCode: this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage)
        };

        this.appointmentProvider.getAppointmentBulletinPdf(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.processFileResponse(response);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private processFileResponse(response): void {
        const downloadUrl = URL.createObjectURL(response.file);
        // open in new tab
        window.open(downloadUrl, '_blank');
    }

    private loadMabTenantCustomizing() {
        this.tenantCustomizingService.getTenantCustomizingValuesFor(
            this.getMabTenantCustomizingFilterObject()
        ).subscribe((response: ProcessedTenantCustomizingGroupedByControlNameType) => {
            this.multiAppointmentBookingMdUtils.appointmentInformationOptions.tenantCustomizingData = lodash.pick(response, ['Email', 'MainPhoneNumber', 'AlternatePhoneNumber']);
            this.multiAppointmentBookingMdUtils.appointmentInformationOverviewOptions.tenantCustomizingData = lodash.pick(response, ['Email', 'MainPhoneNumber', 'AlternatePhoneNumber']);
        });
    }

    private getMabTenantCustomizingFilterObject(): TenantCustomizingFilterInputType {
        return {
            ui: 'reception.patientOverview',
            entity: 'PatientDTO',
            situation: 'Edit',
            role: this.userInfoStorage?.team?.organizationalRole
        } as TenantCustomizingFilterInputType;
    }
}
