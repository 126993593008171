import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PatientType} from 'sked-base/lib/data-model/patientTypes';
import {
    PatientClientAgreementsEnum,
    PatientOverviewClientAgreementsType,
    PatientOverviewCoveragePlansType
} from './create-patient-overview.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {CreatePatientUtils} from '../create-patient.utils';
import {PatientDashboardUtils} from '../../patient-dashboard.util';

@Component({
    selector: 'app-create-patient-overview',
    templateUrl: './create-patient-overview.component.html',
    styleUrls: ['./create-patient-overview.component.scss']
})
export class CreatePatientOverviewComponent implements OnInit, OnChanges {
    @Input() patient: PatientType;

    patientCoveragePlans = {} as PatientOverviewCoveragePlansType;
    patientClientAgreements = {} as PatientOverviewClientAgreementsType;
    displayAdditionalInfoLabel: boolean;

    constructor(public generalUtils: GeneralUtils,
                public createPatientUtils: CreatePatientUtils,
                public patientDashboardUtils: PatientDashboardUtils) {
    }

    ngOnInit(): void {
        this.setInitialData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.patient.currentValue) {
            this.patient = changes.patient.currentValue;
            this.setInitialData();
        }
    }

    private setInitialData(): void {
        if (this.patient?.coveragePlans?.length > 0) {
            this.setPatientCoveragePlans();
        } else {
            this.resetPatientCoveragePlans();
        }

        this.setPatientClientAgreements();
        this.mapPatientFullName();
        this.displayAdditionalInfoLabel =
            !!this.patientCoveragePlans?.mainCoveragePlan
            || this.patientCoveragePlans?.secondaryCoveragePlans?.length > 0
            || this.patient?.tags?.length > 0 || !!this.patientClientAgreements?.onlineAccount
            || !!this.patientClientAgreements?.whatsappNotifications
            || !!this.patient?.remarks || !!this.patient?.id;
    }

    private mapPatientFullName() {
        const {firstName1, firstName2, lastName1, lastName2} = this.patient;
        this.patient.fullName = [firstName1, firstName2, lastName1, lastName2].filter(name => name !== '' && name).join(' ');
    }

    private setPatientClientAgreements(): void {
        this.patientClientAgreements = {
            onlineAccount: this.patient?.clientAgreements?.some(({version}) => version === PatientClientAgreementsEnum.OnlineAccount),
            whatsappNotifications: this.patient?.clientAgreements?.some(({version}) => version === PatientClientAgreementsEnum.WhatsappNotifications)
        };
    }

    private setPatientCoveragePlans(): void {
        this.patientCoveragePlans = {
            mainCoveragePlan: this.patient.coveragePlans.filter(({mainCoveragePlan}) => mainCoveragePlan)[0]?.name,
            secondaryCoveragePlans: this.patient.coveragePlans.filter(({mainCoveragePlan}) => !mainCoveragePlan).map(({name}) => name)
        };
    }

    private resetPatientCoveragePlans(): void {
        this.patientCoveragePlans = {
            mainCoveragePlan: '',
            secondaryCoveragePlans: []
        };
    }
}
