<div class="container-fluid overview-screen-md patient-management-component create-entity-md-wrapper">
    <div class="patient-management-header">
        <h3 class="title" translate="label.patientAudit"></h3>
    </div>
    <div class="row create-entity-md-section patient-management-section mt-2">
        <!-- name -->
        <div class="col">
            <div class="form-group">
                <label translate="label.patientName"></label>
                <input type="text"
                       class="input-element"
                       [(ngModel)]="searchBy.searchPhrase"
                       (ngModelChange)="onNameValueChanged($event)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{'label.patientName'|translate}}"/>
            </div>
        </div>
        <!-- document number -->
        <div class="col">
            <div class="form-group">
                <label translate="label.documentNumber"></label>
                <input type="text"
                       [(ngModel)]="searchBy.documentNumber"
                       (ngModelChange)="onDocumentNumberValueChanged(searchBy.documentNumber)"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{'label.documentNumber'|translate}}"
                       class="input-element">
            </div>
        </div>
        <!-- countries -->
        <div class="col">
            <div class="form-group">
                <label translate="label.documentCountry"></label>
                <ng-select [items]="documentCountries"
                           placeholder="{{countryPlaceholder | translate}}"
                           bindLabel="countryName"
                           bindValue="id"
                           class="custom-ng-select input-element"
                           [(ngModel)]="selectedCountry"
                           (search)="onCountrySearch($event)"
                           (focus)="onCountrySearch($event)"
                           (blur)="onCountryBlur($event)"
                           (change)="onCountryValueChanged($event)"
                           notFoundText="{{'toastr.error.notFound' | translate}}">
                </ng-select>
            </div>
        </div>
        <!-- document types -->
        <div class="col">
            <div class="form-group">
                <label translate="label.documentType"></label>
                <ng-select
                        class="custom-ng-select input-element"
                        loadingText="{{'label.loading' | translate}}"
                        dropdownPosition="bottom"
                        [(ngModel)]="selectedDocumentType"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [ngModelOptions]="{standalone: true}"
                        (change)="onDocumentTypeValueChanged($event)">
                    <ng-option value="noValue"><span translate="label.choose"></span></ng-option>
                    <ng-option *ngFor="let documentType of documentTypes"
                               [value]="documentType">
                        {{'enum.documentType.' + documentType.name | translate}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-1">
            <div class="form-group">
                <label translate="label.reset"></label>
                <div>
                    <button type="button"
                            class="btn">
                        <i class="fas fa-times fa-1x cursor-pointer"
                           (click)="onResetInput()"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.fullName"></td>
            <td translate="label.documentNumber"></td>
            <td translate="label.documentCountry"></td>
            <td translate="label.documentType"></td>
            <td translate="label.birthDate"></td>
            <td translate="label.physicianTab.gender"></td>
            <td translate="label.centerTab.mainPhoneNumber"></td>
            <td translate="label.alternatePhoneNumber"></td>
            <td translate="label.physicianTab.email"></td>
            <td translate="label.cancelled"></td>
            <td translate="label.action"></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="patients.length === 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr *ngFor="let patient of patients">
            <td class="text-center">{{patient.fullName}}</td>
            <td class="text-center">{{patient.documentNumber}}</td>
            <td class="text-center">{{patient.documentCountry}}</td>
            <td class="text-center">{{patient.documentType ? ('enum.documentType.' + patient.documentType | translate ): ''}}</td>
            <td class="text-center">{{patient.birthDate | fullDateNoTimeFormatPipe}}</td>
            <td class="text-center">{{'label.systemConfigTab.tabs.gender.keyGenderAllowed' + patient.gender | translate}}</td>
            <td class="text-center">{{patient.mainPhoneNumber}}</td>
            <td class="text-center">{{patient.alternatePhoneNumber}}</td>
            <td class="text-center">{{patient.email}}</td>
            <td class="text-center">{{patient.cancelled | yesNoPipe | translate}}</td>
            <td class="text-center">
                <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                        popoverClass="popover-shadow">
                    <i class="fas fa-ellipsis-h"></i>
                </button>
                <ng-template #popContent>
                    <ul class="list-style-none m-0 p-0 custom-drop-down">
                        <li>
                            <button type="button" class="btn" translate="label.anonymizePatient"
                                    [activityDisplay]="constants.ANONIMIZE_PATIENT"
                                    (click)="onAnonymizePatient(patient.id)">
                            </button>
                        </li>
                        <!--Cancel action is not implemented yet-->
                        <li *ngIf="false">
                            <button type="button" class="btn"
                                    translate="label.cancel"
                                    (click)="onCancelPatient(patient.id)">
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn"
                                    translate="label.exportData"
                                    (click)="onEnqueuePatientDataExport(patient.id)">
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn"
                                    translate="label.changeLog"
                                    (click)="displayChangeLog(patient.id, EntityTypeEnum.patient, patient.fullName)"
                                    [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ">
                            </button>
                        </li>
                    </ul>
                </ng-template>
            </td>
        </tr>
        </tbody>
    </table>
</div>


