import {Injectable} from '@angular/core';
import {
    ExternalKeysValueType,
    IdNameType,
    ODataFilterQueryType,
    ODataOrderByQueryType,
    TableFiltersType
} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {
    AreaDependentFiltersType,
    CenterProvider,
    CenterType, ChannelDependentFiltersType,
    CoveragePlanDependentFiltersType,
    CoveragePlanProvider,
    CoveragePlanType,
    Expand,
    LocationDependentFiltersType, MultiResourceBluePrintDependentFiltersType,
    ServiceExternalKeyType,
    ServiceProvider,
    ServiceType,
    SpecialityDependentFiltersType,
    SubServiceDependentFiltersType,
    SubServiceProvider
} from 'sked-base';
import {ODataQueryObjectType} from 'sked-base/lib/data-model/oDataObjectTypes';
import {
    BackofficeServiceMDType,
    MultiResourceBluePrintFilterOptionsType,
    ServiceMDSearchByEnum,
    ServiceMDSearchByNameType,
    ServiceMDSearchByShortIdType,
    ServiceModalFilterOptionsType,
    ServiceMultiSelectTableOptionsType,
    ValidServiceObjectType
} from './service-md.types';
import {SubServiceType} from 'sked-base/lib/data-model/subServiceTypes';
import {ServiceChannelRestrictionType} from 'sked-base/lib/data-model/channelRestrictionTypes';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {
    MultiSelectTableColumnKindEnum,
    MultiSelectTableColumnType,
    MultiSelectTableOptionsType
} from '../../shared/component/multi-select-table/multi-select-table.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {MultiSelectTableUtils} from '../../shared/component/multi-select-table/multi-select-table-util';

@Injectable({
    providedIn: 'root'
})
export class ServiceMdUtils {
    validTemplate: ValidServiceObjectType;
    tableFilters: TableFiltersType;
    modalFilters: ServiceModalFilterOptionsType;
    previousModalFilters: ServiceModalFilterOptionsType;
    initialSpecialityValues: any[] = [];
    initialCenterValues: any[] = [];
    initialAreaValues: any[] = [];
    initialChannelValues: any[] = [];
    initialMultiResourceBluePrintFilterValues: any[] = [];

    constructor(private generalUtils: GeneralUtils,
                private configData: ConfigDataService,
                private centerProvider: CenterProvider,
                private coveragePlanProvider: CoveragePlanProvider,
                private subServiceProvider: SubServiceProvider,
                private serviceProvider: ServiceProvider,
                private translatedLanguageService: TranslatedLanguageService,
                private multiSelectTableUtils: MultiSelectTableUtils
    ) {
    }

    getQueryFilterForServices(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            expand: this.getExpandFilter(),
            select: this.getSelectFilter(),
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy)
        };
    }

    getSelectFilter(): string[] {
        return ['Id', 'RowVersion', 'Code', 'ShortId', 'Name', 'DefaultDuration', 'AreaId', 'CheckinDisabled', 'SpecialityId',
            'MultiResourceBluePrintId', 'MinSubServices', 'MaxSubServices', 'OnlineConsultation', 'EnableWaitList'];
    }

    getExpandFilter(): Expand {
        return {
            Centers: {select: ['Id', 'Name']},
            Speciality: {select: ['Id', 'Name']},
            Area: {select: ['Id', 'Name', 'Cancelled']},
            ChannelRestrictions: {select: ['Id', 'ServiceId', 'Channel']},
            MultiResourceBluePrint: {select: ['Id', 'Name']},
            CoveragePlans: {select: ['Id', 'Name']},
            AppointmentPreconditionFlags: {select: ['Id', 'CoveragePlanId']},
            ServiceExternalKeys: {select: ['Id', 'Key', 'Origin', 'EntityId']},
            SubServices: {select: ['Id', 'Name']}
        };
    }

    getItemsPerPageList(): number[] {
        return [5, 10];
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'name': {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                    case 'onlineConsultation':
                    case 'shortId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'areaId':
                    case 'specialityId':
                    case 'multiResourceBluePrintId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'channelRestriction': {
                        filterQuery.ChannelRestrictions = {any: {Channel: {eq: filter[item].enumValue}}};
                        break;
                    }
                    case 'center': {
                        filterQuery.Centers = {any: {Id: {eq: {type: 'guid', value: filter[item].id}}}};
                        break;
                    }
                    case 'hasMultiResourceBluePrint': {
                        filterQuery.MultiResourceBluePrintId = {ne: null};
                        break;
                    }
                }

            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (item === 'speciality' || item === 'area') {
                orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
            } else {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getInitialServiceValue(): BackofficeServiceMDType {
        return {
            name: '',
            code: '',
            defaultDuration: 0,
            area: 'noValue' as unknown as IdNameType,
            checkinDisabled: false,
            onlineConsultation: false,
            shortId: undefined,
            speciality: undefined,
            multiResourceBluePrint: 'noValue' as unknown as IdNameType,
            minSubServices: 0,
            maxSubServices: 1,
            hasSubServices: undefined,
            channelRestrictions: [],
            centers: [],
            visitReasons: [],
            coveragePlans: [],
            appointmentPreconditionFlags: [],
            serviceExternalKeys: [],
            subServices: [],
            tags: [],
            objectDetails: [],
            enableWaitList: false
        };
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getCoveragePlanDependentFilters(): CoveragePlanDependentFiltersType {
        return {
            searchPhrase: '',
            excludePrivatePlans: false,
            excludeSystemManagedPlans: false,
            serviceId: null,
            resourceId: null,
            areaId: null,
            patientId: null,
            includeChannel: false,
            onlyMainPlans: false,
            onlySecondaryPlans: false,
            exclusionList: [],
            count: true
        };
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }

    getSubServiceDependentFilters = (): SubServiceDependentFiltersType => ({
        searchPhrase: '',
        serviceId: null,
        specialityId: null,
        includeAvailabilities: false,
        resourceId: null,
        locationId: null,
        areaId: null,
        exclusionList: [],
        count: true
    })

    getSpecialityDependentFilters(): SpecialityDependentFiltersType {
        return {
            searchPhrase: '',
            areaId: null,
            count: true,
            exclusionList: []
        };
    }

    getMultiResourceBluePrintDependentFilters(): MultiResourceBluePrintDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            count: true
        };
    }

    mapServiceToSend(service: BackofficeServiceMDType, systemTagId?: string): ServiceType {
        const serviceToSend: ServiceType = {} as ServiceType;
        if (service.id) {
            serviceToSend.id = service.id;
        }
        if (service.etag) {
            serviceToSend.etag = service.etag;
        }
        serviceToSend.name = service.name;
        serviceToSend.code = service.code;
        serviceToSend.defaultDuration = service.defaultDuration;
        serviceToSend.shortId = service.shortId;
        serviceToSend.minSubServices = service.minSubServices;
        serviceToSend.maxSubServices = service.maxSubServices;
        serviceToSend.hasSubServices = service.hasSubServices;
        serviceToSend.checkinDisabled = service.checkinDisabled;
        serviceToSend.onlineConsultation = service.onlineConsultation;
        serviceToSend.centers = [];
        serviceToSend.coveragePlans = [];
        serviceToSend.subServices = [];
        serviceToSend.channelRestrictions = [];
        serviceToSend.serviceExternalKeys = [];

        if (service.enableWaitList) {
            serviceToSend.enableWaitList = service.enableWaitList;
        }

        if (service.area) {
            serviceToSend.areaId = service.area.id;
        }
        if (service.speciality) {
            serviceToSend.specialityId = service.speciality.id;
        }
        if (!!service.multiResourceBluePrint?.id && !this.generalUtils.isSelectedNoValueOption(service.multiResourceBluePrint)) {
            serviceToSend.multiResourceBluePrintId = service.multiResourceBluePrint.id;
        }
        if (service?.tags?.length > 0) {
            service.tags = service.tags.filter(tag => tag.id !== systemTagId);
        }

        for (const subService of service.subServices) {
            const subServiceItemToSent: SubServiceType = {} as SubServiceType;
            subServiceItemToSent.id = subService.id;
            serviceToSend.subServices.push(subServiceItemToSent);
        }

        for (const center of service.centers) {
            const centerToSent: CenterType = {} as CenterType;
            centerToSent.id = center.id;
            serviceToSend.centers.push(centerToSent);
        }

        for (const coveragePlan of service.coveragePlans) {
            const coveragePlanToSend: CoveragePlanType = {} as CoveragePlanType;
            coveragePlanToSend.id = coveragePlan.id;
            serviceToSend.coveragePlans.push(coveragePlanToSend);
        }

        for (const channelRestriction of service.channelRestrictions) {
            const channelRestrictionToSend: ServiceChannelRestrictionType = {} as ServiceChannelRestrictionType;
            channelRestrictionToSend.id = channelRestriction.id;
            channelRestrictionToSend.channel = channelRestriction.enumValue;
            channelRestrictionToSend.enumValue = channelRestriction.enumValue;
            serviceToSend.channelRestrictions.push(channelRestrictionToSend);
        }

        for (const externalKey of service.serviceExternalKeys) {
            const externalKeysToSend: ServiceExternalKeyType = {} as ServiceExternalKeyType;
            externalKeysToSend.id = externalKey.id;
            externalKeysToSend.origin = externalKey.origin;
            externalKeysToSend.key = externalKey.key;

            serviceToSend.serviceExternalKeys.push(externalKeysToSend);
        }

        return serviceToSend;
    }

    getEmptyExternalKeyItem(): ExternalKeysValueType {
        return {
            id: '',
            origin: '',
            key: '',
            entityId: ''
        };
    }

    getInitValidTempleObject(): ValidServiceObjectType {
        return {
            isNameValid: false,
            isDurationValid: true,
            isAreaValid: false,
            isSpecialityValid: false,
            isMaxSubServicesNumberValid: true,
            areCentersValid: false,
            areCoveragePlansValid: false
        };
    }

    objectAddedOnEditAction(initialService, selectedItem, refObjectName): boolean {
        const listName = lodash.lowerFirst(refObjectName);
        const foundItem = lodash.find(initialService[listName], {id: selectedItem.id});

        return !!foundItem;
    }

    getDurationValidation(duration: number): boolean {
        return !(duration === null || duration < 0);
    }

    getIsMaxSubServicesNumberValid(serviceItem: BackofficeServiceMDType): boolean {
        return (serviceItem.minSubServices >= 0 && serviceItem.maxSubServices >= 1 &&
            serviceItem.maxSubServices >= serviceItem.minSubServices) || serviceItem.maxSubServices === null;
    }

    getServiceMultiSelectTableOptions(
        service: BackofficeServiceMDType, screenTemplateLayoutAction: string
    ): ServiceMultiSelectTableOptionsType {
        const isPatientAccessSubServicesEnabled = this.configData.isFeatureActive('patient-access-subservices');
        const patientAccessSubServicesColumns = !isPatientAccessSubServicesEnabled ? [] : [{
            type: MultiSelectTableColumnKindEnum.DISPLAY,
            displayUpperHeader: false,
            headerLabel: 'label.enabledOnPatientAccess',
            itemPropertyNameInTableRow: 'enabledOnPatientAccess',
            useYesNoPipe: true,
        } as MultiSelectTableColumnType, {
            type: MultiSelectTableColumnKindEnum.DISPLAY,
            displayUpperHeader: false,
            headerLabel: 'label.favoriteForPatientAccess',
            itemPropertyNameInTableRow: 'favorite',
            useYesNoPipe: true,
        } as MultiSelectTableColumnType];
        return {
            centers: {
                provider: this.centerProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.serviceProvider,
                confirmDeleteParentId: service.id,
                confirmDeleteRefName: 'Centers',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems( ['Id', 'Name'], null, null, ['Name asc']),
                initialSelectedItems: service.centers,
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.center',
                    displayUpperHeader: true,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.centerProvider,
                    buttonName: 'label.center',
                    dependentFilters: this.getLocationDependentFilters(),
                    propertyName: 'center',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType,
            coveragePlans: {
                provider: this.coveragePlanProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.serviceProvider,
                confirmDeleteParentId: service.id,
                confirmDeleteRefName: 'CoveragePlans',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems( ['Id', 'Name'], null, null, ['Name asc']),
                initialSelectedItems: service.coveragePlans,
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.coveragePlan',
                    displayUpperHeader: true,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.coveragePlanProvider,
                    buttonName: 'label.coveragePlan',
                    dependentFilters: this.getCoveragePlanDependentFilters(),
                    propertyName: 'coveragePlan',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType,
            subServices: {
                provider: this.subServiceProvider as any,
                providerMethod: 'getEntries',
                confirmDeleteParentId: service.id,
                confirmDeleteRefName: 'SubServices',
                confirmDeleteProvider: this.serviceProvider,
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(['Id', 'Name', 'EnabledOnPatientAccess', 'Favorite', 'DefaultDuration'], null, null, ['Name asc']),
                initialSelectedItems: service.subServices,
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    displayUpperHeader: true,
                    headerLabel: 'label.subService',
                    itemPropertyNameInTableRow: 'name',
                    provider: this.subServiceProvider as any,
                    buttonName: 'label.subService',
                    dependentFilters: this.getSubServiceDependentFilters(),
                    propertyName: 'subService',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                } as MultiSelectTableColumnType, {
                    type: MultiSelectTableColumnKindEnum.DISPLAY,
                    displayUpperHeader: false,
                    headerLabel: 'label.duration',
                    itemPropertyNameInTableRow: 'defaultDuration',
                    useYesNoPipe: false,
                } as MultiSelectTableColumnType,
                    ...patientAccessSubServicesColumns
                ],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType,
        } as ServiceMultiSelectTableOptionsType;
    }

    getInitialModalFilters(): ServiceModalFilterOptionsType {
        return {
            multiResourceBluePrintOptions: {
                ngModel: false
            },
            multiResourceBluePrintFilterOptions: {
                ngModel: undefined as MultiResourceBluePrintFilterOptionsType
            },
            areFiltersActive: false
        } as ServiceModalFilterOptionsType;
    }


    isAnyFilterActive(modalFilters: ServiceModalFilterOptionsType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different than the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    isSearchByName(searchByObject: {name: ServiceMDSearchByEnum, value: string | number}): searchByObject is ServiceMDSearchByNameType {
        return searchByObject.name === ServiceMDSearchByEnum.Name && typeof searchByObject.value === 'string';
    }

    isSearchByShortId(searchByObject: {name: ServiceMDSearchByEnum, value: string | number}): searchByObject is ServiceMDSearchByShortIdType {
        return searchByObject.name === ServiceMDSearchByEnum.ShortId && typeof searchByObject.value === 'number';
    }
}
