import {Component, OnInit} from '@angular/core';
import {
    ActionNameEnum,
    AppointmentActivityStatusEnum,
    AppointmentActivityType,
    AppointmentProvider, AppointmentStatusEnum, AppointmentStatusValueEnum, AppointmentType,
    OptionalResourceStatusEnum,
    PatientProvider,
    ResourceDependentFiltersType,
    ResourceProvider,
    ResourceSearchType,
    RUAppointmentsType,
    RUResourceAppointmentsType,
} from 'sked-base';
import {Router} from '@angular/router';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {forkJoin} from 'rxjs';
import {ResourceUtilizationUtils} from '../resource-utilization.utils';
import * as lodash from 'lodash';
import {take} from 'rxjs/operators';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {CancelAppointmentOptionsType} from 'sked-base/lib/components/cancel-appointment/cancel-appointment.type';
import {AppointmentStatusChangeButtonOptionsType} from 'sked-base/lib/components/appointment-status-change-button/appointment-status-change-button.type';

@Component({
    selector: 'app-resource-utilization-manage-appointment-page',
    templateUrl: './resource-utilization-manage-appointment.component.html',
    styleUrls: ['./resource-utilization-manage-appointment.component.scss']
})
export class ResourceUtilizationManageAppointmentComponent implements OnInit {
    initialResourceUtilizationAppointment: RUAppointmentsType;
    resourceUtilizationAppointment: RUAppointmentsType;
    appointmentActivityStatusEnum = AppointmentActivityStatusEnum;
    optionalResourceStatusEnum = OptionalResourceStatusEnum;
    actionNameEnum = ActionNameEnum;
    newActivityName: string;
    optionalResourcesDependentFilter: ResourceDependentFiltersType;
    optionalResourceAdditionalExclusionList: string[] = [];
    patientCoveragePlansSpinnerId = 'patientCoveragePlansSpinnerId';
    appointmentCommentsSpinnerId = 'appointmentCommentsSpinnerId';
    displayManageAppointment = true;
    optionalResourceManagementActivity = false;
    cancelAppointmentOptions: CancelAppointmentOptionsType;
    appointmentStatusChangeOptions: AppointmentStatusChangeButtonOptionsType;

    constructor(private router: Router,
                private patientProvider: PatientProvider,
                private appointmentProvider: AppointmentProvider,
                private messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService,
                private resourceUtilizationUtils: ResourceUtilizationUtils,
                public resourceProvider: ResourceProvider,
                private configDataService: ConfigDataService) {
    }

    ngOnInit(): void {
        if (!history?.state?.resourceUtilizationAppointment) {
            this.displayManageAppointment = false;
            this.goToParentPage(ActionNameEnum.Back);
        } else {
            this.loadInitialData(history.state.resourceUtilizationAppointment);
        }
    }

    onAppointmentStatusChange(appointment: AppointmentType) {
        this.resourceUtilizationAppointment.eTag = appointment.etag;
        this.resourceUtilizationAppointment.status = AppointmentStatusValueEnum[appointment.status];
        this.initialResourceUtilizationAppointment = lodash.cloneDeep(this.resourceUtilizationAppointment);
        this.loadOptionsForAppointmentStatusChangeComponent();
        this.resourceUtilizationUtils.refreshDataButKeepCalendarDay.next();
    }

    onAppointmentCancelled(): void {
        this.goToParentPage(this.actionNameEnum.CancelAppointment);
    }

    onReschedule(): void {
        // If no advanced booking show a toaster error
        if (!this.initialResourceUtilizationAppointment?.advancedAppointmentBooking) {
            this.messagesService.error('label.forNowYouCanOnlyRescheduleAppointmentsMadeFromGraphicalResourcePlanner', true);
            return;
        }
        this.router.navigate(['/graphicalResourcePlanner'], {
            state: {rescheduleAppointment: this.initialResourceUtilizationAppointment}
        });
    }

    goToParentPage(action: ActionNameEnum) {
        this.router.navigate(['/resourceUtilization'], {
            state: this.resourceUtilizationUtils.getStateOptionsBasedOnAction(action)
        });
    }

    addActivity(newActivityName: string): void {
        if (!newActivityName) {
            return;
        }
        if (lodash.find(this.resourceUtilizationAppointment.activities, (activity: AppointmentActivityType) =>
            activity.name === newActivityName.trim())) {
            this.messagesService.error('toastr.error.activityAlreadyExists', true);
            return;
        }

        this.resourceUtilizationAppointment.activities.push({
            name: newActivityName,
            status: AppointmentActivityStatusEnum.Todo
        } as AppointmentActivityType);
        this.clearNewActivityInput();
    }

    deleteActivityFromList(deletedActivityName: string): void {
        lodash.remove(this.resourceUtilizationAppointment.activities, (activity: AppointmentActivityType) => activity.name === deletedActivityName);
    }

    saveManageAppointmentData(appointment: RUAppointmentsType) {
        appointment.optionalResources?.forEach(app => appointment.resourceAppointments.push(app));
        this.ngxLoader.start();
        this.appointmentProvider.updateActivitiesAndResources(this.initialResourceUtilizationAppointment, appointment)
            .pipe(take(1))
            .subscribe((res) => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.changesWereSavedWithSuccess', true);
                this.goToParentPage(this.actionNameEnum.Save);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    changeActivityStatus(activity: AppointmentActivityType): void {
        const {Todo, Done} = AppointmentActivityStatusEnum;
        activity.status = activity.status === Todo ? Done : Todo;
    }

    changeResourceStatus(resource: RUResourceAppointmentsType): void {
        const {Accepted, Rejected} = OptionalResourceStatusEnum;
        resource.optionalResourceStatus = resource.optionalResourceStatus === Accepted ? Rejected : Accepted;
    }

    deleteResourceFromList(deletedResource: RUResourceAppointmentsType): void {
        lodash.remove(this.resourceUtilizationAppointment.optionalResources, (resource: RUResourceAppointmentsType) =>
            resource.name === deletedResource.name);
        lodash.remove(this.resourceUtilizationAppointment.resourceAppointments, (resource: RUResourceAppointmentsType) =>
            resource.name === deletedResource.name);
        // we need to use cloneDeep to update the resources list from the sbase-filter
        this.resourceUtilizationAppointment.optionalResources = lodash.cloneDeep(this.resourceUtilizationAppointment.optionalResources);
    }

    addOptionalResource(resources: ResourceSearchType[]): void {
        const [resource] = lodash.differenceBy(resources, this.resourceUtilizationAppointment.optionalResources, 'name');
        this.resourceUtilizationAppointment.optionalResources.push({
            name: resource.name,
            optional: true,
            mainResource: false,
            optionalResourceStatus: OptionalResourceStatusEnum.Pending,
            resourceId: resource.id
        } as RUResourceAppointmentsType);
    }

    clearNewActivityInput(): void {
        this.newActivityName = '';
    }

    private loadInitialData(resourceUtilizationAppointment: RUAppointmentsType) {
        this.optionalResourceManagementActivity =
            this.configDataService.activeActivities.indexOf('OptionalResourceManagement') !== -1;
        this.resourceUtilizationAppointment = resourceUtilizationAppointment;
        this.initialResourceUtilizationAppointment = lodash.cloneDeep(resourceUtilizationAppointment);
        this.optionalResourceAdditionalExclusionList =
            this.resourceUtilizationUtils.getOptionalResourcesExclusionList(this.resourceUtilizationAppointment.resourceAppointments);
        this.optionalResourcesDependentFilter = this.resourceUtilizationUtils
            .getOptionalResourcesDependentFilters(this.resourceUtilizationAppointment.resourceAppointments);
        this.fetchAppointmentCoveragePlansAndComments(this.resourceUtilizationAppointment.patient.id,
            this.resourceUtilizationAppointment.appointmentId);
        this.loadOptionsForCancelAppointmentComponent();
        this.loadOptionsForAppointmentStatusChangeComponent();
    }

    private fetchAppointmentCoveragePlansAndComments(patientId: string, appointmentId: string): void {
        this.ngxLoader.startLoader(this.patientCoveragePlansSpinnerId);
        this.ngxLoader.startLoader(this.appointmentCommentsSpinnerId);
        forkJoin([this.patientProvider.getById(patientId, this.resourceUtilizationUtils.getPatientCoveragePlansQueryFilter()),
            this.appointmentProvider.getComments(appointmentId)])
            .subscribe(([patient, appointmentComments]) => {
                    this.resourceUtilizationAppointment.patientCoveragePlans = patient.coveragePlans ? patient.coveragePlans : [];
                    this.resourceUtilizationAppointment.comments = appointmentComments.value ? appointmentComments.value : [];
                    this.ngxLoader.stopLoader(this.patientCoveragePlansSpinnerId);
                    this.ngxLoader.stopLoader(this.appointmentCommentsSpinnerId);
                },
                (error) => {
                    this.messagesService.handlingErrorMessage(error);
                    this.ngxLoader.stopLoader(this.patientCoveragePlansSpinnerId);
                    this.ngxLoader.stopLoader(this.appointmentCommentsSpinnerId);
                });
    }

    private loadOptionsForAppointmentStatusChangeComponent(): void {
        this.appointmentStatusChangeOptions = {
            appointmentId: this.resourceUtilizationAppointment.appointmentId,
            status: AppointmentStatusEnum[this.resourceUtilizationAppointment.status],
            etag: this.resourceUtilizationAppointment.eTag,
            messagesServiceInstance: this.messagesService
        };
    }

    private loadOptionsForCancelAppointmentComponent(): void {
        this.cancelAppointmentOptions = {
            appointmentId: this.initialResourceUtilizationAppointment.appointmentId,
            messagesServiceInstance: this.messagesService
        } as CancelAppointmentOptionsType;
    }
}
