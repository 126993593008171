import {ValidatorFn} from '@angular/forms';
import {IDatePickerConfig} from 'ng2-date-picker';

export enum DynamicFormInputTemplateEnum {
    Text = 'Text',
    Number = 'Number',
    Date = 'Date',
    PhoneNumber = 'PhoneNumber',
    PhoneNumberCountryCode = 'PhoneNumberCountryCode',
    Select = 'Select',
    Email = 'Email',
    Checkbox = 'Checkbox',
    Textarea = 'Textarea'
}

export interface DynamicSelectInputDataType {
    value: any;
    text: string;
    shouldTranslate?: boolean;
}

export interface DynamicFormOptionsType {
    containerStyles?: DynamicFormContainerStylesType;
    groups: DynamicFormGroupedInputType[];
}

export interface DynamicFormGroupedInputType {
    containerStyles?: DynamicFormContainerStylesType;
    inputs: DynamicFormInputType[];
}

export interface DynamicFormInputType {
    formControlName: string;
    template?: DynamicFormInputTemplateEnum;
    customClass?: string;
    styles?: DynamicFormInputStylesType;
    placeholder?: string;
    labelText?: string;
    selectInputData?: DynamicSelectInputDataType[];
    required?: boolean;
    visible?: boolean;
    enabled?: boolean;
    customValidators?: ValidatorFn[];
    datePickerConfig?: IDatePickerConfig;
}

export interface DynamicFormContainerStylesType {
    numberOfColumns?: number;
    columnsGap?: string;
    numberOfRows?: number;
    rowsGap?: string;
    customCSS?: DynamicFormInputStylesType;
    customClass?: string;
}

export interface DynamicFormInputStylesType {
    gridColumn?: string;
    gridRow?: string;
    height?: string;
    display?: string;
    gridTemplateColumns?: string;
    gridTemplateRows?: string;
}
