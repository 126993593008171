import { Injectable } from '@angular/core';
import {
    AreaDependentFiltersType,
    ChannelDependentFiltersType,
    Expand, IncludeExcludeEnum, LocationDependentFiltersType, MaxAppointmentRuleType,
    ODataQueryObjectType, ResourceDependentFiltersType, SpecialityDependentFiltersType,
} from 'sked-base';
import * as lodash from 'lodash';
import {
    AreaModalFilterOptionsType,
    CenterModalFilterOptionsType,
    ChannelModalFilterOptionsType,
    MaxAppointmentRuleModalFiltersType,
    MaxAppointmentRuleStateType, MaxAppointmentRuleTagsType,
    MaxAppointmentsModalFilterOptionsType,
    MessageModalFilterOptionsType, ResourceModalFilterOptionsType, RuleScopeModalFilterOptionsType,
    SpecialityModalFilterOptionsType,
    TagModalFilterOptionsType,
} from './max-appointment-rule.types';
import { GeneralUtils } from '../../../../shared/utils/general.utils';
import { ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType } from '../../../../data-model/general.type';
import { RulesUtils } from '../../rules.utils';
import { RulesTableHeaderOptions } from '../../rules.types';
import {TranslatedLanguageService} from '../../../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class MaxAppointmentRuleUtils {
    overviewState: MaxAppointmentRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
        private rulesUtils: RulesUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'maxAppointmentRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.channel',
            hasSort: true,
            onSortByProperty: 'channel',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.ruleScope',
            hasSort: true,
            onSortByProperty: 'ruleScope',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resource',
            hasSort: true,
            onSortByProperty: 'resource',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.center2',
            hasSort: true,
            onSortByProperty: 'center',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.area2',
            hasSort: true,
            onSortByProperty: 'area',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.speciality2',
            hasSort: true,
            onSortByProperty: 'speciality',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.serviceTag',
            hasSort: true,
            onSortByProperty: 'tag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.patientTag',
            hasSort: true,
            onSortByProperty: 'patientTag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.maxAppointments',
            abbreviatedLabel: 'label.maxAppointmentsAbbreviation',
            hasSort: true,
            onSortByProperty: 'maxAppointments',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.message',
            hasSort: true,
            onSortByProperty: 'message',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && (filter[item] || filter[item] === 0)) {
                switch (item) {
                    case 'maxAppointmentRuleTableId':
                    case 'resourceId':
                    case 'centerId':
                    case 'areaId':
                    case 'specialityId':
                    case 'tagId':
                    case 'patientTagId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'channel':
                    case 'ruleScope': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    case 'maxAppointments': {
                        filterQuery[lodash.upperFirst(item)] = {eq: Number(filter[item])};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'maxAppointmentRuleTable' || item === 'area' || item === 'tag'
                    || item === 'speciality' || item === 'patientTag' || item === 'center' || item === 'resource'
                ) {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            Tag: {select: EXPAND_TAG_SELECT},
            PatientTag: {select: EXPAND_TAG_SELECT},
            MaxAppointmentRuleTable: {select: ['Id', 'Name']},
            Resource: {select: ['Id', 'Name']},
            Center: {select: ['Id', 'Name']},
            Area: {select: ['Id', 'Name']},
            Speciality: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): MaxAppointmentRuleType {
        return {
            name: undefined,
            active: false,
            action: false,
            area: undefined,
            areaId: undefined,
            resourceId: undefined,
            resource: undefined,
            center: undefined,
            centerId: undefined,
            channel: undefined,
            ruleScope: undefined,
            maxAppointmentRuleTable: undefined,
            maxAppointmentRuleTableId: undefined,
            maxAppointments: 0,
            message: '',
            onlyWarning: false,
            speciality: undefined,
            specialityId: undefined,
            tag: undefined,
            tagId: undefined,
            includePatientTag: undefined,
            patientTag: undefined,
            patientTagId: undefined,
        } as MaxAppointmentRuleType;
    }

    getInitialModalFilters(): MaxAppointmentRuleModalFiltersType {
        return {
            channelModalFilterOptions: {
                ngModel: undefined,
            } as ChannelModalFilterOptionsType,
            resourceModalFilterOptions: {
                ngModel: undefined,
            } as ResourceModalFilterOptionsType,
            centerModalFilterOptions: {
                ngModel: undefined,
            } as CenterModalFilterOptionsType,
            areaModalFilterOptions: {
                ngModel: undefined,
            } as AreaModalFilterOptionsType,
            specialityModalFilterOptions: {
                ngModel: undefined,
            } as SpecialityModalFilterOptionsType,
            maxAppointmentsModalFilterOptions: {
                ngModel: null,
            } as MaxAppointmentsModalFilterOptionsType,
            messageModalFilterOptions: {
                ngModel: '',
            } as MessageModalFilterOptionsType,
            patientTagOptions: {
                ngModel: undefined,
            } as TagModalFilterOptionsType,
            serviceTagOptions: {
                ngModel: undefined,
            } as TagModalFilterOptionsType,
            ruleScopeModalFilterOptions: {
                ngModel: undefined,
            } as RuleScopeModalFilterOptionsType,
            areFiltersActive: false,
        } as MaxAppointmentRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: MaxAppointmentRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: MaxAppointmentRuleType): MaxAppointmentRuleType {
        const rule = lodash.cloneDeep(ruleToMap);
        return rule;
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getInitialMaxAppointmentRuleTags(): MaxAppointmentRuleTagsType {
        return {
            selectedServiceTags: [],
            selectedPatientTags: [],
            includePatientTagFlag: true
        } as MaxAppointmentRuleTagsType;
    }

    getSpecialityDependentFilters(): SpecialityDependentFiltersType {
        return {
            searchPhrase: '',
            areaId: null,
            count: true,
            exclusionList: []
        };
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    })

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }
}
