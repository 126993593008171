<div class="resource-utilization-calendar-container">
    <div class="resource-utilization-calendar">
        <ngx-ui-loader class="local-spinner" [hasProgressBar]="false"
                       [loaderId]="resourceUtilizationCalendarUtil.calendarSpinnerId"></ngx-ui-loader>
        <!-- -------------------- -->
        <!-- Small calendar (positioned with css in fullcalendar header - right) -->
        <!-- -------------------- -->
        <div class="row d-flex" *ngIf="!!resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel">
            <div class="col text-right resource-utilization-small-header-calendar-container">
                <!-- Small calendar button -->
                <button #buttonEl
                        class="btn btn-calendar resource-utilization-small-header-calendar-button"
                        [ngClass]="{'d-none' : false}"
                        (click)="date.toggle()">
                    <i class="fas fa-calendar-day"></i>
                </button>
                <!-- Actual small calendar (date picker) -->
                <input class="resource-utilization-small-header-calendar-datepicker"
                       [(ngModel)]="resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel"
                       [hidden]="true" type="text" placement="bottom"
                       [positionTarget]="buttonEl"
                       [dayTemplate]="customDay"
                       [placement]="'left'"
                       [minDate]="resourceUtilizationUtils.calendarOptions.calendarRanges.smallCalendarMinDate"
                       [maxDate]="resourceUtilizationUtils.calendarOptions.calendarRanges.smallCalendarMaxDate"
                       ngbDatepicker
                       #date="ngbDatepicker"
                       (dateSelect)="onSmallHeaderCalendarDateSelect($event)"/>
                <!-- Day template -->
                <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                             let-disabled="disabled" let-focused="focused">
                    <div class="custom-date-day btn-light"
                         [class.selected]="selected"
                         [class.hidden]="date.month !== currentMonth"
                         [class.focused]="focused"
                         [class.text-muted]="date.month !== currentMonth"
                         (click)="onSmallHeaderCalendarDateSelect(date)">
                        {{ date.day }}
                        <span [ngClass]="{'day-with-slots': resourceUtilizationCalendarUtil.isDayWithSlots(date)}"></span>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- -------------------- -->
        <!-- Full calendar title (positioned with css in fullcalendar header - center) -->
        <!-- -------------------- -->
        <div class="row d-flex" *ngIf="!!resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel">
            <div class="col text-center d-flex justify-content-center">
                <div class="resource-utilization-fullcalendar-title-container" [ngbTooltip]="CenterTimezoneIsDifferentFromBrowserTimezone"
                     container="body" [disableTooltip]="resourceUtilizationUtils.areAppointmentsInSameTimezoneAsBrowser"
                     [ngClass]="resourceUtilizationUtils.areAppointmentsInSameTimezoneAsBrowser ? 'timezone-normal':'timezone-warning'">
                    <div class="resource-utilization-fullcalendar-title-top-part">
                        {{resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel | dateMMDDcommaYYYYFormatPipe}}
                        <i class="fas fa-exclamation-circle" *ngIf="!resourceUtilizationUtils.areAppointmentsInSameTimezoneAsBrowser"></i>
                        <ng-template #CenterTimezoneIsDifferentFromBrowserTimezone>
                            {{'label.centerTimezoneIsDifferentFromCurrent' | translate}}
                        </ng-template>
                    </div>
                    <div class="resource-utilization-fullcalendar-title-bot-part">
                        {{resourceUtilizationUtils.calendarOptions.smallHeaderCalendarNgModel | dateddddFormatPipe | uppercase }}
                    </div>
                </div>
            </div>
        </div>

        <!-- -------------------- -->
        <!-- Full calendar info message (positioned with css in fullcalendar header - left) -->
        <!-- -------------------- -->
        <div class="row d-flex" *ngIf="!!resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions">
            <div class="col text-left d-flex justify-content-start">
                <div class="resource-utilization-fullcalendar-info-message-container">
                    <i class="fas fa-info-circle mr-2"></i>
                    <span translate="label.clickOnTheSlotForAppointmentInfo"></span>
                </div>
            </div>
        </div>

        <!-- -------------------- -->
        <!-- Fullcalendar -->
        <!-- -------------------- -->
        <full-calendar *ngIf="!!resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions"
                       class="resource-utilization-calendar"
                       #resourceUtilizationCalendar
                       [options]="resourceUtilizationUtils.calendarOptions.resourceUtilizationCalendarOptions"
        ></full-calendar>
    </div>


    <!-- -------------------- -->
    <!-- Tippy popover templates -->
    <!-- -------------------- -->

    <!-- -------------------- -->
    <!-- Legend -->
    <!-- -------------------- -->
    <div class="resource-utilization-events-legend"
         id="resource-utilization-events-legend-template"
         [ngClass]="{'d-none': !resourceUtilizationUtils.calendarOptions.legendHeaderTippyInstance}">
        <!-- Title -->
        <h3 class="font-weight-bold mb-0" translate="reception.resourceSlotsOverview.legend"></h3>

        <!-- Colors -->
        <p class="font-weight-bold mb-0" translate="reception.resourceSlotsOverview.colors"></p>
        <ul class="list-group">
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-1 color-bullet custom-event-type-Normal"></span>
                <span translate="reception.resourceSlotsOverview.appointment"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-1 color-bullet custom-event-type-Blocked"></span>
                <span translate="reception.resourceSlotsOverview.blockedAppointment"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-1 color-bullet custom-event-type-Overcapacity"></span>
                <span translate="reception.resourceSlotsOverview.overcapacity"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-1 color-bullet custom-event-type-OutsideBookingHours"></span>
                <span translate="label.outsideBookingHoursAppointment"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-1 color-bullet custom-event-type-Pending"></span>
                <span translate="label.pendingAppointment"></span>
            </li>
        </ul>

        <!-- Icons -->
        <p class="font-weight-bold mb-0 mt-2" translate="reception.resourceSlotsOverview.icons"></p>
        <ul class="list-group">
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="fas fa-credit-card"></i></span>
                <span translate="label.paid"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="fas fa-desktop"></i></span>
                <span translate="label.onlineConsultation"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="far fa-calendar-check"></i></span>
                <span translate="reception.appointmentStatus.confirmed"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="fas fa-check"></i></span>
                <span translate="reception.appointmentStatus.checkedIn"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="far fa-check-circle"></i></span>
                <span translate="reception.appointmentStatus.servicePerformed"></span>
            </li>
            <li class="list-group-item px-0 pb-0 d-flex align-items-center">
                <span class="mb-0 mr-1"><i class="fas fa-exclamation"></i></span>
                <span translate="label.pendingAppointment"></span>
            </li>
        </ul>
    </div>

    <!-- -------------------- -->
    <!-- Options -->
    <!-- -------------------- -->
    <div class="resource-utilization-fullcalendar-header-options"
         id="resource-utilization-fullcalendar-header-options-template"
         [ngClass]="{'d-none': !resourceUtilizationUtils.calendarOptions.optionsHeaderTippyInstance}">
        <div class="resource-utilization-header-options-container">
            <div class="row">
                <label class="col-5 options-label" translate="label.viewSize"></label>
                <div class="col-7">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="resourceUtilizationUtils.calendarOptions.calendarGranularityNgModel"
                                    (ngModelChange)="onCalendarGranularityChanged()"
                                    disableOptionCentering>
                            <mat-option *ngFor="let item of calendarGranularityList"
                                        [value]='item.value'>{{'label.' + item.title | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

