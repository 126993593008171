<div class="container-fluid create-exclusion-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="row pl-4">
        <div class="col-4 p-0">
            <div class="create-exclusion-header">
                <h3 class="create-exclusion-title">{{screenTemplateLayout.pageTitle|translate}}
                    <span class="text-lowercase" translate="label.exclusion"></span>
                </h3>
            </div>
        </div>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!-- GENERAL-->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== VIEW"
                          [stepControl]="getStepControl(0)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-5">
                                <!--shortId-->
                                <div class="form-group" *ngIf="screenTemplateLayout.action !== CREATE">
                                    <label translate="label.shortId"></label>
                                    <input type="text"
                                           name="shortId"
                                           class="input-element"
                                           [(ngModel)]="exclusionData.shortId"
                                           [disabled]=true>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <!--resource-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]='"label.resource"'
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [initialFilterValues]="[exclusionData?.resource]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': hasResourceError(exclusionData)}"
                                                  [providerInstance]="resourceProvider">
                                    </sbase-filter>
                                    <div *ngIf="!exclusionData?.resource?.id && !exclusionData?.center?.id && isExclusionCenterActivityActive"
                                         class="help-block">
                                        <span translate="label.error.resourceOrCenterRequired"></span>
                                    </div>

                                    <div *ngIf="!exclusionData?.resource?.id && !isExclusionCenterActivityActive"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <!--time zone-->
                                <div class="form-group"
                                     *ngIf="exclusionData?.resourceId">
                                    <label translate="label.centerTab.timeZone"></label>
                                    <ng-select
                                            loadingText="{{'label.loading' | translate}}"
                                            dropdownPosition="bottom"
                                            class="custom-ng-select input-element"
                                            [(ngModel)]="exclusionData.timeZoneId"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [ngClass]="{'input-element-has-error': !isTimeZoneValid(exclusionData)}"
                                            [ngModelOptions]="{standalone: true}">
                                        <ng-option value="undefined"><span translate="label.choose"></span></ng-option>
                                        <ng-option *ngFor="let timeZone of timeZones"
                                                   [value]="timeZone.timeZoneId">
                                            {{timeZone.timeZoneId}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!isTimeZoneValid(exclusionData)"
                                         class="help-block">
                                         <span translate="label.error.timeZoneRequiredIfNoCenter"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <!--center-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                                  [buttonName]='"label.center2"'
                                                  [dependentFilters]="mainDependentFilters.location"
                                                  [initialFilterValues]="[exclusionData?.center]"
                                                  [isMultipleSelectedItem]="false"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="centerProvider"
                                                  [ngClass]="{'input-element-has-error': hasCenterError(exclusionData)}"
                                                  [useSelectedValueAsLabel]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                    <div *ngIf="!exclusionData?.resource?.id && !exclusionData?.center?.id && isExclusionCenterActivityActive"
                                         class="help-block">
                                        <span translate="label.error.resourceOrCenterRequired"></span>
                                    </div>
                                    <div *ngIf="!exclusionData?.center?.id && exclusionData?.resource?.isRoom && isExclusionCenterActivityActive"
                                         class="help-block">
                                        <span translate="label.error.centerRequiredForResourceRoom"></span>
                                    </div>
                                    <div *ngIf="!exclusionData?.center?.id && !isExclusionCenterActivityActive"
                                         class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">
                                <!-- Exclusion reason-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.exclusionReason"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedExclusionReason($event)"
                                                  [buttonName]="'label.exclusionReason'"
                                                  [dependentFilters]="mainDependentFilters.exclusionReason"
                                                  [initialFilterValues]="[exclusionData?.exclusionReasonData]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [providerInstance]="exclusionReasonProvider"
                                                  [ngClass]="{'input-element-has-error': (!exclusionData?.exclusionReasonData?.id || !exclusionData?.exclusionReasonData.active || exclusionData?.exclusionReasonData.cancelled)}"
                                                  [customTemplate]="exclusionReasonTemplate">
                                    </sbase-filter>
                                    <ng-template let-item #exclusionReasonTemplate>
                                        {{item.description}}
                                    </ng-template>
                                    <div *ngIf="!exclusionData?.exclusionReasonData?.id || !exclusionData?.exclusionReasonData.active || exclusionData?.exclusionReasonData.cancelled"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !exclusionData?.exclusionReasonData?.id}"
                                              *ngIf="!exclusionData?.exclusionReasonData?.id"
                                              translate="label.error.required">
                                        </span>
                                        <span *ngIf="exclusionData?.exclusionReasonData?.id"
                                              [ngClass]="{'has-error': exclusionData?.exclusionReasonData?.id && (!exclusionData?.exclusionReasonData.active || exclusionData?.exclusionReasonData.cancelled)}"
                                              translate="label.error.exclusionReasonNotAvailable">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="form-group"
                                     *ngIf="exclusionData?.exclusionReasonData?.showExtraInformation"
                                     [ngClass]="{'has-error': !exclusionData?.exclusionExtraInformation && exclusionData?.exclusionReasonData?.extraInformationMandatory}">
                                    <label translate="label.extraInformation"></label>
                                    <input type="text" name="name" class="form-control rounded-0 pl-0"
                                           autocomplete="off"
                                           [(ngModel)]="exclusionData.exclusionExtraInformation"
                                           [ngClass]="{'has-error': !exclusionData?.exclusionExtraInformation && exclusionData?.exclusionReasonData?.extraInformationMandatory}"
                                           placeholder="{{'label.name'|translate}}">
                                    <div *ngIf="!exclusionData?.exclusionExtraInformation && exclusionData?.exclusionReasonData?.extraInformationMandatory"
                                         class="help-block">
                                          <span [ngClass]="{'has-error': !exclusionData?.exclusionExtraInformation && exclusionData?.exclusionReasonData?.extraInformationMandatory}"
                                                translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--TIME SLOTS-->
                <mat-step [completed]="false"
                          *ngIf="screenTemplateLayout.action !== VIEW"
                          [stepControl]="getStepControl(1)">
                    <ng-template matStepLabel><span translate="label.timeSlots"></span></ng-template>
                    <div class="step-form mt-3 time-slots">
                        <!-- Dropdown row (WholeDay, Partial or Range) -->
                        <div class="row mb-2">
                            <div class="col-5">
                                <div class="form-group create-exclusion-select-container">
                                    <label class="create-exclusion-select-label" translate="label.exclusionType"></label>
                                    <ng-select
                                            class="custom-ng-select"
                                            loadingText="{{'label.loading' | translate}}"
                                            autofocus
                                            dropdownPosition="bottom"
                                            [(ngModel)]="exclusionData.exclusionType"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false"
                                            (change)="onExclusionTypeChange()">
                                        <ng-option [value]="'WholeDay'">
                                            <span translate="label.wholeDay"></span>
                                        </ng-option>
                                        <ng-option [value]="'Partial'">
                                            <span translate="label.partial"></span>
                                        </ng-option>
                                        <ng-option [value]="'Range'">
                                            <span translate="label.range"></span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <!-- Date range input (used for Partial and WholeDay only) -->
                        <div class="row mb-2" *ngIf="exclusionData?.exclusionType === ExclusionTypeEnum.Partial || exclusionData?.exclusionType === ExclusionTypeEnum.WholeDay">
                            <div class="col-7">
                                <div class="form-group">
                                    <sbase-date-range class="input-element"
                                                      [options]="dateRangeOptions"
                                                      [updateDateRangeValue]="updateDateRange"
                                                      (onDateRangeChanged)="onDateRangeChanged($event)"
                                                      [ngClass]="{'input-element-has-error': (!exclusionData?.validFrom && !exclusionData?.validTo) ||
                                                               !areDatesValid(exclusionData?.validFrom, exclusionData?.validTo)
                                                               || !exclusionRangeValidation.isValid}"></sbase-date-range>
                                    <div *ngIf="!exclusionData?.validFrom && !exclusionData?.validTo"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !exclusionData?.validFrom && !exclusionData?.validTo}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
<!--                                    This div was commented because we have another validation after this div-->
<!--                                    <div *ngIf="((exclusionData?.validFrom && exclusionData?.validTo) && !areDatesValid(exclusionData?.validFrom, exclusionData?.validTo))"-->
<!--                                         class="help-block">-->
<!--                                        <span [ngClass]="{'has-error': !areDatesValid(exclusionData?.validFrom, exclusionData?.validTo)}"-->
<!--                                              translate="toastr.error.dateNotInPast">-->
<!--                                        </span>-->
<!--                                    </div>-->
                                    <div *ngIf="!exclusionRangeValidation.isValid" class="help-block">
                                        <span class="input-element-has-error"
                                              [translate]="exclusionRangeValidation.errorMessage">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5" *ngIf="exclusionData?.exclusionType === ExclusionTypeEnum.Partial">
                                <div class="form-group create-exclusion-select-container">
                                    <label class="create-exclusion-select-label" translate="label.repetitionPeriod"></label>
                                    <ng-select
                                            class="custom-ng-select"
                                            loadingText="{{'label.loading' | translate}}"
                                            autofocus
                                            dropdownPosition="bottom"
                                            [(ngModel)]="exclusionData.repetition"
                                            [multiple]="false"
                                            [searchable]="false"
                                            [clearable]="false">
                                        <ng-option [value]="1">
                                            1 <span translate="label.week2"></span>
                                        </ng-option>
                                        <ng-option [value]="2">
                                            2 <span translate="label.weeks"></span>
                                        </ng-option>
                                        <ng-option [value]="3">
                                            3 <span translate="label.weeks"></span>
                                        </ng-option>
                                        <ng-option [value]="4">
                                            4 <span translate="label.weeks"></span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <!-- Time slot section (used for Partial only) -->
                        <div *ngIf="exclusionData?.exclusionType === ExclusionTypeEnum.Partial">
                            <div class="row mt-2"
                                 [ngClass]="{'mb-3':updateExclusionTimeSlots}">
                                <div class="col-12">
                                    <button (click)="openTimeSlotsSection(action, undefined)"
                                            type="button"
                                            class="btn theme-btn">
                                        <i class="fas fa-plus"></i>
                                        <span translate="button.addTimeSlot2" class="ml-2"></span>
                                    </button>
                                </div>
                            </div>

                            <!--Time slot section-->
                            <div class="row"
                                 *ngIf="updateExclusionTimeSlots">
                                <div class="card col-12">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-6 pt-3">
                                                    <div class="time-slot-time-picker-inputs-container">
                                                        <div class="time-slot-time-picker-input">
                                                            <div class="form-group mr-1">
                                                                <label translate="label.from" class="mr-1"></label>
                                                            </div>
                                                            <div class="time-picker-input-container" *ngIf="scopedTimeSlot.hourFromTime">
                                                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                                      [ngClass]="{'input-element-has-error': !isTimeSlotTimeValid}"
                                                                                      [format]="24"
                                                                                      [cancelBtnTmpl]="closeBtn"
                                                                                      [confirmBtnTmpl]="confirmBtn"
                                                                                      [defaultTime]="scopedTimeSlot.hourFromTime"
                                                                                      (timeChanged)="onTimeSlotHourFromChanged($event)">
                                                                </ngx-timepicker-field>
                                                            </div>
                                                        </div>
                                                        <div class="time-slot-time-picker-input">
                                                            <div class="form-group mx-1">
                                                                <label translate="label.to" class="mr-1"></label>
                                                            </div>
                                                            <div class="time-picker-input-container" *ngIf="scopedTimeSlot.hourToTime">
                                                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                                      [ngClass]="{'input-element-has-error': !isTimeSlotTimeValid}"
                                                                                      [format]="24"
                                                                                      [cancelBtnTmpl]="closeBtn"
                                                                                      [confirmBtnTmpl]="confirmBtn"
                                                                                      [defaultTime]="scopedTimeSlot.hourToTime"
                                                                                      (timeChanged)="onTimeSlotHourToChanged($event)">
                                                                </ngx-timepicker-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pt-3">
                                                    <div class="form-group create-exclusion-multiple-select-container">
                                                        <label translate="label.selectDays" class="create-exclusion-multiple-select-label"></label>
                                                        <div class="d-inline-block ml-1">
                                                            <mat-form-field appearance="fill">
                                                                <mat-select multiple
                                                                            disableOptionCentering
                                                                            [(ngModel)]="scopedTimeSlot.selectedDays">
                                                                    <mat-select-trigger>
                                                                        <span *ngFor="let day of scopedTimeSlot.selectedDays; last as isLast">
                                                                            {{"label.date." + day + "Med" | translate }}
                                                                            <span *ngIf="!isLast">,</span>
                                                                        </span>
                                                                    </mat-select-trigger>
                                                                    <mat-option *ngFor="let day of timeSlotDaysList"
                                                                                [value]="day">{{"label.date." + day | translate}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="d-flex float-right mb-3 mt-1 update-buttons"
                                 *ngIf="updateExclusionTimeSlots">
                                <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                                        translate="button.save"
                                        (click)="onSaveTimeSlot(scopedTimeSlot)">
                                </button>
                                <button class="btn border-0 p-0"
                                        translate="label.close"
                                        (click)="onCancelTimeSlot()">
                                </button>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group mb-0">
                                        <label class="pt-3" translate="label.timeSlots"></label>
                                    </div>
                                </div>
                            </div>
                            <!-- TimeSlots table-->
                            <table class="table table-bordered grey-table">
                                <thead class="thead-light">
                                <tr>
                                    <td translate="label.mon"></td>
                                    <td translate="label.tu"></td>
                                    <td translate="label.we"></td>
                                    <td translate="label.th"></td>
                                    <td translate="label.fr"></td>
                                    <td translate="label.sa"></td>
                                    <td translate="label.su"></td>
                                    <td translate="label.action"></td>
                                </tr>
                                </thead>
                                <!--display an empty row if no timeslot is added-->
                                <tbody *ngIf="!exclusionData?.timeSlots || exclusionData?.timeSlots?.length===0">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                                <tbody *ngFor="let timeSlot of exclusionData?.timeSlots; let index = index">
                                <tr>
                                    <td><span *ngIf="timeSlot.monday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.tuesday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.wednesday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.thursday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.friday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.saturday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td><span *ngIf="timeSlot.sunday">{{timeSlot.hourFrom | hourFromMinutes}}
                                        - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                    <td class="text-center">
                                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                                popoverClass="popover-shadow">
                                            <i class="fas fa-ellipsis-h"></i>
                                        </button>
                                        <ng-template #popContent>
                                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                                <li>
                                                    <button type="button" class="btn" translate="label.edit"
                                                            (click)="editExclusionTimeSlot(timeSlot, index)"></button>
                                                </li>
                                                <li>
                                                    <button type="button" class="btn" translate="label.delete"
                                                            (click)="deleteExclusionTimeSlot(timeSlot, index)"></button>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Date range with EndOfDay flag visible (used for Range only) -->
                        <div class="row mb-2" *ngIf="exclusionData?.exclusionType === ExclusionTypeEnum.Range">
                            <!-- Valid from -->
                            <div class="col-5 date-picker-col">
                                <label translate="label.validFrom"></label>
                                <div class="date-and-time-picker-inputs-container">
                                    <div class="date-picker-input-container">
                                        <input class="date-picker-input"
                                               [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                               name="dpfrom1"
                                               readonly
                                               [minDate]="dateRangeOptions.minDate"
                                               [(ngModel)]="exclusionDateAndTimeRange.dateFrom"
                                               (dateSelect)="onValidFromDateTimeChanged($event, exclusionDateAndTimeRange.timeFrom, true)"
                                               ngbDatepicker #datePickerFrom="ngbDatepicker"
                                               (click)="datePickerFrom.toggle()">
                                        <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                                [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                                (click)="datePickerFrom.toggle()"
                                                type="button"></button>
                                    </div>
                                    <div class="date-picker-input-container">
                                        <div class="time-picker-input-container" *ngIf="exclusionDateAndTimeRange.timeFrom">
                                            <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                  [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                                                  [format]="24"
                                                                  [cancelBtnTmpl]="closeBtn"
                                                                  [confirmBtnTmpl]="confirmBtn"
                                                                  #timePickerTimeFrom
                                                                  [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(exclusionDateAndTimeRange.timeFrom)"
                                                                  (timeChanged)="onValidFromDateTimeChanged(exclusionDateAndTimeRange.dateFrom, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                            </ngx-timepicker-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Valid to -->
                            <div class="col-5 date-picker-col">
                                <label translate="label.validTo"></label>
                                <div class="date-and-time-picker-inputs-container">
                                    <div class="date-picker-input-container">
                                        <input class="date-picker-input"
                                               [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                               name="dpto1"
                                               readonly
                                               [minDate]="dateRangeOptions.minDate"
                                               [(ngModel)]="exclusionDateAndTimeRange.dateTo"
                                               (dateSelect)="onValidToDateTimeChanged($event,exclusionDateAndTimeRange.timeTo)"
                                               ngbDatepicker #datePickerTo="ngbDatepicker"
                                               (click)="datePickerTo.toggle()">
                                        <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                                [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                                (click)="datePickerTo.toggle()"
                                                type="button"></button>
                                    </div>
                                    <div class="date-picker-input-container">
                                        <div class="time-picker-input-container" *ngIf="!exclusionData.endOfDay && exclusionDateAndTimeRange.timeTo">
                                            <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                  [ngClass]="{'input-element-has-error': !exclusionRangeValidation.isValid}"
                                                                  [format]="24"
                                                                  [cancelBtnTmpl]="closeBtn"
                                                                  [confirmBtnTmpl]="confirmBtn"
                                                                  [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(exclusionDateAndTimeRange.timeTo)"
                                                                  (timeChanged)="onValidToDateTimeChanged(exclusionDateAndTimeRange.dateTo, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                            </ngx-timepicker-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Endofday flag -->
                            <div class="col-2 end-of-day-col">
                                <div class="form-group">
                                    <label translate="label.endOfDay"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked name="endOfDay"
                                                   [(ngModel)]="exclusionData.endOfDay"
                                                   (ngModelChange)="onEndOfDayChange()">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- Error messages (used for Range only) -->
                        <div class="row mb-2" *ngIf="exclusionData?.exclusionType === ExclusionTypeEnum.Range">
                            <!-- Error messages -->
                            <div class="col-12">
                                <div class="form-group">
                                    <div *ngIf="!exclusionRangeValidation.isValid" class="help-block">
                                        <span class="has-error"
                                              [translate]="exclusionRangeValidation.errorMessage">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div>
                            <ngx-ui-loader [loaderId]="validateExclusionLoaderId"></ngx-ui-loader>
                            <div class="text-danger mb-2">{{exclusionValidation.error}}</div>
                            <div clas="text-warning"
                                 *ngFor="let warning of exclusionValidation.warnings">{{warning}}</div>
                        </div>
                        <app-view-exclusion [exclusion]="exclusionData"></app-view-exclusion>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="goBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="goForward(stepper)"
                    *ngIf="displayNext(stepper, totalStepsCount)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveExclusionData(exclusionData)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===VIEW && exclusionData.status === exclusionStatusEnum.APPROVED
                    && !exclusionUtils.isExclusionFromMSGraphIntegration(exclusionData) && !dateTimeUtils.isExpiredDate(exclusionData.validTo)"
                    [activityDisplay]="'ExclusionUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn" translate="label.close"></button>
    </div>
</ng-template>
