import {Calendar, CalendarOptions, CustomButtonInput} from '@fullcalendar/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Instance} from 'tippy.js';
import {CalendarSlotDurationEnum, RUAppointmentsType} from 'sked-base';

export interface ResourceUtilizationCalendarOptionsType {
    // The options and API for fullcalendar
    resourceUtilizationCalendarOptions: CalendarOptions;
    resourceUtilizationCalendarApi: Calendar;
    // Custom buttons, resources and events for fullcalendar
    customButtons: { [name: string]: CustomButtonInput };
    calendarResources: ResourceUtilizationCalendarResourceType[];
    calendarEvents: ResourceUtilizationCalendarEventType[];
    // Translated text that is updated in fullcalendar
    translatedText: { [key: string]: string };
    // Options needed for the small calendar that is situated in the header of fullcalendar
    smallHeaderCalendarNgModel: NgbDateStruct;
    smallHeaderCalendarIsDayWithSlots: { [key: string]: boolean };
    // Tippy instances of the legend and the options (granularity, ...) buttons that are situated in the header of fullcalendar
    legendHeaderTippyInstance: Instance;
    optionsHeaderTippyInstance: Instance;
    // NgModel for granularity that is situated in the options popover in the header of fullcalendar
    calendarGranularityNgModel: CalendarSlotDurationEnum;
    // Valid ranges for fullcalendar and small calendar
    calendarRanges: ResourceUtilizationCalendarRangesType;
    // Used to keep the current state of the calendar (selected day, horizontal and vertical scroll)
    lastSelectedAppointment: RUAppointmentsType;
}

export interface ResourceUtilizationCalendarRangesType {
    fullCalendarMinDate: string;
    fullCalendarMaxDate: string;
    smallCalendarMinDate: NgbDateStruct;
    smallCalendarMaxDate: NgbDateStruct;
}

export enum ResourceUtilizationCalendarEventTypesEnum {
    Normal = 'Normal',
    FreeSlot = 'FreeSlot',
    Group = 'Group',
    Blocked = 'Blocked',
    Overcapacity = 'Overcapacity',
    OutsideBookingHours = 'OutsideBookingHours',
    Exclusion = 'Exclusion',
    Pending = 'Pending',
}

export interface ResourceUtilizationCalendarResourceType {
    id: string;
    title: string;
}

export interface ResourceUtilizationCalendarEventType {
    resourceId: string;
    title: string;
    tippyContent: string;
    start: string;
    end: string;
    appointment: RUAppointmentsType;
    className: string;
    iconList: string[];
    customBackgroundColor: string;
    customFontColor: string;
}
