<div class="container-fluid create-resource-work-schedule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-resource-work-schedule-header">
        <h3 class="create-resource-work-schedule-title">{{screenTemplateLayout.pageTitle|translate}} <span
            class="text-lowercase" translate="label.resourceWorkSchedule"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->
    <form name="form" novalidate (ngSubmit)="saveResourceWorkScheduleData(resourceWorkScheduleItem)">
        <div class="create-entity-md-section">
            <!--Body-->
            <!-- =========================================== -->
            <div class="form-group" *ngIf="screenTemplateLayout.action === CONSTANTS.VIEW">
                <div class="col-12 pl-0">
                    <div class="form-group mb-0">
                        <label class="title pt-3" translate="label.generalInformation"></label>
                    </div>
                </div>
                <div class="form-group overview-table-container mt-2">
                    <table class="table table-bordered grey-table">
                        <thead class="thead-light">
                        <tr>
                            <td class="name-column text-center" translate="label.resource"></td>
                            <td class="name-column text-center" translate="label.center2"></td>
                            <td class="name-column text-center" translate="label.validFrom"></td>
                            <td class="name-column text-center" translate="label.validTo"></td>
                            <td class="name-column text-center" translate="label.repetitionPeriod"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{resourceWorkScheduleItem?.resource?.name}}</td>
                            <td>{{resourceWorkScheduleItem?.center?.name}}</td>
                            <td>{{resourceWorkScheduleItem?.validFrom | fullDateFormatPipe}}</td>
                            <td>{{resourceWorkScheduleItem?.validTo | fullDateFormatPipe}}</td>
                            <td *ngIf="resourceWorkScheduleItem?.repetition === 1">
                                {{resourceWorkScheduleItem?.repetition}} <span translate="label.week2"></span>
                            </td>
                            <td *ngIf="resourceWorkScheduleItem?.repetition > 1">
                                {{resourceWorkScheduleItem?.repetition}} <span translate="label.weeks"></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group sbase-input-element"
                             [ngClass]="{'has-error': !resourceWorkScheduleItem.resourceId}">
                            <label translate="label.resource"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedResourceFilter($event)"
                                          [buttonName]="'label.resource'"
                                          [dependentFilters]="mainDependentFilters.resource"
                                          [initialFilterValues]="initialFilterValues.resource"
                                          [isMultipleSelectedItem]="false"
                                          [useSelectedValueAsLabel]="true"
                                          [hideSelectedItems]="true"
                                          [providerInstance]="resourceProvider"
                                          [ngClass]="{'input-element-has-error': !resourceWorkScheduleItem.resourceId}">
                            </sbase-filter>
                            <div *ngIf="!resourceWorkScheduleItem.resourceId" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group sbase-input-element"
                             [ngClass]="{'has-error': !resourceWorkScheduleItem.centerId}">
                            <label translate="label.center2"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedCenterFilter($event)"
                                          [buttonName]="'label.center2'"
                                          [dependentFilters]="mainDependentFilters.center"
                                          [initialFilterValues]="initialFilterValues.center"
                                          [isMultipleSelectedItem]="false"
                                          [useSelectedValueAsLabel]="true"
                                          [hideSelectedItems]="true"
                                          [providerInstance]="centerProvider"
                                          [ngClass]="{ 'input-element-has-error': !resourceWorkScheduleItem.centerId}"
                                          class="sbase-dropdown-ml">
                            </sbase-filter>
                            <div *ngIf="!resourceWorkScheduleItem.centerId" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="resourceWorkScheduleItem.resourceId && resourceWorkScheduleItem.centerId">
                    <div class="col-md-6 col-12">
                        <div class="form-group mb-2"
                             [ngClass]="{'has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW}">
                            <label translate="label.validFrom"></label>
                        </div>
                        <div class="date-and-time-picker-inputs-container">
                            <div class="date-picker-input-container">
                                <input class="date-picker-input"
                                       [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                       name="dpfrom1"
                                       readonly
                                       [minDate]="dateRangeOptions.minDate"
                                       [(ngModel)]="availabilityDateAndTimeRange.dateFrom"
                                       (dateSelect)="onValidFromDateTimeChanged($event, availabilityDateAndTimeRange.timeFrom)"
                                       ngbDatepicker #datePickerFrom="ngbDatepicker"
                                       (click)="datePickerFrom.toggle()">
                                <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                        [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                        (click)="datePickerFrom.toggle()"
                                        type="button"></button>
                            </div>
                            <div class="time-picker-input-container" *ngIf="availabilityDateAndTimeRange.timeFrom">
                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                      [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                                      [format]="24"
                                                      [cancelBtnTmpl]="closeBtn"
                                                      [confirmBtnTmpl]="confirmBtn"
                                                      [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilityDateAndTimeRange.timeFrom)"
                                                      (timeChanged)="onValidFromDateTimeChanged(availabilityDateAndTimeRange.dateFrom, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                </ngx-timepicker-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12"
                         [ngClass]="{'has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW}">
                        <div class="form-group mb-2"
                             [ngClass]="{'has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW}">
                            <label translate="label.validTo"></label>
                        </div>
                        <div class="date-and-time-picker-inputs-container">
                            <div class="date-picker-input-container">
                                <input class="date-picker-input"
                                       [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                       name="dpto1"
                                       readonly
                                       [minDate]="dateRangeOptions.minDate"
                                       [(ngModel)]="availabilityDateAndTimeRange.dateTo"
                                       (dateSelect)="onValidToDateTimeChanged($event, availabilityDateAndTimeRange.timeTo)"
                                       ngbDatepicker #datePickerTo="ngbDatepicker"
                                       (click)="datePickerTo.toggle()">
                                <button class="btn btn-outline-secondary calendar date-picker-input-calendar-icon"
                                        [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                        (click)="datePickerTo.toggle()"
                                        type="button">
                                </button>
                            </div>
                            <div class="time-picker-input-container" *ngIf="availabilityDateAndTimeRange.timeTo">
                                <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                      [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid && screenTemplateLayout.action !== CONSTANTS.VIEW, 'disabled-class': screenTemplateLayout.action === CONSTANTS.VIEW}"
                                                      [format]="24"
                                                      [cancelBtnTmpl]="closeBtn"
                                                      [confirmBtnTmpl]="confirmBtn"
                                                      [defaultTime]="dateTimeUtils.ngbTimeStructToHourMinuteString(availabilityDateAndTimeRange.timeTo)"
                                                      (timeChanged)="onValidToDateTimeChanged(availabilityDateAndTimeRange.dateTo, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                </ngx-timepicker-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"
                     *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW && resourceWorkScheduleItem.resourceId && resourceWorkScheduleItem.centerId && !availabilityRangeValidation.isValid">
                    <div class="col-12 mt-1">
                        <span class="help-block" [translate]="availabilityRangeValidation.errorMessage"></span>
                    </div>
                </div>
            </div>

            <div class="step-form time-slots"
                 *ngIf="resourceWorkScheduleItem.resourceId && resourceWorkScheduleItem.centerId">
                <!-- Time slot button, Repetition period -->
                <div *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW" class="row mt-4"
                     [ngClass]="{'mb-3':isSaveButtonEnabled()}">
                    <!-- Repetition period -->
                    <div class="col-3">
                        <div class="form-group">
                            <div class="form-group create-availability-select-container">
                                <label class="create-availability-select-label"
                                       translate="label.repetitionPeriod"></label>
                                <ng-select
                                    name="repetition"
                                    class="custom-ng-select"
                                    loadingText="{{'label.loading' | translate}}"
                                    autofocus
                                    dropdownPosition="bottom"
                                    [(ngModel)]="resourceWorkScheduleItem.repetition"
                                    [ngModelOptions]="{standalone: true}"
                                    [multiple]="false"
                                    [searchable]="false"
                                    [clearable]="false">
                                    <ng-option [value]="1">
                                        1 <span translate="label.week2"></span>
                                    </ng-option>
                                    <ng-option [value]="2">
                                        2 <span translate="label.weeks"></span>
                                    </ng-option>
                                    <ng-option [value]="3">
                                        3 <span translate="label.weeks"></span>
                                    </ng-option>
                                    <ng-option [value]="4">
                                        4 <span translate="label.weeks"></span>
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Time slot section-->
                <div class="row" *ngIf="resourceWorkScheduleItem.resourceId && resourceWorkScheduleItem.centerId">
                    <div class="col-12">
                        <div class="form-group mb-0">
                            <label class="text-high-contrast pt-3" translate="label.timeSlots"></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <button (click)="showTimeSlotForm = !showTimeSlotForm"
                                type="button"
                                [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW || showTimeSlotForm"
                                class="btn theme-btn">
                            <i class="fas fa-plus"></i>
                            <span translate="button.addTimeSlot2" class="ml-2"></span>
                        </button>
                    </div>
                </div>
                <div class="row split-date-container"
                     *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW && showTimeSlotForm">
                    <div class="col-6 pt-3">
                        <div class="time-slot-time-picker-inputs-container">
                            <div class="time-slot-time-picker-input">
                                <div class="form-group mr-1"
                                     [ngClass]="{'has-error': !isTimeValid}">
                                    <label translate="label.from" class="mr-1"></label>
                                </div>
                                <div class="time-picker-input-container" *ngIf="scopedTimeSlotForm.hourFrom">
                                    <ngx-timepicker-field class="time-picker-input"
                                                          [ngClass]="{'input-element-has-error': !isTimeValid}"
                                                          [format]="24"
                                                          [cancelBtnTmpl]="closeBtn"
                                                          [confirmBtnTmpl]="confirmBtn"
                                                          [defaultTime]="scopedTimeSlotForm.hourFrom"
                                                          (timeChanged)="onHourFromChanged($event)">
                                    </ngx-timepicker-field>
                                </div>
                            </div>
                            <div class="time-slot-time-picker-input">
                                <div class="form-group mx-1"
                                     [ngClass]="{'has-error': !isTimeValid}">
                                    <label translate="label.to" class="mr-1"></label>
                                </div>
                                <div class="time-picker-input-container" *ngIf="scopedTimeSlotForm.hourTo">
                                    <ngx-timepicker-field class="time-picker-input"
                                                          [ngClass]="{'input-element-has-error': !isTimeValid}"
                                                          [format]="24"
                                                          [cancelBtnTmpl]="closeBtn"
                                                          [confirmBtnTmpl]="confirmBtn"
                                                          [defaultTime]="scopedTimeSlotForm.hourTo"
                                                          (timeChanged)="onHourToChanged($event)">
                                    </ngx-timepicker-field>
                                </div>
                            </div>
                        </div>
                        <div class="help-block mt-1" *ngIf="!isTimeValid">
                            <span translate="toastr.error.slotInvalidPeriod"></span>
                        </div>
                    </div>
                    <div class="col-3 pt-3">
                        <div class="form-group create-availability-multiple-select-container mat-select-input-element"
                             [ngClass]="{'input-element-has-error': scopedTimeSlotForm.selectedDays.length === 0}">
                            <label translate="label.selectDays"
                                   class="create-availability-multiple-select-label mb-4"></label>
                            <div>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'label.choose' | translate}}</mat-label>
                                    <mat-select multiple
                                                name="selectedDays"
                                                disableOptionCentering
                                                [(ngModel)]="scopedTimeSlotForm.selectedDays">
                                        <mat-select-trigger>
                                            <span *ngFor="let day of scopedTimeSlotForm.selectedDays; last as isLast">
                                                {{"label.date." + day + "Med" | translate }}
                                                <span *ngIf="!isLast">,</span>
                                            </span>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let day of DAYS_OF_WEEK"
                                                    [value]="day">{{"label.date." + day | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="scopedTimeSlotForm.selectedDays.length === 0"
                                     class="help-block create-availability-multiple-select-validation">
                                    <span [ngClass]="{'has-error': scopedTimeSlotForm.selectedDays.length === 0}"
                                          translate="label.error.required">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex float-right mb-3 mt-1 update-buttons"
                     *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW && showTimeSlotForm">
                    <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                            type="button"
                            translate="button.save"
                            [disabled]="!scopedTimeSlotForm.hourFrom || !scopedTimeSlotForm.hourTo || !isTimeValid || scopedTimeSlotForm.selectedDays.length === 0"
                            (click)="onSaveTimeSlot()">
                    </button>
                    <button class="btn border-0 p-0" translate="button.cancel" type="button"
                            (click)="onCancelTimeSlot()"></button>
                </div>
                <!-- TimeSlots table-->
                <div class="form-group">
                    <div class="form-group overview-table-container mt-2">
                        <table class="table table-bordered grey-table">
                            <thead class="thead-light">
                            <tr>
                                <td class="name-column text-center" translate="label.date.monday"></td>
                                <td class="name-column text-center" translate="label.date.tuesday"></td>
                                <td class="name-column text-center" translate="label.date.wednesday"></td>
                                <td class="name-column text-center" translate="label.date.thursday"></td>
                                <td class="name-column text-center" translate="label.date.friday"></td>
                                <td class="name-column text-center" translate="label.date.saturday"></td>
                                <td class="name-column text-center" translate="label.date.sunday"></td>
                                <td class="name-column text-center" translate="label.action"
                                    *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW"></td>
                            </tr>
                            </thead>
                            <!--display an empty row if no timeslot is added-->
                            <tbody
                                *ngIf="!resourceWorkScheduleItem?.timeSlots || resourceWorkScheduleItem?.timeSlots?.length===0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW"></td>
                            </tr>
                            </tbody>
                            <tbody *ngFor="let timeSlot of resourceWorkScheduleItem?.timeSlots; let index = index">
                            <tr>
                                <td><span *ngIf="timeSlot.monday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.tuesday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.wednesday">{{timeSlot.interval.hourFrom |
                                    hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.thursday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.friday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.saturday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.sunday">{{timeSlot.interval.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.interval.hourTo | hourFromMinutes}}</span></td>
                                <td class="text-center" *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                            popoverClass="popover-shadow">
                                        <i class="fas fa-ellipsis-h"></i>
                                    </button>
                                    <ng-template #popContent>
                                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                                            <li>
                                                <button type="button" class="btn" translate="label.edit"
                                                        (click)="editTimeSlot(timeSlot, index)"></button>
                                            </li>
                                            <li>
                                                <button type="button" class="btn" translate="label.delete"
                                                        (click)="deleteTimeSlot(index)"></button>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="create-entity-footer mt-2 footer-position-sticky">
            <button class="btn mr-3"
                    type="button"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn"
                    type="submit"
                    [hidden]="screenTemplateLayout.action === CONSTANTS.VIEW"
                    [disabled]="!isSaveButtonEnabled()"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'ResourceWorkScheduleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close">
        </button>
    </div>
</ng-template>
