import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RUAppointmentsType} from 'sked-base';
import {ResourceUtilizationUtils} from '../resource-utilization.utils';
import {ResourceUtilizationStateComponentsEnum} from '../resource-utilization.types';
import * as lodash from 'lodash';

@Component({
    selector: 'app-appointment-list',
    templateUrl: './resource-appointment-list.component.html',
    styleUrls: ['./resource-appointment-list.component.scss']
})
export class ResourceAppointmentListComponent implements OnInit {
    @Output() selectedAppointment = new EventEmitter<RUAppointmentsType>();
    @Output() viewAppointmentInCalendar = new EventEmitter<RUAppointmentsType>();

    constructor(
        public resourceUtilizationUtils: ResourceUtilizationUtils,
    ) {
    }

    ngOnInit(): void {
        this.loadPendingAppointmentsPerPage();
    }

    loadPendingAppointmentsPerPage(): void {
        // Load from state if required
        if (this.resourceUtilizationUtils.shouldLoadState(ResourceUtilizationStateComponentsEnum.appointmentsListOptions)) {
            this.resourceUtilizationUtils.appointmentsListOptions =
                lodash.cloneDeep(this.resourceUtilizationUtils.resourceUtilizationState.appointmentsListOptions);
            return;
        }
        this.resourceUtilizationUtils.appointmentsListOptions.pendingAppointmentsPerPage =
            this.resourceUtilizationUtils.getAppointmentsPerPage(
                this.resourceUtilizationUtils.appointmentsListOptions.currentPage,
                this.resourceUtilizationUtils.appointmentsListOptions.pageSize
            );
        // Update state
        this.resourceUtilizationUtils.updateAppointmentsListState(['pendingAppointmentsPerPage']);
    }

    selectAppointment(appointment: RUAppointmentsType) {
        this.selectedAppointment.emit(appointment);
    }

    viewInCalendar(appointment: RUAppointmentsType, event: MouseEvent) {
        // Don't emit on selectedAppointment
        event.stopPropagation();
        this.viewAppointmentInCalendar.emit(appointment);
    }

    changePagination(page): void {
        this.resourceUtilizationUtils.appointmentsListOptions.currentPage = page;
        this.resourceUtilizationUtils.appointmentsListOptions.pendingAppointmentsPerPage = this.resourceUtilizationUtils.getAppointmentsPerPage(
            this.resourceUtilizationUtils.appointmentsListOptions.currentPage,
            this.resourceUtilizationUtils.appointmentsListOptions.pageSize
        );
        // Update appointment list state
        this.resourceUtilizationUtils.updateAppointmentsListState(['currentPage', 'pendingAppointmentsPerPage']);
    }
}
