<div class="container-fluid user-md-wrapper users-md-screen overview-screen-md pb-1">
    <div class="user-md-header d-flex justify-content-between">
        <h3 class="user-md-title" translate="label.userManagementTab.user"></h3>
        <div class="user-md-header-buttons-container filters-section d-flex">
            <sbase-filter (selectedItemsOutput)="onSelectedTeamFilter($event)"
                          [buttonName]=teamButtonName
                          [dependentFilters]="{}"
                          [hideSelectedItems]="true"
                          [initialFilterValues]="userMDUtils.initialTeamValues"
                          [isMultipleSelectedItem]="false"
                          [providerInstance]="teamProvider"
                          [useSelectedValueAsLabel]="true">
            </sbase-filter>
            <ng-select
                class="form-control rounded-1 custom-ng-select"
                dropdownPosition="bottom"
                placeholder="{{'label.type' | translate}}"
                [(ngModel)]="userMDUtils.tableFilters.filter.userType"
                [multiple]="false"
                [searchable]="false"
                [clearable]="false"
                [ngClass]="!!userMDUtils.tableFilters.filter.userType ? 'ng-select-has-value' : null"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="applyFilter($event, 'userTypeFilterOptions')">
                <ng-option [value]="'all'">
                    <span translate="label.all"></span>
                </ng-option>
                <ng-option *ngFor="let type of UserTypeEnumList"
                           [value]="type">
                    <span [translate]="'enum.userType.' + type"></span>
                </ng-option>
            </ng-select>
            <div class="mt-1 mb-1 ml-1">
                <app-generate-export-file [exportFileEntity]="exportFileEntityEnum.Users"></app-generate-export-file>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(userMDUtils.tableFilters.orderBy)">
                  <span
                      *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + userMDUtils.tableFilters.orderBy[key] | translate}}
                    <i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
            </span>
            </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input (ngModelChange)="onSearchUserNameChanged($event)"
                       [(ngModel)]="userMDUtils.tableFilters.filter.Username"
                       autocomplete="off" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" type="text">
            </div>
            <div class="ml-2">
                <button (click)='createUser()' [activityDisplay]="MDEntityName + constants.CREATE"
                        class="btn theme-alt-btn border-0"
                        translate="label.createNew" type="button">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th (click)="onSortBy('username')" class="name-column cursor-pointer">
                    {{'label.userManagementTab.userName' | translate}}
                    <button *ngIf="!userMDUtils.tableFilters.orderBy.username" class="btn p-0" type="button"><i
                        class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="userMDUtils.tableFilters.orderBy.username === 'asc'" class="btn p-0" type="button"><i
                        class="fas fa-sort-down"></i></button>
                    <button *ngIf="userMDUtils.tableFilters.orderBy.username === 'desc'" class="btn p-0" type="button">
                        <i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th translate="label.userManagementTab.fullName"></th>
                <th translate="label.userManagementTab.team"></th>
                <th translate="label.userManagementTab.email"></th>
                <th translate="label.resource"></th>
                <th translate="label.centers"></th>
                <th class="text-center" translate="label.serviceTab.externalKeysTab">
                <th translate="label.apiKeyHint"></th>
                <th translate="label.type"></th>
                <th translate="label.lastLogin">
                <th translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of usersList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewUser(user)">
                <td>{{user.username}}</td>
                <td>{{user.firstName}}&nbsp;{{user.lastName}}</td>
                <td>{{user.team?.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.resource?.name}}</td>
                <td>
                    <div *ngFor="let center of user.centers | slice:0:3">
                        {{center.name}}
                    </div>
                    <ng-template #centersContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let center of user.centers">{{center.name}}</div>
                        </div>
                    </ng-template>
                    <div *ngIf="user.centers && user.centers.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="centersContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <div *ngFor="let externalKey of user.userExternalKeys| slice:0:3">
                        {{externalKey.origin}} - {{externalKey.key}}
                    </div>
                    <ng-template #centerExternalKeysContent>
                        <div>
                            <div *ngFor="let externalKey of user.userExternalKeys">
                                {{externalKey.origin}} - {{externalKey.key}}
                            </div>
                        </div>
                    </ng-template>
                    <button *ngIf="user.userExternalKeys && user.userExternalKeys.length>3"
                            (click)="$event.stopPropagation()"
                            class="btn text-center w-100"
                            type="button">
                        <div [ngbPopover]="centerExternalKeysContent"
                             class="fas fa-ellipsis-h">
                        </div>
                    </button>
                </td>
                <td class="text-center">
                    <div *ngFor="let apiKey of user.apiKeys">
                        {{apiKey.apiKeyHint}}
                    </div>
                </td>
                <td>{{user.type}}</td>
                <td>{{user.lastLogin | fullDateBrowserTzFormatPipe}}</td>
                <td (click)="$event.stopPropagation()">
                    <button [ngbPopover]="popContent" class="btn" placement="left" popoverClass="popover-shadow"
                            type="button">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button (click)="viewUser(user)" class="btn" translate="label.view"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="editUser(user)" [activityDisplay]="MDEntityName + constants.UPDATE"
                                        class="btn"
                                        translate="label.edit"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="displayConfirmDeleteItemModal(user.id)"
                                        [activityDisplay]="MDEntityName+constants.DELETE" class="btn"
                                        translate="label.delete"
                                        type="button"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(user.id, EntityTypeEnum.user, user.username)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                            <li>
                                <button *ngIf="user.type === UserTypeEnum.API"
                                        type="button" class="btn" translate="label.apiKey"
                                        (click)="displayApiKeyForUserModal(user)"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="userMDUtils.tableFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="userMDUtils.tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                  <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                        [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                        aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                        data-toggle="dropdown">
                      <strong>{{userMDUtils.tableFilters.itemsPerPage}}</strong>
                  </span>
            <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                 [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                   class="dropdown-item">{{page}}</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
