import {Injectable} from '@angular/core';
import {
    ODataFilterQueryType,
    ODataOrderByQueryType,
    ScreenTemplateLayoutType,
    TableFiltersType
} from '../../data-model/general.type';
import {
    ActivityPlanExecutionTypeEnum, ActivityPlanScheduledEndTypeEnum,
    ActivityPlanTemplateServiceType,
    ActivityPlanTemplateType,
    EnqueueUnitEnum,
    Expand,
    ODataQueryObjectType,
    SpecialityType,
    Validations
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {
    ActivityPlanTemplateFrequency,
    ActivityPlanTemplateFrequencyTypeEnum,
    ActivityPlanTemplateFrequencyValue,
    ActivityPlanTemplateServiceValidation,
    FrequencyMonthDayType,
    FrequencyWeekDayType
} from './activity-plan-template-md.types';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {Subject} from 'rxjs';
import {constants} from '../../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ActivityPlanTemplateMdUtils {
    tableFilters: TableFiltersType;
    WEEKDAYS: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    MONTHDAYS: number[] = [...Array(32).keys()].slice(1); // array of numbers from 1 to 31

    screenTemplateLayout: ScreenTemplateLayoutType;
    activityPlanTemplateItem: ActivityPlanTemplateType = {} as ActivityPlanTemplateType;
    scopedServiceItem: ActivityPlanTemplateServiceType;
    scopedServiceFrequencyItem: ActivityPlanTemplateFrequency;
    scopedServiceItemIndex: number;
    scopedServiceHasValues = false;
    scopedServiceValidation: ActivityPlanTemplateServiceValidation;
    scopedServiceInEditMode = false;
    maximumTimeWindow: number;
    triggerSimulationSubject: Subject<void> = new Subject<void>();
    triggerClearCalendarPageSubject: Subject<void> = new Subject<void>();
    isExecutionTypeOnceSelected: boolean;

    constructor(
        private generalUtils: GeneralUtils,
        private validations: Validations,
        private dateTimeUtils: DateTimeUtils,
    ) {
    }

    isScopedServiceValid(): boolean {
        this.updateScopedServiceValidation();
        return this.scopedServiceValidation.service.isValid &&
            this.scopedServiceValidation.recurrenceModal.isValid;
    }

    updateScopedServiceValidation() {
        this.scopedServiceValidation = this.getValidateActivityTemplateService(
            this.scopedServiceItem,
            this.scopedServiceFrequencyItem
        );
        this.triggerClearCalendarPageSubject.next();
    }

    onExecutionTypeChange() {
        this.scopedServiceHasValues = true;
        if (this.isExecutionTypeOnceSelected) {
            this.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Once;
        } else {
            this.scopedServiceItem.executionType = ActivityPlanExecutionTypeEnum.Scheduled;
        }
        this.updateScopedServiceValidation();
    }

    onFrequencyTypeChange() {
        this.scopedServiceHasValues = true;
        if (this.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.monthDays) {
            this.scopedServiceFrequencyItem.selectedMonthDays =
                this.getMonthDays(this.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.weekDays) {
            this.scopedServiceFrequencyItem.selectedWeekDays =
                this.getWeekDays(this.scopedServiceFrequencyItem.selectedDays as string[]);
        }
        this.scopedServiceFrequencyItem.selectedDays = [];
        this.updateScopedServiceValidation();
    }

    onFrequencyWeekDayClick(weekDay: FrequencyWeekDayType) {
        this.scopedServiceHasValues = true;
        weekDay.selected = !weekDay.selected;
        if (weekDay.selected) {
            (this.scopedServiceFrequencyItem.selectedDays as string[]).push(weekDay.name);
        } else {
            lodash.pull(this.scopedServiceFrequencyItem.selectedDays, weekDay.name);
        }
        this.updateScopedServiceValidation();
    }

    onFrequencyMonthDayClick(monthDay: FrequencyMonthDayType) {
        this.scopedServiceHasValues = true;
        monthDay.selected = !monthDay.selected;
        if (monthDay.selected) {
            (this.scopedServiceFrequencyItem.selectedDays as number[]).push(monthDay.name);
        } else {
            lodash.pull(this.scopedServiceFrequencyItem.selectedDays, monthDay.name);
        }
        this.updateScopedServiceValidation();
    }

    addSpecialityNameToServices(activityPlanTemplateList: ActivityPlanTemplateType[], specialityData: SpecialityType[]): ActivityPlanTemplateType[] {
        const activityPlanTemplateListToMap = lodash.cloneDeep(activityPlanTemplateList);
        // for all templates, map an input template to a template whose services.service have the specialityName field added
        activityPlanTemplateListToMap.map(activityPlanTemplate => {
            activityPlanTemplate.services = activityPlanTemplate.services.map((service: ActivityPlanTemplateServiceType) => {
                // if the ActivityPlanTemplateServiceType service doesn't have a ServiceType service, don't change anything
                if (!service?.service) {
                    return service;
                }
                // otherwise, add the speciality name to its ServiceType service
                const serviceWithSpecialityName = lodash.cloneDeep(service);
                const specialityItem = lodash.find(specialityData, {id: serviceWithSpecialityName.service.specialityId});
                serviceWithSpecialityName.service.specialityName = specialityItem?.name;
                return serviceWithSpecialityName;
            });
            return activityPlanTemplate;
        });
        return activityPlanTemplateListToMap;
    }

    addParsedFrequencyToTemplateItem(activityPlanTemplate: ActivityPlanTemplateType): ActivityPlanTemplateType {
        const activityPlanTemplateWithParsedFrequency = lodash.cloneDeep(activityPlanTemplate);
        activityPlanTemplateWithParsedFrequency.services = activityPlanTemplateWithParsedFrequency.services.map((
            service: ActivityPlanTemplateServiceType
        ) => {
            const serviceWithParsedFrequency = lodash.cloneDeep(service);
            serviceWithParsedFrequency.parsedFrequency = this.parseActivityPlanTemplateServiceFrequency(serviceWithParsedFrequency.frequency);
            return serviceWithParsedFrequency;
        });
        return activityPlanTemplateWithParsedFrequency;
    }

    getQueryFilterForActivityPlanTemplateMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            select: ['Id', 'Name', 'RowVersion'],
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            Services: {
                expand: {
                    Service: {
                        select: ['Id', 'Name', 'OnlineConsultation', 'SpecialityId']
                    }
                }
            },
        };
    }

    getQueryFilterForSpecialityNames(): ODataQueryObjectType {
        return {
            select: ['Id', 'Name'],
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialActivityPlanTemplate(): ActivityPlanTemplateType {
        return {
            name: '',
            services: []
        } as ActivityPlanTemplateType;
    }

    getInitialActivityTemplateServiceValidation(): ActivityPlanTemplateServiceValidation {
        return {
            frequencyType: {isValid: true, errorMessage: ''},
            frequencyDays: {isValid: true, errorMessage: ''},
            interval: {isValid: true, errorMessage: ''},
            count: {isValid: true, errorMessage: ''},
            enqueue: {isValid: true, errorMessage: ''},
            service: {isValid: true, errorMessage: ''},
            recurrenceModal: {isValid: true, errorMessage: ''},
        };
    }

    getInitialActivityPlanTemplateService(): ActivityPlanTemplateServiceType {
        return {
            serviceId: '',
            frequency: '{}',
            interval: null,
            count: null,
            enqueuePeriod: 2,
            enqueueUnit: EnqueueUnitEnum.Weeks,
            executionType: ActivityPlanExecutionTypeEnum.Scheduled,
            scheduledEndType: ActivityPlanScheduledEndTypeEnum.EndsNever,
        } as ActivityPlanTemplateServiceType;
    }

    getEmptyActivityPlanTemplateServiceFrequency(): ActivityPlanTemplateFrequency {
        return {
            frequencyType: ActivityPlanTemplateFrequencyTypeEnum.weekDays,
            selectedDays: []
        } as ActivityPlanTemplateFrequency;
    }

    parseActivityPlanTemplateServiceFrequency(frequency: string): ActivityPlanTemplateFrequency {
        let frequencyJSON;
        try {
            frequencyJSON = JSON.parse(frequency);
        } catch {
            return this.getEmptyActivityPlanTemplateServiceFrequency();
        }
        let keys;
        try {
            keys = Object.keys(frequencyJSON);
        } catch {
            return this.getEmptyActivityPlanTemplateServiceFrequency();
        }
        if (!lodash.has(keys, 'length') || keys.length < 0 || keys.length > 1 ||
            (keys[0] !== ActivityPlanTemplateFrequencyTypeEnum.monthDays && keys[0] !== ActivityPlanTemplateFrequencyTypeEnum.weekDays)) {
            return this.getEmptyActivityPlanTemplateServiceFrequency();
        }
        const frequencyType = keys[0] === ActivityPlanTemplateFrequencyTypeEnum.monthDays ?
            ActivityPlanTemplateFrequencyTypeEnum.monthDays :
            ActivityPlanTemplateFrequencyTypeEnum.weekDays;
        return {
            frequencyType,
            selectedDays: frequencyJSON[keys[0]]
        } as ActivityPlanTemplateFrequency;
    }

    stringifyActivityPlanTemplateServiceFrequency(frequency: ActivityPlanTemplateFrequency): string {
        const selectedDaysAsString = (frequency.selectedDays as any[]).map((day: ActivityPlanTemplateFrequencyValue) => {
            if (typeof day === 'string') {
                return `"${day}"`;
            }
            else {
                return `${day}`;
            }
        }).join(',');
        return `{"${frequency.frequencyType}":[${selectedDaysAsString}]}`;
    }

    getDaysFromEnqueuePeriodAndUnit(period: number, unit: EnqueueUnitEnum): number {
        if (unit === EnqueueUnitEnum.Days) {
            return period;
        }
        if (unit === EnqueueUnitEnum.Weeks) {
            return period * constants.DAYS_IN_WEEK;
        }
        if (unit === EnqueueUnitEnum.Months) {
            return period * constants.DAYS_IN_MONTH;
        }
    }

    mapScopedServiceToSave(service: ActivityPlanTemplateServiceType, frequency: ActivityPlanTemplateFrequency): ActivityPlanTemplateServiceType {
        const serviceToSave = lodash.cloneDeep(service);
        // sort the selected days
        const frequencyOrdered = lodash.cloneDeep(frequency);
        if (frequencyOrdered.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.weekDays) {
            frequencyOrdered.selectedDays = lodash.orderBy(frequencyOrdered.selectedDays, (day) => lodash.indexOf(this.WEEKDAYS, day));
        } else { // monthDays
            frequencyOrdered.selectedDays = lodash.orderBy(frequencyOrdered.selectedDays);
        }
        serviceToSave.frequency = this.stringifyActivityPlanTemplateServiceFrequency(frequencyOrdered);
        serviceToSave.parsedFrequency = frequencyOrdered;
        return serviceToSave as ActivityPlanTemplateServiceType;
    }

    mapActivityPlanTemplateToSend(activityPlanTemplate: ActivityPlanTemplateType): ActivityPlanTemplateType {
        const activityPlanTemplateToSend = lodash.cloneDeep(activityPlanTemplate);
        activityPlanTemplateToSend.services = activityPlanTemplateToSend.services.map((service: ActivityPlanTemplateServiceType) => {
            const serviceToSend: ActivityPlanTemplateServiceType = lodash.cloneDeep(service);
            if (service.count !== undefined && service.count !== null && !isNaN(Number(service.count)) && Number(service.count) > 0) {
                serviceToSend.count = service.count;
            }
            if (!!activityPlanTemplateToSend.id && !service.activityPlanTemplateId) {
                serviceToSend.activityPlanTemplateId = activityPlanTemplateToSend.id;
            }
            return serviceToSend;
        });
        return activityPlanTemplateToSend;
    }

    getWeekDays(selectedDays: string[]): FrequencyWeekDayType[] {
        return this.WEEKDAYS.map((weekday: string) => {
            return {
                name: weekday,
                selected: (lodash.indexOf(selectedDays, weekday) > -1)
            } as FrequencyWeekDayType;
        });
    }

    getMonthDays(selectedDays: number[]): FrequencyMonthDayType[] {
        return this.MONTHDAYS.map((monthday: number) => {
            return {
                name: monthday,
                selected: (lodash.indexOf(selectedDays, monthday) > -1)
            } as FrequencyMonthDayType;
        });
    }

    private getValidateActivityTemplateService(
        service: ActivityPlanTemplateServiceType, frequency: ActivityPlanTemplateFrequency
    ): ActivityPlanTemplateServiceValidation {
        const serviceValidation = {
            frequencyType: {isValid: true, errorMessage: ''},
            frequencyDays: {isValid: true, errorMessage: ''},
            interval: {isValid: true, errorMessage: ''},
            count: {isValid: true, errorMessage: ''},
            enqueue: {isValid: true, errorMessage: ''},
            service: {isValid: true, errorMessage: ''},
            recurrenceModal: {isValid: true, errorMessage: ''},
        } as ActivityPlanTemplateServiceValidation;

        // Count is not required; if any value is inserted it must be > 0
        if (!this.generalUtils.isUndefinedOrNull(service.count)) {
            serviceValidation.count = this.validations.getValidateIntegerBetween(`${service.count}`, 1, undefined, 'label.error.greaterThanZero');
        }

        // Interval is required and > 0
        if (this.generalUtils.isUndefinedOrNull(service.interval)) {
            serviceValidation.interval = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.interval = this.validations.getValidateIntegerBetween(
                `${service.interval}`, 1, undefined, 'label.error.greaterThanZero'
            );
        }

        // FrequencyType is required: type should be selected
        if (this.generalUtils.isUndefinedOrNull(frequency.frequencyType) || lodash.isEmpty(frequency.frequencyType)) {
            serviceValidation.frequencyType = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.frequencyType = {isValid: true, errorMessage: ''};
        }

        // FrequencyDays is required: should have at least one element selected
        if (this.generalUtils.isUndefinedOrNull(frequency.selectedDays) || lodash.isEmpty(frequency.selectedDays)) {
            serviceValidation.frequencyDays = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            serviceValidation.frequencyDays = {isValid: true, errorMessage: ''};
        }

        // Enqueue is required, > 0 and <= maximum time window (from system config)
        if (this.generalUtils.isUndefinedOrNull(service.enqueueUnit) || this.generalUtils.isUndefinedOrNull(service.enqueuePeriod)) {
            serviceValidation.enqueue = {isValid: false, errorMessage: 'label.error.required'};
        } else {
            const enqueuePeriodInDays = this.getDaysFromEnqueuePeriodAndUnit(service.enqueuePeriod, service.enqueueUnit);
            // check for lower bound first
            serviceValidation.enqueue = this.validations.getValidateIntegerBetween(`${enqueuePeriodInDays}`, 1, undefined, 'label.error.greaterThanZero');
            if (serviceValidation.enqueue.isValid === true) {
                // if lower bound is fine, check for upper bound
                serviceValidation.enqueue = this.validations.getValidateIntegerBetween(`${enqueuePeriodInDays}`, undefined, this.maximumTimeWindow, 'label.error.activityPlanInvalidEnqueue');
            }
        }

        // Service is required
        if (this.generalUtils.isUndefinedOrNull(service.service) || this.generalUtils.isUndefinedOrNull(service.service)) {
            serviceValidation.service = {isValid: false, errorMessage: 'label.error.required'};
        }

        if (service.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences) {
            if (this.generalUtils.isUndefinedOrNull(service.count)) {
                serviceValidation.count = {isValid: false, errorMessage: 'label.error.required'};
            } else {
                const countNumber = Number(service.count ?? 0) ?? 0;
                if (isNaN(countNumber) || countNumber < 1) {
                    serviceValidation.count = {isValid: false, errorMessage: 'label.error.valueMustBeAtLeastOne'};
                }
            }
        }

        const isModalValid = service.executionType === ActivityPlanExecutionTypeEnum.Once
            ? true
            : serviceValidation.frequencyType.isValid
            && serviceValidation.frequencyDays.isValid
            && serviceValidation.interval.isValid
            && serviceValidation.enqueue.isValid
            && serviceValidation.count.isValid;
        serviceValidation.recurrenceModal = {
            isValid: isModalValid,
            errorMessage: isModalValid ? '' : 'label.thereAreErrorsInside',
        };

        return serviceValidation;
    }
}
