<div class="comments-modal">
    <div class="modal-header">
        <h4>
            <strong>{{"label.comments" | translate}}</strong>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container d-flex no-block flex-column custom-scrollbar">
            <div class="row">
                <div class="col-10 pr-1">
                    <textarea type="text" name="comments" class="form-control rounded-0 w-100 mr-3"
                              [(ngModel)]="comment"
                              placeholder="{{'label.hereAddYourComments' | translate}}"
                              autocomplete="off">
                    </textarea>
                </div>
                <div class="col-2 pr-1 pl-1">
                    <button type="button"
                            class="btn theme-btn min-width-auto"
                            [disabled]= "!comment"
                            (click)="onAddNewComment(comment)" translate="label.save">
                    </button>
                </div>
            </div>
            <div class="row mt-3">
                <ngx-ui-loader [hasProgressBar]="false" [loaderId]="spinnerId">
                </ngx-ui-loader>
                <div class="col-12" *ngFor="let comment of comments; let index = index">
                    <div>
                        <i class="fa-solid fa-user mr-1 text-dark font-weight-bold"
                           *ngIf="comment?.modifiedByClient !== 'PatientPortal'"></i>
                        <i class="fa-solid fa-user-injured mr-1 text-dark font-weight-bold"
                           *ngIf="comment?.modifiedByClient === 'PatientPortal'"></i>
                        <span class="mr-2 text-dark font-weight-bold">{{comment.username}}</span>
                        <i class="fa-solid fa-circle small-dot mr-2"></i>
                        <span>{{comment.modifiedOn | fullDateBrowserTzFormatPipe}}</span>
                    </div>
                    <div class="mt-2 text-dark">
                        {{comment.content}}
                    </div>
                    <div class="horizontal-line" *ngIf="index < comments?.length - 1"></div>
                </div>
            </div>
        </div>
        <div class="pagination-component mt-4" *ngIf="options?.commentsCount > 0">
            <ngb-pagination [collectionSize]="options?.commentsCount" [(page)]="currentPage" [maxSize]="5"
                            [pageSize]="itemsPerPage" [boundaryLinks]="true"
                            (pageChange)="onChangePagination($event)"></ngb-pagination>
    </div>
</div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn min-width-auto"
                (click)="onCloseModal()" translate="label.close">
        </button>
    </div>
</div>



