// module for class - directive , pipes
//import where ever its required

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureDisplayDirective} from './feature-display.directive';
import {ActivityDisplayDirective} from './activity-display.directive';
import { LocalVariableDirective } from './local-variable.directive';
import { ResizeEventDirective } from './resize-event/resize-event.directive';

@NgModule({
  declarations: [FeatureDisplayDirective, ActivityDisplayDirective, LocalVariableDirective, ResizeEventDirective],
  imports: [
    CommonModule
  ],
    exports: [FeatureDisplayDirective, ActivityDisplayDirective, LocalVariableDirective, ResizeEventDirective]
})
export class DirectiveModule {
}
