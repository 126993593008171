<div class="container-fluid create-work-order-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-work-order-rule-header">
        <h3 class="create-work-order-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.workOrderRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === constants.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.workOrderRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.workOrderRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.workOrderRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.workOrderRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Service -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect service sbase-input-element">
                                    <label translate="label.service2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                  [buttonName]="'label.service2'"
                                                  [dependentFilters]="mainDependentFilters.service"
                                                  [initialFilterValues]="[ruleItem.service]"
                                                  [providerInstance]="serviceProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.service}"
                                                  [hideSelectedItems]="true">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.service"
                                         class="help-block">
                                        <span class="has-error"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Center -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                                  [buttonName]="'label.center2'"
                                                  [dependentFilters]="mainDependentFilters.location"
                                                  [initialFilterValues]="[ruleItem.center]"
                                                  [providerInstance]="centerProvider"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== constants.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Booking Type -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.bookingType"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               [ngClass]="!!ruleItem.bookingType ? 'ng-select-has-value' : 'input-element-has-error'"
                                               dropdownPosition="bottom"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               placeholder="{{'label.choose' | translate}}"
                                               [(ngModel)]="ruleItem.bookingType"
                                               (ngModelChange)="sanitizeNgSelectValue('bookingType');">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let item of bookingTypeEnumList"
                                                   [value]="item">
                                            <span [translate]="'enum.bookingType.' + item"></span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem?.bookingType"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.bookingType}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.workOrderRequired"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.workOrderRequired"
                                                   name="workOrderRequired">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.ruleTable"></td>
                                            <td class="text-right">{{ruleItem.workOrderRuleTable?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.name"></td>
                                            <td class="text-right">{{ruleItem?.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.active"></td>
                                            <td class="text-right">{{ruleItem?.active | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.service2"></td>
                                            <td class="text-right">
                                                <span class="font-weight-bold"> {{ruleItem.service?.speciality?.name}} </span>
                                                {{ruleItem.service?.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.center2"></td>
                                            <td class="text-right">{{ruleItem?.center?.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td class="font-weight-bold" translate="label.bookingType"></td>
                                            <td class="text-right" [translate]="'enum.bookingType.' + ruleItem?.bookingType"></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                translate="label.workOrderRequired"></td>
                                            <td class="text-right">{{ruleItem?.workOrderRequired | yesNoPipe | translate}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==constants.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    *ngIf="rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'WorkOrderRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
