import {AfterContentInit, Component} from '@angular/core';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {forkJoin} from 'rxjs';
import {
    AppointmentProvider,
    AppointmentType,
    FilterWrapperPatientSearchType,
    MergePatientType,
    ODataQueryObjectType,
    PatientDependentFiltersType,
    PatientProvider,
    PatientSearchResultType,
    PatientType
} from 'sked-base';
import {MessagesService} from '../../../shared/services/messages.service';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {PatientMergeOptions, PatientMergeSide} from './patient-merge.types';
import {PatientMergeUtils} from './patient-merge.utils';

@Component({
    selector: 'app-patient-merge',
    templateUrl: './patient-merge.component.html',
    styleUrls: ['./patient-merge.component.scss']
})
export class PatientMergeComponent implements AfterContentInit {

    options: PatientMergeOptions = {
        [PatientMergeSide.LEFT]: {
            appointmentsTablePagination: this.patientMergeUtils.getInitialPaginationConfig(),
            keepPatient: false,
            keepCancelledAppointments: false,
        },
        [PatientMergeSide.RIGHT]: {
            appointmentsTablePagination: this.patientMergeUtils.getInitialPaginationConfig(),
            keepPatient: false,
            keepCancelledAppointments: false,
        }
    };

    mainDependentFilters: PatientDependentFiltersType;
    patientSearchTags: FilterWrapperPatientSearchType[][] = [[], []];

    readonly PatientMergeSide = PatientMergeSide;

    constructor(
        private appointmentProvider: AppointmentProvider,
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public patientContextService: PatientContextService,
        public patientProvider: PatientProvider,
        public patientMergeUtils: PatientMergeUtils,
        private router: Router,
    ) {
    }

    ngAfterContentInit(): void {
        this.fillLeftPatientData();
        this.mainDependentFilters = this.patientMergeUtils.getPatientDependentFilters();
    }

    onClose(): void {
        this.router.navigate(['/patientDashboard']);
    }

    onMerge(): void {
        this.ngxLoader.start();
        let requestBody: MergePatientType;
        // checks which patient has to be kept and builds the request object accordingly
        if (this.options[PatientMergeSide.LEFT].keepPatient) {
            requestBody = {
                oldPatientId: this.options[PatientMergeSide.RIGHT].patient.id,
                newPatientId: this.options[PatientMergeSide.LEFT].patient.id,
                includeCancelledAppointments: this.options[PatientMergeSide.RIGHT].keepCancelledAppointments
            };
        } else {
            requestBody = {
                oldPatientId: this.options[PatientMergeSide.LEFT].patient.id,
                newPatientId: this.options[PatientMergeSide.RIGHT].patient.id,
                includeCancelledAppointments: this.options[PatientMergeSide.LEFT].keepCancelledAppointments
            };
        }
        this.patientProvider.mergePatient(requestBody).subscribe(() => {
            this.clearPatientData(PatientMergeSide.LEFT);
            this.clearPatientData(PatientMergeSide.RIGHT);
            this.ngxLoader.stop();
            this.messagesService.success('toastr.success.mergePatient');
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    onPatientFilterValueChanged(patientSelectedArray: PatientType[] | PatientSearchResultType[], side: PatientMergeSide): void {
        const patient = patientSelectedArray[0];
        if (!this.isPatientChangeValid(patient, side)) {
            return;
        }
        this.options[side].appointmentsTablePagination.tableFilters.filter.patientId = patient.id;
        let getAppointmentsQuery: ODataQueryObjectType;
        getAppointmentsQuery = this.patientMergeUtils.getAppointmentListQuery(this.options[side].appointmentsTablePagination.tableFilters);
        this.ngxLoader.start();
        forkJoin({
            patientData: this.patientProvider.getById(patient.id, this.patientMergeUtils.getPatientQueryFilter()),
            appointments: this.appointmentProvider.getByUserInformation(getAppointmentsQuery, false, false)
        }).subscribe(({patientData, appointments}) => {
            this.options[side].patient = patientData;
            this.options[side].appointments = appointments.value;
            this.options[side].appointmentsTablePagination.totalTableItems = appointments.count ?? 0;
            this.ngxLoader.stop();
        }, err => {
            this.clearPatientData(side);
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    // reloads appointments table with page selected by the user
    onChangePagination(page: number, side: PatientMergeSide): void {
        this.options[side].appointmentsTablePagination.tableFilters.currentPage = page;
        this.getAppointmentTableData(side);
    }

    // checks if both patients are selected and one is chosen to be kept
    isMergeButtonEnabled(): boolean {
        return this.options[PatientMergeSide.LEFT].patient && this.options[PatientMergeSide.RIGHT].patient
            && (this.options[PatientMergeSide.LEFT].keepPatient || this.options[PatientMergeSide.RIGHT].keepPatient);
    }

    // formats and returns patient address depending on their data
    displayPatientAddress(side: PatientMergeSide): string {
        return this.generalUtils.displayItemsWithComma([
            this.options[side].patient?.country?.countryName || this.options[side].patient?.country,
            this.options[side].patient?.region?.name || this.options[side].patient?.region,
            this.options[side].patient?.address?.locality,
            this.options[side].patient?.address?.streetNumber
        ]);
    }

    isAddressVisible(side: PatientMergeSide): boolean {
        return this.generalUtils.isAnyValueTruthy([
            this.options[side].patient?.country,
            this.options[side].patient?.region,
            this.options[side].patient?.address?.locality,
            this.options[side].patient?.address?.streetNumber
        ]);
    }

    isKeepAppointmentsCheckboxVisible(side: PatientMergeSide): boolean {
        return ((!this.options[PatientMergeSide.LEFT].keepPatient && !this.options[PatientMergeSide.RIGHT].keepPatient)
            || this.options[side === PatientMergeSide.LEFT ? PatientMergeSide.RIGHT : PatientMergeSide.LEFT].keepPatient);
    }

    // searches for appointments according to pagination changes
    private getAppointmentTableData(side: PatientMergeSide): void {
        let queryFilter: ODataQueryObjectType;
        queryFilter = this.patientMergeUtils.getAppointmentListQuery(this.options[side].appointmentsTablePagination.tableFilters);
        this.ngxLoader.start();
        this.appointmentProvider.getByUserInformation(queryFilter, false, false)
            .subscribe((appointments: { value: AppointmentType[], count: number }) => {
                this.options[side].appointments = appointments.value;
                this.options[side].appointmentsTablePagination.totalTableItems = appointments.count ?? 0;
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // checks if the change in the patient control should go through and make a request to back-end
    private isPatientChangeValid(patient: PatientType | PatientSearchResultType, side: PatientMergeSide): boolean {
        return !this.isPatientSameAsOtherSide(patient, side) && !this.isPatientRemoved(patient, side);
    }

    // if a patient was selected, checks that it isn't the same as the one selected on the other side
    private isPatientSameAsOtherSide(patient: PatientType | PatientSearchResultType, side: PatientMergeSide): boolean {
        const otherSide: PatientMergeSide = side === PatientMergeSide.LEFT ? PatientMergeSide.RIGHT : PatientMergeSide.LEFT;
        if (!patient) {
            return false;
        }
        if (patient.id !== this.options[otherSide].patient?.id) {
            return false;
        }
        this.messagesService.error('toastr.error.selectedSamePatientOnBothSides');
        return true;
    }

    // if a patient was removed from the select control, updates the information displayed
    private isPatientRemoved(patient: PatientType | PatientSearchResultType, side: PatientMergeSide): boolean {
        if (!!patient) {
            return false;
        }
        this.clearPatientData(side);
        return true;
    }

    private clearPatientData(side: PatientMergeSide): void {
        this.options[side].patient = null;
        this.options[side].appointments = null;
        this.patientSearchTags[side] = [];
    }

    // checks if patient was selected on another page in patient-search component and if so fills left side with such data
    private fillLeftPatientData(): void {
        if (this.patientContextService.patient) {
            this.patientSearchTags[PatientMergeSide.LEFT] = [{
                id: this.patientContextService.patient.id,
                name: this.patientContextService.patient.fullName,
                disabled: false
            }];
            this.onPatientFilterValueChanged([this.patientContextService.patient], PatientMergeSide.LEFT);
        }
    }

}
