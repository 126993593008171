<div *ngIf="addressInformationForm" [formGroup]="addressInformationForm"
     class="form-container address-information-form-wrapper"
     [ngClass]="options?.containerStyles?.customClass ? options?.containerStyles?.customClass : 'form-container'"
     [style.grid-template-columns]="options?.containerStyles?.numberOfColumns ? 'repeat(' + options?.containerStyles?.numberOfColumns + ', 1fr)' : 'auto'"
     [style.grid-template-rows]="options?.containerStyles?.numberOfRows ? 'repeat(' + options?.containerStyles?.numberOfRows + ', 1fr)' : 'auto'"
     [style.grid-column-gap]="options?.containerStyles?.columnsGap ? options?.containerStyles?.columnsGap : '0'"
     [style.grid-row-gap]="options?.containerStyles?.rowsGap ? options?.containerStyles?.rowsGap : '0'">
    <!-- COUNTRY -->
    <div *ngIf="getInputByControlName(formControlNames.COUNTRY)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.COUNTRY)?.styles">
        <label [for]="formControlNames.COUNTRY" translate="label.country">
            <span [style.visibility]="getInputByControlName(formControlNames.COUNTRY)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <ng-select [id]="formControlNames.COUNTRY"
                   appendTo="body"
                   [formControlName]="formControlNames.COUNTRY"
                   class="custom-ng-select input-element"
                   notFoundText="{{'toastr.error.notFound' | translate}}"
                   (change)="onCountryValueChanged($event)"
                   [ngClass]="{
                   'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.COUNTRY),
                   'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.COUNTRY]?.disabled
                   }">
            <ng-option value=""><span>{{countryPlaceholder | translate}}</span></ng-option>
            <ng-option [value]="country" *ngFor="let country of createPatientUtils.clientIdentificationCountries">
                <span>{{country.countryName}}</span>
            </ng-option>
        </ng-select>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                      [controlName]="formControlNames.COUNTRY">
        </app-dynamic-form-input-error>
    </div>
    <!-- REGION -->
    <div *ngIf="getInputByControlName(formControlNames.REGION)?.visible"
         class="dynamic-input-container">
        <label [for]="formControlNames.REGION" translate="label.region">
            <span [style.visibility]="getInputByControlName(formControlNames.REGION)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div [ngClass]="{'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.REGION]?.disabled}">
            <ng-select [id]="formControlNames.REGION"
                       appendTo="body"
                       [formControlName]="formControlNames.REGION"
                       class="custom-ng-select input-element"
                       notFoundText="{{'toastr.error.notFound' | translate}}"
                       (change)="onRegionValueChanged($event)"
                       [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.REGION)}">
                <ng-option value=""><span>{{regionPlaceholder | translate}}</span></ng-option>
                <ng-option [value]="region" *ngFor="let region of regions">
                    <span>{{region.name}}</span>
                </ng-option>
            </ng-select>
        </div>
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                      [controlName]="formControlNames.REGION">
        </app-dynamic-form-input-error>
    </div>
    <!-- LOCALITY -->
    <div *ngIf="getInputByControlName(formControlNames.ADDRESS_LOCALITY)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.ADDRESS_LOCALITY)?.styles">
        <label [for]="formControlNames.ADDRESS_LOCALITY" translate="label.locality">
            <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_LOCALITY)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <div *ngIf="localities.length > 0"
             [ngClass]="{'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_LOCALITY]?.disabled}">
            <ng-select [id]="formControlNames.ADDRESS_LOCALITY"
                       appendTo="body"
                       [formControlName]="formControlNames.ADDRESS_LOCALITY"
                       class="custom-ng-select input-element"
                       notFoundText="{{'toastr.error.notFound' | translate}}"
                       (change)="onLocalityValueChanged($event)"
                       [ngClass]="{'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_LOCALITY)}">
                <ng-option value=""><span>{{localityPlaceholder | translate}}</span></ng-option>
                <ng-option [value]="locality" *ngFor="let locality of localities">
                    <span>{{locality?.name}}</span>
                </ng-option>
            </ng-select>
        </div>
        <input *ngIf="localities.length <= 0"
               [placeholder]="'label.locality' | translate"
               [formControlName]="formControlNames.ADDRESS_LOCALITY"
               class="dynamic-input input-element"
               [ngClass]="{
               'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_LOCALITY),
               'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_LOCALITY]?.disabled}">
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                      [controlName]="formControlNames.ADDRESS_LOCALITY">
        </app-dynamic-form-input-error>
    </div>
    <!-- STREET -->
    <div *ngIf="getInputByControlName(formControlNames.ADDRESS_STREET)?.visible"
         class="dynamic-input-container"
         [ngStyle]="getInputByControlName(formControlNames.ADDRESS_STREET)?.styles">
        <label [for]="formControlNames.ADDRESS_STREET" translate="label.addressTemplate.street">
            <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_STREET)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
        </label>
        <input type="text"
               [id]="formControlNames.ADDRESS_STREET"
               [placeholder]="('label.addressTemplate.street' | translate)"
               [formControlName]="formControlNames.ADDRESS_STREET"
               class="dynamic-input input-element"
               [ngClass]="{
               'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_STREET),
               'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_STREET]?.disabled}">
        <!-- Error message -->
        <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                      [controlName]="formControlNames.ADDRESS_STREET">
        </app-dynamic-form-input-error>
    </div>
    <div class="two-columns-container">
        <!-- STREET NUMBER -->
        <div *ngIf="getInputByControlName(formControlNames.ADDRESS_STREET_NUMBER)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.ADDRESS_STREET_NUMBER)?.styles"
             [style.grid-column]="getInputByControlName(formControlNames.ADDRESS_FLOOR)?.visible ? '1/2' : '1/3'">
            <label [for]="formControlNames.ADDRESS_STREET_NUMBER" translate="label.addressTemplate.streetNumber">
            <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_STREET_NUMBER)?.required ? 'visible' : 'hidden'"
                  class="input-required-asterisk">*</span>
            </label>
            <input type="text"
                   [id]="formControlNames.ADDRESS_STREET_NUMBER"
                   [placeholder]="('label.addressTemplate.streetNumber' | translate)"
                   [formControlName]="formControlNames.ADDRESS_STREET_NUMBER"
                   class="dynamic-input input-element"
                   [ngClass]="{
               'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_STREET_NUMBER),
               'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_STREET_NUMBER]?.disabled}">
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                          [controlName]="formControlNames.ADDRESS_STREET_NUMBER">
            </app-dynamic-form-input-error>
        </div>
        <!-- FLOOR -->
        <div *ngIf="getInputByControlName(formControlNames.ADDRESS_FLOOR)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.ADDRESS_FLOOR)?.styles"
             [style.grid-column]="getInputByControlName(formControlNames.ADDRESS_STREET_NUMBER)?.visible ? '2/3' : '1/3'">
            <label [for]="formControlNames.ADDRESS_FLOOR" translate="label.addressTemplate.floor">
                <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_FLOOR)?.required ? 'visible' : 'hidden'"
                      class="input-required-asterisk">*</span>
            </label>
            <input type="text"
                   [id]="formControlNames.ADDRESS_FLOOR"
                   [placeholder]="('label.addressTemplate.floor' | translate)"
                   [formControlName]="formControlNames.ADDRESS_FLOOR"
                   class="dynamic-input input-element"
                   [ngClass]="{
                   'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_FLOOR),
                   'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_FLOOR]?.disabled
                   }">
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                          [controlName]="formControlNames.ADDRESS_FLOOR">
            </app-dynamic-form-input-error>
        </div>
    </div>
    <div class="two-columns-container">
        <!-- APARTMENT NUMBER -->
        <div *ngIf="getInputByControlName(formControlNames.ADDRESS_APARTMENT_NUMBER)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.ADDRESS_APARTMENT_NUMBER)?.styles"
             [style.grid-column]="getInputByControlName(formControlNames.ADDRESS_ZIP_CODE)?.visible ? '1/2' : '1/3'">
            <label [for]="formControlNames.ADDRESS_APARTMENT_NUMBER" translate="label.addressTemplate.apartmentNumber">
                <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_APARTMENT_NUMBER)?.required ? 'visible' : 'hidden'"
                      class="input-required-asterisk">*</span>
            </label>
            <input type="text"
                   [id]="formControlNames.ADDRESS_APARTMENT_NUMBER"
                   [placeholder]="('label.addressTemplate.apartmentNumber' | translate)"
                   [formControlName]="formControlNames.ADDRESS_APARTMENT_NUMBER"
                   class="dynamic-input input-element"
                   [ngClass]="{
                   'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_APARTMENT_NUMBER),
                   'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_APARTMENT_NUMBER]?.disabled}">
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                          [controlName]="formControlNames.ADDRESS_APARTMENT_NUMBER">
            </app-dynamic-form-input-error>
        </div>
        <!-- ZIP CODE -->
        <div *ngIf="getInputByControlName(formControlNames.ADDRESS_ZIP_CODE)?.visible"
             class="dynamic-input-container"
             [ngStyle]="getInputByControlName(formControlNames.ADDRESS_ZIP_CODE)?.styles"
             [style.grid-column]="getInputByControlName(formControlNames.ADDRESS_APARTMENT_NUMBER)?.visible ? '2/3' : '1/3'">
            <label [for]="formControlNames.ADDRESS_ZIP_CODE" translate="label.addressTemplate.zipCode">
                <span [style.visibility]="getInputByControlName(formControlNames.ADDRESS_ZIP_CODE)?.required ? 'visible' : 'hidden'"
                      class="input-required-asterisk">*</span>
            </label>
            <input type="text"
                   [id]="formControlNames.ADDRESS_ZIP_CODE"
                   [placeholder]="('label.addressTemplate.zipCode' | translate)"
                   [formControlName]="formControlNames.ADDRESS_ZIP_CODE"
                   class="dynamic-input input-element"
                   [ngClass]="{
                   'input-element-has-error': dynamicFormInputService.hasError(addressInformationForm, formControlNames.ADDRESS_ZIP_CODE),
                   'cursor-not-allowed is-disabled': addressInformationForm?.controls[formControlNames.ADDRESS_ZIP_CODE]?.disabled}">
            <!-- Error message -->
            <app-dynamic-form-input-error [formGroup]="addressInformationForm"
                                          [controlName]="formControlNames.ADDRESS_ZIP_CODE">
            </app-dynamic-form-input-error>
        </div>
    </div>
</div>

