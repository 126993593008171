<div class="view-availability-component">
    <!--GENERAL INFORMATION-->
    <label translate="label.generalInformation" class="title"></label>

    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.resource"></td>
            <td translate="label.center2"></td>
            <td [translate]="availability?.status === AvailabilityStatusEnum.PENDING_SPLIT ? 'label.splitFrom' : 'label.validFrom'"></td>
            <td [translate]="availability?.status === AvailabilityStatusEnum.PENDING_SPLIT ? 'label.splitTo' : 'label.validTo'"></td>
            <td translate="label.repetition"></td>
            <td translate="label.tags"></td>
            <td translate="label.roomReservationNeededAbbreviation"
                ngbTooltip="{{'label.roomReservationNeeded'| translate}}" container="body"></td>
            <td translate="label.onTheFlyAbbreviation" ngbTooltip="{{'label.onTheFly'| translate}}"
                container="body"></td>
            <td [featureDisplay]="'availability-holiday-ignore'" translate="label.ignoreHolidaysAbbreviation" ngbTooltip="{{'label.ignoreHolidays'| translate}}"
                container="body"></td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{{availability?.resource?.name}}</td>
            <td>{{availability?.center?.name}}</td>
            <td>{{availability?.validFrom | fullDateFormatPipe}}</td>
            <td *ngIf="!availability.endOfDay">{{availability?.validTo | fullDateFormatPipe}}</td>
            <td *ngIf="availability.endOfDay">{{availability?.validTo | dateEndOfDay}}
                <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoAvailability'| translate}}"></i>
            </td>
            <td>{{availability?.repetition}} <span *ngIf="availability?.unit">{{'enum.repetitionInterval.' + availability?.unit | translate}}</span></td>
            <td>
                 <span *ngFor="let tag of availability?.tags; last as islast">
                        {{tag.name}}<span *ngIf="!islast">, </span>
                 </span>
            </td>
            <td>{{availability?.roomReservationNeeded  | yesNoPipe | translate}}</td>
            <td>{{availability?.onTheFly  | yesNoPipe | translate}}</td>
            <td [featureDisplay]="'availability-holiday-ignore'">{{availability?.ignoreHolidays  | yesNoPipe | translate}}</td>
        </tr>
        </tbody>
    </table>

    <!--ASSIGNED SERVICES-->
    <label translate="label.assignedServices" class="title"></label>
    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.services"></td>
            <td translate="label.durationShort" ngbTooltip="{{'label.duration'| translate}}" container="body"></td>
            <td translate="label.controlDurationShort" ngbTooltip="{{'label.controlDuration'| translate}}" container="body"
                [featureDisplay]="'control-patient-service-duration'"></td>
            <td translate="label.groupAppointmentsSlotsAbbreviation"
                ngbTooltip="{{'label.groupAppointmentsSlots'| translate}}" container="body"></td>
            <td translate="label.tags"></td>
            <td translate="label.coveragePlans"></td>
            <td translate="label.subServices"></td>
        </tr>
        </thead>
        <tbody *ngFor="let service of availability?.services">
        <tr>
            <td>
                <b>{{service.specialityName}}</b> {{service.name}}
                <span *ngIf="service.multiResourceBluePrintId"
                      ngbTooltip="{{'label.blueprint' | translate}}"
                      class="mr-1">
                    <i class="fa-regular fa-feather-pointed"></i>
                </span>
                <i *ngIf="service.onlineConsultation" class="fas fa-desktop ml-1" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
            </td>
            <td>
                <span *ngIf="service?.isMainResourceInBluePrint">
                    {{service.duration}}
                    <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>
                </span>
                <span *ngIf="!service?.isMainResourceInBluePrint" class="mr-1">
                    <app-show-availability-main-resource-duration
                            [serviceId]="service.serviceId"
                            [resourceId]="availability.resourceId">
                    </app-show-availability-main-resource-duration>
                </span>
            </td>
            <td [featureDisplay]="'control-patient-service-duration'">
                <span *ngIf="service?.isMainResourceInBluePrint">
                    {{service.controlDuration}}
                    <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>
                </span>
            </td>
            <td>{{service.groupAppointmentSlots}}</td>
            <td>
                 <span *ngFor="let tag of service?.tags; last as islast">
                        {{tag.name}}<span *ngIf="!islast">, </span>
                 </span>
            </td>
            <td>
                <span *ngFor="let coveragePlan of service?.coveragePlans; last as islast">
                        {{coveragePlan.name}}<span *ngIf="!islast">, </span>
                 </span>
            </td>
            <td>
                <span *ngFor="let subService of service?.subServices; last as islast">
                    {{subService.name}}
                    <span *ngIf="service?.isMainResourceInBluePrint"> | {{subService.duration}} <span translate="label.min"></span></span>
                    <span *ngIf="!islast">, </span>
                 </span>
            </td>
        </tr>
        </tbody>
    </table>

    <!--TIME SLOTS-->
    <label translate="label.timeSlots" class="title"></label>
    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.mon"></td>
            <td translate="label.tu"></td>
            <td translate="label.we"></td>
            <td translate="label.th"></td>
            <td translate="label.fr"></td>
            <td translate="label.sa"></td>
            <td translate="label.su"></td>
            <td translate="label.appointmentTypes"></td>
            <td translate="label.durationShort" ngbTooltip="{{'label.duration'| translate}}" container="body"></td>
            <td translate="label.quantityShort" ngbTooltip="{{'label.quantity'| translate}}" container="body"></td>
        </tr>
        </thead>
        <tbody *ngFor="let timeSlot of availability?.timeSlots">
        <tr>
            <td><span *ngIf="timeSlot.monday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.tuesday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.wednesday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.thursday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.friday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.saturday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td><span *ngIf="timeSlot.sunday">{{timeSlot.hourFrom | hourFromMinutes}}
                - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
            <td>
                <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                    {{appointmentType?.name}}</div>
            </td>
            <td>
                <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                    <span *ngIf="appointmentType.duration">{{appointmentType.duration}}</span>
                    <span *ngIf="!appointmentType.duration">&nbsp;-</span>
                </div>
            </td>
            <td>
                <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                    <span *ngIf="appointmentType.quantity">{{appointmentType.quantity}}</span>
                    <span *ngIf="!appointmentType.quantity">&nbsp;-</span>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
