import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ActivityPlanComponent} from './activity-plan.component';
import {AddActivityPlanComponent} from './add-activity-plan/add-activity-plan.component';
import {
    ActivityPlanDisplayCalendarComponent
} from './add-activity-plan/activity-plan-display-calendar/activity-plan-display-calendar.component';
import {
    ActivityPlanServiceRecurrenceModalComponent
} from './add-activity-plan/activity-plan-service-recurrence-modal/activity-plan-service-recurrence-modal.component';
import {
    DisplayScheduleOccurrencesModalComponent
} from './display-schedule-occurrences-modal/display-schedule-occurrences-modal.component';

@NgModule({
    declarations: [
        ActivityPlanComponent,
        AddActivityPlanComponent,
        ActivityPlanServiceRecurrenceModalComponent,
        ActivityPlanDisplayCalendarComponent,
        DisplayScheduleOccurrencesModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class ActivityPlanModule {
}
