import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbAccordion, NgbActiveModal, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {MessagesService} from '../../services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AvailabilityProvider, MainResourceServiceDurationType} from 'sked-base';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-main-resource-service-duration-modal',
    templateUrl: './main-resource-service-duration-modal.component.html',
    styleUrls: ['./main-resource-service-duration-modal.component.scss']
})
export class MainResourceServiceDurationModalComponent implements OnInit {
    @Input() serviceId: string;
    @Input() resourceId: string;
    @Input() subServiceId?: string;
    activeCardsIds: string[] = [];
    mainResourceServiceDurationList: MainResourceServiceDurationType[] = [];

    constructor(public activeModal: NgbActiveModal,
                private availabilityProvider: AvailabilityProvider,
                private messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService) {
    }

    ngOnInit(): void {
        this.loadMainResourceServiceDuration(this.serviceId, this.resourceId, this.subServiceId);
    }

    private loadMainResourceServiceDuration(serviceId: string, resourceId: string, subServiceId?: string) {
        this.ngxLoader.start();
        this.getMainResourceServiceOrSubServiceDuration(serviceId, resourceId, subServiceId)
            .pipe(take(1))
            .subscribe(response => {
                this.mainResourceServiceDurationList = response.value;
                for (let i = 0; i < this.mainResourceServiceDurationList.length; i++) {
                    this.activeCardsIds.push('ngb-panel-' + i);
                }
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }


    private getMainResourceServiceOrSubServiceDuration(serviceId: string, resourceId: string, subServiceId?: string) {
        return subServiceId ? this.availabilityProvider.getMainResourceSubServiceDurationsForSecondaryResources(serviceId, resourceId, subServiceId) :
            this.availabilityProvider.getMainResourceServiceDurationsForSecondaryResources(serviceId, resourceId);
    }

}
