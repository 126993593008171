import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AvailabilityChannelRuleProvider,
    AvailabilityChannelRuleType,
    ChannelDependentFiltersType,
    ChannelEnum, ChannelProvider,
    ChannelType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagDependentFiltersType,
    TagProvider
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {AvailabilityChannelRuleTagsType} from '../availability-channel-rule.types';
import {AvailabilityChannelRuleUtils} from '../availability-channel-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-availability-channel-rule',
    templateUrl: './create-availability-channel-rule.component.html',
    styleUrls: ['./create-availability-channel-rule.component.scss']
})
export class CreateAvailabilityChannelRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: AvailabilityChannelRuleType = {} as AvailabilityChannelRuleType;
    initialRule: AvailabilityChannelRuleType;
    totalStepsCount: number;
    constants = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.AvailabilityChannelRule);
    availabilityChannelRuleTags: AvailabilityChannelRuleTagsType;
    mainDependentFilters: {
        channel: ChannelDependentFiltersType,
        tags: TagDependentFiltersType,
    };
    initialChannel: ChannelType[] = [];
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;

    constructor(
        public availabilityChannelRuleUtils: AvailabilityChannelRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public tagProvider: TagProvider,
        public channelProvider: ChannelProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private availabilityChannelRuleProvider: AvailabilityChannelRuleProvider,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadDependentFilters();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: AvailabilityChannelRuleType): boolean {
        return !!(rule.availabilityChannelRuleTableId &&
            !lodash.isEmpty(rule.availabilityChannelRuleTable) && rule.name);
    }

    isInputStepValid(rule: AvailabilityChannelRuleType): boolean {
        return !!rule.channel && rule.tagId !== undefined;
    }

    isOutputStepValid(rule: AvailabilityChannelRuleType): boolean {
        return !!rule.message;
    }

    areAllStepsValid(rule: AvailabilityChannelRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.availabilityChannelRuleTable = ruleTable[0];
            this.ruleItem.availabilityChannelRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.availabilityChannelRuleTable = undefined;
            this.ruleItem.availabilityChannelRuleTableId = undefined;
        }
    }

    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedChannel(channel: ChannelType[]): void {
        this.ruleItem.channel = channel?.length > 0 ? channel[0].enumValue as ChannelEnum : undefined;
        this.initialChannel = channel?.length > 0 ? [channel[0]] : [];
    }

    onSelectedTag(entityName: string, tagList: TagType[]) {
        const tagBaseName = entityName ? entityName + 'Tag' : 'tag';
        if (tagList?.length > 0) {
            this.ruleItem[tagBaseName] = tagList[0];
            this.ruleItem[tagBaseName + 'Id'] = tagList[0].id;
        } else {
            this.ruleItem[tagBaseName] = undefined;
            this.ruleItem[tagBaseName + 'Id'] = undefined;
        }
    }

    // endregion Input methods

    // region Output methods
    // endregion Output methods

    // region Overview methods
    //
    saveRule(rule: AvailabilityChannelRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.availabilityChannelRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: AvailabilityChannelRuleType) {
        this.ngxLoader.start();
        const ruleToSend: AvailabilityChannelRuleType =
            this.availabilityChannelRuleUtils.mapRuleForServer(rule);
        this.availabilityChannelRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newAvailabilityChannelRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: AvailabilityChannelRuleType, newRule: AvailabilityChannelRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: AvailabilityChannelRuleType =
            this.availabilityChannelRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: AvailabilityChannelRuleType =
            this.availabilityChannelRuleUtils.mapRuleForServer(newRule);
        this.availabilityChannelRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.availabilityChannelRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        this.availabilityChannelRuleTags = this.availabilityChannelRuleUtils.getInitialAvailabilityChannelRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                this.initialChannel = [{name: this.ruleItem.channel} as any as ChannelType];
                this.availabilityChannelRuleTags = {
                    selectedTags: !!this.ruleItem.tag ? [this.ruleItem.tag] : [],
                } as AvailabilityChannelRuleTagsType;
            }
        } else {
            this.ruleItem = this.availabilityChannelRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            channel: this.availabilityChannelRuleUtils.getChannelDependentFilters(),
            tags: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
        };
    }
}
