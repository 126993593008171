<div class="outside-availability-booking-modal-container p-3" *ngIf="!!ngModels && !!nowInCenterTimezone">
    <div class="modal-header d-flex justify-content-center align-items-start">
        <span class="modal-title"
              translate="label.outsideAvailabilityBookingTitle">
        </span>
    </div>

    <div class="modal-body">
        <!-- Please select a date and time outside an availability -->
        <div class="row mb-3">
            <div class="col-12">
                <span class="info-message"
                      translate="label.outsideAvailabilityAppointmentInfo"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="date-and-time-picker-inputs-container justify-content-between">
                    <!-- Date -->
                    <div class="date-picker-input-container">
                        <label class="font-weight-bold font-14" translate="label.holidayTab.date">
                        </label>
                        <input class="date-picker-input"
                               name="outsideAvailabilityAppointmentDatePicker"
                               [(ngModel)]="ngModels.date"
                               (ngModelChange)="validateNgModels()"
                               [ngClass]="{ 'has-error': !ngModels.date}"
                               ngbDatepicker
                               #outsideAvailabilityAppointmentDatePicker="ngbDatepicker"
                               (click)="outsideAvailabilityAppointmentDatePicker.toggle()"
                               [minDate]="{year: nowInCenterTimezone.year(), month: nowInCenterTimezone.month() + 1, day: nowInCenterTimezone.date()}"
                               readonly>
                        <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                (click)="outsideAvailabilityAppointmentDatePicker.toggle()"
                                type="button">
                        </button>
                    </div>
                    <!-- Time -->
                    <div class="time-picker-input-container" *ngIf="ngModels.time">
                        <label translate="label.time"
                               class="font-weight-bold font-14"
                               [ngClass]="{'warning-color': !isLocalTimezoneSameAsCenterTimezone()}"
                               ngbTooltip="{{'label.localTimezoneIsDifferentThanCenterTimezone' | translate }}"
                               placement="bottom"
                               [disableTooltip]="isLocalTimezoneSameAsCenterTimezone()">
                            <i class="fas fa-exclamation-circle ml-1" *ngIf="!isLocalTimezoneSameAsCenterTimezone()"
                            ></i>
                        </label>
                        <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                              ngDefaultControl
                                              [ngClass]="{'input-element-has-error': !ngModels.time || !isSelectedDateTimeAfterNow}"
                                              [format]="24"
                                              [cancelBtnTmpl]="closeBtn"
                                              [confirmBtnTmpl]="confirmBtn"
                                              [(ngModel)]="ngModels.time"
                                              [defaultTime]="ngModels.time"
                                              (timeChanged)="validateNgModels()">
                        </ngx-timepicker-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Duration -->
        <div class="row">
            <div class="col-12">
                <div class="input-element-all-sides-border-with-icon">
                    <label class="font-weight-bold font-14 flex-grow-1 flex-basis-100" translate="label.duration">
                    </label>
                    <input type="number"
                           class="input-element-all-sides-border"
                           [ngClass]="{'input-element-has-error': !ngModels.duration}"
                           placeholder="{{'label.outsideAvailabilityBookingDurationPlaceholder' | translate}}"
                           required
                           [(ngModel)]='ngModels.duration'
                           (ngModelChange)="validateNgModels()"
                           min="1">
                    <span class="btn btn-outline-secondary input-element-icon"
                          [ngClass]="{'input-element-has-error': !ngModels.duration}">
                        <i class="fas fa-hourglass-half"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn close-btn"
                (click)="activeModal.dismiss()"
                translate="label.cancel"
        ></button>
        <button class="btn theme-btn"
                (click)="onOutsideAvailabilityBooking()"
                [disabled]="!isDataValid"
                translate="label.next"
        ></button>
    </div>
</div>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close">
        </button>
    </div>
</ng-template>
