<div class="resource-swap-appointment-list-container ml-0 mr-0 custom-scrollbar">
    <div>
        <ng-container>
            <!-- Appointment list -->
            <div class="mx-2 my-1 resource-swap-appointment-container"
                 *ngFor="let appointment of resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentList; let index = index">
                <div class="d-flex no-block align-items-center"
                     (appResized)="listenToAppointmentCardContainerHeightChanges($event)"
                     [ngClass]="{'disabled-appointment': isAppointmentDisabled(appointment.status)}"
                     ngbPopover="{{'label.resourceCannotBeSwapped'| translate}} {{'enum.appointmentStatus.' + appointment.status | translate}}"
                     placement="bottom"
                     popoverClass="popover-shadow"
                     triggers="mouseenter:mouseleave"
                     [disablePopover]="!isAppointmentDisabled(appointment.status)">
                    <input class="resource-swap-appointment-list-checkbox ml-2 mr-2" type="checkbox"
                           [disabled]="shouldDisableEverything"
                           [checked]="isAppointmentInputAlreadyChecked(appointment.id)"
                           (change)="onAppointmentInputChanged(appointment.id, $event)">
                    <app-appointment-card [options]="{appointment: appointment, displayPatientInformation: true}"
                                          (appointmentCardAction)="onAppointmentItemAction($event, index)"></app-appointment-card>
                </div>
                <div class="horizontal-line"></div>
            </div>
        </ng-container>
    </div>
    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="resource-swap-appointment-list-footer sticky-element" *ngIf="resourceSwapUtils.swapResourcesAppointmentListItemOptions.totalSwapResourcesAppointmentItems > 0">
        <div class="pagination-component px-2 py-2 text-high-contrast">
            <ngb-pagination (pageChange)="onChangePagination($event)"
                            [(page)]="resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters.pageFilters.currentPage"
                            [boundaryLinks]="true"
                            [disabled]="shouldDisableEverything"
                            [collectionSize]="resourceSwapUtils.swapResourcesAppointmentListItemOptions.totalSwapResourcesAppointmentItems"
                            [maxSize]="5"
                            [pageSize]="resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters.pageFilters.itemsPerPage">
            </ngb-pagination>
            <div class="btn-group items-per-page-group">
                <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
                <span class="btn-group ml-2">
                    <span (click)="onPageChange()"
                          [ngClass]="{'show': resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown, 'disabled': shouldDisableEverything}"
                          aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{resourceSwapUtils.swapResourcesAppointmentListItemOptions.swapResourcesAppointmentListRequestFilters.pageFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': resourceSwapUtils.swapResourcesAppointmentListItemOptions.showItemsPerPageDropdown}"
                   class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)"
                     *ngFor="let page of resourceSwapUtils.swapResourcesAppointmentListItemOptions.itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
            </div>
        </div>
        <div class="row px-2">
            <div class="col-12">
                <button type="button" class="btn theme-btn border-0 ml-2 float-right"
                        translate="label.searchForReplacements"
                        (click)="onSearchForReplacements()"
                        [disabled]="resourceSwapUtils.appointmentsIds.length <= 0 || shouldDisableEverything">
                </button>
            </div>
        </div>
    </div>
</div>
