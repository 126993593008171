import {Component, OnInit, OnDestroy, EventEmitter, AfterViewInit} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    AppointmentTypeDefinitionProvider,
    AppointmentTypeDefinitionType,
    AppointmentTypeProvider,
    ResourceProvider,
    CenterProvider,
    GenericFilterOptionsType,
    GenericFilterResultType,
    FilterWrapperNameEnum,
    ConfirmDeleteModalService,
    EntityTypeEnum
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AppointmentTypeDefinitionMdUtils} from './appointment-type-definition-md-util';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-appointment-type-definition-md',
    templateUrl: './appointment-type-definition-md.component.html',
    styleUrls: ['./appointment-type-definition-md.component.scss']
})
export class AppointmentTypeDefinitionMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[];
    appointmentTypeDefinitionMdList: any = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;

    updateFiltersValue: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();

    APPOINTMENT_TYPE_DEFINITION = 'AppointmentTypeDefinition';
    constants = constants;

    private searchAppointmentTypeDefinitionChanged = new Subject<string>();
    private filterWrapperValues: GenericFilterResultType[] = [];
    private appointmentTypeButtonName = 'label.appointmentType';
    private resourceButtonName = 'label.resource';
    private centerButtonName = 'label.center2';
    VIEW_ACTIVITY_ENABLED = false;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public appointmentTypeDefinitionMdUtils: AppointmentTypeDefinitionMdUtils,
        public appointmentTypeDefinitionProvider: AppointmentTypeDefinitionProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public appointmentTypeProvider: AppointmentTypeProvider,
        public resourceProvider: ResourceProvider,
        public centerProvider: CenterProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('AppointmentTypeDefinitionRead');
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();
        this.loadFilters();
        this.getAppointmentTypeDefinitionMdData();
        this.subscribeSearchByAppointmentTypeDefinitionChanged();
    }

    ngOnDestroy(): void {
    }

    onClearFilters() {
        // Clear filter wrapper values
        this.updateFiltersValue.next([]);
        // Load filters and data
        setTimeout(() => {
            this.loadFilters();
            this.getAppointmentTypeDefinitionMdData();
        });
    }

    // method to navigate create AppointmentTypeDefinition
    createAppointmentTypeDefinition(): void {
        this.router.navigate(['/createAppointmentTypeDefinition']);
    }

    editAppointmentTypeDefinition(appointmentTypeDefinition: AppointmentTypeDefinitionType) {
        this.router.navigate(['/createAppointmentTypeDefinition'], {
            state: {
                appointmentTypeDefinition,
                action: constants.EDIT
            }
        });
    }

    viewAppointmentTypeDefinition(appointmentTypeDefinition: AppointmentTypeDefinitionType) {
        this.router.navigate(['/createAppointmentTypeDefinition'], {
            state: {
                appointmentTypeDefinition,
                action: constants.VIEW
            }
        });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.appointmentTypeDefinitionMdUtils.tableFilters.currentPage = page;
        this.getAppointmentTypeDefinitionMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.appointmentTypeDefinitionMdUtils.tableFilters.currentPage = 1;
        this.appointmentTypeDefinitionMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getAppointmentTypeDefinitionMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.appointmentTypeDefinitionMdUtils.tableFilters.orderBy[selectedItem];
        this.getAppointmentTypeDefinitionMdData(false);
    }

    displayConfirmDeleteItemModal(appointmentTypeDefinitionId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.appointmentTypeDefinitionProvider, appointmentTypeDefinitionId)
            .result
            .then(() => {
            }, (response) => {
                if (response) {
                    this.deleteItem(appointmentTypeDefinitionId);
                }
            });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onFilterWrapperValueChanged(filtersValue: GenericFilterResultType[]): void {
        this.filterWrapperValues = filtersValue;
        for (const filterOpt of this.appointmentTypeDefinitionMdUtils.filterWrapperOptions) {
            const foundFilter = lodash.find(this.filterWrapperValues, {name: filterOpt.name});
            if (foundFilter) {
                this.appointmentTypeDefinitionMdUtils.tableFilters.filter[foundFilter.name] = foundFilter.value;
            } else { // filter was deleted
                this.appointmentTypeDefinitionMdUtils.tableFilters.filter[filterOpt.name] = undefined;
            }
        }
        this.getAppointmentTypeDefinitionMdData();
    }

    isOvercapacity(appointmentTypeDefinitionItem): boolean {
        return appointmentTypeDefinitionItem.appointmentType.system &&
            !appointmentTypeDefinitionItem.appointmentType.consumesPlannedCapacity;
    }

    // function to get AppointmentTypeDefinition data
    private getAppointmentTypeDefinitionMdData(includeCount: boolean = true) {
        const queryFilter = this.appointmentTypeDefinitionMdUtils.getQueryFilterForAppointmentTypeDefinitionMD(this.appointmentTypeDefinitionMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.appointmentTypeDefinitionProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.appointmentTypeDefinitionMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // method for deleting table item
    private deleteItem(id) {
        this.appointmentTypeDefinitionMdUtils.tableFilters = this.appointmentTypeDefinitionMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.appointmentTypeDefinitionProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getAppointmentTypeDefinitionMdData();
                this.messagesService.success('toastr.success.appointmentTypeDefinitionDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByAppointmentTypeDefinitionChanged() {
        const self = this;
        this.searchAppointmentTypeDefinitionChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.appointmentTypeDefinitionMdUtils.tableFilters.filter.name = searchValue;
                self.getAppointmentTypeDefinitionMdData();
            });
    }

    private getFilterWrapperOptions = (): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.appointmentTypeDefinitionMdUtils.getAppointmentTypeDependentFilters(),
                disableFilter: false,
                label: this.appointmentTypeButtonName,
                name: FilterWrapperNameEnum.appointmentType,
                parentFilterValue: undefined,
                providerInstance: this.appointmentTypeProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.appointmentTypeDefinitionMdUtils.getResourceDependentFilters(),
                disableFilter: false,
                label: this.resourceButtonName,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.appointmentTypeDefinitionMdUtils.getLocationDependentFilters(),
                disableFilter: false,
                label: this.centerButtonName,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            }
        ]
    )

    private loadFilters(): void {
        // If not coming from route createMd, reset filters to initial values
        if (this.previousRouteService.getPreviousUrl() !== '/createAppointmentTypeDefinition') {
            this.appointmentTypeDefinitionMdUtils.tableFilters = this.appointmentTypeDefinitionMdUtils.getInitialTableFilter();
            this.appointmentTypeDefinitionMdUtils.filterWrapperOptions = this.getFilterWrapperOptions();
        } else {
            this.appointmentTypeDefinitionMdUtils.filterWrapperOptions = lodash.cloneDeep(this.appointmentTypeDefinitionMdUtils.filterWrapperOptions)?.map((filterOptions) => ({
                ...filterOptions,
                ...(!!this.appointmentTypeDefinitionMdUtils.tableFilters?.filter && !!this.appointmentTypeDefinitionMdUtils.tableFilters.filter[filterOptions.name] ? {
                    parentFilterValue: {
                        name: filterOptions.name,
                        value: this.appointmentTypeDefinitionMdUtils.tableFilters.filter[filterOptions.name],
                    }
                } : {}),
            }));
        }
    }
}
