import {Component, EventEmitter, OnInit} from '@angular/core';
import {AppointmentListUtils} from '../appointment-list.utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {DateRangeResponseType, DateRangeType, FormValidationType, JobProvider} from 'sked-base';

@Component({
    selector: 'app-appointment-list-export-modal',
    templateUrl: './appointment-list-export-modal.component.html',
    styleUrls: ['./appointment-list-export-modal.component.scss']
})
export class AppointmentListExportModalComponent implements OnInit {
    dateRangeValidation: FormValidationType;
    updateDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();

    constructor(public appointmentListUtils: AppointmentListUtils,
                private activeModal: NgbActiveModal,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private jobProvider: JobProvider) {
    }

    ngOnInit(): void {
        this.loadOptionsForDateRange();
    }

    closeModal() {
        this.activeModal.dismiss('close');
    }

    onDateRangeChanged(dateRange: DateRangeResponseType) {
        this.appointmentListUtils.dateRangeOptions.fromDate = dateRange.fromDate;
        this.appointmentListUtils.dateRangeOptions.toDate = dateRange.toDate;
        this.validateDateRange(dateRange.isValid);
    }

    onGenerateExportAppointments() {
        const queryFilter = this.appointmentListUtils.getQueryFilterForExportAppointmentsPdf();
        this.ngxLoader.start();
        this.jobProvider.exportAppointments(queryFilter).subscribe(() => {
            this.messagesService.success('label.generateExportWaitMessageGoToExports');
            this.closeModal();
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private loadOptionsForDateRange() {
        this.appointmentListUtils.dateRangeOptions = this.appointmentListUtils.getExportModalInitialDateRangeOptions();
        const {fromDate, toDate} = this.appointmentListUtils.dateRangeOptions;
        this.updateDateRangeValue.next({
            fromDate,
            toDate
        } as DateRangeType);
        this.validateDateRange();
    }

    private validateDateRange(isValidInner: boolean = true) {
        const {fromDate, toDate} = this.appointmentListUtils.dateRangeOptions;
        this.dateRangeValidation = {
            isValid: isValidInner && !!fromDate && !!toDate,
            errorMessage: ''
        };
    }
}
