<div class="multi-appointment-booking-wrapper pb-1">
    <div class="error-message d-flex mb-2 p-2" *ngIf="!displayMultiAppointmentBooking">
        <i class="fas fa-exclamation-circle ml-3 mr-2 mt-1"></i>
        <span translate="toastr.error.noOrganizationalRoleAssignedForTeam"></span>
    </div>

    <div class="patient-search-component-container p-2" *ngIf="displayMultiAppointmentBooking">
        <sbase-patient-search [options]="patientSearchOptions"
                              (onSelectedPatientOutput)="onSelectedPatient($event)"
                              (patientSearchActions)="patientContextService.onPatientSearchActions($event)">
        </sbase-patient-search>
    </div>

    <div class="container-fluid multi-appointment-booking-wrapper-inner pb-0" *ngIf="displayMultiAppointmentBooking">
        <div class="d-flex justify-content-between">
        </div>
        <div class="row slots-filters-container">
            <sbase-slots-filters
                    [options]="slotsFiltersOptions"
                    [enableCollapse]="true"
                    [updatePatientExternalEmitter]="slotsFiltersExternalUpdatePatientEmitter"
                    (search)="onSearch($event)"
                    (isDataLoaded)="filtersAreLoaded($event)">
            </sbase-slots-filters>
        </div>

        <div class="row slots-calendar-and-results-container">
            <div class="col-custom-4 col-custom-3 px-0 pt-1 slots-calendar-and-local-filters-column custom-scrollbar"
                 [ngClass]="{'calendar-collapsed': multiAppointmentBookingMdUtils.isCalendarCollapsed}"
                 [attr.aria-expanded]="!multiAppointmentBookingMdUtils.isCalendarCollapsed">
                <div class="calendar-and-local-filters-header"
                     (click)="onCalendarCollapse()"
                     [ngClass]="{'before-search': !multiAppointmentBookingMdUtils.slotsCalendarOptions.areOptionsAfterSearch}">
                    <span translate="label.calendarAndFilters"
                          class="calendar-and-local-filters-title"
                          [ngClass]="{'vertical-text': multiAppointmentBookingMdUtils.isCalendarCollapsed}"
                    ></span>
                    <button class="calendar-filters-collapse-button"
                            [ngClass]="{'before-search': !multiAppointmentBookingMdUtils.slotsCalendarOptions.areOptionsAfterSearch}">
                        <span *ngIf="!multiAppointmentBookingMdUtils.isCalendarCollapsed"><</span>
                        <span *ngIf="multiAppointmentBookingMdUtils.isCalendarCollapsed">></span>
                    </button>
                </div>
                <app-mab-slots-calendar
                        (selectValue)="onSlotsCalendarSelectValue()"
                        [ngbCollapse]="multiAppointmentBookingMdUtils.isCalendarCollapsed"
                        (continueSearching)="onSlotsCalendarContinueSearching()">
                </app-mab-slots-calendar>
                <app-mab-slots-local-filters *ngIf="displayLocalFilters"
                                             (localFiltersSelected)="onSelectedLocalFilters()"
                                             [ngbCollapse]="multiAppointmentBookingMdUtils.isCalendarCollapsed">
                </app-mab-slots-local-filters>
            </div>
            <div class="col-custom-8 col-custom-9 container-fluid pr-0 pl-1 pb-2 slots-results-outer-container"
                 [ngClass]="{'calendar-collapsed': multiAppointmentBookingMdUtils.isCalendarCollapsed}">
                <div class="reserved-slots-wrapper-row">
                    <app-mab-reserved-slots
                            (bookAppointments)="onBookAppointments($event)"
                    ></app-mab-reserved-slots>
                </div>
                <div class="slots-lists-wrapper-row">
                    <app-mab-slots-lists-wrapper
                            (selectValue)="onSelectSlot($event)"
                            (slotsListRemoved)="onSlotsListRemoved($event)"
                    ></app-mab-slots-lists-wrapper>
                </div>
            </div>

        </div>
    </div>
</div>




