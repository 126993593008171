import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {GeneralUtils} from "../../utils/general.utils";
import {DetailsModalComponent} from "./details-modal/details-modal.component";

@Component({
    selector: 'app-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {
    @Input() appointmentId: string;

    constructor(private generalUtils: GeneralUtils,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    openAppointmentDetails() {
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'appointment-details';

        const modalRef = this.modalService
            .open(DetailsModalComponent, modalOptions);

        modalRef.componentInstance.appointmentId = this.appointmentId;

        modalRef.result.then(() => {
        }, (onClose) => {
            //x button was clicked, previous values needs to be displayed next time
        });
    }

}
