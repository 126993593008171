import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    MultiAppointmentBookingLocalFilterTypeEnum,
    SlotsLocalFiltersOptionsType
} from '../multi-appointment-booking.types';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import tippy, {Instance} from 'tippy.js';

@Component({
    selector: 'app-mab-slots-local-filters',
    templateUrl: './mab-slots-local-filters.component.html',
    styleUrls: ['./mab-slots-local-filters.component.scss']
})
export class MabSlotsLocalFiltersComponent implements OnInit, AfterViewInit {
    @Output() localFiltersSelected: EventEmitter<SlotsLocalFiltersOptionsType> = new EventEmitter();
    displaySelfPayer = true;
    tippyInstances: { [key: string]: Instance } = {};
    multiAppointmentBookingLocalFilterTypeEnum = MultiAppointmentBookingLocalFilterTypeEnum;

    constructor(public multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils) {
    }

    ngOnInit(): void {
        // At the moment we always display this field
        // this.displaySelfPayer = this.multiAppointmentBookingMdUtils.isSelfPayerSystemConfigActive();
        if (this.displaySelfPayer && this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selfPayer) {
            this.onLocalFiltersChanged();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.makeTippyPopovers();
        });
    }

    onLocalFiltersChanged(filterType?: MultiAppointmentBookingLocalFilterTypeEnum): void {
        if (filterType === MultiAppointmentBookingLocalFilterTypeEnum.AppointmentType) {
            this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.appointmentTypeChangedByUser = true;
            // Sanitize select value
            if (this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions?.selectedAppointmentType?.hasOwnProperty('$ngOptionValue')) {
                this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selectedAppointmentType = undefined;
            }
        }
        this.localFiltersSelected.emit(this.multiAppointmentBookingMdUtils.slotsLocalFiltersOptions);
    }

    private makeTippyPopovers() {
        this.makeTippyPopover('selfPayer', 'slots-local-filters-self-payer-element', 'slots-local-filters-self-payer-popover');
        this.makeTippyPopover('amFlag', 'slots-local-filters-am-element', 'slots-local-filters-am-popover');
        this.makeTippyPopover('pmFlag', 'slots-local-filters-pm-element', 'slots-local-filters-pm-popover');
    }

    private makeTippyPopover(tippyInstanceKey: string, elementClassName: string, popoverClassName: string) {
        const element = document.getElementsByClassName(elementClassName)[0];
        const popover = document.getElementsByClassName(popoverClassName)[0];
        if (!!element && !!popover) {
            this.tippyInstances[tippyInstanceKey] = tippy(element, {
                content: popover,
                placement: 'top',
                theme: 'light-border',
                animation: false,
                popperOptions: {strategy: 'fixed'}
            });
        }
    }
}
