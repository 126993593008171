import {Injectable} from '@angular/core';
import {
    ObjectDetailRuleType,
    Expand,
    ODataQueryObjectType,
    ChannelDependentFiltersType,
    ObjectDetailDependentFiltersType,
    ServiceDependentFiltersType,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ObjectDetailRuleAppliesToType
} from 'sked-base';
import * as lodash from 'lodash';
import {
    CenterModalFilterOptionsType,
    ChannelModalFilterOptionsType,
    ObjectDetailModalFilterOptionsType,
    ObjectDetailRuleAppliesToEnum,
    ObjectDetailRuleModalFiltersType,
    ObjectDetailRuleStateType,
    ObjectDetailRuleTagsType,
    ResourceModalFilterOptionsType,
    ServiceModalFilterOptionsType,
    TagModalFilterOptionsType,
} from './object-detail-rule.types';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../../data-model/general.type';
import {RulesTableHeaderOptions} from '../../rules.types';
import {TranslatedLanguageService} from '../../../../shared/services/translated-language.service';

@Injectable({
    providedIn: 'root'
})
export class ObjectDetailRuleUtils {
    overviewState: ObjectDetailRuleStateType = {};

    constructor(
        private generalUtils: GeneralUtils,
        private translatedLanguageService: TranslatedLanguageService,
    ) {
    }

    getRuleTableHeaderOptions(): RulesTableHeaderOptions[] {
        return [{
            class: 'name-column',
            label: 'label.ruleTable',
            hasSort: true,
            onSortByProperty: 'objectDetailRuleTable',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.active',
            hasSort: true,
            onSortByProperty: 'active',
        } as RulesTableHeaderOptions, {
            class: 'name-center',
            label: 'label.rule',
            hasSort: true,
            onSortByProperty: 'name',
        } as RulesTableHeaderOptions, {
            class: 'id-column',
            label: 'label.service2',
            hasSort: true,
            onSortByProperty: 'service',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.center2',
            hasSort: true,
            onSortByProperty: 'center',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.resource',
            hasSort: true,
            onSortByProperty: 'resource',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.channel',
            hasSort: true,
            onSortByProperty: 'channel',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.tag',
            hasSort: true,
            onSortByProperty: 'tag',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.objectDetail',
            hasSort: true,
            onSortByProperty: 'objectDetail',
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.appliesTo',
            hasSort: false,
        } as RulesTableHeaderOptions, {
            class: 'text-center',
            label: 'label.action',
            hasSort: false,
        } as RulesTableHeaderOptions];
    }

    getQueryFilter(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            select: ['Id', 'ObjectDetailRuleTableId', 'Name', 'Active', 'Priority', 'ServiceId', 'CenterId', 'ResourceId', 'TagId', 'Channel', 'ObjectDetailId', 'AppliesTo', 'RowVersion'],
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'objectDetailRuleTableId':
                    case 'serviceId':
                    case 'centerId':
                    case 'resourceId':
                    case 'tagId':
                    case 'objectDetailId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'channel': {
                        filterQuery[lodash.upperFirst(item)] = {eq: filter[item]};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'objectDetailRuleTable' || item === 'center' || item === 'service'
                    || item === 'resource' || item === 'tag' || item === 'objectDetail'
                ) {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        // If the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandFilter(): Expand {
        const EXPAND_TAG_SELECT = ['Id', 'Name', 'ScopedNone', 'ScopedResource', 'ScopedPatient', 'EnabledForRules'];
        return {
            Tag: {select: EXPAND_TAG_SELECT},
            Service: {select: ['Id', 'Name', 'SpecialityId']},
            Center: {select: ['Id', 'Name']},
            Resource: {select: ['Id', 'Name']},
            ObjectDetail: {select: ['Id', 'Name']},
            ObjectDetailRuleTable: {select: ['Id', 'Name']},
        };
    }

    getInitialRule(): ObjectDetailRuleType {
        return {
            name: undefined,
            active: false,
            serviceId: undefined,
            centerId: undefined,
            resourceId: undefined,
            channel: undefined,
            tagId: undefined,
            objectDetailId: undefined,
            objectDetailRuleTableId: undefined,
            appliesTo: {
                center: false,
                resource: false,
                service: false
            }
        } as ObjectDetailRuleType;
    }

    getInitialModalFilters(): ObjectDetailRuleModalFiltersType {
        return {
            serviceOptions: {
                ngModel: undefined
            } as ServiceModalFilterOptionsType,
            centerOptions: {
                ngModel: undefined
            } as CenterModalFilterOptionsType,
            resourceOptions: {
                ngModel: undefined
            } as ResourceModalFilterOptionsType,
            channelOptions: {
                ngModel: undefined
            } as ChannelModalFilterOptionsType,
            tagOptions: {
                ngModel: undefined
            } as TagModalFilterOptionsType,
            objectDetailOptions: {
                ngModel: undefined
            } as ObjectDetailModalFilterOptionsType,
            areFiltersActive: false,
        } as ObjectDetailRuleModalFiltersType;
    }

    isAnyFilterActive(modalFilters: ObjectDetailRuleModalFiltersType): boolean {
        // We take all the fields from modal filters except activeFilters
        const {areFiltersActive, ...initialModalFilters} = this.getInitialModalFilters();
        const {areFiltersActive: currentActiveFilters, ...currentModalFilters} = modalFilters;
        // If initial modal filters are different from the current modal filters then
        // surely some filters are active, so we return true
        return !lodash.isEqual(initialModalFilters, currentModalFilters);
    }

    mapRuleForServer(ruleToMap: ObjectDetailRuleType): ObjectDetailRuleType {
        return lodash.cloneDeep(ruleToMap);
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getInitialObjectDetailRuleTags(): ObjectDetailRuleTagsType {
        return {
            selectedTags: []
        } as ObjectDetailRuleTagsType;
    }

    getChannelDependentFilters(): ChannelDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            countryCode: this.translatedLanguageService.translatedLanguage,
        };
    }

    getObjectDetailDependentFilters(): ObjectDetailDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
        };
    }

    getServiceDependentFilters(): ServiceDependentFiltersType {
        return {
            count: true,
            exclusionList: [],
            includeAvailabilities: false,
            includeChannel: false,
            onlyAssignedToLocationsOfUser: false,
            searchPhrase: '',
            patientId: null,
            locationId: null,
            regionId: null,
            resourceId: null,
            areaId: null,
            coveragePlanId: null,
        }
    }

    getLocationDependentFilters(): LocationDependentFiltersType {
        return {
            count: true,
            exclusionList: [],
            includeAvailabilities: false,
            onlyAssignedToUser: false,
            searchPhrase: '',
            isRequestAllowed: null,
            locationId: null,
            regionId: null,
            resourceId: null,
            serviceId: null,
            areaId: null,
            useOnlyAssignedToUser: null,
        }
    }

    getResourceDependentFilters(): ResourceDependentFiltersType {
        return {
            count: true,
            exclusionList: [],
            includeAvailabilities: false,
            includeSelfPayer: false,
            onlyAssignedToLocationsOfUser: false,
            onlyAssignedToUser: false,
            onlyDirectlyBookable: false,
            resourceTypeExclusionList: [],
            searchPhrase: '',
            serviceId: null,
            locationId: null,
            coveragePlanId: null,
            areaId: null,
            resourceTypeId: null,
        }
    }

    // Searches for option in appliesTo object that is set to true and returns the name of the property
    getKeyOfAppliesToValue(appliesTo: ObjectDetailRuleAppliesToType): ObjectDetailRuleAppliesToEnum {
        const KEY_VALUE_PAIR = !!appliesTo ? Object.entries(appliesTo).find(([key, value]) => !!value) : [];
        return KEY_VALUE_PAIR.length > 0 ? KEY_VALUE_PAIR[0] as ObjectDetailRuleAppliesToEnum : null;
    }
}
