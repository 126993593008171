<div class="activity-plan-container overview-screen-md d-flex no-block flex-column">
    <div class="patient-search-component-container p-2">
        <sbase-patient-search
                [options]="patientContextService.getPatientSearchOptions()"
                (onSelectedPatientOutput)="patientContextService.onSelectedPatient($event)"
                (patientSearchActions)="patientContextService.onPatientSearchActions($event)">
        </sbase-patient-search>
    </div>
    <div class="activity-plan-inner-container pt-2 px-2 pb-0 flex-grow-1 d-flex no-block flex-column">
        <!-- Title and buttons row -->
        <div class="d-flex no-block justify-content-between flex-wrap">
            <h3 translate="label.activityPlansAssignedToPatient"></h3>
            <div class="header-buttons-container d-flex no-block flex-row align-items-start ml-auto">
                <!-- Refresh button -->
                <div class="btn theme-btn float-right refresh-button"
                     ngbPopover="{{'label.refresh'| translate}}"
                     placement="left"
                     popoverClass="popover-shadow"
                     triggers="mouseenter:mouseleave"
                     (click)="refreshData()">
                    <i class="fas fa-sync-alt"></i>
                </div>

                <!-- Add new item button -->
                <button type="button" class="btn theme-alt-btn border-0 add-item-button"
                        [activityDisplay]="ACTIVITY_PLAN+'Create'"
                        (click)="addActivityPlan()"
                        translate="label.addActivityPlan">
                </button>
            </div>
        </div>

        <!-- In case patient has no activity plans created -->
        <div class="no-item-container mt-2 flex-grow-1 d-flex no-block align-items-center justify-content-center" *ngIf="totalTableItems === 0">
            <div class="no-item-message">
                <i class="fas fa-info-circle mr-3"></i>
                <span translate="label.noActivityPlansCreatedForThisPatient"></span>
            </div>
        </div>

        <div class="table-responsive overview-table-container mt-2 flex-grow-1" *ngIf="totalTableItems > 0">
            <table class="table">
                <thead>
                <tr>
                    <th class="id-column" translate="label.templateName"></th>
                    <th class="name-column" translate="label.services"></th>
                    <th class="text-center" translate="label.action"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let activityPlan of activityPlanList"
                    [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                    sbaseMarkTableRowOnHover
                    (click)="viewActivityPlan(activityPlan)">
                    <td class="id-column">
                    <span *ngIf="!!activityPlan?.activityPlanTemplate?.name">
                        {{activityPlan?.activityPlanTemplate?.name}}
                    </span>
                        <span *ngIf="!activityPlan?.activityPlanTemplate?.name" translate="label.activityPlanWithoutTemplate"></span>
                    </td>
                    <td class="name-column">
                        <div *ngFor="let service of activityPlan?.services | slice:0:3">
                            <!-- field specialityName is added in addSpecialityNameToServices (utils method) -->
                            <span class="font-weight-bold" *ngIf="service.service.specialityName">{{service.service.specialityName}} </span>
                            <span *ngIf="service.service.name">{{service.service.name}}</span>
                            <i *ngIf="service.service.onlineConsultation" class="fas fa-desktop ml-1" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                        </div>
                        <ng-template #servicesContent>
                            <div class="table-tooltip-container px-2 py-1">
                                <div *ngFor="let service of activityPlan?.services">
                                    <!-- field specialityName is added in addSpecialityNameToServices (utils method) -->
                                    <span class="font-weight-bold" *ngIf="service.service.specialityName">{{service.service.specialityName}} </span>
                                    <span *ngIf="service.service.name">{{service.service.name}}</span>
                                    <i *ngIf="service.service.onlineConsultation" class="fas fa-desktop ml-1" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                                </div>
                            </div>
                        </ng-template>
                        <div *ngIf="activityPlan?.services && activityPlan?.services.length > 3" (click)="$event.stopPropagation()">
                            <div [ngbPopover]="servicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                            </div>
                        </div>
                    </td>
                    <td class="text-center" (click)="$event.stopPropagation()">
                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                popoverClass="popover-shadow">
                            <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <ng-template #popContent>
                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                <li>
                                    <button type="button" class="btn" translate="label.view"
                                            (click)="viewActivityPlan(activityPlan)"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.edit"
                                            (click)="editActivityPlan(activityPlan)"
                                            [activityDisplay]="ACTIVITY_PLAN+'Update'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.occurrences"
                                            (click)="scheduleOccurrencesAction(activityPlan)"
                                            [activityDisplay]="ACTIVITY_PLAN+'Read'"></button>
                                </li>
                                <li>
                                    <button type="button" class="btn" translate="label.delete"
                                            (click)="displayConfirmDeleteItemModal(activityPlan.id)"
                                            [activityDisplay]="ACTIVITY_PLAN+'Delete'"></button>
                                </li>
<!--                                TODO: Commented out because no changelog is generated-->
<!--                                <li>  -->
<!--                                    <button type="button" class="btn" translate="label.changeLog"-->
<!--                                            (click)="displayChangeLog(activityPlan.id, EntityTypeEnum.activityPlan)"-->
<!--                                            [activityDisplay]="CONSTANTS.CHANGE_LOG_OBJECT_HISTORY_READ"></button>-->
<!--                                </li>-->
                            </ul>
                        </ng-template>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- Page Filter -->
        <!-- ============================================================== -->
        <div class="pagination-component mt-4 mx-2" *ngIf="totalTableItems > 0">
            <ngb-pagination [collectionSize]="totalTableItems" [(page)]="activityPlanUtils.tableFilters.currentPage" [maxSize]="5"
                            [pageSize]="activityPlanUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                            (pageChange)="onChangePagination($event)"></ngb-pagination>

            <div class="btn-group items-per-page-group">
                <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
                <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{activityPlanUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
            </div>
            <!-- ============================================================== -->
            <!-- End Page Filter -->
        </div>
    </div>
</div>
