import {Component, OnInit} from '@angular/core';
import {CapacityPlannerUtils} from '../capacity-planner/capacity-planner.utils';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {NavigationEnd, Router} from '@angular/router';
import {LabelValueType} from '../../data-model/general.type';
import {GeneralUtils, GenericObjectType} from 'sked-base';
import {constants} from '../../shared/constants/constants';
import {NavBarService} from '../nav-bar/nav-bar.service';
import {LoggerService} from '../../shared/services/logger.service';
import {RulesUtils} from '../rules-management/rules.utils';
import {ResizedEvent} from '../../shared/directives/resize-event/resized.event';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    isMultiAppointmentActive = false;
    resourceVal = 'resource';
    roomVal = 'room';
    isRoomCapacitySelected = false;
    isAvailabilityApproveSelected = false;
    isResourceCapacitySelected = false;
    isAvailabilityOverviewSelected = false;
    showSideBarContent = false;
    capacityPlannerViewTypes: LabelValueType[] = this.capacityPlannerUtils.getViewTypesForCapacityPlanner();
    showBranding = false;
    logoPathForPoweredByLogoFooter: string;
    showLogoPoweredByLabel = window.innerWidth >= 1440;
    sideBarExpanded: {
        expandRoomManagement: boolean,
        expandMedicalManagement: boolean,
        expandUserManagement: boolean,
        expandResourceManagement: boolean,
        expandPatientManagement: boolean,
        expandAppointmentTypeManagement: boolean,
        expandMasterDataManagement: boolean,
        expandAvailabilityManagement: boolean,
        expandHolidayManagement: boolean,
        expandEventManagement: boolean,
        expandTimeWindows: boolean,
        expandConfiguration: boolean,
    };

    constructor(
        public capacityPlannerUtils: CapacityPlannerUtils,
        public rulesUtils: RulesUtils,
        private router: Router,
        public configDataService: ConfigDataService,
        private generalUtils: GeneralUtils,
        private navbarService: NavBarService,
        private loggerService: LoggerService
    ) {
        router.events.subscribe((event: NavigationEnd) => {
            if (event.url) {
                if (event.url === '/createMultiAppointmentTemplate' || event.url === '/multiAppointment') {
                    this.isMultiAppointmentActive = true;
                } else {
                    this.isMultiAppointmentActive = false;
                }
                if (event.url === '/eventRules') {
                    this.rulesUtils.isUnderEventRules = true;
                } else if (event.url === '/rules') {
                    this.rulesUtils.isUnderEventRules = false;
                }
            }
        });
    }

    ngOnInit() {
        // show sidebarContent after the initial config request is finished
        // feature-access directive needs to have all the dates saved in browser storage
        this.configDataService.initialConfigRequestFinish
            .subscribe(val => {
                if (val) {
                    this.showSideBarContent = true;
                    this.showBrandingBasedOnSystemConfig();
                }
            });

        this.capacityPlannerUtils.showResourceOrRoom.next(this.capacityPlannerViewTypes[0]);
        this.capacityPlannerUtils.showResourceOrRoom.subscribe((data) => {
            if (data.value === this.resourceVal) {
                this.isResourceCapacitySelected = true;
                this.isRoomCapacitySelected = false;
            } else {
                this.isResourceCapacitySelected = false;
                this.isRoomCapacitySelected = true;
            }
        });

        this.subscribeToggleSideBar(this.navbarService.toggleSideBar$);

        this.setExpandedMenuValue();
    }

    toggleSidebarSubMenuPosition(element: HTMLElement) {
        const sidebarSubMenuListElement = element.lastElementChild as HTMLElement;
        const sidebarSubMenuListElementHeight = sidebarSubMenuListElement.clientHeight;
        const elementBottomPosition = element.getBoundingClientRect().bottom;
        const displayViewportHeight = window.innerHeight;
        // When the element position exceed the half part of the display, we change the sub menu list to moved in the upper part
        sidebarSubMenuListElement.style.top = elementBottomPosition > (displayViewportHeight / 2) && !this.showLogoPoweredByLabel ? `-${sidebarSubMenuListElementHeight}px` : '46px';
    }

    listenToPoweredByLogoContainerWidthChanges(event: ResizedEvent) {
        const element = document.querySelector('.side-bar-horizontal-line') as HTMLElement;
        if (element) {
            element.style.width = `${event.newRect.width}px`;
        }
    }

    showResourceOrRoom(value) {
        if (value === this.resourceVal) {
            this.capacityPlannerUtils.showResourceOrRoom.next(this.capacityPlannerViewTypes[0]);
        } else {
            this.capacityPlannerUtils.showResourceOrRoom.next(this.capacityPlannerViewTypes[1]);
        }
    }

    changeExpandedMenuValue(menu: string) {
        this.sideBarExpanded[menu] = !this.sideBarExpanded[menu];
        sessionStorage.setItem('sideBarExpanded', JSON.stringify(this.sideBarExpanded));
    }

    private setExpandedMenuValue() {
        const SESSION_STORAGE_VALUE = sessionStorage.getItem('sideBarExpanded');
        this.sideBarExpanded = SESSION_STORAGE_VALUE ? JSON.parse(SESSION_STORAGE_VALUE) : {};
    }

    private subscribeToggleSideBar(toggleSideBar$) {
        toggleSideBar$
            .subscribe(toggleEvent => {
                this.showLogoPoweredByLabel = !toggleEvent;
            },
                errorMessage => {
                    this.loggerService.log(errorMessage);
                });
    }

    private showBrandingBasedOnSystemConfig() {
        let parsedSked24BrandingLogoValue: GenericObjectType;
        parsedSked24BrandingLogoValue = (this.generalUtils.getSystemConfigValue(
            this.configDataService.systemConfig.value,
            constants.SYSTEM_CONFIG_FRONTEND_ENVIRONMENT_CONFIGURATION,
            true
        ) as GenericObjectType)?.Sked24BrandingLogo;
        this.showBranding = parsedSked24BrandingLogoValue ? parsedSked24BrandingLogoValue.Backoffice : false;
        if (this.showBranding) {
            this.logoPathForPoweredByLogoFooter = '../assets/images/LogoGray.png';
        }
    }
}
