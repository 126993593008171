import {EventEmitter, Injectable} from '@angular/core';
import {
    AppointmentActivityType,
    OptionalResourceStatusEnum,
    RUAppointmentsType,
    AppointmentActivityStatusEnum,
    ODataQueryObjectType,
    ResourceDependentFiltersType,
    RUResourceAppointmentsType,
    IdNameType,
    ResourceUtilizationRequestFilterType, ActionNameEnum, AppointmentType, AppointmentStatusValueEnum
} from 'sked-base';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {
    ResourceUtilizationFiltersOptionsType,
    ResourceUtilizationFiltersSearchType
} from './resource-utilization-filters/resource-utilization-filters.types';
import {ResourceUtilizationCalendarOptionsType} from './resource-utilization-calendar/resource-utilization-calendar.types';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {ResourceUtilizationAppointmentListOptionsType} from './resource-appointment-list/resource-appointment-list.types';
import {ResourceUtilizationStateType, ResourceUtilizationStateComponentsEnum} from './resource-utilization.types';

@Injectable({
    providedIn: 'root'
})

export class ResourceUtilizationUtils {
    // State management
    resourceUtilizationState: ResourceUtilizationStateType = this.getEmptyState();
    shouldKeepFiltersState = false;
    shouldKeepListState = false;
    shouldKeepCalendarState = false;
    shouldMakeNewRequest = false;
    shouldKeepResponseData = false;
    // Request data
    appointments: RUAppointmentsType[] = [];
    resources: IdNameType[] = [];
    isAppointmentListCollapsed = false;
    // Options for filters
    filtersOptions: ResourceUtilizationFiltersOptionsType = this.getEmptyFiltersOptions();
    clearFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();
    // Options for appointments to review list
    appointmentsListOptions: ResourceUtilizationAppointmentListOptionsType = this.getEmptyAppointmentListOptions();
    isAppointmentListVisible = false;
    // Options for calendar
    calendarOptions: ResourceUtilizationCalendarOptionsType = this.getEmptyCalendarOptions();
    isCalendarVisible = false;
    // Event emitters
    refreshDataButKeepCalendarDay: EventEmitter<void> = new EventEmitter<void>();
    // Timezone boolean
    areAppointmentsInSameTimezoneAsBrowser = true;

    constructor(
        private dateTimeUtils: DateTimeUtils
    ) {
    }
    // Timezone
    loadAppointmentTimezone(ISODate: string) {
        this.areAppointmentsInSameTimezoneAsBrowser = this.dateTimeUtils.isInBrowserTimeZone(moment.parseZone(ISODate).utcOffset());
    }
    // State management

    updateFilterState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.filtersOptions.hasOwnProperty(property)) {
                return;
            }
            this.resourceUtilizationState.filtersOptions[property] = lodash.cloneDeep(this.filtersOptions[property]);
        });
    }

    updateCalendarState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.calendarOptions.hasOwnProperty(property)) {
                return;
            }
            this.resourceUtilizationState.calendarOptions[property] = lodash.cloneDeep(this.calendarOptions[property]);
        });
    }

    updateAppointmentsListState(properties: string[]) {
        properties.forEach((property: string) => {
            if (!this.appointmentsListOptions.hasOwnProperty(property)) {
                return;
            }
            this.resourceUtilizationState.appointmentsListOptions[property] = lodash.cloneDeep(this.appointmentsListOptions[property]);
        });
    }

    shouldLoadState(component: ResourceUtilizationStateComponentsEnum): boolean {
        switch (component) {
            case ResourceUtilizationStateComponentsEnum.filtersOptions: {
                return this.shouldKeepFiltersState && !!this.resourceUtilizationState.filtersOptions;
            }
            case ResourceUtilizationStateComponentsEnum.calendarOptions: {
                return this.shouldKeepCalendarState && !!this.resourceUtilizationState.calendarOptions;
            }
            case ResourceUtilizationStateComponentsEnum.appointmentsListOptions: {
                return this.shouldKeepListState && !!this.resourceUtilizationState.appointmentsListOptions;
            }
        }
    }

    getEmptyState(): ResourceUtilizationStateType {
        return {
            filtersOptions: this.getEmptyFiltersOptions() as ResourceUtilizationFiltersOptionsType,
            appointmentsListOptions: this.getEmptyAppointmentListOptions() as ResourceUtilizationAppointmentListOptionsType,
            calendarOptions: this.getEmptyCalendarOptions() as ResourceUtilizationCalendarOptionsType,
        } as ResourceUtilizationStateType;
    }

    // ///

    getOptionalResourcesDependentFilters(resources: RUResourceAppointmentsType[]): ResourceDependentFiltersType {
        return {
            searchPhrase: '',
            includeAvailabilities: false,
            onlyDirectlyBookable: false,
            serviceId: null,
            locationId: null,
            coveragePlanId: null,
            areaId: null,
            onlyAssignedToUser: false,
            onlyAssignedToLocationsOfUser: false,
            resourceTypeId: null,
            includeSelfPayer: false,
            resourceTypeExclusionList: [],
            exclusionList: this.getOptionalResourcesExclusionList(resources),
            count: true,
        } as ResourceDependentFiltersType;
    }

    getOptionalResourcesExclusionList(resources: RUResourceAppointmentsType[]): string[] {
        const resourceIds: string[] = [];
        resources.forEach((resource: RUResourceAppointmentsType) => resourceIds.push(resource.resourceId));
        return resourceIds;
    }

    changeCollapseAppointmentListSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.appointmentsListOptions.isAppointmentListCollapsed = value ?? !this.appointmentsListOptions.isAppointmentListCollapsed;
        // Update state
        this.updateAppointmentsListState(['isAppointmentListCollapsed']);
    }

    // Get number of appointments for each page
    getAppointmentsPerPage(currentPage: number, pageSize: number): RUAppointmentsType[] {
        if (this.appointmentsListOptions.pendingAppointments.length > 0) {
            return this.appointmentsListOptions.pendingAppointments.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
        }
        return [];
    }

    getResourceAppointmentsOverviewQueryFilter(filterValues: ResourceUtilizationFiltersSearchType): ResourceUtilizationRequestFilterType {
        const queryFilter: ResourceUtilizationRequestFilterType = {} as ResourceUtilizationRequestFilterType;
        queryFilter.resourceTypeId = filterValues?.resourceType?.id;
        queryFilter.resourceId = filterValues?.resource?.id;
        queryFilter.specialityId = filterValues?.speciality?.id;
        queryFilter.areaId = filterValues?.area?.id;
        queryFilter.centerId = filterValues?.location?.id;
        queryFilter.dateFrom = this.dateTimeUtils.getMomentFromNgbDate(filterValues.dateFrom).format('YYYY-MM-DD');
        // Because the hour is sent with 00:00 we need to add 1 day to display the appointments correctly
        queryFilter.dateTo = this.dateTimeUtils.getMomentFromNgbDate(filterValues.dateTo).add(1, 'days').format('YYYY-MM-DD');
        return queryFilter;
    }

    getEmptyFiltersOptions(): ResourceUtilizationFiltersOptionsType {
        return {
            areFiltersReady: true,
            filterWrapperInitialValues: undefined,
            filterWrapperOptions: [],
            filterValidations: {},
            dateRangeOptions: {},
            resourceUtilizationFiltersValues: {},
            areFiltersValid: false,
            areFiltersCollapsed: false,
            latestSearchFilterValues: undefined
        } as ResourceUtilizationFiltersOptionsType;
    }

    getEmptyAppointmentListOptions(): ResourceUtilizationAppointmentListOptionsType {
        return {
            currentPage: 1,
            pageSize: 5,
            isAppointmentListCollapsed: false,
            pendingAppointments: [],
            pendingAppointmentsPerPage: [],
        } as ResourceUtilizationAppointmentListOptionsType;
    }

    getEmptyCalendarOptions(): ResourceUtilizationCalendarOptionsType {
        const todayMomentDate = moment();
        const todayNgbDate: NgbDateStruct = this.dateTimeUtils.getNgbDateFromMoment(todayMomentDate);
        return {
            resourceUtilizationCalendarOptions: undefined,
            resourceUtilizationCalendarApi: undefined,
            customButtons: undefined,
            calendarResources: [],
            calendarEvents: [],
            translatedText: {},
            smallHeaderCalendarNgModel: undefined,
            smallHeaderCalendarIsDayWithSlots: {},
            legendHeaderTippyInstance: undefined,
            optionsHeaderTippyInstance: undefined,
            calendarGranularityNgModel: undefined,
            calendarRanges: {
                smallCalendarMinDate: todayNgbDate,
                smallCalendarMaxDate: todayNgbDate,
                fullCalendarMinDate: todayMomentDate.format('YYYY-MM-DD'),
                fullCalendarMaxDate: todayMomentDate.format('YYYY-MM-DD')
            }
        } as ResourceUtilizationCalendarOptionsType;
    }

    getPendingAppointments(appointments: RUAppointmentsType[] = []): RUAppointmentsType[] {
        return lodash.filter(appointments, {pendingAppointment: true});
    }

    mapAppointmentsForDisplay(appointments: RUAppointmentsType[] = []): RUAppointmentsType[] {
        const appointmentsForDisplay = [] as RUAppointmentsType[];
        appointments.forEach(appointment => {
            appointmentsForDisplay.push({
                ...appointment,
                confirmedResourcesLength: this.getConfirmedResourcesLength(appointment.resourceAppointments),
                confirmedTasksLength: this.getConfirmedTasksLength(appointment.activities),
                mainResourceName: this.getMainResourceName(appointment.resourceAppointments),
                optionalResources: this.getOptionalResources(appointment.resourceAppointments)
            });
        });
        return appointmentsForDisplay;
    }

    getPatientCoveragePlansQueryFilter(): ODataQueryObjectType {
        return {
            select: ['CoveragePlans'],
            expand: {CoveragePlans: {select: ['Id', 'Name']}}
        };
    }

    getStateOptionsBasedOnAction(action: ActionNameEnum) {
        let state: any;
        switch (action) {
            case ActionNameEnum.Back: {
                state = {
                    shouldKeepFiltersState: true,
                    shouldKeepListState: true,
                    shouldKeepCalendarState: true,
                    shouldKeepResponseData: true
                };
                break;
            }
            case ActionNameEnum.Save: {
                state = {
                    shouldKeepFiltersState: true,
                    shouldMakeNewRequest: true,
                    shouldKeepCalendarState: true
                };
                break;
            }
            case ActionNameEnum.CancelAppointment: {
                state = {
                    shouldKeepFiltersState: true,
                    shouldMakeNewRequest: true,
                    shouldKeepCalendarState: true
                };
                break;
            }
        }
        return state;
    }

    getQueryFilterForAppointment(): ODataQueryObjectType {
        return {
            select: ['Id', 'Status', 'DateTimeFrom', 'Duration', 'RowVersion'],
            expand: {
                Patient: {select: ['Id', 'FullName', 'MainPhoneNumber', 'AlternatePhoneNumber']},
                ResourceAppointments: {
                    select: ['MainResource'],
                    expand: {Resource: {select: ['Name']}}
                },
                Service: {select: ['Name']},
                SubServices: {
                    expand: {SubService: {select: ['Name']}}
                },
                Center: {select: ['Name']},
                AppointmentType: {select: ['Name']},
                CoveragePlan: {select: ['Name']}
            }
        };
    }

    mapAppointmentToResourceUtilizationAppointment(appointment: AppointmentType): RUAppointmentsType {
        return {
            patient: {
                id: appointment.patient?.id,
                name: appointment.patient?.fullName,
                mainPhoneNumber: appointment.patient?.mainPhoneNumber,
                alternatePhoneNumber: appointment.patient?.alternatePhoneNumber,
            },
            resourceAppointments: appointment.resourceAppointments.map(({resource, mainResource}) => ({name: resource.name, mainResource})),
            service: {
                name: appointment.service?.name
            },
            subServices: appointment.subServices.map(({subService}) => ({name: subService.name})),
            center: {
                name: appointment.center?.name
            },
            status: AppointmentStatusValueEnum[appointment.status],
            dateTimeFrom: appointment.dateTimeFrom,
            duration: appointment.duration,
            appointmentType: {
                name: appointment.appointmentType?.name
            },
            coveragePlan: {
                name: appointment.coveragePlan?.name
            },
            appointmentId: appointment.id,
            eTag: appointment.etag
        } as RUAppointmentsType;
    }

    private getConfirmedResourcesLength(resources: RUResourceAppointmentsType[]): number {
        return resources.filter(resource => resource.optionalResourceStatus === OptionalResourceStatusEnum.Accepted).length;
    }

    private getConfirmedTasksLength(tasks: AppointmentActivityType[]): number {
        return tasks.filter(task => task.status === AppointmentActivityStatusEnum.Done).length;
    }

    private getMainResourceName(resources: RUResourceAppointmentsType[]): string {
        return resources.find(resource => resource.mainResource).name;
    }

    private getOptionalResources(resources: RUResourceAppointmentsType[]): RUResourceAppointmentsType[] {
        const found = resources.filter(resource => resource.optional);
        return found ? found : [];
    }
}
