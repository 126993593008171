<div class="container-fluid resource-type-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
        <h3 translate="label.resourceTypes"></h3>
        <button class="reset-button btn" (click)="onClearFilters()">
            <i class="far fa-trash-alt"></i>
            <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
        </button>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
        <span *ngFor="let key of objectKeys(resourceTypeMdUtils.tableFilters.orderBy)">
          <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + resourceTypeMdUtils.tableFilters.orderBy[key] | translate}}
            <span>
            <i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
          </span>
              </span>
              </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input (ngModelChange)="onSearchResourceTypeChanged($event)" [(ngModel)]="resourceTypeMdUtils.tableFilters.filter.Name"
                       autocomplete="off" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" type="text">
            </div>
            <div class="ml-2">
                <button (click)="createResourceType()" [activityDisplay]="MDEntityName+constants.CREATE"
                        class="btn theme-alt-btn border-0"
                        type="button"
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="id-column" translate="label.shortId"></th>
                <th (click)="onSortBy('name')" class="name-column cursor-pointer">
                    {{'label.name' | translate}}
                    <button *ngIf="!resourceTypeMdUtils.tableFilters.orderBy.name" class="btn p-0" type="button"><i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="resourceTypeMdUtils.tableFilters.orderBy.name === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="resourceTypeMdUtils.tableFilters.orderBy.name === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th class="isDirectlyBookable-column" translate="label.isDirectlyBookable"></th>
                <th class="isRoom-column" translate="label.isRoom"></th>
                <th class="canBeRegisteredToWaitList-column" translate="label.waitList"></th>
                <th class="hasContactInformation-column" translate="label.contactInfo"></th>
                <th class="hasUniqueCode-column" translate="label.uniqueCode"></th>
                <th class="hasImage-column" translate="label.image"></th>
                <th class="hasDescription-column" translate="label.description"></th>
                <th class="representsPerson-column" translate="label.person"></th>
                <th class="hasAdditionalInformation-column" translate="label.alerts"></th>
                <th class="displayAlwaysAdditionalInformation-column" translate="label.displayAlertsAsTextOnPatientAccess"></th>
                <th class="text-center" translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let resourceType of resourceTypeMdList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewResourceType(resourceType)">
                <td class="id-column">{{resourceType.shortId}}</td>
                <td class="name-column">{{resourceType.name}}</td>
                <td class="isDirectlyBookable-column">{{resourceType.isDirectlyBookable | yesNoPipe | translate}}</td>
                <td class="isRoom-column">{{resourceType.isRoom | yesNoPipe | translate}}</td>
                <td class="canBeRegisteredToWaitList-column">{{resourceType.canBeRegisteredToWaitList | yesNoPipe | translate}}</td>
                <td class="hasContactInformation-column">{{resourceType.hasContactInformation | yesNoPipe | translate}}</td>
                <td class="hasUniqueCode-column">{{resourceType.hasUniqueCode | yesNoPipe | translate}}</td>
                <td class="hasImage-column">{{resourceType.hasImage | yesNoPipe | translate}}</td>
                <td class="hasDescription-column">{{resourceType.hasDescription | yesNoPipe | translate}}</td>
                <td class="representsPerson-column">{{resourceType.representsPerson | yesNoPipe | translate}}</td>
                <td class="hasAdditionalInformation-column">{{resourceType.hasAdditionalInfo | yesNoPipe | translate}}</td>
                <td class="displayAlwaysAdditionalInformation-column">{{resourceType.displayAlwaysPatientAccessAdditionalInformation | yesNoPipe | translate}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button [ngbPopover]="popContent" class="btn" placement="left" popoverClass="popover-shadow"
                            type="button">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button (click)="viewResourceType(resourceType)" class="btn" translate="label.view"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="editResourceType(resourceType)" [activityDisplay]="MDEntityName+constants.UPDATE" class="btn"
                                        translate="label.edit"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="displayConfirmDeleteItemModal(resourceType.id)" [activityDisplay]="MDEntityName+constants.DELETE" class="btn"
                                        translate="label.delete"
                                        type="button"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(resourceType.id, EntityTypeEnum.resourceType, resourceType.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="resourceTypeMdUtils.tableFilters.currentPage" [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="resourceTypeMdUtils.tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;" [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{resourceTypeMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
