<div class="details-modal">
    <div class="modal-header">
        <h4>
            <strong>{{"label.appointmentDetails" | translate}}</strong>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container custom-scrollbar">
            <div class="row">
                <label class="font-weight-bold text-dark mr-2">{{"label.appointmentId" | translate}}:</label>
                <span class="text-high-contrast">{{appointmentId}}</span>
            </div>
            <div class="row details-modal-table-container">
                <ngx-ui-loader [hasProgressBar]="false" [loaderId]="spinnerId">
                </ngx-ui-loader>
                <label class="font-weight-bold text-dark mr-2">{{"label.appointmentHistory" | translate}}:</label>
                <table class="table table-bordered grey-table">
                    <thead class="thead-light">
                    <tr>
                        <td translate="label.status"></td>
                        <td translate="label.modifiedBy"></td>
                        <td translate="label.modifiedOn"></td>
                        <td translate="label.channel"></td>
                    </tr>
                    </thead>
                    <tbody *ngIf="appointment?.statusHistories?.length < 1">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tbody *ngFor="let statusHistory of appointment?.statusHistories; let index = index">
                    <tr class="text-high-contrast">
                        <td>{{"enum.appointmentStatus." + statusHistory.status | translate}}</td>
                        <td>{{statusHistory.createdByUser}}</td>
                        <td>{{statusHistory.modifiedOn | fullDateFormatPipe: false}}</td>
                        <td>{{"enum.channel." + statusHistory.modifiedByClient | translate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row external-keys-table-container">
                <ngx-ui-loader [hasProgressBar]="false" [loaderId]="spinnerId">
                </ngx-ui-loader>
                <label class="font-weight-bold text-dark mr-2">{{'label.externalKeysLabel' | translate}}:</label>
                <table class="table table-bordered grey-table">
                    <thead class="thead-light">
                    <tr>
                        <td translate="label.origin"></td>
                        <td translate="label.key"></td>
                    </tr>
                    </thead>
                    <tbody *ngIf="appointment?.appointmentExternalKeys?.length < 1">
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                    <tbody *ngFor="let externalKey of appointment?.appointmentExternalKeys; let index = index">
                    <tr class="text-high-contrast">
                        <td>{{externalKey.origin}}</td>
                        <td>{{externalKey.key}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn close-btn min-width-auto"
                (click)="onCloseModal()" translate="label.close">
        </button>
    </div>
</div>


