import {Injectable} from '@angular/core';
import {
    AreaDependentFiltersType,
    AreaProvider,
    CenterProvider,
    DateRangeOptionsType,
    FilterWrapperButtonNameEnum,
    FilterWrapperNameEnum,
    FormValidationType,
    GenericFilterOptionsType,
    GenericFilterResultType,
    LocationDependentFiltersType,
    PatientProvider,
    ResourceDependentFiltersType,
    ResourceProvider,
    ServiceDependentFiltersType,
    ServiceProvider,
    SkedTaskGetDataSearchingDependentFilters, SkedTaskGetDataSearchingMethodNameEnum,
    SkedTaskProcessingTypeEnum, SkedTaskProvider,
    SkedTaskStatusEnum,
    SubServiceDependentFiltersType,
    SubServiceProvider
} from 'sked-base';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {
    TaskListFiltersSearchType,
    TaskListFiltersValidationType,
    TaskListFilterWrapperInitialValuesType
} from './task-list-filters.types';
import {PatientDependentFiltersType} from 'sked-base/lib/data-model/patientTypes';
import {IGenericProvider} from 'sked-base/lib/data-model/genericProviderInterface';

@Injectable({
    providedIn: 'root'
})
export class TaskListFiltersUtil {
    constructor(
        private areaProvider: AreaProvider,
        private patientProvider: PatientProvider,
        private resourceProvider: ResourceProvider,
        private serviceProvider: ServiceProvider,
        private subServiceProvider: SubServiceProvider,
        private skedTaskProvider: SkedTaskProvider,
        private centerProvider: CenterProvider,
        private dateTimeUtils: DateTimeUtils
    ) {
    }

    getInitialFilterWrapperValuesFromPreviousValues(
        taskListFiltersValues: TaskListFilterWrapperInitialValuesType
    ): GenericFilterResultType[] {
        const filterWrapperValues: GenericFilterResultType[] = [];
        Object.keys(taskListFiltersValues).forEach((property: string) => {
            if (!!taskListFiltersValues[property]) {
                filterWrapperValues.push({
                    name: FilterWrapperNameEnum[property],
                    value: taskListFiltersValues[property]
                } as GenericFilterResultType);
            }
        });
        return filterWrapperValues;
    }

    mapFilterValuesForSearch(
        dueDateRangeOptions: DateRangeOptionsType,
        taskListFiltersValues: TaskListFilterWrapperInitialValuesType,
        onlyTasksWithAppointmentsCheckboxNgModel: boolean,
        onlyExpiringTasksCheckboxNgModel: boolean,
        processTypeSelectNgModel: SkedTaskProcessingTypeEnum,
        statusSelectNgModel: SkedTaskStatusEnum,
        createdDateRangeOptions: DateRangeOptionsType
    ): TaskListFiltersSearchType {
        return {
            dueDateFrom: dueDateRangeOptions.fromDate,
            dueDateTo: dueDateRangeOptions.toDate,
            area: taskListFiltersValues.area,
            patient: taskListFiltersValues.patient,
            resource: taskListFiltersValues.resource,
            service: taskListFiltersValues.service,
            subService: taskListFiltersValues.subService,
            taskType: taskListFiltersValues.taskType,
            location: taskListFiltersValues.location,
            onlyTasksWithAppointments: onlyTasksWithAppointmentsCheckboxNgModel,
            onlyExpiringTasks: onlyExpiringTasksCheckboxNgModel,
            taskProcessType: processTypeSelectNgModel,
            taskStatus: statusSelectNgModel,
            createdDateFrom: createdDateRangeOptions.fromDate,
            createdDateTo: createdDateRangeOptions.toDate,
        } as TaskListFiltersSearchType;
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    getPatientDependentFilters(): PatientDependentFiltersType {
        return {
            searchPhrase: '',
            exclusionList: [],
            count: true,
        } as PatientDependentFiltersType;
    }

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyDirectlyBookable: false,
        serviceId: null,
        locationId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        exclusionList: [],
        resourceTypeExclusionList: [],
        count: true,
    } as ResourceDependentFiltersType)

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        onlyAssignedToLocationsOfUser: true,
        includeAvailabilities: false,
        includeChannel: true,
        searchPhrase: '',
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        hasMultiResourceBluePrint: null,
        exclusionList: [],
        count: true,
    } as ServiceDependentFiltersType)

    getSubServiceDependentFilters = (): SubServiceDependentFiltersType => ({
        searchPhrase: '',
        areaId: null,
        count: true,
        serviceId: null,
        locationId: null,
        includeAvailabilities: false,
        resourceId: null,
        specialityId: null,
        exclusionList: []
    } as SubServiceDependentFiltersType)

    getTaskTypeDependentFilters = (): SkedTaskGetDataSearchingDependentFilters => ({
        getDataSearchingMethodName: SkedTaskGetDataSearchingMethodNameEnum.getAvailableTypes
    })

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: true,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: true,
        exclusionList: [],
        count: true
    })

    getInitialDueDateRangeOptions(): DateRangeOptionsType {
        const todayMoment = moment();
        const todayPlus7DaysMoment = moment().add(7, 'days');
        const today = this.dateTimeUtils.getNgbDateFromMoment(todayMoment);
        const todayMinus7DaysMoment = moment().subtract(7, 'days');
        const todayMinus7Days = this.dateTimeUtils.getNgbDateFromMoment(todayMinus7DaysMoment);
        const todayPlus7Days = this.dateTimeUtils.getNgbDateFromMoment(todayPlus7DaysMoment);
        const fromDate = {year: today.year, month: today.month, day: today.day};
        const minDate = {year: todayMinus7Days.year, month: todayMinus7Days.month, day: todayMinus7Days.day};
        const toDate = {year: todayPlus7Days.year, month: todayPlus7Days.month, day: todayPlus7Days.day};
        return {
            fromDate,
            toDate,
            fromDateLabel: 'label.dueDateFrom',
            toDateLabel: 'label.dueDateTo',
            minDate,
            maximumRange: 60,
            disabled: false
        } as DateRangeOptionsType;
    }

    getInitialCreatedDateRangeOptions(): DateRangeOptionsType {
        return {
            fromDateLabel: 'label.createdDateFrom',
            toDateLabel: 'label.createdDateTo',
            disabled: false,
            maximumRange: 60,
        } as DateRangeOptionsType;
    }

    getInitialFilterValidations(): TaskListFiltersValidationType {
        return {
            dueDateRange: {isValid: true, errorMessage: ''} as FormValidationType,
            area: {isValid: true, errorMessage: ''} as FormValidationType,
            patient: {isValid: true, errorMessage: ''} as FormValidationType,
            resource: {isValid: true, errorMessage: ''} as FormValidationType,
            service: {isValid: true, errorMessage: ''} as FormValidationType,
            subService: {isValid: true, errorMessage: ''} as FormValidationType,
            taskType: {isValid: true, errorMessage: ''} as FormValidationType,
            location: {isValid: true, errorMessage: ''} as FormValidationType,
            createdDateRange: {isValid: true, errorMessage: ''} as FormValidationType,
            processTypeSelect: {isValid: true, errorMessage: ''} as FormValidationType,
            statusSelect: {isValid: true, errorMessage: ''} as FormValidationType,
        } as TaskListFiltersValidationType;
    }

    getFilterWrapperOptions = (initialValues?: TaskListFilterWrapperInitialValuesType): GenericFilterOptionsType[] => ([
            {
                dependentFilters: this.getAreaDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.area,
                name: FilterWrapperNameEnum.area,
                parentFilterValue: initialValues?.area ? {
                    name: FilterWrapperNameEnum.area,
                    value: initialValues.area
                } : undefined,
                providerInstance: this.areaProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getPatientDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.patient,
                name: FilterWrapperNameEnum.patient,
                parentFilterValue: initialValues?.patient ? {
                    name: FilterWrapperNameEnum.patient,
                    value: initialValues.patient
                } : undefined,
                providerInstance: this.patientProvider,
                useSelectedValueAsLabel: true,
                customInitialNotFoundLabel: 'label.searchPatientByNameOrDocument',
                customError400Label: 'label.searchPatientByNameOrDocument'
            },
            {
                dependentFilters: this.getResourceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.resource,
                name: FilterWrapperNameEnum.resource,
                parentFilterValue: initialValues?.resource ? {
                    name: FilterWrapperNameEnum.resource,
                    value: initialValues.resource
                } : undefined,
                providerInstance: this.resourceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getServiceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.service,
                name: FilterWrapperNameEnum.service,
                parentFilterValue: initialValues?.service ? {
                    name: FilterWrapperNameEnum.service,
                    value: initialValues.service
                } : undefined,
                providerInstance: this.serviceProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getSubServiceDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.subService,
                name: FilterWrapperNameEnum.subService,
                parentFilterValue: initialValues?.subService ? {
                    name: FilterWrapperNameEnum.subService,
                    value: initialValues.subService
                } : undefined,
                providerInstance: this.subServiceProvider as unknown as IGenericProvider,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getTaskTypeDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.taskType,
                name: FilterWrapperNameEnum.taskType,
                parentFilterValue: initialValues?.taskType ? {
                    name: FilterWrapperNameEnum.taskType,
                    value: initialValues.taskType
                } : undefined,
                providerInstance: this.skedTaskProvider as any,
                useSelectedValueAsLabel: true
            },
            {
                dependentFilters: this.getLocationDependentFilters(),
                disableFilter: false,
                label: FilterWrapperButtonNameEnum.location,
                name: FilterWrapperNameEnum.location,
                parentFilterValue: initialValues?.location ? {
                    name: FilterWrapperNameEnum.location,
                    value: initialValues.location
                } : undefined,
                providerInstance: this.centerProvider,
                useSelectedValueAsLabel: true
            },
        ]
    )
}
