import {Injectable} from '@angular/core';
import {GRPRescheduleOptionsType} from 'sked-base';

@Injectable({
    providedIn: 'root'
})
export class GraphicalResourcePlannerUtil {
    rescheduleAppointmentState: GRPRescheduleOptionsType;

    constructor() {
    }
}
