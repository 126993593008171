<div *ngIf="noResultsFound"
     class="change-log-list-message mb-3 mt-4"
     translate="label.noResultsFound">
</div>
<div *ngIf="changeLogs.length > 0">
    <div class="col-12 mb-3 mt-4">
        <div class="text-info">
            <i class="fa-solid fa-circle-exclamation pr-1"></i>
            <span translate="label.onlyLastTenChangesDisplayed"></span>
        </div>
    </div>
</div>
<div class="change-log-table-container custom-scrollbar mt-3">
    <table class="table parent-table">
        <tbody>
        <ng-container *ngFor="let item of changeLogs">
            <tr class="cursor-pointer" (click)="item.isRowCollapsed = !item.isRowCollapsed">
                <td class="text-center">
                    {{item.modifiedOn | date: 'dd/MM/yyyy'}}
                </td>
                <td>
                    <span>{{'enum.entityType.' + item.entityType | translate}} </span>
                    <span class="bold-text">{{item.entityId}} </span>
                    <span>{{'label.modifiedBy' | translate}} </span>
                    <span class="bold-text">{{item.createdByUser}} </span>
<!--                    <span>{{'label.inChannel' | translate}} </span>-->
<!--                    <span class="bold-text">{{'label.channels.' + item.modifiedByClient | translate}} </span>-->
                </td>
                <td class="text-center">
                    <button class="btn btn-link black-color">
                        <i *ngIf="item.isRowCollapsed" class="fa fa-chevron-down"
                           aria-hidden="true"></i>
                        <i *ngIf="!item.isRowCollapsed" class="fa fa-chevron-up"
                           aria-hidden="true"></i>
                    </button>
                </td>
            </tr>
            <tr [(ngbCollapse)]="item.isRowCollapsed">
                <td class="pt-0 pb-0" colspan="3">
                    <ng-container class="overview-table-container">
                        <table class="mt-2 table table-bordered grey-table">
                            <thead>
                            <tr>
                                <td translate="label.operation"></td>
                                <td translate="label.path"></td>
                                <td translate="label.value"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let content of item.contentList">
                                <td>
                                    <i *ngIf="('label.' + content.op | translate) === ('label.replace' | translate)"
                                       class="fa-regular fa-pen"></i>
                                    <i *ngIf="('label.' + content.op | translate) === ('label.add' | translate)"
                                       class="fa-regular fa-plus"></i>
                                    <i *ngIf="('label.' + content.op | translate) === ('label.remove' | translate)"
                                       class="fa-regular fa-trash"></i>
                                    <span>
                                        {{'label.' + content.op | translate}}
                                    </span>
                                </td>
                                <td>{{content.path | translate}}</td>
                                <td>{{content.value | json}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>
