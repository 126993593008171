import {Injectable} from '@angular/core';
import {
    ODataFilterQueryType,
    TableFiltersType,
    ODataOrderByQueryType, IdNameType,
} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {
    BackofficeCenterMDType,
    ValidationNumberWithRange,
    ValidCenterObjectType
} from './center-md.types';
import {
    AddressType,
    AreaDependentFiltersType, AreaProvider,
    AreaType,
    CenterBaseType, CenterType, HolidayCalendarType, HolidayType,
    ODataQueryObjectType,
    TagTypeComponent
} from 'sked-base';
import {ExternalKeysType} from 'sked-base/lib/data-model/externalKeysTypes';
import {
    MultiSelectTableColumnKindEnum, MultiSelectTableColumnType,
    MultiSelectTableOptionsType
} from '../../shared/component/multi-select-table/multi-select-table.types';
import {MultiSelectTableUtils} from '../../shared/component/multi-select-table/multi-select-table-util';

@Injectable({
    providedIn: 'root'
})
export class CenterMdUtils {
    initialAreaValues: TagTypeComponent[] = [];
    tableFilters: TableFiltersType;

    constructor(private areaProvider: AreaProvider,
                private multiSelectTableUtils: MultiSelectTableUtils) {
    }

    getQueryFilterForCenterMD(tableFilters: TableFiltersType, count: boolean = true) {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandByQuery()
        };
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {name: ''},
            orderBy: {},
            expand: {}
        };
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (filter.hasOwnProperty(item) && filter[item]) {
                switch (item) {
                    case 'name': {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                        break;
                    }
                    case 'area': {
                        filterQuery.Areas = {any: {Id: {eq: {type: 'guid', value: filter[item].id}}}};
                        break;
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'region': {
                        orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                        break;
                    }
                    case 'locality': {
                        orderByQuery.push('Address/' + lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getExpandByQuery() {
        return {
            Country: {select: ['Id', 'CountryName', 'CountryCode']},
            Region: {select: ['Id', 'Name']},
            Areas: {select: ['Id', 'Name']},
            CenterExternalKeys: {select: ['Origin', 'Key', 'EntityId', 'Id']},
        };
    }

    getRegionFilterQuery(countryId: string): ODataQueryObjectType {
        return {
            count: true,
            select: ['Id', 'Name'],
            filter: {CountryId: {eq: {type: 'guid', value: countryId}}},
        };
    }

    getLocalityFilterQuery(regionId: string): ODataQueryObjectType {
        return {
            count: true,
            select: ['Id', 'Name'],
            filter: {RegionId: {eq: {type: 'guid', value: regionId}}},
        };
    }

    getInitialCenter(): BackofficeCenterMDType {
        const center: BackofficeCenterMDType = {} as BackofficeCenterMDType;

        center.name = '';
        center.countryId = '';
        center.regionId = '';
        center.timeZoneId = 'noValue';
        center.region = 'noValue' as unknown as IdNameType;
        center.latitude = null;
        center.longitude = null;
        center.callCenterPhoneNumber = '';
        center.logoUrl = '';
        center.webSiteUrl = '';
        center.redirectUrl = '';
        center.areas = [];
        center.centerExternalKeys = [];
        center.address = this.getInitialAddress();

        return center;
    }

    getInitialAddress(): AddressType {
        return {
            locality: undefined,
            street: '',
            streetNumber: '',
            floor: '',
            apartmentNumber: ''
        };
    }

    getEmptyExternalKeyItem(): ExternalKeysType {
        return {
            id: '',
            origin: '',
            key: '',
            entityId: '',
        };
    }

    getInitValidTempleObject(): ValidCenterObjectType {
        return {
            isNameValid: false,
            isCountryValid: false,
            isRegionValid: false,
            isTimeZoneValid: false,
            isLatitudeValid: false,
            isLongitudeValid: false,
            isExternalKeysValid: false,
            callCenterPhoneNumberValid: {isValid: true, errorMessage: ''},
            areAreasValid: false,
            logoUrl: {isValid: true, errorMessage: ''},
            webSiteUrl: {isValid: true, errorMessage: ''},
            redirectUrl: {isValid: true, errorMessage: ''},
            locality: {isValid: true, errorMessage: ''},
            street: {isValid: true, errorMessage: ''},
            streetNumber: {isValid: true, errorMessage: ''},
            floor: {isValid: true, errorMessage: ''},
            apartmentNumber: {isValid: true, errorMessage: ''},
            mainPhoneNumberValid: {isValid: true, errorMessage: ''},
            alternatePhoneNumberValid: {isValid: true, errorMessage: ''},
            email: {isValid: true, errorMessage: ''},
        };
    }

    getAreaDependentFilters(): AreaDependentFiltersType {
        return {
            locationId: null,
            searchPhrase: '',
            exclusionList: []
        };
    }

    objectAddedOnEditAction(initialCenter: BackofficeCenterMDType, selectedItem: AreaType, refObjectName: string): boolean {
        const listName = lodash.lowerFirst(refObjectName);
        const foundItem = lodash.find(initialCenter[listName], {id: selectedItem.id});

        return !!foundItem;
    }

    validationCoordinates(minimum: number, maximum: number, decimals: number): ValidationNumberWithRange {
        return {
            errorProperties: {
                minimumCoordinate: minimum,
                maximumCoordinate: maximum,
                decimals
            }
        };
    }

    mapCenterAsProviderType(center: BackofficeCenterMDType, systemTagId?: string) {
        let centerToSend;
        centerToSend = center as CenterBaseType;

        if (center.region) {
            centerToSend.regionId = center.region.id;
        }
        if (center.country) {
            centerToSend.countryId = center.country.id;
        }

        const areas = [];
        for (const area of center.areas) {
            const areaToSent: AreaType = {} as AreaType;
            areaToSent.id = area.id;
            areas.push(areaToSent);
        }

        if (center?.tags?.length > 0) {
            center.tags = center.tags.filter(tag => tag.id !== systemTagId);
        }
        centerToSend.areas = areas;

        const externalKeys = [];
        for (const externalKey of center.centerExternalKeys) {
            const externalKeysToSend: ExternalKeysType = {} as ExternalKeysType;
            externalKeysToSend.id = externalKey.id;
            externalKeysToSend.origin = externalKey.origin;
            externalKeysToSend.key = externalKey.key;
            externalKeys.push(externalKeysToSend);
        }
        centerToSend.centerExternalKeys = externalKeys;
        return centerToSend;
    }

    isExternalKeysTableValid(externalKeysList: ExternalKeysType[]): boolean {
        if (externalKeysList && externalKeysList.length > 0) {
            const externalKeyItem = lodash.find(externalKeysList, (item) => {
                if (item.key === '' || item.origin === '') {
                    return item;
                }
            });

            return !(externalKeyItem !== undefined);
        } else {
            return true;
        }
    }

    getAreaMultiSelectTableOptions(center: BackofficeCenterMDType, screenTemplateLayoutAction: string): MultiSelectTableOptionsType {
        const select = ['Id', 'Name'];
        return {
            provider: this.areaProvider,
            providerMethod: 'getEntries',
            confirmDeleteProvider: this.areaProvider,
            confirmDeleteParentId: center?.id,
            confirmDeleteRefName: 'Areas',
            getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(select, null, null, ['Name asc']),
            initialSelectedItems: center.areas,
            tableMaxHeight: '400px',
            columns: [{
                type: MultiSelectTableColumnKindEnum.FILTER,
                headerLabel: 'label.centerTab.medicalArea',
                displayUpperHeader: false,
                itemPropertyNameInTableRow: 'name',
                provider: this.areaProvider,
                buttonName: 'label.centerTab.medicalArea',
                dependentFilters: this.getAreaDependentFilters(),
                propertyName: 'area',
                propertyFilterIdName: 'Id',
                propertyFilterIdNameMapped: 'id',
            } as MultiSelectTableColumnType,
            ],
            screenTemplateLayoutAction
        };
    }
}
