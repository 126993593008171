<div class="slots-list-container pl-1" *ngIf="!!getOptions()">
    <div class="label-and-remove-row d-flex no-block flex-row justify-content-between align-items-center">
        <h6 class="font-weight-bold mb-0" [ngClass]="{'hovered': getOptions()?.hovered}">
            <span class="pr-1" *ngIf="getOptions()?.translateLabel" [translate]="getOptions()?.label"></span>
            <span class="pr-1" *ngIf="!getOptions()?.translateLabel">
                {{getOptions()?.label}}
            </span>
        </h6>
        <div class="remove-list">
            <button *ngIf="!getOptions()?.isEmpty"
                    class="action-button reset-button btn" (click)="onRemoveList()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1" translate='label.remove'></span>
            </button>
        </div>
    </div>
    <div *ngIf="getOptions()?.isEmpty"
         class="slots-list-before-search-container d-flex no-block justify-content-center align-items-center">
        <i class="fa-light fa-circle-info mr-2"></i>
        <span translate="label.searchForATimeSlot"></span>
    </div>
    <ul *ngIf="!getOptions()?.isEmpty"
        class="list-group list-group-flush slots-list-per-day mt-2 text-high-contrast"
        [ngClass]="{'has-reservation': getOptions().hasReservation}"
        infinite-scroll
        [infiniteScrollDistance]="10"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        [alwaysCallback]="true"
        (scrolled)="onScrollDown()">
        <li class="no-slots-error"
            translate="label.thereAreNoResultsForYourSearch"
            *ngIf="getOptions()?.noSlotsFound">
        </li>
        <li class="no-slots-error"
            translate="label.thereAreNoResultsForTheSelectedDay"
            *ngIf="!getOptions()?.noSlotsFound && getOptions()?.noSlotsOnSelectedDay">
        </li>
        <li class="list-group-item"
            *ngFor="let slot of getOptions()?.displaySlots?.slice(0, getOptions()?.slotsResultsSliceUpperBound); let isFirstSlot = first; let isLastSlot = last"
            [ngClass]="{'not-bookable': !slot.bookable, 'marked': slot.marked, 'hovered': slot.hovered}"
            (click)="onSelectSlot(slot)"
            (mouseenter)="onMouseEnterSlot(slot)"
            (mouseleave)="onMouseLeaveSlot(slot)"
        >
            <!-- SUN FOR FIRST AM SLOT AND PM SLOT -->
            <span *ngIf="slot?.isFirstAMTimeSlot" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <img src="./assets/images/icon/Sun.png">
            </span>
            <span *ngIf="slot?.isFirstPMTimeSlot" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <img src="./assets/images/icon/Sundown.png">
            </span>
            <span *ngIf="!slot?.isFirstAMTimeSlot && !slot?.isFirstPMTimeSlot" class="empty-space pr-1"></span>
            <!-- WARNINGS FOR RULE MESSAGES -->
            <span class="pr-1" *ngIf="slot?.messages?.length > 0">
                <div [ngbPopover]="messages"
                     triggers="mouseenter:mouseleave"
                     popoverClass="popover-shadow"
                     [placement]="isFirstSlot ? 'bottom-left' : isLastSlot ? 'top-left' : 'right'"
                     class="fa fa-exclamation-triangle"
                     [ngClass]="{'has-error': !slot?.bookable, 'has-warning': slot?.bookable}">
                </div>
                <ng-template #messages>
                        <div *ngFor="let message of slot.messages; isLast as last">
                            {{message?.name}}
                            <br *ngIf="!last">
                        </div>
                </ng-template>
            </span>
            <span *ngIf="slot?.messages?.length === 0" class="empty-space pr-1"></span>
            <!-- TIME SLOT Normal Slots-->
            <span class="time-container font-weight-bold pr-1" *ngIf="!getOptions()?.specialAppointmentBooking"
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.time}}</span>
            <!-- TIME SLOT Special Appointment-->
            <span class="time-container font-weight-bold pr-1" *ngIf="getOptions()?.specialAppointmentBooking"
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.timeInterval}}</span>
            <!-- CENTER NAME -->
            <span class="center-container pr-1" [ngClass]="{'op-5': !slot.bookable}">{{slot.center?.name}},</span>
            <!-- RESOURCE NAME Normal Slots-->
            <span class="pr-1" *ngIf=!getOptions()?.specialAppointmentBooking
                  [ngClass]="{'op-5': !slot.bookable}">{{slot.resource?.name}}</span>
            <!-- RESOURCE NAME Special Appointment-->
            <span class="pr-1" *ngIf=getOptions()?.specialAppointmentBooking
                  [ngClass]="{'op-5': !slot.bookable, 'available': slot.isCurrentlyAvailable, 'not-available': !slot.isCurrentlyAvailable}">
                {{slot.resource?.name}}</span>
            <span *ngIf="!slot.resource?.additionalInformation" class="empty-space pr-1"></span>
            <!-- ADDITIONAL INFORMATION -->
            <app-slot-resource-additional-info [options]="getSlotResourceAdditionalInfoOptions(slot, true, isFirstSlot, isLastSlot)">
            </app-slot-resource-additional-info>
            <!-- SECONDARY RESOURCE -->
            <span *ngIf="slot.secondaryResources?.length === 1" class="pr-1" [ngClass]="{'op-5': !slot.bookable}">
                <!-- NAME -->
                <span class="pr-1">{{slot.secondaryResources[0]?.name}}</span>
                <!-- ADDITIONAL INFORMATION -->
                <app-slot-resource-additional-info [options]="getSlotResourceAdditionalInfoOptions(slot, false, isFirstSlot, isLastSlot)">
                </app-slot-resource-additional-info>
            </span>
            <!-- ONLINE CONSULTATION -->
            <span *ngIf="slot.service?.onlineConsultation" class="pr-1 float-right online-consultation-container"
                  [ngClass]="{'op-5': !slot.bookable}">
                <i class="fas fa-desktop"
                   [ngbPopover]="'label.onlineConsultation' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot.service?.onlineConsultation" class="empty-space pr-1 float-right"></span>
            <!-- IS SELF PAYER -->
            <span *ngIf="slot.isSelfPayer" class="pr-1 float-right" [ngClass]="{'op-5': !slot.bookable}">
                <i class="fas fa-dollar-sign"
                   [ngbPopover]="'label.selfPayer' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot.isSelfPayer" class="empty-space pr-1 float-right"></span>
            <!-- OVERSOLD -->
            <span *ngIf="slot?.oversellingDefinitionId && getOptions()?.expertBookingMode" class="pr-1 float-right"
                  [ngClass]="{'op-5': !slot.bookable}">
                <i class="fa-regular fa-file-invoice-dollar"
                   [ngbPopover]="'label.oversold' | translate"
                   triggers="mouseenter:mouseleave"
                   popoverClass="popover-shadow"
                   placement="left"></i>
            </span>
            <span *ngIf="!slot?.oversellingDefinitionId" class="empty-space pr-1 float-right"></span>
            <!-- SECONDARY RESOURCES ADDITIONAL INFORMATION -->
            <app-slot-resource-additional-info *ngIf="slot?.secondaryResources?.length > 1"
                                               [options]="getSlotResourceAdditionalInfoOptions(slot, false, isFirstSlot, isLastSlot)">
            </app-slot-resource-additional-info>
            <span *ngIf="slot?.secondaryResources?.length === 0" class="empty-space pr-1 float-right"></span>
            <div *ngIf="getOptions()?.specialAppointmentBooking"
                 [ngClass]="{'ml-5': slot?.messages?.length > 0, 'ml-4': slot?.messages?.length <= 0 }">
                <span class="pl-1 pr-1" *ngIf="slot.appointmentType?.duration">{{'label.duration' | translate}}
                    : {{slot.appointmentType?.duration}} </span>
                <span class="pl-1 pr-1" *ngIf="!slot.appointmentType?.duration">{{'label.duration' | translate}}
                    : - </span>
                <span class="empty-space pr-1"></span>
                <span class="pr-1" *ngIf="slot.appointmentType?.numberOfSlots">{{'label.quantity' | translate}}
                    : {{slot.appointmentType?.numberOfSlots}} </span>
                <span class="pr-1" *ngIf="!slot.appointmentType?.numberOfSlots">{{'label.quantity' | translate}}
                    : - </span>
            </div>
        </li>
    </ul>
</div>
