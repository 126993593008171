import {Component, OnDestroy, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {IdleTimerService} from '../../shared/services/idle-timer.service';
import {SessionStorageService} from '@efaps/ngx-store';
import {Router} from '@angular/router';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    constructor(
        private idleTimerService: IdleTimerService,
        private sessionStorageService: SessionStorageService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.idleTimerService.init();
        const redirectUrlAfterSignIn = this.sessionStorageService.get('redirectUrlAfterSignIn');
        this.sessionStorageService.remove('redirectUrlAfterSignIn');
        if (redirectUrlAfterSignIn && redirectUrlAfterSignIn !== 'dashboard') {
            this.sessionStorageService.set('redirectedAutomaticallyAfterSignIn', true);
            this.router.navigate([`/${redirectUrlAfterSignIn}`]);
            setTimeout(() => {
                // Remove after a while for safety measures
                this.sessionStorageService.remove('redirectedAutomaticallyAfterSignIn');
            }, 8000);
        }
    }

    ngOnDestroy(): void {
    }

}
