import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {EventActionMDComponent} from './event-action-md.component';
import {CreateEventActionComponent} from './create-event-action/create-event-action.component';

@NgModule({
    declarations: [EventActionMDComponent, CreateEventActionComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class EventActionMDModule { }
