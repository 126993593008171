<div *ngIf="formGroup && controlName && dynamicFormInputService.hasError(formGroup, controlName)"
     class="help-block">
    <!-- We use *appVar here so we don't need to call dynamicFormInputService.getErrorMessage() four different times -->
    <ng-container *appVar="dynamicFormInputService.getErrorMessage(formGroup, controlName) as error">
        <span *ngIf="error.shouldTranslateMessage">
            {{error.message | translate:error?.params}}
        </span>
        <span *ngIf="!error.shouldTranslateMessage">
            {{error.message}}
        </span>
    </ng-container>
</div>
