import {Injectable} from '@angular/core';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ActionType, ODataOrderByQueryType, TableFiltersType} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import {
    AvailabilityDataAppointmentTypeType,
    AvailabilityDataServiceType,
    AvailabilityDataTimeSlotType,
    AvailabilityDataType,
    AvailabilityOverviewFilterType,
    AvailabilityServiceWithTagsType,
    AvailabilityTimeSlotType,
    AvailabilityValidationTypeEnum,
    AvailabilityWithTagsType, DateRangeOptionsType,
    ODataQueryObjectType,
    RepetitionIntervalEnum,
    ServiceSearchType
} from 'sked-base';
import * as lodash from 'lodash';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import {
    AvailabilityExclusionIntraPageContextStateFiltersType,
    AvailabilityStatusEnum,
    AvailabilityViewPageEnum,
    BackofficeAvailabilityDataType,
    BackofficeAvailabilityServiceType,
    BackofficeAvailabilityTimeSlotAppointmentTypeType,
    BackofficeAvailabilityTimeSlotType
} from './availability.types';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {MessagesService} from '../../shared/services/messages.service';
import {AvailabilitySubServiceType} from 'sked-base/lib/data-model/availabilityTypes';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {TimeSlotsUtils} from '../../shared/utils/time-slots.utils';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityUtils {
    filtersState: AvailabilityExclusionIntraPageContextStateFiltersType = {};

    constructor(public generalUtils: GeneralUtils,
                private dateTimeUtils: DateTimeUtils,
                private messagesService: MessagesService,
                private configDataService: ConfigDataService,
                private timeSlotsUtils: TimeSlotsUtils) {
    }

    getFilterByViewType(availabilityPage: AvailabilityViewPageEnum) {
        return {
            name: '',
            requestedIntervalKind: IntervalTypeEnum.OVERLAP,
            statuses: this.getStatusesFilterByViewType(availabilityPage)
        };
    }

    getStatusesFilterByViewType(availabilityPage: AvailabilityViewPageEnum) {
        switch (availabilityPage) {
            case AvailabilityViewPageEnum.AvailabilityOverview:
                return [AvailabilityStatusEnum.NEW, AvailabilityStatusEnum.APPROVED, AvailabilityStatusEnum.MARKED_FOR_DELETE];
            case AvailabilityViewPageEnum.ApproveAvailabilityOverview:
                return [AvailabilityStatusEnum.NEW, AvailabilityStatusEnum.EDITED, AvailabilityStatusEnum.MARKED_FOR_DELETE,
                    AvailabilityStatusEnum.PENDING_SPLIT];
            default:
                return undefined;
        }
    }

    getQueryFilterForAvailabilityOverview(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            orderBy: this.getOrderByQuery(tableFilters.orderBy)
        };
    }

    getRequestBodyForAvailabilityOverview(filters: any): AvailabilityOverviewFilterType {
        const body: AvailabilityOverviewFilterType = {} as AvailabilityOverviewFilterType;

        body.resourceId = filters?.resource?.id;
        body.serviceId = filters?.service?.id;
        body.centerId = filters?.location?.id;
        body.areaId = filters?.area?.id;
        body.validFrom = filters?.validFrom;
        body.validTo = filters?.validTo;
        if (filters.validFrom || filters.validTo) {
            body.requestedIntervalKind = filters.requestedIntervalKind;
        }
        body.statuses = filters?.statuses;
        body.shortId = filters?.shortId;
        body.onlineConsultation = filters?.onlineConsultation;
        body.roomReservationNeeded = filters?.roomReservationNeeded;
        body.ignoreHolidays = filters?.ignoreHolidays;

        return body;
    }

    getApproveAvailabilityTemplateTitle(action: string): string {
        if (action === constants.APPROVE) {
            return 'label.approveAvailabilityTab.approveText';
        } else if (action === constants.DECLINE) {
            return 'label.approveAvailabilityTab.declineText';
        }
    }

    mapItemToAvailabilityWithTags(availability: AvailabilityDataType): AvailabilityWithTagsType {
        const availabilityWithTags = lodash.cloneDeep(availability) as undefined as AvailabilityWithTagsType;

        availabilityWithTags.tags = lodash.map(availability.tags, 'id');
        for (const timeSlot of availabilityWithTags.timeSlots) {
            timeSlot.interval = {
                hourFrom: lodash.find(availability.timeSlots, {id: timeSlot.id}).hourFrom,
                hourTo: lodash.find(availability.timeSlots, {id: timeSlot.id}).hourTo
            };
            timeSlot.timeSlotAppointmentTypes = lodash.find(availability.timeSlots, {id: timeSlot.id}).appointmentTypes;
        }
        availabilityWithTags.availabilityServices = [];
        for (const service of availability.services) {
            const availabilityService = lodash.find(availability.services, {id: service.id});
            availabilityWithTags.availabilityServices.push(availabilityService);
        }

        delete availabilityWithTags.center;
        delete availabilityWithTags.resource;

        return availabilityWithTags;
    }

    getInitialAvailabilityDataValue(): BackofficeAvailabilityDataType {
        const initialAvailability = {} as BackofficeAvailabilityDataType;
        initialAvailability.resource = undefined;
        initialAvailability.center = undefined;
        initialAvailability.advancedMode = false;
        initialAvailability.roomReservationNeeded = false;
        initialAvailability.validFrom = moment().format('YYYY-MM-DD');
        initialAvailability.validTo = moment().format('YYYY-MM-DD');
        initialAvailability.endOfDay = true;
        initialAvailability.repetition = 1;
        initialAvailability.unit = RepetitionIntervalEnum.Weekly;
        initialAvailability.tags = [];
        initialAvailability.services = [];
        initialAvailability.timeSlots = [];
        return initialAvailability;
    }

    getInitialScopedService(): BackofficeAvailabilityServiceType {
        const serviceData = {} as BackofficeAvailabilityServiceType;

        serviceData.groupAppointmentSlots = 1;
        serviceData.duration = 0;
        serviceData.controlDuration = 0;
        serviceData.showDurationForMainResource = true;
        serviceData.tags = [];
        serviceData.subServices = [];
        serviceData.coveragePlans = [];
        serviceData.onlineConsultation = false;

        return serviceData;
    }

    getInitialTimeSlot(): BackofficeAvailabilityTimeSlotType {
        const initialTimeSlot = {} as BackofficeAvailabilityTimeSlotType;
        initialTimeSlot.selectedDays = [];
        initialTimeSlot.hourFromTime = '08:00';
        initialTimeSlot.hourFrom = 480;
        initialTimeSlot.hourToTime = '18:00';
        initialTimeSlot.hourTo = 1080;
        initialTimeSlot.appointmentTypes = [];
        return initialTimeSlot;
    }

    getInitialAppointmentType(): BackofficeAvailabilityTimeSlotAppointmentTypeType {
        return {
            id: undefined,
            name: undefined,
            duration: null,
            quantity: null,
            consumesPlannedCapacity: undefined,
            appointmentTypeId: undefined
        };
    }

    mapTimeSlotFromViewToSave(timeSlot: BackofficeAvailabilityTimeSlotType): AvailabilityDataTimeSlotType {
        const updatedTimeSlot = {} as AvailabilityDataTimeSlotType;
        const weekDays: string[] = this.timeSlotsUtils.getWeekDays();
        updatedTimeSlot.hourFrom = this.dateTimeUtils.getMinutesFromStringHours(timeSlot.hourFromTime);
        updatedTimeSlot.hourTo = this.dateTimeUtils.getMinutesFromStringHours(timeSlot.hourToTime);
        for (const day of weekDays) {
            updatedTimeSlot[day] = (lodash.indexOf(timeSlot.selectedDays, day) > -1);
        }
        updatedTimeSlot.appointmentTypes = [];
        for (const appointmentType of timeSlot.appointmentTypes) {
            const updatedAppointmentType = {} as AvailabilityDataAppointmentTypeType;
            if (appointmentType.appointmentTypeId) {
                updatedAppointmentType.id = appointmentType.appointmentTypeId;
            } else {
                updatedAppointmentType.id = appointmentType.id;
            }
            updatedAppointmentType.name = appointmentType.name;
            updatedAppointmentType.duration = appointmentType.duration;
            updatedAppointmentType.quantity = appointmentType.quantity;
            updatedAppointmentType.consumesPlannedCapacity = appointmentType.consumesPlannedCapacity;
            updatedTimeSlot.appointmentTypes.push(updatedAppointmentType);
        }
        return updatedTimeSlot;
    }

    validateTimeSlot(newTimeSlot: BackofficeAvailabilityTimeSlotType): boolean {
        let valid = true;
        if (!newTimeSlot) {
            //timeSlot is not filled
            valid = false;
            this.messagesService.error('toastr.error.noTimeSlot');
        } else if (!newTimeSlot.hourFromTime || !newTimeSlot.hourToTime) {
            //timeSlot intervalinvalid
            valid = false;
            this.messagesService.error('toastr.error.invalidTimeSlot');
        } else if (newTimeSlot?.selectedDays?.length === 0) {
            //days are not checked
            valid = false;
            this.messagesService.error('toastr.error.slotNoDaySelected');
        } else if (moment(newTimeSlot.hourFromTime, 'HH:mm').diff(moment(newTimeSlot.hourToTime, 'HH:mm'), 'minutes') >= 0) {
            //validFrom > validTo
            valid = false;
            this.messagesService.error('toastr.error.slotInvalidPeriod');
        } else if (!this.validateTimeSlotAppointmentType(newTimeSlot.appointmentTypes)) {
            // invalid timeslot
            valid = false;
        }
        /*//timeSlot exist
        else {
            const auxTimeSlot = createTimeSlotItem(lodash.cloneDeep(newTimeSlot));
            const overlappInterval = getOverlapping(timeSlotsArray, auxTimeSlot);
            if (overlappInterval.overlapp) {
                valid = false;
                this.messagesService.error('toastr.error.slotOverlapping');
            }
        }*/
        return valid;
    }

    validateTimeSlotAppointmentType(appointmentTypes: BackofficeAvailabilityTimeSlotAppointmentTypeType[]): boolean {
        for (const appointmentType of appointmentTypes) {
            const duplicateAppointmentTypes = lodash.filter(appointmentTypes, {id: appointmentType.id});
            if (duplicateAppointmentTypes?.length > 1) {
                this.messagesService.error('toastr.error.duplicateAppointmentTypeInTimeSlot');
                return false;
            }
            if (!appointmentType.id) {
                this.messagesService.error('toastr.error.slotInvalidAppointmentType');
                return false;
            }
            if (!appointmentType.consumesPlannedCapacity) {
                if (!appointmentType.quantity) {
                    this.messagesService.error('toastr.error.invalidAppointmentTypeQuantity');
                    return false;
                }
                //duration must be null or >0
                if (appointmentType.duration !== null &&
                    (appointmentType.duration === 0 || appointmentType.duration === undefined)) {
                    this.messagesService.error('toastr.error.invalidAppointmentTypeDuration');
                    return false;
                }
            }
        }
        return true;
    }

    mapAvailabilityDataServiceToServiceSearch(availabilityDataService: AvailabilityDataServiceType): ServiceSearchType {
        let serviceSearch: ServiceSearchType = {} as ServiceSearchType;

        serviceSearch = availabilityDataService as unknown as ServiceSearchType;
        serviceSearch.id = availabilityDataService.serviceId;

        return serviceSearch;
    }

    isScopedServiceValid(scopedService: BackofficeAvailabilityServiceType): boolean {
        return true;
    }

    mapAvailabilityForSend(availabilityItem: BackofficeAvailabilityDataType): AvailabilityWithTagsType {
        const availabilityToAdd: AvailabilityWithTagsType = lodash.cloneDeep(availabilityItem) as unknown as AvailabilityWithTagsType;
        availabilityToAdd.tags = lodash.map(availabilityItem.tags, 'id');
        availabilityToAdd.validFrom = moment.tz(moment(availabilityItem.validFrom).parseZone()
            .format('YYYY-MM-DD HH:mm'), availabilityItem.timeZoneId).format();

        const actualValidTo = availabilityItem.validTo; // already transformed in onStepChange
        availabilityToAdd.validTo = moment.tz(moment(actualValidTo).parseZone()
            .format('YYYY-MM-DD HH:mm'), availabilityItem.timeZoneId).format();

        // because we always have center id, we don't need to send timeZoneId
        delete availabilityToAdd.timeZoneId;
        //map timeslots for send
        availabilityToAdd.timeSlots = [];
        for (const timeSlot of availabilityItem.timeSlots) {
            const timeSlotToSend = timeSlot as unknown as AvailabilityTimeSlotType;
            timeSlotToSend.interval = {hourFrom: timeSlot.hourFrom, hourTo: timeSlot.hourTo};
            timeSlotToSend.timeSlotAppointmentTypes = [];
            for (const appointmentType of timeSlot.appointmentTypes) {
                const appointmentTypeToSend: AvailabilityDataAppointmentTypeType = {} as AvailabilityDataAppointmentTypeType;

                if (appointmentType.appointmentTypeId) {
                    appointmentTypeToSend.appointmentTypeId = appointmentType.appointmentTypeId;
                } else {
                    appointmentTypeToSend.appointmentTypeId = appointmentType.id;
                }
                appointmentTypeToSend.consumesPlannedCapacity = appointmentType.consumesPlannedCapacity;
                if (!appointmentType.consumesPlannedCapacity) {
                    appointmentTypeToSend.duration = appointmentType.duration;
                    appointmentTypeToSend.quantity = appointmentType.quantity;
                }

                timeSlotToSend.timeSlotAppointmentTypes.push(appointmentTypeToSend);
            }
            availabilityToAdd.timeSlots.push(timeSlotToSend);
        }

        //map availabilityServices for send
        availabilityToAdd.availabilityServices = [];
        for (const service of availabilityItem.services) {
            const availabilityServiceWithTags = lodash.cloneDeep(service) as unknown as AvailabilityServiceWithTagsType;
            availabilityServiceWithTags.tags = lodash.map(service.tags, 'id');
            availabilityServiceWithTags.coveragePlans = lodash.map(service.coveragePlans, 'id');
            availabilityServiceWithTags.availabilitySubServices = service.subServices as unknown as AvailabilitySubServiceType[];
            availabilityToAdd.availabilityServices.push(availabilityServiceWithTags);
        }

        // for now we don't use advance mode flag for coverage plans => so we make sure it is always set to true
        // in order to make the coverage plans visible also on old backoffice
        availabilityToAdd.advancedMode = true;

        return availabilityToAdd;

    }

    isAvailabilityServiceValid(availabilityService: BackofficeAvailabilityServiceType, availabilityServices: AvailabilityDataServiceType[]) {
        if (!availabilityService?.service?.id) {
            return false;
        }

        if (availabilityService.showDurationForMainResource && !this.isScopedServiceDurationValid(availabilityService)) {
            return false;
        }

        if (this.configDataService.isFeatureActive('control-patient-service-duration')
            && availabilityService.showDurationForMainResource
            && !this.isScopedServiceControlDurationValid(availabilityService)) {
            return false;
        }

        if (this.configDataService.isFeatureActive('admin-subServices') &&
            availabilityService.service.hasSubServices && availabilityService.subServices?.length === 0) {
            return false;
        }

        if (!availabilityService.groupAppointmentSlots) {
            return false;
        }
        if (availabilityService.editedIndex === null || availabilityService.editedIndex === undefined) {
            if (lodash.find(availabilityServices, {serviceId: availabilityService.id})) {
                return false;
            }
        }

        return true;
    }

    isGeneralInfoStepValid(availabilityData: AvailabilityDataType) {
        return !!(availabilityData.resourceId && availabilityData?.resource?.id &&
            availabilityData.centerId && availabilityData?.center?.id);
    }

    isServicesStepValid(availabilityData: AvailabilityDataType, isServiceCardOpened: boolean): boolean {
        return (availabilityData?.services?.length > 0 && !isServiceCardOpened);
    }

    isTimeSlotsStepValid(availabilityData: AvailabilityDataType, isTimeSlotCardOpened: boolean): boolean {
        const validRangeValidation = this.dateTimeUtils.getValidateDateAndTimeRange(
            availabilityData.validFrom,
            availabilityData.validTo,
            availabilityData.endOfDay,
            availabilityData.timeZoneId
        );
        return !!availabilityData?.validFrom && !!availabilityData?.validTo && validRangeValidation.isValid &&
            (availabilityData?.timeSlots?.length > 0) && !isTimeSlotCardOpened;
    }

    isScopedServiceDurationValid(scopedService: BackofficeAvailabilityServiceType): boolean {
        if (scopedService?.service?.id) {
            //if service doesn't have sub services than duration must be > 0
            if (!this.configDataService.isFeatureActive('admin-subServices') ||
                !scopedService.service.hasSubServices ||
                (scopedService.service.hasSubServices && (scopedService.minSubServices === 0 || scopedService.minSubServices === null))) {
                return (scopedService.duration > 0);
            } else if (!scopedService.duration) {
                // check all assigned sub services duration to be > 0
                const foundSubServiceWithDuration0 = lodash.find(scopedService.subServices, (item) => {
                    return !item.duration;
                });
                return !foundSubServiceWithDuration0;
            } else {
                return true;
            }
        }
        return true;
    }

    isScopedServiceControlDurationValid({service, controlDuration}: BackofficeAvailabilityServiceType): boolean {
        if (service?.id) {
            return controlDuration > 0;
        }
        return true;
    }

    getQueryFilterForServices(service: ServiceSearchType): ODataQueryObjectType {
        return {
            count: true,
            select: ['Id', 'DefaultDuration', 'MinSubServices', 'MaxSubServices', 'HasSubServices', 'MultiResourceBluePrintId'],
            filter: {Id: {eq: {type: 'guid', value: service.id}}},
        };
    }

    getTypeForValidateAvailability(action: ActionType): AvailabilityValidationTypeEnum {
        switch (action) {
            case ActionType.Create:
                return AvailabilityValidationTypeEnum.Add;
            case ActionType.Edit:
                return AvailabilityValidationTypeEnum.Edit;
            case ActionType.Approve:
                return AvailabilityValidationTypeEnum.Approve;
        }
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        const dateRangeOptions: DateRangeOptionsType = this.dateTimeUtils.getInitialDateRangeOptions();
        dateRangeOptions.fromDateLabel = 'label.validFrom';
        dateRangeOptions.toDateLabel = 'label.validTo';
        return dateRangeOptions;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                if (item === 'resource') {
                    orderByQuery.push(lodash.upperFirst(item) + '/Name ' + orderBy[item]);
                    orderByQuery.push('Center/Name ' + orderBy[item]);
                } else {
                    orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }
}
