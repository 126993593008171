import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {ScreenTemplateLayoutType} from '../../data-model/general.type';
import {constants} from '../../shared/constants/constants';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ValidUserType} from './user.types';
import {UserMDUtils} from './user-md.utils';
import {
    CenterProvider,
    LocationDependentFiltersType,
    ResourceDependentFiltersType,
    ResourceProvider,
    TeamProvider,
    TeamType,
    UserProvider,
    UserTypeEnum
} from 'sked-base';
import {take} from 'rxjs/operators';
import * as crypto from 'crypto-js';
import {UserType} from 'sked-base/lib/data-model/userTypes';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {MultiSelectTableOptionsType} from '../../shared/component/multi-select-table/multi-select-table.types';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-user-md',
    templateUrl: './create-user-md.component.html',
    styleUrls: ['./create-user-md.component.scss']
})
export class CreateUserMDComponent implements OnInit, AfterViewInit, OnDestroy {
    screenTemplateLayout: ScreenTemplateLayoutType;
    userItem: UserType = {} as UserType;
    validTemplate: ValidUserType;
    historyStateValue: any = history.state;
    initialUser: UserType;
    constants = constants;
    teamButtonName = 'label.userManagementTab.team';
    resourceButtonName = 'label.resource';
    centerButtonName = 'label.centers';
    mainDependentFilters: {
        location: LocationDependentFiltersType,
        resource: ResourceDependentFiltersType
    };
    initialTeamValues: any[] = [];
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    totalStepsCount: number;
    UserTypeList = Object.values(UserTypeEnum);
    centerMultiSelectTableOptions: MultiSelectTableOptionsType;
    @ViewChild('stepper', {static: true}) private myStepper: MatStepper;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public userMDUtils: UserMDUtils,
        public userProvider: UserProvider,
        public teamProvider: TeamProvider,
        public resourceProvider: ResourceProvider,
        public centerProvider: CenterProvider,
        private changeDetectorRef: ChangeDetectorRef,
        private configDataService: ConfigDataService
    ) {
    }

    ngOnInit() {
        this.setInitialSetup();

        this.mainDependentFilters = {
            location: this.userMDUtils.getLocationDependentFilters(),
            resource: this.userMDUtils.getResourceDependentFilters(),
        };
    }

    ngAfterViewInit() {
        this.totalStepsCount = this.myStepper._steps.length;
        //  it's triggered when change detection for all child components have been
        //  performed and so they all had possibility to update parent components property
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    onSelectedResource(resourceFilterList): void {
        // this.initialResourceValues = resourceFilterList;
        if (resourceFilterList.length > 0) {
            this.userItem.resourceId = resourceFilterList[0].id;
            this.userItem.resource = resourceFilterList[0];
        } else {
            this.userItem.resourceId = undefined;
            this.userItem.resource = undefined;
        }
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
    }

    onSelectedTeam(teamFilterList): void {
        if (teamFilterList.length > 0) {
            this.userItem.teamId = teamFilterList[0].id;
            this.userItem.team.name = teamFilterList[0].name;
        } else {
            this.userItem.teamId = undefined;
        }
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
    }

    onSelectedCenter(selectedCenters): void {
        this.userItem.centers = selectedCenters;
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
    }

    removeCenterFromList(list: any[], item: any, userItem: UserType) {
        lodash.remove(list, {id: item.id});
        // const isItemAddedOnEditAction = this.userMDUtils.objectAddedOnEditAction(this.initialUser, item, 'centers');
        // if (item && userItem) {
        //     if (this.screenTemplateLayout.action === constants.EDIT && isItemAddedOnEditAction) {
        //         lodash.remove(userItem.centers, {id: item.id});
        //         this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
        //     } else {
        //         lodash.remove(list, {id: item.id});
        //         this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
        //     }
        // }
    }

    cancelUserData() {
        this.goToParentPage();
    }

    goToParentPage() {
        this.router.navigate(['/users']);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        stepper.next();
    }

    onStepChange(e) {
    }

    isLinearStepperOrNot(validTemplate: ValidUserType) {
        for (const property in validTemplate) {
            if (validTemplate[property] === false) {
                return true;
            }
        }

        return false;
    }

    onRemoveExternalKeyItem(index: number) {
        this.userItem.userExternalKeys.splice(index, 1);
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
        this.areStepsValid(this.validTemplate, 1);
    }

    onAddExternalKeyItem() {
        this.userItem.userExternalKeys.push(this.userMDUtils.getEmptyExternalKeyItem());
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
        this.areStepsValid(this.validTemplate, 1);
    }

    areStepsValid(validTemplate: ValidUserType, currentStep): boolean {
        switch (currentStep) {
            case 0:
                return (validTemplate.isUsernameValid && validTemplate.isPasswordValid && validTemplate.email.isValid &&
                    validTemplate.isTeamValid);
            case 1:
                return validTemplate.areExternalKeysValid && validTemplate.isResourceValid;
            default:
                return true; // other steps which don't need validation
        }
    }

    onInputChanged(user) {
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
    }

    onUserTypeChanged(type: UserTypeEnum) {
        if (type === UserTypeEnum.API && this.screenTemplateLayout.action === constants.EDIT) {
            this.messagesService.warning('toastr.warning.changingUserTypeToApiWarning', true);
        }
    }

    saveUserData(user: UserType) {
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
        if (this.validTemplate.isUsernameValid && this.validTemplate.email.isValid && this.validTemplate.isPasswordValid
            && this.validTemplate.isTeamValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createUserData(user);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialUser, user)) {
                    this.messagesService.success('toastr.success.employeeEdit', true);
                    this.goToParentPage();
                } else {
                    this.editUserData(this.initialUser, user);
                }
            }
        }
    }

    createUserData(user: UserType) {
        const userToCreate = lodash.cloneDeep(user);
        if (!this.isDisablePasswordHashingSystemConfigEnabled()) {
            userToCreate.password = crypto.SHA512(user.password).toString();
        }

        this.ngxLoader.start();
        this.userProvider.addEntry(userToCreate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newEmployeeAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                if (err.status === 400 && err.error.value && lodash.includes(err.error.value, 'A user with this username already exists')) {
                    this.messagesService.error('toastr.error.userNotUnique');
                } else {
                    this.messagesService.handlingErrorMessage(err);
                }
            });
    }

    editUserData(oldUser: UserType, newUser: UserType) {
        const userToEdit = lodash.cloneDeep(newUser);
        if (userToEdit.password !== undefined && userToEdit.password !== null && !this.isDisablePasswordHashingSystemConfigEnabled()) {
            userToEdit.password = crypto.SHA512(userToEdit.password).toString();
        }

        this.ngxLoader.start();
        this.userProvider.updateEntry(oldUser, userToEdit)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.employeeEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    goToEdit() {
        history.replaceState({
            user: this.userItem,
            action: constants.EDIT
        }, '');

        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    checkIfUserIsTypeApiAndHasApiKeys(): boolean {
        return (this.userItem?.type === UserTypeEnum.API && this.userItem?.apiKeys?.length > 0);
    }

    private setInitialSetup() {
        if (this.historyStateValue && this.historyStateValue.user) {
            this.initialUser = this.historyStateValue.user;
            this.setInitialFilterComponentArrays(this.initialUser);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
            this.userItem = lodash.cloneDeep(this.historyStateValue.user);
            if (!this.userItem.team) {
                this.userItem.team = {} as TeamType;
            }
        } else {
            this.userItem = this.userMDUtils.getInitialUser();
            this.initialUser = this.userMDUtils.getInitialUser();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.centerMultiSelectTableOptions = this.userMDUtils.getCenterMultiSelectTableOptions(this.initialUser, this.screenTemplateLayout.action);
        this.validTemplate = this.userMDUtils.getValidatedFields(this.userItem);
    }

    private setInitialFilterComponentArrays(initialUser: UserType) {
        if (initialUser.team) {
            this.initialTeamValues.push(initialUser.team);
        }
        if (initialUser.resource) {
            this.initialResourceValues.push(initialUser.resource);
        }
        if (initialUser.centers) {
            this.initialCenterValues = initialUser.centers;
        }
    }

    private isDisablePasswordHashingSystemConfigEnabled(): boolean {
        const systemConfigValues = this.configDataService.systemConfig?.value ?? [];
        return !!this.generalUtils.getSystemConfigValue(systemConfigValues, 'DisablePasswordHashing', true);
    }
}
