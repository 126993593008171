import {Component, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    EntityTypeEnum,
    ResourceTypeProvider,
    ResourceTypeType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ResourceTypeMdUtils} from '../resouce-type-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {UploadFileUtils} from '../../../shared/component/upload-file/upload-file.utils';
import {UploadFileOptionsType} from '../../../shared/component/upload-file/upload-file.types';

@Component({
    selector: 'app-create-resource-type',
    templateUrl: './create-resource-type.component.html',
    styleUrls: ['./create-resource-type.component.scss']
})
export class CreateResourceTypeComponent implements OnInit {
    constants = constants;
    initialResourceType: ResourceTypeType;
    resourceTypeItem: ResourceTypeType = {} as ResourceTypeType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    isTemplateValid: boolean;
    uploadFileOptions: UploadFileOptionsType;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private resourceTypeMdUtils: ResourceTypeMdUtils,
        public resourceTypeProvider: ResourceTypeProvider,
        public router: Router,
        public generalUtils: GeneralUtils,
        public uploadFileUtils: UploadFileUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    saveResourceTypeData(resourceType: ResourceTypeType) {
        this.isTemplateValid = this.validateFields(resourceType);
        if (this.isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createResourceType(resourceType);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                // If the objects are equal, we don't want to make a request because nothing was changed
                if (lodash.isEqual(this.initialResourceType, resourceType) && lodash.isEqual(this.uploadFileUtils.initialUploadedFile, this.uploadFileUtils.uploadedFile)) {
                    this.messagesService.success('toastr.success.resourceTypeEdit', true);
                    this.goToParentPage();
                } else {
                    this.editResourceType(this.initialResourceType, resourceType);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/resourceTypes']);
    }

    onInputChanged(resourceType: ResourceTypeType) {
        this.isTemplateValid = this.validateFields(resourceType);
    }

    goToEdit() {
        history.replaceState({
            resourceType: this.resourceTypeItem,
            action: this.constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new resource type
    private createResourceType(resourceType: ResourceTypeType) {
        this.ngxLoader.start();
        this.resourceTypeProvider.addEntry(resourceType)
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.resourceType);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newResourceTypeAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the resource type
    private editResourceType(oldResourceType: ResourceTypeType, newResourceType: ResourceTypeType) {
        this.ngxLoader.start();
        this.resourceTypeProvider.updateEntry(oldResourceType, newResourceType)
            .pipe(take(1))
            .subscribe((response) => {
                const {id} = response as any;
                this.uploadFileUtils.parseAndSendFile(id, EntityTypeEnum.resourceType);
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.resourceTypeEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(resourceTypeItem: ResourceTypeType): boolean {
        return !!(resourceTypeItem && resourceTypeItem.name);
    }

    private setupInitialState() {
        if (history.state && history.state.resourceType) {
            this.initialResourceType = history.state.resourceType;
            this.resourceTypeItem = lodash.cloneDeep(history.state.resourceType);
            if (this.resourceTypeItem.hasImage) {
                this.uploadFileUtils.getUploadedFile(this.resourceTypeItem.id, EntityTypeEnum.resourceType);
            }
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.resourceTypeItem = this.resourceTypeMdUtils.getInitialResourceType();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.uploadFileOptions = {
            entityType: EntityTypeEnum.resourceType,
            disabled: this.screenTemplateLayout.action === constants.VIEW,
            itemId: this.resourceTypeItem?.id,
            labelText: 'label.imageForPatientAccess'
        };
        this.isTemplateValid = this.validateFields(this.resourceTypeItem);
    }
}
