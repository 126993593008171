import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {
    ActivityPlanTemplateDisplayCalendarDayType,
    ActivityPlanTemplateDisplayCalendarPageType
} from '../../activity-plan-template-md.types';
import {Subject} from 'rxjs';
import {ActivityPlanTemplateMdUtils} from '../../activity-plan-template-md-util';
import {
    ActivityPlanScheduledEndTypeEnum,
    ActivityPlanProvider,
    GenerateOccurrencePreviewBodyType,
    GenerateOccurrencePreviewResponseOuterType
} from 'sked-base';
import {MessagesService} from '../../../../shared/services/messages.service';
import {DaysWithBannerType} from '../../../activity-plan/activity-plan.types';
import {GenerateOccurrencePreviewResponseType} from 'sked-base/lib/data-model/activityPlanTypes';

@Component({
    selector: 'app-activity-plan-template-display-calendar',
    templateUrl: './activity-plan-template-display-calendar.component.html',
    styleUrls: ['./activity-plan-template-display-calendar.component.scss']
})
export class ActivityPlanTemplateDisplayCalendarComponent implements OnInit {
    @Input() triggerSimulation?: Subject<void>;
    @Input() triggerClearCalendarPage?: Subject<void>;

    activityPlanScheduledEndTypeEnum = ActivityPlanScheduledEndTypeEnum;
    calendarPage: ActivityPlanTemplateDisplayCalendarPageType;
    ddFormatWeekDays = this.getWeekDays('dd');
    LEFT = 'left';
    RIGHT = 'right';
    currentDayWeekMoment: moment.Moment;
    daysWithBanner: DaysWithBannerType[];

    constructor(
        public activityPlanTemplateUtils: ActivityPlanTemplateMdUtils,
        private activityPlanProvider: ActivityPlanProvider,
        private messagesService: MessagesService,
    ) {
    }

    ngOnInit(): void {
        this.currentDayWeekMoment = moment();
        this.daysWithBanner = [];
        this.loadCalendarPage();
        this.listenToCalendarPageActions();
    }

    onArrowClick(direction: string): void {
        if (direction === this.LEFT) {
            this.currentDayWeekMoment = this.currentDayWeekMoment.subtract(28, 'days');
        } else if (direction === this.RIGHT) {
            this.currentDayWeekMoment = this.currentDayWeekMoment.add(28, 'days');
        }
        this.loadCalendarPage();
    }

    onCalendarDayClick(dayItem: ActivityPlanTemplateDisplayCalendarDayType) {
        if (!this.isCalendarEnabled()) {
            return;
        }
        if (!dayItem.isAfterToday) {
            return;
        }
        this.updateCalendarStartDate(dayItem);
        this.startSimulation();
    }

    isCalendarEnabled(): boolean {
        return this.activityPlanTemplateUtils.scopedServiceHasValues
            && this.activityPlanTemplateUtils.scopedServiceValidation.recurrenceModal.isValid;
    }

    onRefreshCalendar() {
        if (!this.isCalendarEnabled()) {
            return;
        }
        this.startSimulation();
    }

    private startSimulation() {
        const scopedService = this.activityPlanTemplateUtils.mapScopedServiceToSave(
            this.activityPlanTemplateUtils.scopedServiceItem,
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem
        );
        const isEndsAfterOccurences = scopedService.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsAfterOccurences;
        const isEndsOnSpecificDate = scopedService.scheduledEndType === ActivityPlanScheduledEndTypeEnum.EndsOnSpecificDate;

        const body: GenerateOccurrencePreviewBodyType = {
            service: {
                frequency: scopedService?.frequency,
                interval: scopedService?.interval,
                enqueuePeriod: scopedService?.enqueuePeriod,
                enqueueUnit: scopedService?.enqueueUnit,
                startTime: moment.parseZone(this.calendarPage.startDate?.momentDate).format('YYYY-MM-DD'),
                scheduledEndType: scopedService?.scheduledEndType,
                executionType: scopedService?.executionType,
                ...(isEndsAfterOccurences && scopedService?.count
                    ? {count: scopedService?.count}
                    : (isEndsOnSpecificDate
                        ? {endTime: moment.parseZone(this.calendarPage.startDate?.momentDate).add(3, 'months').format('YYYY-MM-DD')}
                        : {})),
            }
        };
        this.activityPlanProvider.generateOccurrencePreview(body).subscribe((response: GenerateOccurrencePreviewResponseOuterType) => {
            this.loadBannerDays(response);
            this.loadCalendarPage();
        }, (error: any) => {
            this.messagesService.error(error);
        });

    }

    private updateCalendarStartDate(dayItem: ActivityPlanTemplateDisplayCalendarDayType) {
        this.calendarPage.days?.forEach((day: ActivityPlanTemplateDisplayCalendarDayType) => {
            day.isStartDate = day.stringDate === dayItem.stringDate;
        });
        this.calendarPage.startDate = dayItem;
        this.calendarPage.endDateMoment = moment.parseZone(this.calendarPage.startDate?.momentDate).add(3, 'months');
    }

    private loadCalendarPage() {
        const calendarDays: ActivityPlanTemplateDisplayCalendarDayType[] = [];
        const months = [];
        const years = [];
        // moment().day(x) returns the x week day starting from the current week
        // More details here: https://momentjs.com/docs/#/get-set/day/
        for (let momentDayParameter = 1; momentDayParameter <= 28; momentDayParameter += 1) {
            const momentDate = moment(this.currentDayWeekMoment).day(momentDayParameter);
            const doesMomentDateHaveBanner = this.doesMomentDateHaveBanner(momentDate);
            const enqueueDate = doesMomentDateHaveBanner ? this.getEnqueueDateOfTargetDate(momentDate) : undefined;
            // Create and save the day
            calendarDays.push({
                momentDate,
                stringDate: momentDate.format('YYYY-MM-DD'),
                displayDay: momentDate.format('D'),
                isToday: momentDate.startOf('d').isSame(moment().startOf('d')),
                isAfterToday: momentDate.startOf('d').isSameOrAfter(moment().startOf('d')),
                isStartDate: momentDate.format('YYYY-MM-DD') === this.calendarPage?.startDate?.stringDate,
                hasBanner: doesMomentDateHaveBanner,
                ...(enqueueDate ? {enqueueDate} : {}),
            } as ActivityPlanTemplateDisplayCalendarDayType);
            months.push(momentDate.format('MMM'));
            years.push(momentDate.format('YYYY'));
        }
        const uniqueMonths = lodash.uniq(months);
        const uniqueYears = lodash.uniq(years);

        this.calendarPage = {
            startDate: this.calendarPage?.startDate ?? undefined,
            endDateMoment: this.calendarPage?.endDateMoment ?? undefined,
            displayMonth: uniqueMonths.join(' - '),
            displayYear: uniqueYears.join(' - '),
            days: calendarDays,
        };
    }

    private loadBannerDays(response: GenerateOccurrencePreviewResponseOuterType) {
        this.daysWithBanner = response?.value?.map((day: GenerateOccurrencePreviewResponseType) => ({
            targetDate: day.targetDate?.substring(0, 10),
            enqueueDate: day.enqueueDate?.substring(0, 10),
        } as DaysWithBannerType));
    }

    private doesMomentDateHaveBanner(momentDate: moment.Moment): boolean {
        return this.daysWithBanner.some((day: DaysWithBannerType) => day.targetDate === momentDate.parseZone().format('YYYY-MM-DD'));
    }

    private getEnqueueDateOfTargetDate(momentDate: moment.Moment): string {
        return this.daysWithBanner.find(
            (day: DaysWithBannerType) => day.targetDate === momentDate.parseZone().format('YYYY-MM-DD')
        )?.enqueueDate ?? undefined;
    }

    private listenToCalendarPageActions() {
        this.triggerSimulation?.subscribe(() => {
            this.startSimulation();
        });
        this.triggerClearCalendarPage?.subscribe(() => {
            this.daysWithBanner = [];
            this.calendarPage.startDate = undefined;
            this.calendarPage.endDateMoment = undefined;
            this.loadCalendarPage();
        });
    }

    private getWeekDays(format): string[] {
        return [...Array(7).keys()].map(i => moment().days(i + 1).format(format).toUpperCase());
    }
}
