import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import tippy, {Instance} from 'tippy.js';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {
    HumanReadableMinutesEnum,
    HumanReadableMinutesOptionsType,
    HumanReadableMinutesPopoverTextType
} from './human-readable-minutes.type';

@Component({
    selector: 'app-human-readable-minutes',
    templateUrl: './human-readable-minutes.component.html',
    styleUrls: ['./human-readable-minutes.component.scss']
})
export class HumanReadableMinutesComponent implements OnInit, OnChanges {
    @Input() options: HumanReadableMinutesOptionsType;
    @Input() textForPopoverValueZero: string;

    tippyInstance: Instance;
    popoverText: HumanReadableMinutesPopoverTextType = {};
    forceTippyCreate = false;

    constructor(private translatePipe: TranslatePipe,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.forceTippyCreateOnLanguageChange();
    }

    ngOnChanges({options}: SimpleChanges) {
        const {previousValue, currentValue} = options;
        if (previousValue?.totalMinutes !== currentValue?.totalMinutes) {
            this.forceTippyCreate = true;
        }
    }

    makeTippyIfNotAlreadyMade(): void {
        if (!this.tippyInstance) {
            // No tippy instance yet, create one
            this.makeTippyPopover();
            return;
        }
        if (this.forceTippyCreate) {
            // The tippy exists already but we want to update it, so we destroy the old one and create another
            this.tippyInstance.destroy();
            this.makeTippyPopover();
            this.forceTippyCreate = false;
            return;
        }
        // The tippy exists already and we don't want to update it, so we don't do anything
    }

    private forceTippyCreateOnLanguageChange(): void {
        // If the language is changed we need to create another tippy instance and destroy the older one
        this.translateService.onLangChange.subscribe(ev => {
            this.forceTippyCreate = true;
        });
    }

    private makeTippyPopover(): void {
        this.getNumberOfDaysHourMinutesFromMinutes(this.options?.totalMinutes);
        const element = document.getElementById(this.options?.elementId);
        const popoverText = (this.options?.totalMinutes === 0 || this.options?.totalMinutes === '0')
            ? this.translatePipe.transform(this.textForPopoverValueZero)
            : `${this.getPopoverText(HumanReadableMinutesEnum.Days)} ${this.getPopoverText(HumanReadableMinutesEnum.Hours)}
            ${this.getPopoverText(HumanReadableMinutesEnum.Minutes)}`.toLowerCase();
        if (!!element) {
            this.tippyInstance = tippy(element, {
                content: popoverText,
                placement: 'top',
                theme: 'light-border',
                animation: false,
                popperOptions: {strategy: 'fixed'}
            });
        }
        this.tippyInstance.show();
    }

    private getNumberOfDaysHourMinutesFromMinutes(totalMinutes: number): void {
        // Change the total minutes to absolute value, to also cover the case when the number is with the - sign
        const totalAbsoluteValue = Math.abs(totalMinutes);
        const minutes = totalAbsoluteValue % 60;
        const hours = Math.floor(totalAbsoluteValue / 60);
        const days = Math.floor(hours / 24);
        const hoursDivisionRemainder = hours % 24;
        this.popoverText = {
            days: days >= 0 ? days : null,
            hours: hoursDivisionRemainder >= 0 ? hoursDivisionRemainder : null,
            minutes: minutes >= 0 ? minutes : null
        };
    }

    private getPopoverText(action: HumanReadableMinutesEnum): string {
        switch (action) {
            case HumanReadableMinutesEnum.Days: {
                const textToTranslate = this.popoverText.days === 1 ? 'day' : 'days';
                const comaOrEmptySpace = this.popoverText.hours || this.popoverText.minutes ? ', ' : '';
                return this.popoverText.days ? `${this.popoverText.days} ${this.translateLanguage(textToTranslate)}${comaOrEmptySpace}` : '';
            }
            case HumanReadableMinutesEnum.Hours: {
                const textToTranslate = this.popoverText.hours === 1 ? 'hour' : 'hours';
                const comaOrEmptySpace = this.popoverText.minutes ? ', ' : '';
                return this.popoverText.hours ? `${this.popoverText.hours} ${this.translateLanguage(textToTranslate)}${comaOrEmptySpace}` : '';
            }
            case HumanReadableMinutesEnum.Minutes: {
                const textToTranslate = this.popoverText.minutes === 1 ? 'minute' : 'minutes';
                return this.popoverText.minutes ? `${this.popoverText.minutes} ${this.translateLanguage(textToTranslate)}` : '';
            }
        }
    }

    private translateLanguage(labelProperty: string): string {
        const languageToTranslate = `label.${labelProperty}`;
        return this.translatePipe.transform(languageToTranslate);
    }

}
