import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    WorkOrderRuleProvider,
    WorkOrderRuleType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    CenterType,
    LocationDependentFiltersType,
    CenterProvider,
    ServiceProvider,
    ServiceType,
    ServiceDependentFiltersType,
    BookingTypeEnum
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {WorkOrderRuleUtils} from '../work-order-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-work-order-rule',
    templateUrl: './create-work-order-rule.component.html',
    styleUrls: ['./create-work-order-rule.component.scss']
})
export class CreateWorkOrderRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: WorkOrderRuleType = {} as WorkOrderRuleType;
    initialRule: WorkOrderRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    constants = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.WorkOrderRule);
    bookingTypeEnumList = Object.keys(BookingTypeEnum);
    mainDependentFilters: {
        location: LocationDependentFiltersType,
        service: ServiceDependentFiltersType,
    };

    constructor(
        public centerProvider: CenterProvider,
        public serviceProvider: ServiceProvider,
        public workOrderRuleUtils: WorkOrderRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private workOrderRuleProvider: WorkOrderRuleProvider,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
        this.loadDependentFilters();
    }

    ngAfterViewInit() {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.ruleItem[option].hasOwnProperty('$ngOptionValue')) {
            this.ruleItem[option] = undefined;
        }
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: WorkOrderRuleType): boolean {
        return !!(rule.workOrderRuleTableId && !lodash.isEmpty(rule.workOrderRuleTable) && rule.name);
    }

    isInputStepValid(rule: WorkOrderRuleType): boolean {
        return !!rule.service;
    }

    isOutputStepValid(rule: WorkOrderRuleType): boolean {
        return !!(rule.bookingType);
    }

    areAllStepsValid(rule: WorkOrderRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.workOrderRuleTable = ruleTable[0];
            this.ruleItem.workOrderRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.workOrderRuleTable = undefined;
            this.ruleItem.workOrderRuleTableId = undefined;
        }
    }

    //
    // endregion First step methods

    // region Input methods
    //

    onSelectedService(service: ServiceType[]): void {
        this.ruleItem.service = service?.length > 0 ? service[0] : undefined;
        this.ruleItem.serviceId = service?.length > 0 ? service[0].id : undefined;
    }

    onSelectedCenter(center: CenterType[]): void {
        this.ruleItem.center = center?.length > 0 ? center[0] : undefined;
        this.ruleItem.centerId = center?.length > 0 ? center[0].id : undefined;
    }

    // endregion Input methods

    // region Overview methods
    //
    saveRule(rule: WorkOrderRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.workOrderRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: WorkOrderRuleType) {
        this.ngxLoader.start();
        this.workOrderRuleProvider.addEntry(rule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newWorkOrderRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: WorkOrderRuleType, newRule: WorkOrderRuleType) {
        this.ngxLoader.start();
        this.workOrderRuleProvider.updateEntry(oldRule, newRule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.workOrderRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.ruleItem = this.workOrderRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            location: this.workOrderRuleUtils.getLocationDependentFilters(),
            service: this.workOrderRuleUtils.getServiceDependentFilters()
        };
    }
}
