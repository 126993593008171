import {Injectable} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {OptionalIntervalType} from 'sked-base/lib/data-model/optionalIntervalTypes';
import {
    SearchFilterUtils,
    SkedTaskProcessingTypeEnum,
    SkedTaskType,
    SkedTaskTypeEnum
} from 'sked-base';
import {WaitListTaskOptionsType} from './wait-list-modal.types';
import {DateTimeUtils} from '../../utils/dateTime.utils';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class WaitListModalUtils {
    waitListTaskOptions = {} as WaitListTaskOptionsType;

    constructor(
        private dateTimeUtils: DateTimeUtils,
        private searchFilterUtils: SearchFilterUtils
    ) {
    }

    loadInitialWaitListTaskOptions(): void {
        this.waitListTaskOptions.am = false;
        this.waitListTaskOptions.pm = false;
        this.waitListTaskOptions.minDate = this.dateTimeUtils.convertDateInNgbDateStruct(new Date()) as NgbDate;
        this.waitListTaskOptions.selectedDate = this.waitListTaskOptions.minDate;
        this.waitListTaskOptions.filters = {
            service: {dependentFilter: this.searchFilterUtils.getServiceDependentFilters(), initialFilter: []},
            resource: {dependentFilter: this.searchFilterUtils.getResourceDependentFilters(), initialFilter: []},
            center: {dependentFilter: this.searchFilterUtils.getLocationDependentFilters(), initialFilter: []}
        };
    }

    getInitialWaitListTask(): SkedTaskType {
        return {
            preferredInterval: {} as OptionalIntervalType,
            type: SkedTaskTypeEnum.WaitList,
            processingType: SkedTaskProcessingTypeEnum.Manual,
            dueDate:  moment(this.dateTimeUtils.convertNgbDateToDate(this.waitListTaskOptions.selectedDate)).format()
        } as SkedTaskType;
    }

    areFiltersValidBasedOnSelectedTaskType(waitListTask: SkedTaskType): boolean {
        if (waitListTask.processingType === SkedTaskProcessingTypeEnum.Automated) {
            return !!(waitListTask && waitListTask.serviceId && (waitListTask.centerId || waitListTask.resourceId));
        } else {
            return !!(waitListTask && waitListTask.serviceId);
        }
    }

    isValidForTypeAutomated(waitListTask: SkedTaskType): boolean {
        return waitListTask.processingType === SkedTaskProcessingTypeEnum.Automated && !waitListTask.centerId && !waitListTask.resourceId;
    }
}
