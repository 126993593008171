import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
    SlotsManagementCalendarDayType,
    SlotsManagementCalendarPageType,
    SlotsManagementCalendarSelectedDayType,
    SlotsTypeEnum
} from '../slots-management.types';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {SlotsManagementMdUtils} from '../slots-management-util';

@Component({
    selector: 'app-slots-calendar',
    templateUrl: './slots-calendar.component.html',
    styleUrls: ['./slots-calendar.component.scss']
})
export class SlotsCalendarComponent implements OnInit {
    @Output() selectValue: EventEmitter<SlotsManagementCalendarDayType> = new EventEmitter<SlotsManagementCalendarDayType>();
    @Output() continueSearching: EventEmitter<void> = new EventEmitter<void>();
    ddFormatWeekDays = this.getWeekDays('dd');
    slotsTypeEnum = SlotsTypeEnum;
    LEFT = 'left';
    RIGHT = 'right';

    constructor(
        public slotsManagementMdUtils: SlotsManagementMdUtils
    ) {
    }

    ngOnInit(): void {
    }

    onArrowClick(arrow: string) {
        let direction = 1;
        if (!this.slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch) {
            // Calendar not ready to be interactive yet
            return;
        }
        if (arrow === this.LEFT) {
            if (this.slotsManagementMdUtils.slotsCalendarOptions.currentPage <= 0) {
                // User clicked on left arrow but currentPage is first page
                return;
            }
            direction = -1;
        } else if (this.slotsManagementMdUtils.slotsCalendarOptions.currentPage >=
            this.slotsManagementMdUtils.slotsCalendarOptions.numberOfPages - 1) {
            // User clicked on right arrow but currentPage is last page
            // If didn't already search on time window maximum, continue searching and navigate
            if (this.slotsManagementMdUtils.timeWindowInDaysBasedOnSearch !== this.slotsManagementMdUtils.timeWindowMaximum) {
                this.slotsManagementMdUtils.shouldNavigateOnNextCalendarAfterContinueSearch = true;
                this.continueSearching.emit();
            }
            return;
        }
        // Direction is 1 if arrow is Right and -1 if arrow is Left
        this.slotsManagementMdUtils.slotsCalendarOptions.currentPage += direction;

        // If currentPage is last page, and there are disabled days, continue searching
        if (this.shouldContinueSearching()) {
            this.continueSearching.emit();
        }

        // Update display month with the months and years of the current page
        this.slotsManagementMdUtils.updateCalendarDisplayMonthAndYear();

        // Keep state of calendar updated
        this.slotsManagementMdUtils.slotsManagementState.slotsCalendarOptions =
            lodash.cloneDeep(this.slotsManagementMdUtils.slotsCalendarOptions);
    }

    onCalendarDaySelected(dayItem: SlotsManagementCalendarDayType, dayIndex: number) {
        // Don't do anything if user didn't search for slots yet
        if (!this.slotsManagementMdUtils.slotsCalendarOptions.areOptionsAfterSearch) {
            return;
        }
        // Don't do anything if selected day is disabled
        if (dayItem.isDisabled) {
            return;
        }
        // First remove isSelected from the previously selected day
        if (this.slotsManagementMdUtils.slotsCalendarOptions.previouslySelectedDay?.pageIndex !== undefined &&
            this.slotsManagementMdUtils.slotsCalendarOptions.previouslySelectedDay?.dayIndex !== undefined) {
            const previouslySelectedDay = this.slotsManagementMdUtils.slotsCalendarOptions.previouslySelectedDay;
            this.slotsManagementMdUtils.slotsCalendarOptions
                .calendarPages[previouslySelectedDay.pageIndex]
                .calendarDays[previouslySelectedDay.dayIndex]
                .isSelected = false;
        }
        // Then add isSelected to the newly selected day
        this.slotsManagementMdUtils.slotsCalendarOptions
            .calendarPages[this.slotsManagementMdUtils.slotsCalendarOptions.currentPage]
            .calendarDays[dayIndex]
            .isSelected = true;
        this.slotsManagementMdUtils.slotsCalendarOptions.previouslySelectedDay = {
            pageIndex: this.slotsManagementMdUtils.slotsCalendarOptions.currentPage,
            dayIndex,
        } as SlotsManagementCalendarSelectedDayType;
        this.slotsManagementMdUtils.slotsCalendarOptions.selectedDay =  this.slotsManagementMdUtils.slotsCalendarOptions
            .calendarPages[this.slotsManagementMdUtils.slotsCalendarOptions.currentPage]
            .calendarDays[dayIndex];
        // Update the display month and year
        this.slotsManagementMdUtils.slotsCalendarOptions.displayMonth = dayItem.momentDate.format('MMMM');
        this.slotsManagementMdUtils.slotsCalendarOptions.displayYear = dayItem.momentDate.format('YYYY');
        // And emit changes to parent
        this.selectValue.emit(dayItem);
    }

    onTodayButtonClick() {
        // Go to page 0 and simulate click on today
        this.slotsManagementMdUtils.slotsCalendarOptions.currentPage = 0;
        this.slotsManagementMdUtils.slotsCalendarOptions.calendarPages.forEach((page: SlotsManagementCalendarPageType) => {
            page.calendarDays.forEach((day: SlotsManagementCalendarDayType, dayIndex: number) => {
                if (day.momentDate.isSame(moment(), 'day')) {
                    this.onCalendarDaySelected(day, dayIndex);
                    return;
                }
            });
        });
    }

    isRightArrowEnabled(): boolean {
        // Basically allow user to click on the right arrow when all days on the current page have
        // been searched but maxtimewindow hasn't been reached
        if (this.slotsManagementMdUtils.slotsCalendarOptions.currentPage < this.slotsManagementMdUtils.slotsCalendarOptions.numberOfPages - 1) {
            // Not on last page
            return true;
        }
        // Only enable right arrow on last page if not already searched on time window maximum
        return this.slotsManagementMdUtils.timeWindowInDaysBasedOnSearch !== this.slotsManagementMdUtils.timeWindowMaximum;
    }

    getCurrentPageOptions(): SlotsManagementCalendarPageType {
        return this.slotsManagementMdUtils.slotsCalendarOptions.calendarPages[
            this.slotsManagementMdUtils.slotsCalendarOptions.currentPage
        ];
    }

    private shouldContinueSearching(): boolean {
        // If not on last page, don't continue searching
        if (this.slotsManagementMdUtils.slotsCalendarOptions.currentPage <
            this.slotsManagementMdUtils.slotsCalendarOptions.numberOfPages - 1) {
            return false;
        }
        // If already searched on time window maximum, don't continue searching
        if (this.slotsManagementMdUtils.timeWindowInDaysBasedOnSearch === this.slotsManagementMdUtils.timeWindowMaximum) {
            return false;
        }
        // Otherwise, continue searching
        return true;
    }

    private getWeekDays(format): string[] {
        return [...Array(7).keys()].map(i => moment().days(i + 1).format(format).toUpperCase());
    }
}
