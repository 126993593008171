import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
    DateRangeResponseType,
    DateRangeType,
    GenericFilterResultType,
    SkedTaskProcessingTypeEnum,
    SkedTaskStatusEnum
} from 'sked-base';
import {TaskListUtils} from '../task-list-util';
import {TaskListFiltersUtil} from './task-list-filters-util';
import {TaskListFiltersSearchType, TaskListFilterWrapperInitialValuesType} from './task-list-filters.types';
import * as lodash from 'lodash';
import {TaskListFiltersViewMoreModalComponent} from './task-list-filters-view-more-modal/task-list-filters-view-more-modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {GeneralUtils} from '../../../shared/utils/general.utils';

@Component({
    selector: 'app-task-list-filters',
    templateUrl: './task-list-filters.component.html',
    styleUrls: ['./task-list-filters.component.scss']
})
export class TaskListFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() search: EventEmitter<TaskListFiltersSearchType> = new EventEmitter<TaskListFiltersSearchType>();
    @ViewChild('taskListFiltersViewMoreTemplate') viewMoreFiltersTemplate: TemplateRef<any>;
    updateDueDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    updateFilterWrapperValues: EventEmitter<GenericFilterResultType[]> = new EventEmitter<GenericFilterResultType[]>();
    updateCreatedDateRangeValue: EventEmitter<DateRangeType> = new EventEmitter<DateRangeType>();
    skedTaskStatusEnumList = Object.values(SkedTaskStatusEnum);
    skedTaskProcessingTypeEnumList = Object.values(SkedTaskProcessingTypeEnum);

    private FILTERS_LOCATION_CSS_QUERY_SELECTOR =
        '#task-list-filters-container sbase-filter-wrapper .filter-wrapper-component';

    constructor(
        public taskListUtils: TaskListUtils,
        public taskListFiltersUtil: TaskListFiltersUtil,
        private generalUtils: GeneralUtils,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.loadInitialValues();
        this.registerToClearFilters();
    }

    ngAfterViewInit(): void {
        this.moveFiltersNextToFiltersFromFilterWrapper();
    }

    ngOnDestroy(): void {
    }

    onOnlyExpiringTasksCheckboxChange() {
        this.taskListUtils.updateFilterState(['onlyExpiringTasksCheckboxNgModel']);
        // If checkbox only Expiring Tasks is checked disable date ranges, if is not checked do not disable
        this.taskListUtils.filtersOptions.dueDateRangeOptions.disabled =
            this.taskListUtils.taskListState.filtersOptions.onlyExpiringTasksCheckboxNgModel;
    }

    // Filter values changed
    onDueDateRangeChanged(dueDateRange: DateRangeResponseType) {
        this.taskListUtils.filtersOptions.dueDateRangeOptions.fromDate = dueDateRange.fromDate;
        this.taskListUtils.filtersOptions.dueDateRangeOptions.toDate = dueDateRange.toDate;
        this.validateDueDateRange(dueDateRange.isValid);
        // Update state
        this.taskListUtils.updateFilterState(['dueDateRangeOptions']);
    }

    onCreatedDateRangeChanged(createdDateRange: DateRangeResponseType) {
        this.taskListUtils.filtersOptions.createdDateRangeOptions.fromDate = createdDateRange.fromDate;
        this.taskListUtils.filtersOptions.createdDateRangeOptions.toDate = createdDateRange.toDate;
        this.validateCreatedDateRange(createdDateRange.isValid);
        // Update state
        this.taskListUtils.updateFilterState(['createdDateRangeOptions']);
    }

    onFilterWrapperValueChanged(filtersValues: GenericFilterResultType[]): void {
        // Reset all filter values
        Object.keys(this.taskListUtils.filtersOptions.taskListFiltersValues).forEach((filterName: string) => {
            this.taskListUtils.filtersOptions.taskListFiltersValues[filterName] = undefined;
        });
        // Set coming filter wrapper values
        for (const filter of filtersValues) {
            this.taskListUtils.filtersOptions.taskListFiltersValues[filter.name] = filter.value;
        }
        // Validate
        this.validateFilterWrapperFilters();
        // Update state
        this.taskListUtils.updateFilterState(['taskListFiltersValues']);
    }

    onViewMore() {
        // Prepare and open the modal
        const modalOptions: NgbModalOptions = {
            keyboard: false,
            size: 'md',
            windowClass: 'task-list-filters-view-more-modal'
        };
        const modalRef = this.modalService.open(TaskListFiltersViewMoreModalComponent, modalOptions);
        // Send the content template to the modal
        modalRef.componentInstance.contentTemplate = this.viewMoreFiltersTemplate;
    }

    // Reset filters action
    onClear() {
        // Reset initial validations and date range options
        this.resetValidationsAndDateRanges();
        // Clear checkboxes
        this.taskListUtils.filtersOptions.onlyExpiringTasksCheckboxNgModel = false;
        this.taskListUtils.filtersOptions.onlyTasksWithAppointmentsCheckboxNgModel = false;
        // Reset select filters
        this.taskListUtils.filtersOptions.statusSelectNgModel = SkedTaskStatusEnum.InWork;
        this.taskListUtils.filtersOptions.processTypeSelectNgModel = undefined;
        // Tell filter wrapper component to reset everything
        this.updateFilterWrapperValues.next([]);
        // Update state
        this.taskListUtils.updateFilterState([
            'onlyExpiringTasksCheckboxNgModel', 'onlyTasksWithAppointmentsCheckboxNgModel',
            'statusSelectNgModel', 'processTypeSelectNgModel', 'taskListFiltersValues'
        ]);
    }

    // Search action
    onSearch() {
        const filterValues: TaskListFiltersSearchType = this.taskListFiltersUtil.mapFilterValuesForSearch(
            this.taskListUtils.filtersOptions.dueDateRangeOptions,
            this.taskListUtils.filtersOptions.taskListFiltersValues,
            this.taskListUtils.filtersOptions.onlyTasksWithAppointmentsCheckboxNgModel,
            this.taskListUtils.filtersOptions.onlyExpiringTasksCheckboxNgModel,
            this.taskListUtils.filtersOptions.processTypeSelectNgModel,
            this.taskListUtils.filtersOptions.statusSelectNgModel,
            this.taskListUtils.filtersOptions.createdDateRangeOptions
        );
        this.validateDueDateRange();
        this.validateCreatedDateRange();
        this.validateFilterWrapperFilters();
        if (this.taskListUtils.filtersOptions.areFiltersValid) {
            this.search.emit(filterValues);
            this.changeCollapseFilterSection(true);
        }
    }

    changeCollapseFilterSection(value?: boolean): void {
        // If value provided, use that, otherwise toggle
        this.taskListUtils.filtersOptions.areFiltersCollapsed =
            value ?? !this.taskListUtils.filtersOptions.areFiltersCollapsed;
        // Update state
        this.taskListUtils.updateFilterState(['areFiltersCollapsed']);
    }

    sanitizeNgSelectValue(option: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (this.taskListUtils.filtersOptions[option].hasOwnProperty('$ngOptionValue')) {
            this.taskListUtils.filtersOptions[option] = undefined;
        }
    }

    private moveFiltersNextToFiltersFromFilterWrapper() {
        const elementsToMoveContainer = document.querySelector('#task-list-filters-to-move');
        const filtersLocation = document.querySelector(this.FILTERS_LOCATION_CSS_QUERY_SELECTOR);
        Array.from(elementsToMoveContainer.children).forEach((childToMove) => {
            filtersLocation?.appendChild(childToMove);
        });
    }

    // Validations
    private validateDueDateRange(isValidInner: boolean = true) {
        this.taskListUtils.filtersOptions.filterValidations.dueDateRange = {
            isValid: isValidInner && !!this.taskListUtils.filtersOptions.dueDateRangeOptions.fromDate &&
                !!this.taskListUtils.filtersOptions.dueDateRangeOptions.toDate,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    private validateCreatedDateRange(isValidInner: boolean = true) {
        this.taskListUtils.filtersOptions.filterValidations.createdDateRange = {
            isValid: isValidInner,
            errorMessage: ''
        };
        this.updateFiltersValidation();
    }

    private validateFilterWrapperFilters() {
        this.updateFiltersValidation();
    }

    private updateFiltersValidation() {
        this.taskListUtils.filtersOptions.areFiltersValid =
            this.taskListUtils.filtersOptions.filterValidations.dueDateRange.isValid;
        // Update state
        this.taskListUtils.updateFilterState(['filterValidations', 'areFiltersValid']);
    }

    // Load data
    private loadOptionsForDueDateRange() {
        this.taskListUtils.filtersOptions.dueDateRangeOptions = this.taskListFiltersUtil.getInitialDueDateRangeOptions();
        this.updateDueDateRangeValue.next({
            fromDate: this.taskListUtils.filtersOptions.dueDateRangeOptions.fromDate,
            toDate: this.taskListUtils.filtersOptions.dueDateRangeOptions.toDate
        } as DateRangeType);
        this.validateDueDateRange();
        // Update state
        this.taskListUtils.updateFilterState(['dueDateRangeOptions']);
    }

    private loadOptionsForCreatedDateRange() {
        this.taskListUtils.filtersOptions.createdDateRangeOptions = this.taskListFiltersUtil.getInitialCreatedDateRangeOptions();
        // Set initial min and max dates to null, to signify that whenever the modal is reopened, the minDate should reset back to null
        this.taskListUtils.filtersOptions.createdDateRangeOptions.initialMinDate = null;
        this.taskListUtils.filtersOptions.createdDateRangeOptions.initialMaxDate = null;
        this.updateCreatedDateRangeValue.next({
            fromDate: this.taskListUtils.filtersOptions.createdDateRangeOptions.fromDate,
            toDate: this.taskListUtils.filtersOptions.createdDateRangeOptions.toDate
        } as DateRangeType);
        this.validateCreatedDateRange();
        // Update state
        this.taskListUtils.updateFilterState(['createdDateRangeOptions']);
    }

    private resetValidationsAndDateRanges() {
        // Reset validations
        this.taskListUtils.filtersOptions.filterValidations = this.taskListFiltersUtil.getInitialFilterValidations();
        // Reset options for date ranges
        this.loadOptionsForDueDateRange();
        this.loadOptionsForCreatedDateRange();
        // Reset values for search event
        this.taskListUtils.filtersOptions.taskListFiltersValues =
            this.taskListUtils.filtersOptions.filterWrapperInitialValues
                ? lodash.cloneDeep(this.taskListUtils.filtersOptions.filterWrapperInitialValues)
                : {} as TaskListFilterWrapperInitialValuesType;
        // Validate filter wrapper filters
        this.validateFilterWrapperFilters();
        // Update state
        this.taskListUtils.updateFilterState(['filterValidations', 'taskListFiltersValues']);
    }

    private loadOptionsFromState() {
        // Load options from state (filter wrapper component needs special attention)
        this.taskListUtils.filtersOptions = {
            ...lodash.cloneDeep(this.taskListUtils.taskListState.filtersOptions),
            // Need initial values to show them on filters
            filterWrapperOptions: this.taskListFiltersUtil.getFilterWrapperOptions(
                this.taskListUtils.taskListState.filtersOptions.taskListFiltersValues
            )
        };
        // Tell filter wrapper component to preselect values from state
        this.updateFilterWrapperValues.next(
            this.taskListFiltersUtil.getInitialFilterWrapperValuesFromPreviousValues(
                this.taskListUtils.filtersOptions.taskListFiltersValues
            )
        );
    }

    private registerToClearFilters() {
        this.taskListUtils?.clearFiltersEmitter?.subscribe(() => {
            this.onClear();
        });
    }

    private loadInitialValues() {
        if (!!this.taskListUtils.shouldKeepFiltersState && !!this.taskListUtils.taskListState?.filtersOptions) {
            this.loadOptionsFromState();
            return;
        }
        // Reset filter options
        this.taskListUtils.filtersOptions = this.taskListUtils.getEmptyFiltersOptions();
        // Load options for the filter wrapper component
        this.taskListUtils.filtersOptions.filterWrapperOptions =
            this.taskListFiltersUtil.getFilterWrapperOptions(this.taskListUtils.filtersOptions.filterWrapperInitialValues);
        // Load initial validations and date ranges options
        this.resetValidationsAndDateRanges();
        // Initialize select filters
        this.taskListUtils.filtersOptions.statusSelectNgModel = SkedTaskStatusEnum.InWork;
        // Reset the initial values so the reset button will clear the filters instead of resetting to these initial values
        this.taskListUtils.filtersOptions.filterWrapperInitialValues = undefined;
        // Update state
        this.taskListUtils.updateFilterState([
            'filterWrapperOptions', 'statusSelectNgModel', 'filterWrapperInitialValues'
        ]);
    }
}
