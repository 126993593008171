import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessagesService} from '../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {UserMDUtils} from './user-md.utils';
import {constants} from '../../shared/constants/constants';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {UserType} from 'sked-base/lib/data-model/userTypes';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';
import {
    ConfirmDeleteModalService,
    ExportFileEntityEnum,
    JobProvider,
    TeamProvider,
    UserProvider,
    EntityTypeEnum,
    UserTypeEnum
} from 'sked-base';
import {UserApiKeyModalService} from './user-api-key-modal/user-api-key-modal.service';
import * as lodash from 'lodash';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-user-md',
    templateUrl: './user-md.component.html',
    styleUrls: ['./user-md.component.scss']
})
export class UserMDComponent implements OnInit, OnDestroy {
    exportFileEntityEnum = ExportFileEntityEnum;
    VIEW_ACTIVITY_ENABLED = false;
    public usersList: UserType[] = [];
    public itemsPerPageList: number[];
    public showItemsPerPageDropdown = false;
    public objectKeys = Object.keys;
    public totalTableItems: number;
    public MDEntityName: string;
    public constants = constants;
    public teamButtonName = 'label.userManagementTab.team';
    public EntityTypeEnum = EntityTypeEnum;
    public UserTypeEnum = UserTypeEnum;
    public UserTypeEnumList = Object.values(UserTypeEnum);
    private searchUserNameChanged = new Subject<string>();

    constructor(
        public userMDUtils: UserMDUtils,
        public userProvider: UserProvider,
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public teamProvider: TeamProvider,
        public confirmDeleteService: ConfirmDeleteModalService,
        private jobProvider: JobProvider,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService,
        private userApiKeyModalService: UserApiKeyModalService
    ) {
    }


    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('UserRead');
        this.MDEntityName = this.userProvider.name;
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();

        if (this.previousRouteService.getPreviousUrl() !== '/createUser') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.subscribeSearchByNameChanged();
        this.loadTableEntries();
    }

    ngOnDestroy(): void {
    }

    createUser(): void {
        this.router.navigate(['/createUser']);
    }

    viewUser(user: UserType) {
        this.router.navigate(['/createUser'], {state: {user, action: constants.VIEW}});
    }

    editUser(user: UserType) {
        this.router.navigate(['/createUser'], {state: {user, action: constants.EDIT}});
    }

    // get user data and display into table
    public loadTableEntries(includeCount: boolean = true) {
        const queryFilter = this.userMDUtils.getQueryFilterForUserMD(this.userMDUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.userProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response) => {
                this.usersList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.onSearchUserNameChanged('');
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadTableEntries(true);
    }

    // method for deleting table item
    public deleteItem(id) {
        this.ngxLoader.start();
        this.userProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadTableEntries();
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.employeeDelete', true);
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    public onChangePagination(page: number) {
        this.userMDUtils.tableFilters.currentPage = page;
        this.loadTableEntries(false);
    }

    public changeNumberOfItemsPerPage(itemPerPage) {
        this.userMDUtils.tableFilters.currentPage = 1;
        this.userMDUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadTableEntries(false);
    }

    public onSortBy(property: string) {
        // initial isAscendingMode is undefined => ascending sorting
        const isAscendingMode = this.userMDUtils.tableFilters.orderBy[property];

        if (this.userMDUtils.tableFilters.orderBy) {
            this.userMDUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }

        this.loadTableEntries(false);
    }

    public onSearchUserNameChanged(value: string) {
        this.searchUserNameChanged.next(value);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.userMDUtils.tableFilters = this.generalUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.userMDUtils.initialTeamValues = [];
        }
    }

    private subscribeSearchByNameChanged() {
        const self = this;
        this.searchUserNameChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.userMDUtils.tableFilters.filter.username = searchValue;
                self.loadTableEntries();
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.userMDUtils.tableFilters.orderBy[selectedItem];
        this.loadTableEntries(false);
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onSelectedTeamFilter(teamFilterList): void {
        this.userMDUtils.initialTeamValues = teamFilterList;
        this.userMDUtils.tableFilters.filter.team = (teamFilterList && teamFilterList.length > 0) ? teamFilterList[0] : undefined;
        this.loadTableEntries();
    }


    displayConfirmDeleteItemModal(userId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.userProvider, userId)
            .result
            .then(() => {
            }, (response) => {
                if (response) {
                    this.deleteItem(userId);
                }
            });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    displayApiKeyForUserModal(user: UserType): void {
        if (!user.apiKeys) {
            user.apiKeys = [];
        }
        const modal = this.userApiKeyModalService.displayApiKeyModal(user);
        modal.result.then(
            () => {},
            () => {
                if (modal.componentInstance.apiKey.apiKey && modal.componentInstance.apiKey.apiKey !== constants.DUMMY_API_KEY) {
                    this.loadTableEntries(true);
                }
            }
        );
    }

    applyFilter(option: string, UserTypeOptions: string) {
        if (UserTypeOptions) {
            if (option.hasOwnProperty('$ngOptionValue') || option === 'all') {
                delete this.userMDUtils.tableFilters.filter.userType;
            } else {
                this.userMDUtils.tableFilters.filter.userType = option;
            }
        }
        this.loadTableEntries(true);
    }
}
