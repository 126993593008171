import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessagesService} from '../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {constants} from '../../../shared/constants/constants';
import * as lodash from 'lodash';
import {take} from 'rxjs/operators';
import {TagMdUtils} from '../tag-md-utils';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {ScreenTemplateLayoutType} from '../../../data-model/general.type';
import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {TagProvider} from 'sked-base';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-tag',
    templateUrl: './create-tag.component.html',
    styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit, OnDestroy {
    constants = constants;
    initialTag: TagType;
    tagItem: TagType = {} as TagType;
    screenTemplateLayout: ScreenTemplateLayoutType;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private tagMdUtils: TagMdUtils,
        public tagProvider: TagProvider,
        public router: Router,
        public generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    private validateFields(tagItem: TagType): boolean {
        return !!(tagItem && tagItem.name);
    }

    /**
     * Save tag data
     *
     * @param tag TagType
     */
    public saveTagData(tag: TagType) {
        const isTemplateValid = this.validateFields(tag);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createTag(tag);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialTag, tag)) {
                    this.messagesService.success('toastr.success.tagEdit', true);
                    this.goToParentPage();
                } else {
                    this.editTag(this.initialTag, tag);
                }
            }
        }
    }

    /**
     * Creat new tag
     *
     * @param tag TagType
     */
    private createTag(tag: TagType) {
        this.ngxLoader.start();
        this.tagProvider.addEntry(tag)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newTagAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    /**
     * Edit tag
     *
     * @param oldTag TagType
     * @param newTag TagType
     */
    private editTag(oldTag: TagType, newTag: TagType) {
        this.ngxLoader.start();
        this.tagProvider.updateEntry(oldTag, newTag)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.tagEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    /**
     * Go to parent page
     */
    public goToParentPage() {
        this.router.navigate(['/tags']);
    }

    goToEdit() {
        history.replaceState({tag: this.tagItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
    }

    private setupInitialState() {
        if (history.state && history.state.tag) {
            this.initialTag = history.state.tag;
            this.tagItem = lodash.cloneDeep(history.state.tag);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.tagItem = this.tagMdUtils.getInitialTag();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.validateFields(this.tagItem);
    }
}
