<div class="container-fluid create-patient-wrapper create-entity-md-wrapper custom-scrollbar">
    <!--Header-->
    <!-- =========================================== -->
    <h3 class="create-patient-title" *ngIf="isDataLoaded">
        <span>{{!displaySave() && action === actionType.Edit ? (actionType.View | translate) :  (screenTemplateLayout?.pageTitle | translate)}}&nbsp;</span>
        <span class="text-lowercase" translate="label.patient"></span>
    </h3>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <form [formGroup]="patientForm"
          class="create-entity-md-section h-100"
          autocomplete="off"
          [ngClass]="{'is-view-mode':screenTemplateLayout?.action === actionType.View, 'op-0': isBeforeNavigate}">
        <mat-horizontal-stepper labelPosition="bottom"
                                #stepper
                                [linear]="true"
                                (selectionChange)="onStepChange($event)">
            <ng-container *ngIf="!!tenantCustomizingData">
                <!-- STEP 1 -->
                <mat-step [stepControl]="getStepControl(0)"
                          [completed]="false"
                          *ngIf="screenTemplateLayout?.action !== actionType.View">
                    <ng-template matStepLabel><span translate="label.patientInfo"></span></ng-template>
                    <div>
                        <app-client-identification-form
                                formGroupName="clientIdentification"
                                [options]="clientIdentificationOptions">
                        </app-client-identification-form>
                        <app-dynamic-form-input
                                [parentFormGroup]="patientForm"
                                [options]="createPatientStepOneFormOptions"
                                [initialValue]="patientForm?.get(formControlNames.BIRTHDATE)?.value"
                        ></app-dynamic-form-input>
                    </div>
                </mat-step>
                <!-- STEP 2 -->
                <mat-step [stepControl]="getStepControl(1)"
                          [completed]="false"
                          *ngIf="screenTemplateLayout?.action !== actionType.View">
                    <ng-template matStepLabel><span translate="label.contact"></span></ng-template>
                    <app-address-information-form
                            formGroupName="addressInformation"
                            [options]="addressInformationOptions"
                    ></app-address-information-form>
                    <div class="phone-numbers-container">
                        <app-phone-number-form
                                formGroupName="mainPhoneNumber"
                                [parentFormGroup]="patientForm"
                                [options]="mainPhoneNumberOptions"
                        ></app-phone-number-form>
                        <app-phone-number-form
                                formGroupName="alternatePhoneNumber"
                                [parentFormGroup]="patientForm"
                                [options]="alternatePhoneNumberOptions"
                        ></app-phone-number-form>
                    </div>
                    <app-dynamic-form-input
                            [parentFormGroup]="patientForm"
                            [options]="createPatientStepTwoFormOptions"
                    ></app-dynamic-form-input>
                </mat-step>
                <!-- STEP 3-->
                <mat-step [stepControl]="getStepControl(2)"
                          [completed]="false"
                          *ngIf="screenTemplateLayout?.action !== actionType.View">
                    <ng-template matStepLabel><span translate="label.additionalInfo"></span></ng-template>
                    <app-coverage-plans-form
                            formGroupName="coveragePlans"
                            [options]="coveragePlanFormOptions">
                    </app-coverage-plans-form>
                    <app-tags-form formGroupName="tags"
                                   [options]="tagsFormOptions"
                                   [featureDisplay]="'patient-tags'"
                                   [activityDisplay]="'PatientTagsManager'">
                    </app-tags-form>
                    <app-dynamic-form-input
                            [parentFormGroup]="patientForm"
                            [options]="createPatientStepThreeFormOptions"
                    ></app-dynamic-form-input>
                    <app-external-keys-form
                        formGroupName="externalKeys"
                        [options]="externalKeysFormOptions">
                    </app-external-keys-form>
                </mat-step>
                <!-- STEP 4-->
                <mat-step [stepControl]="getStepControl(3)"
                          [completed]="false"
                          *ngIf="screenTemplateLayout?.action !== actionType.View && createPatientUtils.isCreatePatientExceptionsActive()">
                    <ng-template matStepLabel><span translate="label.exceptions"></span></ng-template>
                    <app-exceptions-form
                            formGroupName="exceptions"
                            [options]="exceptionsFormOptions">
                    </app-exceptions-form>
                </mat-step>
                <!-- STEP 5-->
                <mat-step [stepControl]="getStepControl(4)"
                          [completed]="false"
                          *ngIf="screenTemplateLayout?.action !== actionType.View && createPatientUtils.isTimeDependentTagsActive()">
                    <ng-template matStepLabel><span translate="label.tags"></span></ng-template>
                    <app-time-dependent-tags-form
                            formGroupName="timeDependentTags"
                            [options]="timeDependentTagsOptions">
                    </app-time-dependent-tags-form>
                </mat-step>
                <!-- STEP 6-->
                <mat-step [stepControl]="getStepControl(5)"
                          [completed]="false">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <app-create-patient-overview *ngIf="mappedPatient"
                                                 [patient]="mappedPatient"></app-create-patient-overview>
                </mat-step>
            </ng-container>
        </mat-horizontal-stepper>
    </form>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky" *ngIf="isDataLoaded">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout?.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout?.cancelOrBackButton}}">
            </button>
            <button type="button"
                    *ngIf="screenTemplateLayout?.action !== actionType.View"
                    [disabled]="isBackButtonDisabled()"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="goForward()"
                    *ngIf="displayNext()"
                    type="button"
                    class="btn theme-btn mr-3">
                <span translate="button.next"></span>
            </button>
            <button (click)="savePatientData()" type="button"
                    *ngIf="displaySave()"
                    [disabled]="!patientValidation.isValid"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
