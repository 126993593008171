import {Component, Input, OnInit} from '@angular/core';
import {ConfirmDeleteModalService, FileProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import * as lodash from 'lodash';
import {UploadFileUtils} from './upload-file.utils';
import {UploadFileOptionsType} from './upload-file.types';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    @Input() options: UploadFileOptionsType;
    uploadedFiles: File[] = [];  // contains all the uploaded files
    formDataToSend: FormData; // populated via ngfFormData directive
    validUploadedItems: boolean;

    constructor(public uploadFileUtils: UploadFileUtils,
                private confirmDeleteService: ConfirmDeleteModalService,
                private fileProvider: FileProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService) {
    }

    ngOnInit(): void {
        this.uploadFileUtils.uploadedFile = null;
        this.getUploadFileOptions();
    }

    onUploadImage() {
        this.uploadFileUtils.uploadedFile = this.uploadedFiles[0];
    }

    isFileTypeBlob(file: any): boolean {
        return file instanceof Blob;
    }

    displayConfirmDeleteItemModal() {
        this.confirmDeleteService.displayConfirmDeleteModal(this.fileProvider, this.uploadFileUtils.fileId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteUploadedFile();
            }
        });
    }

    private deleteUploadedFile() {
        if (!this.uploadFileUtils.fileId) {
            this.uploadFileUtils.uploadedFile = {} as File;
        } else {
            this.ngxLoader.start();
            this.fileProvider.deleteFileForEntity(this.options?.entityType, this.options?.itemId, this.uploadFileUtils?.fileId).subscribe(() => {
                    this.uploadFileUtils.uploadedFile = {} as File;
                    this.uploadFileUtils.lastInvalids = [];
                    this.uploadFileUtils.fileId = null;
                    this.ngxLoader.stop();
                },
                err => {
                    this.ngxLoader.stop();
                    this.messagesService.handlingErrorMessage(err);
                });
        }
    }

    private getUploadFileOptions() {
        this.ngxLoader.start();
        this.fileProvider.getFileSupportedData(this.options?.entityType)
            .subscribe(({supportedFormats, maximumSize}) => {
                this.uploadFileUtils.uploadFileOptions.acceptedFileFormats = supportedFormats;
                this.uploadFileUtils.acceptedFormatsString = this.mapSupportedFormatsAsString(supportedFormats);
                this.uploadFileUtils.uploadFileOptions.maxAllowedFileSize = maximumSize;
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private mapSupportedFormatsAsString(supportedFormatsArray: string[]): string {
        if (supportedFormatsArray?.length < 1) {
            return '';
        }
        const supportedFormatsMapped = lodash.map(supportedFormatsArray, (item) => {
            return `.${item}`;
        });
        return supportedFormatsMapped.join(', '); //'.svg, .png, .jpeg, .jpg'
    }
}
