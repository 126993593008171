import {Injectable} from '@angular/core';
import {AvailabilitySplitValidationType, BackofficeAvailabilitySplitDataType} from '../../availability.types';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {DateTimeUtils} from '../../../../shared/utils/dateTime.utils';
import {AvailabilitySplitType} from 'sked-base';
import {AvailabilityUtils} from '../../availability-utils';

@Injectable({
    providedIn: 'root'
})
export class CreateAvailabilitySplitUtils {

    constructor(private dateTimeUtils: DateTimeUtils,
                private availabilityUtils: AvailabilityUtils) {
    }

    getInitialAvailabilitySplitData(availabilityId: string): BackofficeAvailabilitySplitDataType {
        const availabilitySplit = {} as BackofficeAvailabilitySplitDataType;
        availabilitySplit.id = availabilityId;
        availabilitySplit.timeFrom = {hour: 0, minute: 0, second: 0};
        availabilitySplit.timeTo = {hour: 0, minute: 0, second: 0};
        availabilitySplit.dateFrom = this.dateTimeUtils.getTodayNgbDateStruct();
        availabilitySplit.dateTo = this.dateTimeUtils.getTodayNgbDateStruct();
        availabilitySplit.splitFrom = this.getAvailabilitySplitFormatDate(availabilitySplit.dateFrom, availabilitySplit.timeFrom);
        availabilitySplit.splitTo = this.getAvailabilitySplitFormatDate(availabilitySplit.dateTo, availabilitySplit.timeTo);
        return availabilitySplit;
    }

    getAvailabilitySplitFormatDate(date: NgbDateStruct, time: NgbTimeStruct): string {
        return this.dateTimeUtils.getMomentStringFromDateAndTime(date, time);
    }

    validateSplitInterval(availabilitySplitFrom: string, availabilitySplitTo: string, availabilityValidFrom: string,
                          availabilityValidTo: string, endOfDay: boolean = false): AvailabilitySplitValidationType {
        const validDates = {
            isValid: true,
            errorMessage: ''
        };

        if (!availabilitySplitFrom || !availabilitySplitTo) {
            validDates.isValid = false;
            validDates.errorMessage = 'label.error.required';
            return validDates;
        }

        if (endOfDay) {
            availabilitySplitTo = moment(availabilitySplitTo).add(1, 'days').startOf('day').format();
        }

        if (moment(availabilitySplitFrom, 'YYYY-MM-DDTHH:mm:ss').isAfter(moment(availabilitySplitTo, 'YYYY-MM-DDTHH:mm:ss'))
            || moment(availabilitySplitFrom, 'YYYY-MM-DDTHH:mm:ss').isSame(moment(availabilitySplitTo, 'YYYY-MM-DDTHH:mm:ss'))) {
            validDates.isValid = false;
            validDates.errorMessage = 'label.error.invalidDiffDate2';
            return validDates;
        }

        if (moment(moment(availabilitySplitFrom).format('YYYY-MM-DDTHH:mm:ss')).isBefore(moment(availabilityValidFrom)
                .parseZone().format('YYYY-MM-DDTHH:mm:ss')) ||
            moment(moment(availabilitySplitFrom).format('YYYY-MM-DDTHH:mm:ss')).isAfter(moment(availabilityValidTo)
                .parseZone().format('YYYY-MM-DDTHH:mm:ss'))) {
            validDates.isValid = false;
            validDates.errorMessage = 'tenantConfig.invalidDateInterval';
            return validDates;
        }

        if (moment(moment(availabilitySplitTo).format('YYYY-MM-DDTHH:mm:ss')).isAfter(moment(availabilityValidTo)
                .parseZone().format('YYYY-MM-DDTHH:mm:ss')) ||
            moment(moment(availabilitySplitTo).format('YYYY-MM-DDTHH:mm:ss')).isBefore(moment(availabilityValidFrom)
                .parseZone().format('YYYY-MM-DDTHH:mm:ss'))) {
            validDates.isValid = false;
            validDates.errorMessage = 'tenantConfig.invalidDateInterval';
            return validDates;
        }


        return validDates;

    }

    mapAvailabilitySplitForSave(availabilitySplit: BackofficeAvailabilitySplitDataType, timeZoneId: string, endOfDay: boolean = false): AvailabilitySplitType {
        const splitFromMoment = moment.tz(moment(availabilitySplit.splitFrom).parseZone().format('YYYY-MM-DD HH:mm'), timeZoneId);
        const splitToMoment = moment.tz(moment(availabilitySplit.splitTo).parseZone().format('YYYY-MM-DD HH:mm'), timeZoneId);
        if (endOfDay) {
            splitToMoment.add(1, 'days').startOf('day');
        }

        return {
            id: availabilitySplit.id,
            splitFrom: splitFromMoment.format(),
            splitTo: splitToMoment.format(),
            endOfDay
        } as AvailabilitySplitType;
    }
}
