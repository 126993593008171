import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    AreaDependentFiltersType,
    AreaProvider,
    AreaType,
    ChannelDependentFiltersType,
    ChannelEnum,
    ChannelProvider,
    CrossAvailabilityRuleOptionEnum,
    CrossAvailabilityRuleProvider,
    CrossAvailabilityRuleType,
    CrossAvailabilityRuleTypeEnum,
    FilterComponentChannelType,
    RuleScopeEnum,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType,
    RuleTypeEnum,
    RuleUnitEnum,
    SearchFilterUtils,
    TagDependentFiltersScopeEnum,
    TagDependentFiltersType,
    TagProvider,
    TagType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {map, take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {CrossAvailabilityRuleUtils} from '../cross-availability-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';
import {
    CrossAvailabilityRuleFiltersEnum, CrossAvailabilityRuleInfoMessageOptionsType,
    CrossAvailabilityRuleInfoMessagePropertyType,
    CrossAvailabilityRuleInfoMessageType,
    CrossAvailabilityRuleInfoMessageTypeEnum
} from '../cross-availability-rule.types';
import {forkJoin} from 'rxjs';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-cross-availability-rule',
    templateUrl: './create-cross-availability-rule.component.html',
    styleUrls: ['./create-cross-availability-rule.component.scss']
})
export class CreateCrossAvailabilityRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: CrossAvailabilityRuleType = {} as CrossAvailabilityRuleType;
    initialRule: CrossAvailabilityRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.CrossAvailabilityRule);

    initialChannel: FilterComponentChannelType;
    CrossAvailabilityRuleFiltersEnum = CrossAvailabilityRuleFiltersEnum;
    crossAvailabilityRuleTypeEnumList = Object.values(CrossAvailabilityRuleTypeEnum);
    CrossAvailabilityRuleOptionEnum = CrossAvailabilityRuleOptionEnum;
    crossAvailabilityRuleOptionEnumList = Object.values(CrossAvailabilityRuleOptionEnum);
    crossAvailabilityRuleOptionEnumListWithoutInput = [CrossAvailabilityRuleOptionEnum.Output, CrossAvailabilityRuleOptionEnum.MatchAndOutput];
    ruleScopeEnumList = Object.values(RuleScopeEnum).filter((value => value !== RuleScopeEnum.NoLimit));
    ruleUnitEnumList = Object.values(RuleUnitEnum);
    mainDependentFilters: {
        area: AreaDependentFiltersType,
        channel: ChannelDependentFiltersType,
        centerTag: TagDependentFiltersType,
        coveragePlanTag: TagDependentFiltersType,
        specialityTag: TagDependentFiltersType,
        serviceTag: TagDependentFiltersType,
        resourceTag: TagDependentFiltersType,
        availabilityTag: TagDependentFiltersType,
        patientTag: TagDependentFiltersType,
        patientTagException: TagDependentFiltersType,
    };
    infoMessages: CrossAvailabilityRuleInfoMessageType[];

    isTypeSelectEnabled = true;

    constructor(
        public crossAvailabilityRuleUtils: CrossAvailabilityRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public areaProvider: AreaProvider,
        public channelProvider: ChannelProvider,
        public tagProvider: TagProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private crossAvailabilityRuleProvider: CrossAvailabilityRuleProvider,
        private searchFilterUtils: SearchFilterUtils,
    ) {
    }

    ngOnInit() {
        this.loadDependentFilters();
        this.loadInfoMessages();
        this.setupInitialState();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: CrossAvailabilityRuleType): boolean {
        return !!(rule.crossAvailabilityRuleTableId &&
            !lodash.isEmpty(rule.crossAvailabilityRuleTable) && rule.name
            && !this.generalUtils.isNullOrUndefined(rule.priority)
            && this.isPriorityValid(rule.priority));
    }

    isInputStepValidAtLeastOneMatch(rule: CrossAvailabilityRuleType): boolean {
        const ruleOptions: CrossAvailabilityRuleOptionEnum[] = [
            rule.channelRuleOption,
            rule.areaRuleOption,
            rule.centerTagRuleOption,
            rule.coveragePlanTagRuleOption,
            rule.specialityTagRuleOption,
            rule.serviceTagRuleOption,
            rule.resourceTagRuleOption,
            rule.availabilityTagRuleOption,
            rule.patientTagRuleOption,
        ];
        return ruleOptions.some((value) => value === CrossAvailabilityRuleOptionEnum.Match || value === CrossAvailabilityRuleOptionEnum.MatchAndOutput);
    }

    isInputStepValidAtLeastOneOutputSegment(rule: CrossAvailabilityRuleType): boolean {
        const ruleOptions: CrossAvailabilityRuleOptionEnum[] = [
            rule.channelRuleOption,
            rule.coveragePlanTagRuleOption,
            rule.patientTagRuleOption,
        ];
        return ruleOptions.some((value) => value === CrossAvailabilityRuleOptionEnum.Output || value === CrossAvailabilityRuleOptionEnum.MatchAndOutput);
    }

    isInputStepValidAtLeastOneOutputCapacity(rule: CrossAvailabilityRuleType): boolean {
        const ruleOptions: CrossAvailabilityRuleOptionEnum[] = [
            rule.areaRuleOption,
            rule.centerTagRuleOption,
            rule.coveragePlanTagRuleOption,
            rule.specialityTagRuleOption,
            rule.serviceTagRuleOption,
            rule.resourceTagRuleOption,
            rule.availabilityTagRuleOption,
        ];
        return ruleOptions.some((value) => value === CrossAvailabilityRuleOptionEnum.Output || value === CrossAvailabilityRuleOptionEnum.MatchAndOutput);
    }

    isInputStepValid(rule: CrossAvailabilityRuleType): boolean {
        const requiredFields = this.isInputStepValidAtLeastOneMatch(rule)
            && this.isInputStepValidAtLeastOneOutputSegment(rule) && this.isInputStepValidAtLeastOneOutputCapacity(rule);
        const ruleOptionRequirements =
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.channel, rule.channelRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.area, rule.areaRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.centerTag, rule.centerTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.coveragePlanTag, rule.coveragePlanTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.specialityTag, rule.specialityTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.serviceTag, rule.serviceTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.resourceTag, rule.resourceTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.availabilityTag, rule.availabilityTagRuleOption) &&
            this.generalUtils.areEitherBothTruthyOrBothFalsy(rule.patientTag, rule.patientTagRuleOption);
        return requiredFields && ruleOptionRequirements;
    }

    isOutputStepValid(rule: CrossAvailabilityRuleType): boolean {
        return !!(rule.type && rule.ruleScope && rule.unit
            && this.rulesUtils.isNumberValidForUnit(rule.unit, rule.value)
            && this.isWholePositiveNumber(rule.deactivateBefore)
            && rule.message);
    }

    areAllStepsValid(rule: CrossAvailabilityRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }

    isWholePositiveNumber(number: number, canBeZero: boolean = true): boolean {
        return number % 1 === 0 && (canBeZero ? number >= 0 : number > 0);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.crossAvailabilityRuleTable = ruleTable[0];
            this.ruleItem.crossAvailabilityRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.crossAvailabilityRuleTable = undefined;
            this.ruleItem.crossAvailabilityRuleTableId = undefined;
        }
        this.checkRuleTableType();
    }

    isPriorityValid(priority: number): boolean {
        return Number.isInteger(priority) && priority > 0 && priority < 201;
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedTagOrAreaValue(entityName: CrossAvailabilityRuleFiltersEnum, selectedValues: TagType[] | AreaType[]) {
        if (selectedValues?.length > 0) {
            this.ruleItem[entityName.valueOf()] = selectedValues[0];
            this.ruleItem[entityName + 'Id'] = selectedValues[0].id;
        } else {
            this.ruleItem[entityName.valueOf()] = undefined;
            this.ruleItem[entityName + 'Id'] = undefined;
        }
        if (this.ruleItem?.hasOwnProperty(entityName + 'RuleOption')) {
            this.ruleItem[entityName + 'RuleOption'] = undefined;
        }
    }

    onSelectedChannelValue(selectedValues: FilterComponentChannelType[]) {
        if (selectedValues?.length > 0) {
            this.initialChannel = selectedValues[0];
            this.ruleItem.channel = selectedValues[0].enumValue as ChannelEnum;
        } else {
            this.ruleItem.channel = undefined;
            this.initialChannel = undefined;
        }
        this.ruleItem.channelRuleOption = undefined;
    }

    //
    // endregion Input methods

    // region Output methods
    //
    sanitizeNgSelectValue(option: string, property: string) {
        // On selecting the empty value, instead of returning undefined, ng-select returns an object that looks like this:
        //  {$ngOptionValue: undefined, $ngOptionLabel: ...., ....}
        // Basically we need this sanitization because ng-select is dumb
        if (option.hasOwnProperty('$ngOptionValue')) {
            this.ruleItem[property] = undefined;
        }
    }
    //
    // endregion Output methods

    // region Overview methods
    //
    saveRule(rule: CrossAvailabilityRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.crossAvailabilityRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: CrossAvailabilityRuleType) {
        this.ngxLoader.start();
        this.crossAvailabilityRuleProvider.addEntry(rule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newCrossAvailabilityRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: CrossAvailabilityRuleType, newRule: CrossAvailabilityRuleType) {
        this.ngxLoader.start();
        this.crossAvailabilityRuleProvider.updateEntry(oldRule, newRule)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.crossAvailabilityRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.checkRuleTableType();
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
                if (!this.generalUtils.isNullOrUndefined(this.ruleItem.channel)) {
                    // TODO-rules: check if this can be done without loadChannels
                    this.loadChannels();
                }
            }
        } else {
            this.ruleItem = this.crossAvailabilityRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }

    private checkRuleTableType() {
        if (!this.ruleItem.crossAvailabilityRuleTableId) {
            this.isTypeSelectEnabled = true;
            return;
        }
        this.ngxLoader.start();
        const FILTER = this.crossAvailabilityRuleUtils.getQueryFilterForRuleTableType(this.ruleItem.crossAvailabilityRuleTableId);
        // If we're creating a new rule, we only check for one existing rule
        // If we're editing an existing rule, we can edit the rule type if it's the only one in that rule table
        const VALUE_TO_CHECK = history.state?.action === constants.EDIT ? 2 : 1;
        this.crossAvailabilityRuleProvider.getEntries(FILTER)
            .pipe(map(entries => entries.value.length >= VALUE_TO_CHECK ? entries.value[0].type : null))
            .subscribe(type => {
                this.isTypeSelectEnabled = !type;
                if (!!type) {
                    this.ruleItem.type = type;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
                this.rulesUtils.goToParentPage('createRule');
            });
    }

    private loadChannels() {
        this.ngxLoader.start();
        this.channelProvider.getEntries().subscribe(({value, count}) => {
            const CHANNEL = value.find(channel => channel.enumValue === this.ruleItem.channel);
            this.initialChannel = {id: CHANNEL?.id, name: CHANNEL.channel, enumValue: CHANNEL.enumValue};
            this.ngxLoader.stop();
        }, error => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
            this.rulesUtils.goToParentPage('createRule');
        });
    }

    private loadDependentFilters() {
        this.mainDependentFilters = {
            channel: this.crossAvailabilityRuleUtils.getChannelDependentFilters(),
            area: this.crossAvailabilityRuleUtils.getAreaDependentFilters(),
            centerTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedCenter, false, true),
            coveragePlanTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedCoveragePlan, true, true),
            specialityTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedSpeciality, true, true),
            serviceTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedService, false, true),
            resourceTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedResource, false, true),
            availabilityTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedNone, false),
            patientTag: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true),
            patientTagException: this.searchFilterUtils.getTagsDependentFilters(true, TagDependentFiltersScopeEnum.ScopedPatient, true, true),
        };
    }

    private isInfoMessagePropertyVisible(
        ruleItem: CrossAvailabilityRuleType,
        type: CrossAvailabilityRuleInfoMessageTypeEnum,
        propertyName: string
    ): boolean {
        const propertyValue: any = ruleItem[propertyName];
        const propertyRuleOptionValue: CrossAvailabilityRuleOptionEnum =
            ruleItem[`${propertyName}RuleOption`] as CrossAvailabilityRuleOptionEnum;
        if (!propertyValue) {
            return false;
        }
        switch (type) {
            case CrossAvailabilityRuleInfoMessageTypeEnum.Output: {
                return propertyRuleOptionValue === CrossAvailabilityRuleOptionEnum.MatchAndOutput
                    || propertyRuleOptionValue === CrossAvailabilityRuleOptionEnum.Output;
            }
            case CrossAvailabilityRuleInfoMessageTypeEnum.Input: {
                return propertyRuleOptionValue === CrossAvailabilityRuleOptionEnum.Match
                    || propertyRuleOptionValue === CrossAvailabilityRuleOptionEnum.MatchAndOutput;
            }
            case CrossAvailabilityRuleInfoMessageTypeEnum.NoRuleOptionCheck: {
                return true;
            }
        }
        return false;
    }

    private getInfoMessagePropertiesNames(
        ruleItem: CrossAvailabilityRuleType,
        type: CrossAvailabilityRuleInfoMessageTypeEnum,
        properties: CrossAvailabilityRuleInfoMessagePropertyType[]
    ): string {
        return properties
            ?.filter(({propertyName}) => this.isInfoMessagePropertyVisible(ruleItem, type, propertyName))
            ?.map(({propertyLabel, propertyName}) => `${propertyLabel}: ${ruleItem[propertyName]?.name ?? ruleItem[propertyName]}`)
            ?.join(', ') ?? '';
    }

    private loadInfoMessages() {
        // Translate all needed labels
        const toTranslate = [
            'label.channel', 'label.area2', 'label.coveragePlanTag', 'label.centerTag', 'label.specialityTag',
            'label.serviceTag', 'label.resourceTag', 'label.availabilityTag', 'label.patientTag',
        ];
        forkJoin(
            toTranslate.map(label => this.messagesService.translateMessage(label))
        ).subscribe(([
            channelLabel, areaLabel, coveragePlanTagLabel, centerTagLabel, specialityTagLabel,
            serviceTagLabel, resourceTagLabel, availabilityTagLabel, patientTagLabel
        ]) => {

            // Setup for info messages options
            const infoMessagesOptions: CrossAvailabilityRuleInfoMessageOptionsType[] = [
                {
                    label: 'label.crossAvailabilityRuleAppliesToTheFollowingUserSegment',
                    type: CrossAvailabilityRuleInfoMessageTypeEnum.Output,
                    ruleItemProperties: [
                        {propertyLabel: channelLabel, propertyName: 'channel'},
                        {propertyLabel: patientTagLabel, propertyName: 'patientTag'},
                        {propertyLabel: coveragePlanTagLabel, propertyName: 'coveragePlanTag'},
                    ],
                },
                {
                    label: 'label.crossAvailabilityRuleCapacityIsGeneratedByTheResourcesMatching',
                    type: CrossAvailabilityRuleInfoMessageTypeEnum.Output,
                    ruleItemProperties: [
                        {propertyLabel: areaLabel, propertyName: 'area'},
                        {propertyLabel: centerTagLabel, propertyName: 'centerTag'},
                        {propertyLabel: coveragePlanTagLabel, propertyName: 'coveragePlanTag'},
                        {propertyLabel: specialityTagLabel, propertyName: 'specialityTag'},
                        {propertyLabel: serviceTagLabel, propertyName: 'serviceTag'},
                        {propertyLabel: resourceTagLabel, propertyName: 'resourceTag'},
                        {propertyLabel: availabilityTagLabel, propertyName: 'availabilityTag'},
                    ],
                },
                {
                    label: 'label.crossAvailabilityTheRuleWillBeTakenIntoConsiderationIfTheFollowingApply:',
                    type: CrossAvailabilityRuleInfoMessageTypeEnum.Input,
                    ruleItemProperties: [
                        {propertyLabel: channelLabel, propertyName: 'channel'},
                        {propertyLabel: areaLabel, propertyName: 'area'},
                        {propertyLabel: centerTagLabel, propertyName: 'centerTag'},
                        {propertyLabel: coveragePlanTagLabel, propertyName: 'coveragePlanTag'},
                        {propertyLabel: specialityTagLabel, propertyName: 'specialityTag'},
                        {propertyLabel: serviceTagLabel, propertyName: 'serviceTag'},
                        {propertyLabel: resourceTagLabel, propertyName: 'resourceTag'},
                        {propertyLabel: availabilityTagLabel, propertyName: 'availabilityTag'},
                        {propertyLabel: patientTagLabel, propertyName: 'patientTag'},
                    ],
                },
                {
                    label: 'label.crossAvailabilityTheRuleWillNotBeTakenIntoConsiderationIfThePatientHasTag',
                    type: CrossAvailabilityRuleInfoMessageTypeEnum.NoRuleOptionCheck,
                    ruleItemProperties: [
                        {propertyLabel: patientTagLabel, propertyName: 'patientTagException'},
                    ],
                },
            ];

            // Create info messages
            this.infoMessages = infoMessagesOptions.map((options: CrossAvailabilityRuleInfoMessageOptionsType) => ({
                label: options.label,
                ruleItemProperties: options.ruleItemProperties,
                visible: (ruleItem: CrossAvailabilityRuleType) => options.ruleItemProperties.some(
                    ({propertyName}) => this.isInfoMessagePropertyVisible(ruleItem, options.type, propertyName)
                ),
                getPropertiesNames: (ruleItem: CrossAvailabilityRuleType) => this.getInfoMessagePropertiesNames(
                    ruleItem, options.type, options.ruleItemProperties
                ),
            }));
        });
    }
}
