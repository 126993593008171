<div class="slots-management-wrapper pb-1">
    <div class="error-message d-flex mb-2 p-2" *ngIf="!displaySlotsManagement">
        <i class="fas fa-exclamation-circle ml-3 mr-2 mt-1"></i>
        <span translate="toastr.error.noOrganizationalRoleAssignedForTeam"></span>
    </div>

<div class="patient-search-component-container p-2" *ngIf="displaySlotsManagement">
    <sbase-patient-search [options]="patientSearchOptions"
                          (onSelectedPatientOutput)="onSelectedPatient($event)"
                          (patientSearchActions)="patientContextService.onPatientSearchActions($event)">
    </sbase-patient-search>
</div>

    <div class="container-fluid slots-management-wrapper-inner pb-0" *ngIf="displaySlotsManagement">
        <div class="d-flex justify-content-between">
        </div>
        <div class="row slots-filters-container">
            <sbase-slots-filters
                    [options]="slotsFiltersOptions"
                    [enableCollapse]="true"
                    [updatePatientExternalEmitter]="slotsFiltersExternalUpdatePatientEmitter"
                    (search)="onSearch($event)"
                    (filtersChange)="onFiltersChange($event)"
                    (outsideAvailabilityBooking)="onOutsideAvailabilityBooking($event)"
                    (reset)="onFiltersReset()"
                    (isDataLoaded)="filtersAreLoaded($event)">
            </sbase-slots-filters>
        </div>

        <div class="row slots-calendar-and-results-container">
            <div class="col-custom-4 col-custom-3 px-0 pt-1 slots-calendar-and-local-filters-column custom-scrollbar">
                <app-slots-calendar
                        (selectValue)="onSlotsCalendarSelectValue()"
                        (continueSearching)="onSlotsCalendarContinueSearching()">
                </app-slots-calendar>
                <app-slots-local-filters *ngIf="displayLocalFilters"
                                         (localFiltersSelected)="onSelectedLocalFilters()">
                </app-slots-local-filters>
                <!-- Slots centers filter -->
                <app-slots-local-filters-centers
                        *ngIf="displayLocalFilters" (select)="onSlotsCentersOkClick()">
                </app-slots-local-filters-centers>
            </div>
            <div class="col-custom-8 col-custom-9 container-fluid pr-0 pl-1 pb-2 slots-results-outer-container">
                <app-slots-results
                        (selectValue)="onSelectSlot($event)">
                </app-slots-results>
            </div>
        </div>
    </div>
</div>




