<div class="container-fluid create-availability-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="row pl-4">
        <div class="col-4 p-0">
            <div class="create-availability-header">
                <h3 class="create-availability-title">{{screenTemplateLayout.pageTitle|translate}}
                    <span class="text-lowercase" translate="label.availability2"></span>
                </h3>
            </div>
        </div>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-service-section create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!-- GENERAL-->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== VIEW">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-5">
                                <!--shortId-->
                                <div class="form-group" *ngIf="screenTemplateLayout.action !== CREATE">
                                    <label translate="label.shortId"></label>
                                    <input type="text"
                                           name="shortId"
                                           class="input-element"
                                           [(ngModel)]="availabilityData.shortId"
                                           [disabled]=true>
                                </div>
                                <!--resource-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.resource"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                                                  [buttonName]='"label.resource"'
                                                  [dependentFilters]="mainDependentFilters.resource"
                                                  [initialFilterValues]="[availabilityData.resource]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !availabilityData?.resource?.id}"
                                                  [providerInstance]="resourceProvider">
                                    </sbase-filter>
                                    <div *ngIf="!availabilityData?.resource?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !availabilityData?.resource?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>

                                <!--center-->
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.center2"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCenter($event)"
                                                  [buttonName]='"label.center2"'
                                                  [dependentFilters]="mainDependentFilters.location"
                                                  [initialFilterValues]="[availabilityData.center]"
                                                  [isMultipleSelectedItem]="false"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="centerProvider"
                                                  [ngClass]="{'input-element-has-error': availabilityData.center === undefined}"
                                                  [useSelectedValueAsLabel]="true"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                    <div *ngIf="availabilityData.center === undefined"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': availabilityData.center === undefined}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-7">
                                <div class="form-group customSelect tags-input">
                                    <label translate="label.tags"></label>
                                    <div [ngClass]="{'disabled-sbaseFilter': !availabilityData?.resource?.id}">
                                        <ngx-ui-loader
                                                [loaderId]="resourceTagsLoaderId"
                                                [hasProgressBar]="false"
                                                fgsSize=20
                                                bgsOpacity=0.2></ngx-ui-loader>
                                        <sbase-filter (selectedItemsOutput)="onSelectTagsForResource($event)"
                                                      [buttonName]='"label.newTag"'
                                                      [dependentFilters]="mainDependentFilters?.resourceTags"
                                                      [initialFilterValues]="availabilityData.tags"
                                                      [isMultipleSelectedItem]="true"
                                                      [useSelectedValueAsLabel]="true"
                                                      [hideSelectedItems]="true"
                                                      [providerInstance]="tagProvider"
                                                      [customTemplate]="tagsTemplate">
                                        </sbase-filter>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!--THIS MIGHT BE NEEDED IN THE FUTURE-->
                            <!--                                <div class="form-group" [featureDisplay]="'admin-availabilities-advanced-scenario'">-->
                            <!--                                    <label translate="label.advancedMode"></label>-->
                            <!--                                    <div class="switch">-->
                            <!--                                        <label>-->
                            <!--                                            <span translate="label.no"></span>-->
                            <!--                                            <input type="checkbox" checked-->
                            <!--                                                   [(ngModel)]="availabilityData.advancedMode">-->
                            <!--                                            <span class="lever switch-col-black"></span>-->
                            <!--                                            <span translate="label.yes"></span>-->
                            <!--                                        </label>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <div class="p-3" [featureDisplay]="'admin-room-reservation'">
                                <div class="form-group">
                                    <label translate="label.roomReservationNeeded"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="availabilityData.roomReservationNeeded">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="p-3" [featureDisplay]="'availability-holiday-ignore'">
                                <div class="form-group">
                                    <label translate="label.ignoreHolidays"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="availabilityData.ignoreHolidays">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--SERVICES-->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== VIEW">
                    <ng-template matStepLabel><span translate="label.services"></span></ng-template>
                    <div class="step-form services">
                        <div class="row ml-1"
                             [ngClass]="{'mb-3':updateAvailabilityServices}">
                            <button (click)="openAvailabilityServicesSection(action, undefined)"
                                    type="button"
                                    class="btn theme-btn">
                                <i class="fas fa-plus"></i>
                                <span translate="label.addService" class="ml-2"></span>
                            </button>
                        </div>

                        <!--Availability services section-->
                        <div class="row ml-1" *ngIf="updateAvailabilityServices">
                            <div class="card service-card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-2">
                                                <label translate="label.service2"></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group customSelect service mt-1 sbase-input-element"
                                                     [ngClass]="{'disabled-class': scopedService?.editedIndex >=0 }">
                                                    <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                                                  [buttonName]='"label.search"'
                                                                  [dependentFilters]="mainDependentFilters.service"
                                                                  [initialFilterValues]="initialScopedService"
                                                                  [isMultipleSelectedItem]="false"
                                                                  [providerInstance]="serviceProvider"
                                                                  [hideSelectedItems]="true"
                                                                  [useSelectedValueAsLabel]="true"
                                                                  [additionalExclusionList]="servicesExclusionList"
                                                                  [ngClass]="{'input-element-has-error': !scopedService?.service?.id}"
                                                                  [customTemplate]="serviceTemplate">
                                                    </sbase-filter>
                                                    <ng-template let-item #serviceTemplate>
                                                        <span *ngIf="!!item.specialityName">
                                                            <span class="font-weight-bold">{{item.specialityName}} </span>
                                                        </span>
                                                        <!-- in select -->
                                                        <span *ngIf="!item.nameWithoutSpeciality">
                                                            <span>{{item.name}}</span>
                                                        </span>
                                                        <!-- in dropdown -->
                                                        <span *ngIf="!!item.nameWithoutSpeciality">
                                                            <span>{{item.nameWithoutSpeciality}}</span>
                                                        </span>
                                                        <i *ngIf="item.onlineConsultation" class="fas fa-desktop ml-1"
                                                           ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                                                    </ng-template>
                                                    <div *ngIf="!scopedService?.service?.id"
                                                         class="help-block">
                                                        <span [ngClass]="{'has-error': !scopedService?.service?.id}"
                                                              translate="label.error.required">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-2">
                                                <label translate="label.duration"></label>
                                            </div>
                                            <div class="col-2 numeric-input-width pr-0"
                                                 *ngIf="scopedService.showDurationForMainResource">
                                                <div class="form-group customSelect">
                                                    <div [ngClass]="{'disabled-class': !scopedService?.service?.id}">
                                                        <input type="number" class="input-element"
                                                               name="duration"
                                                               #duration="ngModel"
                                                               min="0"
                                                               step="1"
                                                               [(ngModel)]="scopedService.duration"
                                                               [pattern]="RegexWholeNumber"
                                                               [disabled]="!scopedService?.service?.id"
                                                               [ngClass]="{'input-element-has-error': (scopedService?.duration === undefined || scopedService?.duration === null) || !validServiceDuration}"
                                                               (ngModelChange)="onScopedServiceDurationChanged(scopedService.duration)"
                                                               autocomplete="off">
                                                        <div *ngIf="(scopedService?.duration === undefined || scopedService?.duration === null)"
                                                             class="help-block">
                                                                <span [ngClass]="{'has-error': (scopedService?.duration === undefined || scopedService?.duration === null)}"
                                                                      translate="label.error.required">
                                                                </span>
                                                        </div>
                                                        <div *ngIf="!validServiceDuration"
                                                             class="help-block">
                                                                <span [ngClass]="{'has-error': !validServiceDuration}"
                                                                      translate="label.error.duration0">
                                                                </span>
                                                        </div>
                                                        <div *ngIf="duration?.errors?.pattern" class="help-block">
                                                                <span class="inValidPosition"
                                                                      translate="label.invalidField"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2" [featureDisplay]="'control-patient-service-duration'">
                                                <label translate="label.controlDuration"></label>
                                            </div>
                                            <div class="col-2 numeric-input-width pr-0"
                                                 *ngIf="scopedService.showDurationForMainResource"
                                                 [featureDisplay]="'control-patient-service-duration'">
                                                <div class="form-group customSelect">
                                                    <div [ngClass]="{'disabled-class': !scopedService?.service?.id}">
                                                        <input type="number" class="input-element"
                                                               name="controlDuration"
                                                               #controlDuration="ngModel"
                                                               min="0"
                                                               step="1"
                                                               [(ngModel)]="scopedService.controlDuration"
                                                               [pattern]="RegexWholeNumber"
                                                               [disabled]="!scopedService?.service?.id"
                                                               [ngClass]="{'input-element-has-error': !validServiceControlDuration }"
                                                               (ngModelChange)="onScopedServiceControlDurationChanged(scopedService)"
                                                               autocomplete="off">
                                                        <div *ngIf="(scopedService?.controlDuration === undefined || scopedService?.controlDuration === null)"
                                                             class="help-block">
                                                                <span [ngClass]="{'has-error': (scopedService?.controlDuration === undefined || scopedService?.controlDuration === null)}"
                                                                      translate="label.error.required">
                                                                </span>
                                                        </div>
                                                        <div *ngIf="!validServiceControlDuration"
                                                             class="help-block">
                                                                <span [ngClass]="{'has-error': !validServiceControlDuration}"
                                                                      translate="label.error.controlDuration0">
                                                                </span>
                                                        </div>
                                                        <div *ngIf="controlDuration?.errors?.pattern"
                                                             class="help-block">
                                                                <span class="inValidPosition"
                                                                      translate="label.invalidField"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1 pl-0"
                                                 *ngIf="scopedService.showDurationForMainResource">
                                                <label translate="label.min"></label>
                                            </div>
                                            <div class="col-2 numeric-input-width"
                                                 *ngIf="!scopedService.showDurationForMainResource">
                                                <app-show-availability-main-resource-duration
                                                        [serviceId]="scopedService.serviceId"
                                                        [resourceId]="availabilityData.resourceId"
                                                ></app-show-availability-main-resource-duration>
                                            </div>

                                            <div class="col-3 pr-0">
                                                <label translate="label.groupAppointmentsSlots"></label>
                                            </div>
                                            <div class="col-2 numeric-input-width pl-0">
                                                <div class="form-group customSelect">
                                                    <div [ngClass]="{'disabled-class': !scopedService?.service?.id}">
                                                     <span [ngClass]="{ 'has-error': !scopedService?.groupAppointmentSlots}">
                                                        <input type="number" class="form-control"
                                                               name="groupAppointmentSlots"
                                                               #groupAppointmentSlots="ngModel"
                                                               min="1"
                                                               [(ngModel)]="scopedService.groupAppointmentSlots"
                                                               [pattern]="RegexWholeNumber"
                                                               autocomplete="off">
                                                            <div *ngIf="scopedService?.groupAppointmentSlots === undefined || scopedService?.groupAppointmentSlots === null"
                                                                 class="help-block">
                                                                <span [ngClass]="{'has-error': scopedService?.groupAppointmentSlots === undefined || scopedService?.groupAppointmentSlots === null}"
                                                                      translate="label.error.required">
                                                                </span>
                                                            </div>
                                                            <div *ngIf="groupAppointmentSlots?.errors?.pattern"
                                                                 class="help-block">
                                                                <span class="inValidPosition"
                                                                      translate="label.invalidField"></span>
                                                            </div>
                                                     </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-2">
                                                <label translate="label.tags"></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group customSelect tags-input">
                                                    <div [ngClass]="{'disabled-sbaseFilter': !scopedService?.service?.id}">
                                                        <ngx-ui-loader [loaderId]="serviceTagsLoaderId"
                                                                       [hasProgressBar]="false"
                                                                       fgsSize=20
                                                                       bgsOpacity=0.2></ngx-ui-loader>
                                                        <sbase-filter
                                                                (selectedItemsOutput)="onSelectServiceTags($event)"
                                                                [buttonName]='"label.newTag"'
                                                                [dependentFilters]="mainDependentFilters?.serviceTags"
                                                                [initialFilterValues]="scopedService.tags"
                                                                [isMultipleSelectedItem]="true"
                                                                [useSelectedValueAsLabel]="true"
                                                                [hideSelectedItems]="true"
                                                                [providerInstance]="tagProvider"
                                                                [customTemplate]="tagsTemplate">
                                                        </sbase-filter>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-2">
                                                <label translate="label.coveragePlans"></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group customSelect service">
                                                    <div [ngClass]="{'disabled-class': !scopedService?.service?.id}">
                                                        <sbase-filter
                                                                (selectedItemsOutput)="onSelectedCoveragePlans($event)"
                                                                [buttonName]='"label.search"'
                                                                [dependentFilters]="mainDependentFilters.coveragePlan"
                                                                [initialFilterValues]="initialCoveragePlan"
                                                                [isMultipleSelectedItem]="true"
                                                                [providerInstance]="coveragePlanProvider"
                                                                [useSelectedValueAsLabel]="true"
                                                                [hideSelectedItems]="true"
                                                                class="sbase-dropdown-ml">
                                                        </sbase-filter>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item" [featureDisplay]="'admin-subServices'">
                                        <div class="row">
                                            <div class="col-2">
                                                <label translate="label.subServices"></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group customSelect mt-2">
                                                    <div [ngClass]="{'disabled-class': !scopedService?.service?.id || !scopedService?.service?.hasSubServices,
                                                    'has-error': scopedService?.service?.hasSubServices && scopedService?.subServices?.length == 0}">
                                                        <span *ngFor="let subService of scopedService?.subServices; last as islast">
                                                            {{subService.name}}
                                                            <span *ngIf="scopedService.showDurationForMainResource"> | {{subService.duration}}
                                                                <span translate="label.min"></span></span>
                                                            <span *ngIf="!islast">, </span>
                                                        </span>
                                                        <div class="float-right"
                                                             *ngIf="scopedService?.service?.hasSubServices">
                                                            <app-assign-sub-services [options]="subServicesOptions"
                                                                                     (onSubServicesChanged)="onSelectedSubServices($event)"></app-assign-sub-services>
                                                        </div>
                                                        <div *ngIf="scopedService?.service?.hasSubServices && scopedService?.subServices?.length == 0"
                                                             class="help-block mt-3">
                                                            <span [ngClass]="{'has-error': scopedService?.service?.hasSubServices && scopedService?.subServices?.length == 0}"
                                                                  translate="label.error.required">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex float-right mb-3 mt-1 update-buttons"
                             *ngIf="updateAvailabilityServices">
                            <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                                    [disabled]="!availabilityUtils.isScopedServiceValid(scopedService)"
                                    translate="button.save"
                                    (click)="onSaveService(scopedService)">
                            </button>
                            <button class="btn border-0 p-0" translate="button.cancel"
                                    (click)="onCancelService()"></button>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label class="pt-3" translate="label.availabilityServices"></label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive services-table-container custom-scrollbar">
                            <table class="table table-bordered grey-table">
                                <thead class="thead-light">
                                <tr>
                                    <td translate="label.service2"></td>
                                    <td translate="label.durationShort" ngbTooltip="{{'label.duration'| translate}}"
                                        container="body"></td>
                                    <td translate="label.controlDurationShort"
                                        ngbTooltip="{{'label.controlDuration'| translate}}"
                                        container="body"
                                        [featureDisplay]="'control-patient-service-duration'"></td>
                                    <td translate="label.groupAppointmentsSlotsAbbreviation"
                                        ngbTooltip="{{'label.groupAppointmentsSlots'| translate}}"
                                        container="body"></td>
                                    <td translate="label.tags"></td>
                                    <td translate="label.coveragePlans"></td>
                                    <td [featureDisplay]="'admin-subServices'"
                                        translate="label.subServices"></td>
                                    <td translate="label.action"></td>
                                </tr>
                                </thead>
                                <!--display an empty row if no timeslot is added-->
                                <tbody *ngIf="!availabilityData?.services || availabilityData?.services?.length===0">
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td [featureDisplay]="'control-patient-service-duration'"></td>
                                    <td></td>
                                    <td></td>
                                    <td [featureDisplay]="'admin-subServices'"></td>
                                    <td></td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr *ngFor="let service of availabilityData?.services; let index = index">
                                    <td>
                                        <span class="font-weight-bold">{{service.specialityName}}</span> {{service.name}}
                                        <i *ngIf="service.onlineConsultation" class="fas fa-desktop ml-1"
                                           ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                                    </td>
                                    <td><span
                                            *ngIf="service?.isMainResourceInBluePrint"> {{service.duration}}
                                        <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>
                                            </span>
                                        <span *ngIf="!service?.isMainResourceInBluePrint"
                                              class="mr-1">
                                        <app-show-availability-main-resource-duration
                                                [serviceId]="service.serviceId"
                                                [resourceId]="availabilityData.resourceId">
                                        </app-show-availability-main-resource-duration>
                                    </span>
                                    </td>
                                    <td [featureDisplay]="'control-patient-service-duration'">
                                    <span *ngIf="service?.isMainResourceInBluePrint"> {{service.controlDuration}}
                                        <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>
                                    </span>
                                    </td>
                                    <td>{{service.groupAppointmentSlots}}</td>
                                    <td>
                                     <span *ngFor="let tag of service?.tags; last as islast">
                                            {{tag.name}}<span *ngIf="!islast">, </span>
                                     </span>
                                    </td>
                                    <td>
                                    <span *ngFor="let coveragePlan of service?.coveragePlans; last as islast">
                                            {{coveragePlan.name}}<span *ngIf="!islast">, </span>
                                     </span>
                                    </td>
                                    <td [featureDisplay]="'admin-subServices'">

                                    <span *ngFor="let subService of service?.subServices; last as islast">
                                        {{subService.name}}
                                        <span *ngIf="service?.isMainResourceInBluePrint"> | {{subService.duration}}
                                            <span translate="label.min"></span></span>
                                        <span *ngIf="!islast">, </span>
                                     </span>
                                    </td>
                                    <td class="text-center">
                                        <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                                popoverClass="popover-shadow">
                                            <i class="fas fa-ellipsis-h"></i>
                                        </button>
                                        <ng-template #popContent>
                                            <ul class="list-style-none m-0 p-0 custom-drop-down">
                                                <li>
                                                    <button type="button" class="btn" translate="label.edit"
                                                            (click)="editAvailabilityService(service, index)"></button>
                                                </li>
                                                <li>
                                                    <button type="button" class="btn" translate="label.delete"
                                                            (click)="deleteAvailabilityService(index)"></button>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-step>

                <!--TIME SLOTS-->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== VIEW">
                    <ng-template matStepLabel><span translate="label.timeSlots"></span></ng-template>
                    <div class="step-form time-slots">
                        <!-- Valid from date, valid from time, Valid to date, valid to time, endofday flag -->
                        <div class="row mb-2">
                            <!-- Valid from -->
                            <div class="col-5 date-picker-col">
                                <label translate="label.validFrom"></label>
                                <div class="date-and-time-picker-inputs-container">
                                    <div class="date-picker-input-container">
                                        <input class="date-picker-input"
                                               [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                               name="dpfrom1"
                                               readonly
                                               [minDate]="dateRangeOptions.minDate"
                                               [(ngModel)]="availabilityDateAndTimeRange.dateFrom"
                                               (dateSelect)="onValidFromDateTimeChanged($event, availabilityDateAndTimeRange.timeFrom, true)"
                                               ngbDatepicker #datePickerFrom="ngbDatepicker"
                                               (click)="datePickerFrom.toggle()">
                                        <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                                [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                                (click)="datePickerFrom.toggle()"
                                                type="button">
                                        </button>
                                    </div>
                                    <div class="time-picker-input-container" *ngIf="timeInputsDefaultTimes.timeFrom">
                                        <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                              [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                                              [format]="24"
                                                              [cancelBtnTmpl]="closeBtn"
                                                              [confirmBtnTmpl]="confirmBtn"
                                                              [defaultTime]="timeInputsDefaultTimes.timeFrom"
                                                              #timePickerTimeFrom
                                                              (timeChanged)="onValidFromDateTimeChanged(availabilityDateAndTimeRange.dateFrom, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                        </ngx-timepicker-field>
                                    </div>
                                </div>
                            </div>
                            <!-- Valid to -->
                            <div class="col-5 date-picker-col">
                                <label translate="label.validTo"></label>
                                <div class="date-and-time-picker-inputs-container">
                                    <div class="date-picker-input-container">
                                        <input class="date-picker-input"
                                               [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                               name="dpto1"
                                               readonly
                                               [minDate]="dateRangeOptions.minDate"
                                               [(ngModel)]="availabilityDateAndTimeRange.dateTo"
                                               (dateSelect)="onValidToDateTimeChanged($event,availabilityDateAndTimeRange.timeTo)"
                                               ngbDatepicker #datePickerTo="ngbDatepicker"
                                               (click)="datePickerTo.toggle()">
                                        <button class="btn btn-outline-secondary date-picker-input-calendar-icon"
                                                [ngClass]="{'has-error': !availabilityRangeValidation.isValid}"
                                                (click)="datePickerTo.toggle()"
                                                type="button">
                                        </button>
                                    </div>
                                    <div class="time-picker-input-container" *ngIf="!availabilityData.endOfDay && timeInputsDefaultTimes.timeTo">
                                        <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                              [ngClass]="{'input-element-has-error': !availabilityRangeValidation.isValid}"
                                                              [format]="24"
                                                              [cancelBtnTmpl]="closeBtn"
                                                              [confirmBtnTmpl]="confirmBtn"
                                                              [defaultTime]="timeInputsDefaultTimes.timeTo"
                                                              (timeChanged)="onValidToDateTimeChanged(availabilityDateAndTimeRange.dateTo, dateTimeUtils.hourMinuteStringToNgbTimeStruct($event))">
                                        </ngx-timepicker-field>
                                    </div>
                                </div>
                            </div>
                            <!-- Endofday flag -->
                            <div class="col-2 end-of-day-col">
                                <div class="form-group">
                                    <label translate="label.endOfDay"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked name="endOfDay"
                                                   [(ngModel)]="availabilityData.endOfDay"
                                                   (ngModelChange)="onEndOfDayChange()">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Error messages -->
                        <div class="row mb-2">
                            <!-- Error messages -->
                            <div class="col-12">
                                <div class="form-group">
                                    <div *ngIf="!availabilityRangeValidation.isValid" class="help-block">
                                        <span class="has-error"
                                              [translate]="availabilityRangeValidation.errorMessage">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Time slot button, Repetition period -->
                        <div class="row mt-4"
                             [ngClass]="{'mb-3':updateAvailabilityTimeSlots}">
                            <!-- Time slot button -->
                            <div class="col-9">
                                <button (click)="openTimeSlotsSection(action, undefined)"
                                        type="button"
                                        class="btn theme-btn">
                                    <i class="fas fa-plus"></i>
                                    <span translate="button.addTimeSlot2" class="ml-2"></span>
                                </button>
                            </div>
                            <!-- Repetition period -->
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="form-group create-availability-select-container">
                                        <label class="create-availability-select-label"
                                               translate="label.repetitionPeriod"></label>
                                        <ng-select
                                                class="custom-ng-select input-element"
                                                loadingText="{{'label.loading' | translate}}"
                                                dropdownPosition="bottom"
                                                [(ngModel)]="availabilityData.repetition"
                                                [multiple]="false"
                                                [searchable]="false"
                                                [clearable]="false">
                                            <ng-option [value]="1">
                                                1 <span translate="label.week2"></span>
                                            </ng-option>
                                            <ng-option [value]="2">
                                                2 <span translate="label.weeks"></span>
                                            </ng-option>
                                            <ng-option [value]="3">
                                                3 <span translate="label.weeks"></span>
                                            </ng-option>
                                            <ng-option [value]="4">
                                                4 <span translate="label.weeks"></span>
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Time slot section-->
                        <div class="row" *ngIf="updateAvailabilityTimeSlots">
                            <div class="card col-12">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-6 pt-3">
                                                <div class="time-slot-time-picker-inputs-container">
                                                    <div class="time-slot-time-picker-input">
                                                        <div class="form-group mr-1">
                                                            <label translate="label.from" class="mr-1"></label>
                                                        </div>
                                                        <div class="time-picker-input-container" *ngIf="scopedTimeSlot.hourFromTime">
                                                            <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                                  [ngClass]="{'input-element-has-error': !isTimeValid}"
                                                                                  [format]="24"
                                                                                  [cancelBtnTmpl]="closeBtn"
                                                                                  [confirmBtnTmpl]="confirmBtn"
                                                                                  [defaultTime]="scopedTimeSlot.hourFromTime"
                                                                                  (timeChanged)="onHourFromChanged($event)">
                                                            </ngx-timepicker-field>
                                                        </div>
                                                    </div>

                                                    <div class="time-slot-time-picker-input">
                                                        <div class="form-group mx-1">
                                                            <label translate="label.to" class="mr-1"></label>
                                                        </div>
                                                        <div class="time-picker-input-container" *ngIf="scopedTimeSlot.hourToTime">
                                                            <ngx-timepicker-field class="time-picker-input text-high-contrast"
                                                                                  [ngClass]="{'input-element-has-error': !isTimeValid}"
                                                                                  [format]="24"
                                                                                  [cancelBtnTmpl]="closeBtn"
                                                                                  [confirmBtnTmpl]="confirmBtn"
                                                                                  [defaultTime]="scopedTimeSlot.hourToTime"
                                                                                  (timeChanged)="onHourToChanged($event)">
                                                            </ngx-timepicker-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3 pt-3">
                                                <div class="form-group create-availability-multiple-select-container mat-select-input-element"
                                                     [ngClass]="{'input-element-has-error': scopedTimeSlot?.selectedDays?.length === 0}">
                                                    <label translate="label.selectDays"
                                                           class="create-availability-multiple-select-label"></label>
                                                    <div>
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>{{'label.choose' | translate}}</mat-label>
                                                            <mat-select multiple
                                                                        disableOptionCentering
                                                                        [(ngModel)]="scopedTimeSlot.selectedDays">
                                                                <mat-select-trigger>
                                                                    <span *ngFor="let day of scopedTimeSlot.selectedDays; last as isLast">
                                                                         {{"label.date." + day + "Med" | translate }}
                                                                        <span *ngIf="!isLast">,</span>
                                                                    </span>
                                                                </mat-select-trigger>
                                                                <mat-option *ngFor="let day of timeSlotDaysList"
                                                                            [value]="day">{{"label.date." + day | translate}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="scopedTimeSlot?.selectedDays?.length === 0"
                                                             class="help-block create-availability-multiple-select-validation">
                                                            <span [ngClass]="{'input-element-has-error': scopedTimeSlot?.selectedDays?.length === 0}"
                                                                  translate="label.error.required">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item"
                                        *ngFor="let appointmentType of scopedTimeSlot?.appointmentTypes; let i= index">
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="form-group customSelect customSelectShort appointment-type"
                                                     *ngIf="appointmentType?.id === undefined">
                                                    <label translate="label.appointmentType">
                                                        <sbase-filter
                                                                (selectedItemsOutput)=" onAppointmentTypeSelected($event,i)"
                                                                [buttonName]='"label.choose"'
                                                                [dependentFilters]="mainDependentFilters.appointmentType"
                                                                [initialFilterValues]="[]"
                                                                [isMultipleSelectedItem]="false"
                                                                [useSelectedValueAsLabel]="true"
                                                                [providerInstance]="appointmentTypeProvider"
                                                                [hideSelectedItems]="true">
                                                        </sbase-filter>
                                                    </label>
                                                </div>
                                                <div class="form-group customSelect appointment-type"
                                                     *ngIf="appointmentType?.id !== undefined">
                                                    <label translate="label.appointmentType">
                                                        <sbase-filter
                                                                (selectedItemsOutput)=" onAppointmentTypeSelected($event,i)"
                                                                [buttonName]='"label.choose"'
                                                                [dependentFilters]="mainDependentFilters.appointmentType"
                                                                [initialFilterValues]="[appointmentType]"
                                                                [isMultipleSelectedItem]="false"
                                                                [useSelectedValueAsLabel]="true"
                                                                [providerInstance]="appointmentTypeProvider"
                                                                [hideSelectedItems]="true">
                                                        </sbase-filter>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <div class="form-group"
                                                     *ngIf="appointmentType?.id">
                                                    <label translate="label.durationShort">
                                                        <input [(ngModel)]="appointmentType.duration"
                                                               *ngIf="appointmentType?.id && !appointmentType.consumesPlannedCapacity"
                                                               #appointmentTypeDuration="ngModel"
                                                               name="appointmentTypeDuration"
                                                               [pattern]="RegexWholeNumber"
                                                               type="number"
                                                               min="1"
                                                               step="1"
                                                               max=1440
                                                               autocomplete="off"
                                                               class="form-control">
                                                        <div *ngIf="appointmentTypeDuration?.errors?.pattern"
                                                             class="help-block">
                                                                <span class="inValidPosition"
                                                                      translate="label.invalidField"></span>
                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                            <div class="col-1">
                                                <div class="form-group"
                                                     *ngIf="appointmentType?.id">
                                                    <label translate="label.quantityShort">
                                                        <input [(ngModel)]='appointmentType.quantity'
                                                               *ngIf="appointmentType?.id && !appointmentType?.consumesPlannedCapacity"
                                                               type="number"
                                                               min="1"
                                                               step="1"
                                                               max=1440
                                                               class="form-control pl-0 rounded-0">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group"
                                                     *ngIf="appointmentType?.id">
                                                    <label translate="label.consumesPlannedCapacity"></label>
                                                    <div class="switch">
                                                        <label>
                                                            <span translate="label.no"></span>
                                                            <input type="checkbox" checked
                                                                   [disabled]="true"
                                                                   [(ngModel)]="appointmentType.consumesPlannedCapacity">
                                                            <span class="lever switch-col-black"></span>
                                                            <span translate="label.yes"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <div class="remove-button">
                                                    <i class="fas fa-times fa-1x cursor-pointer"
                                                       (click)="onAppointmentTypeDeleted(appointmentType, i)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <div class="d-flex float-left mb-3 mt-1 ml-1 update-buttons">
                                    <button class="btn btn-apply border-0 p-0 float-left"
                                            translate="button.addAppointmentType"
                                            (click)="addNewTimeSlotAppointmentType()"></button>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex float-right mb-3 mt-1 update-buttons" *ngIf="updateAvailabilityTimeSlots">
                            <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                                    translate="button.save"
                                    (click)="onSaveTimeSlot(scopedTimeSlot)">
                            </button>
                            <button class="btn border-0 p-0" translate="button.cancel"
                                    (click)="onCancelTimeSlot()"></button>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <label class="pt-3" translate="label.availabilityTimeSlots"></label>
                                </div>
                            </div>
                        </div>
                        <!-- TimeSlots table-->
                        <table class="table table-bordered grey-table">
                            <thead class="thead-light">
                            <tr>
                                <td translate="label.mon"></td>
                                <td translate="label.tu"></td>
                                <td translate="label.we"></td>
                                <td translate="label.th"></td>
                                <td translate="label.fr"></td>
                                <td translate="label.sa"></td>
                                <td translate="label.su"></td>
                                <td translate="label.appointmentTypes"></td>
                                <td translate="label.durationShort" ngbTooltip="{{'label.duration'| translate}}"
                                    container="body"></td>
                                <td translate="label.quantityShort" ngbTooltip="{{'label.quantity'| translate}}"
                                    container="body"></td>
                                <td translate="label.action"></td>
                            </tr>
                            </thead>
                            <!--display an empty row if no timeslot is added-->
                            <tbody *ngIf="!availabilityData?.timeSlots || availabilityData?.timeSlots?.length===0">
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                            <tbody *ngFor="let timeSlot of availabilityData?.timeSlots; let index = index">
                            <tr>
                                <td><span *ngIf="timeSlot.monday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.tuesday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.wednesday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.thursday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.friday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.saturday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td><span *ngIf="timeSlot.sunday">{{timeSlot.hourFrom | hourFromMinutes}}
                                    - {{timeSlot.hourTo | hourFromMinutes}}</span></td>
                                <td>
                                    <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                                        {{appointmentType?.name}}</div>
                                </td>
                                <td>
                                    <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                                        <span *ngIf="appointmentType.duration">{{appointmentType.duration}}</span>
                                        <span *ngIf="!appointmentType.duration">&nbsp;-</span>
                                    </div>
                                </td>
                                <td>
                                    <div *ngFor="let appointmentType of timeSlot.appointmentTypes">
                                        <span *ngIf="appointmentType.quantity">{{appointmentType.quantity}}</span>
                                        <span *ngIf="!appointmentType.quantity">&nbsp;-</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                            popoverClass="popover-shadow">
                                        <i class="fas fa-ellipsis-h"></i>
                                    </button>
                                    <ng-template #popContent>
                                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                                            <li>
                                                <button type="button" class="btn" translate="label.edit"
                                                        (click)="editAvailabilityTimeSlot(timeSlot, index)"></button>
                                            </li>
                                            <li>
                                                <button type="button" class="btn" translate="label.delete"
                                                        (click)="deleteAvailabilityTimeSlot(timeSlot, index)"></button>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel>
                        <span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <div>
                            <ngx-ui-loader [loaderId]="validateAvailabilityLoaderId"></ngx-ui-loader>
                            <div class="text-danger mb-2">{{availabilityValidation.error}}</div>
                            <div class="text-warning"
                                 *ngFor="let warning of availabilityValidation.warnings">{{warning}}</div>
                        </div>
                        <app-view-availability [availability]="availabilityData"></app-view-availability>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="goBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="goForward(stepper)"
                    *ngIf="displayNext(stepper, totalStepsCount)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveAvailabilityData(availabilityData)" type="button"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==VIEW"
                    [disabled]="availabilityValidation.error"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===VIEW && !availabilityData.onTheFly
                    && availabilityData.status === availabilityStatusEnum.Approved && !dateTimeUtils.isExpiredDate(availabilityData.validTo)"
                    [activityDisplay]="'AvailabilityUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn theme-btn" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button type="button" class="btn close-btn"
                translate="label.close">
        </button>
    </div>
</ng-template>

<ng-template let-item #tagsTemplate>
    <span [ngClass]="{'font-weight-bold black-color':item?.recommended}">{{ item?.name }}</span>
</ng-template>

