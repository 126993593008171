import {ActivityPlanTemplateServiceType, FormValidationType} from 'sked-base';
import * as moment from 'moment';

export enum ActivityPlanTemplateFrequencyTypeEnum {
    weekDays = 'weekDays',
    monthDays = 'monthDays'
}

export type ActivityPlanTemplateFrequencyValue = string[] | number[];

export interface ActivityPlanTemplateFrequency {
    frequencyType: ActivityPlanTemplateFrequencyTypeEnum;
    selectedDays: ActivityPlanTemplateFrequencyValue;
    selectedWeekDays: FrequencyWeekDayType[];
    selectedMonthDays: FrequencyMonthDayType[];
}

export interface ActivityPlanTemplateServiceValidation {
    frequencyType: FormValidationType;
    frequencyDays: FormValidationType;
    interval: FormValidationType;
    count: FormValidationType;
    enqueue: FormValidationType;
    service: FormValidationType;
    recurrenceModal: FormValidationType;
}

export interface FrequencyWeekDayType {
    name: string;
    selected: boolean;
}

export interface FrequencyMonthDayType {
    name: number;
    selected: boolean;
}

export interface ActivityPlanTemplateDisplayCalendarPageType {
    days: ActivityPlanTemplateDisplayCalendarDayType[];
    displayMonth: string;
    displayYear: string;
    startDate?: ActivityPlanTemplateDisplayCalendarDayType;
    endDateMoment?: moment.Moment;
}

export interface ActivityPlanTemplateDisplayCalendarDayType {
    displayDay: string;
    hasBanner: boolean;
    enqueueDate?: string;
    isToday: boolean;
    isAfterToday: boolean;
    isStartDate?: boolean;
    momentDate: moment.Moment;
    stringDate: string;
}
