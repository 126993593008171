<div class="container-fluid area-md-wrapper area-md-screen overview-screen-md pb-1">
  <div class="d-flex justify-content-between">
    <h3 translate="label.medicalAreas"></h3>
    <div class="filters-section d-flex">
      <div class="mr-2">
        <app-generate-export-file [exportFileEntity]="exportFileEntityEnum.Areas"></app-generate-export-file>
      </div>
      <button class="reset-button btn" (click)="onClearFilters()">
        <i class="far fa-trash-alt"></i>
        <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
      </button>
    </div>
  </div>
  <div class="sort-search-wrapper d-flex justify-content-between">
    <div class="sort text-high-contrast">
      <span translate="label.activeSorting"></span>:
      <span class="text-info">
      <span *ngFor="let key of objectKeys(areaMdUtil.tableFilters.orderBy)">
        <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + areaMdUtil.tableFilters.orderBy[key] | translate}}
        <span>
          <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
        </span>
            </span>
            </span>
    </div>

    <div class="d-flex">
      <div class="d-flex search">
        <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
        <input type="text" class="form-control rounded-0"
               placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" autocomplete="off"
               [(ngModel)]="areaMdUtil.tableFilters.filter.Name" (ngModelChange)="onSearchAreaNameChanged($event)">
      </div>
      <div class="ml-2">
        <button [activityDisplay]="MDEntityName+constants.CREATE" type="button" class="btn theme-alt-btn border-0"
                (click)="createArea()" translate="label.createNew">
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
    <table class="table">
      <thead>
      <tr>
        <th class="name-column" translate="label.shortId"></th>
        <th class="name-column cursor-pointer" (click)="onSortBy('name')">
          {{'label.name' | translate}}
          <button type="button" class="btn p-0" *ngIf="!areaMdUtil.tableFilters.orderBy.name"><i class="fas fa-sort"></i></button>
          <button type="button" class="btn p-0" *ngIf="areaMdUtil.tableFilters.orderBy.name === 'asc'"><i
            class="fas fa-sort-down"></i></button>
          <button type="button" class="btn p-0" *ngIf="areaMdUtil.tableFilters.orderBy.name === 'desc'"><i
            class="fas fa-sort-up"></i></button>
        </th>
        <th class="text-center" translate="label.position"></th>
        <th class="text-center" translate="label.multipleSlots"></th>
        <th class="text-center" translate="label.channelRestrictions"></th>
        <th class="text-center" translate="label.requiresWorkOrder" [featureDisplay]="'admin-WorkOrder'"></th>
        <th class="text-center" translate="label.action"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let area of areaMDList"
          [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
          sbaseMarkTableRowOnHover
          (click)="viewArea(area)">
        <td class="name-column">{{area.shortId}}</td>
        <td class="name-column max-width-table-column">{{area.name}}</td>
        <td class="text-center">{{area.position}}</td>
        <td class="text-center">{{area.multipleSlots ? ('label.yes' | translate) : ('label.no' | translate)}}</td>
        <td class="text-center">
          <div *ngFor="let channels of area.channelRestrictions">
            {{('label.channels.' + channels.channel) | translate}}
          </div>
        </td>
        <td class="text-center"
            [featureDisplay]="'admin-WorkOrder'">{{area.requiresWorkOrder ? ('label.yes' | translate) : ('label.no' | translate)}}</td>
        <td class="text-center" (click)="$event.stopPropagation()">
          <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ng-template #popContent>
            <ul class="list-style-none m-0 p-0 custom-drop-down">
              <li>
                <button type="button" class="btn" translate="label.view" (click)="viewArea(area)"></button>
              </li>
              <li>
                <button [activityDisplay]="MDEntityName + constants.UPDATE" type="button" class="btn"
                        translate="label.edit" (click)="editArea(area)"></button>
              </li>
              <li>
                <button [activityDisplay]="MDEntityName+constants.DELETE" type="button" class="btn"
                        translate="label.delete" (click)="displayConfirmDeleteItemModal(area.id)"></button>
              </li>
              <li>
                <button type="button" class="btn" translate="label.changeLog"
                        (click)="displayChangeLog(area.id, EntityTypeEnum.area, area.name)"
                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
              </li>
            </ul>
          </ng-template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <!-- Page Filter -->
  <!-- ============================================================== -->
  <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
    <ngb-pagination [collectionSize]="totalTableItems" [(page)]="areaMdUtil.tableFilters.currentPage" [maxSize]="5"
                    [pageSize]="areaMdUtil.tableFilters.itemsPerPage" [boundaryLinks]="true"
                    (pageChange)="onChangePagination($event)"></ngb-pagination>

    <div class="btn-group items-per-page-group">
      <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
      <span class="btn-group ml-2">
                  <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                        [ngClass]="{'show': showItemsPerPageDropdown}">
                      <strong>{{areaMdUtil.tableFilters.itemsPerPage}}</strong>
                  </span>
            <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                 (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                   (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
            </div>
            </span>
    </div>
    <!-- ============================================================== -->
    <!-- End Page Filter -->
  </div>

</div>
