import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocationSearchGroupType, ResourceSearchType} from 'sked-base';

export interface RoomReservationFilterType {
    roomOrResourceOverview: string;
    center: LocationSearchGroupType;
    resource: ResourceSearchType;
    room: ResourceSearchType,
    currentDate: NgbDateStruct;
    showFullyBookedRooms: boolean;
    viewType: string;
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
    tags: any[];
}


export interface ConflictInstances {
    masterId: string;
    date: string;
    formattedDate: string;
    formattedTimeInterval: string;
    hourFrom: number;
    hourTo: number;
    isSuccessful: boolean;
}

export interface CalendarResourceType {
    id: string;
    name: string;
    isResourceHaveReservation: boolean;
}

export interface ResourceCalendarEventType {
    center: any;
    resource: any;
    showBooking: boolean;
    date: NgbDateStruct;
}

export interface RoomsCalendarEventType {
    center: any;
    resource: any;
    showBooking: boolean;
    date: NgbDateStruct;
}

//fullCalendar types
export interface FullCalendarDropEventType {
    date: Date;
    dateStr: string;
    allDay: boolean;
    draggedEl: HTMLElement;
    jsEvent: MouseEvent;
    view: any;
}

export interface FullCalendarEventDropType {
    el: HTMLElement;
    event: any;
    oldEvent: any;
    delta: any;
    revert: () => void;
    jsEvent: Event;
    view: any;
}

export interface FullCalendarEventLeaveReceiveType {
    draggedEl: HTMLElement;
    event: any;
    view: any;
}

export interface FullCalendarEventRenderType {
    isMirror: boolean;
    isStart: boolean;
    isEnd: boolean;
    event: any;
    el: HTMLElement;
    view: any;
}

export interface FullCalendarEventEventDragOrClickType {
    event: any;
    el: HTMLElement;
    jsEvent: MouseEvent;
    view: any;
}

export interface BackOfficeChangeAssignationType {
    thisInstance: boolean;
    allInstances: boolean;
    thisAndFollowingInstances: boolean;
    thisAndFollowingInstancesTillEndDate: boolean;
}

export enum ExportTypeEnum {
    PlannedCapacityForRooms = 'PlannedCapacityForRooms',
    RoomReservations = 'RoomReservations',
    AvailabilitiesWithoutReservation = 'AvailabilitiesWithoutReservation'
}

export interface GenerateExportOptionsType {
    exportType: ExportTypeEnum;
    resourceTypeId: string;
    centerInput?: { hide: boolean };
    resourceInput?: { hide: boolean };
    tagsInput?: { hide: boolean };
    starDateInput?: { hide: boolean };
    endDateInput?: { hide: boolean };
}
