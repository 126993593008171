<div class="container-fluid team-wrapper overview-screen-md pb-1">
    <div class="team-header d-flex justify-content-between">
        <h3 class="team-title" translate="label.userManagementTab.team"></h3>
        <button class="reset-button btn" (click)="onClearFilters()">
            <i class="far fa-trash-alt"></i>
            <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
        </button>
    </div>
    <div class="sort-search-wrapper d-flex justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(teamMDUtils.tableFilters.orderBy)">
                <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + teamMDUtils.tableFilters.orderBy[key] | translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-3" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>

        <div class="d-flex">
            <div class="d-flex search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" autocomplete="off"
                       [(ngModel)]="teamMDUtils.tableFilters.filter.name" (ngModelChange)="onSearchTeamNameChanged($event)">
            </div>
            <div class="ml-2">
                <button [activityDisplay]="MDEntityName+constants.CREATE" type="button" class="btn theme-alt-btn border-0"
                        (click)='createTeam()'
                        translate="label.createNew">
                </button>
            </div>
        </div>
    </div>
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th class="name-column cursor-pointer" (click)="onSortBy('name')">
                    {{'label.name' | translate}}
                    <button type="button" class="btn p-0" *ngIf="!teamMDUtils.tableFilters.orderBy.name"><i class="fas fa-sort"></i>
                    </button>
                    <button type="button" class="btn p-0" *ngIf="teamMDUtils.tableFilters.orderBy.name === 'asc'"><i
                            class="fas fa-sort-down"></i></button>
                    <button type="button" class="btn p-0" *ngIf="teamMDUtils.tableFilters.orderBy.name === 'desc'"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th translate="label.activities"></th>
                <th translate="label.employee"></th>
                <th translate="label.organizationalRole"></th>
                <th translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let team of teamList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewTeam(team)">
                <td>{{team.name}}</td>
                <td>
                    <div *ngFor="let activity of team.activities| slice:0:3">
                        {{'label.activityName.' + activity|translate}}
                    </div>

                    <ng-template #subServicesContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let activity of team.activities">{{'label.activityName.' + activity|translate}}</div>
                        </div>
                    </ng-template>
                    <div *ngIf="team.activities && team.activities.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="subServicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngFor="let user of team.users| slice:0:3">
                        {{user.firstName}}
                        <span *ngIf="user.firstName"> </span>
                        {{user.lastName}}
                        <span *ngIf="user.firstName || user.lastName"> </span>
                        ({{user.username}})
                    </div>

                    <ng-template #employeeContent>
                        <div class="table-tooltip-container px-2 py-1 employee-content">
                            <div *ngFor="let user of team.users">
                                {{user.firstName}}
                                <span *ngIf="user.firstName"> </span>
                                {{user.lastName}}
                                <span *ngIf="user.firstName || user.lastName"> </span>
                                ({{user.username}})
                            </div>
                        </div>
                    </ng-template>

                    <div *ngIf="team.users && team.users.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="employeeContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer"></div>
                    </div>
                </td>
                <td>{{!team.organizationalRole ? '' : 'enum.organizationalRoleType.' + team.organizationalRole | translate}}</td>
                <td (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewTeam(team)"></button>
                            </li>
                            <li>
                                <button [activityDisplay]="MDEntityName + constants.UPDATE" type="button" class="btn"
                                        translate="label.edit" (click)="editTeam(team)"></button>
                            </li>
                            <li>
                                <button [activityDisplay]="MDEntityName+constants.DELETE" type="button" class="btn"
                                        translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(team.id)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(team.id, EntityTypeEnum.team, team.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="teamMDUtils.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="teamMDUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                  <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                        [ngClass]="{'show': showItemsPerPageDropdown}">
                      <strong>{{teamMDUtils.tableFilters.itemsPerPage}}</strong>
                  </span>
            <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                 (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                   (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>
</div>
