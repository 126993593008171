import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {ResourceTypeMDComponent} from './resource-type-md.component';
import {CreateResourceTypeComponent} from './create-resource-type/create-resource-type.component';

@NgModule({
    declarations: [ResourceTypeMDComponent, CreateResourceTypeComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class ResourceTypeMDModule {
}
