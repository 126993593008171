import {constants} from '../../shared/constants/constants';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TagMdUtils} from './tag-md-utils';
import {ConfirmDeleteModalService, EntityTypeEnum, TagProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {Subject} from 'rxjs';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-tag-md',
    templateUrl: './tag-md.component.html',
    styleUrls: ['./tag-md.component.scss']
})
export class TagMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[];
    tagMdList: any = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    VIEW_ACTIVITY_ENABLED = false;
    public MDEntityName: string;
    public constants = constants;
    EntityTypeEnum = EntityTypeEnum;

    private searchTagChanged = new Subject<string>();

    constructor(
        public tagMdUtils: TagMdUtils,
        public tagProvider: TagProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('TagRead');
        this.MDEntityName = this.tagProvider.name;
        this.itemsPerPageList = this.generalUtils.getItemsPerPageList();

        if (this.previousRouteService.getPreviousUrl() !== '/createTag') {
            this.loadInitialFilter({tableFilters: true});
        }

        this.getTagMdData();
        this.subscribeSearchByTagChanged();
    }

    ngOnDestroy(): void {
    }

    createTag(): void {
        this.router.navigate(['/createTag']);
    }

    editTag(tag: TagType) {
        this.router.navigate(['/createTag'], {state: {tag, action: constants.EDIT}});
    }

    viewTag(tag: TagType) {
        this.router.navigate(['/createTag'], {state: {tag, action: constants.VIEW}});
    }

    displayScopedTagLabel(tag: TagType): string[] {
        const LABEL = 'label.';
        const LABELS = [];
        if (tag.scopedPatient) {
            LABELS.push(LABEL + 'patients');
        }
        if (tag.scopedResource) {
            LABELS.push(LABEL + 'resources');
        }
        if (tag.scopedCoveragePlan) {
            LABELS.push(LABEL + 'coveragePlan');
        }
        if (tag.scopedSpeciality) {
            LABELS.push(LABEL + 'speciality');
        }
        if (tag.scopedService) {
            LABELS.push(LABEL + 'service');
        }
        if (tag.scopedCenter) {
            LABELS.push(LABEL + 'center');
        }
        return LABELS;
    }

    private getTagMdData(includeCount: boolean = true) {
        const queryFilter = this.tagMdUtils.getQueryFilterForTagMD(this.tagMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.tagProvider.getEntries(queryFilter).pipe(take(1)).subscribe((response: any) => {
            this.tagMdList = response.value;
            if (response.count !== undefined && response.count !== null) {
                this.totalTableItems = response.count;
            }
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        }, () => {
            this.ngxLoader.stop();
        });
    }

    private deleteItem(id: string) {
        this.tagMdUtils.tableFilters = this.tagMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.tagProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.getTagMdData();
                this.messagesService.success('toastr.success.tagDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.tagMdUtils.tableFilters.currentPage = page;
        this.getTagMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage) {
        this.tagMdUtils.tableFilters.currentPage = 1;
        this.tagMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.getTagMdData(false);
    }

    onSortBy(property: string) {
        const isAscendingMode = this.tagMdUtils.tableFilters.orderBy[property];
        if (this.tagMdUtils.tableFilters.orderBy) {
            this.tagMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.getTagMdData(false);
    }

    onSearchTagChanged(value: string) {
        this.searchTagChanged.next(value);
    }

    onClearFilters() {
        // Load filters and data
        setTimeout(() => {
            this.onSearchTagChanged('');
            this.loadInitialFilter({tableFilters: true});
            this.getTagMdData();
        });
    }

    private loadInitialFilter({tableFilters}: { tableFilters?: boolean }) {
        if (!!tableFilters) {
            this.tagMdUtils.tableFilters = this.tagMdUtils.getInitialTableFilter();
        }
    }

    private subscribeSearchByTagChanged() {
        const self = this;
        this.searchTagChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.tagMdUtils.tableFilters.filter.name = searchValue;
                self.getTagMdData();
            });
    }

    onClearOrderBy(selectedItem) {
        delete this.tagMdUtils.tableFilters.orderBy[selectedItem];
        this.getTagMdData(false);
    }

    displayConfirmDeleteItemModal(tagId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.tagProvider, tagId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(tagId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }
}
