<div class="phone-number-input-container">
    <label class="phone-number-label" [for]="options?.phoneNumberInputName">{{options?.labelText | translate}}</label>
    <div class="phone-number-input">
        <div class="country-code-container">
            <span class="plus-sign input-element"
                  [ngClass]="{'country-code-focused' : isCountryCodeFocused,
                  'input-element-has-error': !phoneNumberValidation.isValid}">+</span>
            <input [(ngModel)]="phoneNumber.countryCode"
                   (ngModelChange)="onPhoneNumberChanged(phoneNumber)"
                   [ngClass]="{'input-element-has-error': !phoneNumberValidation.isValid}"
                   (focus)="onCountryCodeInputFocused(true)"
                   (blur)="onCountryCodeInputFocused(false)"
                   autocomplete="off"
                   class="input-element country-code"
                   [id]="options?.countryCodeInputName"
                   [name]="options?.countryCodeInputName"
                   placeholder="{{'label.phoneNumberCountryCodePlaceholder' | translate}}"
                   type="text">
        </div>
        <input [(ngModel)]="phoneNumber.phoneNumber"
               (ngModelChange)="onPhoneNumberChanged(phoneNumber)"
               [ngClass]="{'input-element-has-error': !phoneNumberValidation.isValid}"
               autocomplete="off"
               class="input-element phone-number"
               [id]="options?.phoneNumberInputName"
               [name]="options?.phoneNumberInputName"
               placeholder="{{'label.phoneNumberPlaceholder' | translate}}"
               type="tel">
        <span *ngIf="!phoneNumberValidation?.isValid" class="help-block">
            {{phoneNumberValidation?.errorMessage | translate}}
        </span>
    </div>
</div>
