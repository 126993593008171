<ng-container [ngSwitch]="rulesUtils.selectedRule">
    <ng-template [ngSwitchCase]="ruleTypeEnum.FormAssignmentDynamicWorkListRule">
        <app-create-form-assignment-dynamic-work-list-rule></app-create-form-assignment-dynamic-work-list-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.MaxAppointmentRule">
        <app-create-max-appointment-rule></app-create-max-appointment-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.WorkOrderRule">
        <app-create-work-order-rule></app-create-work-order-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AvailabilityChannelRule">
        <app-create-availability-channel-rule></app-create-availability-channel-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.SubServiceObjectDetailRule">
        <app-create-sub-service-object-detail-rule></app-create-sub-service-object-detail-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PaymentManagementRule">
        <app-create-payment-management-rule></app-create-payment-management-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.LeadTimeRule">
        <app-create-lead-time-rule></app-create-lead-time-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.ObjectDetailRule">
        <app-create-object-detail-rule></app-create-object-detail-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PatientControlRule">
        <app-create-patient-control-rule></app-create-patient-control-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.SlotFilterRule">
        <app-create-slot-filter-rule></app-create-slot-filter-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AppointmentPriceRule">
        <app-create-appointment-price-rule></app-create-appointment-price-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.MinimumAppointmentsRule">
        <app-create-minimum-appointments-rule></app-create-minimum-appointments-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.RevenueOptimizationRule">
        <app-create-revenue-optimization-rule></app-create-revenue-optimization-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.FormAssignmentAmbulatoryRule">
        <app-create-form-assignment-ambulatory-rule></app-create-form-assignment-ambulatory-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.AppointmentEventRule">
        <app-create-appointment-event-rule></app-create-appointment-event-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.ExclusionEventRule">
        <app-create-exclusion-event-rule></app-create-exclusion-event-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.CrossAvailabilityRule">
        <app-create-cross-availability-rule></app-create-cross-availability-rule>
    </ng-template>
    <ng-template [ngSwitchCase]="ruleTypeEnum.PatientCalculatedTagRule">
        <app-create-patient-calculated-tag-rule></app-create-patient-calculated-tag-rule>
    </ng-template>
</ng-container>
