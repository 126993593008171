import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AreaMDComponent } from './area-md.component';
import { CreateAreaComponent } from './create-area/create-area.component';
import {ngfModule} from 'angular-file';

@NgModule({
  declarations: [AreaMDComponent, CreateAreaComponent],
    imports: [
        CommonModule,
        SharedModule,
        ngfModule
    ]
})
export class AreaMDModule { }
