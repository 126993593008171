import {Injectable} from '@angular/core';
import {
    AbstractControlOptions,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {DynamicFormGroupedInputType, DynamicFormInputType, DynamicFormOptionsType} from '../../component/dynamic-form-input/dynamic-form-input.types';
import {TenantCustomizingService} from '../tenant-customizing.service';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DynamicFormInputService {

    constructor(
        private formBuilder: FormBuilder,
        private tenantCustomizingService: TenantCustomizingService,
    ) {
    }

    hasError(parentFormGroup: FormGroup | FormArray, formControlName: string): boolean {
        if (!formControlName || !parentFormGroup?.controls || !parentFormGroup?.controls[formControlName]?.errors) {
            return false;
        }
        return parentFormGroup.controls[formControlName].touched && Object.keys(parentFormGroup.controls[formControlName].errors)?.length > 0;
    }

    getErrorMessage(parentFormGroup: FormGroup, formControlName: string): { message: string, shouldTranslateMessage?: boolean, params?: any } {
        const errors = parentFormGroup.controls[formControlName].errors;
        if (!errors) {
            return {message: ''};
        }
        const errorList = Object.keys(errors).map((errorKey: string) => errors[errorKey]);
        const errorListOrdered = lodash.orderBy(errorList, ['priority'], ['asc']);
        return errorListOrdered?.length > 0
            ? ({message: errorListOrdered[0]?.message, shouldTranslateMessage: !!errorListOrdered[0]?.shouldTranslateMessage, params: errorListOrdered[0]?.params} ?? {message: ''})
            : {message: ''};
    }

    getFormControls(formOptions: DynamicFormOptionsType): {[key in string]?: any} {
        const controls: {[key in string]?: any} = {};
        formOptions?.groups.forEach((group: DynamicFormGroupedInputType) => {
            group.inputs.forEach((input: DynamicFormInputType) => {
                const tenantCustomizingValidator = this.tenantCustomizingService.getTenantCustomizingValidator(input.formControlName);
                const configObject = {
                    value: '',
                    disabled: input.enabled === false,
                };
                const validators = [
                    ...(input?.customValidators?.length > 0 ? input.customValidators : []),
                    ...(input?.required ? [this.tenantCustomizingService.getIsRequiredValidator()] : []),
                    tenantCustomizingValidator,
                ];
                controls[input.formControlName] = [configObject, validators];
            });
        });
        return controls;
    }

    getPhoneFormGroupOptions(formControlName: string): AbstractControlOptions {
        return {
            validators: [
                this.tenantCustomizingService.getLibPhoneValidator(formControlName),
            ]
        };
    }
}
