<div class="container-fluid export-appointments-wrapper p-0 pb-1">
    <div class="export-appointments-filters-container ml-0 mr-0 mb-1">
        <div class="col ml-1 text-left">
            <h5 class="text-left title">{{'label.filters' | translate | uppercase}}</h5>
        </div>
        <div class="row mb-0 mx-1 pl-2">
            <div class="col-10 custom-col-10 form-group pl-2 pr-0 list-filters-container"
                 id="export-appointments-filters-container">
                <sbase-filter-wrapper
                        class="d-flex my-1 list-filter list-filter-sbase-filter-wrapper"
                        [options]="exportAppointmentUtils.exportAppointmentsFilterOptions.filterWrapperOptions"
                        [updateFiltersValue]="updateFilterWrapperValues"
                        (onFiltersValueChanged)="onFilterWrapperValueChanged($event)">
                </sbase-filter-wrapper>
            </div>
            <div class="col-1 custom-col-2 action-button no-block my-2 flex-column">
                <div class="row">
                    <button class="action-button reset-button btn" (click)="onClear()">
                        <i class="far fa-trash-alt custom-text-color"></i>
                        <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.resetFilters'></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-none" id="export-appointments-filters-to-move">
            <!-- These elements are moved next to the filters above from filter wrapper on ngAfterViewInit -->
            <div class="form-group mb-0 export-appointments-select-filter list-filter list-filter-select">
                <ng-select class="form-control rounded-1 custom-ng-select"
                           [ngClass]="!!exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues.status ? 'ng-select-has-value' : null"
                           dropdownPosition="bottom"
                           [multiple]="false"
                           [searchable]="false"
                           [clearable]="false"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="{{'label.status' | translate}}"
                           [(ngModel)]="exportAppointmentUtils.exportAppointmentsFilterOptions.exportAppointmentsFiltersValues.status"
                           (ngModelChange)="sanitizeNgSelectValue('statusSelectNgModel')">
                    <ng-option [value]="'undefined'">
                        <span translate="label.status"></span>
                    </ng-option>
                    <ng-option *ngFor="let item of appointmentStatusEnumList"
                               [value]="item">
                        <span [translate]="'enum.appointmentStatus.' + item"></span>
                    </ng-option>
                </ng-select>
            </div>
            <div class="form-group mb-0 list-filter list-filter-date-range">
                <sbase-date-range class="input-element"
                                  [ngClass]="{'input-element-has-error': !exportAppointmentUtils?.exportAppointmentsFilterOptions?.filterValidations?.dateRange?.isValid}"
                                  [options]="exportAppointmentUtils.exportAppointmentsFilterOptions.dateRangeOptions"
                                  [updateDateRangeValue]="updateDateRangeValue"
                                  (onDateRangeChanged)="onDateRangeChanged($event)">
                </sbase-date-range>
            </div>
        </div>
    </div>
    <div class="export-appointments-buttons-container ml-0 mr-0 pt-2">
        <div class="d-flex flex-column export-appointments-buttons-text text-high-contrast">
            <span><i class="fas fa-info-circle mr-2"></i>{{'label.dateIntervalCannotBeLongerThanOneMonth' | translate}}</span>
            <span><i class="fas fa-info-circle mr-2"></i>{{'label.toGeneratePDFFileSelectACenterOrResource' | translate}}</span>
        </div>
        <!-- Export buttons -->
        <span class="mx-1 pl-2">
            <button class="btn theme-btn pl-2"
                    (click)="onGenerateExportAppointments()"
                    [disabled]="!exportAppointmentUtils.exportAppointmentsFilterOptions.areFiltersValid">
                {{'label.export' | translate}}<i class="fas fa-file-export ml-2"></i>
            </button>
        </span>
        <span ngbPopover="{{'label.pleaseSelectACenterOrResource'| translate}}"
              [disablePopover]="isPrintPdfButtonEnabled"
              placement="bottom"
              popoverClass="popover-shadow"
              triggers="mouseenter:mouseleave">
            <button class="btn theme-btn"
                    [disabled]="!isPrintPdfButtonEnabled"
                    (click)="onGetAppointmentsPdf()">
                        {{'label.printPDF' | translate}}<i class="fa-solid fa-file-pdf ml-2"></i>
            </button>
        </span>
    </div>
</div>
