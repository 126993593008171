import {PatientPhoneNumberType, PatientType} from 'sked-base/lib/data-model/patientTypes';
import {
    AppointmentPriceFromExternalServiceResponseType, AppointmentProvider,
    Validations
} from 'sked-base';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { formControlNames } from 'src/app/shared/constants/formControlNames';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {
    MabAppointmentFormDataValidationType,
    MabAppointmentInformationActionType,
    MabAppointmentInformationFormData,
    MabAppointmentOptionsType,
    MabAppointmentsInformationOptionsType,
    MabBookingActionEnum
} from '../multi-appointment-booking.types';
import * as lodash from 'lodash';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {MessagesService} from '../../../shared/services/messages.service';

@Component({
    selector: 'app-mab-appointments-information',
    templateUrl: './mab-appointments-information.component.html',
    styleUrls: ['./mab-appointments-information.component.scss']
})
export class MabAppointmentsInformationComponent implements OnInit {
    @Input() options: MabAppointmentsInformationOptionsType;
    @Output() action: EventEmitter<MabAppointmentInformationActionType> = new EventEmitter<MabAppointmentInformationActionType>();

    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    mainPhoneNumber = {} as PatientPhoneNumberType;
    alternatePhoneNumber = {} as PatientPhoneNumberType;
    patient: PatientType;
    appointmentInformationFormData: MabAppointmentInformationFormData = {} as MabAppointmentInformationFormData;
    appointmentInformationFormDataValidation: MabAppointmentFormDataValidationType;
    formControlNames = formControlNames;

    constructor(
        public tenantCustomizingService: TenantCustomizingService,
        public configDataService: ConfigDataService,
        public multiAppointmentBookingUtils: MultiAppointmentBookingMdUtils,
        private validations: Validations,
        private appointmentProvider: AppointmentProvider,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
    ) {
    }

    ngOnInit(): void {
        this.loadDetailsFromOptions();
        this.appointmentInformationFormData = this.multiAppointmentBookingUtils.getMabAppointmentInformationFormData(
            this.patient, this.multiAppointmentBookingUtils.bookAppointmentListOptions);
        this.parsePhoneNumbers();
        if (!this.mainPhoneNumber?.countryCode) {
            this.mainPhoneNumber.countryCode = String(this.options.defaultPhoneNumberCountryCode);
        }
        if (!this.alternatePhoneNumber?.countryCode) {
            this.alternatePhoneNumber.countryCode = String(this.options.defaultPhoneNumberCountryCode);
        }
        this.appointmentInformationFormDataValidation =
            this.multiAppointmentBookingUtils.getMabAppointmentInformationFormDataValidation(
                this.appointmentInformationFormData, this.options.tenantCustomizingData,
                this.mainPhoneNumber, this.alternatePhoneNumber
            );
        this.loadAllPriceInformation();
    }

    onEmailChange(email: string) {
        this.appointmentInformationFormDataValidation.email =
            this.tenantCustomizingService['getValidationResult'](
                email, 'Email', this.options.tenantCustomizingData
            );
    }

    onMainPhoneNumberChange(mainPhoneNumber: PatientPhoneNumberType) {
        const {phoneNumber, countryCode} = mainPhoneNumber;
        this.appointmentInformationFormData.mainPhoneNumber = phoneNumber !== '' ? `+${countryCode}${phoneNumber}` : null;
        this.appointmentInformationFormDataValidation.mainPhoneNumber =
            this.multiAppointmentBookingUtils.getPhoneNumberFormDataValidation(
                mainPhoneNumber, this.options.tenantCustomizingData, 'MainPhoneNumber'
            );
    }

    onAlternatePhoneNumberChange(alternatePhoneNumber: PatientPhoneNumberType) {
        const {phoneNumber, countryCode} = alternatePhoneNumber;
        this.appointmentInformationFormData.alternatePhoneNumber = phoneNumber !== '' ? `+${countryCode}${phoneNumber}` : null;
        this.appointmentInformationFormDataValidation.alternatePhoneNumber =
            this.multiAppointmentBookingUtils.getPhoneNumberFormDataValidation(
                alternatePhoneNumber, this.options.tenantCustomizingData, 'AlternatePhoneNumber'
            );
    }

    isAppointmentInformationFormDataValid(): boolean {
        return this.checkIsAppointmentInformationFormDataValid();
    }


    onCancelButtonClick(): void {
        this.action.emit({actionName: MabBookingActionEnum.Cancel, formData: this.appointmentInformationFormData});
    }

    onBookButtonClick(): void {
        this.action.emit({actionName: MabBookingActionEnum.Book, formData: this.appointmentInformationFormData});
    }

    onDoneButtonClick(): void {
        this.action.emit({actionName: MabBookingActionEnum.Done} as MabAppointmentInformationActionType);
    }

    onPrintButtonClick(appointmentIndex?: number): void {
        const otherData = {
            ...(appointmentIndex || appointmentIndex === 0 ? {appointmentIndex} : {printAll: true}),
        };
        this.action.emit({actionName: MabBookingActionEnum.Print, otherData} as MabAppointmentInformationActionType);
    }

    appointmentCollapse(index: number, value?: boolean) {
        const slotsOptions: MabAppointmentOptionsType = lodash.find(this.multiAppointmentBookingUtils?.bookAppointmentListOptions, {index});
        slotsOptions.isCollapsed = value === undefined ? !slotsOptions.isCollapsed : value;
    }

    private parsePhoneNumbers() {
        if (this?.patient?.mainPhoneNumber) {
            this.parsePhoneNumber(this.patient.mainPhoneNumber, 'main');
        }
        if (this?.patient?.alternatePhoneNumber) {
            this.parsePhoneNumber(this.patient.alternatePhoneNumber, 'alternate');
        }
    }

    private loadDetailsFromOptions() {
        const appointmentPriceFeatureAccess = this.configDataService.isFeatureActive('backoffice-appointment-price');

        this.patient = this.options?.patient;
        this.multiAppointmentBookingUtils.bookAppointmentListOptions = this.options?.slotsOptions?.map((
            slotOptions: MabAppointmentOptionsType, index: number
        ) => ({
            slot: slotOptions.slot,
            index,
            appointment: slotOptions.appointment,
            servicePriceSpinnerId: `servicePriceSpinnerId-${index}`,
            actualPriceSpinnerId: `actualPriceSpinnerId-${index}`,
            priceInformation: undefined,
            shouldShowPaymentSections: slotOptions?.slot?.showPrice && !!appointmentPriceFeatureAccess,
            additionalData: slotOptions.additionalData,
            objectDetailsOptions: this.multiAppointmentBookingUtils.getObjectDetailsModalOptionsObject(slotOptions.slot),
            isCollapsed: false,
        } as MabAppointmentOptionsType));
    }

    private loadAllPriceInformation() {
        this.appointmentInformationFormDataValidation.price.isValid = true;
        this.multiAppointmentBookingUtils.bookAppointmentListOptions?.forEach((appointmentOptions: MabAppointmentOptionsType) => {
            if (appointmentOptions?.shouldShowPaymentSections) {
                this.loadPriceInformation(appointmentOptions);
            }
        });
    }

    private loadPriceInformation(appointmentOptions: MabAppointmentOptionsType) {
        const filter = this.multiAppointmentBookingUtils.getPriceFromExternalServiceFilter(appointmentOptions.slot, this.patient);
        this.ngxLoader.startLoader(appointmentOptions.servicePriceSpinnerId);
        this.ngxLoader.startLoader(appointmentOptions.actualPriceSpinnerId);
        appointmentOptions.priceIsValid = true;
        this.appointmentProvider.getPriceFromExternalServiceV3(filter)
            .subscribe((response: AppointmentPriceFromExternalServiceResponseType) => {
                this.onPriceResponse(response, appointmentOptions);
            }, (error) => {
                this.onPriceError(error, appointmentOptions);
            });
    }

    private onPriceResponse(response: AppointmentPriceFromExternalServiceResponseType, appointmentOptions: MabAppointmentOptionsType) {
        this.multiAppointmentBookingUtils.bookAppointmentListOptions[appointmentOptions.index].priceInformation = lodash.cloneDeep(response);
        this.ngxLoader.stopLoader(appointmentOptions.servicePriceSpinnerId);
        this.ngxLoader.stopLoader(appointmentOptions.actualPriceSpinnerId);
    }

    private onPriceError(error, appointmentOptions: MabAppointmentOptionsType) {
        if (error.status === 400) {
            this.appointmentInformationFormDataValidation.price.message = error.error?.message;
            appointmentOptions.priceErrorMessage = error.error?.message;
            // Disable booking button if paymentActive is required
            if (appointmentOptions.slot.paymentActive !== 'NoPayment' && appointmentOptions.slot.paymentActive !== 'PaymentOptional') {
                this.appointmentInformationFormDataValidation.price.isValid = false;
                appointmentOptions.priceIsValid = false;
            }
        } else {
            this.messagesService.handlingErrorMessage(error);
        }
        appointmentOptions.priceInformation = {} as any;
        this.ngxLoader.stopLoader(appointmentOptions.servicePriceSpinnerId);
        this.ngxLoader.stopLoader(appointmentOptions.actualPriceSpinnerId);
    }

    private checkIsAppointmentInformationFormDataValid(): boolean {
        for (const key in this.appointmentInformationFormDataValidation) {
            if (key && !this.appointmentInformationFormDataValidation[key].isValid) {
                return false;
            }
        }
        return true;
    }

    private parsePhoneNumber(phoneNumber: string, attributeName: string): void {
        if (phoneNumber.includes('+')) {
            // assume phone number that contains '+' has country code (is of the form +56987654321)
            try {
                const libFullPhoneNumber = this.libPhoneUtil.parseAndKeepRawInput(phoneNumber);
                this[`${attributeName}PhoneNumber`].phoneNumber = `${libFullPhoneNumber.getNationalNumber()}`;
                this[`${attributeName}PhoneNumber`].countryCode = `${libFullPhoneNumber.getCountryCode()}`;
            } catch { // in case parseAndKeepRawInput throws error => leave current phone number as it is
            }
        }
    }

}
