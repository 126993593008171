import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoomReservationComponent} from './room-reservation.component';
import {SharedModule} from '../../shared/shared.module';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin
import listPlugin from '@fullcalendar/list'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline'; // a plugin

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {CreateReservationModalComponent} from './create-reservation-modal.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    resourceTimeLinePlugin
]);

@NgModule({
    declarations: [RoomReservationComponent, CreateReservationModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        FormsModule,
        NgbModule,
        FullCalendarModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule
    ],
    providers: [NgbActiveModal],
    exports: [CreateReservationModalComponent],
    bootstrap: [CreateReservationModalComponent]
})
export class RoomReservationModule {
}
