import {Component, Input, OnInit} from '@angular/core';
import {SlotResourceType, SlotSecondaryResourceType} from 'sked-base/lib/data-model/slotTypes';
import {
    SlotResourceAdditionalInfoModalComponent
} from './slot-resource-additional-info-modal/slot-resource-additional-info-modal.component';
import {GeneralUtils} from '../../utils/general.utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../services/messages.service';
import {ResourceProvider, ResourceType} from 'sked-base';
import {ODataFilterQueryType} from '../../../data-model/general.type';
import {forkJoin} from 'rxjs';
import {SlotResourceAdditionalInfoOptionsType} from './slot-resource-additional-info.types';

@Component({
    selector: 'app-slot-resource-additional-info',
    templateUrl: './slot-resource-additional-info.component.html',
    styleUrls: ['./slot-resource-additional-info.component.scss']
})
export class SlotResourceAdditionalInfoComponent implements OnInit {
    @Input() options = {} as SlotResourceAdditionalInfoOptionsType;
    resources: (SlotResourceType | SlotSecondaryResourceType)[] = [];

    constructor(public generalUtils: GeneralUtils,
                private modalService: NgbModal,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private resourceProvider: ResourceProvider) {
    }

    ngOnInit(): void {
        this.mapResources();
    }

    openModalOrNewTab(resource: SlotResourceType): void {
        if (this.generalUtils.isValueTrue(resource.hasDescription)) {
            this.getResourcesData();
        } else if (resource.externalUrlForDescription) {
            window.open(resource.externalUrlForDescription, '_blank');
        }
    }

    openModalWithMultipleSecondaryResources(): void {
        const hasAnyResourceDataToDisplay = this.resources?.some(({hasDescription, additionalInformation, externalUrlForDescription}) => {
            return hasDescription === 'True' || !!additionalInformation || !!externalUrlForDescription;
        });
        if (hasAnyResourceDataToDisplay) {
            this.getResourcesData();
        }
    }

    private mapResources(): void {
        const {mainResource, secondaryResources} = this.options;
        if (mainResource) {
            this.resources.push(mainResource);
        }
        if (secondaryResources?.length > 0) {
            secondaryResources.forEach(secondaryResource => {
                this.resources.push(secondaryResource);
            });
        }
    }

    private getResourcesData(): void {
        const queryFilter = {
            select: ['Name', 'Description', 'ExternalUrlForDescription', 'AdditionalInformation', 'IsDirectlyBookable']
        } as ODataFilterQueryType;
        const getResourcesByIdObservables = this.resources.map(({id}) => {
            return this.resourceProvider.getById(id, queryFilter);
        });
        this.ngxLoader.start();
        forkJoin(getResourcesByIdObservables).subscribe((resources) => {
            this.openSlotResourceModal(resources);
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

    private openSlotResourceModal(resources: ResourceType[]): void {
        // Open modal
        const modalOptions = {...this.generalUtils.getModalOptions(), size: 'xl'};
        const modalRef = this.modalService.open(SlotResourceAdditionalInfoModalComponent, modalOptions);
        modalRef.componentInstance.resources = resources;
        modalRef.componentInstance.resourceTabToPreselect = this.options.resourceTabToPreselect;
        // Wait for response
        modalRef.result.then(() => {
            // On close, do nothing
        }, () => {
            // On dismiss, do nothing
        });
    }
}
