import {Component, Input, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExportFileEntityEnum, JobProvider} from 'sked-base';
import {MessagesService} from '../../services/messages.service';

@Component({
    selector: 'app-generate-export-file',
    templateUrl: './generate-export-file.component.html',
    styleUrls: ['./generate-export-file.component.scss']
})
export class GenerateExportFileComponent implements OnInit {
    @Input() exportFileEntity: ExportFileEntityEnum;

    constructor(private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private jobProvider: JobProvider) {
    }

    ngOnInit(): void {
    }

    generateExport() {
        this.ngxLoader.start();
        this.jobProvider.generateExportFile(this.exportFileEntity).subscribe(() => {
            this.messagesService.success('label.generateExportWaitMessageGoToExports');
            this.ngxLoader.stop();
        }, err => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(err);
        });
    }

}
