<div class="container-fluid create-slot-filter-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-slot-filter-rule-header">
        <h3 class="create-slot-filter-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.slotFilterRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect"
                                     [ngClass]="{'has-error': !ruleItem?.slotFilterRuleTable?.id}">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.slotFilterRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.slotFilterRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'has-error': !ruleItem?.slotFilterRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule only warning-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.onlyWarning"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.onlyWarning"
                                                   name="onlyWarning">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row ml-1 mb-2" *ngIf="!(ruleItem.gender || ruleItem.age || ruleItem.coveragePlanId || ruleItem.tagId || ruleItem.patientTagId)">
                            <i class="fa fa-exclamation-triangle has-error"></i>
                            <span class="has-error ml-2"
                                  translate="label.slotFilterRuleMinimumConditionForInput"></span>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                            <label translate="label.gender"></label>
                                            <ng-select
                                                       class="custom-ng-select input-element"
                                                       loadingText="{{'label.loading' | translate}}"
                                                       placeholder="{{'label.choose' | translate}}"
                                                       dropdownPosition="bottom"
                                                       [(ngModel)]="ruleItem.gender"
                                                       [multiple]="false"
                                                       [searchable]="false"
                                                       [clearable]="false"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW"
                                                       (ngModelChange)="onSelectedGender($event)">
                                                <ng-option [value]="undefined">
                                                    <span translate="label.choose"></span>
                                                </ng-option>
                                                <ng-option *ngFor="let gender of genderEnumList" [value]="gender">
                                                    <span>{{'enum.gender.' + gender | translate}}</span>
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.gender">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle" ngbTooltip="{{'label.exclude'| translate}}" placement="bottom"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="slotFilterRuleTags.includeGenderFlag"
                                                           (change)="onChangeIncludeFlag('gender', $event.target.checked)"
                                                           name="includeGender">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle" ngbTooltip="{{'label.include'| translate}}" placement="bottom"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="form-group customSelect"
                                             [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                            <label translate="label.age"></label>
                                            <input type="text" class="input-element"
                                                   autocomplete="off"
                                                   [(ngModel)]="ruleItem.age"
                                                   [ngClass]="{'input-element-has-error': !ruleItem.age && !!ruleItem.operationAge
                                                              || (!!ruleItem.age && ruleItem.operationAge !== SlotFilterRuleOperationAgeEnum.in && isWholeNumber(+ruleItem.age) && !isAgeValid(+ruleItem.age))
                                                              || (!!(ruleItem.age && ruleItem.operationAge !== SlotFilterRuleOperationAgeEnum.in && !isWholeNumber(+ruleItem.age))
                                                              || !!(ruleItem.age && ruleItem.operationAge === SlotFilterRuleOperationAgeEnum.in && !isAgeRangeFormatValid(ruleItem.age)))}"
                                                              placeholder="{{'label.age' | translate}}">
                                            <div *ngIf="!ruleItem.age && !!ruleItem.operationAge" class="help-block">
                                                <span translate="label.error.required"></span>
                                            </div>
                                            <div *ngIf="!!ruleItem.age && ruleItem.operationAge !== SlotFilterRuleOperationAgeEnum.in && isWholeNumber(+ruleItem.age) && !isAgeValid(+ruleItem.age)"
                                                 class="help-block">
                                                <span>{{"label.error.invalidNumberForInterval"|translate:'{min:0,max:999}'}}</span>
                                            </div>
                                            <div *ngIf="!!(ruleItem.age && ruleItem.operationAge !== SlotFilterRuleOperationAgeEnum.in && !isWholeNumber(+ruleItem.age))
                                                 || !!(ruleItem.age && ruleItem.operationAge === SlotFilterRuleOperationAgeEnum.in && !isAgeRangeFormatValid(ruleItem.age))"
                                                 class="help-block">
                                                <span translate="label.invalidField"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="h-100 d-flex no-block align-items-center" *ngIf="ruleItem?.operationAge === SlotFilterRuleOperationAgeEnum.in">
                                            <i class="fa-solid fa-circle-info h6"
                                               ngbTooltip="{{'tooltip.dateFormat' | translate}}(val1..val2][val1;val2)[val1,val2]"
                                               placement="bottom">
                                            </i>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group customSelect">
                                            <label translate="label.range"></label>
                                            <ng-select
                                                       class="custom-ng-select input-element"
                                                       [ngClass]="{'input-element-has-error': ruleItem.age && !ruleItem.operationAge}"
                                                       loadingText="{{'label.loading' | translate}}"
                                                       placeholder="{{'label.choose' | translate}}"
                                                       dropdownPosition="bottom"
                                                       [(ngModel)]="ruleItem.operationAge"
                                                       (ngModelChange)="sanitizeNgSelectValue($event, 'operationAge')"
                                                       [multiple]="false"
                                                       [searchable]="false"
                                                       [clearable]="false"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW">
                                                <ng-option [value]="undefined">
                                                    <span translate="label.choose"></span>
                                                </ng-option>
                                                <ng-option *ngFor="let operation of ageOperationEnumList"
                                                           [value]="operation">
                                                    <span>{{operation}}</span>
                                                </ng-option>
                                            </ng-select>
                                            <div *ngIf="ruleItem.age && !ruleItem.operationAge" class="help-block">
                                                <span translate="label.error.required"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label class="mb-1" translate="label.coveragePlan2"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedCoveragePlan($event)"
                                                          [buttonName]='"label.coveragePlan2"'
                                                          [dependentFilters]="mainDependentFilters?.coveragePlan"
                                                          [initialFilterValues]="[ruleItem.coveragePlan]"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [providerInstance]="coveragePlanProvider"
                                                          class="sbase-dropdown-ml">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.coveragePlanId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="slotFilterRuleTags.includeCoveragePlanFlag"
                                                           (change)="onChangeIncludeFlag('coveragePlan', $event.target.checked)"
                                                           name="includeCoveragePlan">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label class="mb-1" translate="label.availabilityTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag('tag', $event)"
                                                          [buttonName]='"label.newTag"'
                                                          [dependentFilters]="mainDependentFilters?.availabilityTag"
                                                          [initialFilterValues]="slotFilterRuleTags.selectedAvailabilityTags"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [providerInstance]="tagProvider">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.tagId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="slotFilterRuleTags.includeAvailabilityTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag('tag', slotFilterRuleTags.includeAvailabilityTagFlag)"
                                                           name="includeAvailabilityTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label class="mb-1" translate="label.patientTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag('patientTag', $event)"
                                                          [buttonName]='"label.newTag"'
                                                          [dependentFilters]="mainDependentFilters?.patientTag"
                                                          [initialFilterValues]="slotFilterRuleTags.selectedPatientTags"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [providerInstance]="tagProvider">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.patientTagId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="slotFilterRuleTags.includePatientTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag('patientTag', slotFilterRuleTags.includePatientTagFlag)"
                                                           name="includePatientTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group customSelect">
                                    <label translate="label.patientType"></label>
                                    <ng-select
                                               class="custom-ng-select"
                                               loadingText="{{'label.loading' | translate}}"
                                               autofocus
                                               placeholder="{{'label.choose' | translate}}"
                                               dropdownPosition="bottom"
                                               [(ngModel)]="ruleItem.patientType"
                                               (ngModelChange)="sanitizeNgSelectValue($event, 'patientType')"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [disabled]="screenTemplateLayout.action===CONSTANTS.VIEW">
                                        <ng-option [value]="null || undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let type of patientTypeEnumList"
                                                   [value]="type">
                                            <span>{{('enum.patientType.' + type) | translate}}</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group customSelect"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.message"></label>
                                    <input type="text" class="input-element"
                                           autocomplete="off"
                                           [(ngModel)]="ruleItem.message"
                                           [ngClass]="{'input-element-has-error': !ruleItem.message}"
                                           placeholder="{{'label.message' | translate}}">
                                    <div class="help-block" *ngIf="!ruleItem.message">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleTable"></td>
                                                <td class="text-right">{{ruleItem.slotFilterRuleTable?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.name"></td>
                                                <td class="text-right">{{ruleItem.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.active"></td>
                                                <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.onlyWarning"></td>
                                                <td class="text-right">{{ruleItem.onlyWarning | yesNoPipe | translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr *ngIf="ruleItem.gender">
                                                <td class="font-weight-bold" translate="label.gender"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayIncludeIcon(ruleItem.gender, ruleItem.operationGender)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayExcludeIcon(ruleItem.gender, ruleItem.operationGender)"></i>
                                                    &nbsp;{{('enum.gender.' + ruleItem.gender) | translate}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="ruleItem.age">
                                                <td class="font-weight-bold" translate="label.age"></td>
                                                <td class="text-right">
                                                    {{ruleItem.operationAge + ' ' + ruleItem.age}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="ruleItem.coveragePlan">
                                                <td class="font-weight-bold" translate="label.coveragePlan2"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayIncludeIcon(ruleItem.coveragePlan, ruleItem.operationCoveragePlan)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayExcludeIcon(ruleItem.coveragePlan, ruleItem.operationCoveragePlan)"></i>
                                                    &nbsp;{{ruleItem.coveragePlan?.name}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="ruleItem.tag">
                                                <td class="font-weight-bold" translate="label.availabilityTag"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayIncludeIcon(ruleItem.tag, ruleItem.operationTag)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayExcludeIcon(ruleItem.tag, ruleItem.operationTag)"></i>
                                                    &nbsp;{{ruleItem.tag?.name}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="ruleItem.patientTag">
                                                <td class="font-weight-bold" translate="label.patientTag"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayIncludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="slotFilterRuleUtils.displayExcludeIcon(ruleItem.patientTag, ruleItem.includePatientTag)"></i>
                                                    &nbsp;{{ruleItem.patientTag?.name}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="ruleItem.patientType">
                                                <td class="font-weight-bold" translate="label.patientType"></td>
                                                <td class="text-right">{{('enum.patientType.' + ruleItem.patientType) | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.message"></td>
                                                <td class="text-right">{{ruleItem.message}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'SlotFilterRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
