import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {AppointmentTypeType, AppointmentTypeProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AppointmentTypeMdUtils} from '../appointment-type-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-appointment-type',
    templateUrl: './create-appointment-type.component.html',
    styleUrls: ['./create-appointment-type.component.scss']
})
export class CreateAppointmentTypeComponent implements OnInit, OnDestroy {
    constants = constants;
    initialAppointmentType: AppointmentTypeType;
    appointmentTypeItem: AppointmentTypeType = {} as AppointmentTypeType;
    screenTemplateLayout: ScreenTemplateLayoutType;

    constructor(
        public messagesService: MessagesService,
        public ngxLoader: NgxUiLoaderService,
        private appointmentTypeMdUtils: AppointmentTypeMdUtils,
        public appointmentTypeProvider: AppointmentTypeProvider,
        public router: Router,
        public generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveAppointmentTypeData(appointmentType: AppointmentTypeType) {
        const isTemplateValid = this.validateFields(appointmentType);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createAppointmentType(appointmentType);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                // If the objects are equal, we don't want to make a request because nothing was changed
                if (lodash.isEqual(this.initialAppointmentType, appointmentType)) {
                    this.messagesService.success('toastr.success.appointmentTypeEdit', true);
                    this.goToParentPage();
                } else {
                    this.editAppointmentType(this.initialAppointmentType, appointmentType);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/appointmentTypes']);
    }

    goToEdit() {
        history.replaceState({
            appointmentType: this.appointmentTypeItem,
            action: this.constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new AppointmentType
    private createAppointmentType(appointmentType: AppointmentTypeType) {
        this.ngxLoader.start();
        this.appointmentTypeProvider.addEntry(appointmentType)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newAppointmentTypeAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the AppointmentType
    private editAppointmentType(oldAppointmentType: AppointmentTypeType, newAppointmentType: AppointmentTypeType) {
        this.ngxLoader.start();
        this.appointmentTypeProvider.updateEntry(oldAppointmentType, newAppointmentType)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.appointmentTypeEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(appointmentTypeItem: AppointmentTypeType): boolean {
        return !!(appointmentTypeItem && appointmentTypeItem.name);
    }

    private setupInitialState() {
        if (history.state && history.state.appointmentType) {
            this.initialAppointmentType = history.state.appointmentType;
            this.appointmentTypeItem = lodash.cloneDeep(history.state.appointmentType);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.appointmentTypeItem = this.appointmentTypeMdUtils.getInitialAppointmentType();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
        this.validateFields(this.appointmentTypeItem);
    }
}
