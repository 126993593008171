import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmDeleteModalService, CountryProvider, CountryType, EntityTypeEnum, PatientProvider} from 'sked-base';
import {PatientReviewFilter, PatientSearchResultExtendedType} from 'sked-base/lib/data-model/patientTypes';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {constants} from '../../shared/constants/constants';
import {forkJoin, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, take} from 'rxjs/operators';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {IdNameType} from '../../data-model/general.type';
import * as lodash from 'lodash';
import {PatientAuditUtil} from './patient-audit.util';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-patient-audit.component.ts',
    templateUrl: './patient-audit.component.html',
    styleUrls: ['./patient-audit.component.scss']
})
export class PatientAuditComponent implements OnInit, OnDestroy {
    patients: PatientSearchResultExtendedType[] = [];
    searchBy: PatientReviewFilter = {} as PatientReviewFilter;
    documentTypes: string[] = [];
    documentCountries: CountryType[] = [];
    selectedCountry: CountryType;
    selectedDocumentType = 'noValue' as unknown as IdNameType;
    constants = constants;
    countryPlaceholder = 'label.documentCountry';
    EntityTypeEnum = EntityTypeEnum;
    private searchPatientNameChanged = new Subject<string>(); //notify wend search input changed
    private searchPatientByDocumentNumberChanged = new Subject<string>(); //notify wend search input changed

    constructor(private patientProvider: PatientProvider,
                private patientAuditUtil: PatientAuditUtil,
                private ngxLoader: NgxUiLoaderService,
                private countryProvider: CountryProvider,
                private translatedLanguageService: TranslatedLanguageService,
                private messagesService: MessagesService,
                private confirmDeleteService: ConfirmDeleteModalService,
                private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit(): void {
        this.searchBy = this.patientAuditUtil.getInitialQueryFilter();
        this.loadInitialData();
        this.subscribeSearchByNameChanged();
        this.subscribeSearchByDocumentNumberChanged();
    }

    ngOnDestroy(): void {
    }

    onNameValueChanged(searchPhrase: string) {
        this.searchPatientNameChanged.next(searchPhrase);
    }

    onDocumentNumberValueChanged(documentNumber: string) {
        this.searchPatientByDocumentNumberChanged.next(documentNumber);
    }

    onDocumentTypeValueChanged(documentType) {
        this.searchBy.documentType = documentType.id;
        this.selectedDocumentType = documentType;
        if (this.patientAuditUtil.hasAtLeastOneFilterValue(this.searchBy)) {
            this.loadPatientReview(this.searchBy);
        } else {
            this.patients = [];
        }
    }

    onCountrySearch(searchPhrase) {
        this.countryPlaceholder = '';
    }

    onCountryBlur(searchPhrase) {
        this.countryPlaceholder = this.searchBy?.documentCountry ? '' : 'label.documentCountry';
    }

    onCountryValueChanged(documentCountry: CountryType) {
        this.searchBy.documentCountry = documentCountry?.countryCode;
        this.countryPlaceholder = '';
        if (this.patientAuditUtil.hasAtLeastOneFilterValue(this.searchBy)) {
            this.loadPatientReview(this.searchBy);
        } else {
            this.patients = [];
        }

    }

    // this must be used to anonymize patient
    onAnonymizePatient(patientId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.patientProvider, patientId).result.then(() => {
        }, (response) => {
            if (response) {
                this.ngxLoader.start();
                this.patientProvider.anonimizePatient(patientId)
                    .pipe(take(1))
                    .subscribe((anonymizedPatient) => {
                        this.patients = this.patientAuditUtil.removePatient(this.patients, anonymizedPatient);
                    }, error => {
                        this.onError(error);
                    }, () => {
                        this.ngxLoader.stop();
                    });
            }
        });
    }

    onCancelPatient(patientId: string) {
        const foundPatient = lodash.find(this.patients, {id: patientId});
        if (foundPatient) {
            foundPatient.cancelled = true;
        }
    }

    onEnqueuePatientDataExport(patientId: string) {
        this.ngxLoader.start();
        this.patientProvider.enqueuePatientDataExport(patientId)
            .pipe(take(1))
            .subscribe((jobEnqueueResult) => {
                if (jobEnqueueResult.jobId) {
                    this.messagesService.success('toastr.success.exportDataWithSuccess');
                }
            }, error => {
                this.onError(error);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    displayChangeLog(id: string, actionType: string, name: string): void {
        this.changeLogModalService.displayModal(id, actionType, name);
    }

    onResetInput() {
        this.searchBy = this.patientAuditUtil.getInitialQueryFilter();
        this.selectedCountry = undefined;
        this.selectedDocumentType = 'noValue' as undefined as IdNameType;
        this.patients = [];
        this.searchPatientNameChanged.next(this.searchBy.searchPhrase);
        this.searchPatientByDocumentNumberChanged.next(this.searchBy.documentNumber);
    }

    private subscribeSearchByNameChanged() {
        const self = this;
        this.searchPatientNameChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.searchBy.searchPhrase = searchValue;
                if (this.patientAuditUtil.hasAtLeastOneFilterValue(self.searchBy)) {
                    this.loadPatientReview(self.searchBy);
                } else {
                    this.patients = [];
                }
            });
    }

    private subscribeSearchByDocumentNumberChanged() {
        const self = this;
        this.searchPatientByDocumentNumberChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((documentNumber) => {
                self.searchBy.documentNumber = documentNumber;
                if (this.patientAuditUtil.hasAtLeastOneFilterValue(self.searchBy)) {
                    this.loadPatientReview(self.searchBy);
                } else {
                    this.patients = [];
                }
            });
    }

    private loadInitialData() {
        this.ngxLoader.start();
        const defaultTranslatedLanguageCode =
            this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage);
        forkJoin([this.patientProvider.getDocumentTypes(),
            this.countryProvider.getCountriesForLanguage(defaultTranslatedLanguageCode)])
            .pipe(take(1))
            .subscribe(([documentTypes, countries]) => {
                this.documentTypes = documentTypes;
                this.documentCountries = lodash.sortBy(countries.value, [(country) => {
                    return country.countryName;
                }]);
            }, error => {
                this.onError(error);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private onError(error) {
        this.messagesService.handlingErrorMessage(error);
        this.ngxLoader.stop();
    }

    private loadPatientReview(searchBy) {
        this.ngxLoader.start();
        this.patientProvider.getPatientReview(searchBy)
            .subscribe((response) => {
                this.patients = response.value;
            }, error => {
                this.onError(error);
            }, () => {
                this.ngxLoader.stop();
            });
    }
}
