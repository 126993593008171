import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {SubServiceMDComponent} from './sub-service-md.component';
import {CreateSubServiceComponent} from './create-sub-service.component';

@NgModule({
    declarations: [SubServiceMDComponent, CreateSubServiceComponent],
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        FormsModule,
        NgbModule
    ]
})
export class SubServiceModule {
}
