import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    SubServiceObjectDetailRuleProvider,
    SubServiceObjectDetailRuleType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
    TagProvider,
    ObjectDetailType,
    ObjectDetailProvider,
    ChannelEnum,
    ChannelProvider,
    FilterComponentChannelType
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {TagType} from 'sked-base/lib/data-model/tagTypes';
import {SubServiceObjectDetailRuleUtils} from '../sub-service-object-detail-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';
import {SubServiceObjectDetailRuleTagsType} from '../sub-service-object-detail-rule.types';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-sub-service-object-detail-rule',
    templateUrl: './create-sub-service-object-detail-rule.component.html',
    styleUrls: ['./create-sub-service-object-detail-rule.component.scss']
})
export class CreateSubServiceObjectDetailRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: SubServiceObjectDetailRuleType = {} as SubServiceObjectDetailRuleType;
    initialRule: SubServiceObjectDetailRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    CONSTANTS = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;

    ruleTableDependentFilters = this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.SubServiceObjectDetailRule);
    mainDependentFilters = this.subServiceObjectDetailRuleUtils.getMainDependentFilters();
    initialChannel: FilterComponentChannelType[] = [];
    subServiceObjectDetailRuleTags: SubServiceObjectDetailRuleTagsType;

    constructor(
        public channelProvider: ChannelProvider,
        public subServiceObjectDetailRuleUtils: SubServiceObjectDetailRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public tagProvider: TagProvider,
        public objectDetailProvider: ObjectDetailProvider,
        public generalUtils: GeneralUtils,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private subServiceObjectDetailRuleProvider: SubServiceObjectDetailRuleProvider,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as {invalid?: boolean, pending?: boolean} as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: SubServiceObjectDetailRuleType): boolean {
        return !!(rule.subServiceObjectDetailRuleTableId && !lodash.isEmpty(rule.subServiceObjectDetailRuleTable) && rule.name);
    }

    isInputStepValid(rule: SubServiceObjectDetailRuleType): boolean {
        return !!(!lodash.isEmpty(rule.tag) && rule.tagId);
    }

    isOutputStepValid(rule: SubServiceObjectDetailRuleType): boolean {
        return !!(!lodash.isEmpty(rule.objectDetail) && rule.objectDetailId);

    }

    areAllStepsValid(rule: SubServiceObjectDetailRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.subServiceObjectDetailRuleTable = ruleTable[0];
            this.ruleItem.subServiceObjectDetailRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.subServiceObjectDetailRuleTable = undefined;
            this.ruleItem.subServiceObjectDetailRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    //
    onSelectedChannel(channelList: FilterComponentChannelType[]) {
        this.ruleItem.channel = channelList?.length > 0 ? channelList[0].enumValue as ChannelEnum : undefined;
        this.initialChannel = channelList?.length > 0 ? [channelList[0]] : [];
    }

    onSelectedTag(tagList: TagType[]) {
        this.ruleItem.tagId = tagList?.length > 0 ? tagList[0].id : undefined;
        this.ruleItem.tag = tagList?.length > 0 ? tagList[0] : undefined;
    }
    //
    // endregion Input methods

    // region Output methods
    //
    onSelectedObjectDetail(objectDetailList: ObjectDetailType[]) {
        this.ruleItem.objectDetailId = objectDetailList?.length > 0 ? objectDetailList[0].id : undefined;
        this.ruleItem.objectDetail = objectDetailList?.length > 0 ? objectDetailList[0] : undefined;
    }
    //
    // endregion Output methods

    // region Overview methods
    //
    saveRule(rule: SubServiceObjectDetailRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.subServiceObjectDetailRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: SubServiceObjectDetailRuleType) {
        this.ngxLoader.start();
        const ruleToSend: SubServiceObjectDetailRuleType =
            this.subServiceObjectDetailRuleUtils.mapRuleForServer(rule);
        this.subServiceObjectDetailRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newSubServiceObjectDetailRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: SubServiceObjectDetailRuleType, newRule: SubServiceObjectDetailRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: SubServiceObjectDetailRuleType =
            this.subServiceObjectDetailRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: SubServiceObjectDetailRuleType =
            this.subServiceObjectDetailRuleUtils.mapRuleForServer(newRule);
        this.subServiceObjectDetailRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.subServiceObjectDetailRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.CONSTANTS.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private loadChannels() {
        this.ngxLoader.start();
        this.channelProvider.getEntries().subscribe(channels => {
            const CHANNEL_FROM_LIST = channels.value.find(channel => channel.enumValue === this.ruleItem.channel);
            this.initialChannel = [{id: CHANNEL_FROM_LIST?.id, enumValue: CHANNEL_FROM_LIST?.enumValue, name: CHANNEL_FROM_LIST?.channel}];
            this.ngxLoader.stop();
        }, error => {
            this.ngxLoader.stop();
            this.messagesService.handlingErrorMessage(error);
            this.rulesUtils.goToParentPage('createRule');
        });
    }

    private setupInitialState() {
        this.subServiceObjectDetailRuleTags = this.subServiceObjectDetailRuleUtils.getInitialRuleTags();
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                // TODO-rules: check if this can be done without loadChannels
                if (!this.generalUtils.isNullOrUndefined(this.ruleItem.channel)) {
                    this.loadChannels();
                }
                this.subServiceObjectDetailRuleTags = {
                    selectedTags: !!this.ruleItem.tag ? [this.ruleItem.tag] : []
                } as SubServiceObjectDetailRuleTagsType;
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.ruleItem = this.subServiceObjectDetailRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }
}
