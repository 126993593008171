<div class="container-fluid create-activity-plan-template-wrapper create-entity-md-wrapper p-0 d-flex no-block flex-column">

    <!-- Heading and Item name -->
    <div class="create-entity-md-section mb-2 px-3 py-3 text-high-contrast">
        <div class="create-activity-plan-template-header px-1">
            <h3 class="create-activity-plan-template-title">{{activityPlanTemplateUtils.screenTemplateLayout.pageTitle|translate}} <span
                    class="text-lowercase" translate="label.activityPlanTemplateTab.activityPlanTemplate"></span></h3>
        </div>

        <div class="row" *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action === constants.VIEW">
            <table class="table border mt-2 view-table">
                <tbody>
                <tr>
                    <td translate="label.name"></td>
                    <td class="text-right">{{activityPlanTemplateUtils.activityPlanTemplateItem.name}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="row" *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW">
            <div class="col-12 px-2">
                <div class="form-group mb-0">
                    <label translate="label.name"></label> <span class="input-required-asterisk">*</span>
                    <div class="row">
                        <div class="col-6 p-0">
                            <input type="text" name="name" class="input-element text-high-contrast"
                                   [ngClass]="{'input-element-has-error': !activityPlanTemplateUtils.activityPlanTemplateItem.name}"
                                   [(ngModel)]="activityPlanTemplateUtils.activityPlanTemplateItem.name"
                                   placeholder="{{'label.name'|translate}}"
                                   autocomplete="off">
                        </div>
                    </div>
                    <div *ngIf="!activityPlanTemplateUtils.activityPlanTemplateItem.name" class="help-block">
                        <span translate="label.error.required"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Edit / Add service to activity template -->
    <div class="create-entity-md-section mb-2 px-3 py-3 text-high-contrast">
        <div class="row"
             *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW">
            <div class="col-12 p-0">
                <div class="row">
                    <!-- Select service (ServiceDTO) -->
                    <div class="col-6 px-2">
                        <div class="form-group customSelect service mt-0 sbase-input-element text-high-contrast">
                            <label translate="label.service2"></label> <span class="input-required-asterisk">*</span>
                            <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                                          [buttonName]='"label.search"'
                                          [dependentFilters]="mainDependentFiltersForServiceSearch"
                                          [initialFilterValues]="[activityPlanTemplateUtils.scopedServiceItem.service]"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="serviceProvider"
                                          [hideSelectedItems]="true"
                                          [useSelectedValueAsLabel]="true"
                                          [ngClass]="{'input-element-has-error': !activityPlanTemplateUtils.scopedServiceValidation.service.isValid}"
                                          [customTemplate]="serviceTemplate">
                            </sbase-filter>
                            <ng-template let-item #serviceTemplate>
                                    <span *ngIf="!!item.specialityName">
                                        <span class="font-weight-bold">{{item.specialityName}} </span>
                                    </span>
                                <!-- in select -->
                                <span *ngIf="!item.nameWithoutSpeciality">
                                        <span>{{item.name}}</span>
                                    </span>
                                <!-- in dropdown -->
                                <span *ngIf="!!item.nameWithoutSpeciality">
                                        <span>{{item.nameWithoutSpeciality}}</span>
                                    </span>
                                <i *ngIf="item.onlineConsultation" class="fas fa-desktop ml-1"
                                   ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                            </ng-template>
                            <div *ngIf="!activityPlanTemplateUtils.scopedServiceValidation.service.isValid" class="help-block">
                                <span [translate]="activityPlanTemplateUtils.scopedServiceValidation.service.errorMessage"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 px-2 d-flex no-block flex-column">
                        <div class="form-group mt-0">
                            <label translate="label.recurrence"></label> <span class="input-required-asterisk">*</span>
                            <div class="row mt-2">
                                <label class="checkbox d-flex no-block align-items-center text-high-contrast mb-0 mr-4">
                                    <input type="checkbox" name="recurrenceTypeOnce"
                                           [value]="activityPlanTemplateUtils.isExecutionTypeOnceSelected"
                                           [(ngModel)]="activityPlanTemplateUtils.isExecutionTypeOnceSelected"
                                           [ngModelOptions]="{standalone: true}"
                                           (ngModelChange)="activityPlanTemplateUtils.onExecutionTypeChange()">
                                    <span>{{'label.once' | translate}}</span>
                                </label>
                                <button class="btn theme-btn mr-2"
                                        [ngClass]="{
                                            'button-has-error': !activityPlanTemplateUtils.scopedServiceValidation.recurrenceModal.isValid,
                                            'disabled': activityPlanTemplateUtils.isExecutionTypeOnceSelected
                                        }"
                                        (click)="openServiceRecurrenceModal()"
                                        [hidden]="activityPlanTemplateUtils.screenTemplateLayout.action === constants.VIEW"
                                        translate="label.recurrence">
                                </button>
                                <div *ngIf="!activityPlanTemplateUtils.scopedServiceValidation.recurrenceModal.isValid" class="help-block mt-2">
                                    <span [translate]="activityPlanTemplateUtils.scopedServiceValidation.recurrenceModal.errorMessage"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 px-2 update-buttons ml-auto"
                 *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW">
                <button class="btn btn-apply border-0 p-0 mr-2 shadow-none"
                        [translate]="activityPlanTemplateUtils.scopedServiceInEditMode ? 'label.saveService' : 'label.addService'"
                        (click)="saveScopedService()">
                </button>
                <button class="btn border-0 p-0" translate="label.clear"
                        (click)="clearScopedService()"></button>
            </div>
        </div>
    </div>

    <!-- Services table and footer buttons -->
    <div class="create-entity-md-section mb-2 px-3 py-3 flex-grow-1 d-flex no-block flex-column">
        <div class="services-table-container flex-grow-1 overflow-auto">
            <div class="row">
                <table class="table table-bordered grey-table">
                    <thead class="thead-light">
                    <tr>
                        <td translate="label.service2"></td>
                        <td translate="label.recurrence"></td>
                        <td translate="label.action" *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW"></td>
                    </tr>
                    </thead>
                    <!-- display an empty row if no service is added-->
                    <tbody *ngIf="!activityPlanTemplateUtils.activityPlanTemplateItem?.services || activityPlanTemplateUtils.activityPlanTemplateItem?.services?.length===0">
                    <tr>
                        <td></td>
                        <td></td>
                        <td *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW"></td>
                    </tr>
                    </tbody>
                    <tbody *ngFor="let service of activityPlanTemplateUtils.activityPlanTemplateItem?.services; let index = index">
                    <tr class="text-dark">
                        <td>
                            <span *ngIf="service.service">
                                <!-- field specialityName is added in addSpecialityNameToServices (utils method) -->
                                <span class="font-weight-bold"
                                      *ngIf="service.service.specialityName">{{service.service.specialityName}} </span>
                                <span *ngIf="service.service.name">{{service.service.name}}</span>
                                <i *ngIf="service.service.onlineConsultation" class="fas fa-desktop ml-1"
                                   ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                            </span>
                        </td>
                        <td>
                            <div *ngIf="service.executionType === activityPlanExecutionTypeEnum.Once">
                                <span translate="label.once"></span>
                            </div>
                            <div *ngIf="service.executionType === activityPlanExecutionTypeEnum.Scheduled">
                                <!-- Interval -->
                                <div>
                                    <span translate="label.repeatEvery" class="font-weight-bold"></span>:&nbsp;
                                    {{service.interval}}&nbsp;
                                    <span *ngIf="service.frequency.includes(activityPlanTemplateFrequencyTypeEnum.monthDays)">
                                        {{'enum.enqueueUnitType.Months' | translate}},&nbsp;
                                    </span>
                                    <span *ngIf="service.frequency.includes(activityPlanTemplateFrequencyTypeEnum.weekDays)">
                                        {{'label.weeks' | translate}},&nbsp;
                                    </span>

                                    <!-- Frequency (days) field parsedFrequency is added in addParsedFrequencyToTemplateItem (utils method) -->
                                    <span *ngIf="service?.parsedFrequency">
                                        <span translate="label.on"></span>&nbsp;
                                        <span *ngIf="service.parsedFrequency?.frequencyType === activityPlanTemplateFrequencyTypeEnum.weekDays">
                                            {{'enum.frequencyType.weekDays' | translate}}:
                                            <span *ngIf="service.parsedFrequency?.selectedDays?.length > 0">
                                                <!-- display first week day -->
                                                <span>{{'label.date.' + (service.parsedFrequency?.selectedDays[0] | lowercase) + 'Med' | translate}}</span>
                                                <!-- then the rest, with comma in between -->
                                                <span *ngFor="let weekday of service.parsedFrequency?.selectedDays | slice : 1">,
                                                    {{'label.date.' + (weekday | lowercase) + 'Med' | translate}}
                                                </span>
                                            </span>
                                        </span>
                                        <span *ngIf="service.parsedFrequency?.frequencyType === activityPlanTemplateFrequencyTypeEnum.monthDays">
                                            {{'enum.frequencyType.monthDays' | translate}}:
                                            <span *ngIf="service.parsedFrequency?.selectedDays?.length > 0">
                                                <!-- display first month day -->
                                                <span>{{service.parsedFrequency?.selectedDays[0]}}</span>
                                                <!-- then the rest, with comma in between -->
                                                <span *ngFor="let monthday of service.parsedFrequency?.selectedDays | slice : 1">,
                                                    {{monthday}}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>

                                <!-- Period before generating task -->
                                <div>
                                    <span translate="label.activityPlanTemplateTab.enqueue" class="font-weight-bold"></span>:&nbsp;
                                    {{service.enqueuePeriod}} {{'enum.enqueueUnitType.' + service.enqueueUnit | translate}}
                                </div>

                                <!-- Ends -->
                                <div>
                                    <span translate="label.ends" class="font-weight-bold"></span>:&nbsp;
                                    <span *ngIf="service.scheduledEndType === activityPlanScheduledEndTypeEnum.EndsNever" translate="label.never"></span>
                                    <span *ngIf="service.scheduledEndType === activityPlanScheduledEndTypeEnum.EndsAfterOccurences">
                                    <span translate="label.after"></span>&nbsp;
                                    <span>{{service.count}}</span>&nbsp;
                                    <span translate="label.occurrences"></span>
                                </span>
                                    <span *ngIf="service.scheduledEndType === activityPlanScheduledEndTypeEnum.EndsOnSpecificDate">
                                    <span translate="label.onSpecificDate"></span>
                                </span>
                                </div>
                            </div>
                        </td>
                        <td class="text-center" *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action !== constants.VIEW">
                            <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                                    popoverClass="popover-shadow">
                                <i class="fas fa-ellipsis-h"></i>
                            </button>
                            <ng-template #popContent>
                                <ul class="list-style-none m-0 p-0 custom-drop-down">
                                    <li>
                                        <button type="button" class="btn" translate="label.edit"
                                                (click)="onEditScopedServiceDetails(index)"></button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn" translate="label.delete"
                                                (click)="deleteActivityPlanTemplateService(index)"></button>
                                    </li>
                                </ul>
                            </ng-template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="footer-buttons-container">
            <button class="btn mr-3" type="button"
                    (click)="goToParentPage()"
                    [ngClass]="activityPlanTemplateUtils.screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{activityPlanTemplateUtils.screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn"
                    (click)="saveActivityPlanTemplateData(activityPlanTemplateUtils.activityPlanTemplateItem)"
                    [hidden]="activityPlanTemplateUtils.screenTemplateLayout.action === constants.VIEW"
                    translate="{{activityPlanTemplateUtils.screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="activityPlanTemplateUtils.screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'ActivityPlanTemplateUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
</div>
