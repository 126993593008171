import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SlotProvider} from 'sked-base';
import {take, tap} from 'rxjs/operators';
import {SlotSearchRequestFilterType, SlotSearchResponseType} from 'sked-base/lib/data-model/slotTypes';
import {SlotSearchModalOptionsType} from '../multi-appointment-booking.types';
import {MultiAppointmentBookingMdUtils} from '../multi-appointment-booking.utils';
import {MessagesService} from '../../../shared/services/messages.service';

@Component({
    selector: 'app-mab-slot-search-modal',
    templateUrl: './mab-slot-search-modal.component.html',
    styleUrls: ['./mab-slot-search-modal.component.scss']
})
export class MabSlotSearchModalComponent implements OnInit {
    @Input() options: SlotSearchModalOptionsType;

    slotsQueryFilter: SlotSearchRequestFilterType = {} as SlotSearchRequestFilterType;

    private stopProcess = false;
    private searchInProgress = false;

    constructor(public activeModal: NgbActiveModal,
                private multiAppointmentBookingMdUtils: MultiAppointmentBookingMdUtils,
                private slotProvider: SlotProvider,
                private messagesService: MessagesService) {
    }

    ngOnInit(): void {
        if (!this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage) {
            this.multiAppointmentBookingMdUtils.calculateSlotSearchMaxTimeWindowValue();
        }
        this.slotsQueryFilter = this.multiAppointmentBookingMdUtils.getQueryFilterForSlotSearch(
            this.options.filterValues, this.options.patient, this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage);
        this.searchForSlots();
    }

    onCloseModal() {
        this.activeModal.dismiss('cancel');
        // anytime the search can be stopped if the user press Cancel
        // in this case we need to know so we don't return the results to the component
        this.stopProcess = true;
    }

    private searchForSlots() {
        this.slotProvider.searchForSlots(this.slotsQueryFilter)
            .pipe(
                take(1),
                tap((response) => {
                    // when slot search returns no data but the search period is still inside search time window
                    // we calculate the new search period and trigger another request
                    if (!this.stopProcess && response?.slots.length === 0 &&
                        this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch < this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum &&
                        !this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage
                    ) {
                        this.calculateNewTimeWindowValuesForSlotSearch();
                        this.slotsQueryFilter = this.multiAppointmentBookingMdUtils.getQueryFilterForSlotSearch(this.options.filterValues,
                            this.options.patient, this.multiAppointmentBookingMdUtils.searchesAreDoneForMoreThanOnePage);
                        this.searchInProgress = true;
                        this.searchForSlots();
                        return;
                    } else {
                        this.searchInProgress = false;
                        return response;
                    }
                })
            )
            .subscribe((response: SlotSearchResponseType) => {
                // if the slot search was not stopped and the search is not in progress
                // we can send the data to the parent component
                if (!this.stopProcess && !this.searchInProgress) {
                    this.activeModal.close(response);
                }
            }, err => {
                this.activeModal.dismiss('error');
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private calculateNewTimeWindowValuesForSlotSearch(): void {
        const searchTimeWindow = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch
            + this.multiAppointmentBookingMdUtils.mabSearchStep;
        this.multiAppointmentBookingMdUtils.previousTimeWindowInDaysBasedOnSearch = this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch;
        this.multiAppointmentBookingMdUtils.timeWindowInDaysBasedOnSearch =
            Math.min(this.multiAppointmentBookingMdUtils.mabTimeWindowMaximum, searchTimeWindow);
    }

}
