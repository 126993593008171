import {EntityTypeEnum, FileAssignationEnum} from 'sked-base';

export enum ViewerFileTypeEnum {
    PDF = 'PDF',
    IMG = 'IMG',
}

export enum ViewerActionTypeEnum {
    ZoomIn = 'ZoomIn',
    ZoomOut = 'ZoomOut',
    RotateLeft = 'RotateLeft',
    RotateRight = 'RotateRight',
    Reset = 'Reset',
    Print = 'Print',
    Download = 'Download',
}

export interface PdfImageViewerOptionsType {
    entityType: EntityTypeEnum;
    entityId: string;
}

export interface ViewerFileType {
    id: string;
    name: string;
    type: ViewerFileTypeEnum;
    blobSrc: string;
    options?: ViewerFileOptionsType;
}

export interface ViewerFileOptionsType {
    pdfOptions: {
        zoom: number;
        rotation: number;
    };
    imageOptions: {
        styles?: { transform: string, msTransform: string, oTransform: string, webkitTransform: string };
        scale: number;
        rotation: number;
        translateX: number;
        translateY: number;
        prevX: number;
        prevY: number;
    };
}
