import {Component, OnDestroy, OnInit} from '@angular/core';
import {AvailabilityDataType, AvailabilityProvider} from 'sked-base';
import {ScreenTemplateLayoutType} from '../../../../data-model/general.type';
import {MessagesService} from '../../../../shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from '../../../../shared/utils/general.utils';
import * as moment from 'moment';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {constants} from '../../../../shared/constants/constants';
import {take} from 'rxjs/operators';
import {
    AvailabilitySplitValidationType,
    AvailabilityViewPageEnum,
    BackofficeAvailabilitySplitDataType,
    BackofficeAvailabilitySplitDateTypeEnum
} from '../../availability.types';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {CreateAvailabilitySplitUtils} from './create-availability-split.utils';
import {DateTimeUtils} from '../../../../shared/utils/dateTime.utils';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-create-availability-split',
    templateUrl: './create-availability-split.component.html',
    styleUrls: ['./create-availability-split.component.scss']
})
export class CreateAvailabilitySplitComponent implements OnInit, OnDestroy {
    constants = constants;
    splitDateTypeEnum = BackofficeAvailabilitySplitDateTypeEnum;
    screenTemplateLayout = {} as ScreenTemplateLayoutType;
    validationParams = {} as { minDate: string; maxDate: string };
    dateOptions = {} as { minDate: NgbDateStruct, maxDate: NgbDateStruct };

    availabilitySplitValidationItem: AvailabilitySplitValidationType;
    availabilitySplitItem: BackofficeAvailabilitySplitDataType = {} as BackofficeAvailabilitySplitDataType;
    availabilityItem: AvailabilityDataType = {} as AvailabilityDataType;
    endOfDay = false;

    constructor(private messagesService: MessagesService,
                private ngxLoader: NgxUiLoaderService,
                private router: Router,
                private generalUtils: GeneralUtils,
                private createAvailabilitySplitUtils: CreateAvailabilitySplitUtils,
                private availabilityProvider: AvailabilityProvider,
                public dateTimeUtils: DateTimeUtils) {
    }

    ngOnInit(): void {
        if (!history?.state?.availabilityId) {
            this.goToParentPage();
        } else {
            this.setupInitialState(history.state.availabilityId);
            this.loadInitialData();
        }
    }

    //don't delete this
    ngOnDestroy(): void {
    }

    onEndOfDayChange() {
        // validate split dates
        this.availabilitySplitValidationItem = this.createAvailabilitySplitUtils.validateSplitInterval(
            this.availabilitySplitItem.splitFrom,
            this.availabilitySplitItem.splitTo,
            this.availabilityItem.validFrom,
            this.availabilityItem.validTo,
            this.endOfDay);
    }

    onDateTimeChanged(date: NgbDateStruct, time: NgbTimeStruct, type: BackofficeAvailabilitySplitDateTypeEnum) {
        if (type === BackofficeAvailabilitySplitDateTypeEnum.SplitFrom) {
            this.availabilitySplitItem.timeFrom = time;
            this.availabilitySplitItem.splitFrom = this.createAvailabilitySplitUtils.getAvailabilitySplitFormatDate(date, time);
        } else if (type === BackofficeAvailabilitySplitDateTypeEnum.SplitTo) {
            this.availabilitySplitItem.timeTo = time;
            this.availabilitySplitItem.splitTo = this.createAvailabilitySplitUtils.getAvailabilitySplitFormatDate(date, time);
        }
        // validate split dates
        this.availabilitySplitValidationItem = this.createAvailabilitySplitUtils.validateSplitInterval(
            this.availabilitySplitItem.splitFrom,
            this.availabilitySplitItem.splitTo,
            this.availabilityItem.validFrom,
            this.availabilityItem.validTo,
            this.endOfDay);
    }

    saveAvailabilitySplit(availabilitySplit: BackofficeAvailabilitySplitDataType) {
        if (this.availabilitySplitValidationItem.isValid) {
            this.createAvailabilitySplit(availabilitySplit);
        }
    }

    goToParentPage() {
        this.router.navigate(['/' + AvailabilityViewPageEnum.AvailabilityOverview]);
    }

    private loadInitialData() {
        this.ngxLoader.start();
        this.availabilityProvider.getAvailabilityDataById(history?.state?.availabilityId)
            .pipe(take(1))
            .subscribe((availability: AvailabilityDataType) => {
                this.onAvailabilityDataReceived(availability);
            }, err => {
                this.onError(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private onAvailabilityDataReceived(availability: AvailabilityDataType) {
        this.availabilityItem = availability;
        // set parameters for error message
        const validationParamsMaxDates = {
            // @ts-ignore
            maxDateNoEndOfDay: moment.parseZone(availability.validTo).format(moment.localeData().longDateFormat('fullDate')),
            // @ts-ignore
            maxDateEndOfDay: moment.parseZone(availability.validTo).subtract(1, 'days').format(moment.localeData().longDateFormat('fullDateNoTime'))
        };
        this.validationParams = {
            // @ts-ignore
            minDate: moment(availability.validFrom).parseZone().format(moment.localeData().longDateFormat('fullDate')),
            maxDate: availability.endOfDay ? validationParamsMaxDates.maxDateEndOfDay : validationParamsMaxDates.maxDateNoEndOfDay
        };

        //validate initial split date
        this.availabilitySplitValidationItem = this.createAvailabilitySplitUtils.validateSplitInterval(
            this.availabilitySplitItem.splitFrom,
            this.availabilitySplitItem.splitTo,
            this.availabilityItem.validFrom,
            this.availabilityItem.validTo,
            this.endOfDay);
    }

    private setupInitialState(availabilityId: string) {
        // we have only create action
        this.availabilitySplitItem = this.createAvailabilitySplitUtils.getInitialAvailabilitySplitData(availabilityId);
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        this.setDateOptions();
    }

    private setDateOptions() {
        this.dateOptions = {
            minDate: this.dateTimeUtils.getTodayNgbDateStruct(),
            maxDate: this.dateTimeUtils.getTodayNgbDateStruct(),
        };
    }


    // function to create the new CoverageCompany
    private createAvailabilitySplit(availabilitySplit: BackofficeAvailabilitySplitDataType) {
        this.ngxLoader.start();
        this.availabilityProvider.addSplit(this.createAvailabilitySplitUtils.mapAvailabilitySplitForSave(availabilitySplit,
            this.availabilityItem.timeZoneId, this.endOfDay))
            .pipe(take(1))
            .subscribe(() => {
                this.messagesService.success('toastr.success.newAvailabilitySplitAdded', true);
                this.goToParentPage();
            }, err => {
                this.onError(err);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private onError(err) {
        this.ngxLoader.stop();
        this.messagesService.handlingErrorMessage(err);
    }
}
