import {Injectable} from '@angular/core';
import {ODataFilterQueryType, TableFiltersType, ODataOrderByQueryType} from '../../data-model/general.type';
import {
    ResourceType,
    ODataQueryObjectType,
    Expand,
    ResourceTypeDependentFilterType,
    ServiceProvider,
    CenterProvider,
    CoveragePlanProvider,
    ResourceProvider,
    ServiceDependentFiltersType,
    LocationDependentFiltersType,
    CoveragePlanDependentFiltersType, SpecialityDependentFiltersType, SpecialityProvider, CenterType, CoveragePlanType,
    ServiceType,
} from 'sked-base';
import * as lodash from 'lodash';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {ResourceMDFiltersType, ResourceMultiSelectTableOptionsType, ValidResourceObjectType} from './resource-md.types';
import {
    MultiSelectTableColumnKindEnum,
    MultiSelectTableColumnType, MultiSelectTableOptionsType
} from '../../shared/component/multi-select-table/multi-select-table.types';
import {ExternalKeysType} from 'sked-base/lib/data-model/externalKeysTypes';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {MultiSelectTableUtils} from '../../shared/component/multi-select-table/multi-select-table-util';

@Injectable({
    providedIn: 'root'
})
export class ResourceMdUtils {
    tableFilters: TableFiltersType = {} as TableFiltersType;
    initialFilterValues: ResourceMDFiltersType = {} as ResourceMDFiltersType;

    constructor(private generalUtils: GeneralUtils,
                private serviceProvider: ServiceProvider,
                private specialityProvider: SpecialityProvider,
                private centerProvider: CenterProvider,
                private coveragePlanProvider: CoveragePlanProvider,
                private resourceProvider: ResourceProvider,
                private multiSelectTableUtils: MultiSelectTableUtils) {
    }

    getQueryFilterForResourceMD(tableFilters: TableFiltersType, count: boolean = true): ODataQueryObjectType {
        return {
            count,
            skip: (tableFilters.currentPage - 1) * tableFilters.itemsPerPage,
            top: tableFilters.itemsPerPage,
            filter: this.getFilterQuery(tableFilters.filter),
            orderBy: this.getOrderByQuery(tableFilters.orderBy),
            expand: this.getExpandFilter()
        };
    }

    getExpandFilter(): Expand {
        return {
            ResourceType: {},
            ResourceExternalKeys: {select: ['Origin', 'Key', 'EntityId', 'Id']},
            Services: {select: ['Id', 'Name']},
            Centers: {select: ['Id', 'Name']},
            CoveragePlans: {select: ['Id', 'Name']}
        };
    }

    getInitialTableFilter(): TableFiltersType {
        const tableFilters = this.generalUtils.getInitialTableFilter();
        tableFilters.filter = {Name: ''};
        return tableFilters;
    }

    getFilterQuery(filter: ODataFilterQueryType): ODataFilterQueryType {
        const filterQuery: ODataFilterQueryType = {} as ODataFilterQueryType;
        for (const item in filter) {
            if (item && filter[item]) {
                switch (item) {
                    case 'specialityId':
                    case 'serviceId':
                    case 'areaId': {
                        if (lodash.isEmpty(filterQuery)) {
                            filterQuery.Services = {any: {}};
                        }
                        const filterKey = String(item) === 'serviceId' ? 'id' : item;
                        filterQuery.Services.any[filterKey] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'centerId': {
                        if (lodash.isEmpty(filterQuery)) {
                            filterQuery.Centers = {any: {}};
                        }
                        const filterKey = String(item) === 'centerId' ? 'id' : item;
                        filterQuery.Centers.any[filterKey] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'coveragePlanId': {
                        if (lodash.isEmpty(filterQuery)) {
                            filterQuery.CoveragePlans = {any: {}};
                        }
                        const filterKey = String(item) === 'coveragePlanId' ? 'id' : item;
                        filterQuery.CoveragePlans.any[filterKey] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    case 'resourceTypeId': {
                        filterQuery[lodash.upperFirst(item)] = {eq: {type: 'guid', value: filter[item]}};
                        break;
                    }
                    default: {
                        filterQuery[lodash.upperFirst(item)] = {contains: filter[item]};
                    }
                }
            }
        }
        return filterQuery;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                switch (item) {
                    case 'type': {
                        orderByQuery.push('ResourceType/Name ' + orderBy[item]);
                        break;
                    }
                    default: {
                        orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
                        break;
                    }
                }
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getInitialResource(): ResourceType {
        return {
            name: '',
            resourceTypeId: '',
            priority: null,
            isDirectlyBookable: false,
            isRoom: false,
            resourceExternalKeys: []
        } as ResourceType;
    }

    getResourceTypeDependentFilters(): ResourceTypeDependentFilterType {
        return {
            searchPhrase: '',
            exclusionList: []
        };
    }

    getInitialFilterValues(): ResourceMDFiltersType {
        return {
            speciality: [],
            service: [],
            area: [],
            resourceType: [],
            center: [],
            coveragePlan: []
        };
    }

    getInitValidTempleObject(): ValidResourceObjectType {
        return {
            isResourceTypeValid: false,
            isNameValid: false,
            isPriorityValid: false,
            uniqueCode: {isValid: true, errorMessage: ''},
            email: {isValid: true, errorMessage: ''},
            isPhoneNumberValid: true,
            location: {isValid: true, errorMessage: ''},
            additionalInformation: {isValid: true, errorMessage: ''},
            externalUrlForDescription: {isValid: true, errorMessage: ''},
            description: {isValid: true, errorMessage: ''},
            areServicesValid: true,
            areCentersValid: true,
            areCoveragePlansValid: true
        };
    }

    getResourceMultiSelectTableOptions(
        resource: ResourceType, screenTemplateLayoutAction: string, customTemplate: any): ResourceMultiSelectTableOptionsType {
        const servicesExpand = {Speciality: {select: ['Id', 'Name']}};
        const servicesOrderBy = ['Speciality/Name asc', 'Name asc'];
        return {
            services: {
                provider: this.serviceProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.resourceProvider,
                confirmDeleteParentId: resource.id,
                confirmDeleteRefName: 'Services',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems( ['Id', 'Name', 'SpecialityId'], null, servicesExpand, servicesOrderBy),
                initialSelectedItems: resource.services,
                filterBySpecialProperty: 'CoveragePlans',
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.speciality',
                    displayUpperHeader: true,
                    itemPropertyNameInTableRow: 'name',
                    itemPropertyExpandName: 'speciality',
                    provider: this.specialityProvider,
                    buttonName: 'label.speciality',
                    dependentFilters: this.getSpecialityDependentFilters(),
                    propertyName: 'speciality',
                    propertyFilterIdName: 'SpecialityId',
                    propertyFilterIdNameMapped: 'specialityId',
                    orderByProperty: 'Speciality/Name'
                } as MultiSelectTableColumnType, {
                    type: MultiSelectTableColumnKindEnum.DISPLAY,
                    headerLabel: 'label.service',
                    displayUpperHeader: false,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.serviceProvider,
                    buttonName: 'label.service',
                    dependentFilters: this.getServiceDependentFilters(),
                    propertyName: 'service',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                    customHTMLTemplate: customTemplate,
                    orderByProperty: 'name'
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType,
            centers: {
                provider: this.centerProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.resourceProvider,
                confirmDeleteParentId: resource.id,
                confirmDeleteRefName: 'Centers',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(['Id', 'Name'], null, null, ['Name asc']),
                initialSelectedItems: resource.centers,
                tableMaxHeight: '20rem',
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.center',
                    displayUpperHeader: true,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.centerProvider,
                    buttonName: 'label.center',
                    dependentFilters: this.getLocationDependentFilters(),
                    propertyName: 'center',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                    orderByProperty: 'name'
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType,
            coveragePlans: {
                provider: this.coveragePlanProvider,
                providerMethod: 'getEntries',
                confirmDeleteProvider: this.resourceProvider,
                confirmDeleteParentId: resource.id,
                confirmDeleteRefName: 'CoveragePlans',
                getQueryForItems: this.multiSelectTableUtils.getQueryMakerForItems(['Id', 'Name'], null, null, ['Name asc']),
                initialSelectedItems: resource.coveragePlans,
                tableMaxHeight: '20rem',
                columns: [{
                    type: MultiSelectTableColumnKindEnum.FILTER,
                    headerLabel: 'label.coveragePlan',
                    displayUpperHeader: true,
                    itemPropertyNameInTableRow: 'name',
                    provider: this.coveragePlanProvider,
                    buttonName: 'label.coveragePlan',
                    dependentFilters: this.getCoveragePlanDependentFilters(),
                    propertyName: 'coveragePlan',
                    propertyFilterIdName: 'Id',
                    propertyFilterIdNameMapped: 'id',
                    orderByProperty: 'name'
                } as MultiSelectTableColumnType],
                screenTemplateLayoutAction
            } as MultiSelectTableOptionsType
        } as ResourceMultiSelectTableOptionsType;
    }

    getServiceDependentFilters = (): ServiceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        coveragePlanId: null,
        includeChannel: false,
        onlyAssignedToLocationsOfUser: true,
        patientId: null,
        locationId: null,
        regionId: null,
        resourceId: null,
        areaId: null,
        exclusionList: [],
        applyOnlineConsultationCondition: false,
        count: true
    })

    getSpecialityDependentFilters = (): SpecialityDependentFiltersType => ({
        searchPhrase: '',
        areaId: null,
        count: true,
        exclusionList: []
    })

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: false,
        isRequestAllowed: false,
        locationId: null,
        regionId: null,
        resourceId: null,
        serviceId: null,
        areaId: null,
        useOnlyAssignedToUser: false,
        exclusionList: [],
        count: true
    })

    getCoveragePlanDependentFilters(): CoveragePlanDependentFiltersType {
        return {
            searchPhrase: '',
            excludePrivatePlans: false,
            excludeSystemManagedPlans: false,
            serviceId: null,
            resourceId: null,
            areaId: null,
            patientId: null,
            includeChannel: false,
            onlyMainPlans: false,
            onlySecondaryPlans: false,
            exclusionList: [],
            count: true
        };
    }

    mapResourceForServer(resource: ResourceType): ResourceType {
        const resourceToSend = {
            ...resource,
            services: [],
            centers: [],
            coveragePlans: [],
            resourceExternalKeys: []
        } as ResourceType;

        resourceToSend.services = resource?.services?.map((service) => ({id: service.id} as ServiceType));
        resourceToSend.centers = resource?.centers?.map((center) => ({id: center.id} as CenterType));
        resourceToSend.coveragePlans = resource?.coveragePlans?.map((coveragePlan) => ({id: coveragePlan.id} as CoveragePlanType));
        resourceToSend.resourceExternalKeys = resource?.resourceExternalKeys?.map((externalKey) => ({
            id: externalKey.id,
            origin: externalKey.origin,
            key: externalKey.key,
        } as ExternalKeysType));
        resourceToSend.tags = resource?.tags;

        return resourceToSend;
    }

    getInitialTextEditorConfig(): AngularEditorConfig {
        return {
            editable: true,
            height: '20vh',
            width: 'auto',
            showToolbar: true,
            toolbarPosition: 'top',
            defaultFontName: 'Arial'
        };
    }
}
