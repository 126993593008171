<div class="container-fluid lead-time-rule-md-wrapper overview-screen-md pb-1">
    <!-- Row with rule type name, rule table filter and modal filters button -->
    <!-- ============================================================== -->
    <div class="d-flex no-block justify-content-between">
        <h3 translate="label.leadTimeRules"></h3>

        <!-- Row with rule table filter and modal filters button -->
        <div class="d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                              [buttonName]="'label.searchForRuleTable'"
                              [dependentFilters]="ruleTableDependentFilters"
                              [initialFilterValues]="rulesUtils.overviewState.ruleTable ? [rulesUtils.overviewState.ruleTable] : []"
                              [providerInstance]="ruleTableProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <div *ngIf="rulesUtils.overviewState.modalFilters">
                <sbase-show-template-modal-filter-container
                        [contentTemplate]="leadTimeRuleModalFiltersContentTemplate"
                        [areFiltersActive]="rulesUtils.overviewState.modalFilters.areFiltersActive"
                        [title]="'label.filterMore'"
                        (modalClose)="onModalClose($event)">
                </sbase-show-template-modal-filter-container>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <!-- Modal filters template -->
    <!-- ============================================================== -->
    <ng-template #leadTimeRuleModalFiltersContentTemplate>
        <div class="lead-time-rule-filter-more-modal-container">
            <!-- Channel filter -->
            <div class="rules-text-filter channelFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.channel"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedChannel($event)"
                              [buttonName]="'label.channel'"
                              [dependentFilters]="mainDependentFilters.channel"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['channelModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['channelModalFilterOptions'].ngModel] : []"
                              [providerInstance]="channelProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>
            <!-- Area filter -->
            <div class="rules-text-filter areaFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.area2"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedArea($event)"
                              [buttonName]="'label.area2'"
                              [dependentFilters]="mainDependentFilters.area"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['areaModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['areaModalFilterOptions'].ngModel] : []"
                              [providerInstance]="areaProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>
            <!-- Service filter -->
            <div class="rules-text-filter serviceFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.service2"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedService($event)"
                              [buttonName]="'label.service2'"
                              [dependentFilters]="mainDependentFilters.service"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['serviceModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['serviceModalFilterOptions'].ngModel] : []"
                              [providerInstance]="serviceProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>
            <!-- Resource filter -->
            <div class="rules-text-filter resourceFilter">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.resource"></label>
                <sbase-filter (selectedItemsOutput)="onSelectedResource($event)"
                              [buttonName]="'label.resource'"
                              [dependentFilters]="mainDependentFilters.resource"
                              [initialFilterValues]="rulesUtils.overviewState.modalFilters['resourceModalFilterOptions'].ngModel ? [rulesUtils.overviewState.modalFilters['resourceModalFilterOptions'].ngModel] : []"
                              [providerInstance]="resourceProvider"
                              [isMultipleSelectedItem]="false"
                              [useSelectedValueAsLabel]="true"
                              [hideSelectedItems]="true">
                </sbase-filter>
            </div>

            <!-- Minutes filter -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.minutesModalFilterOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.minutes"></label>
                <input type="number" class="input-element-all-sides-border rounded"
                       placeholder="{{'label.searchBy' | translate}} {{'label.minutes' | translate}}"
                       autocomplete="off" min="0" max="525000"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.minutesModalFilterOptions.ngModel">
            </div>
            <!-- Message filter -->
            <div class="rules-text-filter" *ngIf="!!rulesUtils.overviewState.modalFilters.messageModalFilterOptions">
                <label class="mb-1 mr-2 text-high-contrast" translate="label.message"></label>
                <input type="text" class="input-element-all-sides-border rounded"
                       placeholder="{{'label.searchBy' | translate}} {{'label.message' | translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.modalFilters.messageModalFilterOptions.ngModel">
            </div>
        </div>
    </ng-template>

    <!-- Row with active sorting, search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
    <!-- ============================================================== -->
    <div class="sort-search-wrapper d-flex no-block justify-content-between mt-3">
        <!-- Active soring -->
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
                <span *ngFor="let key of objectKeys(rulesUtils.overviewState.tableFilters.orderBy)">
                    <span *ngIf="key">{{'label.' + key + (['area','service'].includes(key) ? '2' : '') | translate}}</span> - {{'label.' + rulesUtils.overviewState.tableFilters.orderBy[key] |
                    translate}}
                    <span>
                        <i class="fas fa-times cursor-pointer mr-1" (click)="onClearOrderBy(key)"></i>
                    </span>
                </span>
            </span>
        </div>
        <!-- Group with search by rule name, go back button (unselect the context rule type), create rule button, and create rule set button -->
        <div class="d-flex no-block">
            <!-- Search by rule name -->
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label text-high-contrast">{{'label.search2'|translate}}:</label>
                <input type="text" class="form-control rounded-0 mr-2"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}"
                       autocomplete="off"
                       [(ngModel)]="rulesUtils.overviewState.tableFilters.filter.Name"
                       (ngModelChange)="onSearchLeadTimeRuleChanged($event)">
            </div>
            <!-- Go back button (unselect the context rule type) -->
            <button type="button"
                    (click)="goBack()"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <!-- Create rule button -->
            <button type="button" class="btn theme-alt-btn border-0 text-white float-right ml-auto"
                    [activityDisplay]="'LeadTimeRuleCreate'"
                    (click)="createRule()">
                <span translate='label.createRule'></span>
            </button>
            <!-- Create rule set button -->
            <button type="button" class="btn theme-btn border-0 float-right ml-2"
                    [activityDisplay]="'LeadTimeRuleCreate'"
                    (click)="navigateToRuleSet()">
                <span translate='label.ruleTable'></span>
            </button>
        </div>
    </div>

    <!-- Table container -->
    <!-- ============================================================== -->
    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <!-- Table headers -->
            <tr>
                <!-- These values can be set from the getRuleTableHeaderOptions method found in the utils file -->
                <ng-container *ngFor="let column of tableHeaderColumns">
                    <th *ngIf="column.hasSort" [ngClass]="column.class + ' cursor-pointer'"
                        (click)="onSortBy(column.onSortByProperty)">
                        {{column.label | translate}}
                        <button type="button" class="btn p-0"
                                *ngIf="!rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty]">
                            <i class="fas fa-sort"></i>
                        </button>
                        <button type="button" class="btn p-0"
                                *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'asc'">
                            <i class="fas fa-sort-down"></i>
                        </button>
                        <button type="button" class="btn p-0"
                                *ngIf="rulesUtils.overviewState.tableFilters.orderBy[column.onSortByProperty] === 'desc'">
                            <i class="fas fa-sort-up"></i>
                        </button>
                    </th>
                    <th *ngIf="!column.hasSort" [ngClass]="column.class" [translate]="column.label"></th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <!-- Table rows -->
            <tr *ngFor="let rule of ruleList"
                [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                sbaseMarkTableRowOnHover
                (click)="viewRule(rule)">
                <td class="name-column">{{rule.leadTimeRuleTable?.name}}</td>
                <td class="id-column">{{rule.active | yesNoPipe | translate}}</td>
                <td class="name-column">{{rule.name}}</td>
                <td class="text-center">{{rule.onlyWarning | yesNoPipe | translate}}</td>
                <td class="text-center">{{rule.channel ? ('enum.channel.' + rule.channel | translate) : ''}}</td>
                <td class="text-center">{{rule.area?.name}}</td>
                <td class="text-center">
                    <span class="font-weight-bold"> {{rule.service?.speciality?.name}} </span>
                    {{rule.service?.name}}
                </td>
                <td class="text-center">{{rule.resource?.name}}</td>
                <td class="text-center">{{rule.minutes}}</td>
                <td class="text-center">{{rule.message}}</td>
                <!-- Last column, used for action buttons -->
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button type="button" class="btn" placement="left" [ngbPopover]="popContent"
                            popoverClass="popover-shadow">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button type="button" class="btn" translate="label.view"
                                        (click)="viewRule(rule)"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.edit"
                                        (click)="editRule(rule)"
                                        [activityDisplay]="'LeadTimeRuleUpdate'"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.delete"
                                        (click)="displayConfirmDeleteItemModal(rule.id)"
                                        [activityDisplay]="'LeadTimeRuleDelete'"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(rule.id, EntityTypeEnum.leadTimeRule, rule.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Page filters -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-2" *ngIf="totalTableItems > 0">
        <ngb-pagination [collectionSize]="totalTableItems" [(page)]="rulesUtils.overviewState.tableFilters.currentPage" [maxSize]="5"
                        [pageSize]="rulesUtils.overviewState.tableFilters.itemsPerPage" [boundaryLinks]="true"
                        (pageChange)="onChangePagination($event)"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                      [ngClass]="{'show': showItemsPerPageDropdown}">
                    <strong>{{rulesUtils.overviewState.tableFilters.itemsPerPage}}</strong>
                </span>
                <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                     (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                     x-placement="top-start">
                    <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                       (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
                </div>
            </span>
        </div>
    </div>
</div>
