import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {RelationPairsMDComponent} from './relation-pairs-md.component';
import {CreateRelationPairsComponent} from './create-relation-pairs/create-relation-pairs.component';

@NgModule({
    declarations: [RelationPairsMDComponent, CreateRelationPairsComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class RelationPairsMDModule { }
