import {Component, Input, OnInit} from '@angular/core';
import {ExclusionOverviewItemType, ExclusionTypeEnum} from 'sked-base';

@Component({
    selector: 'app-view-exclusion',
    templateUrl: './view-exclusion.component.html',
    styleUrls: ['./view-exclusion.component.scss']
})
export class ViewExclusionComponent implements OnInit {
    @Input() exclusion: ExclusionOverviewItemType;

    ExclusionTypeEnum = ExclusionTypeEnum;

    constructor() {
    }

    ngOnInit(): void {

    }

}
