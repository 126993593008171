<div class="container-fluid create-rules-weighted-combination-wrapper create-entity-md-wrapper custom-scrollbar">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-rules-weighted-combination-header">
        <h3 class="create-rules-weighted-combination-title">
            {{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase" translate="label.rulesWeightedCombination"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="row create-rules-weighted-combination-section create-entity-md-section">
        <div class="col-12 p-0">
            <mat-horizontal-stepper labelPosition="bottom"
                                    class="h-100"
                                    (selectionChange)="onStepChange($event)" #stepper
                                    [linear]="true">
                <!-- General Information -->
                <mat-step [completed]="false" *ngIf="screenTemplateLayout.action !== constants.VIEW"
                          [stepControl]="getStepControl(0)">
                    <ng-template matStepLabel><span translate="label.generalInformation"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.areaWeight"></label>
                                    <input type="number" name="areaWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.area"
                                           [ngClass]="{'input-element-has-error': !area.checkValidity() || !area.value}"
                                           placeholder="{{'label.areaWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Area)"
                                           #area>
                                    <div *ngIf="!area.checkValidity() || !area.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.specialityWeight"></label>
                                    <input type="number" name="specialityWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.speciality"
                                           [ngClass]="{'input-element-has-error': !speciality.checkValidity() || !speciality.value}"
                                           placeholder="{{'label.specialityWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Speciality)"
                                           #speciality>
                                    <div *ngIf="!speciality.checkValidity() || !speciality.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.serviceWeight"></label>
                                    <input type="number" name="serviceWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.service"
                                           [ngClass]="{'input-element-has-error': !service.checkValidity() || !service.value}"
                                           placeholder="{{'label.serviceWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Service)"
                                           #service>
                                    <div *ngIf="!service.checkValidity() || !service.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.resourceWeight"></label>
                                    <input type="number" name="resourceWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.resource"
                                           [ngClass]="{'input-element-has-error': !resource.checkValidity() || !resource.value}"
                                           placeholder="{{'label.resourceWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Resource)"
                                           #resource>
                                    <div *ngIf="!resource.checkValidity() || !resource.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.centerWeight"></label>
                                    <input type="number" name="centerWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.center"
                                           [ngClass]="{'input-element-has-error': !center.checkValidity() || !center.value}"
                                           placeholder="{{'label.centerWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Center)"
                                           #center>
                                    <div *ngIf="!center.checkValidity() || !center.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.coveragePlanWeight"></label>
                                    <input type="number" name="coveragePlanWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.coveragePlan"
                                           [ngClass]="{'input-element-has-error': !coveragePlan.checkValidity() || !coveragePlan.value}"
                                           placeholder="{{'label.coveragePlanWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.CoveragePlan)"
                                           #coveragePlan>
                                    <div *ngIf="!coveragePlan.checkValidity() || !coveragePlan.value"
                                         class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.channelWeight"></label>
                                    <input type="number" name="channelWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.channel"
                                           [ngClass]="{'input-element-has-error': !channel.checkValidity() || !channel.value}"
                                           placeholder="{{'label.channelWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.Channel)"
                                           #channel>
                                    <div *ngIf="!channel.checkValidity() || !channel.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === constants.VIEW}">
                                    <label translate="label.patientTagWeight"></label>
                                    <input type="number" name="patientTagWeight" class="input-element"
                                           [(ngModel)]="rulesWeightedCombinationItem.patientTag"
                                           [ngClass]="{'input-element-has-error': !patientTag.checkValidity() || !patientTag.value}"
                                           placeholder="{{'label.patientTagWeight'|translate}}"
                                           autocomplete="off"
                                           min="0"
                                           max="255"
                                           (ngModelChange)="setValidTemplateKey(validTemplateRuleManagementWeightedCombinationEnum.PatientTag)"
                                           #patientTag>
                                    <div *ngIf="!patientTag.checkValidity() || !patientTag.value" class="help-block">
                                        <span>{{"label.error.invalidNumberForInterval" | translate: '{min: 0, max:255}' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!-- Overview-->
                <mat-step [completed]="false">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form mt-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.generalInformation"></label>
                                    <table class="table border">
                                        <tbody>
                                        <tr>
                                            <td translate="label.areaWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.area}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.specialityWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.speciality}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.serviceWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.service}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.resourceWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.resource}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.centerWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.center}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.coveragePlanWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.coveragePlan}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.channelWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.channel}}</td>
                                        </tr>
                                        <tr>
                                            <td translate="label.patientTagWeight"></td>
                                            <td class="text-right">{{rulesWeightedCombinationItem.patientTag}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>

    <!--Footer-->
    <!-- =========================================== -->
    <div class="mt-2 footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3"
                    type="button"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button (click)="goBack(stepper)" type="button"
                    [disabled]="stepper.selectedIndex === 0"
                    *ngIf="screenTemplateLayout.action !== constants.VIEW"
                    class="btn back-btn mr-3"
                    translate="button.back">
            </button>
            <button (click)="goForward(stepper)" type="button"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    *ngIf="(stepper.selectedIndex < totalStepsCount-1)"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRulesWeightedCombinationData(rulesWeightedCombinationItem)"
                    [disabled]="screenTemplateLayout.action === constants.CREATE"
                    *ngIf="stepper.selectedIndex === totalStepsCount-1 && screenTemplateLayout.action!==constants.VIEW"
                    class="btn theme-btn"
                    type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'RuleWeightCombinationUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
