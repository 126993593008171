<div class="container-fluid create-payment-management-rule-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-payment-management-rule-header">
        <h3 class="create-payment-management-rule-title">
            {{screenTemplateLayout.pageTitle|translate}}
            <span class="text-lowercase" translate="label.paymentManagementRule"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <!--Body-->
    <!-- =========================================== -->
    <div class="create-entity-md-section">
        <div class="col-12 p-0"
             [ngClass]="{'is-view-mode':screenTemplateLayout.action === CONSTANTS.VIEW}">
            <mat-horizontal-stepper labelPosition="bottom"
                                    #stepper
                                    [linear]="true"
                                    (selectionChange)="onStepChange($event)">
                <!--RULE DETAILS-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(0)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.ruleDetails"></span></ng-template>
                    <div class="step-form">
                        <!--Rule set-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group customSelect sbase-input-element">
                                    <label translate="label.ruleTable"></label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedRuleTable($event)"
                                                  [buttonName]='"label.ruleTable"'
                                                  [dependentFilters]="ruleTableDependentFilters"
                                                  [initialFilterValues]="[ruleItem.paymentManagementRuleTable]"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [ngClass]="{'input-element-has-error': !ruleItem?.paymentManagementRuleTable?.id}"
                                                  [providerInstance]="ruleTableProvider">
                                    </sbase-filter>
                                    <div *ngIf="!ruleItem?.paymentManagementRuleTable?.id"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.paymentManagementRuleTable?.id}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule name-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.name"></label>
                                    <input type="text" name="name" class="input-element"
                                           [(ngModel)]="ruleItem.name"
                                           [ngClass]="{'input-element-has-error': !ruleItem.name}"
                                           placeholder="{{'label.name'|translate}}"
                                           autocomplete="off">
                                    <div *ngIf="!ruleItem.name" class="help-block">
                                        <span translate="label.error.required"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Rule is active-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group"
                                     [ngClass]="{'disabled-input': screenTemplateLayout.action === CONSTANTS.VIEW}">
                                    <label translate="label.active"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.active"
                                                   name="active">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--INPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(1)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.input"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Service tag -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect sbase-input-element">
                                            <label class="mb-1" translate="label.serviceTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.SERVICE, $event)"
                                                          [buttonName]='"label.newTag"'
                                                          [dependentFilters]="mainDependentFilters?.serviceTags"
                                                          [initialFilterValues]="paymentManagementRuleTags.selectedServiceTags"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [ngClass]="{'input-element-has-error': !ruleItem?.serviceTagId}"
                                                          [providerInstance]="tagProvider">
                                            </sbase-filter>
                                            <div *ngIf="!ruleItem?.serviceTagId"
                                                 class="help-block">
                                                    <span [ngClass]="{'input-element-has-error': !ruleItem?.serviceTagId}"
                                                          translate="label.error.required">
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.serviceTagId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle" ngbTooltip="{{'label.exclude'| translate}}" placement="bottom"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="paymentManagementRuleTags.includeServiceTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.SERVICE, paymentManagementRuleTags.includeServiceTagFlag)"
                                                           name="includeServiceTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle" ngbTooltip="{{'label.include'| translate}}" placement="bottom"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Center tag -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label class="mb-1" translate="label.centerTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.CENTER, $event)"
                                                          [buttonName]='"label.newTag"'
                                                          [dependentFilters]="mainDependentFilters?.centerTags"
                                                          [initialFilterValues]="paymentManagementRuleTags.selectedCenterTags"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [providerInstance]="tagProvider">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.centerTagId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="paymentManagementRuleTags.includeCenterTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.CENTER, paymentManagementRuleTags.includeCenterTagFlag)"
                                                           name="includeCenterTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Resource tag -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group customSelect">
                                            <label class="mb-1" translate="label.resourceTag"></label>
                                            <sbase-filter (selectedItemsOutput)="onSelectedTag(CONSTANTS.RESOURCE, $event)"
                                                          [buttonName]='"label.newTag"'
                                                          [dependentFilters]="mainDependentFilters?.resourceTags"
                                                          [initialFilterValues]="paymentManagementRuleTags.selectedResourceTags"
                                                          [isMultipleSelectedItem]="false"
                                                          [useSelectedValueAsLabel]="true"
                                                          [hideSelectedItems]="true"
                                                          [providerInstance]="tagProvider">
                                            </sbase-filter>
                                        </div>
                                    </div>
                                    <div class="col-4 mt-4">
                                        <div class="form-group" *ngIf="ruleItem.resourceTagId">
                                            <div class="switch">
                                                <label>
                                                    <i class="fas fa-minus-circle h6" ngbTooltip="{{'label.exclude'| translate}}"></i>
                                                    <input type="checkbox"
                                                           [(ngModel)]="paymentManagementRuleTags.includeResourceTagFlag"
                                                           (ngModelChange)="onChangeIncludeFlag(CONSTANTS.RESOURCE, paymentManagementRuleTags.includeResourceTagFlag)"
                                                           name="includeResourceTag">
                                                    <span class="lever switch-col-black"></span>
                                                    <i class="fas fa-plus-circle h6" ngbTooltip="{{'label.include'| translate}}"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Coverage plan -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label class="mb-1" translate="label.coveragePlan2">
                                    </label>
                                    <sbase-filter (selectedItemsOutput)="onSelectedCoveragePlan($event)"
                                                  [buttonName]='"label.coveragePlan2"'
                                                  [dependentFilters]="mainDependentFilters?.coveragePlan"
                                                  [initialFilterValues]="initialCoveragePlan"
                                                  [isMultipleSelectedItem]="false"
                                                  [useSelectedValueAsLabel]="true"
                                                  [hideSelectedItems]="true"
                                                  [providerInstance]="coveragePlanProvider"
                                                  class="sbase-dropdown-ml">
                                    </sbase-filter>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OUTPUT-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(2)"
                          *ngIf="screenTemplateLayout.action !== CONSTANTS.VIEW">
                    <ng-template matStepLabel><span translate="label.output"></span></ng-template>
                    <div class="step-form">
                        <div class="row">
                            <!-- Activate payment -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group customSelect">
                                    <label class="mb-1 mr-2" translate="label.activatePayment"></label>
                                    <ng-select class="pb-0 custom-ng-select input-element"
                                               [ngClass]="!!ruleItem?.activatePayment ? 'ng-select-has-value' : 'input-element-has-error'"
                                               dropdownPosition="bottom"
                                               placeholder="{{'label.choose' | translate}}"
                                               [multiple]="false"
                                               [searchable]="false"
                                               [clearable]="false"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="ruleItem.activatePayment"
                                               (ngModelChange)="sanitizeNgSelectValue('activatePayment')">
                                        <ng-option [value]="undefined">
                                            <span translate="label.choose"></span>
                                        </ng-option>
                                        <ng-option *ngFor="let item of activatePaymentEnumList"
                                                   [value]="item">
                                            <span [translate]="'enum.activatePayment.' + item"></span>
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="!ruleItem?.activatePayment"
                                         class="help-block">
                                        <span [ngClass]="{'input-element-has-error': !ruleItem?.activatePayment}"
                                              translate="label.error.required">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Show price -->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.showPrice"></label>
                                    <div class="switch">
                                        <label>
                                            <span translate="label.no"></span>
                                            <input type="checkbox" checked
                                                   [(ngModel)]="ruleItem.showPrice"
                                                   name="showPrice">
                                            <span class="lever switch-col-black"></span>
                                            <span translate="label.yes"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <!--OVERVIEW-->
                <!-- =========================================== -->
                <mat-step [completed]="false" [stepControl]="getStepControl(3)">
                    <ng-template matStepLabel><span translate="label.overview"></span></ng-template>
                    <div class="step-form">
                        <!--Rule details-->
                        <!-- =========================================== -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate="label.ruleDetails"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.ruleTable"></td>
                                                <td class="text-right">{{ruleItem.paymentManagementRuleTable?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.name"></td>
                                                <td class="text-right">{{ruleItem.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.active"></td>
                                                <td class="text-right">{{ruleItem.active | yesNoPipe | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--Rule inputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.input"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.serviceTag"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="rulesUtils.displayIncludeIcon(ruleItem.serviceTag, ruleItem.operationServiceTag)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="rulesUtils.displayExcludeIcon(ruleItem.serviceTag, ruleItem.operationServiceTag)"></i>
                                                    &nbsp;{{ruleItem.serviceTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.centerTag"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="rulesUtils.displayIncludeIcon(ruleItem.centerTag, ruleItem.operationCenterTag)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="rulesUtils.displayExcludeIcon(ruleItem.centerTag, ruleItem.operationCenterTag)"></i>
                                                    &nbsp;{{ruleItem.centerTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.resourceTag"></td>
                                                <td class="text-right">
                                                    <i class="fas fa-plus-circle"
                                                       ngbTooltip="{{'label.include'| translate}}"
                                                       *ngIf="rulesUtils.displayIncludeIcon(ruleItem.resourceTag, ruleItem.operationResourceTag)"></i>
                                                    <i class="fas fa-minus-circle"
                                                       ngbTooltip="{{'label.exclude'| translate}}"
                                                       *ngIf="rulesUtils.displayExcludeIcon(ruleItem.resourceTag, ruleItem.operationResourceTag)"></i>
                                                    &nbsp;{{ruleItem.resourceTag?.name}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.coveragePlan2"></td>
                                                <td class="text-right">
                                                    {{ruleItem.coveragePlanId ? ruleItem.coveragePlan?.name : ''}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--Rule outputs-->
                            <!-- =========================================== -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label translate="label.output"></label>
                                    <table class="table border">
                                        <tbody>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.activatePayment"></td>
                                                <td class="text-right"
                                                    *ngIf="!generalUtils.isSelectedNoValueOption(ruleItem.activatePayment)">
                                                    {{'enum.activatePayment.' + ruleItem.activatePayment |
                                                    translate}}</td>
                                                <td class="text-right"
                                                    *ngIf="generalUtils.isSelectedNoValueOption(ruleItem.activatePayment)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold" translate="label.showPrice"></td>
                                                <td class="text-right">{{ruleItem.showPrice | yesNoPipe | translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <!--End Body-->
    <!-- =========================================== -->

    <!--Footer-->
    <!-- =========================================== -->
    <div class="create-entity-footer footer-position-sticky">
        <div class="justify-content-end">
            <button class="btn mr-3" (click)="rulesUtils.goToParentPage('createRule')"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button type="button"
                    (click)="rulesUtils.stepperGoBack(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW"
                    class="btn back-btn mr-3" translate="button.back">
            </button>
            <button (click)="rulesUtils.stepperGoForward(stepper)"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && rulesUtils.shouldDisplayNext(stepper, totalStepsCount)"
                    [disabled]="!areStepsValid(stepper.selectedIndex)"
                    type="button"
                    class="btn theme-btn">
                <span translate="button.next"></span>
            </button>
            <button (click)="saveRule(ruleItem)" type="button"
                    *ngIf="screenTemplateLayout.action!==CONSTANTS.VIEW && stepper.selectedIndex === totalStepsCount-1"
                    class="btn theme-btn">
                <span>{{screenTemplateLayout.createOrSaveButton | translate}}</span>
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===CONSTANTS.VIEW"
                    [activityDisplay]="'PaymentManagementRuleUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
    </div>
    <!--End Footer-->
    <!-- =========================================== -->
</div>
