<div class="slots-local-filters-wrapper">
    <div class="form-group mb-3">
        <div class="d-flex no-block justify-content-between">
            <label class="checkbox d-inline-flex align-items-center ml-3 slots-local-filters-self-payer-element" *ngIf="displaySelfPayer">
                <input type="checkbox" name="self-payer"
                       [(ngModel)]="multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selfPayer"
                       (ngModelChange)="onLocalFiltersChanged(multiAppointmentBookingLocalFilterTypeEnum.IncludeSelfPayer)"/>
                <span class="ml-2 text-high-contrast" translate="label.includeSelfPayer"></span>
                <span class="ml-2 slots-local-filters-self-payer-popover text-high-contrast"
                      translate="label.includeSelfPayer"></span>
            </label>
            <span class="d-flex align-items-center mr-2">
                <label class="checkbox d-inline-flex align-items-center mr-3 slots-local-filters-am-element">
                    <input type="checkbox" name="am"
                           [(ngModel)]="multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.am"
                           (ngModelChange)="onLocalFiltersChanged(multiAppointmentBookingLocalFilterTypeEnum.AM)"/>
                    <span class="ml-2 text-high-contrast" translate="label.am"></span>
                    <span class="ml-2 slots-local-filters-am-popover text-high-contrast" translate="label.am"></span>
                </label>
                <label class="checkbox d-inline-flex align-items-center slots-local-filters-pm-element">
                    <input type="checkbox" name="pm"
                           [(ngModel)]="multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.pm"
                           (ngModelChange)="onLocalFiltersChanged(multiAppointmentBookingLocalFilterTypeEnum.PM)"/>
                    <span class="ml-2 text-high-contrast" translate="label.pm"></span>
                    <span class="ml-2 slots-local-filters-pm-popover text-high-contrast" translate="label.pm"></span>
                </label>
            </span>
        </div>
        <div class="row">
            <div class="col-9">
                <div class="form-group ml-3 mt-2 mb-0">
                    <ng-select class="form-control pl-0 rounded-0 p-0 b-0 custom-ng-select"
                               dropdownPosition="bottom"
                               placeholder="{{'label.choose' | translate}}"
                               [multiple]="false"
                               [searchable]="false"
                               [clearable]="false"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.selectedAppointmentType"
                               (ngModelChange)="onLocalFiltersChanged(multiAppointmentBookingLocalFilterTypeEnum.AppointmentType)">
                        <ng-option [value]="undefined"><span translate="label.selectAppointmentType"></span></ng-option>
                        <ng-option *ngFor="let appointmentType of multiAppointmentBookingMdUtils.slotsLocalFiltersOptions.appointmentTypes"
                                   [value]="appointmentType">
                            {{appointmentType.name}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</div>
