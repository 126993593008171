import {Injectable} from '@angular/core';
import {WaitListModalComponent} from '../../component/wait-list-modal/wait-list-modal.component';
import {GeneralUtils} from '../../utils/general.utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EntityTypeEnum, PatientSearchActionsCallbackType, PatientSearchActionsEnum, SlotsFiltersSelectValueType} from 'sked-base';
import {Router} from '@angular/router';
import {DisplayChangeLogModalService} from '../../component/display-change-log-modal/display-change-log-modal.service';

@Injectable({
    providedIn: 'root'
})
export class PatientActionsService {
    patientId: string;
    slotsFilters: SlotsFiltersSelectValueType[];

    constructor(private generalUtils: GeneralUtils,
                private modalService: NgbModal,
                private changeLogModalService: DisplayChangeLogModalService,
                private router: Router) {
    }

    onAction(actionCallback: PatientSearchActionsCallbackType) {
        switch (actionCallback.action) {
            case PatientSearchActionsEnum.waitList: {
                this.openWaitListModal();
                return;
            }
            case PatientSearchActionsEnum.ActivityPlans: {
                this.router.navigate(['activityPlans']);
                return;
            }
            case PatientSearchActionsEnum.CreatePatient: {
                this.router.navigate(['createPatient'], {state: {newPatientSearchPhrase: actionCallback.searchPhrase}});
                return;
            }
            case PatientSearchActionsEnum.PatientMerge: {
                this.router.navigate(['patientMerge'], {state: {patientId: actionCallback.patientId}});
                return;
            }
            case PatientSearchActionsEnum.AddAppointment: {
                this.router.navigate(['slotsManagement']);
                return;
            }
            case PatientSearchActionsEnum.AddMultiAppointment: {
                this.router.navigate(['multiAppointmentBooking']);
                return;
            }
            case PatientSearchActionsEnum.DisplayChangelog: {
                this.changeLogModalService.displayModal(actionCallback.patientId, EntityTypeEnum.patient, actionCallback.patientName);
                return;
            }
        }
    }

    private openWaitListModal() {
        // Open modal
        const modalOptions = this.generalUtils.getModalOptions();
        const modalRef = this.modalService.open(WaitListModalComponent, modalOptions);

        // Wait for response
        modalRef.result.then(() => {
            // On close, do nothing
        }, () => {
            // On dismiss, do nothing
        });
    }
}
