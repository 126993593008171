<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav" class="in" *ngIf="showSideBarContent">
                <!-- patientManagement -->
                <li (mouseover)="toggleSidebarSubMenuPosition(patientManagement)" #patientManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandPatientManagement"
                       (click)="changeExpandedMenuValue('expandPatientManagement')"
                       [featureDisplay]="['backoffice-new-patients', 'patient-documents']"
                       [activityDisplay]="['PatientManagement', 'PatientRead', 'PatientDocumentRead', 'PatientMerge']"
                    >
                        <i class="fas fa-user-cog float-left"></i>
                        <span class="hide-menu d-block" translate="label.patientManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandPatientManagement ? 'collapse' : ''">
                        <!-- patient dashboard -->
                        <li>
                            <a [routerLink]="['patientDashboard']"
                               [featureDisplay]="'backoffice-new-patients'"
                               [activityDisplay]="'PatientRead'"
                               [ngClass]="{'active': patientDashboard.isActive }">
                                <span class="hide-menu" translate="label.patientDashboard"></span>
                            </a>
                            <a [routerLink]="['patientDashboard']" routerLinkActive #patientDashboard="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <!-- patient appointment list -->
                        <li>
                            <a [routerLink]="['patientAppointmentList']"
                               [featureDisplay]="'backoffice-new-patients'"
                               [activityDisplay]="'PatientRead'"
                               [ngClass]="{'active': patientAppointmentList.isActive }">
                                <span class="hide-menu" translate="label.patientAppointmentList"></span>
                            </a>
                            <a [routerLink]="['patientAppointmentList']" routerLinkActive
                               #patientAppointmentList="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <!-- patient documents -->
                        <li>
                            <a [routerLink]="['patientDocuments']" routerLinkActive="active"
                               [featureDisplay]="'patient-documents'"
                               [activityDisplay]="['PatientDocumentRead']"
                               [ngClass]="{'active': patientDocuments.isActive }">
                                <span class="hide-menu" translate="label.patientDocuments"></span>
                            </a>
                            <a [routerLink]="['patientDocuments']" routerLinkActive #patientDocuments="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <!-- patient audit -->
                        <li>
                            <a [routerLink]="['patientAudit']" routerLinkActive="active"
                               [featureDisplay]="'backoffice-new-patients'"
                               [activityDisplay]="['PatientAudit']"
                               [ngClass]="{'active': patientAudit.isActive }">
                                <span class="hide-menu" translate="label.patientAudit"></span>
                            </a>
                            <a [routerLink]="['patientAudit']" routerLinkActive #patientAudit="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <!-- patient merge -->
                        <li>
                            <a [routerLink]="['patientMerge']" routerLinkActive="active"
                               [featureDisplay]="'backoffice-new-patients'"
                               [activityDisplay]="['PatientMerge']"
                               [ngClass]="{'active': patientMerge.isActive }">
                                <span class="hide-menu" translate="label.patientMerge"></span>
                            </a>
                            <a [routerLink]="['patientMerge']" routerLinkActive #patientMerge="routerLinkActive"
                               class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!--booking-->
                <li>
                    <a [routerLink]="['slotsManagement']" routerLinkActive="active"
                       [featureDisplay]="'appointment-booking'"
                       [activityDisplay]="'AppointmentBooking'">
                        <i class="far fa-calendar-alt float-left"></i>
                        <span class="hide-menu d-block" translate="label.booking"></span>
                    </a>
                </li>
                <!--Multi appointment booking-->
                <li>
                    <a [routerLink]="['multiAppointmentBooking']" routerLinkActive="active"
                       [featureDisplay]="'multi-appointment-booking'"
                       [activityDisplay]="'MultiAppointmentBooking'">
                        <i class="far fa-calendar-alt float-left"></i>
                        <span class="hide-menu d-block" translate="label.multiAppointmentBooking"></span>
                    </a>
                </li>
                <!--resourceCalendar-->
                <li>
                    <a [routerLink]="['resourceCalendar']" routerLinkActive="active"
                       [featureDisplay]="'resourceCalendarNew'"
                       [activityDisplay]="['ResourceCalendarNew']">
                        <i class="far fa-calendar-plus float-left"></i>
                        <span class="hide-menu d-block" translate="label.resourceCalendar"></span>
                    </a>
                </li>
                <!-- Graphical resource planner -->
                <li>
                    <a [routerLink]="['graphicalResourcePlanner']" routerLinkActive="active"
                       [featureDisplay]="'advancedbookingplanner'"
                       [activityDisplay]="'AdvancedBookingPlanner'">
                        <i class="fas fa-project-diagram float-left"></i>
                        <span class="hide-menu d-block"
                              translate="label.graphicalResourcePlanner"></span>
                    </a>
                </li>
                <!-- Resource Utilization-->
                <li>
                    <a [routerLink]="['resourceUtilization']" routerLinkActive="active"
                       [featureDisplay]="'resource-utilization'"
                       [activityDisplay]="'ResourceUtilization'">
                        <i class="fas fa-user-friends float-left"></i>
                        <span class="hide-menu d-block"
                              translate="label.resourceUtilization"></span>
                    </a>
                </li>
                <!-- Appointment list -->
                <li>
                    <a [routerLink]="['appointmentList']"
                       [ngClass]="{'active': appointmentList.isActive}"
                       [featureDisplay]="'appointment-list'"
                       [activityDisplay]="['AppointmentList']">
                        <i class="fa-solid fa-calendar-lines-pen float-left"></i>
                        <span class="hide-menu d-block" translate="label.appointments"></span>
                    </a>
                    <a [routerLink]="['appointmentList']" routerLinkActive #appointmentList="routerLinkActive"
                       class="d-none"></a>
                </li>
                <!-- Swap resources -->
                <li>
                    <a [routerLink]="['resourceSwap']"
                       [ngClass]="{'active': resourceSwap.isActive }"
                       [featureDisplay]="'resource-swap'"
                       [activityDisplay]="'ResourceSwap'">
                        <i class="fa-regular fa-people-arrows"></i>
                        <span class="hide-menu d-block" translate="label.swapResources"></span>
                    </a>
                    <a [routerLink]="['resourceSwap']" routerLinkActive #resourceSwap="routerLinkActive"
                       class="d-none"></a>
                </li>
                <!-- Task list -->
                <li>
                    <a [routerLink]="['taskList']"
                       [ngClass]="{'active': taskList.isActive || taskDetails.isActive }"
                       [featureDisplay]="'sked-tasks'"
                       [activityDisplay]="['SkedTaskRead']">
                        <i class="fas fa-list-ul float-left"></i>
                        <span class="hide-menu d-block" translate="label.taskList"></span>
                    </a>
                    <a [routerLink]="['taskList']" routerLinkActive #taskList="routerLinkActive"
                       class="d-none"></a>
                    <a [routerLink]="['taskDetails']" routerLinkActive #taskDetails="routerLinkActive"
                       class="d-none"></a>
                </li>
                <!--Export View-->
                <li>
                    <a [routerLink]="['export']" routerLinkActive="active"
                       [featureDisplay]="'admin-export-view'"
                       [activityDisplay]="'GetJobResult'">
                        <i class="fas fa-file-export float-left"></i>
                        <span class="hide-menu d-block" translate="label.exportView"></span>
                    </a>
                </li>
                <!--Excel Import-->
                <li>
                    <a [routerLink]="['excelImport']" routerLinkActive="active"
                       [featureDisplay]="'admin-excelDataImporter'"
                       [activityDisplay]="'UseImportFeatureAllowed'">
                        <i class="fas fa-solid fa-file-import float-left"></i>
                        <span class="hide-menu d-block" translate="label.excelImporter"></span>
                    </a>
                </li>
                <span class="side-bar-horizontal-line">&nbsp;</span>
                <!--availabilityManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(availabilityManagement)" #availabilityManagement>
                    <!-- All the features for availability and exclusion will be displayed according
                    admin-availabilities feature-access (only)-->
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandAvailabilityManagement"
                       (click)="changeExpandedMenuValue('expandAvailabilityManagement')"
                       [featureDisplay]="['admin-availabilities','admin-capacityOverview-resource','admin-exclusions-exclusionReasonManagement','admin-availability-export']"
                       [activityDisplay]="['AvailabilityRead','ExclusionRead','ExclusionApprove','AvailabilityApprove','GetPlannedCapacity','ExclusionReasonRead','AvailabilityExport','ExclusionExport']">
                        <i class="fas fa-business-time float-left"></i>
                        <span class="hide-menu d-block" translate="label.availabilityManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandAvailabilityManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['availability']"
                               [featureDisplay]="'admin-availabilities'"
                               [activityDisplay]="'AvailabilityRead'"
                               [ngClass]="{'active': availability.isActive || createAvailability.isActive || availabilityHistory.isActive || createAvailabilitySplit.isActive || availabilityBlockedAppointmentsPreview.isActive}">
                                <span class="hide-menu" translate="label.availabilities"></span>
                            </a>
                            <a [routerLink]="['availability']" routerLinkActive #availability="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createAvailability']" routerLinkActive
                               #createAvailability="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['availabilityHistory']" routerLinkActive
                               #availabilityHistory="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createAvailabilitySplit']" routerLinkActive
                               #createAvailabilitySplit="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['availabilityBlockedAppointmentsPreview']" routerLinkActive
                               #availabilityBlockedAppointmentsPreview="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['approveAvailability']"
                               [featureDisplay]="'admin-availabilities'"
                               [activityDisplay]="'AvailabilityApprove'"
                               [ngClass]="{'active': approveAvailability.isActive || viewApproveAvailability.isActive}">
                                <span class="hide-menu d-block" translate="label.approveAvailabilities"></span>
                            </a>
                            <a [routerLink]="['approveAvailability']" routerLinkActive
                               #approveAvailability="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['viewApproveAvailability']" routerLinkActive
                               #viewApproveAvailability="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['exclusion']"
                               [featureDisplay]="'admin-availabilities'"
                               [activityDisplay]="'ExclusionRead'"
                               [ngClass]="{'active': exclusion.isActive || createExclusion.isActive || exclusionHistory.isActive || exclusionBlockedAppointmentsPreview.isActive}">
                                <span class="hide-menu" translate="label.exclusions"></span>
                            </a>
                            <a [routerLink]="['exclusion']" routerLinkActive #exclusion="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createExclusion']" routerLinkActive
                               #createExclusion="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['exclusionHistory']" routerLinkActive
                               #exclusionHistory="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['exclusionBlockedAppointmentsPreview']" routerLinkActive
                               #exclusionBlockedAppointmentsPreview="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['approveExclusion']"
                               [featureDisplay]="'admin-availabilities'"
                               [activityDisplay]="'ExclusionApprove'"
                               [ngClass]="{'active': approveExclusion.isActive || viewApproveExclusion.isActive}">
                                <span class="hide-menu d-block" translate="label.approveExclusions"></span>
                            </a>
                            <a [routerLink]="['approveExclusion']" routerLinkActive
                               #approveExclusion="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['viewApproveExclusion']" routerLinkActive
                               #viewApproveExclusion="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['exclusionReasons']"
                               [featureDisplay]="'admin-exclusions-exclusionReasonManagement'"
                               [activityDisplay]="'ExclusionReasonRead'"
                               [ngClass]="{'active': exclusionReasons.isActive || createExclusionReason.isActive}">
                                <span class="hide-menu d-block"
                                      translate="label.exclusionReasonTab.exclusionReasonsHeader"></span>
                            </a>
                            <a [routerLink]="['exclusionReasons']" routerLinkActive #exclusionReasons="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createExclusionReason']" routerLinkActive
                               #createExclusionReason="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['capacityPlannerResource']" routerLinkActive="active"
                               [featureDisplay]="'admin-capacityOverview-resource'"
                               [activityDisplay]="'GetPlannedCapacity'"
                               [ngClass]="{inactive: isRoomCapacitySelected}"
                               (click)="showResourceOrRoom(resourceVal)">
                                <span class="hide-menu d-block" translate="label.resourcesCapacityOverview"></span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['availabilityExclusionExport']"
                               [featureDisplay]="'admin-availability-export'"
                               [activityDisplay]="['AvailabilityExport', 'ExclusionExport']"
                               [ngClass]="{'active': availabilityExclusionExport.isActive}">
                                <span class="hide-menu" translate="label.availabilityExclusionExportTitle"></span>
                            </a>
                            <a [routerLink]="['availabilityExclusionExport']" routerLinkActive
                               #availabilityExclusionExport="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!--masterDataManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(masterDataManagement)" #masterDataManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandMasterDataManagement"
                       (click)="changeExpandedMenuValue('expandMasterDataManagement')"
                       [featureDisplay]="['admin-new-tag', 'admin-new-coverageCompanies', 'admin-new-coveragePlans',
                            'admin-new-centers', 'admin-new-activityPlanTemplates', 'booking-filters', 'admin-relationPair', 'admin-object-details']"
                       [activityDisplay]="['TagRead', 'CoverageCompanyRead', 'CoveragePlanRead', 'CenterRead',
                            'ActivityPlanTemplateRead', 'BookingFilterRead', 'RelationPairRead', 'ObjectDetailRead']">
                        <i class="fas fa-laptop-medical float-left"></i>
                        <span class="hide-menu d-block" translate="label.masterDataManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandMasterDataManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['centers']"
                               [featureDisplay]="'admin-new-centers'"
                               [activityDisplay]="'CenterRead'"
                               [ngClass]="{'active': centers.isActive || createCenter.isActive }">
                                <span class="hide-menu d-block" translate="label.centers"></span>
                            </a>
                            <a [routerLink]="['centers']" routerLinkActive #centers="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createCenter']" routerLinkActive #createCenter="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['tags']"
                               [featureDisplay]="'admin-new-tag'"
                               [activityDisplay]="'TagRead'"
                               [ngClass]="{'active': tags.isActive || createTag.isActive }">
                                <span class="hide-menu d-block" translate="label.tags"></span>
                            </a>
                            <a [routerLink]="['tags']" routerLinkActive #tags="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createTag']" routerLinkActive #createTag="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['coveragePlans']"
                               [featureDisplay]="'admin-new-coveragePlans'"
                               [activityDisplay]="'CoveragePlanRead'"
                               [ngClass]="{'active': coveragePlans.isActive || createCoveragePlan.isActive }">
                                <span class="hide-menu d-block" translate="label.coveragePlans"></span>
                            </a>
                            <a [routerLink]="['coveragePlans']" routerLinkActive #coveragePlans="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createCoveragePlan']" routerLinkActive
                               #createCoveragePlan="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['coverageCompanies']"
                               [featureDisplay]="'admin-new-coverageCompanies'"
                               [activityDisplay]="'CoverageCompanyRead'"
                               [ngClass]="{'active': coverageCompanies.isActive || createCoverageCompany.isActive }">
                                <span class="hide-menu d-block" translate="label.coverageCompanies"></span>
                            </a>
                            <a [routerLink]="['coverageCompanies']" routerLinkActive
                               #coverageCompanies="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createCoverageCompany']" routerLinkActive
                               #createCoverageCompany="routerLinkActive" class="d-none"></a>
                        </li>

                        <li>
                            <a [routerLink]="['activityPlanTemplates']"
                               [featureDisplay]="'admin-new-activityPlanTemplates'"
                               [activityDisplay]="'ActivityPlanTemplateRead'"
                               [ngClass]="{'active': activityPlanTemplates.isActive || createActivityPlanTemplates.isActive}"
                            >
                                <span class="hide-menu" translate="label.activityPlanTemplate"></span>
                            </a>
                            <a [routerLink]="['activityPlanTemplates']" routerLinkActive
                               #activityPlanTemplates="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createActivityPlanTemplate']" routerLinkActive
                               #createActivityPlanTemplates="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['relationPairs']"
                               [featureDisplay]="'admin-relationPair'"
                               [activityDisplay]="'RelationPairRead'"
                               [ngClass]="{'active': relationPairs.isActive || createRelationPair.isActive}">
                                <span class="hide-menu d-block" translate="label.relationPairs"></span>
                            </a>
                            <a [routerLink]="['relationPairs']" routerLinkActive
                               #relationPairs="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createRelationPair']" routerLinkActive
                               #createRelationPair="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['objectDetails']"
                               [featureDisplay]="'admin-object-details'"
                               [activityDisplay]="'ObjectDetailRead'"
                               [ngClass]="{'active': objectDetails.isActive || createObjectDetail.isActive }">
                                <span class="hide-menu" translate="label.objectDetails"></span>
                            </a>
                            <a [routerLink]="['objectDetails']" routerLinkActive #objectDetails="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createObjectDetail']" routerLinkActive
                               #createObjectDetail="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!--appointmentTypeManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(appointmentTypeManagement)" #appointmentTypeManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandAppointmentTypeManagement"
                       (click)="changeExpandedMenuValue('expandAppointmentTypeManagement')"
                       [featureDisplay]="['admin-new-appointmentType', 'admin-new-appointmentTypeDefinition', 'admin-multiAppointments-template', 'admin-new-appointmentStatusTransitionReasons']"
                       [activityDisplay]="['AppointmentTypeRead', 'AppointmentTypeDefinitionRead', 'MultiAppointmentTemplateRead', 'AppointmentStatusTransitionReasonRead']">
                        <i class="fas fa-calendar-alt float-left"></i>
                        <span class="hide-menu d-block" translate="label.appointmentTypeManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandAppointmentTypeManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['appointmentTypeDefinitions']"
                               [featureDisplay]="'admin-new-appointmentTypeDefinition'"
                               [activityDisplay]="'AppointmentTypeDefinitionRead'"
                               [ngClass]="{'active': appointmentTypeDefinitions.isActive || createAppointmentTypeDefinition.isActive }">
                                <span class="hide-menu" translate="label.appointmentTypeDefinitions"></span>
                            </a>
                            <a [routerLink]="['appointmentTypeDefinitions']" routerLinkActive
                               #appointmentTypeDefinitions="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createAppointmentTypeDefinition']" routerLinkActive
                               #createAppointmentTypeDefinition="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['multiAppointmentsTemplate']"
                               [featureDisplay]="'admin-multiAppointments-template'"
                               [activityDisplay]="'MultiAppointmentTemplateRead'"
                               [ngClass]="{'active': multiAppointmentsTemplate.isActive || createMultiAppointmentTemplate.isActive }">
                                <span class="hide-menu" translate="label.multiAppointmentTemplate"></span>
                            </a>
                            <a [routerLink]="['multiAppointmentsTemplate']" routerLinkActive
                               #multiAppointmentsTemplate="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createMultiAppointmentTemplate']" routerLinkActive
                               #createMultiAppointmentTemplate="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['appointmentTypes']"
                               [featureDisplay]="'admin-new-appointmentType'"
                               [activityDisplay]="'AppointmentTypeRead'"
                               [ngClass]="{'active': appointmentTypes.isActive || createAppointmentType.isActive }">
                                <span class="hide-menu" translate="label.appointmentTypes"></span>
                            </a>
                            <a [routerLink]="['appointmentTypes']" routerLinkActive #appointmentTypes="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createAppointmentType']" routerLinkActive
                               #createAppointmentType="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['appointmentStatusTransitionReasons']"
                               [featureDisplay]="'admin-new-appointmentStatusTransitionReasons'"
                               [activityDisplay]="'AppointmentStatusTransitionReasonRead'"
                               [ngClass]="{'active': appointmentStatusTransitionReasons.isActive || createAppointmentStatusTransitionReason.isActive }">
                                <span class="hide-menu" translate="label.transitionReasonTab.title"></span>
                            </a>
                            <a [routerLink]="['appointmentStatusTransitionReasons']" routerLinkActive
                               #appointmentStatusTransitionReasons="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createAppointmentStatusTransitionReason']" routerLinkActive
                               #createAppointmentStatusTransitionReason="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['exportAppointments']"
                               [featureDisplay]="'backoffice-appointment-export'"
                               [activityDisplay]="'AppointmentExport'"
                               [ngClass]="{'active': exportAppointments.isActive}">
                                <span class="hide-menu" translate="label.export"></span>
                            </a>
                            <a [routerLink]="['exportAppointments']" routerLinkActive
                               #exportAppointments="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!--resourceManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(resourceManagement)" #resourceManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandResourceManagement"
                       (click)="changeExpandedMenuValue('expandResourceManagement')"
                       [featureDisplay]="['admin-resources', 'admin-new-resourceType', 'admin-multiResourceBlueprint', 'admin-multiResourceCombination']"
                       [activityDisplay]="['ResourceRead', 'ResourceTypeRead', 'MultiResourceBluePrintRead', 'MultiResourceCombinationActivityRead']">
                        <i class="mdi mdi-gauge float-left"></i>
                        <span class="hide-menu d-block" translate="label.resourceManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandResourceManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['resources']"
                               [featureDisplay]="'admin-resources'"
                               [activityDisplay]="'ResourceRead'"
                               [ngClass]="{'active': resources.isActive || createResource.isActive }">
                                <span class="hide-menu" translate="label.resource"></span>
                            </a>
                            <a [routerLink]="['resources']" routerLinkActive #resources="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createResource']" routerLinkActive
                               #createResource="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['resourceTypes']"
                               [featureDisplay]="'admin-new-resourceType'"
                               [activityDisplay]="'ResourceTypeRead'"
                               [ngClass]="{'active': resourceTypes.isActive || createResourceType.isActive }">
                                <span class="hide-menu" translate="label.resourceTypes"></span>
                            </a>
                            <a [routerLink]="['resourceTypes']" routerLinkActive #resourceTypes="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createResourceType']" routerLinkActive
                               #createResourceType="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['resourceWorkSchedules']"
                               [featureDisplay]="'admin-resourceWorkSchedule'"
                               [activityDisplay]="'ResourceWorkScheduleRead'"
                               [ngClass]="{'active': resourceWorkSchedules.isActive || createResourceWorkSchedule.isActive }">
                                <span class="hide-menu" translate="label.resourceWorkSchedules"></span>
                            </a>
                            <a [routerLink]="['resourceWorkSchedules']" routerLinkActive
                               #resourceWorkSchedules="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createResourceWorkSchedule']" routerLinkActive
                               #createResourceWorkSchedule="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['multiResourceBluePrints']"
                               [featureDisplay]="'admin-multiResourceBlueprint'"
                               [activityDisplay]="'MultiResourceBluePrintRead'"
                               [ngClass]="{'active': multiResourceBluePrints.isActive || createMultiResourceBluePrint.isActive }">
                                <span class="hide-menu" translate="label.multiResourceBlueprints"></span>
                            </a>
                            <a [routerLink]="['multiResourceBluePrints']" routerLinkActive
                               #multiResourceBluePrints="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createMultiResourceBluePrint']" routerLinkActive
                               #createMultiResourceBluePrint="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['multiResourceCombinations']"
                               [featureDisplay]="'admin-multiResourceCombination'"
                               [activityDisplay]="'MultiResourceCombinationActivityRead'"
                               [ngClass]="{'active': multiResourceCombinations.isActive || createMultiResourceCombination.isActive }">
                                <span class="hide-menu" translate="label.multiResourceCombinationTab.title"></span>
                            </a>
                            <a [routerLink]="['multiResourceCombinations']" routerLinkActive
                               #multiResourceCombinations="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createMultiResourceCombination']" routerLinkActive
                               #createMultiResourceCombination="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!--roomReservationManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(roomReservationManagement)" #roomReservationManagement>
                    <a [featureDisplay]="['admin-capacityOverview-room','admin-room-reservation','admin-export-roomReservation' ]"
                       [activityDisplay]="['GetPlannedCapacityForRooms','ReadRoomReservation','GenerateExportJobRoomAvailabilities']"
                       class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandRoomManagement"
                       (click)="changeExpandedMenuValue('expandRoomManagement')">
                        <i class="fas fa-hospital float-left"></i>
                        <span class="hide-menu d-block" translate="label.roomReservationManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandRoomManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['capacityPlannerRoom']" routerLinkActive="active"
                               [featureDisplay]="'admin-capacityOverview-room'"
                               [activityDisplay]="'GetPlannedCapacityForRooms'"
                               [ngClass]="{inactive: isResourceCapacitySelected}"
                               (click)="showResourceOrRoom(roomVal)">
                                <span class="hide-menu" translate="label.roomsCapacityOverview"></span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['roomReservationExport']" routerLinkActive="active"
                               [featureDisplay]="'admin-export-roomReservation'"
                               [activityDisplay]="'GenerateExportJobRoomAvailabilities'">
                                <span class="hide-menu" translate="label.export"></span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['roomReservation']" routerLinkActive="active"
                               [activityDisplay]="['ReadRoomReservation']"
                               [featureDisplay]="'admin-room-reservation'">
                                <span class="hide-menu" translate="label.roomReservation"></span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--serviceManagement-->
                <li (mouseover)="toggleSidebarSubMenuPosition(serviceManagement)" #serviceManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandMedicalManagement"
                       (click)="changeExpandedMenuValue('expandMedicalManagement')"
                       [featureDisplay]="['admin-services','admin-subServices','admin-specialities','admin-areas']"
                       [activityDisplay]="['ServiceRead','SubServiceRead','SpecialityRead','AreaRead']">
                        <i class="fas fa-stethoscope float-left"></i>
                        <span class="hide-menu d-block" translate="label.serviceManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandMedicalManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['areas']"
                               [featureDisplay]="'admin-areas'"
                               [activityDisplay]="'AreaRead'"
                               [ngClass]="{'active': areas.isActive || createArea.isActive }">
                                <span class="hide-menu" translate="label.medicalAreas"></span>
                            </a>
                            <a [routerLink]="['areas']" routerLinkActive #areas="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createArea']" routerLinkActive #createArea="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['specialities']" [featureDisplay]="'admin-specialities'"
                               [activityDisplay]="'SpecialityRead'"
                               [ngClass]="{'active': specialities.isActive || createSpeciality.isActive }"
                            >
                                <span class="hide-menu" translate="label.specialities"></span>
                            </a>
                            <a [routerLink]="['specialities']" routerLinkActive #specialities="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createSpeciality']" routerLinkActive #createSpeciality="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['services']" [featureDisplay]="'admin-services'"
                               [activityDisplay]="'ServiceRead'"
                               [ngClass]="{'active': services.isActive || createService.isActive }"
                            >
                                <span class="hide-menu" translate="label.services"></span>
                            </a>
                            <a [routerLink]="['services']" routerLinkActive #services="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createService']" routerLinkActive #createService="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['subServices']" [featureDisplay]="'admin-subServices'"
                               [activityDisplay]="'SubServiceRead'"
                               [ngClass]="{'active': subServices.isActive || createSubService.isActive }"
                            >
                                <span class="hide-menu" translate="label.subServices"></span>
                            </a>
                            <a [routerLink]="['subServices']" routerLinkActive #subServices="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createSubService']" routerLinkActive #createSubService="routerLinkActive"
                               class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!-- Users management-->
                <li (mouseover)="toggleSidebarSubMenuPosition(usersManagement)" #usersManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandUserManagement"
                       (click)="changeExpandedMenuValue('expandUserManagement')"
                       [featureDisplay]="['admin-employees','admin-teams']"
                       [activityDisplay]="['UserRead','TeamRead']">
                        <i class="fas fa-users float-left"></i>
                        <span class="hide-menu d-block" translate="label.userManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandUserManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['teams']" [featureDisplay]="'admin-teams'" [activityDisplay]="'TeamRead'"
                               [ngClass]="{'active': teams.isActive || createTeam.isActive }"
                            >
                                <span class="hide-menu" translate="label.userManagementTab.teams"></span>
                            </a>
                            <a [routerLink]="['teams']" routerLinkActive #teams="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createTeam']" routerLinkActive #createTeam="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['users']" [featureDisplay]="'admin-employees'"
                               [activityDisplay]="'UserRead'"
                               [ngClass]="{'active': users.isActive || createUser.isActive }"
                            >
                                <span class="hide-menu" translate="label.userManagementTab.users"></span>
                            </a>
                            <a [routerLink]="['users']" routerLinkActive #users="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createUser']" routerLinkActive #createUser="routerLinkActive"
                               class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!-- Holiday management -->
                <li (mouseover)="toggleSidebarSubMenuPosition(holidayManagement)" #holidayManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandHolidayManagement"
                       (click)="changeExpandedMenuValue('expandHolidayManagement')"
                       [featureDisplay]="['admin-new-Holidays', 'admin-new-holidayCalendars']"
                       [activityDisplay]="['HolidayRead', 'HolidayCalendarRead']">
                        <i class="fas fa-snowman"></i>
                        <span class="hide-menu d-block" translate="label.activityName.holidayManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandHolidayManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['holidays']" [featureDisplay]="'admin-new-Holidays'"
                               [activityDisplay]="'HolidayRead'"
                               [ngClass]="{'active': holidays.isActive || createHoliday.isActive }"
                            >
                                <span class="hide-menu" translate="label.holidayTab.title"></span>
                            </a>
                            <a [routerLink]="['holidays']" routerLinkActive #holidays="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createHoliday']" routerLinkActive #createHoliday="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['holidayCalendars']"
                               [featureDisplay]="'admin-new-holidayCalendars'"
                               [activityDisplay]="'HolidayCalendarRead'"
                               [ngClass]="{'active': holidayCalendars.isActive || createHolidayCalendar.isActive }">
                                <span class="hide-menu" translate="label.holidayCalendarTab.title"></span>
                            </a>
                            <a [routerLink]="['holidayCalendars']" routerLinkActive #holidayCalendars="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createHolidayCalendar']" routerLinkActive
                               #createHolidayCalendar="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!-- Time Windows -->
                <li (mouseover)="toggleSidebarSubMenuPosition(timeWindows)" #timeWindows>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandTimeWindows"
                       (click)="changeExpandedMenuValue('expandTimeWindows')"
                       [featureDisplay]="['admin-timeWindow']"
                       [activityDisplay]="['KeyTimeWindowReadActivity', 'KeyResourceChannelRestrictionReadActivity']">
                        <i class="fa-regular fa-calendar-clock"></i>
                        <span class="hide-menu d-block" translate="label.timeWindowTab.title"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandTimeWindows ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['timeWindowsForChannel']"
                               [featureDisplay]="'admin-timeWindow'"
                               [activityDisplay]="'KeyTimeWindowReadActivity'"
                               [ngClass]="{'active': timeWindowsForChannel.isActive || createTimeWindowForChannel.isActive }">
                                <span class="hide-menu" translate="label.channel"></span>
                            </a>
                            <a [routerLink]="['timeWindowsForChannel']" routerLinkActive
                               #timeWindowsForChannel="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createTimeWindowForChannel']" routerLinkActive
                               #createTimeWindowForChannel="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['timeWindowsForSpecialitiesAndAreas']"
                               [featureDisplay]="'admin-timeWindow'"
                               [activityDisplay]="'TimeWindowSpecialityAreaRead'"
                               [ngClass]="{'active': timeWindowSpecialityAreas.isActive || createTimeWindowSpecialityArea.isActive }">
                                <span class="hide-menu" translate="label.areaAndSpeciality"></span>
                            </a>
                            <a [routerLink]="['timeWindowsForSpecialitiesAndAreas']" routerLinkActive
                               #timeWindowSpecialityAreas="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createTimeWindowForSpecialityAndArea']" routerLinkActive
                               #createTimeWindowSpecialityArea="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['timeWindowsForResource']"
                               [featureDisplay]="'admin-timeWindow'"
                               [activityDisplay]="'KeyResourceChannelRestrictionReadActivity'"
                               [ngClass]="{'active': resourceChannelRestrictions.isActive || createResourceChannelRestriction.isActive }">
                                <span class="hide-menu" translate="label.resourceAndService"></span>
                            </a>
                            <a [routerLink]="['timeWindowsForResource']" routerLinkActive
                               #resourceChannelRestrictions="routerLinkActive" class="d-none"></a>
                            <a [routerLink]="['createTimeWindowForResource']" routerLinkActive
                               #createResourceChannelRestriction="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!-- Event management -->
                <li (mouseover)="toggleSidebarSubMenuPosition(eventManagement)" #eventManagement>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandEventManagement"
                       (click)="changeExpandedMenuValue('expandEventManagement')"
                       [featureDisplay]="['event-management', 'secret-store']"
                       [activityDisplay]="['EventActionRead', 'DataStoreRead']">
                        <i class="fab fa-elementor"></i>
                        <span class="hide-menu d-block" translate="label.eventManagement"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandEventManagement ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['eventActions']"
                               [featureDisplay]="['event-management']"
                               [activityDisplay]="['EventActionRead']"
                               [ngClass]="{'active': eventActions.isActive || createEventAction.isActive }">
                                <span class="hide-menu" translate="label.eventActions"></span>
                            </a>
                            <a [routerLink]="['eventActions']" routerLinkActive
                               #eventActions="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createEventAction']" routerLinkActive
                               #createEventAction="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['dataStore']"
                               [featureDisplay]="'secret-store'"
                               [activityDisplay]="'DataStoreRead'"
                               [ngClass]="{'active': dataStore.isActive || createDataStore.isActive }">
                                <span class="hide-menu" translate="label.secrets"></span>
                            </a>
                            <a [routerLink]="['dataStore']" routerLinkActive #dataStore="routerLinkActive"
                               class="d-none"></a>
                            <a [routerLink]="['createDataStore']" routerLinkActive #createDataStore="routerLinkActive"
                               class="d-none"></a>
                        </li>
                        <li>
                            <!-- Display this item only if both event-management and rule-management are active -->
                            <a [routerLink]="['eventRules']"
                               [featureDisplay]="['rule-management', 'event-management']"
                               featureDisplayCondition="AND"
                               [activityDisplay]="'RuleRead'"
                               (click)="rulesUtils.selectedRule = null"
                               [ngClass]="{'active': eventRules.isActive}">
                                <span class="hide-menu" translate="label.rules"></span>
                            </a>
                            <a [routerLink]="['eventRules']" routerLinkActive
                               #eventRules="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['actionExecutionLogs']"
                               [featureDisplay]="'action-execution-logs-overview'"
                               [activityDisplay]="'ActionExecutionLogsOverviewRead'"
                               [ngClass]="{'active': actionExecutionLogs.isActive}">
                                <span class="hide-menu" translate="label.actionExecutionLogs"></span>

                            </a>
                            <a [routerLink]="['actionExecutionLogs']"
                               routerLinkActive #actionExecutionLogs="routerLinkActive" class="d-none"></a>
                        </li>
                    </ul>
                </li>
                <!-- Rules -->
                <li>
                    <a [routerLink]="['rules']"
                       [featureDisplay]="'rule-management'"
                       [activityDisplay]="'RuleRead'"
                       (click)="rulesUtils.selectedRule = null"
                       [ngClass]="{'active': rules.isActive || createRule.isActive || ruleSet.isActive }">
                        <i class="fa-solid fa-folder-gear float-left"></i>
                        <span class="hide-menu d-block" translate="label.rules"></span>
                    </a>
                    <a [routerLink]="['rules']" routerLinkActive #rules="routerLinkActive"
                       class="d-none"></a>
                    <a [routerLink]="['createRule']" routerLinkActive #createRule="routerLinkActive"
                       class="d-none"></a>
                    <a [routerLink]="['ruleSet']" routerLinkActive #ruleSet="routerLinkActive"
                       class="d-none"></a>
                </li>
                <!-- API Integration -->
                <li>
                    <a [routerLink]="['apiIntegration']" routerLinkActive="active"
                       [featureDisplay]="'admin-system-config-apikeys'"
                       [activityDisplay]="['read-apim-integration-information']">
                        <i class="fas fa-server float-left"></i>
                        <span class="hide-menu d-block" translate="label.apiIntegration"></span>
                    </a>
                </li>
                <!-- ChangeLog -->
                <li>
                    <a [routerLink]="['changeLogs']"
                       routerLinkActive="active"
                       [featureDisplay]="'changelog-object-history'"
                       [activityDisplay]="'ChangeLogObjectHistoryRead'">
                        <i class="fa-regular fa-floppy-disk-pen"></i>
                        <span class="hide-menu" translate="label.changeLog"></span>
                    </a>
                </li>
                <!-- Configuration -->
                <li (mouseover)="toggleSidebarSubMenuPosition(configuration)" #configuration>
                    <a class="has-arrow waves-effect waves-dark"
                       [attr.aria-expanded]="sideBarExpanded.expandConfiguration"
                       (click)="changeExpandedMenuValue('expandConfiguration')"
                       [featureDisplay]="['admin-new-systemConfig', 'booking-filters', 'rule-management-weightedcombination']"
                       [activityDisplay]="['SystemConfigurationRead', 'BookingFilterRead', 'RuleWeightCombinationRead']">
                        <i class="fas fa-cog"></i>
                        <span class="hide-menu d-block" translate="label.configuration"></span>
                    </a>
                    <ul [className]="!sideBarExpanded.expandConfiguration ? 'collapse' : ''">
                        <li>
                            <a [routerLink]="['systemConfig']" routerLinkActive="active"
                               [featureDisplay]="'admin-new-systemConfig'"
                               [activityDisplay]="'SystemConfigurationRead'">
                                <span class="hide-menu d-block" translate="label.system"></span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['bookingFilters']" routerLinkActive="active"
                               [featureDisplay]="'booking-filters'"
                               [activityDisplay]="'BookingFilterRead'"
                               [ngClass]="{'active': bookingFilters.isActive || bookingFilters.isActive}">
                                <span class="hide-menu d-block" translate="label.bookingFilters"></span>
                            </a>
                            <a [routerLink]="['bookingFilters']" routerLinkActive
                               #bookingFilters="routerLinkActive" class="d-none"></a>
                        </li>
                        <li>
                            <a [routerLink]="['rulesWeightedCombinations']" routerLinkActive="active"
                               [featureDisplay]="'rule-management-weightedcombination'"
                               [activityDisplay]="'RuleWeightCombinationRead'">
                                <span class="hide-menu d-block" translate="label.rulesWeightedCombinations"></span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
    <!-- Sidebar logo -->
    <div class="border-top border-dark d-flex no-block align-items-center justify-content-center"
         [class.onlyLogo]="!showLogoPoweredByLabel"
         (appResized)="listenToPoweredByLogoContainerWidthChanges($event)">
        <sbase-powered-by-logo-footer
            *ngIf="showBranding"
            [logoPath]="logoPathForPoweredByLogoFooter">
        </sbase-powered-by-logo-footer>
    </div>
</aside>
