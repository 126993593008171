import {Injectable} from '@angular/core';
import {GeneralUtils} from '../../utils/general.utils';
import {ODataQueryObjectType, ServiceType} from 'sked-base';
import * as lodash from 'lodash';
import {ODataFilterQueryType, ODataOrderByQueryType, TableFiltersType} from '../../../data-model/general.type';
import {constants} from '../../constants/constants';
import {MultiSelectTableColumnType, MultiSelectTableOptionsType} from './multi-select-table.types';
import {IdNameType} from 'sked-base/lib/data-model/generalTypes';

@Injectable({
    providedIn: 'root'
})
export class MultiSelectTableUtils {

    constructor(public generalUtils: GeneralUtils) {
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {},
            orderBy: {},
            expand: {}
        };
    }

    paginate(array: any[], pageSize: number, pageNumber: number): any[] {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    sort(array: any[], orderByObject: ODataOrderByQueryType) {
        const orderByKeys = Object.keys(orderByObject).map(key => {
            if (key.includes('/')) {
                key = key.replace('/', '.');
            }
            return key.toLowerCase();
        });
        const orderByValues = Object.values(orderByObject).map(value => value.toLowerCase());

        return  lodash.orderBy(array, orderByKeys, orderByValues);
    }

    getOnlyAssignedItems(
        tableFilters: TableFiltersType, selectedItems: any[], options: MultiSelectTableOptionsType
    ): { value: ServiceType[], count?: number } {
        const filteredSelectedItems: { value: any[], count?: number } = {} as { value: any[], count?: number };

        let filteredItems = lodash.cloneDeep(selectedItems);

        for (const filterPropertyName in tableFilters.filter) {
            if (tableFilters.filter.hasOwnProperty(filterPropertyName) && tableFilters.filter[filterPropertyName]) {
                const column: MultiSelectTableColumnType = lodash.find(options.columns, {propertyName: filterPropertyName});
                if (!!column?.propertyFilterIdName) {
                    filteredItems = lodash.filter(filteredItems, {
                        [column.propertyFilterIdNameMapped]: tableFilters.filter[filterPropertyName].id
                    });
                }
            }
        }

        if (!!tableFilters.orderBy) {
            filteredItems = this.sort(filteredItems, tableFilters.orderBy);
        }

        filteredSelectedItems.value = this.paginate(filteredItems, tableFilters.itemsPerPage, tableFilters.currentPage);
        filteredSelectedItems.count = filteredItems.length;

        return filteredSelectedItems;
    }

    getQueryForItemsRequest(
        tableFilters: TableFiltersType, options: MultiSelectTableOptionsType, includeCount: boolean = true
    ): ODataQueryObjectType {
        const skip = ((tableFilters.currentPage - 1) * tableFilters.itemsPerPage);
        const top = tableFilters.itemsPerPage;
        const count = includeCount;
        const filter = {} as ODataFilterQueryType;
        const orderBy = this.getOrderByQuery(tableFilters.orderBy);
        for (const filterPropertyName in tableFilters.filter) {
            if (tableFilters.filter.hasOwnProperty(filterPropertyName) && tableFilters.filter[filterPropertyName]) {
                if (!!options.filterBySpecialProperty && filterPropertyName === options.filterBySpecialProperty && tableFilters.filter[options.filterBySpecialProperty]?.length > 0) {
                    filter[options.filterBySpecialProperty] = {
                        any: {
                            Id: {
                                in: tableFilters.filter[options.filterBySpecialProperty].map(selectedItem => (
                                    {type: 'guid', value: selectedItem}
                                )),
                            },
                        }
                    };
                } else {
                    const column: MultiSelectTableColumnType = lodash.find(options.columns, {propertyName: filterPropertyName});
                    if (!!column?.propertyFilterIdName) {
                        filter[column.propertyFilterIdName] = { eq: { type: 'guid', value: tableFilters.filter[filterPropertyName].id } };
                    }
                }
            }
        }

        return options.getQueryForItems(skip, count, filter, top, orderBy);
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    matchAssignedItems(items: any[], assignedItems: IdNameType[]): any[] {
        if (assignedItems && assignedItems.length > 0) {
            for (const item of items) {
                const foundItem = lodash.find(assignedItems, {id: item.id});
                if (foundItem) {
                    item.selected = true;
                }
            }
        }
        return items;
    }

    // Used to get getQueries methods for the options object above
    getQueryMakerForItems(querySelect: any, queryFilter?: any, queryExpand?: any, queryOrderBy?: string[]): (skip?, count?, filter?, top?, orderBy?) => ODataQueryObjectType {
        const query: ODataQueryObjectType = {} as ODataQueryObjectType;
        query.select = querySelect;
        if (!!queryFilter) {
            query.filter = queryFilter;
        }
        if (!!queryExpand) {
            query.expand = queryExpand; // For example {Speciality: {select: ['Id', 'Name']}};
        }
        if (!!queryOrderBy) {
            query.orderBy = queryOrderBy;
        }

        return (skip?, count?, filter?, top?, orderBy?) => {
            query.count = count !== undefined ? count : true;
            if (skip !== undefined) {
                query.skip = skip;
            }
            if (filter !== undefined) {
                query.filter = filter;
            }
            if (top !== undefined) {
                query.top = top;
            }
            if (orderBy !== undefined) {
                query.orderBy = orderBy;
            }
            return query;
        };
    }
}
