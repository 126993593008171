import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    FormAssignmentDynamicWorkListRuleProvider,
    FormAssignmentDynamicWorkListRuleType,
    ResourceDependentFiltersType,
    ResourceProvider,
    ResourceType,
    RuleTableDependentFiltersType,
    RuleTableProvider,
    RuleTableType, RuleTypeEnum,
} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {FormAssignmentDynamicWorkListRuleUtils} from '../form-assignment-dynamic-work-list-rule.utils';
import {AbstractControl} from '@angular/forms';
import {RulesUtils} from '../../../rules.utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-form-assignment-dynamic-work-list-rule',
    templateUrl: './create-form-assignment-dynamic-work-list-rule.component.html',
    styleUrls: ['./create-form-assignment-dynamic-work-list-rule.component.scss']
})
export class CreateFormAssignmentDynamicWorkListRuleComponent implements OnInit, AfterViewInit, OnDestroy {
    ruleItem: FormAssignmentDynamicWorkListRuleType = {} as FormAssignmentDynamicWorkListRuleType;
    initialRule: FormAssignmentDynamicWorkListRuleType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    constants = constants;
    screenTemplateLayout: ScreenTemplateLayoutType;
    ruleTableDependentFilters: RuleTableDependentFiltersType =
        this.rulesUtils.getEmptyRuleSetDependentFilters(RuleTypeEnum.FormAssignmentDynamicWorkListRule);
    resourceDependentFilters: ResourceDependentFiltersType = this.formAssignmentDynamicWorkListRuleUtils.getEmptyResourceDependentFilters();

    constructor(
        public formAssignmentDynamicWorkListRuleUtils: FormAssignmentDynamicWorkListRuleUtils,
        public rulesUtils: RulesUtils,
        public ruleTableProvider: RuleTableProvider,
        public generalUtils: GeneralUtils,
        public resourceProvider: ResourceProvider,
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private router: Router,
        private formAssignmentDynamicWorkListRuleProvider: FormAssignmentDynamicWorkListRuleProvider,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngAfterViewInit() {
        // Empty setTimeout solves the expressionchangedafterithasbeencheckederror problem
        setTimeout(() => {
            this.totalStepsCount = this.stepper._steps.length;
            // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
            // with a method we could use to check if the user click on the header is valid
            document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
                matStepHeader.addEventListener('click', (event) => this.rulesUtils.onStepHeaderClick(this.stepper, this.areStepsValid.bind(this))(key));
            });
            if (this.screenTemplateLayout.action === constants.EDIT && this.stepper?._steps?.length) {
                // On edit, set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
                this.stepper._steps.forEach(step => {
                    step.interacted = true;
                });
            }
        });
    }

    ngOnDestroy(): void {
    }

    onStepChange(stepper: StepperSelectionEvent) {
        // Here is logic for in-between steps
    }

    // Used to check whether you can go to other steps or not
    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step)
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    // region Validation methods
    //
    areStepsValid(currentStep: number): boolean {
        switch (currentStep) {
            case 0:
                return this.isRuleDetailsStepValid(this.ruleItem);
            case 1:
                return this.isInputStepValid(this.ruleItem);
            case 2:
                return this.isOutputStepValid(this.ruleItem);
            default:
                return true; // other steps which don't need validation
        }
    }

    isRuleDetailsStepValid(rule: FormAssignmentDynamicWorkListRuleType): boolean {
        return !!(rule.formAssignmentDynamicWorkListRuleTableId &&
            !lodash.isEmpty(rule.formAssignmentDynamicWorkListRuleTable) && rule.name);
    }

    isInputStepValid(rule: FormAssignmentDynamicWorkListRuleType): boolean {
        return !!rule.workListId;
    }

    isOutputStepValid(rule: FormAssignmentDynamicWorkListRuleType): boolean {
        return !!(rule.formId && rule.formDisplayName);
    }

    areAllStepsValid(rule: FormAssignmentDynamicWorkListRuleType): boolean {
        return this.isRuleDetailsStepValid(rule) &&
            this.isInputStepValid(rule) &&
            this.isOutputStepValid(rule);
    }
    //
    // endregion Validation methods

    // region First step methods
    //
    onSelectedRuleTable(ruleTable: RuleTableType[]): void {
        if (ruleTable?.length > 0) {
            this.ruleItem.formAssignmentDynamicWorkListRuleTable = ruleTable[0];
            this.ruleItem.formAssignmentDynamicWorkListRuleTableId = ruleTable[0].id;
        } else {
            this.ruleItem.formAssignmentDynamicWorkListRuleTable = undefined;
            this.ruleItem.formAssignmentDynamicWorkListRuleTableId = undefined;
        }
    }
    //
    // endregion First step methods

    // region Input methods
    onSelectedResource(resource: ResourceType[]): void {
        if (resource?.length > 0) {
            this.ruleItem.resource = resource[0];
            this.ruleItem.resourceId = resource[0].id;
        } else {
            this.ruleItem.resource = undefined;
            this.ruleItem.resourceId = undefined;
        }
    }
    // endregion Input methods

    // region Output methods
    // endregion Output methods

    // region Overview methods
    //
    saveRule(rule: FormAssignmentDynamicWorkListRuleType) {
        const isTemplateValid = this.areAllStepsValid(rule);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createRule(rule);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialRule, rule)) {
                    this.messagesService.success('toastr.success.formAssignmentDynamicWorkListRuleEdit', true);
                    this.rulesUtils.goToParentPage('createRule');
                } else {
                    this.editRule(this.initialRule, rule);
                }
            }
        }
    }

    private createRule(rule: FormAssignmentDynamicWorkListRuleType) {
        this.ngxLoader.start();
        const ruleToSend: FormAssignmentDynamicWorkListRuleType =
            this.formAssignmentDynamicWorkListRuleUtils.mapRuleForServer(rule);
        this.formAssignmentDynamicWorkListRuleProvider.addEntry(ruleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newFormAssignmentDynamicWorkListRuleAdded', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }

    private editRule(oldRule: FormAssignmentDynamicWorkListRuleType, newRule: FormAssignmentDynamicWorkListRuleType) {
        this.ngxLoader.start();
        const oldRuleToSend: FormAssignmentDynamicWorkListRuleType =
            this.formAssignmentDynamicWorkListRuleUtils.mapRuleForServer(oldRule);
        const newRuleToSend: FormAssignmentDynamicWorkListRuleType =
            this.formAssignmentDynamicWorkListRuleUtils.mapRuleForServer(newRule);
        this.formAssignmentDynamicWorkListRuleProvider.updateEntry(oldRuleToSend, newRuleToSend)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.formAssignmentDynamicWorkListRuleEdit', true);
                this.rulesUtils.goToParentPage('createRule');
            }, error => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(error);
            });
    }
    //
    // endregion Overview methods

    goToEdit() {
        history.replaceState({rule: this.ruleItem, action: this.constants.EDIT}, '');
        this.ngOnInit();
        setTimeout(() => {
            this.ngAfterViewInit();
        });
    }

    private setupInitialState() {
        if (history.state && history.state.rule) {
            this.initialRule = history.state.rule;
            this.ruleItem = lodash.cloneDeep(history.state.rule);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.ruleItem = this.formAssignmentDynamicWorkListRuleUtils.getInitialRule();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            // Preselect ruleset if only one available
            this.rulesUtils.preselectRuleSetIfOnlyOneIsAvailable(this.onSelectedRuleTable.bind(this));
        }
    }
}
