import {Component, OnDestroy, OnInit} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {
    ActivityPlanExecutionTypeEnum,
    ActivityPlanScheduledEndTypeEnum,
    ActivityPlanTemplateProvider,
    ActivityPlanTemplateType,
    SearchFilterUtils,
    ServiceProvider
} from 'sked-base';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ActivityPlanTemplateMdUtils} from '../activity-plan-template-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ActivityPlanTemplateFrequencyTypeEnum} from '../activity-plan-template-md.types';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {ServiceType} from 'sked-base/lib/data-model/serviceTypes';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
    ActivityPlanTemplateServiceRecurrenceModalComponent
} from './activity-plan-template-service-recurrence-modal/activity-plan-template-service-recurrence-modal.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-activity-plan-template',
    templateUrl: './create-activity-plan-template.component.html',
    styleUrls: ['./create-activity-plan-template.component.scss']
})
export class CreateActivityPlanTemplateComponent implements OnInit, OnDestroy {
    constants = constants;
    activityPlanExecutionTypeEnum = ActivityPlanExecutionTypeEnum;
    activityPlanScheduledEndTypeEnum = ActivityPlanScheduledEndTypeEnum;
    activityPlanTemplateFrequencyTypeEnum = ActivityPlanTemplateFrequencyTypeEnum;
    initialActivityPlanTemplate: ActivityPlanTemplateType;
    mainDependentFiltersForServiceSearch = this.searchFilterUtils.getServiceDependentFilters();

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        public activityPlanTemplateUtils: ActivityPlanTemplateMdUtils,
        private activityPlanTemplateProvider: ActivityPlanTemplateProvider,
        private router: Router,
        private generalUtils: GeneralUtils,
        private configDataService: ConfigDataService,
        public searchFilterUtils: SearchFilterUtils,
        public serviceProvider: ServiceProvider,
        private dateTimeUtils: DateTimeUtils,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    onEditScopedServiceDetails(itemIndex?: number) {
        this.activityPlanTemplateUtils.scopedServiceInEditMode = true;
        this.activityPlanTemplateUtils.scopedServiceItemIndex = itemIndex;
        this.activityPlanTemplateUtils.scopedServiceItem = lodash.cloneDeep(
            this.activityPlanTemplateUtils.activityPlanTemplateItem.services[itemIndex]
        );
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem =
            this.activityPlanTemplateUtils.parseActivityPlanTemplateServiceFrequency(this.activityPlanTemplateUtils.scopedServiceItem.frequency);
        this.activityPlanTemplateUtils.scopedServiceHasValues = true;
        this.activityPlanTemplateUtils.isExecutionTypeOnceSelected =
            this.activityPlanTemplateUtils.scopedServiceItem.executionType === ActivityPlanExecutionTypeEnum.Once;
        if (this.activityPlanTemplateUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.monthDays) {
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedMonthDays =
                this.activityPlanTemplateUtils.getMonthDays(this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.activityPlanTemplateUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.weekDays) {
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedWeekDays =
                this.activityPlanTemplateUtils.getWeekDays(this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedDays as string[]);
        }
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
    }

    deleteActivityPlanTemplateService(itemIndex) {
        this.activityPlanTemplateUtils.activityPlanTemplateItem.services.splice(itemIndex, 1);
    }

    clearScopedService() {
        this.activityPlanTemplateUtils.scopedServiceItemIndex = undefined;
        this.activityPlanTemplateUtils.isExecutionTypeOnceSelected = false;
        this.activityPlanTemplateUtils.scopedServiceItem = this.activityPlanTemplateUtils.getInitialActivityPlanTemplateService();
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem = this.activityPlanTemplateUtils.getEmptyActivityPlanTemplateServiceFrequency();
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedWeekDays = this.activityPlanTemplateUtils.getWeekDays([]);
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedMonthDays = this.activityPlanTemplateUtils.getMonthDays([]);
        this.activityPlanTemplateUtils.scopedServiceHasValues = false;
        this.activityPlanTemplateUtils.scopedServiceInEditMode = false;
        this.activityPlanTemplateUtils.scopedServiceValidation = this.activityPlanTemplateUtils.getInitialActivityTemplateServiceValidation();
    }

    saveScopedService() {
        const isScopedServiceValid = this.activityPlanTemplateUtils.isScopedServiceValid();
        if (!isScopedServiceValid) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
            return;
        }
        const serviceToSave = lodash.cloneDeep(this.activityPlanTemplateUtils.mapScopedServiceToSave(
            this.activityPlanTemplateUtils.scopedServiceItem,
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem
        ));
        // add to or edit the services table
        if (this.activityPlanTemplateUtils.scopedServiceItemIndex === undefined) {
            this.activityPlanTemplateUtils.activityPlanTemplateItem.services.push(serviceToSave);
        } else {
            this.activityPlanTemplateUtils.activityPlanTemplateItem.services[this.activityPlanTemplateUtils.scopedServiceItemIndex] =
                serviceToSave;
        }
        // show success message after service was added / edited
        if (this.activityPlanTemplateUtils.scopedServiceInEditMode) {
            this.messagesService.success('toastr.success.serviceEdit');
        } else {
            this.messagesService.success('toastr.success.newServiceAdded');
        }
        this.clearScopedService();
    }

    saveActivityPlanTemplateData(activityPlanTemplate: ActivityPlanTemplateType) {
        const isTemplateValid = this.validateFields(activityPlanTemplate);
        if (isTemplateValid) {
            const templateToSend = this.activityPlanTemplateUtils.mapActivityPlanTemplateToSend(activityPlanTemplate);
            if (this.activityPlanTemplateUtils.screenTemplateLayout.action === constants.CREATE) {
                this.createActivityPlanTemplate(templateToSend);
            } else if (this.activityPlanTemplateUtils.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialActivityPlanTemplate, activityPlanTemplate)) {
                    this.messagesService.success('toastr.success.activityPlanTemplateEdit', true);
                    this.goToParentPage();
                } else {
                    this.editActivityPlanTemplate(this.initialActivityPlanTemplate, templateToSend);
                }
            }
        }
    }

    onSelectedService(service: ServiceType[]) {
        this.activityPlanTemplateUtils.scopedServiceHasValues = true;
        if (!service?.length) {
            this.activityPlanTemplateUtils.scopedServiceItem.service = null;
            this.activityPlanTemplateUtils.updateScopedServiceValidation();
            return;
        }
        this.activityPlanTemplateUtils.scopedServiceItem.service = service[0];
        this.activityPlanTemplateUtils.scopedServiceItem.serviceId = service[0].id;
        this.activityPlanTemplateUtils.updateScopedServiceValidation();
    }

    goToParentPage() {
        this.router.navigate(['/activityPlanTemplates']);
    }

    goToEdit() {
        history.replaceState({
            activityPlanTemplate: this.activityPlanTemplateUtils.activityPlanTemplateItem,
            action: constants.EDIT
        }, '');
        this.ngOnInit();
    }

    openServiceRecurrenceModal() {
        if (this.activityPlanTemplateUtils.isExecutionTypeOnceSelected) {
            return;
        }
        const modalOptions: NgbModalOptions = this.generalUtils.getModalOptions();
        modalOptions.windowClass = 'activity-plan-template-service-recurrence';

        const modalRef = this.modalService
            .open(ActivityPlanTemplateServiceRecurrenceModalComponent, modalOptions);

        modalRef.componentInstance.options = {};

        modalRef.result.then(() => {
        }, (onClose) => {
            //x button was clicked, previous values needs to be displayed next time
        });
    }

    private initializeScopedService() {
        this.activityPlanTemplateUtils.scopedServiceItemIndex = undefined;
        this.activityPlanTemplateUtils.scopedServiceItem = this.activityPlanTemplateUtils.getInitialActivityPlanTemplateService();
        this.activityPlanTemplateUtils.scopedServiceFrequencyItem =
            this.activityPlanTemplateUtils.parseActivityPlanTemplateServiceFrequency(this.activityPlanTemplateUtils.scopedServiceItem.frequency);
        this.activityPlanTemplateUtils.isExecutionTypeOnceSelected = false;
        this.activityPlanTemplateUtils.scopedServiceHasValues = false;
        if (this.activityPlanTemplateUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.monthDays) {
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedMonthDays =
                this.activityPlanTemplateUtils.getMonthDays(this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedDays as number[]);
        } else if (this.activityPlanTemplateUtils.scopedServiceFrequencyItem.frequencyType === ActivityPlanTemplateFrequencyTypeEnum.weekDays) {
            this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedWeekDays =
                this.activityPlanTemplateUtils.getWeekDays(this.activityPlanTemplateUtils.scopedServiceFrequencyItem.selectedDays as string[]);
        }
    }

    // function to create the new ActivityPlanTemplate
    private createActivityPlanTemplate(activityPlanTemplate: ActivityPlanTemplateType) {
        this.ngxLoader.start();
        this.activityPlanTemplateProvider.addEntry(activityPlanTemplate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newActivityPlanTemplateAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the ActivityPlanTemplate
    private editActivityPlanTemplate(oldActivityPlanTemplate: ActivityPlanTemplateType, newActivityPlanTemplate: ActivityPlanTemplateType) {
        this.ngxLoader.start();
        this.activityPlanTemplateProvider.updateEntry(oldActivityPlanTemplate, newActivityPlanTemplate)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.activityPlanTemplateEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(activityPlanTemplateItem: ActivityPlanTemplateType): boolean {
        if (this.activityPlanTemplateUtils.scopedServiceHasValues) {
            this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
            return false;
        }
        if (!activityPlanTemplateItem || !activityPlanTemplateItem.name || !activityPlanTemplateItem.services) {
            return false;
        }
        if (!lodash.has(activityPlanTemplateItem?.services, 'length') || lodash.isEmpty(activityPlanTemplateItem.services)) {
            this.messagesService.error('label.error.multiAppointmentTemplateNoServices');
            return false;
        }
        return true;
    }

    private setupInitialState() {
        this.activityPlanTemplateUtils.scopedServiceValidation = this.activityPlanTemplateUtils.getInitialActivityTemplateServiceValidation();
        this.initializeScopedService();
        this.activityPlanTemplateUtils.maximumTimeWindow = this.dateTimeUtils.getMaximumTimeWindowFromSystemConfig();
        if (history.state && history.state.activityPlanTemplate) {
            this.initialActivityPlanTemplate = history.state.activityPlanTemplate;
            this.activityPlanTemplateUtils.activityPlanTemplateItem = lodash.cloneDeep(history.state.activityPlanTemplate);
            if (history.state.action === constants.VIEW) {
                this.activityPlanTemplateUtils.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.activityPlanTemplateUtils.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.activityPlanTemplateUtils.activityPlanTemplateItem = this.activityPlanTemplateUtils.getInitialActivityPlanTemplate();
            this.activityPlanTemplateUtils.screenTemplateLayout =
                this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
            this.activityPlanTemplateUtils.scopedServiceHasValues = true;
            this.activityPlanTemplateUtils.updateScopedServiceValidation();
        }
        // Add parsedFrequency field to each service from this.activityPlanTemplateItem.services so we don't have to use a pipe (better performance)
        this.activityPlanTemplateUtils.activityPlanTemplateItem =
            this.activityPlanTemplateUtils.addParsedFrequencyToTemplateItem(this.activityPlanTemplateUtils.activityPlanTemplateItem);
    }
}
