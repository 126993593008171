import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {CoverageCompanyMDComponent} from './coverage-company-md.component';
import {CreateCoverageCompanyComponent} from './create-coverage-company/create-coverage-company.component';

@NgModule({
    declarations: [CoverageCompanyMDComponent, CreateCoverageCompanyComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
    ]
})
export class CoverageCompanyMDModule {
}
