import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {constants} from 'src/app/shared/constants/constants';
import {
    AreaProvider,
    ChannelProvider,
    ConfirmDeleteModalService,
    EntityTypeEnum,
    SpecialityProvider,
    TimeWindowSpecialityAreaProvider,
    TimeWindowSpecialityAreaType
} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take, filter, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TimeWindowSpecialityAreaMdUtils} from './time-window-speciality-area-md-util';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    TimeWindowForAreaAndSpecialityMainDependentFiltersType
} from './time-window-speciality-area.types';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {PreviousRouteService} from '../../shared/services/previous-route.service';
import {DisplayChangeLogModalService} from '../../shared/component/display-change-log-modal/display-change-log-modal.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-time-window-speciality-area-md',
    templateUrl: './time-window-speciality-area-md.component.html',
    styleUrls: ['./time-window-speciality-area-md.component.scss']
})
export class TimeWindowSpecialityAreaMDComponent implements OnInit, OnDestroy {
    itemsPerPageList: number[] = this.generalUtils.getItemsPerPageList();
    timeWindowSpecialityAreaMdList: TimeWindowSpecialityAreaType[] = [];
    objectKeys = Object.keys;
    showItemsPerPageDropdown = false;
    totalTableItems: number;
    readonly TIME_WINDOW_SPECIALITY_AREA = 'TimeWindowSpecialityArea';
    readonly CONSTANTS = constants;
    private searchTimeWindowSpecialityAreaChanged = new Subject<string>();
    VIEW_ACTIVITY_ENABLED = false;
    mainDependentFilters: TimeWindowForAreaAndSpecialityMainDependentFiltersType;
    EntityTypeEnum = EntityTypeEnum;

    constructor(
        public timeWindowSpecialityAreaProvider: TimeWindowSpecialityAreaProvider,
        public ngxLoader: NgxUiLoaderService,
        public messagesService: MessagesService,
        public router: Router,
        public generalUtils: GeneralUtils,
        public confirmDeleteService: ConfirmDeleteModalService,
        public areaProvider: AreaProvider,
        public specialityProvider: SpecialityProvider,
        public channelProvider: ChannelProvider,
        public timeWindowSpecialityAreaMdUtils: TimeWindowSpecialityAreaMdUtils,
        private configDataService: ConfigDataService,
        private previousRouteService: PreviousRouteService,
        private changeLogModalService: DisplayChangeLogModalService
    ) {
    }

    ngOnInit() {
        this.VIEW_ACTIVITY_ENABLED = this.configDataService.isActivityActive('TimeWindowSpecialityAreaUpdate');
        this.mainDependentFilters = {
            area: this.timeWindowSpecialityAreaMdUtils.getAreaDependentFilters(),
            speciality: this.timeWindowSpecialityAreaMdUtils.getSpecialityDependentFilters(),
            channel: this.timeWindowSpecialityAreaMdUtils.getChannelDependentFilters()
        };

        if (this.previousRouteService.getPreviousUrl() !== '/createTimeWindowForSpecialityAndArea') {
            this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        }

        this.loadTimeWindowSpecialityAreaMdData();
        this.subscribeSearchByTimeWindowSpecialityAreaChanged();
    }

    ngOnDestroy(): void {
    }

    // method to navigate create TimeWindowSpecialityArea
    createTimeWindowSpecialityArea(): void {
        this.router.navigate(['/createTimeWindowForSpecialityAndArea']);
    }

    editTimeWindowSpecialityArea(timeWindowSpecialityArea: TimeWindowSpecialityAreaType) {
        this.router.navigate(['/createTimeWindowForSpecialityAndArea'], {
            state: {
                timeWindowSpecialityArea,
                action: constants.EDIT
            }
        });
    }

    viewTimeWindowSpecialityArea(timeWindowSpecialityArea: TimeWindowSpecialityAreaType) {
        this.router.navigate(['/createTimeWindowForSpecialityAndArea'], {
            state: {
                timeWindowSpecialityArea,
                action: constants.VIEW
            }
        });
    }

    onSelectedAreaFilter(areaFilterList: any[]) {
        this.timeWindowSpecialityAreaMdUtils.initialFilterValues.area = areaFilterList;
        this.timeWindowSpecialityAreaMdUtils.tableFilters.filter.area = (areaFilterList && areaFilterList.length > 0) ? areaFilterList[0] : undefined;
        this.loadTimeWindowSpecialityAreaMdData();
    }

    onSelectedSpecialityFilter(specialityFilterList: any[]) {
        this.timeWindowSpecialityAreaMdUtils.initialFilterValues.speciality = specialityFilterList;
        this.timeWindowSpecialityAreaMdUtils.tableFilters.filter.speciality = (specialityFilterList && specialityFilterList.length > 0)
            ? specialityFilterList[0] : undefined;
        this.loadTimeWindowSpecialityAreaMdData();
    }

    onSelectedChannelFilter(channelFilterList: any[]) {
        this.timeWindowSpecialityAreaMdUtils.initialFilterValues.channel = channelFilterList;
        this.timeWindowSpecialityAreaMdUtils.tableFilters.filter.channel = (channelFilterList && channelFilterList.length > 0)
            ? channelFilterList[0] : undefined;
        this.loadTimeWindowSpecialityAreaMdData();
    }

    onClickedOutsideItemsPerPageFilter(e: Event) {
        this.showItemsPerPageDropdown = false;
    }

    onChangePagination(page: number) {
        this.timeWindowSpecialityAreaMdUtils.tableFilters.currentPage = page;
        this.loadTimeWindowSpecialityAreaMdData(false);
    }

    changeNumberOfItemsPerPage(itemPerPage: number) {
        this.timeWindowSpecialityAreaMdUtils.tableFilters.currentPage = 1;
        this.timeWindowSpecialityAreaMdUtils.tableFilters.itemsPerPage = itemPerPage;
        this.showItemsPerPageDropdown = false;
        this.loadTimeWindowSpecialityAreaMdData(false);
    }

    // method to sort data by ascending/descending order.
    onSortBy(property: string) {
        const isAscendingMode = this.timeWindowSpecialityAreaMdUtils.tableFilters.orderBy[property];
        if (this.timeWindowSpecialityAreaMdUtils.tableFilters.orderBy) {
            this.timeWindowSpecialityAreaMdUtils.tableFilters.orderBy[property] = isAscendingMode === 'asc' ? 'desc' : 'asc';
        }
        this.loadTimeWindowSpecialityAreaMdData(false);
    }

    onClearOrderBy(selectedItem) {
        delete this.timeWindowSpecialityAreaMdUtils.tableFilters.orderBy[selectedItem];
        this.loadTimeWindowSpecialityAreaMdData(false);
    }

    displayConfirmDeleteItemModal(timeWindowSpecialityAreaId: string) {
        this.confirmDeleteService.displayConfirmDeleteModal(this.timeWindowSpecialityAreaProvider, timeWindowSpecialityAreaId).result.then(() => {
        }, (response) => {
            if (response) {
                this.deleteItem(timeWindowSpecialityAreaId);
            }
        });
    }

    displayChangeLog(id: string, actionType: string): void {
        this.changeLogModalService.displayModal(id, actionType);
    }

    // function to get TimeWindowSpecialityArea data
    private loadTimeWindowSpecialityAreaMdData(includeCount: boolean = true) {
        const queryFilter = this.timeWindowSpecialityAreaMdUtils.
        getQueryFilterForTimeWindowSpecialityAreaMD(this.timeWindowSpecialityAreaMdUtils.tableFilters, includeCount);
        this.ngxLoader.start();
        this.timeWindowSpecialityAreaProvider.getEntries(queryFilter)
            .pipe(take(1))
            .subscribe((response: any) => {
                this.timeWindowSpecialityAreaMdList = response.value;
                if (response.count !== undefined && response.count !== null) {
                    this.totalTableItems = response.count;
                }
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    onClearFilters() {
        this.loadInitialFilters({tableFilters: true, sbaseFilters: true});
        this.loadTimeWindowSpecialityAreaMdData(true);
    }

    private loadInitialFilters({tableFilters, sbaseFilters}: { tableFilters?: boolean, sbaseFilters?: boolean }) {
        if (!!tableFilters) {
            this.timeWindowSpecialityAreaMdUtils.tableFilters = this.timeWindowSpecialityAreaMdUtils.getInitialTableFilter();
        }

        if (!!sbaseFilters) {
            this.timeWindowSpecialityAreaMdUtils.initialFilterValues = this.timeWindowSpecialityAreaMdUtils.getInitialFilterValues();
        }
    }

    // method for deleting table item
    private deleteItem(id: string) {
        this.timeWindowSpecialityAreaMdUtils.tableFilters = this.timeWindowSpecialityAreaMdUtils.getInitialTableFilter();
        this.ngxLoader.start();
        this.timeWindowSpecialityAreaProvider.deleteEntry(id)
            .pipe(take(1))
            .subscribe(() => {
                this.loadTimeWindowSpecialityAreaMdData();
                this.messagesService.success('toastr.success.timeWindowSpecialityAreaDelete', true);
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private subscribeSearchByTimeWindowSpecialityAreaChanged() {
        const self = this;
        this.searchTimeWindowSpecialityAreaChanged
            .pipe(
                filter(value => {
                    return value.length >= 3 || value.length === 0;
                }),
                debounceTime(constants.inputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe((searchValue) => {
                self.timeWindowSpecialityAreaMdUtils.tableFilters.filter.areaId = searchValue;
                self.loadTimeWindowSpecialityAreaMdData();
            });
    }
}
