<div class="container-fluid create-exclusion-reason-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-sub-service-header">
        <h3 class="create-exclusion-reason-title">{{screenTemplateLayout.pageTitle|translate}} <span
                class="text-lowercase"
                translate="label.exclusionReasonTab.exclusionReasonHeader"></span>
        </h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveExclusionReasonData(exclusionReasonItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <div class="col-3" *ngIf="screenTemplateLayout.action !== constants.CREATE">
                        <div class="form-group" [ngClass]="{'disabled-input': 'true'}">
                            <label translate="label.shortId"></label>
                            <input type="text" name="shortId"
                                   class="input-element"
                                   placeholder="{{'label.shortId'|translate}}"
                                   [(ngModel)]="exclusionReasonItem.shortId">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group"
                             [ngClass]="{'disabled-input': isAssignedToAnExclusion}">
                            <label translate="label.exclusionReasonTab.description"></label>
                            <span>
                            <input type="text" name="description" class="input-element"
                                   [ngClass]="{'input-element-has-error': !exclusionReasonItem.description}"
                                   [(ngModel)]="exclusionReasonItem.description"
                                   placeholder="{{'label.exclusionReasonTab.description'|translate}}">
                              <div *ngIf="!exclusionReasonItem.description" class="help-block">
                                  <span translate="label.error.required"></span>
                              </div>
                        </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group"
                             [ngClass]="{'disabled-input': isAssignedToAnExclusion}">
                            <label translate="label.showExtraInformation"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="exclusionReasonItem.showExtraInformation"
                                           name="showExtraInformation"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group"
                             [ngClass]="{'disabled-input': isAssignedToAnExclusion}">
                            <label translate="label.extraInformationMandatory"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="exclusionReasonItem.extraInformationMandatory"
                                           name="extraInformationMandatory"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group disabled-input">
                            <label translate="label.exclusionReasonTab.isExternalReason"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="exclusionReasonItem.isExternalReason"
                                           name="isExternalReason"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.active"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="exclusionReasonItem.active"
                                           name="active"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.shortId"></td>
                                    <td class="text-right">{{exclusionReasonItem.shortId}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.description"></td>
                                    <td class="text-right">{{exclusionReasonItem.description}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.showExtraInformation"></td>
                                    <td class="text-right">
                                        <span *ngIf="exclusionReasonItem.showExtraInformation"
                                              translate="label.yes"></span>
                                        <span *ngIf="!exclusionReasonItem.showExtraInformation"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.extraInformationMandatory"></td>
                                    <td class="text-right">
                                    <span *ngIf="exclusionReasonItem.extraInformationMandatory"
                                          translate="label.yes"></span>
                                        <span *ngIf="!exclusionReasonItem.extraInformationMandatory"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.exclusionReasonTab.isExternalReason"></td>
                                    <td class="text-right">
                                    <span *ngIf="exclusionReasonItem.isExternalReason"
                                          translate="label.yes"></span>
                                        <span *ngIf="!exclusionReasonItem.isExternalReason"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.active"></td>
                                    <td class="text-right">
                                    <span *ngIf="exclusionReasonItem.active"
                                          translate="label.yes"></span>
                                        <span *ngIf="!exclusionReasonItem.active"
                                              translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button" (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button (click)="goToEdit()"
                    *ngIf="screenTemplateLayout.action===constants.VIEW"
                    [activityDisplay]="'ExclusionReasonUpdate'"
                    class="btn theme-btn"
                    type="button">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
