import {Component, Input, TemplateRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-task-list-filters-view-more-modal',
    templateUrl: './task-list-filters-view-more-modal.component.html',
    styleUrls: ['./task-list-filters-view-more-modal.component.scss']
})
export class TaskListFiltersViewMoreModalComponent {
    @Input() contentTemplate: TemplateRef<any>;

    constructor(public activeModal: NgbActiveModal) {
    }

    onCloseButtonClick() {
        this.activeModal.close();
    }
}
