<div class="container-fluid blocked-appointments-preview create-entity-md-wrapper">
    <div class="row pl-4">
        <div class="col-4 p-0">
            <div class="blocked-appointments-preview">
                <h3 class="blocked-appointments-preview-title" translate="label.blockingSimulation">
                </h3>
            </div>
        </div>
        <div class="col-offset-6 col-8 p-0">
            <div>
                <div class="d-flex justify-content-end my-3 mr-3">
                    <button type="button" class="btn theme-btn mr-3 min-width-auto"
                            (click)="loadData()">
                        <i class="fas fa-sync-alt"></i>
                    </button>
                    <button type="button"
                            (click)="goToParentPage()"
                            class="btn close-btn mr-3" translate="label.close">
                    </button>
                    <button (click)="runSimulation()" type="button"
                            class="btn theme-btn"
                            translate="label.blockedAppointmentsPreview.runSimulation">
                    </button>
                </div>
            </div>
        </div>
    </div>

    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.status"></td>
            <td translate="label.blockedAppointmentsPreview.title"></td>
            <td translate="label.blockedAppointmentsPreview.lastJobRun"></td>
            <td translate="label.blockedAppointmentsPreview.isSimulation"></td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td *ngIf="statusForBlockedAppointments?.status">{{'enum.jobStatus.' + statusForBlockedAppointments?.status | translate}}</td>
            <td *ngIf="!statusForBlockedAppointments?.status"></td>
            <td>{{blockedAppointmentsPreview?.numberOfBlockedAppointments}}</td>
            <td>{{blockedAppointmentsPreview?.dateTime | fullDateBrowserTzFormatPipe}}</td>
            <td>{{blockedAppointmentsPreview?.isSimulation | yesNoPipe | translate}}
                <span *ngIf="blockedAppointmentsPreview?.isSimulation" class="has-warning font-weight-bold mr-2">
                <i class="fas fa-exclamation-triangle"></i>
                </span>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="row create-service-section create-entity-md-section ml-1">
        <sbase-filter-wrapper
                [options]="filterWrapperOptions"
                [updateFiltersValue]="updateFiltersValue"
                (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"></sbase-filter-wrapper>
        <div class="ml-5 mt-2 d-flex">
            <label> {{"label.blockedAppointmentsPreview.appointmentInformations" | translate}}: &nbsp;</label>
            <label class="mb-3">{{filteredBlockedAppointments?.length}}&nbsp;
                <span translate="label.blockedAppointmentsPreview.blockedAppointments"></span>
            </label>
        </div>
    </div>

    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td translate="label.resource"></td>
            <td translate="label.center2"></td>
            <td translate="label.service"></td>
            <td translate="label.from"></td>
            <td translate="label.to"></td>
            <td translate="label.duration"></td>
        </tr>
        </thead>
        <!--display an empty row if no blocked appointments are returned -->
        <tbody *ngIf="!filteredBlockedAppointments || filteredBlockedAppointments.length===0">
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tbody *ngFor="let appointment of filteredBlockedAppointments">
        <tr>
            <td>{{appointment?.resourceName}}</td>
            <td>{{appointment?.centerName}}</td>
            <td>
                <b>{{appointment.specialtyName}}</b> {{appointment.serviceName}}
                <span *ngIf="appointment.onlineConsultation" class="mr-1">
                    <i class="fas fa-desktop" ngbTooltip="{{'label.onlineConsultation'| translate}}"></i>
                </span>
            </td>
            <td>{{appointment?.dateTimeFrom | fullDateFormatPipe}}</td>
            <td>{{appointment?.dateTimeTo | fullDateFormatPipe}}</td>
            <td>{{appointment?.duration}}</td>
        </tr>
        </tbody>
    </table>
</div>
