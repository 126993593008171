<div class="container-fluid resource-swap-wrapper list-page-wrapper p-0 pb-1">
    <!-- Search filters -->
    <div class="row resource-swap-filters-container ml-0 mr-0 mb-1">
        <app-resource-swap-filters *ngIf="resourceSwapUtils.filtersOptions.areFiltersReady"
                                    (search)="onSearch($event)"></app-resource-swap-filters>
    </div>
    <div class="resource-swap-appointment-list-title-row">
        <!-- Title / instructions -->
        <div class="resource-swap-appointment-list-left-container">
            <span *ngIf="!resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState
                        && !resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState"
                  class="resource-swap-appointment-list-label" translate="label.appointments"></span>
            <span *ngIf="resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState
                        && !resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState"
                  class="resource-swap-appointment-list-message text-high-contrast" translate="label.selectAResourceAndSearchForAppointments">
            </span>
            <span *ngIf="resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState"
                  class="resource-swap-appointment-list-message mt-3 text-high-contrast" translate="label.appointmentListNotFound">
            </span>
            <div *ngIf="!resourceSwapUtils.swapResourcesAppointmentListItemOptions.isBeforeSearchState
                        && !resourceSwapUtils.swapResourcesAppointmentListItemOptions.isNotFoundState"
                 class="d-flex align-items-center toggle-all-container">
                <input id="resource-swap-toggle-all-appointments-checkbox" class="resource-swap-appointment-list-checkbox ml-2 mr-2" type="checkbox"
                       [checked]="false"
                       [disabled]="shouldDisableEverything"
                       (change)="onToggleAllAppointments($event)">
                <label class="mb-0 text-high-contrast" translate="label.toggleAllAppointments" for="resource-swap-toggle-all-appointments-checkbox"></label>
            </div>
        </div>
        <!-- Resource for all replacements -->
        <div class="resource-swap-option-all-resources-select-container mx-2">
            <span *ngIf="resourceSwapUtils?.swapResourcesOptions?.appointmentsSwapResources?.length <= 0"
                  class="resource-swap-options-message text-high-contrast"
                  translate="label.clickOnSearchForReplacements">
            </span>
            <div class="resource-swap-success-message-container"
                 *ngIf="resourceSwapUtils?.swapResourcesOptions?.appointmentsSwapResources?.length > 0 && shouldDisableEverything">
                <span class="resource-swap-options-message theme-color"
                      translate="label.someResourcesWereSuccessfullySwappedPleaseRefreshTheData">
                </span>
                <!-- Refresh button -->
                <div class="btn theme-btn float-right refresh-button"
                     ngbPopover="{{'label.refresh'| translate}}"
                     placement="left"
                     popoverClass="popover-shadow"
                     triggers="mouseenter:mouseleave"
                     (click)="refreshData()">
                    <i class="fas fa-sync-alt"></i>
                </div>
            </div>
            <div *ngIf="resourceSwapUtils?.swapResourcesOptions?.appointmentsSwapResources?.length > 0 && !shouldDisableEverything">
                <ng-select class="ml-2 text-high-contrast"
                           [ngClass]="!!resourceSwapUtils.swapResourcesOptions.selectsNgValues.selectResourceForAllReplacementsValue ? 'ng-select-has-value' : null"
                           dropdownPosition="bottom"
                           appendTo="body"
                           [multiple]="false"
                           [searchable]="true"
                           [clearable]="false"
                           [disabled]="shouldDisableEverything"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="resourceSwapUtils.swapResourcesOptions.selectsNgValues.selectResourceForAllReplacementsValue"
                           (ngModelChange)="onSelectResourceForAllReplacement($event)">
                    <ng-option [value]="'noValue'"><span translate="label.resourceForAllReplacements"></span></ng-option>
                    <ng-option *ngFor="let resource of resourceSwapUtils?.swapResourcesOptions?.allAvailableSwapResources"
                               [value]="resource">
                        <span>{{resource.name}}</span>
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <span class="vertical-line-small"></span>
    <div class="resource-swap-results-container custom-scrollbar">
        <div class="resource-swap-appointment-list-container">
            <!-- Appointment List -->
            <app-resource-swap-appointment-list
                    [swapResourcesFiltersSearch]="searchSwapResourcesAppointmentsFilterValues">
            </app-resource-swap-appointment-list>
        </div>
        <div class="resource-swap-options-container-2">
            <app-resource-swap-options *ngIf="resourceSwapUtils?.swapResourcesAppointmentListItemOptions?.swapResourcesAppointmentList?.length > 0">
            </app-resource-swap-options>
        </div>
    </div>
</div>
