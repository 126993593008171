import {Injectable} from '@angular/core';
import {
    AccessConfigProvider, IConfigDataService,
    ODataQueryObjectType,
    SystemConfigProvider,
    TenantConfigProvider, UserProvider
} from 'sked-base';
import {SessionStorageService, LocalStorageService} from '@efaps/ngx-store';
import {BehaviorSubject, forkJoin, of} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import * as lodash from 'lodash';
import {constants} from '../constants/constants';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
// Whenever changing this Service make sure the interface in sked is updated as well
export class ConfigDataService implements IConfigDataService {

    constructor(private accessConfigProvider: AccessConfigProvider,
                private tenantConfigProvider: TenantConfigProvider,
                private systemConfigProvider: SystemConfigProvider,
                private userProvider: UserProvider,
                private sessionStorageService: SessionStorageService,
                private localStorageService: LocalStorageService) {
    }

    initialConfigRequestFinish: BehaviorSubject<any> = new BehaviorSubject(false);

    get systemConfig() {
        const systemConfig = this.getConfigFromStorage(constants.SYSTEM_CONFIG_STORAGE_NAME);
        return systemConfig || null;
    }

    get featureAccessConfig() {
        const featureAccessConfig = this.getConfigFromStorage(constants.ACCESS_CONFIG_STORAGE_NAME);
        return featureAccessConfig || null;
    }

    get activeActivities() {
        const activeActivities = this.getConfigFromStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME);
        return activeActivities || null;
    }

    get userData() {
        const userData = this.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        return userData || null;
    }

    getInitialConfigData(userId: string) {
        return forkJoin([
            this.getAccessConfigData(),
            this.getTenantConfig(),
            this.getSystemConfig(),
            this.getUserActivities(userId),
            this.getUserByToken()])
            .pipe(
                tap(() => {
                    this.initialConfigRequestFinish.next(true);
                }),
                map(([accessConfig, tenantConfig, systemConfig, activeActivities, userInfo]) => {
                    return {accessConfig, tenantConfig, systemConfig, activeActivities, userInfo};
                })
            );
    }

    getAccessConfigData() {
        // get accessConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.ACCESS_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.accessConfigProvider.getAccessConfig()
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.setConfigToStorage(constants.ACCESS_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    getTenantConfig() {
        // get accessConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.TENANT_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.tenantConfigProvider.getTenantConfig()
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.setConfigToStorage(constants.TENANT_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    getSystemConfig() {
        // get sysConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.SYSTEM_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.systemConfigProvider.getSystemConfigV3(this.getSystemConfigQueryFilter())
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.setConfigToStorage(constants.SYSTEM_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    isActivityActive(activityName: string): boolean {
        const activeActivities = this.activeActivities;
        return activeActivities?.indexOf(activityName) > -1;
    }

    getUserActivities(userId: string) {
        // get user active activities from browser storage
        const storedActiveActivities = this.getConfigFromStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME);
        if (!lodash.isEmpty(storedActiveActivities)) {
            return of(storedActiveActivities);
        } else {
            // const url = environment.BASE_URL + 'Teams?$expand=Users&$filter=((Users/any(x:%20x/Id%20eq%20' + userId + ')))';
            const filter: ODataQueryObjectType = {} as ODataQueryObjectType;
            filter.filter = {Users: {any: [{Id: {type: 'guid', value: userId}}]}};

            const url = environment.BASE_URL + 'Users/GetUserActivitiesByToken';
            return this.userProvider.getUserActivitiesByToken()
                .pipe(
                    tap((response) => {
                            if (response && response.value) {
                                this.setConfigToStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME, response.value);
                            } else {
                                this.setConfigToStorage(constants.ACTIVE_ACTIVITIES_STORAGE_NAME, []);
                            }
                        }
                    ));
        }
    }

    getUserByToken() {
        const storedUserInfo = this.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        if (!lodash.isEmpty(storedUserInfo)) {
            return of(storedUserInfo);
        } else {
            return this.userProvider.getUserByToken(this.getQueryFilterForUserInfo())
                .pipe(
                    take(1),
                    tap((response) => {
                            if (response) {
                                this.setConfigToStorage(constants.USER_INFO_STORAGE_NAME, response);
                            } else {
                                this.setConfigToStorage(constants.USER_INFO_STORAGE_NAME, {});
                            }
                            return response;
                        }
                    ));
        }
    }

    isFeatureActive(featureName: string): boolean {
        const featureAccess = this.featureAccessConfig;
        if (featureAccess === null) {
            return false;
        }
        return featureAccess[featureName] && featureAccess[featureName] === 'true';
    }

    getConfigFromStorage(storageName: string) {
        return this.sessionStorageService.get(storageName);
    }

    private setConfigToStorage(storageName: string, value: any) {
        this.sessionStorageService.set(storageName, value);
    }

    checkActivityByRoute(route: string) {
        const userActivitiesList = this.activeActivities;
        const accessByActivityPerRoute = {
            systemConfig: 'SystemConfigurationRead', // route name: Activity name
            calendarOverview: 'ResourceCalendarNew',
            graphicalResourcePlanner: 'AdvancedBookingPlanner',
            generateSkedTask: 'AdvancedBookingPlannerTaskManager',
            grpBookingAppointment: 'AdvancedBookingPlanner',
            slotsManagement: 'AppointmentBooking',
            resourceCalendar: 'ResourceCalendarNew',
            resourceUtilization: 'ResourceUtilization',
            centers: 'CenterRead',
            createCenter: 'CenterRead',
            tags: 'TagRead',
            coveragePlans: 'CoveragePlanRead',
            createCoveragePlan: 'CoveragePlanRead',
            coverageCompanies: 'CoverageCompanyRead',
            createCoverageCompany: 'CoverageCompanyRead',
            activityPlanTemplates: 'ActivityPlanTemplateRead',
            createActivityPlanTemplate: 'ActivityPlanTemplateRead',
            bookingFilters: 'BookingFilterRead',
            relationPairs: 'RelationPairRead',
            createRelationPair: 'RelationPairRead',
            objectDetails: 'ObjectDetailRead',
            createObjectDetail: 'ObjectDetailRead',
            export: 'GetJobResult',
            appointmentTypeDefinitions: 'AppointmentTypeDefinitionRead',
            createAppointmentTypeDefinition: 'AppointmentTypeDefinitionRead',
            multiAppointmentsTemplate: 'MultiAppointmentTemplateRead',
            createMultiAppointmentTemplate: 'MultiAppointmentTemplateRead',
            appointmentTypes: 'AppointmentTypeRead',
            createAppointmentType: 'AppointmentTypeRead',
            appointmentStatusTransitionReasons: 'AppointmentStatusTransitionReasonRead',
            createAppointmentStatusTransitionReason: 'AppointmentStatusTransitionReasonRead',
            availability: 'AvailabilityRead',
            createAvailability: 'AvailabilityRead',
            availabilityHistory: 'AvailabilityRead',
            createAvailabilitySplit: 'AvailabilityRead',
            availabilityBlockedAppointmentsPreview: 'AvailabilityRead',
            approveAvailability: 'AvailabilityApprove',
            viewApproveAvailability: 'AvailabilityApprove',
            exclusion: 'ExclusionRead',
            createExclusion: 'ExclusionRead',
            exclusionHistory: 'ExclusionRead',
            exclusionBlockedAppointmentsPreview: 'ExclusionRead',
            approveExclusion: 'ExclusionApprove',
            viewApproveExclusion: 'ExclusionApprove',
            exclusionReasons: 'ExclusionReasonRead',
            createExclusionReason: 'ExclusionReasonRead',
            capacityPlannerResource: 'GetPlannedCapacity',
            availabilityExclusionExport: 'AvailabilityExport',
            patientAudit: 'PatientAudit',
            patientDashboard: 'PatientRead',
            resourceTypes: 'ResourceTypeRead',
            createResourceType: 'ResourceTypeRead',
            capacityPlannerRoom: 'GetPlannedCapacityForRooms',
            roomReservationExport: 'GenerateExportJobRoomAvailabilities',
            roomReservation: 'ReadRoomReservation',
            areas: 'AreaRead',
            createArea: 'AreaRead',
            specialities: 'SpecialityRead',
            createSpeciality: 'SpecialityRead',
            services: 'ServiceRead',
            createService: 'ServiceRead',
            subServices: 'SubServiceRead',
            createSubService: 'SubServiceRead',
            teams: 'TeamRead',
            createTeam: 'TeamRead',
            users: 'UserRead',
            createUser: 'UserRead',
            holidays: 'HolidayRead',
            createHoliday: 'HolidayRead',
            holidayCalendars: 'HolidayCalendarRead',
            createHolidayCalendar: 'HolidayCalendarRead',
            eventActions: 'EventActionRead',
            createEventAction: 'EventActionRead',
            dataStore: 'DataStoreRead',
            createDataStore: 'DataStoreRead',
            eventRules: 'AppointmentEventRuleRead',
            taskList: 'SkedTaskRead',
            taskDetails: 'SkedTaskRead',
            apiIntegration: 'read-apim-integration-information',
            createPatient: 'PatientRead',
            patientAppointmentList: 'PatientRead',
            appointmentList: 'AppointmentList',
            patientMerge: 'PatientMerge',
            timeWindowsForChannel: 'KeyTimeWindowReadActivity',
            createTimeWindowForChannel: 'KeyTimeWindowReadActivity',
            excelImport: 'UseImportFeatureAllowed',
            timeWindowsForSpecialitiesAndAreas: 'TimeWindowSpecialityAreaRead',
            createTimeWindowForSpecialityAndArea: 'TimeWindowSpecialityAreaRead',
            timeWindowsForResource: 'KeyResourceChannelRestrictionReadActivity',
            createTimeWindowForResource: 'KeyResourceChannelRestrictionReadActivity',
            rules: 'RuleRead',
            createRule: 'RuleRead',
            ruleSet: 'RuleRead',
            availabilityOversellingDefinitions: 'OversellingDefinitionRead',
            multiResourceBluePrints: 'MultiResourceBluePrintRead',
            createMultiResourceBluePrint: 'MultiResourceBluePrintRead',
            multiResourceCombinations: 'MultiResourceCombinationActivityRead',
            createMultiResourceCombination: 'MultiResourceCombinationActivityRead',
            exportAppointments: 'AppointmentExport',
            resourceWorkSchedules: 'ResourceWorkScheduleRead',
            createResourceWorkSchedule: 'ResourceWorkScheduleRead',
            resourceSwap: 'ResourceSwap',
            resources: 'ResourceRead',
            createResource: 'ResourceRead',
            multiAppointmentBooking: 'MultiAppointmentBooking',
            bookMultiAppointment: 'MultiAppointmentBooking',
            rulesWeightedCombinations: 'RuleWeightCombinationRead',
            changeLogs: 'ChangeLogObjectHistoryRead',
            activityPlans: 'ActivityPlanRead',
            addActivityPlan: 'ActivityPlanRead',
        };

        const activityByRoute = accessByActivityPerRoute[route];
        if (activityByRoute === undefined) {
            return true;
        }
        const activityFound = lodash.indexOf(userActivitiesList, activityByRoute);
        return activityFound > -1;
    }

    //check feature access is enabled for tenant
    checkFeatureAccessByRoute(routeName: string) {
        const featureAccess = this.featureAccessConfig;
        const key = this.getFeatureAccessForSelectedRoute(routeName);

        if (featureAccess[key] !== undefined) {
            return featureAccess[key] === 'true';
        } else {
            return false;
        }
    }

    private getFeatureAccessForSelectedRoute(route: string) {
        const routeFeature = {
            //routeName : 'feature access for this route name'
            capacityPlannerResource: 'admin-capacityOverview-resource',
            capacityPlannerRoom: 'admin-capacityOverview-room',
            roomReservation: 'admin-room-reservation',
            export: 'admin-export-view',
            roomReservationExport: 'admin-export-roomReservation',
            multiAppointmentsTemplate: 'admin-multiAppointments-template',
            createMultiAppointmentTemplate: 'admin-multiAppointments-template',
            services: 'admin-services',
            createService: 'admin-services',
            subServices: 'admin-subServices',
            createSubService: 'admin-subServices',
            specialities: 'admin-specialities',
            createSpeciality: 'admin-specialities',
            areas: 'admin-areas',
            createArea: 'admin-areas',
            teams: 'admin-teams',
            createTeam: 'admin-teams',
            users: 'admin-employees',
            createUser: 'admin-employees',
            tags: 'admin-new-tag',
            createTag: 'admin-new-tag',
            resourceCalendar: 'resourceCalendarNew',
            resourceTypes: 'admin-new-resourceType',
            createResourceType: 'admin-new-resourceType',
            appointmentTypes: 'admin-new-appointmentType',
            createAppointmentType: 'admin-new-appointmentType',
            appointmentTypeDefinitions: 'admin-new-appointmentTypeDefinition',
            createAppointmentTypeDefinition: 'admin-new-appointmentTypeDefinition',
            coverageCompanies: 'admin-new-coverageCompanies',
            createCoverageCompany: 'admin-new-coverageCompanies',
            coveragePlans: 'admin-new-coveragePlans',
            createCoveragePlan: 'admin-new-coveragePlans',
            centers: 'admin-new-centers',
            createCenter: 'admin-new-centers',
            availability: 'admin-availabilities',
            createAvailability: 'admin-availabilities',
            approveAvailability: 'admin-availabilities',
            createAvailabilitySplit: 'admin-availabilities',
            viewApproveAvailability: 'admin-availabilities',
            approveAvailabilitySplit: 'admin-availabilities',
            availabilityBlockedAppointmentsPreview: 'admin-availabilities',
            exclusionBlockedAppointmentsPreview: 'admin-availabilities',
            availabilityHistory: 'admin-availabilities',
            exclusion: 'admin-availabilities',
            createExclusion: 'admin-availabilities',
            approveExclusion: 'admin-availabilities',
            viewApproveExclusion: 'admin-availabilities',
            exclusionHistory: 'admin-availabilities',
            patientAudit: 'backoffice-new-patients',
            patientDashboard: 'backoffice-new-patients',
            holidays: 'admin-new-Holidays',
            createHoliday: 'admin-new-Holidays',
            exclusionReasons: 'admin-exclusions-exclusionReasonManagement',
            createExclusionReason: 'admin-exclusions-exclusionReasonManagement',
            holidayCalendars: 'admin-new-holidayCalendars',
            createHolidayCalendar: 'admin-new-holidayCalendars',
            appointmentStatusTransitionReasons: 'admin-new-appointmentStatusTransitionReasons',
            createAppointmentStatusTransitionReason: 'admin-new-appointmentStatusTransitionReasons',
            availabilityExclusionExport: 'admin-availability-export',
            systemConfig: 'admin-new-systemConfig',
            activityPlanTemplates: 'admin-new-activityPlanTemplates',
            createActivityPlanTemplate: 'admin-new-activityPlanTemplates',
            bookingFilters: 'booking-filters',
            slotsManagement: 'appointment-booking',
            bookAppointment: 'appointment-booking',
            eventActions: 'event-management',
            createEventAction: 'event-management',
            dataStore: 'secret-store',
            createDataStore: 'secret-store',
            eventRules: 'rule-management',
            patientDocuments: 'patient-documents',
            relationPairs: 'admin-relationPair',
            createRelationPair: 'admin-relationPair',
            objectDetails: 'admin-object-details',
            createObjectDetail: 'admin-object-details',
            resourceUtilization: 'resource-utilization',
            resourceUtilizationManageAppointment: 'resource-utilization',
            graphicalResourcePlanner: 'advancedbookingplanner',
            generateSkedTask: 'advancedbookingplanner',
            grpBookingAppointment: 'advancedbookingplanner',
            taskList: 'sked-tasks',
            taskDetails: 'sked-tasks',
            apiIntegration: 'admin-system-config-apikeys',
            createPatient: 'backoffice-new-patients',
            patientAppointmentList: 'backoffice-new-patients',
            appointmentList: 'appointment-list',
            patientMerge: 'backoffice-new-patients',
            timeWindowsForChannel: 'admin-timeWindow',
            createTimeWindowForChannel: 'admin-timeWindow',
            excelImport: 'admin-excelDataImporter',
            rules: 'rule-management',
            createRule: 'rule-management',
            ruleSet: 'rule-management',
            timeWindowsForSpecialitiesAndAreas: 'admin-timeWindow',
            createTimeWindowForSpecialityAndArea: 'admin-timeWindow',
            timeWindowsForResource: 'admin-timeWindow',
            createTimeWindowForResource: 'admin-timeWindow',
            availabilityOversellingDefinitions: 'overselling',
            multiResourceBluePrints: 'admin-multiResourceBlueprint',
            createMultiResourceBluePrint: 'admin-multiResourceBlueprint',
            multiResourceCombinations: 'admin-multiResourceCombination',
            createMultiResourceCombination: 'admin-multiResourceCombination',
            exportAppointments: 'backoffice-appointment-export',
            resourceWorkSchedules: 'admin-resourceWorkSchedule',
            createResourceWorkSchedule: 'admin-resourceWorkSchedule',
            resourceSwap: 'resource-swap',
            resources: 'admin-resources',
            createResource: 'admin-resources',
            multiAppointmentBooking: 'multi-appointment-booking',
            bookMultiAppointment: 'multi-appointment-booking',
            rulesWeightedCombinations: 'rule-management-weightedcombination',
            createRulesWeightedCombination: 'rule-management-weightedcombination',
            actionExecutionLogs: 'action-execution-logs-overview',
            changeLogs: 'changelog-object-history',
            activityPlans: 'activityPlans',
            addActivityPlan: 'activityPlans',
        };

        return routeFeature[route];
    }

    private getSystemConfigQueryFilter() {
        const query: any = {};
        query.select = ['Id', 'Name', 'Value', 'RowVersion'];
        return query;
    }

    setValueToLocalStorage(key: string, value: string) {
        this.localStorageService.set(key, value);
    }

    getValueFromLocalStorage(key: string) {
        return this.localStorageService.get(key);
    }

    clearBrowserStorages() {
        this.localStorageService.clear();
        this.sessionStorageService.clear();
    }

    private getQueryFilterForUserInfo(): ODataQueryObjectType {
        return {
            select: ['Id', 'Username', 'FirstName', 'LastName', 'System', 'Email'],
            expand: {
                Centers: {select: ['Id', 'Name']},
                Resource: {select: ['Id', 'Name']},
                Team: {select: ['Id', 'Name', 'OrganizationalRole']}
            }
        };
    }
}
