<div class="container-fluid appointment-type-definition-md-wrapper overview-screen-md pb-1">
    <div class="d-flex justify-content-between">
      <h3 translate="label.appointmentTypeDefinitions"></h3>
      <div class="filters-section d-flex">
          <sbase-filter-wrapper
                  class="d-flex"
                  [options]="appointmentTypeDefinitionMdUtils.filterWrapperOptions"
                  [updateFiltersValue]="updateFiltersValue"
                  (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"></sbase-filter-wrapper>
          <button class="reset-button btn" (click)="onClearFilters()">
              <i class="far fa-trash-alt"></i>
              <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
          </button>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn theme-alt-btn border-0 create-appointment-type-definition-button"
              [activityDisplay]="APPOINTMENT_TYPE_DEFINITION+constants.CREATE"
              (click)="createAppointmentTypeDefinition()" translate="label.createNew">
      </button>
    </div>

    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
      <table class="table">
        <thead>
        <tr>
          <th translate="label.appointmentType"></th>
          <th translate="label.resource"></th>
          <th translate="label.center2"></th>
          <th translate="label.consumesPlannedCapacity"></th>
          <th translate="label.quantity"></th>
          <th translate="label.duration"></th>
          <th class="text-center" translate="label.action"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let appointmentTypeDefinition of appointmentTypeDefinitionMdList"
            [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
            sbaseMarkTableRowOnHover
            (click)="viewAppointmentTypeDefinition(appointmentTypeDefinition)">
          <td>{{appointmentTypeDefinition.appointmentType?.name}}
            <span *ngIf="isOvercapacity(appointmentTypeDefinition)"
              class="fa fa-exclamation-triangle overcapacity-warning-triangle"
              ngbTooltip="{{'label.warning.overcapacityAppointmentType'| translate}}"
              placement="right">
            </span>
          </td>
          <td>{{appointmentTypeDefinition.resource?.name}}</td>
          <td>{{appointmentTypeDefinition.center?.name}}</td>
          <td>{{appointmentTypeDefinition.appointmentType?.consumesPlannedCapacity | yesNoPipe | translate}}</td>
          <td>{{appointmentTypeDefinition.quantity}}</td>
          <td>{{appointmentTypeDefinition.duration}}</td>
          <td class="text-center" (click)="$event.stopPropagation()">
            <button type="button" class="btn" placement="left" [ngbPopover]="popContent" popoverClass="popover-shadow">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <ng-template #popContent>
              <ul class="list-style-none m-0 p-0 custom-drop-down">
                <li>
                  <button type="button" class="btn" translate="label.view" (click)="viewAppointmentTypeDefinition(appointmentTypeDefinition)"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.edit" (click)="editAppointmentTypeDefinition(appointmentTypeDefinition)"
                          [activityDisplay]="APPOINTMENT_TYPE_DEFINITION+constants.UPDATE"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.delete"
                          (click)="displayConfirmDeleteItemModal(appointmentTypeDefinition.id)"
                          [activityDisplay]="APPOINTMENT_TYPE_DEFINITION+constants.DELETE"></button>
                </li>
                <li>
                  <button type="button" class="btn" translate="label.changeLog"
                          (click)="displayChangeLog(appointmentTypeDefinition.id, EntityTypeEnum.appointmentTypeDefinition, appointmentTypeDefinition.name)"
                          [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                </li>
              </ul>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div class="pagination-component text-high-contrast mt-4" *ngIf="totalTableItems > 0">
      <ngb-pagination [collectionSize]="totalTableItems" [(page)]="appointmentTypeDefinitionMdUtils.tableFilters.currentPage" [maxSize]="5"
                      [pageSize]="appointmentTypeDefinitionMdUtils.tableFilters.itemsPerPage" [boundaryLinks]="true"
                      (pageChange)="onChangePagination($event)"></ngb-pagination>

      <div class="btn-group items-per-page-group">
        <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
        <span class="btn-group ml-2">
                    <span class="dropdown-toggle page-filter cursor-pointer" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true" (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}">
                        <strong>{{appointmentTypeDefinitionMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
              <div [exclude]="'.page-filter'" [ngClass]="{'show': showItemsPerPageDropdown}"
                   (clickOutside)=onClickedOutsideItemsPerPageFilter($event) class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a *ngFor="let page of itemsPerPageList" class="dropdown-item"
                     (click)="changeNumberOfItemsPerPage(page)">{{page}}</a>
              </div>
              </span>
      </div>
      <!-- ============================================================== -->
      <!-- End Page Filter -->
    </div>

  </div>
