import {Component, OnInit} from '@angular/core';
import {JobProvider} from 'sked-base';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-export-page',
    templateUrl: './export-page.component.html',
    styleUrls: ['./export-page.component.scss']
})
export class ExportPageComponent implements OnInit {
    usersJobList: any = [];

    constructor(
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        public jobProvider: JobProvider
    ) {
    }

    ngOnInit() {
        this.getUsersJob();
    }

    //  get job users data and display into table
    getUsersJob() {
        this.ngxLoader.start();
        this.jobProvider.getJobsForUser()
            .pipe(take(1))
            .subscribe((response: any) => {
                this.usersJobList = response?.value?.map(userJobList => ({
                    ...userJobList,
                    data: JSON.parse(userJobList.data)
                }));
                this.ngxLoader.stop();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to download the export
    downloadReport(id) {
        this.jobProvider.getJobResult('jobId=' + id)
            .pipe(take(1))
            .subscribe((response) => {
                const element = document.createElement('a');
                const downloadUrl = URL.createObjectURL(response.file);
                element.href = downloadUrl;
                element.download = response.fileName;
                document.body.appendChild(element);
                // start download
                element.click();
                // after certain amount of time remove this object!!!
                setTimeout(() => {
                    URL.revokeObjectURL(downloadUrl);
                }, 100);
            }, err => {
                this.messagesService.handlingErrorMessage(err);
            });
    }
}
