import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {SessionStorageService} from '@efaps/ngx-store';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private sessionStorageService: SessionStorageService,
    ) {
    }

    ngOnInit() {
        const redirectedAutomaticallyAfterSignIn = this.sessionStorageService.get('redirectedAutomaticallyAfterSignIn');
        this.sessionStorageService.remove('redirectedAutomaticallyAfterSignIn');
        if (redirectedAutomaticallyAfterSignIn) {
            // Automatically redirected on 404 page, so we redirect to dashboard
            this.goToHome();
        }
    }

    goToHome(): void {
        this.router.navigate(['/dashboard']);
    }

    ngOnDestroy(): void {
    }
}
