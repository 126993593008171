<div class="container-fluid create-object-detail-wrapper create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="create-object-detail-header">
        <h3 class="create-object-detail-title">{{screenTemplateLayout.pageTitle|translate}} <span class="text-lowercase" translate="label.objectDetailsTab.title"></span></h3>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form name="form" novalidate (ngSubmit)="saveObjectDetailData(objectDetailItem)">
        <!--Body-->
        <!-- =========================================== -->
        <div class="create-entity-md-section">
            <ng-container *ngIf="screenTemplateLayout.action === constants.VIEW">
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.generalInformation"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td translate="label.name"></td>
                                    <td class="text-right">{{objectDetailItem.name}}</td>
                                </tr>
                                <tr>
                                    <td translate="label.objectDetailsTab.mustShow"></td>
                                    <td class="text-right">
                                        <span *ngIf="objectDetailItem.mustShow" translate="label.yes"></span>
                                        <span *ngIf="!objectDetailItem.mustShow" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.objectDetailsTab.sendByEmail"></td>
                                    <td class="text-right">
                                        <span *ngIf="objectDetailItem.sendByEmail" translate="label.yes"></span>
                                        <span *ngIf="!objectDetailItem.sendByEmail" translate="label.no"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td translate="label.objectDetailsTab.sendByEmail"></td>
                                    <td class="text-right">
                                        <span *ngIf="objectDetailItem.sendByEmail" translate="label.yes"></span>
                                        <span *ngIf="!objectDetailItem.sendByEmail" translate="label.no"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label translate="label.details"></label>
                            <table class="table border mt-2">
                                <tbody>
                                <tr>
                                    <td [innerHTML]="objectDetailItem.content"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="screenTemplateLayout.action !== constants.VIEW">
                <div class="row">
                    <!-- Name -->
                    <div class="col-5 ">
                        <div class="form-group">
                            <label translate="label.name"></label>
                            <input type="text" name="name" class="input-element"
                                   [(ngModel)]="objectDetailItem.name"
                                   [ngClass]="{'input-element-has-error': !objectDetailItem.name}"
                                   placeholder="{{'label.name'|translate}}"
                                   autocomplete="off">
                            <div *ngIf="!objectDetailItem.name" class="help-block">
                                <span translate="label.error.required"></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-1"></div>

                    <!-- Must show -->
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.objectDetailsTab.mustShow"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="objectDetailItem.mustShow"
                                           name="mustShow"
                                    >
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Send by email -->
                    <div class="col-3">
                        <div class="form-group">
                            <label translate="label.objectDetailsTab.sendByEmail"></label>
                            <div class="switch">
                                <label>
                                    <span translate="label.no"></span>
                                    <input type="checkbox" checked
                                           [(ngModel)]="objectDetailItem.sendByEmail"
                                           name="sendByEmail">
                                    <span class="lever switch-col-black"></span>
                                    <span translate="label.yes"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Object Detail Editor -->
                <div class="row" *ngIf="!!textEditorConfig">
                    <div class="col-12 form-group">
                        <angular-editor [placeholder]="('label.textEditorPlh'| translate )"
                                        [(ngModel)]="objectDetailItem.content"
                                        [config]= "textEditorConfig"
                                        name="objectDetailTextEditor">
                        </angular-editor>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn mr-3" type="button"
                    (click)="goToParentPage()"
                    [ngClass]="screenTemplateLayout.cancelOrBackButton === 'button.back' ? 'back-btn' : 'close-btn'"
                    translate="{{screenTemplateLayout.cancelOrBackButton}}">
            </button>
            <button class="btn theme-btn" type="submit"
                    [disabled]="!objectDetailItem.name"
                    [ngClass]="{'disabled-button': !objectDetailItem.name}"
                    [hidden]="screenTemplateLayout.action === constants.VIEW"
                    translate="{{screenTemplateLayout.createOrSaveButton}}">
            </button>
            <button class="btn theme-btn" type="button"
                    *ngIf="screenTemplateLayout.action === constants.VIEW"
                    (click)="goToEdit()"
                    [activityDisplay]="'ObjectDetailUpdate'">
                <span translate="label.edit"></span>
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
