import {Component, OnInit, OnDestroy} from '@angular/core';
import {constants} from 'src/app/shared/constants/constants';
import {AppointmentStatusTransitionReasonType, AppointmentStatusTransitionReasonProvider, HolidayTypeEnum} from 'sked-base';
import {ScreenTemplateLayoutType} from 'src/app/data-model/general.type';
import {MessagesService} from 'src/app/shared/services/messages.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AppointmentStatusTransitionReasonMdUtils} from '../appointment-status-transition-reason-md-util';
import {Router} from '@angular/router';
import {GeneralUtils} from 'src/app/shared/utils/general.utils';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AppointmentStatusTransitionReasonCategoryEnum} from 'sked-base';
import {AppointmentStatusTransitionSituationEnum} from 'sked-base';

@AutoUnsubscribe()
@Component({
    selector: 'app-create-appointment-status-transition-reason',
    templateUrl: './create-appointment-status-transition-reason.component.html',
    styleUrls: ['./create-appointment-status-transition-reason.component.scss']
})
export class CreateAppointmentStatusTransitionReasonComponent implements OnInit, OnDestroy {
    constants = constants;
    initialAppointmentStatusTransitionReason: AppointmentStatusTransitionReasonType;
    appointmentStatusTransitionReasonItem: AppointmentStatusTransitionReasonType = {} as AppointmentStatusTransitionReasonType;
    screenTemplateLayout: ScreenTemplateLayoutType;
    categoryEnumList = Object.values(AppointmentStatusTransitionReasonCategoryEnum);
    situationEnumList = Object.values(AppointmentStatusTransitionSituationEnum);

    constructor(
        private messagesService: MessagesService,
        private ngxLoader: NgxUiLoaderService,
        private appointmentStatusTransitionReasonMdUtils: AppointmentStatusTransitionReasonMdUtils,
        private appointmentStatusTransitionReasonProvider: AppointmentStatusTransitionReasonProvider,
        private router: Router,
        public generalUtils: GeneralUtils
    ) {
    }

    ngOnInit() {
        this.setupInitialState();
    }

    ngOnDestroy(): void {
    }

    saveAppointmentStatusTransitionReasonData(appointmentStatusTransitionReason: AppointmentStatusTransitionReasonType) {
        const isTemplateValid = this.validateFields(appointmentStatusTransitionReason);
        if (isTemplateValid) {
            if (this.screenTemplateLayout.action === constants.CREATE) {
                this.createAppointmentStatusTransitionReason(appointmentStatusTransitionReason);
            } else if (this.screenTemplateLayout.action === constants.EDIT) {
                if (lodash.isEqual(this.initialAppointmentStatusTransitionReason, appointmentStatusTransitionReason)) {
                    this.messagesService.success('toastr.success.appointmentStatusTransitionReasonEdit', true);
                    this.goToParentPage();
                } else {
                    this.editAppointmentStatusTransitionReason(this.initialAppointmentStatusTransitionReason, appointmentStatusTransitionReason);
                }
            }
        }
    }

    goToParentPage() {
        this.router.navigate(['/appointmentStatusTransitionReasons']);
    }

    onCategoryValueChanged(selectCategory: AppointmentStatusTransitionReasonCategoryEnum) {
        this.appointmentStatusTransitionReasonItem.category = this.generalUtils.isEmpty(selectCategory) ?
            'noValue' as AppointmentStatusTransitionReasonCategoryEnum : selectCategory;
    }

    goToEdit() {
        history.replaceState({
            appointmentStatusTransitionReason: this.appointmentStatusTransitionReasonItem,
            action: this.constants.EDIT
        }, '');
        this.ngOnInit();
    }

    // function to create the new AppointmentStatusTransitionReason
    private createAppointmentStatusTransitionReason(appointmentStatusTransitionReason: AppointmentStatusTransitionReasonType) {
        this.ngxLoader.start();
        this.appointmentStatusTransitionReasonProvider.addEntry(appointmentStatusTransitionReason)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.newAppointmentStatusTransitionReasonAdded', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    // function to update the AppointmentStatusTransitionReason
    private editAppointmentStatusTransitionReason(oldAppointmentStatusTransitionReason: AppointmentStatusTransitionReasonType, newAppointmentStatusTransitionReason: AppointmentStatusTransitionReasonType) {
        this.ngxLoader.start();
        this.appointmentStatusTransitionReasonProvider.updateEntry(oldAppointmentStatusTransitionReason, newAppointmentStatusTransitionReason)
            .pipe(take(1))
            .subscribe(() => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.appointmentStatusTransitionReasonEdit', true);
                this.goToParentPage();
            }, err => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(err);
            });
    }

    private validateFields(appointmentStatusTransitionReasonItem: AppointmentStatusTransitionReasonType): boolean {
        return !!(appointmentStatusTransitionReasonItem &&
            appointmentStatusTransitionReasonItem.name &&
            appointmentStatusTransitionReasonItem.others !== undefined &&
            !this.generalUtils.isSelectedNoValueOption(appointmentStatusTransitionReasonItem.category));
    }

    private setupInitialState() {
        if (history.state && history.state.appointmentStatusTransitionReason) {
            this.initialAppointmentStatusTransitionReason = history.state.appointmentStatusTransitionReason;
            this.appointmentStatusTransitionReasonItem = lodash.cloneDeep(history.state.appointmentStatusTransitionReason);
            if (history.state.action === constants.VIEW) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', constants.VIEW, undefined, 'button.back');
            } else if (history.state.action === constants.EDIT) {
                this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', constants.EDIT, 'button.save', 'label.close');
            }
        } else {
            this.appointmentStatusTransitionReasonItem = this.appointmentStatusTransitionReasonMdUtils.getInitialAppointmentStatusTransitionReason();
            this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', constants.CREATE, 'label.create', 'label.close');
        }
    }
}
