import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {HolidayTypeEnum} from 'sked-base';
import * as moment from 'moment';
import {HolidayMdUtils} from '../holiday-md-util';

@Injectable()
export class NgbDateFixedOrMovableFormatter extends NgbDateParserFormatter {
  holidayType: HolidayTypeEnum;

  constructor(
    private holidayMdUtils: HolidayMdUtils,
  ) {
    super();
    this.holidayMdUtils.holidayTypeSubscription = this.holidayMdUtils.holidayTypeSubject.subscribe((holidayType: HolidayTypeEnum) => {
      this.holidayType = holidayType;
    });
  }

  parse(value: string): NgbDateStruct {
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const dateMonthFromZero = {year: date.year, month: date.month - 1, day: date.day};
    if (this.holidayType === HolidayTypeEnum.Fixed) {
      // @ts-ignore
      return moment(dateMonthFromZero).format(moment.localeData().longDateFormat('MMDD'));
    }
    // @ts-ignore
    return moment(dateMonthFromZero).format(moment.localeData().longDateFormat('MMDDYYY'));
  }
}
