<div class="container-fluid overview-screen-md filters-management create-entity-md-wrapper">
    <!--Header-->
    <!-- =========================================== -->
    <div class="booking-filters-header">
        <h2 class="title" translate="label.bookingFilters"></h2>
    </div>
    <!--End Header-->
    <!-- =========================================== -->

    <form (ngSubmit)="sendData(scopedBookingFilters)"
          class="custom-loader"
          name="form" novalidate>
        <!--Body-->
        <!-- =========================================== -->
        <div class="row create-entity-md-section mt-2">
            <!-- name -->
            <div class="col-3">
                <div class="form-group">
                    <label translate="label.nameTemplate"></label>
                    <app-display-change-log-icon
                        [EntityType]="EntityTypeEnum.bookingFilter"
                        [EntityId]="scopedBookingFilters.id"
                        [EntityName]="scopedBookingFilters.name"
                    ></app-display-change-log-icon>
                    <input [(ngModel)]="scopedBookingFilters.name"
                           [ngModelOptions]="{standalone: true}"
                           class="input-element"
                           placeholder="{{'label.nameTemplate'|translate}}"
                           type="text"/>
                </div>
            </div>
        </div>

        <div class="col-12 text-center">
            <h5>
                <i class="fas fa-info-circle mx-1"></i>
                <span translate="label.bookingFiltersMinimumRequiredFilters"></span>
            </h5>
        </div>

        <div class="row mr-0 ml-0">
            <div class="col-6 available-filter-list">
                <div class="filters-container">
                    <h3 translate="label.availableFilters"></h3>
                    <label translate="label.dragAndDropMessage"></label>

                    <div *ngIf="scopedBookingFilters.availableFilters.length === 0"
                         class="empty-filter-container">
                    </div>
                    <!--                    TODO More filters were ditched in AWA-4867 - to add them see the PR changes-->
                    <div
                        #availableFiltersList="cdkDropList"
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListConnectedTo]="[mainFilterList]"
                        [cdkDropListData]="scopedBookingFilters.availableFilters"
                        cdkDropList
                        class="filter-list-container text-high-contrast">
                        <div *ngFor="let item of scopedBookingFilters.availableFilters" cdkDrag
                             class="filter-box">{{'label.' + item.name | lowercase | translate}}</div>
                    </div>

                </div>
            </div>

            <div class="col-6">
                <div class="filters-container">
                    <h3 translate="label.mainFilters"></h3>
                    <div *ngIf="scopedBookingFilters.mainFilters.length === 0"
                         class="empty-filter-container">
                    </div>
                    <!--                    TODO More filters were ditched in AWA-4867 - to add them see the PR changes-->
                    <div
                        #mainFilterList="cdkDropList"
                        (cdkDropListDropped)="dropMainFilters($event)"
                        [cdkDropListConnectedTo]="[availableFiltersList]"
                        [cdkDropListData]="scopedBookingFilters.mainFilters"
                        cdkDropList
                        class="filter-list-container text-high-contrast">
                        <div *ngFor="let item of scopedBookingFilters.mainFilters"
                             [cdkDragData]="item"
                             [cdkDragDisabled]="(item.name==='Area' || item.name === 'Coverage Plan')"
                             [ngClass]="{'disabled-filter': (item.name === 'Area' || item.name === 'Coverage Plan')}"
                             cdkDrag
                             class="filter-box">
                            {{'label.' + item.name | lowercase | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-4" *ngIf="false">
                <div class="filters-container">
                    <h3 translate="label.moreFilters"></h3>
                    <div *ngIf="scopedBookingFilters.moreFilters.length === 0"
                         class="empty-filter-container">
                    </div>
                    <div
                        #moreFilterList="cdkDropList"
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListConnectedTo]="[availableFiltersList, mainFilterList]"
                        [cdkDropListData]="scopedBookingFilters.moreFilters"
                        cdkDropList
                        class="filter-list-container">
                        <div *ngFor="let item of scopedBookingFilters.moreFilters" cdkDrag
                             class="filter-box">{{'label.' + item.name | lowercase | translate}}</div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Body-->
        <!-- =========================================== -->

        <!--Footer-->
        <!-- =========================================== -->
        <div class="mt-2 footer-position-sticky">
            <button class="btn close-btn mr-3"
                    type="button" (click)="goToParentPage()"
                    translate="label.close">
            </button>
            <button class="btn theme-btn"
                    type="submit"
                    [activityDisplay]="'BookingFilterUpdate'"
                    translate="button.save">
            </button>
        </div>
        <!--End Footer-->
        <!-- =========================================== -->
    </form>
</div>
