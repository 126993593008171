import {AppointmentStatusValueEnum, ResourceType, RuleTableType, ServiceType} from 'sked-base';
import {TableFiltersType} from '../../../../data-model/general.type';

export interface ServiceModalFilterOptionsType {
    ngModel?: ServiceType[];
}

export interface ResourceModalFilterOptionsType {
    ngModel?: ResourceType[];
}

export interface FormIdModalFilterOptionsType {
    ngModel?: string;
}

export interface FormDisplayNameModalFilterOptionsType {
    ngModel?: string;
}

export interface FormAssignmentAmbulatoryRuleModalFiltersType {
    serviceOptions: ServiceModalFilterOptionsType;
    resourceOptions: ResourceModalFilterOptionsType;
    formIdOptions: FormIdModalFilterOptionsType;
    formDisplayNameOptions: FormDisplayNameModalFilterOptionsType;
    areFiltersActive: boolean;
}

export interface FormAssignmentAmbulatoryRuleStateType {
    tableFilters?: TableFiltersType;
    modalFilters?: FormAssignmentAmbulatoryRuleModalFiltersType;
    ruleTableFilterItem?: RuleTableType;
}

export enum FormAssignmentAmbulatoryRuleFilterEnum {
    service = 'service',
    resource = 'resource'
}
