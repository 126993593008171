import {AccessType, FormValidationType} from 'sked-base';
import {SystemConfigurationType} from 'sked-base/lib/data-model/systemConfigurationTypes';
import {FileContentType} from "sked-base/lib/data-model/fileTypes";

export type SystemConfigFieldsAccessType = {
    [key in string]?: AccessType;
};

export enum SystemConfigTabsTypeEnum {
    general = 'general',
    email = 'email',
    gender = 'gender',
    location = 'location',
    documentType = 'documentType',
    others = 'others',
    patientAccessConfig = 'patientAccessConfig',
    taskConfiguration = 'taskConfiguration',
    reservationLifeTimes = 'reservationLifeTimes'
}

export interface SystemConfigurationWithNgModelType {
    item: SystemConfigurationType;
    ngModel: string | boolean | number; // add more types here if needed
}

export type SystemConfigTabDataType = {
    [key in string]?: SystemConfigurationWithNgModelType;
};

// example:
//  systemConfigNameType: SystemConfigNameType = {
//      general: ['SelfServicePasswordChange', 'CenterLock'],
//      anotherTab: ['logo']
//  }
export type SystemConfigNameType = {
    [key in SystemConfigTabsTypeEnum]: string[];
};

// example:
//  systemConfigDataType: SystemConfigDataType = {
//      general: {
//          'SelfServicePasswordChange': {
//              item: {id: ..., name: ..., value: 'true'},
//              ngModel: true,
//          },
//          'CenterLock': {
//               item: {id: ..., name: ..., value: 'false'},
//               ngModel: false,
//          }
//      },
//      anotherTab: {
//          'logo': {
//               item: {id: ..., name: ..., value: 'src/assets/etc/etc/image'},
//               ngModel: 'src/assets/etc/etc/image',
//          }
//      }
//  }
export type SystemConfigDataType = {
    [key in SystemConfigTabsTypeEnum]: SystemConfigTabDataType;
};

// example:
//  systemConfigNameToTabMap: SystemConfigNameToTabMap = {
//      'SelfServicePasswordChange': SystemConfigTabsTypeEnum.general,
//      'CenterLock': SystemConfigTabsTypeEnum.general,
//  }
export type SystemConfigNameToTabMap = {
    [key in string]?: SystemConfigTabsTypeEnum;
};

export interface SystemConfigValidationType {
    formValidation: FormValidationType;
    validationMethod: (input: string, ...args: any[]) => FormValidationType;
    argsList: any[];
}

// example:
//  systemConfigValidationType: SystemConfigValidationType = {
//      'TimeWindowMaximum': {
//          formValidation: {isValid: true, errorMessage: ''},
//          validationMethod: validations.getValidateIntegerBetween,
//          argsList: [1, 366, 'label.error.invalidNumberOfDaysFull']
//       },
//      'FromEmail': {
//          formValidation: {isValid: true, errorMessage: ''},
//          validationMethod: validations.getValidateEmail,
//          argsList: [true]
//       }
//  }
export type SystemConfigValidationsType = {
    [key in string]?: SystemConfigValidationType;
};

// Name is the name from system config, that is also used for downloading the images: /DownloadImage(ImageKey=name)
// UploadPath is the path used for uploading the image; example: /SystemConfigurationImages/UploadLogoImage
// ResponseSrc is the source for the img html element from the server
// Src is used as kind of the ngModel of the file input: local changes in the file input updates this field only (not responseSrc as well)
export interface SystemConfigImageType {
    name: string;
    uploadPath: string;
    responseSrc?: string;
    src?: string;
    responseBlob?: Blob;
    blob?: Blob;
    error?: string;
}

export type SystemConfigImagesType = {
    [key in string]?: SystemConfigImageType;
};

export interface SystemConfigFileType {
    name: string;
    fileId?: string;
    fileContent?: FileContentType;
    src?: string;
    responseSrc?: string;
    blob?: Blob;
    responseBlob?: Blob;
    error?: string;
}

export type SystemConfigFilesType = {
    [key in string]?: SystemConfigFileType;
};
