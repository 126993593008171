<div class="container-fluid exclusion-history create-entity-md-wrapper">
    <div class="row pl-4">
        <div class="col-4 p-0">
            <div class="exclusion-history">
                <h3 class="exclusion-history-title" translate="label.exclusionHistory">
                </h3>
            </div>
        </div>
        <div class="col-offset-6 col-8 p-0">
            <div>
                <div class="d-flex justify-content-end my-3 mr-3">
                    <button type="button"
                            (click)="goToParentPage()"
                            class="btn close-btn mr-3" translate="label.close">
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--exclusion HISTORY-->
    <label translate="label.exclusionHistory" class="title"></label>
    <table class="table table-bordered grey-table">
        <thead class="thead-light">
        <tr>
            <td>{{"label.availabilityTab.availabilityHistory.status" | translate}} /
                {{"label.availabilityTab.availabilityHistory.modifyBy" | translate}}</td>
            <td translate="label.availabilityTab.availabilityHistory.shortIdOldNew"></td>
            <td translate="label.availabilityTab.availabilityHistory.periodOldNew"></td>
            <td translate="label.availabilityTab.availabilityHistory.timeSlotsOldNew"></td>
<!--            <td translate="label.availabilityTab.availabilityHistory.servicesOldNew"></td>-->
            <td translate="label.availabilityTab.availabilityHistory.centersOldNew"></td>
        </tr>
        </thead>
        <tbody *ngFor="let history of exclusionHistoryList; let index = index"
               [ngClass]="{'current-value':history.newShortId===exclusion?.shortId}">
        <tr>
            <td>
                {{'label.' + history?.status | translate}} <br>
                {{history.modifiedBy}} - {{history.modifiedOn | fullDateBrowserTzFormatPipe}}
            </td>
            <td>
                <div [ngClass]="{'old-value':history?.oldShortId}">
                    {{history.oldShortId}}
                </div>
                <div [ngClass]="{'new-value': history?.newShortId}">
                    {{history.newShortId}}
                </div>
            </td>
            <td>
                <div [ngClass]="{'old-value':history?.oldValidFrom && history?.oldValidTo}">
                    <span *ngIf="history?.oldValidFrom" translate="label.availabilityTab.availabilityHistory.from"></span>
                    {{history?.oldValidFrom | fullDateFormatPipe}}
                    <br>
                    <span *ngIf="history?.oldValidTo" translate="label.availabilityTab.availabilityHistory.to">
                        <span *ngIf="!history?.endOfDay"> {{history?.oldValidTo| fullDateFormatPipe}}</span>
                        <span *ngIf="history?.endOfDay"> {{history?.oldValidTo| dateEndOfDay}}
                            <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoExclusion'| translate}}"></i></span>
                    </span>
                </div>
                <div [ngClass]="{'new-value':history?.newValidFrom && history?.newValidTo}">
                    <span *ngIf="history?.newValidFrom" translate="label.availabilityTab.availabilityHistory.from"></span>
                    {{history?.newValidFrom | fullDateFormatPipe}}
                    <br>
                    <span *ngIf="history?.newValidTo" translate="label.availabilityTab.availabilityHistory.to">
                        <span *ngIf="!history?.endOfDay"> {{history?.newValidTo| fullDateFormatPipe}}</span>
                        <span *ngIf="history?.endOfDay"> {{history?.newValidTo| dateEndOfDay}}
                            <i class="far fa-hourglass" ngbTooltip="{{'label.endOfDayInfoExclusion'| translate}}"></i></span>
                    </span>
                </div>
            </td>
            <td>
                <div [ngClass]="{'old-value':history?.removedTimeSlots?.length>0}">
                     <span *ngFor="let timeSlot of history.removedTimeSlots">
                        <span class="mr-1" *ngIf="timeSlot.monday" translate="label.mon"></span>
                        <span class="mr-1" *ngIf="timeSlot.tuesday" translate="label.tu"></span>
                        <span class="mr-1" *ngIf="timeSlot.wednesday" translate="label.we"></span>
                        <span class="mr-1" *ngIf="timeSlot.thursday" translate="label.th"></span>
                        <span class="mr-1" *ngIf="timeSlot.friday" translate="label.fr"></span>
                        <span class="mr-1" *ngIf="timeSlot.saturday" translate="label.sa"></span>
                        <span class="mr-1" *ngIf="timeSlot.sunday" translate="label.su"></span>
                        <span class="mr-1">:</span>
                                <br/><span>{{timeSlot.interval.hourFrom | hourFromMinutes}}
                         - {{timeSlot.interval.hourTo | hourFromMinutes}}</span><br/>
                                <span *ngFor="let appointmentType of timeSlot.appointmentTypes">
                                    {{appointmentType}}<span ng-if="!$last">,&nbsp;</span>
                                </span>
                                 <br/>
                     </span>
                </div>
                <div [ngClass]="{'new-value':history?.addedTimeSlots?.length>0}">
                    <span *ngFor="let timeSlot of history.addedTimeSlots">
                        <span class="mr-1" *ngIf="timeSlot.monday" translate="label.mon"></span>
                        <span class="mr-1" *ngIf="timeSlot.tuesday" translate="label.tu"></span>
                        <span class="mr-1" *ngIf="timeSlot.wednesday" translate="label.we"></span>
                        <span class="mr-1" *ngIf="timeSlot.thursday" translate="label.th"></span>
                        <span class="mr-1" *ngIf="timeSlot.friday" translate="label.fr"></span>
                        <span class="mr-1" *ngIf="timeSlot.saturday" translate="label.sa"></span>
                        <span class="mr-1" *ngIf="timeSlot.sunday" translate="label.su"></span>
                        <span class="mr-1">:</span>
                                <br/><span>{{timeSlot.interval.hourFrom | hourFromMinutes}}
                        - {{timeSlot.interval.hourTo | hourFromMinutes}}</span><br/>
                                <span *ngFor="let appointmentType of timeSlot.appointmentTypes">
                                    {{appointmentType}}<span ng-if="!$last">,&nbsp;</span>
                                </span>
                                 <br/>
                     </span>
                </div>
            </td>
<!--            <td>-->
<!--                <div [ngClass]="{'old-value':history?.removedAvailabilityServices?.length>0}">-->
<!--                    <span *ngFor="let service of history?.removedAvailabilityServices | slice: 0 : 3">-->
<!--                        <span> {{service.name}} </span>-->
<!--                        <span *ngIf="service?.duration">| {{service.duration}}-->
<!--                            <span class="mr-1"-->
<!--                                  translate="label.availabilityTab.minuteShort">-->
<!--                                </span>-->
<!--                        </span>-->
<!--                    </span>-->
<!--                    <span *ngIf="history?.removedAvailabilityServices.length > 3">-->
<!--                        <div class="cursor-pointer"-->
<!--                             placement="bottom"-->
<!--                             popoverClass="popover-shadow"-->
<!--                             [ngbPopover]="moreServices">-->
<!--                            <span translate="label.showMore"></span>-->
<!--                        </div>-->
<!--                        <ng-template #moreServices>-->
<!--                            <div class="row">-->
<!--                                <div class="col-md-6">-->
<!--                                    <div *ngFor="let service of history?.removedAvailabilityServices"-->
<!--                                         class="nowrap px-2 py-1">-->
<!--                                        <div> {{service.name}}-->
<!--                                            <span *ngIf="!(service?.duration)"> | {{service.duration}}-->
<!--                                                <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>-->
<!--                                            </span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </ng-template>-->
<!--                    </span>-->
<!--                </div>-->
<!--                <div [ngClass]="{'new-value':history?.addedAvailabilityServices?.length>0}">-->
<!--                     <span *ngFor="let service of history?.addedAvailabilityServices | slice: 0 : 3">-->
<!--                        <span> {{service.name}} </span>-->
<!--                        <span *ngIf="service?.duration">| {{service.duration}}-->
<!--                            <span class="mr-1"-->
<!--                                  translate="label.availabilityTab.minuteShort">-->
<!--                                </span>-->
<!--                        </span>-->
<!--                          <br/>-->
<!--                    </span>-->
<!--                    <span *ngIf="history?.addedAvailabilityServices.length > 3">-->
<!--                        <div class="cursor-pointer"-->
<!--                             placement="bottom"-->
<!--                             popoverClass="popover-shadow"-->
<!--                             [ngbPopover]="moreServices">-->
<!--                            <span translate="label.showMore"></span>-->
<!--                        </div>-->
<!--                        <ng-template #moreServices>-->
<!--                            <div class="row">-->
<!--                                <div class="col-md-6">-->
<!--                                    <div *ngFor="let service of history?.addedAvailabilityServices"-->
<!--                                         class="nowrap px-2 py-1">-->
<!--                                        <div> {{service.name}}-->
<!--                                            <span *ngIf="!(service?.duration)"> | {{service.duration}}-->
<!--                                                <span class="mr-1" translate="label.availabilityTab.minuteShort"></span>-->
<!--                                            </span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </ng-template>-->
<!--                    </span>-->
<!--                </div>-->
<!--            </td>-->
            <td>
                <div [ngClass]="{'old-value':history?.removedAvailabilityCenters?.length>0}">
                    <span *ngFor="let center of history?.removedAvailabilityCenters">
                        {{center.name}}
                    </span>
                </div>
                <div [ngClass]="{'new-value':history?.addedAvailabilityCenters?.length>0}">
                    <span *ngFor="let center of history?.addedAvailabilityCenters">
                        {{center.name}}
                    </span>
                </div>
            </td>
        </tr>
        </tbody>
    </table>

    <!--CURRENT EXCLUSION-->
    <app-view-exclusion [exclusion]="exclusion"></app-view-exclusion>
</div>
