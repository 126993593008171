<div class="container-fluid patient-appointment-list-wrapper list-page-wrapper p-0 pb-1">
    <!-- Patient search -->
    <div class="patient-search-component-container p-2">
        <sbase-patient-search [options]="patientContextService.getPatientSearchOptions()"
                              (onSelectedPatientOutput)="patientContextService.onSelectedPatient($event)"
                              (patientSearchActions)="patientContextService.onPatientSearchActions($event)">
        </sbase-patient-search>
    </div>

    <!-- Search filters -->
    <div class="row patient-appointment-list-filters-container ml-0 mr-0 mb-1">
        <app-patient-appointment-list-filters *ngIf="patientAppointmentListUtils.filtersOptions.areFiltersReady"
                               (search)="onFilterSearch($event)"
        ></app-patient-appointment-list-filters>
    </div>

    <!-- PatientAppointment list item -->
    <div class="patient-appointment-list-results-container ml-0 mr-0 custom-scrollbar">
        <!-- Appointment label, refresh button and Upcoming / Past / Cancelled tabs row -->
        <div *ngIf="!patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState"
             class="row appointment-list-label-and-filters-row d-flex no-block justify-content-between mx-3 my-3">
            <!-- Appointment label -->
            <span class="appointment-list-label-container" translate="label.appointments"></span>
            <!-- Refresh button -->
            <div class="btn theme-btn float-right refresh-button"
                 ngbTooltip="{{'label.refresh'| translate}}"
                 placement="left"
                 (click)="refreshData()">
                <i class="fas fa-sync-alt"></i>
            </div>
            <!-- Upcoming / Past / Cancelled tabs -->
            <div class="appointment-list-filter-tabs-container">
                <div class="btn-group" role="filters">
                    <button type="button"
                            class="btn back-btn font-weight-high-contrast"
                            [ngClass]="patientAppointmentListUtils.filtersOptions.typeFilterTab === appointmentTypeEnum.Upcoming? 'active' : null"
                            translate="label.upcoming"
                            (click)="onChangeAppointmentTypeFilter(appointmentTypeEnum.Upcoming)">
                    </button>
                    <button type="button"
                            class="btn back-btn font-weight-high-contrast"
                            [ngClass]="patientAppointmentListUtils.filtersOptions.typeFilterTab === appointmentTypeEnum.Past? 'active' : null"
                            translate="label.past"
                            (click)="onChangeAppointmentTypeFilter(appointmentTypeEnum.Past)">
                    </button>
                    <button type="button"
                            class="btn back-btn font-weight-high-contrast"
                            [ngClass]="patientAppointmentListUtils.filtersOptions.typeFilterTab === appointmentTypeEnum.Cancelled? 'active' : null"
                            translate="label.cancelledApp"
                            (click)="onChangeAppointmentTypeFilter(appointmentTypeEnum.Cancelled)">
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState" class="patient-appointment-list-message" translate="label.patientAppointmentListNotSearchedYet">
        </div>
        <div *ngIf="patientAppointmentListUtils.patientAppointmentListItemOptions.isNotFoundState" class="patient-appointment-list-message" translate="label.appointmentListNotFound">
        </div>
        <ng-container *ngIf="!patientAppointmentListUtils.patientAppointmentListItemOptions.isBeforeSearchState">
            <!-- Appointment list -->
            <div class="ml-2 my-1" *ngFor="let patientAppointment of patientAppointmentListUtils.patientAppointmentListItemOptions.appointmentList; let index = index">
                <app-appointment-card [options]="{appointment: patientAppointment, displayPatientInformation: false}"
                                      (appointmentCardAction)="onPatientAppointmentItemAction($event, index)">
                </app-appointment-card>
                <div class="horizontal-line"></div>
            </div>
        </ng-container>
    </div>

    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="patientAppointmentListUtils.patientAppointmentListItemOptions.totalPatientAppointmentItems > 0" class="pagination-component px-2 py-2 text-high-contrast">
        <ngb-pagination (pageChange)="onChangePagination($event)"
                        [(page)]="patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="patientAppointmentListUtils.patientAppointmentListItemOptions.totalPatientAppointmentItems" [maxSize]="5"
                        [pageSize]="patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown = !patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown;"
                          [ngClass]="{'show': patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown}"
                          aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{patientAppointmentListUtils.patientAppointmentListItemOptions.patientAppointmentListRequestFilters.pageFilters.itemsPerPage}}</strong>
                    </span>
              <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                   [ngClass]="{'show': patientAppointmentListUtils.patientAppointmentListItemOptions.showItemsPerPageDropdown}"
                   class="dropdown-menu dropdown-menu-item"
                   x-placement="top-start" x-placement="bottom-start">
                  <a (click)="changeNumberOfItemsPerPage(page)"
                     *ngFor="let page of patientAppointmentListUtils.patientAppointmentListItemOptions.itemsPerPageList"
                     class="dropdown-item">{{page}}</a>
              </div>
              </span>
        </div>
    </div>
</div>
