import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {DynamicFormInputService} from '../../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {
    DynamicFormInputTemplateEnum,
    DynamicFormOptionsType
} from '../../../../shared/component/dynamic-form-input/dynamic-form-input.types';

@Component({
    selector: 'app-phone-number-form',
    templateUrl: './phone-number-form.component.html',
    styleUrls: ['./phone-number-form.component.scss']
})
export class PhoneNumberFormComponent implements OnInit {
    // We need the parentFormGroup because the validation is made on the group not for every input
    @Input() parentFormGroup!: FormGroup;
    @Input() formGroupName!: string;
    //  It`s important that countryCode to be the first object in the inputs array,
    //  if is the second one the component will display first the phoneNumber than the countryCode
    @Input() options: DynamicFormOptionsType;

    dynamicFormInputTemplateEnum = DynamicFormInputTemplateEnum;
    phoneNumberForm: FormGroup;
    isPhoneNumberInputFocused = false;

    constructor(public dynamicFormInputService: DynamicFormInputService,
                private rootFormGroup: FormGroupDirective) {
    }

    ngOnInit(): void {
        this.phoneNumberForm = this.rootFormGroup?.control?.get(this.formGroupName) as FormGroup;
        // Add error to phoneNumber and phoneNumberCountryCode controls
        // We need to add this manually because when the phoneNumber input has value even if the parent form is invalid
        // phoneNumber input is valid so the step validation is passed
        this.phoneNumberForm?.valueChanges.subscribe(() => {
            const {errors, controls} = this.phoneNumberForm;
            const controlsKeys = Object.keys(controls);
            if (!!errors) {
                controlsKeys.forEach(key => {
                    // We don`t need to add a message because we display the message from the parent
                    controls[key].setErrors({error: {}});
                });
            } else {
                controlsKeys.forEach(key => {
                    // Remove errors if parent form has no errors
                    controls[key].setErrors(null);
                });
            }
        });
    }
}
