<div class="resource-utilization-filters-container">
    <div class="row mb-0 mx-1 pt-1 cursor-pointer" (click)="changeCollapseFilterSection()">
        <div class="col ml-1 text-left">
            <h5 translate="label.resourceSelector" class="text-left title"></h5>
        </div>
        <div class="col mr-1 text-right align-self-center">
            <i class="btn btn-sm fas"
               [ngClass]="resourceUtilizationUtils.filtersOptions.areFiltersCollapsed ? 'fa-angle-down':'fa-angle-up'"
               [attr.aria-expanded]="!resourceUtilizationUtils.filtersOptions.areFiltersCollapsed">
            </i>
        </div>
    </div>
    <div [ngbCollapse]="resourceUtilizationUtils.filtersOptions.areFiltersCollapsed" class="row mb-0 mx-1 pl-2">
        <div class="col-7 custom-col-7 form-group pl-2 pr-0">
            <sbase-filter-wrapper
                    class="d-flex my-1"
                    [ngClass]="{
                        'has-error-resource': !resourceUtilizationUtils.filtersOptions.filterValidations.resource?.isValid,
                        'has-error-resourceType': !resourceUtilizationUtils.filtersOptions.filterValidations.resourceType?.isValid,
                        'has-error-location': !resourceUtilizationUtils.filtersOptions.filterValidations.location?.isValid,
                        'has-error-area': !resourceUtilizationUtils.filtersOptions.filterValidations.area?.isValid,
                        'has-error-speciality': !resourceUtilizationUtils.filtersOptions.filterValidations.speciality?.isValid
                    }"
                    [options]="resourceUtilizationUtils.filtersOptions.filterWrapperOptions"
                    [updateFiltersValue]="updateFilterWrapperValues"
                    (onFiltersValueChanged)="onFilterWrapperValueChanged($event)"
            ></sbase-filter-wrapper>
        </div>
        <div class="col-4 custom-col-4">
            <div class="row my-1 justify-content-end">
                <div class="form-group mb-0 resource-utilization-date-range-container">
                    <sbase-date-range class="input-element"
                                      [options]="resourceUtilizationUtils.filtersOptions.dateRangeOptions"
                                      [updateDateRangeValue]="updateDateRangeValue"
                                      [ngClass]="{'input-element-has-error': !resourceUtilizationUtils.filtersOptions.filterValidations?.dateRange?.isValid}"
                                      (onDateRangeChanged)="onDateRangeChanged($event)">
                    </sbase-date-range>
                </div>

            </div>
            <div class="row">
                <button class="action-button reset-button btn" (click)="onClear()">
                    <i class="far fa-trash-alt custom-text-color"></i>
                    <span class="pl-1 custom-text-color font-weight-high-contrast" translate='label.resetFilters'></span>
                </button>
            </div>
        </div>
        <div class="col-1 custom-col-1 action-button resource-utilization-filters-search no-block my-2">
            <button class="search-button"
                    [ngClass]="!resourceUtilizationUtils.filtersOptions.areFiltersValid ? 'disabled' : null"
                    [ngbCollapse]="resourceUtilizationUtils.filtersOptions.areFiltersCollapsed"
                    (click)="onSearch()">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</div>
