<div class="container-fluid  overview-screen-md services-md-screen pb-1 d-flex no-block flex-column">
    <div class="services d-flex no-block justify-content-between">
        <h2 translate="label.services"></h2>
        <div class="filters-section d-flex no-block">
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)="onSelectedSpecialityFilter($event)"
                              [buttonName]=specialityButtonName
                              [dependentFilters]="mainDependentFilters.speciality"
                              [hideSelectedItems]="true"
                              [initialFilterValues]="serviceMdUtils.initialSpecialityValues" [isMultipleSelectedItem]="false"
                              [providerInstance]="specialityProvider"
                              [useSelectedValueAsLabel]="true">
                </sbase-filter>
            </div>
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)=" onSelectedCenterFilter($event)" [buttonName]=centerButtonName
                              [dependentFilters]="mainDependentFilters.location"
                              [hideSelectedItems]="true"
                              [initialFilterValues]="serviceMdUtils.initialCenterValues" [isMultipleSelectedItem]="false"
                              [providerInstance]="centerProvider"
                              [useSelectedValueAsLabel]="true"
                              class="sbase-dropdown-ml">
                </sbase-filter>
            </div>
            <div class="mr-2">
                <sbase-filter (selectedItemsOutput)=" onSelectedAreaFilter($event)" [buttonName]=areaButtonName
                              [dependentFilters]="mainDependentFilters.area" [hideSelectedItems]="true"
                              [initialFilterValues]="serviceMdUtils.initialAreaValues" [isMultipleSelectedItem]="false"
                              [providerInstance]="areaProvider"
                              [useSelectedValueAsLabel]="true">
                </sbase-filter>
            </div>
            <div class="channel-filter-container mr-2">
                <sbase-filter (selectedItemsOutput)=" onSelectedChannelFilter($event)" [buttonName]=channelButtonName
                              [dependentFilters]="mainDependentFilters.channel"
                              [hideSelectedItems]="true"
                              [initialFilterValues]="serviceMdUtils.initialChannelValues" [isMultipleSelectedItem]="false"
                              [providerInstance]="channelProvider"
                              [useSelectedValueAsLabel]="true">
                </sbase-filter>
            </div>
            <div class="form-group d-flex no-block align-items-center m-0">
                <!-- Online Consultation Filter -->
                <!-- ============================================================== -->
                <label translate="label.onlineConsultationsOnly" class="text-high-contrast"></label>
                <span class="switch">
                <label>
                    <input (ngModelChange)="onOnlineConsultationChanged($event)"
                           [(ngModel)]="serviceMdUtils.tableFilters.filter.onlineConsultation"
                           checked
                           type="checkbox"
                    >
                    <span class="lever switch-col-black"></span>
                </label>
            </span>
            </div>
            <app-generate-export-file [exportFileEntity]="exportFileEntityEnum.Services"></app-generate-export-file>
            <div class="d-inline-block ml-2 mr-1" *ngIf="serviceMdUtils.modalFilters">
                <sbase-show-template-modal-filter-container
                        [contentTemplate]="serviceModalFiltersContentTemplate"
                        [areFiltersActive]="serviceMdUtils.modalFilters.areFiltersActive"
                        [title]="'label.filterMore'"
                        (modalClose)="onModalClose($event)"
                ></sbase-show-template-modal-filter-container>
                <ng-template #serviceModalFiltersContentTemplate>
                    <div class="mb-2">
                        <div class="col-12 p-0">
                            <!-- ============================================================== -->
                            <!-- Has multiResource BluePrint filter -->
                            <!-- ============================================================== -->
                            <label translate="label.onlyServicesWithBluePrint" class="text-high-contrast"></label>
                            <span class="switch">
                                <label>
                                    <input [(ngModel)]="serviceMdUtils.modalFilters.multiResourceBluePrintOptions.ngModel"
                                           checked
                                           type="checkbox">
                                    <span class="lever switch-col-black"></span>
                                </label>
                            </span>
                        </div>
                        <!-- ============================================================== -->
                        <!-- Multi Resource Blue Print Filter -->
                        <!-- ============================================================== -->
                        <div class="col-12 p-0">
                            <label class="mb-1 mr-2 w-100 text-high-contrast" translate="label.bluePrintName"></label>
                            <sbase-filter (selectedItemsOutput)="onSelectedMultiResourceBluePrintFilter($event)"
                                          [buttonName]=multiResourceBluePrintButtonName
                                          [dependentFilters]="mainDependentFilters.multiResourceBluePrint"
                                          [hideSelectedItems]="true"
                                          [initialFilterValues]="serviceMdUtils.initialMultiResourceBluePrintFilterValues"
                                          [isMultipleSelectedItem]="false"
                                          [providerInstance]="multiResourceBluePrintProvider"
                                          [useSelectedValueAsLabel]="true">
                            </sbase-filter>
                        </div>
                    </div>
                </ng-template>
            </div>
            <button class="reset-button btn" (click)="onClearFilters()">
                <i class="far fa-trash-alt"></i>
                <span class="pl-1 font-weight-high-contrast" translate='label.resetFilters'></span>
            </button>
        </div>
    </div>

    <div class="sort-search-wrapper d-flex no-block justify-content-between">
        <div class="sort text-high-contrast">
            <span translate="label.activeSorting"></span>:
            <span class="text-info">
      <span *ngFor="let key of objectKeys(serviceMdUtils.tableFilters.orderBy)">
        <span *ngIf="key">{{'label.' + key |translate}}</span> - {{'label.' + serviceMdUtils.tableFilters.orderBy[key] | translate}}
          <span>
          <i (click)="onClearOrderBy(key)" class="fas fa-times cursor-pointer mr-3"></i>
        </span>
            </span>
            </span>
        </div>

        <div class="d-flex no-block">
            <div class="d-flex no-block search">
                <label class="m-0 mr-1 control-label">{{'label.search2'|translate}}:</label>
                <input (ngModelChange)="onSearchServiceChanged(ServiceMDSearchByEnum.Name, $event)" [(ngModel)]="serviceMdUtils.tableFilters.filter.Name"
                       autocomplete="off" class="form-control rounded-0"
                       placeholder="{{'label.searchBy'|translate}} {{'label.name'|translate}}" type="text">
                <input (ngModelChange)="onSearchServiceChanged(ServiceMDSearchByEnum.ShortId, $event)" [(ngModel)]="serviceMdUtils.tableFilters.filter.ShortId"
                        autocomplete="off" class="form-control rounded-0 ml-2"
                        placeholder="{{'label.searchBy'|translate}} {{'label.shortId'|translate}}" type="text">
            </div>
            <div class="ml-2">
                <!-- Create Button -->
                <!-- ============================================================== -->
                <button (click)="createService()" [activityDisplay]="MDEntityName+constants.CREATE"
                        class="btn theme-alt-btn border-0" type="button" translate="label.createNew">
                </button>
            </div>
        </div>
    </div>

    <div class="table-responsive overview-table-container mt-2 custom-scrollbar">
        <table class="table">
            <thead>
            <tr>
                <th (click)="onSortBy('shortId')" class="name-column cursor-pointer">
                    {{'label.shortId' | translate}}
                    <button *ngIf="!serviceMdUtils.tableFilters.orderBy.shortId" class="btn p-0" type="button"><i
                            class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.shortId === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.shortId === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th (click)="onSortBy('name')" class="name-column cursor-pointer">
                    {{'label.serviceName' | translate}}
                    <button *ngIf="!serviceMdUtils.tableFilters.orderBy.name" class="btn p-0" type="button"><i class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.name === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.name === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th (click)="onSortBy('speciality')" class="name-column cursor-pointer">
                    {{'label.speciality2' | translate}}
                    <button *ngIf="!serviceMdUtils.tableFilters.orderBy.speciality" class="btn p-0" type="button"><i
                            class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.speciality === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.speciality === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th (click)="onSortBy('area')" class="name-column cursor-pointer" translate="label.area2">
                    <button *ngIf="!serviceMdUtils.tableFilters.orderBy.area" class="btn p-0" type="button"><i
                            class="fas fa-sort"></i>
                    </button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.area === 'asc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-down"></i></button>
                    <button *ngIf="serviceMdUtils.tableFilters.orderBy.area === 'desc'" class="btn p-0" type="button"><i
                            class="fas fa-sort-up"></i></button>
                </th>
                <th [featureDisplay]="'admin-subServices'"
                    translate="label.subServices"></th>
                <th translate="label.centers"></th>
                <th translate="label.coveragePlans"></th>
                <th translate="label.duration"></th>
                <th translate="label.code"></th>
                <th translate="label.action"></th>
            </tr>
            </thead>
            <tbody>
            <tr [ngClass]="{'view-active': VIEW_ACTIVITY_ENABLED}"
                *ngFor="let item of tableItems" sbaseMarkTableRowOnHover (click)="viewService(item)">
                <td class="text-center">{{item.shortId}}</td>
                <td class="max-width-table-column">
                    {{item.name}}
                    <span *ngIf="item.onlineConsultation"
                          ngbTooltip="{{'label.onlineConsultation' | translate}}"
                          class="mx-1">
                        <i class="fas fa-desktop"></i>
                    </span>
                    <span *ngIf="item.multiResourceBluePrintId"
                          ngbTooltip="{{'label.blueprint' | translate}}">
                        <i class="fa-regular fa-feather-pointed"></i>
                    </span>
                </td>
                <td class="max-width-table-column">{{item.speciality.name}}</td>
                <td class="max-width-table-column">{{item.area.name}}</td>
                <td [featureDisplay]="'admin-subServices'">
                    <div *ngFor="let subService of item.subServices| slice:0:3">
                        {{subService.name}}
                    </div>

                    <ng-template #subServicesContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let subService of item.subServices">{{subService.name}}</div>
                        </div>
                    </ng-template>
                    <div *ngIf="item.subServices && item.subServices.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="subServicesContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngFor="let center of item.centers| slice:0:3">
                        {{center.name}}
                    </div>

                    <ng-template #centersContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let center of item.centers">{{center.name}}</div>
                        </div>
                    </ng-template>
                    <div *ngIf="item.centers && item.centers.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="centersContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngFor="let coveragePlan of item.coveragePlans| slice:0:3">
                        {{coveragePlan.name}}
                    </div>

                    <ng-template #coveragePlansContent>
                        <div class="table-tooltip-container px-2 py-1">
                            <div *ngFor="let coveragePlan of item.coveragePlans">{{coveragePlan.name}}</div>
                        </div>
                    </ng-template>
                    <!--          <button class="text-center w-100"-->
                    <!--                  *ngIf="item.coveragePlans && item.coveragePlans.length>3">-->
                    <!--            <div-->
                    <!--              [ngbPopover]="coveragePlansContent"-->
                    <!--              class="fas fa-ellipsis-h">-->
                    <!--            </div>-->
                    <!--          </button>-->
                    <div *ngIf="item.coveragePlans && item.coveragePlans.length>3" (click)="$event.stopPropagation()">
                        <div [ngbPopover]="coveragePlansContent" placement="bottom top" class="fas fa-ellipsis-h cursor-pointer">
                        </div>
                    </div>
                </td>
                <td class="text-center">{{item.defaultDuration}}</td>
                <td class="text-center">{{item.code}}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                    <button [ngbPopover]="popContent" class="btn" placement="left" popoverClass="popover-shadow"
                            type="button">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <ng-template #popContent>
                        <ul class="list-style-none m-0 p-0 custom-drop-down">
                            <li>
                                <button (click)="viewService(item)" class="btn" translate="label.view"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="editService(item)" [activityDisplay]="MDEntityName+constants.UPDATE"
                                        class="btn"
                                        translate="label.edit"
                                        type="button"></button>
                            </li>
                            <li>
                                <button (click)="displayConfirmDeleteItemModal(item.id)"
                                        [activityDisplay]="MDEntityName+constants.DELETE" class="btn"
                                        translate="label.delete"
                                        type="button"></button>
                            </li>
                            <li>
                                <button type="button" class="btn" translate="label.changeLog"
                                        (click)="displayChangeLog(item.id, EntityTypeEnum.service, item.name)"
                                        [activityDisplay]="constants.CHANGE_LOG_OBJECT_HISTORY_READ"></button>
                            </li>
                        </ul>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>


    <!-- Page Filter -->
    <!-- ============================================================== -->
    <div *ngIf="totalTableItems > 0" class="pagination-component text-high-contrast mt-4">
        <ngb-pagination (pageChange)="onChangePagination($event)" [(page)]="serviceMdUtils.tableFilters.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="totalTableItems" [maxSize]="5"
                        [pageSize]="serviceMdUtils.tableFilters.itemsPerPage"></ngb-pagination>

        <div class="btn-group items-per-page-group">
            <span>{{'label.listActions.pagination.itemsPerPage' | translate}}:</span>
            <span class="btn-group ml-2">
                    <span (click)="showItemsPerPageDropdown = !showItemsPerPageDropdown;"
                          [ngClass]="{'show': showItemsPerPageDropdown}" aria-expanded="true"
                          aria-haspopup="true" class="dropdown-toggle page-filter cursor-pointer"
                          data-toggle="dropdown">
                        <strong>{{serviceMdUtils.tableFilters.itemsPerPage}}</strong>
                    </span>
            <div (clickOutside)=onClickedOutsideItemsPerPageFilter($event) [exclude]="'.page-filter'"
                 [ngClass]="{'show': showItemsPerPageDropdown}" class="dropdown-menu dropdown-menu-item"
                 x-placement="top-start" x-placement="bottom-start">
                <a (click)="changeNumberOfItemsPerPage(page)" *ngFor="let page of itemsPerPageList"
                   class="dropdown-item">{{page}}</a>
            </div>
            </span>
        </div>
        <!-- ============================================================== -->
        <!-- End Page Filter -->
    </div>

</div>
