import {AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {
    CountryProvider,
    CountryType,
    CoveragePlanProvider,
    CoveragePlanType,
    ODataQueryObjectType,
    PatientProvider,
    ResourceProvider,
    SearchFilterUtils,
    ServiceProvider,
    ServiceType,
    SpecialityProvider,
    TagProvider
} from 'sked-base';
import {DynamicFormOptionsType} from '../../../shared/component/dynamic-form-input/dynamic-form-input.types';
import {ConfigDataService} from '../../../shared/services/config-data.service';
import {TenantCustomizingService} from '../../../shared/services/tenant-customizing.service';
import {
    ProcessedTenantCustomizingGroupedByControlNameType,
    TenantCustomizingFilterInputType
} from '../../../data-model/tenant-customizing.type';
import {DynamicFormInputService} from '../../../shared/services/dynamic-form-input/dynamic-form-input.service';
import {forkJoin, Observable, of} from 'rxjs';
import {TranslatedLanguageService} from '../../../shared/services/translated-language.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {
    ActionType,
    FormValidationType,
    PhoneNumberType,
    ScreenTemplateLayoutType
} from '../../../data-model/general.type';
import {PatientControlRuleExceptionType, PatientType} from 'sked-base/lib/data-model/patientTypes';
import {CreatePatientUtils} from './create-patient.utils';
import {GeneralUtils} from '../../../shared/utils/general.utils';
import {Router} from '@angular/router';
import {MatStepper} from '@angular/material/stepper';
import {PatientContextService} from '../../../shared/services/patient-context.service';
import * as lodash from 'lodash';
import * as moment from 'moment';
import {PatientClientAgreementsEnum} from './create-patient-overview/create-patient-overview.types';
import {formControlNames} from '../../../shared/constants/formControlNames';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {
    concatMap,
    debounceTime,
    distinctUntilChanged,
    retry,
    take
} from 'rxjs/operators';
import {constants} from 'src/app/shared/constants/constants';
import {CacheService} from '../../../shared/services/cache.service';

@Component({
    selector: 'app-create-patient',
    templateUrl: './create-patient.component.html',
    styleUrls: ['./create-patient.component.scss']
})
export class CreatePatientComponent implements OnInit, AfterContentChecked {
    screenTemplateLayout: ScreenTemplateLayoutType;
    patientForm: FormGroup = new FormGroup({});
    mainDependentFilters: any;
    clientIdentificationOptions = {} as DynamicFormOptionsType;
    addressInformationOptions = {} as DynamicFormOptionsType;
    mainPhoneNumberOptions = {} as DynamicFormOptionsType;
    alternatePhoneNumberOptions = {} as DynamicFormOptionsType;
    createPatientStepOneFormOptions: DynamicFormOptionsType;
    createPatientStepTwoFormOptions: DynamicFormOptionsType;
    createPatientStepThreeFormOptions: DynamicFormOptionsType;
    coveragePlanFormOptions: DynamicFormOptionsType;
    tagsFormOptions: DynamicFormOptionsType;
    externalKeysFormOptions: DynamicFormOptionsType;
    exceptionsFormOptions: DynamicFormOptionsType;
    timeDependentTagsOptions: DynamicFormOptionsType;
    mappedPatient: PatientType;
    tenantCustomizingData: ProcessedTenantCustomizingGroupedByControlNameType;
    action: ActionType;
    actionType = ActionType;
    @ViewChild('stepper', {static: true}) private stepper: MatStepper;
    totalStepsCount: number;
    patientValidation: FormValidationType;
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    clientIdentificationWasPrefilledFromSearchPhrase = false;
    userInfoStorage;
    constants = constants;
    isDataLoaded: boolean;
    isBeforeNavigate = true;
    formControlNames = formControlNames;
    skipPatientFormValidation = false;

    constructor(
        public coveragePlanProvider: CoveragePlanProvider,
        public searchFilterUtils: SearchFilterUtils,
        private tenantCustomizingService: TenantCustomizingService,
        private formBuilder: FormBuilder,
        private dynamicFormInputService: DynamicFormInputService,
        private countryProvider: CountryProvider,
        private translatedLanguageService: TranslatedLanguageService,
        private ngxLoader: NgxUiLoaderService,
        private messagesService: MessagesService,
        private patientProvider: PatientProvider,
        public createPatientUtils: CreatePatientUtils,
        private generalUtils: GeneralUtils,
        private router: Router,
        private patientContextService: PatientContextService,
        private configDataService: ConfigDataService,
        private changeDetectorRef: ChangeDetectorRef,
        private tagProvider: TagProvider,
        private serviceProvider: ServiceProvider,
        private specialityProvider: SpecialityProvider,
        private resourceProvider: ResourceProvider,
        private cacheService: CacheService
    ) {
    }

    ngOnInit(): void {
        this.action = !history?.state?.action ? ActionType.Create : history?.state?.action;
        this.createPatientUtils.childComponentRequestsDone = [];
        if (!history?.state?.step) {
            this.isBeforeNavigate = false;
        }
        this.skipPatientFormValidation = !!history?.state?.skipPatientFormValidation;
        this.loadData();
        this.listenToChildComponentsRequests();
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    savePatientData() {
        this.mappedPatient = this.createPatientUtils.mapPatientFormToPatientType(this.patientForm, this.createPatientUtils.initialPatient);
        this.createPatientUtils.patientControlExceptions = [];
        this.createPatientUtils.patientTimeDependentTags = [];
        if (!this.isTheWholeFormValid()) {
            this.messagesService.error('toastr.warning.formInvalidOrDataNotSaved');
            // Validate all inputs
            this.patientForm.markAllAsTouched();
            return;
        }

        this.ngxLoader.start();
        this.getCreateOrEditPatientObservable()
            .pipe(take(1),
                retry(1),
                concatMap((response: PatientType) => {
                    // Because the responses for create and edit don't have all the fields (clientAgreements for example),
                    // we need to make a new request
                    return forkJoin([
                        this.patientProvider.getById(response?.id, this.createPatientUtils.getPatientQueryFilter())
                    ]).pipe(take(1), retry(1));
                }))
            .subscribe(([patientResponse]: [PatientType]) => {
                this.messagesService.success(
                    this.action === ActionType.Create ? 'toastr.success.patientCreate' : 'toastr.success.patientEdit',
                    true
                );
                this.ngxLoader.stop();
                // Update tags
                patientResponse.tags = this.configDataService.isFeatureActive('patient-tags') ? this.mappedPatient?.tags : [];
                // Set the patient in context
                this.patientContextService.patient = patientResponse;
                // Redirect to dashboard
                setTimeout(() => {
                    this.router.navigate(['/patientDashboard']);
                }, 0);
            }, (error) => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            });
    }

    goToParentPage() {
        this.router.navigate(['patientDashboard']);
    }

    // STEPPER CONTROLS

    goBack() {
        // If the save button was disabled because we have opened the overview step coming from the patient dashboard after
        // we clicked on the view icon, enable the save button
        if (history?.state?.disableSaveButton) {
            history.state.disableSaveButton = false;
        }
        if (this.skipPatientFormValidation) {
            // If skipPatientFormValidation is true, the user was on view and clicked on the back button to enter the
            // edit flow. We simulate a goUntilStep from step 0 to the second to last step
            const createPatientOverviewStep = this.createPatientUtils.getCreatePatientOverviewStep();
            this.stepper.selectedIndex = 0;
            this.skipPatientFormValidation = false;
            this.goUntilStep(createPatientOverviewStep - 1);
        } else {
            this.stepper.previous();
        }
    }

    goForward() {
        if (this.areStepsValid(this.stepper.selectedIndex)) {
            this.stepper.next();
        } else {
            this.navigateFromStepToStep(this.stepper.selectedIndex, this.stepper.selectedIndex + 1);
            // Validate all inputs
            this.patientForm.markAllAsTouched();
        }
    }

    goUntilStep(step: number) {
        while (this.stepper.selectedIndex < step) {
            if (!this.areStepsValid(this.stepper.selectedIndex)) {
                this.navigateFromStepToStep(this.stepper.selectedIndex, this.stepper.selectedIndex + 1);
                break;
            }
            this.stepper.next();
        }
        if (this.isBeforeNavigate) {
            setTimeout(() => {
                this.isBeforeNavigate = false;
                this.isDataLoaded = true;
                this.ngxLoader.stop();
            }, 500);
        }
    }

    isBackButtonDisabled(): boolean {
        return this.stepper?.selectedIndex === 0;
    }

    displayNext(): boolean {
        if (this.totalStepsCount !== undefined) {
            return (this.stepper?.selectedIndex < this.totalStepsCount - 1);
        } else {
            return true;
        }
    }

    displaySave(): boolean {
        return (this.screenTemplateLayout?.action === ActionType.Edit
            || (this.stepper?.selectedIndex === this.totalStepsCount - 1
                && this.screenTemplateLayout?.action !== ActionType.View)) && !history?.state?.disableSaveButton;
    }

    areStepsValid(currentStep: number): boolean {
        if (!this.patientForm?.controls) {
            return false;
        }
        if (this.skipPatientFormValidation) {
            return true;
        }
        switch (currentStep) {
            case 0:
                return this.createPatientUtils.areFormControlsValid(
                    this.patientForm, [
                        'clientIdentification->NoDocument',
                        'clientIdentification->DocumentCountry',
                        'clientIdentification->DocumentType',
                        'clientIdentification->DocumentNumber',
                        'FirstName1',
                        'FirstName2',
                        'LastName1',
                        'LastName2',
                        'Gender',
                        'BirthDate',
                        'WhatsappNotifications',
                        'OnlineAccount',
                    ],
                );
            case 1:
                return this.createPatientUtils.areFormControlsValid(
                    this.patientForm, [
                        'addressInformation->Country',
                        'addressInformation->Region',
                        'addressInformation->Address.Locality',
                        'addressInformation->Address.Street',
                        'addressInformation->Address.StreetNumber',
                        'addressInformation->Address.Floor',
                        'addressInformation->Address.ApartmentNumber',
                        'mainPhoneNumber',
                        'mainPhoneNumber->MainPhoneNumber',
                        'mainPhoneNumber->MainPhoneNumberCountryCode',
                        'alternatePhoneNumber',
                        'alternatePhoneNumber->AlternatePhoneNumber',
                        'alternatePhoneNumber->AlternatePhoneNumberCountryCode',
                        'Email',
                    ],
                );
            case 2:
                return this.createPatientUtils.areFormControlsValid(
                    this.patientForm, [
                        'coveragePlans',
                        'coveragePlans->MainCoveragePlan',
                        'coveragePlans->SecondaryCoveragePlans',
                        'tags',
                        'tags->Tags',
                        'Remarks',
                        'externalKeys->ExternalKeys'
                    ],
                );
            case 3:
                return this.createPatientUtils.areFormControlsValid(
                    this.patientForm, [
                        'exceptions->Service',
                        'exceptions->Specialty',
                        'exceptions->Resource',
                        'exceptions->ValidTo'
                    ]
                ) && !Object.values(this.patientForm.get('exceptions').value).some(value => value);
            case 4:
                return this.createPatientUtils.areFormControlsValid(
                    this.patientForm, [
                        'timeDependentTags->TimeDependentTag',
                        'timeDependentTags->DateTimeFrom',
                        'timeDependentTags->DateTimeTo',
                        'timeDependentTags->NumberOfDays'
                    ]
                ) && !Object.values(this.patientForm.get('timeDependentTags').value).some(value => value);
            default:
                // Other steps which don't need validation
                return true;
        }
    }

    onStepHeaderClick(stepClicked: number) {
        // Click event is fired after selectedIndex is changed, so if selectedIndex === stepClicked then the
        // user just clicked on stepClicked and the move was successful
        if (this.skipPatientFormValidation) {
            // If skipPatientFormValidation is true, the user was on view and clicked on a header step to enter the
            // edit flow. We simulate a navigateFromStepToStep from step 0 to the clicked step
            this.stepper.selectedIndex = 0;
            this.skipPatientFormValidation = false;
        }
        const selectedIndex = this.stepper?.selectedIndex;
        this.navigateFromStepToStep(selectedIndex, stepClicked);
    }

    onStepChange(step) {
        const createPatientOverviewStep = this.createPatientUtils.getCreatePatientOverviewStep();
        if (step.selectedIndex === createPatientOverviewStep && this.patientForm.controls.addressInformation.valid) {
            // Overview step
            this.mappedPatient =
                this.createPatientUtils.mapPatientFormToPatientType(this.patientForm, this.createPatientUtils.initialPatient, this.action);
        }
        if (step.selectedIndex < step.previouslySelectedIndex) {
            // If the save button was disabled because we have opened the overview step coming from the patient dashboard after
            // we clicked on the view icon, enable the save button
            if (history?.state?.disableSaveButton) {
                history.state.disableSaveButton = false;
            }
        }
    }

    getStepControl(step: number): AbstractControl {
        return {
            invalid: !this.areStepsValid(step),
        } as { invalid?: boolean, pending?: boolean } as AbstractControl;
    }

    isTheWholeFormValid(): boolean {
        if (!this.areStepsValid(0)) {
            return false;
        }

        if (!this.areStepsValid(1)) {
            return false;
        }

        if (!this.areStepsValid(2)) {
            return false;
        }
        if (!this.areStepsValid(3)) {
            return false;
        }
        if (!this.areStepsValid(4)) {
            return false;
        }
        return true;
    }

    // ///

    private navigateFromStepToStep(selectedIndex: number, clickedIndex: number) {
        // Display error on first invalid step + move to that step
        for (let step = selectedIndex; step < clickedIndex; step++) {
            if (!this.areStepsValid(step)) {
                this.messagesService.warning('toastr.warning.formInvalidOrDataNotSaved');
                if (selectedIndex !== step) {
                    this.stepper.selectedIndex = step;
                }
                return;
            }
        }
        // If no invalid steps in-between, move to the clicked step
        this.stepper.selectedIndex = clickedIndex;
    }

    private listenToChildComponentsRequests() {
        this.createPatientUtils.childComponentRequests.pipe(
            debounceTime(constants.inputDebounceTime),
            distinctUntilChanged()
        ).subscribe(() => {
            // Here we need to add all requests that we need to made
            const addressInformationForm = this.patientForm.get('addressInformation') as FormGroup;
            const clientIdentificationForm = this.patientForm.get('clientIdentification') as FormGroup;
            const requestsToMadeFromChildComponents = [];
            if (addressInformationForm.controls[formControlNames.REGION].enabled) {
                requestsToMadeFromChildComponents.push(formControlNames.REGION);
                if (addressInformationForm.controls[formControlNames.REGION].value) {
                    requestsToMadeFromChildComponents.push(formControlNames.ADDRESS_LOCALITY);
                }
            }
            const uniqChildComponentRequestsDone = lodash.uniq(this.createPatientUtils.childComponentRequestsDone).sort();
            // Check if the request that have been made are included in the array
            const areAllRequestsDone = lodash.isEqual(requestsToMadeFromChildComponents.sort(), uniqChildComponentRequestsDone);
            if (areAllRequestsDone) {
                // Navigate to step provided in history.state
                if (history?.state?.step) {
                    this.goUntilStep(history.state.step);
                }
                this.createPatientUtils.childComponentRequestsDone = [];
            }
        });
    }

    private loadStepperFunctionality() {
        this.totalStepsCount = this.stepper?._steps?.length;

        // We need to add click listeners on mat-step-header by ourselves, as the stepper doesn't come
        // with a method we could use to check if the user click on the header is valid
        document.querySelectorAll('mat-step-header').forEach((matStepHeader, key) => {
            matStepHeader.addEventListener('click', (event) => this.onStepHeaderClick(key));
        });
        if (this.action === ActionType.Edit && this.stepper?._steps?.length) {
            // Set interacted = true to all steps, so user can jump from page 1 to page 4 if no errors in-between
            this.stepper._steps.forEach(step => {
                step.interacted = true;
            });
        }
    }

    private getCreateOrEditPatientObservable(): Observable<PatientType> {
        // Before we save the patient we make a new mapping to send only the fields that we have in the patientForm
        const newMappedPatient = this.createPatientUtils
            .getMappedPatientOnlyWithValuesThatAreEnabledAndVisible(this.mappedPatient, this.patientForm, this.action);
        this.mappedPatient = {
            ...newMappedPatient,
            etag: this.mappedPatient.etag
        };
        if (this.action === ActionType.Create) {
            return this.patientProvider.createPatientWithTags(this.mappedPatient) as unknown as Observable<PatientType>;
        } else if (this.action === ActionType.Edit) {
            // We need this mapping to make sure that initialPatient and mappedPatient have the same properties
            const initialPatient = {address: {}} as PatientType;
            Object.keys(this.createPatientUtils.initialPatient).forEach(key => {
                if (key in this.mappedPatient && key in this.createPatientUtils.initialPatient && key !== 'address') {
                    initialPatient[key] = this.createPatientUtils.initialPatient[key];
                }
            });
            if (!!this.createPatientUtils.initialPatient?.address) {
                Object.keys(this.createPatientUtils.initialPatient.address).forEach(key => {
                    if (key in this.mappedPatient.address && key in this.createPatientUtils.initialPatient.address) {
                        initialPatient.address[key] = this.createPatientUtils.initialPatient.address[key];
                    }
                });
            }
            this.createPatientUtils.initialPatient = initialPatient;
            return this.patientProvider.editPatientWithTags(
                this.createPatientUtils.initialPatient, this.mappedPatient
            ) as unknown as Observable<PatientType>;
        }
    }

    private setupState() {
        this.action = !history?.state?.action ? ActionType.Create : history?.state?.action;
        this.patientValidation = {isValid: true, errorMessage: ''};

        switch (this.action) {
            case ActionType.View: {
                this.setupStateView();
                break;
            }
            case ActionType.Create: {
                this.setupStateCreate();
                break;
            }
            case ActionType.Edit: {
                this.setupStateEdit();
                break;
            }
        }
    }

    private setupStateView() {
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.view', ActionType.View, undefined, 'button.back');
        // We need to map the country like this because we need only the name, and when we are in create mode the value from the select input
        // is only the name
        this.mappedPatient = {...history?.state?.patient, country: history?.state?.patient?.country?.countryName};
    }

    private setupStateCreate() {
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.create', ActionType.Create, 'label.create', 'label.close');
        // Reset initialPatient values
        this.createPatientUtils.initialPatient = {} as PatientType;
        this.createPatientUtils.patientControlExceptions = [];
        this.createPatientUtils.patientTimeDependentTags = [];

        // Set initial phone number country code based on language
        const countryCodeForRegion = this.libPhoneUtil.getCountryCodeForRegion(this.createPatientUtils.defaultCountryCode);
        this.patientForm.patchValue({
            mainPhoneNumber: {
                [formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]: countryCodeForRegion,
            },
            alternatePhoneNumber: {
                [formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]: countryCodeForRegion,
            },
        });

        // Set initial client identification data (either by search phrase of by system config defaults)
        this.clientIdentificationWasPrefilledFromSearchPhrase = false;
        if (history.state?.newPatientSearchPhrase) {
            // Preselect data based on search phrase
            this.prefillNewPatientInputsBasedOnSearchedText(history.state.newPatientSearchPhrase);
        }
        if (!this.clientIdentificationWasPrefilledFromSearchPhrase) {
            // Preselect the default document country and type
            const defaultCountry = this.createPatientUtils.clientIdentificationCountries?.find(({countryCode}) =>
                countryCode.toUpperCase() === this.createPatientUtils.defaultCountryCode.toUpperCase());
            if (defaultCountry?.countryName) {
                this.patientForm.patchValue({
                    clientIdentification: {
                        DocumentCountry: defaultCountry.countryName,
                        DocumentType: 'NationalId',
                    },
                });
            }
        }

        // Set initial address country (by default)
        const defaultAddressCountry = this.createPatientUtils.clientIdentificationCountries?.find(({countryCode}) =>
            countryCode.toUpperCase() === this.createPatientUtils.defaultCountryCode.toUpperCase());
        if (defaultAddressCountry?.countryName) {
            this.patientForm.patchValue({
                addressInformation: {
                    Country: defaultAddressCountry.countryName,
                },
            });
        }
    }

    private setupStateEdit() {
        this.screenTemplateLayout = this.generalUtils.setTemplateLayout('label.edit', ActionType.Edit, 'button.save', 'label.close');
        this.createPatientUtils.patientControlExceptions = [];
        this.mappedPatient = history?.state?.patient ?? this.patientContextService.patient;
        this.createPatientUtils.initialPatient = lodash.cloneDeep(history?.state?.patient ?? this.patientContextService.patient);
        this.prefillPatientDetails(this.mappedPatient);
        // Validate all inputs
        this.patientForm.markAllAsTouched();
    }

    private getPatientControlExceptions(patientControlExceptions: PatientControlRuleExceptionType[]) {
        const serviceObservables = this.getServiceObservables(patientControlExceptions);
        const specialityObservables = this.getSpecialityObservables(patientControlExceptions);
        const resourceObservables = this.getResourceObservables(patientControlExceptions);
        const allObservables: Observable<any>[] = lodash.flatten([serviceObservables, specialityObservables, resourceObservables]);

        this.ngxLoader.start();
        forkJoin(allObservables).subscribe((response) => {
            if (this.createPatientUtils.patientControlExceptions.length <= 0) {
                patientControlExceptions.forEach(exception => {
                    const patientControlExceptionService: ServiceType = response.find(({id}) => exception.serviceId === id);
                    this.createPatientUtils.patientControlExceptions.push({
                        service: {
                            ...patientControlExceptionService,
                            specialityName: patientControlExceptionService?.speciality?.name ?? ''
                        },
                        specialty: response.find(({id}) => exception.specialtyId === id),
                        resource: response.find(({id}) => exception.resourceId === id),
                        validTo: exception.validTo,
                        id: exception.id
                    });
                });
            }
            // If it navigates automatically to another page, don't stop the loader yet
            if (!history?.state?.step) {
                this.ngxLoader.stop();
            }
        }, err => {
            this.messagesService.handlingErrorMessage(err);
            this.ngxLoader.stop();
        });
    }

    private getServiceObservables(patientControlExceptions: PatientControlRuleExceptionType[]): Observable<any>[] {
        const serviceIds = patientControlExceptions.map(({serviceId}) => serviceId);
        const queryFilter: ODataQueryObjectType = this.createPatientUtils.getQueryFilterForExceptionsService();
        return serviceIds.map((id) => this.cacheService.getDataById('Services', this.serviceProvider, queryFilter, id));
    }

    private getSpecialityObservables(patientControlExceptions: PatientControlRuleExceptionType[]): Observable<any>[] {
        const specialtyIds: string[] = patientControlExceptions.map(({specialtyId}) => specialtyId);
        const queryFilter: ODataQueryObjectType = this.createPatientUtils.getQueryFilterForExceptionsSpeciality();
        return specialtyIds.map((id) => this.cacheService.getDataById('Specialities', this.specialityProvider, queryFilter, id));
    }

    private getResourceObservables(patientControlExceptions: PatientControlRuleExceptionType[]): Observable<any>[] {
        const resourceIds = patientControlExceptions.map(({resourceId}) => resourceId);
        const queryFilter: ODataQueryObjectType = this.createPatientUtils.getQueryFilterForExceptionsResource();
        return resourceIds.map((id) => this.cacheService.getDataById('Resources', this.resourceProvider, queryFilter, id));
    }

    private getPatientFormControls(): FormGroup {
        return this.formBuilder.group(
            {
                clientIdentification: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.clientIdentificationOptions),
                })),
                addressInformation: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.addressInformationOptions),
                })),
                mainPhoneNumber: this.formBuilder.group({
                    ...this.dynamicFormInputService.getFormControls(this.mainPhoneNumberOptions),
                }, {
                    // In order to validate 2 inputs at the same time we need to implement cross-field validation.
                    // So, we have to make a group of the inputs we want to be validated together and pass the custom validator to the group.
                    // In this situation the validation is made to the whole group not for every input.
                    ...this.dynamicFormInputService.getPhoneFormGroupOptions(formControlNames.MAIN_PHONE_NUMBER),
                }),
                alternatePhoneNumber: this.formBuilder.group({
                    ...this.dynamicFormInputService.getFormControls(this.alternatePhoneNumberOptions),
                }, {
                    ...this.dynamicFormInputService.getPhoneFormGroupOptions(formControlNames.ALTERNATE_PHONE_NUMBER),
                }),
                ...this.dynamicFormInputService.getFormControls(this.createPatientStepOneFormOptions),
                ...this.dynamicFormInputService.getFormControls(this.createPatientStepTwoFormOptions),
                coveragePlans: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.coveragePlanFormOptions),
                })),
                tags: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.tagsFormOptions),
                })),
                externalKeys: this.formBuilder.group({
                    ExternalKeys: this.formBuilder.array([])
                }),
                exceptions: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.exceptionsFormOptions),
                    ...this.dynamicFormInputService.getFormControls(this.createPatientUtils.getValidToFormOptions())
                })),
                timeDependentTags: this.formBuilder.group(({
                    ...this.dynamicFormInputService.getFormControls(this.timeDependentTagsOptions),
                    ...this.dynamicFormInputService.getFormControls(this.createPatientUtils.getTimeDependentTagsDynamicInputFormOptions())
                })),
                ...this.dynamicFormInputService.getFormControls(this.createPatientStepThreeFormOptions),
            }
        );
    }

    private loadForms() {
        // Make sure loading forms is always done after the initial getTenantCustomizingValuesFor call
        // Step 1:
        this.createPatientStepOneFormOptions = this.createPatientUtils.getCreatePatientStepOneFormOptions();
        this.clientIdentificationOptions = this.createPatientUtils.getClientIdentificationSubformOptions();
        // Step 2:
        this.createPatientStepTwoFormOptions = this.createPatientUtils.getCreatePatientStepTwoFormOptions();
        this.addressInformationOptions = this.createPatientUtils.getAddressInformationSubformOptions();
        this.mainPhoneNumberOptions = this.createPatientUtils.getMainPhoneNumberSubformOptions();
        this.alternatePhoneNumberOptions = this.createPatientUtils.getAlternatePhoneNumberSubformOptions();
        // Step 3:
        this.coveragePlanFormOptions = this.createPatientUtils.getCoveragePlansSubformOptions();
        this.tagsFormOptions = this.createPatientUtils.getTagsSubformOptions();
        this.externalKeysFormOptions = this.createPatientUtils.getExternalKeysSubformOptions(this.action === ActionType.Edit);
        this.createPatientStepThreeFormOptions = this.createPatientUtils.getCreatePatientStepThreeFormOptions();
        // Step 4:
        this.exceptionsFormOptions = this.createPatientUtils.getExceptionsSubformOptions();
        // Step 5
        this.timeDependentTagsOptions = this.createPatientUtils.getTimeDependentTagsSubformOptions();

        this.patientForm = this.getPatientFormControls();
    }

    private onTenantCustomizingResponse(response: ProcessedTenantCustomizingGroupedByControlNameType) {
        this.tenantCustomizingData = response;
        this.loadForms();
        this.setupState();
    }

    private loadData() {
        this.ngxLoader.start();
        forkJoin(this.getLoadDataObservables()).pipe(take(1)).subscribe(([tenantCustomizingResponse, documentTypes, countries, tags]
        ) => {
            // Load data needed for client identification
            if (documentTypes && countries?.value) {
                this.saveDocumentTypesAndCountries(documentTypes as unknown as DocumentType[], countries?.value);
            }
            // Load forms and set up state
            this.onTenantCustomizingResponse(tenantCustomizingResponse);
            // We need to trigger this Subject in case there are no requests made in components
            const {countryId, regionId, address} = this.createPatientUtils?.initialPatient;
            if (!countryId && !regionId && !address?.locality) {
                this.createPatientUtils.childComponentRequests.next();
            }
            // After view initializes, load stepper functionality
            setTimeout(() => {
                this.loadStepperFunctionality();
                if (!history?.state?.step) {
                    this.ngxLoader.stop();
                    this.isDataLoaded = true;
                }
            }, 0);
        }, (error) => {
            this.messagesService.handlingErrorMessage(error);
            this.ngxLoader.stop();
        });
    }

    private getLoadDataObservables(): Observable<any>[] {
        const translatedLanguage = this.translatedLanguageService.getLanguageForCountryCode(this.translatedLanguageService.translatedLanguage);
        return [
            this.tenantCustomizingService.getTenantCustomizingValuesFor(this.getTenantCustomizingFilterObject()),
            history?.state?.action !== ActionType.View ? this.patientProvider.getDocumentTypes() : of(null),
            history?.state?.action !== ActionType.View ? this.countryProvider.getCountriesForLanguage(translatedLanguage) : of(null)
        ];
    }

    private getTenantCustomizingFilterObject(): TenantCustomizingFilterInputType {
        this.userInfoStorage = this.configDataService.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        return {
            ui: 'reception.patientOverview',
            entity: 'PatientDTO',
            situation: this.action === ActionType.Create ? 'Add' : 'Edit',
            role: this.userInfoStorage?.team?.organizationalRole
        } as TenantCustomizingFilterInputType;
    }

    private saveDocumentTypesAndCountries(documentTypes: DocumentType[], countries: CountryType[]) {
        this.createPatientUtils.clientIdentificationAvailableDocumentTypes =
            documentTypes.map((documentType: DocumentType) => lodash.upperFirst(documentType.name));
        this.createPatientUtils.clientIdentificationCountries = lodash.orderBy(countries, ['countryName']);
    }

    private prefillClientIdentificationData(newPatientNames: string[]): void {
        // Search the countries to see if any of them has the country code equal to the first word of the phrase
        const thisCountryExist = this.createPatientUtils.clientIdentificationCountries?.find(
            ({countryCode}) => countryCode.toUpperCase() === newPatientNames[0].toUpperCase()
        );
        if (thisCountryExist) {
            // If we have a country set document country and document number
            this.clientIdentificationWasPrefilledFromSearchPhrase = true;
            this.patientForm.controls.clientIdentification.patchValue({
                DocumentCountry: thisCountryExist.countryName,
                DocumentNumber: newPatientNames[1],
                DocumentType: this.createPatientUtils.getPatientDocumentType(thisCountryExist.countryCode)
            });
            this.createPatientUtils.verifyPatientDocumentNumberAndMPI(this.patientForm);
        } else {
            // Otherwise, display a toaster error
            this.messagesService.warning('label.userInputCouldNotBePrefilledBasedOnTheSearchCriteria', true);
        }
    }

    private prefillNewPatientInputsBasedOnSearchedText(newPatientSearchPhrase: string) {
        // Regex to check if a string contains a number
        const isStringContainsNumber = /\d/;
        // Split new patient names
        const newPatientNames = newPatientSearchPhrase.split(' ');
        if (isStringContainsNumber.test(newPatientSearchPhrase)) {
            if (newPatientNames.length === 1) {
                // If we have only one word and the word contains numbers prefill document number with the
                // word. Assume it's Chile national id (Example: 8642131-3)
                this.patientForm.controls.clientIdentification.get('DocumentNumber').patchValue(newPatientSearchPhrase);
            } else if (newPatientNames.length === 2) {
                // If we have two words and the second one contains numbers search for the country and
                // prefill Client Identification Data (Example: RO 123)
                this.prefillClientIdentificationData(newPatientNames);
            }
        } else {
            // If the phrase does not contain numbers prefill the names (Example: Test Test)
            this.prefillNewPatientNames(newPatientNames);
        }
    }

    private prefillNewPatientNames(newPatientNames: string[]) {
        switch (newPatientNames.length) {
            case 0: {
                return;
            }
            case 1: {
                this.patientForm.patchValue({FirstName1: newPatientNames[0]});
                return;
            }
            case 2: {
                this.patientForm.patchValue({
                    FirstName1: newPatientNames[0],
                    LastName1: newPatientNames[1]
                });
                return;
            }
            case 3: {
                this.patientForm.patchValue({
                    FirstName1: newPatientNames[0],
                    LastName1: newPatientNames[1],
                    LastName2: newPatientNames[2]
                });
                return;
            }
            default: {
                this.patientForm.patchValue({
                    FirstName1: newPatientNames[0],
                    FirstName2: newPatientNames[1],
                    LastName1: newPatientNames[2],
                    LastName2: newPatientNames[3]
                });
            }
        }
    }

    private prefillPatientDetails(patient: PatientType): void {
        const mainPhoneNumber: PhoneNumberType = this.generalUtils.parsePhoneNumber(patient?.mainPhoneNumber);
        const alternatePhoneNumber: PhoneNumberType = this.generalUtils.parsePhoneNumber(patient?.alternatePhoneNumber);
        const mainCoveragePlan: CoveragePlanType[] = lodash.filter(patient?.coveragePlans ?? [], {mainCoveragePlan: true});
        const secondaryCoveragePlans: CoveragePlanType[] = lodash.filter(patient?.coveragePlans ?? [], {mainCoveragePlan: false});
        const documentCountry: CountryType = this.createPatientUtils.clientIdentificationCountries?.find(
            ({countryCode}) => countryCode?.toUpperCase() === patient?.documentCountry
        );
        const addressCountry: CountryType = this.createPatientUtils.clientIdentificationCountries?.find(
            (country: CountryType) => country?.id === patient?.countryId
        );
        const birthdate = patient.birthDate ? moment.utc(patient.birthDate).format(moment.localeData().longDateFormat('L')) : '';

        // Form arrays cannot be updated with patchValue or setValue, so we need to push every value into the array
        const externalKeys = this.patientForm.get('externalKeys').get(formControlNames.EXTERNAL_KEYS) as FormArray;
        patient.patientExternalKeys?.map(({origin, key, id}) => {
            externalKeys?.push(this.formBuilder.group({
                [formControlNames.ORIGIN]: origin,
                [formControlNames.KEY]: key,
                // The id is not used as input in html, he makes the mapping easier because we need to also send the id to get the patch correctly
                [formControlNames.ID]: id
            }));
        });
        this.getPatientControlExceptions(patient.patientControlExceptions ?? []);
        this.patientForm.patchValue({
            [formControlNames.FIRST_NAME1]: patient.firstName1,
            [formControlNames.FIRST_NAME2]: patient.firstName2,
            [formControlNames.LAST_NAME1]: patient.lastName1,
            [formControlNames.LAST_NAME2]: patient.lastName2,
            [formControlNames.BIRTHDATE]: birthdate,
            [formControlNames.EMAIL]: patient.email,
            [formControlNames.GENDER]: patient.gender,
            [formControlNames.ONLINE_ACCOUNT]: lodash.find(patient.clientAgreements ?? [], {version: PatientClientAgreementsEnum.OnlineAccount}),
            [formControlNames.WHATSAPP_NOTIFICATIONS]: lodash.find(patient.clientAgreements ?? [], {version: PatientClientAgreementsEnum.WhatsappNotifications}),
            [formControlNames.REMARKS]: patient.remarks,
            clientIdentification: {
                [formControlNames.DOCUMENT_NUMBER]: patient.documentNumber,
                [formControlNames.DOCUMENT_TYPE]: patient.documentType,
                [formControlNames.DOCUMENT_COUNTRY]: documentCountry?.countryName,
                [formControlNames.NO_DOCUMENT]: patient.noDocument,
            },
            addressInformation: {
                [formControlNames.COUNTRY]: addressCountry?.countryName ?? null,
                [formControlNames.REGION]: patient.region?.name,
                [formControlNames.ADDRESS_LOCALITY]: patient.address?.locality,
                [formControlNames.ADDRESS_STREET]: patient.address?.street,
                [formControlNames.ADDRESS_STREET_NUMBER]: patient.address?.streetNumber,
                [formControlNames.ADDRESS_FLOOR]: patient.address?.floor,
                [formControlNames.ADDRESS_APARTMENT_NUMBER]: patient.address?.apartmentNumber,
                [formControlNames.ADDRESS_ZIP_CODE]: patient.address?.zipCode,
            },
            mainPhoneNumber: {
                [formControlNames.MAIN_PHONE_NUMBER_COUNTRY_CODE]: mainPhoneNumber.countryCode,
                [formControlNames.MAIN_PHONE_NUMBER]: mainPhoneNumber.phoneNumber,
            },
            alternatePhoneNumber: {
                [formControlNames.ALTERNATE_PHONE_NUMBER_COUNTRY_CODE]: alternatePhoneNumber.countryCode,
                [formControlNames.ALTERNATE_PHONE_NUMBER]: alternatePhoneNumber.phoneNumber,
            },
            coveragePlans: {
                [formControlNames.MAIN_COVERAGE_PLAN]: mainCoveragePlan ?? [],
                [formControlNames.SECONDARY_COVERAGE_PLANS]: secondaryCoveragePlans ?? [],
            },
            tags: {
                [formControlNames.TAGS]: patient.tags,
            },
        });
    }
}
